import { InboxTab } from 'app/inbox/types';
import { LocalStorage } from 'app/vault/VaultTokenStorage';

export type MemberChartState = {
  inboxMenuOpen: boolean;
  activeInboxMenuTab: InboxTab;
  openTabs: string[];
  activeTab: string | null;
  activeMemberId: string | null;
};

class MemberChartStorage {
  private key = 'carehub-inbox-multi-tabs';

  constructor(private storage: LocalStorage = localStorage) {}

  get(): MemberChartState {
    const defaultState: MemberChartState = {
      activeInboxMenuTab: InboxTab.TODAYS,
      activeMemberId: null,
      activeTab: null,
      inboxMenuOpen: false,
      openTabs: [],
    };
    return JSON.parse(
      this.storage.getItem(this.key) ?? JSON.stringify(defaultState),
    );
  }

  set(data: Partial<MemberChartState>): void {
    const prev = this.get();
    this.storage.setItem(this.key, JSON.stringify({ ...prev, ...data }));
  }

  clear(): void {
    this.storage.removeItem(this.key);
  }
}

const memberChartStorage = new MemberChartStorage(localStorage);
export default memberChartStorage;
