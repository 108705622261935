import { FetchArgs } from '@reduxjs/toolkit/query';
import { listenerApi } from 'app/services/server/listenerApi';
import { ServerType } from 'app/services/server/types';

import { GetConversationsParams, GetConversationsResponse } from './types';

export const INBOX_BASE_URL = 'care_hub/inbox/v1';

export const inboxApi = listenerApi.injectEndpoints({
  endpoints: (builder) => ({
    getConversations: builder.query<
      GetConversationsResponse,
      GetConversationsParams
    >({
      extraOptions: { server: ServerType.Listener },
      query: ({ status, memberIds, limit, cursor }: GetConversationsParams) => {
        return {
          params: buildGetConversationsUrlParameters({
            cursor,
            limit,
            memberIds,
            status,
          }),
          responseHandler: 'json',
          url: `${INBOX_BASE_URL}/conversations`,
        } as FetchArgs;
      },
    }),
  }),
  overrideExisting: false,
});

export const buildGetConversationsUrlParameters = (
  conversationParams: GetConversationsParams,
) => {
  const { memberIds, status, limit, cursor } = conversationParams;

  const params = new URLSearchParams();
  // memberId must be passed as individual query params
  (memberIds || []).forEach((id) => {
    params.append('memberId', id.toString());
  });
  if (status) params.append('status', status);
  if (limit) params.append('limit', limit.toString());
  if (cursor) params.append('cursor', cursor);

  return params;
};

// hooks are auto generated based on endpoints we add;
// so once you add a new endpoint query you can export it as a hook
export const { useGetConversationsQuery } = inboxApi;
