import { ILogger } from 'app/state/log/Logger';
import appointmentErrors from 'i18n/apps/appointments/Errors/en_US.json';
import memberDemographicsErrors from 'i18n/apps/memberDemographics/Errors/en_US.json';
import validationErrors from 'i18n/apps/shared/ValidationErrors/en_US.json';
import { DEFAULT_LOCALE, LocaleName } from 'i18n/locales';

const errorsTable_en_US = {
  ...memberDemographicsErrors,
  ...appointmentErrors,
  ...validationErrors,
};

const errorsForLocale: Record<LocaleName, Record<string, string>> = {
  [LocaleName.EN_US]: errorsTable_en_US,
};

type GenericError = {
  __typename: string;
  errorCode: string;
  path: string | null;
};

const useValidationErrorPath = (error: any) => {
  if (error?.errorCode === 'VALIDATION ERROR') {
    return error?.path ? error.path : 'unknown';
  }
};

const removeDuplicates = (array: any[]) => {
  return array.filter((value, index, self) => self.indexOf(value) === index);
};

/**
 * Given a list of error objects, returns a string of error messages.
 * In most cases, the errorCode is used to look up the error message
 * in the error table. If the errorCode is equal to a "VALIDATION ERROR",
 * the path will be used to retrieve the error message.
 */
export const getExpectedErrorMessages = (
  errors: (GenericError | null)[],
  logger?: ILogger,
  locale: LocaleName = DEFAULT_LOCALE,
): string => {
  const errorMessageTable =
    errorsForLocale[locale] ?? errorsForLocale[DEFAULT_LOCALE];

  const errorMessages = errors.map((error) => {
    let errorMessage = '';
    if (error?.errorCode) {
      errorMessage =
        errorMessageTable[useValidationErrorPath(error) ?? error.errorCode] ??
        '';
    }
    if (errorMessage === '' && logger) {
      logger.error(
        new Error(
          `Error message not found for errorCode: ${error?.errorCode}, path: ${error?.path}, locale: ${locale}`,
        ),
      );
    }
    return errorMessage;
  });
  return removeDuplicates(errorMessages).join('\n');
};
