import VideocamIcon from '@mui/icons-material/Videocam';
import { useLogger } from 'app/state/log/useLogger';
import Messages from 'i18n/en/appointment.json';
import React, { SyntheticEvent, useCallback } from 'react';
import { Button } from 'shared-components/button/Button';
import { formatZoomMeetingUrl } from 'utils';

import styles from './ZoomButton.module.scss';

// Currently, clinical appointments generate the Join Zoom Meeting URL using the meetingId and the
// formatZoomMeetingUrl function. In the future, we plan to have the server provide the full Zoom
// meeting URL for both clinical and coaching sessions. Until then, the optional prop meetingUrl
// will be used to support this functionality.
export type ZoomButtonProps = {
  meetingId: string;
  label: string;
  className?: string;
  appointmentId?: string;
  noteId?: string;
  meetingUrl?: string | null;
};

export const ZoomButton = (props: ZoomButtonProps) => {
  const {
    label,
    meetingId,
    meetingUrl,
    className,
    appointmentId,
    noteId,
  } = props;
  const logger = useLogger();

  const onClick = useCallback(
    (event?: SyntheticEvent<Element, Event> | undefined) => {
      event?.stopPropagation();

      if (!meetingId) {
        logger.error(new Error(Messages.noZoomId), {
          appointmentId,
          noteId,
        });
        return;
      }

      logger.info(Messages.openZoomId, {
        appointmentId,
        meetingId,
        noteId,
      });

      window.open(
        meetingUrl ?? formatZoomMeetingUrl(meetingId),
        '_blank',
        'noopener, noreferrer',
      );
    },
    [meetingId, logger, appointmentId, noteId],
  );

  return (
    <Button
      testId={`zoombutton-${meetingId}`}
      className={className || styles.zoomLink}
      endIcon={<VideocamIcon />}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};
