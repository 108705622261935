import { CardFieldAndGridCell } from 'app/member-chart-cards/card-field/CardField';
import { CardSection } from 'app/member-chart-cards/card-section/CardSection';
import styles from 'app/member-chart-cards/prescriptions/PrescriptionCard.module.scss';
import { Prescription } from 'app/member-chart-cards/prescriptions/PrescriptionCardTypes';
import React from 'react';

interface PrescriptionCardSectionProps {
  prescription: Prescription | null;
  'data-testid'?: string;
}

export const PrescriptionCard = (props: PrescriptionCardSectionProps) => {
  const { prescription, 'data-testid': testId } = props;

  const effectiveDate = prescription?.effectiveDate
    ? new Date(prescription.effectiveDate).toDateString()
    : prescription?.effectiveDate;

  if (prescription) {
    let pharmacyElement = (
      <div data-testid="inactive-pharmacy">
        <b>Pharmacy: </b>
        Inactive
      </div>
    );

    if (prescription?.pharmacy) {
      pharmacyElement = (
        <>
          <div className={styles.activePrescriptionItem}>
            <b>Pharmacy: </b>
            {prescription.pharmacy.name}
          </div>
          <div className={styles.activePrescriptionItem}>
            {prescription.pharmacy.address1}
          </div>
          <div className={styles.activePrescriptionItem}>
            {`${prescription.pharmacy.city}, ${prescription.pharmacy.state} ${prescription.pharmacy.zip}`}
          </div>
          <div className={styles.activePrescriptionItem}>
            {prescription.pharmacy.phone}
          </div>
        </>
      );
    }
    const valueElement = (
      <div className={styles.prescriptionContent}>
        <div className={styles.activePrescriptionItem}>
          <b>Effective date: </b>
          {effectiveDate}
        </div>
        <div className={styles.activePrescriptionItem}>
          <b>Refills: </b>
          {prescription.refills}
        </div>
        {pharmacyElement}
      </div>
    );

    const fields: CardFieldAndGridCell[] = [
      {
        'data-testid': testId,
        label: prescription?.displayName ?? '',
        labelForAnalytics: 'Prescription Card Section',
        value: valueElement,
      },
    ];
    return (
      <CardSection
        className={styles.cardSection}
        fields={fields}
        hideTooltip={true}
      />
    );
  }

  return null;
};
