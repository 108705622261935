import { usePubNubAPI } from 'app/coach/pubnub/PubNubContextProvider';
import { Width } from 'types/StyleTypes';
import { TextArea } from 'shared-components/text-area/TextArea';
import { textAreaFocusChangeEvent } from 'app/state/amplitude/actions/chat';
import {
  addTypingState,
  onCareProviderTypingEvent,
  onEnterClick,
  setInputValue,
} from 'app/state/chat/actions';
import {
  selectIsConvoInTheTypingStateMap,
  selectLoggingContextWithMemberData,
  selectTextAreaInput,
  selectTypingStateByChannelId,
} from 'app/state/chat/selectors';
import { useLogger } from 'app/state/log/useLogger';
import { selectCountDownForDelayedMessage } from 'app/state/timed-sends/selectors';
import React, { KeyboardEventHandler, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { TypingState } from '../types';
import styles from './TextAreaContainer.module.scss';

/* this component is an intermediary between the TextAreaGroup (which responsibility is to be a container for the
text area, canned replies, smart replies, timed sends and any other features related to sending messages) and the TextArea (a component that we
re-use to display the text area itself).
We use this intermediary to:
- better divide the responsibility between components
- prevent unnecessary re-renders of TextAreaGroup's children when an input is changed  and
- simplify testing */
interface TextAreaContainerProps {
  currentChannelId: string;
  callerId: string;
  memberId: string;
}
export const TextAreaContainer = ({
  currentChannelId,
  callerId,
  memberId,
}: TextAreaContainerProps) => {
  const dispatch = useDispatch();
  const logger = useLogger();
  const api = usePubNubAPI();

  const { setPubnubState } = api;
  const { nextMessageId, clock, isTyping } = selectTypingStateByChannelId(
    currentChannelId,
  );
  const text = selectTextAreaInput(currentChannelId);
  const isConvoInTheTypingStateMap = selectIsConvoInTheTypingStateMap(
    currentChannelId,
  );
  const inputValue = selectTextAreaInput(currentChannelId);
  const isDelayCountdownActive = Boolean(
    selectCountDownForDelayedMessage(currentChannelId),
  );
  const logsContext = selectLoggingContextWithMemberData(callerId, memberId);
  const updatePubnubState = async ({
    isTyping,
    clock,
    nextMessageId,
  }: Partial<TypingState>) => {
    return setPubnubState({
      channels: [currentChannelId],
      state: {
        clock,
        id: nextMessageId,
        is_typing: isTyping,
      },
    });
  };

  const handlePubnubState = async (value: string) => {
    const newTypingState: Partial<TypingState> = {};
    const hasText = value.length;
    // it means a care provider started to type
    if (!isTyping && hasText) {
      // set isTyping true and update pubnub state
      newTypingState.isTyping = true;
      newTypingState.clock = clock + 1;

      // it means a care provider ceased to type
    } else if (isTyping && !hasText) {
      // set isTyping false and update pubnub state
      newTypingState.isTyping = false;
      newTypingState.clock = clock + 1;
    }

    // update state if something has changed
    if (Object.keys(newTypingState).length > 0) {
      try {
        await updatePubnubState({
          clock: newTypingState.clock,
          isTyping: newTypingState.isTyping,
          nextMessageId,
        });
      } catch (e) {
        logger.error(
          new Error(`Could not update pubnub state.`, { cause: e }),
          {
            callerId,
            currentChannelId,
            error: e,
            memberId,
            status: e.status,
          },
        );
      }
      dispatch(
        onCareProviderTypingEvent({
          channelId: currentChannelId,
          logsContext: {
            ...logsContext,
            is_typing: newTypingState.isTyping || isTyping,
          },
          updatedTypingState: {
            ...newTypingState,
          },
        }),
      );
    }
  };

  const onInputChange = (value: string) => {
    dispatch(setInputValue({ channelId: currentChannelId, input: value }));
    void handlePubnubState(value);
  };

  const onTyping: KeyboardEventHandler = async (e) => {
    if (e.key === 'Enter') {
      if (!inputValue || isDelayCountdownActive) return;
      e.preventDefault();
      dispatch(
        onEnterClick({
          channelId: currentChannelId,
          inputValue,
          logsContext,
          memberId,
          nextMessageId,
        }),
      );
    }
  };

  const onTextAreaFocus = () => {
    dispatch(
      textAreaFocusChangeEvent({
        ...logsContext,
        focus: true,
      }),
    );
  };

  const onTextAreaBlur = () => {
    dispatch(
      textAreaFocusChangeEvent({
        ...logsContext,
        focus: false,
      }),
    );
  };

  useEffect(() => {
    /* on opening a new convo we need to add it to the typingStateMap if it hasn't been added before */
    if (!isConvoInTheTypingStateMap) {
      dispatch(addTypingState({ channelId: currentChannelId }));
    }
  }, []);

  return (
    <div data-testid="text-area-container">
      <TextArea
        minRows={1}
        maxRows={10}
        width={Width.FULL}
        value={text}
        onChange={onInputChange}
        placeholder="Type here..."
        onKeyDown={onTyping}
        textAreaClassName={styles.textArea}
        onFocus={onTextAreaFocus}
        onBlur={onTextAreaBlur}
        disabled={isDelayCountdownActive}
      />
    </div>
  );
};
