import {
  ClinicalAppointmentById,
  ClinicalAppointmentByIdVariables,
} from '@headspace/carehub-graphql/dist/appointments/generated/ClinicalAppointmentById';
import { DEFAULT_TIMEZONE } from 'app/clinician/ClinicianSettingsComponent';
import { ApolloCachingStrategy } from 'app/constants';
import { useAppState, useDispatch } from 'app/state';
import { viewAppointmentAction } from 'app/state/amplitude/actions/appointments';
import { useQuery } from 'hooks/useQuery';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { ErrorState } from 'shared-components/error-state/ErrorState';

import {
  appointmentQuery,
  updateAppointmentForMeMutation,
} from './appointmentQueries';
import { UpdateAppointmentClinicianForm } from './UpdateAppointmentClinicianForm';
import { useUpdateAppointmentMutation } from './useUpdateAppointmentMutation';

/**
 * Container to update existing appointments for clinicians
 */
export function UpdateAppointmentClinicianContainer({
  appointmentId,
}: {
  appointmentId: string;
}) {
  const history = useHistory();
  const timezone = useAppState((_) => _.user.timezone) ?? DEFAULT_TIMEZONE;
  const onUpdate = useUpdateAppointmentMutation(
    updateAppointmentForMeMutation,
    appointmentId,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewAppointmentAction({ appointmentId }));
  }, [dispatch, appointmentId]);

  return useQuery<ClinicalAppointmentById, ClinicalAppointmentByIdVariables>(
    ({ getClinicalAppointment }) => {
      if (getClinicalAppointment) {
        return (
          <UpdateAppointmentClinicianForm
            onClose={history.goBack}
            onUpdate={onUpdate}
            timezone={timezone}
            data={getClinicalAppointment}
          />
        );
      }
      return (
        <div data-testid="appointment-not-found-clinician">
          <ErrorState error={new Error('Appointment not found')} />
        </div>
      );
    },
    appointmentQuery,
    {
      fetchPolicy: ApolloCachingStrategy.NETWORK_ONLY,
      variables: { input: { id: appointmentId } },
    },
  );
}
