import React from 'react';

import { CSCOutreachStatus } from './types';

export type Props = {
  history: (CSCOutreachStatus | null)[];
};

export function CSCAutoOutreacHistoryPopup(props: Props) {
  return (
    <div>
      {props.history.map((item) => (
        <div key={item?.createdAt}>
          {item?.pause && (
            <div>
              {' '}
              Disabled - {item?.reason} - {item?.createdAt} - {item?.updatedBy}{' '}
            </div>
          )}
          {!item?.pause && (
            <div>
              {' '}
              Enabled - {item?.reason} - {item?.createdAt} - {item?.updatedBy}{' '}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
