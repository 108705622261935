import gql from 'graphql-tag';

export const queryApptsForMemberSchedule = gql`
  query GetClinicalAppointmentsForMember(
    $input: GetClinicalAppointmentsForMemberInput!
  ) {
    getClinicalAppointmentsForMember(input: $input) {
      appointments {
        id
        drchronoId
        clinician {
          id
          user {
            id
          }
          fullName
          role
        }
        member {
          id
          firstName
          lastName
        }
        start
        end
        appointmentStatus
        cancelledReason
        type
        zoomMeetingId
        clinicalNote {
          id
          status
          noteType
          location
          statusUpdatedAt
        }
      }
    }
  }
`;
