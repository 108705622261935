import {
  arrayField,
  booleanField,
  field,
  FieldDefinitions,
  stringField,
} from '@ginger.io/react-use-form';
import {
  MentalStatusExam,
  MentalStatusExam_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MentalStatusExam';
import {
  MentalStatusExam_Association,
  MentalStatusExam_Orientation,
} from '@ginger.io/vault-clinical-notes/src/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MentalStatusExam';
import { optionalField } from 'app/notes-ui/shared/ValidationRules';

import { requiredIfYes } from './MentalStatusExamFormV2';

export const fieldDefs: FieldDefinitions<MentalStatusExam> = {
  appointmentId: stringField(),
  associations: arrayField<MentalStatusExam_Association>({ default: [] }),
  attentionAndConcentration: {
    description: stringField({ rules: [optionalField] }),
    isPresent: booleanField({ default: false, rules: [optionalField] }),
  },
  fundOfKnowledge: {
    description: stringField({ rules: [optionalField] }),
    isPresent: booleanField({ default: false, rules: [optionalField] }),
  },
  appearance: {
    effectWithinNormalLimits: field({ default: true, rules: [] }),
    other: {
      description: stringField({
        rules: [(value, state) => requiredIfYes(value, state, 'appearance')],
      }),
      isPresent: booleanField({ default: false, rules: [] }),
    },
  },
  insight: field({ rules: [optionalField] }),
  insightStatus: {
    effectWithinNormalLimits: booleanField({ default: true, rules: [] }),
    other: {
      isPresent: booleanField({ default: false, rules: [] }),
      description: stringField({
        rules: [(value, state) => requiredIfYes(value, state, 'insightStatus')],
      }),
    },
  },
  language: {
    description: stringField({ rules: [optionalField] }),
    isPresent: booleanField({ default: false, rules: [optionalField] }),
  },
  judgement: {
    effectWithinNormalLimits: booleanField({ default: true, rules: [] }),
    other: {
      isPresent: booleanField({ default: false, rules: [] }),
      description: stringField({
        rules: [(value, state) => requiredIfYes(value, state, 'judgement')],
      }),
    },
  },
  moodAndAffect: {
    affect: {
      congruentWithContent: booleanField({
        default: false,
        rules: [optionalField],
      }),
      congruentWithMood: booleanField({
        default: false,
        rules: [optionalField],
      }),
      scope: field({ rules: [optionalField] }),
    },
    affectDescription: arrayField({ rules: [optionalField] }),
    moodInPatientWord: stringField(),
  },
  cognitive: {
    effectWithinNormalLimits: booleanField({ default: true, rules: [] }),
    other: {
      isPresent: booleanField({ default: false, rules: [] }),
      description: stringField({
        rules: [(value, state) => requiredIfYes(value, state, 'cognitive')],
      }),
    },
  },
  orientation: arrayField<MentalStatusExam_Orientation>({ default: [] }),
  affect: {
    effectWithinNormalLimits: booleanField({ default: true, rules: [] }),
    other: {
      description: stringField({
        rules: [(value, state) => requiredIfYes(value, state, 'affect')],
      }),
      isPresent: booleanField({ default: false, rules: [] }),
    },
  },
  judgment: field({ rules: [optionalField] }),
  orientationStatus: {
    effectWithinNormalLimits: booleanField({ default: true, rules: [] }),
    other: {
      description: stringField({
        rules: [
          (value, state) => requiredIfYes(value, state, 'orientationStatus'),
        ],
      }),
      isPresent: booleanField({ default: false, rules: [] }),
    },
  },
  recentAndRemoteMemory: {
    description: stringField({ rules: [optionalField] }),
    isPresent: booleanField({ default: false, rules: [optionalField] }),
  },
  speech: arrayField({ rules: [optionalField] }),
  speechStatus: {
    effectWithinNormalLimits: booleanField({ default: true, rules: [] }),
    other: {
      description: stringField({
        rules: [(value, state) => requiredIfYes(value, state, 'speechStatus')],
      }),
      isPresent: booleanField({ default: false, rules: [] }),
    },
  },
  thoughtContent: arrayField({ rules: [optionalField] }),
  thoughtContentStatus: {
    effectWithinNormalLimits: booleanField({ default: true, rules: [] }),
    other: {
      description: stringField({
        rules: [
          (value, state) => requiredIfYes(value, state, 'thoughtContentStatus'),
        ],
      }),
      isPresent: booleanField({ default: false, rules: [] }),
    },
  },
  thoughtProcess: arrayField({ rules: [optionalField] }),
  thoughtProcessStatus: {
    effectWithinNormalLimits: booleanField({ default: true, rules: [] }),
    other: {
      description: stringField({
        rules: [
          (value, state) => requiredIfYes(value, state, 'thoughtProcessStatus'),
        ],
      }),
      isPresent: booleanField({ default: false, rules: [] }),
    },
  },
  version: field<MentalStatusExam_Version>({
    default: MentalStatusExam_Version.v0,
  }),
};
