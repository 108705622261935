import MaterialUITooltip, {
  TooltipProps as MaterialUITooltipProps,
} from '@mui/material/Tooltip';
import { classNameCombiner } from 'utils';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { FC } from 'react';

import styles from './Tooltip.module.scss';

export interface TooltipProps extends MaterialUITooltipProps {
  variation?: 'default' | 'regular' | 'compact';
}

const Tooltip: FC<TooltipProps> = (props) => {
  const { children, variation = 'default', classes, ...restProps } = props;
  const {
    enable_care_hub_notes_efficiency,
  } = useFeatureFlags().transientFeatureFlags;
  const tooltipStyle = enable_care_hub_notes_efficiency
    ? styles.tooltipV2
    : styles.tooltip;
  const tooltipArrow = enable_care_hub_notes_efficiency
    ? styles.tooltipArrowV2
    : styles.tooltipArrow;
  const variationStyle = {
    compact: {
      classes: {
        arrow: '',
        popper: styles.popper,
        tooltip: styles.compactTooltip,
      },
      restProps: {
        arrow: false,
        placement: 'bottom' as TooltipProps['placement'],
      },
    },
    default: {
      classes: {
        arrow: tooltipArrow,
        popper: styles.popper,
        tooltip: tooltipStyle,
      },
      restProps: {
        arrow: false,
        placement: 'right' as TooltipProps['placement'],
      },
    },
    regular: {
      classes: {
        arrow: tooltipArrow,
        popper: styles.popper,
        tooltip: tooltipStyle,
      },
      restProps: {
        arrow: true,
        placement: 'bottom-start' as TooltipProps['placement'],
      },
    },
  };
  const customClasses = {
    ...variationStyle[variation].classes,
    ...classes,
    popper: classNameCombiner([
      variationStyle[variation].classes.popper,
      classes?.popper ?? '',
    ]),
    tooltip: classNameCombiner([
      variationStyle[variation].classes.tooltip,
      classes?.tooltip ?? '',
    ]),
  };
  const customProps = { ...variationStyle[variation].restProps, ...restProps };

  return (
    <MaterialUITooltip
      data-html="true"
      data-testid="tooltip"
      classes={customClasses}
      {...customProps}
    >
      {children}
    </MaterialUITooltip>
  );
};

export default Tooltip;
