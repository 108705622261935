import { Avatar } from '@ginger.io/core-ui';
import { useAvatarURL } from 'app/collaboration/hooks/useAvatarURL';
import { ChatMessage } from 'app/collaboration/type';
import Tooltip from 'shared-components/tooltip/Tooltip';
import React from 'react';

import { CommunicationBubble } from './CommunicationBubble';
import styles from './MessageContainer.module.scss';

export interface MessageProp {
  message: ChatMessage;
  dateAndTime: string;
}

export const RepliedMessageContainer = ({
  message,
  dateAndTime,
}: MessageProp) => {
  const avatarUrl = useAvatarURL(message);
  const tooltipTitle = `${dateAndTime} . ${message.author.firstName} ${message.author.lastName}`;
  return (
    <div className={`${styles.container} ${styles.replyContainer}`}>
      <Avatar
        className={styles.avatar}
        firstName={message.author.firstName}
        lastName={message.author.lastName}
        src={avatarUrl}
        includeLastName={true}
      />
      <Tooltip
        classes={{ popper: styles.popper, tooltip: styles.tooltip }}
        title={tooltipTitle}
        enterDelay={0}
        enterTouchDelay={0}
        placement="bottom-end"
      >
        <div>
          <CommunicationBubble isOwner={false} chat={message} />
        </div>
      </Tooltip>
    </div>
  );
};
