import { BooleanOption } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/BooleanOption';
import { RiskLevel } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/RiskLevel';
import { SelfHarmRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/SelfHarmRisk';
import DeleteIcon from '@mui/icons-material/HighlightOffRounded';
import { Typography } from '@mui/material';
import {
  RiskFormComponent,
  RiskFormProps,
  RiskType,
} from 'app/coach/coach-notes/CoachNotesTypes';
import {
  FIDO_FIELDS,
  FIDO_PLACEHOLDERS,
} from 'app/coach/coach-notes/constants';
import { CardTextAreaField } from 'app/coach/coach-notes/note-detail-view/note-fields';
import { selfHarmRisk } from 'app/coach/coach-notes/note-detail-view/note-templates/fieldDefs';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { useRiskForm } from 'app/coach/coach-notes/useRiskForm';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import { EnumRadioField } from 'app/notes-ui/forms/fields';
import { BooleanRadioField } from 'app/notes-ui/forms/form-controls/BooleanRadioField';
import { classNameCombiner } from 'utils';
import React from 'react';

const FieldLabels = {
  DISCLOSED_TO: 'Who? *',
  DISCLOSURE:
    'Disclosure to anyone, such as a friend, family member, PCP, or therapist?',
  MEANS: 'Means',
  OTHER_RELEVANT_INFO: 'Other relevant information',
  RISK_LEVEL: 'Risk level outcome *',
  SOUGHT_MEDICAL_ATTENTION: 'Ever sought medical attention as a result? *',
  SUMMARY: 'Summarization of past self-harm risk',
  ...FIDO_FIELDS,
};

const Placeholders = {
  DISCLOSED_TO: 'Type here...',
  MEANS: 'Razor blade, hair pulling, burning...',
  OTHER_RELEVANT_INFO: 'Location, if imminent...',
  SUMMARY: 'Type here...',
  ...FIDO_PLACEHOLDERS,
};

const TOOLTIP_EVENT_LABEL = 'Coach Notes > Self-Harm Risk';

export const SelfHarmRiskForm: RiskFormComponent<SelfHarmRisk> = (
  props: RiskFormProps<SelfHarmRisk>,
) => {
  const {
    readOnly,
    inline,
    isRemovable,
    initialValue,
    onChange,
    removeRisk,
  } = props;
  const { fields, onRemoveRisk } = useRiskForm<SelfHarmRisk>({
    initialValue,
    onChange,
    removeRisk,
    riskType: RiskType.SELF_HARM_RISK,
    validationSchema: selfHarmRisk,
  });

  return (
    <div className={inline ? styles.inlineRiskSection : styles.cardSection}>
      <div
        className={classNameCombiner([
          styles.riskTitleField,
          isRemovable ? styles.withButton : '',
        ])}
      >
        <Typography className={styles.riskAssessmentTitle}>
          Self-Harm Risk
        </Typography>
        <span className={styles.inlineBorder} />
        {isRemovable && (
          <DeleteIcon
            data-testid="removeBtn"
            className={styles.removeRiskBtn}
            onClick={onRemoveRisk}
          />
        )}
      </div>

      <EnumRadioField
        label={FieldLabels.RISK_LEVEL}
        disabled={readOnly}
        options={RiskLevel}
        keysToExclude={['imminent']}
        field={fields.riskLevel}
        formControlStyles={{ label: styles.label }}
      />

      <FieldLabel className={styles.label} labelText={FieldLabels.SUMMARY} />
      <CardTextAreaField
        name="selfHarmRiskSummary"
        field={fields.summary}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.SUMMARY}`}
        tooltipText=""
        placeholder={Placeholders.SUMMARY}
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.groupLabel}`}
        labelText={FieldLabels.FIDO}
      />
      <div className={`${styles.helperText} ${styles.subtle}`}>
        {Placeholders.FIDO_HELPER_TEXT}
      </div>

      <CardTextAreaField
        name="selfHarmRiskFrequency"
        field={fields.frequency}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.FREQUENCY}`}
        tooltipText=""
        placeholder={Placeholders.FREQUENCY}
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.subtle}`}
        labelText={FieldLabels.INTENSITY}
      />
      <CardTextAreaField
        name="selfHarmRiskIntensity"
        field={fields.intensity}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.INTENSITY}`}
        tooltipText=""
        placeholder={Placeholders.INTENSITY}
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.subtle}`}
        labelText={FieldLabels.DURATION}
      />
      <CardTextAreaField
        name="selfHarmRiskDuration"
        field={fields.duration}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.DURATION}`}
        tooltipText=""
        placeholder={Placeholders.DURATION}
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.subtle}`}
        labelText={FieldLabels.ONSET}
      />
      <CardTextAreaField
        field={fields.onset}
        name="selfHarmRiskOnSet"
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.ONSET}`}
        tooltipText=""
        placeholder={Placeholders.ONSET}
        readOnly={readOnly}
      />

      <FieldLabel className={styles.label} labelText={FieldLabels.MEANS} />
      <CardTextAreaField
        name="selfHarmRiskMeans"
        field={fields.means}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.MEANS}`}
        tooltipText=""
        placeholder={Placeholders.MEANS}
        readOnly={readOnly}
      />

      <FieldLabel className={styles.label} labelText={FieldLabels.DISCLOSURE} />
      <BooleanRadioField
        disabled={readOnly}
        name="selfHarmRiskDisclosure"
        value={fields.disclosure.value}
        onChange={fields.disclosure.setValue}
      />

      {fields.disclosure.value === BooleanOption.yes && (
        <>
          <FieldLabel
            className={styles.label}
            labelText={FieldLabels.DISCLOSED_TO}
          />
          <CardTextAreaField
            name="selfHarmRiskDisclosedTo"
            field={fields.disclosedTo}
            tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.DISCLOSED_TO}`}
            tooltipText=""
            placeholder={Placeholders.DISCLOSED_TO}
            readOnly={readOnly}
          />
        </>
      )}

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.SOUGHT_MEDICAL_ATTENTION}
      />
      <BooleanRadioField
        disabled={readOnly}
        name="selfHarmRiskSoughtMedicalAttention"
        value={fields.soughtMedicalAttention.value}
        onChange={fields.soughtMedicalAttention.setValue}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.OTHER_RELEVANT_INFO}
      />
      <CardTextAreaField
        name="selfHarmRiskOtherRelevantInfo"
        field={fields.otherRelevantInfo}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.OTHER_RELEVANT_INFO}`}
        tooltipText=""
        placeholder={Placeholders.OTHER_RELEVANT_INFO}
        readOnly={readOnly}
      />
    </div>
  );
};
