import {
  field,
  FieldDefinitions,
  positiveNumberField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  Assessment,
  Assessment_CPTCode as CPTCode,
  Assessment_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/progress/Assessment';
import {
  ProgressAssessmentSection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { NumberField, TextAreaField } from 'app/notes-ui/forms/fields';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import {
  buildCodeSetFieldDefs,
  CodeSetFields,
} from 'app/notes-ui/shared/code-set/CodeSetFields';
import {
  AUTO_SAVE_DRAFT_DELAY,
  codeSetsFromAssessment,
} from 'app/notes-ui/utils';
import { acceptedPsychiatryCptCodes, filterCptCodes } from 'utils/notes';
import React from 'react';

type Props = {
  appointmentId: string;
  onSubmit: (data: ProgressAssessmentSection) => void;
  updateDraftNoteState: (data: ProgressAssessmentSection) => void;
  disabled?: boolean;
  savingNote?: boolean;
  initialValue?: Assessment;
};

const fieldDefs: FieldDefinitions<Assessment> = {
  appointmentDurationInMinutes: positiveNumberField(),
  appointmentId: field(),
  todaysUpdate: field(),
  version: field<Assessment_Version>({
    default: Assessment_Version.undefined_version,
  }),
  ...buildCodeSetFieldDefs(),
};

export const validate = (data: Assessment | null) =>
  data !== null && validateForm(data, fieldDefs);

export function AssessmentPlanForm(props: Props) {
  const { savingNote, disabled, initialValue, appointmentId } = props;
  const { fields, validate, getValue } = useForm<Assessment>(
    fieldDefs,
    Assessment.fromPartial({
      ...initialValue,
      appointmentId,
      codeSets: codeSetsFromAssessment(initialValue),
    }),
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: PsychiatrySectionName.ASSESSMENT,
        }),
    },
  );
  const onSubmit = async () => {
    const isValid = await validate();
    if (isValid) {
      props.onSubmit({
        data: getValue(),
        name: PsychiatrySectionName.ASSESSMENT,
      });
    }
  };
  return (
    <>
      <CodeSetFields
        disabled={disabled}
        cptCodeEnum={filterCptCodes(CPTCode, acceptedPsychiatryCptCodes)}
        field={fields.codeSets}
      />

      <div className={styles.formGroup}>
        <NumberField
          testId="appointmentDurationInMinutes"
          label="Total minutes spent on appointment (face to face and non-face to face)*"
          field={fields.appointmentDurationInMinutes}
          tooltip="i.e., reviewing clinical documentation or test results, orders for medication/labs, case management, and/or other administrative tasks related to this appointment."
        />
      </div>
      <TextAreaField
        disabled={disabled}
        rows={10}
        testId="todaysUpdate"
        label="Today's update*"
        field={fields.todaysUpdate}
      />
      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </>
  );
}
