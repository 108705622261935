import { GetMemberById_getMember } from '@headspace/carehub-graphql/dist/queries/generated/GetMemberById';
import { formatVideoEligibilityForB2BMember } from 'app/member-chart-cards/benefits-card/utils';
import { useAppState } from 'app/state';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { TooltipWithRedux } from 'shared-components/tooltip/TooltipWithRedux';
import { classNameCombiner } from 'utils';
import { getTimezone } from 'utils/dateTime';

import headerStyles from './header.module.scss';
import styles from './MemberHeaderBar.module.scss';
import {
  formatCoverageRenewalInfo,
  formatD2cSessionInfo,
  moreInfoText,
} from './utils';

interface DemographicsProps {
  member: GetMemberById_getMember;
  bottomLineText: string;
  isLongBottomLineCase: boolean;
}

export function Demographics({
  member,
  bottomLineText,
  isLongBottomLineCase,
}: Readonly<DemographicsProps>) {
  const { transientFeatureFlags } = useFeatureFlags();
  const { timezone: coachTimezone, memberSessionUsage } = useAppState(
    ({ user, scheduler }) => ({
      memberSessionUsage: scheduler.memberSessionUsage?.[member.id],
      timezone: getTimezone(user.timezone),
    }),
  );
  const {
    enable_d2c_coaching_coverage_info: enableD2cCoachingCoverageInfo,
    enable_tooltip_label_underline,
  } = transientFeatureFlags;
  const nextEligibleVideoSessionDate =
    !member.isD2c && member.isVideoCoachingEligible
      ? formatVideoEligibilityForB2BMember(
          coachTimezone,
          memberSessionUsage?.nextEligibleVideoSessionDate,
        )
      : '';
  const renewalTooltip =
    member.isD2c && memberSessionUsage && enableD2cCoachingCoverageInfo
      ? formatD2cSessionInfo(memberSessionUsage, coachTimezone)
      : formatCoverageRenewalInfo(
          member.coverageDetails,
          nextEligibleVideoSessionDate,
        );

  if (isLongBottomLineCase) {
    return (
      <TooltipWithRedux
        labelForAnalytics="Member Header: Coverage Info And Demographics"
        title={[
          <span
            className={classNameCombiner([
              styles.tooltipText,
              enable_tooltip_label_underline ? headerStyles.underline : '',
            ])}
            key="span"
          >
            {bottomLineText}
          </span>,
          <br key="br" />,
          renewalTooltip,
        ]}
      >
        <div className={styles.moreInfo}>{moreInfoText}</div>
      </TooltipWithRedux>
    );
  }
  return (
    <TooltipWithRedux
      labelForAnalytics="Member Header: Coverage Info"
      title={renewalTooltip}
    >
      <span
        className={classNameCombiner([
          styles.demographicsItem,
          styles.bottomLine,
          enable_tooltip_label_underline ? headerStyles.underline : '',
        ])}
        data-testid="coverage-info"
      >
        {bottomLineText}
      </span>
    </TooltipWithRedux>
  );
}
