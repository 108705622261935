import gql from 'graphql-tag';

export const getPreferredPharmacyQuery = gql`
  query GetPreferredPharmacy($input: GetPreferredPharmacyInput!) {
    getPreferredPharmacy(input: $input) {
      id
      name
      address1
      address2
      city
      state
      zip
      phone
    }
  }
`;

export const getPharmaciesQuery = gql`
  query GetPharmacies($input: GetPharmaciesInput!) {
    getPharmacies(input: $input) {
      pharmacies {
        id
        name
        address1
        address2
        city
        state
        zip
        phone
      }
    }
  }
`;

export const updatePatientPreferredPharmacyMutation = gql`
  mutation UpdatePatientPreferredPharmacy(
    $input: UpdatePatientPreferredPharmacyInput!
  ) {
    updatePatientPreferredPharmacy(input: $input) {
      ok
      error
    }
  }
`;

export const getPrescriptionsForMember = gql`
  query GetPrescriptionsForMember($input: GetPrescriptionsForMemberInput!) {
    getPrescriptionsForMember(input: $input) {
      prescriptions {
        displayName
        effectiveDate
        refills
        pharmacy {
          id
          name
          address1
          address2
          city
          state
          zip
          phone
        }
      }
    }
  }
`;
