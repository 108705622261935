import { MenuProps as MenuPropsInterface } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { classNameCombiner } from 'utils';

import { Size, Width } from '../../types/StyleTypes';
import { FormError } from '../FormError';
import styles from './Dropdown.module.scss';

export type DropdownProps<T> = {
  initialValue?: T;
  options: { name: React.ReactNode; value: T | string }[];
  onSelect: (selected: T) => void;
  disabled?: boolean;
  variant?: SelectProps['variant'];
  className?: string;
  menuItemClass?: string;
  selectClass?: string;
  placeholder?: string;
  dataTestId?: string;
  dataTestIdPrefix?: string;
  error?: string;
  width?: Width;
  size?: Size;
  showSelectedValue?: boolean;
  MenuProps?: Partial<MenuPropsInterface>;
  onOpen?: () => void;
  name?: string;
};

export function Dropdown<T extends string | number>(props: DropdownProps<T>) {
  const {
    options,
    disabled,
    className = '',
    menuItemClass = '',
    selectClass = '',
    placeholder = '',
    dataTestId,
    dataTestIdPrefix,
    initialValue,
    variant = 'outlined',
    width,
    size = Size.SM,
    showSelectedValue = true,
    MenuProps,
    onOpen,
    name,
    error,
    onSelect,
  } = props;

  const [selected, setSelected] = useState(initialValue);
  useEffect(() => {
    setSelected(initialValue);
  }, [initialValue]);

  const labels: { [key: string]: string } = useMemo(
    () =>
      options.reduce(
        (prev, { name: optionName, value }) => ({
          ...prev,
          [`${value}`]: optionName,
        }),
        {},
      ),
    [options],
  );

  const handleOnSelect = useCallback(
    (event: SelectChangeEvent<T>) => {
      const value = event.target.value as T;
      if (showSelectedValue) {
        setSelected(value);
      }
      onSelect(value);
    },
    [onSelect, showSelectedValue],
  );

  const children = options.map(({ name: optionName, value }) => (
    <MenuItem
      key={`${value}-option`}
      className={menuItemClass}
      value={value}
      data-testid={
        dataTestIdPrefix
          ? `${dataTestIdPrefix}-${value}-option`
          : `${value}-option`
      }
    >
      {optionName}
    </MenuItem>
  ));
  let widthStyle = '';
  if (width === Width.FULL) {
    widthStyle = styles.full;
  }
  if (width === Width.HALF) {
    widthStyle = styles.half;
  }
  const rootStyles = `${className} ${widthStyle}`;

  return (
    <div>
      <Select
        disabled={disabled}
        displayEmpty={true}
        data-testid={dataTestId}
        aria-label={dataTestId}
        name={name}
        className={rootStyles}
        classes={{
          select: classNameCombiner([
            styles.selectInput,
            styles[size],
            selectClass || '',
          ]),
        }}
        value={selected ?? ''}
        onChange={(selectedItem) => {
          if (handleOnSelect) handleOnSelect(selectedItem);
        }}
        variant={variant}
        renderValue={(value) =>
          labels[`${value}`] || (
            <div style={{ opacity: 0.3 }}>{placeholder}</div>
          )
        }
        error={error !== undefined}
        onOpen={() => {
          if (onOpen) onOpen();
        }}
        MenuProps={MenuProps}
      >
        {children}
      </Select>
      {error && <FormError error={error} />}
    </div>
  );
}
