import {
  ClinicalAppointmentStatus,
  ClinicalAppointmentType,
} from 'generated/globalTypes';
import React, { PropsWithChildren, ReactElement } from 'react';
import { EventWrapperProps } from 'react-big-calendar';

import styles from './EventWrapper.module.scss';
import {
  EventAttendanceStatus,
  EventType,
  GingerEvent,
  GoogleCalendarMeetingGingerEvent,
} from './types';

export type GingerEventWrapperProps = PropsWithChildren<
  EventWrapperProps<GingerEvent>
>;

/*
  Big Calendar will pass in an optional ReactElement through this wrapper to render the event.
  This clones the element to add our styling to it.
*/
export const EventWrapper = (props: GingerEventWrapperProps) => {
  const { children, event } = props;
  const { id, gingerType } = event;
  const className = getClassName(event);

  if (children) {
    /* Typing for CalendarProps.components.eventWrapper is a little too generic as ReactNode can be many things */
    const child = children as ReactElement;
    return React.cloneElement(child, {
      className: `${child.props.className} ${className}`,
      'data-testid': `event-${gingerType}-${id}`,
    });
  }
  return <></>;
};

function getClassName(event: GingerEvent) {
  const baseAppointmentClass = styles.scheduleCellWrapperAppointment;
  const baseMeetingClass = styles.scheduleCellWrapperMeeting;
  const intakeTypes = [
    ClinicalAppointmentType.THERAPY_INTAKE,
    ClinicalAppointmentType.PSYCHIATRY_INTAKE,
  ];
  switch (event.gingerType) {
    case EventType.APPOINTMENT:
      switch (event.appointmentStatus) {
        case ClinicalAppointmentStatus.Tentative:
          return `${baseAppointmentClass} ${styles.tentativeAppointment}`;
        case ClinicalAppointmentStatus.CancelledClinician:
        case ClinicalAppointmentStatus.Cancelled:
        case ClinicalAppointmentStatus.LateCancelled:
        case ClinicalAppointmentStatus.Rescheduled:
          return `${baseAppointmentClass} ${styles.canceledAppointment}`;
        default:
          if (
            intakeTypes.includes(
              event.appointmentType as ClinicalAppointmentType,
            )
          ) {
            return 'intakeAppointment';
          }
          return baseAppointmentClass;
      }
    case EventType.AVAILABILITY:
      return styles.scheduleCellWrapperAvailability;
    case EventType.MEETING:
      switch ((event as GoogleCalendarMeetingGingerEvent)?.attendanceStatus) {
        case EventAttendanceStatus.TENTATIVE:
          return `${baseMeetingClass} ${styles.tentativeMeeting}`;
        default:
          return baseMeetingClass;
      }
    default:
      return styles.scheduleCellWrapperEvent;
  }
}
