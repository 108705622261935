import { AmplitudeLogsContextFromRedux } from 'app/coach/chat/types';
import {
  getMemberLocationInAllInboxTab,
  getUrl,
  initializeTypingState,
} from 'app/coach/chat/utils';
import { InboxTab } from 'app/inbox/types';
import { useAppState } from 'app/state/index';
import { State } from 'app/state/schema';

export const selectPubnubStatus = () => {
  return useAppState((state: State) => {
    const {
      chat: { pubnubStatus },
    } = state;
    return pubnubStatus;
  });
};

export const selectTypingStateByChannelId = (channelId: string) => {
  return useAppState((state: State) => {
    const {
      chat: { typingStateMap },
    } = state;
    return channelId in typingStateMap
      ? typingStateMap[channelId]
      : initializeTypingState();
  });
};

export const selectIsConvoInTheTypingStateMap = (channelId: string) => {
  return useAppState((state: State) => {
    const {
      chat: { typingStateMap },
    } = state;
    return channelId in typingStateMap;
  });
};

export const selectTextAreaInput = (channelId: string) => {
  return useAppState((state: State) => {
    const {
      chat: { inputsMap },
    } = state;
    return channelId in inputsMap ? inputsMap[channelId] : '';
  });
};

export const selectIsReadOnly = (channelId: string) => {
  return useAppState((state: State) => {
    const {
      chat: { isReadOnlyMap },
    } = state;
    return channelId in isReadOnlyMap ? isReadOnlyMap[channelId] : false;
  });
};

export const selectShouldPlaySound = () => {
  return useAppState((state: State) => {
    const {
      chat: { shouldPlayNotificationSound },
    } = state;
    return shouldPlayNotificationSound;
  });
};

export const selectAmplitudeContextForLogs = (
  memberId: string,
): AmplitudeLogsContextFromRedux => {
  return useAppState((state: State) => {
    const {
      inbox: { currentChannelId, selectedTab, tabSections },
      user: { shiftStatus, listenerUserId, listenerId, coachinghubUsername },
    } = state;

    const section =
      selectedTab === InboxTab.ALL
        ? getMemberLocationInAllInboxTab(memberId, tabSections)
        : null;

    return {
      channel_id: currentChannelId,
      ginger_id: memberId,
      listener_id: listenerId,
      listener_state: shiftStatus,
      request_user_id: listenerUserId,
      request_username: coachinghubUsername,
      section,
      tab: InboxTab[selectedTab] as keyof typeof InboxTab,
    };
  });
};

export function selectLoggingContextWithMemberData(
  callerId: string,
  memberId: string,
) {
  const logsContextFromRedux = selectAmplitudeContextForLogs(memberId);

  return {
    ...logsContextFromRedux,
    caller_id: callerId,
    url: getUrl(),
  };
}
