import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ActionDialog } from 'shared-components/ActionDialog';
import React, { SyntheticEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'shared-components/button/Button';

import styles from './AppointmentScreen.module.scss';
import { DialogMessage } from './types';

export type EventButtonsProps = {
  onSave: () => void;
  loading?: boolean;
  saveDisabled?: boolean;
  disableActionMenu?: boolean | undefined;
  dialogMessage?: DialogMessage | undefined;
  onDelete?: () => Promise<void>;
};

export function EventButtons({
  onSave,
  loading,
  saveDisabled,
  disableActionMenu,
  onDelete,
  dialogMessage,
}: EventButtonsProps) {
  const history = useHistory();
  const label = loading ? 'Saving' : 'Save';
  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onMoreActionsClick = (event?: SyntheticEvent) => {
    setAnchorEl(event?.currentTarget);
  };
  const onActionMenuClose = () => {
    setAnchorEl(undefined);
  };

  const onDeleteClick = () => {
    setAnchorEl(undefined);
    setIsDeleteModalOpen(true);
  };

  const _onDelete = async () => {
    await onDelete?.();
    history.goBack(); // Go back
  };

  return (
    <div className={styles.buttons}>
      <Button
        className={styles.saveBtn}
        size="small"
        testId="save"
        onClick={onSave}
        disabled={loading || saveDisabled}
      >
        {label}
      </Button>
      <>
        <ActionDialog
          isOpen={isDeleteModalOpen}
          setIsOpen={setIsDeleteModalOpen}
          actionHandler={_onDelete}
          dialogMessage={dialogMessage!}
          titleText="Are you sure you want to delete?"
          confirmLabel="Delete"
          className={styles.deleteModalContainer}
          showWarningIcon={true}
          showNotifications={true}
        />
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={onMoreActionsClick}
          className={styles.actionsBtn}
          variant="outlined"
          size="small"
          endIcon={<ArrowDropDownIcon />}
          disabled={disableActionMenu}
          testId="moreActionsBtn"
        >
          More Actions
        </Button>
        <Menu
          anchorEl={anchorEl}
          keepMounted={true}
          open={Boolean(anchorEl)}
          onClose={onActionMenuClose}
          PaperProps={{
            style: { marginTop: '12px', width: anchorEl?.clientWidth },
          }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <MenuItem
            key="deleteItem"
            data-testid="deleteItem"
            onClick={onDeleteClick}
          >
            Delete
          </MenuItem>
        </Menu>
      </>
    </div>
  );
}
