import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { debounce, isNaN } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

export interface Result {
  id: string;
  display: string;
  queryOn: string;
  groupBy?: string;
}

export type AutoCompleteSearchProps = {
  /**
   * @property The results array that will be displayed as dropdown options.
   */
  results: Result[];

  /**
   * @property This callback will be debounced and called after a half second with the value of the search input field.
   * Use this to update results with matches using network query using the provided value.
   */
  searchCallback?: (value: string) => void;

  /**
   * @property This callback will return the value of the selected result
   */
  resultCallback: (value: Result | null) => void;

  /**
   * @property The placeholder of the search field before input
   */
  placeholder: string;

  /**
   * @property Loading status of the results
   */
  loading?: boolean;

  className?: string;
  testId?: string;
};

export function AutoCompleteSearch(props: AutoCompleteSearchProps) {
  const {
    resultCallback,
    results,
    searchCallback,
    placeholder,
    testId,
    loading,
    className,
  } = props;

  const isMountedRef = useRef<boolean | null>(null);
  const [currentResults, setResults] = useState<Result[]>([]);

  // Prevent callback after unmounted
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  const debounced = debounce((value, reason) => {
    if (searchCallback && isMountedRef.current && reason !== 'reset') {
      searchCallback(value);
    }
  }, 500);
  // avoid flashes of empty results between search callbacks
  useEffect(() => {
    if (!loading) {
      setResults(results);
    }
  }, [loading, results]);

  const handleGroupBy = (option: any) => {
    return option.groupBy ?? '';
  };

  return (
    <>
      <Autocomplete
        className={className}
        loading={loading}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder={placeholder} />
        )}
        options={currentResults}
        filterOptions={(options, state) => {
          const { inputValue } = state;
          if (!isNaN(Number.parseInt(inputValue)))
            return options.filter((o) => o.id.includes(inputValue));
          return options.filter((o) =>
            o.queryOn.toLowerCase().includes(inputValue.toLowerCase()),
          );
        }}
        onInputChange={(event, value, reason) => debounced(value, reason)}
        onChange={(_, value, __) => resultCallback(value)}
        data-testid={testId}
        getOptionLabel={(option) => option.display}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        groupBy={(option) => handleGroupBy(option)}
      />
    </>
  );
}
