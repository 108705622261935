import React, { useState } from 'react';
import { FormError } from 'shared-components/FormError';
import { classNameCombiner } from 'utils';

import {
  NoteFormControlLabel,
  NoteFormControlLabelStyles,
} from './NoteFormControlLabel';
import RadioButton from './RadioButton';
import styles from './RadioButtonGroup.module.scss';

export type Option<T> = {
  value: T;
  label: string;
};

export enum RadioListOrientations {
  HORIZONTAL = 'rowDirection',
  VERTICAL = 'columnDirection',
}

type Props<T> = {
  name: string;
  label: string;
  subtext?: string;
  value: T;
  options: Option<T>[];
  onChange: (value: T) => void;
  formatSelectedValue?: (value: T) => string;
  error?: string;
  readOnly?: boolean;
  showSelectedOnly?: boolean;
  tooltip?: string;
  formControlStyles?: NoteFormControlLabelStyles;
  radioListOrientation?: RadioListOrientations;
};

export function RadioButtonGroup<T>(props: Props<T>) {
  const {
    name,
    label,
    subtext,
    options,
    error,
    readOnly,
    tooltip,
    formControlStyles,
    formatSelectedValue = (value: T) => `${value}`,
    showSelectedOnly = false,
    radioListOrientation = RadioListOrientations.VERTICAL,
  } = props;

  const [value, setValue] = useState(props.value);

  const onChange = (value: T) => {
    props.onChange(value);
    setValue(value);
  };

  const radioListOrientationClassName = styles[radioListOrientation];

  return (
    <div data-testid={name || 'radioButtonGroup'} className={styles.root}>
      <NoteFormControlLabel
        data-testid="singleAnswerInputLabel"
        label={label}
        subtext={subtext}
        tooltip={tooltip}
        styles={formControlStyles}
      >
        <div
          className={classNameCombiner([
            styles.radioGroup,
            radioListOrientationClassName,
          ])}
        >
          {showSelectedOnly && readOnly && (
            <p className={styles.selectedValue}>{formatSelectedValue(value)}</p>
          )}
          {!showSelectedOnly &&
            options.map((option, index) => (
              <div
                key={`${option.label}-${index}`}
                className={styles.radioButton}
              >
                <RadioButton
                  name={name}
                  data-testid={`Radio:${option.label}`}
                  disabled={readOnly}
                  value={option.value}
                  label={option.label}
                  checked={option.value === value}
                  onChange={onChange}
                />
              </div>
            ))}
        </div>
        {error && <FormError error={error} />}
      </NoteFormControlLabel>
    </div>
  );
}
