import { Typography } from '@mui/material';
import { MarkdownContent } from 'shared-components/markdown-content/MarkdownContent';
import { classNameCombiner } from 'utils';
import React from 'react';

import styles from './styles/FieldLabel.module.scss';

interface FieldLabelProps {
  labelText?: string;
  className?: string;
  withMarkdown?: boolean;
}
export const FieldLabel = ({
  className = '',
  labelText = '',
  withMarkdown,
}: FieldLabelProps) => {
  return (
    <Typography
      component={withMarkdown ? MarkdownContent : 'h4'}
      className={classNameCombiner([styles.cardFieldLabel, className])}
    >
      {labelText}
    </Typography>
  );
};
