import { ChatMessage } from 'app/collaboration/type';
import Tooltip from 'shared-components/tooltip/Tooltip';
import React from 'react';

import { CommunicationBubble } from './CommunicationBubble';
import styles from './MessageContainer.module.scss';

export interface MessageProp {
  chat: ChatMessage;
  dateAndTime: string;
}

export const ClinicianAndCoachMessageContainer = ({
  chat,
  dateAndTime,
}: MessageProp) => {
  return (
    <div className={styles.container}>
      <Tooltip
        classes={{ popper: styles.popper, tooltip: styles.tooltip }}
        title={dateAndTime}
        enterDelay={0}
        enterTouchDelay={0}
        placement="bottom-end"
      >
        <div>
          <CommunicationBubble chat={chat} isOwner={true} />
        </div>
      </Tooltip>
    </div>
  );
};
