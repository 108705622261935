import ladybug from 'assets/member-chart/error-ladybug.svg';
import React from 'react';
import { BugReportButton } from 'shared-components/BugReportButton';
import { ErrorState } from 'shared-components/error-state/ErrorState';
import styles from 'shared-components/error-state/styles/BaseErrorState.module.scss';
import {
  CardColumnGaps,
  CardGrid,
  CardRowGaps,
  NumberOfCardColumns,
} from 'shared-components/grid-layout/CardGrid.module';

export type ErrorProps = {
  error?: Error;
};

export function CoachNotesErrorState(props: ErrorProps) {
  const { error } = props;
  return (
    <ErrorState
      error={error}
      children={<CoachNotesErrorComponent error={error} />}
    />
  );
}

const CoachNotesErrorComponent = (props: ErrorProps): React.ReactElement => {
  const { error } = props;

  return (
    <div data-testid="member-chart-error" className={styles.container}>
      <div className={styles.title}>
        <span>Uh oh! Something went wrong when loading notes</span>
        <img className={styles.picture} src={ladybug} alt="ladybug" />
      </div>

      <div className={styles.body}>
        <h4 className={styles.subtitle}>Let’s try this:</h4>
        <ul className={styles.list}>
          <li className={styles.item}>Refresh the page.</li>
          <li className={styles.item}>
            If that doesn’t work please submit a bug report so we can get you
            back up and running!
          </li>
        </ul>
      </div>

      <div className={styles.footer}>
        <CardGrid
          rowGap={CardRowGaps.CONDENSED}
          columnGap={CardColumnGaps.CONDENSED}
          numberOfColumns={NumberOfCardColumns.TWO}
        >
          <BugReportButton
            className={styles.butReportButtonContainer}
            errors={[error ?? new Error('Error not provided')]}
          />
        </CardGrid>
      </div>
    </div>
  );
};
