import { useLazyQuery } from '@apollo/client';
import { useOnMount } from 'hooks/useOnMount';
import { StateSlice } from 'app/state/status/types/StateSlice';
import { QueryOptions } from 'app/vault/hooks/useMappedQuery';
import { useStateSlice } from 'app/vault/hooks/utils';
import { useCallback, useEffect } from 'react';

export function useRefreshableQuery<T, U, V>(
  options: QueryOptions<U, T>,
  f: (data: T) => Promise<V>,
): {
  state: StateSlice<V>;
  refreshData: (variable: U, showLoader?: boolean) => Promise<void>;
} {
  const { state, setLoading, setError, setData } = useStateSlice<V>();
  const [runInitQuery, { error, data, fetchMore }] = useLazyQuery<T, U>(
    options.query,
  );
  useOnMount(() => {
    const init = async () => {
      try {
        setLoading();
        const { variables, ...opts } = options;
        const queryOptions = { ...opts, variables: await variables() };
        await runInitQuery(queryOptions);
      } catch (error) {
        setError(error);
      }
    };
    void init();
  });
  useEffect(() => {
    const handleResult = async () => {
      try {
        if (error) {
          setError(error);
        } else if (data) {
          setData(await f(data));
        }
      } catch (error) {
        setError(error);
      }
    };
    void handleResult();
  }, [data, error]);

  const refreshData = useCallback(
    async (variables: U, showLoader = false) => {
      if (fetchMore === undefined) return;
      if (showLoader) setLoading();

      const { data, error } = await fetchMore({ variables });
      if (data) {
        setData(await f(data));
      } else {
        throw error ?? new Error('Unable to refresh data');
      }
    },
    [fetchMore],
  );

  return {
    refreshData,
    state: state.current,
  };
}
