/* eslint-disable max-classes-per-file */
// TODO: https://headspace.atlassian.net/browse/CARE-5972
// Remove eslint-disable once the file is refactored
import { Font, oneInchInPixels } from './constants';
import { CustomPDFDocument } from './CustomPDFDocument';

// Matches all newline characters and the unicode character for line separator, which are invalid characters for PDFKit.
const INVALID_CHARACTERS_REGEX = /[\n\u2028]+/g;

export type TextStyle = {
  font: Font;
  fontSize: number;
  underline?: boolean;
};

export type LabelValueTextStyle = {
  labelStyle: { font: Font; fontSize: number };
  valueStyle: { font: Font; fontSize: number };
};

export type Styles = {
  regularTextStyle: TextStyle;
  h1Style: TextStyle;
  h2Style: TextStyle;
  h3Style: TextStyle;
  listTextStyle: TextStyle;
};

const getFont = (
  isAlternateFont: boolean,
  alternateFont: Font,
  defaultFont: Font,
): Font => {
  return isAlternateFont ? alternateFont : defaultFont;
};

export const getLabelValueTextStyle = (
  useAlternateFont: boolean,
): LabelValueTextStyle => ({
  labelStyle: {
    font: getFont(useAlternateFont, Font.NOTO_BOLD, Font.HELVETICA_BOLD),
    fontSize: 10,
  },
  valueStyle: {
    font: getFont(useAlternateFont, Font.NOTO, Font.HELVETICA),
    fontSize: 10,
  },
});

export const getTextStyles = (useAlternateFont: boolean): Styles => ({
  h1Style: {
    font: getFont(useAlternateFont, Font.NOTO_BOLD, Font.HELVETICA_BOLD),
    fontSize: 16,
  },
  h2Style: {
    font: getFont(useAlternateFont, Font.NOTO_BOLD, Font.HELVETICA_BOLD),
    fontSize: 12,
    underline: true,
  },
  h3Style: {
    font: getFont(useAlternateFont, Font.NOTO_BOLD, Font.HELVETICA_BOLD),
    fontSize: 10,
  },
  listTextStyle: {
    font: getFont(useAlternateFont, Font.NOTO, Font.HELVETICA),
    fontSize: 10,
  },
  regularTextStyle: {
    font: getFont(useAlternateFont, Font.NOTO, Font.HELVETICA),
    fontSize: 10,
  },
});

export type DocumentFormat = {
  leftAndRightMargins: number;
  topAndBottomMargins: number;
  rowGap: number;
};

const defaultDocumentFormat: DocumentFormat = {
  leftAndRightMargins: oneInchInPixels,
  rowGap: 18,
  topAndBottomMargins: oneInchInPixels,
};

export type TextColumn = {
  width: number;
  content: { label: string; value: string }[];
};

export interface PDFBuilder {
  build(): typeof PDFDocument;
  lineBreak(): this;
  inlineText(text: string, styleOverrides?: Partial<TextStyle>): this;
  text(text: string, styleOverrides?: Partial<TextStyle>): this;
  h1(text: string, styleOverrides?: Partial<TextStyle>): this;
  h2(text: string, styleOverrides?: Partial<TextStyle>): this;
  h3(text: string, styleOverrides?: Partial<TextStyle>): this;
  ul(listItems: string[], styleOverrides?: Partial<TextStyle>): this;
  table(
    headers: string[],
    rows: string[][],
    styleOverrides?: Partial<TextStyle>,
  ): this;
  headerTextInColumns(
    columns: TextColumn[],
    fontStyles?: LabelValueTextStyle,
  ): this;
  horizontalLine(startPositionY: number): this;
}

export class PDFBuilderImpl implements PDFBuilder {
  private readonly styles: Styles;

  private readonly documentFormat: DocumentFormat;

  constructor(
    protected password?: string,
    protected enableNewPdfFont: boolean = false,
    protected pdfDoc: typeof PDFDocument = new CustomPDFDocument({
      userPassword: password,
    }),
    styles?: Partial<Styles>,
    documentFormat?: Partial<DocumentFormat>,
  ) {
    this.styles = { ...getTextStyles(enableNewPdfFont), ...styles };
    this.documentFormat = { ...defaultDocumentFormat, ...documentFormat };

    // Init regular font style.
    const {
      regularTextStyle: { font, fontSize },
    } = this.styles;
    if (enableNewPdfFont) {
      this.initFonts();
    }
    this.pdfDoc.font(font).fontSize(fontSize);
  }

  initFonts() {
    const regularFont = PDFBuilderImpl.loadFontSynchronously(
      `${process.env.PUBLIC_URL}/font/NotoSerif-Regular.ttf`,
    );
    this.pdfDoc.registerFont('NotoSerif-Regular', regularFont);

    const boldFont = PDFBuilderImpl.loadFontSynchronously(
      `${process.env.PUBLIC_URL}/font/NotoSerif-Bold.ttf`,
    );
    this.pdfDoc.registerFont('NotoSerif-Bold', boldFont);

    return this;
  }

  static loadFontSynchronously(url: string): ArrayBuffer {
    const request = new XMLHttpRequest();
    request.open('GET', url, false);
    request.overrideMimeType('text/plain; charset=x-user-defined');
    request.send(null);

    if (request.status === 200) {
      const binaryString = request.responseText;
      const { length } = binaryString;
      const arrayBuffer = new ArrayBuffer(length);
      const uintArray = new Uint8Array(arrayBuffer);

      for (let i = 0; i < length; i++) {
        // eslint no-bitwise: ["error", { "allow": ["&"] }]
        uintArray[i] = binaryString.charCodeAt(i) & 0xff;
      }

      return arrayBuffer;
    }
    throw new Error(`Failed to load font from ${url}`);
  }

  build(): typeof PDFDocument {
    this.pdfDoc.end();
    return this.pdfDoc;
  }

  lineBreak(): this {
    this.pdfDoc.moveDown();
    return this;
  }

  /**
   * Write text that will be on the same line as a subsequent section of text.
   *
   * For example: pdfBuilder.inlineText("Patient name: ").text("Ronnie") would render "Patient name: Ronnie"
   * on a single line.
   */
  inlineText(text: string, styleOverrides?: Partial<TextStyle>): this {
    const style = PDFBuilderImpl.getStyle(
      this.styles.regularTextStyle,
      styleOverrides,
    );
    return this._text(text, style, true);
  }

  /**
   * Write text on its own line. For example: pdfBuilder.text("Some Header").text("some value") would render each
   * on its own line:
   *
   * Some Header
   * some value
   */
  text(text: string, styleOverrides?: Partial<TextStyle>): this {
    const style = PDFBuilderImpl.getStyle(
      this.styles.regularTextStyle,
      styleOverrides,
    );
    return this._text(text, style);
  }

  h1(text: string, styleOverrides?: Partial<TextStyle>): this {
    const style = PDFBuilderImpl.getStyle(this.styles.h1Style, styleOverrides);
    return this._text(text, style);
  }

  h2(text: string, styleOverrides?: Partial<TextStyle>): this {
    const style = PDFBuilderImpl.getStyle(this.styles.h2Style, styleOverrides);
    return this._text(text, style);
  }

  h3(text: string, styleOverrides?: Partial<TextStyle>): this {
    const style = PDFBuilderImpl.getStyle(this.styles.h3Style, styleOverrides);
    return this._text(text, style);
  }

  ul(listItems: string[], styleOverrides?: Partial<TextStyle>): this {
    const style = PDFBuilderImpl.getStyle(
      this.styles.listTextStyle,
      styleOverrides,
    );
    const { font, fontSize, underline } = style;
    const trimmedListItems = listItems.map((item) =>
      item.trim().replace(INVALID_CHARACTERS_REGEX, ' '),
    );
    this.pdfDoc
      .font(font)
      .fontSize(fontSize)
      .list(trimmedListItems, { underline });
    return this;
  }

  headerTextInColumns(
    columns: TextColumn[],
    fontStyles: LabelValueTextStyle = getLabelValueTextStyle(
      this.enableNewPdfFont,
    ),
  ): this {
    const {
      leftAndRightMargins,
      topAndBottomMargins,
      rowGap,
    } = this.documentFormat;
    const { labelStyle, valueStyle } = fontStyles;

    let numRowsWritten = 0;
    let startPositionX = leftAndRightMargins;
    let currPositionY = topAndBottomMargins;

    columns.forEach((column) => {
      const { width, content } = column;
      numRowsWritten = Math.max(content.length, numRowsWritten);

      content.forEach(({ label, value }, i) => {
        if (i > 0) {
          this.lineBreak();
        }

        // Must set the y-axis position for the text once per column. Setting it to undefined for subsequent elements
        // of the same column allows the text to naturally flow onto the next line rather than being placed in the same
        // position (stacked on top of) the text from the first array element for the column.
        const startPositionY = i === 0 ? topAndBottomMargins : undefined;

        // label
        this.pdfDoc
          .font(labelStyle.font)
          .fontSize(labelStyle.fontSize)
          .text(`${label}: `, startPositionX, startPositionY, {
            continued: true,
            width,
          })

          // value
          .font(valueStyle.font)
          .fontSize(valueStyle.fontSize)
          .text(value);
      });

      startPositionX += width;
    });

    // Move the cursor back to the left margin.
    this.setCursorPosition(leftAndRightMargins);

    // Add padding above the horizontal line.
    numRowsWritten++;
    this.lineBreak();

    // Track the y-axis position of the cursor based on the number of rows we've written.
    currPositionY += rowGap * numRowsWritten;

    this.horizontalLine(currPositionY);

    // Move the cursor down a few lines so that the next content that is added will be below this header content.
    for (let i = 0; i < 4; i++) {
      this.lineBreak();
    }

    return this;
  }

  horizontalLine(startPositionY: number): this {
    const { leftAndRightMargins } = this.documentFormat;

    // PDFKit pages are 8.5" wide.
    const pageWidthInches = 8.5;

    this.pdfDoc
      .moveTo(leftAndRightMargins, startPositionY)
      .lineTo(
        pageWidthInches * oneInchInPixels - leftAndRightMargins,
        startPositionY,
      )
      .stroke();
    return this;
  }

  private setCursorPosition(x: number, y?: number) {
    this.pdfDoc.text('', x, y);
  }

  private static getStyle(
    defaultStyle: TextStyle,
    overrides?: Partial<TextStyle>,
  ): TextStyle {
    return { ...defaultStyle, ...overrides };
  }

  private _text(text: string, style: TextStyle, inline = false): this {
    const { font, fontSize, underline } = style;
    this.pdfDoc
      .font(font)
      .fontSize(fontSize)
      .text(text, { continued: inline, underline });
    return this;
  }

  table(
    headers: string[],
    rows: string[][],
    styleOverrides?: Partial<TextStyle>,
  ): this {
    if (this.pdfDoc instanceof CustomPDFDocument) {
      this.pdfDoc.table(
        { headers, rows },
        {
          prepareHeader: (pdfDoc) =>
            pdfDoc
              .font(styleOverrides?.font ?? Font.NOTO_BOLD)
              .fontSize(styleOverrides?.fontSize ?? 10),
          prepareRow: (_, __, pdfDoc) =>
            pdfDoc
              .font(styleOverrides?.font ?? Font.NOTO)
              .fontSize(styleOverrides?.fontSize ?? 10),
        },
      );
    } else {
      throw Error('Not supported');
    }
    return this;
  }
}

export enum ElementType {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  HEADER_TEXT_IN_COLUMNS = 'headerTextInColumns',
  HORIZONTAL_LINE = 'horizontalLine',
  INLINE_TEXT = 'inlineText',
  LINE_BREAK = 'lineBreak',
  TEXT = 'text',
  UL = 'ul',
}

export class StubPDFBuilder implements PDFBuilder {
  // Track whether the previously added element is an inline element, meaning that the next element that's added must
  // be added to the same line.
  private isPrevLineInline: boolean = false;

  constructor(public lines: string[] = []) {}

  build(): typeof PDFDocument {
    return new PDFDocument();
  }

  h1(text: string, styleOverrides?: Partial<TextStyle>): this {
    this.maybeAddInline(ElementType.H1, text);
    return this;
  }

  h2(text: string, styleOverrides?: Partial<TextStyle>): this {
    this.maybeAddInline(ElementType.H2, text);
    return this;
  }

  h3(text: string, styleOverrides?: Partial<TextStyle>): this {
    this.maybeAddInline(ElementType.H3, text);
    return this;
  }

  headerTextInColumns(
    columns: TextColumn[],
    fontStyles?: LabelValueTextStyle,
  ): this {
    // Format TextColumn[] as `label: value` pairs.
    const labelValuePairs = columns.flatMap((_) =>
      _.content.map(({ label, value }) => `${label}: ${value}`),
    );
    this.lines = [...this.lines, ...labelValuePairs];
    this.isPrevLineInline = false;
    return this;
  }

  horizontalLine(startPositionY: number): this {
    this.lines.push('-----');
    this.isPrevLineInline = false;
    return this;
  }

  inlineText(text: string, styleOverrides?: Partial<TextStyle>): this {
    this.maybeAddInline(ElementType.INLINE_TEXT, text);
    return this;
  }

  lineBreak(): this {
    this.lines.push('\n');
    this.isPrevLineInline = false;
    return this;
  }

  text(text: string, styleOverrides?: Partial<TextStyle>): this {
    this.maybeAddInline(ElementType.TEXT, text);
    return this;
  }

  ul(listItems: string[], styleOverrides?: Partial<TextStyle>): this {
    const trimmedListItems = listItems.map((item) =>
      item.trim().replace(INVALID_CHARACTERS_REGEX, ' '),
    );
    this.lines = [...this.lines, ...trimmedListItems];
    this.isPrevLineInline = false;
    return this;
  }

  private maybeAddInline(elementType: ElementType, text: string) {
    const prevElementIdx = this.lines.length - 1;

    if (this.isPrevLineInline) {
      this.lines[prevElementIdx] += text;
    } else {
      this.lines.push(text);
    }

    this.isPrevLineInline = elementType === ElementType.INLINE_TEXT;
  }

  table(
    headers: string[],
    rows: string[][],
    styleOverrides?: Partial<TextStyle>,
  ): this {
    this.lines = [...this.lines, ...headers, ...rows.flatMap((_) => _)];
    return this;
  }
}
