import { ClinicianIntakeChecklist_Version } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/ClinicianIntakeChecklist';
import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { validate as validateAssessment } from 'app/notes-ui/psychiatry/intake/assessment/schema';
import { validate as validateAllergies } from 'app/notes-ui/psychiatry/shared/allergies/AllergiesForm';
import { validate as validateChiefComplaint } from 'app/notes-ui/psychiatry/shared/chief-complaint/ChiefComplaintForm';
import { validate as validateChiefComplaintV2 } from 'app/notes-ui/psychiatry/shared/chief-complaint/ChiefComplaintFormV2';
import { validate as validateClinicianChecklistV2 } from 'app/notes-ui/psychiatry/shared/clinician-checklist/ClinicianChecklistFormV2';
import { validate as validateFamilyHistory } from 'app/notes-ui/psychiatry/shared/family-history/FamilyHistoryForm';
import { validate as validateMedicalHistory } from 'app/notes-ui/psychiatry/shared/medical-history/MedicalHistoryForm';
import { validate as validateMedicalHistoryV2 } from 'app/notes-ui/psychiatry/shared/medical-history/MedicalHistoryFormV2';
import { validate as validateMedicalReviewOfSystem } from 'app/notes-ui/psychiatry/shared/medical-ros/MedicalReviewOfSystemForm';
import { validate as validateMedicalReviewOfSystemV2 } from 'app/notes-ui/psychiatry/shared/medical-ros/MedicalReviewOfSystemFormV2';
import { validate as validateMentalStatusExam } from 'app/notes-ui/psychiatry/shared/mental-status-exam/MentalStatusExamForm';
import { validate as validateMentalStatusExamV2 } from 'app/notes-ui/psychiatry/shared/mental-status-exam/MentalStatusExamFormV2';
import { validate as validatePsychReviewOfSystem } from 'app/notes-ui/psychiatry/shared/psych-ros/PsychReviewOfSystemForm';
import { validate as validatePsychReviewOfSystemV2 } from 'app/notes-ui/psychiatry/shared/psych-ros/PsychReviewOfSystemFormV2';
import { validate as validatePsychiatricHistory } from 'app/notes-ui/psychiatry/shared/psychiatric-history/PsychiatricHistoryForm';
import { validate as validatePsychiatricHistoryV2 } from 'app/notes-ui/psychiatry/shared/psychiatric-history/PsychiatricHistoryFormV2';
import { validate as validateSocialHistory } from 'app/notes-ui/psychiatry/shared/social-history/SocialHistoryForm';
import { validate as validateSocialHistoryV2 } from 'app/notes-ui/psychiatry/shared/social-history/SocialHistoryFormV2';
import { validate as validateTreatmentPlan } from 'app/notes-ui/psychiatry/shared/treatment-plan/TreatmentPlanForm';
import { validate as validateTreatmentPlanV2 } from 'app/notes-ui/psychiatry/shared/treatment-plan/TreatmentPlanFormV2';
import { validate as validateClinicianChecklist } from 'app/notes-ui/shared/clinician-checklist/ClinicianChecklistForm';
import { validate as validateCollaboration } from 'app/notes-ui/shared/collaboration-plan/schema';
import { validate as validateSafety } from 'app/notes-ui/shared/safety-intake/SafetyForm';
import { validatePsychiatry as validateSafetyV2 } from 'app/notes-ui/shared/safety-intake/schema';
import {
  validate as validateSubstanceUsed,
  validateV2 as validateSubstanceUsedV2,
} from 'app/notes-ui/shared/substance-abuse/schema';
import { PsychiatryIntakeNote } from 'app/vault/api/PsychiatryIntakeNotesAPI';

export function PsychiatryIntakeIsNoteLockable(
  note: PsychiatryIntakeNote,
): boolean {
  return (
    note.metadata.status === Metadata_NoteStatus.draft_note &&
    validateAssessment(note.ASSESSMENT) &&
    validateClinicianChecklist(note.CLINICIAN_CHECKLIST) &&
    validateChiefComplaint(note.CHIEF_COMPLAINT) &&
    validateMedicalReviewOfSystem(note.MEDICAL_REVIEW_OF_SYSTEM) &&
    validatePsychReviewOfSystem(note.PSYCH_REVIEW_OF_SYSTEM) &&
    validateSafety(note.SAFETY) &&
    validatePsychiatricHistory(note.PSYCH_HISTORY) &&
    validateAllergies(note.ALLERGIES) &&
    validateMedicalHistory(note.MEDICAL_HISTORY) &&
    validateSocialHistory(note.SOCIAL_HISTORY) &&
    validateFamilyHistory(note.FAMILY_HISTORY) &&
    validateSubstanceUsed(note.SUBSTANCE_ABUSE) &&
    validateTreatmentPlan(note.TREATMENT_PLAN) &&
    validateMentalStatusExam(note.MENTAL_STATUS_EXAM) &&
    validateCollaboration(note.COLLABORATION_PLAN)
  );
}

export function PsychiatryIntakeIsNoteLockableV2(
  note: PsychiatryIntakeNote,
): boolean {
  return (
    note.metadata.status === Metadata_NoteStatus.draft_note &&
    validateAssessment(note.ASSESSMENT) &&
    validateClinicianChecklistV2(note.CLINICIAN_CHECKLIST) &&
    validateChiefComplaintV2(note.CHIEF_COMPLAINT) &&
    validateMedicalReviewOfSystemV2(note.MEDICAL_REVIEW_OF_SYSTEM) &&
    validatePsychReviewOfSystemV2(note.PSYCH_REVIEW_OF_SYSTEM) &&
    validateSafetyV2(note.SAFETY) &&
    validatePsychiatricHistoryV2(note.PSYCH_HISTORY) &&
    validateMedicalHistoryV2(note.MEDICAL_HISTORY) &&
    validateSocialHistoryV2(note.SOCIAL_HISTORY) &&
    validateSubstanceUsedV2(note.SUBSTANCE_ABUSE) &&
    validateTreatmentPlanV2(note.TREATMENT_PLAN) &&
    validateMentalStatusExamV2(note.MENTAL_STATUS_EXAM)
  );
}

/**
 * This is a map for psychiatry intake note versions to their respective validation functions
 * The Record is used to ensure that all versions are accounted for
 * It uses the ClinicianIntakeChecklist_Version enum as the key as it is the first section in the note
 * and we don't have a note version field in the note metadata
 */
export const versionValidationMap: Record<
  ClinicianIntakeChecklist_Version,
  (note: PsychiatryIntakeNote) => boolean
> = {
  [ClinicianIntakeChecklist_Version.undefined_version]: PsychiatryIntakeIsNoteLockable,
  [ClinicianIntakeChecklist_Version.UNRECOGNIZED]: PsychiatryIntakeIsNoteLockable,
  // V0 corresponds to the most recent version of the note, as previous versions were not versioned
  [ClinicianIntakeChecklist_Version.v0]: PsychiatryIntakeIsNoteLockableV2,
};

/**
 * This function is used to validate a psychiatry intake note
 * It checks for the notes schema version and then calls the appropriate validation function
 * @param note The note to validate
 * @returns true if the note is valid, false otherwise
 */
export function validatePsychiatryIntakeNote(
  note: PsychiatryIntakeNote | null,
): boolean {
  if (!note || note.CLINICIAN_CHECKLIST?.version === undefined) return false;

  // select the validation function based on the version of the clinician checklist
  // the reason for using clinician checks is merely because it is the first section and we
  // don't have a note version field in the note metadata
  const validationFunction =
    versionValidationMap[note.CLINICIAN_CHECKLIST?.version];

  return validationFunction ? validationFunction(note) : false;
}
