import {
  booleanField,
  field,
  FieldDefinitions,
  positiveNumberField,
  stringField,
} from '@ginger.io/react-use-form';
import {
  AbuseOfVulnerablePopulationsRisk,
  AbuseOfVulnerablePopulationsRisk_Version,
} from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/AbuseOfVulnerablePopulationsRisk';
import {
  ActionsTaken,
  ActionsTaken_Version,
} from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/ActionsTaken';
import { BooleanOption } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/BooleanOption';
import {
  DomesticViolenceRisk,
  DomesticViolenceRisk_Version,
} from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/DomesticViolenceRisk';
import { DeescalationNeed_Version } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/DeescalationNeed';
import { DropInConsult_Version } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/DropInConsult';
import { ExperiencingRisk_Version } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/ExperiencingRisk';
import { ExploringTheApp_Version } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/ExploringTheApp';
import { OtherNote_Version } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/OtherNote';
import { SeekingClinical_Version } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/SeekingClinical';
import {
  EatingDisorderRisk,
  EatingDisorderRisk_Version,
} from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/EatingDisorderRisk';
import { FollowUp_Version } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/FollowUp';
import {
  HomicideRisk,
  HomicideRisk_Version,
} from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/HomicideRisk';
import { InitialConsult_Version } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/InitialConsult';
import {
  MemberNotAppropriateForPlatformRisk,
  MemberNotAppropriateForPlatformRisk_Version,
} from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/MemberNotAppropriateForPlatformRisk';
import {
  MemberWasInppropriateWithCoachRisk,
  MemberWasInppropriateWithCoachRisk_Version,
} from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/MemberWasInppropriateWithCoachRisk';
import {
  OtherRisk,
  OtherRisk_Version,
} from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/OtherRisk';
import {
  OutreachAttempt as OutreachAttemptVaultType,
  OutreachAttempt_Version,
} from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/OutreachAttempt';
import {
  QuickNote as QuickNoteVaultType,
  QuickNote_Version,
} from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/QuickNote';
import { RiskAssessment_Version } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/RiskAssessment';
import { RiskLevel } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/RiskLevel';
import {
  SelfHarmRisk,
  SelfHarmRisk_Version,
} from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/SelfHarmRisk';
import {
  SubstanceUseRisk,
  SubstanceUseRisk_Version,
} from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/SubstanceUseRisk';
import {
  SuicideRisk,
  SuicideRisk_Version,
} from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/SuicideRisk';
import {
  DeescalationNeedWithoutRisks,
  DropInConsultWithoutRisks,
  ExperiencingRiskWithoutRisks,
  ExploringTheAppWithoutRisks,
  FollowUpWithoutRisks,
  InitialConsultWithoutRisks,
  OtherNoteWithoutRisks,
  RiskAssessmentWithoutRisks,
  SeekingClinicalWithoutRisks,
} from 'app/coach/coach-notes/types';
import {
  booleanOptionRequired,
  requiredIfYes,
} from 'app/coach/coach-notes/validationRules';

export const dropInConsult: FieldDefinitions<DropInConsultWithoutRisks> = {
  additionalBackgroundInfo: stringField({ rules: [] }),
  collaborateOnPlan: stringField({ rules: [] }),
  initialConsultCompleted: field<BooleanOption>({
    rules: [booleanOptionRequired],
  }),
  memberId: stringField({ rules: [] }),
  nextSteps: stringField({ rules: [] }),
  selfCareStrategies: stringField({ rules: [] }),
  supportSystem: stringField({ rules: [] }),
  values: stringField({ rules: [] }),
  version: field<DropInConsult_Version>({
    default: DropInConsult_Version.v0,
    rules: [],
  }),
  whatCausedMemberToDropIn: stringField(),
};

export const deescalationNeed: FieldDefinitions<DeescalationNeedWithoutRisks> = {
  benefitsOfCoachingExplained: field<BooleanOption>({ rules: [] }),
  connectedToMs: field<BooleanOption>({ rules: [] }),
  copingSkills: stringField({ rules: [] }),
  directionToCoachProvided: field<BooleanOption>({ rules: [] }),
  explanationOfItmsProvided: field<BooleanOption>({ rules: [] }),
  nextSteps: stringField({ rules: [] }),
  otherRelevantInfo: stringField({ rules: [] }),
  processingSpaceProvided: field<BooleanOption>({ rules: [] }),
  symptoms: stringField({ rules: [] }),
  version: field<DeescalationNeed_Version>({
    default: DeescalationNeed_Version.v0,
    rules: [],
  }),
  resourcesProvided: stringField({ rules: [] }),
  whatCausedMemberToDropIn: stringField(),
  resourcesShared: field<BooleanOption>({ rules: [] }),
  riskAssessmentProvided: field<BooleanOption>({ rules: [] }),
};

export const quickNote: FieldDefinitions<QuickNoteVaultType> = {
  memberId: stringField({ rules: [] }),
  notes: stringField(),
  title: stringField({ rules: [] }),
  version: field<QuickNote_Version>({
    default: QuickNote_Version.v0,
    rules: [],
  }),
};

export const outreachAttempt: FieldDefinitions<OutreachAttemptVaultType> = {
  anythingElse: stringField({ rules: [] }),
  didMemberReadMessages: field<BooleanOption>({ rules: [] }),
  didSendResources: field<BooleanOption>({ rules: [booleanOptionRequired] }),
  memberId: stringField({ rules: [] }),
  reasonForOutreach: stringField(),
  version: field<OutreachAttempt_Version>({
    default: OutreachAttempt_Version.v0,
    rules: [],
  }),
};

export const initialConsult: FieldDefinitions<InitialConsultWithoutRisks> = {
  additionalBackgroundInfo: stringField({ rules: [] }),
  collaborateOnPlan: stringField({ rules: [] }),
  initialConsultCompleted: field<BooleanOption>({
    rules: [booleanOptionRequired],
  }),
  memberId: stringField({ rules: [] }),
  nextSteps: stringField({ rules: [] }),
  selfCareStrategies: stringField({ rules: [] }),
  sessionCount: positiveNumberField(),
  supportSystem: stringField({ rules: [] }),
  values: stringField({ rules: [] }),
  version: field<InitialConsult_Version>({
    default: InitialConsult_Version.v0,
    rules: [],
  }),
  whatBroughtMemberToPlatform: stringField(),
};

export const followup: FieldDefinitions<FollowUpWithoutRisks> = {
  challenges: stringField({ rules: [] }),
  goingWell: stringField({ rules: [] }),
  memberId: stringField({ rules: [] }),
  needsProgressReview: field<BooleanOption>({ rules: [booleanOptionRequired] }),
  nextSteps: stringField({ rules: [] }),
  progressReview: stringField({
    rules: [(value, state) => requiredIfYes(value, state.needsProgressReview)],
  }),
  sessionCount: positiveNumberField(),
  title: stringField({ rules: [] }),
  version: field<FollowUp_Version>({ default: FollowUp_Version.v0, rules: [] }),
};

export const experiencingRiskFieldDefs: FieldDefinitions<ExperiencingRiskWithoutRisks> = {
  version: field<ExperiencingRisk_Version>({
    default: ExperiencingRisk_Version.v0,
    rules: [],
  }),
  whatCausedMemberToDropIn: stringField(),
};

export const exploringTheAppFieldDefs: FieldDefinitions<ExploringTheAppWithoutRisks> = {
  nextSteps: stringField({ rules: [] }),
  notes: stringField(),
  version: field<ExploringTheApp_Version>({
    default: ExploringTheApp_Version.v0,
    rules: [],
  }),
  whatCausedMemberToDropIn: stringField(),
};

export const otherNoteFieldDefs: FieldDefinitions<OtherNoteWithoutRisks> = {
  nextSteps: stringField({ rules: [] }),
  notes: stringField(),
  title: stringField({ rules: [] }),
  version: field<OtherNote_Version>({
    default: OtherNote_Version.v0,
    rules: [],
  }),
  whatCausedMemberToDropIn: stringField(),
};

export const seekingClinicalFieldDefs: FieldDefinitions<SeekingClinicalWithoutRisks> = {
  nextSteps: stringField({ rules: [] }),
  notes: stringField(),
  version: field<SeekingClinical_Version>({
    default: SeekingClinical_Version.v0,
    rules: [],
  }),
  whatCausedMemberToDropIn: stringField(),
};

function riskLevelRequired(level: RiskLevel) {
  return level > 0 ? undefined : 'This field is required.';
}
export const suicideRisk: FieldDefinitions<SuicideRisk> = {
  disclosedTo: stringField({
    rules: [(value, state) => requiredIfYes(value, state.disclosure)],
  }),
  disclosure: field<BooleanOption>({ rules: [] }),
  intent: booleanField({ default: false }),
  location: stringField({ rules: [] }),
  means: booleanField({ default: false, rules: [] }),
  otherRelevantInfo: stringField({ rules: [] }),
  plan: booleanField({ default: false, rules: [] }),
  riskLevel: field<RiskLevel>({ rules: [riskLevelRequired] }),
  summary: stringField({ rules: [] }),
  thoughts: booleanField({ default: false, rules: [] }),
  version: field<SuicideRisk_Version>({
    default: SuicideRisk_Version.v0,
    rules: [],
  }),
};

export const homicideRisk: FieldDefinitions<HomicideRisk> = {
  disclosedTo: stringField({
    rules: [(value, state) => requiredIfYes(value, state.disclosure)],
  }),
  disclosure: field<BooleanOption>({ rules: [] }),
  intent: booleanField({ default: false }),
  location: stringField({ rules: [] }),
  means: booleanField({ default: false, rules: [] }),
  otherRelevantInfo: stringField({ rules: [] }),
  plan: booleanField({ default: false, rules: [] }),
  riskLevel: field<RiskLevel>({ rules: [riskLevelRequired] }),
  summary: stringField({ rules: [] }),
  thoughts: booleanField({ default: false, rules: [] }),
  version: field<HomicideRisk_Version>({
    default: HomicideRisk_Version.v0,
    rules: [],
  }),
};

export const selfHarmRisk: FieldDefinitions<SelfHarmRisk> = {
  disclosedTo: stringField({
    rules: [(value, state) => requiredIfYes(value, state.disclosure)],
  }),
  disclosure: field<BooleanOption>({ rules: [] }),
  duration: stringField(),
  frequency: stringField(),
  intensity: stringField(),
  means: stringField({ rules: [] }),
  onset: stringField(),
  otherRelevantInfo: stringField({ rules: [] }),
  riskLevel: field<RiskLevel>({ rules: [riskLevelRequired] }),
  soughtMedicalAttention: field<BooleanOption>({
    rules: [booleanOptionRequired],
  }),
  version: field<SelfHarmRisk_Version>({
    default: SelfHarmRisk_Version.v1,
    rules: [],
  }),
  summary: stringField({ rules: [] }),
};

export const domesticViolenceRisk: FieldDefinitions<DomesticViolenceRisk> = {
  attributes: stringField({ rules: [] }),
  disclosedTo: stringField({
    rules: [(value, state) => requiredIfYes(value, state.disclosure)],
  }),
  disclosure: field<BooleanOption>({ rules: [] }),
  duration: stringField(),
  frequency: stringField(),
  intensity: stringField(),
  onset: stringField(),
  otherRelevantInfo: stringField({ rules: [] }),
  riskLevel: field<RiskLevel>({ rules: [riskLevelRequired] }),
  summary: stringField({ rules: [] }),
  version: field<DomesticViolenceRisk_Version>({
    default: DomesticViolenceRisk_Version.v0,
    rules: [],
  }),
  viewTowardChange: stringField({ rules: [] }),
};

export const substanceUseRisk: FieldDefinitions<SubstanceUseRisk> = {
  degreeOfImpact: stringField(),
  disclosedTo: stringField({
    rules: [(value, state) => requiredIfYes(value, state.disclosure)],
  }),
  disclosure: field<BooleanOption>({ rules: [] }),
  duration: stringField(),
  frequency: stringField(),
  intensity: stringField(),
  isConcerned: field<BooleanOption>({ rules: [] }),
  onset: stringField(),
  riskLevel: field<RiskLevel>({ rules: [riskLevelRequired] }),
  substancesUsed: stringField({ rules: [] }),
  version: field<SubstanceUseRisk_Version>({
    default: SubstanceUseRisk_Version.v0,
    rules: [],
  }),
  otherRelevantInfo: stringField({ rules: [] }),
  summary: stringField({ rules: [] }),
};

export const eatingDisorderRisk: FieldDefinitions<EatingDisorderRisk> = {
  copingSkills: stringField({ rules: [] }),
  disclosedTo: stringField({
    rules: [(value, state) => requiredIfYes(value, state.disclosure)],
  }),
  disclosure: field<BooleanOption>({ rules: [] }),
  duration: stringField(),
  frequency: stringField(),
  intensity: stringField(),
  location: stringField({ rules: [] }),
  motivationToRecover: stringField({ rules: [] }),
  onset: stringField(),
  riskLevel: field<RiskLevel>({ rules: [riskLevelRequired] }),
  version: field<EatingDisorderRisk_Version>({
    default: EatingDisorderRisk_Version.v0,
    rules: [],
  }),
  otherRelevantInfo: stringField({ rules: [] }),
  purgingBehavior: stringField({ rules: [] }),
  soughtMedicalAttention: field<BooleanOption>({
    rules: [booleanOptionRequired],
  }),
};

export const abuseOfVulnerablePopulationsRisk: FieldDefinitions<AbuseOfVulnerablePopulationsRisk> = {
  authoritiesNotified: field<BooleanOption>({ rules: [booleanOptionRequired] }),
  dateOfIncident: stringField({ rules: [] }),
  disclosedTo: stringField({
    rules: [(value, state) => requiredIfYes(value, state.disclosure)],
  }),
  disclosure: field<BooleanOption>({ rules: [] }),
  locationOfAbuse: stringField({ rules: [] }),
  mandatedReport: stringField(),
  nameOfAuthority: stringField({ rules: [] }),
  nameOfPersonResponsible: stringField({ rules: [] }),
  natureOfAbuse: stringField({ rules: [] }),
  reportNecessary: booleanField(),
  otherRelevantInfo: stringField({ rules: [] }),
  version: field<AbuseOfVulnerablePopulationsRisk_Version>({
    default: AbuseOfVulnerablePopulationsRisk_Version.v0,
    rules: [],
  }),
  victimAgeAndGender: stringField({ rules: [] }),
  victimPersonalInfo: stringField({ rules: [] }),
  whoDisclosedIncident: stringField({ rules: [] }),
};

export const inappropriateForPlatform: FieldDefinitions<MemberNotAppropriateForPlatformRisk> = {
  concerns: stringField(),
  version: field<MemberNotAppropriateForPlatformRisk_Version>({
    default: MemberNotAppropriateForPlatformRisk_Version.v0,
    rules: [],
  }),
};

export const inappropriateWithCoach: FieldDefinitions<MemberWasInppropriateWithCoachRisk> = {
  concerns: stringField(),
  version: field<MemberWasInppropriateWithCoachRisk_Version>({
    default: MemberWasInppropriateWithCoachRisk_Version.v0,
    rules: [],
  }),
};

export const otherRisk: FieldDefinitions<OtherRisk> = {
  concerns: stringField(),
  version: field<OtherRisk_Version>({
    default: OtherRisk_Version.v0,
    rules: [],
  }),
};

export const actionsTaken: FieldDefinitions<ActionsTaken> = {
  clinicalServicesRecommended: field<BooleanOption>({ rules: [] }),
  clinicianNotified: field<BooleanOption>({ rules: [] }),
  connectedToMemberSupport: field<BooleanOption>({ rules: [] }),
  consultedCoachManager: field<BooleanOption>({ rules: [] }),
  crrFilled: field<BooleanOption>({ rules: [] }),
  engagedCareCoordination: field<BooleanOption>({ rules: [] }),
  onCallInvolved: field<BooleanOption>({ rules: [] }),
  onCallPerson: stringField({
    rules: [(value, state) => requiredIfYes(value, state.onCallInvolved)],
  }),
  referredForTherapy: field<BooleanOption>({ rules: [] }),
  resourcesShared: field<BooleanOption>({ rules: [] }),
  version: field<ActionsTaken_Version>({
    default: ActionsTaken_Version.v0,
    rules: [],
  }),
};

export const riskAssessments = {
  abuseOfVulnerablePopulationsRisk,
  actionsTaken,
  domesticViolenceRisk,
  eatingDisorderRisk,
  homicideRisk,
  inappropriateForPlatform,
  inappropriateWithCoach,
  otherRisk,
  selfHarmRisk,
  substanceUseRisk,
  suicideRisk,
};

export const riskAssessmentsFieldDefs: FieldDefinitions<RiskAssessmentWithoutRisks> = {
  memberId: stringField({ rules: [] }),
  version: field<RiskAssessment_Version>({
    default: RiskAssessment_Version.v0,
    rules: [],
  }),
};
