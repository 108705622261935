import { InboxV2 } from 'app/inboxV2';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

import { useAppState } from '../state';
import { selectShouldDisplayInboxV2 } from '../state/user/selectors';
import { InboxV1 } from './InboxV1';

export function InboxContainer() {
  const {
    carehub_enable_coach_inbox_v2: enableCoachInboxV2,
  } = useFeatureFlags().transientFeatureFlags;

  // Since the inbox components are shared by coaches and clinicians, this checks the user role, in addition to the
  // feature flag, to determine which inbox to display.
  const shouldShowInboxV2 = useAppState((state) =>
    selectShouldDisplayInboxV2(enableCoachInboxV2)(state),
  );

  return <>{shouldShowInboxV2 ? <InboxV2 /> : <InboxV1 />}</>;
}
