import { TimetokensMap } from 'app/coach/chat/types';
import { InboxItem } from 'app/inbox/types';
import { extractStateFromInboxItems } from 'app/inbox/utils';
import { addTimetokens } from 'app/state/features/conversationTimetokens/conversationTimetokensSlice';
import {
  getInboxConversationTimetokens,
  getInboxConversationTimetokens_getInboxConversationByIds_conversationStats,
  getInboxConversationTimetokensVariables,
} from 'app/state/features/conversationTimetokens/generated/getInboxConversationTimetokens';
import { GetTimetokensForConversations } from 'app/state/features/conversationTimetokens/queriesAndMutations';
import {
  pubnubTimeTokenToMoment,
  uniqueArrayList,
} from 'app/state/inbox/utils';
import { ILogger } from 'app/state/log/Logger';
import { batch } from 'react-redux';
import { isCoachOrSupervisor } from 'utils';

import {
  addChannelMemberMap,
  addConversationStateMap,
  updateInboxItems,
  updateUnreadMessages,
} from '../actions';
import { ActionHandler } from './types';

// init the state for the conversation: unread messages, timetokens, state, memberCoachChannel
export const onInitConversationsInInbox = async ({
  action,
  redux,
  context,
}: ActionHandler<{ inboxItems: InboxItem[] }>) => {
  const { user } = redux.getState();
  const { inboxItems } = action.payload;
  const { apollo, logger } = context.services;
  const { conversationStateMap, channelMemberMap } = extractStateFromInboxItems(
    inboxItems,
  );
  const memberIds = uniqueArrayList(
    inboxItems.map((i) => i.id).filter((id) => id),
  );
  // clinicians do not chat with the members, so the state is applicable only for coaches
  if (isCoachOrSupervisor(user.role)) {
    batch(async () => {
      // a temp solution to keep action handlers working w/ RTK during migration;
      try {
        const { data, error } = await apollo.query<
          getInboxConversationTimetokens,
          getInboxConversationTimetokensVariables
        >({
          fetchPolicy: 'network-only',
          query: GetTimetokensForConversations,
          variables: { memberIds },
        });

        if (!data.getInboxConversationByIds || error) {
          logger.error(
            new Error(
              'inboxActionHandlers.initConversationsInInbox: Unable to fetch conversation timetokens: unexpected getInboxConversationByIds value; initConversationsInInbox action handler',
              { cause: error },
            ),
            {
              error,
              memberIds,
            },
          );
          return;
        }

        logConversationWithInvalidChannelId({ data, logger, memberIds });

        const timetokensWithValidChannel = data.getInboxConversationByIds
          .filter((item) =>
            Boolean(item.conversationStats?.memberCoachChannelId),
          )
          .reduce((acc, item) => {
            // safe assertions due to the above filter
            const channelId = item.conversationStats!.memberCoachChannelId!;
            acc[channelId] = {
              lastListenerReadTimeToken:
                item.conversationStats?.lastListenerReadTimeToken ?? null,
              lastMemberReadTimeToken:
                item.conversationStats?.lastMemberReadTimeToken ?? null,
              lastMemberWriteTimeToken:
                item.conversationStats?.lastMemberWriteTimeToken ?? null,
              latestWriteTimestamp:
                item.conversationStats?.latestWriteTimestamp ?? null,
            };
            maybeLogOutDatedConversationTimetoken({
              channelId,
              convoStats: item.conversationStats!,
              logger,
              redux,
            });

            return acc;
          }, {} as TimetokensMap);
        const channelsWithTruthyLastListenerRead = Object.keys(
          timetokensWithValidChannel,
        ).map((i) => ({
          lastListenerReadTimetoken:
            timetokensWithValidChannel[i].lastListenerReadTimeToken ?? '0',
          memberCoachChannelId: i,
        }));
        redux.dispatch(
          updateUnreadMessages(channelsWithTruthyLastListenerRead),
        );
        redux.dispatch(
          addTimetokens({ timetokensMap: timetokensWithValidChannel }),
        );
        redux.dispatch(addConversationStateMap({ conversationStateMap }));
        redux.dispatch(addChannelMemberMap({ channelMemberMap }));
      } catch (e) {
        logger.error(
          new Error(
            'inboxActionHandlers.initConversationsInInbox: Unable to fetch conversation timetokens: caught an error; initConversationsInInbox action handler',
            { cause: e },
          ),
          {
            error: e,
            memberIds,
          },
        );
      }
    });
  }
  redux.dispatch(updateInboxItems(inboxItems));
};

function logConversationWithInvalidChannelId({
  data,
  logger,
  memberIds,
}: {
  data: getInboxConversationTimetokens;
  logger: ILogger;
  memberIds: string[];
}) {
  const invalidConversations = data.getInboxConversationByIds.filter(
    (item) => !item.conversationStats?.memberCoachChannelId,
  );

  if (invalidConversations.length > 0) {
    logger.error(
      new Error(
        'inboxActionHandlers.initConversationsInInbox: queried time tokens from initConversationsInInbox but got unexpected result for some convo(s)',
      ),
      {
        queriedMemberIds: memberIds,
        suspiciousResults: invalidConversations,
      },
    );
  }
}

function maybeLogOutDatedConversationTimetoken({
  redux,
  logger,
  convoStats,
  channelId,
}: {
  redux: ActionHandler<{ inboxItems: InboxItem[] }>['redux'];
  channelId: string;
  convoStats: getInboxConversationTimetokens_getInboxConversationByIds_conversationStats;
  logger: ILogger;
}) {
  /*
   It's possible that the server response is become out of data. For example, 
   1. When we receive member update_read_state RPC or a new member message from pubnub while the request was in-flight
      and the backend hasn't updated yet.
   2. Care-hub sends a request to update the listener read/write timetoken while the request is in-flight.
   
   For now, we just log a warning message when we detect the server response is out of data.
  */
  const {
    conversationsTimetokens: { timetokensMap },
  } = redux.getState();
  const previousTimetokens = timetokensMap[channelId];

  if (
    (previousTimetokens?.lastMemberReadTimeToken ?? '0') >
    (convoStats?.lastMemberReadTimeToken ?? '0')
  ) {
    logger.warning(
      'onInitConversationsInInbox: lastMemberReadTimeToken from server response is out of data',
      {
        channelId,
        lastMemberReadTimeToken: pubnubTimeTokenToMoment(
          convoStats?.lastMemberReadTimeToken,
        ).toISOString(),
        lastMemberReadTimeTokenInStore: pubnubTimeTokenToMoment(
          previousTimetokens?.lastMemberReadTimeToken,
        ).toISOString(),
      },
    );
  }

  if (
    (previousTimetokens?.lastMemberWriteTimeToken ?? '0') >
    (convoStats?.lastMemberWriteTimeToken ?? '0')
  ) {
    logger.warning(
      'onInitConversationsInInbox: lastMemberWriteTimeToken from server response is out of data',
      {
        channelId,
        lastMemberWriteTimeToken: pubnubTimeTokenToMoment(
          convoStats?.lastMemberWriteTimeToken,
        ).toISOString(),
        lastMemberWriteTimeTokenInStore: pubnubTimeTokenToMoment(
          previousTimetokens?.lastMemberWriteTimeToken,
        ).toISOString(),
      },
    );
  }
  if (
    (previousTimetokens?.lastListenerReadTimeToken ?? '0') >
    (convoStats?.lastListenerReadTimeToken ?? '0')
  ) {
    logger.warning(
      'onInitConversationsInInbox: lastListenerReadTimeToken from server response is out of data',
      {
        channelId,
        lastListenerReadTimeToken: pubnubTimeTokenToMoment(
          convoStats?.lastListenerReadTimeToken,
        ).toISOString(),
        lastListenerReadTimeTokenInStore: pubnubTimeTokenToMoment(
          previousTimetokens?.lastListenerReadTimeToken,
        ).toISOString(),
      },
    );
  }
  if (
    new Date(previousTimetokens?.latestWriteTimestamp ?? '0').getTime() >
    new Date(convoStats?.latestWriteTimestamp ?? '0').getTime()
  ) {
    logger.warning(
      'onInitConversationsInInbox: latestWriteTimestamp from server response is out of data',
      {
        channelId,
        latestWriteTimestamp: convoStats?.latestWriteTimestamp,
        latestWriteTimestampInStore: previousTimetokens?.latestWriteTimestamp,
      },
    );
  }
}
