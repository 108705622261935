import { decode } from 'jsonwebtoken';

export type VaultJWTToken = {
  token: string;
};

export type DecodedJWTToken = {
  payload: {
    exp: number;
  };
};

export type Clock = { now: () => Date };
export type LocalStorage = Pick<
  typeof localStorage,
  'getItem' | 'setItem' | 'removeItem'
>;

const oneHourInSeconds = 60 * 60;

export class VaultTokenStorage {
  private key = 'vault-jwt-token';

  constructor(
    private storage: LocalStorage = localStorage,
    private clock: Clock = { now: () => new Date() },
  ) {}

  private getToken(): string | undefined {
    const data = this.storage.getItem(this.key);
    if (!data) return;
    const { token } = JSON.parse(data!) as VaultJWTToken;
    return token;
  }

  getIfNotExpired(): string | undefined {
    const exp = this.getTokenExpirationDate();
    const oneHourFromNow = new Date(
      this.clock.now().getTime() + oneHourInSeconds * 1000,
    );
    if (exp && exp > oneHourFromNow) {
      return this.getToken();
    }
  }

  set(data: VaultJWTToken): void {
    this.storage.setItem(this.key, JSON.stringify(data));
  }

  clear(): void {
    this.storage.removeItem(this.key);
  }

  getTokenExpirationDate() {
    try {
      const token = this.getToken();
      if (!token) return;
      const { payload } = decode(token, { complete: true }) as DecodedJWTToken;
      return new Date(payload.exp * 1000);
    } catch (e) {
      console.warn('Error parsing or decoding VaultJWTToken', e);
    }
  }
}

const tokenStorage = new VaultTokenStorage();

export default tokenStorage;
