import { FormControlLabel } from '@mui/material';
import Checkbox from 'shared-components/checkbox/Checkbox';
import { FormError } from 'shared-components/FormError';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import React, { useState } from 'react';

import styles from './PsychiatryHistoryTable.module.scss';
import { Props as TableProps, Table } from './Table';

export type OnChangeHandler<T> = (input: {
  items: T[];
  isValid: boolean;
}) => void;

type Props<T> = {
  label: string;
  noDataCheckboxLabel: string;
  onChange: OnChangeHandler<T>;
  error?: string;
  testId?: string;
} & Omit<TableProps<T>, 'onChange'>;

export function PsychiatricHistoryTable<T>(props: Props<T>) {
  const {
    columns: columnDefs,
    fieldDefs,
    initialValue = [],
    label,
    getBlankRecord,
    noDataCheckboxLabel,
  } = props;
  const [hasNoRecord, setHasNoRecord] = useState(
    props.initialValue !== undefined && initialValue.length === 0,
  );

  return (
    <div data-testid={props.testId} className={styles.root}>
      <NoteFormControlLabel data-testid="title" label={label}>
        <FormControlLabel
          classes={{ label: styles.label, root: styles.checkBoxLabel }}
          label={noDataCheckboxLabel}
          control={
            <Checkbox
              disabled={props.disabled}
              checked={hasNoRecord}
              onChange={(_, checked) => {
                setHasNoRecord(checked);
                props.onChange({
                  isValid: checked || initialValue.length > 0,
                  items: checked ? [] : initialValue,
                });
              }}
            />
          }
        />
        <Table
          initialValue={initialValue}
          disabled={props.disabled || hasNoRecord}
          fieldDefs={fieldDefs}
          onChange={(records, isValid) =>
            props.onChange({
              isValid: !hasNoRecord && records.length > 0 && isValid,
              items: records,
            })
          }
          columns={columnDefs}
          getBlankRecord={getBlankRecord}
          renderEmptyRow={hasNoRecord} //
        />
        <FormError error={props.error} />
      </NoteFormControlLabel>
    </div>
  );
}
