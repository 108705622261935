import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import React, { FormEvent, useState } from 'react';

import classes from './Search.module.scss';

type SearchProps = {
  onSearchQuery?: (query: string) => void;
  placeholder?: string;
  className?: string;
};
export default function Search(props: SearchProps) {
  const [query, setQuery] = useState('');
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    props.onSearchQuery && props.onSearchQuery(query);
  };
  return (
    <form data-testid="searchForm" onSubmit={handleSubmit}>
      <Paper className={[classes.root, props.className].join(' ')}>
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
          size="large"
        >
          <SearchIcon />
        </IconButton>
        <InputBase
          data-testid="searchInput"
          name="search"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className={classes.input}
          placeholder={props.placeholder || 'Search Members'}
          inputProps={{ 'aria-label': 'search members' }}
        />
      </Paper>
    </form>
  );
}
