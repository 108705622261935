import React from 'react';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';

import FourDots from './FourDots';
import styles from './LineChart.module.scss';
import { ThreeDots } from './ThreeDots';

type TooltipVariant = 'three' | 'four';

/** A toolip for survey data that displays additional dimensions
 *  + a total value in a table format with dots to visualize
 */
export function SurveyTooltip(props: {
  data: [string, number][]; // label, value
  total: number;
  variant?: TooltipVariant;
}) {
  const { data, total, variant = 'three' } = props;
  const rows = data.map(([label, value], index) => (
    <tr key={`${label}-${index}`}>
      <td>{label}</td>
      <td>
        {variant === 'three' ? <ThreeDots value={value} /> : null}
        {variant === 'four' ? <FourDots value={value} /> : null}
      </td>
    </tr>
  ));

  // Split our table rows into two equal (+/- 1) groups
  // so that this tooltip's height doesn't become unweidly
  const [left, right] = partition(rows, (_, i) => i <= rows.length / 2);

  return (
    <div className={styles.tooltip}>
      <Grid>
        <Columns
          className={styles.columns}
          widths={[COLUMN_WIDTH.HALF, COLUMN_WIDTH.HALF]}
        >
          <table>
            <tbody>{left}</tbody>
          </table>

          <table>
            <tbody>
              {right}
              <tr>
                <td>
                  <strong>Total</strong>
                </td>
                <td>
                  <strong>{total}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </Columns>
      </Grid>
    </div>
  );
}

/** Partitions an array into two arrays given a predicate */
function partition<T>(
  items: T[],
  predicate: (item: T, index: number) => boolean,
): [T[], T[]] {
  const left: T[] = [];
  const right: T[] = [];

  items.forEach((item, i) => {
    if (predicate(item, i)) {
      left.push(item);
    } else {
      right.push(item);
    }
  });

  return [left, right];
}
