import { CoachClinicianCollaborationChatMessage } from '@ginger.io/vault-care-collaboration/dist/generated/protobuf-schemas/vault-care-collaboration/CoachClinicianCollaborationChatMessage';
import { KeyGenerator } from '@ginger.io/vault-core/dist/crypto';
import { AuthorWithName } from 'app/collaboration/type';
import {
  useCollaborationChatStream,
  UseCollaborationChatStreamResult,
} from 'app/collaboration/useCollaborationChatStream';
import { ChatTextAreaGroup } from 'app/collaboration/v2/ChatTextAreaGroup';
import { MemberChartErrorState } from 'app/member-chart-cards/error-state/MemberChartErrorState';
import { useDispatch } from 'app/state';
import {
  collaborationNewMessageSendClick,
  collaborationTabClicked,
} from 'app/state/amplitude/actions/collaborationChat';
import { Status } from 'hooks/useHookState';
import { useOnMount } from 'hooks/useOnMount';
import React from 'react';
import { Loader } from 'shared-components/loader/Loader';

import styles from './CommunicationContainer.module.scss';
import { CommunicationList } from './CommunicationList';

export interface Props {
  memberId: string;
  author: AuthorWithName;
  keyGenerator?: KeyGenerator;
}

export const CommunicationContainer = (props: Props) => {
  const { memberId, author, keyGenerator } = props;
  const dispatch = useDispatch();

  const chatStream = useCollaborationChatStream(
    memberId,
    author,
    35,
    keyGenerator,
  );

  useOnMount(() => {
    dispatch(collaborationTabClicked({ author, memberId }));
  });

  const onSubmit = async (
    chatMessage: CoachClinicianCollaborationChatMessage,
  ) => {
    await chatStream.createChatMessage(chatMessage);
    dispatch(collaborationNewMessageSendClick({ author, memberId }));
  };

  return (
    <div data-testid="communication-container" className={styles.wrapper}>
      <div className={styles.container}>
        <div
          /**
           * Setting "flex-direction to column-reverse" because it controls the behavior of infinite scrolling, enabling
           * the loader to appear at the top of the list when the user scrolls up. Without it, the function to load
           * additional messages will not be triggered.
           */
          style={{ display: 'flex', flexDirection: 'column-reverse' }}
          id="message-container"
          className={styles.content}
        >
          {renderMessageContent(chatStream, author)}
        </div>
        <ChatTextAreaGroup
          memberId={memberId}
          author={author}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
};

function renderMessageContent(
  chatStream: UseCollaborationChatStreamResult,
  author: AuthorWithName,
) {
  const chatMessageState = chatStream.chatMessages.current;

  if (chatMessageState.status === Status.LOADING) {
    return <Loader />;
  }
  if (chatMessageState.status === Status.ERROR) {
    return <MemberChartErrorState error={chatMessageState.error} />;
  }

  return (
    <CommunicationList
      loadMoreChatMessage={chatStream.loadMoreChatMessage}
      cursor={chatMessageState.data.cursor}
      chats={chatMessageState.data.chats}
      author={author}
    />
  );
}
