import Grid from '@mui/material/Grid';
import React, { ReactNode } from 'react';

// Grid is based on 12 columns
export enum COLUMN_WIDTH {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  SEVEN = 7,
  EIGHT = 8,
  NINE = 9,
  TEN = 10,
  ELEVEN = 11,
  TWELVE = 12,

  QUARTER = 3,
  THIRD = 4,
  HALF = 6,
  THREE_QUARTERS = 9,
  FULL = 12,
}

type Props = {
  children:
    | ReactNode
    | [ReactNode, ReactNode]
    | [ReactNode, ReactNode, ReactNode];

  widths?: COLUMN_WIDTH[];

  className?: string;
};

export function Columns(props: Props) {
  const { children, widths, className } = props;

  const components = Array.isArray(children) ? children : [children];

  return (
    <>
      {components.map((child, i) => {
        let xs: boolean | COLUMN_WIDTH = true;
        if (widths !== undefined) {
          xs = widths[i];
        }

        return (
          <Grid
            data-testid={`grid-column-${i}`}
            key={i}
            item={true}
            xs={xs}
            className={className}
          >
            {child}
          </Grid>
        );
      })}
    </>
  );
}
