export const Labels = {
  chiefComplaint: {
    chiefComplaint: {
      id: 'chiefComplaint',
      label: 'Chief Complaint',
      placeholder: 'Reason for visit in patient’s own words...',
    },
    historyOfPresentIllness: {
      id: 'historyOfPresentIllness',
      label: 'History of present illness (HPI)',
      placeholder: 'Type here...',
    },
    relevantPhysicalHealthUpdates: {
      id: 'relevantPhysicalHealthUpdates',
      label: 'Relevant physical health updates',
      placeholder: 'Type here...',
      subtext: 'Weight, blood pressure, AIMS',
    },
  },
};
