import moment from 'moment';
import React from 'react';

import styles from './SessionTime.module.scss';

interface Props {
  userTime: moment.Moment;
  coachTime: moment.Moment;
}

const SessionTime = ({ userTime, coachTime }: Props) => {
  if (userTime === undefined || coachTime === undefined) {
    return null;
  }
  const coachDate = moment(coachTime).utc(true).startOf('day');
  const userDate = moment(userTime).utc(true).startOf('day');
  // Use dayOfYear for offset to handle last/first day of month differences
  const offset = userDate.dayOfYear() - coachDate.dayOfYear();
  return (
    <div className={styles.container}>
      <span>{coachTime.format('ddd, MMM D, YYYY')}</span>
      <span>
        {coachTime.format('h:mma')} {coachTime.zoneAbbr()} /{' '}
        {userTime.format('h:mma')} {userTime.zoneAbbr()}
        {/* Need to consider 364 for December 31, and 365 for leap year December 31 */}
        {[-1, 364, 365].includes(offset) ? (
          <sup data-testid="minusOne">-1</sup>
        ) : null}
        {[1, -364, -365].includes(offset) ? (
          <sup data-testid="plusOne">+1</sup>
        ) : null}
      </span>
    </div>
  );
};

export default SessionTime;
