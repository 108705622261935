import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GetClinicalAppointmentsSummaries,
  GetClinicalAppointmentsSummariesVariables,
} from '@headspace/carehub-graphql/dist/care-metrics/generated/GetClinicalAppointmentsSummaries';
import {
  GetClinicalAppointmentsTargets,
  GetClinicalAppointmentsTargetsVariables,
} from '@headspace/carehub-graphql/dist/care-metrics/generated/GetClinicalAppointmentsTargets';
import {
  getClinicalAppointmentsSummariesQuery,
  getClinicalAppointmentsTargetsQuery,
} from '@headspace/carehub-graphql/dist/care-metrics/queries';
import { CardContent, Divider, Grid, Link, Typography } from '@mui/material';
import BarChart from 'app/charts/BarChart';
import { GOOGLE_CALENDAR_URL } from 'app/constants';
import {
  clickGoogleCalendarAction,
  clickWeeklyBreakdownViewAction,
} from 'app/state/amplitude/actions/careMetrics';
import { useOnMount } from 'hooks/useOnMount';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card } from 'shared-components/Card';

import style from './CareMetrics.module.scss';
import DateRangeCard from './DateRangeCard';
import { AppointmentsSummary, ChartLabel, ChartSeries } from './types';
import { calculateSeries, getAppointmentsSummaryPerWeek } from './utils';

interface SessionsOfferedProps {
  enableCompanyTarget: boolean;
  clinicianId: string;
  startDate: string;
  endDate: string;
}

function SessionsOffered(props: SessionsOfferedProps) {
  const { clinicianId, startDate, endDate, enableCompanyTarget } = props;

  const appointmentsSummaries = useQuery<
    GetClinicalAppointmentsSummaries,
    GetClinicalAppointmentsSummariesVariables
  >(getClinicalAppointmentsSummariesQuery, {
    variables: { input: { clinicianId, endDate, startDate } },
  });

  const [runTargetQuery, { data: appointmentsTargetsData }] = useLazyQuery<
    GetClinicalAppointmentsTargets,
    GetClinicalAppointmentsTargetsVariables
  >(getClinicalAppointmentsTargetsQuery);
  useOnMount(() => {
    if (enableCompanyTarget) {
      void runTargetQuery({ variables: { input: { clinicianId, startDate } } });
    }
  });
  let companyTargets: number[] = [];

  if (appointmentsTargetsData?.getClinicalAppointmentsTargets) {
    const targets = appointmentsTargetsData?.getClinicalAppointmentsTargets;
    companyTargets = [
      targets?.offered || 0,
      targets?.scheduled || 0,
      targets?.completed || 0,
    ];
  }

  const clinicianSummary =
    appointmentsSummaries.data?.getClinicalAppointmentsSummaries?.summaries ??
    [];
  return (
    <SessionsOfferedGraph
      clinicianSummary={clinicianSummary}
      companyTargets={companyTargets}
      clinicianId={clinicianId}
    />
  );
}

interface SessionsOfferedGraphProps {
  clinicianSummary: AppointmentsSummary[];
  companyTargets: number[];
  clinicianId: string;
}

export function SessionsOfferedGraph(props: SessionsOfferedGraphProps) {
  const { clinicianSummary, companyTargets } = props;
  const initialDate =
    clinicianSummary.length > 0
      ? moment(clinicianSummary[0].date, 'YYYY-MM-DD')
      : moment();
  const monthName = initialDate.format('MMMM');
  const series = calculateSeries(clinicianSummary, companyTargets);
  const [selected, setSelected] = useState(monthName);
  const [cardBody, setCardBody] = useState('');
  const [chartSeries, setChartSeries] = useState<ChartSeries>(series);
  const appointmentsSummaryPerWeek = getAppointmentsSummaryPerWeek(
    clinicianSummary,
  );
  const dispatch = useDispatch();

  const onToggleDate = (key: string, series: ChartSeries) => {
    setSelected(key);
    setChartSeries(series);
    dispatch(
      clickWeeklyBreakdownViewAction({
        clinicianId: props.clinicianId,
        key,
      }),
    );
  };

  const onGoogleCalendarClick = () => {
    dispatch(clickGoogleCalendarAction({ clinicianId: props.clinicianId }));
  };

  const datalabels = {
    labels: {
      title: {
        align: 'start' as const,
        anchor: 'end' as const,
        color: (context: any) => {
          if (
            context.dataIndex === 1 &&
            context.dataset.label === ChartLabel.CANCELLED
          ) {
            return 'white';
          }
          return 'black';
        },
        display: (context: any) => {
          return context.dataset.data[context.dataIndex] > 2;
        },
      },
    },
  };
  const data = {
    datasets: [
      {
        backgroundColor: '#609ce1',
        data: chartSeries.myPerformance,
        label: ChartLabel.MY_PERFORMANCE,
        stack: 'Stack 0',
      },
      {
        backgroundColor: '#d7eaff',
        data: chartSeries.companyTarget,
        label: ChartLabel.COMPANY_TARGET,
        stack: 'Stack 1',
      },
      {
        backgroundColor: '#254c7e',
        data: chartSeries.cancelled,
        label: ChartLabel.CANCELLED,
        stack: 'Stack 0',
      },
    ],
    labels: ['Offered', 'Scheduled', 'Completed'],
  };

  useEffect(() => {
    setChartSeries(series);
    setSelected(monthName);

    if (companyTargets.length > 0) {
      const performance = series.myPerformance[0];
      const target = series.companyTarget[0];
      const percentage =
        target !== 0 ? Math.round((performance / target) * 100) : 0;
      setCardBody(`${percentage}%`);
    }
  }, [companyTargets]);

  return (
    <Card boxTitle="Sessions Offered" size="large">
      <CardContent>
        <Typography mb={1} sx={{ fontSize: 13, fontWeight: 'bold' }}>
          {
            "Percentage and use of slots you've made available for scheduling within "
          }
          <Link
            className={style.link}
            href={GOOGLE_CALENDAR_URL}
            target="_blank"
            onClick={onGoogleCalendarClick}
          >
            Google Calendar
          </Link>
        </Typography>
        <Grid
          mb={2}
          container={true}
          alignItems="center"
          justifyContent="center"
        >
          <DateRangeCard
            title={monthName}
            body={cardBody}
            series={series}
            onClick={onToggleDate}
            selected={selected}
          />

          {appointmentsSummaryPerWeek.map((summaries, idx) => {
            const start = moment(summaries[0].date, 'YYYY-MM-DD').format('Do');
            const end = moment(
              summaries[summaries.length - 1].date,
              'YYYY-MM-DD',
            ).format('Do');
            const series = calculateSeries(summaries, []);
            return (
              <DateRangeCard
                key={idx}
                title={`${start}-${end}`}
                series={series}
                onClick={onToggleDate}
                selected={selected}
              />
            );
          })}
        </Grid>
        <Divider />
        <BarChart
          title="By sessions offered"
          data={data}
          datalabels={datalabels}
        />
      </CardContent>
    </Card>
  );
}

export default SessionsOffered;
