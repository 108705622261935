import React, { ReactElement, ReactNode } from 'react';

import { Grid } from './Grid.module';

export enum CardRowGaps {
  STANDARD = 18,
  CONDENSED = 12,
}

export enum CardColumnGaps {
  STANDARD = 0,
  CONDENSED = 12,
}

export enum NumberOfCardColumns {
  ONE = 1,
  TWO = 2,
}

interface CardGridProps {
  children: ReactNode;
  rowGap: CardRowGaps;
  columnGap: CardColumnGaps;
  numberOfColumns: NumberOfCardColumns;
  template?: string; // implies a valid grid-template-areas value
}

export const CardGrid = ({
  children,
  rowGap,
  columnGap,
  numberOfColumns,
  template,
}: CardGridProps): ReactElement => {
  const gridTemplateColumns = `repeat(${numberOfColumns}, 1fr)`;
  return (
    <Grid
      children={children}
      rowGap={rowGap}
      columnGap={columnGap}
      template={template}
      gridTemplateColumns={gridTemplateColumns}
    />
  );
};
