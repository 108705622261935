import styles from 'app/inbox/components/styles/Tab.module.scss';
import {
  FrenchTag,
  RiskTag,
  SpanishTag,
  TeenTag,
} from 'app/inbox/components/Tags';
import { classNameCombiner } from 'utils';
import React, { MouseEvent } from 'react';

export interface TabProps {
  title: string;
  width: number;
  hasRisk?: boolean;
  isTeen?: boolean;
  showNewRiskTaskIndicator?: boolean;
  isFrench?: boolean;
  isSpanish?: boolean;
  isActive?: boolean;
  notifyCount?: number;
  onTabSelect?: () => void;
  onTabClose?: () => void;
  'data-testid'?: string;
}

export default function Tab({
  title,
  width,
  hasRisk,
  isTeen,
  isFrench,
  isSpanish,
  isActive,
  onTabSelect,
  onTabClose,
  notifyCount = 0,
  showNewRiskTaskIndicator,
  'data-testid': testId,
}: TabProps) {
  const handleCloseButtonClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (onTabClose) onTabClose();
    event.stopPropagation();
  };
  const handleSelectButtonClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (onTabSelect) onTabSelect();
    event.stopPropagation();
  };

  const tabPaddingAllowance = 6;
  const tabWidth = width - tabPaddingAllowance;
  return (
    <div
      data-testid={testId}
      className={classNameCombiner([styles.tab, isActive ? styles.active : ''])}
      style={{ width: `${tabWidth}px` }}
    >
      <button
        style={{ width: `${tabWidth - 30}px` }}
        className={styles.tabBtn}
        onClick={handleSelectButtonClick}
      >
        {hasRisk && <RiskTag />}
        {isTeen && <TeenTag />}
        {isFrench && <FrenchTag />}
        {isSpanish && <SpanishTag />}
        <div className={styles.title}>{title}</div>
        {(notifyCount > 0 || (hasRisk && showNewRiskTaskIndicator)) && (
          <div
            className={classNameCombiner([
              styles.notify,
              notifyCount === 0 ? styles.notifyWithoutCount : '',
            ])}
          >
            {notifyCount || null}
          </div>
        )}
      </button>
      <button className={styles.cancel} onClick={handleCloseButtonClick} />
    </div>
  );
}
