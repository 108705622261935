import { GQL_QUERY_POLLING_INTERVAL } from 'app/constants';

import {
  refreshClinicianTodaysMemberList,
  setInboxPollingID,
} from '../actions';
import { ActionHandler } from './types';

export const onStartInboxPolling = ({ redux }: ActionHandler<any>) => {
  const pollingID = setInterval(() => {
    redux.dispatch(refreshClinicianTodaysMemberList({}));
  }, GQL_QUERY_POLLING_INTERVAL);
  redux.dispatch(setInboxPollingID((pollingID as unknown) as number));
};
