import styles from 'app/patients/tabs/intake/IntakeTab.module.scss';
import React from 'react';

export function EmptyIntakeTab() {
  return (
    <div className={styles.emptyTabContent}>
      <h1>No intake form submitted</h1>
    </div>
  );
}
