import { Fields } from '@ginger.io/react-use-form';
import { Field } from '@ginger.io/react-use-form/src/types';
import { FormControl, FormControlLabel, FormGroup } from '@mui/material';
import Checkbox, { CheckboxVariant } from 'shared-components/checkbox/Checkbox';
import { SimplePopover } from 'shared-components/popover/SimplePopover';
import React from 'react';

import styles from './EventTypeFilter.module.scss';
import { EventType } from './types';

export type EventTypeFilterForm = Record<EventType, boolean>;

export function EventTypeFilter(props: EventTypeFilterProps) {
  const { fields, onOpen, onFilter } = props;

  return (
    <SimplePopover ariaId="eventTypeFilter" buttonText="Filter" onOpen={onOpen}>
      <FormControl component="fieldset" className="">
        <FormGroup id={styles.eventTypeGroup}>
          <EventTypeCheckBoxControl
            eventType={EventType.EVENT}
            field={fields.Event}
            onFilter={onFilter}
          />
          <EventTypeCheckBoxControl
            eventType={EventType.APPOINTMENT}
            field={fields.Appointment}
            onFilter={onFilter}
          />
          <EventTypeCheckBoxControl
            eventType={EventType.AVAILABILITY}
            field={fields.Availability}
            onFilter={onFilter}
          />
          <EventTypeCheckBoxControl
            eventType={EventType.MEETING}
            field={fields.Meeting}
            onFilter={onFilter}
          />
        </FormGroup>
      </FormControl>
    </SimplePopover>
  );
}

function getCheckboxVariant(eventType: EventType) {
  switch (eventType) {
    case EventType.APPOINTMENT:
      return CheckboxVariant.STEEL_BLUE;
    case EventType.AVAILABILITY:
      return CheckboxVariant.TUMERIC;
    case EventType.MEETING:
      return CheckboxVariant.DARK_GREY;
    default:
      return CheckboxVariant.PINE;
  }
}

type EventTypeCheckBoxProps = {
  field: Field<boolean>;
  eventType: EventType;
  onFilter?: (eventType: EventType, checked: boolean) => void;
};

function EventTypeCheckBoxControl({
  field,
  eventType,
  onFilter,
}: EventTypeCheckBoxProps) {
  return (
    <FormControlLabel
      className={styles.label}
      label={eventType}
      control={
        <Checkbox
          size="medium"
          checked={field.value}
          name={eventType}
          onChange={(e) => {
            if (onFilter) onFilter(eventType, e.target.checked);
            field.setValue(e.target.checked);
          }}
          variant={getCheckboxVariant(eventType)}
        />
      }
    />
  );
}

export type EventTypeFilterProps = {
  fields: Fields<EventTypeFilterForm>;
  onOpen?: () => void;
  onFilter?: (eventType: EventType, checked: boolean) => void;
};
