import { TeenIntakeV2 } from 'app/patients/tabs/intake/dependent-intake/TeenIntakeV2';
import { DependentIntakeTab } from 'app/patients/tabs/intake/DependentIntakeTab';
import {
  IntakeTabV2,
  IntakeTabV2WithDeprecatedResolver,
} from 'app/patients/tabs/intake/IntakeTabV2';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import React from 'react';

export function IntakeTabView({
  memberId,
  pathPrefix,
  isMinorDependent,
}: {
  memberId: string;
  pathPrefix: string;
  isMinorDependent: boolean;
}) {
  const { enable_teen_intake_tab_v2 } = useFeatureFlags().transientFeatureFlags;
  if (enable_teen_intake_tab_v2) {
    if (isMinorDependent) {
      return <TeenIntakeV2 pathPrefix={pathPrefix} memberId={memberId} />;
    }
    return <IntakeTabV2 pathPrefix={pathPrefix} memberId={memberId} />;
  }
  if (isMinorDependent) {
    return <DependentIntakeTab patientId={memberId} />;
  }
  return <IntakeTabV2WithDeprecatedResolver memberId={memberId} />;
}
