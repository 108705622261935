import styles from 'app/patients/tabs/surveys/SurveysV3/ScoreDetails.module.scss';
import { classNameCombiner } from 'utils';
import React, { ElementType } from 'react';

type Props = {
  scoreLabels: string[];
  Component: ElementType<{ value: number }>;
  questions: Record<string, any> | undefined;
  answers: Record<string, any> | undefined;
  title?: string;
};

export function ScoreDetails({
  title,
  Component,
  questions,
  answers,
  scoreLabels,
}: Readonly<Props>) {
  let body;
  if (questions) {
    body = (
      <>
        {title && (
          <div className={classNameCombiner([styles.textLabel, styles.title])}>
            {title}
          </div>
        )}
        {Object.entries(questions)
          .filter(([key]) => key !== '__typename')
          .map(([key, question]) => {
            const answer = answers?.[key];
            return (
              <div key={key} className={styles.item}>
                {answer !== undefined && (
                  <div className={styles.dot}>
                    <Component value={answer as number} />
                  </div>
                )}
                <span className={styles.textLabel}>{question ?? ''}</span>
              </div>
            );
          })}
      </>
    );
  }

  return (
    <div className={styles.body}>
      <div className={styles.header}>
        {scoreLabels.map((label, index) => (
          <div key={label}>
            <p>{label}</p>
            <Component value={index} />
          </div>
        ))}
      </div>
      {body}
    </div>
  );
}
