import SelfCareIcon from 'assets/insights/Asymptomatic.svg';
import FocusIcon from 'assets/insights/Cognitive-Focus.svg';
import ConcentrationIcon from 'assets/insights/Concentration.svg';
import ConfidenceIcon from 'assets/insights/Confidence.svg';
import ControlIcon from 'assets/insights/Control.svg';
import DeterminationIcon from 'assets/insights/Determination.svg';
import EatingHabits from 'assets/insights/Eating-Habits.svg';
import EnergyIcon from 'assets/insights/Energy.svg';
import EnjoymentIcon from 'assets/insights/Enjoyment.svg';
import FearfulnessIcon from 'assets/insights/Fearfulness.svg';
import FailureIcon from 'assets/insights/Feelings-of-Failure.svg';
import FlexibilityIcon from 'assets/insights/Flexibility.svg';
import GritIcon from 'assets/insights/Grit.svg';
import HandlingChallengesIcon from 'assets/insights/Handling Challenges.svg';
import IrritabilityIcon from 'assets/insights/Irritation.svg';
import MoodIcon from 'assets/insights/Mood.svg';
import NervousnessIcon from 'assets/insights/Nervousness.svg';
import PerseveranceIcon from 'assets/insights/Perseverance.svg';
import PersonalGrowthIcon from 'assets/insights/Personal Growth.svg';
import PositivityIcon from 'assets/insights/Positivity.svg';
import RelaxationIcon from 'assets/insights/Relaxation.svg';
import RestlessnessIcon from 'assets/insights/Restlessness.svg';
import SelfHarmIcon from 'assets/insights/Self-Harm.svg';
import RelianceIcon from 'assets/insights/Self-reliance.svg';
import SleepHabitsIcon from 'assets/insights/Sleep-Patterns.svg';
import AdaptabilityIcon from 'assets/insights/summary/Adaptability.svg';
import DepressionAnxietyIcon from 'assets/insights/summary/Depression-Anxiety.svg';
import SuicideRiskIcon from 'assets/insights/summary/Suicide-Risk.svg';
import WeatheringEmotionsIcon from 'assets/insights/Weathering Emotions.svg';
import WorryIcon from 'assets/insights/Worry.svg';
import WarningIcon from 'assets/warning-icon.svg';

export const titleImageMapping: Record<string, any> = {
  'Activity Level': RestlessnessIcon,
  Concentration: ConcentrationIcon,
  'Eating Habits': EatingHabits,
  Energy: EnergyIcon,
  Enjoyment: EnjoymentIcon,
  'Feeling of Failure': FailureIcon,
  Fearfulness: FearfulnessIcon,
  Mood: MoodIcon,
  Flexibility: FlexibilityIcon,
  Irritability: IrritabilityIcon,
  Nervousness: NervousnessIcon,
  'Self-harm': SelfHarmIcon,
  'Sense of Control': ControlIcon,
  'Sleep Habits': SleepHabitsIcon,
  Confidence: ConfidenceIcon,
  Worry: WorryIcon,
  Determination: DeterminationIcon,
  Focus: FocusIcon,
  Perseverance: PerseveranceIcon,
  Positivity: PositivityIcon,
  Grit: GritIcon,
  'Sense of Relaxation': RelaxationIcon,
  'Handling Challenges': HandlingChallengesIcon,
  MILD: DepressionAnxietyIcon,
  Restlessness: RestlessnessIcon,
  HIGH: AdaptabilityIcon,
  LOW: WarningIcon,
  MEDIUM: AdaptabilityIcon,
  MINIMAL: DepressionAnxietyIcon,

  'Personal Growth': PersonalGrowthIcon,
  'Self-care': SelfCareIcon,
  'Self-reliance': RelianceIcon,
  MODERATE: WarningIcon,
  MODERATELY_SEVERE: WarningIcon,
  Resilience: HandlingChallengesIcon,
  SEVERE: WarningIcon,
  'Suicide-Risk': SuicideRiskIcon,
  'Weathering Emotions': WeatheringEmotionsIcon,
  VERY_HIGH: AdaptabilityIcon,
};

export const PSS_SCORE_LABEL = [
  'Never',
  'Almost never',
  'Sometimes',
  'Fairly often',
  'Very often',
];

export const PHQ_GAD_SCORE_LABEL = [
  'Not at all',
  'Several days',
  '> Half the days',
  'Nearly every day',
];

export const CSSRS_SCORE_LABEL = [
  'No risk',
  'Low risk',
  'Moderate risk',
  'High risk',
];

export const RISC_SCORE_LABEL = [
  'Not true at all',
  'Rarely true',
  'Sometimes true',
  'Often true',
  'True nearly all the time',
];

export const PHQ9MAX = 27;
export const GAD7MAX = 21;
export const RISC10MAX = 40;
export const PSSMAX = 40;
export const CSSRSMAX = 3;
