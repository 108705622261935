import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useDispatch } from 'app/state';
import { setSessionTimes } from 'app/state/features/scheduler/schedulerSlice';
import React, { useState } from 'react';

import styles from './AvailabilityList.module.scss';
import { Availability } from './types';
import { generateTimeSlots, getUniqueTimeSlots } from './utils';

interface AvailabilityListProps {
  availabilities: Availability[];
  coachTimezone: string;
  memberTimezone: string;
}

const AvailabilityList = ({
  availabilities,
  coachTimezone,
  memberTimezone,
}: AvailabilityListProps) => {
  const dispatch = useDispatch();
  const [selectedTime, setSelectedTime] = useState<string>('');

  const selectStartTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    const startTime = event.target.value;
    setSelectedTime(startTime);
    dispatch(setSessionTimes({ startTime }));
  };

  const timeSlots = getUniqueTimeSlots(
    availabilities.flatMap((availability) =>
      generateTimeSlots({
        coachTimezone,
        endTime: availability.endTime,
        memberTimezone,
        startTime: availability.startTime,
      }),
    ),
  );

  return (
    <FormControl
      classes={{ root: styles.pillList }}
      data-testid="availability-list-time-slots"
    >
      <RadioGroup
        aria-labelledby="availability-list-label"
        name="availability-list-radio-group"
        value={selectedTime}
        onChange={selectStartTime}
      >
        {timeSlots.map(
          (
            {
              id,
              displayCoachStartTime,
              coachTimezone: timeSlotCoachTimezone,
              displayMemberStartTime,
              memberTimezone: timeSlotMemberTimezone,
            },
            index,
          ) => (
            <FormControlLabel
              key={id}
              value={id}
              control={
                <Radio
                  classes={{ checked: styles.checked, root: styles.radioInput }}
                />
              }
              label={`${displayCoachStartTime} ${timeSlotCoachTimezone} | ${displayMemberStartTime} ${timeSlotMemberTimezone}`}
              data-testid={`availability-list-item-${index}`}
              classes={{ label: styles.pillLabel, root: styles.pill }}
              labelPlacement="start"
            />
          ),
        )}
      </RadioGroup>
    </FormControl>
  );
};

export default AvailabilityList;
