import { field, stringField, useForm } from '@ginger.io/react-use-form';
import { KeyGenerator } from '@ginger.io/vault-core/dist/crypto';
import { AdditionalDemographicInfo_Version } from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/AdditionalDemographicInfo';
import {
  Gender,
  GenderIdentification,
  UserRole,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { MemberProfileFragment as MemberProfileData } from '@headspace/carehub-graphql/dist/member-chart-cards/member-profile/generated/MemberProfileFragment';
import {
  CardField,
  CardFieldAndGridCell,
} from 'app/member-chart-cards/card-field/CardField';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import Titles from 'app/member-chart-cards/constants/cards-titles';
import { CardTextArea } from 'app/member-chart-cards/text-area/CardTextArea';
import {
  DEMOGRAPHIC_INFO_PLACEHOLDER,
  TEXT_AREA_PLACEHOLDER_CLINICIAN,
} from 'app/member-chart-cards/text-area/constants';
import { useAppState } from 'app/state';
import { renderSliceStateResult } from 'app/state/status/RenderSliceState';
import { GraphQLProps } from 'app/typeUtils';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card } from 'shared-components/Card';
import styles from 'shared-components/Card/Card.module.scss';
import {
  CardColumnGaps,
  CardGrid,
  CardRowGaps,
  NumberOfCardColumns,
} from 'shared-components/grid-layout/CardGrid.module';
import { GridCell } from 'shared-components/grid-layout/GridCell';
import {
  formatAge,
  formatDateOfBirth,
  formatEthnicity,
  formatGender,
  formatName,
  formatPronouns,
  toSentenceCase,
} from 'utils';
import { formatTimestampWithTz } from 'utils/dateTime';

import {
  GridAreaOptions,
  gridTemplate,
  gridTemplateAdjusted,
} from './constants/constants';
import {
  ProfileCardFields,
  VersionedAdditionalDemographicInfo,
} from './types/memberProfleTypes';
import { useAdditionalDemographicInfo } from './useAdditionalDemographicInfo';

function _formatGender(
  genderIdentification?: (GenderIdentification | null)[] | null,
  gender?: Gender | null,
) {
  if (genderIdentification) {
    return formatGender(genderIdentification, true);
  }

  if (gender) {
    return toSentenceCase(gender);
  }

  return null;
}

export const MemberProfile = ({
  member,
  generateId,
  keyGenerator,
  'data-testid': testId = 'memberProfileCard',
}: {
  member: GraphQLProps<MemberProfileData>;
  generateId?: () => string;
  keyGenerator?: KeyGenerator;
  'data-testid'?: string;
}): React.ReactElement | null => {
  const {
    id: memberId,
    preferredFirstName,
    preferredLastName,
    ethnicities,
    gender,
    genderIdentification,
    pronouns,
    dateOfBirth: officialDOB,
  } = member;

  const { timezone, isCoach } = useAppState(({ user: { timezone, role } }) => ({
    isCoach: role === UserRole.COACH || role === UserRole.COACH_SUPERVISOR,
    timezone: timezone!,
  }));

  const {
    enable_profile_card_improvements,
  } = useFeatureFlags().transientFeatureFlags;

  const {
    additionalDemographicInfo,
    saveAdditionalDemographicInfo,
  } = useAdditionalDemographicInfo(memberId, {
    generateId,
    keyGenerator,
  });

  const dateOfBirth = officialDOB;

  const profileFields: CardFieldAndGridCell[] = [
    {
      gridArea: GridAreaOptions.OVERVIEW,
      label: ProfileCardFields.OVERVIEW,
      labelForAnalytics: `Member Profile: ${ProfileCardFields.OVERVIEW}`,
      value: '',
    },
    {
      gridArea: GridAreaOptions.NAME,
      label: ProfileCardFields.NAME,
      labelForAnalytics: `Member Profile: ${ProfileCardFields.NAME}`,
      value: formatName(preferredFirstName, preferredLastName),
    },
    {
      gridArea: GridAreaOptions.GENDER,
      label: ProfileCardFields.GENDER,
      labelForAnalytics: `Member Profile: ${ProfileCardFields.GENDER}`,
      value: _formatGender(genderIdentification, gender),
    },
    {
      gridArea: GridAreaOptions.PRONOUNS,
      label: ProfileCardFields.PRONOUNS,
      labelForAnalytics: `Member Profile: ${ProfileCardFields.PRONOUNS}`,
      value: formatPronouns(pronouns, true),
    },
    {
      gridArea: GridAreaOptions.RACE,
      label: ProfileCardFields.RACE,
      labelForAnalytics: `Member Profile: ${ProfileCardFields.RACE}`,
      value: formatEthnicity(ethnicities, true),
    },
    {
      gridArea: GridAreaOptions.BIRTHDATE,
      label: ProfileCardFields.BIRTHDATE,
      labelForAnalytics: `Member Profile: ${ProfileCardFields.BIRTHDATE}`,
      value: formatDateOfBirth(dateOfBirth),
    },
    {
      gridArea: GridAreaOptions.AGE,
      label: ProfileCardFields.AGE,
      labelForAnalytics: `Member Profile: ${ProfileCardFields.AGE}`,
      value: formatAge(dateOfBirth),
    },
  ];

  return (
    <Card data-testid={testId} boxTitle={Titles.MEMBER_PROFILE_TITLE}>
      <div className={styles.cardSection}>
        {renderSliceStateResult(additionalDemographicInfo, () => (
          <CardGrid
            rowGap={CardRowGaps.STANDARD}
            columnGap={CardColumnGaps.STANDARD}
            template={
              enable_profile_card_improvements
                ? gridTemplateAdjusted
                : gridTemplate
            }
            numberOfColumns={NumberOfCardColumns.TWO}
          >
            {profileFields.map((field) =>
              field.label === ProfileCardFields.OVERVIEW ? (
                <GridCell key={field.label} gridArea={field.gridArea}>
                  <MemberProfileForm
                    label={field.label}
                    labelForAnalytics={field.labelForAnalytics}
                    memberId={memberId}
                    timezone={timezone}
                    tooltip={additionalDemographicInfo.data?.tooltipText ?? ''}
                    value={additionalDemographicInfo.data?.value ?? ''}
                    sourceVersion={
                      additionalDemographicInfo.data?.sourceVersion ?? ''
                    }
                    isCoach={isCoach}
                    onUpdate={async (input) => {
                      await saveAdditionalDemographicInfo(input);
                    }}
                  />
                </GridCell>
              ) : (
                <GridCell key={field.label} gridArea={field.gridArea}>
                  <CardField field={field} />
                </GridCell>
              ),
            )}
          </CardGrid>
        ))}
      </div>
    </Card>
  );
};

function MemberProfileForm(props: {
  label: string;
  labelForAnalytics: string;
  memberId: string;
  sourceVersion: string;
  timezone: string;
  tooltip: string;
  value: string;
  isCoach?: boolean;
  onUpdate: (data: VersionedAdditionalDemographicInfo) => Promise<void>;
}) {
  const {
    label,
    labelForAnalytics,
    timezone,
    value,
    tooltip,
    memberId,
    sourceVersion,
    onUpdate,
    isCoach = false,
  } = props;
  const [tooltipText, setTooltipText] = useState('');

  const { fields, getValue, isTouched, validate, reset } = useForm<
    VersionedAdditionalDemographicInfo
  >({
    memberId: stringField({ default: memberId, rules: [] }),
    source: stringField({ default: 'Member Profile > Additional Info' }),
    sourceVersion: stringField({ default: sourceVersion, rules: [] }),
    textValue: stringField({ default: value, rules: [] }),
    version: field<AdditionalDemographicInfo_Version>({
      default: AdditionalDemographicInfo_Version.v0,
      rules: [],
    }),
  });

  useEffect(() => {
    setTooltipText(tooltip);
  }, [tooltip]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedAt = formatTimestampWithTz(moment().format(), timezone);
    // This is hard-coded to say "You" to prevent the Tooltip from saying
    // "[previousUpdatedAt] • [potentially someone else] last updated" when the
    // user has not yet refreshed the page to fetch the updated Vault updatedBy value
    const tooltipText = `${updatedAt} • You last updated`;
    setTooltipText(tooltipText);
    fields.textValue.setValue(e.target.value);
    fields.sourceVersion?.setValue(sourceVersion);
  };

  const onBlur = async () => {
    if (isTouched && (await validate())) {
      const updateValue = getValue();
      await onUpdate(updateValue);
      reset(updateValue);
    }
  };

  return (
    <>
      <FieldLabel labelText={label} />
      <CardTextArea
        data-testid="card-text-area"
        tooltipEventLabel={labelForAnalytics}
        tooltipText={tooltipText ?? ''}
        value={fields.textValue.value ?? ''}
        placeholder={
          isCoach
            ? DEMOGRAPHIC_INFO_PLACEHOLDER
            : TEXT_AREA_PLACEHOLDER_CLINICIAN
        }
        readOnly={!isCoach}
        onChange={onChangeHandler}
        onBlur={onBlur}
      />
    </>
  );
}
