import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import React from 'react';
import { classNameCombiner } from 'utils';

import styles from './ButtonAdd.module.scss';

export type Props = {
  'data-testid': string;
  label: string;
  disabled?: boolean;
  onClick: () => void;
  color?: 'orange' | 'blue';
  showLine?: boolean;
};

export function ButtonAdd(props: Props) {
  const {
    'data-testid': dataTestId,
    label,
    disabled,
    onClick,
    color = 'orange',
    showLine = true,
  } = props;

  return (
    <div className={styles.container}>
      <button
        disabled={disabled}
        id={dataTestId}
        className={classNameCombiner([styles.button_add, styles[color]])}
        onClick={onClick}
      >
        <div className={styles.button_add__label}>
          <PlusIcon className={styles.button_add__icon} />
          {label}
        </div>
      </button>
      {showLine && (
        <div className={styles.line}>
          <hr className={styles[`border_${color}`]} />
        </div>
      )}
    </div>
  );
}
