import Labels from 'app/notes-ui/strings/en.json';
import { ILogger } from 'app/state/log/Logger';

import { EnumOption, ProtobufCodeSet, ProtobufEnum } from '../types';
import { NotePDFBuilder } from './NotePDFBuilder';

function getDiagnosisCodes(
  diagnosisCodesOptions: EnumOption[],
  codes: number[],
  logger: ILogger,
) {
  const options = diagnosisCodesOptions.filter((o) => codes.includes(o.value));

  if (options.length !== codes.length) {
    const codesFound = options.map((v) => v.value);
    const codesNotFound = codes.filter((v) => !codesFound.includes(v));
    if (codesNotFound.length > 0) {
      logger.error(
        new Error(`Diagnosis codes not found: ${codesNotFound.join(', ')}`),
      );
    }
  }

  return options.map((o) => o.name.replaceAll('\t', ' '));
}

export function buildAssessmentCodeSets(
  pdfBuilder: NotePDFBuilder,
  cptCodeEnum: ProtobufEnum,
  diagnosisCodesOptions: EnumOption[],
  codeSets: ProtobufCodeSet[],
  cptCode: number,
  icd10Codes: number[],
  logger: ILogger,
) {
  if (
    codeSets?.length > 0 &&
    codeSets[0]?.cptCode > 0 &&
    codeSets[0]?.icd10Codes?.length > 0
  ) {
    for (let i = 0; i < codeSets.length; i++) {
      const codeSet = codeSets[i];

      pdfBuilder = pdfBuilder.subsection(`Code Set ${i + 1}`, () => {
        pdfBuilder
          .radioItemAnswer(cptCodeEnum, Labels.CPT_CODE, codeSet.cptCode)
          .padding()
          .stringGroup(
            Labels.ICD10_CODES,
            getDiagnosisCodes(
              diagnosisCodesOptions,
              codeSet.icd10Codes,
              logger,
            ),
            'No ICD10 codes',
          );
      });
    }
    return pdfBuilder;
  } else {
    return pdfBuilder
      .radioItemAnswer(cptCodeEnum, Labels.CPT_CODE, cptCode)
      .padding()
      .stringGroup(
        Labels.ICD10_CODES,
        getDiagnosisCodes(diagnosisCodesOptions, icd10Codes, logger),
        'No ICD10 codes',
      );
  }
}
