export interface TimedSend {
  message: string;
  timeout: NodeJS.Timeout | null;
}

export interface CountDown {
  seconds: number;
  intervalId: NodeJS.Timer | null;
}

export type State = {
  // a key is a channelId
  timedSends: Record<string, TimedSend>;
  countDownMap: Record<string, CountDown>;
};

export const getInitialTimedSendState = () => {
  return {
    countDownMap: {},
    timedSends: {},
  };
};
