import { Fields } from '@ginger.io/react-use-form/src/types';
import { SafetyConcern } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyConcern';
import { SafetyIntake } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyIntake';
import {
  Safety,
  Safety as SafetyProgress,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyProgress';
import { InitialStateChecker as DomesticViolenceChecker } from 'app/notes-ui/shared/safety-progress/concerns/DomesticViolenceRisk';
import { InitialStateChecker as EatingDisorderChecker } from 'app/notes-ui/shared/safety-progress/concerns/EatingDisorderRisk';
import { InitialStateChecker as HighSubstanceChecker } from 'app/notes-ui/shared/safety-progress/concerns/HighSubstanceRisk';
import { InitialStateChecker as HomicideRiskChecker } from 'app/notes-ui/shared/safety-progress/concerns/HomicideRisk';
import { InitialStateChecker as OtherConcernsChecker } from 'app/notes-ui/shared/safety-progress/concerns/OtherConcerns';
import { InitialStateChecker as SelfHarmChecker } from 'app/notes-ui/shared/safety-progress/concerns/SelfHarmRisk';
import { InitialStateChecker as SuicideRiskChecker } from 'app/notes-ui/shared/safety-progress/concerns/SuicideRisk';
import { InitialStateChecker as VulnerablePopulationsAbuseChecker } from 'app/notes-ui/shared/safety-progress/concerns/VulnerablePopulationsAbuseRisk';

import { SafetyConcerns } from './safety-progress/constants';

export const SafetyConcernMap = {
  [SafetyConcern.abuse_of_vulnerable_risk]:
    SafetyConcerns.ABUSE_OF_VULNERABLE_RISK,
  [SafetyConcern.self_harm_risk]: SafetyConcerns.SELF_HARM_RISK,
  [SafetyConcern.homicide_risk]: SafetyConcerns.HOMICIDE_RISK,
  [SafetyConcern.domestic_violence_risk]: SafetyConcerns.DOMESTIC_VIOLENCE_RISK,
  [SafetyConcern.substance_use_risk]: SafetyConcerns.SUBSTANCE_USE_RISK,
  [SafetyConcern.eating_disorder_risk]: SafetyConcerns.EATING_DISORDER_RISK,
  [SafetyConcern.other_risks]: SafetyConcerns.OTHER_RISKS,
  [SafetyConcern.suicide_risk]: SafetyConcerns.SUICIDE_RISK,
  [SafetyConcern.undefined_concern]: undefined,
  [SafetyConcern.UNRECOGNIZED]: undefined,
};

export const loadSafetyConcernComponents = (
  note: SafetyIntake | SafetyProgress,
): SafetyConcern[] => {
  // This method is required in order to display the correct safety concerns
  // for older note versions that did not have the selectedConcerns field.

  if (note.selectedConcerns && note.selectedConcerns.length > 0) {
    return note.selectedConcerns;
  }

  const safetyConcerns = [];

  if (SuicideRiskChecker(note)) safetyConcerns.push(SafetyConcern.suicide_risk);
  if (SelfHarmChecker(note)) safetyConcerns.push(SafetyConcern.self_harm_risk);
  if (HomicideRiskChecker(note))
    safetyConcerns.push(SafetyConcern.homicide_risk);
  if (DomesticViolenceChecker(note))
    safetyConcerns.push(SafetyConcern.domestic_violence_risk);
  if (HighSubstanceChecker(note))
    safetyConcerns.push(SafetyConcern.substance_use_risk);
  if (EatingDisorderChecker(note))
    safetyConcerns.push(SafetyConcern.eating_disorder_risk);
  if (VulnerablePopulationsAbuseChecker(note))
    safetyConcerns.push(SafetyConcern.abuse_of_vulnerable_risk);
  if (OtherConcernsChecker(note))
    safetyConcerns.push(SafetyConcern.other_risks);

  return safetyConcerns;
};

export const clearSuicideRiskFields = (fields: Fields<Safety>) => {
  fields.currentSuicidalIdeation?.thoughts.setValue(false);
  fields.currentSuicidalIdeation?.intent.setValue(false);
  fields.currentSuicidalIdeation?.plan.setValue(false);
  fields.currentSuicidalIdeation?.means.setValue(false);
  fields.currentSuicidalIdeation?.description.setValue('');
  fields.currentSuicidalIdeation?.hasPast.setValue(false);
  fields.currentSuicidalIdeation?.pastSummarization.setValue('');
};

export const clearSelfHarmFields = (fields: Fields<Safety>) => {
  fields.currentSelfInjury?.isPresent.setValue(false);
  fields.currentSelfInjury?.everSoughtMedicalAttentionAsAResult.setValue(false);
  fields.currentSelfInjury?.currentSummarization.setValue('');
  fields.currentSelfInjury?.hasPast.setValue(false);
  fields.currentSelfInjury?.pastEverSoughtMedicalAttentionAsAResult.setValue(
    false,
  );
  fields.currentSelfInjury?.pastSummarization.setValue('');
};

export const clearHomicideRiskFields = (fields: Fields<Safety>) => {
  fields.currentHomicidalIdeation?.thoughts.setValue(false);
  fields.currentHomicidalIdeation?.intent.setValue(false);
  fields.currentHomicidalIdeation?.plan.setValue(false);
  fields.currentHomicidalIdeation?.means.setValue(false);
  fields.currentHomicidalIdeation?.specificTargetsEntityOrLocationConsidered.setValue(
    false,
  );
  fields.currentHomicidalIdeation?.description.setValue('');
  fields.currentHomicidalIdeation?.hasPast.setValue(false);
  fields.currentHomicidalIdeation?.pastSummarization.setValue('');
};

export const clearDomesticViolenceFields = (fields: Fields<Safety>) => {
  fields.domesticViolenceRisk?.currentDescription.setValue('');
};

export const clearSubstanceUseFields = (fields: Fields<Safety>) => {
  fields.highSubstanceUse?.currentDescription.setValue('');
};

export const clearEatingDisorderFields = (fields: Fields<Safety>) => {
  fields.eatingDisorderRisk?.currentDescription.setValue('');
};

export const clearVulnerablePopulationsAbuseFields = (
  fields: Fields<Safety>,
) => {
  fields.vulnerablePopulationsAbuse?.currentDescription.setValue('');
};

export const clearOtherConcernsFields = (fields: Fields<Safety>) => {
  fields.otherConcerns?.currentDescription.setValue('');
};

export const clearAllSafetyFields = (fields: Fields<Safety>) => {
  clearSuicideRiskFields(fields);
  clearSelfHarmFields(fields);
  clearHomicideRiskFields(fields);
  clearDomesticViolenceFields(fields);
  clearSubstanceUseFields(fields);
  clearEatingDisorderFields(fields);
  clearVulnerablePopulationsAbuseFields(fields);
  clearOtherConcernsFields(fields);
  fields.selectedConcerns.setValue([]);
};
