import { Field } from '@ginger.io/react-use-form';
import TableCell from '@mui/material/TableCell';
import {
  NumberField,
  TextAreaField,
  TextBoxField,
} from 'app/notes-ui/forms/fields';
import React, { useEffect } from 'react';

type Props = {
  field: Field<any>;
  testId: string;
  isEditing: boolean;
  disabled: boolean;
  columnType: 'text' | 'number' | 'date' | 'textarea';
  placeholder: string | undefined;
  label: string;
  onChange: (value: any) => void;
};

export function PsychiatryHistoryCell(props: Props) {
  const {
    field,
    columnType,
    placeholder,
    testId,
    disabled,
    isEditing,
    onChange,
  } = props;

  // adding onChange as a dep causes an infinite loop - seems like a code smell
  useEffect(() => {
    if (field.touched) onChange(field.value);
  }, [field.value, field.error, field.touched]);

  let cell = <span />;
  if (!isEditing) {
    cell = <span data-testid={testId}>{field.value || '-'}</span>;
  } else if (columnType === 'text') {
    cell = (
      <TextBoxField
        disabled={disabled}
        placeholder={placeholder}
        label=""
        testId={testId}
        field={field}
      />
    );
  } else if (columnType === 'number') {
    cell = (
      <NumberField
        disabled={disabled}
        placeholder={placeholder}
        testId={testId}
        label=""
        field={field}
      />
    );
  } else if (columnType === 'textarea') {
    cell = (
      <TextAreaField
        disabled={disabled}
        placeholder={placeholder}
        testId={testId}
        label=""
        field={field}
      />
    );
  }
  return <TableCell>{cell}</TableCell>;
}
