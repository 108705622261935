import React, { ReactElement } from 'react';
import { TooltipWithRedux } from 'shared-components/tooltip/TooltipWithRedux';

import { TaskItemResponse } from './types';

export type Metadata = Omit<TaskItemResponse, 'item'>;

type Prop = {
  metadata: Metadata;
  showCreationDetails?: boolean;
  labelForAnalytics: string;
  children: ReactElement;
};

/**
 * Render a tooltip showing the updated and (optionally) creation time of the task item.
 *
 * It doesn't show details about the updated time if creation and updated time and author match.
 */

export function TasksTooltip(props: Readonly<Prop>) {
  const {
    metadata,
    labelForAnalytics,
    children,
    showCreationDetails = false,
  } = props;

  if (!metadata) return <>{children}</>;

  const { createdAt, createdBy, updatedAt, updatedBy } = metadata;
  const hasBeenUpdated =
    updatedAt &&
    updatedBy &&
    (updatedAt !== createdAt || updatedBy !== createdBy);

  if (!showCreationDetails && !hasBeenUpdated) return <>{children}</>;
  const creationDetails = <span>{`${createdAt} • ${createdBy} created`}</span>;
  const updatedDetails = (
    <span>{`${updatedAt} • ${updatedBy} last updated`}</span>
  );

  const title = (
    <span data-testid="task-tooltip" style={{ whiteSpace: 'pre-line' }}>
      {showCreationDetails && (
        <>
          {creationDetails}
          <br />
        </>
      )}
      {hasBeenUpdated && updatedDetails}
    </span>
  );

  return (
    <TooltipWithRedux title={title} labelForAnalytics={labelForAnalytics}>
      {children}
    </TooltipWithRedux>
  );
}
