import { FormHelperText } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import MatUISelect, {
  SelectProps as MuiSelectProps,
} from '@mui/material/Select';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import React, { useState } from 'react';

export type Option = { text: string; value: string };

export type SelectProps = {
  name: string;
  placeholder?: string;
  className?: string;
  options: Option[];
  variant?: MuiSelectProps['variant'];
  value?: string;
  error?: string;
  onChange: (val: string) => void;
  'data-testid'?: string;
};

export function Select(props: SelectProps) {
  const [currentValue, setCurrentValue] = useState(props.value);

  const onOptionChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setCurrentValue(value);
    props.onChange(value);
  };
  return (
    <div data-testid={props['data-testid']}>
      <MatUISelect
        variant={props.variant ?? 'standard'}
        data-testid="mui-select"
        value={currentValue}
        className={props.className || ''}
        id={`select ${props.name}`}
        error={props.error !== undefined}
        onChange={onOptionChange}
      >
        {props.options.map((option: Option) => (
          <MenuItem
            data-testid={`id-${option.value}`}
            key={option.text}
            value={`${option.value}`}
          >
            {option.text}
          </MenuItem>
        ))}
      </MatUISelect>
      {props.error && (
        <FormHelperText error={true}>{props.error}</FormHelperText>
      )}
    </div>
  );
}
