import { useLogger } from 'app/state/log/useLogger';
import React, { ReactElement, useMemo } from 'react';
import Chart, { Props as ChartProps } from 'react-apexcharts';
import ReactDOM from 'react-dom';

import { SkyColor } from '../AppTheme';

export type Series = { name: string; data: number[] };

export type RenderTooltip = (props: {
  series: Series[];
  seriesIndex: number;
  dataPointIndex: number;
}) => ReactElement;

type LineChartOptions = {
  dataLabels: {
    enabled: boolean;
  };
  chart: {
    toolbar: boolean;
    zoom: boolean;
    events?: {
      dataPointMouseEnter?: Function;
      dataPointMouseLeave?: Function;
      dataPointSelection?: Function;
    };
  };
  stroke: {
    curve: 'straight' | 'smooth' | 'stepline';
  };

  grid?: {
    row: {
      colors: string[];
      opacity: number;
    };
  };

  xaxis: {
    categories: string[];
  };

  colors: string[];

  tooltip: {
    enabled: boolean;
    intersect: boolean;
    shared: boolean;
    custom: (args: {
      series: Series[];
      seriesIndex: number;
      dataPointIndex: number;
    }) => string; // HTML
  };

  markers: {
    size: number;
  };
};

export type XAxisOptions = {
  title?: string;
};

export type YAxisOptions = {
  min?: number;
  max?: number;
  tickAmount?: number;
  title?: string;
};

type Props = {
  labels: string[];
  data: Series[];
  width?: number | string; // e.g 60 | "60%"
  tooltip: RenderTooltip;
  onHover?: Function;
  onClick?: (dataPointIndex: number) => void;
  xaxisOptions?: XAxisOptions;
  yaxisOptions?: YAxisOptions;
  disableTooltip?: boolean;
  disableDataLabel?: boolean;
  'data-testid'?: string;
  /**
   * @description An optional array of color hex codes to use for the data points in the chart. The number of array elements must match the number of data points in the chart.
   * */
  dataLabelColors?: string[];
};

export function LineChart(props: Props) {
  const {
    data,
    labels,
    width,
    tooltip,
    onHover,
    onClick,
    xaxisOptions,
    yaxisOptions,
    dataLabelColors = [SkyColor],
  } = props;
  const { disableDataLabel = false, disableTooltip = false } = props;
  const logger = useLogger();
  const { min: yMin, max: yMax, tickAmount, title: yTitle } =
    yaxisOptions || {};
  const xTitle: string = xaxisOptions?.title || '';
  // if we are passing in distinct colors per data point the sizes should match.
  const dataLength = data.reduce((acc, curr) => acc + curr.data.length, 0);
  if (dataLabelColors.length > 1 && dataLabelColors.length !== dataLength) {
    logger.error(
      new Error(`LineChart: DataLabelColors length does not match data length`),
      { data, dataLabelColors },
    );
  }

  const options: LineChartOptions = useMemo(() => {
    return {
      chart: {
        events: {
          dataPointMouseEnter: () => (onHover ? onHover() : null),
          dataPointSelection: (
            _: MouseEvent,
            __: Record<string, any>,
            { dataPointIndex }: { dataPointIndex: number },
          ) => {
            return onClick ? onClick(dataPointIndex) : null;
          },
        },
        toolbar: false,
        zoom: false,
      },

      colors: [SkyColor],

      dataLabels: {
        distributed: dataLabelColors.length > 1,
        enabled: !disableDataLabel,
        style: {
          colors: dataLabelColors,
        },
      },

      markers: {
        size: 6,
      },

      plotOptions: {},

      stroke: {
        curve: 'straight',
      },

      tooltip: {
        custom: (args) => {
          const container = document.createElement('div');
          ReactDOM.render(tooltip(args), container);
          return container.innerHTML;
        },
        enabled: !disableTooltip,
        intersect: true,
        shared: false,
      },

      xaxis: {
        categories: labels,
        title: {
          text: xTitle,
        },
      },

      yaxis: {
        max: yMax,
        min: yMin,
        tickAmount,
        title: {
          text: yTitle || '',
        },
      },
    };
  }, [labels, tooltip, onHover, onClick, yMax, yMin, tickAmount]);

  // Apex charts will only render data points in a line chart if there are at least two data points
  // so as a workaround, in the case of 1 data point we use a scatter plot (to ensure the data point renders)
  // Note we don't care about 0 data points, as we'll draw nothing anyways
  const chartType = labels.length === 1 ? 'scatter' : 'line';

  const chartProps: ChartProps = {
    height: 350,
    options,
    series: data,
    type: chartType,
    width: width || '100%',
  };

  /** Apex charts does not work in the test environment due to us not using a real DOM.
   * So we "cheat" and use StubChart in the test env
   */
  if (process.env.NODE_ENV === 'test') {
    return <StubChart data-testid="apexChart" {...chartProps} />;
  }
  return <Chart data-testid={props['data-testid']} {...chartProps} />;
}

function StubChart(props: ChartProps) {
  return <div />;
}
