export const PDFLabels = {
  constitutionalLabels: {
    denies_constitutional_symptoms: 'Not Endorsing Constitutional symptoms',
    other: 'Other constitutional symptom',
  },
  depressionLabels: {
    current_hypervigilance_or_easy_startle:
      'Current hypervigilance or easy startle',
    delusional_thinking_observed: 'Delusions endorsed',
    denies_avh: 'Perceptual disturbance',
    denies_constant_worry: 'Constant or uncontrollable worry',
    denies_low_mood: 'Altered mood',
    low_appetite: 'Appetite changes',
    low_energy: 'Altered level of energy',
    paranoid_ideation: 'Paranoid thoughts',
    poor_sleep: 'Altered sleep',
    spontaneous_panic_attacks:
      'Spontaneous panic attacks or triggered anxiety attacks',
  },
  endocrineLabels: {
    denies_endocrine_symptoms: 'Not Endorsing Endocrine symptoms',
    other_endocrine: 'Other endocrine symptom',
  },
  hypomaniaLabels: {
    denies_any_decreased_need_for_sleep: 'Diminished need for sleep',
    grandiosity_lasting_one_week_or_longer: 'Grandiosity',
    restlessness: 'Distractibility',
    trouble_relaxing: 'Racing thoughts',
  },
  musculoskeletalLabels: {
    bone_pain: 'Pain',
    denies_musculoskeletal_symptoms: 'Not Endorsing Musculoskeletal symptoms',
    other_musculoskeletal: 'Other musculoskeletal symptom',
  },
  neurologicalLabels: {
    denies_neurological_symptoms: 'Not Endorsing Neurological symptoms',
    other_neurological: 'Other neurologic symptom',
  },
};
