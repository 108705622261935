import { VaultMasterKeys } from '@ginger.io/vault-core/dist/crypto/UserMasterKeySet';
import { CoachShiftStatusEnum, UserRole } from 'generated/globalTypes';

export type State = Readonly<{
  userId: string | null;
  vaultUserId: string | null;
  firstName: string | null;
  lastName: string | null;
  role: UserRole | null;
  timezone: string | null;
  clinicianId: string | null;
  loggedIntoVault: boolean;
  vaultAuthChallenge: string | null;
  vaultJWTToken: string | null;
  vaultMasterKeys: VaultMasterKeys | null;
  pubnubAuthKey: string | null;
  listenerId: string | null;
  coachinghubUsername: string | null;
  rpcToCoachChannelId: string | null;
  shiftStatus: CoachShiftStatusEnum | null;
  listenerUserId: string | null;
  showAuthorizationModal: boolean;
  isAcceptingDropinMembers: boolean;
}>;

export function getInitialState(state?: Partial<State>): State {
  return {
    clinicianId: null,
    coachinghubUsername: null,
    firstName: null,
    isAcceptingDropinMembers: false,
    lastName: null,
    listenerId: null,
    listenerUserId: null,
    loggedIntoVault: false,
    pubnubAuthKey: null,
    role: null,
    rpcToCoachChannelId: null,
    shiftStatus: null,
    showAuthorizationModal: false,
    timezone: null,
    userId: null,
    vaultAuthChallenge: null,
    vaultJWTToken: null,
    vaultMasterKeys: null,
    vaultUserId: null,
    ...state,
  };
}
