import { RiskType } from 'app/coach/coach-notes/CoachNotesTypes';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { Button } from 'shared-components/button/Button';
import { Dropdown } from 'shared-components/Dropdown';
import { Width } from 'types/StyleTypes';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import { classNameCombiner } from 'utils';
import React, { useState } from 'react';

export enum Placeholders {
  RISK = 'Select...',
}

type RiskOption = {
  name: string;
  value: RiskType;
};
const RISK_OPTIONS: RiskOption[] = [
  { name: 'Suicide Risk', value: RiskType.SUICIDE_RISK },
  { name: 'Self-Harm Risk', value: RiskType.SELF_HARM_RISK },
  { name: 'Homicide Risk', value: RiskType.HOMICIDE_RISK },
  { name: 'Domestic Violence Risk', value: RiskType.DOMESTIC_VIOLENCE_RISK },
  { name: 'Substance Use Risk', value: RiskType.SUBSTANCE_USE_RISK },
  { name: 'Eating Disorder Risk', value: RiskType.EATING_DISORDER_RISK },
  {
    name:
      'Abuse of Vulnerable Populations (child(ren), dependent adults, elderly)',
    value: RiskType.ABUSE_OF_VULNERABLE_POPULATIONS_RISK,
  },
  {
    name: 'Member May Not Be Appropriate for the Platform',
    value: RiskType.MEMBER_INAPPROPRIATE_FOR_PLATFORM_RISK,
  },
  {
    name:
      'Member Was Inappropriate with Coach (i.e. hostile language, sexual/romantic advances, etc.)',
    value: RiskType.MEMBER_INAPPROPRIATE_WITH_COACH_RISK,
  },
  { name: 'Other', value: RiskType.OTHER_RISK },
];

enum FieldLabels {
  RISK = 'Risk',
}

export function AddRiskDropDown({
  onSelectRisk,
  selectedRisks,
  inline,
  readOnly,
  enableDropDown,
}: {
  inline: boolean;
  readOnly: boolean;
  enableDropDown: boolean;
  onSelectRisk: (risk: RiskType) => void;
  selectedRisks: RiskType[];
}) {
  const isRiskSelectionPending = !inline && !selectedRisks.length;
  const [showDropdown, setShowRiskDropdown] = useState<boolean>(
    isRiskSelectionPending || enableDropDown,
  );
  const _onSelectRisk = (risk: RiskType) => {
    toggleDropdown();
    onSelectRisk(risk);
  };

  const toggleDropdown = () => setShowRiskDropdown(!showDropdown);
  const filteredRiskOptions = RISK_OPTIONS.filter((option) =>
    selectedRisks.length ? selectedRisks.indexOf(option.value) === -1 : true,
  );

  const hasInlineRisks = !inline && selectedRisks.length;
  const hasRemainingRiskOptions = filteredRiskOptions.length > 0;

  if (!hasRemainingRiskOptions || readOnly) return null;

  if (showDropdown) {
    return (
      <div
        className={
          !inline && selectedRisks.length ? styles.dropdownContainer : ''
        }
      >
        <FieldLabel
          className={classNameCombiner([
            styles.label,
            selectedRisks.length ? styles.withBorder : '',
          ])}
          labelText={FieldLabels.RISK}
        />
        <Dropdown
          dataTestId="select"
          placeholder={Placeholders.RISK}
          disabled={readOnly}
          selectClass={styles.dropdown}
          menuItemClass={styles.menuItem}
          options={filteredRiskOptions}
          onSelect={_onSelectRisk}
          width={Width.FULL}
          showSelectedValue={false}
          MenuProps={{
            PaperProps: {
              style: {
                maxWidth: '320px',
                width: 'auto',
              },
            },
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            transformOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
          }}
        />
      </div>
    );
  }
  return (
    <div className={styles.dropdownTitleField}>
      <Button
        className={classNameCombiner([
          styles.baseRiskButton,
          hasInlineRisks ? styles.addAdditionalRisk : '',
        ])}
        size="small"
        testId="inlineRisk"
        onClick={toggleDropdown}
      >
        + Risk
      </Button>
      <span
        className={classNameCombiner([styles.inlineBorder, styles.riskBorder])}
      />
    </div>
  );
}
