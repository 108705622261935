import React from 'react';
import { Input, InputProps } from 'shared-components/form-inputs/Input';
import { FormError } from 'shared-components/FormError';

export function TextInput(props: InputProps) {
  return (
    <>
      <Input
        value={props.value}
        name={props.name}
        type="text"
        variant={props.variant}
        onChange={props.onChange}
        disabled={props.disabled}
        placeholder={props.placeholder}
        className={props.className}
      />
      <FormError error={props.error} />
    </>
  );
}
