import { VaultItem_SchemaType } from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import { ItemState } from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/member-tasks/ItemState';
import { TasksProps } from 'app/member-chart-cards/tasks/Tasks';
import { Metadata } from 'app/member-chart-cards/tasks/TasksTooltip';
import { useLogger } from 'app/state/log/useLogger';
import { useDebounce } from 'hooks/useDebounce';
import { useSnackNotification } from 'hooks/useSnackNotification';
import { groupBy } from 'lodash';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { TaskItem, TodaysItem } from './types';

const DELAY = process.env.NODE_ENV !== 'test' ? 2000 : 0;

const itemStateLabelMap: Record<ItemState, string> = {
  [ItemState.checked]: 'Checked',
  [ItemState.unchecked]: 'Unchecked',
  [ItemState.not_applicable]: 'Not Applicable',
  [ItemState.undefined_state]: '',
  [ItemState.UNRECOGNIZED]: '',
};

const formatAmplitudeMetadata = (
  update: TaskItem,
  action: string,
  memberId?: string,
  includeItemId = false,
) => {
  const { state, schema, id } = update;
  const data: Record<string, any> = {
    action,
    checkboxState: itemStateLabelMap[state ?? ItemState.unchecked],
    memberId,
    source:
      schema ===
      VaultItem_SchemaType.vault_member_chart_member_tasks_followup_item
        ? 'Member Tasks > Follow-Up Item'
        : 'Member Tasks > Initial Consult Item',
  };
  if (includeItemId) {
    data.itemId = id;
  }
  return data;
};

export function useTasksState({
  memberId,
  followupItems,
  initialConsultItems,
  todaysTasks,
  createTask,
  updateTask,
  deleteTask,
  onCheck,
  generateId = () => uuidv4(),
}: TasksProps) {
  const logger = useLogger();
  const [activeInitialConsultItems, updateActiveInitialConsultItems] = useState<
    TaskItem[]
  >([]);
  const [activeFollowupItems, updateActiveFollowupItems] = useState<TaskItem[]>(
    [],
  );
  const [todaysItems, updateTodaysItems] = useState<TodaysItem[]>([]);
  const [itemMetadata, setItemMetadata] = useState<Record<string, Metadata>>(
    {},
  );
  const [checkAllState, setCheckAllState] = useState(ItemState.unchecked);
  const [
    updatingAllInitialConsultState,
    setUpdatingAllInitialConsultState,
  ] = useState(false);
  const [updatingState, setUpdatingState] = useState<boolean>(false);
  const [savingFollowup, setSavingFollowup] = useState<Record<string, boolean>>(
    {},
  );
  const [savingInitialConsult, setSavingInitialConsult] = useState<
    Record<string, boolean>
  >({});
  const [pendingRequests, setPendingRequests] = useState<
    Record<string, [ItemState, TaskItem]>
  >({});
  const [pendingTodaysRequests, setPendingTodaysRequests] = useState<
    Record<string, [ItemState, TodaysItem]>
  >({});
  const { showErrorNotification } = useSnackNotification();

  // the following useEffect are used to refresh local state
  // since TaskItem.id changes after we trigger a create vault item request
  useEffect(() => {
    if (updatingAllInitialConsultState || updatingState) return;
    const items: TaskItem[] = [];
    const states: Array<ItemState | undefined> = [];
    const metadata: Record<string, Metadata> = {};
    initialConsultItems.forEach(({ item, ...rest }) => {
      items.push(item);
      states.push(item.state);
      metadata[item.id] = rest;
    });

    let checkAllState: ItemState;
    if (states.some((state) => !state || state === ItemState.unchecked)) {
      checkAllState = ItemState.unchecked;
    } else if (states.some((_) => _ === ItemState.checked)) {
      checkAllState = ItemState.checked;
    } else {
      checkAllState = ItemState.not_applicable;
    }

    updateActiveInitialConsultItems(items);
    setItemMetadata((data) => ({ ...data, ...metadata }));
    setCheckAllState(checkAllState);
  }, [initialConsultItems, updatingAllInitialConsultState, updatingState]);

  useEffect(() => {
    if (updatingState) return;
    const items: TaskItem[] = [];
    const metadata: Record<string, Metadata> = {};
    followupItems.forEach(({ item, ...rest }) => {
      items.push(item);
      metadata[item.id] = rest;
    });
    updateActiveFollowupItems(items);
    setItemMetadata((data) => ({ ...data, ...metadata }));
  }, [followupItems, updatingState]);

  useEffect(() => {
    updateTodaysItems(todaysTasks ?? []);
  }, [todaysTasks]);

  const onCheckStateUpdate = useDebounce(
    async (pending: Record<string, [ItemState, TaskItem]>) => {
      setPendingRequests({});
      const followupTasks: Record<string, TaskItem> = {};
      const initialConsultTasks: Record<string, TaskItem> = {};
      const toUpdate: TaskItem[] = [];
      const toCreate: TaskItem[] = [];

      Object.values(pending).forEach(([state, task]) => {
        if (
          task.schema ===
          VaultItem_SchemaType.vault_member_chart_member_tasks_followup_item
        ) {
          followupTasks[task.id] = { ...task, state };
          toUpdate.push(followupTasks[task.id]);
          return setSavingFollowup((data) => ({ ...data, [task.id]: true }));
        }

        setSavingInitialConsult((data) => ({ ...data, [task.id]: true }));
        if (task.state === undefined) {
          initialConsultTasks[task.id] = { ...task, state };
          toCreate.push(initialConsultTasks[task.id]);
        } else {
          initialConsultTasks[task.id] = { ...task, state };
          toUpdate.push(initialConsultTasks[task.id]);
        }
      });

      updateActiveFollowupItems((items: TaskItem[]) =>
        items.map((_) => followupTasks[_.id] ?? _),
      );
      updateActiveInitialConsultItems((items: TaskItem[]) =>
        items.map((_) => initialConsultTasks[_.id] ?? _),
      );
      // send request to vault
      let createEventMetadata: Record<string, any> = {};
      let updateEventMetadata: Record<string, any> = {};
      if (toUpdate.length === 1) {
        updateEventMetadata = formatAmplitudeMetadata(
          toUpdate[0],
          'Update',
          memberId,
        );
      } else if (toUpdate.length > 0) {
        updateEventMetadata = {
          events: toUpdate.map((item) =>
            formatAmplitudeMetadata(item, 'Update', memberId, true),
          ),
        };
      }
      if (toCreate.length === 1) {
        createEventMetadata = formatAmplitudeMetadata(
          toCreate[0],
          'Create',
          memberId,
        );
      } else if (toCreate.length > 0) {
        createEventMetadata = {
          events: toUpdate.map((item) =>
            formatAmplitudeMetadata(item, 'Create', memberId, true),
          ),
        };
      }

      const response = await Promise.all([
        toUpdate.length > 0
          ? updateTask(toUpdate, { amplitudeMetadata: updateEventMetadata })
          : Promise.resolve([]),
        toCreate.length > 0
          ? createTask(toCreate, { amplitudeMetadata: createEventMetadata })
          : Promise.resolve([]),
      ]).finally(() => setUpdatingState(false));

      setSavingFollowup((data) => {
        const update = { ...data };
        response.flat().forEach((_) => delete update[_.id]);
        return update;
      });

      setSavingInitialConsult((data) => {
        const update = { ...data };
        response.flat().forEach((_) => delete update[_.id]);
        return update;
      });

      // Since the request failed revert state.
      const failedRequests = response.flat().filter((_) => !_.success);
      failedRequests.forEach((result) => {
        if (!pending[result.id]) return;
        const task = pending[result.id][1];
        if (
          task.schema ===
          VaultItem_SchemaType.vault_member_chart_member_tasks_followup_item
        ) {
          followupTasks[task.id] = task;
        } else {
          initialConsultTasks[result.id] = task;
        }
      });
      updateActiveFollowupItems((items: TaskItem[]) =>
        items.map((_) => followupTasks[_.id] ?? _),
      );
      updateActiveInitialConsultItems((items: TaskItem[]) =>
        items.map((_) => initialConsultTasks[_.id] ?? _),
      );
    },
    DELAY,
  );

  const onUpdateTodaysTask = useDebounce(
    async (pending: Record<string, [ItemState, TodaysItem]>) => {
      const itemsToUpdate = Object.values(
        pending,
      ).map(([checkboxState, item]) => ({ checkboxState, taskId: item.id }));
      updateTodaysItems((items) =>
        items.map(({ state, ...rest }) => ({
          ...rest,
          state: pending[rest.id]?.[0] ?? state,
        })),
      );
      const results = await onCheck(itemsToUpdate);

      const errors: { message: string; id: string }[] = [];
      const failedRequests = results.reduce((previousValue, currentValue) => {
        if (!currentValue.ok && currentValue.task!.id) {
          errors.push({
            id: currentValue.task!.id,
            message: currentValue.error
              ? currentValue.error
              : 'Unknown error occurred while updating task',
          });
          return { ...previousValue, [currentValue.task!.id]: true };
        }
        return previousValue;
      }, {} as Record<string, boolean>);
      if (errors.length > 0) {
        logger.error(
          new Error('useTasksState.onUpdateTodaysTask: Failed to update tasks'),
          { tasks: failedRequests },
        );
      }

      updateTodaysItems((items) =>
        items.map(({ state, id, ...item }) => ({
          ...item,
          id,
          state:
            pending[id] && failedRequests[id] ? pending[id][1].state : state,
        })),
      );
      errors.forEach((error) => showErrorNotification(error.message, true));
    },
    DELAY,
  );

  const unifyItemStates = useDebounce(
    async (state: ItemState, items: TaskItem[]): Promise<void> => {
      setUpdatingAllInitialConsultState(true);
      const toUpdate: TaskItem[] = [];
      const toCreate: TaskItem[] = [];

      items.forEach((item) => {
        if (item.state === undefined) {
          toCreate.push({ ...item, state });
        } else if (item.state !== state) {
          toUpdate.push({ ...item, state });
        }
      });

      setUpdatingAllInitialConsultState(true);

      const amplitudeMetadata = {
        action: 'Check All',
        checkboxState: itemStateLabelMap[state],
        memberId,
        source: 'Member Tasks > Initial Consult Item',
      };
      const results = await Promise.all([
        toUpdate.length > 0
          ? updateTask(toUpdate, {
              amplitudeMetadata: { ...amplitudeMetadata },
            })
          : Promise.resolve([]),
        toCreate.length > 0
          ? createTask(toCreate, {
              amplitudeMetadata: { ...amplitudeMetadata },
            })
          : Promise.resolve([]),
      ]).finally(() => setUpdatingAllInitialConsultState(false));

      const failedRequests = results.flat().filter(({ success }) => !success);

      // revert failed request to old state
      if (failedRequests.length > 0) {
        const mappedById = groupBy(failedRequests, (item) => item.id);
        updateActiveInitialConsultItems((prevState) => {
          return [
            ...prevState.map((item) => {
              if (mappedById[item.id]) {
                const state = items.find((_) => _.id === item.id)?.state;
                return { ...item, state };
              }
              return item;
            }),
          ];
        });
      }
    },
    DELAY,
  );

  const onCreateFollowup = async (label: string) => {
    const item: TaskItem = {
      id: generateId(),
      label,
      schema:
        VaultItem_SchemaType.vault_member_chart_member_tasks_followup_item,
      state: ItemState.undefined_state,
    };
    updateActiveFollowupItems((activeFollowupItems: TaskItem[]) => [
      item,
      ...activeFollowupItems,
    ]);
    setSavingFollowup((data) => ({ ...data, [item.id]: true }));
    return createTask([item], {
      amplitudeMetadata: {
        action: 'Create',
        checkboxState: itemStateLabelMap[ItemState.unchecked],
        memberId,
        source: 'Member Tasks > Follow-Up Item',
      },
    }).finally(() =>
      setSavingFollowup((data) => {
        const updatedData = { ...data };
        delete updatedData[item.id];
        return updatedData;
      }),
    );
  };

  const onUpdateFollowup = async (
    label: string,
    item: TaskItem,
  ): Promise<boolean> => {
    const existingFollowupItem = activeFollowupItems.find(
      (_) => _.id === item.id,
    );
    if (!existingFollowupItem) throw new Error('Item not found');

    const { id, state, sourceVersion } = existingFollowupItem;
    const updatedItem: TaskItem = {
      id,
      label,
      schema:
        VaultItem_SchemaType.vault_member_chart_member_tasks_followup_item,
      sourceVersion,
      state,
    };
    updateActiveFollowupItems((activeFollowupItems: TaskItem[]) => {
      let updateItems;
      if (label === '') {
        updateItems = activeFollowupItems.filter((obj) => obj.id !== id);
      } else {
        updateItems = activeFollowupItems.map((obj) =>
          obj.id === id ? updatedItem : obj,
        );
      }
      return [...updateItems];
    });
    if (label === '') {
      return deleteTask(updatedItem, {
        amplitudeMetadata: {
          action: 'Delete',
          checkboxState: undefined,
          memberId,
          source: 'Member Tasks > Follow-Up Item',
        },
      });
    }
    setSavingFollowup((data) => ({ ...data, [item.id]: true }));
    const resp = await updateTask([updatedItem], {
      amplitudeMetadata: {
        action: 'Update',
        checkboxState:
          itemStateLabelMap[updatedItem.state ?? ItemState.unchecked],
        memberId,
        source: 'Member Tasks > Follow-Up Item',
      },
    }).finally(() =>
      setSavingFollowup((data) => {
        const updatedData = { ...data };
        delete updatedData[item.id];
        return updatedData;
      }),
    );
    return resp.length > 0 && resp[0].success;
  };

  return {
    activeFollowupItems,
    activeInitialConsultItems,
    checkAllState,
    isSavingFollowup: (id: string) => savingFollowup[id] ?? false,
    isSavingInitialConsult: (id: string) => savingInitialConsult[id] ?? false,
    metadata: itemMetadata,
    onCheckStateUpdate: async (state: ItemState, prevTaskItem: TaskItem) => {
      if (
        prevTaskItem.schema !==
        VaultItem_SchemaType.vault_member_chart_member_tasks_followup_item
      ) {
        setUpdatingState(true);
      }
      const updatePending: Record<string, [ItemState, TaskItem]> = {
        ...pendingRequests,
        [prevTaskItem.id]: [state, prevTaskItem],
      };
      setPendingRequests(updatePending);

      return onCheckStateUpdate(updatePending);
    },
    onCreateFollowup,
    onUpdateFollowup,
    onUpdateTodaysTask: (state: ItemState, prevTaskItem: TodaysItem) => {
      const updatePending: Record<string, [ItemState, TodaysItem]> = {
        ...pendingTodaysRequests,
        [prevTaskItem.id]: [state, prevTaskItem],
      };
      setPendingTodaysRequests(updatePending);

      return onUpdateTodaysTask(updatePending);
    },
    todaysTasks: todaysItems,
    unifyItemStates: (state: ItemState) => {
      const prev = [...activeInitialConsultItems];
      setCheckAllState(state);
      updateActiveInitialConsultItems((items) =>
        items.map((obj) => ({ ...obj, state })),
      );
      unifyItemStates(state, prev);
    },
    updatingAllInitialConsultState,
    updatingState,
  };
}
