import {
  FetchResult,
  MutationFunctionOptions,
  useApolloClient,
  useMutation,
} from '@apollo/client';
import { Container } from '@mui/material';
import { useAppState } from 'app/state';
import { useLogger } from 'app/state/log/useLogger';
import { updateTimezone } from 'app/state/user/actions';
import { UpdateClinicianPreferencesMutationInput } from 'generated/globalTypes';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useDispatch } from 'redux-reloaded';

import { ClinicianSettingsComponent } from './ClinicianSettingsComponent';
import { GetAuthenticatedClinician } from './generated/GetAuthenticatedClinician';
import {
  GetClinicianPreferences,
  GetClinicianPreferencesVariables,
} from './generated/GetClinicianPreferences';
import {
  UpdateClinicianPreferences,
  UpdateClinicianPreferencesVariables,
} from './generated/UpdateClinicianPreferences';

export interface Settings {
  timeZone: string;
  maxAppointments: number;
  calendarSyncEnabled: boolean;
}

interface ClinicianSettings {
  clinicianId: string;
  settings: Settings;
}

export const getAuthenticatedClinicianQuery = gql`
  query GetAuthenticatedClinician {
    getAuthenticatedClinician {
      id
      userId
      timezone
    }
  }
`;

export const getClinicianPreferencesQuery = gql`
  query GetClinicianPreferences($input: GetClinicianInput!) {
    getClinician(input: $input) {
      id
      user {
        preferences {
          timezone
        }
      }
      preferences {
        maxAppointmentsPerWeek
        calendarSyncEnabled
      }
    }
  }
`;

export const updateClinicianPreferencesQuery = gql`
  mutation UpdateClinicianPreferences(
    $input: UpdateClinicianPreferencesMutationInput!
  ) {
    updateClinicianPreferences(input: $input) {
      clinician {
        id
      }
    }
  }
`;

type MutationFunction = (
  options?: MutationFunctionOptions<
    UpdateClinicianPreferences,
    UpdateClinicianPreferencesVariables
  >,
) => Promise<FetchResult>;

function ClinicianSettingsScreen() {
  const { timezone } = useAppState((_) => _.user);
  const [clinicianSettings, setClinicianSettings] = useState<
    ClinicianSettings | undefined
  >(undefined);
  const [updateClinicianPrefs] = useMutation<
    UpdateClinicianPreferences,
    UpdateClinicianPreferencesVariables
  >(updateClinicianPreferencesQuery);
  const logger = useLogger();
  const dispatch = useDispatch();
  const apolloClient = useApolloClient();

  useEffect(() => {
    // The mounted true/false is to avoid having the async results within init() to come back after some time
    // and attempt to set the clinician state potentially after the component is unmounted for whatever reason.
    // Without it, React will throw a warning:
    //   Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory
    //   leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup
    //   function.
    let mounted = true;
    async function init() {
      const {
        data: { getAuthenticatedClinician: authenticatedClinician },
      } = await apolloClient.query<GetAuthenticatedClinician>({
        query: getAuthenticatedClinicianQuery,
      });
      const fallbackTimezone = authenticatedClinician.timezone;
      const { data } = await apolloClient.query<
        GetClinicianPreferences,
        GetClinicianPreferencesVariables
      >({
        query: getClinicianPreferencesQuery,
        variables: { input: { id: authenticatedClinician.id } },
      });
      const clinician = data.getClinician!;

      const clinicianTimezone = timezone ?? fallbackTimezone ?? '';
      const maxAppointmentsPerWeek =
        clinician.preferences?.maxAppointmentsPerWeek ?? 0;
      const calendarSyncEnabled =
        clinician.preferences?.calendarSyncEnabled ?? false;

      if (mounted) {
        setClinicianSettings({
          clinicianId: clinician.id,
          settings: {
            calendarSyncEnabled,
            maxAppointments: maxAppointmentsPerWeek,
            timeZone: clinicianTimezone,
          },
        });
      }
    }

    void init();
    return () => {
      mounted = false;
    };
  }, [apolloClient]);

  const onSaveClinicianSettings = (
    updateClinicianPrefs: MutationFunction,
  ) => async (clinicianId: string, settings: Settings) => {
    const input: UpdateClinicianPreferencesMutationInput = {
      calendarSyncEnabled: settings.calendarSyncEnabled,
      maxAppointmentsPerWeek: settings.maxAppointments,
      timezone: settings.timeZone,
    };
    const result = await updateClinicianPrefs({ variables: { input } });

    if (result.errors && result.errors.length > 0) {
      logger.error(
        new Error('ClinicianSettingsScreen::Cannot update preferences'),
        { errors: result.errors },
      );
    } else {
      dispatch(updateTimezone({ timezone: settings.timeZone }));
    }
  };

  if (clinicianSettings) {
    return (
      <ClinicianSettingsComponent
        clinicianId={clinicianSettings.clinicianId}
        initialSettings={clinicianSettings.settings}
        onSave={onSaveClinicianSettings(updateClinicianPrefs)}
      />
    );
  }
  return (
    <Container maxWidth="md">
      <div>Please wait. Loading clinician settings...</div>
    </Container>
  );
}

export default withRouter(ClinicianSettingsScreen);
