import MaterialButton from '@mui/material/Button';
import { classNameCombiner } from 'utils';
import React, { ReactNode, SyntheticEvent } from 'react';

import styles from './Button.module.scss';

export type Props = {
  className?: string;
  onClick?: (event?: SyntheticEvent) => void;
  children: any;
  disabled?: boolean;
  href?: string;
  download?: string;
  variant?: 'contained' | 'outlined';
  fullWidth?: boolean;
  size?: 'normal' | 'small' | 'medium';
  testId?: string;
  endIcon?: ReactNode;
  component?: React.ElementType;
  disableElevation?: boolean;
  disableRipple?: boolean;
};

export function Button(props: Props) {
  const {
    variant = 'contained',
    fullWidth = true,
    size = 'normal',
    component = 'button',
    className = '',
    disableElevation,
    disableRipple,
  } = props;

  return (
    <MaterialButton
      component={component}
      fullWidth={fullWidth}
      variant={variant}
      color="primary"
      href={props.href || ''}
      download={props.download}
      disabled={props.disabled}
      onClick={props.onClick}
      disableElevation={disableElevation}
      disableRipple={disableRipple}
      className={classNameCombiner([
        styles.button,
        variant === 'outlined' ? styles.outlined : '',
        size === 'small' ? styles.small : '',
        size === 'medium' ? styles.medium : '',
        className,
      ])}
      data-testid={props.testId}
      endIcon={props.endIcon}
    >
      {props.children}
    </MaterialButton>
  );
}
