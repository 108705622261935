import {
  field,
  FieldDefinitions,
  stringField,
} from '@ginger.io/react-use-form';
import { NoteType } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/NoteType';
import {
  TreatmentGoal,
  TreatmentGoal_GoalStatus,
  TreatmentGoal_GoalType,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/TreatmentGoal';
import {
  TreatmentGoals,
  TreatmentGoals_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/TreatmentGoals';

export const TreatmentGoalFieldDefs: FieldDefinitions<TreatmentGoal> = {
  actionPlan: stringField(),
  goalDetails: stringField(),
  goalStatus: field<TreatmentGoal_GoalStatus>({
    default: TreatmentGoal_GoalStatus.undefined_goal_status,
  }),
  goalStatusUpdateComment: stringField({ rules: [] }),
  goalType: field(),
  otherGoalStatusComment: stringField({
    rules: [
      (value: string, treatmentGoal: TreatmentGoal) => {
        if (
          treatmentGoal?.goalType === TreatmentGoal_GoalType.other &&
          !value
        ) {
          return 'Please enter a comment';
        }
      },
    ],
  }),
};

export const treatmentGoalsFieldDefs: FieldDefinitions<TreatmentGoals> = {
  appointmentEnd: stringField({ rules: [] }),
  appointmentId: stringField({ rules: [] }),
  appointmentStart: stringField({ rules: [] }),
  goal: field<TreatmentGoal[]>({ rules: [] }),
  noteType: field<NoteType>({ rules: [] }),
  version: field<TreatmentGoals_Version>({ rules: [] }),
};
