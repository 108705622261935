import React from 'react';
import { Button } from 'shared-components/button/Button';

import styles from './AppointmentScreen.module.scss';

export type AppointmentButtonsProps = {
  onSave: () => void;
  loading?: boolean;
  saveDisabled?: boolean;
};

export function AppointmentButtons({
  onSave,
  loading,
  saveDisabled,
}: AppointmentButtonsProps) {
  const label = loading ? 'Saving' : 'Save';

  return (
    <div className={styles.buttons}>
      <Button
        className={styles.save}
        size="small"
        testId="save"
        onClick={onSave}
        disabled={loading || saveDisabled}
      >
        {label}
      </Button>
    </div>
  );
}
