import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { AdditionalData } from 'app/services/sentry/SentryClientImpl';
import { RootState } from 'app/state/hooks/baseTypedHooks';
import { ILogger } from 'app/state/log/Logger';

import { RejectValue, RejectValueWithNullError } from './types';

// Middleware factory function
export const createRtkLoggerMiddleware = (
  logger: ILogger,
  cutOutError: (value: RejectValue) => RejectValueWithNullError,
) => {
  // middlewre that listens to every single action
  const rtkLoggerMiddleware: Middleware<{}, RootState> = (api) => (next) => (
    action,
  ) => {
    /*  we may adjust the contents of the middleware, though this check should remain the first thing to do,
    since the middleware is invoked on ANY action, so we need to run next action when we're not dealing with something
    that should be logged by our logger service */
    if (isRejectedWithValue(action)) {
      const additionalInfo = action.payload as RejectValue;
      const { error } = additionalInfo;
      const {
        meta: { requestId },
        type,
      } = action;
      const additionalData: AdditionalData = {
        ...additionalInfo,
        requestId,
        type,
      };
      logger.error(error, additionalData);
      const actionWithNullifiedError = {
        ...action,
        payload: cutOutError(action.payload),
      };
      return next(actionWithNullifiedError);
    }
    return next(action);
  };
  return rtkLoggerMiddleware;
};
