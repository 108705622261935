import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import MUIPopover from '@mui/material/Popover';
import React, { MouseEvent, useState } from 'react';

import { CSCAutoOutreachEnablePopup } from './CSCAutoOutreachEnablePopup';
import styles from './CSCAutoOutreachLaunchButton.module.scss';

export type Props = {
  memberId: string;
  clinicianId: string;
};

export function CSCAutoOutreachLaunchButton(props: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [cscOutreachpopoverOpen, setCscOutreachpopoverOpen] = useState(false);

  return (
    <Grid item={true}>
      <Button
        color="primary"
        variant="outlined"
        size="small"
        className={styles.launchBtn}
        endIcon={<SendOutlinedIcon className={styles.rotateIcon} />}
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
          setCscOutreachpopoverOpen(true);
        }}
        data-testid="outreachBtn"
      >
        Outreach
      </Button>
      <MUIPopover
        id="enable-outreach-popover"
        onClose={() => setCscOutreachpopoverOpen(false)}
        open={cscOutreachpopoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <CSCAutoOutreachEnablePopup
          memberId={props.memberId}
          clinicianId={props.clinicianId}
        />
      </MUIPopover>
    </Grid>
  );
}
