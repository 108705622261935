import { createAction } from 'redux-reloaded';
import { SnackbarNotification } from 'shared-components/Snackbar';

export enum Actions {
  SHOW_SNACK_BAR_NOTIFICATION = 'HIDE_OR_SHOW_SNACK_BAR_NOTIFICATION',
}

export const hideOrShowSnackNotification = createAction(
  Actions.SHOW_SNACK_BAR_NOTIFICATION,
  (notification: SnackbarNotification | null) => ({ notification }),
);
