import gql from 'graphql-tag';

export const publicKeyFragment = gql`
  fragment publicKeyFragment on VaultPublicKey {
    key
    encoding
    version
  }
`;

export const encryptedDataFragment = gql`
  fragment encryptedDataFragment on VaultEncryptedData {
    cipherText
    nonce
    encoding
    encryptionVersion
  }
`;

export const vaultItemWithKeyFragment = gql`
  fragment vaultItemWithKeyFragment on VaultItemWithKey {
    encryptedItem {
      id
      creatorId
      version
      encryptedData {
        ...encryptedDataFragment
      }
      creator {
        id
        firstName
        lastName
      }
    }

    encryptedItemKey {
      id
      itemId
      creatorId
      recipientId
      encryptedData {
        ...encryptedDataFragment
      }
    }
  }
  ${encryptedDataFragment}
`;

export const memberChartVaultItem = gql`
  fragment memberChartVaultItem on VaultItem {
    id
    sourceVersion
    createdAt
    updatedAt
    creator {
      id
      firstName
      lastName
    }
    firstVersionCreator {
      id
      firstName
      lastName
    }
    permissions
    encryptedData {
      ...encryptedDataFragment
    }
  }
  ${encryptedDataFragment}
`;

export const getVaultItemsByTagQuery = gql`
  query getVaultItemsByTag($tag: String!) {
    getVaultItemsByTag(tag: $tag) {
      ...vaultItemWithKeyFragment
    }
  }
  ${vaultItemWithKeyFragment}
`;

export const getVaultItemByIdQuery = gql`
  query getVaultItemById($id: ID!) {
    getVaultItemById(id: $id) {
      ...vaultItemWithKeyFragment
    }
  }
  ${vaultItemWithKeyFragment}
`;

export const getAppointmentById = gql`
  query GetAppointmentById($id: ID!) {
    getAppointmentById(id: $id) {
      id
      member {
        id
        firstName
        lastName
        dateOfBirth
        gender
      }
      clinician {
        userId
        name
      }
      start
      end
      previousAppointment {
        id
        clinicalNote {
          noteType
        }
      }
    }
  }
`;

export const getAuditLogForVaultItem = gql`
  query getAuditLogForVaultItem($id: ID!) {
    getVaultItemById(id: $id) {
      auditLog {
        id
        actorId
        eventType
        timestamp
      }
    }
  }
`;

export const getMemberDetails = gql`
  query MemberDetailsById($patientId: ID!) {
    getMember(input: { id: $patientId }) {
      id
      firstName
      lastName
      dateOfBirth
      gender
    }
  }
`;
