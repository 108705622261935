import {
  booleanField,
  field,
  FieldDefinitions,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  ClinicianIntakeChecklist,
  ClinicianIntakeChecklist_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/ClinicianIntakeChecklist';
import {
  ClinicianChecklist,
  Location,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/ClinicianChecklist';
import {
  ClinicianChecklistSection as TherapyClinicianChecklistSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import { Width } from 'types/StyleTypes';
import {
  CheckboxWithDescriptionField,
  EnumRadioField,
  TextAreaField,
} from 'app/notes-ui/forms/fields';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { NoteFormControlLabelStyles } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { RadioListOrientations } from 'app/notes-ui/forms/form-controls/RadioButtonGroup';
import { deprecatedField } from 'app/notes-ui/shared/ValidationRules';
import Labels from 'app/notes-ui/strings/en.json';
import {
  NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
  requiredFieldIf,
} from 'app/notes-ui/utils';
import React from 'react';

export type Props = {
  appointmentId: string;
  onSubmit: (checklist: TherapyClinicianChecklistSection) => void;
  updateDraftNoteState: (data: TherapyClinicianChecklistSection) => void;
  initialValue?: ClinicianIntakeChecklist; // used for read-only
  disabled?: boolean;
  savingNote?: boolean;
};

export const fieldDefs: FieldDefinitions<ClinicianIntakeChecklist> = {
  appointmentId: stringField(),
  introducedCoachingToMember: booleanField({
    rules: [
      (value) => {
        if (!value) {
          return 'This field must be checked';
        }
      },
    ],
  }),
  memberSaidYesToThoughtsOfHarm: field({ rules: [deprecatedField] }),
  reviewedInformedConsentWithMember: field({ rules: [deprecatedField] }),
  reviewedIntakeBeforeMeeting: field({ rules: [deprecatedField] }),
  memberGaveConsentForTelehealthAppointment: field({
    rules: [deprecatedField],
  }),
  releaseOfInformationRequested: field({ rules: [deprecatedField] }),
  physicallyLocatedAtApptAddress: field<Location>({
    default: Location.undefined_location,
    rules: [
      (value, _) => {
        if (value === Location.undefined_location) {
          return 'This field must be selected';
        }
      },
    ],
  }),
  currentLocation: stringField({
    default: '',
    rules: [
      (value, state: ClinicianChecklist) =>
        requiredFieldIf(
          value,
          state,
          'physicallyLocatedAtApptAddress',
          Location.located_outside_appt_address,
        ),
    ],
  }),
  version: field<ClinicianIntakeChecklist_Version>({
    default: ClinicianIntakeChecklist_Version.v0,
  }),
};

const formControlStyles: NoteFormControlLabelStyles = {
  label: styles.label,
  subtext: styles.subtext,
  checkbox: styles.checkbox,
  root: styles.root,
};

export function ClinicianChecklistFormV2(props: Props) {
  const { initialValue, appointmentId, disabled } = props;

  const { fields, getValue } = useForm<ClinicianIntakeChecklist>(
    fieldDefs,
    {
      ...initialValue,
      appointmentId,
      version: ClinicianIntakeChecklist_Version.v0,
    } as ClinicianIntakeChecklist,
    {
      onStateChange: async () => {
        await onSubmit();
      },
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
    },
  );

  const onSubmit = async () => {
    if (disabled) return;

    props.onSubmit({
      name: TherapyIntakeSectionName.CLINICIAN_CHECKLIST,
      data: getValue(),
    });
  };

  return (
    <div>
      {disabled &&
      fields.physicallyLocatedAtApptAddress.value ===
        Location.undefined_location ? null : (
        <EnumRadioField
          disabled={disabled}
          testId="physicallyLocatedAtApptAddress"
          label="Member is physically located at address on file for this session"
          options={Location}
          field={fields.physicallyLocatedAtApptAddress}
          keyLabels={{
            located_at_appt_address: 'Yes',
            located_outside_appt_address: 'No',
          }}
          formControlStyles={formControlStyles}
          radioListOrientation={RadioListOrientations.HORIZONTAL}
        />
      )}

      {fields.physicallyLocatedAtApptAddress.value ===
        Location.located_outside_appt_address && (
        <TextAreaField
          testId="currentLocation"
          disabled={disabled}
          label="Provide current location information"
          placeholder="If member is unable to provide exact address, document known identifiers, such as a license plate number or name of a building / park"
          field={fields.currentLocation}
          width={Width.FULL}
          rows={1}
          autoExpand={true}
        />
      )}

      <div className={styles.formGroupV2}>
        <CheckboxWithDescriptionField
          disabled={disabled}
          title="Clinician reviewed informed consent / attestation"
          description="Verified member’s identity, reviewed informed consent, including telehealth expectations, and determined telehealth is appropriate for member"
          label={'I attest to the above'}
          field={fields.introducedCoachingToMember}
          tooltip={Labels.LEGAL_REQUIREMENTS_CONSENT}
          styles={formControlStyles}
        />
      </div>
    </div>
  );
}

export const validate = (data: ClinicianIntakeChecklist | null) =>
  data !== null && validateForm(data, fieldDefs);
