import {
  AnticipatedSession,
  AnticipatedSession_Frequency as Frequency,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/AnticipatedSession';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';
import { Width } from 'types/StyleTypes';
import styles from 'app/notes-ui/forms/form-controls/AnticipatedNumberOfSessionsField.module.scss';
import { EnumDropdown } from 'app/notes-ui/forms/form-controls/EnumDropdown';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { NumberInput } from 'app/notes-ui/forms/form-controls/NumberInputV2';
import React, { useState } from 'react';

interface Props {
  value: AnticipatedSession;
  name?: string;
  label: string;
  error?: string;
  onChange: (session: AnticipatedSession) => void;
}

const Labels = {
  frequency: {
    description: 'Only edit if / when revising the cadence of future sessions',
    label: 'Frequency',
  },
  sessionInput: {
    description:
      'Only edit if / when revising the total # of anticipated sessions.',
    label: 'Total # anticipated sessions',
    label2: 'Number of sessions anticipated',
    tooltip:
      'This is the total number of anticipated sessions for the member’s care.',
  },
};

export function AnticipatedNumberOfSessionsField(props: Props) {
  const [value, setValue] = useState<AnticipatedSession>(props.value);

  const {
    enable_care_hub_notes_efficiency,
  } = useFeatureFlags().transientFeatureFlags;

  const OnChange = (
    field: keyof AnticipatedSession,
    fieldValue: number | Frequency | null,
  ) => {
    const session = { ...value, [field]: fieldValue };
    setValue(session);
    props.onChange(session);
  };

  const sessionInputLabel = enable_care_hub_notes_efficiency
    ? Labels.sessionInput.description
    : '';
  const frequencyLabel = enable_care_hub_notes_efficiency
    ? Labels.sessionInput.description
    : '';
  const sessionLabel = enable_care_hub_notes_efficiency
    ? Labels.sessionInput.label
    : Labels.sessionInput.label2;

  return (
    <>
      <div data-testid={props.name || 'sessionInput'} className={styles.root}>
        <Grid spacing={0}>
          <Columns
            widths={[COLUMN_WIDTH.HALF, COLUMN_WIDTH.HALF]}
            className={styles.col}
          >
            <NoteFormControlLabel
              label={sessionLabel}
              labelDescription={sessionInputLabel}
            >
              <NumberInput
                placeholder="0"
                value={value.numberOfSessionsAnticipated}
                label={props.label}
                tooltip={Labels.sessionInput.tooltip}
                name="sessionNumber"
                onChange={(sessionNumber) => {
                  let value: number | null = sessionNumber;
                  if (isNaN(value)) value = null;
                  OnChange('numberOfSessionsAnticipated', value);
                }}
                error={props.error}
              />
            </NoteFormControlLabel>
            <NoteFormControlLabel
              label={Labels.frequency.label}
              labelDescription={frequencyLabel}
            >
              <EnumDropdown
                data-testid="sessionFrequency"
                initialValue={value.frequency as Frequency}
                enum={Frequency}
                onSelect={(session: Frequency) =>
                  OnChange('frequency', session)
                }
                placeholder="Frequency"
                width={Width.FULL}
              />
            </NoteFormControlLabel>
          </Columns>
        </Grid>
      </div>
    </>
  );
}
