import { useState } from 'react';

import { MenuItem } from '../shared-components/menu/FilterMenu';

export const useFilters = (
  items: MenuItem[],
): [MenuItem[], (key: string) => void] => {
  const [filters, setFilters] = useState<MenuItem[]>(items);

  const toggleFilter = (key: string) => {
    const newFilters = [...filters];
    const index = newFilters.findIndex((filter) => filter.key === key);
    newFilters[index].checked = !newFilters[index].checked;
    setFilters(newFilters);
  };

  return [filters, toggleFilter];
};
