import React, { Fragment, useMemo, useState } from 'react';

import { Dropdown } from '../../shared-components/Dropdown';
import { Size } from '../../types/StyleTypes';
import { RISC10MAX } from '../patients/tabs/surveys/SurveysV3/constants';
import { RISC10SurveyResponse } from '../patients/tabs/surveys/SurveysV3/types';
import { useAppState } from '../state';
import { formatDateFromUTC } from '../../utils/dateTime';
import { LineChart, RenderTooltip, Series, YAxisOptions } from './LineChart';
import styles from './LineChart.module.scss';
import { SurveyTooltip } from './SurveyTooltip';

type Props = {
  disableTooltip?: boolean;
  disableDataLabel?: boolean;
  responses: RISC10SurveyResponse[];
  onClick?: (index: number, data: RISC10SurveyResponse) => void;
};

type Dimension = keyof Omit<
  RISC10SurveyResponse,
  'timestamp' | 'strengths' | 'severity' | 'summaries'
>;

export default function RISC10LineChart(props: Props) {
  const { responses, onClick } = props;
  const [dimension, setDimension] = useState<Dimension>('total');
  const defaultYaxisOption = { max: RISC10MAX, min: 0, title: 'Score' };
  const [yaxis, setYaxis] = useState<YAxisOptions>(defaultYaxisOption);
  const timezone = useAppState((_) => _.user.timezone!);

  const labels = useMemo(
    () => responses.map((_) => formatDateFromUTC(_.timestamp, timezone)),
    [responses],
  );

  const data = useMemo(() => responses.map((r) => r[dimension]), [
    responses,
    dimension,
  ]);

  const series: Series[] = [{ data, name: dimension }];

  const handleClick = (dataPointIndex: number) => {
    if (onClick) onClick(dataPointIndex, responses[dataPointIndex]);
  };

  return (
    <Fragment>
      <div className={styles.chartTitle}>
        <span className={styles.titleLabel}>
          <b>CD-RISC-10 (Resiliency Scale)</b> • Adaptability Check-in
        </span>
        <Dropdown
          size={Size.SM}
          variant="standard"
          data-testid="CD-RISC-10-select"
          initialValue="total"
          options={[
            { name: 'Total Scores', value: 'total' },
            { name: 'Flexibility', value: 'flexibility' },
            { name: 'Self Reliance', value: 'selfReliance' },
            { name: 'Positivity', value: 'positivity' },
            { name: 'Personal Growth', value: 'personalGrowth' },
            { name: 'Perserverance', value: 'perseverance' },
            { name: 'Confidence', value: 'confidence' },
            { name: 'Focus', value: 'focus' },
            { name: 'Determination', value: 'determination' },
            { name: 'Grit', value: 'grit' },
            { name: 'Weathering Emotions', value: 'weatheringEmotions' },
          ]}
          onSelect={(selected) => {
            if (selected === 'total') {
              setYaxis(defaultYaxisOption);
            } else {
              // Adaptability scores go from 0-4, not 0-3 like PHQ/GAD
              setYaxis({ max: 4, min: 0, tickAmount: 4, title: 'Score' });
            }
            return setDimension(selected);
          }}
        />
      </div>
      <LineChart
        data-testid="lineChart"
        labels={labels}
        data={series}
        tooltip={createRISC10Tooltip(responses)}
        xaxisOptions={{ title: 'Date Submitted' }}
        yaxisOptions={yaxis}
        onClick={handleClick}
        disableDataLabel={props.disableDataLabel}
        disableTooltip={props.disableTooltip}
      />
    </Fragment>
  );
}

function createRISC10Tooltip(risc10s: RISC10SurveyResponse[]): RenderTooltip {
  return (props: { dataPointIndex: number }) => {
    const { dataPointIndex } = props;
    const risc10 = risc10s[dataPointIndex];
    const data: [string, number][] = [
      ['Flexibility', risc10.flexibility],
      ['Self Reliance', risc10.selfReliance],
      ['Positivity', risc10.positivity],
      ['Personal Growth', risc10.personalGrowth],
      ['Perseverance', risc10.perseverance],
      ['Confidence', risc10.confidence],
      ['Focus', risc10.focus],
      ['Determination', risc10.determination],
      ['Grit', risc10.grit],
      ['Weathering Emotions', risc10.weatheringEmotions],
    ];
    return <SurveyTooltip data={data} total={risc10.total} variant="four" />;
  };
}
