import { Typography } from '@mui/material';
import { Size } from 'types/StyleTypes';
import { classNameCombiner } from 'utils';
import React, { ReactNode } from 'react';

import defaultStyles from './NoteFormControlLabel.module.scss';
import { NoteTooltip } from './NoteTooltip';

export type NoteFormControlLabelStyles = {
  label?: string;
  subtext?: string;
  root?: string;
  checkbox?: string;
};

type Props = {
  label?: string;
  size?: Size;
  tooltip?: string;
  children: ReactNode;
  subtext?: string | ReactNode;
  styles?: NoteFormControlLabelStyles;
  labelDescription?: string;
  optional?: boolean;
};

export function NoteFormControlLabel({
  children,
  subtext,
  label,
  labelDescription,
  tooltip,
  styles,
  size = Size.SM,
  optional = false,
}: Props) {
  const styleClasses = {
    ...defaultStyles,
    ...styles,
  };

  return (
    <div className={styleClasses.root}>
      <label
        className={classNameCombiner([
          styleClasses.label || defaultStyles.label,
          defaultStyles[size],
        ])}
        data-testid="label"
      >
        <span>
          {label}{' '}
          {optional && (
            <span className={defaultStyles.optional}> (optional)</span>
          )}{' '}
          {tooltip && <NoteTooltip tooltip={tooltip} />}
          {subtext && <div className={styleClasses.subtext}>{subtext}</div>}
        </span>
      </label>
      {labelDescription && (
        <Typography variant="body2" color="textSecondary">
          {labelDescription}
        </Typography>
      )}
      {children}
    </div>
  );
}
