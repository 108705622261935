import { SvgIcon } from '@mui/material';
import { ReactComponent as UncheckedRadioButtonSVG } from 'assets/unchecked-radio-button-icon.svg';
import React from 'react';

export const UncheckedRadioButtonIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <UncheckedRadioButtonSVG />
    </SvgIcon>
  );
};
