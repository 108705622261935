/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export interface SideBarState {
  showInbox: boolean;
}

export const initialState: SideBarState = {
  showInbox: false,
};

const inboxSlice = createSlice({
  initialState,
  name: 'sideBar',
  reducers: {
    toggleInbox(state) {
      state.showInbox = !state.showInbox;
    },
  },
});

export const { toggleInbox } = inboxSlice.actions;
export default inboxSlice.reducer;
