import { arrayField, Field, field } from '@ginger.io/react-use-form';
import { Width } from 'types/StyleTypes';
import { EnumDropdown } from 'app/notes-ui/forms/form-controls/EnumDropdown';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { ConfirmDeleteModal } from 'app/notes-ui/shared/code-set/ConfirmDeleteModal';
import { isEmpty } from 'app/notes-ui/shared/ValidationRules';
import { SectionDivider } from 'app/notes-ui/therapy/shared/SectionDivider';
import { ProtobufCodeSet, ProtobufEnum } from 'app/notes-ui/types';
import { cptCodeToOptions } from 'utils/notes';
import React, { useEffect, useState } from 'react';

import { ICD10CodesSelector } from '../icd10-codes-selector/ICD10CodesSelector';
import styles from './CodeSetFields.module.scss';
import { emptyCodeSet } from './constants';

type CodeSetFieldsProps = {
  disabled?: boolean;
  cptCodeEnum: ProtobufEnum;
  field: Field<ProtobufCodeSet[]>;
};

const codeSetsValidation = (codeSets: ProtobufCodeSet[]) => {
  if (isEmpty(codeSets) || codeSets?.length === 0) {
    return 'At least one code set is required';
  }

  if (codeSets.some(isCodeSetInvalid)) {
    return 'Invalid code sets';
  }

  return undefined;
};

export const areCodeSetsValid = (codeSets: ProtobufCodeSet[]) => {
  return codeSets?.length > 0 && !isCodeSetInvalid(codeSets[0]);
};

const isCodeSetInvalid = (codeSet: ProtobufCodeSet) => {
  return (
    isEmpty(codeSet) ||
    isEmpty(codeSet.cptCode) ||
    codeSet.cptCode === 0 ||
    isEmpty(codeSet.icd10Codes) ||
    codeSet.icd10Codes.length <= 0 ||
    codeSet.icd10Codes.length > 4
  );
};

const cptCodeValidation = (cptCode: number) =>
  isEmpty(cptCode) || cptCode <= 0 ? 'This field is required' : undefined;

const icd10CodesValidation = (icd10Codes: number[]) =>
  isEmpty(icd10Codes) || icd10Codes?.length === 0
    ? "This field can't be empty."
    : icd10Codes?.length > 4
    ? 'Maximum of 4 ICD10 codes.'
    : undefined;

export function buildCodeSetFieldDefs() {
  return {
    codeSets: arrayField({
      rules: [codeSetsValidation],
    }),
    cptCode: field<number>({ rules: [] }),
    icd10Codes: arrayField<number>({ rules: [] }),
  };
}

export function CodeSetFields(props: CodeSetFieldsProps) {
  const { disabled, cptCodeEnum, field } = props;
  const values = [...field.value];

  const [deleteCodeSetIndex, setDeleteCodeSetIndex] = useState<number | null>(
    null,
  );
  const [addButtonDisabled, setAddButtonDisabled] = useState<boolean>(
    values.length < 1,
  );

  const deleteCodeSet = () => {
    if (deleteCodeSetIndex !== null) {
      field.setValue([
        ...field.value.slice(0, deleteCodeSetIndex),
        ...field.value.slice(deleteCodeSetIndex + 1),
      ]);
    }
    setDeleteCodeSetIndex(null);
  };

  const updateCodeSet = (codeSet: ProtobufCodeSet, index: number) => {
    const codeSets = [
      ...field.value.slice(0, index),
      codeSet,
      ...field.value.slice(index + 1),
    ];
    field.setValue(codeSets);
  };

  useEffect(() => {
    setAddButtonDisabled(isCodeSetInvalid(values[values.length - 1]));
  }, [values]);

  const fields = values.map((fieldValue, i) => {
    const cptCodeValue = fieldValue.cptCode;
    const icd10CodesValue = fieldValue.icd10Codes;

    return (
      <div key={`Code-Set-${i}`}>
        <SectionDivider
          label={`Code Set #${i + 1}`}
          isNew={false}
          index={i}
          isRemovable={values.length > 1}
          onDelete={() => setDeleteCodeSetIndex(i)}
        />
        <NoteFormControlLabel label="CPT Code">
          <EnumDropdown
            placeholder="Select..."
            data-testid={`codeSet${i}-cptCode`}
            menuItemClass={styles.menuItem}
            selectClass={styles.selectClass}
            disabled={disabled}
            initialValue={cptCodeValue}
            error={
              props.field.error ? cptCodeValidation(cptCodeValue) : undefined
            }
            enum={cptCodeEnum}
            onSelect={(value) =>
              updateCodeSet({ ...field.value[i], cptCode: value }, i)
            }
            width={Width.FULL}
            convertEnumToOptions={cptCodeToOptions}
          />
        </NoteFormControlLabel>
        <NoteFormControlLabel
          label="ICD-10 Code"
          subtext="You must select at least one F code. Designate the primary diagnosis by selecting it first."
        >
          <ICD10CodesSelector
            data-testid={`codeSet${i}-icd10Codes`}
            disabled={disabled}
            value={icd10CodesValue}
            name="icd10Codes"
            tagClass={styles.tag}
            className={styles.enumDropdown}
            error={
              field.error ? icd10CodesValidation(icd10CodesValue) : undefined
            }
            onChange={(value) =>
              updateCodeSet({ ...field.value[i], icd10Codes: value }, i)
            }
          />
        </NoteFormControlLabel>
      </div>
    );
  });

  return (
    <div className={styles.addButton}>
      {fields}
      <SectionDivider
        label="+ Code Set"
        isNew={true}
        onClick={() => {
          field.setValue([...values, emptyCodeSet]);
        }}
        disabled={disabled || addButtonDisabled}
      />
      <ConfirmDeleteModal
        codeSetIndex={deleteCodeSetIndex}
        onConfirm={deleteCodeSet}
        onCancel={() => setDeleteCodeSetIndex(null)}
      />
    </div>
  );
}
