import { Warning as WarningIcon } from '@mui/icons-material';
import { MessageType } from 'app/coach/pubnub/types';
import { Avatar } from 'shared-components/avatar/Avatar';
import Tooltip from 'shared-components/tooltip/Tooltip';
import { classNameCombiner } from 'utils';
import React from 'react';

import { MemberProfileBaseProps } from '../ChatContainer';
import { MessageBubble } from './MessageBubble';
import styles from './MessageContainer.module.scss';

export const MemberMessageContainer = ({
  memberProfileBaseProps,
  messageProps,
  isOutOfSession,
  containsRisk,
}: {
  memberProfileBaseProps: MemberProfileBaseProps;
  messageProps: { message: string; dateAndTime: string; type?: MessageType };
  isOutOfSession?: boolean;
  containsRisk?: boolean;
}) => {
  const { avatarSrc, firstName, lastName } = memberProfileBaseProps;
  const { message, dateAndTime } = messageProps;

  return (
    <div
      className={classNameCombiner([styles.container, styles.memberContainer])}
    >
      <Avatar
        src={avatarSrc}
        className={styles.avatar}
        firstName={firstName}
        lastName={lastName}
        includeLastName={true}
      />
      <Tooltip
        classes={{ popper: styles.popper, tooltip: styles.tooltip }}
        title={dateAndTime}
        enterDelay={0}
        enterTouchDelay={0}
        placement="bottom-end"
      >
        <div>
          {isOutOfSession && <div className={styles.noteTitle}>Note</div>}
          <MessageBubble
            message={message}
            isOutOfSession={isOutOfSession}
            isFromMember={true}
          />
        </div>
      </Tooltip>
      {containsRisk && <WarningIcon className={styles.warningIcon} />}
    </div>
  );
};
