import { GAD7MAX } from 'app/patients/tabs/surveys/SurveysV3/constants';
import { GAD7SurveyResponse } from 'app/patients/tabs/surveys/SurveysV3/types';
import { useAppState } from 'app/state';
import {
  selectGAD7ChartDropdown,
  selectGAD7ChartHover,
} from 'app/state/amplitude/actions/surveys';
import { formatDateFromUTC } from 'utils/dateTime';
import React, { Fragment, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'shared-components/Dropdown';
import { Size } from 'types/StyleTypes';

import { LineChart, RenderTooltip, Series, YAxisOptions } from './LineChart';
import styles from './LineChart.module.scss';
import { SurveyTooltip } from './SurveyTooltip';
import { GAD7 } from './types';

type Props = {
  disableTooltip?: boolean;
  disableDataLabel?: boolean;
  responses: GAD7[] | GAD7SurveyResponse[];
  onClick?: (index: number, data: GAD7SurveyResponse) => void;
};

// We don't want timestamp to be a selectable dimension
type Dimension =
  | keyof Omit<GAD7, 'timestamp'>
  | keyof Omit<
      GAD7SurveyResponse,
      'timestamp' | 'insights' | 'severity' | 'summaries'
    >;

export function Gad7LineChart(props: Props) {
  const { responses, onClick } = props;
  const [dimension, setDimension] = useState<Dimension>('total');
  const defaultYaxisOption = { max: GAD7MAX, min: 0, title: 'Score' };
  const [yaxis, setYaxis] = useState<YAxisOptions>(defaultYaxisOption);
  const timezone = useAppState((_) => _.user.timezone!);

  const labels = useMemo(
    () => responses.map((_) => formatDateFromUTC(_.timestamp, timezone)),
    [responses],
  );

  const data = useMemo(() => responses.map((r) => r[dimension]), [
    responses,
    dimension,
  ]);
  const dispatch = useDispatch();

  const series: Series[] = [{ data, name: dimension }];

  const handleClick = (dataPointIndex: number) => {
    if (onClick)
      onClick(dataPointIndex, responses[dataPointIndex] as GAD7SurveyResponse);
  };
  return (
    <Fragment>
      <div className={styles.chartTitle}>
        <span className={styles.titleLabel}>
          <b>GAD-7 (Anxiety Scale)</b> • Progress Check-in
        </span>
        <Dropdown
          size={Size.SM}
          variant="standard"
          data-testid="GAD-7-select"
          dataTestId="GAD-7-select"
          initialValue="total"
          options={[
            { name: 'Total Scores', value: 'total' },
            { name: 'Anxiety', value: 'anxiety' },
            { name: 'Control', value: 'control' },
            { name: 'Worry', value: 'worry' },
            { name: 'Relaxation', value: 'relaxation' },
            { name: 'Restlessness', value: 'restlessness' },
            { name: 'Irritation', value: 'irritation' },
            { name: 'Fear', value: 'fear' },
          ]}
          onSelect={(selected) => {
            dispatch(selectGAD7ChartDropdown({ selectedItem: selected }));
            if (selected === 'total') {
              setYaxis(defaultYaxisOption);
            } else {
              setYaxis({ max: 3, min: 0, tickAmount: 3, title: 'Score' });
            }
            return setDimension(selected);
          }}
        />
      </div>

      <LineChart
        data-testid="lineChart"
        labels={labels}
        data={series}
        tooltip={createGad7Tooltip(responses)}
        xaxisOptions={{ title: 'Date Submitted' }}
        yaxisOptions={yaxis}
        onHover={() => {
          dispatch(selectGAD7ChartHover({ chartName: 'Gad7LineChart' }));
        }}
        onClick={handleClick}
        disableDataLabel={props.disableDataLabel}
        disableTooltip={props.disableTooltip}
      />
    </Fragment>
  );
}

function createGad7Tooltip(gad7s: GAD7[]): RenderTooltip {
  return (props: { dataPointIndex: number }) => {
    const { dataPointIndex } = props;
    const gad7 = gad7s[dataPointIndex];
    const data: [string, number][] = [
      ['Anxiety', gad7.anxiety],
      ['Control', gad7.control],
      ['Worry', gad7.worry],
      ['Relaxation', gad7.relaxation],
      ['Restlessness', gad7.restlessness],
      ['Irritation', gad7.irritation],
      ['Fear', gad7.fear],
    ];

    return <SurveyTooltip data={data} total={gad7.total} />;
  };
}
