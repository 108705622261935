import {
  QueryOptions as ApolloQueryOptions,
  useLazyQuery,
} from '@apollo/client';
import { useOnMount } from 'hooks/useOnMount';
import { StateSlice } from 'app/state/status/types/StateSlice';
import { useEffect, useRef } from 'react';

import { useStateSlice } from './utils';

export type QueryOptions<T, U> = Omit<ApolloQueryOptions<T, U>, 'variables'> & {
  variables: () => Promise<T>;
};
/** Triggers a graphQL query and maps the result using the passed function */
export function useMappedQuery<T, U, V>(
  options: QueryOptions<U, T>,
  mapFunction: (data: T) => Promise<V>,
): StateSlice<V> {
  const { state, setLoading, setError, setData } = useStateSlice<V>();
  const [runInitQuery, { error, data }] = useLazyQuery<T, U>(options.query);
  const mountRef = useRef(false);

  useOnMount(() => {
    mountRef.current = true;
    const runQuery = async () => {
      try {
        setLoading();
        const { variables, ...opts } = options;
        const queryOptions = { ...opts, variables: await variables() };
        if (mountRef.current) await runInitQuery(queryOptions);
      } catch (error) {
        if (mountRef.current) setError(error);
      }
    };
    void runQuery();

    return () => {
      mountRef.current = false;
    };
  });

  useEffect(() => {
    const handleResult = async () => {
      try {
        if (error) {
          if (mountRef.current) setError(error);
        } else if (data) {
          const mappedData = await mapFunction(data);
          if (mountRef.current) setData(mappedData);
        }
      } catch (error) {
        if (mountRef.current) setError(error);
      }
    };
    void handleResult();
  }, [data, error]);

  return state.current;
}
