import { chatErrorAmplitudeEvent } from 'app/state/amplitude/actions/chat';
import { selectLoggingContextWithMemberData } from 'app/state/chat/selectors';
import flower from 'assets/chat/error-flower.svg';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'redux-reloaded';

import styles from './ChatErrorState.module.scss';

export const ChatErrorState = ({ error }: { error: Error }) => {
  const { memberId } = useParams<{ memberId: string }>();
  const dispatch = useDispatch();
  const logsContext = selectLoggingContextWithMemberData('', memberId);
  useEffect(() => {
    dispatch(
      chatErrorAmplitudeEvent({
        ...logsContext,
        error,
      }),
    );
  }, []);
  return (
    <div data-test-id="chat-error" className={styles.container}>
      <h3 className={styles.title}>Well, this is unexpected...</h3>
      <span className={styles.text}>
        Something went wrong on our end. Don’t worry, it’s not you — it’s us.
        Sorry about that.{' '}
      </span>
      <img className={styles.picture} src={flower} alt="flower" />
      <h4 className={styles.subtitle}>Let’s try this:</h4>
      <ul className={styles.list}>
        <li className={styles.item}>Refresh the page.</li>
        <li className={styles.item}>
          If that doesn’t work please submit a bug report so we can get you back
          up and running!
        </li>
      </ul>
    </div>
  );
};
