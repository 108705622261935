import { createApi, EndpointDefinitions } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from './baseQueryWithReauth';

// eslint-disable-next-line @typescript-eslint/ban-types -- temp, to be removed once we populate the endpoints
export const webApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (): EndpointDefinitions => ({} as EndpointDefinitions),
  reducerPath: 'webApi',
});
