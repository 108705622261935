export const Labels = {
  chatTextAreaGroup: {
    id: 'requireTimelyReview',
    label: 'Requires timely review',
    note: 'Note: All messages are part of the member’s medical file.',
    placeholder:
      'Important member updates not included in notes, care suggestions/discussion...',
    tooltip:
      'Send a notification for everyone on this member’s care team. Use this when you need the team to see your message sooner than their next appointment with the member.',
  },
  emptyState: 'Start a conversation with the member’s care team',
};
