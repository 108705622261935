import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link as MuiLink,
  TableCell,
  TableRow,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { DEFAULT_TIMEZONE } from 'app/clinician/ClinicianSettingsComponent';
import { Button } from 'shared-components/button/Button';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { V2Table as Table } from 'shared-components/table/V2Table';
import { V2TableBody as TableBody } from 'shared-components/table/V2TableBody';
import { V2TableHead as TableHead } from 'shared-components/table/V2TableHead';
import { appointmentRoute, patientProfileRoute } from 'app/top-nav/Routes';
import {
  AutoCompleteSearch,
  Result,
} from 'shared-components/autocomplete-search/AutoCompleteSearch';
import { useAppState } from 'app/state';
import {
  clickAppointmentListAppointment,
  clickScheduleAppointmentListMemberFilterAction,
  viewAppointmentsTableAction,
} from 'app/state/amplitude/actions/appointments';
import {
  psychiatryIntakeClick,
  psychiatryProgressClick,
  therapyIntakeClick,
  therapyProgressClick,
} from 'app/state/amplitude/actions/notes';
import { useLogger } from 'app/state/log/useLogger';
import { formatAppointmentStatus, formatEnumValue } from 'utils';
import { formatDate, formatTime } from 'utils/dateTime';
import {
  ClinicalAppointmentNoteType,
  NoteType,
  UserRole,
} from 'generated/globalTypes';
import { chain, first, isNull } from 'lodash';
import moment from 'moment';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import styles from './AppointmentsTable.module.scss';
import { ClinicalNoteActionBar } from './ClinicalNoteActionBar';
import {
  MemberSessionStatsContainer,
  MemberSessionStatsVariant,
} from './MemberSessionStatsContainer';
import { Appointment, NoteAction } from './types';
import { noPropagation } from './utils';

type Column = {
  key: string;
  label: string;
};

function createDefaultColumns(timezone: string): Column[] {
  return [
    { key: 'date', label: `Date ${timezone}` },
    { key: 'time', label: 'Time' },
    { key: 'name', label: 'Clinician' },
    { key: 'memberName', label: 'Member Name' },
    { key: 'type', label: 'Appt Type' },
    { key: 'status', label: 'Appt Status' },
    { key: 'noteStatus', label: 'Note Status' },
    { key: 'clinicalNote', label: 'Note Actions' },
  ];
}

export type Props = {
  appointments: Appointment[];
  filterAppointments?: (start_date?: string, end_date?: string) => void;
  localTimezone: string;
  memberTimezone?: string;
  hideClinicianName?: boolean;
  hideMemberName?: boolean;
  navigateTo: (url: string) => void;
  signedLockedAction: NoteAction.VIEW | NoteAction.DOWNLOAD;
  onNewAppointment: () => void;
  filterBannerEnabled?: boolean;
  role?: UserRole;
  enableMemberSessionStats?: boolean;
  todaysAppointmentsOnly?: boolean;
  updateTodaysAppointmentsOnly: (todaysAppointmentsOnly: boolean) => void;
};

export function AppointmentsTableSchedule(props: Props) {
  const dispatch = useDispatch();
  const {
    appointments,
    filterAppointments,
    localTimezone,
    navigateTo,
    onNewAppointment,
    hideClinicianName,
    hideMemberName,
    filterBannerEnabled,
    memberTimezone = DEFAULT_TIMEZONE,
    role,
    enableMemberSessionStats = true,
    todaysAppointmentsOnly = false,
    updateTodaysAppointmentsOnly,
  } = props;
  const { userId, timezone } = useAppState((_) => _.user);
  const userTimezone = timezone || DEFAULT_TIMEZONE;
  const appointmentTimezone =
    role === UserRole.MEMBER_SUPPORT ? memberTimezone : localTimezone;

  const {
    vaultEnabled,
    allowedNoteActions,
    canViewPatients,
  } = useFeatureFlags();
  const logger = useLogger();
  const onMemberFilter = (
    phrase: string | null,
    todaysAppointmentOnly: boolean,
  ) => {
    dispatch(clickScheduleAppointmentListMemberFilterAction());
    setFilterValue(phrase);
    updateTodaysAppointmentsOnly(todaysAppointmentOnly);
  };
  const [filterValue, setFilterValue] = React.useState<string | null>(null);
  const isMounting = React.useRef(true);

  useEffect(() => {
    dispatch(viewAppointmentsTableAction());
  }, [dispatch]);

  useEffect(() => {
    // To prevent a refetch loop we don't `filterAppointments` onMount
    //   this component is wrapped in a useQuery that unMounts the component on query refetch
    if (isMounting.current) {
      isMounting.current = false;
      return;
    }
    if (filterAppointments === undefined) {
      return;
    }

    let start_date;
    let end_date;
    if (todaysAppointmentsOnly) {
      // We add some buffer before and after to avoid leaving out any appointment
      // The backend filters dates in utc so this is to include appointments at 20:00 in a GMT-5.

      // Current UTC date and time
      const now = moment.utc();
      // Set time to midnight
      now.set({ hour: 0, millisecond: 0, minute: 0, second: 0 });

      // Subtract 1 day
      start_date = moment.utc(now).subtract(1, 'days').format();
      // Add 2 days
      end_date = moment.utc(now).add(2, 'days').format();
    }

    logger.info(
      `Today's appointments for userId:${userId} toggled, fetching ${
        todaysAppointmentsOnly ? `from ${start_date} to ${end_date}` : 'all'
      } appointments`,
    );

    filterAppointments(start_date, end_date);
  }, [todaysAppointmentsOnly]);

  const columns = createDefaultColumns(appointmentTimezone).filter(
    ({ key }) =>
      !(
        (props.hideClinicianName && key === 'name') ||
        (props.hideMemberName && key === 'memberName')
      ),
  );

  let filteredAppointments = isNull(filterValue)
    ? appointments
    : appointments.filter(
        (appointment) => appointment.member.id === filterValue,
      );

  if (todaysAppointmentsOnly) {
    const currentDate = moment().format('L');

    if (filteredAppointments.length > 0) {
      const first_appt_start = filteredAppointments[0].start;
      const last_appt_start =
        filteredAppointments[filteredAppointments.length - 1].start;
      logger.info(
        `Today's appointments for userId:${userId} toggled, filtering start date range: ${first_appt_start} - ${last_appt_start}. ` +
          `Current date: ${currentDate}`,
      );
    }

    filteredAppointments = filteredAppointments.filter((a) => {
      const date = formatDate(a.start, timezone || '');
      return currentDate === moment(date).format('L');
    });
  }

  const memberStatsMemberId = enableMemberSessionStats
    ? first(filteredAppointments)?.member.id
    : undefined;

  const header = filterBannerEnabled ? (
    <FiltersAndNewAppointmentBanner
      data-testid="filter-bar"
      onMemberFilter={onMemberFilter}
      onNewAppointment={onNewAppointment}
      appointments={appointments}
      todaysAppointmentOnly={todaysAppointmentsOnly}
    />
  ) : (
    <NewAppointmentSection onNewAppointment={onNewAppointment} />
  );
  const memberStatsComponent = memberStatsMemberId ? (
    <MemberSessionStatsContainer
      variant={MemberSessionStatsVariant.OUTLINE}
      memberId={memberStatsMemberId}
      timezone={userTimezone}
    />
  ) : (
    <></>
  );

  return (
    <Paper
      data-testid="schedule-appointment-list"
      className={styles.appointments}
    >
      <Box p={2}>
        {header}
        {memberStatsComponent}
        <Table
          className={styles.borderCollapseSeparate}
          dataTestId="appointments-table-schedule"
        >
          <AppointmentTableHead
            columns={columns}
            hideClinicianName={hideClinicianName}
          />
          <AppointmentTableBody
            userId={userId!}
            timezone={appointmentTimezone}
            appointments={filteredAppointments}
            hideClinicianName={hideClinicianName}
            hideMemberName={hideMemberName}
            vaultEnabled={vaultEnabled}
            navigateTo={navigateTo}
            allowedNoteActions={allowedNoteActions}
            signedLockedAction={props.signedLockedAction}
            showPatientLinks={canViewPatients}
            role={role}
          />
        </Table>
      </Box>
    </Paper>
  );
}

type NewAppointmentSectionProps = {
  onNewAppointment: () => void;
  children?: ReactElement;
};

function NewAppointmentSection(props: NewAppointmentSectionProps) {
  const { children, onNewAppointment } = props;
  return children ? (
    <Grid
      container={true}
      alignItems="center"
      direction="row"
      spacing={2}
      justifyContent="space-between"
    >
      {children}
      <Grid item={true} className={styles.justifyEnd}>
        <Button
          className={styles.newAppointment}
          variant="contained"
          size="small"
          onClick={onNewAppointment}
        >
          + New Appointment
        </Button>
      </Grid>
    </Grid>
  ) : (
    <Grid
      container={true}
      alignItems="end"
      direction="row"
      spacing={2}
      justifyContent="flex-end"
    >
      <Grid item={true} className={styles.justifyEnd}>
        <Button
          className={styles.newAppointment}
          variant="contained"
          size="small"
          onClick={onNewAppointment}
        >
          + New Appointment
        </Button>
      </Grid>
    </Grid>
  );
}

type FiltersAndNewAppointmentProps = {
  onMemberFilter: (
    phrase: string | null,
    todaysAppointmentOnly: boolean,
  ) => void;
  onNewAppointment: () => void;
  appointments: Appointment[];
  todaysAppointmentOnly: boolean;
};

export function FiltersAndNewAppointmentBanner(
  props: FiltersAndNewAppointmentProps,
) {
  const { onNewAppointment, onMemberFilter, todaysAppointmentOnly } = props;
  const memberFilterPlaceholder = '🔍  Search member name or ID number';
  const results: Result[] = chain(props.appointments)
    .map((value) => ({
      display: `${value.member.name} (${value.member.id})`,
      id: value.member.id,
      queryOn: value.member.name,
    }))
    .uniqBy('id')
    .value();

  return (
    <NewAppointmentSection onNewAppointment={onNewAppointment}>
      <React.Fragment>
        <Grid
          className={styles.centeredGridItem}
          data-testid="header-and-new-appointment-member-filter-input"
          item={true}
        >
          <AutoCompleteSearch
            className={styles.memberFilter}
            results={results}
            searchCallback={(v) => {}}
            resultCallback={(r) =>
              onMemberFilter(r?.id ?? null, todaysAppointmentOnly)
            }
            placeholder={memberFilterPlaceholder}
            testId="filter-autocomplete"
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  data-testid="todays-appointment-only-filter"
                  color="primary"
                  checked={todaysAppointmentOnly}
                  onChange={(e) => onMemberFilter(null, !todaysAppointmentOnly)}
                />
              }
              label="Today's appointments only"
            />
          </FormGroup>
        </Grid>
      </React.Fragment>
    </NewAppointmentSection>
  );
}

function AppointmentTableHead(props: {
  hideMemberName?: boolean;
  hideClinicianName?: boolean;
  columns: Column[];
}) {
  const columns = props.columns.map(({ label, key }) => (
    <TableCell key={key}>{label}</TableCell>
  ));

  return <TableHead>{columns}</TableHead>;
}

function AppointmentTableBody(props: {
  userId: string;
  timezone: string;
  appointments: Appointment[];
  vaultEnabled: boolean;
  hideClinicianName?: boolean;
  hideMemberName?: boolean;
  navigateTo: (url: string) => void;
  allowedNoteActions: Set<NoteAction>;
  signedLockedAction: NoteAction.VIEW | NoteAction.DOWNLOAD;
  showPatientLinks: boolean;
  role?: UserRole;
}) {
  const {
    userId,
    appointments,
    timezone,
    vaultEnabled,
    hideClinicianName = false,
    hideMemberName = false,
    navigateTo,
    allowedNoteActions,
    signedLockedAction,
    showPatientLinks,
    role,
  } = props;

  const dispatch = useDispatch();
  const rows = appointments.map((appt, i) => {
    const status = appt.appointmentStatus
      ? formatAppointmentStatus(appt.appointmentStatus)
      : '-';

    const appointmentType = appt.appointmentType
      ? formatEnumValue(appt.appointmentType)
      : '-';

    const date = formatDate(appt.start, timezone);
    const startTime = formatTime(appt.start, timezone);
    const endTime = formatTime(appt.end, timezone);
    const zoomUrl = appt.zoomUrl ? (
      <MuiLink
        href={appt.zoomUrl}
        rel="noopener noreferrer"
        target="_blank"
        onClick={noPropagation}
      >
        {appt.zoomUrl}
      </MuiLink>
    ) : undefined;

    const onClick = (
      type: ClinicalAppointmentNoteType | NoteType,
      appointmentId: string,
    ) => {
      switch (type) {
        case ClinicalAppointmentNoteType.THERAPY_INTAKE:
        case NoteType.THERAPY_INTAKE:
          return dispatch(therapyIntakeClick({ appointmentId }));
        case ClinicalAppointmentNoteType.THERAPY_PROGRESS:
        case NoteType.THERAPY_PROGRESS:
          return dispatch(therapyProgressClick({ appointmentId }));
        case ClinicalAppointmentNoteType.PSYCHIATRY_INTAKE:
        case NoteType.PSYCHIATRY_INTAKE:
          return dispatch(psychiatryIntakeClick({ appointmentId }));
        case ClinicalAppointmentNoteType.PSYCHIATRY_PROGRESS:
        case NoteType.PSYCHIATRY_PROGRESS:
          return dispatch(psychiatryProgressClick({ appointmentId }));
      }
    };

    return (
      <TableRow
        key={appt.id}
        onClick={() => {
          dispatch(clickAppointmentListAppointment({ appointmentId: appt.id }));
          navigateTo(appointmentRoute({ appointmentId: appt.id, role }));
        }}
        hover={true}
        data-testid="schedule-appointment-row"
      >
        <TableCell data-testid={`date-${i}`} key={`date-${i}`}>
          {date}
        </TableCell>

        <TableCell data-testid={`time-${i}`} key={`time-${i}`}>
          <div>{`${startTime} - ${endTime}`}</div>
          <div>{zoomUrl}</div>
        </TableCell>

        {hideClinicianName === false && (
          <TableCell
            data-testid={`clinicianName-${i}`}
            key={`clinicianName-${i}`}
            classes={{ root: styles.appointmentCell }}
          >
            {appt.name}
          </TableCell>
        )}

        {!hideMemberName && (
          <TableCell
            data-testid={`memberName-${i}`}
            key={`memberName-${i}`}
            classes={{ root: styles.appointmentCell }}
          >
            {showPatientLinks ? (
              <Link
                onClick={noPropagation}
                to={patientProfileRoute(appt.member.id)}
              >
                {appt.member.name}
              </Link>
            ) : (
              appt.member.name
            )}
          </TableCell>
        )}

        <TableCell
          data-testid={`appointmentType-${i}`}
          key={`appointmentType-${i}`}
        >
          {appointmentType}
        </TableCell>

        <TableCell
          data-testid={`appointmentStatus-${i}`}
          key={`appointmentStatus-${i}`}
        >
          {status}
        </TableCell>

        <TableCell data-testid={`noteStatus-${i}`} key={`noteStatus-${i}`}>
          {appt.noteStatus}
        </TableCell>

        <TableCell data-testid={`actionBar-${i}`} key={`actionBar-${i}`}>
          <ClinicalNoteActionBar
            userId={userId}
            appointment={appt}
            vaultEnabled={vaultEnabled}
            navigateTo={navigateTo}
            className={styles.iconActionBar}
            allowedNoteActions={allowedNoteActions}
            signedLockedAction={signedLockedAction}
            onClick={onClick}
          />
        </TableCell>
      </TableRow>
    );
  });

  return <TableBody>{rows}</TableBody>;
}
