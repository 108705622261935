import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { isNull, isUndefined } from 'lodash';
import React, { Fragment } from 'react';

import styles from './ChangeIcon.module.scss';

interface ChangeIconProps {
  latestScore: number | null | undefined;
  previousScore: number | null | undefined;
}

const ChangeIcon = ({ latestScore, previousScore }: ChangeIconProps) => {
  // have to accept 0 as a valid score, and 0 is falsy
  if (
    !isNull(latestScore) &&
    !isUndefined(latestScore) &&
    !isNull(previousScore) &&
    !isUndefined(previousScore)
  ) {
    if (latestScore < previousScore) {
      return (
        <span className={styles.decrease}>
          <ArrowDownwardIcon fontSize="small" />
          <span>{previousScore - latestScore}</span>
        </span>
      );
    }
    if (latestScore > previousScore) {
      return (
        <span className={styles.increase}>
          <ArrowUpwardIcon fontSize="small" />
          <span>{latestScore - previousScore}</span>
        </span>
      );
    }
  }
  return <Fragment />;
};

export default ChangeIcon;
