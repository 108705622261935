import {
  arrayField,
  booleanField,
  field,
  FieldDefinitions,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  MedicalHistory,
  MedicalHistory_Allergy,
  MedicalHistory_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MedicalHistory';
import { BooleanWithComments } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanWithComments';
import {
  MedicalHistorySection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { TextAreaField, YesOrNoField } from 'app/notes-ui/forms/fields';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { isEmpty } from 'app/notes-ui/shared/ValidationRules';
import { AUTO_SAVE_DRAFT_DELAY } from 'app/notes-ui/utils';
import React from 'react';

type Props = {
  appointmentId: string;
  onSubmit: (data: MedicalHistorySection) => void;
  updateDraftNoteState: (data: MedicalHistorySection) => void;
  disabled?: boolean;
  savingNote?: boolean;
  initialValue?: MedicalHistory;
};

function requiredIfYesAnswer(
  value: string,
  state: MedicalHistory,
): string | undefined {
  const hasYes = Object.entries(state).some(([key, value]) => {
    if (key !== 'comments' && key !== 'appointmentId') {
      return (value as BooleanWithComments)?.isPresent;
    }
    return false;
  });
  if (hasYes && isEmpty(value)) return 'This field is required.';
  return undefined;
}

export const fieldDefs: FieldDefinitions<MedicalHistory> = {
  allergies: arrayField<MedicalHistory_Allergy>({ default: [], rules: [] }),
  appointmentId: field(),
  comments: stringField({ rules: [requiredIfYesAnswer] }),
  heartDisease: {
    description: stringField({ rules: [] }),
    isPresent: booleanField(),
  },
  nonPsychiatricCurrentMedicines: stringField({ rules: [] }),
  obstructiveSleepApnea: {
    description: stringField({ rules: [] }),
    isPresent: booleanField(),
  },
  seizures: {
    description: stringField({ rules: [] }),
    isPresent: booleanField(),
  },
  thyroidDisease: {
    description: stringField({ rules: [] }),
    isPresent: booleanField(),
  },
  traumaticBrainInjury: {
    description: stringField({ rules: [] }),
    isPresent: booleanField(),
  },
  version: field<MedicalHistory_Version>({
    default: MedicalHistory_Version.undefined_version,
  }),
};

export function MedicalHistoryForm(props: Props) {
  const { appointmentId, disabled, savingNote, initialValue } = props;

  const { fields, validate, getValue } = useForm<MedicalHistory>(
    fieldDefs,
    { ...initialValue, appointmentId } as MedicalHistory,
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: PsychiatrySectionName.MEDICAL_HISTORY,
        }),
    },
  );

  const onSubmit = async () => {
    const isValid = await validate();
    if (isValid) {
      props.onSubmit({
        data: getValue(),
        name: PsychiatrySectionName.MEDICAL_HISTORY,
      });
    }
  };

  return (
    <div>
      <TextAreaField
        label="Comments"
        disabled={disabled}
        testId="comments"
        placeholder="Comments (required only for “yes” answer)"
        field={fields.comments}
      />
      <div className={styles.formGroup}>
        <YesOrNoField
          disabled={disabled}
          testId="seizures"
          field={fields.seizures.isPresent}
          label="Seizures*"
        />
        <TextAreaField
          disabled={disabled}
          label=""
          testId="seizuresDescription"
          placeholder="Comments (optional)"
          field={fields.seizures.description}
        />
      </div>
      <div className={styles.formGroup}>
        <YesOrNoField
          disabled={disabled}
          testId="traumaticBrainInjury"
          field={fields.traumaticBrainInjury.isPresent}
          label="TBI / LOC*"
        />
        <TextAreaField
          disabled={disabled}
          testId="traumaticBrainInjuryDescription"
          label=""
          placeholder="Comments (optional)"
          field={fields.traumaticBrainInjury.description}
        />
      </div>
      <div className={styles.formGroup}>
        <YesOrNoField
          disabled={disabled}
          testId="obstructiveSleepApnea"
          field={fields.obstructiveSleepApnea.isPresent}
          label="Obstructive Sleep Apnea (OSA)*"
        />
        <TextAreaField
          disabled={disabled}
          testId="obstructiveSleepApneaDescription"
          label=""
          placeholder="Comments (optional)"
          field={fields.obstructiveSleepApnea.description}
        />
      </div>
      <div className={styles.formGroup}>
        <YesOrNoField
          disabled={disabled}
          testId="thyroidDisease"
          field={fields.thyroidDisease.isPresent}
          label="Thyroid Disease*"
        />
        <TextAreaField
          disabled={disabled}
          testId="thyroidDiseaseDescription"
          label=""
          placeholder="Comments (optional)"
          field={fields.thyroidDisease.description}
        />
      </div>
      <div className={styles.formGroup}>
        <YesOrNoField
          disabled={disabled}
          testId="heartDisease"
          field={fields.heartDisease.isPresent}
          label="Heart/Cardiovascular Disease*"
        />
        <TextAreaField
          disabled={disabled}
          testId="heartDiseaseDescription"
          label=""
          placeholder="Comments (optional)"
          field={fields.heartDisease.description}
        />
      </div>
      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </div>
  );
}

export const validate = (data: MedicalHistory | null) =>
  data !== null && validateForm(data, fieldDefs);
