import { VaultMasterKeys } from '@ginger.io/vault-core/dist/crypto/UserMasterKeySet';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthService } from 'app/state/features/auth/AuthService';
import tokenStorage from 'app/vault/VaultTokenStorage';

import { IAuthSlice, SessionState } from './types';

export const getInitialState = (): IAuthSlice => ({
  loggedIntoVault: false,
  sessionState: SessionState.NOT_INITIALIZED,
  vaultAuthChallenge: null,
  vaultJWTToken: null,
  vaultMasterKeys: null,
});

const authSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(AuthService.logout.fulfilled, (state) => {
      Object.assign(state, {
        loggedIntoVault: false,
        sessionState: SessionState.NOT_INITIALIZED,
        vaultAuthChallenge: null,
        vaultJWTToken: null,
        vaultMasterKeys: null,
      });
    });
    builder.addCase(AuthService.getAuthUserData.fulfilled, (state) => {
      const vaultJWTToken = tokenStorage.getIfNotExpired() || null;
      Object.assign(state, {
        loggedIntoVault: vaultJWTToken !== null,
        sessionState:
          vaultJWTToken !== null ? SessionState.VALID : SessionState.EXPIRED,
        vaultJWTToken,
      });
    });
  },
  initialState: getInitialState(),
  name: 'auth',
  // we can use extra reducers API to respond to actions of other slices
  reducers: {
    completeVaultAuth(state, { payload }: PayloadAction<{ jwtToken: string }>) {
      tokenStorage.set({ token: payload.jwtToken });
      Object.assign(state, {
        loggedIntoVault: true,
        vaultAuthChallenge: null,
        vaultJWTToken: payload.jwtToken,
      });
    },
    initiateVaultAuth(
      state,
      { payload }: PayloadAction<{ authChallenge: string }>,
    ) {
      state.vaultAuthChallenge = payload.authChallenge;
    },
    setVaultKeys(state, { payload }: PayloadAction<VaultMasterKeys>) {
      state.vaultMasterKeys = payload;
    },
    updateSessionState(state, { payload }: PayloadAction<SessionState>) {
      state.sessionState = payload;
    },
  },
});

export default authSlice.reducer;

export const {
  updateSessionState,
  completeVaultAuth,
  initiateVaultAuth,
  setVaultKeys,
} = authSlice.actions;
