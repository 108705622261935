import { TextareaAutosize } from '@mui/material';
import { InputBaseProps } from '@mui/material/InputBase';
import { FormError } from 'shared-components/FormError';
import { Width } from 'types/StyleTypes';
import { classNameCombiner } from 'utils';
import React, { KeyboardEventHandler } from 'react';

import styles from './TextArea.module.scss';

export type TextAreaProps = {
  name?: string;
  label?: string;
  value?: string;
  placeholder?: string;
  error?: string;
  rootClassName?: string;
  textAreaClassName?: string;
  // if you don't want the text area to expand, pass the same number to minRows and maxRows
  minRows?: number;
  // if you don't need the maximum limit, set it to undefined/don't pass this prop
  maxRows?: number;
  inputRef?: InputBaseProps['inputRef'];
  onChange: (value: string) => void;
  disabled?: boolean;
  width?: Width;
  onKeyDown?: KeyboardEventHandler;
  onFocus?: () => void;
  onBlur?: () => void;
};

export function TextArea(props: TextAreaProps) {
  const {
    value = '',
    error,
    placeholder,
    name,
    rootClassName = '',
    textAreaClassName = '',
    minRows = 1,
    maxRows,
    disabled,
    onKeyDown,
    onFocus = () => undefined,
    onBlur = () => undefined,
  } = props;
  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {
      target: { value },
    } = event;
    props.onChange(value);
  };

  const { width = Width.HALF } = props;

  let widthStyle = '';

  if (width === Width.FULL) {
    widthStyle = styles.fullWidth;
  } else if (width === Width.HALF) {
    widthStyle = styles.halfWidth;
  }

  return (
    <div
      className={classNameCombiner([styles.root, rootClassName, widthStyle])}
    >
      <TextareaAutosize
        minRows={minRows}
        maxRows={maxRows}
        data-testid={name}
        readOnly={disabled}
        className={classNameCombiner([styles.textArea, textAreaClassName])}
        placeholder={placeholder}
        name={name}
        value={value}
        onKeyDown={onKeyDown}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {error && <FormError error={error} />}
    </div>
  );
}
