import { SafetyConcern } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyConcern';

export enum SafetyConcerns {
  SUICIDE_RISK = 'Suicide Risk',
  SELF_HARM_RISK = 'Self-Harm Risk',
  HOMICIDE_RISK = 'Homicide Risk / Risk of Harm to Others',
  DOMESTIC_VIOLENCE_RISK = 'Domestic Violence Risk',
  SUBSTANCE_USE_RISK = 'High Risk Substance Use',
  EATING_DISORDER_RISK = 'Eating Disorder Risk',
  ABUSE_OF_VULNERABLE_RISK = 'Abuse of Vulnerable Populations',
  OTHER_RISKS = 'Other Concern',
}

export const safetyConcernEnumOptions = [
  {
    name: SafetyConcerns.SUICIDE_RISK,
    readonly: false,
    value: SafetyConcern.suicide_risk,
  },
  {
    name: SafetyConcerns.SELF_HARM_RISK,
    readonly: false,
    value: SafetyConcern.self_harm_risk,
  },
  {
    name: SafetyConcerns.DOMESTIC_VIOLENCE_RISK,
    readonly: false,
    value: SafetyConcern.domestic_violence_risk,
  },
  {
    name: SafetyConcerns.SUBSTANCE_USE_RISK,
    readonly: false,
    value: SafetyConcern.substance_use_risk,
  },
  {
    name: SafetyConcerns.EATING_DISORDER_RISK,
    readonly: false,
    value: SafetyConcern.eating_disorder_risk,
  },
  {
    name: SafetyConcerns.ABUSE_OF_VULNERABLE_RISK,
    readonly: false,
    value: SafetyConcern.abuse_of_vulnerable_risk,
  },
  {
    name: SafetyConcerns.OTHER_RISKS,
    readonly: false,
    value: SafetyConcern.other_risks,
  },
];

export const FULL_SAFETY_PLAN_FIELDS_CONCERNS = [
  SafetyConcerns.SUICIDE_RISK,
  SafetyConcerns.SELF_HARM_RISK,
  SafetyConcerns.SUBSTANCE_USE_RISK,
  SafetyConcerns.HOMICIDE_RISK,
  SafetyConcerns.EATING_DISORDER_RISK,
];

export const FULL_SAFETY_PLAN_FIELDS_CONCERN = [
  SafetyConcern.suicide_risk,
  SafetyConcern.self_harm_risk,
  SafetyConcern.substance_use_risk,
  SafetyConcern.homicide_risk,
  SafetyConcern.eating_disorder_risk,
];

export const Labels = {
  anyChangesOrRisks: {
    id: 'anyChangesOrRisks',
    label:
      'Were there any changes to risk or new safety concerns discussed in this session?',
    label2:
      'Were any current or historical safety risks presented by this member?',
    subtext:
      'Suicide, harm to self or others, substance use, violence or abuse, eating disorder, risk involving an adolescent or minor',
  },
  btnAddAdditionalSafetyConcern: {
    id: 'addAdditionalSafetyConcern',
    label: 'Additional Safety Concern',
  },
  currentHomicidalIdeation: {
    current: {
      id: 'currentSuicidalIdeation_current',
      label: 'Current',
    },
    description: {
      id: 'currentSuicidalIdeation_description',
      label: 'Summarization of current homicide risk / risk of harm to others',
      placeholder:
        'Circumstances, time frame, any gesture / attempt made, treatment provided as a result…',
    },
    hasPast: {
      id: 'urrentSuicidalIdeation_hasPast',
      label: 'Past',
    },
    intent: {
      id: 'currentSuicidalIdeation_intent',
      label: 'Intent',
    },
    means: {
      id: 'currentSuicidalIdeation_means',
      label: 'Means',
    },
    pastSummarization: {
      id: 'currentSuicidalIdeation_pastSummarization',
      label: 'Summarization of past homicide risk / risk of harm to others',
      placeholder:
        'Circumstances, time frame, any gesture / attempt made, treatment provided as a result…',
    },
    plan: {
      id: 'currentSuicidalIdeation_plan',
      label: 'Plans',
    },
    specificTargetsEntityOrLocationConsidered: {
      id: 'currentSuicidalIdeation_specificTargetsEntityOrLocationConsidered',
      label: 'Identifiable target(s), entity, or location?',
    },
    thoughts: {
      id: 'currentSuicidalIdeation_thoughts',
      label: 'Thoughts',
    },
  },
  currentSelfInjury: {
    currentSummarization: {
      id: 'currentSelfInjury_currentSummarization',
      label: 'Summarization of current self-harm risk',
      placeholder:
        'Frequency, instrument used, part of body harmed, extent of injury, treatment provided as a result…',
    },
    everSoughtMedicalAttentionAsAResult: {
      id: 'currentSelfInjury_everSoughtMedicalAttentionAsAResult',
      label: 'Ever sought medical attention as a result?',
    },
    hasPast: {
      id: 'currentSelfInjury_hasPast',
      label: 'Past',
    },
    isPresent: {
      id: 'currentSelfInjury_isPresent',
      label: 'Current',
    },
    pastEverSoughtMedicalAttentionAsAResult: {
      id: 'currentSelfInjury_pastEverSoughtMedicalAttentionAsAResult',
      label: 'Ever sought medical attention as a result?',
    },
    pastSummarization: {
      id: 'currentSelfInjury_pastSummarization',
      label: 'Summarization of past self-harm risk',
      placeholder:
        'Frequency, instrument used, part of body harmed, extent of injury, treatment provided as a result…',
    },
  },
  currentSuicidalIdeation: {
    current: {
      id: 'currentSuicidalIdeation_current',
      label: 'Current',
    },
    description: {
      id: 'currentSuicidalIdeation_description',
      label: 'Summarization of current suicide risk',
      placeholder:
        'Circumstances, time frame, any gesture / attempt made, treatment provided as a result…',
    },
    hasPast: {
      id: 'urrentSuicidalIdeation_hasPast',
      label: 'Past',
    },
    intent: {
      id: 'currentSuicidalIdeation_intent',
      label: 'Intent',
    },
    means: {
      id: 'currentSuicidalIdeation_means',
      label: 'Means',
    },
    pastSummarization: {
      id: 'currentSuicidalIdeation_pastSummarization',
      label: 'Summarization of past suicide risk',
      placeholder:
        'Circumstances, time frame, any gesture / attempt made, treatment provided as a result…',
    },
    plan: {
      id: 'currentSuicidalIdeation_plan',
      label: 'Plans',
    },
    thoughts: {
      id: 'currentSuicidalIdeation_thoughts',
      label: 'Thoughts',
    },
  },
  domesticViolenceRisk: {
    id: 'domesticViolenceRisk',
    label: 'Summarization of domestic violence risk',
    placeholder: 'FIDO assessment of risk and details of safety plan...',
  },
  eatingDisorderRisk: {
    id: 'eatingDisorderRisk',
    label: 'Summarization of eating disorder risk',
    placeholder: 'FIDO assessment of risk and details of safety plan...',
  },
  highSubstanceRisk: {
    id: 'highSubstanceRisk',
    label: 'Summarization of high risk substance use',
    placeholder: 'FIDO assessment of risk and details of safety plan...',
  },
  otherConcerns: {
    id: 'otherConcerns',
    label: 'Summarization of concern',
    placeholder: 'FIDO assessment of risk and details of safety plan...',
  },
  safetyPlan: {
    actionsTaken: {
      onCallClinicianContactedNotes: {
        id: 'onCallClinicianContactedNotes',
        label: 'Who?',
        placeholder: 'Name of on-call clinician',
      },
    },
    id: 'safetyPlan',
    psychiatryQuestions: {
      agenciesToContactInCrisis: {
        id: 'safetyPlan_psychiatryQuestions_agenciesToContactInCrisis',
        labels: ['Professionals / agencies I can contact during a crisis:'],
        placeholder: 'Type here...',
        subtexts: [
          'Suicide Prevention Lifeline Phone:  988\nText Option: text HOME to 741741',
        ],
      },
      safetyPlanDocumentation: {
        id: 'safetyPlan_psychiatryQuestions_safetyPlanDocumentation',
        label: 'Document a safety plan',
        placeholder: 'Type here...',
      },
    },
    safetyPlanCheckboxStatus:
      'Due to imminent risk, safety plan was not completed',
    status: {
      id: 'safetyPlan_status',
      label: 'Due to imminent risk, safety plan was not completed',
    },
    therapyQuestions: {
      agenciesToContactInCrisis: {
        id: 'agenciesToContactInCrisis',
        labels: ['Professionals / agencies I can contact during a crisis:'],
        placeholder: 'Local Urgent Care Services...',
        subtexts: [
          'Suicide Prevention Lifeline Phone:  988 \nText Option: text HOME to 741741',
        ],
      },
      externalStrategiesOrientationForMentalHealthCrisis: {
        id: 'externalStrategiesOrientationForMentalHealthCrisis',
        placeholder: 'People, social settings, virtual interactions...',
        subtext: 'External coping strategies:',
      },
      internalStrategiesOrientationForMentalHealthCrisis: {
        id: 'internalStrategiesOrientationForMentalHealthCrisis',
        label: 'Things I can do to take my mind off my problems',
        placeholder: 'Relaxation techniques, physical activities...',
        subtext: 'Internal coping strategies:',
      },
      peopleToContactInCrisis: {
        id: 'peopleToContactInCrisis',
        labels: ['People I can ask for help'],
        placeholder: 'Name and how to contact',
        subtexts: ['1 (within 5 miles)', '2', '3'],
      },
      rememberWhatsImportant: {
        id: 'rememberWhatsImportant',
        label: 'Things that are important to me and worth living for:',
        placeholder: 'Short term and long term...',
      },
      safeEnvironmentOrientation: {
        id: 'safeEnvironmentOrientation',
        label: 'Making the environment safe',
        placeholder:
          'Removal and safeguarding of lethal means — guns, pills, sharps...',
      },
      warningSignsAndTriggersForDevelopingCrisis: {
        id: 'warningSignsAndTriggersForDevelopingCrisis',
        label:
          'Warning signs and triggers that indicate that a crisis may be developing or contribute to the crisis',
        placeholder: 'Thoughts, images, mood, situation, behavior...',
      },
    },
    title: 'Safety Plan',
  },
  vulnerablePopulationsAbuse: {
    id: 'domesticViolenceRisk',
    label: 'Summarization of abuse',
    placeholder: 'FIDO assessment of risk and details of safety plan...',
    subtitle: '(child(ren), dependent adults, elderly)',
  },
};
