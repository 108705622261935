import React from 'react';
import Tooltip from 'shared-components/tooltip/Tooltip';

import styles from './AutoMessage.module.scss';

export const AutoMessage = ({
  message,
  dateAndTime,
  title,
}: {
  message: string;
  dateAndTime: string;
  title: string;
}) => {
  return (
    <Tooltip
      classes={{ popper: styles.popper, tooltip: styles.tooltip }}
      title={dateAndTime}
      enterDelay={0}
      enterTouchDelay={0}
      placement="bottom-end"
    >
      <div className={styles.container}>
        <div className={styles.bubble}>
          <h5 className={styles.title}>{title}</h5>
          <span className={styles.text}>{message}</span>
        </div>
      </div>
    </Tooltip>
  );
};
