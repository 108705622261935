import React from 'react';

export function ComingSoon() {
  return (
    <div
      data-testid="comingSoon"
      style={{
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
      }}
    >
      <h1>Coming Soon</h1>
    </div>
  );
}
