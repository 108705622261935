export type State = {
  activeTab: string | null;
  openTabs: string[];
  initialized: boolean;
};

export const getInitialMultiTabState = (): State => {
  return {
    activeTab: null,
    initialized: false,
    openTabs: [],
  };
};
