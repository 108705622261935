import { gql } from '@apollo/client';

export const todaysMemberChannels = gql`
  query TodaysMemberChannels {
    scheduled: getTodaysScheduledSessionsTodayForMe(
      pagination: { maxItemsPerPage: 100 }
    ) {
      items {
        member {
          id
        }
        conversationStats {
          id
          memberCoachChannelId
        }
      }
    }
    conversations: getMemberWithOpenChatConversationsForMe(
      pagination: { maxItemsPerPage: 100 }
    ) {
      items {
        member {
          id
        }
        conversationStats {
          id
          memberCoachChannelId
        }
      }
    }
  }
`;
export const todaysMemberTaskChannels = gql`
  query TodaysMemberTaskChannels(
    $timezone: String!
    $excludeMemberIds: [String!]!
  ) {
    tasks: getTodaysMembersWithActiveTasksForMe(
      input: {
        timezone: $timezone
        excludeMemberIds: $excludeMemberIds
        pagination: { maxItemsPerPage: 100 }
      }
    ) {
      items {
        member {
          id
        }
        conversationStats {
          id
          memberCoachChannelId
        }
      }
    }
  }
`;

export const updateRiskTaskIndicatorMutation = gql`
  mutation TaskUpdate($input: BulkUpdateCareProviderTaskInput!) {
    bulkUpdateCareProviderTask(input: $input) {
      results {
        ok
        error
        task {
          id
          hasViewed
        }
      }
    }
  }
`;
