import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import { FormError } from 'shared-components/FormError';
import { Size } from 'types/StyleTypes';
import React from 'react';

import { Checkbox } from './Checkbox';
import styles from './CheckboxGroup.module.scss';
import { NoteFormControlLabel } from './NoteFormControlLabel';

export type Options<T extends Record<string, any>> = {
  [K in keyof T]: boolean;
};

export type Props<T> = {
  name?: string;
  question: string;
  error?: string;
  value: Options<T>;
  onChange?: (values: Options<T>) => void;
  disabled?: boolean;
  tooltips?: Record<string, string>;
  labelSize?: Size;
};

export function CheckboxGroup<T extends Record<string, any>>(props: Props<T>) {
  const { tooltips = {}, error, disabled } = props;
  const onChange = (name: string, checked: boolean) => {
    const updatedState: Options<T> = { ...props.value, [name]: checked };
    props.onChange && props.onChange(updatedState);
  };

  const Label = props.question
    ? NoteFormControlLabel
    : ({ ...props }) => <div {...props} />;
  return (
    <FormControl
      data-testid={props.name || 'checkboxGroup'}
      component="div"
      classes={{ root: styles.root }}
    >
      <Label
        size={props.labelSize}
        data-testid="questionLabel"
        label={props.question}
      >
        <FormGroup>
          {Object.entries(props.value).map(([label, checked], index) => (
            <Checkbox
              key={label}
              tooltip={tooltips[label]}
              label={label}
              checked={checked}
              testId={`answerCheckbox-${index}`}
              onChange={(checked) => onChange(label, checked)}
              disabled={disabled}
            />
          ))}
        </FormGroup>
        <FormError error={error} />
      </Label>
    </FormControl>
  );
}
