import gql from 'graphql-tag';

export const appointmentQuery = gql`
  query ClinicalAppointmentById($input: GetClinicalAppointmentInput!) {
    getClinicalAppointment(input: $input) {
      clinician {
        id
        fullName
        user {
          preferences {
            timezone
          }
        }
      }
      start
      end
      member {
        id
        firstName
        lastName
        preferences {
          timezone
        }
      }
      appointmentStatus
      cancelledReason
      type
      zoomMeetingId
      details
      clinicalNote {
        id
        status
      }
    }
  }
`;

export const createAppointmentForMeMutation = gql`
  mutation CreateClinicalAppointmentForMe(
    $input: CreateClinicalAppointmentForMeInput!
  ) {
    createAppointmentForMe(input: $input) {
      appointment {
        id
      }
    }
  }
`;

export const createAppointmentForClinicianMutation = gql`
  mutation CreateClinicalAppointment(
    $input: CreateClinicalAppointmentForClinicianInput!
  ) {
    createAppointmentForClinician(input: $input) {
      appointments {
        id
      }
    }
  }
`;

export const updateAppointmentForMeMutation = gql`
  mutation UpdateClinicalAppointmentForMe(
    $input: UpdateClinicalAppointmentForMeInput!
  ) {
    updateAppointmentForMe(input: $input) {
      appointment {
        id
      }
    }
  }
`;

export const updateAppointmentForClinicianMutation = gql`
  mutation UpdateClinicalAppointmentForClinician(
    $input: UpdateClinicalAppointmentForClinicianInput!
  ) {
    updateAppointmentForClinician(input: $input) {
      appointment {
        id
      }
    }
  }
`;

export const getMemberTimezoneAndBillingByIdQuery = gql`
  query GetMemberTimezoneById($id: ID!) {
    getMember(input: { id: $id }) {
      preferences {
        timezone
      }
      firstName
      lastName
      id
      billing {
        creditCardStatus
      }
      coverageDetails {
        therapy {
          sessionsCoveredByEmployer
          sessionsCoveredUsed
          sessionsAnticipated
        }
        psychiatry {
          sessionsCoveredByEmployer
          sessionsCoveredUsed
          sessionsAnticipated
        }
        both {
          sessionsCoveredByEmployer
          sessionsCoveredUsed
          sessionsAnticipated
        }
        renewalDate
        upcomingScheduled
      }
    }
  }
`;

export const getMemberTherapySessionsQuery = gql`
  query GetMemberTherapySessions($id: ID!) {
    getMember(input: { id: $id }) {
      id
      billing {
        creditCardStatus
      }
      coverageDetails {
        coverageType
        therapy {
          sessionsCoveredByEmployer
          sessionsCoveredUsed
        }
      }
    }
  }
`;

export const getClinicianTimezoneQuery = gql`
  query GetClinicianTimezone($input: GetClinicianInput!) {
    getClinician(input: $input) {
      user {
        preferences {
          timezone
        }
      }
      fullName
      id
    }
  }
`;
export const getMembersForMe = gql`
  query GetMembersForMe($input: GetMembersForMeInput!) {
    getMembersForMe(input: $input) {
      members {
        id
        firstName
        lastName
        preferredFirstName
        preferredLastName
      }
    }
  }
`;

export const getClinicianTimeAvailableQuery = gql`
  query GetClinicianTimeAvailable($input: GetClinicianTimeAvailableInput!) {
    getClinicianTimeAvailable(input: $input) {
      isTimeAvailable
    }
  }
`;

export const getClinicianAppointmentConfig = gql`
  query getClinicianAppointmentConfig(
    $input: ClinicianNewAppointmentsConfigInput!
  ) {
    maxRecurrenceCount(input: $input)
  }
`;

export const getTasksForMember = gql`
  query GetTasksForMember($input: GetTasksForMemberInput!) {
    getTasksForMember(input: $input) {
      tasks {
        id
        callToAction
        reasonForCreation
        priority
        relatedCareProviderName
      }
    }
  }
`;
