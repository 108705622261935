import { selectCurrentPubnubChannelId } from 'app/state/inbox/selectors';
import { nullifyCountdown } from 'app/state/timed-sends/actions';
import { selectCountDownForDelayedMessage } from 'app/state/timed-sends/selectors';
import React from 'react';
import { useDispatch } from 'react-redux';

import styles from './CountdownTimer.module.scss';

const SECONDS_PER_MINUTE = 60;

export const CountdownTimer = ({ sendNow }: { sendNow: () => void }) => {
  const currentChannelId = selectCurrentPubnubChannelId();
  const secondsRemaining = selectCountDownForDelayedMessage(currentChannelId);
  const dispatch = useDispatch();

  const calculateCountdownString = (timeRemaining: number): string => {
    if (timeRemaining === 1) {
      return `${timeRemaining} second...`;
    }
    if (timeRemaining <= SECONDS_PER_MINUTE) {
      return `${timeRemaining} seconds...`;
    }
    const minutes = Math.floor(timeRemaining / SECONDS_PER_MINUTE);
    const seconds = timeRemaining % SECONDS_PER_MINUTE;
    const minuteLabel = minutes === 1 ? 'minute' : 'minutes';
    const secondsLabel = seconds === 1 ? 'second' : 'seconds';
    return `${minutes} ${minuteLabel} ${seconds} ${secondsLabel}...`;
  };

  const cancelTimer = () => {
    dispatch(nullifyCountdown({ channelId: currentChannelId }));
  };

  return (
    <div className={styles.container}>
      <p className={styles.text}>
        Sending message in {calculateCountdownString(secondsRemaining)}
      </p>
      <div>
        <button className={styles.blueButton} onClick={sendNow}>
          Send Now
        </button>
        <button className={styles.grayButton} onClick={cancelTimer}>
          Cancel
        </button>
      </div>
    </div>
  );
};
