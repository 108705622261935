import { ClassNameMap } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import MUITableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React, { ReactNode } from 'react';
import { ActionMenu } from 'shared-components/menu/ActionMenu';

import checkboxStyles from './Checkbox.module.scss';
import { RenderCellProps, RowType } from './Table';
import styles from './TableBody.module.scss';
import { ActionItem, HeadCell } from './TableHead';

export type TableBodyClassKey = 'root' | 'row' | 'cell' | 'emptyCell';

type Props<T, U> = {
  rows: RowType<T>;
  columns: HeadCell<T>[];
  onSelectClick: (row: string) => void;
  selectedRows: Array<keyof RowType<T>>;
  actionItems?: ActionItem<U>[];
  onActionItemClick?: (item: U, row: T) => void;
  renderCell?: (props: RenderCellProps<T>) => ReactNode;
  disabled?: boolean;
  showCheckboxes?: boolean;
  classes?: Partial<ClassNameMap<TableBodyClassKey>>;
  noDataText?: string;
  dataTestId?: string;
};

export function TableBody<T, U>(props: Props<T, U>) {
  const {
    columns,
    disabled,
    renderCell,
    showCheckboxes,
    onSelectClick,
    actionItems = [],
    classes = {},
    selectedRows,
    rows,
    dataTestId,
    onActionItemClick,
    noDataText,
  } = props;

  const isSelected = (row: string) => selectedRows.indexOf(row) !== -1;
  const enableAction = actionItems.length > 0;

  // column length + checkbox cell + action item cell
  const checkboxCellSpan = showCheckboxes ? 1 : 0;
  const actionItemCellSpan = enableAction ? 1 : 0;
  const emptyCellColSpan =
    columns.length + checkboxCellSpan + actionItemCellSpan;
  const tableRows = Object.entries(rows);
  return (
    <MUITableBody
      className={`${styles.root} ${classes.root || ''}`}
      data-testid={dataTestId}
    >
      {tableRows.map(([rowKey, row], index) => {
        const isItemSelected = isSelected(rowKey);
        const labelId = `table-checkbox-${index}`;
        return (
          <TableRow
            data-testid={`${rowKey}Row`}
            hover={true}
            classes={{ root: classes.row, selected: styles.rowSelected }}
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={`${rowKey}`}
          >
            {showCheckboxes && (
              <TableCell role="checkbox" padding="checkbox">
                <Checkbox
                  data-testid={`${rowKey}-checkbox`}
                  disableRipple={true}
                  disableFocusRipple={true}
                  disableTouchRipple={true}
                  disabled={disabled}
                  onChange={() => onSelectClick(rowKey)}
                  checked={isItemSelected}
                  classes={{
                    checked: checkboxStyles.checkbox,
                    root: checkboxStyles.root,
                  }}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </TableCell>
            )}
            {columns.map((column, columnIndex) => {
              const { key: name } = column;
              const columnKey = `${name.toString()}-${columnIndex}`;
              return (
                <TableCell
                  key={columnKey}
                  className={`${styles.cell} ${classes.cell || ''}`}
                  padding={
                    showCheckboxes && columnIndex === 0 ? 'none' : 'normal'
                  }
                >
                  {renderCell &&
                    renderCell({ column, row, selected: isItemSelected })}

                  {!renderCell && (
                    <span data-testid={`${name.toString()}-text`}>
                      {row[column.key]}
                    </span>
                  )}
                </TableCell>
              );
            })}
            {enableAction && (
              <TableCell
                data-testid="actionItemMenu"
                key={`${rowKey}-actionItem`}
                className={`${styles.actionMenu} ${styles.cell} ${
                  classes.cell || ''
                }`}
              >
                <ActionMenu
                  data-testid="actionItemMenu"
                  options={actionItems}
                  onSelectOption={(action) => {
                    onActionItemClick && onActionItemClick(action, row);
                  }}
                />
              </TableCell>
            )}
          </TableRow>
        );
      })}
      {tableRows.length <= 0 && (
        <TableRow data-testid="emptyRow" className={classes.row}>
          <TableCell className={classes.cell} colSpan={emptyCellColSpan}>
            <div
              data-testid="emptyRow-text"
              className={`${styles.emptyCell} ${classes.emptyCell || ''}`}
            >
              {noDataText || 'No Data'}
            </div>
          </TableCell>
        </TableRow>
      )}
    </MUITableBody>
  );
}
