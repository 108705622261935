import { ToggleOff, ToggleOn } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import { classNameCombiner } from 'utils';
import React, { ReactNode } from 'react';

import styles from './AppointmentsAndNotesCard.module.scss';
import { useCardContext } from './CardContext';

export type AppointmentsAndNotesCard = {
  children: ReactNode;
  boxTitle?: string;
  onToggle?: (isOn: boolean) => void;
  'data-testid'?: string;
  size?: 'small' | 'medium' | 'large';
  actionMenu?: ReactNode;
  toggleInitialState?: boolean;
};

export function AppointmentsAndNotesCard({
  children,
  boxTitle,
  onToggle,
  'data-testid': testId,
  size = 'medium',
  actionMenu,
  toggleInitialState = false,
}: AppointmentsAndNotesCard): JSX.Element {
  const { isExpanded, toggle } = useCardContext(toggleInitialState);

  const handleToggleClick = (): void => {
    const newToggleStatus = !isExpanded;
    toggle();
    if (onToggle) onToggle(newToggleStatus);
  };

  const toggleButton: JSX.Element = isExpanded ? (
    <ToggleOn />
  ) : (
    <ToggleOff className={styles.disabled} />
  );

  const boxHeader: JSX.Element | null = boxTitle ? (
    <>
      <div className={styles.boxHeader}>
        <button
          data-testid="toggle-btn"
          className={styles.toggleButton}
          onClick={handleToggleClick}
        >
          {toggleButton}
        </button>
        <h4 className={styles.boxTitle}>{boxTitle}</h4>
        <div className={styles.actionMenu}>{actionMenu}</div>
      </div>
    </>
  ) : null;

  const contentStyle: string = boxTitle
    ? classNameCombiner([
        styles.boxContent,
        styles[`boxContent-${size}`],
        styles.hr,
      ])
    : styles.boxContent;
  const boxContent = (
    <Collapse
      in={true}
      timeout={300}
      children={children}
      className={classNameCombiner([contentStyle])}
    />
  );

  return (
    <div
      data-testid={testId}
      className={classNameCombiner([styles.box, styles[`box-${size}`]])}
    >
      {boxHeader}
      {boxContent}
    </div>
  );
}
