import { ItemState } from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/member-tasks/ItemState';
import ActiveCareMembers from 'app/care-team/active-care-members/ActiveCareMembers';
import CareMemberProfile from 'app/care-team/care-member-profile/CareMemberProfile';
import styles from 'app/care-team/care-team-v2/CareTeamV2.module.scss';
import CareTeamV2Button from 'app/care-team/care-team-v2/CareTeamV2Button';
import PastCareMembers from 'app/care-team/past-care-team-members/PastCareMembers';
import { SICoachAddedBanner } from 'app/care-team/si-coach-added-banner/SICoachAddedBanner';
import { ErrorState } from 'shared-components/error-state/ErrorState';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { Card } from 'shared-components/Card';
import { Loader } from 'shared-components/loader/Loader';
import useTaskMutations from 'app/member-chart-cards/tasks/useTaskMutations';
import { useCareProviderTasks } from 'app/member-chart-cards/tasks/useTasks';
import { isTest } from 'app/Stage';
import { useAppState } from 'app/state';
import {
  subscribeCoachChannels,
  toggleActiveCareTeamView,
  unsubscribeCoachChannels,
} from 'app/state/care-team/actions';
import { ActiveView } from 'app/state/care-team/schema';
import {
  selectCareTeamFetchStatus,
  selectClinicalState,
  selectCoachState,
} from 'app/state/care-team/selectors';
import { Status } from 'app/state/status/types/StateSlice';
import { isCoach } from 'utils';
import { Reason } from 'generated/globalTypes';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

export type CareTeamV2Props = {
  'data-testid': string;
  columns?: 1 | 2 | undefined;
  memberId: string;
};

export const CareTeamV2 = (props: CareTeamV2Props) => {
  const dispatch = useDispatch();
  const { memberId, 'data-testid': testId, columns = 2 } = props;
  // care team card redux states
  const { error, isFetching } = useAppState(selectCareTeamFetchStatus);
  const onToggle = useCallback(
    () => dispatch(toggleActiveCareTeamView({})),
    [],
  );
  const { dismissTasksByReason } = useTaskMutations({ memberId });
  const tasks = useCareProviderTasks(memberId);
  const {
    enable_tasks_v2,
    enable_coach_shift_status_changes,
  } = useFeatureFlags().transientFeatureFlags;
  const role = useAppState(({ user }) => user.role!);

  const { currentCoaches } = selectCoachState();

  useEffect(() => {
    if (
      enable_coach_shift_status_changes &&
      isCoach(role) &&
      currentCoaches.length > 0
    ) {
      dispatch(subscribeCoachChannels({ coaches: currentCoaches }));
    }
    return () => {
      // to avoid unneceseary pubnub connections, we unsubscribe from all
      // coach channels when the component unmounts
      dispatch(unsubscribeCoachChannels());
    };
  }, [
    dispatch,
    memberId,
    enable_coach_shift_status_changes,
    role,
    currentCoaches,
  ]);

  useEffect(() => {
    if (!enable_tasks_v2 || tasks.status !== Status.COMPLETE || isFetching)
      return;

    const unCompletedCollabTasks = tasks.data.todaysItems.filter(
      ({ label, state }) =>
        label === Reason.NewActionableCollabMessage &&
        [ItemState.unchecked, ItemState.undefined_state, undefined].includes(
          state,
        ),
    );
    if (unCompletedCollabTasks.length > 0) {
      dismissTasksByReason([Reason.NewActionableCollabMessage]);
    }
  }, [tasks.status, isFetching]);

  if (error) return <ErrorState error={error} />;

  return (
    <>
      <Card
        data-testid={testId}
        size="large"
        boxTitle="Care Team"
        toggleInitialState={false}
        toggleLabel="Show past"
        onToggle={onToggle}
      >
        {isFetching && (
          <div className={styles.loading}>
            <Loader topMargin={false} />
          </div>
        )}
        <CareProviderProfiles columns={columns} />
        <CareTeamV2Button memberId={memberId} />
      </Card>
      {/* Used in test context to allow unit test wait for the dismiss task logic to complete before making any assertion */}
      {isTest() && !isFetching && tasks.status === Status.COMPLETE && (
        <span data-testid="loadTaskComplete" />
      )}
    </>
  );
};
export const CareProviderProfiles = React.memo(
  ({ columns = 2 }: { columns?: CareTeamV2Props['columns'] }) => {
    const {
      selectedCareTeamMemberProfile: activeMemberProfile,
      activeView: careTeamActiveView,
    } = useAppState(
      ({ careTeam: { activeView, selectedCareTeamMemberProfile } }) => ({
        activeView,
        selectedCareTeamMemberProfile,
      }),
    );
    const { pastCoaches, currentCareTeam, hasSICoach } = selectCoachState();
    const {
      pastTherapists,
      pastPsychiatrists,
      currentTherapists,
      currentPsychiatrists,
    } = selectClinicalState();
    return (
      <>
        {!activeMemberProfile && (
          <>
            {careTeamActiveView === ActiveView.PAST_CARE_TEAM ? (
              <PastCareMembers
                coaches={pastCoaches}
                therapists={pastTherapists}
                psychiatrists={pastPsychiatrists}
              />
            ) : (
              <ActiveCareMembers
                columns={columns}
                coachTeam={currentCareTeam}
                therapists={currentTherapists}
                psychiatrists={currentPsychiatrists}
              />
            )}
          </>
        )}

        {activeMemberProfile && <CareMemberProfile />}
        {hasSICoach && <SICoachAddedBanner />}
      </>
    );
  },
);
