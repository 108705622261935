import { VaultIds } from './VaultIds';

export class TerminationNoteIds extends VaultIds {
  protected static prefix = 'nonAppointment';

  protected static noteName = 'terminationNotes';

  protected static delimiter = '-';

  static sectionTags(memberId: string, clinicianId: string): string[] {
    return [
      this.myNotesTag(memberId),
      this.memberNotesTag(memberId),
      this.memberNonAppointmentNotes(memberId),
      this.memberPrefixedId(memberId, 'clinician', clinicianId, this.noteName),
    ];
  }

  static subSectionTags(memberId: string, clinicianId: string): string[] {
    return [
      `member-chart-${memberId}-coach-notes`,
      `member-chart-${memberId}-${clinicianId}`,
    ];
  }
}
