import { useEffect, useRef, useState } from 'react';

/**
 * Custom hook to get the width of an element
 * @example
 * const { elementWidth, elementRef, refreshElementWidth } = useElementWidth()
 * <div ref={elementRef}>...</div>
 */
const useElementWidth = () => {
  const [elementWidth, setElementWidth] = useState(0);
  const elementRef = useRef<HTMLDivElement>(null);

  const refreshElementWidth = () => {
    if (elementRef.current) {
      const width = elementRef.current.offsetWidth; // Access the offsetWidth property of the current property
      setElementWidth(width);
    }
  };

  useEffect(() => {
    // Attach event listener to window resize
    window.addEventListener('resize', refreshElementWidth);

    // Cleanup: Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', refreshElementWidth);
    };
  }, [elementRef]);

  useEffect(() => {
    // set the initial width of the element
    refreshElementWidth();
  }, []);

  return { elementRef, elementWidth, refreshElementWidth };
};

export default useElementWidth;
