import { useEffect } from 'react';

/** Runs a function once when the consuming React component mounts
 *
 *  This hook primarily exists to avoid spamming warnings about useEffect having non-exhaustive deps due to:
 *  https://github.com/facebook/create-react-app/issues/6880
 */
export function useOnMount(f: () => void) {
  useEffect(
    f,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
