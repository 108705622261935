import {
  UnreadMessagesMap,
  UpdateUnreadMessagesPayload,
} from 'app/coach/chat/types';
import { countUnreadMessagesForAllConversations } from 'app/coach/chat/utils';
import {
  matchesPubNubErrorCategory,
  PubNubErrorCategory,
} from 'app/coach/pubnub/errors';
import { isString } from 'util';

import { setUnreadMessages } from '../actions';
import { ActionHandler } from './types';

// this handler now fetches history only to update the unreadMessages, it doesn't update the messagesMap
export const onUpdateUnreadMessages = async ({
  action,
  redux,
  context,
}: ActionHandler<{ inboxItemsData: UpdateUnreadMessagesPayload }>) => {
  const { inboxItemsData } = action.payload;
  const { pubnub, logger } = context.services;
  if (inboxItemsData.length === 0) return;
  const channelsForInboxItemsToInit = inboxItemsData.map(
    (item) => item.memberCoachChannelId,
  );
  const invalidChannels: Array<any> = [];
  const channelsAndListenerReadTimes = inboxItemsData
    .filter((inboxItem) => {
      if (
        !inboxItem.memberCoachChannelId ||
        !isString(inboxItem.memberCoachChannelId)
      ) {
        invalidChannels.push(inboxItem);
        return false;
      }
      return true;
    })
    .map((inboxItem) => {
      // If the coach has never read any messages from this member (i.e. a newly created ChatConversation), the
      // lastListenerReadTimetoken will be null. In that case, use a timetoken of "0" to tell PubNub to count unread
      // messages since the beginning of the epoch.
      if (!isString(inboxItem.lastListenerReadTimetoken)) {
        return { ...inboxItem, lastListenerReadTimetoken: '0' };
      }
      return inboxItem;
    });
  if (invalidChannels.length > 0) {
    context.services.logger.error(
      new Error(
        `Unable to count unread messages due to invalid memberCoachChannel`,
      ),
      {
        inboxItem: invalidChannels,
      },
    );
  }

  if (!channelsAndListenerReadTimes.length) return;
  const channelIds = channelsAndListenerReadTimes.map(
    (it) => it.memberCoachChannelId,
  );
  const lastListenerReadTimetokens = channelsAndListenerReadTimes.map(
    (i) => i.lastListenerReadTimetoken,
  ) as string[]; // this is safe since we check the type in the function above
  const getNumberOfUnreadMessagesPayload = {
    channelTimetokens: lastListenerReadTimetokens,
    channels: channelIds,
  };
  try {
    const res = await pubnub.getNumberOfUnreadMessages(
      getNumberOfUnreadMessagesPayload,
    );

    const channelsWithAnyUnreadMessages = Object.entries(res.channels)
      .filter(([_, unreadCount]) => unreadCount > 0)
      .map(([channel, _]) => channel);

    if (channelsWithAnyUnreadMessages.length === 0) return;
    const messagesInEnvelopesMap = await pubnub.getHistory({
      channelIds: channelsWithAnyUnreadMessages,
    });

    const emptyUnreadMessagesMap = {
      ...channelsForInboxItemsToInit.reduce<UnreadMessagesMap>(
        (accum, channel) => {
          accum[channel] = [];
          return accum;
        },
        {},
      ),
    };

    const unreadCountingResult = countUnreadMessagesForAllConversations({
      channelsWithAnyUnreadMessages,
      inboxItemsData: channelsAndListenerReadTimes,
      logger: context.services.logger,
      messagesInEnvelopesMap,
    });

    // this is needed to set an empty array for channels that don't have unread messages
    const mergedUnreadMessagesMap = {
      ...emptyUnreadMessagesMap,
      ...unreadCountingResult.unreadMessages,
    };
    redux.dispatch(
      setUnreadMessages({ unreadMessagesMap: mergedUnreadMessagesMap }),
    );

    /* Currently we only count text messages for the purposes of unread messages. It turned out to be wrong - some RPCs
    should be counted as well. Here we introduce info logs to store cases when Pubnub says that there are at least some (>0)
    unread messages but after our calculations we say that there are NONE */
    const channelsWithDifferentUnreadResults = channelsWithAnyUnreadMessages.filter(
      (channel) =>
        !(channel in unreadCountingResult.unreadMessages) ||
        unreadCountingResult.unreadMessages[channel].length === 0,
    );

    /* Log RPCs names that we ignored to count unread messages */
    const infoToLog = {
      channelsWithNoUnreadBasedOnCHFrontend: channelsWithDifferentUnreadResults,
      rpcsIgnoredForCounting: JSON.stringify(unreadCountingResult.ignoredRPCs),
    };

    logger.info(
      'updateUnreadMessages: counted 0 unread messages for some channels when Pubnub says otherwise',
      infoToLog,
    );
  } catch (e) {
    const additionalLogData = {
      error: e,
      getNumberOfUnreadMessagesPayload,
      status: e.status,
    };

    if (matchesPubNubErrorCategory(e, PubNubErrorCategory.NETWORK_ISSUES)) {
      // TODO: add a counter metric for this.
      context.services.logger.warning(
        'updateUnreadMessages: got PNNetworkIssuesCategory error',
        additionalLogData,
      );
    } else {
      context.services.logger.error(
        new Error(
          'Error in pubnub.getNumberOfUnreadMessages or pubnub.getHistory in updateUnreadMessages',
        ),
        additionalLogData,
      );
    }
  }
};
