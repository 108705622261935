import { SubstanceUse_SubstanceType } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';

export const substances = [
  SubstanceUse_SubstanceType.etoh,
  SubstanceUse_SubstanceType.tobacco,
  SubstanceUse_SubstanceType.cannabis,
  SubstanceUse_SubstanceType.caffeine,
  SubstanceUse_SubstanceType.stimulants,
  SubstanceUse_SubstanceType.opioids,
  SubstanceUse_SubstanceType.hallucinogens,
  SubstanceUse_SubstanceType.iv_drug_use,
  SubstanceUse_SubstanceType.Other,
];
