import { useMutation } from '@apollo/client';
import {
  GetMembersForMe,
  GetMembersForMeVariables,
} from '@headspace/carehub-graphql/dist/appointments/generated/GetMembersForMe';
import { DEFAULT_TIMEZONE } from 'app/clinician/ClinicianSettingsComponent';
import { useAppState, useDispatch } from 'app/state';
import {
  appointmentRecurringClick,
  clickScheduleNewAppointmentSaveAction,
  createAppointmentAction,
} from 'app/state/amplitude/actions/appointments';
import { AdditionalLogData } from 'app/state/log/actions';
import { ILogger } from 'app/state/log/Logger';
import { useLogger } from 'app/state/log/useLogger';
import { isValidEnumValue } from 'utils';
import { CreateClinicalAppointmentForMeInput } from 'generated/globalTypes';
import { useQuerySkippable } from 'hooks/useQuery';
import { useSnackNotification } from 'hooks/useSnackNotification';
import { first } from 'lodash';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import {
  createAppointmentForMeMutation,
  getMembersForMe,
} from './appointmentQueries';
import { getErrorCode, getErrorMessage } from './errorUtils';
import { NewAppointmentForMeForm } from './NewAppointmentForMeForm';
import { makeOnRecurrenceSelect } from './utils';

/**
 * Container for new appointment form for clinicians
 */

type NewAppointmentForMeContainerProps = {
  patientId?: string;
  clinicianId?: string;
  start?: string;
  end?: string;
};

// Error codes that should be logged as info when creating an appointment
export enum AppointmentCreationInfoErrorCodes {
  INVALID_CREATION_WITH_CANCELLED_STATUS = 'INVALID CREATION WITH CANCELLED STATUS',
  INVALID_DELETED_APPOINTMENT_CHANGE = 'INVALID DELETED APPOINTMENT CHANGE',
  INVALID_CANCELLED_APPOINTMENT_STATUS_CHANGE = 'INVALID CANCELLED APPOINTMENT STATUS CHANGE',
  INVALID_CANCELLED_APPOINTMENT_START_END_CHANGE = 'INVALID CANCELLED APPOINTMENT START END CHANGE',
  INVALID_CANCELLED_REASON_APPOINTMENT_REQUIRED = 'INVALID CANCELLED REASON APPOINTMENT REQUIRED',
  APPOINTMENT_PATIENT_DATETIME_CONFLICT = 'APPOINTMENT PATIENT DATETIME CONFLICT',
  APPOINTMENT_CLINICIAN_DATETIME_CONFLICT = 'APPOINTMENT CLINICIAN DATETIME CONFLICT',
  DAYLIGHT_SAVINGS_DISRUPTIVE_SHIFT = 'DAYLIGHT SAVINGS DISRUPTIVE SHIFT',
}

export function NewAppointmentForMeContainer(
  props: NewAppointmentForMeContainerProps,
) {
  const { clinicianId, patientId, start, end } = props;
  const history = useHistory();
  const timezone = useAppState((_) => _.user.timezone) ?? DEFAULT_TIMEZONE;
  const [createAppointment] = useMutation(createAppointmentForMeMutation);
  const dispatch = useDispatch();
  const {
    showErrorNotification,
    showSuccessNotification,
  } = useSnackNotification();
  const logger = useLogger();

  useEffect(() => {
    dispatch(createAppointmentAction({ clinicianId, patientId }));
  }, [dispatch, clinicianId, patientId]);

  const onCreate = async (input: CreateClinicalAppointmentForMeInput) => {
    let response;
    try {
      response = await createAppointment({ variables: { input } });
      const appointmentId = response.data.createAppointmentForMe.appointment.id;
      dispatch(
        clickScheduleNewAppointmentSaveAction({
          appointmentIds: [appointmentId],
        }),
      );
      history.goBack();
      showSuccessNotification('Appointment Created');
    } catch (e) {
      const errors = response?.errors ? response.errors.join(', ') : e;
      const errorMessage = getErrorMessage(errors);
      const errorCode = getErrorCode(errors);
      const message = ['Error updating event', errorMessage].join(': ');

      const logMessage =
        'NewAppointmentForMeContainer.onCreate: Unable to create appointment';
      const logAdditionalInfo = {
        appointmentId: response?.data?.createAppointmentForMe?.appointment?.id,
        clinicianId,
        error: e,
        errorCode,
        errorMessage,
        input,
        patientId,
      };

      logOnCreateError(errorCode, logger, logMessage, logAdditionalInfo);

      showErrorNotification(message);
    }
  };

  const onRecurrenceClick = () => {
    dispatch(appointmentRecurringClick());
  };

  return useQuerySkippable<GetMembersForMe, GetMembersForMeVariables>(
    (data) => {
      const member = first(data?.getMembersForMe?.members);
      return (
        <NewAppointmentForMeForm
          onClose={history.goBack}
          member={member}
          start={start}
          end={end}
          onCreate={onCreate}
          timezone={timezone}
          onRecurrenceClick={onRecurrenceClick}
          onRecurrenceSelect={makeOnRecurrenceSelect(dispatch)}
        />
      );
    },
    getMembersForMe,
    { skip: !patientId, variables: { input: { q: patientId } } },
  );
}

export const logOnCreateError = (
  errorCode: string | undefined,
  logger: ILogger,
  logMessage: string,
  logAdditionalInfo: AdditionalLogData,
) => {
  if (isValidEnumValue(AppointmentCreationInfoErrorCodes, errorCode)) {
    logger.info(logMessage, logAdditionalInfo);
  } else {
    logger.error(new Error(logMessage), logAdditionalInfo);
  }
};
