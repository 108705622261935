import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

import styles from './AppointmentsTable.module.scss';

export type MemberSessionStats = Partial<{
  sessionsCoveredByEmployer: number | null;
  sessionsCoveredUsed?: number;
  sessionsAnticipated: number;
  resetDate: string;
  upcomingScheduled?: number | null;
}>;

export type Props = {
  memberSessionStats: MemberSessionStats;
};

export function MemberSessionStatsBanner(props: Props) {
  return (
    <div data-testid="member-session-stats">
      <Box className={styles.memberStats} my={2} p={2} boxShadow={1}>
        <MemberSessionStats {...props} />
      </Box>
    </div>
  );
}

export function MemberSessionStats(props: Props) {
  const {
    memberSessionStats: {
      sessionsCoveredByEmployer = 0,
      sessionsAnticipated = 'N/A',
      sessionsCoveredUsed = 0,
      resetDate = 'N/A',
    },
  } = props;
  // If sessionsCoveredByEmployer is 0, it indicates either a configuration error of the employer or the employer
  // does not have session coverage configured, therefore, the session counts aren't applicable
  // If sessionsCoveredByEmployer is null, it indicates there are unlimited appointments covered
  const sessionsCoveredDisplay =
    sessionsCoveredByEmployer === null ? (
      <AllInclusiveIcon fontSize="inherit" className={styles.icon} />
    ) : sessionsCoveredByEmployer > 0 ? (
      sessionsCoveredByEmployer
    ) : (
      'N/A'
    );
  const sessionsUsedDisplay =
    sessionsCoveredByEmployer !== 0 ? sessionsCoveredUsed : 'N/A';

  return (
    <div className={styles.memberStats}>
      <Box component="span" mr={2}>
        <Box component="span" mr={1}>
          Session Covered:
        </Box>
        <Box
          className={styles.value}
          component="span"
          data-testid="member-session-stats-sessions-covered"
        >
          {sessionsCoveredDisplay}
        </Box>
      </Box>
      <Box component="span" mx={2}>
        <Box component="span" mr={1}>
          Used:
        </Box>
        <Box
          className={styles.value}
          component="span"
          data-testid="member-session-stats-sessions-used"
        >
          {sessionsUsedDisplay}
        </Box>
      </Box>
      <Box component="span" mx={2}>
        <Box component="span" mr={1}>
          Anticipated:
        </Box>
        <Box
          className={styles.value}
          component="span"
          data-testid="member-session-stats-sessions-anticipated"
        >
          {sessionsAnticipated}
        </Box>
      </Box>
      <Box component="span" mx={2}>
        <Box component="span" mr={1}>
          Renews:
        </Box>
        <Box
          className={styles.value}
          component="span"
          data-testid="member-session-stats-reset-date"
        >
          {resetDate}
        </Box>
      </Box>
    </div>
  );
}

export function MemberSessionStatsV2(props: Props) {
  const {
    memberSessionStats: {
      sessionsCoveredByEmployer = 0,
      sessionsCoveredUsed = 0,
      upcomingScheduled,
    },
  } = props;
  const sessionsCoveredDisplay =
    sessionsCoveredByEmployer === null
      ? 'unlimited'
      : sessionsCoveredByEmployer !== 0
      ? sessionsCoveredByEmployer
      : 'N/A';
  const sessionsUsedDisplay =
    sessionsCoveredByEmployer !== 0 ? sessionsCoveredUsed : 'N/A';

  return (
    <div className={styles.memberScheduleStats}>
      <Typography variant="body1" color="textSecondary" className={styles.text}>
        You have
        <Box
          className={styles.value}
          component="span"
          data-testid="member-session-stats-sessions-scheduled"
        >
          {upcomingScheduled || 0}
        </Box>
        upcoming scheduled. You have completed
        <Box
          className={styles.value}
          component="span"
          data-testid="member-session-stats-sessions-covered"
        >
          {sessionsUsedDisplay}
        </Box>
        of
        <Box
          className={styles.value}
          component="span"
          data-testid="member-session-stats-sessions-anticipated"
        >
          {sessionsCoveredDisplay}
        </Box>
        anticipated sessions.
      </Typography>
    </div>
  );
}
