import { RoleType } from 'app/coach/coach-notes/CoachNotesTypes';
import { useFilters } from 'hooks/useFilters';
import { formatAppointmentStatus } from 'utils';
import {
  AppointmentType,
  ClinicalAppointmentStatus,
  ClinicalNoteStatus,
  UserRole,
} from 'generated/globalTypes';
import { useState } from 'react';
import { useAppState } from 'app/state';

const RolesAllowedToEditAllClinicalAppointments = new Set([
  UserRole.MEMBER_SUPPORT,
  UserRole.THERAPIST_SUPERVISOR,
  UserRole.PSYCHIATRIST_SUPERVISOR,
  UserRole.CLINICAL_SUPERVISOR, // I'm not sure this is used in this context
]);

export const useAppointmentsAndNotesFilters = () => {
  const [unreadFilter, toggleUnreadFilter] = useFilters([
    { label: 'Unread', key: 'Unread', checked: true },
  ]);

  const [typeFilters, toggleTypeFilters] = useFilters([
    { label: 'Therapy', key: AppointmentType.THERAPY, checked: true },
    { label: 'Psychiatry', key: AppointmentType.PSYCHIATRY, checked: true },
    { label: 'Coaching', key: RoleType.COACHING, checked: true },
  ]);

  const [appStatusFilters, toggleAppStatusFilters] = useFilters([
    {
      label: formatAppointmentStatus(ClinicalAppointmentStatus.Confirmed),
      key: ClinicalAppointmentStatus.Confirmed,
      checked: true,
    },
    {
      label: formatAppointmentStatus(ClinicalAppointmentStatus.Complete),
      key: ClinicalAppointmentStatus.Complete,
      checked: true,
    },
    {
      label: formatAppointmentStatus(ClinicalAppointmentStatus.Tentative),
      key: ClinicalAppointmentStatus.Tentative,
      checked: true,
    },
    {
      label: formatAppointmentStatus(ClinicalAppointmentStatus.NoShow),
      key: ClinicalAppointmentStatus.NoShow,
      checked: true,
    },
    {
      label: formatAppointmentStatus(ClinicalAppointmentStatus.ProviderNoShow),
      key: ClinicalAppointmentStatus.ProviderNoShow,
      checked: true,
    },
    {
      label: formatAppointmentStatus(ClinicalAppointmentStatus.Cancelled),
      key: ClinicalAppointmentStatus.Cancelled,
      checked: true,
    },
    {
      label: formatAppointmentStatus(
        ClinicalAppointmentStatus.CancelledClinician,
      ),
      key: ClinicalAppointmentStatus.CancelledClinician,
      checked: true,
    },
    {
      label: formatAppointmentStatus(ClinicalAppointmentStatus.Rescheduled),
      key: ClinicalAppointmentStatus.Rescheduled,
      checked: true,
    },
    {
      label: formatAppointmentStatus(ClinicalAppointmentStatus.LateCancelled),
      key: ClinicalAppointmentStatus.LateCancelled,
      checked: true,
    },
  ]);

  const [noteStatusFilters, toggleNoteStatusFilters] = useFilters([
    {
      label: 'Draft',
      key: ClinicalNoteStatus.DRAFT,
      checked: true,
    },
    {
      label: 'Not Started',
      key: ClinicalNoteStatus.NOT_STARTED,
      checked: true,
    },
    {
      label: 'Signed & Locked',
      key: ClinicalNoteStatus.SIGNED_AND_LOCKED,
      checked: true,
    },
  ]);

  const activeTypeFilters = typeFilters
    .filter((_) => _.checked)
    .map((_) => _.key as AppointmentType);
  const activeAppStatusFilters = appStatusFilters
    .filter((_) => _.checked)
    .map((_) => _.key as ClinicalAppointmentStatus);
  const activeNoteStatusFilters = noteStatusFilters
    .filter((_) => _.checked)
    .map((_) => _.key as ClinicalNoteStatus);

  return {
    unreadFilter,
    typeFilters,
    appStatusFilters,
    noteStatusFilters,
    toggleUnreadFilter,
    toggleTypeFilters,
    toggleAppStatusFilters,
    toggleNoteStatusFilters,
    activeTypeFilters,
    activeAppStatusFilters,
    activeNoteStatusFilters,
  };
};

export const useAppointmentWarnings = () => {
  const [showAppointmentInPastWarning, setAppointmentInPastWarning] = useState(
    false,
  );
  const [showAvailabilityWarning, setAvailabilityWarning] = useState(false);
  const [showMissingCredictCardWarning, setMissingCreditCardWarning] = useState(
    false,
  );
  const [showNoEAPSessionsWarning, setNoEAPSessionsWarning] = useState(false);
  const [showPreviousClinicianWarning, setPreviousClinicianWarning] = useState(
    false,
  );

  const closeAll = () => {
    setAppointmentInPastWarning(false);
    setAvailabilityWarning(false);
    setMissingCreditCardWarning(false);
    setNoEAPSessionsWarning(false);
    setPreviousClinicianWarning(false);
  };

  return {
    modalStatus: {
      showAppointmentInPastWarning,
      showAvailabilityWarning,
      showMissingCredictCardWarning,
      showNoEAPSessionsWarning,
      showPreviousClinicianWarning,
    },
    modalActions: {
      setAppointmentInPastWarning,
      setAvailabilityWarning,
      setMissingCreditCardWarning,
      setNoEAPSessionsWarning,
      setPreviousClinicianWarning,
      closeAll,
    },
  };
};

export const useAppointmentScreenHelpers = (userRole: UserRole | null) => {
  const effectiveUserRole = userRole ?? useAppState(({ user }) => user.role);

  const allowEditOthersAppointments = effectiveUserRole
    ? RolesAllowedToEditAllClinicalAppointments.has(effectiveUserRole)
    : false;

  return {
    allowEditOthersAppointments,
  };
};
