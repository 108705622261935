import {
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import Tooltip from 'shared-components/tooltip/Tooltip';
import styles from 'app/inbox/vertical-menu/styles/index.module.scss';
import React, { SyntheticEvent, useEffect, useRef } from 'react';

type Classes = {
  popper?: string;
  tooltip?: string;
  button?: string;
  menuList?: string;
};

type Props = {
  title: string;
  Icon: React.ReactElement;
  menuItems: React.ReactElement[];
  idAttr?: string;
  classes?: Classes;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export function SideMenu({
  Icon,
  menuItems,
  idAttr = 'side-menu',
  classes,
  title,
  open,
  setOpen,
}: Props) {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const prevOpen = useRef<boolean>(open);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleClose = (event: Event | SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title={title}
        classes={{ popper: styles.popper, tooltip: styles.tooltip }}
      >
        <button
          className={classes?.button}
          ref={anchorRef}
          id={`${idAttr}-button`}
          aria-controls={open ? `${idAttr}-menu` : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          data-testid={`${idAttr}-menu-button`}
        >
          {Icon}
        </button>
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="right-start"
        transition={true}
        className={classes?.popper}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id={`${idAttr}-menu`}
                  aria-labelledby={`${idAttr}-button`}
                  className={classes?.menuList}
                >
                  {menuItems}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
