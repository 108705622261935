import { RefObject, useCallback, useLayoutEffect, useState } from 'react';

// this hook utilizes ResizeObserver API
export const useResizeObserver = (
  ref: RefObject<HTMLElement>,
  callback?: (entry: DOMRectReadOnly) => void,
) => {
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();

  const handleResize: ResizeObserverCallback = useCallback(
    (entries) => {
      if (!Array.isArray(entries)) {
        return;
      }

      const entry = entries[0];
      setWidth(entry.contentRect.width);
      setHeight(entry.contentRect.height);

      if (callback) {
        callback(entry.contentRect);
      }
    },
    [callback],
  );

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const RO = new ResizeObserver(handleResize);
    RO.observe(ref.current);

    return () => {
      RO.disconnect();
    };
  }, [ref]);

  return [width, height];
};
