import gql from 'graphql-tag';

export const BenefitsFragment = gql`
  fragment Benefits on User {
    insurance {
      carrierId
      company
      idNumber
    }
    coverageDetails {
      coverageType
      clinicalCoverageExtent
      orgBenefits
      planDescription
      eapCarrierName
      renewalDate
      servicesCovered
      totalSessionsCovered
      totalCoveredSessionsRemaining
      upcomingScheduled
      both {
        sessionsAnticipated
        sessionsCovered
        sessionsCoveredUsed
      }
      therapy {
        sessionsAnticipated
        sessionsAggregateUsed
        sessionsCovered
        sessionsCoveredUsed
      }
      psychiatry {
        sessionsAnticipated
        sessionsAggregateUsed
        sessionsCovered
        sessionsCoveredUsed
      }
    }
  }
`;

export const DemographicFragment = gql`
  fragment DemographicInfo on User {
    dateOfBirth
    ethnicities
    firstName
    gender
    genderIdentification
    lastName
    preferredFirstName
    preferredLanguage
    preferredLastName
    preferredPharmacy
    pronouns
  }
`;

export const ContactInfoFragment = gql`
  fragment ContactInfo on User {
    firstName
    lastName
    email
    phone
    cscAutomatedOutreachPaused

    address {
      street
      city
      zip
      state
    }

    emergencyContact {
      name
      phone
      relationship
    }
  }
`;

export const UserFragment = gql`
  fragment User on User {
    id
    dosespotId
    billing {
      cardAdded
      creditCardStatus
      customerType
      employerName
    }
    device {
      model
      version
    }
    eligibility {
      activationDateTime
      couponCode
    }
    guardianRelationship {
      guardianRelationshipType
    }
    insurance {
      carrierId
      company
      idNumber
    }
    preferences {
      timezone
    }
    timezone
    profilePicture {
      original
    }
    ...Benefits
    ...ContactInfo
    ...DemographicInfo
  }

  ${BenefitsFragment}
  ${ContactInfoFragment}
  ${DemographicFragment}
`;
