import React from 'react';
import { classNameCombiner } from 'utils';

import styles from './MessageBubble.module.scss';

export const MessageBubble = ({
  message,
  isFromMember,
  isError,
  isOutOfSession,
}: {
  message: string;
  isError?: boolean;
  isFromMember?: boolean;
  isOutOfSession?: boolean;
}) => {
  return (
    <div
      className={classNameCombiner([
        styles.message,
        isFromMember ? styles.memberBubble : '',
        isError ? styles.errorBubble : '',
        isOutOfSession ? styles.outOfSession : '',
      ])}
    >
      <span
        className={classNameCombiner([
          styles.text,
          isFromMember ? styles.memberText : '',
          isError ? styles.textInErrorBubble : '',
          isOutOfSession ? styles.outOfSessionText : '',
        ])}
      >
        {message}
      </span>
    </div>
  );
};
