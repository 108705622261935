import { CoachClinicianCollaborationChatMessage } from '@ginger.io/vault-care-collaboration/dist/generated/protobuf-schemas/vault-care-collaboration/CoachClinicianCollaborationChatMessage';
import { decodeBase64VaultItems } from '@ginger.io/vault-core';
import {
  VaultItem,
  VaultItem_SchemaType,
} from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import { ChatMessageMutation } from '@ginger.io/vault-ui';

import { ChatMessage } from './type';

export function getCollaborationChatStreamTag(memberId: number | string) {
  return `coach-clinician-collaboration-chat-stream-member-urn:web:user:${memberId}`;
}

export function chatMessageToVaultItem(
  chatMessage: CoachClinicianCollaborationChatMessage,
): VaultItem {
  return {
    data: CoachClinicianCollaborationChatMessage.encode(chatMessage).finish(),
    schemaType:
      VaultItem_SchemaType.vault_care_collaboration_coach_clinician_chat_message,
  };
}

export async function decodeCollaborationChatMessage(
  vaultItems: ChatMessageMutation['createVaultItems'],
): Promise<ChatMessage[]> {
  const promises = vaultItems.map(async (item) => {
    const { creator, encryptedData } = item.encryptedItem!;
    const type =
      VaultItem_SchemaType.vault_care_collaboration_coach_clinician_chat_message;
    const decodedItem = await decodeBase64VaultItems(
      [encryptedData.cipherText],
      {
        [type]: CoachClinicianCollaborationChatMessage,
      },
    );
    const [message] = decodedItem[type];
    const chatMessage: ChatMessage = {
      ...message,
      author: {
        ...message.author!,
        firstName: creator.firstName!,
        lastName: creator.lastName!,
      },
    };
    return chatMessage;
  });
  return Promise.all(promises);
}
