import { getFormattedDateAndTime } from 'app/coach/chat/utils';
import { ContentRPC, MessageRPC } from 'app/coach/pubnub/types';
import { convertTimeStampToPubNubTimetoken } from 'app/coach/pubnub/utils';
import { assignHomeworkToMember } from 'app/patients/tabs/content/ContentQueries';
import {
  assignHomeworkToMember as AssignHomeWorkToMember,
  assignHomeworkToMemberVariables,
} from 'app/patients/tabs/content/generated/assignHomeworkToMember';
import {
  assignHomeworkResultStatus,
  HomeworkSource,
  HomeworkStatus,
  HomeworkType,
} from 'app/patients/tabs/content/types';
import {
  extractAssignmentIdFromUri,
  getHomeworkThumbnail,
} from 'app/patients/tabs/content/utils';
import { createActionHandlers } from 'redux-reloaded';
import { v4 as uuidv4 } from 'uuid';

import { onPublishSuccess } from '../chat/actions';
import { Context } from '../context';
import { State } from '../schema';
import {
  addHomework,
  assignHomework,
  setHomeworkAssignmentStatus,
} from './actions';

enum HOMEWORK_ERR_MSG {
  IS_DUPLICATED = "This assignment already exists in the member's stream.",
  IS_COMPLETED = 'This task was already completed by the member.',
}

export const handlers = createActionHandlers<Context, State>();

// the handler replicates the behavior thta we previously had in the useAssignHomework hook
handlers.on(assignHomework, async ({ action, context, redux }) => {
  const { dispatch, getState } = redux;
  const { logger } = context.services;
  const { memberId, contentId, contentType, channelId } = action.payload;
  const {
    user: { timezone, listenerId, coachinghubUsername },
    inbox: { inboxItems },
  } = getState();
  const conversationId = inboxItems[memberId]?.conversationId;
  const { apollo, pubnub } = context.services;
  const nowTimetoken = convertTimeStampToPubNubTimetoken(Date.now());
  const { formattedString } = getFormattedDateAndTime(
    nowTimetoken,
    timezone ?? 'UTC',
  );
  try {
    const res = await apollo.mutate<
      AssignHomeWorkToMember,
      assignHomeworkToMemberVariables
    >({
      mutation: assignHomeworkToMember,
      variables: {
        input: {
          contentId,
          contentType,
          memberId: Number(memberId),
        },
      },
    });

    if (res.data?.assignHomeworkToMember?.success) {
      if (!res.data?.assignHomeworkToMember.links) {
        dispatch(
          setHomeworkAssignmentStatus({
            assignmentState: {
              dateAndTime: formattedString,
              status: assignHomeworkResultStatus.NO_LINK,
              title: '',
            },
            channelId,
          }),
        );
        return;
      }

      const uri = res.data.assignHomeworkToMember.links[0] ?? '';
      const assignmentId = extractAssignmentIdFromUri(uri);

      if (!assignmentId) {
        logger.error(
          new Error(
            `Received an invalid link from assignHomeworkToMember mutation`,
          ),
          {
            contentId,
            contentType,
            memberId,
            response: res,
          },
        );

        return;
      }

      const title = res.data.assignHomeworkToMember.title ?? '';
      const thumbnail = getHomeworkThumbnail(
        res.data.assignHomeworkToMember.thumbnail ?? '',
      );
      const newHomework: HomeworkType = {
        assignmentId: `${contentType}-${assignmentId}`,
        contentType,
        date: new Date().toISOString(),
        slug: contentId,
        source: HomeworkSource.GINGER,
        status: HomeworkStatus.NOT_STARTED,
        thumbnail,
        title,
      };

      if (listenerId) {
        const assignHomeworkRPC: MessageRPC = {
          extra_params: {
            content_id: newHomework.slug,
            content_type: 'content',
            cta: 'Activity',
            label: newHomework.title,
            thumbnail: newHomework.thumbnail,
            uri,
          },
          id: uuidv4(),
          oncall_listener_id: null,
          rpc: ContentRPC.GIO_DEEPLINK_BUTTON,
          senderId: listenerId,
          senderType: 'listener',
          username: coachinghubUsername ?? '',
        };
        try {
          const { timetoken } = await pubnub.publishMessage({
            channel: channelId,
            message: assignHomeworkRPC,
          });
          dispatch(
            onPublishSuccess({
              conversationId,
              timetoken: timetoken.toString(),
            }),
          );
        } catch (e) {
          logger.error(
            new Error(
              `Could not publish ${ContentRPC.GIO_DEEPLINK_BUTTON} rpc. Got an error:`,
            ),
            {
              channelId,
              contentId,
              contentType,
              error: e,
              memberId,
            },
          );
        }
      }

      dispatch(
        addHomework({
          homework: newHomework,
          userId: `${memberId}`,
        }),
      );
      dispatch(
        setHomeworkAssignmentStatus({
          assignmentState: {
            dateAndTime: formattedString,
            status: assignHomeworkResultStatus.OK,
            title,
          },
          channelId,
        }),
      );
    } else {
      const title = res.data?.assignHomeworkToMember?.title ?? '';
      const exception = res.data?.assignHomeworkToMember?.exception;
      switch (exception) {
        case HOMEWORK_ERR_MSG.IS_DUPLICATED:
          dispatch(
            setHomeworkAssignmentStatus({
              assignmentState: {
                dateAndTime: formattedString,
                status: assignHomeworkResultStatus.IS_DUPLICATED,
                title,
              },
              channelId,
            }),
          );
          break;
        case HOMEWORK_ERR_MSG.IS_COMPLETED:
          dispatch(
            setHomeworkAssignmentStatus({
              assignmentState: {
                dateAndTime: formattedString,
                status: assignHomeworkResultStatus.IS_COMPLETED,
                title,
              },
              channelId,
            }),
          );
          break;
        default:
          dispatch(
            setHomeworkAssignmentStatus({
              assignmentState: {
                dateAndTime: formattedString,
                status: assignHomeworkResultStatus.UNKNOWN,
                title,
              },
              channelId,
            }),
          );
      }
    }
  } catch (e) {
    dispatch(
      setHomeworkAssignmentStatus({
        assignmentState: {
          dateAndTime: formattedString,
          status: assignHomeworkResultStatus.UNKNOWN,
          title: '',
        },
        channelId,
      }),
    );
    logger.error(
      new Error(
        `Mutation assignHomeworkToMember failed, homework has not been assigned.`,
      ),
      {
        contentId,
        contentType,
        memberId,
      },
    );
  }
});
