import { CoachShiftStatusEnum } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { useListenerShiftStatus } from 'app/inbox/vertical-menu/useUpdateListenerShiftStatus';
import { useAppState, useDispatch } from 'app/state';
import {
  disableDropInNotificationDisplayed,
  enableDropInNotificationDisplayed,
} from 'app/state/amplitude/actions/structuredCoaching';
import { DropInAlertType } from 'app/state/features/structuredCoaching/schema';
import { updateCurrentAlert } from 'app/state/features/structuredCoaching/structuredCoachingSlice';
import React from 'react';
import { Button } from 'shared-components/button/Button';

import styles from './DropInAlert.module.scss';

export const ITMS_TEAM_AT_MAX_LOAD_TITLE =
  'Can you help support ITMS sessions?';
export const ITMS_TEAM_AT_MAX_LOAD_MESSAGE =
  'The in-the-moment support team is currently experiencing a high volume of members.';

export const LOW_SESSION_LOAD_TITLE =
  'You have availability within the next hour';
export const LOW_SESSION_LOAD_MESSAGE =
  'If you want, you can support additional in-the-moment support members.';

export const HIGH_SESSION_LOAD_TITLE =
  'Still want to accept new ITMS sessions?';
export const HIGH_SESSION_LOAD_MESSAGE =
  'Based on your schedule, you may want to turn off ITMS.';

export type DropInAlertProps = {
  alert: DropInAlertType;
};

export const DropInAlert: React.FC<DropInAlertProps> = ({ alert }) => {
  const dispatch = useDispatch();
  const { updateListenerShiftStatus } = useListenerShiftStatus();
  const { displayedAt, listenerId } = useAppState(
    ({ structuredCoaching, user }) => ({
      displayedAt: structuredCoaching.displayedAt,
      listenerId: user.listenerId,
    }),
  );

  let title = '';
  let message = '';
  const turnOnButtonLabel = 'Turn on ITMS';
  let dismissButtonLabel = 'No thanks';
  const turnOffButtonLabel = 'Turn off ITMS';
  let showTurnOn = false;
  let showTurnOff = false;

  switch (alert) {
    case DropInAlertType.ITMS_TEAM_AT_MAX_LOAD:
      title = ITMS_TEAM_AT_MAX_LOAD_TITLE;
      message = ITMS_TEAM_AT_MAX_LOAD_MESSAGE;
      showTurnOn = true;
      break;
    case DropInAlertType.LOW_SESSION_LOAD:
      title = LOW_SESSION_LOAD_TITLE;
      message = LOW_SESSION_LOAD_MESSAGE;
      showTurnOn = true;
      break;
    case DropInAlertType.HIGH_SESSION_LOAD:
      title = HIGH_SESSION_LOAD_TITLE;
      message = HIGH_SESSION_LOAD_MESSAGE;
      showTurnOff = true;
      dismissButtonLabel = 'Keep it on';
      break;
    default:
      return null;
  }

  const handleShiftStatusChange = (status: CoachShiftStatusEnum) => {
    updateListenerShiftStatus({ shiftStatus: status }).then(() =>
      handleAccept(true),
    );
  };

  const handleAccept = (accepted: boolean) => {
    const action =
      alert === DropInAlertType.HIGH_SESSION_LOAD
        ? disableDropInNotificationDisplayed
        : enableDropInNotificationDisplayed;

    dispatch(
      action({
        accepted,
        displayedAt,
        listenerId,
        motive: alert,
      }),
    );
    dispatch(updateCurrentAlert());
  };

  const onTurnOnItms = () =>
    handleShiftStatusChange(CoachShiftStatusEnum.ONLINE);
  const onTurnOffItms = () =>
    handleShiftStatusChange(CoachShiftStatusEnum.REPLY_ONLY);
  const onDismiss = () => handleAccept(false);

  return (
    <div data-testid="drop-in-alert" className={styles.alertContainer}>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.message}>{message}</p>
      <div className={styles.buttonGroup}>
        {showTurnOn && (
          <Button
            testId="accept-button"
            onClick={onTurnOnItms}
            className={styles.turnOnButton}
          >
            {turnOnButtonLabel}
          </Button>
        )}
        {showTurnOff && (
          <Button
            testId="accept-button"
            onClick={onTurnOffItms}
            className={styles.turnOffButton}
          >
            {turnOffButtonLabel}
          </Button>
        )}
        <Button
          testId="dismiss-button"
          onClick={onDismiss}
          className={styles.dismissButton}
        >
          {dismissButtonLabel}
        </Button>
      </div>
    </div>
  );
};
