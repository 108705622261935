import {
  MedicalHistory,
  MedicalHistory_Allergy,
  MedicalHistory_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MedicalHistory';
import {
  PsychiatricHistory_PsychotropicMedication as PsychotropicMedication,
  PsychiatricHistory_SuicideAttempt as SuicideAttempt,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/PsychiatricHistory';
import { Allergies_Allergy } from '@ginger.io/vault-clinical-notes/src/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/Allergies';

// Combine previous comments into one field
const formulateMedicalHistoryComment = (medicalHistory: MedicalHistory) => {
  let comments = '';
  if (medicalHistory.seizures?.description) {
    comments = medicalHistory.seizures.description;
  }
  if (medicalHistory.traumaticBrainInjury?.description) {
    comments = `${comments},${medicalHistory.traumaticBrainInjury.description}`;
  }
  if (medicalHistory.obstructiveSleepApnea?.description) {
    comments = `${comments},${medicalHistory.obstructiveSleepApnea.description}`;
  }
  if (medicalHistory.thyroidDisease?.description) {
    comments = `${comments},${medicalHistory.thyroidDisease.description}`;
  }
  if (medicalHistory.heartDisease?.description) {
    comments = `${comments},${medicalHistory.heartDisease.description}`;
  }
  return comments;
};

export const mapMedicalHistory = (
  previousNote: MedicalHistory | null,
  pastAllergies: Allergies_Allergy[] | undefined,
  appointmentId: string,
): MedicalHistory | null => {
  if (!previousNote) return null;
  let medicalHistoryComment: string;
  let { allergies } = previousNote;
  if (
    (previousNote.allergies === undefined ||
      previousNote.allergies.length === 0) &&
    pastAllergies != undefined &&
    pastAllergies.length > 0
  ) {
    allergies = mapAllergies(pastAllergies);
  }
  if (previousNote.version === MedicalHistory_Version.v0) {
    medicalHistoryComment = previousNote.heartDisease?.description || '';
  } else {
    medicalHistoryComment = formulateMedicalHistoryComment(previousNote);
  }
  return {
    allergies: allergies || [],
    appointmentId,
    comments: previousNote.comments || '',
    heartDisease: {
      description: medicalHistoryComment,
      isPresent: previousNote.heartDisease?.isPresent || false,
    },
    nonPsychiatricCurrentMedicines:
      previousNote.nonPsychiatricCurrentMedicines || '',
    obstructiveSleepApnea: {
      description: previousNote.obstructiveSleepApnea?.description || '',
      isPresent: previousNote.obstructiveSleepApnea?.isPresent || false,
    },
    seizures: {
      description: previousNote.seizures?.description || '',
      isPresent: previousNote.seizures?.isPresent || false,
    },
    thyroidDisease: {
      description: previousNote.thyroidDisease?.description || '',
      isPresent: previousNote.thyroidDisease?.isPresent || false,
    },
    traumaticBrainInjury: {
      description: previousNote.traumaticBrainInjury?.description || '',
      isPresent: previousNote.traumaticBrainInjury?.isPresent || false,
    },
    version: MedicalHistory_Version.v0,
  };
};

export function mapAllergies(
  allergies: Allergies_Allergy[],
): MedicalHistory_Allergy[] {
  return allergies?.map((allergy) => {
    return {
      comments: allergy.comments,
      medication: allergy.medication,
    };
  });
}

export function mapMedicationResponses(
  medications: PsychotropicMedication[],
): PsychotropicMedication[] {
  return medications?.map((medication) => {
    let comment: string;
    if (medication.comments) {
      comment = medication.comments;
    } else {
      comment = formulateComment(medication);
    }
    return {
      adverseReaction: medication.adverseReaction,
      clinicalResponse: medication.clinicalResponse,
      comments: comment,
      dosage: medication.dosage,
      duration: medication.duration,
      medication: medication.medication,
    };
  });
}

export function mapSuicideAttempt(
  suicideAttempts: SuicideAttempt[],
): SuicideAttempt[] {
  return suicideAttempts?.map((suicideAttempt) => {
    let comment: string;
    let { timeframe } = suicideAttempt;
    if (suicideAttempt.method.trim().length > 0) {
      timeframe = `${suicideAttempt.timeframe}, ${suicideAttempt.method}`;
      comment = `${suicideAttempt.clinicalIntervention}, ${suicideAttempt.comments}`;
    } else {
      comment = suicideAttempt.comments;
    }
    return {
      clinicalIntervention: '',
      comments: comment,
      method: '',
      timeframe,
    };
  });
}

function formulateComment(medication: PsychotropicMedication) {
  let comment = `${medication.dosage},${medication.duration}`;
  if (medication.clinicalResponse) {
    comment = `${comment},${medication.clinicalResponse}`;
  }
  if (medication.adverseReaction) {
    comment = `${comment},${medication.adverseReaction}`;
  }
  return comment;
}
