import styles from 'app/inbox/components/styles/InboxSectionItem.module.scss';
import { ConvoPresence } from 'app/state/inbox/schema';
import { classNameCombiner } from 'utils';
import moment from 'moment';
import React from 'react';

export interface ConversationsAndTasksContentProps {
  unreadMessageCount: number;
  presence?: ConvoPresence;
  isOpenConvo: boolean;
  summary?: string;
  previewText?: string;
  latestWriteTimestamp?: string;
}

export const OPEN_CONVERSATION_TEXT = 'Open conversation';

export const ConversationsAndTasksContent = (
  props: ConversationsAndTasksContentProps,
) => {
  const {
    presence,
    unreadMessageCount,
    isOpenConvo,
    summary,
    previewText,
    latestWriteTimestamp,
  } = props;

  const isTyping = !!presence?.isTyping;
  const hasUnreadMessages = unreadMessageCount > 0;

  const label = getInboxText(
    isOpenConvo,
    summary,
    previewText,
    latestWriteTimestamp,
  );

  return (
    <div className={classNameCombiner([styles.tr, styles.summary])}>
      <div
        className={classNameCombiner([
          styles.td,
          styles.text,
          hasUnreadMessages ? styles.primary : styles.default,
        ])}
      >
        {isTyping ? 'typing' : label}
      </div>
      {hasUnreadMessages && (
        <span
          className={classNameCombiner([styles.td, styles.messageCountBubble])}
        >
          {unreadMessageCount < 100 ? unreadMessageCount : '99+'}
        </span>
      )}
    </div>
  );
};

const getInboxText = (
  isOpenConvo: boolean,
  summary?: string,
  previewText?: string,
  timestamp?: string,
) => {
  const now = moment();
  const hoursDifference = now.diff(moment(timestamp), 'hours');

  if (!isOpenConvo) {
    return previewText;
  }
  if (hoursDifference > 24) {
    return OPEN_CONVERSATION_TEXT;
  }
  return summary;
};
