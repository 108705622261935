import {
  CancelMemberCoachingSessionInput,
  CreateMemberCoachingSessionInput,
  UpdateMemberCoachingSessionInput,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import Fab from '@mui/material/Fab';
import { EnumRadioField } from 'app/notes-ui/forms/fields';
import { NoteFormControlLabelStyles } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { NumberInput } from 'app/notes-ui/forms/form-controls/NumberInput';
import { RadioListOrientations } from 'app/notes-ui/forms/form-controls/RadioButtonGroup';
import { useAppState } from 'app/state';
import React, { useState } from 'react';
import { Button } from 'shared-components/button/Button';
import ChipCard from 'shared-components/Card/ChipCard';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';

import Titles from '../constants/cards-titles';
import { DateInputField } from './form-fields/DateInputField';
import { TimeDropdownField } from './form-fields/TimeDropDownField';
import { useCoachingSessionForm } from './hooks/useCoachingSessionForm';
import { CancelSessionModal, DialogDetails } from './modals/CancelSessionModal';
import { SessionModal } from './modals/SessionModal';
import { ReminderInfoBox } from './ReminderInfoBox';
import styles from './SchedulerCard.module.scss';
import { Duration, RECURRENCE_VALUES, RecurrenceEnum } from './types';

export interface Props {
  memberId: string;
  onClose: () => void;
  onCreate: (input: CreateMemberCoachingSessionInput) => void;
  onUpdate: (input: UpdateMemberCoachingSessionInput) => void;
  onCancelSession: (input: CancelMemberCoachingSessionInput) => void;
  coachTimezone: string;
  memberTimezone: string;
}

const SessionForm = (props: Props) => {
  const {
    memberId,
    onClose,
    onCreate,
    onUpdate,
    onCancelSession,
    coachTimezone,
    memberTimezone,
  } = props;
  const {
    fields,
    onClickSave,
    onConfirmEdit,
    onDismissConfirmEdit,
    onClickCancel,
    onClickEdit,
    onConfirmCancel,
    onDismissConfirmCancel,
    showConfirmEdit,
    showConfirmCancel,
  } = useCoachingSessionForm({
    coachTimeZone: coachTimezone,
    memberId,
    memberTimeZone: memberTimezone,
    onCancelSession,
    onCreate,
    onUpdate,
  });
  const { selectedCoachingSession: existingCoachingSession } = useAppState(
    ({ scheduler: { selectedCoachingSession } }) => ({
      selectedCoachingSession,
    }),
  );
  const [selectedDate, setSelectedDate] = useState<string | null>(null);

  let cancelDialogDetails: DialogDetails;
  let editDialogDetails: DialogDetails;

  if (existingCoachingSession && existingCoachingSession.recurrence === null) {
    cancelDialogDetails = {
      dialogContent: 'Are you sure you want to cancel this session?',
      dialogTitle: 'Cancel session',
    };
    editDialogDetails = {
      dialogContent:
        'Are you sure you want to save your edits for this session?',
      dialogTitle: 'Save session',
    };
  } else {
    cancelDialogDetails = {
      dialogContent: '',
      dialogTitle: 'Cancel recurring session',
    };
    editDialogDetails = {
      dialogContent: '',
      dialogTitle: 'Save recurring session',
    };
  }

  const creationButtons = (
    <>
      <Fab
        className={styles.fab}
        variant="extended"
        size="small"
        onClick={() => onClose()}
      >
        <span className={styles.fabText}>Cancel</span>
      </Fab>
      {existingCoachingSession ? (
        <Fab
          className={styles.fab}
          variant="extended"
          size="small"
          color="primary"
          onClick={onClickEdit}
        >
          <span className={styles.fabText}>Save</span>
        </Fab>
      ) : (
        <Fab
          className={styles.fab}
          variant="extended"
          size="small"
          color="primary"
          onClick={onClickSave}
        >
          <span className={styles.fabText}>Save</span>
        </Fab>
      )}
    </>
  );

  const noteStyles: NoteFormControlLabelStyles = {
    label: styles.noteLabel,
    root: styles.noteRoot,
  };

  return (
    <ChipCard boxTitle={Titles.SCHEDULER_TITLE} chip={creationButtons}>
      <div className={styles.createContainer}>
        <div className={styles.dateContainer}>
          <DateInputField
            className={styles.datePicker}
            inputClassName={styles.datePickerInput}
            noteStyles={noteStyles}
            fields={fields.startDate}
            onSelectDate={setSelectedDate}
          />
        </div>
        <div className={styles.timeContainer}>
          <TimeDropdownField
            fields={fields.sessionStartTime}
            memberTimezone={memberTimezone}
            selectedDate={selectedDate}
          />
        </div>
        <EnumRadioField
          label="Duration (min)"
          disabled={false}
          options={Duration}
          field={fields.duration}
          formControlStyles={{ label: styles.label }}
          radioListOrientation={RadioListOrientations.HORIZONTAL}
          keyLabels={{
            FIFTY: '50',
            FORTY: '40',
            SIXTY: '60',
            THIRTY: '30',
            TWENTY: '20',
          }}
        />

        <EnumRadioField
          label="Repeats"
          disabled={Boolean(existingCoachingSession)}
          options={RecurrenceEnum}
          field={fields.everyNWeeks}
          formControlStyles={{ label: styles.label }}
          keyLabels={{
            DOES_NOT_REPEAT: 'Does not repeat',
            EVERY_2_WEEKS: 'Every 2 weeks',
            EVERY_3_WEEKS: 'Every 3 weeks',
            EVERY_4_WEEKS: 'Every 4 weeks',
            ONCE_A_WEEK: 'Once a week',
          }}
        />

        {RECURRENCE_VALUES.includes(fields.everyNWeeks.value) && (
          <div className={styles.recurrenceSection}>
            <Grid>
              <Columns
                className={styles.grid}
                widths={[
                  COLUMN_WIDTH.THREE,
                  COLUMN_WIDTH.FOUR,
                  COLUMN_WIDTH.FOUR,
                ]}
              >
                <span className={styles.label}>Ends after</span>
                <NumberInput
                  disabled={Boolean(existingCoachingSession)}
                  value={fields.numOccurrences.value || 2}
                  label=""
                  placeholder=""
                  name="numOccurrences"
                  inputClass={styles.numberInput}
                  inputProps={{
                    max: 12,
                    min: 2,
                    step: 1,
                    type: 'number',
                  }}
                  onChange={(value) => {
                    if (Number.isNaN(value)) {
                      fields.numOccurrences.setValue(2);
                    } else {
                      fields.numOccurrences.setValue(value);
                    }
                  }}
                  error={fields.numOccurrences.error}
                  className={styles.numberInput}
                />
                <span className={styles.label}>sessions</span>
              </Columns>
            </Grid>
          </div>
        )}
        <ReminderInfoBox
          memberId={memberId}
          className={styles.reminderInfoBoxV1}
        />

        {existingCoachingSession && (
          <>
            <Button
              aria-controls="cancel-session"
              aria-haspopup="true"
              onClick={onClickCancel}
              className={styles.cancelButton}
              variant="outlined"
              size="small"
              disabled={false}
              testId="moreActionsBtn"
            >
              Cancel Session
            </Button>
            <SessionModal
              onClose={onDismissConfirmEdit}
              sessionId={existingCoachingSession?.coachingSession.id}
              onConfirm={onConfirmEdit}
              open={showConfirmEdit}
              isSingleSession={existingCoachingSession.recurrence === null}
              dialogDetails={editDialogDetails}
            />
            <CancelSessionModal
              onClose={onDismissConfirmCancel}
              sessionId={existingCoachingSession?.coachingSession.id}
              onConfirm={(modificationScope) =>
                onConfirmCancel({
                  coachingSessionId:
                    existingCoachingSession?.coachingSession.id,
                  modificationScope,
                })
              }
              open={showConfirmCancel}
              isSingleSession={existingCoachingSession.recurrence === null}
              dialogDetails={cancelDialogDetails}
            />
          </>
        )}
      </div>
    </ChipCard>
  );
};

export default SessionForm;
