import { ClinicianIntakeChecklist } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/ClinicianIntakeChecklist';
import { CollaborationPlan } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/CollaborationPlan';
import { SafetyIntake as Safety } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyIntake';
import { SubstanceUse } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import { Assessment } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/Assessment';
import { CurrentFunctioning } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/CurrentFunctioning';
import { MedicalHealth } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/MedicalHealth';
import { PresentingProblem } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/PresentingProblem';
import { SocialAndDevelopmental } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/SocialAndDevelopmental';
import { TreatmentPlan } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/TreatmentPlan';
import { BehavioralObservations } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/BehavioralObservations';
import { Metadata } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { TherapyIntakeSectionName } from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import { VaultItem_SchemaType } from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import { VaultItemWithId } from '@ginger.io/vault-ui/dist/api/VaultAPI';
import { AmendmentWithAuditLog } from 'app/notes-ui/shared/amendments/types';
import { TherapyIntakeNote } from 'app/vault/api/TherapyIntakeNotesAPI';
import { newClinicalNoteMetadata } from 'app/vault/data/utils';

export function decodeTherapyIntakeNote(
  appointmentId: string,
  items: VaultItemWithId[],
  amendments: AmendmentWithAuditLog[],
): TherapyIntakeNote {
  const note: TherapyIntakeNote = {
    [TherapyIntakeSectionName.CLINICIAN_CHECKLIST]: null,
    [TherapyIntakeSectionName.PRESENTING_PROBLEM]: null,
    [TherapyIntakeSectionName.CURRENT_FUNCTIONING]: null,
    [TherapyIntakeSectionName.SOCIAL_DEVELOPMENTAL]: null,
    [TherapyIntakeSectionName.MEDICAL_HEALTH]: null,
    [TherapyIntakeSectionName.SAFETY]: null,
    [TherapyIntakeSectionName.SUBSTANCE_ABUSE]: null,
    [TherapyIntakeSectionName.BEHAVIORAL_OBSERVATION]: null,
    [TherapyIntakeSectionName.ASSESSMENT]: null,
    [TherapyIntakeSectionName.TREATMENT_PLAN]: null,
    [TherapyIntakeSectionName.COLLABORATION_PLAN]: null,
    [TherapyIntakeSectionName.AMENDMENTS]: null,
    amendments,
    createdAt: null,
    metadata: newClinicalNoteMetadata(appointmentId),
    permissions: null,
    updatedAt: null,
  };

  const schemas = VaultItem_SchemaType;
  items.forEach(({ item: vaultItem, permissions, updatedAt, createdAt }) => {
    switch (vaultItem.schemaType) {
      case schemas.vault_clinical_notes_therapy_intake_clinician_checklist: {
        const item = ClinicianIntakeChecklist.decode(vaultItem.data);
        note[TherapyIntakeSectionName.CLINICIAN_CHECKLIST] = item;
        break;
      }

      case schemas.vault_clinical_notes_therapy_intake_presenting_problem: {
        const item = PresentingProblem.decode(vaultItem.data);
        note[TherapyIntakeSectionName.PRESENTING_PROBLEM] = item;
        break;
      }

      case schemas.vault_clinical_notes_therapy_intake_current_functioning: {
        const item = CurrentFunctioning.decode(vaultItem.data);
        note[TherapyIntakeSectionName.CURRENT_FUNCTIONING] = item;
        break;
      }

      case schemas.vault_clinical_notes_therapy_intake_social_and_developmental: {
        const item = SocialAndDevelopmental.decode(vaultItem.data);
        note[TherapyIntakeSectionName.SOCIAL_DEVELOPMENTAL] = item;
        break;
      }

      case schemas.vault_clinical_notes_therapy_intake_medical_health: {
        const item = MedicalHealth.decode(vaultItem.data);
        note[TherapyIntakeSectionName.MEDICAL_HEALTH] = item;
        break;
      }

      case schemas.vault_clinical_notes_therapy_intake_safety: {
        const item = Safety.decode(vaultItem.data);
        note[TherapyIntakeSectionName.SAFETY] = item;
        break;
      }
      case schemas.vault_clinical_notes_therapy_intake_substance_use: {
        const item = SubstanceUse.decode(vaultItem.data);
        note[TherapyIntakeSectionName.SUBSTANCE_ABUSE] = item;
        break;
      }

      case schemas.vault_clinical_notes_therapy_intake_behavioral_observations: {
        const item = BehavioralObservations.decode(vaultItem.data);
        note[TherapyIntakeSectionName.BEHAVIORAL_OBSERVATION] = item;
        break;
      }

      case schemas.vault_clinical_notes_therapy_intake_assessment: {
        const item = Assessment.decode(vaultItem.data);
        note[TherapyIntakeSectionName.ASSESSMENT] = item;
        break;
      }

      case schemas.vault_clinical_notes_therapy_intake_treatment_plan: {
        const item = TreatmentPlan.decode(vaultItem.data);
        note[TherapyIntakeSectionName.TREATMENT_PLAN] = item;
        break;
      }

      case schemas.vault_clinical_notes_therapy_intake_collaboration_plan: {
        const item = CollaborationPlan.decode(vaultItem.data);
        note[TherapyIntakeSectionName.COLLABORATION_PLAN] = item;
        break;
      }

      case schemas.vault_clinical_notes_therapy_intake_metadata: {
        const item = Metadata.decode(vaultItem.data);
        note.metadata = item;
        note.permissions = permissions;
        note.createdAt = createdAt;
        note.updatedAt = updatedAt ?? createdAt;
        break;
      }

      // TODO: add amendments here after we refactor them to be retrieved in the same query
      // as the rest of the note sections

      default: {
      } // no-op
    }
  });

  return note;
}
