import {
  TherapyIntakeSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import {
  ClinicalNote,
  ClinicalNotesScreenV2,
  Section,
} from 'app/notes-ui/ClinicalNotesScreenV2';
import { validateTherapy as validateSafety } from 'app/notes-ui/shared/safety-intake/schema';
import { validateV2 as validateSubstanceUsed } from 'app/notes-ui/shared/substance-abuse/schema';
import {
  BehavioralObservationsFormv2,
  validate as validateBehavioralObservation,
} from 'app/notes-ui/therapy/intake/behavioral-observations/BehavioralObservationsFormv2';
import { SafetyFormV2 } from 'app/notes-ui/therapy/intake/safety/SafetyFormV2';
import { SubstanceAbuseFormV2 } from 'app/notes-ui/therapy/intake/substance-abuse/SubstanceAbuseFormV2';
import { getTherapyIntakeV2SectionLabel } from 'app/notes-ui/utils';
import { Status } from 'app/state/status/types/StateSlice';
import { TherapyIntakeIds } from 'app/vault/api/TherapyIntakeIds';
import { TherapyIntakeNote } from 'app/vault/api/TherapyIntakeNotesAPI';
import React, { ElementType } from 'react';

import { NoteHeaderItems } from '../TherapyIntakeNoteContainerv2';
import { AssessmentFormv2 } from './assessment/AssessmentFormv2';
import { validate as validateAssessment } from './assessment/schema';
import {
  ClinicianChecklistFormV2,
  validate as validateClinicianChecklist,
} from './clinician-checklist/ClinicianChecklistFormV2';
import {
  CurrentFunctioningFormv2,
  validate as validateCurrentFunctioning,
} from './current-funtioning/CurrentFunctioningFormv2';
import {
  PresentingProblemFormV2,
  validate as validatePresentingProblem,
} from './presenting-problem/PresentingProblemFormV2';
import {
  SocialDevelopmentFormV2,
  validate as validateSocialDevelopment,
} from './social-development/SocialDevelopmentFormV2';
import {
  TreatmentPlanFormV2,
  validate as validateTreatmentPlan,
} from './treatment-plan/TreatmentPlanFormV2';

export type Props = {
  appointmentId: string;
  memberId?: string;
  status: Status;
  note: TherapyIntakeNote;
  draftNote: TherapyIntakeNote;
  onSubmit: (section: TherapyIntakeSection) => Promise<void>;
  updateDraftNoteState: (section: TherapyIntakeSection) => void;
  readOnly: boolean;
  noteHeader: NoteHeaderItems;
};

function section<T extends TherapyIntakeSection>(
  name: T['name'],
  form: ElementType<{
    appointmentId: string;
    initialValue?: T['data'];
    noteLastUpdatedAt?: string;
    updateDraftNoteState: (section: T) => void;
    onSubmit: (section: T) => void;
  }>,
  validator: (data: any) => boolean,
): Section<T> {
  return [name, getTherapyIntakeV2SectionLabel(name), form, validator];
}

const sections = [
  section(
    TherapyIntakeSectionName.CLINICIAN_CHECKLIST,
    ClinicianChecklistFormV2,
    validateClinicianChecklist,
  ),
  section(
    TherapyIntakeSectionName.PRESENTING_PROBLEM,
    PresentingProblemFormV2,
    validatePresentingProblem,
  ),
  section(
    TherapyIntakeSectionName.SOCIAL_DEVELOPMENTAL,
    SocialDevelopmentFormV2,
    validateSocialDevelopment,
  ),
  section(
    TherapyIntakeSectionName.SUBSTANCE_ABUSE,
    SubstanceAbuseFormV2,
    validateSubstanceUsed,
  ),
  section(
    TherapyIntakeSectionName.CURRENT_FUNCTIONING,
    CurrentFunctioningFormv2,
    validateCurrentFunctioning,
  ),
  section(TherapyIntakeSectionName.SAFETY, SafetyFormV2, validateSafety),
  section(
    TherapyIntakeSectionName.BEHAVIORAL_OBSERVATION,
    BehavioralObservationsFormv2,
    validateBehavioralObservation,
  ),
  section(
    TherapyIntakeSectionName.ASSESSMENT,
    AssessmentFormv2,
    validateAssessment,
  ),
  section(
    TherapyIntakeSectionName.TREATMENT_PLAN,
    TreatmentPlanFormV2,
    validateTreatmentPlan,
  ),
];

export function TherapyIntakeNoteScreenV2(props: Props) {
  const {
    appointmentId,
    note,
    status,
    memberId,
    onSubmit,
    updateDraftNoteState,
    draftNote,
    noteHeader,
  } = props;

  return (
    <ClinicalNotesScreenV2
      appointmentId={appointmentId}
      status={status}
      onSubmit={onSubmit}
      updateDraftNoteState={updateDraftNoteState}
      memberId={memberId}
      sections={sections}
      ids={TherapyIntakeIds}
      note={note as ClinicalNote<TherapyIntakeSection>}
      draftNote={draftNote as ClinicalNote<TherapyIntakeSection>}
      readOnly={props.readOnly}
      noteHeader={noteHeader}
    />
  );
}
