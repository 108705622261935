import { useDispatch } from 'app/state';
import { updateSessionState } from 'app/state/features/auth/authSlice';
import { SessionState } from 'app/state/features/auth/types';
import React from 'react';
import { isGraphQLAuthenticationError } from 'shared-components/error-state/utils';

import styles from './EmptyCollaborationChatMessage.module.scss';

type Props = { error?: Error };

export function ErrorMessage(props: Props) {
  const { error } = props;
  const dispatch = useDispatch();
  let content;
  if (isGraphQLAuthenticationError(error)) {
    dispatch(updateSessionState(SessionState.EXPIRED));
    return <></>;
  }
  const errorMessage = props.error ? props.error.toString() : 'Error :(';
  content = (
    <>
      <h3>Oops an error occurred</h3>
      <p>{errorMessage}</p>
    </>
  );

  return (
    <div data-testid="error" className={styles.emptyMessageBody}>
      {content}
    </div>
  );
}
