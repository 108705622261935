import { Assessment_CPTCode as CPTCode } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/progress/Assessment';
import { PsychiatrySectionName } from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import Labels from 'app/notes-ui/strings/en.json';
import { psychiatryIntakeSectionToLabel } from 'app/notes-ui/utils';
import { ILogger } from 'app/state/log/Logger';
import { PsychiatryProgressNote } from 'app/vault/api/PsychiatryProgressNotesAPI';
import { PDFBuilderImpl } from 'shared-components/pdf/PDFBuilder';

import { EnumOption } from '../types';
import { NotePDFBuilder, NotePDFBuilderImpl } from './NotePDFBuilder';
import { PsychiatryNotePDF } from './PsychiatryNotePDF';
import { NoteHeader } from './types';
import { buildAssessmentCodeSets } from './utils';

export class PsychiatryProgressNotePDF extends PsychiatryNotePDF<
  PsychiatryProgressNote
> {
  constructor(
    protected note: PsychiatryProgressNote,
    protected noteHeader: NoteHeader,
    protected diagnosisCodesOptions: EnumOption[],
    protected logger: ILogger,
    protected password?: string,
    protected enableNewPdfFont?: boolean,
    protected timezone = 'America/Los_Angeles',
    protected pdfBuilder: NotePDFBuilder = new NotePDFBuilderImpl(
      password,
      psychiatryIntakeSectionToLabel,
      enableNewPdfFont,
      new PDFBuilderImpl(password, enableNewPdfFont),
      logger,
    ),
  ) {
    super(
      note,
      noteHeader,
      timezone,
      diagnosisCodesOptions,
      logger,
      pdfBuilder,
    );
  }

  getPDF(): typeof PDFDocument {
    try {
      this.header();
      this.clinicianChecklist();
      this.chiefComplaint();
      this.psychiatryReviewOfSystem();
      this.medicalReviewOfSystem();
      this.safetyProgress(true);
      this.psychiatricHistory();
      this.allergies();
      this.medicalHistory();
      this.familyHistory();
      this.socialHistory();
      this.substanceAbuse();
      this.mentalStatusExam();
      this.assessment();
      this.treatmentPlan();
      this.collaborationPlan();
      this.amendments(PsychiatrySectionName.AMENDMENTS);

      return this.pdfBuilder.build();
    } catch (e) {
      this.logger.error(new Error('[PDFBuilder]: Error rendering PDF'), {
        appointmentId: this.note.metadata.appointmentId,
        originalError: e,
      });

      throw e;
    }
  }

  private assessment() {
    this.pdfBuilder.section(
      PsychiatrySectionName.ASSESSMENT,
      () => {
        const assessment = this.note.ASSESSMENT;

        if (assessment) {
          buildAssessmentCodeSets(
            this.pdfBuilder,
            CPTCode,
            this.diagnosisCodesOptions,
            assessment.codeSets,
            assessment.cptCode,
            assessment.icd10Codes,
            this.logger,
          )
            .padding()
            .longTextAnswer(
              Labels.APPOINTMENT_DURATION_IN_MINUTES,
              assessment.appointmentDurationInMinutes.toString(),
            )
            .padding()
            .longTextAnswer(Labels.TODAY_UPDATE, assessment.todaysUpdate);
          return true;
        }
        return false;
      },
      'No assessment data',
    );
  }
}
