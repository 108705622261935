import { ReactComponent as VideoIcon } from 'assets/icons/video-icon.svg';
import moment from 'moment';
import React from 'react';
import { classNameCombiner } from 'utils';

import styles from './ReminderInfoBox.module.scss';

interface VideoEligibilityBoxProps {
  className?: string;
  nextEligibleDate?: ISODateString;
}

export const VideoEligibilityBox = ({
  className = '',
  nextEligibleDate,
}: VideoEligibilityBoxProps) => {
  const formattedDate = nextEligibleDate
    ? moment(nextEligibleDate).format('MMMM D, YYYY')
    : moment().format('MMMM D, YYYY');

  return (
    <div className={classNameCombiner([className, styles.reminderBox])}>
      <VideoIcon className={styles.calendarIcon} />
      <span>{`Next eligible for video starting ${formattedDate}`}</span>
    </div>
  );
};
