import React, { PropsWithChildren, ReactElement } from 'react';
import Masonry from 'react-masonry-css';

import styles from './DeprecatedMasonryLayout.module.scss';

export interface MasonryProps {
  breakPointCols?:
    | number
    | { default: number; [key: number]: number }
    | { [key: number]: number };
  className?: string;
}
type MasonryPropsWithChildren = PropsWithChildren<MasonryProps>;

const TABS_MASONRY_DEFAULT_CONFIG: MasonryItemProps & MasonryProps = {
  breakPointCols: {
    665: 1,
    990: 2,
    default: 3,
  },
};

export const TABS_MASONRY_2_COLUMNS_CONFIG: MasonryItemProps & MasonryProps = {
  breakPointCols: {
    665: 1,
    990: 2,
    default: 2,
  },
};

export const TABS_MASONRY_1_COLUMN_CONFIG: MasonryItemProps & MasonryProps = {
  breakPointCols: {
    665: 1,
    990: 2,
    default: 1,
  },
};

export const TABS_PATIENTS_PROFILE_V2_2_COLUMNS_CONFIG: MasonryItemProps &
  MasonryProps = {
  breakPointCols: {
    default: 2,
  },
};

export const TABS_PATIENTS_PROFILE_V2_1_COLUMN_CONFIG: MasonryItemProps &
  MasonryProps = {
  breakPointCols: {
    default: 1,
  },
};
/** @deprecated Use the Masonry component from app/src/app/core/layout/masonry/Masonry.tsx instead. * */
export const DeprecatedMasonryLayout = ({
  children,
  className,
  breakPointCols = TABS_MASONRY_DEFAULT_CONFIG.breakPointCols,
}: MasonryPropsWithChildren): ReactElement => {
  return (
    <Masonry
      breakpointCols={breakPointCols}
      className={`${styles.masonryGrid} ${className}`}
      columnClassName={styles.column}
    >
      {children}
    </Masonry>
  );
};

export interface MasonryItemProps {
  className?: string;
}
type MasonryItemPropsWithChildren = PropsWithChildren<MasonryItemProps>;

export const MasonryItem = ({
  children,
  className,
}: MasonryItemPropsWithChildren): ReactElement => {
  return (
    <div className={`${styles.column} ${styles.masonryItem} ${className}`}>
      {children}
    </div>
  );
};
