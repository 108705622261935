import { updateSessionState } from 'app/state/features/auth/authSlice';
import { SessionState } from 'app/state/features/auth/types';
import { Mutex } from 'async-mutex';

import { getBaseQuery } from '..';
import { BaseQueryWithExtraOptions } from './types';

// create a new mutex
const mutex = new Mutex();

export const baseQueryWithReauth: BaseQueryWithExtraOptions = async (
  args,
  api,
  extraOptions,
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();

  if (!extraOptions?.server)
    throw new Error('server in extraOptions is required');

  const baseQuery = getBaseQuery(extraOptions.server);

  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    // set the session to expired as we currently do for ApolloAuthenticationError
    api.dispatch(updateSessionState(SessionState.EXPIRED));
    // uncomment and adjust this when implementing the re-authentication
    /*     const { okta } = services;
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        // try to re-new the token w/o a redirect to re-auth page
        // if not successful - redicet to re-auth page
      } finally {
        // release must be called once the mutex should be released again
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    } */
  }
  return result;
};
