import React, { useState } from 'react';
import Truncate from 'react-truncate';

import styles from './ChatMessage.module.scss';

export function ShowMoreText(props: {
  children: React.ReactNode;
  lines?: number;
}) {
  const { lines, children } = props;
  const [expanded, setExpanded] = useState(false);
  const [truncated, setTruncated] = useState(false);
  const onClick = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <Truncate
        lines={!expanded && lines}
        ellipsis={
          <span>
            ...&nbsp;
            <p>
              <button className={styles.moreOrLess} onClick={onClick}>
                More
              </button>
            </p>
          </span>
        }
        onTruncate={setTruncated}
      >
        {children}
      </Truncate>
      {!truncated && expanded && (
        <p>
          <button tabIndex={0} className={styles.moreOrLess} onClick={onClick}>
            <p>Less</p>
          </button>
        </p>
      )}
    </>
  );
}
