import {
  booleanField,
  field,
  FieldDefinitions,
  nonEmptyArrayField,
  stringField,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import { Affect_Scope } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Affect';
import { Insight } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Insight';
import { Interventions } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Interventions';
import { Judgment } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Judgment';
import { Mood } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Mood';
import { Speech } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Speech';
import { ThoughtContent } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/ThoughtContent';
import { ThoughtProcess } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/ThoughtProcess';
import {
  BehavioralObservations,
  BehavioralObservations_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/BehavioralObservations';

export const fieldDefs: FieldDefinitions<BehavioralObservations> = {
  affect: {
    congruentWithContent: field(),
    congruentWithMood: field(),
    scope: field(),
  },
  appointmentId: stringField(),
  insight: field(),
  interventionsUsed: nonEmptyArrayField(),
  judgment: field(),
  mood: nonEmptyArrayField(),
  speech: nonEmptyArrayField(),
  thoughtContent: nonEmptyArrayField(),
  thoughtProcess: nonEmptyArrayField(),
  version: field<BehavioralObservations_Version>({
    default: BehavioralObservations_Version.undefined_version,
  }),
};

export const fieldDefsV2: FieldDefinitions<BehavioralObservations> = {
  affect: {
    congruentWithContent: booleanField({ default: true }),
    congruentWithMood: booleanField({ default: true }),
    scope: field<Affect_Scope>({ default: Affect_Scope.broad }),
  },
  appointmentId: stringField(),
  insight: field<Insight>({ default: Insight.good_insight }),
  interventionsUsed: field<Interventions[]>({
    default: [Interventions.UNRECOGNIZED],
    rules: [],
  }),
  judgment: field<Judgment>({ default: Judgment.intact_judgment }),
  mood: nonEmptyArrayField<Mood>({ default: [Mood.normal] }),
  speech: nonEmptyArrayField<Speech>({
    default: [Speech.speech_within_normal_limits],
  }),
  thoughtContent: nonEmptyArrayField<ThoughtContent>({
    default: [ThoughtContent.thoughts_within_normal_limits],
  }),
  thoughtProcess: nonEmptyArrayField<ThoughtProcess>({
    default: [ThoughtProcess.coherent],
  }),
  version: field<BehavioralObservations_Version>({
    default: BehavioralObservations_Version.v0,
  }),
};

export const validate = (data: BehavioralObservations | null) =>
  data !== null && validateForm(data, fieldDefs);
