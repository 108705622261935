import gql from 'graphql-tag';

export const getHomeworkForMember = gql`
  query getHomeworkForMember($input: GetHomeworkForMemberInput!) {
    getHomeworkForMember(input: $input) {
      success
      tasks {
        id
        text
        createdAt
        startedAt
        expiresAt
        completedAt
        contentType
        contentId
        thumbnail
      }
      exception
    }
  }
`;

export const removeHomeworkFromMember = gql`
  mutation removeHomeworkFromMember($input: RemoveHomeworkFromMemberInput!) {
    removeHomeworkFromMember(input: $input) {
      success
      exception
    }
  }
`;

export const assignHomeworkToMember = gql`
  mutation assignHomeworkToMember($input: AssignHomeworkToMemberInput!) {
    assignHomeworkToMember(input: $input) {
      success
      title
      links
      exception
      thumbnail
    }
  }
`;
