import {
  Assessment,
  Assessment_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/progress/Assessment';
import { Allergies } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/Allergies';
import {
  ChiefComplaint,
  ChiefComplaint_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/ChiefComplaint';
import { FamilyHistory } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/FamilyHistory';
import {
  MedicalHistory,
  MedicalHistory_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MedicalHistory';
import {
  MentalStatusExam,
  MentalStatusExam_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MentalStatusExam';
import {
  PsychiatricHistory,
  PsychiatricHistory_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/PsychiatricHistory';
import {
  PsychReviewOfSystem,
  PsychReviewOfSystem_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/PsychROS';
import {
  SocialHistory,
  SocialHistory_ChildHoodAbuse,
  SocialHistory_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/SocialHistory';
import { TreatmentPlan } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/TreatmentPlan';
import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import { BooleanWithComments } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanWithComments';
import { CollaborationPlan } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/CollaborationPlan';
import { NoteType as ClinicalNoteType } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/NoteType';
import {
  SafetyPlanStatus,
  Version as SafetyPlanVersion,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/safety/SafetyPlan';
import {
  Safety,
  Version as SafetyVersion,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyProgress';
import {
  SubstanceUse,
  SubstanceUse_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import { TreatmentPlan_Version } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/TreatmentPlan';
import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import {
  PsychiatryProgressSection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { MentalStatusExam_MentalStatusItem } from '@ginger.io/vault-clinical-notes/src/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MentalStatusExam';
import { DeleteVaultItemsMutation } from '@ginger.io/vault-ui/src/generated/graphql';
import { ShareableClinicianNoteType } from 'app/coach/coach-notes/CoachNotesTypes';
import { remapSubstanceUse } from 'app/notes-ui/shared/substance-abuse/utils';
import { useAppState } from 'app/state';
import {
  deletedDraftClinicalNote,
  signedAndLockedClinicalNote,
  viewedClinicalNote,
  wroteClinicalNote,
} from 'app/state/amplitude/actions/notes';
import { ILogger } from 'app/state/log/Logger';
import { useLogger } from 'app/state/log/useLogger';
import {
  PsychiatryIntakeNote,
  PsychiatryIntakeNotesAPI,
} from 'app/vault/api/PsychiatryIntakeNotesAPI';
import {
  PsychiatryProgressNote,
  PsychiatryProgressNotesAPI,
} from 'app/vault/api/PsychiatryProgressNotesAPI';
import { SubsectionType } from 'app/vault/api/ShareableSubsectionTypes';
import { decodePsychiatryIntakeNote } from 'app/vault/data/decodePsychiatryIntakeNote';
import { decodePsychiatryProgressNote } from 'app/vault/data/decodePsychiatryProgressNote';
import { GetAppointmentById_getAppointmentById as Appointment } from 'app/vault/generated/GetAppointmentById';
import { validatePsychiatryProgressNote } from 'app/vault/validations/psychiatryProgress';
import { NoteType } from 'generated/globalTypes';
import { useDeprecatedDiagnosisCodes } from 'hooks/useDiagnosisCodes';
import { FeatureFlags, useFeatureFlags } from 'hooks/useFeatureFlags';
import { useOnMount } from 'hooks/useOnMount';
import Messages from 'i18n/en/vault.json';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTimezone } from 'utils/dateTime';
import { CareProviderNotesLabel } from 'utils/notes';

import {
  mapMedicalHistory,
  mapMedicationResponses,
  mapSuicideAttempt,
} from './templateMappingUtil';
import {
  useClinicalAppointmentsAPI,
  usePsychiatryIntakeNotesAPI,
  usePsychiatryProgressNotesAPI,
} from './useClinicalNotesAPI';
import { IdAndNoteType } from './useTherapyProgressNote';
import {
  buildCodeSets,
  collaborationPlanGoalsToTreatmentGoals,
  isItemAlreadyExistsError,
  StateController,
  useStateSlice,
  UseTherapyNoteResult,
} from './utils';

export function usePsychiatryProgressNote(
  userId: string,
  appointmentId: string,
): UseTherapyNoteResult<PsychiatryProgressNote, PsychiatryProgressSection> {
  const logger = useLogger();
  const api = usePsychiatryProgressNotesAPI();
  const dispatch = useDispatch();
  const psychiatryIntakeAPI = usePsychiatryIntakeNotesAPI();
  const noteState = useStateSlice<PsychiatryProgressNote>();
  const noteDraftState = useStateSlice<PsychiatryProgressNote>();
  const clinicalAppointmentsAPI = useClinicalAppointmentsAPI();
  const timezone = useAppState((_) => getTimezone(_.user.timezone));

  const { transientFeatureFlags } = useFeatureFlags();
  const { getDeprecatedDiagnosisCodes } = useDeprecatedDiagnosisCodes();

  const allowGroupWriteAccess =
    transientFeatureFlags.enable_supervisor_sign_and_lock_notes_for_user;

  const [appointment, setAppointment] = useState<Appointment>();
  const isLockable = validatePsychiatryProgressNote(
    noteState.state.current.data,
  );

  useOnMount(() => {
    const analyticsEventData = {
      appointmentId,
      clinicianId: userId,
      label: CareProviderNotesLabel.NOTE_VIEWED,
      noteType: ShareableClinicianNoteType.PSYCHIATRY_PROGRESS,
    };
    const callback = async () => {
      try {
        noteState.setLoading();

        const [
          appointmentData,
          previousApptIdAndNoteType,
          deprecatedDiagnosisCodes,
        ] = await Promise.all([
          api.getAppointment(appointmentId),
          clinicalAppointmentsAPI.getLatestCompleteClinicalAppointmentAndNoteType(
            appointmentId,
          ),
          getDeprecatedDiagnosisCodes(),
        ]);

        const data = await getNote(
          api,
          psychiatryIntakeAPI,
          userId,
          appointmentData,
          previousApptIdAndNoteType,
          transientFeatureFlags,
          deprecatedDiagnosisCodes,
          logger,
        );
        noteState.setData(data);
        noteDraftState.setData(data);
        setAppointment(appointmentData);
        logger.info('usePsychiatryProgressNote onMount succeeded', {
          ...analyticsEventData,
        });
      } catch (e) {
        logger.error(e, {
          message: 'usePsychiatryProgressNote onMount failed',
          ...analyticsEventData,
        });
        noteState.setError(e);
      }
      dispatch(
        viewedClinicalNote({
          ...analyticsEventData,
          label: CareProviderNotesLabel.NOTE_VIEWED,
        }),
      );
    };

    void callback();
  });

  const createOrUpdateSection = useCallback(
    createOrUpdateSectionHandler(
      userId,
      appointment,
      api,
      noteState,
      allowGroupWriteAccess,
      dispatch,
      logger,
    ),
    [userId, appointment, api, noteState.state.current, allowGroupWriteAccess],
  );

  const updateDraftNoteState = useCallback(updateDraftState(noteDraftState), [
    noteDraftState.state.current,
  ]);

  const lockNote = useCallback(
    lockNoteHandler(
      userId,
      appointment,
      api,
      noteState,
      timezone,
      allowGroupWriteAccess,
      dispatch,
      logger,
    ),
    [appointment, api, noteState.state.current, timezone, userId],
  );

  const deleteDraft = useCallback(
    appointment && noteState.state.current.data
      ? deleteDraftHandler(
          userId,
          appointment,
          api,
          noteState.state.current.data,
          dispatch,
          logger,
        )
      : () => Promise.resolve({} as DeleteVaultItemsMutation),
    [appointment, api, noteState.state.current],
  );

  return {
    appointment,
    createOrUpdateSection,
    deleteDraft,
    draftNote: noteDraftState.state,
    isLockable,
    lockNote,
    note: noteState.state,
    updateDraftNoteState,
  };
}

export const createOrUpdateSectionHandler = (
  userId: string,
  appointment: Appointment | undefined,
  api: PsychiatryProgressNotesAPI,
  noteState: StateController<PsychiatryProgressNote>,
  allowGroupWriteAccess: boolean,
  dispatch: ReturnType<typeof useDispatch>,
  logger: ILogger,
  onCreateDraftNoteSectionHandler = createDraftNoteSectionHandler,
  onUpdateDraftNoteSectionHandler = updateDraftNoteSectionHandler,
): ((section: PsychiatryProgressSection) => Promise<void>) => {
  return async (section: PsychiatryProgressSection) => {
    const note = noteState.state.current.data;
    if (note === null) {
      throw new Error(
        `${Messages.failureToCreateOrUpdateNoteSection}: ${noteState.state.current.status}`,
      );
    }

    if (appointment === undefined) {
      throw new Error(`Appointment object not defined`);
    }

    if (api.amendmentSectionName === section.name) {
      return createAmendment(
        userId,
        appointment,
        api,
        noteState,
        section,
        dispatch,
        logger,
      );
    }

    if (note[section.name] === null) {
      try {
        await onCreateDraftNoteSectionHandler({
          allowGroupWriteAccess,
          api,
          appointment,
          dispatch,
          logger,
          note,
          noteState,
          section,
          userId,
        });
      } catch (e) {
        if (!isItemAlreadyExistsError(e)) {
          logger.info(
            'usePsychiatryProgressNote: onCreateDraftNoteSectionHandler skipping retry',
            { error: e },
          );
          throw e;
        }
        logger.info(
          `usePsychiatryProgressNote: onCreateDraftNoteSectionHandler failed for section ${section.name}, retrying as update...`,
          {
            appointmentId: appointment.id,
            clinicianId: userId,
            error: e,
            label: CareProviderNotesLabel.NOTE_CREATED,
            noteType: ShareableClinicianNoteType.PSYCHIATRY_PROGRESS,
            section: section.name,
          },
        );
        return await onUpdateDraftNoteSectionHandler({
          allowGroupWriteAccess,
          api,
          appointment,
          dispatch,
          logger,
          note,
          noteState,
          section,
          userId,
        });
      }
    } else if (note[section.name] !== null) {
      await onUpdateDraftNoteSectionHandler({
        allowGroupWriteAccess,
        api,
        appointment,
        dispatch,
        logger,
        note,
        noteState,
        section,
        userId,
      });
    }
  };
};

export type createOrUpdateDraftNoteSectionProps = {
  note: PsychiatryProgressNote;
  section: PsychiatryProgressSection;
  api: PsychiatryProgressNotesAPI;
  userId: string;
  appointment: Appointment;
  allowGroupWriteAccess: boolean;
  noteState: StateController<PsychiatryProgressNote>;
  dispatch: ReturnType<typeof useDispatch>;
  logger: ILogger;
};

export const createDraftNoteSectionHandler = async (
  data: createOrUpdateDraftNoteSectionProps,
) => {
  const {
    note,
    section,
    api,
    userId,
    appointment,
    allowGroupWriteAccess,
    noteState,
    dispatch,
    logger,
  } = data;

  const amplitudePayload = {
    appointmentId: note.metadata.appointmentId,
    clinicianId: userId,
    label: CareProviderNotesLabel.NOTE_CREATED,
    memberId: appointment.member.id,
    noteType: ShareableClinicianNoteType.PSYCHIATRY_PROGRESS,
    section: section.name,
  };
  const needToCreateMetadata =
    note.metadata.status === Metadata_NoteStatus.undefined_note_status;

  const updatedNote: PsychiatryProgressNote = {
    ...note,
    metadata: {
      ...note.metadata,
      status: Metadata_NoteStatus.draft_note,
    },
    [section.name]: section.data,
  };

  await api.createDraftNoteSection(
    userId,
    appointment,
    section,
    needToCreateMetadata ? updatedNote.metadata : undefined,
    allowGroupWriteAccess,
  );
  logger.info(
    `usePsychiatryProgressNote: createOrUpdateSectionHandler successfully created draft section ${section.name}`,
    { ...amplitudePayload },
  );

  noteState.setData(updatedNote);
  dispatch(
    wroteClinicalNote({
      ...amplitudePayload,
      label: CareProviderNotesLabel.NOTE_CREATED,
      success: true,
    }),
  );
};

export const updateDraftNoteSectionHandler = async (
  data: createOrUpdateDraftNoteSectionProps,
) => {
  const {
    note,
    section,
    api,
    userId,
    appointment,
    allowGroupWriteAccess,
    noteState,
    dispatch,
    logger,
  } = data;

  const amplitudePayload = {
    appointmentId: note.metadata.appointmentId,
    clinicianId: userId,
    label: CareProviderNotesLabel.NOTE_UPDATED,
    memberId: appointment.member.id,
    noteType: ShareableClinicianNoteType.PSYCHIATRY_PROGRESS,
    section: section.name,
  };
  const updatedNote = { ...note, [section.name]: section.data };

  try {
    await api.updateDraftNoteSection(
      userId,
      appointment,
      section,
      undefined,
      allowGroupWriteAccess,
    );
    logger?.info(
      `usePsychiatryProgressNote: createOrUpdateSectionHandler successfully updated draft section ${section.name}`,
      { ...amplitudePayload },
    );
  } catch (e) {
    logger?.error(e, {
      message: 'usePsychiatryProgressNote: failed to updateDraftNoteSection',
      ...amplitudePayload,
    });
    dispatch(
      wroteClinicalNote({
        ...amplitudePayload,
        success: false,
      }),
    );
    throw e;
  }
  noteState.setData(updatedNote);
  dispatch(
    wroteClinicalNote({
      ...amplitudePayload,
      success: true,
    }),
  );
};

function updateDraftState(
  noteState: StateController<PsychiatryProgressNote>,
): (section: PsychiatryProgressSection) => void {
  return (section: PsychiatryProgressSection) => {
    const note = noteState.state.current.data;
    if (note === null) {
      throw new Error(
        `Illegal state when trying to update a note section: ${noteState.state.current.status}`,
      );
    }

    if (section.name === PsychiatrySectionName.AMENDMENTS) return;
    noteState.setData({ ...note, [section.name]: section.data });
  };
}

function lockNoteHandler(
  userId: string,
  appointment: Appointment | undefined,
  api: PsychiatryProgressNotesAPI,
  noteState: StateController<PsychiatryProgressNote>,
  timezone: string | null,
  allowGroupWriteAccess: boolean,
  dispatch: ReturnType<typeof useDispatch>,
  logger: ILogger,
): () => Promise<void> {
  return async () => {
    if (noteState.state.current.data === null) {
      throw new Error(
        `${Messages.failureToLockNote}: ${noteState.state.current.status}`,
      );
    }
    if (appointment === undefined) {
      throw new Error(Messages.appointmentNotDefined);
    }

    const appointmentId = appointment.id;
    const analyticsEventData = {
      appointmentId,
      clinicianId: appointment.clinician.userId,
      label: CareProviderNotesLabel.NOTE_SIGNED_AND_LOCKED,
      memberId: appointment.member.id,
      noteType: ShareableClinicianNoteType.PSYCHIATRY_PROGRESS,
      safetyRiskEndorsed:
        noteState.state.current.data.SAFETY?.anyChangesOrRisks ===
        BooleanOption.yes,
    };
    const updatedNote: PsychiatryProgressNote = {
      ...noteState.state.current.data,
      metadata: {
        appointmentId,
        status: Metadata_NoteStatus.signed_and_locked_note,
      },
    };

    try {
      await api.updateNoteMetadata(
        updatedNote.metadata,
        appointment.member.id,
        allowGroupWriteAccess,
      );
      logger.info(
        'usePsychiatryProgressNote: lockNoteHandler successfully updateNoteMetadata',
        {
          ...analyticsEventData,
        },
      );
    } catch (e) {
      logger.error(e, {
        message:
          'usePsychiatryProgressNote: lockNoteHandler failed to updateNoteMetadata',
        ...analyticsEventData,
      });
      dispatch(
        signedAndLockedClinicalNote({
          ...analyticsEventData,
          label: CareProviderNotesLabel.NOTE_SIGNED_AND_LOCKED,
          success: false,
        }),
      );
      throw e;
    }

    try {
      await api.lockNoteSections(appointment);
      logger.info(
        'usePsychiatryProgressNote: lockNoteHandler successfully lockNoteSections',
        { ...analyticsEventData },
      );
    } catch (e) {
      logger.error(e, {
        message:
          'usePsychiatryProgressNote: lockNoteHandler failed to lockNoteSections',
        ...analyticsEventData,
      });
      dispatch(
        signedAndLockedClinicalNote({
          ...analyticsEventData,
          label: CareProviderNotesLabel.NOTE_SIGNED_AND_LOCKED,
          success: false,
        }),
      );
      throw e;
    }

    try {
      await api.createShareableSubsections(
        appointment,
        NoteType.PSYCHIATRY_PROGRESS,
        {
          [SubsectionType.SAFETY_PLAN]: updatedNote.SAFETY?.safetyPlan,
          [SubsectionType.TREATMENT_PLAN_GOALS]:
            updatedNote.TREATMENT_PLAN?.goal,
          [SubsectionType.MESSAGE_TO_CARE_TEAM]:
            updatedNote.TREATMENT_PLAN?.messageToCareTeam,
        },
      );
      logger.info(
        'usePsychiatryProgressNote: lockNoteHandler successfully createShareableSubsections',
        {
          ...analyticsEventData,
        },
      );
    } catch (e) {
      logger.error(e, {
        message:
          'usePsychiatryProgressNote: lockNoteHandler failed to createShareableSubsections. Continuing anyway.',
        ...analyticsEventData,
      });
      dispatch(
        signedAndLockedClinicalNote({
          ...analyticsEventData,
          label: CareProviderNotesLabel.NOTE_SIGNED_AND_LOCKED,
          success: false,
        }),
      );
    }

    noteState.setData(updatedNote);
    dispatch(
      signedAndLockedClinicalNote({
        ...analyticsEventData,
        label: CareProviderNotesLabel.NOTE_SIGNED_AND_LOCKED,
        success: true,
      }),
    );
  };
}

export const mapOldChildAbuseField = (
  socialHistory: SocialHistory | null,
): SocialHistory_ChildHoodAbuse[] => {
  const newChildAbuseField: SocialHistory_ChildHoodAbuse[] = [];
  if (socialHistory?.childhood?.childAbuse) {
    const { physical, sexual, emotional } = socialHistory.childhood.childAbuse;
    if (physical) {
      newChildAbuseField.push(SocialHistory_ChildHoodAbuse.physical);
    }
    if (sexual) {
      newChildAbuseField.push(SocialHistory_ChildHoodAbuse.sexual);
    }
    if (emotional) {
      newChildAbuseField.push(SocialHistory_ChildHoodAbuse.emotional);
    }
  }
  if (!newChildAbuseField.length && socialHistory?.childAbuseExperiences) {
    return socialHistory.childAbuseExperiences;
  }
  return newChildAbuseField;
};

async function getNote(
  api: PsychiatryProgressNotesAPI,
  psychiatryIntakeNotesAPI: PsychiatryIntakeNotesAPI,
  userId: string,
  appointment: Appointment,
  previousAppt: IdAndNoteType | null,
  transientFeatureFlags: Partial<FeatureFlags['transientFeatureFlags']> = {},
  deprecatedDiagnosisCodes: number[] = [],
  logger: ILogger,
): Promise<PsychiatryProgressNote> {
  const allowGroupWriteAccess =
    transientFeatureFlags.enable_supervisor_sign_and_lock_notes_for_user;

  // when there is no previous appointment,
  // or the previous appointment is not a progress note nor an intake note, then we can just get the note
  // at a glance, it will just retrieve the previous note if it is a psychiatry intake or progress
  const appointmentId = appointment.id;
  if (
    previousAppt === null ||
    ![NoteType.PSYCHIATRY_PROGRESS, NoteType.PSYCHIATRY_INTAKE].includes(
      previousAppt.noteType,
    )
  ) {
    return api.getNote(appointmentId);
  }

  let prevData: PsychiatryProgressNote | PsychiatryIntakeNote;
  let data: PsychiatryProgressNote;

  if (previousAppt.noteType === NoteType.PSYCHIATRY_PROGRESS) {
    const results = await Promise.all([
      api.getNote(appointmentId),
      api
        .getNote(previousAppt.id)
        .catch(() => decodePsychiatryProgressNote(appointmentId, [], [])),
    ]);

    data = results[0];
    prevData = results[1];
  } else {
    const results = await Promise.all([
      api.getNote(appointmentId),
      psychiatryIntakeNotesAPI
        .getNote(previousAppt.id)
        .catch(() => decodePsychiatryIntakeNote(appointmentId, [], [])),
    ]);

    data = results[0];
    prevData = results[1];
  }

  const updatedNoteSection = buildPsychiatryProgressNote(
    data,
    prevData,
    appointmentId,
    transientFeatureFlags,
    deprecatedDiagnosisCodes,
  );

  const useSingleRequest =
    transientFeatureFlags.enable_single_request_on_progress_note_copied_section;

  const sections = Object.entries(updatedNoteSection)
    .filter(([name]) => {
      const sectionName = name as PsychiatrySectionName;
      return (
        data[sectionName] === null &&
        updatedNoteSection[sectionName] !== null &&
        updatedNoteSection[sectionName] !== undefined &&
        sectionName !== PsychiatrySectionName.AMENDMENTS
      );
    })
    .map(([name, updatedData]) => {
      return {
        data: updatedData,
        name,
      } as PsychiatryProgressSection;
    });

  if (sections.length > 0) {
    if (useSingleRequest) {
      data.metadata.status = Metadata_NoteStatus.draft_note;
      await api
        .createDraftNoteSections(
          userId,
          appointment,
          sections,
          data.metadata,
          allowGroupWriteAccess,
        )
        .catch((error) => {
          const sectionNames = sections.map((section) => section.name);
          sectionNames.forEach((section) => {
            updatedNoteSection[section] = null;
          });
          data.metadata.status = Metadata_NoteStatus.undefined_note_status;

          logger.error(error, {
            appointmentId,
            clinicianUserId: userId,
            message: `usePsychiatryProgressNote: Unable to copy forward from previous note. Clearing section data in local state.`,
            sectionNames,
          });
        });
    } else {
      await Promise.all(
        sections.map((section) =>
          api
            .createDraftNoteSection(
              userId,
              appointment,
              section,
              undefined,
              allowGroupWriteAccess,
            )
            .catch((error) => {
              updatedNoteSection[section.name] = null;
              logger.error(error, {
                appointmentId,
                clinicianUserId: userId,
                message: `usePsychiatryProgressNote: ${section.name} section failed on createDraftNoteSection.
               Clearing section data in local state.`,
                sectionName: section.name,
              });
            }),
        ),
      );
    }
  }

  return {
    ...data,
    ...updatedNoteSection,
  };
}

function buildPsychiatryProgressNote(
  currentNote: PsychiatryProgressNote,
  previousNote: PsychiatryProgressNote | PsychiatryIntakeNote,
  appointmentId: string,
  featureFlags: Partial<FeatureFlags['transientFeatureFlags']> = {},
  deprecatedDiagnosisCodes: number[] = [],
): Partial<PsychiatryProgressNote> {
  // copy forward from previous note: Safety.safetyPlan, SubstanceUsed current &
  // past used substances, Assessment icd-10 codes, CollaborationPlan goals.
  // History of Present Illness (HPI)
  // Note: Vault client may return null of each section of a previous note.
  // This can happen when previous note has not been shared with the clinician.

  // if notes efficiency feature flag is enabled, we will copy forward additional fields from previous note
  const {
    enable_care_hub_notes_efficiency: enableCareHubNotesEfficiency,
  } = featureFlags;

  let psychROS: PsychReviewOfSystem | null = null;
  let mentalStatusExam: MentalStatusExam | null = null;
  let safety: Safety | null = null;
  let collaborationPlan: CollaborationPlan | null = null;
  let substanceAbuse: SubstanceUse | null = null;
  let assessment: Assessment | null = null;
  let chiefComplaint: ChiefComplaint | null = null;
  let psychiatricHistory: PsychiatricHistory | null = null;
  let allergies: Allergies | null = null;
  let socialHistory: SocialHistory | null = null;
  let medicalHistory: MedicalHistory | null = null;
  let familyHistory: FamilyHistory | null = null;
  let treatmentPlan: TreatmentPlan | null = null;

  if (previousNote.CHIEF_COMPLAINT) {
    if (enableCareHubNotesEfficiency) {
      chiefComplaint = {
        appointmentId,
        chiefComplaint: currentNote.CHIEF_COMPLAINT?.chiefComplaint ?? '',
        historyOfPresentIllness:
          currentNote.CHIEF_COMPLAINT?.historyOfPresentIllness ??
          previousNote.CHIEF_COMPLAINT.historyOfPresentIllness ??
          '',
        relevantPhysicalHealthUpdates:
          currentNote.CHIEF_COMPLAINT?.relevantPhysicalHealthUpdates ??
          previousNote.CHIEF_COMPLAINT.relevantPhysicalHealthUpdates ??
          '',
        version:
          currentNote.CHIEF_COMPLAINT?.version || ChiefComplaint_Version.v0,
      };
    } else {
      chiefComplaint = {
        appointmentId,
        chiefComplaint: currentNote.CHIEF_COMPLAINT?.chiefComplaint ?? '',
        historyOfPresentIllness:
          currentNote.CHIEF_COMPLAINT?.historyOfPresentIllness ??
          previousNote.CHIEF_COMPLAINT.historyOfPresentIllness ??
          '',
        relevantPhysicalHealthUpdates:
          currentNote.CHIEF_COMPLAINT?.relevantPhysicalHealthUpdates || '',
        version:
          currentNote.CHIEF_COMPLAINT?.version ||
          ChiefComplaint_Version.undefined_version,
      };
    }
  }

  if (previousNote.PSYCH_REVIEW_OF_SYSTEM) {
    if (enableCareHubNotesEfficiency) {
      psychROS = {
        appointmentId,
        comments:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.comments ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.comments,
        depression:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.depression ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.depression,
        eatingDisorder:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.eatingDisorder ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.eatingDisorder,
        hypomania:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.hypomania ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.hypomania,
        ocd:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.ocd ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.ocd,
        panic:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.panic ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.panic,
        psychosis:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.psychosis ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.psychosis,
        ptsd:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.ptsd ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.ptsd,
        version:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.version ||
          PsychReviewOfSystem_Version.v0,
      };
    } else {
      psychROS = {
        appointmentId,
        comments: currentNote.PSYCH_REVIEW_OF_SYSTEM?.comments || '',
        depression:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.depression ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.depression,
        eatingDisorder:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.eatingDisorder || [],
        hypomania:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.hypomania ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.hypomania,
        ocd:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.ocd ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.ocd,
        panic:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.panic ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.panic,
        psychosis:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.psychosis ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.psychosis,
        ptsd:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.ptsd ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.ptsd,
        version:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.version ||
          PsychReviewOfSystem_Version.undefined_version,
      };
    }
  }

  if (previousNote.SAFETY) {
    let fallbackSafetyVersion = SafetyVersion.undefined_version;
    if (enableCareHubNotesEfficiency) {
      fallbackSafetyVersion = SafetyVersion.v1;
    }

    safety = {
      anyChangesOrRisks:
        currentNote.SAFETY?.anyChangesOrRisks ||
        previousNote.SAFETY.anyChangesOrRisks,
      appointmentId,
      currentHomicidalIdeation:
        currentNote.SAFETY?.currentHomicidalIdeation ||
        previousNote.SAFETY.currentHomicidalIdeation,
      currentSelfInjury:
        currentNote.SAFETY?.currentSelfInjury ||
        previousNote.SAFETY.currentSelfInjury,
      currentSuicidalIdeation:
        currentNote.SAFETY?.currentSuicidalIdeation ||
        previousNote.SAFETY.currentSuicidalIdeation,
      domesticViolenceRisk:
        currentNote.SAFETY?.domesticViolenceRisk ||
        previousNote.SAFETY.domesticViolenceRisk,
      eatingDisorderRisk:
        currentNote.SAFETY?.eatingDisorderRisk ||
        previousNote.SAFETY.eatingDisorderRisk,
      highSubstanceUse:
        currentNote.SAFETY?.highSubstanceUse ||
        previousNote.SAFETY.highSubstanceUse,
      otherConcerns:
        currentNote.SAFETY?.otherConcerns || previousNote.SAFETY.otherConcerns,
      safetyPlan: {
        actionsTaken:
          currentNote.SAFETY?.safetyPlan?.actionsTaken ||
          previousNote.SAFETY.safetyPlan?.actionsTaken,
        appointmentEnd: '',
        appointmentId: '',
        appointmentStart: '',
        description:
          currentNote.SAFETY?.safetyPlan?.description ??
          previousNote.SAFETY.safetyPlan?.description ??
          '',
        noteType: ClinicalNoteType.undefined_note_type,
        psychiatryQuestions:
          currentNote.SAFETY?.safetyPlan?.psychiatryQuestions ||
          previousNote.SAFETY.safetyPlan?.psychiatryQuestions,
        status:
          currentNote.SAFETY?.safetyPlan?.status ||
          previousNote.SAFETY.safetyPlan?.status ||
          SafetyPlanStatus.undefined_status,
        therapyQuestions:
          currentNote.SAFETY?.safetyPlan?.therapyQuestions ||
          previousNote.SAFETY.safetyPlan?.therapyQuestions,
        version:
          currentNote.SAFETY?.safetyPlan?.version ||
          SafetyPlanVersion.undefined_version,
      },
      selectedConcerns:
        currentNote.SAFETY?.selectedConcerns ||
        previousNote.SAFETY.selectedConcerns ||
        [],
      version: currentNote.SAFETY?.version || fallbackSafetyVersion,
      vulnerablePopulationsAbuse:
        currentNote.SAFETY?.vulnerablePopulationsAbuse ||
        previousNote.SAFETY.vulnerablePopulationsAbuse,
    };
  }

  if (previousNote.COLLABORATION_PLAN) {
    const goals = previousNote.COLLABORATION_PLAN!.goal;
    collaborationPlan = {
      appointmentId,
      goal: currentNote.COLLABORATION_PLAN?.goal || goals,
    };
  }

  if (previousNote.ASSESSMENT) {
    let fallbackAssessmentVersion = Assessment_Version.undefined_version;
    if (enableCareHubNotesEfficiency) {
      fallbackAssessmentVersion = Assessment_Version.v0;
    }
    const previousAssessment = previousNote.ASSESSMENT as PsychiatryProgressNote['ASSESSMENT'];
    const previousIntakeAssessment = previousNote.ASSESSMENT as PsychiatryIntakeNote['ASSESSMENT'];
    assessment = {
      appointmentDurationInMinutes:
        currentNote.ASSESSMENT?.appointmentDurationInMinutes || 0,
      appointmentId,
      codeSets: buildCodeSets(
        currentNote.ASSESSMENT?.codeSets!,
        previousAssessment?.codeSets!,
        deprecatedDiagnosisCodes,
      ),
      cptCode: currentNote.ASSESSMENT?.cptCode! || 0,
      icd10Codes:
        currentNote.ASSESSMENT?.icd10Codes ||
        previousAssessment?.icd10Codes?.filter(
          (c) => !deprecatedDiagnosisCodes.includes(c),
        ) ||
        [],
      todaysUpdate:
        currentNote.ASSESSMENT?.todaysUpdate ||
        previousAssessment?.todaysUpdate ||
        previousIntakeAssessment?.formulationAndMedicalDecisionMaking ||
        '',
      version: currentNote.ASSESSMENT?.version || fallbackAssessmentVersion,
    };
  }

  if (previousNote.SUBSTANCE_ABUSE) {
    const substanceUsedVersion = enableCareHubNotesEfficiency
      ? SubstanceUse_Version.v0
      : SubstanceUse_Version.undefined_version;
    const substanceUsed = {
      anySubstanceUsed:
        previousNote.SUBSTANCE_ABUSE.anySubstanceUsed ?? BooleanOption.yes,
      appointmentId,
      pastEtohOrBenzoWithdrawal:
        previousNote.SUBSTANCE_ABUSE.pastEtohOrBenzoWithdrawal ??
        BooleanWithComments.fromPartial({}),
      pastSubstanceUseTreatment:
        previousNote.SUBSTANCE_ABUSE.pastSubstanceUseTreatment ??
        BooleanWithComments.fromPartial({}),
      substancesCurrentlyUsed:
        previousNote.SUBSTANCE_ABUSE.substancesCurrentlyUsed ?? [],
      substancesPreviouslyUsed:
        previousNote.SUBSTANCE_ABUSE.substancesPreviouslyUsed ?? [],
      version: substanceUsedVersion,
    };

    substanceAbuse = {
      anySubstanceUsed:
        currentNote.SUBSTANCE_ABUSE?.anySubstanceUsed ??
        substanceUsed.anySubstanceUsed,
      appointmentId,
      pastEtohOrBenzoWithdrawal: {
        description:
          currentNote.SUBSTANCE_ABUSE?.pastEtohOrBenzoWithdrawal?.description ??
          substanceUsed.pastEtohOrBenzoWithdrawal.description,
        isPresent:
          currentNote.SUBSTANCE_ABUSE?.pastEtohOrBenzoWithdrawal?.isPresent ??
          substanceUsed.pastEtohOrBenzoWithdrawal.isPresent,
      },
      pastSubstanceUseTreatment: {
        description:
          currentNote.SUBSTANCE_ABUSE?.pastSubstanceUseTreatment?.description ??
          substanceUsed.pastSubstanceUseTreatment.description,
        isPresent:
          currentNote.SUBSTANCE_ABUSE?.pastSubstanceUseTreatment?.isPresent ??
          substanceUsed.pastSubstanceUseTreatment.isPresent,
      },
      substancesCurrentlyUsed: remapSubstanceUse(
        currentNote.SUBSTANCE_ABUSE?.substancesCurrentlyUsed ??
          substanceUsed.substancesCurrentlyUsed,
      ),
      substancesPreviouslyUsed: remapSubstanceUse(
        currentNote.SUBSTANCE_ABUSE?.substancesPreviouslyUsed ??
          substanceUsed.substancesPreviouslyUsed,
      ),
      version: currentNote.SUBSTANCE_ABUSE?.version ?? substanceUsed.version,
    };
  }

  if (previousNote.PSYCH_HISTORY) {
    if (enableCareHubNotesEfficiency) {
      psychiatricHistory = currentNote.PSYCH_HISTORY || {
        appointmentId,
        historyOfComplicatedEtohOrBenzoWithdrawal:
          previousNote.PSYCH_HISTORY.historyOfComplicatedEtohOrBenzoWithdrawal,
        pastInpatientHospitalization:
          previousNote.PSYCH_HISTORY.pastInpatientHospitalization,
        pastOutpatientTreatment:
          previousNote.PSYCH_HISTORY.pastOutpatientTreatment,
        pastPsychotropicMedicationsResponses: mapMedicationResponses(
          previousNote.PSYCH_HISTORY.pastPsychotropicMedicationsResponses,
        ),
        pastSelfHarmEpisode: previousNote.PSYCH_HISTORY.pastSelfHarmEpisode,
        pastSubstanceUseTreatment:
          previousNote.PSYCH_HISTORY.pastSubstanceUseTreatment,
        pastSuicidalIdeationEpisode:
          previousNote.PSYCH_HISTORY.pastSuicidalIdeationEpisode,
        pastSuicideAttempt: mapSuicideAttempt(
          previousNote.PSYCH_HISTORY.pastSuicideAttempt,
        ),
        pastTreatments: previousNote.PSYCH_HISTORY.pastTreatments,
        version: PsychiatricHistory_Version.v0,
      };
    } else {
      psychiatricHistory = currentNote.PSYCH_HISTORY || {
        ...previousNote.PSYCH_HISTORY,
        appointmentId,
        version: PsychiatricHistory_Version.undefined_version,
      };
    }
  }

  if (previousNote.ALLERGIES) {
    allergies = currentNote.ALLERGIES || {
      ...previousNote.ALLERGIES,
      appointmentId,
    };
  }

  if (previousNote.MEDICAL_HISTORY) {
    if (enableCareHubNotesEfficiency) {
      medicalHistory =
        currentNote.MEDICAL_HISTORY ||
        mapMedicalHistory(
          previousNote.MEDICAL_HISTORY,
          previousNote.ALLERGIES?.allergies,
          appointmentId,
        );
    } else {
      medicalHistory = currentNote.MEDICAL_HISTORY || {
        ...previousNote.MEDICAL_HISTORY,
        appointmentId,
        version: MedicalHistory_Version.undefined_version,
      };
    }
  }

  if (previousNote.SOCIAL_HISTORY) {
    if (enableCareHubNotesEfficiency) {
      socialHistory = currentNote.SOCIAL_HISTORY || {
        ...previousNote.SOCIAL_HISTORY,
        appointmentId,
        childAbuseExperiences: mapOldChildAbuseField(
          previousNote.SOCIAL_HISTORY,
        ),
        family: {
          additionalSocialFamilyHistoryComments:
            previousNote.SOCIAL_HISTORY.family
              ?.additionalSocialFamilyHistoryComments || '',
          history:
            previousNote.SOCIAL_HISTORY?.family?.history ||
            previousNote.FAMILY_HISTORY?.comments ||
            '',
        },
        version: SocialHistory_Version.v0,
      };
    } else {
      socialHistory = currentNote.SOCIAL_HISTORY || {
        ...previousNote.SOCIAL_HISTORY,
        appointmentId,
        version: SocialHistory_Version.undefined_version,
      };
    }
  }

  if (previousNote.FAMILY_HISTORY) {
    familyHistory = currentNote.FAMILY_HISTORY || {
      ...previousNote.FAMILY_HISTORY,
      appointmentId,
    };
  }
  const defaultValue: MentalStatusExam_MentalStatusItem = {
    effectWithinNormalLimits: false,
    other: {
      description: '',
      isPresent: false,
    },
  };

  if (previousNote.MENTAL_STATUS_EXAM) {
    if (enableCareHubNotesEfficiency) {
      mentalStatusExam = currentNote.MENTAL_STATUS_EXAM || {
        ...previousNote.MENTAL_STATUS_EXAM,
        appointmentId,
        moodAndAffect: {
          affect: previousNote.MENTAL_STATUS_EXAM.moodAndAffect?.affect,
          affectDescription: [],
          moodInPatientWord: '',
        },
        version: MentalStatusExam_Version.v0,
      };
    } else {
      mentalStatusExam = {
        affect: defaultValue,
        appearance: defaultValue,
        appointmentId,
        associations:
          currentNote.MENTAL_STATUS_EXAM?.associations ||
          previousNote.MENTAL_STATUS_EXAM.associations,
        attentionAndConcentration:
          currentNote.MENTAL_STATUS_EXAM?.attentionAndConcentration ||
          previousNote.MENTAL_STATUS_EXAM.attentionAndConcentration,
        cognitive: defaultValue,
        fundOfKnowledge:
          currentNote.MENTAL_STATUS_EXAM?.fundOfKnowledge ||
          previousNote.MENTAL_STATUS_EXAM.fundOfKnowledge,
        insight:
          currentNote.MENTAL_STATUS_EXAM?.insight ||
          previousNote.MENTAL_STATUS_EXAM.insight,
        insightStatus: defaultValue,
        judgement: defaultValue,
        judgment:
          currentNote.MENTAL_STATUS_EXAM?.judgment ||
          previousNote.MENTAL_STATUS_EXAM.judgment,
        language:
          currentNote.MENTAL_STATUS_EXAM?.language ||
          previousNote.MENTAL_STATUS_EXAM.language,
        moodAndAffect: currentNote.MENTAL_STATUS_EXAM?.moodAndAffect || {
          affect: previousNote.MENTAL_STATUS_EXAM.moodAndAffect?.affect,
          affectDescription: [],
          moodInPatientWord: '',
        },
        orientation:
          currentNote.MENTAL_STATUS_EXAM?.orientation ||
          previousNote.MENTAL_STATUS_EXAM.orientation,
        orientationStatus: defaultValue,
        recentAndRemoteMemory:
          currentNote.MENTAL_STATUS_EXAM?.recentAndRemoteMemory ||
          previousNote.MENTAL_STATUS_EXAM.recentAndRemoteMemory,
        speech:
          currentNote.MENTAL_STATUS_EXAM?.speech ||
          previousNote.MENTAL_STATUS_EXAM.speech,
        speechStatus: defaultValue,
        thoughtContent:
          currentNote.MENTAL_STATUS_EXAM?.thoughtContent ||
          previousNote.MENTAL_STATUS_EXAM.thoughtContent,
        thoughtContentStatus: defaultValue,
        thoughtProcess:
          currentNote.MENTAL_STATUS_EXAM?.thoughtProcess ||
          previousNote.MENTAL_STATUS_EXAM.thoughtProcess,
        thoughtProcessStatus: defaultValue,
        version:
          currentNote.MENTAL_STATUS_EXAM?.version ||
          MentalStatusExam_Version.undefined_version,
      };
    }
  }

  if (previousNote.TREATMENT_PLAN) {
    let fallbackTreatmentPlanVersion = TreatmentPlan_Version.undefined_version;
    if (enableCareHubNotesEfficiency) {
      fallbackTreatmentPlanVersion = TreatmentPlan_Version.v0;
    }
    if (enableCareHubNotesEfficiency) {
      treatmentPlan = {
        appointmentId,
        appointmentOffered:
          currentNote.TREATMENT_PLAN?.appointmentOffered ||
          previousNote.TREATMENT_PLAN.appointmentOffered ||
          BooleanOption.not_applicable,
        approachesUpdateComments: '',
        followUp: currentNote.TREATMENT_PLAN?.followUp || '',
        goal: collaborationPlanGoalsToTreatmentGoals(currentNote, previousNote),
        medicationManagement:
          currentNote.TREATMENT_PLAN?.medicationManagement ||
          previousNote.TREATMENT_PLAN.medicationManagement,
        memberOutOfScope:
          currentNote.TREATMENT_PLAN?.memberOutOfScope ||
          previousNote.TREATMENT_PLAN.memberOutOfScope ||
          false,
        memberOutOfScopeReason:
          currentNote.TREATMENT_PLAN?.memberOutOfScopeReason ||
          previousNote.TREATMENT_PLAN.memberOutOfScopeReason ||
          '',

        messageToCareTeam: currentNote.TREATMENT_PLAN?.messageToCareTeam || '',
        numberOfSessionsAnticipated:
          currentNote.TREATMENT_PLAN?.numberOfSessionsAnticipated ||
          previousNote.TREATMENT_PLAN.numberOfSessionsAnticipated,
        offeredAppointmentAccepted:
          currentNote.TREATMENT_PLAN?.offeredAppointmentAccepted ||
          previousNote.TREATMENT_PLAN.offeredAppointmentAccepted ||
          BooleanOption.not_applicable,
        referralsToCareOutsideGingerNecessary:
          currentNote.TREATMENT_PLAN?.referralsToCareOutsideGingerNecessary ||
          previousNote.TREATMENT_PLAN.referralsToCareOutsideGingerNecessary,
        // this is not used in the psychiatry note, just in the therapy note.
        version:
          currentNote.TREATMENT_PLAN?.version || fallbackTreatmentPlanVersion,
        waitTimeDetrimentalEffect:
          currentNote.TREATMENT_PLAN?.waitTimeDetrimentalEffect ||
          previousNote.TREATMENT_PLAN.waitTimeDetrimentalEffect,
      };
    } else {
      treatmentPlan = {
        appointmentId,
        appointmentOffered:
          currentNote.TREATMENT_PLAN?.appointmentOffered ||
          previousNote.TREATMENT_PLAN.appointmentOffered ||
          BooleanOption.not_applicable,
        approachesUpdateComments: '',
        followUp: currentNote.TREATMENT_PLAN?.followUp || '',
        goal:
          currentNote.TREATMENT_PLAN?.goal ||
          previousNote.TREATMENT_PLAN.goal ||
          [],
        medicationManagement:
          currentNote.TREATMENT_PLAN?.medicationManagement ||
          previousNote.TREATMENT_PLAN.medicationManagement,
        memberOutOfScope:
          currentNote.TREATMENT_PLAN?.memberOutOfScope ||
          previousNote.TREATMENT_PLAN.memberOutOfScope ||
          false,
        memberOutOfScopeReason:
          currentNote.TREATMENT_PLAN?.memberOutOfScopeReason ||
          previousNote.TREATMENT_PLAN.memberOutOfScopeReason ||
          '',

        messageToCareTeam: currentNote.TREATMENT_PLAN?.messageToCareTeam || '',
        numberOfSessionsAnticipated:
          currentNote.TREATMENT_PLAN?.numberOfSessionsAnticipated ||
          previousNote.TREATMENT_PLAN.numberOfSessionsAnticipated,
        offeredAppointmentAccepted:
          currentNote.TREATMENT_PLAN?.offeredAppointmentAccepted ||
          previousNote.TREATMENT_PLAN.offeredAppointmentAccepted ||
          BooleanOption.not_applicable,
        referralsToCareOutsideGingerNecessary:
          currentNote.TREATMENT_PLAN?.referralsToCareOutsideGingerNecessary ||
          previousNote.TREATMENT_PLAN.referralsToCareOutsideGingerNecessary,
        // this is not used in the psychiatry note, just in the therapy note.
        version:
          currentNote.TREATMENT_PLAN?.version || fallbackTreatmentPlanVersion,
        waitTimeDetrimentalEffect:
          currentNote.TREATMENT_PLAN?.waitTimeDetrimentalEffect ||
          previousNote.TREATMENT_PLAN.waitTimeDetrimentalEffect,
      };
    }
  }
  return {
    [PsychiatrySectionName.CHIEF_COMPLAINT]: chiefComplaint,

    [PsychiatrySectionName.ASSESSMENT]: assessment,

    [PsychiatrySectionName.COLLABORATION_PLAN]: collaborationPlan,

    [PsychiatrySectionName.SUBSTANCE_ABUSE]: substanceAbuse,

    [PsychiatrySectionName.SAFETY]: safety,

    [PsychiatrySectionName.PSYCH_HISTORY]: psychiatricHistory,

    [PsychiatrySectionName.ALLERGIES]: allergies,

    [PsychiatrySectionName.MEDICAL_HISTORY]: medicalHistory,

    [PsychiatrySectionName.SOCIAL_HISTORY]: socialHistory,

    [PsychiatrySectionName.FAMILY_HISTORY]: familyHistory,

    [PsychiatrySectionName.TREATMENT_PLAN]: treatmentPlan,

    [PsychiatrySectionName.PSYCH_ROS]: psychROS,

    [PsychiatrySectionName.MENTAL_STATUS_EXAM]: mentalStatusExam,
  };
}

async function createAmendment(
  userId: string,
  appointment: Appointment,
  api: PsychiatryProgressNotesAPI,
  noteState: StateController<PsychiatryProgressNote>,
  section: PsychiatryProgressSection,
  dispatch: ReturnType<typeof useDispatch>,
  logger: ILogger,
) {
  const note = noteState.state.current.data;
  if (note === null) {
    throw new Error(
      `Illegal state when trying to update a note section: ${noteState.state.current.status}`,
    );
  }

  const analyticsEventData = {
    appointmentId: appointment.id,
    clinicianId: userId,
    label: CareProviderNotesLabel.NOTE_AMENDED,
    memberId: appointment.member.id,
    noteType: ShareableClinicianNoteType.PSYCHIATRY_PROGRESS,
    section: section.name,
  };

  try {
    await api.createAmendment(userId, appointment.id, section);
  } catch (e) {
    logger.error(e, {
      message: `usePsychiatryProgressNote: failed to create amendment`,
      ...analyticsEventData,
    });
    dispatch(
      wroteClinicalNote({
        ...analyticsEventData,
        success: false,
      }),
    );
    throw e;
  }

  const updatedNote: PsychiatryProgressNote = {
    ...note,
    [section.name]: section.data,
  };
  noteState.setData(updatedNote);
  dispatch(
    wroteClinicalNote({
      ...analyticsEventData,
      success: true,
    }),
  );
}

function deleteDraftHandler(
  userId: string,
  appointment: Appointment,
  api: PsychiatryProgressNotesAPI,
  note: PsychiatryProgressNote,
  dispatch: ReturnType<typeof useDispatch>,
  logger: ILogger,
): () => Promise<DeleteVaultItemsMutation> {
  return async () => {
    const analyticsEventData = {
      appointmentId: note.metadata.appointmentId,
      clinicianId: userId,
      memberId: appointment.member.id,
      noteType: ShareableClinicianNoteType.PSYCHIATRY_PROGRESS,
    };

    try {
      const deletePromise = await api.deleteDraftNote(
        userId,
        appointment,
        note,
      );
      dispatch(
        deletedDraftClinicalNote({
          ...analyticsEventData,
          label: CareProviderNotesLabel.NOTE_DELETED,
          success: true,
        }),
      );
      logger.info('usePsychiatryProgressNote: deleted draft note', {
        ...analyticsEventData,
      });
      return deletePromise;
    } catch (e) {
      logger.error(e, {
        ...analyticsEventData,
        message: 'usePsychiatryProgressNote: failed to delete draft note',
      });
      dispatch(
        deletedDraftClinicalNote({
          ...analyticsEventData,
          label: CareProviderNotesLabel.NOTE_DELETED,
          success: false,
        }),
      );
      throw e;
    }
  };
}
