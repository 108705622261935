import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import { CollaborationPlan } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/CollaborationPlan';
import { NoteType as ClinicalNoteType } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/NoteType';
import {
  SafetyPlanStatus,
  Version as SafetyPlanVersion,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/safety/SafetyPlan';
import {
  Safety,
  Version as SafetyVersion,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyProgress';
import {
  SubstanceUse,
  SubstanceUse_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import {
  Assessment,
  Assessment_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/Assessment';
import {
  TreatmentPlan,
  TreatmentPlan_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/TreatmentPlan';
import {
  BehavioralObservations,
  BehavioralObservations_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/BehavioralObservations';
import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import {
  TherapyProgressSection,
  TherapyProgressSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';
import { DeleteVaultItemsMutation } from '@ginger.io/vault-ui/src/generated/graphql';
import { ShareableClinicianNoteType } from 'app/coach/coach-notes/CoachNotesTypes';
import { remapSubstanceUse } from 'app/notes-ui/shared/substance-abuse/utils';
import { useAppState } from 'app/state';
import { careProviderNotesEvent } from 'app/state/amplitude/actions/notes';
import { ILogger } from 'app/state/log/Logger';
import { useLogger } from 'app/state/log/useLogger';
import { SubsectionType } from 'app/vault/api/ShareableSubsectionTypes';
import {
  TherapyIntakeNote,
  TherapyIntakeNotesAPI,
} from 'app/vault/api/TherapyIntakeNotesAPI';
import {
  TherapyProgressNote,
  TherapyProgressNotesAPI,
} from 'app/vault/api/TherapyProgressNotesAPI';
import { decodeTherapyIntakeNote } from 'app/vault/data/decodeTherapyIntakeNote';
import { decodeTherapyProgressNote } from 'app/vault/data/decodeTherapyProgressNote';
import { GetAppointmentById_getAppointmentById as Appointment } from 'app/vault/generated/GetAppointmentById';
import { validateTherapyProgressNote } from 'app/vault/validations/therapyProgress';
import { NoteType } from 'generated/globalTypes';
import { useDeprecatedDiagnosisCodes } from 'hooks/useDiagnosisCodes';
import { FeatureFlags, useFeatureFlags } from 'hooks/useFeatureFlags';
import { useOnMount } from 'hooks/useOnMount';
import Messages from 'i18n/en/vault.json';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTimezone } from 'utils/dateTime';
import { CareProviderNotesLabel } from 'utils/notes';

import {
  useClinicalAppointmentsAPI,
  useTherapyIntakeNotesAPI,
  useTherapyProgressNotesAPI,
} from './useClinicalNotesAPI';
import {
  buildCodeSets,
  collaborationPlanGoalsToTreatmentGoals,
  isItemAlreadyExistsError,
  StateController,
  useStateSlice,
  UseTherapyNoteResult,
} from './utils';

export type IdAndNoteType = { id: string; noteType: NoteType };

export function useTherapyProgressNote(
  userId: string,
  appointmentId: string,
): UseTherapyNoteResult<TherapyProgressNote, TherapyProgressSection> {
  const logger = useLogger();
  const api = useTherapyProgressNotesAPI();
  const dispatch = useDispatch();
  const therapyIntakeAPI = useTherapyIntakeNotesAPI();
  const noteState = useStateSlice<TherapyProgressNote>();
  const noteDraftState = useStateSlice<TherapyProgressNote>();
  const clinicalAppointmentsAPI = useClinicalAppointmentsAPI();

  const timezone = useAppState((_) => getTimezone(_.user.timezone));

  const { transientFeatureFlags } = useFeatureFlags();
  const { getDeprecatedDiagnosisCodes } = useDeprecatedDiagnosisCodes();

  const allowGroupWriteAccess =
    transientFeatureFlags.enable_supervisor_sign_and_lock_notes_for_user;

  const isLockable = validateTherapyProgressNote(noteState.state.current.data);

  const [appointment, setAppointment] = useState<Appointment>();

  useOnMount(() => {
    const analyticsEventData = {
      appointmentId,
      clinicianId: userId,
      label: CareProviderNotesLabel.NOTE_VIEWED,
      noteType: ShareableClinicianNoteType.THERAPY_PROGRESS,
    };
    const callback = async () => {
      try {
        noteState.setLoading();

        const [
          appointmentData,
          previousApptIdAndNoteType,
          deprecatedDiagnosisCodes,
        ] = await Promise.all([
          api.getAppointment(appointmentId),
          clinicalAppointmentsAPI.getLatestCompleteClinicalAppointmentAndNoteType(
            appointmentId,
          ),
          getDeprecatedDiagnosisCodes(),
        ]);

        const data = await getNote(
          api,
          therapyIntakeAPI,
          userId,
          appointmentData,
          previousApptIdAndNoteType,
          transientFeatureFlags,
          deprecatedDiagnosisCodes,
          logger,
        );
        noteState.setData(data);
        noteDraftState.setData(data);
        setAppointment(appointmentData);
        logger.info('useTherapyProgressNote onMount succeeded', {
          ...analyticsEventData,
        });
      } catch (e) {
        noteState.setError(e);
        logger.error(e, {
          message: 'useTherapyProgressNote onMount failed',
          ...analyticsEventData,
        });
      }
    };
    void callback();
    dispatch(careProviderNotesEvent(analyticsEventData));
  });

  const createOrUpdateSection = useCallback(
    createOrUpdateSectionHandler(
      userId,
      appointment,
      api,
      noteState,
      allowGroupWriteAccess,
      dispatch,
      logger,
    ),
    [userId, appointment, api, noteState.state.current, allowGroupWriteAccess],
  );
  const updateDraftNoteState = useCallback(updateDraftState(noteDraftState), [
    noteDraftState.state.current,
  ]);

  const lockNote = useCallback(
    lockNoteHandler(
      userId,
      appointment,
      api,
      noteState,
      timezone,
      allowGroupWriteAccess,
      dispatch,
      logger,
    ),
    [
      appointment,
      api,
      noteState.state.current,
      timezone,
      userId,
      allowGroupWriteAccess,
    ],
  );

  const deleteDraft = useCallback(
    appointment && noteState.state.current.data
      ? deleteDraftHandler(
          userId,
          appointment,
          api,
          noteState.state.current.data,
          dispatch,
        )
      : () => Promise.resolve({} as DeleteVaultItemsMutation),
    [appointment, api, noteState.state.current],
  );

  return {
    appointment,
    createOrUpdateSection,
    deleteDraft,
    draftNote: noteDraftState.state,
    isLockable,
    lockNote,
    note: noteState.state,
    updateDraftNoteState,
  };
}

async function createAmendment(
  userId: string,
  appointment: Appointment,
  api: TherapyProgressNotesAPI,
  noteState: StateController<TherapyProgressNote>,
  section: TherapyProgressSection,
  dispatch: ReturnType<typeof useDispatch>,
) {
  const note = noteState.state.current.data;
  if (note === null) {
    throw new Error(
      `${Messages.failureToCreateAmendmentSection}: ${noteState.state.current.status}`,
    );
  }

  await api.createAmendment(userId, appointment.id, section);

  const updatedNote: TherapyProgressNote = {
    ...note,
    [section.name]: section.data,
  };
  noteState.setData(updatedNote);
  dispatch(
    careProviderNotesEvent({
      appointmentId: appointment.id,
      clinicianId: appointment.clinician.userId,
      label: CareProviderNotesLabel.NOTE_AMENDED,
      memberId: appointment.member.id,
      noteType: ShareableClinicianNoteType.THERAPY_PROGRESS,
    }),
  );
}

export const createOrUpdateSectionHandler = (
  userId: string,
  appointment: Appointment | undefined,
  api: TherapyProgressNotesAPI,
  noteState: StateController<TherapyProgressNote>,
  allowGroupWriteAccess: boolean,
  dispatch: ReturnType<typeof useDispatch>,
  logger: ILogger,
  onCreateDraftNoteSectionHandler = createDraftNoteSectionHandler,
  onUpdateDraftNoteSectionHandler = updateDraftNoteSectionHandler,
): ((section: TherapyProgressSection) => Promise<void>) => {
  return async (section: TherapyProgressSection) => {
    const note = noteState.state.current.data;
    if (note === null) {
      throw new Error(
        `${Messages.failureToCreateOrUpdateNoteSection}: ${noteState.state.current.status}`,
      );
    }

    if (appointment === undefined) {
      throw new Error(Messages.appointmentNotDefined);
    }

    if (api.amendmentSectionName === section.name) {
      return createAmendment(
        userId,
        appointment,
        api,
        noteState,
        section,
        dispatch,
      );
    }

    if (note[section.name] === null) {
      try {
        await onCreateDraftNoteSectionHandler({
          allowGroupWriteAccess,
          api,
          appointment,
          dispatch,
          logger,
          note,
          noteState,
          section,
          userId,
        });
      } catch (e) {
        if (!isItemAlreadyExistsError(e)) {
          logger.info(
            'useTherapyProgressNote: onCreateDraftNoteSectionHandler skipping retry',
            { error: e },
          );
          throw e;
        }
        logger.info(
          `useTherapyProgressNote: createOrUpdateSectionHandler failed for section ${section.name}, retrying as update...`,
          {
            appointmentId: appointment.id,
            clinicianId: userId,
            error: e,
            label: CareProviderNotesLabel.NOTE_CREATED,
            noteType: ShareableClinicianNoteType.THERAPY_PROGRESS,
            section: section.name,
          },
        );
        return await onUpdateDraftNoteSectionHandler({
          allowGroupWriteAccess,
          api,
          appointment,
          dispatch,
          logger,
          note,
          noteState,
          section,
          userId,
        });
      }
    } else if (note[section.name] !== null) {
      await onUpdateDraftNoteSectionHandler({
        allowGroupWriteAccess,
        api,
        appointment,
        dispatch,
        logger,
        note,
        noteState,
        section,
        userId,
      });
    }
  };
};

export type createOrUpdateDraftNoteSectionProps = {
  note: TherapyProgressNote;
  section: TherapyProgressSection;
  api: TherapyProgressNotesAPI;
  userId: string;
  appointment: Appointment;
  allowGroupWriteAccess: boolean;
  noteState: StateController<TherapyProgressNote>;
  dispatch: ReturnType<typeof useDispatch>;
  logger: ILogger;
};

export const createDraftNoteSectionHandler = async (
  data: createOrUpdateDraftNoteSectionProps,
) => {
  const {
    note,
    section,
    api,
    userId,
    appointment,
    allowGroupWriteAccess,
    noteState,
    dispatch,
    logger,
  } = data;

  const careProviderNoteEventPayload = {
    appointmentId: note.metadata.appointmentId,
    clinicianId: userId,
    label: CareProviderNotesLabel.NOTE_UPDATED,
    memberId: appointment.member.id,
    noteType: ShareableClinicianNoteType.THERAPY_PROGRESS,
    section: section.name,
  };
  // TODO: is this change in logic ok? Previously: needToCreateMetadata ? updatedNote.metadata : undefined,
  const needToCreateMetadata =
    note.metadata.status === Metadata_NoteStatus.undefined_note_status;

  const updatedNote: TherapyProgressNote = {
    ...note,
    metadata: {
      ...note.metadata,
      status: Metadata_NoteStatus.draft_note,
    },
    [section.name]: section.data,
  };

  await api.createDraftNoteSection(
    userId,
    appointment,
    section,
    needToCreateMetadata ? updatedNote.metadata : undefined,
    allowGroupWriteAccess,
  );
  logger.info(
    `useTherapyProgressNote: createOrUpdateSectionHandler successfully created draft section ${section.name}`,
    { ...careProviderNoteEventPayload },
  );

  noteState.setData(updatedNote);
  careProviderNoteEventPayload.label = CareProviderNotesLabel.NOTE_CREATED;
  dispatch(careProviderNotesEvent(careProviderNoteEventPayload));
};

export const updateDraftNoteSectionHandler = async (
  data: createOrUpdateDraftNoteSectionProps,
) => {
  const {
    note,
    section,
    api,
    userId,
    appointment,
    allowGroupWriteAccess,
    noteState,
    dispatch,
    logger,
  } = data;

  const amplitudePayload = {
    appointmentId: note.metadata.appointmentId,
    clinicianId: userId,
    label: CareProviderNotesLabel.NOTE_UPDATED,
    memberId: appointment.member.id,
    noteType: ShareableClinicianNoteType.THERAPY_PROGRESS,
    section: section.name,
  };
  const updatedNote = { ...note, [section.name]: section.data };

  try {
    await api.updateDraftNoteSection(
      userId,
      appointment,
      section,
      undefined,
      allowGroupWriteAccess,
    );
    logger?.info(
      `useTherapyProgressNote: createOrUpdateSectionHandler successfully updated draft section ${section.name}`,
      { ...amplitudePayload },
    );
  } catch (e) {
    // TODO: Why does handling happnen here for update but not for create?
    logger?.error(e, {
      message: 'useTherapyProgressNote: failed to updateDraftNoteSection',
      ...amplitudePayload,
    });
    throw e;
  }
  noteState.setData(updatedNote);
  dispatch(careProviderNotesEvent(amplitudePayload));
};

function updateDraftState(
  noteState: StateController<TherapyProgressNote>,
): (section: TherapyProgressSection) => void {
  return async (section: TherapyProgressSection) => {
    const note = noteState.state.current.data;
    if (note === null) {
      throw new Error(
        `${Messages.failureToUpdateDraftState}: ${noteState.state.current.status}`,
      );
    }

    if (section.name === TherapyProgressSectionName.AMENDMENTS) return;

    const updatedNote = { ...note, [section.name]: section.data };
    noteState.setData(updatedNote);
  };
}

function lockNoteHandler(
  clinicianId: string,
  appointment: Appointment | undefined,
  api: TherapyProgressNotesAPI,
  noteState: StateController<TherapyProgressNote>,
  timezone: string | null,
  allowGroupWriteAccess: boolean,
  dispatch: ReturnType<typeof useDispatch>,
  logger: ILogger,
): () => Promise<void> {
  return async () => {
    if (noteState.state.current.data === null) {
      throw new Error(
        `${Messages.failureToLockNote}: ${noteState.state.current.status}`,
      );
    }

    if (appointment === undefined) {
      throw new Error(Messages.appointmentNotDefined);
    }

    const appointmentId = appointment.id;
    const analyticsEventData = {
      appointmentId,
      clinicianId: appointment.clinician.userId,
      label: CareProviderNotesLabel.NOTE_SIGNED_AND_LOCKED,
      memberId: appointment.member.id,
      noteType: ShareableClinicianNoteType.THERAPY_PROGRESS,
      safetyRiskEndorsed:
        noteState.state.current.data.SAFETY?.anyChangesOrRisks ===
        BooleanOption.yes,
    };
    const updatedNote: TherapyProgressNote = {
      ...noteState.state.current.data,
      metadata: {
        appointmentId,
        status: Metadata_NoteStatus.signed_and_locked_note,
      },
    };

    try {
      await api.updateNoteMetadata(
        updatedNote.metadata,
        appointment.member.id,
        allowGroupWriteAccess,
      );
      logger.info(
        'useTherapyProgressNote: lockNoteHandler successfully updateNoteMetadata',
        { ...analyticsEventData },
      );
    } catch (e) {
      logger.error(e, {
        message:
          'useTherapyProgressNote: lockNoteHandler failed to updateNoteMetadata',
        ...analyticsEventData,
      });
      throw e;
    }

    try {
      await api.lockNoteSections(appointment);
      logger.info(
        'useTherapyProgressNote: lockNoteHandler successfully lockNoteSections',
        { ...analyticsEventData },
      );
    } catch (e) {
      logger.error(e, {
        message:
          'useTherapyProgressNote: lockNoteHandler failed to lockNoteSections',
        ...analyticsEventData,
      });
      throw e;
    }

    try {
      await api.createShareableSubsections(
        appointment,
        NoteType.THERAPY_PROGRESS,
        {
          [SubsectionType.SAFETY_PLAN]: updatedNote.SAFETY?.safetyPlan,
          [SubsectionType.TREATMENT_PLAN_GOALS]:
            updatedNote.TREATMENT_PLAN?.goal,
          [SubsectionType.MESSAGE_TO_CARE_TEAM]:
            updatedNote.TREATMENT_PLAN?.messageToCareTeam,
        },
      );
      logger.info(
        'useTherapyProgressNote: lockNoteHandler successfully createShareableSubsections',
        {
          ...analyticsEventData,
        },
      );
    } catch (e) {
      logger.error(e, {
        message:
          'useTherapyProgressNote: lockNoteHandler failed to createShareableSubsections. Continuing anyway.',
        ...analyticsEventData,
      });
    }

    noteState.setData(updatedNote);
    dispatch(careProviderNotesEvent(analyticsEventData));
  };
}

async function getNote(
  api: TherapyProgressNotesAPI,
  therapyIntakeAPI: TherapyIntakeNotesAPI,
  userId: string,
  appointment: Appointment,
  previousAppt: IdAndNoteType | null,
  transientFeatureFlags: Partial<FeatureFlags['transientFeatureFlags']> = {},
  deprecatedDiagnosisCodes: number[] = [],
  logger: ILogger,
): Promise<TherapyProgressNote> {
  const allowGroupWriteAccess =
    transientFeatureFlags.enable_supervisor_sign_and_lock_notes_for_user;
  const useSingleRequest =
    transientFeatureFlags.enable_single_request_on_progress_note_copied_section;

  const { id: appointmentId } = appointment;
  if (
    previousAppt === null ||
    (previousAppt.noteType !== NoteType.THERAPY_PROGRESS &&
      previousAppt.noteType !== NoteType.THERAPY_INTAKE)
  ) {
    return api.getNote(appointmentId);
  }

  let prevData: TherapyProgressNote | TherapyIntakeNote;
  let data: TherapyProgressNote;

  if (previousAppt.noteType === NoteType.THERAPY_PROGRESS) {
    const results = await Promise.all([
      api.getNote(appointmentId),
      api
        .getNote(previousAppt.id)
        .catch(() => decodeTherapyProgressNote(previousAppt.id, [], [])),
    ]);
    data = results[0];
    prevData = results[1];
  } else {
    const results = await Promise.all([
      api.getNote(appointmentId),
      therapyIntakeAPI
        .getNote(previousAppt.id)
        .catch(() => decodeTherapyIntakeNote(previousAppt.id, [], [])),
    ]);

    data = results[0];
    prevData = results[1];
  }

  const updatedNoteSection: Partial<TherapyProgressNote> = buildTherapyProgressNote(
    data,
    prevData,
    appointmentId,
    transientFeatureFlags,
    deprecatedDiagnosisCodes,
  );

  const sections: TherapyProgressSection[] = Object.entries(updatedNoteSection)
    .filter(([name]) => {
      const sectionName = name as TherapyProgressSectionName;
      return (
        data[sectionName] === null &&
        updatedNoteSection[sectionName] != null &&
        sectionName !== TherapyProgressSectionName.AMENDMENTS
      );
    })
    .map(([name, updatedData]) => {
      const sectionName = name as TherapyProgressSectionName;
      logger.info(
        `useTherapyProgressNote: ${sectionName} section in Vault doesn't exist. Creating draft section.`,
        {
          appointmentId,
          clinicianUserId: userId,
        },
      );
      return {
        data: updatedData,
        name,
      } as TherapyProgressSection;
    });

  if (sections.length > 0) {
    if (useSingleRequest) {
      data.metadata.status = Metadata_NoteStatus.draft_note;
      await api
        .createDraftNoteSections(
          userId,
          appointment,
          sections,
          data.metadata,
          allowGroupWriteAccess,
        )
        .catch((error) => {
          const sectionNames = sections.map((section) => section.name);
          sectionNames.forEach((section) => {
            updatedNoteSection[section] = null;
          });
          data.metadata.status = Metadata_NoteStatus.undefined_note_status;

          logger.error(error, {
            appointmentId,
            clinicianUserId: userId,
            message: `useTherapyProgressNote: Unable to copy forward from previous note. Clearing section data in local state.`,
            sectionNames,
          });
        });
    } else {
      await Promise.all(
        sections.map(async (section) =>
          api
            .createDraftNoteSection(
              userId,
              appointment,
              section,
              undefined,
              allowGroupWriteAccess,
            )
            .catch((error) => {
              const sectionName = section.name;
              updatedNoteSection[sectionName] = null;
              logger.error(error, {
                appointmentId,
                clinicianUserId: userId,
                message: `useTherapyProgressNote: ${sectionName} section failed on createDraftNoteSection.
               Clearing section data in local state.`,
                sectionName,
              });
            }),
        ),
      );
    }
  }

  return {
    ...data,
    ...updatedNoteSection,
  };
}

function buildTherapyProgressNote(
  currentNote: TherapyProgressNote,
  previousNote: TherapyProgressNote | TherapyIntakeNote,
  appointmentId: string,
  featureFlags: Partial<FeatureFlags['transientFeatureFlags']> = {},
  deprecatedDiagnosisCodes: number[] = [],
): Partial<TherapyProgressNote> {
  // copy forward from previous note: Safety.safetyPlan, SubstanceUsed current &
  // past used substances, Assessment icd-10 codes, CollaborationPlan goals
  // Note: Vault client may return null of each section of a previous note.
  // This can happen when previous note has not been shared with the clinician.

  // if notes efficiency feature flag is enabled, we will copy forward additional fields from previous note
  const {
    enable_care_hub_notes_efficiency: enableCareHubNotesEfficiency,
  } = featureFlags;

  let safety: Safety | null = null;
  let collaborationPlan: CollaborationPlan | null = null;
  let substanceAbuse: SubstanceUse | null = null;
  let assessment: Assessment | null = null;
  let behavioralObservation: BehavioralObservations | null = null;
  let treatmentPlan: TreatmentPlan | null = null;

  if (previousNote.SAFETY) {
    let fallbackSafetyVersion = SafetyVersion.undefined_version;
    if (enableCareHubNotesEfficiency) {
      fallbackSafetyVersion = SafetyVersion.v1;
    }
    safety = {
      anyChangesOrRisks:
        currentNote.SAFETY?.anyChangesOrRisks ||
        previousNote.SAFETY.anyChangesOrRisks,
      appointmentId,
      currentHomicidalIdeation:
        currentNote.SAFETY?.currentHomicidalIdeation ||
        previousNote.SAFETY.currentHomicidalIdeation,
      currentSelfInjury:
        currentNote.SAFETY?.currentSelfInjury ||
        previousNote.SAFETY.currentSelfInjury,
      currentSuicidalIdeation:
        currentNote.SAFETY?.currentSuicidalIdeation ||
        previousNote.SAFETY.currentSuicidalIdeation,
      domesticViolenceRisk:
        currentNote.SAFETY?.domesticViolenceRisk ||
        previousNote.SAFETY.domesticViolenceRisk,
      eatingDisorderRisk:
        currentNote.SAFETY?.eatingDisorderRisk ||
        previousNote.SAFETY.eatingDisorderRisk,
      highSubstanceUse:
        currentNote.SAFETY?.highSubstanceUse ||
        previousNote.SAFETY.highSubstanceUse,
      otherConcerns:
        currentNote.SAFETY?.otherConcerns || previousNote.SAFETY.otherConcerns,
      safetyPlan: {
        actionsTaken:
          currentNote.SAFETY?.safetyPlan?.actionsTaken ||
          previousNote.SAFETY?.safetyPlan?.actionsTaken,
        appointmentEnd: '',
        appointmentId: '',
        appointmentStart: '',
        description:
          currentNote.SAFETY?.safetyPlan?.description ??
          previousNote.SAFETY?.safetyPlan?.description ??
          '',
        noteType: ClinicalNoteType.undefined_note_type,
        psychiatryQuestions:
          currentNote.SAFETY?.safetyPlan?.psychiatryQuestions ||
          previousNote.SAFETY?.safetyPlan?.psychiatryQuestions,
        status:
          currentNote.SAFETY?.safetyPlan?.status ||
          previousNote.SAFETY.safetyPlan?.status ||
          SafetyPlanStatus.undefined_status,
        therapyQuestions:
          currentNote.SAFETY?.safetyPlan?.therapyQuestions ||
          previousNote.SAFETY?.safetyPlan?.therapyQuestions,
        version:
          currentNote.SAFETY?.safetyPlan?.version ||
          SafetyPlanVersion.undefined_version,
      },
      selectedConcerns:
        currentNote.SAFETY?.selectedConcerns ||
        previousNote.SAFETY.selectedConcerns ||
        [],
      version: currentNote.SAFETY?.version ?? fallbackSafetyVersion,
      vulnerablePopulationsAbuse:
        currentNote.SAFETY?.vulnerablePopulationsAbuse ||
        previousNote.SAFETY.vulnerablePopulationsAbuse,
    };
  }

  if (previousNote.COLLABORATION_PLAN) {
    const goals = previousNote.COLLABORATION_PLAN!.goal;
    collaborationPlan = {
      appointmentId,
      goal: currentNote.COLLABORATION_PLAN?.goal || goals,
    };
  }

  if (previousNote.ASSESSMENT) {
    const previousAssessment = previousNote.ASSESSMENT as TherapyProgressNote['ASSESSMENT'];
    assessment = {
      appointmentId,
      changeInSymptoms:
        currentNote.ASSESSMENT?.changeInSymptoms ||
        previousAssessment?.changeInSymptoms ||
        '',
      codeSets: buildCodeSets(
        currentNote.ASSESSMENT?.codeSets!,
        previousAssessment?.codeSets!,
        deprecatedDiagnosisCodes,
      ),
      cptCode: currentNote.ASSESSMENT?.cptCode! || 0,
      icd10Codes:
        currentNote.ASSESSMENT?.icd10Codes ||
        previousAssessment?.icd10Codes.filter(
          (c) => !deprecatedDiagnosisCodes.includes(c),
        ) ||
        [],
      version:
        currentNote.ASSESSMENT?.version || previousNote.ASSESSMENT.version,
    };

    if (enableCareHubNotesEfficiency) {
      assessment.changeInSymptoms =
        currentNote.ASSESSMENT?.changeInSymptoms || ''; // we don't want to copy forward change in symptoms from previous note
      assessment.version = Assessment_Version.v0;
    }
  }

  if (previousNote.SUBSTANCE_ABUSE) {
    let fallbackSubstanceAbuseVersion = SubstanceUse_Version.undefined_version;
    if (enableCareHubNotesEfficiency) {
      fallbackSubstanceAbuseVersion = SubstanceUse_Version.v0;
    }
    const substanceUsed = {
      anySubstanceUsed:
        previousNote.SUBSTANCE_ABUSE.anySubstanceUsed ?? BooleanOption.yes,
      appointmentId,
      pastEtohOrBenzoWithdrawal: previousNote.SUBSTANCE_ABUSE
        .pastEtohOrBenzoWithdrawal || {
        description: '',
        isPresent: false,
      },
      pastSubstanceUseTreatment: previousNote.SUBSTANCE_ABUSE
        .pastSubstanceUseTreatment || {
        description: '',
        isPresent: false,
      },
      substancesCurrentlyUsed:
        previousNote.SUBSTANCE_ABUSE!.substancesCurrentlyUsed || [],
      substancesPreviouslyUsed:
        previousNote.SUBSTANCE_ABUSE!.substancesPreviouslyUsed || [],
      version: fallbackSubstanceAbuseVersion,
    };
    substanceAbuse = {
      anySubstanceUsed:
        currentNote.SUBSTANCE_ABUSE?.anySubstanceUsed ??
        substanceUsed.anySubstanceUsed,
      appointmentId,
      pastEtohOrBenzoWithdrawal: {
        description:
          currentNote.SUBSTANCE_ABUSE?.pastEtohOrBenzoWithdrawal?.description ??
          substanceUsed.pastEtohOrBenzoWithdrawal.description ??
          '',
        isPresent:
          currentNote.SUBSTANCE_ABUSE?.pastEtohOrBenzoWithdrawal?.isPresent ||
          substanceUsed.pastEtohOrBenzoWithdrawal.isPresent,
      },
      pastSubstanceUseTreatment: {
        description:
          currentNote.SUBSTANCE_ABUSE?.pastSubstanceUseTreatment?.description ??
          substanceUsed.pastSubstanceUseTreatment.description ??
          '',
        isPresent:
          currentNote.SUBSTANCE_ABUSE?.pastSubstanceUseTreatment?.isPresent ||
          substanceUsed.pastSubstanceUseTreatment.isPresent,
      },
      substancesCurrentlyUsed: remapSubstanceUse(
        currentNote.SUBSTANCE_ABUSE?.substancesCurrentlyUsed ??
          substanceUsed.substancesCurrentlyUsed,
      ),
      substancesPreviouslyUsed: remapSubstanceUse(
        currentNote.SUBSTANCE_ABUSE?.substancesPreviouslyUsed ??
          substanceUsed.substancesPreviouslyUsed,
      ),
      version: currentNote.SUBSTANCE_ABUSE?.version ?? substanceUsed.version,
    };
  }

  if (previousNote.BEHAVIORAL_OBSERVATION && enableCareHubNotesEfficiency) {
    behavioralObservation = {
      affect:
        currentNote.BEHAVIORAL_OBSERVATION?.affect ||
        previousNote.BEHAVIORAL_OBSERVATION?.affect,
      appointmentId,
      insight:
        currentNote.BEHAVIORAL_OBSERVATION?.insight ||
        previousNote.BEHAVIORAL_OBSERVATION?.insight,
      interventionsUsed:
        currentNote.BEHAVIORAL_OBSERVATION?.interventionsUsed || [],
      judgment:
        currentNote.BEHAVIORAL_OBSERVATION?.judgment ||
        previousNote.BEHAVIORAL_OBSERVATION?.judgment,
      mood:
        currentNote.BEHAVIORAL_OBSERVATION?.mood ||
        previousNote.BEHAVIORAL_OBSERVATION?.mood ||
        [],
      speech:
        currentNote.BEHAVIORAL_OBSERVATION?.speech ||
        previousNote.BEHAVIORAL_OBSERVATION?.speech ||
        [],
      thoughtContent:
        currentNote.BEHAVIORAL_OBSERVATION?.thoughtContent ||
        previousNote.BEHAVIORAL_OBSERVATION?.thoughtContent ||
        [],
      thoughtProcess:
        currentNote.BEHAVIORAL_OBSERVATION?.thoughtProcess ||
        previousNote.BEHAVIORAL_OBSERVATION?.thoughtProcess ||
        [], // we don't want to copy forward interventions used from previous note
      version:
        currentNote.BEHAVIORAL_OBSERVATION?.version ||
        BehavioralObservations_Version.v0,
    };
  }

  if (previousNote.TREATMENT_PLAN && enableCareHubNotesEfficiency) {
    const previousIntakeTreatmentPlan = previousNote.TREATMENT_PLAN as TherapyIntakeNote['TREATMENT_PLAN'];
    const previousProgressTreatmentPlan = previousNote.TREATMENT_PLAN as TherapyProgressNote['TREATMENT_PLAN'];

    treatmentPlan = {
      additionalSessionsRequired:
        currentNote.TREATMENT_PLAN?.additionalSessionsRequired ?? 0,
      anticipatedSession:
        currentNote.TREATMENT_PLAN?.anticipatedSession ??
        previousIntakeTreatmentPlan?.anticipatedSession ??
        previousProgressTreatmentPlan?.anticipatedSession,
      appointmentId,
      appointmentOffered:
        currentNote.TREATMENT_PLAN?.appointmentOffered ??
        previousIntakeTreatmentPlan?.appointmentOffered ??
        previousProgressTreatmentPlan?.appointmentOffered ??
        BooleanOption.undefined_choice,
      // we don't want to copy forward the message to care team from the previous note
      approaches:
        currentNote.TREATMENT_PLAN?.approaches ??
        previousIntakeTreatmentPlan?.approaches ??
        previousProgressTreatmentPlan?.approaches ??
        [],

      approachesUpdateComments:
        currentNote.TREATMENT_PLAN?.approachesUpdateComments ?? '',

      changesToTreatmentPlan:
        currentNote.TREATMENT_PLAN?.changesToTreatmentPlan ?? '',

      goal: collaborationPlanGoalsToTreatmentGoals(currentNote, previousNote),

      interventionsUsed: currentNote.TREATMENT_PLAN?.interventionsUsed ?? [],

      memberOutOfScope: currentNote.TREATMENT_PLAN?.memberOutOfScope ?? false,

      memberOutOfScopeReason:
        currentNote.TREATMENT_PLAN?.memberOutOfScopeReason ??
        previousIntakeTreatmentPlan?.memberOutOfScopeReason ??
        previousProgressTreatmentPlan?.memberOutOfScopeReason ??
        '',

      messageToCareTeam: currentNote.TREATMENT_PLAN?.messageToCareTeam ?? '',
      offeredAppointmentAccepted:
        currentNote.TREATMENT_PLAN?.offeredAppointmentAccepted ??
        previousIntakeTreatmentPlan?.offeredAppointmentAccepted ??
        previousProgressTreatmentPlan?.offeredAppointmentAccepted ??
        BooleanOption.undefined_choice,
      otherApproachComment:
        currentNote.TREATMENT_PLAN?.otherApproachComment ?? '',
      referralsToCareOutsideGingerNecessary:
        currentNote.TREATMENT_PLAN?.referralsToCareOutsideGingerNecessary ?? '',
      version: currentNote.TREATMENT_PLAN?.version ?? TreatmentPlan_Version.v0,
      waitTimeDetrimentalEffect:
        currentNote.TREATMENT_PLAN?.waitTimeDetrimentalEffect ?? false,
    };
  }

  if (enableCareHubNotesEfficiency) {
    return {
      [TherapyProgressSectionName.ASSESSMENT]: assessment,

      [TherapyProgressSectionName.BEHAVIORAL_OBSERVATION]: behavioralObservation,

      [TherapyProgressSectionName.COLLABORATION_PLAN]: collaborationPlan,

      [TherapyProgressSectionName.SUBSTANCE_ABUSE]: substanceAbuse,

      [TherapyProgressSectionName.SAFETY]: safety,

      [TherapyProgressSectionName.TREATMENT_PLAN]: treatmentPlan,
    };
  }
  return {
    [TherapyProgressSectionName.ASSESSMENT]: assessment,

    [TherapyProgressSectionName.COLLABORATION_PLAN]: collaborationPlan,

    [TherapyProgressSectionName.SUBSTANCE_ABUSE]: substanceAbuse,

    [TherapyProgressSectionName.SAFETY]: safety,
  };
}

export function getPreviousAppointment(
  appt: Appointment,
): IdAndNoteType | null {
  let previousAppt: IdAndNoteType | null = null;
  if (appt.previousAppointment) {
    const {
      id,
      clinicalNote: { noteType },
    } = appt.previousAppointment;
    previousAppt = { id, noteType: noteType! };
  }
  return previousAppt;
}

function deleteDraftHandler(
  userId: string,
  appointment: Appointment,
  api: TherapyProgressNotesAPI,
  note: TherapyProgressNote,
  dispatch: ReturnType<typeof useDispatch>,
): () => Promise<DeleteVaultItemsMutation> {
  return async () => {
    const deletePromise = await api.deleteDraftNote(userId, appointment, note);
    dispatch(
      careProviderNotesEvent({
        appointmentId: note.metadata.appointmentId,
        clinicianId: userId,
        label: CareProviderNotesLabel.NOTE_DELETED,
        memberId: appointment.member.id,
        noteType: ShareableClinicianNoteType.THERAPY_PROGRESS,
      }),
    );
    return deletePromise;
  };
}
