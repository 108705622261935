import { KeyboardArrowRight } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import { useAppState, useDispatch } from 'app/state';
import { toggleCardExpansion } from 'app/state/features/persistedLayout/persistedLayoutSlice';
import { selectCardState } from 'app/state/features/persistedLayout/selectors';
import { useLogger } from 'app/state/log/useLogger';
import { selectActiveTabMemberId } from 'app/state/member-tabs/selectors';
import { classNameCombiner } from 'utils';
import React, { ReactNode, useEffect, useState } from 'react';

import styles from './Card.module.scss';

export type CardProps = {
  children: ReactNode;
  chip: ReactNode;
  boxTitle?: string;
  centered?: boolean;
  'data-testid'?: string;
  size?: 'small' | 'medium' | 'large' | 'fit-vh' | 'fit-container';
  boxContentClass?: string;
  isScrollable?: boolean;
  isCollapsible?: boolean;
};

export default function ChipCard({
  children,
  chip,
  boxTitle,
  centered,
  'data-testid': testId,
  size = 'medium',
  boxContentClass = '',
  isScrollable = true,
  isCollapsible = true,
}: CardProps): JSX.Element {
  const [isOverflowHidden, setIsOverflowHidden] = useState(!isScrollable);
  const memberId = useAppState((state) => selectActiveTabMemberId(state));
  const logger = useLogger();
  const isBoxExpanded = useAppState((state) =>
    selectCardState(state, memberId, boxTitle),
  );

  const dispatch = useDispatch();

  useEffect(() => setIsOverflowHidden(!isScrollable), [isScrollable]);

  const handleBoxHeaderClick = (
    e: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    e.preventDefault();
    if (!memberId || !boxTitle) {
      logger.warning(
        'ChipCard: Failed to toggle card expansion: memberId or boxTitle is missing',
        {
          boxTitle,
          memberId,
        },
      );
      return;
    }
    dispatch(
      toggleCardExpansion({
        cardTitle: boxTitle,
        memberId,
      }),
    );
  };

  const titleStyle: string = centered
    ? classNameCombiner([styles.centered, styles.boxTitleCenter])
    : styles.boxTitle;
  const boxHeader: JSX.Element | null = boxTitle ? (
    <>
      <div className={styles.actionMenu}>{chip}</div>
      <button
        className={styles.boxHeader}
        onClick={handleBoxHeaderClick}
        disabled={!isCollapsible}
        data-testid="box-header"
      >
        {isCollapsible && (
          <div
            className={
              isBoxExpanded
                ? styles.expandIcon
                : classNameCombiner([styles.expandIcon, styles.rotate90deg])
            }
          >
            <KeyboardArrowRight />
          </div>
        )}
        <h4 className={titleStyle}>{boxTitle}</h4>
        <div className={styles.toggleBox} />
      </button>
    </>
  ) : null;

  const hideOverflow = () => {
    setIsOverflowHidden(true);
  };
  const showOverflow = () => {
    setIsOverflowHidden(false);
  };

  const contentStyle: string = boxTitle
    ? classNameCombiner([
        styles.boxContent,
        styles[`boxContent-${size}`],
        styles.hr,
        boxContentClass,
      ])
    : styles.boxContent;
  const boxContent = (
    <Collapse
      onEntering={hideOverflow}
      onEntered={showOverflow}
      onExiting={hideOverflow}
      onExited={showOverflow}
      in={isBoxExpanded}
      timeout={300}
      children={children}
      className={classNameCombiner([
        contentStyle,
        isOverflowHidden || !isScrollable
          ? styles.hideScrollbar
          : styles.showScrollbar,
      ])}
    />
  );

  return (
    <div
      data-testid={testId}
      className={classNameCombiner([styles.box, styles[`box-${size}`]])}
    >
      {boxHeader}
      {boxContent}
    </div>
  );
}
