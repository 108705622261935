import { useForm } from '@ginger.io/react-use-form';
import { Assessment } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/Assessment';
import { CPTCode } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/CPTCode';
import {
  AssessmentSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { CodeSetFields } from 'app/notes-ui/shared/code-set/CodeSetFields';
import {
  AUTO_SAVE_DRAFT_DELAY,
  codeSetsFromAssessment,
} from 'app/notes-ui/utils';
import { acceptedTherapyCptCodes, filterCptCodes } from 'utils/notes';
import React from 'react';

import { fieldDefs } from './schema';

type Props = {
  appointmentId: string;
  onSubmit: (data: AssessmentSection) => void;
  updateDraftNoteState: (data: AssessmentSection) => void;
  initialValue?: Assessment;
  disabled?: boolean;
  savingNote?: boolean;
};

export function AssessmentForm(props: Props) {
  const { disabled, initialValue, appointmentId, savingNote } = props;

  const { fields, getValue, validate } = useForm<Assessment>(
    fieldDefs,
    {
      ...initialValue,
      appointmentId,
      codeSets: codeSetsFromAssessment(initialValue),
    } as Assessment,
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: TherapyIntakeSectionName.ASSESSMENT,
        }),
    },
  );

  const onSubmit = async () => {
    if (await validate()) {
      props.onSubmit({
        data: getValue(),
        name: TherapyIntakeSectionName.ASSESSMENT,
      });
    }
  };

  return (
    <>
      <CodeSetFields
        disabled={disabled}
        cptCodeEnum={filterCptCodes(CPTCode, acceptedTherapyCptCodes)}
        field={fields.codeSets}
      />

      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </>
  );
}
