import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DropInAlertType, getInitialDropInAlertState } from './schema';

const structuredCoachingSlice = createSlice({
  initialState: getInitialDropInAlertState(),
  name: 'structuredCoaching',
  reducers: {
    updateCurrentAlert: (
      state,
      action: PayloadAction<DropInAlertType | undefined>,
    ) => {
      state.currentAlert = action.payload;
      state.displayedAt = action.payload ? new Date().toISOString() : undefined;
    },
  },
});

export const { updateCurrentAlert } = structuredCoachingSlice.actions;
export const { reducer } = structuredCoachingSlice;
