import notificationSound from 'app/coach/chat/audio/notification.wav';
import { setShouldPlayNotificationSound } from 'app/state/chat/actions';
import { selectShouldPlaySound } from 'app/state/chat/selectors';
import { useLogger } from 'app/state/log/useLogger';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useSoundOnNewMemberMessage = () => {
  const shouldPlay = selectShouldPlaySound();
  const logger = useLogger();
  const dispatch = useDispatch();

  const playAudio = useCallback(() => {
    const audio = new Audio(notificationSound);
    audio.play().catch((error) => {
      logger.error(
        new Error(
          `AudioNotification.playAudio: Haven't played a notification sound`,
        ),
        {
          error,
        },
      );
    });
  }, [logger]);

  useEffect(() => {
    if (shouldPlay) {
      void playAudio();
      dispatch(setShouldPlayNotificationSound({ shouldPlaySound: false }));
    }
  }, [shouldPlay, playAudio, dispatch]);
};
