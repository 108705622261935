import { useForm } from '@ginger.io/react-use-form';
import {
  TreatmentGoal_GoalStatus as GoalStatus,
  TreatmentGoal_GoalType as GoalType,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/TreatmentGoal';
import { TreatmentGoals } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/TreatmentGoals';
import { Typography } from '@mui/material';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import { CardTextArea } from 'app/member-chart-cards/text-area/CardTextArea';
import { treatmentGoalsFieldDefs } from 'app/notes-ui/shared/treatment-plan/schema';
import { enumToOptions } from 'utils/notes';
import { Subsection } from 'app/vault/api/ShareableSubsectionTypes';
import React, { Fragment } from 'react';

enum FieldLabels {
  GOAL_TYPE = 'What is the member working on?',
  GOAL_DETAILS = 'SMART goal details',
  GOAL_STATUS = 'Goal status',
  ACTION_PLAN = "Today's action steps",
}

export const TreatmentPlanGoals = ({
  selectedNote,
  readOnly,
  tooltipEventLabel,
}: {
  readOnly: boolean;
  tooltipEventLabel: string;
  selectedNote?: Subsection;
}): React.ReactElement | null => {
  const { fields } = useForm<TreatmentGoals>(treatmentGoalsFieldDefs, {
    ...(selectedNote as TreatmentGoals),
  });

  return (
    <>
      {fields.goal.value?.map((goal, index) => (
        <Fragment key={index}>
          <div className={styles.titleField}>
            <Typography className={styles.treatmentGoalTitle}>
              {`Goal #${index + 1}`}
            </Typography>
            <span className={`${styles.inlineBorder} ${styles.blueBorder}`} />
          </div>

          <FieldLabel
            className={styles.label}
            labelText={FieldLabels.GOAL_TYPE}
          />
          <CardTextArea
            value={
              enumToOptions(GoalType).filter(
                (option) => option.value === goal.goalType,
              )[0]?.name
            }
            tooltipEventLabel={`${tooltipEventLabel}: ${FieldLabels.GOAL_TYPE}`}
            tooltipText=""
            placeholder=""
            readOnly={readOnly}
            multiline={false}
          />

          <FieldLabel
            className={styles.label}
            labelText={FieldLabels.GOAL_DETAILS}
          />
          <CardTextArea
            value={goal.goalDetails}
            tooltipEventLabel={`${tooltipEventLabel}: ${FieldLabels.GOAL_DETAILS}`}
            tooltipText=""
            placeholder=""
            readOnly={readOnly}
            minRows={1}
          />

          <FieldLabel
            className={styles.label}
            labelText={FieldLabels.GOAL_STATUS}
          />
          <CardTextArea
            value={
              enumToOptions(GoalStatus).filter(
                (option) => option.value === goal.goalStatus,
              )[0]?.name
            }
            tooltipEventLabel={`${tooltipEventLabel}: ${FieldLabels.GOAL_STATUS}`}
            tooltipText=""
            placeholder=""
            readOnly={readOnly}
            multiline={false}
          />

          <FieldLabel
            className={styles.label}
            labelText={FieldLabels.ACTION_PLAN}
          />
          <CardTextArea
            value={goal.actionPlan}
            tooltipEventLabel={`${tooltipEventLabel}: ${FieldLabels.ACTION_PLAN}`}
            tooltipText=""
            placeholder=""
            readOnly={readOnly}
          />
        </Fragment>
      ))}
    </>
  );
};
