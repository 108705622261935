import { stringField, useForm } from '@ginger.io/react-use-form';
import { Amendment as AmendmentType } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/Amendment';
import Divider from '@mui/material/Divider';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import React from 'react';

import { AmendmentInput } from './AmendmentInput';
import { LockedAmendments } from './LockedAmendments';
import { AmendmentsSection, AmendmentWithAuditLog } from './types';

type Props<T extends AmendmentsSection> = {
  appointmentId: string;
  sectionName: T['name'];
  onSubmit: (section: { name: T['name']; data: T['data'] }) => void;
  previousValues?: AmendmentWithAuditLog[];
  disabled?: boolean;
  savingNote?: boolean;
};

export function AmendmentsForm<T extends AmendmentsSection>(props: Props<T>) {
  const {
    sectionName,
    appointmentId,
    disabled,
    savingNote,
    previousValues = [],
  } = props;

  const newAmendment = useForm<AmendmentType>({
    appointmentId: stringField({ default: appointmentId }),
    text: stringField(),
  });

  const onSubmit = async () => {
    const amendmentIsValid = await newAmendment.validate();

    if (amendmentIsValid) {
      props.onSubmit({
        data: newAmendment.getValue(),
        name: sectionName,
      });
    }
  };

  return (
    <>
      <AmendmentInput
        disabled={disabled}
        name="newAmendment"
        label="Amendment"
        fields={newAmendment.fields}
      />

      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />

      <Divider />

      <LockedAmendments amendmentsWithAuditLogs={previousValues} />
    </>
  );
}
