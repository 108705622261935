import { ApolloError } from '@apollo/client';
import { CustomError } from 'app/appointments/errorUtils';

import { State as ReqState } from './actions';

export interface RequestSuccessState {
  queryName: string;
  timestamp: string;
  state: ReqState.SUCCESS;
}

export interface RequestLoadingState {
  queryName: string;
  state: ReqState.SAVING;
}

export interface RequestErrorState {
  queryName: string;
  state: ReqState.ERROR;
  error: ApolloError | string | CustomError;
}

export type RequestState =
  | RequestSuccessState
  | RequestLoadingState
  | RequestErrorState;

export type State = Readonly<Record<string, RequestState | undefined>>;

export function getInitialState(): State {
  return {};
}
