import gql from 'graphql-tag';

import { UserFragment } from './fragments/User';

export const getMemberQuery = gql`
  query GetMemberById($input: GetMemberInput!) {
    getMember(input: $input) {
      id
      callerId
      isFusion
      isD2c
      ...User
    }
  }
  ${UserFragment}
`;

/**
 * This query is used to get the member and their care team. It is used in the member details page.
 * The reason to use this query instead of the getMemberQuery is that the getMemberQuery is used in
 * the member chart and we don't want to add the care team to the member chart query as care team resolvers
 * are expensive to compute and may not be needed on common use cases.
 */
export const getMemberQueryWithCareTeam = gql`
  query GetMemberQueryWithCareTeam($input: GetMemberInput!) {
    getMember(input: $input) {
      id
      callerId
      isFusion
      isD2c
      isMinorDependent
      intakeCompletedAt
      ...User
    }
  }
  ${UserFragment}
`;

export const getMemberCoachingTeam = gql`
  query GetMemberCoachingTeam($input: GetMemberInput!) {
    getMember(input: $input) {
      id
      coachingCareTeam {
        current {
          coaches {
            careTeamRole
            gingerId
            isBackup
            id
            isTeamLead
          }
        }
        past {
          coaches {
            careTeamRole
            gingerId
            isBackup
            id
            isTeamLead
            relationshipLastUpdated
          }
        }
      }
    }
  }
`;
