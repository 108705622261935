import { Add as AddCircleIcon, KeyboardArrowRight } from '@mui/icons-material';
import { Collapse, Popover } from '@mui/material';
import AddHomeworkDialog from 'app/patients/tabs/content/AddHomeworkDialog';
import styles from 'app/patients/tabs/content/ContentSection.module.scss';
import { classNameCombiner, toKebabCase } from 'utils';
import React, { ReactNode, useState } from 'react';

export type ContentSectionProps = {
  children: ReactNode;
  headerStyle: 'light-rose' | 'light-cloud' | 'light-mint' | 'light-grey';
  sectionIcon?: string;
  sectionTitle?: string;
  testId?: string;
  memberId?: string;
  isExpandedByDefault?: boolean;
  isCollapsible?: boolean;
  useAddCircleButton?: boolean;
  addCircleButtonCallback?: Function;
};

export const ContentSection = ({
  children,
  sectionIcon,
  sectionTitle,
  headerStyle,
  testId,
  memberId = '',
  isExpandedByDefault = true,
  isCollapsible = true,
  useAddCircleButton = false,
  addCircleButtonCallback = undefined,
}: ContentSectionProps): JSX.Element => {
  const [isSectionExpanded, setIsSectionExpanded] = useState(
    isExpandedByDefault,
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (addCircleButtonCallback !== undefined) {
      addCircleButtonCallback();
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleSectionHeaderClick = () => {
    if (isCollapsible) {
      setIsSectionExpanded((prevState) => !prevState);
    }
  };

  const sectionHeader = sectionTitle ? (
    <div
      className={classNameCombiner([
        styles.sectionHeaderWrapper,
        sectionTitle ? styles[toKebabCase(sectionTitle.toLowerCase())] : '',
      ])}
    >
      <div
        data-testid={testId}
        className={classNameCombiner([
          styles.sectionHeader,
          styles[headerStyle],
        ])}
        onClick={handleSectionHeaderClick}
        onKeyDown={handleSectionHeaderClick}
        role="button"
        tabIndex={0}
        aria-expanded={isSectionExpanded}
      >
        {sectionIcon && (
          <div
            className={classNameCombiner([
              styles.sectionIcon,
              styles[sectionIcon],
            ])}
          />
        )}
        <div className={styles.sectionTitle}>{sectionTitle}</div>
        {isCollapsible && (
          <div
            className={
              isSectionExpanded
                ? styles.expandIcon
                : classNameCombiner([styles.expandIcon, styles.rotate90deg])
            }
          >
            <KeyboardArrowRight />
          </div>
        )}
        {useAddCircleButton && (
          <button
            className={styles.addCircleButton}
            onClick={handleClick}
            data-testid="add_content_button"
          >
            <AddCircleIcon className={styles.addCircleIcon} />
          </button>
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        >
          <AddHomeworkDialog memberId={memberId} onClose={handleClose} />
        </Popover>
      </div>
    </div>
  ) : null;

  const sectionContent = (
    <Collapse
      in={isSectionExpanded}
      timeout={400}
      children={children}
      className={
        sectionTitle
          ? styles.sectionContent
          : classNameCombiner([styles.sectionContent, styles.paddingTop])
      }
    />
  );

  return (
    <>
      {sectionHeader}
      <div className={styles.section}>{sectionContent}</div>
    </>
  );
};
