import {
  OutOfSessionNote,
  OutOfSessionNote_ContactReason,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/OutOfSessionNote';
import {
  TerminationNote,
  TerminationNote_Reason,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/TerminationNote';
import Labels from 'app/notes-ui/strings/en.json';
import { therapyIntakeSectionToLabel } from 'app/notes-ui/utils';

import { NotePDFBuilder, NotePDFBuilderImpl } from './NotePDFBuilder';
import { NoteHeader } from './types';

export type NonAppointmentNote = TerminationNote | OutOfSessionNote;

export abstract class NonAppointmentNotePDF<T extends NonAppointmentNote> {
  protected constructor(
    protected note: T,
    protected noteHeader: NoteHeader,
    protected timezone: string,
    protected pdfBuilder: NotePDFBuilder = new NotePDFBuilderImpl(),
  ) {}

  abstract getPDF(): typeof PDFDocument;

  protected header() {
    this.pdfBuilder.noteHeader(this.noteHeader);
  }
}

export class TerminationNotePDF extends NonAppointmentNotePDF<TerminationNote> {
  constructor(
    protected note: TerminationNote,
    protected noteHeader: NoteHeader,
    protected password?: string,
    protected enableNewPdfFont?: boolean,
    protected timezone = 'America/Los_Angeles',
    protected pdfBuilder: NotePDFBuilder = new NotePDFBuilderImpl(
      password,
      therapyIntakeSectionToLabel,
      enableNewPdfFont,
    ),
  ) {
    super(note, noteHeader, timezone, pdfBuilder);
  }

  getPDF(): typeof PDFDocument {
    this.header();

    this.pdfBuilder
      .enumSelectionsGroup(
        TerminationNote_Reason,
        this.note.reasons,
        Labels.REASON,
        'No reason selected.',
      )
      .padding()
      .longTextAnswer(Labels.TREATMENT_SUMMARY, this.note.treatmentSummary)
      .padding()
      .longTextAnswer(Labels.AFTER_CARE_PLAN, this.note.careContinuationPlan);

    if (this.note.goalsMet) {
      this.pdfBuilder
        .padding()
        .longTextAnswer(Labels.GOALS_MET, this.note.goalsMet);
    }

    return this.pdfBuilder.build();
  }
}

export class OutOfSessionNotePDF extends NonAppointmentNotePDF<
  OutOfSessionNote
> {
  constructor(
    protected note: OutOfSessionNote,
    protected noteHeader: NoteHeader,
    protected password?: string,
    protected timezone = 'America/Los_Angeles',
    protected pdfBuilder: NotePDFBuilder = new NotePDFBuilderImpl(
      password,
      therapyIntakeSectionToLabel,
    ),
  ) {
    super(note, noteHeader, timezone, pdfBuilder);
  }

  getPDF(): typeof PDFDocument {
    this.header();

    this.pdfBuilder
      .shortTextAnswer(
        Labels.INDIVIDUAL_CONTACTED,
        this.note.individualContacted,
      )
      .padding();

    if (this.note.reasonForContact) {
      this.pdfBuilder
        .radioItemAnswer(
          OutOfSessionNote_ContactReason,
          Labels.REASON_FOR_CONTACT,
          this.note.reasonForContact,
        )
        .padding();
    } else {
      this.pdfBuilder
        .shortTextAnswer(Labels.PURPOSE_OF_NOTE, this.note.purposeOfNote)
        .padding();
    }

    if (this.note.nextSteps) {
      this.pdfBuilder
        .longTextAnswer(Labels.NEXT_STEPS, this.note.nextSteps)
        .padding();
    }

    this.pdfBuilder
      .longTextAnswer(
        Labels.SUMMARY_OF_CONTACT_AND_OUTCOME,
        this.note.topicsDiscussed,
      )
      .padding()
      .yesOrNoAnswer(
        Labels.RELEASE_OF_INFORMATION,
        this.note.releaseOfInformationObtainedIfIndicated,
      );

    return this.pdfBuilder.build();
  }
}
