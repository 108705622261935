import {
  CareProviderSentSomeLinkAmplitudeEvent,
  ChatErrorAmplitudeEvent,
  ChatTextAreaFocusAmplitudeEvent,
  ReadMessageAmplitudeEvent,
  TypingMessageAmplitudeEvent,
  WriteMessageAmplitudeEvent,
} from 'app/coach/chat/types';
import { EventType } from 'app/EventType';
import { createAction } from 'redux-reloaded';

export const careProviderReadMessagesEvent = createAction(
  EventType.LISTENER_CHAT_READ,
  (payload: ReadMessageAmplitudeEvent) => ({ ...payload }),
);
export const careProviderTypingMessagesEvent = createAction(
  EventType.LISTENER_MSG_TYPING,
  (payload: TypingMessageAmplitudeEvent) => ({ ...payload }),
);
export const careProviderWriteMessageEvent = createAction(
  EventType.LISTENER_CHAT_WRITE,
  (payload: WriteMessageAmplitudeEvent) => ({ ...payload }),
);
export const textAreaFocusChangeEvent = createAction(
  EventType.LISTENER_MSG_FOCUS,
  (payload: ChatTextAreaFocusAmplitudeEvent) => ({ ...payload }),
);
export const careProviderSentSomeLinkEvent = createAction(
  EventType.LISTENER_SENT_LINK,
  (payload: CareProviderSentSomeLinkAmplitudeEvent) => ({ ...payload }),
);
export const chatErrorAmplitudeEvent = createAction(
  EventType.LISTENER_CHAT_ERROR,
  (payload: ChatErrorAmplitudeEvent) => ({ ...payload }),
);
