import { CoachClinicianCollaborationChatMessage_Author_AuthorType as AuthorType } from '@ginger.io/vault-care-collaboration/dist/generated/protobuf-schemas/vault-care-collaboration/CoachClinicianCollaborationChatMessage';
import { getVaultWebUserId } from '@ginger.io/vault-core/dist/IdHelpers';
import { ChatCollaborationScreen } from '@ginger.io/vault-ui';
import { useAppState } from 'app/state';
import React from 'react';

import styles from './ChatCollaboration.module.scss';

interface Props {
  memberId: string;
}
export default function ChatCollaboration(props: Props) {
  const { memberId } = props;
  const { userId, firstName, lastName } = useAppState((_) => _.user);
  return (
    <ChatCollaborationScreen
      data-testid="chatCollaborationScreen"
      className={styles.root}
      memberId={memberId}
      author={{
        firstName: firstName!,
        id: getVaultWebUserId(userId!),
        lastName: lastName!,
        type: AuthorType.clinician,
      }}
    />
  );
}
