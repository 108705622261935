import {
  SubstanceUse,
  SubstanceUse_SubstanceType,
  SubstanceUse_SubstanceUseLineItem,
  SubstanceUse_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import {
  SubstanceAbuseSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { SubstanceTable } from 'app/notes-ui/shared/substance-abuse/SubstanceTable';
import React, { useEffect, useRef, useState } from 'react';
import { OnChangeHandler } from './SubstanceTableRow';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { useInterval } from 'app/notes-ui/useInterval';
import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';

type Props = {
  appointmentId: string;
  initialValue?: SubstanceUse;
  readOnly?: boolean;
  onSubmit: (data: SubstanceAbuseSection) => void;
  updateDraftNoteState: (data: SubstanceAbuseSection) => void;
  disabled?: boolean;
  savingNote?: boolean;
};

export function SubstanceAbuseForm(props: Props) {
  // TODO sync initial value here
  const currentUseRows = useSubstanceRows();
  const pastUseRows = useSubstanceRows();

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(currentUseRows.isValid && pastUseRows.isValid);
  }, [currentUseRows.isValid, pastUseRows.isValid]);

  // Run every 2 seconds to pull latest changes in the forms.
  // Using this approach because we run into an indefinite loop when we use setState to track changes to the forms which
  // causes the UI to freeze.
  // TODO: Investigate the reason for this behavior or maybe refactor to what we have for PsychiatricHistoryTable
  //  https://app.asana.com/0/1153081034733470/1200197194199765
  useInterval(() => {
    props.updateDraftNoteState({
      name: TherapyIntakeSectionName.SUBSTANCE_ABUSE,
      data: getSectionData(),
    });
  }, 2000);

  function getSectionData() {
    const substancesCurrentlyUsed = currentUseRows
      .getRows()
      .map((row) => ({ substance: row.item }));

    const substancesPreviouslyUsed = pastUseRows
      .getRows()
      .map((row) => ({ substance: row.item }));

    const section: SubstanceUse = {
      appointmentId: props.appointmentId,
      substancesCurrentlyUsed,
      substancesPreviouslyUsed,
      anySubstanceUsed: BooleanOption.undefined_choice,
      version: SubstanceUse_Version.undefined_version,
      pastEtohOrBenzoWithdrawal: {
        isPresent: false,
        description: '',
      },
      pastSubstanceUseTreatment: {
        isPresent: false,
        description: '',
      },
    };
    return section;
  }

  const onSubmit = async () => {
    if (!isValid) return;

    props.onSubmit({
      name: TherapyIntakeSectionName.SUBSTANCE_ABUSE,
      data: getSectionData(),
    });
  };

  return (
    <>
      <div className={styles.formGroup} data-testid="currentlyUsed">
        <h2>Currently Used</h2>
        <SubstanceTable
          includeStoppedUse={false}
          initialValue={props.initialValue?.substancesCurrentlyUsed}
          onChange={currentUseRows.onChange}
          disabled={props.disabled}
        />
      </div>

      <div className={styles.formGroup} data-testid="previouslyUsed">
        <h2>Previously Used</h2>
        <SubstanceTable
          includeStoppedUse={true}
          initialValue={props.initialValue?.substancesPreviouslyUsed}
          onChange={pastUseRows.onChange}
          disabled={props.disabled}
        />
      </div>

      <SaveButton
        isLoading={props.savingNote}
        disabled={props.disabled}
        onSubmit={onSubmit}
      />
    </>
  );
}

/** Represents the state of a group of substances listed our substance table.
 *
 *  We assume we can only have 1 substance of each type (per row group), hence why we key
 *  the map on substance type
 */
type SubstanceRows = Record<
  SubstanceUse_SubstanceType,
  SubstanceRow | undefined
>;

type SubstanceRow = {
  item: SubstanceUse_SubstanceUseLineItem;
  isChecked: boolean;
  isValid: boolean;
};

/** Custom hook that keeps the state of SubstanceRows  */
function useSubstanceRows(): {
  isValid: boolean;
  onChange: OnChangeHandler;
  getRows: () => SubstanceRow[];
} {
  const rows = useRef<SubstanceRows>({} as SubstanceRows);
  const [isValid, setIsValid] = useState(true);

  const onChange: OnChangeHandler = ({ item, isChecked, isValid }) => {
    if (isChecked) {
      rows.current[item.substanceType] = { item, isChecked, isValid };
    } else {
      delete rows.current[item.substanceType];
    }

    const currentRows = Object.values(rows.current);

    setIsValid(
      currentRows.length === 0 ||
        currentRows.every((_) => _ !== undefined && _.isValid),
    );
  };

  return {
    isValid,
    onChange,
    getRows: () =>
      Object.values(rows.current).filter(
        (_) => _ !== undefined,
      ) as SubstanceRow[],
  };
}
