import gql from 'graphql-tag';

export const getMemberSurvey = gql`
  query GetMemberSurvey(
    $input: GetMemberInput!
    $phqgadPaginationInput: PaginationInput = {
      maxItemsPerPage: 25
      sortDirection: DESC
    }
    $loadPHQ9s: Boolean = false
    $loadGAD7s: Boolean = false
    $loadPSS: Boolean = false
    $loadCSSRS: Boolean = false
    $loadRisc10s: Boolean = false
    $loadQuestions: Boolean = false
  ) {
    getMember(input: $input) {
      id
      surveyResponses {
        phq9s(pagination: $phqgadPaginationInput) @include(if: $loadPHQ9s) {
          questions @include(if: $loadQuestions) {
            concentration
            eating
            energy
            enjoyment
            failure
            mood
            restlessness
            selfHarm
            sleep
          }
          surveys {
            id
            timestamp
            total
            severity
            insights {
              details
              title
              frequency
            }
            concentration
            eating
            energy
            enjoyment
            failure
            mood
            restlessness
            selfHarm
            sleep
            suicidalIdeation
          }
        }
        gad7s(pagination: $phqgadPaginationInput) @include(if: $loadGAD7s) {
          questions @include(if: $loadQuestions) {
            anxiety
            control
            fear
            irritation
            relaxation
            restlessness
            worry
          }
          surveys {
            id
            timestamp
            total
            severity
            insights {
              details
              title
              frequency
            }
            anxiety
            control
            fear
            irritation
            relaxation
            restlessness
            worry
          }
        }
        pssSurveys(pagination: { maxItemsPerPage: 25, sortDirection: DESC })
          @include(if: $loadPSS) {
          surveys {
            id
            timestamp
            total
            stress
            upsetBecauseOfSomethingUnexpected
            unableToControlImportantThings
            feltNervousAndStressed
            feltConfidentToHandlePersonalProblems
            feltThingsGoingYourWay
            foundCouldNotCopeWithAllTheThings
            ableToControlIrritationsInYourLife
            feltOnTopOfThings
            angeredByThingsOutsideOfYourControl
            feltDifficultiesPiling
          }
          questions @include(if: $loadQuestions) {
            upsetBecauseOfSomethingUnexpected
            unableToControlImportantThings
            feltNervousAndStressed
            feltConfidentToHandlePersonalProblems
            feltThingsGoingYourWay
            foundCouldNotCopeWithAllTheThings
            ableToControlIrritationsInYourLife
            feltOnTopOfThings
            angeredByThingsOutsideOfYourControl
            feltDifficultiesPiling
          }
        }
        cssrsSurveys(pagination: { maxItemsPerPage: 25, sortDirection: DESC })
          @include(if: $loadCSSRS) {
          surveys {
            id
            timestamp
            total
            riskAssessment
            wishedDead
            thoughtsOfSuicide
            thoughtsOnHow
            thoughtsWithIntention
            detailedPlans
            intendToAct
            recentActions
          }
          questions @include(if: $loadQuestions) {
            wishedDead
            thoughtsOfSuicide
            thoughtsOnHow
            thoughtsWithIntention
            detailedPlans
            intendToAct
            recentActions
          }
        }
        adaptabilities(
          pagination: { maxItemsPerPage: 25, sortDirection: DESC }
        ) @include(if: $loadRisc10s) {
          questions @include(if: $loadQuestions) {
            flexibility
            selfReliance
            positivity
            personalGrowth
            perseverance
            confidence
            focus
            determination
            grit
            weatheringEmotions
          }
          surveys {
            id
            timestamp
            strengths {
              title
              details
              frequency
            }
            total
            severity
            flexibility
            selfReliance
            positivity
            personalGrowth
            perseverance
            confidence
            focus
            determination
            grit
            weatheringEmotions
          }
        }
      }
    }
  }
`;
