import { Moment } from 'moment';
import moment from 'moment-timezone';

import { AppointmentsSummary, WeekData } from './types';

export function calculateSeries(
  summaries: AppointmentsSummary[],
  companyTarget: number[],
) {
  const clinicianSeries = summaries.reduce((acc: any, summary) => {
    return {
      canceled: (acc.canceled || 0) + summary.canceled,
      completed: (acc.completed || 0) + summary.completed,
      offered: (acc.offered || 0) + summary.offered,
      scheduled: (acc.scheduled || 0) + summary.scheduled + summary.completed,
    };
  }, {});

  return {
    cancelled: [0, clinicianSeries.canceled, 0],
    companyTarget,
    myPerformance: [
      clinicianSeries.offered,
      clinicianSeries.scheduled,
      clinicianSeries.completed,
    ],
  };
}

export const weekOfMonth = (date: Moment) => {
  const firstDayOfMonth = date.clone().startOf('month');
  const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');
  const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');
  return Math.ceil((date.date() + offset) / 7);
};

export const getStartAndEndOfMonth = (date: Date, timezone: string) => {
  const baseDate = moment(date).format();
  return {
    endDate: moment(baseDate).tz(timezone).endOf('month'),
    startDate: moment(baseDate).tz(timezone).startOf('month'),
  };
};

export const getAppointmentsSummaryPerWeek = (
  clinicianSummary: AppointmentsSummary[],
) => {
  return Object.values(
    clinicianSummary.reduce(
      (partial: WeekData, summary: AppointmentsSummary) => {
        const weekNo = weekOfMonth(moment(summary.date, 'YYYY-MM-DD'));
        if (!partial[weekNo]) partial[weekNo] = [];
        partial[weekNo].push(summary);
        return partial;
      },
      {},
    ),
  );
};
