import { GetMemberCoachingTeam_getMember_coachingCareTeam as CoachingCareTeam } from 'app/queries/generated/GetMemberCoachingTeam';
import { useAppState } from 'app/state';
import { getFormattedDate } from 'utils/dateTime';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';

import styles from './ReadOnlyChat.module.scss';

interface ReadOnlyChatProps {
  coachingCareTeam: CoachingCareTeam | null;
}

export function ReadOnlyChat(props: ReadOnlyChatProps) {
  const { coachingCareTeam } = props;
  const { timezone, userId } = useAppState(
    ({ user: { timezone, userId } }) => ({
      timezone: timezone!,
      userId: userId!,
    }),
  );
  const [removedWhenLabel, setRemovedWhenLabel] = useState('');

  useEffect(() => {
    const authCoach = (coachingCareTeam?.past?.coaches ?? []).find(
      (coach) => coach?.gingerId === userId,
    );
    const removedWhen = authCoach?.relationshipLastUpdated
      ? moment(authCoach.relationshipLastUpdated)
      : null;
    setRemovedWhenLabel(
      removedWhen
        ? `This member was removed from your care on ${getFormattedDate(
            removedWhen,
            timezone,
          )}.`
        : '',
    );
  }, [coachingCareTeam]);

  return (
    <div className={styles.readOnlyChat} data-testid="read-only-chat">
      <p>This chat is read-only. {removedWhenLabel}</p>
    </div>
  );
}
