import { AdditionalLogData } from 'app/state/log/actions';
import { ILogger, LogLevel, UserInfo } from 'app/state/log/Logger';

export class StubLogger implements ILogger {
  private logLevel: LogLevel = LogLevel.INFO;

  public debugs: { message: string; additionalData?: AdditionalLogData }[] = [];

  public infos: { message: string; additionalData?: AdditionalLogData }[] = [];

  public warnings: {
    message: string;
    additionalData?: AdditionalLogData;
  }[] = [];

  public errors: { error: Error; additionalData?: AdditionalLogData }[] = [];

  debug(message: string, additionalData?: AdditionalLogData): void {
    if (this.logLevel <= LogLevel.DEBUG) {
      this.debugs.push({ additionalData, message });
    }
  }

  info(message: string, additionalData?: AdditionalLogData): void {
    if (this.logLevel <= LogLevel.INFO) {
      this.infos.push({ additionalData, message });
    }
  }

  warning(message: string, additionalData?: AdditionalLogData): void {
    if (this.logLevel <= LogLevel.WARNING) {
      this.warnings.push({ additionalData, message });
    }
  }

  error(error: Error, additionalData?: AdditionalLogData): void {
    if (this.logLevel <= LogLevel.ERROR) {
      this.errors.push({ additionalData, error });
    }
  }

  log(
    logLevel: LogLevel,
    message: string,
    additionalData?: AdditionalLogData,
  ): void {
    switch (logLevel) {
      case LogLevel.ERROR: {
        this.errors.push({ additionalData, error: new Error(message) });
        break;
      }
      case LogLevel.WARNING: {
        this.warnings.push({ additionalData, message });
        break;
      }
      case LogLevel.DEBUG: {
        this.debugs.push({ additionalData, message });
        break;
      }
      default: {
        this.infos.push({ additionalData, message });
      }
    }
  }

  setUser(user: UserInfo): void {}

  setLogLevel(logLevel: LogLevel): void {
    this.logLevel = logLevel;
  }
}
