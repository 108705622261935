import { MemberNotAppropriateForPlatformRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/MemberNotAppropriateForPlatformRisk';
import { MemberWasInppropriateWithCoachRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/MemberWasInppropriateWithCoachRisk';
import { OtherRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/OtherRisk';
import DeleteIcon from '@mui/icons-material/HighlightOffRounded';
import { Typography } from '@mui/material';
import {
  RiskFormComponent,
  RiskFormProps,
  RiskType,
} from 'app/coach/coach-notes/CoachNotesTypes';
import { CardTextAreaField } from 'app/coach/coach-notes/note-detail-view/note-fields';
import {
  inappropriateForPlatform,
  inappropriateWithCoach,
  otherRisk,
} from 'app/coach/coach-notes/note-detail-view/note-templates/fieldDefs';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { useRiskForm } from 'app/coach/coach-notes/useRiskForm';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import { classNameCombiner } from 'utils';
import React from 'react';

enum FieldLabels {
  CONCERNS = 'What are your concerns around this member? *',
}

enum Placeholders {
  NOT_APPROPRIATE_FOR_PLATFORM = 'Meets exclusionary criteria, prioritize for clinical services...',
  NOT_APPROPRIATE_WITH_COACH = "Member's language or actions...",
  OTHER = 'Type here...',
}

const OtherRiskFields = {
  [RiskType.MEMBER_INAPPROPRIATE_FOR_PLATFORM_RISK]: {
    label: FieldLabels.CONCERNS,
    placeholder: Placeholders.NOT_APPROPRIATE_FOR_PLATFORM,
    subtitle: 'Appropriate for the Platform',
    title: 'Member May Not Be',
    titleDescription: '',
    tooltipEventLabel: `Coach Notes > Member May Not Appropriate for Platform`,
  },
  [RiskType.MEMBER_INAPPROPRIATE_WITH_COACH_RISK]: {
    label: FieldLabels.CONCERNS,
    placeholder: Placeholders.NOT_APPROPRIATE_WITH_COACH,
    subtitle: 'Inappropriate with Coach',
    title: 'Member Was',
    titleDescription: '(i.e. hostile language, sexual/romantic advances, etc.)',
    tooltipEventLabel: `Coach Notes > Member Was Appropriate with Coach`,
  },
  [RiskType.OTHER_RISK]: {
    label: FieldLabels.CONCERNS,
    placeholder: Placeholders.OTHER,
    subtitle: '',
    title: 'Other',
    titleDescription: '',
    tooltipEventLabel: `Coach Notes > Other Risk`,
  },
};

export const MemberNotAppropriateForPlatformRiskForm: RiskFormComponent<MemberNotAppropriateForPlatformRisk> = (
  props: RiskFormProps<MemberNotAppropriateForPlatformRisk>,
) => {
  const {
    label,
    placeholder,
    title,
    subtitle,
    titleDescription,
    tooltipEventLabel,
  } = OtherRiskFields[RiskType.MEMBER_INAPPROPRIATE_FOR_PLATFORM_RISK];

  const {
    readOnly,
    inline,
    isRemovable,
    initialValue,
    onChange,
    removeRisk,
  } = props;
  const { fields, onRemoveRisk } = useRiskForm<
    MemberNotAppropriateForPlatformRisk
  >({
    initialValue,
    onChange,
    removeRisk,
    riskType: RiskType.MEMBER_INAPPROPRIATE_FOR_PLATFORM_RISK,
    validationSchema: inappropriateForPlatform,
  });

  return (
    <div className={inline ? styles.inlineRiskSection : styles.cardSection}>
      <div
        className={classNameCombiner([
          styles.riskTitleField,
          isRemovable ? styles.withButton : '',
        ])}
      >
        <Typography className={styles.riskAssessmentTitle}>{title}</Typography>
        <span className={styles.inlineBorder} />
        {subtitle && (
          <Typography className={styles.riskAssessmentSubtitle}>
            {subtitle}
          </Typography>
        )}
        {titleDescription && (
          <Typography className={styles.titleDescription}>
            {titleDescription}
          </Typography>
        )}
        {isRemovable && (
          <DeleteIcon
            data-testid="removeBtn"
            className={styles.removeRiskBtn}
            onClick={onRemoveRisk}
          />
        )}
      </div>

      <FieldLabel className={styles.label} labelText={label} />
      <CardTextAreaField
        name="memberNotAppropriateForPlatformConcerns"
        field={fields.concerns}
        tooltipEventLabel={tooltipEventLabel}
        tooltipText=""
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </div>
  );
};

export const MemberWasInappropriateWithCoachRiskForm: RiskFormComponent<MemberWasInppropriateWithCoachRisk> = (
  props: RiskFormProps<MemberWasInppropriateWithCoachRisk>,
) => {
  const {
    label,
    placeholder,
    title,
    subtitle,
    titleDescription,
    tooltipEventLabel,
  } = OtherRiskFields[RiskType.MEMBER_INAPPROPRIATE_WITH_COACH_RISK];

  const {
    readOnly,
    inline,
    isRemovable,
    initialValue,
    onChange,
    removeRisk,
  } = props;
  const { fields, onRemoveRisk } = useRiskForm<
    MemberWasInppropriateWithCoachRisk
  >({
    initialValue,
    onChange,
    removeRisk,
    riskType: RiskType.MEMBER_INAPPROPRIATE_WITH_COACH_RISK,
    validationSchema: inappropriateWithCoach,
  });

  return (
    <div className={inline ? styles.inlineRiskSection : styles.cardSection}>
      <div
        className={classNameCombiner([
          styles.riskTitleField,
          isRemovable ? styles.withButton : '',
        ])}
      >
        <Typography className={styles.riskAssessmentTitle}>{title}</Typography>
        <span className={styles.inlineBorder} />
        {subtitle && (
          <Typography className={styles.riskAssessmentSubtitle}>
            {subtitle}
          </Typography>
        )}
        {titleDescription && (
          <Typography
            className={classNameCombiner([
              styles.titleDescription,
              styles.noMargin,
            ])}
          >
            {titleDescription}
          </Typography>
        )}
        {isRemovable && (
          <DeleteIcon
            data-testid="removeBtn"
            className={styles.removeRiskBtn}
            onClick={onRemoveRisk}
          />
        )}
      </div>

      <FieldLabel className={styles.label} labelText={label} />
      <CardTextAreaField
        name="memberWasInappropriateWithCoachConcerns"
        field={fields.concerns}
        tooltipEventLabel={tooltipEventLabel}
        tooltipText=""
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </div>
  );
};

export const OtherRiskForm: RiskFormComponent<OtherRisk> = (
  props: RiskFormProps<OtherRisk>,
) => {
  const {
    label,
    placeholder,
    title,
    subtitle,
    titleDescription,
    tooltipEventLabel,
  } = OtherRiskFields[RiskType.OTHER_RISK];

  const {
    readOnly,
    inline,
    isRemovable,
    initialValue,
    onChange,
    removeRisk,
  } = props;
  const { fields, onRemoveRisk } = useRiskForm<OtherRisk>({
    initialValue,
    onChange,
    removeRisk,
    riskType: RiskType.OTHER_RISK,
    validationSchema: otherRisk,
  });

  return (
    <div className={inline ? styles.inlineRiskSection : styles.cardSection}>
      <div
        className={classNameCombiner([
          styles.riskTitleField,
          isRemovable ? styles.withButton : '',
        ])}
      >
        <Typography className={styles.riskAssessmentTitle}>{title}</Typography>
        <span className={styles.inlineBorder} />
        {subtitle && (
          <Typography className={styles.riskAssessmentSubtitle}>
            {subtitle}
          </Typography>
        )}
        {titleDescription && (
          <Typography className={styles.titleDescription}>
            {titleDescription}
          </Typography>
        )}
        {isRemovable && (
          <DeleteIcon
            data-testid="removeBtn"
            className={styles.removeRiskBtn}
            onClick={onRemoveRisk}
          />
        )}
      </div>

      <FieldLabel className={styles.label} labelText={label} />
      <CardTextAreaField
        name="otherRiskConcerns"
        field={fields.concerns}
        tooltipEventLabel={tooltipEventLabel}
        tooltipText=""
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </div>
  );
};
