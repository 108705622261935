import React, { PropsWithChildren, ReactElement } from 'react';

interface GridProps {
  rowGap: number;
  columnGap: number;
  gridTemplateRows?: string; // implies a valid grid-template-rows value
  gridTemplateColumns?: string; // implies a valid grid-template-columns value
  template?: string; // implies a valid grid-template-areas value
}

type GridPropsWithChildren = PropsWithChildren<GridProps>;

export const Grid = ({
  children,
  rowGap,
  columnGap,
  template,
  gridTemplateColumns,
  gridTemplateRows,
}: GridPropsWithChildren): ReactElement => {
  const style = {
    display: 'grid',
    gridColumnGap: columnGap,
    gridRowGap: rowGap,
    gridTemplateAreas: template,
    gridTemplateColumns,
    gridTemplateRows,
  };

  return <div style={style}>{children}</div>;
};
