import { updateSessionState } from 'app/state/features/auth/authSlice';
import { SessionState } from 'app/state/features/auth/types';
import { useAppDispatch } from 'app/state/hooks/baseTypedHooks';
import { useLogger } from 'app/state/log/useLogger';
import { ReactComponent as Logo } from 'app/top-nav/logo.svg';
import { useOnMount } from 'hooks/useOnMount';
import Messages from 'i18n/en/core.json';
import React from 'react';
import { Modal } from 'shared-components/modal/Modal';

import { isGraphQLAuthenticationError } from './utils';

export type Props = {
  error?: Error;
  children?: React.ReactElement;
};
export function ErrorState(props: Props) {
  const { error, children } = props;
  const logger = useLogger();
  const dispatch = useAppDispatch();

  useOnMount(() => {
    if (error && !isGraphQLAuthenticationError(error)) {
      logger.error(new Error(`ErrorState:: ${error.message}`), { error });
    }
  });

  if (isGraphQLAuthenticationError(error)) {
    dispatch(updateSessionState(SessionState.EXPIRED));
    return <></>;
  }
  if (children) {
    return children;
  }
  const errorMessage = props.error ? props.error.toString() : 'Error :(';
  return (
    <Modal hideButton={true}>
      <Logo />
      <h1>{Messages.fatalErrorTitle}</h1>
      <p data-testid="error-message">{Messages.fatalErrorMessage}</p>
      <p data-testid="error">{errorMessage}</p>
    </Modal>
  );
}
