import {
  Metadata,
  Metadata_NoteStatus,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import {
  TherapyIntakeSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';

import { TherapyIntakeNote } from '../api/TherapyIntakeNotesAPI';

export function newClinicalNoteMetadata(
  appointmentId: string,
  status: Metadata_NoteStatus = Metadata_NoteStatus.undefined_note_status,
): Metadata {
  return {
    appointmentId,
    status,
  };
}

export function getNonNullTherapyIntakeNoteSections(
  note: TherapyIntakeNote,
): TherapyIntakeSection[] {
  return Object.entries(note)
    .filter(([key, value]) => key in TherapyIntakeSectionName && value)
    .map(
      ([sectionName, sectionData]) =>
        ({
          data: sectionData,
          name: sectionName,
        } as TherapyIntakeSection),
    );
}
