import moment from 'moment';
import React, { ReactNode, useState } from 'react';
import { Input } from 'shared-components/form-inputs/Input';
import { FormError } from 'shared-components/FormError';

import styles from './DateTimeRangeInput.module.scss';
import { NoteFormControlLabel } from './NoteFormControlLabel';

type Props = {
  label: string;
  start: string;
  end: string;
  disabled?: boolean;
  error?: string;
  onChange: (data: { start: string; end: string }) => void;
  autoEndTime?: number;
  children?: ReactNode;
};

export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm';

export function DateTimeRangeInput(props: Props) {
  const {
    children,
    start,
    end,
    autoEndTime = 45,
    onChange,
    label,
    disabled,
    error,
  } = props;
  const [value, setValue] = useState({ end, start });

  const onDateChange = (key: 'start' | 'end', time: string) => {
    const updatedValue = { ...value, [key]: time };

    /* Auto update endtime when start updated */
    if (autoEndTime && key === 'start') {
      updatedValue.end = moment(time, DATE_TIME_FORMAT)
        .add(autoEndTime, 'minutes')
        .format(DATE_TIME_FORMAT);
    }

    setValue(updatedValue);
    onChange(updatedValue);
  };

  const inputProps = { step: 300 /* 5min */ };
  return (
    <NoteFormControlLabel label={label}>
      <div className={styles.dateTimeRange}>
        <Input
          className={styles.datetime}
          type="datetime-local"
          variant="outlined"
          name="start"
          value={value.start}
          onChange={(value) => onDateChange('start', value)}
          disabled={disabled}
          inputProps={inputProps}
        />
        <span className={styles.to}>to</span>
        <Input
          className={styles.datetime}
          type="datetime-local"
          variant="outlined"
          name="end"
          value={value.end}
          onChange={(value) => onDateChange('end', value)}
          disabled={disabled}
          inputProps={inputProps}
        />
        {children}
      </div>
      <FormError error={error} />
    </NoteFormControlLabel>
  );
}
