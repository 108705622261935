import {
  EscalateMemberToClinicalMutation,
  EscalateMemberToClinicalMutationVariables,
} from 'app/care-team/generated/EscalateMemberToClinicalMutation';
import { escalateMemberToClinicalMutation } from 'app/care-team/mutations';
import {
  EscalateMemberToClinicalErrorCode,
  EscalateMemberToClinicalInput,
} from 'generated/globalTypes';
import { useMutationWithGlobalState } from 'hooks/useMutationWithGlobalState';
import Messages from 'i18n/en/careTeam.json';

export interface EscalateMemberToClinicalErrorState {
  title?: string;
  message?: string;
  errorType?: EscalateMemberToClinicalErrorCode;
}

export const EscalateMemberToClinicalErrorsMap: Record<
  EscalateMemberToClinicalErrorCode,
  EscalateMemberToClinicalErrorState
> = {
  [EscalateMemberToClinicalErrorCode.UNEXPECTED_ERROR]: {
    errorType: EscalateMemberToClinicalErrorCode.UNEXPECTED_ERROR,
    message: Messages.useEscalateMemberToClinicalMessage,
    title: Messages.useEscalateMemberToClinicalTitle,
  },
};

export interface UseEscalateMemberToClinicalHook {
  escalateMemberToClinical: (
    input: EscalateMemberToClinicalInput,
  ) => Promise<void>;
}

export default function useEscalateMemberToClinical(): UseEscalateMemberToClinicalHook {
  const [escalateMemberToClinicalMutationFn] = useMutationWithGlobalState<
    EscalateMemberToClinicalMutation,
    EscalateMemberToClinicalMutationVariables
  >(escalateMemberToClinicalMutation);

  const escalateMemberToClinical = async (
    input: EscalateMemberToClinicalInput,
  ) => {
    const { data } = await escalateMemberToClinicalMutationFn({ input });

    if (
      data?.escalateMemberToClinical?.errors?.length &&
      data.escalateMemberToClinical.errors.length > 0
    ) {
      throw EscalateMemberToClinicalErrorsMap[
        data.escalateMemberToClinical.errors[0]
      ];
    }

    if (data?.escalateMemberToClinical?.success) {
    } else {
      throw EscalateMemberToClinicalErrorsMap[
        EscalateMemberToClinicalErrorCode.UNEXPECTED_ERROR
      ];
    }
  };

  return { escalateMemberToClinical };
}
