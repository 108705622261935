import AddIcon from '@mui/icons-material/Add';
import { Grid } from '@mui/material';
import { AppointmentsAndNotesAPIProvider } from 'app/appointments/AppointmentsAndNotesAPIContext';
import { AppointmentsAndNotesCard } from 'app/appointments/AppointmentsAndNotesCard';
import { AppointmentsAndNotesTable } from 'app/appointments/AppointmentsAndNotesTable';
import { CardContextProvider } from 'app/appointments/CardContext';
import { TransientFeatureFlag, useFeatureFlags } from 'hooks/useFeatureFlags';
import {
  CardActionMenu,
  MENU_KEY_TYPE,
  MenuItem,
} from 'shared-components/CardActionMenu';
import Tooltip from 'shared-components/tooltip/Tooltip';
import {
  ActionMenuRoutes,
  createAppointmentRoute,
  patientNoteRoute,
} from 'app/top-nav/Routes';
import { CSCAutoOutreachLaunchButton } from 'app/patients/CSCOutreach/CSCAutoOutreachLaunchButton';
import { useAppState } from 'app/state';
import React from 'react';
import { useHistory } from 'react-router-dom';

export type AppointmentsTabScheduleProps = {
  onExpandToggle: (e: boolean) => void;
  patientId: string;
};

export function AppointmentsTabScheduleV2(props: AppointmentsTabScheduleProps) {
  const { patientId, onExpandToggle } = props;

  return (
    <CardContextProvider>
      <AppointmentsAndNotesCard
        size="large"
        boxTitle="Appointments and Notes"
        toggleInitialState={false}
        onToggle={onExpandToggle}
        actionMenu={<CardActionMenuContainer patientId={patientId} />}
      >
        <AppointmentsAndNotesAPIProvider>
          <AppointmentsAndNotesTable patientId={patientId} />
        </AppointmentsAndNotesAPIProvider>
      </AppointmentsAndNotesCard>
    </CardContextProvider>
  );
}

function CardActionMenuContainer({ patientId }: { patientId: string }) {
  const enableCSCOutreachAutomation = useFeatureFlags().transientFeatureFlags[
    TransientFeatureFlag.ENABLE_CSC_AUTOMATION
  ];
  const history = useHistory();

  const clinicianUserId = useAppState((_) => _.user.userId!);
  const clinicianId = useAppState(({ user }) => user.clinicianId!);

  const menuItems: MenuItem[] = [
    { key: ActionMenuRoutes.APPOINTMENT, label: 'Appointment' },
    { key: ActionMenuRoutes.TERMINATION, label: 'Termination' },
    { key: ActionMenuRoutes.OUT_OF_SESSION, label: 'Out of Session' },
  ];

  const navigateTo = (item: MENU_KEY_TYPE) => {
    if (item === ActionMenuRoutes.APPOINTMENT) {
      history.push(createAppointmentRoute({ clinicianId, patientId }));
    } else {
      history.push(patientNoteRoute(patientId, item as ActionMenuRoutes));
    }
  };
  return (
    <Grid container={true} spacing={2}>
      {enableCSCOutreachAutomation && (
        <CSCAutoOutreachLaunchButton
          clinicianId={clinicianUserId}
          memberId={patientId}
        />
      )}
      <Grid item={true}>
        <Tooltip placement="left" title="NEW APPOINTMENT / NOTE" arrow={true}>
          <span>
            <CardActionMenu
              menuItems={menuItems}
              onSelect={navigateTo}
              actionIcon={<AddIcon />}
            />
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
