import { useCardContext } from 'app/appointments/CardContext';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';
import { classNameCombiner } from 'utils';
import React, { ReactElement, useEffect, useMemo } from 'react';

import { useTabContext } from '../contexts/TabContext';
import { NoteActions } from '../NoteActions';
import { NoteHeaderItems } from '../therapy/TherapyIntakeNoteContainerv2';
import { Props as TabPanelProps } from './TabPanel';
import styles from './TabsV2.module.scss';
import { TabStateV2, TabV2 } from './TabV2';

export type Tab = {
  label: string;
  key: string;
  tabState: TabStateV2;
  component: ReactElement<TabPanelProps>;
};

type TabsProps = {
  tabs: Tab[];
  noteHeader: NoteHeaderItems;
  vaultItemId?: string;
  vaultGroupId?: string;
  className?: string;
};

export function TabsV2(props: TabsProps) {
  const { tabs, noteHeader, vaultItemId, vaultGroupId, className = '' } = props;
  const { isExpanded } = useCardContext();
  const { setActiveTab, activeTab } = useTabContext();

  // a dictionary of refs for each tab, the tab key is the key
  const sectionRefs = useMemo(() => {
    const refs: { [key: string]: React.RefObject<HTMLDivElement> } = {};
    tabs.forEach(({ key }) => {
      refs[key] = React.createRef<HTMLDivElement>();
    });
    return refs;
  }, [tabs]);

  const components: { [key: string]: ReactElement } = useMemo(() => {
    const components: { [key: string]: ReactElement } = {};
    tabs.forEach(({ component, key }) => {
      components[key] = (
        <div
          className={styles.section}
          key={`${key}-wrapper`}
          ref={sectionRefs[key]}
        >
          {component}
        </div>
      );
    });
    return components;
  }, [tabs]);

  useEffect(() => {
    if (activeTab) {
      const section = sectionRefs[activeTab].current;
      section?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [activeTab]);

  return (
    <Grid className={classNameCombiner([styles.root, className])} spacing={0}>
      <Columns
        className={classNameCombiner([styles.col, styles.sticky])}
        widths={
          isExpanded
            ? [COLUMN_WIDTH.TWO, COLUMN_WIDTH.EIGHT]
            : [COLUMN_WIDTH.FOUR, COLUMN_WIDTH.EIGHT]
        }
      >
        <div className={styles.sidebar}>
          <div className={styles.tabsV2}>
            {tabs.map(({ label, key, tabState }) => (
              <div
                tabIndex={0}
                role="button"
                data-testid={`${key.toLowerCase()}-button`}
                key={`${key}-li`}
              >
                <TabV2
                  data-testid={`${key.toLowerCase()}-tab`}
                  tabState={tabState}
                  active={key === activeTab}
                  label={label}
                  onClick={() => setActiveTab(key)}
                />
              </div>
            ))}
          </div>
          <NoteActions
            noteType={noteHeader.noteType}
            status={noteHeader.status}
            onNoteLocked={noteHeader.onNoteLocked}
            disabled={noteHeader.disabled}
            appointment={noteHeader.appointment}
            deleteDraftNote={noteHeader.deleteDraftNote}
            vaultItemId={vaultItemId}
            vaultGroupId={vaultGroupId}
          />
        </div>
        {Object.values(components).map((component) => component)}
      </Columns>
    </Grid>
  );
}
