import Markdown from 'markdown-to-jsx';
import React from 'react';
import { classNameCombiner } from 'utils';

import styles from './MarkdownContent.module.scss';

export type Props = {
  children: string;
  className?: string;
  displayInline?: boolean;
  overrides?: object;
};

const defaultOverrides = {};

export function MarkdownContent(props: Props) {
  const { overrides, className = '', children, displayInline } = props;

  if (typeof children !== 'string') {
    return null;
  }

  const options = {
    forceInline: false,
    overrides: {
      ...defaultOverrides,
      ...overrides,
    },
  };

  function Content() {
    return (
      <Markdown
        className={classNameCombiner([styles.root, className])}
        options={options}
      >
        {children}
      </Markdown>
    );
  }

  if (displayInline) {
    options.forceInline = true;

    return <Content />;
  }

  return <Content />;
}
