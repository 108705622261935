import { OutreachAttempt as OutreachAttemptVaultType } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/OutreachAttempt';
import { CoachNotesItem } from 'app/coach/coach-notes/CoachNotesTypes';
import { CardTextAreaField } from 'app/coach/coach-notes/note-detail-view/note-fields';
import { outreachAttempt } from 'app/coach/coach-notes/note-detail-view/note-templates/fieldDefs';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { useCoachNotesForm } from 'app/coach/coach-notes/useCoachNotesForm';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import { BooleanRadioField } from 'app/notes-ui/forms/form-controls/BooleanRadioField';
import React from 'react';

enum FieldLabels {
  REASON_FOR_OUTREACH = 'What initiated the outreach? *',
  DID_MEMBER_READ_MESSAGES = 'Did the member read your message?',
  DID_SEND_RESOURCES = 'Were resources sent? *',
  ANYTHING_ELSE = 'Anything else?',
}

export enum Placeholders {
  REASON_FOR_OUTREACH = 'Survey scores, smart context, offline message...',
  ANYTHING_ELSE = 'Quotes from member, additional resources sent...',
}

const TOOLTIP_EVENT_LABEL = 'Coach Notes > Outreach Attempt';

export const OutreachAttempt = ({
  readOnly,
  onStateChange,
  'data-testid': testId = 'outreachAttempt',
}: {
  readOnly: boolean;
  onStateChange: (noteDraft: CoachNotesItem) => void;
  'data-testid'?: string;
}): React.ReactElement | null => {
  const { fields } = useCoachNotesForm<OutreachAttemptVaultType>({
    onStateChange,
    validationSchema: outreachAttempt,
  });

  return (
    <div data-testid={testId} className={styles.cardSection}>
      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.REASON_FOR_OUTREACH}
      />
      <CardTextAreaField
        field={fields.reasonForOutreach}
        name="coachNotesOutreachAttemptReason"
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.REASON_FOR_OUTREACH}`}
        tooltipText=""
        placeholder={Placeholders.REASON_FOR_OUTREACH}
        readOnly={readOnly}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.DID_MEMBER_READ_MESSAGES}
      />
      <BooleanRadioField
        disabled={readOnly}
        name="outreachAttemptDidMemberReadMessages"
        value={fields.didMemberReadMessages.value}
        onChange={fields.didMemberReadMessages.setValue}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.DID_SEND_RESOURCES}
      />
      <BooleanRadioField
        disabled={readOnly}
        name="outreachAttemptDidSendResources"
        value={fields.didSendResources.value}
        onChange={fields.didSendResources.setValue}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.ANYTHING_ELSE}
      />
      <CardTextAreaField
        name="coachNotesOutreachAnythingElse"
        field={fields.anythingElse}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.ANYTHING_ELSE}`}
        tooltipText=""
        placeholder={Placeholders.ANYTHING_ELSE}
        readOnly={readOnly}
      />
    </div>
  );
};
