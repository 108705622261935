import { TableCell, Typography } from '@mui/material';
import styles from 'app/coach/coach-notes/CoachNotes.module.scss';
import {
  DecodedNotes,
  NotesItemResponse,
  NoteViewOrder,
  RoleType,
} from 'app/coach/coach-notes/CoachNotesTypes';
import { UnreadIndicator } from 'shared-components/UnreadIndicator';
import emptyCoachNotesImg from 'assets/coach-notes-empty.png';
import { NoteTableCard } from 'app/sortable-table/note-card/NoteTableCard';
import { Row } from 'app/sortable-table/SortableTable';
import React from 'react';

const EMPTY_NOTES_HELP_TEXT =
  'Start taking session or risk notes by clicking the plus';

export const getNotesTableBody = ({
  notes,
  noteOrder,
  activeTypeFilters,
  onClick,
}: {
  notes: DecodedNotes | undefined;
  noteOrder: NoteViewOrder;
  activeTypeFilters: RoleType[];
  onClick: (note: NotesItemResponse) => Promise<void>;
}): Row[] | null => {
  if (!notes) {
    return null;
  }

  const orderedNotes = Object.keys(notes);
  if (noteOrder === NoteViewOrder.LEAST_RECENT) {
    orderedNotes.reverse();
  }

  const onNoteSelect = async (note: NotesItemResponse) => {
    await onClick(note);
  };

  return orderedNotes
    .filter((id) =>
      activeTypeFilters.includes(notes[id].noteDetails?.role! as RoleType),
    )
    .map((id) => ({
      component: (
        <>
          <TableCell className={styles.unreadIndicator}>
            {notes[id].isUnread && <UnreadIndicator />}
          </TableCell>
          <NoteTableCard
            dataTestId={`row-${id}`}
            className={styles.noteTableCard}
            note={notes[id]}
            startDate={notes[id].startDate}
            dateString={notes[id].dateString}
            details={notes[id].noteDetails}
            careTeamMember={notes[id].createdBy}
            showDraftIcon={!notes[id].readOnly}
            onClick={onNoteSelect}
          />
        </>
      ),
      id: `note-${id}`,
    }));
};

export const EmptyNoteView = () => (
  <div className={styles.emptyContainer}>
    <img
      className={styles.image}
      alt={EMPTY_NOTES_HELP_TEXT}
      src={emptyCoachNotesImg}
    />
    <Typography className={styles.emptyDescription}>
      {EMPTY_NOTES_HELP_TEXT}
    </Typography>
  </div>
);
