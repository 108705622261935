import { Metadata } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import {
  TherapyProgressSection,
  TherapyProgressSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';
import { TherapyProgressSectionEncoder } from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSectionEncoder';
import { Base64 } from '@ginger.io/vault-core/dist/crypto/Base64';
import {
  VaultItem,
  VaultItem_SchemaType,
} from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import { getClinicalCareTeamGroupId } from '@ginger.io/vault-core/dist/IdHelpers';
import { VaultItemPermissions, VaultSystemName } from '@ginger.io/vault-ui';
import { DeleteVaultItemsMutation } from '@ginger.io/vault-ui/src/generated/graphql';
import { AmendmentWithAuditLog } from 'app/notes-ui/shared/amendments/types';
import {
  GetClinicalAppointmentsForMember,
  GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments,
  GetClinicalAppointmentsForMemberVariables,
} from 'app/patients/generated/GetClinicalAppointmentsForMember';
import { queryApptsForMemberSchedule } from 'app/patients/queries';
import { decodeTherapyProgressNote } from 'app/vault/data/decodeTherapyProgressNote';
import { GetAppointmentById_getAppointmentById as Appointment } from 'app/vault/generated/GetAppointmentById';
import {
  ClinicalAppointmentStatus,
  ClinicalAppointmentType,
  PaginationInput,
} from 'generated/globalTypes';

import { ClinicalNotesAPI } from './ClinicalNotesAPI';
import { TherapyProgressIds } from './TherapyProgressIds';
import { deletionMutationErrorHandler } from './utils';

export type TherapyProgressNote = {
  [T in TherapyProgressSectionName]:
    | Extract<TherapyProgressSection, { name: T }>['data']
    | null;
} & {
  metadata: Metadata;
  amendments: AmendmentWithAuditLog[];
  permissions: VaultItemPermissions | null;
  createdAt: string | null; // ISO date that reps when the note metadata was created
  updatedAt: string | null; // ISO date that reps when the note metadata was last modified e.g. when a note is signed & locked
};

export class TherapyProgressNotesAPI extends ClinicalNotesAPI<
  TherapyProgressNote,
  TherapyProgressSection
> {
  protected ids = TherapyProgressIds;

  protected sectionNames = TherapyProgressSectionName;

  readonly amendmentSectionName = TherapyProgressSectionName.AMENDMENTS;

  async getNote(appointmentId: string): Promise<TherapyProgressNote> {
    const groupId = await this.getClinicalCareTeamGroupId(appointmentId);
    const items = await this.vaultAPI.getVaultItemsByTag(
      TherapyProgressIds.noteSectionsTag(appointmentId),
      { groupId },
    );
    const amendments = await this.getAmendments(appointmentId);
    return decodeTherapyProgressNote(appointmentId, items, amendments);
  }

  protected encodeMetadata(metadata: Metadata): VaultItem {
    return {
      data: Metadata.encode(metadata).finish(),
      schemaType:
        VaultItem_SchemaType.vault_clinical_notes_therapy_progress_metadata,
    };
  }

  protected encodeSection(data: TherapyProgressSection): VaultItem {
    return TherapyProgressSectionEncoder.encode(data);
  }

  async deleteDraftNote(
    userId: string,
    appointment: Appointment,
    note: TherapyProgressNote,
  ): Promise<DeleteVaultItemsMutation> {
    const groupId = await Base64.hash(
      getClinicalCareTeamGroupId(appointment.member.id),
    );
    const vaultItemIds = Object.values(TherapyProgressSectionName)
      .filter((_) => note[_] !== null)
      .map((name) => TherapyProgressIds.section(appointment.id, name));

    if (note.metadata) {
      vaultItemIds.push(TherapyProgressIds.metadata(appointment.id));
    }

    const deletedVaultItems = await this.vaultAPI.deleteVaultItems({
      groupId,
      itemIds: vaultItemIds,
      shareWithSystems: [VaultSystemName.ClinicalNotesSyncProcess],
    });
    deletionMutationErrorHandler(deletedVaultItems); // throws on error
    await this.updateAppointmentCache(appointment.id);
    return deletedVaultItems;
  }

  async getMemberAppointments(
    patientId: string,
    appointmentStatus?: ClinicalAppointmentStatus[],
    pagination?: PaginationInput,
  ): Promise<
    GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments[]
  > {
    const { data } = await this.apollo.query<
      GetClinicalAppointmentsForMember,
      GetClinicalAppointmentsForMemberVariables
    >({
      query: queryApptsForMemberSchedule,
      variables: {
        input: {
          filters: {
            appointmentStatus_In: appointmentStatus,
            appointmentType_In: [
              ClinicalAppointmentType.THERAPY_INTAKE,
              ClinicalAppointmentType.THERAPY_PROGRESS,
            ],
          },
          memberId: patientId,
          pagination,
        },
      },
    });

    if (!data) {
      throw new Error(
        `Could not find appointments for member id: ${patientId}`,
      );
    }

    return data.getClinicalAppointmentsForMember?.appointments || [];
  }
}
