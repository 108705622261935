import { SvgIcon } from '@mui/material';
import { ReactComponent as DisabledCheckboxSVG } from 'assets/disabled-checkbox-icon.svg';
import React from 'react';

export const DisabledCheckboxIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <DisabledCheckboxSVG />
    </SvgIcon>
  );
};
