import { GetTeenDependantSurveyResponse_survey as IntakeSurvey } from '@headspace/carehub-graphql/dist/patients/tabs/intake/generated/GetTeenDependantSurveyResponse';
import { MemberChartTab } from 'app/coach/member-chart/MemberChartTab';
import { RelationshipCard } from 'app/patients/tabs/intake/dependent-intake/RelationshipCard';
import { IntakeCard } from 'app/patients/tabs/intake/IntakeTabV2Card';
import Labels from 'i18n/en/dependent-intake.json';
import React from 'react';
import { toSentenceCase } from 'utils';

import { formatDate, formatYesNoValue } from './common';
import styles from './DependentReport.module.scss';

interface Prop {
  clinicalIntake: IntakeSurvey;
}

export function ClinicalIntakeTab({ clinicalIntake }: Readonly<Prop>) {
  const {
    relationships,
    completedAt,
    clinicalServicesSeeking,
    reasonsForSeekingClinicalServices,
    additionalInformationForClinician,
    preExistingMedicalConditions,
    currentMedications,
    frequencyOfSubstanceConsumption,
    thoughtsOfHarm,
    reasonsForThoughtsOfHarm,
    religion,
    substanceConsumption,
    riskKeywordsExtractedFromIntake = [],
  } = clinicalIntake.intakeSurvey ?? {};
  return (
    <>
      <div className={styles.completedBy}>
        Completed by guardian&nbsp;
        {formatDate(completedAt)}
      </div>
      <MemberChartTab>
        <IntakeCard
          testId="clinicalCareCard"
          title={Labels.clinicalCare}
          terms={{
            [Labels.seekingServices]: toSentenceCase(
              formatYesNoValue(clinicalServicesSeeking).replaceAll('-', ' '),
            ),
            [Labels.seekingServicesReason]: formatYesNoValue(
              reasonsForSeekingClinicalServices,
            ),
            [Labels.additionInfoForClinician]: formatYesNoValue(
              additionalInformationForClinician,
            ),
          }}
        />
        <IntakeCard
          testId="medicalHistoryCard"
          title={Labels.medicalHistory}
          terms={{
            [Labels.preExistingMedicalCondition]: formatYesNoValue(
              preExistingMedicalConditions,
            ),
            [Labels.currentMedications]: formatYesNoValue(currentMedications),
            [Labels.substanceUsed]: formatYesNoValue(
              substanceConsumption?.join(', '),
            ),
            [Labels.substanceUseFrequency]: formatYesNoValue(
              frequencyOfSubstanceConsumption,
            ),
          }}
        />
        <IntakeCard
          testId="riskIndicatorsCard"
          title={Labels.riskIndicators}
          terms={{
            [Labels.reasonsForThoughtsOfHarm]: formatYesNoValue(
              reasonsForThoughtsOfHarm,
            ),
            [Labels.riskKeyword]: formatYesNoValue(
              riskKeywordsExtractedFromIntake?.join(', '),
            ),
            [Labels.thoughtsOfHarm]: formatYesNoValue(thoughtsOfHarm),
          }}
        />
        <RelationshipCard relationships={relationships ?? null} />
        <IntakeCard
          testId="religion"
          title="Religion"
          terms={{
            [Labels.memberOfReligiousGroup]: formatYesNoValue(
              religion?.isMemberOfReligiousGroup ?? false,
            ),
            Comments: formatYesNoValue(religion?.description),
          }}
        />
      </MemberChartTab>
    </>
  );
}
