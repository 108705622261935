import { ApolloCache } from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import gql from 'graphql-tag';
import { encryptedDataFragment, memberChartVaultItem } from 'app/vault/queries';
import {
  CreateMemberChartVaultItems,
  CreateMemberChartVaultItems_createVaultItems as VaultItemWithKey,
} from 'app/coach/member-chart/generated/CreateMemberChartVaultItems';
import {
  GetPaginatedVaultItemsByTag,
  GetPaginatedVaultItemsByTagVariables,
} from './generated/GetPaginatedVaultItemsByTag';
import {
  GetNonAppointmentNotesAndUserMetadata,
  GetNonAppointmentNotesAndUserMetadataVariables,
} from './generated/GetNonAppointmentNotesAndUserMetadata';

export const vaultItemFragment = gql`
  fragment vaultItemFragment on VaultItem {
    id
    encryptedData {
      ...encryptedDataFragment
    }
    creator {
      id
      firstName
      lastName
    }
    permissions
    createdAt
    updatedAt
  }
  ${encryptedDataFragment}
`;

export const getNonAppointmentNotesByTag: TypedDocumentNode<
  GetPaginatedVaultItemsByTag,
  GetPaginatedVaultItemsByTagVariables
> = gql`
  query GetPaginatedVaultItemsByTag(
    $tag: String!
    $pagination: VaultPaginationInput!
    $groupId: ID
  ) {
    getPaginatedVaultItemsByTag(
      input: { tag: $tag, groupId: $groupId, pagination: $pagination }
    ) {
      items {
        encryptedItem {
          ...vaultItemFragment
        }
      }
      cursor
    }
  }
  ${vaultItemFragment}
`;

export const getNonAppointmentNoteById = gql`
  query GetVaultItemById($id: ID!, $groupId: ID) {
    getVaultItemById(id: $id, groupId: $groupId) {
      encryptedItem {
        ...vaultItemFragment
      }
    }
  }
  ${vaultItemFragment}
`;

export const getNonAppointmentNotesAndUserMetadata = gql`
  query GetNonAppointmentNotesAndUserMetadata(
    $clinicalNonAppointmentNotesInput: GetVaultItemsByTagInput!
    $coachNotesInput: GetVaultItemsByTagInput!
    $metadataInput: GetVaultItemsByTagInput!
    $includeCoachNotes: Boolean!
  ) {
    ClinicalNonAppointmentNotes: getPaginatedVaultItemsByTag(
      input: $clinicalNonAppointmentNotesInput
    ) {
      items {
        encryptedItem {
          ...vaultItemFragment
        }
      }
      cursor
    }
    CoachNotes: getPaginatedVaultItemsByTag(input: $coachNotesInput)
      @include(if: $includeCoachNotes) {
      items {
        encryptedItem {
          createdAt
          firstVersionCreator {
            id
            firstName
            lastName
          }
          ...memberChartVaultItem
        }
      }
      cursor
    }
    NotesUserMetadata: getPaginatedVaultItemsByTag(input: $metadataInput) {
      items {
        encryptedItem {
          id
          encryptedData {
            ...encryptedDataFragment
          }
        }
      }
      cursor
    }
  }
  ${memberChartVaultItem}
  ${encryptedDataFragment}
  ${vaultItemFragment}
`;

type UpdateCacheParams = {
  cache: ApolloCache<any>;
  data: CreateMemberChartVaultItems;
  variables: GetNonAppointmentNotesAndUserMetadataVariables;
};

/**
 * Updates the query cache after a create mutation is made to include the newly created VaultItems
 * in the query response body. Currently, this is only needed for the NotesUserMetadata items
 *
 * @param params
 * @returns
 */
export const updateCache = (params: UpdateCacheParams) => {
  const { cache, data, variables } = params;
  const items = cache.readQuery<
    GetNonAppointmentNotesAndUserMetadata,
    GetNonAppointmentNotesAndUserMetadataVariables
  >({
    query: getNonAppointmentNotesAndUserMetadata,
    variables,
  });
  const item: VaultItemWithKey[] = (data as CreateMemberChartVaultItems)
    .createVaultItems;

  const cachedClinicalNonAppointmentNotes =
    items?.ClinicalNonAppointmentNotes.items ?? [];
  const cachedCoachNotesItems = items?.CoachNotes.items ?? [];
  const cachedMetadataNotesItems = items?.NotesUserMetadata.items ?? [];

  cache.writeQuery<
    GetNonAppointmentNotesAndUserMetadata,
    GetNonAppointmentNotesAndUserMetadataVariables
  >({
    query: getNonAppointmentNotesAndUserMetadata,
    variables,
    data: {
      ClinicalNonAppointmentNotes: {
        __typename: 'GetVaultItemsByTagResponse',
        cursor: null,
        ...items?.CoachNotes,
        items: [...cachedClinicalNonAppointmentNotes],
      },
      CoachNotes: {
        __typename: 'GetVaultItemsByTagResponse',
        cursor: null,
        ...items?.CoachNotes,
        items: [...cachedCoachNotesItems],
      },
      NotesUserMetadata: {
        __typename: 'GetVaultItemsByTagResponse',
        cursor: null,
        ...items?.NotesUserMetadata,
        items: [...item, ...cachedMetadataNotesItems],
      },
    },
  });
};
