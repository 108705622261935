export const Labels = {
  psychHistory: {
    Medication: {
      checkboxLabel: 'No known medications trials',
      id: 'psychHistory_Medication',
      label: 'Medication',
    },
    SuicideAttempt: {
      checkboxLabel: 'No known suicide attempts',
      id: 'psychHistory_SuicideAttempt',
      label: 'Past suicide attempts',
    },
    notEndorsingPastSelfHarmEpisode: {
      checkboxLabel: 'No known history of self-harm',
      id: 'psychHistory_SuicidalIdeation',
      label: 'Past episodes of self-harm',
    },
    notEndorsingPastSuicidalIdeationEpisode: {
      checkboxLabel: 'No known suicidal ideation',
      id: 'psychHistory_SuicidalIdeation',
      label: 'Past suicidal ideation',
    },
    pastOutpatientTreatment: {
      id: 'psychHistory_pastOutpatientTreatment',
      label: 'Past Outpatient Treatment (Therapy and/or med management)',
      placeholder: 'Type here...',
    },
    pastPsychotropicMedicationsResponses: {
      label:
        'Must fill the medication response table or check the checkbox if there are no known medications',
    },
    pastSelfHarmEpisode: {
      id: 'psychHistory_pastSelfHarmEpisode',
      label: '',
      placeholder:
        'Timing, method, location, frequency, duration, medical intervention...',
    },
    pastSuicidalIdeationEpisode: {
      id: 'psychHistory_pastSuicidalIdeationEpisode',
      label: '',
      placeholder: 'Type here...',
    },
    pastSuicideAttempt: {
      label:
        'Must fill the suicide attempts table or check the checkbox if there are no known suicide attempts',
    },
    pastTreatments: {
      inpatientTreatment: {
        id: 'psychHistory_inpatientTreatment',
        label: 'Inpatient hospitalizations/residential stays',
      },
      notEndorsingPastTreatment: {
        id: 'psychHistory_notEndorsingPastTreatment',
        label: 'Not endorsing past outpatient/inpatient treatment',
      },
      outpatientTreatment: {
        id: 'psychHistory_outpatientTreatment',
        label: 'Outpatient treatment (therapy/med management)',
      },
    },
  },
};
