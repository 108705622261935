import { Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { Button } from 'shared-components/button/Button';
import { DatePicker } from 'shared-components/form-inputs/DatePicker';
import React from 'react';

import { DisableOutreachReasons } from './constants';
import styles from './CSCAutoOutreachEnablePopup.module.scss';

export type Props = {
  closer: () => void;
  pause: (
    reason: string,
    unpauseDate: string | null,
    additionalNotes: string | null,
  ) => void;
};

export function CSCAutoOutreacDisableComponent(props: Props) {
  const [reason, setReason] = React.useState('');
  const [unPauseDate, setUnPauseDate] = React.useState<string | null>('');
  const [additionalNotes, setAdditionalNotes] = React.useState('');
  const [enableDatePicker, setEnableDatePicker] = React.useState<boolean>(
    false,
  );
  const checkboxOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setEnableDatePicker(checked);
  };
  const handleReasonChange = (event: SelectChangeEvent<typeof reason>) => {
    setReason(event.target.value);
  };
  const handleAdditionalNotesChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setAdditionalNotes(event.target.value);
  };

  const handleDatePickerChange = (value: string | null) => {
    setUnPauseDate(value);
  };

  const handleCancelBtnClick = () => {
    props.closer();
  };

  const handleDisableBtnClick = () => {
    const date = enableDatePicker ? unPauseDate : null;
    props.pause(reason, date, additionalNotes);

    props.closer();
  };

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <div>
        If disabled, this member will not receive auto-outreaches to schedule a
        follow-up if no future appointments are scheduled.
      </div>

      <div>Reason</div>
      <FormControl fullWidth={true} sx={{ m: 1, mt: 3, width: 300 }}>
        <Select
          displayEmpty={true}
          fullWidth={true}
          value={reason}
          onChange={handleReasonChange}
          input={<OutlinedInput />}
          inputProps={{ 'aria-label': 'Without label' }}
          data-testid="reasonList"
        >
          <MenuItem disabled={true} value="">
            <em>Select</em>
          </MenuItem>
          {DisableOutreachReasons.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div>Additional notes</div>

      <TextField
        id="outlined-textarea"
        label="Additional notes"
        placeholder="Details about the situation"
        fullWidth={true}
        maxRows={4}
        value={additionalNotes}
        onChange={handleAdditionalNotesChange}
        data-testid="additionalNotesTextField"
      />

      <FormGroup>
        <FormControlLabel
          control={<Checkbox onChange={checkboxOnChange} />}
          label="Re-enable after:"
        />
      </FormGroup>
      <DatePicker
        name="date"
        onChange={handleDatePickerChange}
        value={unPauseDate}
        fullWidth={true}
        disabled={!enableDatePicker}
        data-testid="unpauseAtPicker"
      />

      <Grid
        container={true}
        direction="row"
        justifyContent="flex-end"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item={true}>
          <Button
            size="medium"
            className={styles.cancel}
            variant="outlined"
            fullWidth={false}
            onClick={handleCancelBtnClick}
            data-testid="cancelDisableBtn"
          >
            Cancel
          </Button>
        </Grid>
        <Grid item={true}>
          <Button
            size="medium"
            className={styles.enableDisable}
            fullWidth={false}
            onClick={handleDisableBtnClick}
            data-testid="saveDisableBtn"
          >
            Disable
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
}
