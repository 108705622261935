import { OutOfSessionNote } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/OutOfSessionNote';
import { TerminationNote } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/TerminationNote';
import { Base64 } from '@ginger.io/vault-core/dist/crypto/Base64';
import { VaultItem } from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import { getClinicalCareTeamGroupId } from '@ginger.io/vault-core/dist/IdHelpers';
import { NonAppointmentNotesAPI } from 'app/vault/api/NonAppointmentNotesAPI';
import { OutOfSessionNoteIds } from 'app/vault/api/OutOfSessionNoteIds';
import { TerminationNoteIds } from 'app/vault/api/TerminationNoteIds';
import {
  GetPaginatedVaultItemsByTag,
  GetPaginatedVaultItemsByTag_getPaginatedVaultItemsByTag_items,
} from 'app/vault/hooks/NonAppointments/generated/GetPaginatedVaultItemsByTag';
import { GetVaultItemById as NonAppointmentNote } from 'app/vault/hooks/NonAppointments/generated/GetVaultItemById';
import {
  vaultItemFragment,
  vaultItemFragment_creator,
} from 'app/vault/hooks/NonAppointments/generated/vaultItemFragment';
import {
  CreateVaultItemInput,
  VaultEncoding,
  VaultEncryptionVersion,
  VaultItemPermissions,
  VaultSystemName,
} from 'generated/globalTypes';
import { vaultItemInput, vaultItemKeyInput } from './utils';

export function anEncodedOutOfSession(data: OutOfSessionNote): Uint8Array {
  return Uint8Array.from(
    VaultItem.encode(
      NonAppointmentNotesAPI.encodeOutOfSessionSection(data),
    ).finish(),
  );
}

export async function createOutOfSessionInput(params: {
  patientId: string;
  clinicianId: string;
  itemId: string;
  note: OutOfSessionNote;
}): Promise<CreateVaultItemInput> {
  const { patientId, clinicianId, itemId, note } = params;

  return {
    item: await vaultItemInput(
      itemId,
      anEncodedOutOfSession(note),
      VaultItemPermissions.READ_ONLY,
    ),
    key: await vaultItemKeyInput(),
    tags: await Base64.hashList(
      OutOfSessionNoteIds.sectionTags(patientId, clinicianId),
    ),
    sharedSystemKey: {
      system: VaultSystemName.CLINICAL_NOTES_SYNC_PROCESS,
      key: await vaultItemKeyInput(),
    },
    sharedGroupKey: null,
    sharedGroupKeys: [
      {
        group: await Base64.hash(getClinicalCareTeamGroupId(patientId)),
        key: await vaultItemKeyInput(),
      },
    ],
    sharedUserKeys: [],
  };
}

export function anEncodedTerminationNote(data: TerminationNote): Uint8Array {
  return Uint8Array.from(
    VaultItem.encode(
      NonAppointmentNotesAPI.encodeTerminationSection(data),
    ).finish(),
  );
}

export async function createTerminationInput(params: {
  patientId: string;
  clinicianId: string;
  itemId: string;
  note: TerminationNote;
  permissions?: VaultItemPermissions;
}): Promise<CreateVaultItemInput> {
  const {
    patientId,
    clinicianId,
    itemId,
    note,
    permissions = VaultItemPermissions.READ_ONLY,
  } = params;

  return {
    item: await vaultItemInput(
      itemId,
      anEncodedTerminationNote(note),
      permissions,
    ),
    key: await vaultItemKeyInput(),
    tags: await Base64.hashList(
      TerminationNoteIds.sectionTags(patientId, clinicianId),
    ),
    sharedSystemKey: {
      system: VaultSystemName.CLINICAL_NOTES_SYNC_PROCESS,
      key: await vaultItemKeyInput(),
    },
    sharedGroupKey: null,
    sharedGroupKeys: [
      {
        group: await Base64.hash(getClinicalCareTeamGroupId(patientId)),
        key: await vaultItemKeyInput(),
      },
    ],
    sharedUserKeys: [],
  };
}

export function aVaultUser(
  user: Partial<vaultItemFragment_creator> = {},
): vaultItemFragment_creator {
  return {
    __typename: 'VaultUser',
    firstName: 'John',
    lastName: 'Doe',
    id: 'user-1',
    ...user,
  };
}

export async function aVaultItem(
  id: string,
  data: Uint8Array,
  item: Partial<vaultItemFragment> = {},
): Promise<vaultItemFragment> {
  return {
    __typename: 'VaultItem',
    permissions: VaultItemPermissions.READ_ONLY,
    creator: aVaultUser(),
    createdAt: new Date('2000-01-01').toISOString(),
    updatedAt: new Date('2000-01-01').toISOString(),
    ...item,
    id,
    encryptedData: await gqlEncryptedData(data),
  };
}

export async function aGetPaginatedItemsByTagResponse(
  input: {
    id: string;
    data: Uint8Array;
    item?: Partial<
      vaultItemFragment & { firstVersionCreator: vaultItemFragment_creator }
    >;
  }[],
): Promise<GetPaginatedVaultItemsByTag> {
  const items = await Promise.all(
    input.map(async ({ id, data, item = {} }) => {
      return {
        __typename: 'VaultItemWithKey',
        encryptedItem: await aVaultItem(id, data, item),
      } as GetPaginatedVaultItemsByTag_getPaginatedVaultItemsByTag_items;
    }),
  );

  return {
    getPaginatedVaultItemsByTag: {
      items,
      cursor: null,
      __typename: 'GetVaultItemsByTagResponse',
    },
  };
}

export async function aGetItemResponse(
  id: string,
  data: Uint8Array,
): Promise<NonAppointmentNote> {
  return {
    getVaultItemById: {
      __typename: 'VaultItemWithKey',
      encryptedItem: await aVaultItem(id, data),
    },
  };
}

async function gqlEncryptedData(
  cipherText: Uint8Array = Buffer.from('dummy encrypted key'),
): Promise<{
  __typename: 'VaultEncryptedData';
  cipherText: string;
  nonce: string;
  encoding: VaultEncoding;
  encryptionVersion: VaultEncryptionVersion;
}> {
  return {
    __typename: 'VaultEncryptedData',
    cipherText: await Base64.encode(Uint8Array.from(cipherText)),
    nonce: await Base64.encode(Uint8Array.from(Buffer.from('dummy nonce'))),
    encoding: VaultEncoding.BASE_64_BUFFER,
    encryptionVersion: VaultEncryptionVersion.V0,
  };
}
