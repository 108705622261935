import Box from '@mui/material/Box';
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

export function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number },
) {
  const [variant, setVariant] = useState<LinearProgressProps['variant']>(
    'determinate',
  );
  useEffect(() => {
    if (props.value === 100) {
      setVariant('indeterminate');
    }
  }, [props.value]);
  return (
    <Box data-testid="linear-progress-bar" display="flex" alignItems="center">
      <Box width={315} mr={1}>
        <LinearProgress variant={variant} {...props} />
      </Box>
      {(variant !== 'indeterminate' || props.value !== 0) && (
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
