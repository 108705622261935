import { AuthorWithName, ChatMessage } from 'app/collaboration/type';
import { useAppState } from 'app/state';
import moment from 'moment';
import React, { memo } from 'react';
import { getTimezone } from 'utils/dateTime';

import { ClinicianAndCoachMessageContainer } from './ClinicianAndCoachMessageContainer';
import { RepliedMessageContainer } from './RepliedMessageContainer';

export type CommunicationProps = {
  chat: ChatMessage;
  author: AuthorWithName;
};

export const CommunicationScreen = memo(
  ({ chat, author }: CommunicationProps) => {
    const userTimezone = useAppState((_) => getTimezone(_.user.timezone));
    const timeZoneAbbreviation = moment.tz(userTimezone).zoneName();
    const formattedDateTime = moment(chat.createdAt)
      .tz(userTimezone)
      .format('MMM D, YYYY · h:mma');
    if (author.id === chat.author.id) {
      return (
        <ClinicianAndCoachMessageContainer
          chat={chat}
          dateAndTime={`${formattedDateTime} ${timeZoneAbbreviation}`}
        />
      );
    }
    return (
      <RepliedMessageContainer
        message={chat}
        dateAndTime={`${formattedDateTime} ${timeZoneAbbreviation}`}
      />
    );
  },
);
