import { CSSRSMAX } from 'app/patients/tabs/surveys/SurveysV3/constants';
import { CSSRSSurveyResponse } from 'app/patients/tabs/surveys/SurveysV3/types';
import { useAppState } from 'app/state';
import { formatDateFromUTC } from 'utils/dateTime';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { Dropdown } from 'shared-components/Dropdown';
import { Size } from 'types/StyleTypes';

import { CSSRS_DROPDOWN_OPTIONS, cssrsStringQuestions } from './constants';
import { LineChart, RenderTooltip, Series, YAxisOptions } from './LineChart';
import styles from './LineChart.module.scss';
import { SurveyTooltip } from './SurveyTooltip';

interface CSSRSLineChartProps {
  disableTooltip?: boolean;
  disableDataLabel?: boolean;
  responses: CSSRSSurveyResponse[];
  onClick?: (index: number, data: CSSRSSurveyResponse) => void;
}

type Dimension = keyof Omit<
  CSSRSSurveyResponse,
  'timestamp' | 'riskAssessment'
>;

export default function CSSRSLineChart(props: CSSRSLineChartProps) {
  const { responses, onClick } = props;
  const [dimension, setDimension] = useState<Dimension>('total');
  const defaultYaxisOption = { max: CSSRSMAX, min: 0, title: 'Score' };
  const [yaxis, setYaxis] = useState<YAxisOptions>(defaultYaxisOption);
  const timezone = useAppState((_) => _.user.timezone!);
  const labels = useMemo(
    () => responses.map((_) => formatDateFromUTC(_.timestamp, timezone)),
    [responses],
  );
  const data = useMemo(() => responses.map((r) => r[dimension]), [
    responses,
    dimension,
  ]);
  const series: Series[] = [{ data, name: dimension }];

  const handleClick = useCallback(
    (dataPointIndex: number) => {
      onClick?.(dataPointIndex, responses[dataPointIndex]);
    },
    [onClick, responses],
  );

  const onSelect = useCallback(
    (selected: Dimension) => {
      if (selected === 'total') {
        setYaxis(defaultYaxisOption);
      } else {
        setYaxis({ max: 3, min: 0, tickAmount: 3, title: 'Score' });
      }
      return setDimension(selected);
    },
    [setYaxis, defaultYaxisOption, setDimension],
  );

  return (
    <Fragment>
      <div className={styles.chartTitle}>
        <span className={styles.titleLabel}>
          <b>Columbia-Suicide Severity Risk Scale</b> • C-SSRS
        </span>
        <Dropdown
          size={Size.SM}
          variant="standard"
          data-testid="selectDimension"
          initialValue="total"
          options={CSSRS_DROPDOWN_OPTIONS}
          onSelect={onSelect}
        />
      </div>
      <LineChart
        data-testid="cssrs-line-chart"
        labels={labels}
        data={series}
        tooltip={createCSSRSTooltip(responses)}
        xaxisOptions={{ title: 'Date Submitted' }}
        yaxisOptions={yaxis}
        onClick={handleClick}
        disableDataLabel={props.disableDataLabel}
        disableTooltip={props.disableTooltip}
      />
    </Fragment>
  );
}

function createCSSRSTooltip(
  cssrsSurveys: CSSRSSurveyResponse[],
): RenderTooltip {
  interface CSSRSTooltipProps {
    dataPointIndex: number;
  }
  return ({ dataPointIndex }: CSSRSTooltipProps) => {
    const cssrs = cssrsSurveys[dataPointIndex];
    const data: [string, number][] = [
      [cssrsStringQuestions.WISHED_DEAD, cssrs.wishedDead],
      [cssrsStringQuestions.THOUGHTS_OF_SUICIDE, cssrs.thoughtsOfSuicide],
      [cssrsStringQuestions.THOUGHTS_ON_HOW, cssrs.thoughtsOnHow],
      [
        cssrsStringQuestions.THOUGHTS_WITH_INTENTION,
        cssrs.thoughtsWithIntention,
      ],
      [cssrsStringQuestions.DETAILED_PLANS, cssrs.detailedPlans],
      [cssrsStringQuestions.INTEND_TO_ACT, cssrs.intendToAct],
      [cssrsStringQuestions.RECENT_ACTIONS, cssrs.recentActions],
    ];
    return <SurveyTooltip data={data} total={cssrs.total} variant="three" />;
  };
}
