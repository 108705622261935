import { VaultMasterKeys } from '@ginger.io/vault-core/dist/crypto/UserMasterKeySet';
import { UserClaims } from '@okta/okta-auth-js';
import { CoachShiftStatusEnum, UserRole } from 'generated/globalTypes';
import { createAction } from 'redux-reloaded';

export enum Actions {
  LOG_IN = 'LOG_IN',
  LOG_OUT = 'LOG_OUT',
  INITIATE_VAULT_AUTH = 'INITIATE_VAULT_LOGIN',
  COMPLETE_VAULT_AUTH = 'COMPLETE_VAULT_LOGIN',
  SET_VAULT_KEYS = 'ENTER_VAULT_KEYS',
  AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
  UPDATE_SHIFT_STATUS = 'UPDATE_SHIFT_STATUS',
  UPDATE_TIMEZONE = 'UPDATE_TIMEZONE',
}

export type PubnubConfig = {
  authKey: string | null;
  rpcToCoachChannelId: string | null;
  coachBroadcastChannelId: string | null;
};

export type AuthenticatedUser = {
  clinicianId: string | null;
  userId: string;
  listenerUserId: string | null;
  role: UserRole;
  timezone: string | null;
  pubnubAuthKey: string | null;
  listenerId: string | null;
  coachinghubUsername: string | null;
  pubnubConfig: PubnubConfig;
  shiftStatus: CoachShiftStatusEnum | null;
  isAcceptingDropinMembers: boolean;
};

export const login = createAction(
  Actions.LOG_IN,
  (params: {
    oktaUser: UserClaims;
    appUser: AuthenticatedUser;
    useVaultUserHeader: boolean;
  }) => ({
    appUser: params.appUser,
    oktaUser: params.oktaUser,
    useVaultUserHeader: params.useVaultUserHeader,
  }),
);

export const logout = createAction(Actions.LOG_OUT);

export const initiateVaultAuth = createAction(
  Actions.INITIATE_VAULT_AUTH,
  (authChallenge: string) => ({
    authChallenge,
  }),
);

export const completeVaultAuth = createAction(
  Actions.COMPLETE_VAULT_AUTH,
  (jwtToken: string) => ({ jwtToken }),
);

export const setVaultKeys = createAction(
  Actions.SET_VAULT_KEYS,
  (masterKeys: VaultMasterKeys) => masterKeys,
);

export const updateShiftStatus = createAction(
  Actions.UPDATE_SHIFT_STATUS,
  (shiftStatus: CoachShiftStatusEnum) => shiftStatus,
);

export const updateTimezone = createAction(
  Actions.UPDATE_TIMEZONE,
  (payload: { timezone: string }) => payload,
);
