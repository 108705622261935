import gql from 'graphql-tag';

export const getMemberIntakeSurveyQuery = gql`
  query GetMemberIntakeSurvey($input: GetMemberIntakeSurveyInput!) {
    getMemberIntakeSurvey(input: $input) {
      intakeSurvey {
        id
        currentlyInPsychotherapy
        currentMedications
        reasonsForSeekingClinicalServices
        substanceConsumption
      }
    }
  }
`;
