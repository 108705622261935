import gql from 'graphql-tag';

export const addMemberSupportMutation = gql`
  mutation AddMemberSupportMutation($input: AddMemberSupportInput!) {
    addMemberSupport(input: $input) {
      success
      errors
    }
  }
`;

export const transferMemberToSICoachMutation = gql`
  mutation TransferMemberToSICoach($input: TransferMemberToSICoachInput!) {
    transferMemberToSICoach(input: $input) {
      coach {
        id
        shift
        name
      }
      success
      errors
    }
  }
`;

export const escalateMemberToClinicalMutation = gql`
  mutation EscalateMemberToClinicalMutation(
    $input: EscalateMemberToClinicalInput!
  ) {
    escalateMemberToClinical(input: $input) {
      success
      errors
    }
  }
`;

export const escalateTeenToClinicalMutation = gql`
  mutation EscalateTeenToClinicalMutation(
    $input: EscalateTeenToClinicalMutationInput!
  ) {
    escalateTeenToClinical(input: $input) {
      success
      created
      exception
    }
  }
`;
