import {
  booleanField,
  field,
  FieldDefinitions,
  stringField,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  Location,
  ClinicianChecklist,
  ClinicianChecklist_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/ClinicianChecklist';
import { requiredFieldIf } from 'app/notes-ui/utils';

export const fieldDefs: FieldDefinitions<ClinicianChecklist> = {
  appointmentId: stringField(),
  introducedCoachingToMember: field(),
  remarkableEntries: stringField(),
  reviewedLastSessionEntries: field(),
  memberGaveConsentForTelehealthAppointment: booleanField({
    rules: [
      (value, _) => {
        if (value === false) {
          return 'This field must be checked';
        }
      },
    ],
  }),
  releaseOfInformationRequested: field(),
  physicallyLocatedAtApptAddress: field<Location>({
    default: Location.undefined_location,
  }),
  currentLocation: stringField({
    default: '',
    rules: [
      (value, state: ClinicianChecklist) =>
        requiredFieldIf(
          value,
          state,
          'physicallyLocatedAtApptAddress',
          Location.located_outside_appt_address,
        ),
    ],
  }),
  version: field<ClinicianChecklist_Version>({
    default: ClinicianChecklist_Version.undefined_version,
  }),
};

export const validate = (data: ClinicianChecklist | null) =>
  data !== null && validateForm(data, fieldDefs);
