import { hideOrShowSnackNotification } from 'app/state/notifications/actions';
import { useDispatch } from 'react-redux';

type Callback = (message: string, dismissible?: boolean) => void;

export function useSnackNotification(): {
  showErrorNotification: Callback;
  showSuccessNotification: Callback;
} {
  const dispatch = useDispatch();
  const callback = (
    message: string,
    dismissible?: boolean,
    type?: 'success' | 'error',
  ) => {
    dispatch(hideOrShowSnackNotification({ dismissible, message, type }));
  };
  return {
    showErrorNotification: (message, dismissible) =>
      callback(message, dismissible, 'error'),
    showSuccessNotification: (message, dismissible) =>
      callback(message, dismissible, 'success'),
  };
}
