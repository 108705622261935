import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import { SafetyPlanStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/safety/SafetyPlan';
import { SafetyIntake as Safety } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyIntake';
import { Safety as ProgressSafety } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyProgress';
import { Time } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/Time';
import { getTimeValue } from 'app/notes-ui/forms/utils';
import moment from 'moment';

import { FULL_SAFETY_PLAN_FIELDS_CONCERN } from './safety-progress/constants';

// Safety form validation rules
export const isEmpty = (value: any): boolean =>
  value === null || value === undefined || value === '';

const hasTruthy = (obj: Record<string, any>): boolean => {
  return Object.values(obj).some((value) => {
    if (typeof value === 'object') {
      return hasTruthy(value);
    }
    return typeof value === 'boolean' && value === true;
  });
};

export const optionalField = (value: any, schema: any) => {
  return undefined;
};

export const deprecatedField = (value: any, schema: any) => {
  return undefined;
};

export const optionalSelfInjury = (
  value: any,
  safety: Safety | ProgressSafety,
) => {
  if (safety.currentSelfInjury?.isPresent && isEmpty(value)) {
    return 'This field is required';
  }
  return undefined;
};

export const optionalPastSelfInjury = (
  value: any,
  safety: Safety | ProgressSafety,
) => {
  if (safety.currentSelfInjury?.hasPast && isEmpty(value)) {
    return 'This field is required';
  }
  return undefined;
};

export const optionalPastHomicidalIdeation = (
  value: any,
  safety: Safety | ProgressSafety,
) => {
  if (safety.currentHomicidalIdeation?.hasPast && isEmpty(value)) {
    return 'This field is required';
  }
  return undefined;
};

export const optionalPastSuicidalIdeation = (
  value: any,
  safety: Safety | ProgressSafety,
) => {
  if (safety.currentSuicidalIdeation?.hasPast && isEmpty(value)) {
    return 'This field is required';
  }
  return undefined;
};

export const optionalCSIDescription = (value: string, safety: Safety) => {
  if (hasTruthy(safety.currentSuicidalIdeation || {}) && isEmpty(value)) {
    return 'This field is required';
  }
  return undefined;
};
export const optionalCHIDescription = (value: string, safety: Safety) => {
  if (hasTruthy(safety.currentHomicidalIdeation || {}) && isEmpty(value)) {
    return 'This field is required';
  }
  return undefined;
};
export const optionalPSIDescription = (value: string, safety: Safety) => {
  if (safety.pastSuicidalIdeation?.isPresent && isEmpty(value)) {
    return 'This field is required';
  }
  return undefined;
};
export const optionalPHIDescription = (value: string, safety: Safety) => {
  if (safety.pastHomicidalIdeation?.isPresent && isEmpty(value)) {
    return 'This field is required';
  }
  return undefined;
};
export const safetyPlanDescription = (value: string, safety: Safety) => {
  const data = {
    currentHomicidalIdeation: safety.currentHomicidalIdeation,
    currentSelfInjury: safety.currentSelfInjury?.isPresent,
    currentSuicidalIdeation: safety.currentSuicidalIdeation,
    pastHomicidalIdeation: safety.pastHomicidalIdeation?.isPresent,
    pastSuicidalIdeation: safety.pastSuicidalIdeation?.isPresent,
  };
  if (hasTruthy(data) && isEmpty(value)) {
    return `Enter a plan for the above "endorsed" answer(s) or write "N/A"`;
  }
  return undefined;
};

export const optionalSafetyPlan = (
  value: string,
  safety: ProgressSafety | Safety,
) => {
  // check no selectedConcerns high risk and return undefined
  if (
    !safety.selectedConcerns?.some((concern) =>
      FULL_SAFETY_PLAN_FIELDS_CONCERN.includes(concern),
    )
  ) {
    return undefined;
  }

  if (
    safety.anyChangesOrRisks !== BooleanOption.yes ||
    safety.safetyPlan?.status === SafetyPlanStatus.incomplete
  ) {
    return undefined;
  }

  if (
    safety.safetyPlan?.status === SafetyPlanStatus.completed &&
    isEmpty(value)
  ) {
    return 'This field is required';
  }
};

export const optionalSafetyPlanList = (
  values: string[],
  safety: ProgressSafety | Safety,
) => {
  const errorMessage = 'This field is required';

  if (
    !safety.selectedConcerns?.some((concern) =>
      FULL_SAFETY_PLAN_FIELDS_CONCERN.includes(concern),
    )
  ) {
    return undefined;
  }

  if (
    safety.anyChangesOrRisks !== BooleanOption.yes ||
    safety.safetyPlan?.status === SafetyPlanStatus.incomplete
  ) {
    return undefined;
  }

  if (values === undefined || values.length === 0) {
    return errorMessage;
  }

  const hasValue = values.some((value) => !isEmpty(value));
  if (safety.safetyPlan?.status === SafetyPlanStatus.completed && !hasValue) {
    return errorMessage;
  }
};

export const optionalActionsTakenOptions = (
  value: BooleanOption,
  safety: Safety,
) => {
  if (
    !safety.selectedConcerns.some((concern) =>
      FULL_SAFETY_PLAN_FIELDS_CONCERN.includes(concern),
    )
  ) {
    return undefined;
  }

  if (
    safety.anyChangesOrRisks === BooleanOption.yes &&
    safety.safetyPlan?.status === SafetyPlanStatus.completed &&
    value <= BooleanOption.undefined_choice
  ) {
    return 'This field is required';
  }
};

export const optionalAnyChangesOrRisks = (
  value: SafetyPlanStatus,
  safety: ProgressSafety | Safety,
) => {
  if (
    safety.anyChangesOrRisks === BooleanOption.yes &&
    (value === SafetyPlanStatus.undefined_status ||
      value === SafetyPlanStatus.UNRECOGNIZED)
  ) {
    return 'This field is required';
  }
};

export const optionalOnCallClinicianContacted = (
  value: string,
  safety: Safety,
) => {
  if (
    safety.safetyPlan?.status === SafetyPlanStatus.completed &&
    safety.safetyPlan?.actionsTaken?.onCallClinicianContacted ===
      BooleanOption.yes &&
    isEmpty(value)
  ) {
    return 'This field is required';
  }
};

export const timeIn24HoursFormat = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i;

const invalidTimeFormat = 'Invalid time format';

export const validateTimeFormat = (value: string) =>
  !timeIn24HoursFormat.test(value) ? invalidTimeFormat : undefined;

export const validateTimeDifference = (
  startTimeStr: string,
  endTimeStr: string,
) => {
  const error =
    validateTimeFormat(startTimeStr) || validateTimeFormat(endTimeStr);
  if (error !== undefined) return error;

  const startTime = moment.utc(startTimeStr, 'HH:mm');
  const endTime = moment.utc(endTimeStr, 'HH:mm');

  if (endTime.isBefore(startTime))
    return 'Start time cannot be greater than the end time';
  return undefined;
};

export const validateTimeField = (timeField?: Time) =>
  validateTimeFormat(getTimeValue(timeField));

export const requiredTimeField = (time?: Time) =>
  time === undefined || (time.hour === undefined && time.minute === undefined)
    ? 'This field is required'
    : undefined;

export const validateTimeFieldDifference = (start?: Time, end?: Time) =>
  validateTimeDifference(getTimeValue(start), getTimeValue(end));

export const validateHour = (hour: number) =>
  hour < 0 || hour > 23 ? invalidTimeFormat : undefined;

export const validateMinute = (minute: number) =>
  minute < 0 || minute > 59 ? invalidTimeFormat : undefined;

export const requiredTrueFalseBooleanOption = (
  value: BooleanOption | undefined,
) => {
  if (value === undefined) return 'This field is required';
  if ([BooleanOption.yes, BooleanOption.no].indexOf(value) === -1)
    return 'This field is required';
  return undefined;
};
