import Tab from 'app/inbox/components/Tab';
import useMultiTabs from 'app/inbox/components/useMultiTabs';
import AlertDialog from 'app/inbox/modals/AlertDialog';
import styles from 'app/inbox/multi-tabs/index.module.scss';
import { useAppState, useDispatch } from 'app/state';
import { selectShowDrawer } from 'app/state/inbox/selectors';
import { closeTab, selectTab } from 'app/state/member-tabs/actions';
import { selectTabState } from 'app/state/member-tabs/selectors';
import { useViewPortSize } from 'hooks/useViewPortSize';
import React, { useLayoutEffect, useRef, useState } from 'react';

type Props = {
  onTabClose: (activeTab: string | null) => void;
  onTabSelect: (key: string) => void;
};
export default function MultiTabs(props: Props) {
  const { onTabSelect, onTabClose } = props;
  const { openTabs, activeTab } = useMultiTabs();
  const showInboxDrawer = selectShowDrawer();
  const { width: viewPortWidth } = useViewPortSize();

  const dispatch = useDispatch();
  const handleTabSelect = (tabId: string): void => {
    dispatch(selectTab({ memberId: tabId }));
    onTabSelect(tabId);
  };
  const handleTabClose = (tabId: string): void => {
    // subtract the one that's closed by the handler
    const index = openTabs.indexOf(tabId);
    let newActiveTab = activeTab;

    if (activeTab === tabId) {
      if (openTabs[index - 1]) {
        newActiveTab = openTabs[index - 1];
      } else if (openTabs[index + 1]) {
        newActiveTab = openTabs[index + 1];
      } else {
        newActiveTab = null;
      }
    }
    dispatch(closeTab({ activeTab: newActiveTab, idToRemove: tabId }));
    onTabClose(newActiveTab);
  };

  const minimumTabWidth = 10;
  const maximumTabWidth = 200;
  const tabLimit = 20;
  const openTabLength = openTabs.length;
  const [tabWidth, setTabWidth] = useState<number>(maximumTabWidth);
  const limitReached = useRef<boolean>(false);

  useLayoutEffect(() => {
    if (openTabLength > tabLimit) {
      limitReached.current = true;
      if (activeTab) handleTabClose(activeTab);
    }

    const tabContainerWidth = document.getElementsByClassName(
      styles.tabContainer,
    )[0].clientWidth;
    const tabSpaceAvailable = Math.abs(Math.floor(tabContainerWidth));
    let tabWidth = Math.abs(Math.floor(tabSpaceAvailable / openTabLength));

    if (tabWidth < minimumTabWidth) {
      tabWidth = minimumTabWidth;
    } else if (tabWidth > maximumTabWidth) {
      tabWidth = maximumTabWidth;
    }
    setTabWidth(tabWidth);
  }, [openTabs.length, showInboxDrawer, viewPortWidth]);

  const resetLimit = () => {
    limitReached.current = false;
  };

  return (
    <div className={styles.tabContainer}>
      <AlertDialog open={limitReached.current} close={resetLimit} />
      {openTabs.map((memberId) => (
        <TabWrapper
          onTabClose={handleTabClose}
          onTabSelect={handleTabSelect}
          tabWidth={tabWidth}
          key={memberId}
          memberId={memberId}
        />
      ))}
    </div>
  );
}
function TabWrapper(props: {
  memberId: string;
  tabWidth: number;
  onTabSelect: (id: string) => void;
  onTabClose: (id: string) => void;
}) {
  const { memberId, tabWidth, onTabClose, onTabSelect } = props;
  const item = useAppState((state) => selectTabState(state, memberId));
  const {
    name,
    isSpanish,
    isFrench,
    isActive,
    isTeen,
    showNewRiskTaskIndicator,
    notifyCount,
    hasRisk,
  } = item;
  return (
    <Tab
      notifyCount={notifyCount}
      key={memberId}
      title={name}
      isSpanish={isSpanish}
      isFrench={isFrench}
      isTeen={isTeen}
      hasRisk={hasRisk}
      width={tabWidth}
      showNewRiskTaskIndicator={showNewRiskTaskIndicator}
      isActive={isActive}
      onTabClose={() => onTabClose(memberId)}
      onTabSelect={() => onTabSelect(memberId)}
    />
  );
}
