import gql from 'graphql-tag';

export const updateListenerShiftStatusMutationQuery = gql`
  mutation UpdateListenerShiftStatus($input: UpdateListenerShiftStatusInput!) {
    updateListenerShiftStatus(input: $input) {
      shiftStatus
      success
      error {
        exceptionName
        exceptionMessage
      }
    }
  }
`;
