import {
  field,
  FieldDefinitions,
  stringField,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  CollaborationPlan,
  CollaborationPlan_Goal,
  CollaborationPlan_GoalType,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/CollaborationPlan';
import { isEmpty } from 'app/notes-ui/shared/ValidationRules';

export const collaborationGoalFieldDefs: FieldDefinitions<CollaborationPlan_Goal> = {
  goalType: field(),
  label: stringField(),
  otherGoalTypeDescription: stringField({
    rules: [
      (value: string, state: CollaborationPlan_Goal) =>
        state.goalType === CollaborationPlan_GoalType.other && !value
          ? 'This is field required'
          : undefined,
    ],
  }),
  recommendedSteps: stringField(),
  recommendedStepsLabel: stringField(),
};

export const validate = (data: CollaborationPlan | null) => {
  if (data !== null) {
    return (
      !isEmpty(data.appointmentId) &&
      data.goal
        .filter(
          (goal) =>
            goal.goalType !== CollaborationPlan_GoalType.undefined_goal_type,
        )
        .map((goal) => validateForm(goal, collaborationGoalFieldDefs))
        .reduce((a, b) => a && b, true)
    );
  }
  return false;
};
