import { useAppState } from 'app/state';
import { selectPubnubStatus } from 'app/state/chat/selectors';
import {
  onNewMessage,
  onPresenceChange,
  onStatusReceived,
} from 'app/state/inbox/actions';
import { useLogger } from 'app/state/log/useLogger';
import { PresenceEvent, StatusEvent } from 'pubnub';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { usePubNubAPI } from './PubNubContextProvider';
import { PUBNUB_STATUS, RPCEnvelope, TextMessageEnvelope } from './types';

export const usePubnubListener = () => {
  const dispatch = useDispatch();
  const logger = useLogger();
  const status = selectPubnubStatus();
  const api = usePubNubAPI();
  const listenerId = useAppState((_) => _.user.listenerId);

  const { addListener, removeListener } = api;
  const handlePubnubMessage = async (
    envelope: TextMessageEnvelope | RPCEnvelope,
  ) => {
    dispatch(onNewMessage({ envelope }));
  };
  const handlePubnubPresence = async (envelope: PresenceEvent) => {
    dispatch(onPresenceChange({ envelope }));
  };
  const handlePubnubStatus = async (envelope: StatusEvent) => {
    dispatch(onStatusReceived({ envelope }));
  };

  const params = {
    message: handlePubnubMessage,
    presence: handlePubnubPresence,
    status: handlePubnubStatus,
  };

  useEffect(() => {
    if (status === PUBNUB_STATUS.INSTANTIATED) {
      try {
        logger.info('usePubnubListener: Adding PubNub event listener', {
          status: PUBNUB_STATUS[status],
        });
        addListener(params);
      } catch (e) {
        logger.error(
          new Error('Failed to add PubNub event listener', { cause: e }),
          {
            error: e,
            listenerId,
            status: e.status,
          },
        );
      }
    } else {
      logger.info('usePubnubListener: status changed', {
        status: PUBNUB_STATUS[status],
      });
    }
    return () => {
      if (status === PUBNUB_STATUS.INSTANTIATED) {
        logger.info('Cleaning up PubNub event listener', {
          status: PUBNUB_STATUS[status],
        });
        try {
          removeListener(params);
        } catch (e) {
          logger.error(
            new Error('Failed to remove PubNub event listener', { cause: e }),
            {
              error: e,
              listenerId,
              status: e.status,
            },
          );
        }
      }
    };
  }, [status]);
};
