import { Button, Popover } from '@mui/material';
import React, { PropsWithChildren } from 'react';

export function SimplePopover(
  props: PropsWithChildren<{
    buttonText: string;
    ariaId: string;
    onOpen?: () => void;
  }>,
) {
  const { children, buttonText, ariaId, onOpen } = props;
  const [
    anchorElement,
    setAnchorElement,
  ] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onOpen) onOpen();
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const open = Boolean(anchorElement);
  const id = open ? `${ariaId}-simple-popover` : undefined;

  return (
    <>
      <Button
        data-testid={`${ariaId}-button`}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        {buttonText}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
      >
        {children}
      </Popover>
    </>
  );
}
