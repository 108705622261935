export enum Status {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  COMPLETE = 'COMPLETE',
}

export type LoadingState = {
  status: Status.LOADING;
  data: null;
  error: null;
};

export type ErrorState = {
  status: Status.ERROR;
  data: null;
  error: Error;
};

export type DataState<T> = {
  status: Status.COMPLETE;
  data: T;
  error: null;
};

export type StateSlice<T = null> = LoadingState | ErrorState | DataState<T>;

export function loadingState(): LoadingState {
  return { data: null, error: null, status: Status.LOADING };
}

export function errorState(error: Error): ErrorState {
  return { data: null, error, status: Status.ERROR };
}

export function dataState<T>(data: T): DataState<T> {
  return { data, error: null, status: Status.COMPLETE };
}
