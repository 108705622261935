import { isApolloError, ServerError } from '@apollo/client';
import { GraphQLError } from 'graphql';

export enum GraphQLErrorCodes {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  FORBIDDEN = 'FORBIDDEN',
}

export function hasAuthenticationError(e: any) {
  const code = e.extensions?.code;
  const detail = e.extensions?.response?.body?.detail ?? '';
  const message = e.message ?? '';
  const hasAuthErrorMessage =
    message.includes('Missing authorization header') ||
    message.includes('Invalid or missing access token') ||
    detail.includes('Missing authorization header') ||
    detail.includes('Invalid or missing access token');
  return code === GraphQLErrorCodes.UNAUTHENTICATED || hasAuthErrorMessage;
}

export function isGraphQLAuthenticationError(error?: any): boolean {
  if (!error) return false;
  if (error instanceof Array) return error.some(isGraphQLAuthenticationError);
  if (isServerError(error))
    return (error.result.errors ?? []).some(hasAuthenticationError);
  if (!isApolloError(error)) return hasAuthenticationError(error);
  const { graphQLErrors, networkError } = error;
  const errors = [...(graphQLErrors ?? [])];
  if (isServerError(networkError)) {
    errors.push(...(networkError.result.errors ?? []));
  }
  return errors.some(hasAuthenticationError);
}

export function isAuthorizationError(error: any) {
  if (!error) return false;
  if (error instanceof Array) return error.some(isAuthorizationError);
  if (isServerError(error))
    return (error.result.errors ?? []).some(isAuthorizationError);
  if (isApolloError(error)) {
    const { graphQLErrors, networkError } = error;
    const errors = [...(graphQLErrors ?? [])];
    if (isServerError(networkError)) {
      errors.push(...(networkError.result.errors ?? []));
    }
    return errors.some(isAuthorizationError);
  }

  const code = error.extensions?.code;
  const message = (error.message ?? '').toLowerCase();
  const hasAuthorizationErrorMessage =
    message.includes('you do not have permission to perform this action') ||
    message.includes('not authorized');
  return hasAuthorizationErrorMessage || code === GraphQLErrorCodes.FORBIDDEN;
}

export function isServerError(error: unknown): error is ServerError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'response' in error &&
    'result' in error &&
    'statusCode' in error
  );
}

export function isGraphQLError(error: unknown): error is GraphQLError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'extensions' in error &&
    'path' in error &&
    'message' in error
  );
}
