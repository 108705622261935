import { Typography } from '@mui/material';
import { MarkdownContent } from 'shared-components/markdown-content/MarkdownContent';
import { classNameCombiner } from 'utils';
import React from 'react';

import styles from './BulletSeparator.module.scss';

type Props = {
  className?: string;
  children: JSX.Element | JSX.Element[] | string | string[] | null;
  [x: string]: any;
  showBullet?: boolean;
  withMarkdown?: boolean;
};

export const BulletSeparator = ({
  className = '',
  children,
  showBullet = true,
  withMarkdown,
  ...rest
}: Props) => {
  return (
    <Typography
      variant="subtitle2"
      component={withMarkdown ? MarkdownContent : 'span'}
      classes={{
        root: classNameCombiner([
          className,
          showBullet ? styles.bulletSeparator : '',
        ]),
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
};
