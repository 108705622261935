import { useForm } from '@ginger.io/react-use-form';
import { FocusArea } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/FocusArea';
import {
  FocusAreaSection,
  TherapyProgressSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';
import { Width } from 'types/StyleTypes';
import { TextAreaField } from 'app/notes-ui/forms/fields';
import { NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD } from 'app/notes-ui/utils';
import React from 'react';

import { fieldDefs } from './schema';

type Props = {
  appointmentId: string;
  initialValue?: FocusArea;
  onSubmit: (data: FocusAreaSection) => void;
  updateDraftNoteState: (data: FocusAreaSection) => void;
  disabled?: boolean;
  savingNote?: boolean;
};

export function FocusAreaFormv2(props: Props) {
  const { appointmentId, initialValue, disabled } = props;

  const { fields, getValue } = useForm<FocusArea>(
    fieldDefs,
    { ...initialValue, appointmentId } as FocusArea,
    {
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
      onStateChange: async () => {
        await onSubmit();
      },
    },
  );

  const onSubmit = async () => {
    if (disabled) return;

    props.onSubmit({
      data: getValue(),
      name: TherapyProgressSectionName.FOCUS_AREA,
    });
  };

  return (
    <>
      <TextAreaField
        disabled={disabled}
        testId="sessionFocusInClientWords"
        label="Focus of session"
        field={fields.sessionFocusInClientWords}
        subtext="To ensure the flow of the note, point out how today's conversation supports the treatment plan goals."
        placeholder="Quotes, topics discussed, skills reviewed, new culture / identity disclosures..."
        width={Width.FULL}
        rows={1}
        autoExpand={true}
      />
    </>
  );
}
