import {
  TransferMemberToSICoach,
  TransferMemberToSICoachVariables,
} from 'app/care-team/generated/TransferMemberToSICoach';
import { transferMemberToSICoachMutation } from 'app/care-team/mutations';
import {
  TransferMemberToSICoachErrorCode,
  TransferMemberToSICoachInput,
} from 'generated/globalTypes';
import { useMutationWithGlobalState } from 'hooks/useMutationWithGlobalState';
import Messages from 'i18n/en/careTeam.json';

export interface AddSICoachErrorState {
  title?: string;
  message?: string;
  errorType?: TransferMemberToSICoachErrorCode;
}

export interface UseTransferMemberToSICoachHook {
  transferMemberToSICoach: (
    input: TransferMemberToSICoachInput,
  ) => Promise<void>;
}

export const AddSICoachGenericError = {
  errorType: undefined,
  message: Messages.addSICoachGenericErrorMessage,
  title: Messages.addSICoachGenericErrorTitle,
};

export const AddSICoachAllCoachesAtPeakError = {
  errorType: TransferMemberToSICoachErrorCode.ALL_SI_COACHES_AT_PEAK,
  message: Messages.addSICoachAllCoachesAtPeakErrorMessage,
  title: Messages.addSICoachAllCoachesAtPeakErrorTitle,
};

export const AddSICoachAllCoachesOfflineError = {
  errorType: TransferMemberToSICoachErrorCode.ALL_SI_COACHES_OFFLINE,
  message: Messages.addSICoachAllCoachesOfflineErrorMessage,
  title: Messages.addSICoachAllCoachesOfflineErrorTitle,
};

export default function useTransferMemberToSICoach(): UseTransferMemberToSICoachHook {
  const [transferMemberToSICoachMutationFn] = useMutationWithGlobalState<
    TransferMemberToSICoach,
    TransferMemberToSICoachVariables
  >(transferMemberToSICoachMutation);

  const transferMemberToSICoach = async (
    input: TransferMemberToSICoachInput,
  ) => {
    const { data } = await transferMemberToSICoachMutationFn({ input });

    let error: AddSICoachErrorState = {};

    if (data && data.transferMemberToSICoach) {
      if (data.transferMemberToSICoach.success) return;

      if (
        data.transferMemberToSICoach.errors.some(
          (error) =>
            error == TransferMemberToSICoachErrorCode.ALL_SI_COACHES_OFFLINE,
        )
      ) {
        error = AddSICoachAllCoachesOfflineError;
      } else if (
        data.transferMemberToSICoach.errors.some(
          (error) =>
            error == TransferMemberToSICoachErrorCode.ALL_SI_COACHES_AT_PEAK,
        )
      ) {
        error = AddSICoachAllCoachesAtPeakError;
      }
    } else {
      error = AddSICoachGenericError;
    }

    throw error;
  };

  return { transferMemberToSICoach };
}
