import React, { useState } from 'react';
import { Input } from 'shared-components/form-inputs/Input';
import { FormError } from 'shared-components/FormError';

import { NoteFormControlLabel } from './NoteFormControlLabel';
import styles from './TimeRangeInput.module.scss';

type Props = {
  label: string;
  startTime: string;
  endTime: string;
  disabled?: boolean;
  error?: string;
  testId: string;
  onChange: (data: { startTime: string; endTime: string }) => void;
};

export function TimeRangeInput(props: Props) {
  const { startTime, endTime } = props;
  const [value, setValue] = useState({ endTime, startTime });
  const onChange = (key: 'startTime' | 'endTime', time: string) => {
    const updatedValue = { ...value, [key]: time };
    setValue(updatedValue);
    props.onChange(updatedValue);
  };
  const inputProps = { step: 300 /* 5min */ };
  return (
    <NoteFormControlLabel label={props.label}>
      <div data-testid={props.testId} className={styles.timeField}>
        <Input
          type="time"
          variant="outlined"
          placeholder="9:00"
          name="startTime"
          value={value.startTime}
          onChange={(value) => onChange('startTime', value)}
          disabled={props.disabled}
          inputProps={inputProps}
        />
        <span className={styles.to}>to</span>
        <Input
          type="time"
          variant="outlined"
          placeholder="9:40"
          name="endTime"
          value={value.endTime}
          onChange={(value) => onChange('endTime', value)}
          disabled={props.disabled}
          inputProps={inputProps}
        />
      </div>
      <FormError error={props.error} />
    </NoteFormControlLabel>
  );
}
