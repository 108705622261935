import { InboxItem, InboxSections, Results } from 'app/inbox/types';
import { State } from 'app/state/schema';
import { isCoachOrSupervisor } from 'utils';
import { batch } from 'react-redux';

import {
  initConversationsInInbox,
  refreshTodaysInboxTotalCount,
  triggerPubnubChannelSubscription,
  updateTabSection,
} from '../actions';
import { ActionHandler } from './types';

export const onUpdateInboxSectionItems = ({
  action,
  redux,
  context,
}: ActionHandler<{
  sections: { [k in InboxSections]?: Results };
  shouldSubscribeToPubnubChannels?: boolean;
  openTabs: InboxItem[];
}>) => {
  const {
    shouldSubscribeToPubnubChannels,
    sections,
    openTabs,
  } = action.payload;
  const inboxItems: InboxItem[] = Object.values(sections)
    .flatMap((_) => _?.items ?? [])
    .concat(openTabs);
  const tabSections: Partial<State['inbox']['tabSections']> = {};
  const { inbox, user } = redux.getState();
  const prevTabSections = inbox.tabSections;

  Object.entries(sections).forEach((_) => {
    const [section, value] = _ as [InboxSections, Results];
    if (!value) return;
    tabSections[section] = {
      cursor: value.cursor,
      hasMore: value.hasMore,
      ids: new Set([
        ...prevTabSections[section].ids,
        ...value.items.map((_) => _.id),
      ]),
    };
  });
  batch(() => {
    redux.dispatch(initConversationsInInbox({ inboxItems }));
    redux.dispatch(updateTabSection({ tabSections }));
    if (isCoachOrSupervisor(user.role) && shouldSubscribeToPubnubChannels) {
      const channelIds: string[] = [];
      const memberIdWithoutChannelId: string[] = [];
      inboxItems.forEach((_) => {
        if (_.memberCoachChannelId) {
          channelIds.push(_.memberCoachChannelId);
        } else {
          memberIdWithoutChannelId.push(_.id);
        }
      });
      if (memberIdWithoutChannelId.length > 0) {
        context.services.logger.warning(
          'updateInboxSectionItems::found some members without a valid channel id',
          {
            memberIdWithoutChannelId,
          },
        );
      }
      redux.dispatch(triggerPubnubChannelSubscription({ channelIds }));
    }
    redux.dispatch(refreshTodaysInboxTotalCount({}));
  });
};
