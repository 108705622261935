import { SessionFormat } from 'app/member-chart-cards/scheduler/types';
import { mapStringToSessionFormat } from 'app/member-chart-cards/scheduler/utils';
import DefaultCalendarIcon from 'assets/CalendarBlank.svg';
import { ReactComponent as ChatBubbleIcon } from 'assets/icons/chat-bubble.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video-icon.svg';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import React from 'react';
import { classNameCombiner } from 'utils';

import styles from './SessionIcon.module.scss';

export const SessionIcon = ({
  className = '',
  sessionFormat,
  calendarIcon = DefaultCalendarIcon,
}: {
  sessionFormat?: string | null;
  className?: string;
  calendarIcon?: string;
}) => {
  const {
    enable_coaching_scheduler: enableCoachingScheduler,
  } = useFeatureFlags().transientFeatureFlags;
  if (!enableCoachingScheduler) {
    return (
      <img
        className={classNameCombiner([styles.icon, className])}
        src={calendarIcon}
        alt="Calendar Icon"
        data-testid="calendar-icon"
      />
    );
  }

  return mapStringToSessionFormat(sessionFormat) === SessionFormat.CHAT ? (
    <ChatBubbleIcon
      className={classNameCombiner([styles.sessionIcon, className])}
      data-testid="chat-bubble-icon"
    />
  ) : (
    <VideoIcon
      className={classNameCombiner([styles.sessionIcon, className])}
      data-testid="video-icon"
    />
  );
};
