import React from 'react';
import { withRouter } from 'react-router';

import DosespotNotifications from './tabs/prescriptions/DosespotNotifications';

const ERxNotificationsScreen = () => {
  return <DosespotNotifications />;
};

export default withRouter(ERxNotificationsScreen);
