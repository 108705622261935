import AvatarWithStatus from 'app/care-team/avatar-vith-status/AvatarWithStatus';
import { useAppState } from 'app/state';
import { selectActiveMemberProfile } from 'app/state/care-team/actions';
import { classNameCombiner } from 'utils';
import { getTimezone } from 'utils/dateTime';
import { max } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';

import { CareMemberTooltip } from '../care-member-tooltip/CareMemberTooltip';
import { Coach, MS } from '../types';
import CoachRow from './CoachRow';
import styles from './Member.module.scss';
import MSRow from './MSRow';

const CoachMemberDetails = ({ item }: { item: Coach | MS }) => {
  if (item.__typename === 'CareTeamCoach') return CoachRow(item);
  if (item.__typename === 'CareTeamMemberSupport') return MSRow(item);

  return null;
};

type CoachMemberProps = {
  item: Coach | MS;
  index: number;
  columns: number;
  disableProfileLink?: boolean; // this is used to disable the profile link when you are on the profile page
  hideStatus?: boolean; // this is used to hide the status when you are on the profile page
};

const CoachMember = (props: CoachMemberProps) => {
  const dispatch = useDispatch();
  const {
    item,
    index,
    columns,
    disableProfileLink = false,
    hideStatus = false,
  } = props;
  const timezone = useAppState((state) => getTimezone(state.user.timezone));

  const onSetCareTeamProfile = (item: Coach) => {
    dispatch(selectActiveMemberProfile({ careTeamMember: item }));
  };

  const lastMessageExchanged =
    item.__typename === 'CareTeamCoach'
      ? max([item.coachLastEngagement, item.memberLastEngagement])
      : item.msLastEngagement;
  const totalMessagesExchanged =
    item.__typename === 'CareTeamCoach'
      ? item.memberMessagesExchanged + item.coachMessagesExchanged
      : item.memberMessagesExchanged + item.msMessagesExchanged;

  const previousAppointmentDate =
    item.__typename === 'CareTeamCoach' ? item.previousSessionDate : null;
  const nextScheduledSession =
    item.__typename === 'CareTeamCoach' ? item.nextSessionDate : null;
  const status = item.__typename === 'CareTeamCoach' ? item.state : null;

  return (
    <CareMemberTooltip
      timezone={timezone}
      key={`coach_member_tooltip_${item.id}`}
      id={item.id}
      name={item.name}
      lastMessageExchanged={lastMessageExchanged?.toString()!}
      totalMessagesExchanged={totalMessagesExchanged}
      nextAppointmentDate={nextScheduledSession}
      previousAppointmentDate={previousAppointmentDate}
    >
      <button
        onClick={() => {
          if (item.__typename === 'CareTeamCoach') {
            onSetCareTeamProfile(item);
          }
        }}
        key={`care-member-${index}`}
        data-testid={`btn_care_member_${item.id}`}
        className={classNameCombiner([
          styles.item,
          columns === 1 ? styles.fullscreen : '',
          item.__typename === 'CareTeamMemberSupport' || disableProfileLink
            ? styles.disabled
            : '',
        ])}
      >
        <AvatarWithStatus
          picture={item.avatar}
          name={item.name}
          status={hideStatus === true ? null : status}
        />
        <CoachMemberDetails item={item} />
      </button>
    </CareMemberTooltip>
  );
};

export default CoachMember;
