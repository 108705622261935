import { PUBNUB_STATUS } from 'app/coach/pubnub/types';
import { selectPubnubStatus } from 'app/state/chat/selectors';
import {
  selectCurrentPubnubChannelId,
  selectMessages,
} from 'app/state/inbox/selectors';
import React, { useState } from 'react';

import { MemberProfileBaseProps } from '../ChatContainer';
import { ChatErrorState } from '../error/ChatErrorState';
import { LoadMoreSectionContainer } from '../load-more-section/LoadMoreSectionContainer';
import { MessagesList } from '../messages/MessagesList';
import { HISTORY_STATE } from '../types';
import styles from './MessagesSection.module.scss';

interface MessageSectionProps {
  memberProfileBaseProps: MemberProfileBaseProps;
  scrollToTheBottom: () => void;
}

export const MessagesSection = ({
  memberProfileBaseProps,
  scrollToTheBottom,
}: MessageSectionProps) => {
  const currentChannelId = selectCurrentPubnubChannelId();
  const status = selectPubnubStatus();
  const [historyState, setHistoryState] = useState<HISTORY_STATE>(
    HISTORY_STATE.STILL_HAS_SOME_MESSAGES,
  );

  const messages = selectMessages(currentChannelId);
  const numberOfMessages = messages.length;
  if (status !== PUBNUB_STATUS.INSTANTIATED)
    return (
      <ChatErrorState
        error={
          new Error(
            'Cannot render messages section when pubnub has not been instantiated',
          )
        }
      />
    );

  return (
    <div data-testid="messages-section" className={styles.messagesContainer}>
      <LoadMoreSectionContainer
        messages={messages}
        currentChannelId={currentChannelId}
        historyState={historyState}
        setHistoryState={setHistoryState}
      />
      {numberOfMessages ? (
        <MessagesList
          messages={messages}
          memberProfileBaseProps={memberProfileBaseProps}
          scrollToTheBottom={scrollToTheBottom}
          historyState={historyState}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
