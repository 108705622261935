import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { ActionModal } from 'app/patients/tabs/document-upload/ActionModal';
import React from 'react';

import { SafetyConcerns } from './safety-progress/constants';

type Props = {
  deletingRisk: SafetyConcerns | null;
  onClose: () => void;
  onConfirm: (() => Promise<void>) | (() => void);
};

export const DeleteRiskDialog = (props: Props) => {
  const { deletingRisk, onClose, onConfirm } = props;

  return (
    <ActionModal
      isOpen={deletingRisk !== null}
      title={`Delete ${deletingRisk}?`}
      onClose={onClose}
      onConfirm={onConfirm}
      actionLabel="Yes, Delete"
      variation="modernWarning"
    >
      <p className={styles.modalText}>
        Are you sure you want to delete <strong>{deletingRisk}</strong> from
        your note? This action is irreversible.
      </p>
      <p className={styles.modalText}>
        <strong>Note:</strong> The rest of your note will not be altered.
      </p>
    </ActionModal>
  );
};
