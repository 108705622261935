import {
  field,
  FieldDefinitions,
  stringField,
  useForm,
} from '@ginger.io/react-use-form';
import {
  MessageToCareTeam as MessageToCareTeamVaultType,
  MessageToCareTeam_Version as Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/MessageToCareTeam';
import { NoteType } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/NoteType';
import { CardTextAreaField } from 'app/coach/coach-notes/note-detail-view/note-fields';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import { Subsection } from 'app/vault/api/ShareableSubsectionTypes';
import React from 'react';

enum FieldLabels {
  MESSAGE_TO_CARE_TEAM = 'Message to care team',
}

export const fieldDefs: FieldDefinitions<MessageToCareTeamVaultType> = {
  appointmentEnd: stringField({ rules: [] }),
  appointmentId: stringField({ rules: [] }),
  appointmentStart: stringField({ rules: [] }),
  messageToCareTeam: stringField({ rules: [] }),
  noteType: field<NoteType>({ rules: [] }),
  version: field<Version>({ default: Version.v0, rules: [] }),
};

export const MessageToCareTeam = ({
  selectedNote,
  readOnly,
  tooltipEventLabel,
}: {
  readOnly: boolean;
  tooltipEventLabel: string;
  selectedNote?: Subsection;
}): React.ReactElement | null => {
  const { fields } = useForm<MessageToCareTeamVaultType>(fieldDefs, {
    ...(selectedNote as MessageToCareTeamVaultType),
  });

  return (
    <>
      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.MESSAGE_TO_CARE_TEAM}
      />
      <CardTextAreaField
        field={fields.messageToCareTeam}
        tooltipEventLabel={`${tooltipEventLabel}: ${FieldLabels.MESSAGE_TO_CARE_TEAM}`}
        tooltipText=""
        placeholder=""
        readOnly={readOnly}
      />
    </>
  );
};
