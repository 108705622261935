import { CollaborationPlan } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/CollaborationPlan';
import { Safety } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyProgress';
import { SubstanceUse } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import { Assessment } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/Assessment';
import { ClinicianChecklist } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/ClinicianChecklist';
import { FocusArea } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/FocusArea';
import { TreatmentPlan } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/TreatmentPlan';
import { BehavioralObservations } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/BehavioralObservations';
import { Metadata } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { TherapyProgressSectionName } from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';
import { VaultItem_SchemaType } from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import { VaultItemWithId } from '@ginger.io/vault-ui/dist/api/VaultAPI';
import { AmendmentWithAuditLog } from 'app/notes-ui/shared/amendments/types';
import { TherapyProgressNote } from 'app/vault/api/TherapyProgressNotesAPI';
import { newClinicalNoteMetadata } from 'app/vault/data/utils';

export function decodeTherapyProgressNote(
  appointmentId: string,
  items: VaultItemWithId[],
  amendments: AmendmentWithAuditLog[],
): TherapyProgressNote {
  const note: TherapyProgressNote = {
    [TherapyProgressSectionName.CLINICIAN_CHECKLIST]: null,
    [TherapyProgressSectionName.FOCUS_AREA]: null,
    [TherapyProgressSectionName.SAFETY]: null,
    [TherapyProgressSectionName.SUBSTANCE_ABUSE]: null,
    [TherapyProgressSectionName.BEHAVIORAL_OBSERVATION]: null,
    [TherapyProgressSectionName.ASSESSMENT]: null,
    [TherapyProgressSectionName.TREATMENT_PLAN]: null,
    [TherapyProgressSectionName.COLLABORATION_PLAN]: null,
    [TherapyProgressSectionName.AMENDMENTS]: null,
    amendments,
    createdAt: null,
    metadata: newClinicalNoteMetadata(appointmentId),
    permissions: null,
    updatedAt: null,
  };

  const schemas = VaultItem_SchemaType;
  items.forEach(({ item: vaultItem, permissions, updatedAt, createdAt }) => {
    switch (vaultItem.schemaType) {
      case schemas.vault_clinical_notes_therapy_progress_clinician_checklist: {
        const item = ClinicianChecklist.decode(vaultItem.data);
        note[TherapyProgressSectionName.CLINICIAN_CHECKLIST] = item;
        break;
      }

      case schemas.vault_clinical_notes_therapy_progress_focus_area: {
        const item = FocusArea.decode(vaultItem.data);
        note[TherapyProgressSectionName.FOCUS_AREA] = item;
        break;
      }

      case schemas.vault_clinical_notes_therapy_progress_safety: {
        const item = Safety.decode(vaultItem.data);
        note[TherapyProgressSectionName.SAFETY] = item;
        break;
      }

      case schemas.vault_clinical_notes_therapy_progress_substance_use: {
        const item = SubstanceUse.decode(vaultItem.data);
        note[TherapyProgressSectionName.SUBSTANCE_ABUSE] = item;
        break;
      }

      case schemas.vault_clinical_notes_therapy_progress_behavioral_observations: {
        const item = BehavioralObservations.decode(vaultItem.data);
        note[TherapyProgressSectionName.BEHAVIORAL_OBSERVATION] = item;
        break;
      }

      case schemas.vault_clinical_notes_therapy_progress_assessment: {
        const item = Assessment.decode(vaultItem.data);
        note[TherapyProgressSectionName.ASSESSMENT] = item;
        break;
      }

      case schemas.vault_clinical_notes_therapy_progress_treatment_plan: {
        const item = TreatmentPlan.decode(vaultItem.data);
        note[TherapyProgressSectionName.TREATMENT_PLAN] = item;
        break;
      }

      case schemas.vault_clinical_notes_therapy_progress_collaboration_plan: {
        const item = CollaborationPlan.decode(vaultItem.data);
        note[TherapyProgressSectionName.COLLABORATION_PLAN] = item;
        break;
      }

      case schemas.vault_clinical_notes_therapy_progress_metadata: {
        const item = Metadata.decode(vaultItem.data);
        note.metadata = item;
        note.permissions = permissions;
        note.createdAt = createdAt;
        note.updatedAt = updatedAt ?? createdAt;
        break;
      }

      default: {
      } // no-op
    }
  });
  return note;
}
