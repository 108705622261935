import { ApolloError } from '@apollo/client';
import Button from '@mui/material/Button';
import styles from 'app/coach/coach-notes/CoachNotes.module.scss';
import { NotesAndSessionInfo } from 'app/coach/coach-notes/CoachNotesTypes';
import { ErrorModal } from 'shared-components/ErrorModal';
import React, { useState } from 'react';

export function CoachNotesErrorNotification({
  error,
}: Readonly<{ error: NotesAndSessionInfo['error'] }>) {
  const [showDetail, setShowDetail] = useState(false);
  if (!error) return null;
  const { coachNotesErrors, legacyNotesErrors } = error.data ?? {};
  const errors: ApolloError[] = [];
  if (coachNotesErrors) errors.push(coachNotesErrors);
  if (legacyNotesErrors) errors.push(legacyNotesErrors);

  return (
    <>
      <div
        data-testid="coachNotesErrorNotification"
        className={styles.error}
        key="error"
      >
        <span className={styles.errorText}>{error.message}</span>
        &nbsp;
        <Button
          disableRipple={true}
          disableFocusRipple={true}
          disableTouchRipple={true}
          className={styles.errorDetailBtn}
          onClick={() => setShowDetail(true)}
        >
          view error
        </Button>
      </div>

      {showDetail && (
        <ErrorModal
          title="An error occurred fetching notes data"
          errors={errors}
          onSubmitReport={() => setShowDetail(false)}
          onClose={() => setShowDetail(false)}
          isOpen={showDetail}
        />
      )}
    </>
  );
}
