import React from 'react';

import styles from './InlineNotification.module.scss';

export interface InlineNotificationProps {
  children: any;
  icon?: JSX.Element;
  testId?: string;
}

/**
 * A notification component that displays a message with an optional icon.
 * @param children The message to display.
 * @param icon An optional icon to display.
 * @param testId An optional test id to apply to the component.
 */
export const InlineNotification = ({
  children,
  icon,
  testId,
}: InlineNotificationProps) => {
  return (
    <div className={styles.container} data-testid={testId}>
      {icon && <div className={styles.icon}>{icon}</div>}
      <div className={styles.text}>{children}</div>
    </div>
  );
};
