import { viewClinicianAnalyticsAction } from 'app/state/amplitude/actions/careMetrics';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';

import styles from './CareMetrics.module.scss';
import ClinicianSidePanel from './ClinicianSidePanel';
import { DashboardContainer } from './DashboardContainer';

function DashboardScreen() {
  const { clinicianId } = useParams<{ clinicianId?: string }>();

  const dispatch = useDispatch();

  useEffect(() => {
    if (clinicianId) dispatch(viewClinicianAnalyticsAction({ clinicianId }));
  }, [clinicianId]);

  if (!clinicianId) return <></>;
  return (
    <Grid className={styles.grid}>
      <Columns
        className={styles.item}
        widths={[COLUMN_WIDTH.TWO, COLUMN_WIDTH.TEN]}
      >
        <ClinicianSidePanel clinicianId={clinicianId} />
        <DashboardContainer clinicianId={clinicianId} />
      </Columns>
    </Grid>
  );
}

export default DashboardScreen;
