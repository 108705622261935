import { Affect_Scope } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Affect';
import { Insight } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Insight';
import { Interventions } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Interventions';
import { Judgment } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Judgment';
import { Mood } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Mood';
import { Speech } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Speech';
import { ThoughtContent } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/ThoughtContent';
import { ThoughtProcess } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/ThoughtProcess';
import { TherapyIntakeSectionName } from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import Labels from 'app/notes-ui/strings/en.json';
import { ILogger } from 'app/state/log/Logger';
import { TherapyIntakeNote } from 'app/vault/api/TherapyIntakeNotesAPI';
import { TherapyProgressNote } from 'app/vault/api/TherapyProgressNotesAPI';

import { EnumOption } from '../types';
import { ClinicalNotePDF } from './ClinicalNotePDF';
import { NotePDFBuilder, NotePDFBuilderImpl } from './NotePDFBuilder';
import { NoteHeader } from './types';

export abstract class TherapyNotePDF<
  T extends TherapyIntakeNote | TherapyProgressNote
> extends ClinicalNotePDF<T> {
  protected constructor(
    protected note: T,
    protected noteHeader: NoteHeader,
    protected timezone: string,
    protected diagnosisCodesOptions: EnumOption[],
    protected logger: ILogger,
    protected pdfBuilder: NotePDFBuilder = new NotePDFBuilderImpl(),
  ) {
    super(
      note,
      noteHeader,
      timezone,
      diagnosisCodesOptions,
      logger,
      pdfBuilder,
    );
  }

  protected behavioralObservation() {
    this.pdfBuilder.section(
      TherapyIntakeSectionName.BEHAVIORAL_OBSERVATION,
      () => {
        const behavior = this.note.BEHAVIORAL_OBSERVATION;

        if (behavior) {
          this.pdfBuilder
            .enumSelectionsGroup(
              Mood,
              behavior.mood,
              Labels.MOOD,
              'No mood observations',
            )
            .padding()

            .radioItemAnswer(
              Affect_Scope,
              Labels.AFFECT,
              behavior.affect?.scope,
            )
            .yesOrNoAnswer(
              Labels.AFFECT_CONGRUENT_WITH_MOOD,
              behavior.affect?.congruentWithMood,
            )
            .yesOrNoAnswer(
              Labels.AFFECT_CONGRUENT_WITH_CONTENT,
              behavior.affect?.congruentWithContent,
            )
            .padding()

            .enumSelectionsGroup(
              ThoughtContent,
              behavior.thoughtContent,
              Labels.THOUGHT_CONTENT,
              'No thought content observations',
            )
            .padding()

            .enumSelectionsGroup(
              ThoughtProcess,
              behavior.thoughtProcess,
              Labels.THOUGHT_PROCESS,
              'No thought process observations',
            )
            .padding()

            .enumSelectionsGroup(
              Speech,
              behavior.speech,
              Labels.SPEECH,
              'No speech observations',
            )
            .padding()

            .radioItemAnswer(Insight, Labels.INSIGHT, behavior.insight)
            .padding()

            .radioItemAnswer(Judgment, Labels.JUDGMENT, behavior.judgment)
            .padding()

            .enumSelectionsGroup(
              Interventions,
              behavior.interventionsUsed,
              Labels.INTERVENTIONS_USED,
              'No interventions used',
            );
          return true;
        } else return false;
      },
      'No behavioral observations',
    );
  }
}
