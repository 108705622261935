import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { Fragment } from 'react';
import { Bar as BarChartJS } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export interface BarChartProps {
  title: string;
  data: ChartData<'bar', number[], unknown>;
  datalabels?: any;
}

export function BarChart(props: BarChartProps) {
  const { title, data, datalabels } = props;

  const options = {
    indexAxis: 'y' as const,
    plugins: {
      datalabels: {},
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: true,
        text: title,
      },
    },
    responsive: true,
  };

  if (datalabels) {
    options.plugins.datalabels = datalabels;
  }

  return (
    <Fragment>
      <BarChartJS options={options} plugins={[ChartDataLabels]} data={data} />
    </Fragment>
  );
}

export default BarChart;
