import { TableCell } from '@mui/material';
import { FilterKeys } from 'app/appointments/table/constants';
import { useLoadAppointmentAndNotes } from 'app/appointments/useLoadAppointmentAndNotes';
import { NotesItemResponse } from 'app/coach/coach-notes/CoachNotesTypes';
import { MemberSessionStatistics } from 'app/patients/tabs/appointments/MemberSessionStatistics';
import { NoteTableCard } from 'app/sortable-table/note-card/NoteTableCard';
import { SortableTable } from 'app/sortable-table/SortableTable';
import { getAppointmentOrNoteIdAndStatus } from 'utils';
import {
  DecodedNotesUserMetadata,
  OutOfSessionOrTerminationNote,
} from 'app/vault/hooks/NonAppointments/useOutOfSessionAndTerminationNotes';
import { useNotesUserMetadata } from 'app/vault/hooks/useNotesUserMetadata';
import qs from 'query-string';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MenuItem as FilterMenuItem } from 'shared-components/menu/FilterMenu';
import { Loader } from 'shared-components/loader/Loader';
import { UnreadIndicator } from 'shared-components/UnreadIndicator';

import { AppointmentNoteDetail } from './AppointmentNoteDetail';
import {
  AppointmentsAndNotesAPIContextProps,
  AppointmentsAndNotesCursor,
  useAppointmentsAndNotesAPI,
} from './AppointmentsAndNotesAPIContext';
import styles from './AppointmentsAndNotesTable.module.scss';
import { AppointmentRow } from './table/AppointmentRow';
import { NoteRow } from './table/NoteRow';
import {
  activeAppStatusFilter,
  activeNoteStatusFilter,
  activeTypeFilter,
  CLINICAL_APPOINTMENT,
} from './utils';

export type AppointmentsAndNotesTableProps = {
  patientId: string;
};

type AppointmentOrNote =
  | AppointmentsAndNotesCursor
  | OutOfSessionOrTerminationNote;

function getAppointmentsAndNotesTableBody(
  apptAndNotesApi: AppointmentsAndNotesAPIContextProps,
  patientId: string,
  notesUserMetadata?: DecodedNotesUserMetadata,
  onClick?: (note: AppointmentOrNote) => Promise<void>,
) {
  const {
    table: {
      appointmentsAndNotes: items,
      filters: {
        activeTypeFilters,
        activeAppStatusFilters,
        activeNoteStatusFilters,
      },
    },
    noteDetails: { setCursor },
  } = apptAndNotesApi;

  const filtered_items = items
    .filter((item) => activeTypeFilter(item, activeTypeFilters))
    .filter((item) => activeAppStatusFilter(item, activeAppStatusFilters))
    .filter((item) => activeNoteStatusFilter(item, activeNoteStatusFilters));

  const onClickSharedCoachNote = async (note: NotesItemResponse) => {
    await onClick?.(note);
    setCursor(note);
  };

  return filtered_items.map((item, i) => {
    const { id, readOnly } = getAppointmentOrNoteIdAndStatus(item);
    const isUnread =
      readOnly &&
      notesUserMetadata &&
      (!notesUserMetadata[id!] || !notesUserMetadata?.[id!].hasReadItem);

    return {
      component: (
        <>
          <TableCell className={styles.unreadIndicator}>
            {isUnread && <UnreadIndicator />}
          </TableCell>
          {'__typename' in item ? ( // clinical appointments and out-of-session/termination notes
            item.__typename === CLINICAL_APPOINTMENT ? (
              <AppointmentRow
                dataTestId={`row-${i}`}
                appointment={item}
                onClick={onClick}
              />
            ) : (
              <NoteRow
                dataTestId={`row-${i}`}
                note={item}
                patientId={patientId}
                onClick={onClick}
              />
            )
          ) : (
            // shared coach notes
            <NoteTableCard
              dataTestId={`row-${id}`}
              className={styles.noteTableCard}
              note={item}
              startDate={item.startDate}
              dateString={item.dateString}
              details={item.noteDetails}
              careTeamMember={item.createdBy}
              onClick={onClickSharedCoachNote}
              showClinicalNotesView={true}
            />
          )}
        </>
      ),
      id: id!,
    };
  });
}

export const AppointmentsAndNotesTable = (
  props: AppointmentsAndNotesTableProps,
) => {
  const { patientId } = props;
  const { search } = useLocation();
  const { appointmentNote } = qs.parse(search);
  const {
    refetchAppointments,
    loading,
    error,
    notesUserMetadata,
  } = useLoadAppointmentAndNotes({ patientId });
  const apptAndNotesApi = useAppointmentsAndNotesAPI();

  const {
    noteDetails: { cursor: activeAppointmentNote, setCursor },
    table: { filters, columns, changeColumnSort, appointmentsAndNotes },
  } = apptAndNotesApi;

  const { saveNotesUserMetadata } = useNotesUserMetadata({
    memberId: patientId,
  });

  const sortedColumns = [
    { filters: filters.unreadFilter, ...columns[0] },
    { filters: filters.typeFilters, ...columns[1] },
    { filters: filters.appStatusFilters, ...columns[2] },
    { filters: filters.noteStatusFilters, ...columns[3] },
  ];

  const onSortableTableFilter = (column: string, item: FilterMenuItem) => {
    switch (column) {
      case FilterKeys.APPOINTMENT_TYPE:
        filters.toggleTypeFilters(item.key);
        break;
      case FilterKeys.APPOINTMENT_STATUS:
        filters.toggleAppStatusFilters(item.key);
        break;
      case FilterKeys.NOTE_STATUS:
        filters.toggleNoteStatusFilters(item.key);
        break;
      default:
        break;
    }
  };

  // re-fetch notes when activeAppointmentNote changes
  // this prevents the notes from being stale when the user navigates back to the table
  useEffect(() => {
    if (!activeAppointmentNote) void refetchAppointments();
  }, [activeAppointmentNote]);

  // set note cursor if appointmentNote is in url
  // this is needed for cases where the user navigates directly to a note from another page
  useEffect(() => {
    if (!appointmentNote) return;
    const appt = appointmentsAndNotes.find(
      (appt) =>
        ('id' in appt && appt.id === appointmentNote) ||
        ('vaultItemId' in appt && appt.vaultItemId === appointmentNote),
    );
    if (appt) setCursor(appt);
  }, [appointmentNote, appointmentsAndNotes]);

  const onRowClick = async (appointmentOrNote: AppointmentOrNote) => {
    const { id: vaultItemId, readOnly } = getAppointmentOrNoteIdAndStatus(
      appointmentOrNote,
    );

    if (vaultItemId && readOnly) {
      const metadata = notesUserMetadata?.[vaultItemId];
      await saveNotesUserMetadata(
        vaultItemId,
        metadata?.metadataId,
        metadata?.hasReadItem,
      );
    }
  };

  if (loading) return <Loader />;

  if (activeAppointmentNote)
    return <AppointmentNoteDetail memberId={patientId} />;

  return (
    <SortableTable
      dataTestId="appointment-notes-table"
      tableClass={styles.appointmentsAndNotesTable}
      InfoHeader={<MemberSessionStatistics patientId={patientId} />}
      columns={sortedColumns}
      onSortableTableFilter={onSortableTableFilter}
      changeColumnSort={changeColumnSort}
      rows={getAppointmentsAndNotesTableBody(
        apptAndNotesApi,
        patientId,
        notesUserMetadata,
        onRowClick,
      )}
      loading={loading}
      error={error ?? false}
      errorMessage={error}
    />
  );
};
