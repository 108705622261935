import {
  Frequency,
  Frequency_Period as Period,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/Frequency';
import { Size } from 'types/StyleTypes';
import { EnumDropdown } from 'app/notes-ui/forms/form-controls/EnumDropdown';
import styles from 'app/notes-ui/forms/form-controls/FrequencyInput.module.scss';
import React, { useState } from 'react';

import { NumberInput } from './NumberInput';

type Props = {
  value: Frequency;
  name?: string;
  disabled?: boolean;
  error?: string;
  onChange: (frequency: Frequency) => void;
};

export function FrequencyInput(props: Props) {
  const [value, setValue] = useState<Frequency>(props.value);

  const handleChange = (
    field: keyof Frequency,
    fieldValue: number | Period | null,
  ) => {
    const frequency = { ...value, [field]: fieldValue };
    setValue(frequency);
    props.onChange(frequency);
  };
  return (
    <>
      <div data-testid={props.name || 'frequencyInput'} className={styles.root}>
        <NumberInput
          placeholder="0"
          disabled={props.disabled}
          value={value.timesPerPeriod}
          label="per time"
          name="amount"
          onChange={(amount) => {
            let value: number | null = amount;
            if (isNaN(value)) value = null;
            handleChange('timesPerPeriod', value);
          }}
          error={props.error}
        />
        <EnumDropdown
          data-testid="select"
          disabled={props.disabled}
          initialValue={value.period as Period}
          enum={Period}
          onSelect={(period: Period) => handleChange('period', period)}
          className={styles.dropdown}
          placeholder="Frequency"
          size={Size.SM}
        />
      </div>
    </>
  );
}
