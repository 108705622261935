import { TypingState } from 'app/coach/chat/types';
import { PUBNUB_STATUS, TimeoutsMap } from 'app/coach/pubnub/types';

import { TaskRelatedMessageInfo } from './types';

export type State = {
  publishTimeoutsMap: TimeoutsMap | null;
  pubnubStatus: PUBNUB_STATUS;
  memberCoachChannelPrefix: string | null;
  typingStateMap: Record<string, TypingState>;
  inputsMap: Record<string, string>;
  isReadOnlyMap: Record<string, boolean>;
  shouldPlayNotificationSound: boolean;
  taskRelatedMessages: Record<string, TaskRelatedMessageInfo>;
};

export const getInitialChatState = (): State => {
  return {
    inputsMap: {},
    isReadOnlyMap: {},
    memberCoachChannelPrefix: null,
    publishTimeoutsMap: null,
    pubnubStatus: PUBNUB_STATUS.NOT_INSTANTIATED,
    shouldPlayNotificationSound: false,
    taskRelatedMessages: {},
    typingStateMap: {},
  };
};
