import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

import style from './CareMetrics.module.scss';
import { ChartSeries } from './types';

interface DateRangeCardProps {
  title: string;
  series: ChartSeries;
  onClick: (key: string, series: ChartSeries) => void;
  selected: string;
  body?: string;
}

function DateRangeCard(props: DateRangeCardProps) {
  const { title, body, selected, onClick, series } = props;
  return (
    <Box
      onClick={() => onClick(title, series)}
      border="solid 2px #EAE7E4"
      borderRadius={2}
      margin={0.5}
      padding={0.2}
      height={50}
      width={60}
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={
        selected === title ? style.dateRangeBoxSelected : style.dateRangeBox
      }
    >
      <Stack justifyContent="center" alignItems="center">
        <Typography fontSize={12}>{title}</Typography>
        {body && (
          <Typography fontSize={18} fontWeight="bold">
            {body}
          </Typography>
        )}
      </Stack>
    </Box>
  );
}

export default DateRangeCard;
