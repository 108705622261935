import {
  field,
  FieldDefinitions,
  nonEmptyArrayField,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  CurrentFunctioning,
  CurrentFunctioning_CopingSkills as CopingSkills,
  CurrentFunctioning_Stressors as Stressors,
  CurrentFunctioning_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/CurrentFunctioning';
import {
  CurrentFunctioningSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import { EnumCheckboxesField } from 'app/notes-ui/forms/fields';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { AUTO_SAVE_DRAFT_DELAY } from 'app/notes-ui/utils';
import React from 'react';

import styles from './CurrentFunctioningForm.module.scss';

type Props = {
  appointmentId: string;
  initialValue?: CurrentFunctioning;
  disabled?: boolean;
  savingNote?: boolean;
  onSubmit: (data: CurrentFunctioningSection) => void;
  updateDraftNoteState: (data: CurrentFunctioningSection) => void;
};

export const fieldDefs: FieldDefinitions<CurrentFunctioning> = {
  appointmentId: stringField(),
  copingSkills: nonEmptyArrayField(),
  copingSkillsComments: stringField({ rules: [] }),
  currentStressors: nonEmptyArrayField(),
  stressorsComments: stringField({ rules: [] }),
  version: field<CurrentFunctioning_Version>({
    default: CurrentFunctioning_Version.undefined_version,
  }),
};

export function CurrentFunctioningForm(props: Props) {
  const { initialValue, appointmentId, disabled, savingNote } = props;

  const { fields, validate, getValue } = useForm<CurrentFunctioning>(
    fieldDefs,
    { ...initialValue, appointmentId } as CurrentFunctioning,
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: TherapyIntakeSectionName.CURRENT_FUNCTIONING,
        }),
    },
  );

  const onSubmit = async () => {
    if (await validate()) {
      props.onSubmit({
        data: getValue(),
        name: TherapyIntakeSectionName.CURRENT_FUNCTIONING,
      });
    }
  };

  return (
    <>
      <div className={styles.container}>
        <EnumCheckboxesField
          disabled={disabled}
          testId="currentStressors"
          label="Current Stressors*"
          options={Stressors}
          field={fields.currentStressors}
          tooltips={{
            [Stressors.identity_related]:
              'e.g.‚ sexual orientation; gender identity',
          }}
          keyLabels={{
            stressors_other: 'Other',
          }}
        />
        <EnumCheckboxesField
          disabled={disabled}
          testId="copingSkills"
          label="Coping skills/strengths*"
          options={CopingSkills}
          field={fields.copingSkills}
          tooltips={{
            [CopingSkills.attention_to_self_care]: 'e.g.‚ sleep‚ nutrition',
          }}
          keyLabels={{
            coping_skills_other: 'Other',
          }}
        />
      </div>
      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </>
  );
}

export const validate = (data: CurrentFunctioning | null) =>
  data !== null && validateForm(data, fieldDefs);
