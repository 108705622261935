import { calculateDelayInSeconds } from 'app/coach/chat/utils';
import { createActionHandlers } from 'redux-reloaded';

import { onEnterClick } from '../chat/actions';
import { Context } from '../context';
import { State } from '../schema';
import {
  nullifyCountdown,
  startTimedSend,
  updateCountDown,
  updateTimedSend,
} from './actions';

export const handlers = createActionHandlers<Context, State>();

handlers.on(startTimedSend, ({ action, redux }) => {
  const {
    channelId,
    messageText,
    currentMessages,
    logsContext,
  } = action.payload;
  const { dispatch, getState } = redux;
  const {
    chat: { typingStateMap },
    inbox: { channelIdMemberIdMap },
  } = getState();

  // calculate the delay based on the length of a message and messages history
  let delayInSeconds = calculateDelayInSeconds(
    messageText,
    currentMessages,
    Date.now(),
  );

  // schedule message sending based on the calculated delay
  const timeoutId = setTimeout(async () => {
    dispatch(
      onEnterClick({
        channelId,
        inputValue: messageText,
        logsContext,
        memberId: channelIdMemberIdMap[channelId],
        nextMessageId: typingStateMap[channelId].nextMessageId,
      }),
    );
    dispatch(nullifyCountdown({ channelId }));
  }, delayInSeconds * 1000);

  const makeTimeoutTicking = () => {
    if (delayInSeconds === 1) {
      dispatch(nullifyCountdown({ channelId }));
    } else {
      delayInSeconds -= 1;
      dispatch(updateCountDown({ channelId, newValue: delayInSeconds }));
    }
  };

  // set the interval to make the clocks ticking in the UI to display the delay
  const tickingInterval = setInterval(makeTimeoutTicking, 1000);

  // put the timeout and intervalId with additional data in the state
  dispatch(
    updateTimedSend({
      channelId,
      countDown: {
        intervalId: tickingInterval,
        seconds: delayInSeconds,
      },
      message: messageText,
      timeoutId,
    }),
  );
});

handlers.on(nullifyCountdown, ({ action, redux }) => {
  const { channelId } = action.payload;
  const { dispatch, getState } = redux;
  const {
    timedSends: { timedSends, countDownMap },
  } = getState();
  const timeoutId = timedSends[channelId]?.timeout;
  const tickingIntervalId = countDownMap[channelId]?.intervalId;
  // clear the interval for the clocks ticking and the timeout for the messages sending
  if (timeoutId) clearTimeout(timeoutId);
  if (tickingIntervalId) clearInterval(tickingIntervalId);

  // clean up the state
  dispatch(
    updateTimedSend({
      channelId,
      countDown: {
        intervalId: null,
        seconds: 0,
      },
      message: '',
      timeoutId: null,
    }),
  );
});
