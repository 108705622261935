import {
  InboxSection,
  InboxSectionHeaderColor,
} from 'app/inbox/components/InboxSection';
import { InboxSectionContentWithRedux } from 'app/inbox/components/InboxSectionContentWithRedux';
import { InboxSearchBox } from 'app/inbox/InboxSearchBox';
import { InboxSections, InboxTab } from 'app/inbox/types';
import { useState } from 'react';

import { DataStateProps } from './InboxView';

const AllTabSectionTypes = {
  [InboxSections.SCHEDULED]: {
    headerColor: InboxSectionHeaderColor.LIGHT_GREY,
    sectionTitle: 'Scheduled',
  },
  [InboxSections.PAST]: {
    headerColor: InboxSectionHeaderColor.LIGHT_GREY,
    sectionTitle: 'Past',
  },
  [InboxSections.CLOSED]: {
    headerColor: InboxSectionHeaderColor.LIGHT_GREY,
    sectionTitle: 'Closed',
  },
};

export const InboxAllTab = (
  props: Readonly<
    DataStateProps & { onSearch: (input: string) => Promise<void> }
  >,
) => {
  const [inputQuery, setInputQuery] = useState<string>('');

  const { loadMore, onItemClick, onSearch } = props;

  return (
    <>
      <InboxSearchBox
        searchDefaultValue={inputQuery}
        onSearch={(query) => {
          setInputQuery(query);
          return onSearch(query);
        }}
      />

      {Object.entries(AllTabSectionTypes).map(
        ([section, { headerColor, sectionTitle }]) => (
          <InboxSection
            headerColor={headerColor}
            sectionTitle={sectionTitle}
            section={section as InboxSections}
          >
            <InboxSectionContentWithRedux
              onItemClick={onItemClick}
              section={section as InboxSections}
              tab={InboxTab.ALL}
              loadMore={loadMore}
            />
          </InboxSection>
        ),
      )}
    </>
  );
};
