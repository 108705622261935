import { SortableColumn } from 'shared-components/table/SortableTableHead';

export const enum FilterKeys {
  UNREAD_STATUS = 'unreadStatus',
  APPOINTMENT_TYPE = 'appointmentType',
  APPOINTMENT_STATUS = 'appointmentStatus',
  NOTE_STATUS = 'noteStatus',
}

export const defaultColumns: SortableColumn[] = [
  {
    active: false,
    direction: 'asc',
    hideMenu: true,
    label: '',
    name: FilterKeys.UNREAD_STATUS,
    size: 'xs',
    sortBy: 'start',
    sortable: false,
  },
  {
    active: true,
    direction: 'asc',
    label: 'Type',
    name: FilterKeys.APPOINTMENT_TYPE,
    size: 'medium',
    sortBy: 'start',
    sortable: false,
  },
  {
    direction: 'asc',
    label: 'Appt Status',
    name: FilterKeys.APPOINTMENT_STATUS,
    size: 'small',
    sortable: false,
  },
  {
    direction: 'asc',
    label: 'Note Status',
    name: FilterKeys.NOTE_STATUS,
    size: 'small',
    sortable: false,
  },
];
