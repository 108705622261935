import { InboxSections } from 'app/inbox/types';

import { sortInboxSections, updateTabSection } from '../actions';
import { ActionHandler } from './types';

export const onMoveInboxItemBetweenSections = ({
  action,
  redux,
  context: { services },
}: ActionHandler<{
  memberId: string;
  from: InboxSections;
  to: InboxSections;
}>) => {
  const { tabSections } = redux.getState().inbox;
  const { memberId, from, to } = action.payload;

  const updatedTabSections = { ...tabSections };

  const index = Array.from(tabSections[from].ids).findIndex(
    (_) => _ === memberId,
  );

  if (index < 0) return;

  services.logger.debug(`Move member_id ${memberId} from ${from} to ${to}`, {
    tabSections,
  });
  const fromSection = tabSections[from];
  const toSection = tabSections[to];

  const { ids } = fromSection;
  const updatedIds = [...ids];
  updatedIds.splice(index, 1);

  updatedTabSections[from] = {
    ...fromSection,
    ids: new Set(updatedIds),
  };
  updatedTabSections[to] = {
    ...toSection,
    ids: new Set([memberId, ...toSection.ids]),
  };
  if (from !== to) {
    redux.dispatch(updateTabSection({ tabSections: updatedTabSections }));
  }

  redux.dispatch(sortInboxSections({ sections: [from, to] }));
};
