import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import {
  SubstanceUse,
  SubstanceUse_CurrentSubstanceUse,
  SubstanceUse_SubstanceType,
  SubstanceUse_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import {
  PresentingProblem,
  PresentingProblem_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/PresentingProblem';
import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { TherapyIntakeSectionName } from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';

import { TherapyIntakeNote } from '../api/TherapyIntakeNotesAPI';

export interface MemberIntakeSurvey {
  id: string;
  currentlyInPsychotherapy: string | null;
  currentMedications: string | null;
  reasonsForSeekingClinicalServices: string | null;
  substanceConsumption: string[] | null;
}

/**
 * Mapping of substance names from the intake survey to the corresponding substance type in the clinical note.
 * https://github.com/HeadspaceMeditation/web/blob/2f0b950c1792d56fc9ec1eea47aaa8461659acab/internal_apps/drchrono/inapp_intake_adapter.py#L407
 */
const SURVEY_SUBSTANCE_TO_NOTE_SUBSTANCE_MAP: Record<
  string,
  SubstanceUse_SubstanceType
> = {
  Alcohol: SubstanceUse_SubstanceType.etoh,
  Cocaine: SubstanceUse_SubstanceType.stimulants,
  Ecstasy: SubstanceUse_SubstanceType.stimulants,
  Hallucinogens: SubstanceUse_SubstanceType.hallucinogens,
  Heroin: SubstanceUse_SubstanceType.opioids,
  Marijuana: SubstanceUse_SubstanceType.cannabis,
  Methadone: SubstanceUse_SubstanceType.opioids,
  Methamphetamines: SubstanceUse_SubstanceType.stimulants,
  Painkillers: SubstanceUse_SubstanceType.opioids,
  'Tobacco products': SubstanceUse_SubstanceType.tobacco,
};

/**
 * Prefix to indicate that a field was prepopulated from the intake survey. This is to avoid confusion with user-entered
 */
const PREPOPULATE_PREFIX = 'From Intake Survey:';

export class TherapyIntakeNotePrepopulator {
  private readonly appointmentId: string;

  private readonly intakeSurvey: MemberIntakeSurvey;

  public static fromIntakeSurvey(
    appointmentId: string,
    initialNote: TherapyIntakeNote,
    intakeSurvey: MemberIntakeSurvey,
  ): TherapyIntakeNote {
    const prepopulator = new TherapyIntakeNotePrepopulator(
      appointmentId,
      intakeSurvey,
    );

    return {
      ...initialNote,
      metadata: {
        ...initialNote.metadata,
        status: Metadata_NoteStatus.draft_note,
      },
      [TherapyIntakeSectionName.ASSESSMENT]: null,
      [TherapyIntakeSectionName.BEHAVIORAL_OBSERVATION]: null,
      [TherapyIntakeSectionName.CLINICIAN_CHECKLIST]: null,
      [TherapyIntakeSectionName.COLLABORATION_PLAN]: null,
      [TherapyIntakeSectionName.CURRENT_FUNCTIONING]: null,
      [TherapyIntakeSectionName.MEDICAL_HEALTH]: null,
      [TherapyIntakeSectionName.PRESENTING_PROBLEM]: prepopulator.getPresentingProblemSection(),
      [TherapyIntakeSectionName.SAFETY]: null,
      [TherapyIntakeSectionName.SOCIAL_DEVELOPMENTAL]: null,
      [TherapyIntakeSectionName.SUBSTANCE_ABUSE]: prepopulator.getSubstanceAbuseSection(),
      [TherapyIntakeSectionName.TREATMENT_PLAN]: null,
      [TherapyIntakeSectionName.AMENDMENTS]: null,
    };
  }

  private constructor(appointmentId: string, intakeSurvey: MemberIntakeSurvey) {
    this.appointmentId = appointmentId;
    this.intakeSurvey = intakeSurvey;
  }

  private getPresentingProblemSection(): PresentingProblem | null {
    const canPrepopulate =
      this.intakeSurvey.currentlyInPsychotherapy ??
      this.intakeSurvey.currentMedications ??
      this.intakeSurvey.reasonsForSeekingClinicalServices;

    if (!canPrepopulate) {
      return null;
    }

    return {
      appointmentId: this.appointmentId,
      currentTreatmentDescription: '',
      historyOfPresentingProblem: this.getHistoryOfPresentingProblem(),
      impactOfSymptoms: [],
      pastMentalHealthTreatment: '',
      presentingProblem: this.getPresentingProblem(),
      receivingTreatmentBeyondClinician: this.getReceivingTreatmentBeyondClinician(),
      symptomsReported: '',
      version: PresentingProblem_Version.v0,
    };
  }

  private getSubstanceAbuseSection(): SubstanceUse | null {
    const canPrepopulate = this.intakeSurvey?.substanceConsumption?.length;

    if (!canPrepopulate) {
      return null;
    }

    return {
      anySubstanceUsed: this.getAnySubstanceUsed(),
      appointmentId: this.appointmentId,
      pastEtohOrBenzoWithdrawal: {
        description: '',
        isPresent: false,
      },
      pastSubstanceUseTreatment: {
        description: '',
        isPresent: false,
      },
      substancesCurrentlyUsed: this.getSubstancesCurrentlyUsed(),
      substancesPreviouslyUsed: [],
      version: SubstanceUse_Version.v0,
    };
  }

  /**
   * Prepends a prefix to the field to indicate that it was prepopulated from the intake survey. This is to avoid
   * confusion with user-entered data. Only used for free-text fields.
   */
  private prependPrefix = (field: string): string => {
    return `${PREPOPULATE_PREFIX} ${field}`;
  };

  private getPresentingProblem(): PresentingProblem['presentingProblem'] {
    if (!this.intakeSurvey.reasonsForSeekingClinicalServices) {
      return '';
    }

    return this.prependPrefix(
      this.intakeSurvey.reasonsForSeekingClinicalServices,
    );
  }

  private getReceivingTreatmentBeyondClinician(): PresentingProblem['receivingTreatmentBeyondClinician'] {
    switch (this.intakeSurvey.currentlyInPsychotherapy) {
      case 'Yes':
        return BooleanOption.yes;
      case 'No':
        return BooleanOption.no;
      default:
        return BooleanOption.undefined_choice;
    }
  }

  private getHistoryOfPresentingProblem(): PresentingProblem['historyOfPresentingProblem'] {
    if (!this.intakeSurvey.currentMedications) {
      return '';
    }

    return this.prependPrefix(this.intakeSurvey.currentMedications);
  }

  private getSubstancesCurrentlyUsed(): SubstanceUse['substancesCurrentlyUsed'] {
    const surveySubstancesUsed = this.intakeSurvey.substanceConsumption;

    if (!surveySubstancesUsed || surveySubstancesUsed.includes('None')) {
      return [];
    }

    return surveySubstancesUsed.map(
      (surveySubstance): SubstanceUse_CurrentSubstanceUse => {
        const enumeratedSubstance =
          SURVEY_SUBSTANCE_TO_NOTE_SUBSTANCE_MAP[surveySubstance];

        return {
          substance: {
            currentlyUsed: true,
            description: '',
            frequency: undefined,
            otherSubstanceTypeDescription: enumeratedSubstance
              ? ''
              : surveySubstance,
            pastUsed: false,
            startedUse: undefined,
            stoppedUse: undefined,
            substanceType:
              enumeratedSubstance ?? SubstanceUse_SubstanceType.Other,
          },
        };
      },
    );
  }

  private getAnySubstanceUsed(): SubstanceUse['anySubstanceUsed'] {
    const { substanceConsumption } = this.intakeSurvey;

    if (!substanceConsumption || substanceConsumption.includes('None')) {
      return BooleanOption.no;
    }

    return substanceConsumption.length
      ? BooleanOption.yes
      : BooleanOption.undefined_choice;
  }
}
