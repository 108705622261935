export const Labels = {
  affect: {
    id: 'affectWithinLimit',
    label: 'Affect',
    other: 'Other',
    withinLimitLabel:
      'Appropriate range, character, and congruent to stated mood',
  },
  appearance: {
    id: 'appearanceWithinLimit',
    label: 'Appearance',
    other: 'Other',
    withinLimitLabel: 'Within normal limits, engaged with interview',
  },
  cognitive: {
    id: 'cognitiveWithinLimit',
    label: 'Cognitive',
    other: 'Other',
    withinLimitLabel:
      'No gross impairment of attention, intact memory (recent & remote), no language deficits, normal fund of knowledge',
  },
  insight: {
    id: 'insightWithinLimit',
    label: 'Insight',
    other: 'Other',
    withinLimitLabel: 'Intact',
  },
  judgement: {
    id: 'judgmentWithinLimit',
    label: 'Judgment',
    other: 'Other',
    withinLimitLabel: 'Intact',
  },
  orientation: {
    id: 'orientationWithinLimit',
    label: 'Orientation',
    other: 'Other',
    withinLimitLabel: 'Oriented x4',
  },
  speech: {
    id: 'speechWithinLimit',
    label: 'Speech',
    other: 'Other',
    withinLimitLabel: 'Normal rate, rhythm, and volume',
  },
  thoughtContent: {
    id: 'thoughtContentWithinLimit',
    label: 'Thought content',
    other: 'Other',
    withinLimitLabel:
      'Unremarkable, consistent with conversation on their care',
  },
  thoughtProcess: {
    id: 'thoughtProcessWithinLimit',
    label: 'Thought Process',
    other: 'Other',
    withinLimitLabel: 'Linear and logical',
  },
};
