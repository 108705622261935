import { ILogger } from 'app/state/log/Logger';
import Pubnub, {
  HereNowParameters,
  HereNowResponse,
  ListenerParameters,
  MessageCountsParameters,
  MessageCountsResponse,
  SetStateParameters,
  SetStateResponse,
} from 'pubnub';

import {
  InstantiatedPubnubstrategy,
  NotInstantiatedPubnubStrategy,
} from './strategies';
import {
  GetHistory,
  PublishMessage,
  PUBNUB_STATUS,
  PubnubInitConfig,
  SubscribeToChannels,
} from './types';

export interface IPubnubStrategy {
  publishMessage: PublishMessage;
  /** Subscribe to channels
   * @param params.channels: string[] - array of channel ids to subscribe to.
   * Please note that if you have already subscribed to a channel, calling this method will not
   * duplicate channels as the strategy will filter out channels that are already subscribed to.
   *
   * @param params.withPresence: boolean = false.
   * If true, will subscribe to the presence channel for each channel in the channels array instead of the
   * channel itself, `-pnpres` will be appended to each channel id.
   * Broadcast channels may not have grants for presence channels, so if you are using broadcast channels,
   * you probably want to use the default value of false.
   */
  subscribeToChannels: SubscribeToChannels;
  subscribeToChannelGroups: SubscribeToChannels;
  unsubscribeChannels: (channels: string[]) => void;
  unsubscribeFromChannelGroups: (channelGroups: string[]) => void;
  getHistory: GetHistory;
  unsubscribeAll: () => void;
  addListener: (params: ListenerParameters) => void;
  removeListener: (params: ListenerParameters) => void;
  setPubnubState: (params: SetStateParameters) => Promise<SetStateResponse>;
  hereNow: (params: HereNowParameters) => Promise<HereNowResponse>;
  getNumberOfUnreadMessages: (
    params: MessageCountsParameters,
  ) => Promise<MessageCountsResponse>;
  retryChannelSubscription: (envelope: any) => void;
  getSubscribedChannels: () => Set<string>;
}

export interface IPubnubAPIService extends IPubnubStrategy {
  getUUID: () => string | null;
  initialize: (
    params: PubnubInitConfig,
    logger: ILogger,
  ) =>
    | { status: PUBNUB_STATUS.ERROR; error: Error }
    | { status: PUBNUB_STATUS.INSTANTIATED; error: null };
  initializeMockPunub: (mockPubnub: Pubnub) => Pubnub;
}

export class PubnubAPIService implements IPubnubAPIService {
  private uuid: string | null = null;

  pubnub: Pubnub | null;

  strategy: IPubnubStrategy;

  status: PUBNUB_STATUS;

  constructor(private logger: ILogger) {
    this.pubnub = null;
    this.strategy = new NotInstantiatedPubnubStrategy();
    this.status = PUBNUB_STATUS.NOT_INSTANTIATED;
  }

  retryChannelSubscription(envelope: any) {
    this.strategy.retryChannelSubscription(envelope);
  }

  getUUID(): string | null {
    return this.uuid;
  }

  initialize(
    params: PubnubInitConfig,
  ):
    | { status: PUBNUB_STATUS.ERROR; error: Error }
    | { status: PUBNUB_STATUS.INSTANTIATED; error: null } {
    const {
      subscribeKey,
      pubnubAuthKey,
      listenerUuid,
      publishKey,
      logVerbosity = false,
    } = params;
    try {
      this.pubnub = new Pubnub({
        authKey: pubnubAuthKey,
        heartbeatInterval: 30,
        logVerbosity,
        presenceTimeout: 60 * 30,
        publishKey,
        restore: true,
        ssl: true,
        subscribeKey,
        uuid: listenerUuid,
      });
      this.strategy = new InstantiatedPubnubstrategy(
        this.pubnub,
        this.logger,
        listenerUuid,
      );
      this.status = PUBNUB_STATUS.INSTANTIATED;
      this.uuid = listenerUuid;
      return { error: null, status: this.status };
    } catch (e) {
      // add retry logic in the future
      this.status = PUBNUB_STATUS.ERROR;
      return { error: e, status: this.status };
    }
  }

  initializeMockPunub(mockPubnub: Pubnub): Pubnub {
    this.pubnub = mockPubnub;
    this.status = PUBNUB_STATUS.INSTANTIATED;
    this.strategy = new InstantiatedPubnubstrategy(
      this.pubnub,
      this.logger,
      'mock-uuid',
    );
    return this.pubnub;
  }

  publishMessage: PublishMessage = (params) => {
    return this.strategy.publishMessage({
      ...params,
    });
  };

  subscribeToChannels: SubscribeToChannels = (channels) => {
    return this.strategy.subscribeToChannels(channels);
  };

  subscribeToChannelGroups: SubscribeToChannels = (channelGroups) => {
    return this.strategy.subscribeToChannelGroups(channelGroups);
  };

  unsubscribeChannels: (channels: string[]) => void = (channels) => {
    return this.strategy.unsubscribeChannels(channels);
  };

  unsubscribeFromChannelGroups: (channelGroups: string[]) => void = (
    channelGroups,
  ) => {
    return this.strategy.unsubscribeFromChannelGroups(channelGroups);
  };

  addListener = (params: ListenerParameters) => {
    return this.strategy.addListener(params);
  };

  removeListener = (params: ListenerParameters) => {
    return this.strategy.removeListener(params);
  };

  getHistory: GetHistory = ({ channelIds, start }) => {
    return this.strategy.getHistory({ channelIds, start });
  };

  unsubscribeAll = () => {
    return this.strategy.unsubscribeAll();
  };

  setPubnubState = (params: SetStateParameters) => {
    return this.strategy.setPubnubState(params);
  };

  hereNow = (params: HereNowParameters) => {
    return this.strategy.hereNow(params);
  };

  getNumberOfUnreadMessages = (
    params: MessageCountsParameters,
  ): Promise<MessageCountsResponse> => {
    return this.strategy.getNumberOfUnreadMessages(params);
  };

  getSubscribedChannels = (): Set<string> => {
    return this.strategy.getSubscribedChannels();
  };
}
