import {
  ChannelIdMemberIdMap,
  ConversationStateMap,
  UnreadMessagesMap,
} from 'app/coach/chat/types';
import { MessagesMap } from 'app/coach/pubnub/types';
import { InboxItemState, InboxSections, InboxTab } from 'app/inbox/types';

export type InboxItems = Record<string, InboxItemState>;

export type ConvoPresence = {
  isOnline: boolean;
  isTyping: boolean;
  timerId: number | null;
};

export type State = {
  inboxItems: InboxItems;
  // an id of a currently opened chat used by the Chat to retrieve messages for a proper channel
  currentChannelId: string;
  showInboxDrawer: boolean;
  tabSections: Record<
    InboxSections,
    { ids: Set<string>; hasMore?: boolean; cursor: string | null }
  >;
  // to-do: remove set from redux state, as thi is a non-serializable obj in js, redux doesn't digest it - https://redux.js.org/style-guide/#do-not-put-non-serializable-values-in-state-or-actions
  search: Record<
    InboxSections.CLOSED | InboxSections.PAST | InboxSections.SCHEDULED,
    { ids: Set<string>; hasMore?: boolean; cursor: string | null } | undefined
  >;
  // the list of all the pubnub channels we have subscribed to.
  presenceByChannelId: Record<string, ConvoPresence>;
  todaysMemberCount: number;
  selectedTab: InboxTab;
  messagesMap: MessagesMap;
  pollingID: number | null;
  conversationStateMap: ConversationStateMap;
  channelIdMemberIdMap: ChannelIdMemberIdMap;
  unreadMessagesMap: UnreadMessagesMap;
  isInboxRefreshing: boolean;
};

export const getInitialInboxState = (): State => {
  const initialTabSection = {
    cursor: null,
    hasMore: false,
    ids: new Set(''),
  };
  return {
    conversationStateMap: {},
    currentChannelId: '',
    channelIdMemberIdMap: {},
    inboxItems: {},
    isInboxRefreshing: false,
    messagesMap: {},
    pollingID: null,
    presenceByChannelId: {},
    search: {
      [InboxSections.SCHEDULED]: undefined,
      [InboxSections.CLOSED]: undefined,
      [InboxSections.PAST]: undefined,
    },
    selectedTab: InboxTab.TODAYS,
    showInboxDrawer: false,
    tabSections: {
      [InboxSections.RISKS]: initialTabSection,
      [InboxSections.TASKS]: initialTabSection,
      [InboxSections.COMPLETED]: initialTabSection,
      [InboxSections.SCHEDULED]: initialTabSection,
      [InboxSections.CLOSED]: initialTabSection,
      [InboxSections.PAST]: initialTabSection,
      [InboxSections.SCHEDULED_CHECKINS]: initialTabSection,
      [InboxSections.CONVERSATIONS_AND_TASKS]: initialTabSection,
      [InboxSections.CONVERSATIONS]: initialTabSection,
      [InboxSections.APPOINTMENTS]: initialTabSection,
    },
    todaysMemberCount: 0,
    unreadMessagesMap: {},
  };
};
