import { CardField } from 'app/member-chart-cards/card-field/CardField';
import styles from 'app/patients/tabs/intake/IntakeTab.module.scss';
import React from 'react';
import { Card } from 'shared-components/Card';
import {
  CardColumnGaps,
  CardGrid,
  CardRowGaps,
  NumberOfCardColumns,
} from 'shared-components/grid-layout/CardGrid.module';
import { GridCell } from 'shared-components/grid-layout/GridCell';

type Props = {
  testId?: string;
  title: string;
  terms: { [key: string]: string | number | null };
};

export function IntakeCard({ testId, terms, title }: Readonly<Props>) {
  return (
    <Card data-testid={testId} boxTitle={title}>
      <div className={styles.cardSection}>
        <CardGrid
          rowGap={CardRowGaps.STANDARD}
          columnGap={CardColumnGaps.STANDARD}
          numberOfColumns={NumberOfCardColumns.ONE}
        >
          {Object.entries(terms).map(([term, definition]) => {
            return (
              <GridCell key={term}>
                <CardField
                  field={{
                    label: term,
                    labelForAnalytics: '',
                    value: definition ?? 'N/A',
                  }}
                  hideTooltip={true}
                />
              </GridCell>
            );
          })}
        </CardGrid>
      </div>
    </Card>
  );
}
