import React from 'react';
import { Button } from 'shared-components/button/Button';
import { classNameCombiner } from 'utils';

import styles from './AddSICoachModalButton.module.scss';

export interface AddSICoachModalButtonProps {
  children: any;
  primary: boolean;
  onClick: () => void;
  testId?: string;
}

export const AddSICoachModalButton = ({
  children,
  primary,
  onClick,
  testId,
}: AddSICoachModalButtonProps) => {
  return (
    <Button
      testId={testId}
      className={classNameCombiner([
        styles.addSICoachModalButton,
        !primary ? styles.light : '',
      ])}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
