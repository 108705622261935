import {
  GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions,
  GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions_recurrence as Recurrence,
  GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions_sessions as CoachingSession,
} from '@headspace/carehub-graphql/dist/scheduler/generated/GetMemberUpcomingCoachingSessions';
import { ReactComponent as CaretLeftArrow } from 'assets/arrows/caretLeft.svg';
import { ReactComponent as CaretLRightArrow } from 'assets/arrows/caretRight.svg';
import React, { useState } from 'react';

import styles from './MultiScheduledSession.module.scss';
import SingleScheduledSession from './SingleScheduledSession';

interface Props {
  memberTimezone: string | undefined;
  multiSession: GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions;
  onClickCoachingSession: (
    coachingSession: CoachingSession,
    recurrence: Recurrence | null,
  ) => void;
}

const MultiScheduledSession = ({
  multiSession,
  onClickCoachingSession,
  memberTimezone,
}: Props) => {
  const [selectedSessionIndex, setSelectedSessionIndex] = useState(0);
  const onNextSessionClick = () => {
    if (selectedSessionIndex < multiSession.sessions.length - 1) {
      setSelectedSessionIndex(selectedSessionIndex + 1);
    }
  };

  const onPreviousSessionClick = () => {
    if (selectedSessionIndex > 0) {
      setSelectedSessionIndex(selectedSessionIndex - 1);
    }
  };

  const selectedSession = multiSession.sessions[selectedSessionIndex];
  // show last recurring badge when the selected session is the last one (add one for indexing)
  const showLastBadge =
    multiSession.sessions.length === selectedSessionIndex + 1;

  /* Query only gets future sessions, we know how many occurences we should have and we should show index starting  */
  const numOccurrences = multiSession.recurrence?.numOccurrences || 1;
  const previousSessions = multiSession.sessions.length - numOccurrences;
  const sessionIndexDisplay = selectedSessionIndex - previousSessions + 1;

  return (
    <div>
      <SingleScheduledSession
        memberTimezone={memberTimezone}
        session={selectedSession}
        recurrence={multiSession.recurrence}
        showLastRecurringSessionBadge={showLastBadge}
        onClickCoachingSession={onClickCoachingSession}
      />
      <div className={styles.controls}>
        <div className={styles.textContainer}>
          <span
            className={styles.text}
            data-testid="sessionIndex"
            data-session-index-display={sessionIndexDisplay}
          >
            Session <b>{sessionIndexDisplay}</b> out of{' '}
            {multiSession.recurrence?.numOccurrences}
          </span>
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.leftButton}>
            <button
              disabled={selectedSessionIndex <= 0}
              data-testid="prevButton"
              type="button"
              onClick={onPreviousSessionClick}
              className={styles.button_circular}
            >
              <CaretLeftArrow width={16} height={16} />
            </button>
          </div>
          <div>
            <button
              disabled={showLastBadge}
              data-testid="nextButton"
              type="button"
              onClick={onNextSessionClick}
              className={styles.button_circular}
            >
              <CaretLRightArrow width={16} height={16} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiScheduledSession;
