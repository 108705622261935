import { getVaultWebUserId } from '@ginger.io/vault-core/dist/IdHelpers';
import { CommunicationContainer } from 'app/collaboration/v2/CommunicationContainer';
import { Card } from 'shared-components/Card';
import Titles from 'app/member-chart-cards/constants/cards-titles';
import { useAppState } from 'app/state';
import { selectUserState } from 'app/state/care-team/selectors';
import React, { useEffect } from 'react';

import { useTeamCommunication } from '../hooks/useTeamCommunication';

interface Props {
  memberId: string;
}

export const CollaborationCard = (props: Props) => {
  const { memberId } = props;
  const { userId, firstName, lastName, authorType } = useAppState(
    selectUserState,
  );
  const {
    showTeamNotification,
    updateUserMetadata,
    updateMetadataInput,
    createMetadataInput,
    createUserMetadata,
  } = useTeamCommunication(memberId);

  useEffect(() => {
    if (updateMetadataInput && updateUserMetadata) {
      void updateUserMetadata(updateMetadataInput);
    }
    if (createMetadataInput && createUserMetadata) {
      void createUserMetadata(createMetadataInput);
    }
  }, [showTeamNotification]);

  return (
    <Card
      data-testid="collaborations"
      size="large"
      boxTitle={Titles.TEAM_COMMUNICATION_TITLE}
      toggleInitialState={false}
    >
      <CommunicationContainer
        memberId={memberId}
        author={{
          firstName: firstName!,
          id: getVaultWebUserId(userId!),
          lastName: lastName!,
          type: authorType,
        }}
      />
    </Card>
  );
};
