import gql from 'graphql-tag';

export const DemographicsFragment = gql`
  fragment DemographicsFragment on User {
    id
    firstName
    lastName
    billingFirstName
    billingLastName
    gender
    email
    phone
    dateOfBirth
    preferredPharmacy
    carrierChoices {
      carrierId
      carrierName
    }

    insurance {
      idNumber
      company
      carrierId
    }

    address {
      street
      city
      zip
      state
    }

    emergencyContact {
      name
      phone
      relationship
    }

    preferences {
      timezone
    }

    dosespotId
  }
`;

export const searchMemberQuery = gql`
  query SearchMember($q: String!) {
    searchMember(q: $q) {
      id
      email
      firstName
      lastName
      billingFirstName
      billingLastName
    }
  }
`;
export const getMemberQuery = gql`
  query GetMember($id: ID!) {
    getMember(input: { id: $id }) {
      ...DemographicsFragment
    }
  }
  ${DemographicsFragment}
`;

export const updateMemberDemographicsMutationV2 = gql`
  mutation UpdateMemberDemographics(
    $demographics: DemographicsInput!
    $patientId: ID!
  ) {
    updateMemberDemographicsV2(
      demographics: $demographics
      patientId: $patientId
    ) {
      result {
        user {
          ...DemographicsFragment
        }
      }
      errors {
        ... on GenericError {
          __typename
          errorCode
          path
        }
      }
    }
  }
  ${DemographicsFragment}
`;

export const updateInsuranceMutation = gql`
  mutation UpdateInsurance($insuranceProvider: InsuranceProviderInput!) {
    updateInsurance(insuranceProvider: $insuranceProvider) {
      user {
        ...DemographicsFragment
      }
      error
    }
  }
  ${DemographicsFragment}
`;
