import React from 'react';

import styles from './HeaderWithDot.module.scss';

type Props = {
  name: string;
  active?: boolean;
  children?: any;
};

export function HeaderWithDot(props: Props) {
  const classes = [styles.dot];
  if (props.active) {
    classes.push(styles.active);
  }

  return (
    <div className={styles.headerWithDot}>
      <h4 className={styles.header}>{props.name}</h4>
      <div className={classes.join(' ')}>{props.children}</div>
    </div>
  );
}
