import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';

interface DebouncedFunc<T extends (...args: any[]) => void> {
  (...args: Parameters<T>): any;
}

export function useDebounce<T extends (...args: any) => void>(
  callback: T,
  delay = 1000,
): DebouncedFunc<T> {
  const [triggerCallback, setShouldTriggerCallback] = useState(false);
  const isMounted = useRef<boolean>(true);
  const [args, setArgs] = useState<any>();

  // Prevent callback after unmounted
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current && triggerCallback) {
      setShouldTriggerCallback(false);
      callback(...args);
    }
  }, [triggerCallback, callback]);

  const [returnCallback] = useState(() =>
    debounce((...args: Parameters<T>) => {
      setArgs(args);
      setShouldTriggerCallback(true);
    }, delay),
  );

  return returnCallback;
}
