import {
  CareTeamV2,
  CareTeamV2Props,
} from 'app/care-team/care-team-v2/CareTeamV2';
import { MemberChartTab } from 'app/coach/member-chart/MemberChartTab';
import { CollaborationCard } from 'app/collaboration/v2/CollaborationCard';
import { careTeamClicked } from 'app/state/amplitude/actions/careTeam';
import { loadCareTeam } from 'app/state/care-team/actions';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export type TeamProps = CareTeamV2Props;

export const Team = (props: TeamProps) => {
  const dispatch = useDispatch();
  const { 'data-testid': testId, columns = 2, memberId } = props;

  // load care team data from graphql api -- see action handler for more details
  useEffect(() => {
    dispatch(
      loadCareTeam({
        forceRefresh: false,
        memberId,
      }),
    );
  }, [dispatch, memberId]);

  useEffect(() => {
    dispatch(
      careTeamClicked({
        memberId,
      }),
    );
  }, [dispatch, memberId]);

  return (
    // use the MemberChatTab (which is a HOC) if your tab is a collection of cards that needs the Masonry
    <MemberChartTab>
      <CareTeamV2 data-testid={testId} columns={columns} memberId={memberId} />
      <CollaborationCard memberId={memberId} />
    </MemberChartTab>
  );
};
