import { Checkbox } from 'app/notes-ui/forms/form-controls/Checkbox';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import React, { useState } from 'react';
import { FormError } from 'shared-components/FormError';

import styles from './PsychiatryHistoryTable.module.scss';
import { Props as TableProps, TableV2 } from './TableV2';

export type OnChangeHandler<T> = (input: {
  items: T[];
  isValid: boolean;
}) => void;

type Props<T> = {
  label: string;
  noDataCheckboxLabel: string;
  onChange: OnChangeHandler<T>;
  error?: string;
  testId?: string;
  addButtonLabel: string;
} & Omit<TableProps<T>, 'onChange'>;

export function PsychiatricHistoryTableV2<T>(props: Props<T>) {
  const {
    columns: columnDefs,
    fieldDefs,
    initialValue = [],
    label,
    getBlankRecord,
    noDataCheckboxLabel,
    addButtonLabel,
  } = props;
  const [hasNoRecord, setHasNoRecord] = useState(
    props.initialValue !== undefined && initialValue.length === 0,
  );

  return (
    <div data-testid={props.testId} className={styles.root}>
      <NoteFormControlLabel data-testid="title" label={label}>
        <Checkbox
          disabled={props.disabled}
          label={noDataCheckboxLabel}
          checked={hasNoRecord}
          onChange={(checked) => {
            setHasNoRecord(checked);
            props.onChange({
              isValid: checked || initialValue.length > 0,
              items: checked ? [] : initialValue,
            });
          }}
        />
        <br />
        <TableV2
          initialValue={initialValue}
          disabled={props.disabled || hasNoRecord}
          fieldDefs={fieldDefs}
          onChange={(records, isValid) =>
            props.onChange({
              isValid: !hasNoRecord && records.length > 0 && isValid,
              items: records,
            })
          }
          columns={columnDefs}
          getBlankRecord={getBlankRecord}
          renderEmptyRow={hasNoRecord}
          addButtonLabel={addButtonLabel}
        />
        <FormError error={props.error} />
      </NoteFormControlLabel>
    </div>
  );
}
