import {
  field,
  FieldDefinitions,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import { FamilyHistory } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/FamilyHistory';
import {
  FamilyHistorySection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { TextAreaField } from 'app/notes-ui/forms/fields';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { AUTO_SAVE_DRAFT_DELAY } from 'app/notes-ui/utils';
import React from 'react';

type Props = {
  appointmentId: string;
  onSubmit: (data: FamilyHistorySection) => void;
  updateDraftNoteState: (data: FamilyHistorySection) => void;
  disabled?: boolean;
  savingNote?: boolean;
  initialValue?: FamilyHistory;
};

export const fieldDefs: FieldDefinitions<FamilyHistory> = {
  appointmentId: field(),
  comments: stringField(),
};

export function FamilyHistoryForm(props: Props) {
  const { appointmentId, disabled, savingNote, initialValue } = props;
  const { fields, validate, getValue } = useForm<FamilyHistory>(
    fieldDefs,
    FamilyHistory.fromPartial({ ...initialValue, appointmentId }),
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: PsychiatrySectionName.FAMILY_HISTORY,
        }),
    },
  );

  const onSubmit = async () => {
    const isValid = await validate();
    if (isValid) {
      props.onSubmit({
        data: getValue(),
        name: PsychiatrySectionName.FAMILY_HISTORY,
      });
    }
  };
  return (
    <div>
      <TextAreaField
        testId="comments"
        label="Comments*"
        field={fields.comments}
      />
      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </div>
  );
}

export const validate = (data: FamilyHistory | null) =>
  data !== null && validateForm(data, fieldDefs);
