import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import {
  CollaborationPlan_Goal,
  CollaborationPlan_GoalType,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/CollaborationPlan';
import { Frequency_Period } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/Frequency';
import { SafetyPlanStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/safety/SafetyPlan';
import { SuicidalIdeation } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/safety/SuicidalIdeation';
import { SafetyConcern } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyConcern';
import {
  SafetyIntake,
  Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyIntake';
import { Safety } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyProgress';
import {
  SubstanceUse_CurrentSubstanceUse,
  SubstanceUse_PastSubstanceUse,
  SubstanceUse_SubstanceType,
  SubstanceUse_SubstanceUseLineItem,
  SubstanceUse_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import {
  TreatmentGoal,
  TreatmentGoal_GoalStatus,
  TreatmentGoal_GoalType,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/TreatmentGoal';
import { PsychiatrySectionName } from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { TherapyIntakeSectionName } from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import { TherapyProgressSectionName } from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';
import { DomesticViolenceRisk } from '@ginger.io/vault-clinical-notes/src/generated/protobuf-schemas/vault-clinical-notes/shared/safety/DomesticViolenceRisk';
import { EatingDisorderRisk } from '@ginger.io/vault-clinical-notes/src/generated/protobuf-schemas/vault-clinical-notes/shared/safety/EatingDisorderRisk';
import { HighSubstanceUse } from '@ginger.io/vault-clinical-notes/src/generated/protobuf-schemas/vault-clinical-notes/shared/safety/HighSubstanceUse';
import { HomicidalIdeation } from '@ginger.io/vault-clinical-notes/src/generated/protobuf-schemas/vault-clinical-notes/shared/safety/HomicidalIdeation';
import { OtherCorcerns } from '@ginger.io/vault-clinical-notes/src/generated/protobuf-schemas/vault-clinical-notes/shared/safety/OtherCorcerns';
import { SafetyPlan } from '@ginger.io/vault-clinical-notes/src/generated/protobuf-schemas/vault-clinical-notes/shared/safety/SafetyPlan';
import { SelfInjury } from '@ginger.io/vault-clinical-notes/src/generated/protobuf-schemas/vault-clinical-notes/shared/safety/SelfInjury';
import { VulnerablePopulationsAbuse } from '@ginger.io/vault-clinical-notes/src/generated/protobuf-schemas/vault-clinical-notes/shared/safety/VulnerablePopulationsAbuse';
import goalLabelsGoingWell from '@ginger.io/vault-clinical-notes/src/i18n/vault-clinical-notes/collaborationPlan/goalLabelsGoingWell/en_US.json';
import goalLabelsWorkingOn from '@ginger.io/vault-clinical-notes/src/i18n/vault-clinical-notes/collaborationPlan/goalLabelsWorkingOn/en_US.json';
import Labels from 'app/notes-ui/strings/en.json';
import { ILogger } from 'app/state/log/Logger';
import {
  labelFromEnumValue,
  protobufDateToString,
  toFrequencyDescription,
} from 'utils/notes';
import { PsychiatryIntakeNote } from 'app/vault/api/PsychiatryIntakeNotesAPI';
import { PsychiatryProgressNote } from 'app/vault/api/PsychiatryProgressNotesAPI';
import { TherapyIntakeNote } from 'app/vault/api/TherapyIntakeNotesAPI';
import { TherapyProgressNote } from 'app/vault/api/TherapyProgressNotesAPI';
import { getItemCreatedEvent } from 'app/vault/api/utils';

import {
  loadSafetyConcernComponents,
  SafetyConcernMap,
} from '../shared/safety';
import { FULL_SAFETY_PLAN_FIELDS_CONCERNS } from '../shared/safety-progress/constants';
import { EnumOption } from '../types';
import { NotePDFBuilder, NotePDFBuilderImpl } from './NotePDFBuilder';
import { Answer, NoteHeader } from './types';

export type ClinicalNotes =
  | PsychiatryProgressNote
  | PsychiatryIntakeNote
  | TherapyIntakeNote
  | TherapyProgressNote;

export abstract class ClinicalNotePDF<T extends ClinicalNotes> {
  protected constructor(
    protected note: T,
    protected noteHeader: NoteHeader,
    protected timezone: string,
    protected diagnosisCodesOptions: EnumOption[],
    protected logger: ILogger,
    protected pdfBuilder: NotePDFBuilder = new NotePDFBuilderImpl(), // eslint-disable-next-line no-empty-function
  ) {}

  abstract getPDF(): typeof PDFDocument;

  protected header() {
    this.pdfBuilder.noteHeader(this.noteHeader);
  }

  protected substanceAbuse() {
    const sectionLabel = PsychiatrySectionName.SUBSTANCE_ABUSE;

    this.pdfBuilder.section(
      sectionLabel,
      () => {
        const substanceAbuseSection = this.note.SUBSTANCE_ABUSE;

        if (!substanceAbuseSection) return false;

        const isV0 =
          substanceAbuseSection.version === SubstanceUse_Version.v0 ||
          substanceAbuseSection.anySubstanceUsed === BooleanOption.yes ||
          substanceAbuseSection.anySubstanceUsed === BooleanOption.no;

        if (isV0) {
          substanceAbuseSection.substancesCurrentlyUsed.forEach((s) => {
            if (s?.substance?.currentlyUsed || s?.substance?.pastUsed) {
              this.pdfBuilder.subsection(
                labelFromEnumValue(
                  SubstanceUse_SubstanceType,
                  s.substance?.substanceType,
                ) ?? 'Unknown substance',
                () => {
                  if (
                    s.substance?.substanceType ===
                    SubstanceUse_SubstanceType.Other
                  ) {
                    this.pdfBuilder
                      .longTextAnswer(
                        Labels.SUBSTANCE_TYPE_OTHER_DESCRIPTION,
                        s.substance?.otherSubstanceTypeDescription,
                      )
                      .padding();
                  }

                  this.pdfBuilder
                    .yesOrNoAnswer(
                      Labels.CURRENTLY_USED,
                      s.substance?.currentlyUsed,
                    )
                    .padding()
                    .yesOrNoAnswer(Labels.PAST_USED, s.substance?.pastUsed)
                    .padding()
                    .longTextAnswer(
                      Labels.SUBSTANCE_USE_DURATION,
                      s.substance?.description,
                    )
                    .padding();
                },
              );
            }
          });

          this.pdfBuilder
            .yesOrNoWithCommentAnswer(
              Labels.SUBSTANCE_ABUSE_PAST_ETOH_OR_BENZO,
              substanceAbuseSection.pastEtohOrBenzoWithdrawal,
              'N/A',
            )
            .padding()
            .yesOrNoWithCommentAnswer(
              Labels.SUBSTANCE_ABUSE_PAST_SUBSTANCE_USE_TREATMENT,
              substanceAbuseSection.pastSubstanceUseTreatment,
              'N/A',
            )
            .padding();
        } else {
          this.substanceUseSection(
            Labels.CURRENTLY_USED,
            substanceAbuseSection.substancesCurrentlyUsed,
            'No substances currently used',
          );
          this.pdfBuilder.padding();

          this.substanceUseSection(
            Labels.PREVIOUSLY_USED,
            substanceAbuseSection.substancesPreviouslyUsed,
            'No substances previously used',
          );
        }
        return true;
      },
      'No substance abuse data',
    );
  }

  protected collaborationPlan() {
    const plan = this.note.COLLABORATION_PLAN;
    const isOriginalVersion =
      plan?.goal !== undefined &&
      (this.note.TREATMENT_PLAN?.goal || []).length === 0;

    if (isOriginalVersion) {
      this.pdfBuilder.section(
        TherapyIntakeSectionName.COLLABORATION_PLAN,
        () => {
          // filter out goals that aren't filled out
          const goals =
            plan?.goal.filter(
              (_) =>
                _.goalType !== CollaborationPlan_GoalType.undefined_goal_type,
            ) ?? [];
          if (goals.length > 0) {
            goals.forEach((goal) => {
              // Nothing in the goal indicates whether this is a "What is the member working on?" or a
              // "What is going well for the member?" type of goal. The goal type determines the goal label set to use.
              // The best we can do is check for some heuristics:
              //   - The goal label is typically correctly set to Labels.WHATS_GOING_WELL_FOR_MEMBER
              //     for goal type "What is going well for the member?" and set to Labels.WHAT_MEMBER_IS_WORKING_ON
              //     for goal type "What is the member working on?"
              if (goal.label === Labels.WHATS_GOING_WELL_FOR_MEMBER) {
                this.goal(goal, goalLabelsGoingWell);
              } else {
                this.goal(goal, goalLabelsWorkingOn);
              }
            });
            return true;
          } else return false;
        },
        'No collaboration/Goals',
      );
    }
  }

  protected safetyIntake() {
    this.pdfBuilder.section(
      TherapyIntakeSectionName.SAFETY,
      () => {
        const safety = this.note.SAFETY as SafetyIntake;
        if (!safety) return false;
        const isNewVersion =
          safety.version === Version.v0 ||
          safety.version === Version.v1 ||
          safety.anyChangesOrRisks === BooleanOption.no ||
          safety.anyChangesOrRisks === BooleanOption.yes;

        if (isNewVersion) {
          this.safetySectionV2(safety);
        } else {
          this.safetyIntakeSection(safety);
        }
        return true;
      },
      'No safety data reported',
    );
  }

  private safetyIntakeSection(safety: SafetyIntake) {
    // Current SI
    this.pdfBuilder
      .endorsedOrDeniedAnswersGroup(
        Labels.CURRENT_SI,
        [
          {
            label: Labels.THOUGHTS,
            value: safety.currentSuicidalIdeation?.thoughts,
          },
          {
            label: Labels.INTENT,
            value: safety.currentSuicidalIdeation?.intent,
          },
          {
            label: Labels.PLAN,
            value: safety.currentSuicidalIdeation?.plan,
          },
          {
            label: Labels.MEANS,
            value: safety.currentSuicidalIdeation?.means,
          },
        ],
        'No current SI',
      )
      .padding()
      .longTextAnswer(
        Labels.EXPLAIN_ENDORSED_ANSWERS,
        safety.currentSuicidalIdeation?.description,
      )
      .padding()

      // Current HI
      .endorsedOrDeniedAnswersGroup(
        Labels.CURRENT_HI,
        [
          {
            label: Labels.THOUGHTS,
            value: safety.currentHomicidalIdeation?.thoughts,
          },
          {
            label: Labels.INTENT,
            value: safety.currentHomicidalIdeation?.intent,
          },
          {
            label: Labels.PLAN,
            value: safety.currentHomicidalIdeation?.plan,
          },
          {
            label: Labels.MEANS,
            value: safety.currentHomicidalIdeation?.means,
          },
          {
            label: Labels.IDENTIFIABLE_TARGET,
            value:
              safety.currentHomicidalIdeation
                ?.specificTargetsEntityOrLocationConsidered,
          },
        ],
        'No current HI',
      )
      .padding()
      .longTextAnswer(
        Labels.EXPLAIN_ENDORSED_ANSWERS,
        safety.currentHomicidalIdeation?.description,
      )
      .padding()

      // Past SI
      .yesOrNoAnswer(
        Labels.PAST_SI_PRESENT,
        safety.pastSuicidalIdeation?.isPresent,
      )
      .padding()
      .longTextAnswer(
        Labels.EXPLAIN_ENDORSED_ANSWERS,
        safety.pastSuicidalIdeation?.description,
      )
      .padding()

      // Past HI
      .yesOrNoAnswer(
        Labels.PAST_HI_PRESENT,
        safety.pastHomicidalIdeation?.isPresent,
      )
      .padding()
      .longTextAnswer(
        Labels.EXPLAIN_ENDORSED_ANSWERS,
        safety.pastHomicidalIdeation?.description,
      )
      .padding()

      // Current self injury
      .textAnswersGroup(
        'Current Self Injury',
        [
          {
            label: Labels.CURRENT_SELF_INJURY_PRESENT,
            value: safety.currentSelfInjury?.isPresent ? 'Endorsed' : 'Denied',
          },
          {
            label: Labels.FREQUENCY,
            value: toFrequencyDescription(safety.currentSelfInjury?.frequency),
          },
          {
            label: Labels.INSTRUMENT_USED,
            value: safety.currentSelfInjury?.instrumentUsed,
          },
          {
            label: Labels.PART_OF_BODY_HARMED,
            value: safety.currentSelfInjury?.bodyPartHarmed,
          },
          {
            label: Labels.DEPTH_EXTENT_OF_INJURY,
            value: safety.currentSelfInjury?.extentOfInjury,
          },
          {
            label: Labels.EVER_SOUGHT_MEDICAL,
            value: safety.currentSelfInjury?.everSoughtMedicalAttentionAsAResult
              ? 'Endorsed'
              : 'Denied',
          },
        ],
        'No current self injury',
      )
      .padding()

      // Safety plan
      .longTextAnswer(
        Labels.SAFETY_PLAN_DESCRIPTION,
        safety.safetyPlan?.description,
      );
  }

  protected safetyProgress(isPsychNote: boolean = false) {
    this.pdfBuilder.section(
      TherapyProgressSectionName.SAFETY,
      () => {
        const safety = this.note.SAFETY;

        if (!safety) return false;

        const isNewVersion =
          safety.version === Version.v0 ||
          safety.version === Version.v1 ||
          safety.anyChangesOrRisks === BooleanOption.no ||
          safety.anyChangesOrRisks === BooleanOption.yes;

        if (isNewVersion) {
          this.safetySectionV2(safety, isPsychNote);
        } else {
          this.safetyProgressSection(safety);
        }
        return true;
      },
      'No safety data reported',
    );
  }

  private safetyProgressSection(safety: Safety) {
    // Current SI
    this.pdfBuilder
      .endorsedOrDeniedAnswersGroup(
        Labels.CURRENT_SI,
        [
          {
            label: Labels.THOUGHTS,
            value: safety.currentSuicidalIdeation?.thoughts,
          },
          {
            label: Labels.INTENT,
            value: safety.currentSuicidalIdeation?.intent,
          },
          {
            label: Labels.PLAN,
            value: safety.currentSuicidalIdeation?.plan,
          },
          {
            label: Labels.MEANS,
            value: safety.currentSuicidalIdeation?.means,
          },
        ],
        'No current SI',
      )
      .padding()
      .longTextAnswer(
        Labels.EXPLAIN_ENDORSED_ANSWERS,
        safety.currentSuicidalIdeation?.description,
      )
      .padding()

      // Current HI
      .endorsedOrDeniedAnswersGroup(
        Labels.CURRENT_HI,
        [
          {
            label: Labels.THOUGHTS,
            value: safety.currentHomicidalIdeation?.thoughts,
          },
          {
            label: Labels.INTENT,
            value: safety.currentHomicidalIdeation?.intent,
          },
          {
            label: Labels.PLAN,
            value: safety.currentHomicidalIdeation?.plan,
          },
          {
            label: Labels.MEANS,
            value: safety.currentHomicidalIdeation?.means,
          },
          {
            label: Labels.IDENTIFIABLE_TARGET,
            value:
              safety.currentHomicidalIdeation
                ?.specificTargetsEntityOrLocationConsidered,
          },
        ],
        'No current HI',
      )
      .padding()
      .longTextAnswer(
        Labels.EXPLAIN_ENDORSED_ANSWERS,
        safety.currentHomicidalIdeation?.description,
      )
      .padding()

      // Current self injury
      .textAnswersGroup(
        'Current Self Injury',
        [
          {
            label: Labels.CURRENT_SELF_INJURY_PRESENT,
            value: safety.currentSelfInjury?.isPresent ? 'Endorsed' : 'Denied',
          },
          {
            label: Labels.FREQUENCY,
            value: toFrequencyDescription(safety.currentSelfInjury?.frequency),
          },
          {
            label: Labels.INSTRUMENT_USED,
            value: safety.currentSelfInjury?.instrumentUsed,
          },
          {
            label: Labels.PART_OF_BODY_HARMED,
            value: safety.currentSelfInjury?.bodyPartHarmed,
          },
          {
            label: Labels.DEPTH_EXTENT_OF_INJURY,
            value: safety.currentSelfInjury?.extentOfInjury,
          },
          {
            label: Labels.EVER_SOUGHT_MEDICAL,
            value: safety.currentSelfInjury?.everSoughtMedicalAttentionAsAResult
              ? 'Endorsed'
              : 'Denied',
          },
        ],
        'No current self injury',
      )
      .padding()

      // Safety plan
      .longTextAnswer(
        Labels.SAFETY_PLAN_DESCRIPTION,
        safety.safetyPlan?.description,
      );
  }

  protected amendments(sectionName: string) {
    const amendments = this.note.amendments;

    if (amendments.length === 0) {
      return;
    }

    this.pdfBuilder.section(
      sectionName,
      () => {
        amendments.forEach(({ amendment, auditLog }) => {
          const creationEvt = getItemCreatedEvent(auditLog);
          if (creationEvt === null) return;

          this.pdfBuilder.amendment(
            amendment.text,
            creationEvt.timestamp,
            this.timezone,
          );
        });
        return true;
      },
      '',
    );
  }

  private static getSubstanceUseDescription(
    substance: SubstanceUse_SubstanceUseLineItem,
  ) {
    const amount = substance.frequency?.timesPerPeriod;
    const frequency = labelFromEnumValue(
      Frequency_Period,
      substance.frequency?.period,
    );
    const startDate = substance.startedUse
      ? protobufDateToString(substance.startedUse)
      : null;
    const endDate = substance.stoppedUse
      ? protobufDateToString(substance.stoppedUse)
      : null;
    const frequencyStr =
      amount && frequency ? `${amount} every ${frequency?.toLowerCase()}` : '';

    let useRangeStr = '';

    if (startDate) {
      useRangeStr = endDate
        ? `from ${startDate} until ${endDate}`
        : `since ${startDate}`;
    }

    return `${frequencyStr} ${useRangeStr}`;
  }

  private substanceUseSection(
    headerLabel: string,
    substances:
      | SubstanceUse_CurrentSubstanceUse[]
      | SubstanceUse_PastSubstanceUse[],
    noUseMessage: string,
  ) {
    const substanceUseAnswers: Answer[] = [];

    if (substances.length > 0) {
      const substancesUsed = substances
        .map((substance) => substance.substance)
        .filter(
          (substance) => substance,
        ) as SubstanceUse_SubstanceUseLineItem[];

      substancesUsed.forEach((substance) => {
        const { otherSubstanceTypeDescription, substanceType } = substance;
        const substanceName = otherSubstanceTypeDescription
          ? otherSubstanceTypeDescription
          : labelFromEnumValue(SubstanceUse_SubstanceType, substanceType);
        const substanceUseDescription = substance.description
          ? substance.description
          : ClinicalNotePDF.getSubstanceUseDescription(substance);

        if (substanceName && substanceUseDescription) {
          substanceUseAnswers.push({
            label: substanceName,
            value: substanceUseDescription,
          });
        }
      });
    }

    this.pdfBuilder.textAnswersGroup(
      headerLabel,
      substanceUseAnswers,
      noUseMessage,
    );
  }

  private goal(
    goal: CollaborationPlan_Goal,
    goalLabels: Record<string, string>,
    bottomPadding = true,
  ) {
    if (!goal) return;

    const goalTypeName = labelFromEnumValue(
      CollaborationPlan_GoalType,
      goal.goalType,
      goalLabels,
    );

    this.pdfBuilder.subsection(
      // since we've saved some goals prior to adding label & recommendedStepsLabel
      // so we need to provide a fallback labels for goals without a value for this fields
      goal.label || Labels.WHATS_GOING_WELL_FOR_MEMBER,
      () => {
        this.pdfBuilder
          .shortTextAnswer(Labels.GOAL_TYPE, goalTypeName!)
          .longTextAnswer(
            Labels.OTHER_GOAL_TYPE_DESCRIPTION,
            goal.otherGoalTypeDescription,
          )
          .padding()
          .longTextAnswer(
            goal.recommendedStepsLabel || Labels.STEPS_AGREED_TO,
            goal.recommendedSteps,
          );
      },
      bottomPadding,
    );
  }

  protected treatmentGoal(
    treatmentGoal: TreatmentGoal,
    index = 1,
    bottomPadding = true,
  ) {
    if (!treatmentGoal) return;
    const goalTypeName = labelFromEnumValue(
      TreatmentGoal_GoalType,
      treatmentGoal.goalType,
    );
    const goalStatusName = labelFromEnumValue(
      TreatmentGoal_GoalStatus,
      treatmentGoal.goalStatus,
    );
    this.pdfBuilder.subsection(
      goalTypeName || Labels.WHATS_GOING_WELL_FOR_MEMBER,
      () => {
        this.pdfBuilder
          .shortTextAnswer(`${Labels.GOAL_TYPE} - #${index}`, goalTypeName!)
          .longTextAnswer(
            Labels.GOAL_TYPE_COMMENT,
            treatmentGoal.otherGoalStatusComment,
          )
          .padding()
          .longTextAnswer(Labels.GOAL_DETAILS, treatmentGoal.goalDetails)
          .padding()
          .longTextAnswer(Labels.GOAL_STATUS, goalStatusName || '')
          .padding()
          .longTextAnswer(Labels.ACTION_PLAN, treatmentGoal.actionPlan)
          .padding();
      },
      bottomPadding,
    );
  }

  private safetySectionV2(
    safety: SafetyIntake | Safety,
    isPsychNote: boolean = false,
  ) {
    // show anyChangesOrRisks irrespective of the selection
    this.pdfBuilder
      .radioItemAnswer(
        BooleanOption,
        Labels.CHANGES_OR_RISKS,
        safety.anyChangesOrRisks,
      )
      .padding();

    const selectedConcerns = loadSafetyConcernComponents(safety);
    const hasFullSafetyPlanFields =
      !isPsychNote &&
      selectedConcerns.some((concern) => {
        if (SafetyConcernMap[concern]) {
          return FULL_SAFETY_PLAN_FIELDS_CONCERNS.includes(
            SafetyConcernMap[concern]!,
          );
        }
        return false;
      });
    if (safety.anyChangesOrRisks === BooleanOption.no) return;

    if (
      selectedConcerns.includes(SafetyConcern.suicide_risk) &&
      safety.currentSuicidalIdeation
    )
      this.suicideRisk(safety.currentSuicidalIdeation);

    if (
      safety.currentSelfInjury &&
      selectedConcerns.includes(SafetyConcern.self_harm_risk)
    )
      this.selfHarmRisk(safety.currentSelfInjury);

    if (
      safety.currentHomicidalIdeation &&
      selectedConcerns.includes(SafetyConcern.homicide_risk)
    )
      this.homicideRisk(safety.currentHomicidalIdeation);

    if (
      safety.domesticViolenceRisk &&
      selectedConcerns.includes(SafetyConcern.domestic_violence_risk)
    )
      this.domesticViolenceRisk(safety.domesticViolenceRisk);

    if (
      safety.eatingDisorderRisk &&
      selectedConcerns.includes(SafetyConcern.eating_disorder_risk)
    )
      this.eatingDisorderRisk(safety.eatingDisorderRisk);

    if (
      safety.highSubstanceUse &&
      selectedConcerns.includes(SafetyConcern.substance_use_risk)
    )
      this.highSubstanceRisk(safety.highSubstanceUse);

    if (
      safety.vulnerablePopulationsAbuse &&
      selectedConcerns.includes(SafetyConcern.abuse_of_vulnerable_risk)
    )
      this.vulnerablePopulationAbuseRisk(safety.vulnerablePopulationsAbuse);

    if (
      safety.otherConcerns &&
      selectedConcerns.includes(SafetyConcern.other_risks)
    )
      this.otherConcern(safety.otherConcerns);

    if (safety.safetyPlan)
      this.safetyPlan(safety.safetyPlan, hasFullSafetyPlanFields);
  }

  private suicideRisk(suicidalIdeation: SuicidalIdeation) {
    this.pdfBuilder.subsection('Suicidal Risk', () => {
      this.pdfBuilder
        .endorsedOrDeniedAnswersGroup(
          Labels.CURRENT_SI,
          [
            {
              label: Labels.THOUGHTS,
              value: suicidalIdeation?.thoughts,
            },
            {
              label: Labels.INTENT,
              value: suicidalIdeation?.intent,
            },
            {
              label: Labels.PLAN,
              value: suicidalIdeation?.plan,
            },
            {
              label: Labels.MEANS,
              value: suicidalIdeation?.means,
            },
          ],
          'No current SI',
        )
        .padding()
        .longTextAnswer(
          Labels.CURRENT_SUMMARIZATION_SUICIDE_RISK,
          suicidalIdeation?.description,
        )
        .padding()
        .yesOrNoAnswer(Labels.RISK_HAS_PAST, suicidalIdeation?.hasPast)
        .padding()
        .longTextAnswer(
          Labels.PAST_SUMMARIZATION_SUICIDE_RISK,
          suicidalIdeation?.pastSummarization,
        )
        .padding();
    });
  }

  private homicideRisk(homicidalRisk: HomicidalIdeation) {
    this.pdfBuilder.subsection('Homicidal Risk', () => {
      this.pdfBuilder
        .endorsedOrDeniedAnswersGroup(
          Labels.CURRENT_HI,
          [
            {
              label: Labels.THOUGHTS,
              value: homicidalRisk?.thoughts,
            },
            {
              label: Labels.INTENT,
              value: homicidalRisk?.intent,
            },
            {
              label: Labels.PLAN,
              value: homicidalRisk?.plan,
            },
            {
              label: Labels.MEANS,
              value: homicidalRisk?.means,
            },
            {
              label: Labels.IDENTIFIABLE_TARGET,
              value: homicidalRisk?.specificTargetsEntityOrLocationConsidered,
            },
          ],
          'No current HI',
        )
        .padding()
        .longTextAnswer(
          Labels.EXPLAIN_ENDORSED_ANSWERS,
          homicidalRisk?.description,
        )
        .padding()
        .yesOrNoAnswer(Labels.RISK_HAS_PAST, homicidalRisk?.hasPast)
        .padding()
        .longTextAnswer(
          Labels.PAST_SUMMARIZATION,
          homicidalRisk?.pastSummarization,
        )
        .padding();
    });
  }

  private selfHarmRisk(selfHarmRisk: SelfInjury) {
    this.pdfBuilder.subsection('Self Harm Risk', () => {
      this.pdfBuilder
        // Current SH
        .yesOrNoAnswer(
          Labels.CURRENT_SELF_INJURY_PRESENT,
          selfHarmRisk?.isPresent,
        )
        .padding()
        .yesOrNoAnswer(
          Labels.EVER_SOUGHT_MEDICAL,
          selfHarmRisk?.everSoughtMedicalAttentionAsAResult,
        )
        .padding()
        .longTextAnswer(
          Labels.CURRENT_SUMMARY_SELF_HARM_RISK,
          selfHarmRisk.currentSummarization,
        )
        .padding()
        // PAST SH
        .yesOrNoAnswer(Labels.RISK_HAS_PAST, selfHarmRisk?.hasPast)
        .padding()
        .yesOrNoAnswer(
          Labels.EVER_SOUGHT_MEDICAL,
          selfHarmRisk?.everSoughtMedicalAttentionAsAResult,
        )
        .padding()
        .longTextAnswer(
          Labels.PAST_SUMMARY_SELF_HARM_RISK,
          selfHarmRisk.pastSummarization,
        )
        .padding();
    });
  }

  private domesticViolenceRisk(domesticViolenceRisk: DomesticViolenceRisk) {
    this.pdfBuilder.subsection('Domestic Violence Risk', () => {
      this.pdfBuilder
        .longTextAnswer(
          Labels.DOMESTIC_VIOLENCE,
          domesticViolenceRisk.currentDescription,
        )
        .padding();
    });
  }

  private eatingDisorderRisk(eatingDisorder: EatingDisorderRisk) {
    this.pdfBuilder.subsection('Eating Disorder', () => {
      this.pdfBuilder
        .longTextAnswer(
          Labels.EATING_DISORDER,
          eatingDisorder.currentDescription,
        )
        .padding();
    });
  }

  private highSubstanceRisk(highSubstanceRisk: HighSubstanceUse) {
    this.pdfBuilder.subsection('High Substance Use Risk', () => {
      this.pdfBuilder
        .longTextAnswer(
          Labels.HIGH_SUBSTANCE_RISK,
          highSubstanceRisk.currentDescription,
        )
        .padding();
    });
  }

  private otherConcern(otherConcern: OtherCorcerns) {
    this.pdfBuilder.subsection('Other Concerns', () => {
      this.pdfBuilder
        .longTextAnswer(Labels.OTHER_CONCERN, otherConcern.currentDescription)
        .padding();
    });
  }

  private vulnerablePopulationAbuseRisk(
    populationRisk: VulnerablePopulationsAbuse,
  ) {
    this.pdfBuilder.subsection('Vulnerable Population Abuse', () => {
      this.pdfBuilder
        .longTextAnswer(
          Labels.POPULATION_ABUSE_RISK,
          populationRisk.currentDescription,
        )
        .padding();
    });
  }

  private safetyPlan(safetyPlan: SafetyPlan, fullSafetyPlanFields: boolean) {
    if (
      safetyPlan?.status !== SafetyPlanStatus.incomplete &&
      !fullSafetyPlanFields
    ) {
      this.pdfBuilder.subsection(Labels.SAFETY_PLAN, () => {
        this.pdfBuilder
          .longTextAnswer(
            Labels.DOCUMENT_SAFETY_PLAN,
            safetyPlan.psychiatryQuestions?.safetyPlanDocumentation,
          )
          .padding()
          .stringGroup(
            Labels.AGENCIES_TO_CONTACT,
            safetyPlan.psychiatryQuestions?.agenciesToContactInCrisis || [],
            'No agencies to contact',
          );
      });
    } else if (
      safetyPlan?.status !== SafetyPlanStatus.incomplete &&
      fullSafetyPlanFields
    ) {
      this.pdfBuilder.subsection(Labels.SAFETY_PLAN, () => {
        this.pdfBuilder
          .longTextAnswer(
            Labels.DEVELOPMENT_CRISIS,
            safetyPlan.therapyQuestions
              ?.warningSignsAndTriggersForDevelopingCrisis,
          )
          .padding()
          .longTextAnswer(
            Labels.INTERNAL_MENTAL_HEALTH_CRISIS,
            safetyPlan.therapyQuestions
              ?.internalStrategiesOrientationForMentalHealthCrisis,
          )
          .padding()
          .longTextAnswer(
            Labels.EXTERNAL_MENTAL_HEALTH_CRISIS,
            safetyPlan.therapyQuestions
              ?.externalStrategiesOrientationForMentalHealthCrisis,
          )
          .padding()
          .stringGroup(
            Labels.PEOPLE_TO_CONTACT,
            safetyPlan.therapyQuestions?.peopleToContactInCrisis || [],
            'No contacts',
          )
          .padding()
          .longTextAnswer(
            Labels.ENVIRONMENT_ORIENTATION,
            safetyPlan.therapyQuestions?.safeEnvironmentOrientation,
          )
          .padding()
          .longTextAnswer(
            Labels.THINGS_WORTH_LIVING_FOR,
            safetyPlan.therapyQuestions?.rememberWhatsImportant,
          )
          .padding()
          .stringGroup(
            Labels.AGENCIES_TO_CONTACT,
            safetyPlan.therapyQuestions?.agenciesToContactInCrisis || [],
            'No contacts',
          )
          .padding();
        this.pdfBuilder.subsection('Actions Taken', () => {
          this.pdfBuilder
            .radioItemAnswer(
              BooleanOption,
              Labels.CRR_FIELD,
              safetyPlan?.actionsTaken?.crr,
            )
            .padding()
            .radioItemAnswer(
              BooleanOption,
              Labels.RESOURCES_SHARED,
              safetyPlan?.actionsTaken?.sharedResources,
            )
            .padding()
            .radioItemAnswer(
              BooleanOption,
              Labels.CONSULTED_WITH_MANAGER,
              safetyPlan?.actionsTaken?.consultedWithManager,
            )
            .padding()
            .radioItemAnswer(
              BooleanOption,
              Labels.ENGAGED_CARE_TEAM,
              safetyPlan?.actionsTaken?.careCoordinationTeamEngaged,
            )
            .padding()
            .radioItemAnswer(
              BooleanOption,
              Labels.CLINICIAN_CONSULTED,
              safetyPlan?.actionsTaken?.onCallClinicianContacted,
            )
            .padding();
        });
      });
    }
  }
}
