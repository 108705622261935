import { createReducer } from 'redux-reloaded';

import { hideOrShowSnackNotification } from './actions';
import { getInitialState, State } from './schema';

export const reducer = createReducer<State>(getInitialState());

reducer.on(
  hideOrShowSnackNotification,
  (state, { payload: { notification } }) => ({
    ...state,
    showSnackbar: Boolean(notification),
    snackbarNotification: notification,
  }),
);
