import { assignHomeworkResultStatus } from 'app/patients/tabs/content/types';
import { onEnterClick } from 'app/state/chat/actions';
import {
  selectLoggingContextWithMemberData,
  selectPubnubStatus,
  selectTextAreaInput,
  selectTypingStateByChannelId,
} from 'app/state/chat/selectors';
import { setHomeworkAssignmentStatus } from 'app/state/content/actions';
import { selectHomeworkAssignmentStatusByChannel } from 'app/state/content/selectors';
import {
  selectCurrentPubnubChannelId,
  selectMessages,
} from 'app/state/inbox/selectors';
import {
  nullifyCountdown,
  startTimedSend,
} from 'app/state/timed-sends/actions';
import { selectCountDownForDelayedMessage } from 'app/state/timed-sends/selectors';
import timerIcon from 'assets/chat/timer-icon.svg';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Tooltip from 'shared-components/tooltip/Tooltip';

import { PUBNUB_STATUS } from '../../pubnub/types';
import { CountdownTimer } from '../CountdownTimer';
import { StickyChatNotification } from '../messages/StickyChatNotification';
import { getAssignHomeworkErrorMessage } from '../utils';
import { TextAreaContainer } from './TextAreaContainer';
import styles from './TextAreaGroup.module.scss';

// this component will contain smart replies, canned replies and text area itself
export const TextAreaGroup = ({
  callerId,
  memberId,
  memberName,
}: {
  callerId: string;
  memberId: string;
  memberName: string;
}) => {
  const dispatch = useDispatch();

  const currentChannelId = selectCurrentPubnubChannelId();
  const delayCountdownIsActive = Boolean(
    selectCountDownForDelayedMessage(currentChannelId),
  );
  const assignHomeworkStatus = selectHomeworkAssignmentStatusByChannel(
    currentChannelId,
  );
  const status = selectPubnubStatus();
  const messageText = selectTextAreaInput(currentChannelId);
  const currentMessages = selectMessages(currentChannelId);
  const logsContext = selectLoggingContextWithMemberData(callerId, memberId);
  const { nextMessageId } = selectTypingStateByChannelId(currentChannelId);
  const homeworkPopupVisibilityTimeout = useRef<NodeJS.Timeout>();

  // we should hide the text area if pubnub hasn't been instantiated
  if (status === PUBNUB_STATUS.ERROR) return <></>;

  const handleDelayedSend = () => {
    dispatch(
      startTimedSend({
        channelId: currentChannelId,
        currentMessages,
        logsContext,
        messageText,
      }),
    );
  };

  const sendNow = () => {
    dispatch(
      onEnterClick({
        channelId: currentChannelId,
        inputValue: messageText,
        logsContext,
        memberId,
        nextMessageId,
      }),
    );
    dispatch(
      nullifyCountdown({
        channelId: currentChannelId,
      }),
    );
  };

  const cleanupHomeworkStatus = () => {
    dispatch(
      setHomeworkAssignmentStatus({
        assignmentState: {
          dateAndTime: '',
          status: assignHomeworkResultStatus.OK,
          title: '',
        },
        channelId: currentChannelId,
      }),
    );
    clearTimeout(homeworkPopupVisibilityTimeout.current);
  };

  const isThereIssueWithHWAssignment =
    assignHomeworkStatus &&
    assignHomeworkStatus.status !== assignHomeworkResultStatus.OK;

  useEffect(() => {
    if (isThereIssueWithHWAssignment) {
      homeworkPopupVisibilityTimeout.current = setTimeout(
        cleanupHomeworkStatus,
        8000,
      );
    }
  }, [assignHomeworkStatus?.status]);

  return (
    <>
      <div className={styles.textareaGroup}>
        {delayCountdownIsActive && <CountdownTimer sendNow={sendNow} />}
        <div className={styles.textareaContainer}>
          {isThereIssueWithHWAssignment && (
            <StickyChatNotification
              // to-do: replace "This" w/ the title from the useHomeworkDuplicationCheck once the baclend returns
              message={getAssignHomeworkErrorMessage(
                memberName,
                assignHomeworkStatus.status,
              )}
              dateAndTime={assignHomeworkStatus.dateAndTime}
            />
          )}
          <TextAreaContainer
            currentChannelId={currentChannelId}
            callerId={callerId}
            memberId={memberId}
          />
          <Tooltip
            title={
              delayCountdownIsActive ? 'TIMER DISABLED WHILE IN PROGRESS' : ''
            }
          >
            <button
              className={styles.timerButton}
              onClick={handleDelayedSend}
              disabled={delayCountdownIsActive}
              data-testid="timed_send_button"
            >
              <img
                className={styles.timerIcon}
                alt="timer icon"
                src={timerIcon}
              />
            </button>
          </Tooltip>
        </div>
      </div>
    </>
  );
};
