import { MemberChartErrorState } from 'app/member-chart-cards/error-state/MemberChartErrorState';
import { sortTimestampDesc } from 'utils';
import { SortDirection } from 'generated/globalTypes';
import { useMemberSurveys } from 'hooks/useMemberSurveys';
import React from 'react';
import { Loader } from 'shared-components/loader/Loader';

import GAD7Info from './GAD7Info';
import styles from './MemberHeaderSurvey.module.scss';
import PHQ9Info from './PHQ9Info';

export function MemberHeaderSurvey({
  memberId,
}: Readonly<{ memberId: string }>) {
  const { data, loading, error } = useMemberSurveys({
    enablePolling: true,
    loadGAD7s: true,
    loadPHQ9s: true,
    memberId,
    phqgadPaginationInput: {
      // we only need the latest 2 surveys
      maxItemsPerPage: 2,
      sortDirection: SortDirection.DESC,
    },
  });

  if (loading) {
    return <Loader className={styles.loader} />;
  }
  if (error) {
    return <MemberChartErrorState error={error} smallError={true} />;
  }

  // Surveys are sorted with newest at the end of the array
  const surveys = data?.getMember!.surveyResponses;
  const phqSurveys = (surveys?.phq9s?.surveys ?? []).sort(sortTimestampDesc);
  const gadSurveys = (surveys?.gad7s?.surveys ?? []).sort(sortTimestampDesc);

  const [phqLatest, phqPrevious] = phqSurveys;
  const [gadLatest, gadPrevious] = gadSurveys;

  return (
    <div className={styles.container} data-testid="member-header-survey">
      <div className={styles.PHQInfo}>
        <PHQ9Info
          phqLatest={phqLatest}
          phqPrevious={phqPrevious}
          memberId={memberId}
        />
      </div>
      <div className={styles.GADInfo}>
        <GAD7Info gad7Latest={gadLatest} gad7Previous={gadPrevious} />
      </div>
    </div>
  );
}
