import styles from 'app/care-team/care-member-profile/CareMemberProfile.module.scss';
import ClinicianMember from 'app/care-team/member-types/ClinicianMember';
import CoachMember from 'app/care-team/member-types/CoachMember';
import { careTeamMemberProfileViewed } from 'app/state/amplitude/actions/careTeam';
import { clearActiveMemberProfile } from 'app/state/care-team/actions';
import { selectCareTeamScreenState } from 'app/state/care-team/selectors';
import { ReactComponent as LeftArrow } from 'assets/arrows/left.svg';
import { ReactComponent as Education } from 'assets/Education.svg';
import { ReactComponent as IdentificationCard } from 'assets/IdentificationCard.svg';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const CareMemberProfile = () => {
  const dispatch = useDispatch();
  const {
    selectedCareTeamMemberProfile: activeMemberProfile,
  } = selectCareTeamScreenState();

  const onClearActiveMemberProfile = () => {
    dispatch(clearActiveMemberProfile());
  };

  useEffect(() => {
    if (activeMemberProfile) {
      dispatch(
        careTeamMemberProfileViewed({
          memberId: activeMemberProfile.id,
        }),
      );
    }
  }, [activeMemberProfile, dispatch]);

  return (
    <div
      className={styles.container}
      data-testid={`care-member-profile-${activeMemberProfile?.id}`}
    >
      <button className={styles.button} onClick={onClearActiveMemberProfile}>
        <LeftArrow width={16} height={16} />
        <div className={styles.buttonText}>Back to list</div>
      </button>
      <div className={styles.sections}>
        <div>
          {activeMemberProfile?.__typename === 'CareTeamCoach' && (
            <CoachMember
              disableProfileLink={true}
              hideStatus={true}
              item={activeMemberProfile}
              index={0}
              columns={1}
            />
          )}
          {activeMemberProfile?.__typename === 'CareTeamClinician' && (
            <ClinicianMember
              disableProfileLink={true}
              item={activeMemberProfile}
              index={0}
              columns={1}
            />
          )}
        </div>
        <div>
          {activeMemberProfile?.__typename === 'CareTeamCoach' &&
            activeMemberProfile.description && (
              <div>
                <div>{activeMemberProfile.description}</div>
              </div>
            )}
          {activeMemberProfile?.__typename === 'CareTeamClinician' && (
            <div className={styles.details}>
              <div className={styles.aboutMe}>
                <div>
                  <IdentificationCard width={20} height={20} />
                </div>
                <div>{activeMemberProfile.moreAboutMe}</div>
              </div>
              <div className={styles.aboutMe}>
                <div>
                  <Education width={20} height={20} />
                </div>
                <div>{activeMemberProfile.educationQualifications}</div>
              </div>
              <hr className={styles.divider} />
              <div>
                <div className={styles.label}>Specialties</div>
                <div>{activeMemberProfile.backgroundSpecialities}</div>
              </div>
              <div>
                <div className={styles.label}>Approach to care</div>
                <div>{activeMemberProfile.approachToCare}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CareMemberProfile;
