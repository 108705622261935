import Link from '@mui/material/Link';
import { Links } from 'app/constants';
import { useAppState } from 'app/state';
import { bannerLinkClicked } from 'app/state/amplitude/actions/etc';
import React from 'react';
import { useDispatch } from 'react-redux';

import styles from './AppBanner.module.scss';

export default function AppBanner() {
  return (
    <div data-testid="appBanner" className={styles.root}>
      <div>
        <span>
          For complex, acute, high risk, or emergency situations, please call
          the consultation line: 415-992-3107. Other Resources:{' '}
        </span>
        <BannerLink
          data-testid="bannerLink"
          href="https://docs.google.com/document/d/1EIJo0kFRqFbA6_Peq9jtMQx78GFR3atWIYx2GpR_n1g/edit"
          linkText="Clinician Procedures Manual"
        />{' '}
        <span>and</span>{' '}
        <BannerLink
          href={Links.CLINICIAN_SITE}
          linkText="Clinician Resources Site"
        />
      </div>
      <div>
        <span>
          Take a few moments for you! Try a supportive practice developed for
          clinicians by clinicians:{' '}
        </span>
        <BannerLink
          href="https://drive.google.com/file/d/1Tm7PORuLExmX-08kFcunS2-f6Z9kBwvA/view?usp=sharing"
          linkText="After Session Meditation"
        />{' '}
        <span>or</span>{' '}
        <BannerLink
          href="https://drive.google.com/file/d/1smf-mQoecErEl7OYcsjV80WyGRU8dOHa/view?usp=sharing"
          linkText="Energizing Practice"
        />{' '}
        <span>or</span>{' '}
        <BannerLink
          href="https://drive.google.com/file/d/18HYZJQ5SLEYNx5YqyPRx8SZGSjnrml_2/view?usp=sharing"
          linkText="Grounding Practice"
        />
      </div>
    </div>
  );
}

function BannerLink(props: { href: string; linkText: string }) {
  const { href, linkText } = props;
  const dispatch = useDispatch();
  const { role } = useAppState((_) => _.user);
  return (
    <Link
      variant="body2"
      href={href}
      target="_blank"
      rel="noopener"
      color="primary"
      onClick={() => dispatch(bannerLinkClicked({ label: linkText, role }))}
      data-testid={`bannerLink-${linkText}`}
    >
      {linkText}
    </Link>
  );
}
