import { SurveyTab } from 'app/patients/tabs/surveys/SurveysV3/utils';

export enum Routes {
  SUMMARY = 'summary',
  CONTENT = 'content',
  DETAILS = 'details',
  SURVEYS = 'surveys',
  TEAM = 'team',
}

export function defaultRoute(prefix: string, memberId: string): string {
  const path = prefix.replace('?', '').replace(':memberId', memberId);
  return `${path}`;
}

export function contentRoute(prefix: string, memberId: string): string {
  return `${defaultRoute(prefix, memberId)}/${Routes.CONTENT}`;
}

export function summaryRoute(prefix: string, memberId: string): string {
  return `${defaultRoute(prefix, memberId)}/${Routes.SUMMARY}`;
}

export function detailsRoute(prefix: string, memberId: string): string {
  return `${defaultRoute(prefix, memberId)}/${Routes.DETAILS}`;
}

export function surveysV3Route(
  prefix: string,
  memberId: string,
  selectedTab?: SurveyTab,
): string {
  let path = `${defaultRoute(prefix, memberId)}/${Routes.SURVEYS}`;
  if (selectedTab) path = `${path}/${selectedTab.toString()}`;
  return path;
}

export function teamRoute(prefix: string, memberId: string): string {
  return `${defaultRoute(prefix, memberId)}/${Routes.TEAM}`;
}
