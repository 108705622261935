import { field, stringField, useForm } from '@ginger.io/react-use-form';
import {
  MemberBackgroundSection_SectionType as SectionType,
  MemberBackgroundSection_Version,
} from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/MemberBackgroundSection';
import styles from 'shared-components/Card/Card.module.scss';
import {
  CardColumnGaps,
  CardGrid,
  CardRowGaps,
  NumberOfCardColumns,
} from 'shared-components/grid-layout/CardGrid.module';
import { GridCell } from 'shared-components/grid-layout/GridCell';
import { CardFieldAndGridCell } from 'app/member-chart-cards/card-field/CardField';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import { BackgroundTaskCardProps } from 'app/member-chart-cards/member-background/BackgroundCard';
import {
  CareTeamNotesSectionFields,
  PLACEHOLDERS,
  ValidSectionType,
  VersionedMemberBackgroundSection,
} from 'app/member-chart-cards/member-background/MemberBackgroundTypes';
import { useMemberBackground } from 'app/member-chart-cards/member-background/useMemberBackground';
import { formatTooltipText } from 'app/member-chart-cards/member-background/utils';
import { CardTextArea } from 'app/member-chart-cards/text-area/CardTextArea';
import { useAppState } from 'app/state';
import { renderSliceStateResult } from 'app/state/status/RenderSliceState';
import { UserRole } from 'generated/globalTypes';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';

import { TEXT_AREA_PLACEHOLDER_CLINICIAN } from '../text-area/constants';

type CareTeamNotesSectionField = CardFieldAndGridCell & {
  sectionType: ValidSectionType;
};
const careTeamNotesSectionFields: CareTeamNotesSectionField[] = [
  {
    label: CareTeamNotesSectionFields.VALUES,
    labelForAnalytics: `Member Background: ${CareTeamNotesSectionFields.VALUES}`,
    placeholder: PLACEHOLDERS.VALUES,
    sectionType: SectionType.member_values,
  },
  {
    label: CareTeamNotesSectionFields.SELF_CARE_STRATEGIES,
    labelForAnalytics: `Member Background: ${CareTeamNotesSectionFields.SELF_CARE_STRATEGIES}`,
    placeholder: PLACEHOLDERS.SELF_CARE_STRATEGIES,
    sectionType: SectionType.self_care_strategies,
  },
  {
    label: CareTeamNotesSectionFields.SUPPORT_SYSTEM,
    labelForAnalytics: `Member Background: ${CareTeamNotesSectionFields.SUPPORT_SYSTEM}`,
    placeholder: PLACEHOLDERS.SUPPORT_SYSTEM,
    sectionType: SectionType.support_system,
  },
  {
    label: CareTeamNotesSectionFields.ADDITIONAL_BACKGROUND_INFO,
    labelForAnalytics: `Member Background: ${CareTeamNotesSectionFields.ADDITIONAL_BACKGROUND_INFO}`,
    placeholder: PLACEHOLDERS.ADDITIONAL_BACKGROUND_INFO,
    sectionType: SectionType.additional_background_info,
  },
];

export type CareTeamNotesSectionProps = BackgroundTaskCardProps & {
  memberId: string;
};

export const CareTeamNotesSection = (
  props: CareTeamNotesSectionProps,
): React.ReactElement => {
  const { memberId, generateId, keyGenerator } = props;

  const { timezone, isCoach } = useAppState(({ user: { timezone, role } }) => ({
    isCoach: role === UserRole.COACH || role === UserRole.COACH_SUPERVISOR,
    timezone: timezone!,
  }));

  const {
    memberBackground,
    saveMemberBackgroundSection,
  } = useMemberBackground(memberId, { generateId, keyGenerator });

  return (
    <div className={styles.cardSection}>
      {renderSliceStateResult(memberBackground, () => (
        <CardGrid
          rowGap={CardRowGaps.STANDARD}
          columnGap={CardColumnGaps.STANDARD}
          numberOfColumns={NumberOfCardColumns.ONE}
        >
          {careTeamNotesSectionFields.map((field) => (
            <SectionTypeForm
              key={field.sectionType}
              field={field}
              memberId={memberId}
              onUpdate={async (input) => {
                await saveMemberBackgroundSection(input);
              }}
              timezone={timezone}
              value={memberBackground.data![field.sectionType]?.value ?? ''}
              tooltip={
                memberBackground.data![field.sectionType]?.tooltipText ?? ''
              }
              sourceVersion={
                memberBackground.data![field.sectionType]?.sourceVersion ?? ''
              }
              readOnly={!isCoach}
            />
          ))}
        </CardGrid>
      ))}
    </div>
  );
};

function SectionTypeForm(props: {
  field: CareTeamNotesSectionField;
  memberId: string;
  sourceVersion: string;
  timezone: string;
  tooltip: string;
  value: string;
  readOnly?: boolean;
  onUpdate: (data: VersionedMemberBackgroundSection) => Promise<void>;
}) {
  const {
    timezone,
    value,
    tooltip,
    memberId,
    sourceVersion,
    onUpdate,
    readOnly = false,
  } = props;
  const { label, labelForAnalytics, sectionType, placeholder } = props.field;
  const [tooltipText, setTooltipText] = useState('');

  const { fields, getValue, isTouched, validate, reset } = useForm<
    VersionedMemberBackgroundSection
  >({
    memberId: stringField({ default: memberId, rules: [] }),
    sectionType: field<SectionType>({
      default: sectionType,
      rules: [],
    }),
    source: stringField({ default: `Member Background Card > ${label}` }),
    sourceVersion: stringField({ default: sourceVersion, rules: [] }),
    textValue: stringField({ default: value, rules: [] }),
    version: field<MemberBackgroundSection_Version>({
      default: MemberBackgroundSection_Version.v0,
      rules: [],
    }),
  });

  useEffect(() => {
    setTooltipText(tooltip);
  }, [tooltip]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    // This is hard-coded to say "You" to prevent the Tooltip from saying
    // "[previousUpdatedAt] • [potentially someone else] last updated" when the
    // user has not yet refreshed the page to fetch the updated Vault updatedBy value
    const tooltipText = formatTooltipText(moment(), timezone, 'You');
    setTooltipText(tooltipText);
    fields.textValue.setValue(e.target.value);
    fields.sourceVersion?.setValue(sourceVersion);
  };

  const onBlur = async () => {
    if (isTouched && (await validate())) {
      const updateValue = getValue();
      await onUpdate(updateValue);
      reset(updateValue);
    }
  };

  return (
    <GridCell key={label}>
      <FieldLabel labelText={label} />
      <CardTextArea
        tooltipEventLabel={labelForAnalytics}
        tooltipText={tooltipText}
        value={fields.textValue.value}
        placeholder={readOnly ? TEXT_AREA_PLACEHOLDER_CLINICIAN : placeholder}
        onBlur={onBlur}
        readOnly={readOnly}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChangeHandler(e)
        }
      />
    </GridCell>
  );
}
