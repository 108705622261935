import { Context } from 'app/state/context';
import {
  bulkSubscribeToTodaysMemberChannels,
  handleRPC,
  handleTextMessage,
  initConversationsInInbox,
  markConversationAsDone,
  moveInboxItemBetweenSections,
  onNewConversation,
  onNewMessage,
  onNewMessageRPC,
  onPresenceChange,
  onStatusReceived,
  refetchHistory,
  refreshClinicianTodaysMemberList,
  refreshCoachTodaysMemberList,
  refreshTodaysInboxTotalCount,
  setSearchResult,
  sortInboxSections,
  startInboxPolling,
  stopInboxPolling,
  triggerPubnubChannelSubscription,
  updateConversationsBasedOnRefetch,
  updateInboxSectionItems,
  updateRiskAlertIndicator,
  updateUnreadMessages,
} from 'app/state/inbox/actions';
import { State } from 'app/state/schema';
import { createActionHandlers } from 'redux-reloaded';

import { onBulkSubscribeToTodaysMemberChannels } from './onBulkSubscribeToTodaysMemberChannels';
import { onHandleRPC } from './onHandleRPC';
import { onHandleTextMessage } from './onHandleTextMessage';
import { onInitConversationsInInbox } from './onInitConversationsInInbox';
import { onMarkConversationAsDone } from './onMarkConversationAsDone';
import { onMoveInboxItemBetweenSections } from './onMoveInboxItemBetweenSections';
import { onNewConversation as onNewConversationActionHandler } from './onNewConversation';
import { onNewMessage as onNewMessageActionHandler } from './onNewMessage';
import { onNewMessageRPC as onNewMessageRPCActionHandler } from './onNewMessageRPC';
import { onPresenceChange as onPresenceChangeActionHandler } from './onPresenceChange';
import { onRefetchHistory } from './onRefetchHistory';
import { onRefreshClinicianTodaysMemberList } from './onRefreshClinicianTodaysMemberList';
import { onRefreshCoachTodaysMemberList } from './onRefreshCoachTodaysMemberList';
import { onRefreshTodaysInboxTotalCount } from './onRefreshTodaysInboxTotalCount';
import { onSetSearchResult } from './onSetSearchResult';
import { onSortInboxSections } from './onSortInboxSections';
import { onStartInboxPolling } from './onStartInboxPolling';
import { onStatusReceived as onStatusReceivedActionHandler } from './onStatusReceived';
import { onStopInboxPolling } from './onStopInboxPolling';
import { onTriggerPubnubChannelSubscription } from './onTriggerPubnubChannelSubscription';
import { onUpdateConversationsBasedOnRefetch } from './onUpdateConversationsBasedOnRefetch';
import { onUpdateInboxSectionItems } from './onUpdateInboxSectionItems';
import { onUpdateRiskAlertIndicator } from './onUpdateRiskAlertIndicator';
import { onUpdateUnreadMessages } from './onUpdateUnreadMessages';

export const handlers = createActionHandlers<Context, State>();

handlers.on(refetchHistory, onRefetchHistory);
handlers.on(onNewMessageRPC, onNewMessageRPCActionHandler);
handlers.on(handleRPC, onHandleRPC);
handlers.on(onNewConversation, onNewConversationActionHandler);
handlers.on(handleTextMessage, onHandleTextMessage);
handlers.on(onNewMessage, onNewMessageActionHandler);
handlers.on(onPresenceChange, onPresenceChangeActionHandler);
handlers.on(onStatusReceived, onStatusReceivedActionHandler);
handlers.on(
  updateConversationsBasedOnRefetch,
  onUpdateConversationsBasedOnRefetch,
);
handlers.on(updateUnreadMessages, onUpdateUnreadMessages);
handlers.on(initConversationsInInbox, onInitConversationsInInbox);
handlers.on(
  triggerPubnubChannelSubscription,
  onTriggerPubnubChannelSubscription,
);
handlers.on(
  bulkSubscribeToTodaysMemberChannels,
  onBulkSubscribeToTodaysMemberChannels,
);
handlers.on(startInboxPolling, onStartInboxPolling);
handlers.on(stopInboxPolling, onStopInboxPolling);
handlers.on(
  refreshClinicianTodaysMemberList,
  onRefreshClinicianTodaysMemberList,
);
handlers.on(markConversationAsDone, onMarkConversationAsDone);
handlers.on(moveInboxItemBetweenSections, onMoveInboxItemBetweenSections);
handlers.on(sortInboxSections, onSortInboxSections);
handlers.on(refreshTodaysInboxTotalCount, onRefreshTodaysInboxTotalCount);
handlers.on(updateRiskAlertIndicator, onUpdateRiskAlertIndicator);
handlers.on(setSearchResult, onSetSearchResult);
handlers.on(updateInboxSectionItems, onUpdateInboxSectionItems);
handlers.on(refreshCoachTodaysMemberList, onRefreshCoachTodaysMemberList);
