import styles from 'app/patients/tabs/content/EllipsisMenu.module.scss';
import React from 'react';

export interface GoalEllipsisProps {
  handleUnassign: () => void;
}

export default function Ellipsis({ handleUnassign }: GoalEllipsisProps) {
  return (
    <div className={styles.ellipsisPopover}>
      <button
        className={styles.unassignButton}
        onClick={handleUnassign}
        aria-label="unassign"
      >
        Unassign
      </button>
    </div>
  );
}
