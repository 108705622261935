import { QuickNote as QuickNoteVaultType } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/QuickNote';
import { CoachNotesItem } from 'app/coach/coach-notes/CoachNotesTypes';
import { CardTextAreaField } from 'app/coach/coach-notes/note-detail-view/note-fields';
import { quickNote } from 'app/coach/coach-notes/note-detail-view/note-templates/fieldDefs';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { useCoachNotesForm } from 'app/coach/coach-notes/useCoachNotesForm';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import { useAppState } from 'app/state';
import { isCoachOrSupervisor } from 'utils';
import React from 'react';

enum FieldLabels {
  TITLE = 'Title this note',
  NOTES = 'Notes *',
}

export enum Placeholders {
  TITLE = 'Unique name...',
  NOTES = 'Outreach, scheduling, other uncategorized details...',
}

const TOOLTIP_EVENT_LABEL = 'Coach Notes > Quick Note';

export const QuickNote = ({
  readOnly,
  onStateChange,
  'data-testid': testId = 'quickNote',
}: {
  readOnly: boolean;
  onStateChange: (noteDraft: CoachNotesItem) => void;
  'data-testid'?: string;
}): React.ReactElement | null => {
  const { isCoach } = useAppState(({ user: { role } }) => ({
    isCoach: isCoachOrSupervisor(role),
  }));
  const { fields } = useCoachNotesForm<QuickNoteVaultType>({
    onStateChange,
    validationSchema: quickNote,
  });

  return (
    <div data-testid={testId} className={styles.cardSection}>
      <FieldLabel className={styles.label} labelText={FieldLabels.TITLE} />
      <CardTextAreaField
        field={fields.title}
        name="coachNotesQuickNoteTitle"
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.TITLE}`}
        tooltipText=""
        placeholder={Placeholders.TITLE}
        readOnly={readOnly}
        multiline={false}
      />

      {isCoach && (
        <>
          <FieldLabel className={styles.label} labelText={FieldLabels.NOTES} />
          <CardTextAreaField
            name="coachNotesQuickNoteNotes"
            field={fields.notes}
            tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.NOTES}`}
            tooltipText=""
            placeholder={Placeholders.NOTES}
            readOnly={readOnly}
          />
        </>
      )}
    </div>
  );
};
