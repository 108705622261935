import { CareProviderInboxLoader } from 'app/inbox/CareProviderInboxLoader';
import { InboxSectionItemProps } from 'app/inbox/components/InboxSectionItem';
import TabPanel from 'app/inbox/components/TabPanel';
import { InboxItem, InboxSections, InboxViewResponse } from 'app/inbox/types';
import { Status } from 'app/state/status/types/StateSlice';
import { PaginationInput } from 'generated/globalTypes';

import { InboxAllTab } from './InboxAllTab';
import { InboxErrorState } from './InboxError/InboxErrorState';
import { InboxTodaysTab } from './InboxTodaysTab';

export type DataStateProps = {
  onItemClick: InboxSectionItemProps['onItemClick'];
  loadMore: (
    input: PaginationInput,
    section: InboxSectionItemProps['section'],
  ) => Promise<void>;
};

interface InboxViewParams extends InboxViewResponse {
  tabIndex: number;
  onItemClick: (item: InboxItem, section: InboxSections) => void;
}

export function InboxView(props: Readonly<InboxViewParams>) {
  const {
    tabIndex,
    todaysTab,
    allTab,
    onSearch,
    loadMore,
    onItemClick,
  } = props;
  return (
    <>
      <TabPanel selectedTabValue={tabIndex} selectedTabIndex={0}>
        {todaysTab.status === Status.LOADING && <CareProviderInboxLoader />}
        {todaysTab.status === Status.ERROR && (
          <InboxErrorState error={todaysTab.error} />
        )}
        {todaysTab.status === Status.COMPLETE && (
          <InboxTodaysTab onItemClick={onItemClick} loadMore={loadMore} />
        )}
      </TabPanel>
      <TabPanel selectedTabValue={tabIndex} selectedTabIndex={1}>
        {allTab.status === Status.LOADING && <CareProviderInboxLoader />}
        {allTab.status === Status.ERROR && (
          <InboxErrorState error={allTab.error} />
        )}
        {allTab.status === Status.COMPLETE && (
          <InboxAllTab
            onItemClick={onItemClick}
            loadMore={loadMore}
            onSearch={onSearch}
          />
        )}
      </TabPanel>
    </>
  );
}
