import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';

// for now, RTKQ supports only optional props in extra options
export interface CustomExtraOptions {
  server?: ServerType;
}

export type BaseQueryWithExtraOptions = BaseQueryFn<
  FetchArgs | string,
  unknown,
  FetchBaseQueryError,
  CustomExtraOptions
>;

export enum ServerType {
  Web = 'Web',
  Listener = 'Listener',
}

export type PaginationArguments = {
  limit?: number;
  cursor?: string;
};
