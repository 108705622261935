import Titles from 'app/member-chart-cards/constants/cards-titles';

import { StateForCard } from './types';

export const getDefaultStateForCards = () => {
  return Object.values(Titles).reduce((acc, title) => {
    acc[title] = true;
    return acc;
  }, {} as StateForCard);
};

export const getInitialState = () => ({
  cardsExpansion: JSON.parse(
    localStorage.getItem(localStorageKeyForCards) ?? '{}',
  ),
});

export const persistedLayoutSliceName = 'persistedLayoutSlice';
export const localStorageKeyForCards = 'cardsExpansion';
