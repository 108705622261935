import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TimeTokensAndStamps, TimetokensMap } from 'app/coach/chat/types';
import { IConversationTimetokensSlice } from './types';
import { TimetokensService } from './TimetokensService';
import { mergeTimetokensMapByOverriding } from './util';

// no pending state here since we don't need to display spinners / other UI when the time tokens are updated
export const initialState: IConversationTimetokensSlice = {
  timetokensMap: {},
};

export const timetokensSliceName = 'conversationTimetokens';

const conversationTimetokensSlice = createSlice({
  name: timetokensSliceName,
  initialState,
  reducers: {
    updateTimetokens(
      state,
      {
        payload,
      }: PayloadAction<
        Array<{ channelId: string; timetokens: Partial<TimeTokensAndStamps> }>
      >,
    ) {
      const updatedTimetokensMap = payload.reduce<TimetokensMap>(
        (accum, timetokensUpdates) => {
          const { channelId } = timetokensUpdates;
          accum[channelId] = {
            //take existing timetokens
            ...state.timetokensMap[channelId],
            //override only timetokens from the payload
            ...timetokensUpdates.timetokens,
          };
          return accum;
        },
        {},
      );
      state.timetokensMap = mergeTimetokensMapByOverriding(
        state.timetokensMap,
        updatedTimetokensMap,
      );
    },
    addTimetokens(
      state,
      { payload }: PayloadAction<{ timetokensMap: TimetokensMap }>,
    ) {
      state.timetokensMap = mergeTimetokensMapByOverriding(
        state.timetokensMap,
        payload.timetokensMap,
      );
    },
  },
  // we can use extra reducers API to respond to actions of other slices
  extraReducers: (builder) => {
    builder.addCase(
      TimetokensService.queryTimetokens.fulfilled,
      (state, { payload: updatedTimetokensMap }) => {
        state.timetokensMap = mergeTimetokensMapByOverriding(
          state.timetokensMap,
          updatedTimetokensMap,
        );
      },
    );
    builder.addCase(
      TimetokensService.updateConversationTimetokens.fulfilled,
      (state, { payload }) => {
        if (payload) {
          const { channelId, updatedTimetokens } = payload;
          state.timetokensMap[channelId] = updatedTimetokens;
        }
        //no else since the only thing that needs to be done in this case is logging, which is handled by the TimeTokensService
      },
    );
  },
});

export default conversationTimetokensSlice.reducer;

export const {
  updateTimetokens,
  addTimetokens,
} = conversationTimetokensSlice.actions;
