import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import { useOnMount } from 'hooks/useOnMount';
import { Loader } from 'shared-components/loader/Loader';
import { MemberChartErrorState } from 'app/member-chart-cards/error-state/MemberChartErrorState';
import { useLogger } from 'app/state/log/useLogger';
import { oktaTokenExpirationDate } from 'utils';
import React, { useEffect } from 'react';

function OktaErrorComponent({ error }: { error: Error }) {
  const logger = useLogger();
  useOnMount(() => {
    logger.warning('OktaCallbackScreen: Okta login error', { error });
  });
  return <MemberChartErrorState error={error} />;
}

export function OktaCallbackScreen() {
  const logger = useLogger();
  const { authState } = useOktaAuth();
  const idToken = authState?.idToken?.idToken;
  useEffect(() => {
    if (idToken) {
      logger.info(
        'OktaCallbackScreen: Okta login complete, handling authentication',
        {
          oktaExpiration: oktaTokenExpirationDate(idToken)?.toISOString(),
        },
      );
    }
  }, [idToken]);
  return (
    <LoginCallback
      errorComponent={OktaErrorComponent}
      loadingElement={<Loader />}
    />
  );
}
