import { Button } from '@mui/material';
import { appTheme } from 'app/AppTheme';
import { Routes } from 'app/top-nav/Routes';
import { GridCell } from 'shared-components/grid-layout/GridCell';
import { ReactComponent as NewTabIcon } from 'assets/ic-open-in-new-tab.svg';
import { BackgroundTaskCardProps } from 'app/member-chart-cards/member-background/BackgroundCard';
import { useAppState } from 'app/state';
import { isCoachOrSupervisor } from 'utils';
import React, { useMemo } from 'react';

import styles from './TeenIntakeFormSection.module.scss';

export type TeenIntakeFormSectionProps = BackgroundTaskCardProps & {
  memberId: string;
};

export const TeenIntakeFormSection = (
  props: TeenIntakeFormSectionProps,
): React.ReactElement => {
  const { memberId } = props;
  const { role } = useAppState((_) => _.user);
  const teenIntakeRoute = useMemo(() => {
    if (isCoachOrSupervisor(role)) {
      return Routes.COACHING_PATIENTS_TEEN_INTAKE;
    }

    return Routes.PATIENTS_TEEN_INTAKE;
  }, [role]);

  return (
    <GridCell>
      <Button
        data-testid="teenIntakeButton"
        href={teenIntakeRoute.replace(':patientId', memberId)}
        target="_blank"
        size="small"
        startIcon={<NewTabIcon stroke={appTheme.palette.primary.main} />}
        fullWidth={false}
        className={styles.link}
        rel="noopener noreferrer"
      >
        Open Teen Intake
      </Button>
    </GridCell>
  );
};
