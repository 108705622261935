import { KeyGenerator } from '@ginger.io/vault-core/dist/crypto';
import ErrorBoundary from 'shared-components/error-state/ErrorBoundary';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { Accordion } from 'shared-components/Accordion';
import { Card } from 'shared-components/Card';
import Titles from 'app/member-chart-cards/constants/cards-titles';
import { MemberChartErrorState } from 'app/member-chart-cards/error-state/MemberChartErrorState';
import { CareTeamNotesSection } from 'app/member-chart-cards/member-background/CareTeamNotesSection';
import { MemberNotesSection } from 'app/member-chart-cards/member-background/MemberNotesSection';
import React from 'react';

import { TeenIntakeFormSection } from './TeenIntakeFormSection';

export type BackgroundTaskCardProps = {
  memberId: string;
  generateId?: () => string;
  keyGenerator?: KeyGenerator;
  'data-testid'?: string;
  isTeen?: boolean;
  intakeCompleted?: boolean;
};

export const BackgroundCard = (
  props: BackgroundTaskCardProps,
): React.ReactElement | null => {
  const {
    memberId,
    generateId,
    keyGenerator,
    'data-testid': testId = 'BackgroundCard',
    isTeen = false,
    intakeCompleted = false,
  } = props;

  const {
    enable_member_background_member_notes_section,
  } = useFeatureFlags().transientFeatureFlags;

  if (!enable_member_background_member_notes_section) {
    return (
      <Card
        data-testid={testId}
        boxTitle={Titles.MEMBER_BACKGROUND_TITLE}
        size="fit-container"
      >
        <CareTeamNotesSection
          memberId={memberId}
          generateId={generateId}
          keyGenerator={keyGenerator}
        />
      </Card>
    );
  }

  return (
    <Card
      data-testid={testId}
      boxTitle={Titles.MEMBER_BACKGROUND_TITLE}
      size="fit-container"
    >
      {isTeen && intakeCompleted && (
        <Accordion
          accordionTitle="Teen Intake Form"
          testId="member-background-teen-intake-form"
        >
          <TeenIntakeFormSection memberId={memberId} />
        </Accordion>
      )}
      <Accordion
        accordionTitle="Care Team Notes"
        testId="member-background-care-team-notes"
      >
        <CareTeamNotesSection
          memberId={memberId}
          generateId={generateId}
          keyGenerator={keyGenerator}
        />
      </Accordion>
      <Accordion
        accordionTitle="Member Notes At Account Creation"
        testId="member-background-member-notes"
        isExpandedByDefault={true}
      >
        <ErrorBoundary FallbackComponent={MemberChartErrorState}>
          <MemberNotesSection memberId={memberId} />
        </ErrorBoundary>
      </Accordion>
    </Card>
  );
};
