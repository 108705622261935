import {
  field,
  FieldDefinitions,
  nonEmptyArrayField,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  PresentingProblem,
  PresentingProblem_SymptomImpact,
  PresentingProblem_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/PresentingProblem';
import {
  PresentingProblemSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import { EnumCheckboxesField, TextAreaField } from 'app/notes-ui/forms/fields';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { AUTO_SAVE_DRAFT_DELAY } from 'app/notes-ui/utils';
import React from 'react';

type Props = {
  appointmentId: string;
  initialValue?: PresentingProblem;
  onSubmit: (presentingProblem: PresentingProblemSection) => void;
  updateDraftNoteState: (data: PresentingProblemSection) => void;
  disabled?: boolean;
  savingNote?: boolean;
};

export const fieldDefs: FieldDefinitions<PresentingProblem> = {
  appointmentId: stringField(),
  currentTreatmentDescription: stringField({ rules: [] }),
  historyOfPresentingProblem: stringField(),
  impactOfSymptoms: nonEmptyArrayField(),
  pastMentalHealthTreatment: stringField({ rules: [] }),
  presentingProblem: stringField(),
  receivingTreatmentBeyondClinician: field({ rules: [] }),
  symptomsReported: stringField(),
  version: field<PresentingProblem_Version>({
    default: PresentingProblem_Version.undefined_version,
  }),
};

export function PresentingProblemForm(props: Props) {
  const { appointmentId, initialValue, disabled } = props;

  const { fields, validate, getValue } = useForm<PresentingProblem>(
    fieldDefs,
    { ...initialValue, appointmentId } as PresentingProblem,
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: TherapyIntakeSectionName.PRESENTING_PROBLEM,
        }),
    },
  );

  const onSubmit = async () => {
    if (await validate()) {
      props.onSubmit({
        data: getValue(),
        name: TherapyIntakeSectionName.PRESENTING_PROBLEM,
      });
    }
  };

  return (
    <>
      <TextAreaField
        disabled={disabled}
        testId="presentingProblem"
        label="Presenting Problem*"
        field={fields.presentingProblem}
        tooltip="In client’s own words."
      />

      <TextAreaField
        disabled={disabled}
        testId="historyOfPresentingProblem"
        label="History of presenting problem*"
        field={fields.historyOfPresentingProblem}
      />

      <TextAreaField
        disabled={disabled}
        testId="symptomsReported"
        label="Symptoms reported*"
        field={fields.symptomsReported}
        tooltip="Please include all necessary symptoms to support diagnosis, as well as others mentioned"
      />

      <EnumCheckboxesField
        disabled={disabled}
        testId="impactOfSymptoms"
        label="How is this impacting things?*"
        options={PresentingProblem_SymptomImpact}
        field={fields.impactOfSymptoms}
      />
      <SaveButton
        isLoading={props.savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </>
  );
}

export const validate = (data: PresentingProblem | null) =>
  data !== null && validateForm(data, fieldDefs);
