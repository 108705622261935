import { GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions as CoachingSessions } from '@headspace/carehub-graphql/dist/scheduler/generated/GetMemberUpcomingCoachingSessions';
import {
  getAvailabilitiesForMe,
  getMemberUpcomingCoachingSessionsQuery,
} from '@headspace/carehub-graphql/dist/scheduler/queries';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Availability } from 'app/member-chart-cards/scheduler/types';
import { getMemberSessionUsage_getMemberSessionUsage as GetMemberSessionUsage } from 'app/scheduler/generated/getMemberSessionUsage';
import { getMemberSessionUsage } from 'app/scheduler/queries';
import { Services } from 'app/services';
import { GetServiceProvisionIntervalsForMember_getServiceProvisionIntervalsForMember_intervals as MemberServiceProvisionInterval } from 'app/state/features/scheduler/generated/GetServiceProvisionIntervalsForMember';
import { AppDispatch, RootState } from 'app/state/hooks/baseTypedHooks';
import { RejectValue } from 'app/state/middlewares/types';
import { GetAvailableTimeSlotsForMeInput } from 'generated/globalTypes';

import { getServiceProvisionIntervalsForMemberQuery } from './queriesAndMutations';
import { SchedulerFormatter } from './SchedulerFormatter';
import { MemberSessionUsage } from './types';

export class SchedulerService {
  static readonly getMemberUpcomingCoachingSessions = createAsyncThunk<
    CoachingSessions[] | undefined,
    { memberId: string },
    {
      dispatch: AppDispatch;
      state: RootState;
      extra: {
        services: Services;
        formatters: {
          schedulerFormatter: SchedulerFormatter;
        };
      };
      rejectValue: RejectValue;
    }
  >(
    'scheduler/getMemberUpcomingCoachingSessions',
    async ({ memberId }, thunkAPI) => {
      const {
        extra: {
          services: { apollo },
        },
        rejectWithValue,
      } = thunkAPI;

      const logPrefix = `SchedulerService.getMemberUpcomingCoachingSessions`;

      try {
        const { data, errors } = await apollo.query({
          fetchPolicy: 'network-only',
          query: getMemberUpcomingCoachingSessionsQuery,
          variables: { input: { memberId } },
        });

        if (errors) {
          const errorMessage = errors.map((e) => e.message).join('; ');
          return rejectWithValue({
            error: new Error(
              `${logPrefix}: GraphQL errors occurred: ${errorMessage}`,
            ),
          });
        }

        if (!data?.getMemberUpcomingCoachingSessions) {
          return rejectWithValue({
            error: new Error(
              `${logPrefix}: No data returned from the coaching sessions query`,
            ),
          });
        }

        if (data.getMemberUpcomingCoachingSessions.error) {
          return rejectWithValue({
            error: new Error(data.getMemberUpcomingCoachingSessions.error),
          });
        }

        return data.getMemberUpcomingCoachingSessions.coachingSessions;
      } catch (error) {
        return rejectWithValue({
          error: new Error(
            `${logPrefix}: An error occurred fetching coaching sessions for member.`,
            {
              cause: error,
            },
          ),
        });
      }
    },
  );

  static readonly getAvailabilitiesForMe = createAsyncThunk<
    Availability[],
    { input: GetAvailableTimeSlotsForMeInput },
    {
      dispatch: AppDispatch;
      state: RootState;
      extra: {
        services: Services;
        formatters: {
          schedulerFormatter: SchedulerFormatter;
        };
      };
      rejectValue: RejectValue;
    }
  >('scheduler/getAvailabilitiesForMe', async ({ input }, thunkAPI) => {
    const {
      extra: {
        services: { apollo },
        formatters: { schedulerFormatter },
      },
      rejectWithValue,
    } = thunkAPI;

    const logPrefix = `SchedulerService.getAvailabilitiesForMe`;

    try {
      const { data, errors } = await apollo.query({
        fetchPolicy: 'network-only',
        query: getAvailabilitiesForMe,
        variables: { input },
      });

      if (errors) {
        const errorMessage = errors.map((e) => e.message).join('; ');
        return rejectWithValue({
          error: new Error(
            `${logPrefix}: GraphQL errors occurred: ${errorMessage}`,
          ),
        });
      }

      if (
        !data?.getAvailabilitiesForMe ||
        data?.getAvailabilitiesForMe?.error
      ) {
        return rejectWithValue({
          error: new Error(
            data.getAvailabilitiesForMe.error ||
              `${logPrefix}: No data returned from getAvailabilitiesForMe query`,
          ),
        });
      }

      const formattedSchedule = schedulerFormatter.formatAvailabilitySchedule(
        data.getAvailabilitiesForMe.timeSlotSeries,
      );
      if (!formattedSchedule) {
        return rejectWithValue({
          error: new Error(
            `${logPrefix}: Failed to format availability schedule`,
          ),
        });
      }
      return formattedSchedule;
    } catch (error) {
      return rejectWithValue({
        error: new Error(
          `${logPrefix}: An error occurred while fetching the coach availability schedule`,
          { cause: error },
        ),
      });
    }
  });

  static async getD2cSessionInfo(
    apollo: Services['apollo'],
    memberId: string,
  ): Promise<MemberServiceProvisionInterval | null> {
    const { data: provisionData, errors: provisionErrors } = await apollo.query(
      {
        fetchPolicy: 'network-only',
        query: getServiceProvisionIntervalsForMemberQuery,
        variables: { input: { memberId } },
      },
    );

    const logPrefix = `SchedulerService.getD2cSessionInfo`;

    if (provisionErrors) {
      const errorMessage = provisionErrors.map((e) => e.message).join('; ');
      throw new Error(`${logPrefix}: ${errorMessage}`);
    }

    if (
      !provisionData?.getServiceProvisionIntervalsForMember?.intervals?.length
    ) {
      return null;
    }

    return provisionData.getServiceProvisionIntervalsForMember.intervals[0];
  }

  static readonly getMemberSessionUsage = createAsyncThunk<
    MemberSessionUsage | undefined,
    { memberId: string; isD2c?: boolean | null },
    {
      dispatch: AppDispatch;
      state: RootState;
      extra: {
        services: Services;
        formatters: {
          schedulerFormatter: SchedulerFormatter;
        };
      };
      rejectValue: RejectValue;
    }
  >(
    'scheduler/getMemberSessionUsage',
    async ({ memberId, isD2c }, thunkAPI) => {
      const {
        extra: {
          services: { apollo },
        },
        rejectWithValue,
      } = thunkAPI;

      const logPrefix = `SchedulerService.getMemberSessionUsage`;

      const now = new Date();
      let numChatSessions = 0;
      let numVideoSessions = 0;
      let isAutorenew = false;
      let endsAt = now.toISOString();
      let startsAt = now.toISOString();

      try {
        if (isD2c) {
          const interval = await SchedulerService.getD2cSessionInfo(
            apollo,
            memberId,
          );

          if (interval) {
            const {
              numSessions: intervalNumSessions,
              numVideoSessions: intervalNumVideoSessions,
              isAutorenew: intervalIsAutorenew,
              endsAt: intervalEndsAt,
              startsAt: intervalStartsAt,
            } = interval;

            numChatSessions = intervalNumSessions;
            numVideoSessions = intervalNumVideoSessions ?? 0;
            isAutorenew = intervalIsAutorenew;
            endsAt = intervalEndsAt;
            startsAt = intervalStartsAt;
          }
        }

        const {
          data: sessionsUsedData,
          errors: sessionsUsedErrors,
        } = await apollo.query({
          fetchPolicy: 'network-only',
          query: getMemberSessionUsage,
          variables: {
            input: { fromDate: startsAt, memberId, toDate: endsAt },
          },
        });

        if (!sessionsUsedData || sessionsUsedErrors) {
          const errorMessage = sessionsUsedErrors
            ? sessionsUsedErrors.map((e) => e.message).join('; ')
            : 'Did not receive sessionsUsedData.';
          return rejectWithValue({
            error: new Error(`${logPrefix}: ${errorMessage}`),
          });
        }

        const {
          sessionUsage,
        } = sessionsUsedData.getMemberSessionUsage as GetMemberSessionUsage;

        const usedChatSessions = sessionUsage?.usedChatSessions ?? 0;
        const chatSessionsRemaining = numChatSessions - usedChatSessions;

        const usedVideoSessions = sessionUsage?.usedVideoSessions ?? 0;
        const videoSessionsRemaining = numVideoSessions - usedVideoSessions;

        const nextEligibleVideoSessionDate = new Date(
          Math.max(
            sessionUsage?.nextEligibleVideoSessionDate
              ? new Date(sessionUsage.nextEligibleVideoSessionDate).getTime()
              : 0,
            now.getTime(),
          ),
        ).toISOString();

        return {
          chatSessions: {
            numSessions: numChatSessions,
            sessionsRemaining: Math.max(chatSessionsRemaining, 0),
            usedSessions: usedChatSessions,
          },
          isAutorenew,
          memberId,
          nextEligibleVideoSessionDate,
          sessionEndDate: isD2c ? endsAt : undefined,
          videoSessions: {
            numSessions: numVideoSessions,
            sessionsRemaining: Math.max(videoSessionsRemaining, 0),
            usedSessions: usedVideoSessions,
          },
        };
      } catch (error) {
        return rejectWithValue({
          error: new Error(`${logPrefix}: An error occurred`, { cause: error }),
        });
      }
    },
  );
}
