import { createAsyncThunk } from '@reduxjs/toolkit';
import { TimetokensMap } from 'app/coach/chat/types';
import { Formatters, Services } from 'app/services';
import { AppDispatch, RootState } from 'app/state/hooks/baseTypedHooks';
import { RejectValue } from 'app/state/middlewares/types';

import {
  getInboxConversationTimetokens,
  getInboxConversationTimetokensVariables,
} from './generated/getInboxConversationTimetokens';
import {
  updateChatTimetoken,
  updateChatTimetokenVariables,
} from './generated/updateChatTimetoken';
import {
  GetTimetokensForConversations,
  updateConversationTimetokenMutation,
} from './queriesAndMutations';
import { UpdateTimetokensResult } from './types';

export class TimetokensService {
  /* Async thunks should be used for async logic (for example, for network requests) or complex sync logic.
Even thiough inside of the thunk we have access to the global state (thunkAPI.getState()) and all services (thunkAPI.extra.services) please
be careful about the dependencies you need (and don't need) to inject for a single thunk to keep it testable and maintainable. */
  static queryTimetokens = createAsyncThunk<
    TimetokensMap,
    { memberIds: string[] },
    {
      dispatch: AppDispatch;
      state: RootState;
      extra: {
        services: Services;
        formatters: Formatters;
      };
      rejectValue: RejectValue;
    }
  >('conversationTimetokens/query', async ({ memberIds }, thunkAPI) => {
    const {
      extra: {
        services: { apollo },
        formatters: { timetokensFormatter },
      },
      rejectWithValue,
    } = thunkAPI;
    try {
      const { data, error: apolloError } = await apollo.query<
        getInboxConversationTimetokens,
        getInboxConversationTimetokensVariables
      >({
        fetchPolicy: 'network-only',
        query: GetTimetokensForConversations,
        variables: { memberIds },
      });
      // no check on the business rules in the thunk, only checking if the response has something to handle
      if (!data.getInboxConversationByIds || apolloError) {
        const error = new Error(
          'TimetokensService.queryTimetokens: Unable to fetch conversation timetokens, unexpected getInboxConversationByIds value',
          {
            cause: apolloError ?? undefined,
          },
        );
        const additionalInfo: RejectValue = {
          error,
          memberIds,
        };
        return rejectWithValue({
          ...additionalInfo,
        });
      }

      return timetokensFormatter.buildTimetokensMap(
        data.getInboxConversationByIds,
      );
    } catch (error) {
      // when we get into this block, it means a user has a network issue, responses w/ 4xx, 5xx codes and apollo responses w/ {code: 200, error: true} don't end up there
      // simplify the debugging by adding unique and helpful error messages
      return rejectWithValue({
        error: new Error(
          'TimetokensService.queryTimetokens: Unable to fetch conversation timetokens, queryTimetokens ended up in a catch block',
          {
            cause: error,
          },
        ),
      });
    }
  });

  static updateConversationTimetokens = createAsyncThunk<
    UpdateTimetokensResult | undefined,
    {
      conversationId: string | null;
      lastListenerReadTimetoken?: string;
      lastListenerWriteTimetoken?: string;
    },
    {
      dispatch: AppDispatch;
      state: RootState;
      extra: {
        services: Services;
        formatters: Formatters;
      };
      rejectValue: RejectValue;
    }
  >(
    'conversationTimetokens/mutate',
    async (
      { conversationId, lastListenerReadTimetoken, lastListenerWriteTimetoken },
      thunkAPI,
    ) => {
      const {
        extra: {
          services: { apollo },
          formatters: { timetokensFormatter },
        },
        rejectWithValue,
      } = thunkAPI;
      try {
        // we need a conversationId and at least one timetoken for the mutation; rejectWithValue to log the info if we don't have it
        if (
          !conversationId ||
          (!lastListenerReadTimetoken && !lastListenerWriteTimetoken)
        ) {
          const additionalInfo: RejectValue = {
            conversationId,
            error: new Error(
              'updateConversationTimetokens was called with invalid params',
            ),
            lastListenerReadTimetoken,
            lastListenerWriteTimetoken,
          };
          return rejectWithValue({
            ...additionalInfo,
          });
        }
        const input = {
          conversationId,
          ...(lastListenerReadTimetoken && {
            listenerReadTimetoken: lastListenerReadTimetoken,
          }),
          ...(lastListenerWriteTimetoken && {
            listenerWriteTimetoken: lastListenerWriteTimetoken,
          }),
        };
        const { data } = await apollo.mutate<
          updateChatTimetoken,
          updateChatTimetokenVariables
        >({
          fetchPolicy: 'network-only',
          mutation: updateConversationTimetokenMutation,
          variables: {
            input,
          },
        });

        if (
          !data?.updateChatConversationTimetoken?.success ||
          !data?.updateChatConversationTimetoken.conversationStats ||
          data?.updateChatConversationTimetoken.error
        ) {
          const additionalInfo: RejectValue = {
            conversationId,
            error: new Error(
              'Failed to update conversation timetokens: request returned an error',
            ),
            errorInUpdateChatConversationTimetoken:
              data?.updateChatConversationTimetoken?.error,
            lastListenerReadTimetoken,
            lastListenerWriteTimetoken,
            success: data?.updateChatConversationTimetoken?.success,
          };
          return rejectWithValue({
            ...additionalInfo,
          });
        }
        return timetokensFormatter.buildTimetokensUpdate(
          data.updateChatConversationTimetoken,
        );
      } catch (error) {
        // when we get into this block, it means a user has a network issue, responses w/ 4xx, 5xx codes and apollo responses w/ {code: 200, error: true} don't end up there
        return rejectWithValue({
          error: new Error(
            'Caught an error in response to the updateTimeToken mutation, a timetoken has not been updated.',
            {
              cause: error,
            },
          ),
        });
      }
    },
  );
}
