import { ClassNameMap } from '@mui/material';
import MUITable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import React, { ChangeEvent, ReactNode, useState } from 'react';

import { TableBody } from './TableBody';
import { ActionItem, HeadCell, TableHead } from './TableHead';

export type TableClassKey = 'root' | 'head' | 'body' | 'row' | 'cell';

export type RowType<T> = { [key: string]: T };

interface TableProps<T, U> {
  rows: RowType<T>;
  columns: HeadCell<T>[];
  actionItems?: ActionItem<U>[];
  onActionItemClick?: (item: U, row: T) => void;
  disabled?: boolean;
  renderCell?: (props: RenderCellProps<T>) => ReactNode;
  showCheckboxes?: boolean; // hide or show checkbox in table
  classes?: Partial<ClassNameMap<TableClassKey>>;
  noDataText?: string;
  selectDefaults?: string[];
  dataTestId?: string;
}

export interface RenderCellProps<T> {
  row: T;
  column: HeadCell<T>;
  selected: boolean;
}

export function Table<T, U>(props: TableProps<T, U>) {
  const {
    rows,
    columns,
    disabled,
    renderCell,
    showCheckboxes = true,
    dataTestId = 'table',
    noDataText,
    onActionItemClick,
  } = props;
  const { selectDefaults = [], actionItems = [], classes = {} } = props;

  const [selected, setSelected] = useState(selectDefaults);

  const onSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const updateSelected = Object.keys(rows);
      setSelected(updateSelected);
      return;
    }
    setSelected([]);
  };

  const onSelectClick = (row: string) => {
    const selectedIndex = selected.indexOf(row);
    let newSelected = [...selected];

    if (selectedIndex === -1) {
      newSelected.push(row);
    } else {
      newSelected = newSelected.filter(
        (selectedValue) => selectedValue !== row,
      );
    }
    setSelected([...newSelected]);
  };

  return (
    <TableContainer>
      <MUITable
        data-testid={dataTestId}
        classes={{ root: classes.root }}
        aria-labelledby="table"
        aria-label="table"
      >
        <TableHead
          data-testid="tableHead"
          classes={{ cell: classes.cell, root: classes.head, row: classes.row }}
          showCheckboxes={showCheckboxes}
          columns={columns}
          numSelected={selected.length}
          onSelectAllClick={onSelectAllClick}
          rowCount={Object.keys(rows).length}
          disableActionItem={!actionItems.length}
        />
        <TableBody
          dataTestId="tableBody"
          classes={{ cell: classes.cell, root: classes.body, row: classes.row }}
          showCheckboxes={showCheckboxes}
          selectedRows={selected}
          onSelectClick={onSelectClick}
          disabled={disabled}
          rows={rows}
          columns={columns}
          renderCell={renderCell}
          actionItems={actionItems}
          onActionItemClick={onActionItemClick}
          noDataText={noDataText}
        />
      </MUITable>
    </TableContainer>
  );
}
