import { InboxItem } from 'app/inbox/types';
import { useAppState, useDispatch } from 'app/state';
import { restoreDrawerState } from 'app/state/inbox/actions';
import {
  initializeMultiTabState,
  setActiveTab,
} from 'app/state/member-tabs/actions';
import memberChartStorage from 'app/state/member-tabs/memberChartStorage';
import { State as MultiTabState } from 'app/state/member-tabs/schema';
import { useEffect } from 'react';

function useMultiTabs(): MultiTabState & {
  initializeTabState: () => void;
  openNewTab: (item: InboxItem, hasRisk?: boolean) => void;
} {
  const dispatch = useDispatch();
  const multiTab = useAppState(({ multiTab }) => multiTab);
  const { initialized, activeTab, openTabs } = multiTab;

  const initializeTabState = () => {
    if (initialized) return;
    dispatch(restoreDrawerState({}));
    dispatch(initializeMultiTabState({}));
  };

  const openNewTab = (item: InboxItem, hasRisk: boolean = false): void =>
    dispatch(setActiveTab(item.id));

  useEffect(() => {
    if (!initialized) return;

    memberChartStorage.set({ activeMemberId: activeTab, activeTab, openTabs });
  }, [openTabs, activeTab, initialized]);

  return { initializeTabState, openNewTab, ...multiTab };
}

export default useMultiTabs;
