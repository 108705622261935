import { useForm } from '@ginger.io/react-use-form';
import { SafetyPlan } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/safety/SafetyPlan';
import { Typography } from '@mui/material';
import { CardTextAreaField } from 'app/coach/coach-notes/note-detail-view/note-fields';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import { CardTextArea } from 'app/member-chart-cards/text-area/CardTextArea';
import { psychiatryFieldDefs } from 'app/notes-ui/shared/safety-intake/schema';
import { Labels } from 'app/notes-ui/shared/safety-progress/constants';
import { Subsection } from 'app/vault/api/ShareableSubsectionTypes';
import React from 'react';

enum FieldLabels {
  AGENCIES_TO_CONTACT = 'Professionals / agencies I can contact during a crisis:',
  SAFETY_PLAN = 'Document a safety plan',
}

export const PsychiatrySafetyPlan = ({
  selectedNote,
  readOnly,
  tooltipEventLabel,
}: {
  readOnly: boolean;
  tooltipEventLabel: string;
  selectedNote?: Subsection;
}): React.ReactElement | null => {
  const { fields } = useForm<SafetyPlan>(psychiatryFieldDefs.safetyPlan, {
    ...(selectedNote as SafetyPlan),
  });

  return (
    <>
      <div className={styles.titleField}>
        <Typography className={styles.riskAssessmentTitle}>
          Safety Plan
        </Typography>
        <span className={styles.inlineBorder} />
      </div>
      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.SAFETY_PLAN}
      />
      <CardTextAreaField
        field={fields.psychiatryQuestions.safetyPlanDocumentation}
        tooltipEventLabel={`${tooltipEventLabel}: ${FieldLabels.SAFETY_PLAN}`}
        tooltipText=""
        placeholder=""
        readOnly={readOnly}
      />
      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.AGENCIES_TO_CONTACT}
      />
      {[
        ...Labels.safetyPlan.psychiatryQuestions.agenciesToContactInCrisis.subtexts[0].split(
          '\n',
        ),
        fields.psychiatryQuestions.agenciesToContactInCrisis.value,
      ].map((agencies, index) => (
        <CardTextArea
          key={`${agencies}-${index}`}
          value={agencies}
          tooltipEventLabel={`${tooltipEventLabel}: ${FieldLabels.AGENCIES_TO_CONTACT}`}
          tooltipText=""
          placeholder=""
          readOnly={readOnly}
          minRows={1}
        />
      ))}
    </>
  );
};
