import gql from 'graphql-tag';

export const getMemberCareTeam = gql`
  query GetMemberCareTeam($id: ID!, $timezone: String) {
    getMember(input: { id: $id }) {
      id
      intakeSent
      dateOfBirth
      guardianRelationship {
        guardianRelationshipType
      }
      coachingCareTeam {
        current {
          coaches {
            id
            name
            avatar
            memberMessagesExchanged
            coachMessagesExchanged
            memberLastEngagement
            coachLastEngagement
            isTeamLead
            isBackup
            careTeamRole
            roleShift
            state
            specializations
            timezone
            gingerId
            description(input: { timezone: $timezone })
            previousSessionDate
            nextSessionDate
            relationshipLastUpdated
            broadcastChannelId
          }
          ms {
            id
            name
            avatar
            memberMessagesExchanged
            msMessagesExchanged
            memberLastEngagement
            msLastEngagement
            timezone
          }
        }
        past {
          coaches {
            id
            name
            avatar
            memberMessagesExchanged
            coachMessagesExchanged
            memberLastEngagement
            coachLastEngagement
            isTeamLead
            isBackup
            careTeamRole
            roleShift
            state
            specializations
            timezone
            gingerId
            description(input: { timezone: $timezone })
            previousSessionDate
            nextSessionDate
            relationshipLastUpdated
          }
          ms {
            id
            name
            avatar
            memberMessagesExchanged
            msMessagesExchanged
            memberLastEngagement
            msLastEngagement
            timezone
          }
        }
      }
      clinicalCareTeam {
        current {
          therapists {
            id
            fullName
            avatar
            specializations
            educationQualifications
            approachToCare
            moreAboutMe
            backgroundSpecialities
            totalCompletedSessions
            prevAppointmentDate
            nextAppointmentDate
          }
          psychiatrists {
            id
            fullName
            avatar
            specializations
            educationQualifications
            approachToCare
            moreAboutMe
            backgroundSpecialities
            totalCompletedSessions
            prevAppointmentDate
            nextAppointmentDate
          }
        }
        past {
          therapists {
            id
            fullName
            avatar
            specializations
            educationQualifications
            approachToCare
            moreAboutMe
            backgroundSpecialities
            totalCompletedSessions
            prevAppointmentDate
            nextAppointmentDate
          }
          psychiatrists {
            id
            fullName
            avatar
            specializations
            educationQualifications
            approachToCare
            moreAboutMe
            backgroundSpecialities
            totalCompletedSessions
            prevAppointmentDate
            nextAppointmentDate
          }
        }
      }
      riskStatus {
        onRiskAlert
      }
    }
  }
`;

export const MemberCareTeamFragment = gql`
  fragment MemberCareTeamFragment on User {
    coachingCareTeam {
      current {
        coaches {
          id
          name
          avatar
          memberMessagesExchanged
          coachMessagesExchanged
          memberLastEngagement
          coachLastEngagement
          isTeamLead
          isBackup
          careTeamRole
          relationshipLastUpdated
          state
          specializations
          timezone
          gingerId
        }
        ms {
          id
          name
          avatar
          memberMessagesExchanged
          msMessagesExchanged
          memberLastEngagement
          msLastEngagement
          timezone
        }
      }
      past {
        coaches {
          id
          name
          avatar
          memberMessagesExchanged
          coachMessagesExchanged
          memberLastEngagement
          coachLastEngagement
          isTeamLead
          isBackup
          careTeamRole
          relationshipLastUpdated
          state
          specializations
          timezone
          gingerId
        }
        ms {
          id
          name
          avatar
          memberMessagesExchanged
          msMessagesExchanged
          memberLastEngagement
          msLastEngagement
          timezone
        }
      }
    }
    clinicalCareTeam {
      current {
        therapists {
          id
          fullName
          avatar
          specializations
          educationQualifications
          approachToCare
          moreAboutMe
          backgroundSpecialities
          totalCompletedSessions
          prevAppointmentDate
          nextAppointmentDate
        }
        psychiatrists {
          id
          fullName
          avatar
          specializations
          educationQualifications
          approachToCare
          moreAboutMe
          backgroundSpecialities
          totalCompletedSessions
          prevAppointmentDate
          nextAppointmentDate
        }
      }
      past {
        therapists {
          id
          fullName
          avatar
          specializations
          educationQualifications
          approachToCare
          moreAboutMe
          backgroundSpecialities
          totalCompletedSessions
          prevAppointmentDate
          nextAppointmentDate
        }
        psychiatrists {
          id
          fullName
          avatar
          specializations
          educationQualifications
          approachToCare
          moreAboutMe
          backgroundSpecialities
          totalCompletedSessions
          prevAppointmentDate
          nextAppointmentDate
        }
      }
    }
  }
`;
export const getEligibleSiCoachesStatusForMember = gql`
  query GetEligibleSiCoachesStatusForMember(
    $input: GetEligibleSICoachesForMemberInput!
  ) {
    getEligibleSiCoachesForMember(input: $input) {
      coaches {
        id
        name
        state {
          atPeak
          shiftStatus
        }
      }
    }
  }
`;
