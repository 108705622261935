import { useDispatch } from 'app/state';
import { updateSessionState } from 'app/state/features/auth/authSlice';
import { SessionState } from 'app/state/features/auth/types';
import React, { useEffect } from 'react';
import { BugReportButton } from 'shared-components/BugReportButton';
import { isGraphQLAuthenticationError } from 'shared-components/error-state/utils';

import styles from './InboxErrorState.module.scss';

export function InboxErrorState(props: { error: Error }) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isGraphQLAuthenticationError(props.error))
      dispatch(updateSessionState(SessionState.EXPIRED));
  }, [props.error]);

  return (
    <div data-test-id="inbox-error" className={styles.container}>
      <div>
        <h3 className={styles.title}>
          Uh oh!
          <br />
          Something went wrong 🐛
        </h3>
        <h4 className={styles.subtitle}>Let’s try this:</h4>
        <ul className={styles.list}>
          <li className={styles.item}>Refresh the page.</li>
          <li className={styles.item}>
            If that does not work please submit a bug report so we can get you
            back up and running!
          </li>
        </ul>
        <BugReportButton className={styles.bugBtn} errors={[props.error]} />
      </div>
    </div>
  );
}
