import MUICheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CheckedCheckboxIcon } from 'shared-components/icons/CheckedCheckboxIcon';
import { FormError } from 'shared-components/FormError';
import { UncheckedCheckboxIcon } from 'shared-components/icons/UncheckedCheckboxIcon';
import React from 'react';

import styles from './Checkbox.module.scss';
import { NoteTooltip } from './NoteTooltip';

type Props = {
  disabled?: boolean;
  tooltip?: string;
  checked: boolean;
  testId?: string;
  error?: string;
  label: string;
  checkBoxClassName?: string;
  rootClassName?: string;
  onChange: (checked: boolean) => void;
};

export function Checkbox(props: Props) {
  const {
    label,
    checked,
    checkBoxClassName,
    rootClassName,
    onChange,
    tooltip,
    testId,
  } = props;
  return (
    <>
      <FormControlLabel
        disabled={props.disabled}
        key={label}
        label={
          <span>
            {label} {tooltip && <NoteTooltip tooltip={tooltip} />}
          </span>
        }
        classes={{
          label: styles.checkboxLabel,
          root: rootClassName || styles.root,
        }}
        control={
          <MUICheckbox
            disabled={props.disabled}
            data-testid={testId}
            color="default"
            classes={{
              checked: checkBoxClassName || styles.checkbox,
              disabled: checkBoxClassName || styles.checkbox,
              root: rootClassName,
            }}
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
            name={label}
            icon={<UncheckedCheckboxIcon />}
            checkedIcon={<CheckedCheckboxIcon />}
          />
        }
      />
      {props.error && <FormError error={props.error} />}
    </>
  );
}
