import { Divider } from '@mui/material';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { classNameCombiner } from 'utils';
import React from 'react';

import styles from './TabPanel.module.scss';

export type Props = {
  title: string;
  children: React.ReactNode;
  testId?: string;
  hideDivider?: boolean;
  className?: string;
  ref?: React.Ref<HTMLDivElement>;
};

export function TabPanel({
  title,
  children,
  testId,
  hideDivider,
  className = '',
  ref = undefined,
}: Props) {
  const {
    enable_care_hub_notes_efficiency,
  } = useFeatureFlags().transientFeatureFlags;
  const panelStyle = enable_care_hub_notes_efficiency
    ? styles.tabPanelV2
    : styles.tabPanel;
  return (
    <div
      ref={ref}
      className={classNameCombiner([panelStyle, className])}
      data-testid={testId}
    >
      <h2 data-testid="title" className={styles.title}>
        {title}
      </h2>
      {!hideDivider && <Divider />}
      {children}
    </div>
  );
}
