import deliveredIcon from 'assets/chat/delivered-icon.png';
import errorIndicator from 'assets/chat/error-indicator.svg';
import sentMessageIcon from 'assets/chat/sent-message.png';
import React from 'react';
import { Avatar } from 'shared-components/avatar/Avatar';

import { MessageStatus } from '../../pubnub/types';
import { MemberProfileBaseProps } from '../ChatContainer';
import styles from './MessageStatusIndicator.module.scss';

interface MessageStatusIndicatorProps {
  status: MessageStatus;
  memberProfileBaseProps: MemberProfileBaseProps;
}

export const MessageStatusIndicator = ({
  status,
  memberProfileBaseProps,
}: MessageStatusIndicatorProps) => {
  const { firstName, lastName, avatarSrc } = memberProfileBaseProps;

  const setApplicableStatus = (status: MessageStatus, avatarSrc?: string) => {
    switch (status) {
      case MessageStatus.SENT:
        return (
          <img
            className={styles.icon}
            alt="sent message icon"
            src={sentMessageIcon}
          />
        );
      case MessageStatus.DELIVERED:
        return (
          <img
            className={styles.icon}
            alt="delivered message icon"
            src={deliveredIcon}
          />
        );
      case MessageStatus.ERROR:
        return (
          <img
            className={styles.icon}
            alt="error message icon"
            src={errorIndicator}
          />
        );
      case MessageStatus.SEEN:
        return (
          <Avatar
            src={avatarSrc}
            className={styles.icon}
            firstName={firstName}
            lastName={lastName}
            includeLastName={true}
          />
        );
      default:
        return <></>;
    }
  };
  return setApplicableStatus(status, avatarSrc);
};
