import { gql } from '@apollo/client';
import {
  checkIfStringHasALink,
  initializeTypingState,
  linkRegex,
  TIMEOUT_FOR_SENDING_A_MESSAGE_MS,
} from 'app/coach/chat/utils';
import { MessageStatus, PUBNUB_STATUS } from 'app/coach/pubnub/types';
import { buildMessage, makeSentMessage } from 'app/coach/pubnub/utils';
import { InboxSections } from 'app/inbox/types';
import { maybeParseActivityId } from 'app/patients/tabs/content/maybeParseActivityId';
import {
  careProviderReadMessagesEvent,
  careProviderSentSomeLinkEvent,
  careProviderTypingMessagesEvent,
  careProviderWriteMessageEvent,
  chatErrorAmplitudeEvent,
} from 'app/state/amplitude/actions/chat';
import { assignHomework } from 'app/state/content/actions';
import { Context } from 'app/state/context';
import {
  addMessage,
  markSentMessageByError,
  readAllMessages,
  sortInboxSections,
} from 'app/state/inbox/actions';
import { State } from 'app/state/schema';
import { batch } from 'react-redux';
import { createActionHandlers } from 'redux-reloaded';

import { updateTimetokens } from '../features/conversationTimetokens/conversationTimetokensSlice';
import {
  updateChatTimetoken,
  updateChatTimetokenVariables,
} from '../features/conversationTimetokens/generated/updateChatTimetoken';
import { updateConversationTimetokenMutation } from '../features/conversationTimetokens/queriesAndMutations';
import {
  initializePubnub,
  maybeUpdateReadOnlyState,
  onCareProviderTypingEvent,
  onEnterClick,
  onPublishError,
  onPublishSuccess,
  onReadingMessagesByCareProvider,
  parseLinksAndLogIfAny,
  sendMessage,
  setInputValue,
  setMemberCoachChannelPrefixForCoach,
  setPublishLatencyTimeout,
  updateChatReadOnly,
  updatePubnubStatus,
  updateTypingState,
} from './actions';

export const getListenerPubnubParams = gql`
  query GetListenerPubnubParams {
    getListenerPubnubParams {
      subscribeKey
      publishKey
    }
  }
`;

export const handlers = createActionHandlers<Context, State>();

handlers.on(initializePubnub, async ({ action, context, redux }) => {
  const { logger } = context.services;
  const {
    authKey: pubnubAuthKey,
    publishKey,
    subscribeKey,
    listenerId,
    rpcToCoachChannelId,
    useWildCard,
    logVerbosity,
  } = action.payload;
  const listenerUuid = `listener-${listenerId}`;
  const additionalData = {
    publishKey: Boolean(publishKey),
    pubnubAuthKey: Boolean(pubnubAuthKey),
    subscribeKey: Boolean(subscribeKey),
  };

  if (!listenerId || !pubnubAuthKey || !subscribeKey || !publishKey) {
    const message = `ChatActionHandler::initializePubnub: Failed with invalid/missing params.`;
    logger.error(new Error(message), additionalData);
    redux.dispatch(updatePubnubStatus(PUBNUB_STATUS.ERROR));
    return;
  }

  try {
    const pubnubConfig = {
      listenerUuid,
      logVerbosity,
      publishKey,
      pubnubAuthKey,
      subscribeKey,
    };
    const { error, status } = context.services.pubnub.initialize(pubnubConfig);
    if (status !== PUBNUB_STATUS.INSTANTIATED) {
      logger.error(
        new Error(
          'ChatActionHandler::initializePubnub: Failed to initialize pubnub service',
        ),
        {
          ...additionalData,
          error,
        },
      );
      redux.dispatch(updatePubnubStatus(PUBNUB_STATUS.ERROR));
      return;
    }
    batch(() => {
      redux.dispatch(updatePubnubStatus(PUBNUB_STATUS.INSTANTIATED));
      if (useWildCard)
        redux.dispatch(
          setMemberCoachChannelPrefixForCoach(`chat.L${listenerId}.`),
        );
    });
    if (rpcToCoachChannelId)
      context.services.pubnub.subscribeToChannels({
        channels: [rpcToCoachChannelId],
      });
  } catch (error) {
    logger.error(
      new Error(
        'ChatActionHandler::initializePubnub: Failed to initialize pubnub service',
        { cause: error },
      ),
      {
        ...additionalData,
        error,
      },
    );
    redux.dispatch(updatePubnubStatus(PUBNUB_STATUS.ERROR));
  }
});

handlers.on(onCareProviderTypingEvent, async ({ action, redux }) => {
  const { logsContext, updatedTypingState, channelId } = action.payload;
  redux.dispatch(
    updateTypingState({ channelId, updatedParam: updatedTypingState }),
  );
  redux.dispatch(careProviderTypingMessagesEvent(logsContext));
});

handlers.on(onReadingMessagesByCareProvider, async ({ action, redux }) => {
  const { channelId, logsPayload } = action.payload;

  redux.dispatch(readAllMessages({ channelId }));
  redux.dispatch(
    careProviderReadMessagesEvent({
      ...logsPayload,
    }),
  );
});

handlers.on(maybeUpdateReadOnlyState, async ({ action, redux, context }) => {
  const { channelId, coachingCareTeam } = action.payload;
  const {
    user: { userId: authUserId },
  } = redux.getState();

  if (!channelId || !authUserId || !coachingCareTeam) return;

  // A read-only chat is created when a coach is removed from the care team because, if the coach sends a message in
  // this case, the member will not receive it since the conversation is not displayed in the app's coaches list.
  // The logic ignores if is a backup coach because this role has a "special" behavior that allows the messages to the
  // member, adding the coach back to the care team.
  const shouldBeReadOnly = (coachingCareTeam.past?.coaches ?? []).some(
    (coach) => !coach?.isBackup && coach?.gingerId === authUserId,
  );

  if (shouldBeReadOnly) {
    redux.dispatch(updateChatReadOnly({ channelId, isReadOnly: true }));
  } else {
    redux.dispatch(updateChatReadOnly({ channelId, isReadOnly: false }));
  }
});

handlers.on(parseLinksAndLogIfAny, async ({ action, redux }) => {
  const { dispatch } = redux;
  const { message, logsContext } = action.payload;

  const { hasLinks, domains } = checkIfStringHasALink(message, linkRegex);
  if (hasLinks) {
    /*  Usually we don't dispatch anything in a loop.
   In this case it shouldn't hurt the performance since the action doesn't update the state (it only sends the logs) so we don't trigger multiple re-renders.
  However, this triggers multiple amplitude network calls - we've done it to keep the parity w/ the logs that are sent from the listener client.
  Normally we don't expect care providers to send dozens of links */
    domains.forEach((domain) =>
      dispatch(
        careProviderSentSomeLinkEvent({
          ...logsContext,
          domain,
        }),
      ),
    );
  }
});

handlers.on(onEnterClick, async ({ action, redux }) => {
  const {
    inputValue: message,
    channelId,
    logsContext,
    nextMessageId,
    memberId,
  } = action.payload;
  const { dispatch } = redux;
  const homework = maybeParseActivityId(message);
  // homework assignment scenario
  if (homework) {
    dispatch(
      assignHomework({
        channelId,
        contentId: homework,
        contentType: 'content',
        memberId,
      }),
    );
  } // sending a text message flow
  else {
    dispatch(
      sendMessage({
        channelId,
        inputValue: message,
        logsContext,
        memberId,
        nextMessageId,
      }),
    );
  }
  dispatch(setInputValue({ channelId, input: '' }));
});

handlers.on(sendMessage, async ({ action, redux, context }) => {
  const {
    inputValue: message,
    channelId,
    logsContext,
    nextMessageId,
    memberId,
  } = action.payload;
  const { dispatch, getState } = redux;
  const {
    user: { listenerId, coachinghubUsername },
    inbox: { inboxItems },
  } = getState();
  const conversationId = inboxItems[memberId]?.conversationId ?? '';
  dispatch(parseLinksAndLogIfAny({ logsContext, message }));

  if (!listenerId || !coachinghubUsername) {
    const error = new Error(
      `Invalid listenerId and/or coachinghubUsername for sending a message`,
    );
    dispatch(
      chatErrorAmplitudeEvent({
        ...logsContext,
        error,
      }),
    );
    context.services.logger.error(error, {
      channelId,
      coachinghubUsername,
      listenerId,
    });

    return;
  }

  // build a message obj for sending it to pubnub
  const messageToSendToPubnub = buildMessage({
    id: nextMessageId,
    input: message,
    senderId: listenerId,
    username: coachinghubUsername,
  });

  // build a temp message obj to display it instantly (not waiting for a response of pubnub)
  const sentMessage = makeSentMessage(messageToSendToPubnub, channelId);
  const subscribedChannels = context.services.pubnub.getSubscribedChannels();
  const timerForSendingMessage = setTimeout(() => {
    const error = new Error(
      'Message will be marked as NOT sent due to the latency timeout',
    );
    context.services.logger.error(error, {
      channelId,
      listenerId,
      messageId: messageToSendToPubnub.id,
      subscribedChannels,
    });
    dispatch(
      onPublishError({
        channelId,
        logsContext,
        sentMessage,
      }),
    );
  }, TIMEOUT_FOR_SENDING_A_MESSAGE_MS);
  dispatch(
    setPublishLatencyTimeout({ [sentMessage.id]: timerForSendingMessage }),
  );

  dispatch(addMessage(sentMessage));

  try {
    const res = await context.services.pubnub.publishMessage({
      channel: channelId,
      message: messageToSendToPubnub,
    });
    const timetoken = res.timetoken.toString();
    if (!timetoken)
      throw new Error(
        `pubnub.publish message returned an unexpected response: ${JSON.stringify(
          res,
        )}`,
      );

    dispatch(
      careProviderWriteMessageEvent({
        ...logsContext,
        message_id: messageToSendToPubnub.id,
        msg_len: messageToSendToPubnub.message.length,

        timetoken,
      }),
    );

    dispatch(onPublishSuccess({ conversationId, timetoken }));
  } catch (e) {
    dispatch(onPublishError({ channelId, logsContext, sentMessage }));
    const error = new Error(`pubnub.publish error in convo`, { cause: e });
    dispatch(
      chatErrorAmplitudeEvent({
        ...logsContext,
        error,
      }),
    );
    context.services.logger.error(error, {
      channelId,
      error: e,
      logsContext,
    });
  }
  const nullifiedState = initializeTypingState();
  dispatch(
    onCareProviderTypingEvent({
      channelId,
      logsContext: {
        ...logsContext,
        is_typing: nullifiedState.isTyping,
      },
      updatedTypingState: nullifiedState,
    }),
  );
});

handlers.on(onPublishSuccess, async ({ action, redux, context }) => {
  const { dispatch } = redux;
  const { timetoken, conversationId } = action.payload;
  const { logger } = context.services;
  // only log an error if there's no conversationId since it's needed to continue the flow
  if (!conversationId) {
    logger.error(
      new Error(
        `onPublishSuccess could not properly update timetokens due to the lack of the conversation id`,
      ),
      { conversationId, timetoken },
    );
    return;
  }

  try {
    const { data, errors } = await context.services.apollo.mutate<
      updateChatTimetoken,
      updateChatTimetokenVariables
    >({
      mutation: updateConversationTimetokenMutation,
      variables: {
        input: {
          conversationId,
          listenerWriteTimetoken: timetoken,
        },
      },
    });
    if (
      !data?.updateChatConversationTimetoken?.conversationStats
        ?.latestWriteTimestamp ||
      data?.updateChatConversationTimetoken?.error ||
      errors
    ) {
      logger.error(
        new Error(
          `Got an unexpected result when called the updateTimeToken mutation`,
        ),
        {
          conversationId,
          errors,
          response: data,
          timetoken,
        },
      );
    } else {
      const {
        memberCoachChannelId,
        latestWriteTimestamp,
      } = data.updateChatConversationTimetoken.conversationStats;
      dispatch(
        updateTimetokens([
          {
            channelId: memberCoachChannelId,
            timetokens: {
              latestWriteTimestamp,
            },
          },
        ]),
      );

      dispatch(
        sortInboxSections({
          sections: [InboxSections.CONVERSATIONS_AND_TASKS],
        }),
      );
    }
  } catch (error) {
    logger.error(
      new Error(`onPublishSuccess: unable to listener write time token`, {
        cause: error,
      }),
      {
        conversationId,
        error,
        timetoken,
      },
    );
  }
});

handlers.on(onPublishError, async ({ action, redux, context }) => {
  const { dispatch, getState } = redux;
  const { sentMessage } = action.payload;
  const { logger } = context.services;
  const {
    inbox: { messagesMap },
  } = getState();
  const { id: sentMessageId, channel: sentMessageChannel } = sentMessage;
  const hasMessageBeenDelivered =
    sentMessage.channel in messagesMap ??
    messagesMap[sentMessage.channel].find((m) => m.id === sentMessageId)
      ?.status === MessageStatus.DELIVERED;
  if (hasMessageBeenDelivered) {
    // do not mark the message by an error, only log since this is not expected
    logger.warning(
      'onPublishError: A timeout for sending a message has expired, BUT we already have this message with a delivered status',
      { sentMessageChannel, sentMessageId },
    );
  } else {
    logger.info('onPublishError: Message delivery failed', {
      channelId: sentMessageChannel,
      messageId: sentMessageId,
    });
    dispatch(
      markSentMessageByError({
        channelId: sentMessageChannel,
        messageId: sentMessageId,
      }),
    );
    dispatch(setPublishLatencyTimeout({ [sentMessageId]: null }));
  }
});
