import { FieldDefinitions, useForm } from '@ginger.io/react-use-form';
import {
  RisksAndActionsTaken,
  RiskType,
} from 'app/coach/coach-notes/CoachNotesTypes';

type UseRiskFormParams<T extends Record<string, any>> = {
  riskType: RisksAndActionsTaken;
  validationSchema: FieldDefinitions<T>;
  initialValue?: T;
  onChange: (data: T, isInitial?: boolean) => void;
  removeRisk?: (riskType: RiskType) => void;
};

export function useRiskForm<T extends Record<string, any>>({
  riskType,
  validationSchema,
  initialValue,
  onChange,
  removeRisk,
}: UseRiskFormParams<T>) {
  const { fields, validate } = useForm<T>(validationSchema, initialValue, {
    onStateChange: async (data) => {
      onChange(data);
      // call validate to update the ui to display error message where applicable
      void validate();
    },
  });

  const onRemoveRisk = () => removeRisk?.(riskType as RiskType);

  return { fields, onRemoveRisk };
}
