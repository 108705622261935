import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import { Loader } from 'shared-components/loader/Loader';
import { MemberChartErrorState } from 'app/member-chart-cards/error-state/MemberChartErrorState';
import {
  GetMemberQueryWithCareTeam,
  GetMemberQueryWithCareTeam_getMember as Member,
  GetMemberQueryWithCareTeamVariables,
} from 'app/queries/generated/GetMemberQueryWithCareTeam';
import { getMemberQueryWithCareTeam } from 'app/queries/GetMember';
import { useLogger } from 'app/state/log/useLogger';
import { StateSlice, Status } from 'app/state/status/types/StateSlice';
import { useStateSlice } from 'app/vault/hooks/utils';
import Messages from 'i18n/en/inbox.json';
import React, { ElementType, useEffect, useRef } from 'react';
import styles from 'shared-components/error-state/styles/SimpleErrorComponent.module.scss';
import {
  isAuthorizationError,
  isGraphQLAuthenticationError,
} from 'shared-components/error-state/utils';

type Props = {
  selectedMemberId: string;
  Component: ElementType<{ member: Member }>;
};

export function MemberChartDataState({
  selectedMemberId,
  Component,
}: Readonly<Props>) {
  const state = useGetMemberData(selectedMemberId);

  if (state.status === Status.LOADING) return <Loader />;

  if (state.status === Status.ERROR) {
    return (
      <div className={styles.errorContainer}>
        <MemberChartErrorState showMessage={true} error={state.error} />
      </div>
    );
  }

  return <Component member={state.data} />;
}

export function useGetMemberData(memberId: string): StateSlice<Member> {
  const { state, setData, setLoading, setError } = useStateSlice<Member>();
  //  Using useQuery/useLazyQuery cancels in-flight requests when switching between tabs. For this reason are
  //  using the apollo client directly here, so users can have a seamless experience without having to wait on the tab for
  // the member's chart to load.
  const apolloClient = useApolloClient();
  const memberIdRef = useRef<string | null>(null);
  const logger = useLogger();
  useEffect(() => {
    memberIdRef.current = memberId;
    const run = async () => {
      try {
        setLoading();
        const response = await apolloClient.query<
          GetMemberQueryWithCareTeam,
          GetMemberQueryWithCareTeamVariables
        >({
          errorPolicy: 'all',
          query: getMemberQueryWithCareTeam,
          variables: { input: { id: memberId } }, // Allow us to render partial data, so we don't block the entire screen when there is an error in the query
        });
        // Do nothing when the active tab id (memberIdRef.current) is not equal to the memberId sent with the
        // getMemberById query.
        if (memberIdRef.current !== memberId) return;
        assertResponseHasNoError(response);
        const { data, errors } = response;

        if (errors) {
          logger.error(
            new Error(`useGetMemberData: ${Messages.fetchedPartialMemberData}`),
            {
              errors,
              memberId,
            },
          );
        }

        const hasData = Boolean(data?.getMember);

        if (hasData) {
          return setData(data.getMember!);
        }

        // Apollo doesn't cache error state, when the user navigates back to a member tab with an error.
        // The `errors` object will be undefined
        if (!errors) {
          logger.warning(
            `useGetMemberData: ${Messages.failureToRetrieveMemberDataInitial}`,
          );
        }
        setError(new Error(Messages.failureToRetrieveMemberDetails));
      } catch (error) {
        // Do nothing when the active tab id (memberIdRef.current) is not equal to the memberId sent with the
        // getMemberById query.
        if (memberIdRef.current !== memberId) return;

        if (isGraphQLAuthenticationError(error)) {
          setError(error);
        } else if (isAuthorizationError(error)) {
          setError(new Error(Messages.unauthorizedToViewMember));
        } else {
          logger.error(
            new Error(
              `useGetMemberData: ${Messages.failureToRetrieveMemberDetailsLog}`,
            ),
            {
              error,
              memberId,
              memberIdRef: memberIdRef.current,
            },
          );
          setError(new Error(Messages.failureToRetrieveMemberDetails));
        }
      }
    };
    void run();
    return () => {
      memberIdRef.current = null;
    };
  }, [memberId]);
  return state.current;
}

function assertResponseHasNoError(
  res: ApolloQueryResult<GetMemberQueryWithCareTeam>,
) {
  const { errors, data } = res;
  if (errors && !data?.getMember) throw errors;
}
