import { HomeworkStatus, HomeworkType } from 'app/patients/tabs/content/types';
import { createReducer } from 'redux-reloaded';

import {
  addHomework,
  setHomeworkAssignmentStatus,
  setHomeworks,
  unassignHomework,
  updateHomework,
} from './actions';
import { getInitialContentState, State } from './schema';

export const reducer = createReducer<State>(getInitialContentState());

reducer.on(addHomework, (state, { payload }) => {
  const { userId, homework } = payload;

  let current: HomeworkType[] = [];
  let completed: HomeworkType[] = [];

  // Check if the userId exists in contentMap
  if (state.contentMap[userId]) {
    // If user exists, use their current homework and completed homework
    current = [...state.contentMap[userId].currentHomework];
    completed = [...state.contentMap[userId].completedHomework];
  }

  if (current.find((hw) => homework.assignmentId == hw.assignmentId)) {
    // Homework already exists, skip...
    // this happens when the RPC comes up and the assignment have been added in the tab
    return state;
  }

  if (homework.status == HomeworkStatus.COMPLETED) {
    completed.push(homework);
  } else if (
    [HomeworkStatus.IN_PROGRESS, HomeworkStatus.NOT_STARTED].includes(
      homework.status,
    )
  ) {
    current.push(homework);
  }

  return {
    ...state,
    contentMap: {
      ...state.contentMap,
      [userId]: {
        completedHomework: completed,
        currentHomework: current,
      },
    },
  };
});

reducer.on(setHomeworks, (state, { payload }) => {
  const { userId, homeworks } = payload;

  return {
    ...state,
    contentMap: {
      ...state.contentMap,
      [userId]: homeworks,
    },
  };
});

reducer.on(unassignHomework, (state, { payload }) => {
  const { userId, contentId } = payload;
  const current: HomeworkType[] = state.contentMap[userId].currentHomework;
  return {
    ...state,
    contentMap: {
      ...state.contentMap,
      [userId]: {
        ...state.contentMap[userId],
        currentHomework: current.filter((el) => {
          return el.assignmentId != contentId;
        }),
      },
    },
  };
});

reducer.on(updateHomework, (state, { payload }) => {
  const { label, status, userId } = payload;
  let current: HomeworkType[] = [];
  let completed: HomeworkType[] = [];

  // Check if the userId exists in contentMap
  if (state.contentMap[userId]) {
    // If user exists, use their current homework and completed homework
    current = [...state.contentMap[userId].currentHomework];
    completed = [...state.contentMap[userId].completedHomework];
  }

  // Payload only has the label of the assignment whose status is updated. We need
  // to find the entry(ies) in the currentHomework to update and, optionally, move
  // into completedHomework.
  const newCurrent: HomeworkType[] = [];

  // This is here to support both options of complete ALL matched or just the 1st
  let completeNextMatched = true;

  current.forEach((homework) => {
    if (homework.title === label) {
      const homeworkWithNewStatus = { ...homework, status };
      switch (status) {
        case HomeworkStatus.NOT_STARTED:
        case HomeworkStatus.IN_PROGRESS:
          newCurrent.push(homeworkWithNewStatus);
          break;
        case HomeworkStatus.COMPLETED:
          if (completeNextMatched) {
            completed.push(homeworkWithNewStatus);
          } else {
            newCurrent.push(homework);
          }
          completeNextMatched = false; // We ONLY want to complete the 1st matched
          break;
      }
    } else {
      newCurrent.push(homework);
    }
  });

  return {
    ...state,
    contentMap: {
      ...state.contentMap,
      [userId]: {
        completedHomework: completed,
        currentHomework: newCurrent,
      },
    },
  };
});

reducer.on(setHomeworkAssignmentStatus, (state, { payload }) => {
  const { channelId, assignmentState } = payload;

  return {
    ...state,
    homeworkAssignmentStatus: {
      ...state.homeworkAssignmentStatus,
      [channelId]: assignmentState,
    },
  };
});
