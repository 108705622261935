import React from 'react';

import { MS } from '../types';
import styles from './Row.module.scss';

const MSRow = (ms: MS) => {
  return (
    <div data-testid={`ms-${ms.id}`}>
      <div className={styles.nameContainer}>
        <div>Member Support</div>
      </div>
    </div>
  );
};

export default MSRow;
