import { Fields } from '@ginger.io/react-use-form';
import { TimeDropdown } from 'app/member-chart-cards/scheduler/form-fields/TimeDropDown';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import React from 'react';

import { StartTime } from '../types';

export interface Props {
  fields: Fields<StartTime>;
  memberTimezone: string;
  selectedDate: string | null;
}

export function TimeDropdownField(props: Props) {
  const { fields, memberTimezone, selectedDate } = props;
  if (selectedDate === null) {
    return null;
  }
  const handleUpdateTime = (hour: number, minute: number) => {
    fields.hour.setValue(hour);
    fields.minute.setValue(minute);
  };

  return (
    <NoteFormControlLabel label="Time (you/ member)">
      <TimeDropdown
        hour={fields.hour.value}
        minute={fields.minute.value}
        onChange={handleUpdateTime}
        memberTimezone={memberTimezone}
        selectedDate={selectedDate}
        testId="time-dropdown"
      />
    </NoteFormControlLabel>
  );
}
