import { MessageToCareTeam } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/MessageToCareTeam';
import { NoteType } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/NoteType';
import { SafetyPlan } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/safety/SafetyPlan';
import { TreatmentGoals } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/TreatmentGoals';
import { TerminationReasons } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/TerminationReasons';
import { Risks } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/RiskAssessment';
import { decodeBase64VaultItems } from '@ginger.io/vault-core';
import { Base64 } from '@ginger.io/vault-core/dist/crypto/Base64';
import {
  VaultItem,
  VaultItem_SchemaType as SchemaType,
  vaultItem_SchemaTypeToJSON,
} from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import { NotesUserMetadata } from '@ginger.io/vault-shared-care-notes/dist/generated/protobuf-schemas/vault-shared-care-notes/NotesUserMetadata';
import {
  CareProviderNoteType,
  CoachNoteType,
  DecodedNotes,
  DropInNoteType,
  LegacyNoteType,
  NoteItemWithSessionCount,
  NoteOrSubsection,
  NotesAndSessionInfo,
  NotesItemResponse,
  ValidClinicianRole,
} from 'app/coach/coach-notes/CoachNotesTypes';
import { decoderMap, SCHEMA_TYPES } from 'app/coach/coach-notes/constants';
import {
  GetCoachNotesAndUserMetadata_CoachNotes,
  GetCoachNotesAndUserMetadata_LegacyCoachSummaryNotes,
  GetCoachNotesAndUserMetadata_LegacyCoachSummaryNotes_notes as LegacyCoachSummaryNotes,
  GetCoachNotesAndUserMetadata_NotesUserMetadata,
  GetCoachNotesAndUserMetadata_NotesUserMetadata_items as NoteItems,
  GetCoachNotesAndUserMetadata_NotesUserMetadata_items_encryptedItem as EncryptedNoteItems,
} from 'app/coach/coach-notes/generated/GetCoachNotesAndUserMetadata';
import { LegacyDailyCoachingNotes_LegacyDailyCoachingNotes } from 'app/coach/coach-notes/generated/LegacyDailyCoachingNotes';
import { NoteSchema } from 'app/coach/coach-notes/types';
import {
  formatSummaryNotes,
  getLatestSessionInfo,
  getNoteDetails,
  getRiskFields,
  getStartDateAndEndTime,
  getTerminationReasonsNoteType,
  noteContainsRisks,
} from 'app/coach/coach-notes/utils';
import {
  GetMemberChartVaultItems_getPaginatedVaultItemsByTag_items as VaultItems,
  GetMemberChartVaultItems_getPaginatedVaultItemsByTag_items_encryptedItem as EncryptedItem,
} from 'app/coach/member-chart/generated/GetMemberChartVaultItems';
import { ILogger } from 'app/state/log/Logger';
import {
  formatName,
  isClinicianOrSupervisor,
  isVaultItemAuthor,
  toKebabCase,
} from 'utils';
import { formatNoteTimestampWithTz } from 'utils/dateTime';
import {
  GetNonAppointmentNotesAndUserMetadata_CoachNotes,
  GetNonAppointmentNotesAndUserMetadata_NotesUserMetadata,
} from 'app/vault/hooks/NonAppointments/generated/GetNonAppointmentNotesAndUserMetadata';
import {
  UserRole,
  VaultItemPermissions,
  VaultItemPermissions as Permissions,
} from 'generated/globalTypes';

interface AuthData {
  role: UserRole;
  timezone: string;
  vaultUserId: string;
  memberId: string;
}

interface AppointmentDate {
  appointmentStart: string;
  appointmentEnd: string;
}

const getNotesItemResponse = async (noteInfo: {
  id: string;
  readOnly: boolean;
  authData: AuthData;
  data?: NoteOrSubsection;
  noteType?: CareProviderNoteType;
  schemaType?: SchemaType;
  riskAssessments?: Risks;
  appointmentDate?: AppointmentDate;
  encryptedItem?: EncryptedItem | EncryptedNoteItems;
}): Promise<NotesItemResponse> => {
  const {
    id,
    data,
    noteType,
    schemaType,
    readOnly,
    riskAssessments,
    appointmentDate,
    encryptedItem,
    authData: { vaultUserId, timezone },
  } = noteInfo;
  const { appointmentStart, appointmentEnd } = appointmentDate || {};
  const {
    sourceVersion,
    updatedAt,
    createdAt,
    creator,
    firstVersionCreator,
  } = (encryptedItem || {}) as EncryptedItem;
  const associatedRisks = getRiskFields(riskAssessments);
  const {
    startDate: appointmentStartDate,
    dateString: appointmentTime,
  } = getStartDateAndEndTime(timezone, appointmentStart, appointmentEnd);

  return {
    id,
    data,
    noteType,
    schemaType,
    sourceVersion,
    readOnly,
    associatedRisks,
    noteDetails: getNoteDetails(data, noteType, associatedRisks),
    startDate:
      appointmentStartDate ??
      getStartDateAndEndTime(timezone, createdAt).startDate,
    dateString:
      appointmentTime ?? getStartDateAndEndTime(timezone, createdAt).dateString,
    createdAt: appointmentStart ?? createdAt,
    createdBy:
      formatName(
        firstVersionCreator?.firstName,
        firstVersionCreator?.lastName,
      ) ?? '',
    updatedAt: updatedAt
      ? formatNoteTimestampWithTz(updatedAt, timezone)
      : undefined,
    updatedBy: (await isVaultItemAuthor(vaultUserId, creator?.id))
      ? `You`
      : formatName(creator?.firstName, creator?.lastName) ?? '',
  };
};

async function addLegacyCoachSummaryNotes(params: {
  memberId: string;
  notes: DecodedNotes;
  legacyCoachSummaryNotes: LegacyCoachSummaryNotes;
  authData: AuthData;
}): Promise<DecodedNotes> {
  const { memberId, notes, legacyCoachSummaryNotes, authData } = params;
  const { timezone } = authData;
  const { __typename, ...summaryNotes } = legacyCoachSummaryNotes;
  const hasLegacySummaryNotes = Object.values(summaryNotes).some((note) =>
    Boolean(note),
  );

  if (hasLegacySummaryNotes) {
    const id = `${toKebabCase(__typename)}-${memberId}`;
    const { data, latestCreationDate, lastCreatedBy } = formatSummaryNotes(
      summaryNotes,
    );

    return {
      ...notes,
      [id]: {
        ...(await getNotesItemResponse({
          id,
          readOnly: true,
          authData,
          data,
          noteType: LegacyNoteType.SUMMARY_NOTE,
        })),
        startDate: getStartDateAndEndTime(timezone, latestCreationDate)
          .startDate,
        dateString: getStartDateAndEndTime(timezone, latestCreationDate)
          .dateString,
        createdAt: latestCreationDate,
        createdBy: lastCreatedBy,
      },
    };
  }

  return notes;
}

type NoteInfo = {
  data?: NoteOrSubsection;
  noteType?: CareProviderNoteType;
  schemaType?: SchemaType;
  appointmentDate?: AppointmentDate;
  riskAssessments?: Risks;
};

export type DecodeVaultItemParams = {
  notesData:
    | GetCoachNotesAndUserMetadata_CoachNotes
    | GetNonAppointmentNotesAndUserMetadata_CoachNotes
    | null;
  metadata:
    | GetCoachNotesAndUserMetadata_NotesUserMetadata
    | GetNonAppointmentNotesAndUserMetadata_NotesUserMetadata
    | null;
  legacySummaryNotesData?: GetCoachNotesAndUserMetadata_LegacyCoachSummaryNotes | null;
  legacyDailyCoachingNotesData?: LegacyDailyCoachingNotes_LegacyDailyCoachingNotes | null;
  authData: AuthData;
  logger: ILogger;
};

function getCachedItems(
  params: DecodeVaultItemParams,
): Array<VaultItems | NoteItems> {
  const { notesData, metadata, legacyDailyCoachingNotesData } = params;
  return [
    ...(notesData?.items ?? []),
    ...(metadata?.items ?? []),
    ...(legacyDailyCoachingNotesData?.items ?? []),
  ];
}

export const decodeVaultItems = async (
  params: DecodeVaultItemParams,
): Promise<NotesAndSessionInfo> => {
  const { legacySummaryNotesData, authData, logger } = params;
  const { memberId, role, vaultUserId } = authData;
  const hashedVaultId = await Base64.hash(vaultUserId);
  const isClinician = isClinicianOrSupervisor(role);

  let latestSessionCount: MaybeUndefined<number> = undefined;

  const cachedItems = getCachedItems(params);

  const notesAndMetadata = await cachedItems.reduce<Promise<DecodedNotes>>(
    async (notesObj: Promise<VaultItems | DecodedNotes>, { encryptedItem }) => {
      const obj = (await notesObj) as DecodedNotes;
      const {
        encryptedData,
        id: encryptedItemId,
        creator,
        permissions,
      } = encryptedItem as EncryptedItem;
      const { id: vaultItemCreatorId } = creator || {};
      const isCurrentUserCreator = vaultItemCreatorId === hashedVaultId;

      const decodedItems = await decodeBase64VaultItems(
        [encryptedData.cipherText],
        decoderMap,
      );

      let id = encryptedItemId;
      let metadataId: MaybeUndefined<string> = obj[id]?.metadataId;
      let isUnread: MaybeUndefined<boolean> = obj[id]?.isUnread; // undefined means metadata does not yet exist for the note
      let noteInfo: MaybeUndefined<NoteInfo> = undefined;
      let data: MaybeUndefined<NoteOrSubsection> = undefined;
      let readOnly = permissions === Permissions.READ_ONLY;

      const metadataResponse = {
        metadataId,
        isUnread: isUnread === undefined && readOnly ? true : isUnread,
      };

      const getNoteInfo = (
        schemaType: NoteSchema,
        noteType: CareProviderNoteType,
      ): NoteInfo => {
        const data = decodedItems[schemaType][0];
        return {
          data,
          noteType,
          schemaType,
          riskAssessments: data.riskAssessments || data.risks,
        };
      };

      const getClinicalNoteInfo = (
        data: any,
        schemaType: NoteSchema,
      ): NoteInfo => {
        return {
          data: { ...obj[id]?.data, ...data },
          noteType:
            schemaType === SCHEMA_TYPES.terminationReasons
              ? getTerminationReasonsNoteType(
                  (data.clinicianRole as unknown) as ValidClinicianRole,
                )
              : (NoteType[data.noteType] as CareProviderNoteType),
          schemaType,
          appointmentDate: {
            appointmentStart: data.appointmentStart,
            appointmentEnd: data.appointmentEnd,
          },
        };
      };

      switch (true) {
        case decodedItems[SCHEMA_TYPES.followUp]?.length > 0:
          noteInfo = getNoteInfo(
            SCHEMA_TYPES.followUp,
            CoachNoteType.FOLLOW_UP,
          );
          latestSessionCount = getLatestSessionInfo(
            noteInfo.data as NoteItemWithSessionCount,
            latestSessionCount,
          );
          break;

        case decodedItems[SCHEMA_TYPES.initialConsult]?.length > 0:
          noteInfo = getNoteInfo(
            SCHEMA_TYPES.initialConsult,
            CoachNoteType.INITIAL_CONSULT,
          );
          latestSessionCount = getLatestSessionInfo(
            noteInfo.data as NoteItemWithSessionCount,
            latestSessionCount,
          );
          break;

        case decodedItems[SCHEMA_TYPES.outreachAttempt]?.length > 0:
          noteInfo = getNoteInfo(
            SCHEMA_TYPES.outreachAttempt,
            CoachNoteType.OUTREACH_ATTEMPT,
          );
          break;

        case decodedItems[SCHEMA_TYPES.quickNote]?.length > 0:
          noteInfo = getNoteInfo(
            SCHEMA_TYPES.quickNote,
            CoachNoteType.QUICK_NOTE,
          );
          break;

        case decodedItems[SCHEMA_TYPES.risk]?.length > 0:
          noteInfo = getNoteInfo(SCHEMA_TYPES.risk, CoachNoteType.RISK);
          break;

        case decodedItems[SCHEMA_TYPES.dropInConsult]?.length > 0:
          noteInfo = getNoteInfo(
            SCHEMA_TYPES.dropInConsult,
            DropInNoteType.DROP_IN_CONSULT,
          );
          break;

        case decodedItems[SCHEMA_TYPES.deescalationNeed]?.length > 0:
          noteInfo = getNoteInfo(
            SCHEMA_TYPES.deescalationNeed,
            DropInNoteType.DE_ESCALATION_NEED,
          );
          break;

        case decodedItems[SCHEMA_TYPES.experiencingRisk]?.length > 0:
          noteInfo = getNoteInfo(
            SCHEMA_TYPES.experiencingRisk,
            DropInNoteType.EXPERIENCING_RISK,
          );
          break;

        case decodedItems[SCHEMA_TYPES.exploringTheApp]?.length > 0:
          noteInfo = getNoteInfo(
            SCHEMA_TYPES.exploringTheApp,
            DropInNoteType.EXPLORING_THE_APP,
          );
          break;

        case decodedItems[SCHEMA_TYPES.seekingClinical]?.length > 0:
          noteInfo = getNoteInfo(
            SCHEMA_TYPES.seekingClinical,
            DropInNoteType.SEEKING_CLINICAL,
          );
          break;

        case decodedItems[SCHEMA_TYPES.dropInOther]?.length > 0:
          noteInfo = getNoteInfo(
            SCHEMA_TYPES.dropInOther,
            DropInNoteType.DROP_IN_OTHER,
          );
          break;

        case decodedItems[SCHEMA_TYPES.legacyDailyCoachingNotes]?.length > 0:
          readOnly = true; // all legacy daily coaching notes should be treated as read-only regardless of Vault permissions

          // only display published daily notes
          if (permissions === VaultItemPermissions.READ_ONLY)
            noteInfo = getNoteInfo(
              SCHEMA_TYPES.legacyDailyCoachingNotes,
              LegacyNoteType.DAILY_COACHING_NOTE,
            );
          break;

        case decodedItems[SCHEMA_TYPES.safetyPlan].length > 0:
          if (isClinician) break;
          data = decodedItems[SCHEMA_TYPES.safetyPlan][0] as SafetyPlan;
          id = `appointment-${data.appointmentId}`;
          noteInfo = getClinicalNoteInfo(data, SCHEMA_TYPES.safetyPlan);
          break;

        case decodedItems[SCHEMA_TYPES.treatmentGoals].length > 0:
          if (isClinician) break;
          data = decodedItems[SCHEMA_TYPES.treatmentGoals][0] as TreatmentGoals;
          id = `appointment-${data?.appointmentId}`;
          noteInfo = getClinicalNoteInfo(data, SCHEMA_TYPES.treatmentGoals);
          break;

        case decodedItems[SCHEMA_TYPES.messageToCareTeam].length > 0:
          if (isClinician) break;
          data = decodedItems[
            SCHEMA_TYPES.messageToCareTeam
          ][0] as MessageToCareTeam;
          id = `appointment-${data.appointmentId}`;
          noteInfo = getClinicalNoteInfo(data, SCHEMA_TYPES.messageToCareTeam);
          break;

        case decodedItems[SCHEMA_TYPES.terminationReasons].length > 0:
          if (isClinician) break;
          data = decodedItems[
            SCHEMA_TYPES.terminationReasons
          ][0] as TerminationReasons;
          noteInfo = getClinicalNoteInfo(data, SCHEMA_TYPES.terminationReasons);
          break;

        case decodedItems[SCHEMA_TYPES.notesUserMetadata].length > 0: {
          const { vaultItemId, hasReadItem } = decodedItems[
            SCHEMA_TYPES.notesUserMetadata
          ][0] as NotesUserMetadata;
          id = vaultItemId;
          metadataId = encryptedItemId;
          isUnread = !hasReadItem;
          return {
            ...obj,
            [id]: {
              ...obj[id],
              metadataId,
              isUnread,
            },
          };
        }

        default: {
          const schemaType = await VaultItem.decode(
            await Base64.decode(encryptedData.cipherText),
          ).schemaType;
          logger.warning(
            `decodeVaultItems: ignoring schema type: ${vaultItem_SchemaTypeToJSON(
              schemaType,
            )}`,
          );
        }
      }

      // don't show notes authored by different Vault users if it is not published yet (i.e., not readOnly)
      // unless it contains risk assessments
      if (
        !isCurrentUserCreator &&
        !readOnly &&
        !noteContainsRisks(noteInfo?.data)
      ) {
        return obj;
      }

      if (isClinician && !noteInfo?.data) {
        return obj;
      }

      return {
        ...obj,
        [id]: {
          ...(await getNotesItemResponse({
            id,
            readOnly,
            authData,
            encryptedItem,
            ...noteInfo,
          })),
          ...metadataResponse,
        },
      };
    },
    Promise.resolve({} as DecodedNotes),
  );

  let notes = notesAndMetadata;
  if (legacySummaryNotesData && legacySummaryNotesData.notes) {
    notes = await addLegacyCoachSummaryNotes({
      memberId,
      notes,
      legacyCoachSummaryNotes: legacySummaryNotesData.notes,
      authData,
    });
  }

  return {
    notes: sortNotesByCreatedAt(notes),
    sessionInfo: {
      latestSessionCount,
    },
    error: null,
  };
};

const sortNotesByCreatedAt = (notes: DecodedNotes): DecodedNotes => {
  const sortedKeys = Object.keys(notes)
    .filter((key) => notes[key].createdAt !== undefined)
    .sort((a, b) => {
      const dateA = new Date(notes[a].createdAt ?? 0).getTime();
      const dateB = new Date(notes[b].createdAt ?? 0).getTime();
      return dateB - dateA;
    });

  const sortedObject = {} as DecodedNotes;
  sortedKeys.forEach((key) => {
    sortedObject[key] = notes[key];
  });

  return sortedObject;
};
