import { CareTeamRole } from 'generated/globalTypes';

import { CareMemberRoleLabels } from './types';

export const careMemberRoleLabels: CareMemberRoleLabels = {
  coach: 'Coach',
  member_support: 'Support',
  psychiatrist: 'Psychiatrist',
  si_coach: 'SI Coach',
  therapist: 'Therapist',
};

export const pluralizedCareMemberRoleLabels: CareMemberRoleLabels = {
  coach: 'Coaches',
  member_support: 'Supports',
  psychiatrist: 'Psychiatrists',
  si_coach: 'SI Coaches',
  therapist: 'Therapists',
};

export const getCareMemberRoleLabel = (
  role: keyof CareMemberRoleLabels | CareTeamRole,
  pluralize: boolean = false,
  lowercase: boolean = false,
): string | undefined => {
  role = role.toString().toLowerCase();
  const result = pluralize
    ? pluralizedCareMemberRoleLabels[role]
    : careMemberRoleLabels[role];
  return lowercase ? result?.toLowerCase() : result;
};

export function getCareTeamRole(role: string): CareTeamRole | undefined {
  return Object.values(CareTeamRole).find(
    (r) => r.toLowerCase() === role.toLowerCase(),
  );
}
