import { surveysV3Route as surveysV3CoachingRoute } from 'app/coach/member-chart/Routes';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { TabLink } from 'shared-components/tabs/NavigationTabs';
import { surveysV3Route } from 'app/patients/Routes';

export enum SurveyTab {
  ALL = 'all',
  PHQ9 = 'phq9',
  GAD7 = 'gad7',
  RISC10 = 'cd-risc-10',
  PSS = 'pss',
  CSSRS = 'cssrs',
}

export const useTabLinksForSurveyV3 = (
  pathPrefix: string,
  patientId: string,
  coachingPaths: boolean,
): TabLink[] => {
  const {
    enable_adaptability_surveys_v3,
    enable_cssrs_score_tab,
  } = useFeatureFlags().transientFeatureFlags;
  const tabs = [
    {
      label: 'All',
      path: coachingPaths
        ? surveysV3CoachingRoute(pathPrefix, patientId, SurveyTab.ALL)
        : surveysV3Route(pathPrefix, patientId, SurveyTab.ALL),
    },
    {
      label: 'PHQ9',
      path: coachingPaths
        ? surveysV3CoachingRoute(pathPrefix, patientId, SurveyTab.PHQ9)
        : surveysV3Route(pathPrefix, patientId, SurveyTab.PHQ9),
    },
    {
      label: 'GAD7',
      path: coachingPaths
        ? surveysV3CoachingRoute(pathPrefix, patientId, SurveyTab.GAD7)
        : surveysV3Route(pathPrefix, patientId, SurveyTab.GAD7),
    },
    {
      label: 'PSS',
      path: coachingPaths
        ? surveysV3CoachingRoute(pathPrefix, patientId, SurveyTab.PSS)
        : surveysV3Route(pathPrefix, patientId, SurveyTab.PSS),
    },
  ];
  if (enable_adaptability_surveys_v3) {
    tabs.push({
      label: 'CD-RISC-10',
      path: coachingPaths
        ? surveysV3CoachingRoute(pathPrefix, patientId, SurveyTab.RISC10)
        : surveysV3Route(pathPrefix, patientId, SurveyTab.RISC10),
    });
  }
  if (enable_cssrs_score_tab) {
    tabs.push({
      label: 'C-SSRS',
      path: coachingPaths
        ? surveysV3CoachingRoute(pathPrefix, patientId, SurveyTab.CSSRS)
        : surveysV3Route(pathPrefix, patientId, SurveyTab.CSSRS),
    });
  }
  return tabs;
};
