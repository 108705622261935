// Chat actions
import { EventType } from 'app/EventType';
import { createAction } from 'redux-reloaded';

export const collaborationTabClicked = createAction(
  EventType.COLLABORATION_TAB_CLICK,
  (payload: { memberId?: string | null | undefined; author?: any }) => ({
    ...payload,
  }),
);
export const collaborationNewMessage = createAction(
  EventType.COLLABORATION_NEW_MESSAGE_CLICK,
  (payload: { memberId?: string | null | undefined; author?: any }) => ({
    ...payload,
  }),
);
export const collaborationNewMessageCancelClick = createAction(
  EventType.COLLABORATION_NEW_MESSAGE_CANCEL_CLICK,
  (payload: { memberId?: string | null | undefined; author?: any }) => ({
    ...payload,
  }),
);
export const collaborationNewMessageSendClick = createAction(
  EventType.COLLABORATION_NEW_MESSAGE_SEND_CLICK,
  (payload: { memberId?: string | null | undefined; author?: any }) => ({
    ...payload,
  }),
);
export const collaborationEmptyView = createAction(
  EventType.COLLABORATION_EMPTY_VIEW,
  (payload: { memberId?: string | null | undefined; author?: any }) => ({
    ...payload,
  }),
);
