import { createSlice } from '@reduxjs/toolkit';

import { getInitialCoachNotesState } from './schema';

const coachNotesSlice = createSlice({
  initialState: getInitialCoachNotesState(),
  name: 'coachNotes',
  reducers: {
    setSelectedNote(state, action) {
      state.selectedNote = action.payload.selectedNote;
    },
    updateDraftNote(state, action) {
      const { updatedNote } = action.payload;
      state.selectedNote = {
        ...state.selectedNote,
        ...updatedNote,
        data: updatedNote.data,
      };
    },
  },
});

const { actions, reducer } = coachNotesSlice;
const { setSelectedNote, updateDraftNote } = actions;

export { reducer, setSelectedNote, updateDraftNote };
