import { ActionModal } from 'app/patients/tabs/document-upload/ActionModal';
import React, { useEffect, useState } from 'react';

interface ConfirmCodeSetDeleteModalProps {
  codeSetIndex: number | null;
  onConfirm: () => void;
  onCancel: () => void;
}

export function ConfirmDeleteModal({
  codeSetIndex,
  onConfirm,
  onCancel,
}: ConfirmCodeSetDeleteModalProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [codeSetNumber, setCodeSetNumber] = useState<number>(0);

  useEffect(() => {
    if (codeSetIndex !== null) {
      setCodeSetNumber(codeSetIndex + 1);
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [codeSetIndex]);

  return (
    <ActionModal
      onConfirm={onConfirm}
      onClose={onCancel}
      actionLabel="Yes, Delete"
      title="Delete Code Set?"
      isOpen={isOpen}
    >
      <p>
        Are you sure you want to delete <b>Code Set #{codeSetNumber}</b> from
        your note? This action is irreversible.
      </p>
      <p>
        <b>Note:</b> The rest of your note will not be altered
      </p>
    </ActionModal>
  );
}
