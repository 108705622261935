import { RPCEnvelope, ServerRPC } from 'app/coach/pubnub/types';

import {
  refreshCoachTodaysMemberList,
  triggerPubnubChannelSubscription,
} from '../actions';
import { COACH_TODAYS_INBOX_SECTIONS } from './constants';
import { ActionHandler } from './types';

export const onNewConversation = async ({
  action,
  redux,
  context: { services },
}: ActionHandler<{ envelope: RPCEnvelope }>) => {
  const { envelope } = action.payload;
  const { conv, member_id: memberId } = envelope.message;
  if (!memberId) {
    services.logger.warning('onNewConversation:: missing member id', {
      envelope,
    });
    return;
  }
  services.logger.info(
    `onNewConversation::processing new conversation with member: ${memberId}`,
  );

  const channelId = conv.channel_id;
  redux.dispatch(triggerPubnubChannelSubscription({ channelIds: [channelId] }));

  redux.dispatch(
    refreshCoachTodaysMemberList({
      RPC: ServerRPC.NEW_CONVERSATION,
      envelope,
      memberIds: [memberId],
      rpcSource: envelope.message.rpc_source,
      sections: COACH_TODAYS_INBOX_SECTIONS,
    }),
  );
};
