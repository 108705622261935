import { GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments as Appointments } from '@headspace/carehub-graphql/dist/patients/generated/GetClinicalAppointmentsForMember';
import { NoteDetailHeader } from 'app/sortable-table/note-header/NoteDetailHeader';
import { useAppState } from 'app/state';
import { getTimezone } from 'utils/dateTime';
import moment from 'moment';
import qs from 'query-string';
import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  AppointmentsAndNotes,
  useAppointmentsAndNotesAPI,
} from './AppointmentsAndNotesAPIContext';
import { APPOINTMENT_NOTE_QUERYSTRING_ID } from './constants';
import { CLINICAL_APPOINTMENT, getAppointmentTypeDetails } from './utils';

export const filterAppointments = (
  appointmentsAndNotes: AppointmentsAndNotes[],
): Appointments[] => {
  return appointmentsAndNotes.filter(
    (a) => '__typename' in a && a.__typename === CLINICAL_APPOINTMENT,
  ) as Appointments[];
};

export const AppointmentNoteDetailHeader = () => {
  const {
    noteDetails: { cursor: appointment, setCursor },
  } = useAppointmentsAndNotesAPI();
  const { timezone } = useAppState((_) => _.user);
  const localTimezone = getTimezone(timezone);
  const history = useHistory();

  const backToListHandler = () => {
    setCursor(null);
    history.push({
      search: qs.stringify({ [APPOINTMENT_NOTE_QUERYSTRING_ID]: undefined }),
    });
  };

  if (!appointment) {
    return null;
  }

  const appointmentTypeDetails = getAppointmentTypeDetails(
    (appointment as Appointments).type,
  );
  const start = moment.tz((appointment as Appointments).start, localTimezone);
  const startDate = start.format('MMM D, YYYY');
  const startTime = start.format('h:mm');
  const endTime = moment
    .tz((appointment as Appointments).end, localTimezone)
    .format('h:mma z');

  return (
    <NoteDetailHeader
      startDate={startDate}
      dateString={`${startTime}-${endTime}`}
      careTeamMember={(appointment as Appointments).clinician?.fullName}
      details={appointmentTypeDetails}
      backToListHandler={backToListHandler}
    />
  );
};
