import { useQuery } from '@apollo/client';
import { getVaultWebUserId } from '@ginger.io/vault-core/dist/IdHelpers';
import {
  CreateUserCollaborationMetadata,
  CreateUserCollaborationMetadataVariables,
} from 'app/collaboration/generated/CreateUserCollaborationMetadata';
import {
  UpdateUserCollaborationMetadata,
  UpdateUserCollaborationMetadataVariables,
} from 'app/collaboration/generated/UpdateUserCollaborationMetadata';
import {
  createUserMetadata,
  getUserCollaborationMetadata,
  updateUserMetadata,
} from 'app/collaboration/queries';
import { useCollaborationChatStream } from 'app/collaboration/useCollaborationChatStream';
import { ApolloCachingStrategy } from 'app/constants';
import { useAppState } from 'app/state';
import { selectUserState } from 'app/state/care-team/selectors';
import { useLogger } from 'app/state/log/useLogger';
import {
  CreateUserMetadataInput,
  UpdateUserMetadataInput,
} from 'generated/globalTypes';
import { Status } from 'hooks/useHookState';
import { useMutationWithGlobalState as useMutation } from 'hooks/useMutationWithGlobalState';

export interface CommunicationTeamHookState {
  showTeamNotification: boolean;
  updateMetadataInput?: UpdateUserMetadataInput;
  createMetadataInput?: CreateUserMetadataInput;
  updateUserMetadata?:
    | ((input: UpdateUserMetadataInput) => Promise<void>)
    | undefined;
  createUserMetadata?:
    | ((input: CreateUserMetadataInput) => Promise<void>)
    | undefined;
}

/*
 - Hook to determine whether to show a blue dot on Team tab (Member Chat for both Clinicians and Coaches)
*/
export function useTeamCommunication(
  memberId: string,
): CommunicationTeamHookState {
  const { userId, firstName, lastName, authorType } = useAppState(
    selectUserState,
  );
  const logger = useLogger();

  const author = {
    firstName,
    id: getVaultWebUserId(userId!),
    lastName,
    type: authorType,
  };
  const defaultReturnValue = { showTeamNotification: false };
  const { chatMessages } = useCollaborationChatStream(memberId, author, 10);

  const { data } = useQuery(getUserCollaborationMetadata, {
    fetchPolicy: ApolloCachingStrategy.NETWORK_ONLY,
    variables: {
      input: {
        memberId,
        userId: userId!,
      },
    },
  });

  const [updateUserMetadataMutation] = useMutation<
    UpdateUserCollaborationMetadata,
    UpdateUserCollaborationMetadataVariables
  >(updateUserMetadata);
  const [createUserMetadataMutation] = useMutation<
    CreateUserCollaborationMetadata,
    CreateUserCollaborationMetadataVariables
  >(createUserMetadata);

  const createUserCollaborationMetadata = async (
    input: CreateUserMetadataInput,
  ) => {
    try {
      await createUserMetadataMutation({ input });
    } catch (error) {
      logger.error(
        new Error(
          'useTeamCommunication::createUserCollaborationMetadata: Unable to create collaboration metadata',
        ),
        { authorType, error, memberId },
      );
    }
  };

  const updateUserCollaborationMetadata = async (
    input: UpdateUserMetadataInput,
  ) => {
    try {
      await updateUserMetadataMutation({ input });
    } catch (error) {
      logger.error(
        new Error(
          'useTeamCommunication::updateUserCollaborationMetadata: Unable to update collaboration metadata',
        ),
        { authorType, error, memberId },
      );
    }
  };

  const compareDates = (primaryDatetime: string, secondaryDateTime: string) => {
    const primaryDate = new Date(primaryDatetime);
    const secondaryDate = new Date(secondaryDateTime);
    return primaryDate < secondaryDate;
  };

  const currentChatMessageState = chatMessages.current;
  const chats = currentChatMessageState.data?.chats ?? [];
  const userMetadata = data?.getUserCollaborationMetadata;

  switch (currentChatMessageState.status) {
    case Status.DATA: {
      const filteredChats = chats.filter(
        (chat) => chat.author.id !== author.id,
      );
      if (filteredChats.length === 0) {
        return defaultReturnValue;
      }
      const chat = filteredChats[0];
      const currentTimestamp = chat.createdAt;

      if (!userMetadata) {
        return {
          createMetadataInput: {
            latestTimestamp: currentTimestamp,
            memberId,
            userId: userId!,
          },
          createUserMetadata: createUserCollaborationMetadata,
          showTeamNotification: false,
        };
      }

      if (compareDates(userMetadata.latestTimestamp, currentTimestamp)) {
        return {
          showTeamNotification: true,
          updateMetadataInput: {
            id: userMetadata?.id,
            latestTimestamp: currentTimestamp,
          },
          updateUserMetadata: updateUserCollaborationMetadata,
        };
      }
    }
  }

  return defaultReturnValue;
}
