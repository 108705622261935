import { ActionsTaken } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/ActionsTaken';
import { Risks } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/RiskAssessment';
import coachNotesStyles from 'app/coach/coach-notes/CoachNotes.module.scss';
import {
  AdditionalRiskActions,
  RiskFormChangeParams,
  RiskFormComponent,
  RiskType,
  RiskVaultTypes,
} from 'app/coach/coach-notes/CoachNotesTypes';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { AddRiskDropDown } from 'app/coach/coach-notes/note-detail-view/risk-templates/AddRiskDropDown';
import { getRiskFields } from 'app/coach/coach-notes/utils';
import { ActionDialog } from 'shared-components/ActionDialog';
import { useAppState } from 'app/state';
import {
  camelCaseToTitleCase,
  classNameCombiner,
  isCoachOrSupervisor,
} from 'utils';
import React, { useState } from 'react';

import {
  AbuseOfVulnerablePopulationsRiskForm,
  ActionsTakenForm,
  DomesticViolenceRiskForm,
  EatingDisorderRiskForm,
  HomicideRiskForm,
  MemberNotAppropriateForPlatformRiskForm,
  MemberWasInappropriateWithCoachRiskForm,
  OtherRiskForm,
  SelfHarmRiskForm,
  SubstanceUseRiskForm,
  SuicideRiskForm,
} from './index';

const riskAssessmentMap: Record<RiskType, RiskFormComponent<any>> = {
  [RiskType.SUICIDE_RISK]: SuicideRiskForm,
  [RiskType.SELF_HARM_RISK]: SelfHarmRiskForm,
  [RiskType.HOMICIDE_RISK]: HomicideRiskForm,
  [RiskType.DOMESTIC_VIOLENCE_RISK]: DomesticViolenceRiskForm,
  [RiskType.SUBSTANCE_USE_RISK]: SubstanceUseRiskForm,
  [RiskType.EATING_DISORDER_RISK]: EatingDisorderRiskForm,
  [RiskType.ABUSE_OF_VULNERABLE_POPULATIONS_RISK]: AbuseOfVulnerablePopulationsRiskForm,
  [RiskType.MEMBER_INAPPROPRIATE_FOR_PLATFORM_RISK]: MemberNotAppropriateForPlatformRiskForm,
  [RiskType.MEMBER_INAPPROPRIATE_WITH_COACH_RISK]: MemberWasInappropriateWithCoachRiskForm,
  [RiskType.OTHER_RISK]: OtherRiskForm,
};

interface RiskRemovalState {
  riskType?: RiskType;
  showModal: boolean;
}

const createWarningMessage = (risk?: RiskType) => {
  return `Are you sure you want to delete **${camelCaseToTitleCase(
    risk!,
  )}** from your note? This action is irreversible.\n\n**Note**: The rest of your note will not be altered`;
};

/**
 * Component for displaying and managing risk assessments within Coach Notes.
 *
 * @param props
 * @param {boolean} props.readOnly
 * @param {(noteDraft: CoachNotesItem) => void} props.onChange - Updates the note draft state when there is a change in the form.
 * @param {boolean} [props.inline=false] - If true, initially displays the "+ Risk" inline button to allow adding nested risks.
 * @param {boolean} [props.showDropdown=false] - If true, initially shows the risk dropdown instead of the risk button.
 * @param {string} [props.className=""]
 * @param {string} [props["data-testid"]="riskForm"]
 * @returns {React.ReactElement | null}
 */
export const RisksForm = (props: {
  risks?: Risks;
  readOnly: boolean;
  onChange: (risks: Risks) => void;
  inline?: boolean;
  showDropdown?: boolean;
  className?: string;
  'data-testid'?: string;
}): React.ReactElement | null => {
  const {
    risks,
    readOnly,
    inline = false,
    showDropdown = false,
    className = '',
    'data-testid': testId = 'riskForm',
    onChange,
  } = props;
  const isCoach = useAppState(({ user: { role } }) =>
    isCoachOrSupervisor(role),
  );

  const [removeRiskAndShowModal, setRiskToRemoveAndShowModal] = useState<
    RiskRemovalState
  >({
    riskType: undefined,
    showModal: false,
  });
  const [selectedRisks, addOrRemoveRisks] = useState<RiskType[]>(() => {
    return getRiskFields(risks);
  });

  const onRemoveRisk = async (riskType: RiskType) => {
    // only show the removal warning modal if the risk form had been previously modified
    if (risks?.[riskType]) {
      setRiskToRemoveAndShowModal({ riskType, showModal: true });
    } else {
      // otherwise remove the unmodified risk
      await removeRisk(riskType);
    }
  };

  const showRiskRemovalModal = (showModal: boolean) => {
    setRiskToRemoveAndShowModal({ riskType: undefined, showModal });
  };

  const onDropdownSelect = (risk: RiskType) =>
    addOrRemoveRisks([...selectedRisks, risk]);

  const removeRisk = async (riskType: RiskType) => {
    const riskTypeTobeRemoved = riskType || removeRiskAndShowModal.riskType;
    const newRiskList = selectedRisks.filter(
      (risk) => risk !== riskTypeTobeRemoved,
    );
    const newRisks = { ...risks, [riskTypeTobeRemoved!]: undefined } as Risks;
    const risksItems = Object.values(newRisks).filter(Boolean);
    // Since the Actions Taken is associated with the Risk Assessment items, we need to remove it if we've removed
    // all the risk assessments items
    if (risksItems.length === 1 && newRisks.actionsTaken) {
      newRisks.actionsTaken = undefined;
    }

    onChangeRisksForm({ riskType: riskTypeTobeRemoved, risks: newRisks });
    addOrRemoveRisks(newRiskList);
  };

  const onChangeRisksForm = (params: RiskFormChangeParams) => {
    const { risks } = params;

    if (risks) {
      onChange(risks);
    }
  };

  const onChangeActionsTakenForm = (actionsTaken: ActionsTaken) => {
    if (risks) {
      onChangeRisksForm({
        riskType: AdditionalRiskActions.ACTIONS_TAKEN,
        risks: { ...risks, actionsTaken } as Risks,
      });
    }
  };

  const isRemovable = !readOnly && (inline || selectedRisks.length > 1);

  const baseClass = inline
    ? classNameCombiner([styles.inlineSection, className])
    : classNameCombiner([styles.cardSection, className]);

  return (
    <>
      <ActionDialog
        data-testid="removeRiskActionDialog"
        className={coachNotesStyles.actionDialog}
        bodyClass={coachNotesStyles.actionDialogBody}
        cancelBtnClass={coachNotesStyles.actionDialogCancel}
        confirmBtnClass={coachNotesStyles.deleteDialogConfirm}
        titleText="Delete Risk Assessment?"
        confirmLabel="Yes, Delete"
        isOpen={removeRiskAndShowModal.showModal}
        setIsOpen={showRiskRemovalModal}
        actionHandler={removeRisk}
        dialogMessage={{
          message: createWarningMessage(removeRiskAndShowModal.riskType),
        }}
      />
      <div
        data-testid={testId}
        className={classNameCombiner([
          selectedRisks.length ? styles.inlineRiskSection : baseClass,
          styles.noBorder,
        ])}
      >
        {selectedRisks.map((riskType) => {
          const Component = riskAssessmentMap[riskType];
          const _onChange = (data: RiskVaultTypes): void => {
            onChangeRisksForm({
              riskType,
              risks: { ...risks, [riskType]: data } as Risks,
            });
          };

          return (
            <Component
              key={`risk-${riskType}`}
              inline={inline}
              readOnly={readOnly}
              initialValue={risks && (risks[riskType] as any)}
              onChange={_onChange}
              removeRisk={onRemoveRisk}
              isRemovable={isRemovable}
            />
          );
        })}
        <AddRiskDropDown
          enableDropDown={showDropdown}
          readOnly={readOnly}
          inline={inline}
          selectedRisks={selectedRisks}
          onSelectRisk={onDropdownSelect}
        />
        {!!selectedRisks.length && isCoach && (
          <ActionsTakenForm
            onChange={onChangeActionsTakenForm}
            initialValue={risks?.actionsTaken}
            readOnly={readOnly}
            inline={inline}
          />
        )}
      </div>
    </>
  );
};
