import styles from 'app/inbox/components/styles/InboxSection.module.scss';
import { InboxItemState, InboxSections, InboxTab } from 'app/inbox/types';
import { INBOX_LOAD_MORE_ITEMS_PER_PAGE } from 'app/inbox/utils';
import { useAppState, useDispatch } from 'app/state';
import { loadMoreMemberListAction } from 'app/state/amplitude/actions/inbox';
import { updateSessionState } from 'app/state/features/auth/authSlice';
import { SessionState } from 'app/state/features/auth/types';
import { PaginationInput } from 'generated/globalTypes';
import { useCallback, useState } from 'react';
import { isGraphQLAuthenticationError } from 'shared-components/error-state/utils';

import { EmptyInboxSection } from '../../inboxV2/components/EmptyInboxSection';
import { InboxSectionItem } from './InboxSectionItem';

export const DISPLAY_LIMIT = 20;

interface InboxSectionContentProps {
  section: InboxSections;
  tab: InboxTab;
  onItemClick: (item: InboxItemState, section: InboxSections) => void;
  loadMore: (input: PaginationInput, section: InboxSections) => Promise<void>;
}

interface LoadMoreItemsResult {
  success: boolean;
  errorMessage?: string;
}

export function InboxSectionContentWithRedux({
  onItemClick,
  section,
  tab,
  loadMore,
}: InboxSectionContentProps) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [displayLimit, setDisplayLimit] = useState(DISPLAY_LIMIT);

  const { userId, role } = useAppState(({ user }) => ({
    role: user.role!,
    userId: user.userId!,
  }));

  const { ids: inboxItems, hasMore, cursor } = useAppState(
    ({ inbox: { tabSections, search } }) => {
      let relevantSection = tabSections[section];
      if (
        section === InboxSections.PAST ||
        section === InboxSections.SCHEDULED ||
        section === InboxSections.CLOSED
      ) {
        relevantSection = search[section] ?? tabSections[section];
      }

      return {
        cursor: relevantSection.cursor,
        hasMore: relevantSection.hasMore,
        ids: relevantSection.ids,
      };
    },
  );

  // hasMore: This is a backend flag indicating more items available to fetch.
  // hasMoreLocalItems: This is a local flag indicating more fetched items are available to display.
  const hasMoreLocalItems = displayLimit < inboxItems.size;
  const showLoadMoreButton = hasMore || hasMoreLocalItems;

  const loadMoreItems = useCallback(async () => {
    setLoading(true);
    setError(undefined);
    const input = { cursor, maxItemsPerPage: INBOX_LOAD_MORE_ITEMS_PER_PAGE };

    let success = true;
    let errorMessage;

    try {
      await loadMore(input, section);
      setDisplayLimit((prevLimit) => prevLimit + DISPLAY_LIMIT);
    } catch (e) {
      success = false;
      errorMessage = e.message;
      setError(e.message);
      if (isGraphQLAuthenticationError(e)) {
        dispatch(updateSessionState(SessionState.EXPIRED));
      }
    } finally {
      setLoading(false);
    }

    return { errorMessage, success };
  }, [cursor, dispatch, loadMore, section]);

  const handleLoadMoreClick = useCallback(async () => {
    let loadMoreResult: LoadMoreItemsResult = {
      errorMessage: undefined,
      success: true,
    };

    if (hasMoreLocalItems) {
      setDisplayLimit((prevLimit) => prevLimit + DISPLAY_LIMIT);
    } else {
      loadMoreResult = await loadMoreItems();
    }

    dispatch(
      loadMoreMemberListAction({
        errorMessage: loadMoreResult.errorMessage,
        hasMore: !!hasMore,
        itemsShown: displayLimit,
        role,
        section,
        success: loadMoreResult.success,
        totalItems: inboxItems.size,
        userId,
      }),
    );
  }, [
    displayLimit,
    hasMoreLocalItems,
    inboxItems.size,
    loadMoreItems,
    role,
    section,
    hasMore,
    userId,
    dispatch,
  ]);

  return inboxItems.size === 0 ? (
    <EmptyInboxSection section={section} />
  ) : (
    <>
      {[...inboxItems].slice(0, displayLimit).map((memberId) => (
        <InboxSectionItem
          onItemClick={onItemClick}
          section={section}
          key={`${section}-${memberId}`}
          tab={tab}
          memberId={memberId}
        />
      ))}
      {error && (
        <div data-testid="load-more-error" className={styles.error} key="error">
          Error loading, please try again
        </div>
      )}
      {showLoadMoreButton && (
        <button
          data-testid="load-more-btn"
          disabled={loading}
          className={styles.loadMoreBtn}
          onClick={handleLoadMoreClick}
          type="button"
        >
          {loading ? 'loading...' : 'Load More'}
        </button>
      )}
    </>
  );
}
