import { defaultNoteActions } from 'app/appointments/ClinicalNoteActionBar';
import { NoteAction } from 'app/appointments/types';
import { getAuthenticatedUser } from 'app/state/generated/getAuthenticatedUser';
import { ILogger } from 'app/state/log/Logger';
import { AuthenticatedUser, PubnubConfig } from 'app/state/user/actions';
import { UserRole } from 'generated/globalTypes';
import {
  defaultFeatureFlags,
  FeatureFlags,
  TransientFeatureFlag,
} from 'hooks/useFeatureFlags';
import { isClinicalSupervisor, isClinicianOrSupervisor } from 'utils';

export class AuthFormatter {
  constructor(private logger: ILogger) {}

  getFeatureFlags(data: getAuthenticatedUser): FeatureFlags {
    const featureFlags = { ...defaultFeatureFlags };
    const { role, waffleFlagValues } = data.getWebAuthenticatedUser;

    if (isClinicalSupervisor(role) || role === UserRole.MEMBER_SUPPORT) {
      featureFlags.canViewMSAppointmentsTable = true;
    }

    if (isClinicianOrSupervisor(role)) {
      featureFlags.allowedNoteActions = defaultNoteActions;
      featureFlags.canViewPatients = true;
    } else if (role === UserRole.MEMBER_SUPPORT) {
      featureFlags.allowedNoteActions = new Set([NoteAction.DOWNLOAD]);
    }

    waffleFlagValues.forEach(({ name, enabled }) => {
      featureFlags.transientFeatureFlags[
        name as TransientFeatureFlag
      ] = enabled;
    });

    if (
      isClinicalSupervisor(role) &&
      featureFlags.transientFeatureFlags[
        TransientFeatureFlag.ENABLE_SUPERVISOR_SIGN_AND_LOCK_NOTES_FOR_USER
      ]
    ) {
      featureFlags.allowedNoteActions.add(NoteAction.REVIEW_DRAFT);
    }

    return featureFlags;
  }

  anAuthenticatedUser(data: getAuthenticatedUser): AuthenticatedUser {
    const { listenerId, pubnubConfig } = data.getListenerAuthenticatedUser;

    const config: PubnubConfig = {
      authKey: pubnubConfig?.authKey ?? null,
      coachBroadcastChannelId: pubnubConfig?.coachBroadcastChannelId ?? null,
      rpcToCoachChannelId: pubnubConfig?.rpcToCoachChannelId ?? null,
    };

    // Implementation note for future reference:

    // The role of a user starts first from the Okta user's "groups" claim. This is done by GingerGraphQL's
    // OktaJwt.ts's getUserRole(...). This "role" is then used by Web's (not Listener's) GingerJWTAuthentication
    // (which subclasses CustomBaseAuthentication). CustomBaseAuthentication's authenticate_user(role, ...) takes
    // that role from the Okta JWT and does some further mapping (mainly for the supervisor roles) to return the
    // FINAL role that is then stored into the Redux's "user" slice by this syncAuthenticatedUserToRedux().
    // Somewhere in there is an opportunity for some refactoring (or at least some documentation somewhere). Until
    // then, this will do for now.
    return {
      ...data.getWebAuthenticatedUser,
      coachinghubUsername:
        data.getListenerAuthenticatedUser.coachinghubUsername,
      isAcceptingDropinMembers:
        data.getListenerAuthenticatedUser.coach?.state
          ?.isAcceptingDropinMembers ?? false,
      listenerId,
      listenerUserId: data.getListenerAuthenticatedUser.listenerUserId,
      pubnubAuthKey: data.getListenerAuthenticatedUser.pubnubAuthKey,
      pubnubConfig: config,
      shiftStatus:
        data.getListenerAuthenticatedUser.coach?.state?.shiftStatus || null,
      timezone:
        data.getWebAuthenticatedUser.timezone ||
        data.getListenerAuthenticatedUser.timezone,
    };
  }
}
