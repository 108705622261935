import { ApolloError } from '@apollo/client';
import { ClinicianRole } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/ClinicianRole';
import { AbuseOfVulnerablePopulationsRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/AbuseOfVulnerablePopulationsRisk';
import { ActionsTaken } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/ActionsTaken';
import { DomesticViolenceRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/DomesticViolenceRisk';
import { DeescalationNeed } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/DeescalationNeed';
import { DropInConsult } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/DropInConsult';
import { ExperiencingRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/ExperiencingRisk';
import { ExploringTheApp } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/ExploringTheApp';
import { OtherNote } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/OtherNote';
import { SeekingClinical } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/SeekingClinical';
import { EatingDisorderRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/EatingDisorderRisk';
import { FollowUp } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/FollowUp';
import { HomicideRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/HomicideRisk';
import { InitialConsult } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/InitialConsult';
import { MemberNotAppropriateForPlatformRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/MemberNotAppropriateForPlatformRisk';
import { MemberWasInppropriateWithCoachRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/MemberWasInppropriateWithCoachRisk';
import { CoachingNote } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/notes/CoachingNote';
import { OtherRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/OtherRisk';
import { OutreachAttempt } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/OutreachAttempt';
import { QuickNote } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/QuickNote';
import {
  RiskAssessment,
  Risks,
} from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/RiskAssessment';
import { SelfHarmRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/SelfHarmRisk';
import { SubstanceUseRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/SubstanceUseRisk';
import { SuicideRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/SuicideRisk';
import { VaultItem_SchemaType } from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import { NotesUserMetadata } from '@ginger.io/vault-shared-care-notes/dist/generated/protobuf-schemas/vault-shared-care-notes/NotesUserMetadata';
import { CustomError } from 'app/appointments/errorUtils';
import { MENU_KEY_TYPE } from 'shared-components/CardActionMenu';
import { StateSlice } from 'app/state/status/types/StateSlice';
import { Subsection } from 'app/vault/api/ShareableSubsectionTypes';
import { OutOfSessionOrTerminationNote } from 'app/vault/hooks/NonAppointments/useOutOfSessionAndTerminationNotes';

export enum NoteViewOrder {
  MOST_RECENT = 'asc',
  LEAST_RECENT = 'desc',
}

export interface NavigationParams {
  index?: number | null;
  noteType?: MENU_KEY_TYPE | CareProviderNoteType;
  noteId?: string;
}

export enum CoachNoteType {
  FOLLOW_UP = 'follow-up',
  QUICK_NOTE = 'quick-note',
  OUTREACH_ATTEMPT = 'outreach-attempt',
  RISK = 'risk',
  INITIAL_CONSULT = 'initial-consult',
}

export enum LegacyNoteType {
  SUMMARY_NOTE = 'summary-note',
  DAILY_COACHING_NOTE = 'daily-coaching-note',
}

export enum ShareableClinicianNoteType {
  UNDEFINED = 'undefined_note_type',
  PSYCHIATRY_INTAKE = 'psychiatry_intake',
  PSYCHIATRY_PROGRESS = 'psychiatry_progress',
  PSYCHIATRY_TERMINATION = 'psychiatry_termination',
  THERAPY_INTAKE = 'therapy_intake',
  THERAPY_PROGRESS = 'therapy_progress',
  THERAPY_TERMINATION = 'therapy_termination',
}

export enum DropInNoteType {
  DROP_IN = 'drop-in',
  DROP_IN_CONSULT = 'drop-in-consult',
  DE_ESCALATION_NEED = 'de-escalation-need',
  EXPERIENCING_RISK = 'experiencing-risk',
  EXPLORING_THE_APP = 'exploring-the-app',
  SEEKING_CLINICAL = 'seeking-clinical',
  DROP_IN_OTHER = 'drop-in-other',
}

export type CareProviderNoteType =
  | CoachNoteType
  | LegacyNoteType
  | ShareableClinicianNoteType
  | DropInNoteType;

/**
  @description This includes all clinical notes that are not appointments (such as Out of Session
  or Termination notes) and notes made by coaches that are shared with Clinicians
*/
export type NonAppointmentNotesForClinicalView =
  | OutOfSessionOrTerminationNote
  | NotesItemResponse;

/**
  @description These sections of clinical notes are split into separate VaultItems that
  are shared with both the clinical care team and coaching care team.
*/
export enum ShareableClinicianNoteDetails {
  SAFETY_PLAN = 'safety-plan',
  TREATMENT_PLAN_GOALS = 'treatment-plan-goals',
  MESSAGE_TO_CARE_TEAM = 'message-to-care-team',
}

export enum CoachNotesActions {
  DELETE_DRAFT = 'deleteDraft',
}

export enum CoachNoteSessionType {
  FOLLOW_UP = 'Follow-up',
  QUICK_NOTE = 'Quick Note',
  OUTREACH_ATTEMPT = 'Outreach Attempt',
  RISK = 'Risk',
  INITIAL_CONSULT = 'Discovery Session',
  SUMMARY_NOTE = 'Listener Summary Notes',
  DAILY_COACHING_NOTE = 'Daily Coaching',

  // The note types below are Clinical Notes, but we include them here since some sections
  // within those clinical notes are shared with coaches as well.
  CLINICAL_NOTE = 'Clinical Note', // generic session name for notes that are missing a type
  PSYCHIATRY_INTAKE = 'Intake (Psychiatry)',
  PSYCHIATRY_PROGRESS = 'Follow-up (Psychiatry)',
  PSYCHIATRY_TERMINATION = 'Termination (Psychiatry)',
  THERAPY_INTAKE = 'Intake (Therapy)',
  THERAPY_PROGRESS = 'Follow-up (Therapy)',
  THERAPY_TERMINATION = 'Termination (Therapy)',

  // Drop In Notes
  DROP_IN_CONSULT = 'Drop-in Note',
  DE_ESCALATION_NEED = 'Drop-in Note',
  EXPERIENCING_RISK = 'Drop-in Note',
  EXPLORING_THE_APP = 'Drop-in Note',
  SEEKING_CLINICAL = 'Drop-in Note',
  DROP_IN_OTHER = 'Drop-in Note',
}

export enum RoleType {
  COACHING = 'Coaching',
  CLINICAL = 'Clinical',
}

export const enum FilterKeys {
  ORDER = 'order',
  NOTE_TYPE = 'noteType',
}

export enum RiskType {
  SUICIDE_RISK = 'suicideRisk',
  SELF_HARM_RISK = 'selfHarmRisk',
  HOMICIDE_RISK = 'homicideRisk',
  DOMESTIC_VIOLENCE_RISK = 'domesticViolenceRisk',
  SUBSTANCE_USE_RISK = 'substanceUseRisk',
  EATING_DISORDER_RISK = 'eatingDisorderRisk',
  ABUSE_OF_VULNERABLE_POPULATIONS_RISK = 'abuseOfVulnerablePopulationsRisk',
  MEMBER_INAPPROPRIATE_FOR_PLATFORM_RISK = 'inappropriateForPlatform',
  MEMBER_INAPPROPRIATE_WITH_COACH_RISK = 'inappropriateWithCoach',
  OTHER_RISK = 'otherRisk',
}

export enum AdditionalRiskActions {
  ACTIONS_TAKEN = 'actionsTaken',
}

export type Details = {
  role: string | RoleType;
  session: string;
  titleAndSessionNumber?: string;
  risks?: string | null;
};

export interface CoachNotesItem extends NotesItemResponse {
  sessionNumber?: number;
  shareWithClinicians?: boolean;
}

export type SummaryNoteDetails = {
  data?: SummaryNote;
  latestCreationDate?: ISODateString;
  lastCreatedBy?: string;
};

export type SummaryNote = {
  followUp: string;
  triage: string;
  demo: string;
  issues: string;
  backgroundInfo: string;
  disposition: string;
  medicationsAndCare: string;
  goals: string;
  msAndCoachNotes: string;
};

export type DropInNote =
  | DropInConsult
  | DeescalationNeed
  | ExperiencingRisk
  | ExploringTheApp
  | SeekingClinical
  | OtherNote;

export type NoteItemWithRiskAssessments =
  | FollowUp
  | InitialConsult
  | DropInNote;

export type NoteItemWithRisks = RiskAssessment;

export type NoteItem =
  | NoteItemWithRiskAssessments
  | NoteItemWithRisks
  | OutreachAttempt
  | QuickNote
  | RiskVaultTypes
  | ActionsTaken;

export type LegacyNote = SummaryNote | CoachingNote;

export type NoteOrSubsection = NoteItem | LegacyNote | Subsection;

export type NoteItemWithSessionCount = FollowUp | InitialConsult;

export type NotesItemResponse = {
  id?: string;
  metadataId?: string;
  data?: NoteOrSubsection;
  noteType?: CareProviderNoteType;
  noteDetails?: Details;
  schemaType?: VaultItem_SchemaType;
  associatedRisks?: RiskType[];
  startDate?: string;
  dateString?: string;
  createdAt?: string;
  createdBy?: string;
  readOnly?: boolean;
  updatedBy?: string;
  updatedAt?: string;
  sourceVersion?: string;
  isUnread?: boolean;
};

export type DecodedNotes = {
  [id: string]: NotesItemResponse;
};

export type SessionInfo = {
  latestSessionCount?: number;
};

export type NotesAndSessionInfo = {
  notes: DecodedNotes;
  sessionInfo: SessionInfo;
  error: CustomError<{
    coachNotesErrors?: ApolloError;
    legacyNotesErrors?: ApolloError;
  }> | null;
};

export interface MutationResponse {
  success: boolean;
  id: string | null;
  error?: string | Error;
}

export type NotesUserMetadataParams = Omit<NotesUserMetadata, 'version'>;

export interface UseCoachNotesResult {
  coachNotes: StateSlice<NotesAndSessionInfo>;
  onSelectCoachNote: (note: NotesItemResponse) => Promise<void>;
  setQueryParams: (params?: NavigationParams) => void;
  publishNote: () => Promise<void>;
  createCoachNote: (params: CoachNotesItem) => Promise<MutationResponse>;
  deleteCoachNote: () => Promise<void>;
  updateCoachNote: (
    itemId: string,
    params: CoachNotesItem,
  ) => Promise<MutationResponse>;
  updateDraftNoteState: (noteState: CoachNotesItem) => Promise<void>;
}

export type RisksAndActionsTaken =
  | RiskType
  | AdditionalRiskActions.ACTIONS_TAKEN;

export interface RiskFormChangeParams {
  risks: Risks;
  riskType: RisksAndActionsTaken;
}

export enum CoachNoteFormatType {
  BASE_RISK_ASSESSMENT,
  NOTE_ITEM_WITH_RISK_ASSESSMENTS,
  NOTE_ITEM_WITHOUT_RISK_ASSESSMENTS,
}

export type RiskFormProps<T> = {
  readOnly: boolean;
  inline?: boolean;
  isRemovable?: boolean;
  'data-testid'?: string;
  initialValue?: T;
  onChange: (data: T) => void;
  removeRisk?: (riskType: RiskType) => void;
};
export type RiskFormComponent<T> = React.ComponentType<RiskFormProps<T>>;

export type RiskVaultTypes =
  | SuicideRisk
  | SelfHarmRisk
  | HomicideRisk
  | DomesticViolenceRisk
  | SubstanceUseRisk
  | EatingDisorderRisk
  | AbuseOfVulnerablePopulationsRisk
  | MemberNotAppropriateForPlatformRisk
  | MemberWasInppropriateWithCoachRisk
  | OtherRisk;
export type RiskOnStateChange = (data: RiskVaultTypes) => void;

type InvalidClinicianRole =
  | ClinicianRole.UNRECOGNIZED
  | ClinicianRole.undefined_role;
export type ValidClinicianRole = Exclude<ClinicianRole, InvalidClinicianRole>;

export const SHAREABLE_COACH_NOTES_FOR_CLINICIAN = new Set([
  CoachNoteType.INITIAL_CONSULT,
  CoachNoteType.OUTREACH_ATTEMPT,
  CoachNoteType.RISK,
  CoachNoteType.FOLLOW_UP,
]);
