import {
  arrayField,
  booleanField,
  field,
  FieldDefinitions,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import { NoteType } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/NoteType';
import {
  SafetyPlanStatus,
  Version as SafetyPlanVersion,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/safety/SafetyPlan';
import { SafetyConcern } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyConcern';
import {
  SafetyIntake as Safety,
  Version as SafetyIntakeVersion,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyIntake';
import {
  SafetySection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import { Divider } from '@mui/material';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';
import { Width } from 'types/StyleTypes';
import {
  FrequencyField,
  TextAreaField,
  YesOrNoField,
} from 'app/notes-ui/forms/fields';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import {
  optionalCHIDescription,
  optionalCSIDescription,
  optionalField,
  optionalPHIDescription,
  optionalPSIDescription,
  optionalSelfInjury,
  safetyPlanDescription,
} from 'app/notes-ui/shared/ValidationRules';
import Labels from 'app/notes-ui/strings/en.json';
import { AUTO_SAVE_DRAFT_DELAY } from 'app/notes-ui/utils';
import React from 'react';

type Props = {
  appointmentId: string;
  initialValue?: Safety;
  onSubmit: (data: SafetySection) => void;
  updateDraftNoteState: (data: SafetySection) => void;
  disabled?: boolean;
  savingNote?: boolean;
};

export const fieldDefs: FieldDefinitions<Safety> = {
  appointmentId: stringField(),

  currentHomicidalIdeation: {
    description: stringField({ rules: [optionalCHIDescription] }),
    hasPast: booleanField({ default: false, rules: [optionalField] }),
    intent: booleanField(),
    means: booleanField(),
    pastSummarization: stringField({ rules: [optionalField] }),
    plan: booleanField(),
    specificTargetsEntityOrLocationConsidered: booleanField(),
    thoughts: booleanField(),
  },

  anyChangesOrRisks: field({ rules: [optionalField] }),

  currentSelfInjury: {
    bodyPartHarmed: stringField({ rules: [optionalSelfInjury] }),
    currentSummarization: stringField({ rules: [optionalField] }),
    everSoughtMedicalAttentionAsAResult: booleanField({
      rules: [optionalSelfInjury],
    }),
    extentOfInjury: stringField({ rules: [optionalSelfInjury] }),
    frequency: {
      period: field({ rules: [optionalSelfInjury] }),
      timesPerPeriod: field({ rules: [optionalSelfInjury] }),
    },
    hasPast: booleanField({ default: false }),
    instrumentUsed: stringField({ rules: [optionalSelfInjury] }),
    isPresent: booleanField(),
    pastEverSoughtMedicalAttentionAsAResult: booleanField({ default: false }),
    pastSummarization: stringField({ rules: [optionalField] }),
  },

  currentSuicidalIdeation: {
    description: stringField({ rules: [optionalCSIDescription] }),
    hasPast: booleanField({ default: false, rules: [optionalField] }),
    intent: booleanField(),
    means: booleanField(),
    pastSummarization: stringField({ rules: [optionalField] }),
    plan: booleanField(),
    thoughts: booleanField(),
  },

  domesticViolenceRisk: {
    currentDescription: stringField({ rules: [optionalField] }),
  },

  eatingDisorderRisk: {
    currentDescription: stringField({ rules: [optionalField] }),
  },

  highSubstanceUse: {
    currentDescription: stringField({ rules: [optionalField] }),
  },
  otherConcerns: {
    currentDescription: stringField({ rules: [optionalField] }),
  },

  pastHomicidalIdeation: {
    description: stringField({ rules: [optionalPHIDescription] }),
    isPresent: booleanField(),
  },

  pastSuicidalIdeation: {
    description: stringField({ rules: [optionalPSIDescription] }),
    isPresent: booleanField(),
  },

  selectedConcerns: arrayField<SafetyConcern>(),

  safetyPlan: {
    appointmentEnd: stringField({ rules: [] }),
    appointmentId: stringField({ rules: [] }),
    appointmentStart: stringField({ rules: [] }),
    description: stringField({ rules: [safetyPlanDescription] }),
    actionsTaken: {
      consultedWithManager: field<BooleanOption>({
        default: BooleanOption.undefined_choice,
        rules: [optionalField],
      }),
      crr: field<BooleanOption>({
        default: BooleanOption.undefined_choice,
        rules: [optionalField],
      }),
      sharedResources: field<BooleanOption>({
        default: BooleanOption.undefined_choice,
        rules: [optionalField],
      }),
      careCoordinationTeamEngaged: field<BooleanOption>({
        default: BooleanOption.undefined_choice,
        rules: [optionalField],
      }),
      onCallClinicianContacted: field<BooleanOption>({
        default: BooleanOption.undefined_choice,
        rules: [optionalField],
      }),
      onCallClinicianContactedNotes: stringField({ rules: [optionalField] }),
    },
    noteType: field<NoteType>({ rules: [] }),
    psychiatryQuestions: {
      agenciesToContactInCrisis: field<string[]>({
        default: [],
        rules: [optionalField],
      }),
      safetyPlanDocumentation: stringField({ rules: [optionalField] }),
    },
    status: field<SafetyPlanStatus>({
      default: SafetyPlanStatus.undefined_status,
      rules: [optionalField],
    }),
    therapyQuestions: {
      agenciesToContactInCrisis: field<string[]>({
        default: [],
        rules: [optionalField],
      }),
      externalStrategiesOrientationForMentalHealthCrisis: stringField({
        rules: [optionalField],
      }),
      internalStrategiesOrientationForMentalHealthCrisis: stringField({
        rules: [optionalField],
      }),
      peopleToContactInCrisis: field<string[]>({
        default: [],
        rules: [optionalField],
      }),
      rememberWhatsImportant: stringField({ rules: [optionalField] }),
      safeEnvironmentOrientation: stringField({ rules: [optionalField] }),
      warningSignsAndTriggersForDevelopingCrisis: stringField({
        rules: [optionalField],
      }),
    },
    version: field<SafetyPlanVersion>({
      default: SafetyPlanVersion.undefined_version,
    }),
  },

  version: field<SafetyIntakeVersion>({
    default: SafetyIntakeVersion.undefined_version,
  }),
  vulnerablePopulationsAbuse: {
    currentDescription: stringField({ rules: [optionalField] }),
  },
};

export function SafetyForm(props: Props) {
  const { appointmentId, initialValue, disabled, savingNote } = props;

  const { fields, getValue, validate } = useForm<Safety>(
    fieldDefs,
    { ...initialValue, appointmentId } as Safety,
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: TherapyIntakeSectionName.SAFETY,
        }),
    },
  );

  const onSubmit = async () => {
    if (await validate()) {
      const fieldValues = { ...getValue() };
      const frequency = fieldValues.currentSelfInjury?.frequency;
      if (
        fieldValues.currentSelfInjury &&
        frequency &&
        frequency.period === undefined &&
        frequency.timesPerPeriod === undefined
      ) {
        fieldValues.currentSelfInjury = {
          ...fieldValues.currentSelfInjury,
          frequency: undefined,
        };
      }
      props.onSubmit({
        data: fieldValues,
        name: TherapyIntakeSectionName.SAFETY,
      });
    }
  };

  return (
    <>
      <Grid spacing={1}>
        <Columns widths={[COLUMN_WIDTH.HALF, COLUMN_WIDTH.HALF]}>
          <NoteFormControlLabel label={`${Labels.CURRENT_SI}*`}>
            <YesOrNoField
              disabled={disabled}
              yesLabel={Labels.ENDORSED_LABEL}
              noLabel={Labels.DENIED_LABEL}
              testId="currentSIThoughts"
              label="Thoughts"
              field={fields.currentSuicidalIdeation.thoughts}
            />

            <YesOrNoField
              disabled={disabled}
              yesLabel={Labels.ENDORSED_LABEL}
              noLabel={Labels.DENIED_LABEL}
              testId="currentSIIntent"
              label="Intent"
              field={fields.currentSuicidalIdeation.intent}
            />

            <YesOrNoField
              disabled={disabled}
              yesLabel={Labels.ENDORSED_LABEL}
              noLabel={Labels.DENIED_LABEL}
              testId="currentSIPlan"
              label="Plan"
              field={fields.currentSuicidalIdeation.plan}
            />

            <YesOrNoField
              disabled={disabled}
              yesLabel={Labels.ENDORSED_LABEL}
              noLabel={Labels.DENIED_LABEL}
              testId="currentSIMeans"
              label="Means"
              field={fields.currentSuicidalIdeation.means}
            />
          </NoteFormControlLabel>

          <NoteFormControlLabel label={Labels.CURRENT_HI}>
            <YesOrNoField
              disabled={disabled}
              yesLabel={Labels.ENDORSED_LABEL}
              noLabel={Labels.DENIED_LABEL}
              testId="currentHIThoughts"
              label="Thoughts"
              field={fields.currentHomicidalIdeation.thoughts}
            />

            <YesOrNoField
              disabled={disabled}
              yesLabel={Labels.ENDORSED_LABEL}
              noLabel={Labels.DENIED_LABEL}
              testId="currentHIIntent"
              label="Intent"
              field={fields.currentHomicidalIdeation.intent}
            />

            <YesOrNoField
              disabled={disabled}
              yesLabel={Labels.ENDORSED_LABEL}
              noLabel={Labels.DENIED_LABEL}
              testId="currentHIPlan"
              label="Plan"
              field={fields.currentHomicidalIdeation.plan}
            />

            <YesOrNoField
              disabled={disabled}
              yesLabel={Labels.ENDORSED_LABEL}
              noLabel={Labels.DENIED_LABEL}
              testId="currentHIMeans"
              label="Means"
              field={fields.currentHomicidalIdeation.means}
            />

            <YesOrNoField
              disabled={disabled}
              yesLabel={Labels.ENDORSED_LABEL}
              noLabel={Labels.DENIED_LABEL}
              testId="specificTargetsEntityOrLocationConsidered"
              label="Identifiable target(s), entity, or location?"
              field={
                fields.currentHomicidalIdeation
                  .specificTargetsEntityOrLocationConsidered
              }
            />
          </NoteFormControlLabel>
        </Columns>

        <Columns widths={[COLUMN_WIDTH.HALF, COLUMN_WIDTH.HALF]}>
          <TextAreaField
            width={Width.FULL}
            disabled={disabled}
            testId="currentSIDescription"
            label={Labels.EXPLAIN_ENDORSED_ANSWERS}
            field={fields.currentSuicidalIdeation.description}
          />

          <TextAreaField
            width={Width.FULL}
            disabled={disabled}
            testId="currentHIDescription"
            label={Labels.EXPLAIN_ENDORSED_ANSWERS}
            field={fields.currentHomicidalIdeation.description}
          />
        </Columns>

        <Columns widths={[COLUMN_WIDTH.FULL]}>
          <Divider />
        </Columns>
      </Grid>
      <Grid spacing={1}>
        <Columns widths={[COLUMN_WIDTH.HALF, COLUMN_WIDTH.HALF]}>
          <>
            <div className={styles.formGroup}>
              <YesOrNoField
                disabled={disabled}
                yesLabel={Labels.ENDORSED_LABEL}
                noLabel={Labels.DENIED_LABEL}
                testId="pastSI"
                label={Labels.PAST_SI_PRESENT}
                field={fields.pastSuicidalIdeation.isPresent}
              />
            </div>

            <TextAreaField
              width={Width.FULL}
              disabled={
                disabled || !fields.pastSuicidalIdeation.isPresent.value
              }
              testId="pastSIDescription"
              label={Labels.EXPLAIN_ENDORSED_ANSWERS}
              field={fields.pastSuicidalIdeation.description}
              tooltip="Explain circumstances, time-frame, any gestures/attempts made, and any treatment provided as a result."
            />
          </>

          <>
            <div className={styles.formGroup}>
              <YesOrNoField
                disabled={disabled}
                yesLabel={Labels.ENDORSED_LABEL}
                noLabel={Labels.DENIED_LABEL}
                testId="pastHI"
                label={Labels.PAST_HI_PRESENT}
                field={fields.pastHomicidalIdeation.isPresent}
              />
            </div>
            <TextAreaField
              width={Width.FULL}
              disabled={
                disabled || !fields.pastHomicidalIdeation.isPresent.value
              }
              testId="pastHIDescription"
              label={Labels.EXPLAIN_ENDORSED_ANSWERS}
              field={fields.pastHomicidalIdeation.description}
              tooltip="Explain circumstances, time-frame, any gestures/attempts made, and any treatment provided as a result."
            />
          </>
        </Columns>

        <Columns widths={[COLUMN_WIDTH.FULL]}>
          <Divider />
        </Columns>
      </Grid>

      <>
        <div className={styles.formGroup}>
          <YesOrNoField
            disabled={disabled}
            yesLabel={Labels.ENDORSED_LABEL}
            noLabel={Labels.DENIED_LABEL}
            testId="selfInjuryPresent"
            label="Current Self Injury*"
            field={fields.currentSelfInjury.isPresent}
          />
        </div>

        <div className={styles.formGroup}>
          <FrequencyField
            disabled={disabled || !fields.currentSelfInjury.isPresent.value}
            testId="selfInjuryFrequency"
            periodField={fields.currentSelfInjury.frequency.period}
            timesPerPeriodField={
              fields.currentSelfInjury.frequency.timesPerPeriod
            }
          />
        </div>

        <TextAreaField
          disabled={disabled || !fields.currentSelfInjury.isPresent.value}
          testId="instrumentUsed"
          label="Instrument Used"
          field={fields.currentSelfInjury.instrumentUsed}
        />

        <TextAreaField
          disabled={disabled || !fields.currentSelfInjury.isPresent.value}
          testId="bodyPartHarmed"
          label="Part of body harmed"
          field={fields.currentSelfInjury.bodyPartHarmed}
        />

        <TextAreaField
          disabled={disabled || !fields.currentSelfInjury.isPresent.value}
          testId="extentOfInjury"
          label="Depth of cutting/extent of burning, etc"
          field={fields.currentSelfInjury.extentOfInjury}
        />

        <div className={styles.formGroup}>
          <YesOrNoField
            disabled={disabled || !fields.currentSelfInjury.isPresent.value}
            yesLabel={Labels.ENDORSED_LABEL}
            noLabel={Labels.DENIED_LABEL}
            testId="everSoughtMedicalAttentionAsAResult"
            label={Labels.EVER_SOUGHT_MEDICAL}
            field={fields.currentSelfInjury.everSoughtMedicalAttentionAsAResult}
          />
        </div>
        <Divider />

        <NoteFormControlLabel label="Safety Plan*">
          <TextAreaField
            disabled={disabled}
            testId="safetyPlan"
            label={Labels.SAFETY_PLAN_DESCRIPTION}
            field={fields.safetyPlan.description}
          />
        </NoteFormControlLabel>
      </>
      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </>
  );
}

export const validate = (data: Safety | null) =>
  data !== null && validateForm(data, fieldDefs);
