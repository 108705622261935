import moment from 'moment-timezone';
import { getFormattedDateAndTimeForTooltips } from 'utils/dateTime';

export function formatTooltipText(
  date: moment.Moment,
  timezone: string,
  nameDisplay: string,
): string {
  return `${nameDisplay} last updated · ${getFormattedDateAndTimeForTooltips(
    date,
    timezone,
  )}`;
}
