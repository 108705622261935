import { MessageStatus } from 'app/coach/pubnub/types';
import Tooltip from 'shared-components/tooltip/Tooltip';
import React from 'react';

import { MemberProfileBaseProps } from '../ChatContainer';
import { errorTooltipText } from '../strings';
import { getTooltipTextWithStatus } from '../utils';
import { MessageBubble } from './MessageBubble';
import styles from './MessageContainer.module.scss';
import { MessageStatusIndicator } from './MessageStatusIndicator';

export const CareProviderMessageContainer = ({
  memberProfileBaseProps,
  messageProps,
  shouldDisplaySeenStatus,
}: {
  memberProfileBaseProps: MemberProfileBaseProps;
  messageProps: {
    message: string;
    dateAndTime: string;
    status: MessageStatus | null;
  };
  shouldDisplaySeenStatus: boolean | undefined;
}) => {
  const { message, dateAndTime, status } = messageProps;
  let tooltipText: string;
  switch (status) {
    case MessageStatus.ERROR: {
      tooltipText = errorTooltipText;
      break;
    }
    case MessageStatus.DELIVERED:
    case MessageStatus.SEEN:
    case MessageStatus.SENT: {
      tooltipText = getTooltipTextWithStatus(status, dateAndTime);
      break;
    }
    default: {
      tooltipText = dateAndTime;
    }
  }

  const renderStatusIndicator = (
    status: MessageStatus | null,
    shouldDisplaySeenStatus?: boolean,
  ) => {
    switch (status) {
      case MessageStatus.ERROR:
      case MessageStatus.DELIVERED:
      case MessageStatus.SENT: {
        return (
          <MessageStatusIndicator
            status={status}
            memberProfileBaseProps={memberProfileBaseProps}
          />
        );
      }
      case MessageStatus.SEEN: {
        return shouldDisplaySeenStatus ? (
          <MessageStatusIndicator
            status={status}
            memberProfileBaseProps={memberProfileBaseProps}
          />
        ) : (
          <></>
        );
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <div className={styles.careProviderMessageContainer}>
      <Tooltip
        classes={{ popper: styles.popper, tooltip: styles.tooltip }}
        title={tooltipText}
        enterDelay={0}
        enterTouchDelay={0}
        placement="bottom-end"
      >
        <div>
          <MessageBubble
            isFromMember={false}
            isError={status === MessageStatus.ERROR}
            message={message}
          />
        </div>
      </Tooltip>
      {renderStatusIndicator(status, shouldDisplaySeenStatus)}
    </div>
  );
};
