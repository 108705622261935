import gql from 'graphql-tag';

export const closeChatConversationWithMember = gql`
  mutation CloseChatConversationWithMember(
    $input: CloseChatConversationWithMemberInput!
  ) {
    closeChatConversationWithMember(input: $input) {
      success
      error
    }
  }
`;
