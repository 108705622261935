import { Grid } from '@mui/material';
import {
  ApolloCachingStrategy,
  GQL_QUERY_POLLING_INTERVAL,
} from 'app/constants';
import { appointmentScheduleToTableAppointment } from 'app/patients/tabs/appointments/AppointmentsTabSchedule';
import { useAppState } from 'app/state';
import {
  clickScheduleAppointmentListNewAppointmentAction,
  viewScheduleAppointmentListScreenAction,
} from 'app/state/amplitude/actions/appointments';
import { createAppointmentRoute } from 'app/top-nav/Routes';
import { getTimezone } from 'utils/dateTime';
import gql from 'graphql-tag';
import { useQuery } from 'hooks/useQuery';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import { AppointmentsTableSchedule } from './AppointmentsTableSchedule';
import { GetClinicalAppointmentsForMe } from './generated/GetClinicalAppointmentsForMe';
import { ScheduleTitleNav } from './ScheduleScreen';
import styles from './ScheduleScreen.module.scss';
import { NoteAction } from './types';

export const getAppointmentsQuery = gql`
  query GetClinicalAppointmentsForMe(
    $input: GetClinicalAppointmentsForMeInput!
  ) {
    getClinicalAppointmentsForMe(input: $input) {
      appointments {
        id
        drchronoId
        member {
          id
          firstName
          lastName
        }
        clinician {
          id
          user {
            id
          }
          fullName
          role
        }
        start
        end
        appointmentStatus
        cancelledReason
        type
        zoomMeetingId
        clinicalNote {
          id
          status
          location
          noteType
          statusUpdatedAt
        }
      }
    }
    getAuthenticatedClinician {
      id
    }
  }
`;

export function ScheduleAppointmentListScreen(props: RouteComponentProps) {
  const { history } = props;
  const { timezone } = useAppState((_) => _.user);
  const localTimezone = getTimezone(timezone);
  const dispatch = useDispatch();
  const [todaysAppointmentsOnly, setTodaysAppointmentsOnly] = React.useState<
    boolean
  >(false);

  useEffect(() => {
    dispatch(viewScheduleAppointmentListScreenAction());
  }, [dispatch]);

  return useQuery<GetClinicalAppointmentsForMe>(
    (data: GetClinicalAppointmentsForMe, refetch) => {
      const tableAppointments = data.getClinicalAppointmentsForMe!.appointments.map(
        (clinicalAppointment) =>
          appointmentScheduleToTableAppointment(
            clinicalAppointment,
            localTimezone,
          ),
      );
      const clinicianId = data.getAuthenticatedClinician.id;
      const onNewAppointment = () => {
        dispatch(clickScheduleAppointmentListNewAppointmentAction());
        history.push(createAppointmentRoute({ clinicianId }));
      };
      function filterAppointments(start_date?: string, end_date?: string) {
        return refetch({
          input: {
            filters: {
              windowEnd: end_date,
              windowStart: start_date,
            },
          },
        });
      }

      return (
        <div className={styles.scheduleContainer}>
          <Grid
            container={true}
            justifyContent="center"
            className={styles.inset}
          >
            <Grid item={true} xs={10}>
              <ScheduleTitleNav />
              <AppointmentsTableSchedule
                localTimezone={localTimezone}
                navigateTo={(url) => history.push(url)}
                onNewAppointment={onNewAppointment}
                appointments={tableAppointments}
                filterAppointments={filterAppointments}
                signedLockedAction={NoteAction.VIEW}
                hideClinicianName={true}
                filterBannerEnabled={true}
                enableMemberSessionStats={false}
                todaysAppointmentsOnly={todaysAppointmentsOnly}
                updateTodaysAppointmentsOnly={setTodaysAppointmentsOnly}
              />
            </Grid>
          </Grid>
        </div>
      );
    },
    getAppointmentsQuery,
    {
      fetchPolicy: ApolloCachingStrategy.CACHE_AND_NETWORK,
      pollInterval: GQL_QUERY_POLLING_INTERVAL,
      variables: {
        input: {},
      },
    },
  );
}

export default withRouter(ScheduleAppointmentListScreen);
