import { DEFAULT_TIMEZONE } from 'app/clinician/ClinicianSettingsComponent';
import { TransientFeatureFlag, useFeatureFlags } from 'hooks/useFeatureFlags';
import { useOnMount } from 'hooks/useOnMount';
import { useAppState } from 'app/state';
import { viewOOSNote } from 'app/state/amplitude/actions/notes';
import { renderSliceStateResult } from 'app/state/status/RenderSliceState';
import { useOutOfSessionNote } from 'app/vault/hooks/NonAppointments/useOutOfSessionNote';
import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { OutOfSessionNoteContainer } from './OutOfSessionNoteContainer';

export function OutOfSessionNoteScreen(): ReactElement {
  const history = useHistory();
  const dispatch = useDispatch();

  const { patientId, vaultItemId } = useParams<{
    patientId: string;
    vaultItemId?: string;
  }>();
  const {
    userId: clinicianId,
    lastName,
    firstName,
    timezone,
    role,
  } = useAppState((_) => _.user);
  const {
    note,
    createOrUpdateNote,
    patientProfile,
    creator,
    noteStatus,
  } = useOutOfSessionNote(patientId, clinicianId!, vaultItemId);
  const featureFlags = useFeatureFlags();
  const isSettingsEnabled =
    featureFlags.transientFeatureFlags[TransientFeatureFlag.SCHEDULE];
  const allowedGroupWriteAccess =
    featureFlags.transientFeatureFlags[
      TransientFeatureFlag.ENABLE_SUPERVISOR_SIGN_AND_LOCK_NOTES_FOR_USER
    ];

  useOnMount(() => {
    dispatch(viewOOSNote({ patientId }));
  });

  return renderSliceStateResult(note, (data) => {
    const clinicianName = `${creator?.firstName ?? firstName} ${
      creator?.lastName ?? lastName
    }`;
    const patientName = patientProfile
      ? `${patientProfile.firstName} ${patientProfile.lastName}`
      : '';
    return (
      <OutOfSessionNoteContainer
        clinicianName={clinicianName}
        patientName={patientName}
        timezone={timezone ?? DEFAULT_TIMEZONE}
        settingsEnabled={isSettingsEnabled}
        initialValue={data ?? undefined}
        onSubmit={createOrUpdateNote}
        clinicianId={clinicianId!}
        patientId={patientId}
        clinicianRole={role!}
        vaultItemId={vaultItemId}
        noteStatus={noteStatus}
        allowedGroupWriteAccess={allowedGroupWriteAccess}
        navigateTo={history.replace}
      />
    );
  });
}
