import {
  d2cTagText,
  d2cTagTooltip,
  fusionTagText,
  fusionTagTooltip,
  getLangTag,
  getRoleTag,
  headspaceEAP,
  legacyGingerAppTagText,
  legacyGingetTagTooltip,
  recordingConsentAcceptedText,
  videoCoachingText,
} from 'app/coach/Utils';
import { GuardianRelationshipType } from 'generated/globalTypes';
import React from 'react';
import { SpecialtyTag } from 'shared-components/tags/SpecialtyTag';
import Tooltip from 'shared-components/tooltip/Tooltip';

import { MemberId } from './MemberId';
import styles from './SpecialtyTagsContainer.module.scss';
import { TagContainer } from './TagContainer';

interface SpecialtyTagsContainerProps {
  guardianRelationshipType?: GuardianRelationshipType | null;
  preferredLanguage: string | null;
  isFusion: boolean;
  isD2c: boolean;
  isHeadspaceEAP: boolean;
  isVideoCoachingEligible: boolean;
  isRecordingConsentAccepted: boolean;
  memberId: string;
}
export const SpecialtyTagsContainer = ({
  guardianRelationshipType,
  preferredLanguage,
  isFusion,
  isD2c,
  isHeadspaceEAP,
  isVideoCoachingEligible,
  isRecordingConsentAccepted,
  memberId,
}: SpecialtyTagsContainerProps) => {
  const headerRoleTag = guardianRelationshipType
    ? getRoleTag(guardianRelationshipType)?.full
    : null;
  const langTag = preferredLanguage
    ? getLangTag(preferredLanguage)?.full
    : null;

  return (
    <div className={styles.container} data-testid="header-second-line">
      <MemberId memberId={memberId} />
      <div className={styles.tagsContainer}>
        {isHeadspaceEAP && (
          <SpecialtyTag text={headspaceEAP} className={styles.headspaceEAP} />
        )}
        {isFusion ? (
          <Tooltip title={fusionTagTooltip} placement="bottom-end">
            {/* MUI tooltip doesn't show up w/o a direct child of an HTMLElement type; 
            it's bacause MUI logic for the tooltip is based on refs */}
            <span>
              <TagContainer classNames={[styles.demographicsTop]}>
                <SpecialtyTag text={fusionTagText} className={styles.fusion} />
              </TagContainer>
            </span>
          </Tooltip>
        ) : (
          <Tooltip title={legacyGingetTagTooltip} placement="bottom-end">
            {/* MUI tooltip doesn't show up w/o a direct child of an HTMLElement type; 
            it's bacause MUI logic for the tooltip is based on refs */}
            <span>
              <TagContainer classNames={[styles.demographicsTop]}>
                <SpecialtyTag
                  text={legacyGingerAppTagText}
                  className={styles.legacyMemberApp}
                />
              </TagContainer>
            </span>
          </Tooltip>
        )}
        {isD2c && (
          <Tooltip title={d2cTagTooltip} placement="bottom-end">
            {/* MUI tooltip doesn't show up w/o a direct child of an HTMLElement type; 
            it's bacause MUI logic for the tooltip is based on refs */}
            <span>
              <TagContainer classNames={[styles.demographicsTop]}>
                <SpecialtyTag text={d2cTagText} className={styles.d2c} />
              </TagContainer>
            </span>
          </Tooltip>
        )}
        {headerRoleTag && <SpecialtyTag text={headerRoleTag} />}
        {langTag && <SpecialtyTag text={langTag} />}
        {!isD2c && isVideoCoachingEligible && (
          <SpecialtyTag
            text={videoCoachingText}
            className={styles.miscellaneousTag}
          />
        )}
        {isRecordingConsentAccepted && (
          <SpecialtyTag
            text={recordingConsentAcceptedText}
            className={styles.miscellaneousTag}
          />
        )}
      </div>
    </div>
  );
};
