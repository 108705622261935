import { InboxItemState } from 'app/inbox/types';
import { State } from 'app/state/schema';

export const selectTabState = (
  state: State,
  memberId: string,
): InboxItemState & {
  notifyCount: number;
  hasRisk: boolean;
  isActive: boolean;
} => {
  const {
    inbox,
    multiTab: { activeTab },
  } = state;
  const { tabSections, unreadMessagesMap } = inbox;
  const riskAlerts = tabSections.RISK_ALERTS.ids;
  const item = inbox.inboxItems[memberId] ?? {
    id: memberId,
    name: `Member ${memberId}`,
  };
  const { memberCoachChannelId } = item;
  const notifyCount =
    memberCoachChannelId && memberCoachChannelId in unreadMessagesMap
      ? unreadMessagesMap[memberCoachChannelId].length
      : 0;

  return {
    ...item,
    hasRisk: riskAlerts.has(memberId),
    isActive: activeTab === memberId,
    notifyCount,
  };
};

export const selectActiveTabMemberId = (state: State) => {
  const {
    multiTab: { activeTab },
  } = state;
  return activeTab;
};
