import amplitude, { AmplitudeClient } from 'amplitude-js';
import { Action } from 'redux-reloaded';

import { Config } from './config';
import { ReactAmplitudeClient } from './ReactAmplitudeClient';

export class AmplitudeClientImpl implements ReactAmplitudeClient {
  private amplitudeInstance: AmplitudeClient;

  private initialized = false;

  private pendingEvents: any = [];

  private config: Config;

  constructor(config: Config) {
    this.amplitudeInstance = amplitude.getInstance();
    this.config = config;
  }

  async logEvent<T>(action: Action<T>) {
    // Include the app name in Amplitude events to distinguish Care Hub events from Listener ones.
    const payloadWithAppName = {
      app_name: this.config.appName,
      ...action.payload,
    };

    if (!this.initialized) {
      try {
        const { id, email } = await this.config.fetchUser();
        const amplitudeApiKey = this.config.apiKey;
        this.amplitudeInstance.init(amplitudeApiKey, id);

        if (email !== undefined) {
          this.amplitudeInstance.setUserProperties({ email });
        }

        this.amplitudeInstance.setVersionName(window.careHubVersion);
        this.amplitudeInstance.logEvent(action.type, payloadWithAppName);

        this.initialized = true;
      } catch (e) {
        this.pendingEvents.push({ action });
      }
    } else {
      this.logPendingEvents(); // note there's a batchEvents option we could set later on to avoid issues with this
      this.amplitudeInstance.logEvent(action.type, payloadWithAppName);
    }
  }

  private logPendingEvents() {
    while (this.pendingEvents.length) {
      const [{ action }] = this.pendingEvents.splice(0, 1);
      void this.logEvent(action);
    }
  }
}
