import { CareMemberTooltip } from 'app/care-team/care-member-tooltip/CareMemberTooltip';
import styles from 'app/care-team/past-care-team-members/PastCareMembers.module.scss';
import { Coach } from 'app/care-team/types';
import { useAppState } from 'app/state';
import { selectActiveMemberProfile } from 'app/state/care-team/actions';
import { getTimezone } from 'utils/dateTime';
import React from 'react';
import { useDispatch } from 'react-redux';

export const CoachRow = (item: Coach) => {
  const dispatch = useDispatch();
  const timezone = useAppState((state) => getTimezone(state.user.timezone));

  const onSetCareTeamProfile = () => {
    dispatch(selectActiveMemberProfile({ careTeamMember: item }));
  };

  return (
    <CareMemberTooltip
      timezone={timezone}
      key={`past_coach_member_tooltip_${item.id}`}
      id={item.id}
      name={item.name}
      lastSession={item.coachLastEngagement?.toString()!}
      totalMessagesExchanged={
        item.coachMessagesExchanged + item.memberMessagesExchanged
      }
    >
      <button
        onClick={onSetCareTeamProfile}
        key={`care_member_${item.id}`}
        data-testid={`care_member_${item.id}`}
        className={styles.item}
      >
        <div className={styles.name}>{item.name}</div>
        <div className={styles.description}>
          {item.coachMessagesExchanged + item.memberMessagesExchanged} messages
          exchanged
        </div>
      </button>
    </CareMemberTooltip>
  );
};
