/* eslint-disable @typescript-eslint/naming-convention */
export enum TimeZoneOptions {
  'America/Chicago' = 'America/Chicago',
  'America/Costa_Rica' = 'America/Costa_Rica',
  'America/Denver' = 'America/Denver',
  'America/Los_Angeles' = 'America/Los_Angeles',
  'Asia/Jerusalem' = 'Asia/Jerusalem',
  'Europe/London' = 'Europe/London',
  'US/Alaska' = 'US/Alaska',
  'US/Arizona' = 'US/Arizona',
  'US/Central' = 'US/Central',
  'US/Eastern' = 'US/Eastern',
  'US/Hawaii' = 'US/Hawaii',
  'US/Mountain' = 'US/Mountain',
  'US/Pacific' = 'US/Pacific',
}
