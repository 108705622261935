import styles from 'app/care-team/active-care-members/ActiveCareMembers.module.scss';
import ClinicianMember from 'app/care-team/member-types/ClinicianMember';
import CoachMember from 'app/care-team/member-types/CoachMember';
import { Coach, MS, Psychiatrist, Therapist } from 'app/care-team/types';
import React from 'react';

export type ActiveCareMembersProps = {
  coachTeam: (Coach | MS)[];
  therapists: Therapist[];
  psychiatrists: Psychiatrist[];
  columns?: 1 | 2 | undefined;
};

const ActiveCareMembers = (props: ActiveCareMembersProps) => {
  const { coachTeam, therapists, psychiatrists, columns = 2 } = props;

  return (
    <div className={styles.itemList}>
      {coachTeam.map((item, index) => (
        <CoachMember
          key={item.id}
          item={item}
          index={index}
          columns={columns}
        />
      ))}
      {therapists.map((item, index) => (
        <ClinicianMember
          key={item.id}
          item={item}
          index={index}
          columns={columns}
          userRole="therapist"
        />
      ))}
      {psychiatrists.map((item, index) => (
        <ClinicianMember
          key={item.id}
          item={item}
          index={index}
          columns={columns}
          userRole="psychiatrist"
        />
      ))}
    </div>
  );
};

export default ActiveCareMembers;
