import React, { Fragment } from 'react';

import styles from './Box.module.scss';

type Props = {
  children: any;
  centered?: boolean;
  title?: string | JSX.Element;
  testId?: string;
};

export function Box(props: Props) {
  const classes = [styles.box];
  if (props.centered === true) {
    classes.push(styles.centered);
  }

  const { title, testId } = props;
  const titleComponent = typeof title === 'string' ? <h2>{title}</h2> : title;

  let content = props.children;
  if (props.title) {
    classes.push(styles.noPadding);
    content = (
      <Fragment>
        <div className={styles.content}>{titleComponent}</div>
        <hr />
        <div className={styles.content}>{props.children}</div>
      </Fragment>
    );
  }

  return (
    <div data-testid={testId} className={classes.join(' ')}>
      {content}
    </div>
  );
}
