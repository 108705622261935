import { MessageToCareTeam } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/MessageToCareTeam';
import { SafetyPlan } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/safety/SafetyPlan';
import { TreatmentGoal } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/TreatmentGoal';
import { TreatmentGoals } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/TreatmentGoals';
import { TerminationReasons } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/TerminationReasons';

export enum SubsectionType {
  SAFETY_PLAN = 'safety-plan',
  TREATMENT_PLAN_GOALS = 'treatment-plan-goals',
  MESSAGE_TO_CARE_TEAM = 'message-to-care-team',
  TERMINATION_REASONS = 'termination-reasons',
}

export type Subsection =
  | SafetyPlan
  | TreatmentGoals
  | TreatmentGoal[]
  | MessageToCareTeam
  | TerminationReasons;
