import { buildUpdateReadStateRPC } from 'app/coach/chat/utils';
import { getPubErrorLogData } from 'app/coach/pubnub/errors';
import { usePubNubAPI } from 'app/coach/pubnub/PubNubContextProvider';
import { useAppState } from 'app/state';
import { onReadingMessagesByCareProvider } from 'app/state/chat/actions';
import { selectLoggingContextWithMemberData } from 'app/state/chat/selectors';
import { TimetokensService } from 'app/state/features/conversationTimetokens/TimetokensService';
import { useAppDispatch } from 'app/state/hooks/baseTypedHooks';
import {
  selectConversationIdByMemberId,
  selectUnreadMessages,
} from 'app/state/inbox/selectors';
import { useLogger } from 'app/state/log/useLogger';

export function useUpdateUnreadMessageCallback({
  memberId,
  callerId,
  channelId,
}: {
  callerId: string;
  channelId: string;
  memberId: string;
}) {
  const dispatch = useAppDispatch();
  const logger = useLogger();
  const api = usePubNubAPI();

  const { publishMessage } = api;

  const { listenerId, coachinghubUsername } = useAppState(({ user }) => ({
    coachinghubUsername: user.coachinghubUsername,
    listenerId: user.listenerId,
  }));

  const currentConversationId = selectConversationIdByMemberId(memberId);
  const unreadMessages = selectUnreadMessages(channelId);
  const logsContext = selectLoggingContextWithMemberData(callerId, memberId);

  return async () => {
    if (!coachinghubUsername || !listenerId) {
      logger.error(
        new Error(`Could not send update read state RPC due to invalid params`),
        {
          channelId,
          coachinghubUsername,
          listenerId,
        },
      );
      return;
    }

    const message_ids = unreadMessages.map((m) => m.id);
    const updateReadStateRPC = buildUpdateReadStateRPC({
      message_ids,
      senderId: listenerId,
      username: coachinghubUsername,
    });
    /* 
    This is the behavior copied from Listener UI: an "update_read_state" RPC is sent to the channel.
    The timetoken of the RPC "update_read_state" is then used to call Listener to update the Listener read
    timetoken/timestamp.
    */
    try {
      const publishResponse = await publishMessage({
        channel: channelId,
        message: updateReadStateRPC,
      });
      const stringifiedPublishTimetoken = publishResponse.timetoken.toString();

      const updateTimetokenRes = await dispatch(
        TimetokensService.updateConversationTimetokens({
          conversationId: currentConversationId,
          lastListenerReadTimetoken: stringifiedPublishTimetoken,
        }),
      ).unwrap();
      // To-Do: amplitude slice should listen to updateConversationTimetokens.fulfill, check if lastListenerRead was
      // updated and log if it was; this doesn't belong to a component
      if (updateTimetokenRes) {
        dispatch(
          onReadingMessagesByCareProvider({
            channelId,
            logsPayload: {
              ...logsContext,
              message_ids,
              timetoken: stringifiedPublishTimetoken,
            },
          }),
        );
      } else {
        const err = new Error(`Could not publish update_read_state rpc.`);
        logger.error(err, {
          channelId,
          response: updateTimetokenRes,
        });
      }
    } catch (e) {
      logger.error(
        new Error(`Could not publish update_read_state rpc.`, { cause: e }),
        {
          callerId,
          channelId,
          error: e,
          listenerId,
          pubnubError: getPubErrorLogData(e),
        },
      );
    }
  };
}
