import CreateIcon from '@mui/icons-material/Create';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, { SetStateAction, useState } from 'react';
import { ClipLoader } from 'react-spinners';

import classes from './components.module.scss';

type TitleContainerProps = {
  title: string;
  testId: string;
  onClick?: () => void;
  label?: string;
  children?: React.ReactNode;
};
function TitleContainer(props: TitleContainerProps) {
  const { title, testId, onClick, label, children } = props;
  return (
    <Tooltip title={title}>
      <IconButton
        data-testid={testId}
        className={classes.iconButton}
        onClick={onClick}
        type="button"
        aria-label={label}
        size="large"
      >
        {children}
      </IconButton>
    </Tooltip>
  );
}

export type CardTitleProps = {
  title: string;
  validate?: () => Promise<boolean>;
  onSave: () => Promise<void>;
  onEdit: React.Dispatch<SetStateAction<boolean>>;
  onCancel: () => void;
};

enum TitleState {
  EDIT,
  SAVING,
  EDITING,
}

export function CardTitle(props: CardTitleProps) {
  const [icon, setIcon] = useState<TitleState>(TitleState.EDIT);
  const handleEdit = () => {
    setIcon(TitleState.EDITING);
    props.onEdit(true);
  };
  const handleCancel = () => {
    props.onCancel();
    setIcon(TitleState.EDIT);
    props.onEdit(false);
  };
  const handleSave = async () => {
    if (props.validate && !(await props.validate())) return;
    setIcon(TitleState.SAVING);
    await props.onSave();
    props.onEdit(false);
    setIcon(TitleState.EDIT);
  };
  let content;
  switch (icon) {
    case TitleState.EDIT:
      content = (
        <TitleContainer
          title="Edit"
          testId="editBtn"
          onClick={handleEdit}
          label="edit"
        >
          <CreateIcon />
        </TitleContainer>
      );
      break;
    case TitleState.EDITING:
      content = (
        <>
          <TitleContainer
            title="Save"
            testId="saveBtn"
            onClick={handleSave}
            label="save"
          >
            <SaveIcon />
          </TitleContainer>
          <TitleContainer
            title="Cancel"
            testId="cancelBtn"
            onClick={handleCancel}
            label="cancel"
          >
            <HighlightOffIcon />
          </TitleContainer>
        </>
      );
      break;
    case TitleState.SAVING:
      content = (
        <TitleContainer title="Processing" testId="loadingIcon">
          <ClipLoader color="#4379b6" size={12} />
        </TitleContainer>
      );
      break;
    default:
      content = null;
  }
  return (
    <div className={classes.titleRoot}>
      <h2 className={classes.title}>{props.title}</h2>
      {content}
    </div>
  );
}
