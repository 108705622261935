import { AppointmentsTable } from 'app/appointments/AppointmentsTable';
import { Appointment, NoteAction } from 'app/appointments/types';
import { GQL_QUERY_POLLING_INTERVAL } from 'app/constants';
import { useAppState } from 'app/state';
import { viewPatientAppointmentTabAction } from 'app/state/amplitude/actions/appointments';
import { formatEnumValue, formatZoomMeetingUrl } from 'utils';
import { formatDate, getTimezone } from 'utils/dateTime';
import { formatClinicalNoteStatus } from 'utils/notes';
import gql from 'graphql-tag';
import { useQuery } from 'hooks/useQuery';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  GetMemberAppointments,
  GetMemberAppointments_getMemberAppointments_all,
  GetMemberAppointmentsVariables,
} from './generated/GetMemberAppointments';

export const query = gql`
  query GetMemberAppointments($patientId: ID!) {
    getMemberAppointments(id: $patientId) {
      all {
        id
        clinician {
          id
          userId
          name
          role
        }
        member {
          id
          firstName
          lastName
          preferences {
            timezone
          }
        }
        start
        end
        appointmentType
        cancelledReason
        status
        zoomID
        clinicalNote {
          id
          url
          locked
          status
          noteType
          location
          updatedAt
        }
      }
    }
  }
`;
export enum UILocation {
  MS,
  CLINICIAN,
}

export type Props = {
  patientId: string;
  navigateTo: (url: string) => void;
  signedLockedAction: NoteAction.VIEW | NoteAction.DOWNLOAD;
  uiLocation: UILocation;
};

export function AppointmentsTab(props: Props) {
  const { patientId, navigateTo, signedLockedAction, uiLocation } = props;
  const { timezone } = useAppState((_) => _.user);
  const localTimezone = getTimezone(timezone);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewPatientAppointmentTabAction());
  }, [dispatch]);

  return useQuery<GetMemberAppointments, GetMemberAppointmentsVariables>(
    ({ getMemberAppointments: appointments }) => {
      const tableAppointments = appointments.all.map((r) =>
        toTableAppointment(r, localTimezone),
      );
      let timezone = localTimezone;
      if (uiLocation == UILocation.MS && appointments.all.length > 0) {
        timezone =
          appointments.all[0].member.preferences.timezone ?? localTimezone;
      }
      return (
        <AppointmentsTable
          localTimezone={timezone}
          appointments={tableAppointments}
          navigateTo={navigateTo}
          hideMemberName={true}
          signedLockedAction={signedLockedAction}
        />
      );
    },
    query,
    {
      pollInterval: GQL_QUERY_POLLING_INTERVAL,
      variables: { patientId },
    },
  );
}

export function toTableAppointment(
  appointment: GetMemberAppointments_getMemberAppointments_all,
  localTimezone: string,
): Appointment {
  const { status: noteStatus, updatedAt } = appointment.clinicalNote;
  const { name, userId: clinicianId } = appointment.clinician;
  const type = formatEnumValue(appointment.appointmentType);
  const { member, zoomID } = appointment;

  return {
    appointmentStatus: appointment.status,
    appointmentType: appointment.appointmentType,
    cancelledReason: appointment.cancelledReason,
    clinicalNote: appointment.clinicalNote,
    clinicianId,
    end: appointment.end,
    id: appointment.id,
    member: {
      id: member.id,
      name: `${member.firstName} ${member.lastName}`,
    },
    name,
    noteStatus: formatClinicalNoteStatus(appointment.status, noteStatus),
    noteUpdatedAt: updatedAt ? formatDate(updatedAt, localTimezone) : '-',
    start: appointment.start,
    type,
    zoomUrl: formatZoomMeetingUrl(zoomID),
  };
}
