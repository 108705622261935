import {
  arrayField,
  booleanField,
  field,
  FieldDefinitions,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  MedicalHistory,
  MedicalHistory_Allergy,
  MedicalHistory_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MedicalHistory';
import {
  MedicalHistorySection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { Width } from 'types/StyleTypes';
import { CheckboxField, TextAreaField } from 'app/notes-ui/forms/fields';
import { ButtonAdd } from 'app/notes-ui/forms/form-controls/ButtonAdd';
import { Checkbox } from 'app/notes-ui/forms/form-controls/Checkbox';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { AllergiesFieldList } from 'app/notes-ui/psychiatry/shared/medical-history/AllergiesFieldList';
import medicalStyles from 'app/notes-ui/psychiatry/shared/medical-history/MedicalHistoryForm.module.scss';
import { NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD } from 'app/notes-ui/utils';
import { classNameCombiner } from 'utils';
import React, { useEffect } from 'react';

import { Labels } from './constants';

type Props = {
  appointmentId: string;
  onSubmit: (data: MedicalHistorySection) => void;
  updateDraftNoteState: (data: MedicalHistorySection) => void;
  disabled?: boolean;
  savingNote?: boolean;
  initialValue?: MedicalHistory;
};

export const fieldDefs: FieldDefinitions<MedicalHistory> = {
  allergies: arrayField<MedicalHistory_Allergy>({ default: [], rules: [] }),
  appointmentId: field(),
  comments: stringField(),
  heartDisease: {
    description: stringField({ rules: [] }),
    isPresent: booleanField(),
  },
  nonPsychiatricCurrentMedicines: stringField(),
  obstructiveSleepApnea: {
    description: stringField({ rules: [] }),
    isPresent: booleanField(),
  },
  seizures: {
    description: stringField({ rules: [] }),
    isPresent: booleanField(),
  },
  thyroidDisease: {
    description: stringField({ rules: [] }),
    isPresent: booleanField(),
  },
  traumaticBrainInjury: {
    description: stringField({ rules: [] }),
    isPresent: booleanField(),
  },
  version: field<MedicalHistory_Version>({
    default: MedicalHistory_Version.v0,
  }),
};

export function MedicalHistoryFormV2(props: Props) {
  const { appointmentId, disabled, initialValue } = props;
  const [notEndorsing, setNotEndorsing] = React.useState(false);
  const [hasAllergies, setHasAllergies] = React.useState(true);

  const { fields, getValue } = useForm<MedicalHistory>(
    fieldDefs,
    {
      ...initialValue,
      appointmentId,
      version: MedicalHistory_Version.v0,
    } as MedicalHistory,
    {
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
      onStateChange: async () => {
        await onSubmit();
      },
    },
  );

  const notEndorsingHandler = () => {
    setNotEndorsing(!notEndorsing);
    if (!notEndorsing) {
      fields.seizures.isPresent.setValue(false);
      fields.seizures.description.setValue('');
      fields.heartDisease.isPresent.setValue(false);
      fields.heartDisease.description.setValue('');
      fields.obstructiveSleepApnea.isPresent.setValue(false);
      fields.obstructiveSleepApnea.description.setValue('');
      fields.thyroidDisease.isPresent.setValue(false);
      fields.thyroidDisease.description.setValue('');
      fields.traumaticBrainInjury.isPresent.setValue(false);
      fields.traumaticBrainInjury.description.setValue('');
    }
  };

  useEffect(() => {
    if (
      fields.seizures.isPresent.value ||
      fields.heartDisease.isPresent.value ||
      fields.obstructiveSleepApnea.isPresent.value ||
      fields.thyroidDisease.isPresent.value ||
      fields.traumaticBrainInjury.isPresent.value
    ) {
      setNotEndorsing(false);
    }
  }, [
    fields.seizures.isPresent,
    fields.heartDisease.isPresent,
    fields.obstructiveSleepApnea.isPresent,
    fields.thyroidDisease.isPresent,
    fields.traumaticBrainInjury.isPresent,
  ]);

  useEffect(() => {
    if (
      !fields.seizures.isPresent.value &&
      !fields.heartDisease.isPresent.value &&
      !fields.obstructiveSleepApnea.isPresent.value &&
      !fields.thyroidDisease.isPresent.value &&
      !fields.traumaticBrainInjury.isPresent.value &&
      initialValue
    ) {
      setNotEndorsing(true);
    }

    if (
      initialValue &&
      ((initialValue.allergies.length === 1 &&
        initialValue.allergies[0].medication === '') ||
        initialValue.allergies.length === 0)
    ) {
      setHasAllergies(false);
    }
  }, []);

  const onSubmit = async () => {
    if (disabled) return;

    const data = {
      ...getValue(),
      allergies: hasAllergies ? getValue().allergies : [],
    };

    props.onSubmit({
      data,
      name: PsychiatrySectionName.MEDICAL_HISTORY,
    });
  };

  return (
    <div>
      <div className={styles.formGroupV2}>
        <TextAreaField
          label={Labels.medicalHistory.comments.label}
          subtext={Labels.medicalHistory.comments.subtext}
          disabled={disabled}
          testId={Labels.medicalHistory.comments.id}
          placeholder={Labels.medicalHistory.comments.placeholder}
          field={fields.comments}
          width={Width.FULL}
          rows={1}
          autoExpand={true}
        />
        <TextAreaField
          label={Labels.medicalHistory.nonPsychiatricCurrentMedicines.label}
          subtext={Labels.medicalHistory.nonPsychiatricCurrentMedicines.subtext}
          disabled={disabled}
          testId={Labels.medicalHistory.nonPsychiatricCurrentMedicines.id}
          placeholder={
            Labels.medicalHistory.nonPsychiatricCurrentMedicines.placeholder
          }
          field={fields.nonPsychiatricCurrentMedicines}
          width={Width.FULL}
          rows={1}
          autoExpand={true}
        />
        <NoteFormControlLabel label={Labels.medicalHistory.checkBoxesTitle}>
          <>
            <Checkbox
              label={Labels.medicalHistory.notEndorsing.label}
              checked={notEndorsing}
              disabled={disabled}
              testId={Labels.medicalHistory.notEndorsing.id}
              onChange={() => {
                notEndorsingHandler();
              }}
            />
            <CheckboxField
              disabled={disabled}
              testId={Labels.medicalHistory.seizures.id}
              label={Labels.medicalHistory.seizures.label}
              field={fields.seizures.isPresent}
            />
            <CheckboxField
              disabled={disabled}
              testId={Labels.medicalHistory.traumaticBrainInjury.id}
              label={Labels.medicalHistory.traumaticBrainInjury.label}
              field={fields.traumaticBrainInjury.isPresent}
            />
            <CheckboxField
              disabled={disabled}
              testId={Labels.medicalHistory.obstructiveSleepApnea.id}
              label={Labels.medicalHistory.obstructiveSleepApnea.label}
              field={fields.obstructiveSleepApnea.isPresent}
            />
            <CheckboxField
              disabled={disabled}
              testId={Labels.medicalHistory.thyroidDisease.id}
              label={Labels.medicalHistory.thyroidDisease.label}
              field={fields.thyroidDisease.isPresent}
            />
            <CheckboxField
              disabled={disabled}
              testId={Labels.medicalHistory.heartDisease.id}
              label={Labels.medicalHistory.heartDisease.label}
              field={fields.heartDisease.isPresent}
            />
            <TextAreaField
              label=""
              disabled={disabled}
              testId={Labels.medicalHistory.heartDisease.id}
              placeholder={Labels.medicalHistory.heartDisease.placeholder}
              field={fields.heartDisease.description}
              width={Width.FULL}
              rows={1}
              autoExpand={true}
            />
          </>
        </NoteFormControlLabel>
        <NoteFormControlLabel label={Labels.medicalHistory.allergies.title}>
          <Checkbox
            disabled={disabled}
            label={Labels.medicalHistory.allergies.label}
            checked={!hasAllergies}
            testId={Labels.medicalHistory.allergies.id}
            onChange={() => setHasAllergies(!hasAllergies)}
          />
          <div className={medicalStyles.allergiesTable}>
            <div
              className={classNameCombiner([
                medicalStyles.column_4,
                medicalStyles.label,
              ])}
            >
              Medication
            </div>
            <div
              className={classNameCombiner([
                medicalStyles.column_8,
                medicalStyles.info,
              ])}
            >
              <b>Comments</b> (Optional)
            </div>
          </div>
          <AllergiesFieldList
            minItems={1}
            field={fields.allergies}
            disabled={disabled || !hasAllergies}
          />
        </NoteFormControlLabel>
        <ButtonAdd
          disabled={disabled}
          label="Allergy"
          color="blue"
          data-testid="add-allergy"
          onClick={() => {
            fields.allergies.setValue([
              ...fields.allergies.value,
              MedicalHistory_Allergy.fromPartial({}),
            ]);
          }}
        />
      </div>
    </div>
  );
}

export const validate = (data: MedicalHistory | null) =>
  data !== null && validateForm(data, fieldDefs);
