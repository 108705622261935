import { useAppState } from 'app/state';
import { viewTerminationNote } from 'app/state/amplitude/actions/notes';
import { renderSliceStateResult } from 'app/state/status/RenderSliceState';
import { useTerminationNote } from 'app/vault/hooks/NonAppointments/useTerminationNote';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useOnMount } from 'hooks/useOnMount';
import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { TerminationNotesContainer } from './TerminationNotesContainer';

export function TerminationNoteScreen(): ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();
  const { patientId, vaultItemId } = useParams<{
    patientId: string;
    vaultItemId?: string;
  }>();
  const { userId: clinicianId, lastName, firstName, role } = useAppState(
    (_) => _.user,
  );
  const {
    note,
    createOrUpdateNote,
    patientProfile,
    creator,
    noteStatus,
  } = useTerminationNote(patientId, clinicianId!, role!, vaultItemId);
  const {
    transientFeatureFlags: {
      enable_supervisor_sign_and_lock_notes_for_user: allowGroupWriteAccess,
    },
  } = useFeatureFlags();

  useOnMount(() => {
    dispatch(viewTerminationNote({ patientId }));
  });

  return renderSliceStateResult(note, (data) => {
    const clinicianName = `${creator?.firstName ?? firstName} ${
      creator?.lastName ?? lastName
    }`;
    const memberName = patientProfile
      ? `${patientProfile.firstName} ${patientProfile.lastName}`
      : '';
    return (
      <TerminationNotesContainer
        clinicianName={clinicianName}
        clinicianRole={role!}
        memberName={memberName}
        initialValue={data ?? undefined}
        onSubmit={createOrUpdateNote}
        clinicianId={clinicianId!}
        patientId={patientId}
        vaultItemId={vaultItemId}
        noteStatus={noteStatus}
        allowedGroupWriteAccess={allowGroupWriteAccess}
        navigateTo={history.replace}
      />
    );
  });
}
