import { AutoMessage } from 'app/coach/chat/messages/AutoMessage';
import { ThumbnailMessage } from 'app/coach/chat/messages/ThumbnailMessage';
import { MessageToDisplay, MessageType } from 'app/coach/pubnub/types';
import React, { memo } from 'react';

import { MemberProfileBaseProps } from '../ChatContainer';
import { rpcAutoMessageTitle, visibleAutoMessageTitle } from '../strings';
import { CareProviderMessageContainer } from './CareProviderMessageContainer';
import { MemberMessageContainer } from './MemberMessageContainer';

export type MessageContainerProps = {
  messageProps: MessageToDisplay;
  memberProfileBaseProps: MemberProfileBaseProps;
  dateAndTime: string;
  shouldDisplaySeenStatus?: boolean;
};

export const MessageContainer = memo(
  ({
    messageProps,
    memberProfileBaseProps,
    dateAndTime,
    shouldDisplaySeenStatus,
  }: MessageContainerProps) => {
    // todo: once https://headspace.atlassian.net/browse/COACHENG-2023 is complete, we can utilize
    // "containsRisk" and the "OUT_OF_SESSION" enum to differentiate out-of-session chat notes.
    // These values will be sourced from the getTasks query response, and will exist in redux to link the
    // message with its task.
    const { type, status, message, containsRisk } = messageProps;

    switch (type) {
      case MessageType.TEXT_FROM_LISTENER: {
        return (
          <CareProviderMessageContainer
            messageProps={{ dateAndTime, message, status }}
            memberProfileBaseProps={memberProfileBaseProps}
            shouldDisplaySeenStatus={shouldDisplaySeenStatus}
          />
        );
      }
      case MessageType.TEXT_FROM_MEMBER: {
        return (
          <MemberMessageContainer
            messageProps={{ dateAndTime, message }}
            memberProfileBaseProps={memberProfileBaseProps}
          />
        );
      }
      case MessageType.AUTO_MESSAGE:
      case MessageType.RPC: {
        const title =
          type === MessageType.AUTO_MESSAGE
            ? visibleAutoMessageTitle
            : rpcAutoMessageTitle;
        return (
          <AutoMessage
            message={message}
            dateAndTime={dateAndTime}
            title={title}
          />
        );
      }
      case MessageType.CONTENT_MESSAGE: {
        if (!messageProps.contentData) {
          return (
            <AutoMessage
              message="Content error."
              dateAndTime={dateAndTime}
              title={rpcAutoMessageTitle}
            />
          );
        }
        const { thumbnail, title, contentType } = messageProps.contentData;
        return (
          <ThumbnailMessage
            thumbnail={thumbnail}
            title={title}
            category={contentType}
            dateAndTime={dateAndTime}
          />
        );
      }
      case MessageType.OUT_OF_SESSION: {
        return (
          <MemberMessageContainer
            messageProps={{ dateAndTime, message, type }}
            memberProfileBaseProps={memberProfileBaseProps}
            containsRisk={containsRisk}
            isOutOfSession={true}
          />
        );
      }
      default:
        return null;
    }
  },
);
