import { KeyGenerator } from '@ginger.io/vault-core/dist/crypto';
import { Base64 } from '@ginger.io/vault-core/dist/crypto/Base64';
import {
  VaultItem,
  VaultItem_SchemaType as SchemaType,
} from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import {
  getClinicalCareTeamGroupId,
  getCoachingTeamGroupId,
} from '@ginger.io/vault-core/dist/IdHelpers';
import {
  createVaultItemInput,
  CreateVaultItemInputParams,
  updateVaultItemInput,
  UpdateVaultItemInputParams,
  VaultItemPermissions,
} from '@ginger.io/vault-ui';
import { CustomError } from 'app/appointments/errorUtils';
import {
  CareProviderNoteType,
  CoachNotesItem,
  MutationResponse,
  NavigationParams,
  NoteItem,
  NoteOrSubsection,
  NotesItemResponse,
  UseCoachNotesResult,
} from 'app/coach/coach-notes/CoachNotesTypes';
import { encoderMap, noteTypeSchemaMap } from 'app/coach/coach-notes/constants';
import {
  removeItemsFromCache,
  updateCache,
  updateShareVaultItemCache,
} from 'app/coach/coach-notes/queries';
import {
  WritableNoteSchema,
  WritableNoteType,
} from 'app/coach/coach-notes/types';
import { useGetCoachNotes } from 'app/coach/coach-notes/useGetCoachNotes';
import {
  getCareTeamInfo,
  getSchemaTypeStr,
  getTags,
  hasValidParams,
  isShareableWithClinicians,
  noteContainsRisks,
  parseQueryString,
} from 'app/coach/coach-notes/utils';
import {
  CreateMemberChartVaultItems,
  CreateMemberChartVaultItemsVariables,
} from 'app/coach/member-chart/generated/CreateMemberChartVaultItems';
import {
  DeleteMemberChartVaultItems,
  DeleteMemberChartVaultItemsVariables,
} from 'app/coach/member-chart/generated/DeleteMemberChartVaultItems';
import {
  ShareMemberChartVaultItems,
  ShareMemberChartVaultItemsVariables,
} from 'app/coach/member-chart/generated/ShareMemberChartVaultItems';
import {
  UpdateMemberChartVaultItems,
  UpdateMemberChartVaultItemsVariables,
} from 'app/coach/member-chart/generated/UpdateMemberChartVaultItems';
import {
  createVaultItems,
  deleteVaultItems,
  getGroupId,
  shareVaultItems,
  updateVaultItems,
} from 'app/coach/member-chart/queries';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import useTaskMutations from 'app/member-chart-cards/tasks/useTaskMutations';
import { GetMemberCoachingTeam_getMember_coachingCareTeam as CoachingCareTeam } from 'app/queries/generated/GetMemberCoachingTeam';
import { useAppState } from 'app/state';
import {
  deletedCoachNote,
  viewedCoachNote,
  wroteCoachNote,
} from 'app/state/amplitude/actions/notes';
import { setSelectedNote } from 'app/state/coach-notes/coachNotesSlice';
import { useLogger } from 'app/state/log/useLogger';
import { error as showErrorNotification } from 'app/state/request/actions';
import { Status } from 'app/state/status/types/StateSlice';
import { CareProviderNotesLabel } from 'utils/notes';
import { useNotesUserMetadata } from 'app/vault/hooks/useNotesUserMetadata';
import {
  CreateVaultItemInput,
  ShareVaultItemsInput,
  UpdateVaultItemInput,
} from 'generated/globalTypes';
import { useMutationWithGlobalState as useMutation } from 'hooks/useMutationWithGlobalState';
import { isEqual } from 'lodash';
import qs from 'query-string';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { vaultItemKeyInput } from 'test/vault/api/utils';
import { v4 as uuidv4 } from 'uuid';

import { getVariables } from './notesQueryVariables';

export function inputToVaultItem(
  memberId: string,
  schemaType: SchemaType,
  data: NoteItem,
): VaultItem {
  if (Object.prototype.hasOwnProperty.call(encoderMap, schemaType)) {
    return {
      data: encoderMap[schemaType as WritableNoteSchema]({
        ...data,
        memberId,
      }).finish(),
      schemaType,
    };
  }
  throw new Error("You're not permitted to perform this operation");
}

export function useCoachNotes({
  memberId,
  coachingCareTeam,
  generateId = uuidv4,
  keyGenerator = new KeyGenerator(),
}: {
  memberId: string;
  coachingCareTeam?: CoachingCareTeam | null;
  keyGenerator?: KeyGenerator;
  generateId?: () => string;
}): UseCoachNotesResult {
  const dispatch = useDispatch();
  const logger = useLogger();
  const history = useHistory();
  const { dismissNoteTasks, closeRiskAssessment } = useTaskMutations({
    memberId,
  });
  const { saveNotesUserMetadata } = useNotesUserMetadata({
    isCoachNotesUser: true,
    memberId,
  });
  const {
    transientFeatureFlags: { enable_tasks_v2: enableTasksV2 },
  } = useFeatureFlags();

  const { selectedNote, role, userId, listenerId } = useAppState(
    ({ coachNotes, user }) => ({
      listenerId: user.listenerId!,
      role: user.role!,
      selectedNote: coachNotes.selectedNote,
      timezone: user.timezone ?? 'UTC',
      userId: user.userId!,
      vaultUserId: user.vaultUserId!,
    }),
  );

  const coachNotes = useGetCoachNotes(memberId);

  const handleCoachNotesNavigation = useCallback(
    (params?: NavigationParams) => {
      const { index, noteType, noteId } = params || {};
      const notesData = coachNotes.data?.notes;

      let targetNote;
      let targetNoteId;

      if (notesData && index != null) {
        targetNoteId = Object.keys(notesData)[index];
        targetNote = notesData[targetNoteId];
        if (targetNote) dispatch(setSelectedNote({ selectedNote: targetNote }));
      } else if (noteId && notesData) {
        targetNoteId = noteId;
        targetNote = notesData[noteId];
        if (targetNote) dispatch(setSelectedNote({ selectedNote: targetNote }));
      } else if (noteType && !noteId) {
        targetNote = {
          noteType: noteType as CareProviderNoteType,
          schemaType: noteTypeSchemaMap[noteType as WritableNoteType],
        };
        dispatch(setSelectedNote({ selectedNote: targetNote }));
      } else if (!noteId && !noteType) {
        dispatch(setSelectedNote({ selectedNote: undefined }));
      }

      history.push({
        search: qs.stringify({
          noteId: targetNoteId,
          noteType: targetNote?.noteType || noteType,
        }),
      });
    },

    [coachNotes, history, dispatch],
  );

  const { noteType, noteId } = parseQueryString(history.location.search);
  useEffect(() => {
    // This hook is called to preserve the draft state on page refresh by syncing the url params with Redux.
    // If the noteId provided in the url matches an existing note, it will set it as the selectedNote.
    if (!noteType || coachNotes.status !== Status.COMPLETE) return;

    handleCoachNotesNavigation({ noteId, noteType });
  }, [noteId, noteType, handleCoachNotesNavigation, coachNotes.status]);

  const [createVaultItemsFn] = useMutation<
    CreateMemberChartVaultItems,
    CreateMemberChartVaultItemsVariables
  >(createVaultItems, {
    update(cache, { data }) {
      if (!data) return;
      void getVariables(memberId, userId, role)
        .then((variables) => updateCache({ cache, data, variables }))
        .catch();
    },
  });

  const [updateVaultItemsFn] = useMutation<
    UpdateMemberChartVaultItems,
    UpdateMemberChartVaultItemsVariables
  >(updateVaultItems);

  const [shareVaultItemsFn] = useMutation<
    ShareMemberChartVaultItems,
    ShareMemberChartVaultItemsVariables
  >(shareVaultItems, {
    update(cache, { data }) {
      if (!data) return;
      void getVariables(memberId, userId, role)
        .then((variables) =>
          updateShareVaultItemCache({
            cache,
            data,
            noteId: selectedNote?.id,
            variables,
          }),
        )
        .catch();
    },
  });

  const [deleteVaultItemsFn] = useMutation<
    DeleteMemberChartVaultItems,
    DeleteMemberChartVaultItemsVariables
  >(deleteVaultItems, {
    update(cache, { data }) {
      if (!data) return;
      void getVariables(memberId, userId, role)
        .then((variables) => removeItemsFromCache({ cache, data, variables }))
        .catch();
    },
  });

  const handleRiskAndDismissTasks = () => {
    if (enableTasksV2) {
      if (noteContainsRisks(selectedNote?.data)) {
        const { coachType, leadCoachId } =
          getCareTeamInfo(listenerId, coachingCareTeam) || {};
        if (coachType && leadCoachId) {
          closeRiskAssessment(coachType, leadCoachId);
        }
      }
      dismissNoteTasks();
    }
  };

  const _createCoachNote = async (
    params: CoachNotesItem,
  ): Promise<MutationResponse> => {
    const { schemaType, data, noteType } = params;

    if (!hasValidParams(params)) {
      return {
        error: new CustomError(
          'useCoachNote.createCoachNote: Missing required params',
          'MISSING_PARAMS',
          {
            data: Boolean(data),
            noteType: Boolean(noteType),
            schemaType: Boolean(schemaType),
          },
        ),
        id: null,
        success: false,
      };
    }

    try {
      const vaultItem = inputToVaultItem(
        memberId,
        schemaType!,
        data as NoteItem,
      );

      // Configure sharing and permissions
      const groupsToShareWith = [getCoachingTeamGroupId(memberId)];
      if (isShareableWithClinicians(noteType!)) {
        groupsToShareWith.push(getClinicalCareTeamGroupId(memberId));
      }

      const riskExists = noteContainsRisks(data);
      const inputParams: CreateVaultItemInputParams = {
        groupsToShareWith,
        itemId: `member-chart-${memberId}-coach-notes-${noteType}-${generateId()}`,
        permissions: riskExists
          ? VaultItemPermissions.WritableByAll
          : VaultItemPermissions.Writable,
        tags: getTags(memberId, noteType!),
        vaultItem,
      };

      const item = await createVaultItemInput(
        inputParams,
        keyGenerator,
        () => '',
        { hashItemIds: true },
      );

      const {
        errors: createVaultItemErrors,
        data: response,
      } = await createVaultItemsFn({
        input: [(item as unknown) as CreateVaultItemInput],
      });

      if (createVaultItemErrors || !response) {
        const errorMessage =
          createVaultItemErrors
            ?.map((_: { message: any }) => _.message)
            .join('\n') ??
          `Unable to create vault item: ${noteType} for member ${memberId}`;

        return {
          error: errorMessage,
          id: null,
          success: false,
        };
      }

      const { id } = response.createVaultItems[0].encryptedItem;

      return {
        id,
        success: true,
      };
    } catch (error) {
      return { error, id: null, success: false };
    }
  };

  const createCoachNote = async (
    note: CoachNotesItem,
  ): Promise<MutationResponse> => {
    const { schemaType, data, noteType } = note;
    const { id, success, error: createError } = await _createCoachNote({
      data,
      noteType,
      schemaType,
    });

    const analyticsEventPayload = {
      label: CareProviderNotesLabel.NOTE_CREATED,
      memberId,
      noteId: id ?? undefined, // id is null if there is an error in creating the note
      noteType: noteType!,
    };

    if (!success) {
      const error = new Error(
        'useCoachNotes.createCoachNote: Unable to create coach note',
        {
          cause:
            typeof createError !== 'string'
              ? createError
              : new Error(createError),
        },
      );
      logger.error(error, {
        ...analyticsEventPayload,
        errors: createError,
        schemaType: getSchemaTypeStr(schemaType),
      });
    }

    dispatch(wroteCoachNote({ ...analyticsEventPayload, success }));

    if (id) {
      handleCoachNotesNavigation({ noteId: id, noteType });
      dispatch(setSelectedNote({ selectedNote: { id, ...note } }));
    }

    return { error: createError, id, success };
  };

  const _shareCoachNote = async (
    itemId: string,
    noteType: CareProviderNoteType,
  ): Promise<MutationResponse> => {
    const sharedGroups = [
      {
        id: await Base64.hash(getCoachingTeamGroupId(memberId)),
        key: await vaultItemKeyInput(keyGenerator),
      },
    ];

    // Exclude Quick Notes from being shared with clinicians
    if (isShareableWithClinicians(noteType)) {
      sharedGroups.push({
        id: await Base64.hash(getClinicalCareTeamGroupId(memberId)),
        key: await vaultItemKeyInput(keyGenerator),
      });
    }

    try {
      const input: ShareVaultItemsInput = {
        groups: sharedGroups,
        itemIds: [itemId],
        setItemPermissionsToReadOnly: true,
        systems: [],
        users: [],
      };

      const {
        errors: shareVaultItemErrors,
        data: response,
      } = await shareVaultItemsFn({
        groupId: await Base64.hash(getCoachingTeamGroupId(memberId)),
        input,
      });

      if (shareVaultItemErrors || !response) {
        const errorMessage =
          shareVaultItemErrors
            ?.map((_: { message: any }) => _.message)
            .join('\n') ??
          `Unable to share vault item ${itemId} with type ${noteType} for member ${memberId}`;

        return {
          error: errorMessage,
          id: null,
          success: false,
        };
      }

      return {
        id: itemId,
        success: true,
      };
    } catch (error) {
      return { error, id: null, success: false };
    }
  };

  const shareCoachNote = async (
    itemId: string,
    noteType: CareProviderNoteType,
  ): Promise<MutationResponse> => {
    const { id, success, error: shareError } = await _shareCoachNote(
      itemId,
      noteType,
    );

    const analyticsEventPayload = {
      label: CareProviderNotesLabel.NOTE_PUBLISHED,
      memberId,
      noteId: itemId,
      noteType,
    };

    if (!success) {
      const error = new Error(
        'useCoachNotes.shareCoachNote: Unable to share coach note',
        {
          cause:
            typeof shareError !== 'string' ? shareError : new Error(shareError),
        },
      );
      logger.error(error, {
        ...analyticsEventPayload,
        errors: shareError,
        itemId,
      });
      dispatch(
        showErrorNotification({
          error: new CustomError('Failed to publish note', 'PUBLISH_ERROR', {
            error: shareError,
            id,
            noteType,
          }),
          queryName: 'useCoachNotes:shareCoachNote',
        }),
      );
    } else {
      await saveNotesUserMetadata(itemId);
      handleRiskAndDismissTasks();
    }

    dispatch(wroteCoachNote({ ...analyticsEventPayload, success }));

    return { error: shareError, id, success };
  };

  const _updateCoachNote = async (
    itemId: string,
    params: CoachNotesItem,
  ): Promise<MutationResponse> => {
    const { schemaType, data, noteType } = params;

    if (!hasValidParams(params)) {
      return {
        error: new CustomError(
          'useCoachNote.updateCoachNote: Missing required params',
          'MISSING_PARAMS',
          {
            data: Boolean(data),
            noteType: Boolean(noteType),
            schemaType: Boolean(schemaType),
          },
        ),
        id: null,
        success: false,
      };
    }

    try {
      const riskExists = noteContainsRisks(data);
      const vaultItem = inputToVaultItem(
        memberId,
        schemaType!,
        data as NoteItem,
      );
      const inputParams: UpdateVaultItemInputParams = {
        groupId: await Base64.hash(getCoachingTeamGroupId(memberId)),
        itemId,
        permissions: riskExists
          ? VaultItemPermissions.WritableByAll
          : VaultItemPermissions.Writable,
        tags: getTags(memberId, noteType!),
        vaultItem,
      };

      const item = await updateVaultItemInput(inputParams, keyGenerator, {
        hashItemIds: false,
      });
      const { errors: updateError, data: response } = await updateVaultItemsFn({
        input: [(item as unknown) as UpdateVaultItemInput],
      });
      if (updateError || !response) {
        const errorMessage =
          updateError?.map((_: { message: any }) => _.message).join('\n') ??
          `Unable to update vault item ${itemId} with type ${noteType} for member ${memberId}`;
        return {
          error: errorMessage,
          id: null,
          success: false,
        };
      }

      const { id } = response.updateVaultItems[0];

      return {
        id,
        success: true,
      };
    } catch (error) {
      return { error, id: null, success: false };
    }
  };

  const updateCoachNote = async (
    itemId: string,
    note: CoachNotesItem,
  ): Promise<MutationResponse> => {
    const { schemaType, noteType } = note;
    const { id, success, error: updateError } = await _updateCoachNote(
      itemId,
      note,
    );

    const analyticsEventPayload = {
      label: CareProviderNotesLabel.NOTE_UPDATED,
      memberId,
      noteId: itemId,
      noteType: noteType!,
    };

    if (!success) {
      const error = new Error(
        'useCoachNotes.updateCoachNote: Unable to update coach note',
        {
          cause:
            typeof updateError !== 'string'
              ? updateError
              : new Error(updateError),
        },
      );
      logger.error(error, {
        ...analyticsEventPayload,
        errors: updateError,
        itemId,
        schemaType: getSchemaTypeStr(schemaType),
      });
    }

    dispatch(wroteCoachNote({ ...analyticsEventPayload, success }));

    return { error: updateError, id, success };
  };

  const deleteCoachNote = async (
    itemId: string,
    noteType: CareProviderNoteType,
  ) => {
    const { errors } = await deleteVaultItemsFn({
      input: {
        groupId: await getGroupId(memberId, role),
        items: [itemId],
      },
    });

    const isDeleted = errors === undefined;
    const analyticsEventPayload = {
      memberId,
      noteId: itemId,
      noteType,
    };

    if (!isDeleted) {
      logger.error(
        new Error(`useCoachNotes.deleteCoachNote: Unable to delete coach note`),
        {
          errors,
          itemId,
          memberId,
          noteType,
        },
      );
    }

    dispatch(
      deletedCoachNote({
        ...analyticsEventPayload,
        label: CareProviderNotesLabel.NOTE_DELETED,
        success: isDeleted,
      }),
    );

    return isDeleted;
  };

  const saveNote = async (
    note: CoachNotesItem,
  ): Promise<MutationResponse | undefined> => {
    const { id: noteId, data } = note;
    const existingNotes = coachNotes?.data?.notes ?? {};
    const isNewNote = !noteId || !existingNotes[noteId];
    const hasData = !!Object.keys(data || {}).length;

    if (isNewNote && hasData) {
      return await createCoachNote(note);
    }

    if (noteId && !isEqual(existingNotes[noteId].data, data)) {
      return await updateCoachNote(noteId, note);
    }
  };

  const updateDraftNoteState = useCallback(
    async (updatedNote: CoachNotesItem) => {
      const newSelectedNote = {
        ...selectedNote,
        ...updatedNote,
        data: {
          ...selectedNote?.data,
          ...updatedNote.data,
        } as NoteOrSubsection,
      };

      if (isEqual(selectedNote, newSelectedNote)) return;
      dispatch(setSelectedNote({ selectedNote: newSelectedNote }));
      const result = await saveNote(newSelectedNote);
      if (result?.error) {
        const { error } = result;
        const { schemaType, noteType, id } = newSelectedNote;
        dispatch(
          showErrorNotification({
            error: new CustomError(
              `An error occurred while trying to save ${noteType} note`,
              'SAVE_ERROR',
              {
                error,
                id,
                noteType,
                schemaType: getSchemaTypeStr(schemaType),
              },
            ),
            queryName: 'useCoachNotes:saveNote',
          }),
        );
      }
    },
    [selectedNote],
  );

  const onSelectCoachNote = async (note: NotesItemResponse) => {
    const { id, metadataId, readOnly, isUnread, noteType } = note;

    if (noteType && id) {
      handleCoachNotesNavigation({ noteId: id, noteType });

      dispatch(
        viewedCoachNote({
          label: CareProviderNotesLabel.NOTE_VIEWED,
          memberId,
          noteId: id,
          noteType,
          totalNotes: Object.keys(coachNotes ?? {}).length,
        }),
      );

      if (readOnly) {
        await saveNotesUserMetadata(id, metadataId, !isUnread);
      }
    }
  };

  const onPublishCoachNote = async () => {
    const { id, noteType } = selectedNote ?? {};
    if (id && noteType) {
      await shareCoachNote(id, noteType);
    }
    handleCoachNotesNavigation();
  };

  const onDeleteCoachNote = async () => {
    const { id, noteType } = selectedNote ?? {};
    if (id && noteType) {
      await deleteCoachNote(id, noteType);
    }
    handleCoachNotesNavigation();
  };

  return {
    coachNotes,
    createCoachNote,
    deleteCoachNote: onDeleteCoachNote,
    onSelectCoachNote,
    publishNote: onPublishCoachNote,
    setQueryParams: handleCoachNotesNavigation,
    updateCoachNote,
    updateDraftNoteState,
  };
}
