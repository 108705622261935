import React, { PropsWithChildren, ReactElement } from 'react';
import { MasonryLayout, WidthRef } from 'shared-components/masonry/Masonry';

const BREAKPOINTS_CONFIG: { [key: number]: number } = {
  295: 1,
  595: 2,
  905: 3,
};

export const MemberChartTab = ({
  children,
}: PropsWithChildren<{}>): ReactElement => {
  return (
    <MasonryLayout
      widthRef={WidthRef.CONTAINER}
      breakPointCols={BREAKPOINTS_CONFIG}
    >
      {children}
    </MasonryLayout>
  );
};
