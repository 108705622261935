import { EventType } from 'app/EventType';
import { createAction } from 'redux-reloaded';

export const viewLibraryButtonClicked = createAction(
  EventType.VIEW_LIBRARY_BUTTON_CLICKED,
);
export const addContentCircleButtonClicked = createAction(
  EventType.ADD_CONTENT_CIRCLE_BUTTON_CLICKED,
);
export const contentUrlPastedIntoModal = createAction(
  EventType.CONTENT_URL_PASTED_INTO_MODAL,
  (payload: { content_url: string }) => ({ ...payload }),
);
export const addHomeworkButtonClicked = createAction(
  EventType.ADD_HOMEWORK_BUTTON_CLICKED,
  (payload: { content_url: string }) => ({ ...payload }),
);
export const invalidContentUrlAdded = createAction(
  EventType.INVALID_CONTENT_URL_ADDED,
  (payload: { invalid_url: string }) => ({ ...payload }),
);
export const unassignButtonClicked = createAction(
  EventType.UNASSIGN_BUTTON_CLICKED,
  (payload: { contentId: string; userId: number }) => ({ ...payload }),
);
