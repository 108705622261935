import { MessageToCareTeam } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/MessageToCareTeam';
import { SafetyPlan } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/safety/SafetyPlan';
import { TreatmentGoals } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/TreatmentGoals';
import { TerminationReasons } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/TerminationReasons';
import { ProtobufEncoder } from '@ginger.io/vault-clinical-notes/dist/type';
import { DeescalationNeed } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/DeescalationNeed';
import { DropInConsult } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/DropInConsult';
import { ExperiencingRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/ExperiencingRisk';
import { ExploringTheApp } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/ExploringTheApp';
import { OtherNote } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/OtherNote';
import { SeekingClinical } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/SeekingClinical';
import { FollowUp } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/FollowUp';
import { InitialConsult } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/InitialConsult';
import { CoachingNote } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/notes/CoachingNote';
import { OutreachAttempt } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/OutreachAttempt';
import { QuickNote } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/QuickNote';
import { RiskAssessment } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/RiskAssessment';
import { VaultItem_SchemaType as SchemaType } from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import { NotesUserMetadata } from '@ginger.io/vault-shared-care-notes/dist/generated/protobuf-schemas/vault-shared-care-notes/NotesUserMetadata';
import {
  CoachNotesActions,
  CoachNotesItem,
  CoachNoteType,
  DropInNoteType,
  ShareableClinicianNoteType,
} from 'app/coach/coach-notes/CoachNotesTypes';
import {
  NoteSchema,
  WritableNoteSchema,
  WritableNoteType,
} from 'app/coach/coach-notes/types';
import { MenuItem } from 'shared-components/CardActionMenu';
import { CoachNotesRoutes } from 'app/top-nav/Routes';

export const NOTE_CREATION_MENU_ITEMS: MenuItem[] = [
  { key: CoachNotesRoutes.FOLLOW_UP, label: 'Follow-up' },
  // { key: CoachNotesRoutes.DROP_IN, label: "Drop-in" }, re-add when enable_drop_in_notes is released
  { key: CoachNotesRoutes.QUICK_NOTE, label: 'Quick Note' },
  { key: CoachNotesRoutes.RISK, label: 'Risk' },
  { key: CoachNotesRoutes.OUTREACH_ATTEMPT, label: 'Outreach Attempt' },
  { key: CoachNotesRoutes.INITIAL_CONSULT, label: 'Discovery Session' },
];

export const NOTE_DRAFT_MENU_ITEMS: MenuItem[] = [
  { key: CoachNotesActions.DELETE_DRAFT, label: 'Delete draft' },
];

export enum FIDO_FIELDS {
  FIDO = 'FIDO *',
  FREQUENCY = 'Frequency',
  INTENSITY = 'Intensity / severity',
  DURATION = 'Duration',
  ONSET = 'Onset',
}

export enum FIDO_PLACEHOLDERS {
  FIDO_HELPER_TEXT = 'Frequency',
  FREQUENCY = 'How often...',
  INTENSITY = 'Extent of injury...',
  INTENSITY_SUBSTANCE_USE = 'How much of a substance at a time...',
  INTENSITY_EATING_DISORDER = 'How intense is the behavior as it is occuring...',
  DURATION = 'Length of behavior...',
  ONSET = 'When did this behavior begin...',
}

export const INITIAL_NOTE_DRAFT_STATE: CoachNotesItem = {
  noteType: undefined,
  schemaType: undefined,
};

export const THERAPY_NOTE_TYPES = [
  ShareableClinicianNoteType.THERAPY_INTAKE,
  ShareableClinicianNoteType.THERAPY_PROGRESS,
];
export const PSYCHIATRY_NOTE_TYPES = [
  ShareableClinicianNoteType.PSYCHIATRY_INTAKE,
  ShareableClinicianNoteType.PSYCHIATRY_PROGRESS,
];
export const TERMINATION_NOTE_TYPES = [
  ShareableClinicianNoteType.PSYCHIATRY_TERMINATION,
  ShareableClinicianNoteType.THERAPY_TERMINATION,
];

export const SCHEMA_TYPES: Record<string, NoteSchema> = {
  // coach notes
  followUp: SchemaType.vault_coach_notes_follow_up,
  deescalationNeed: SchemaType.vault_coach_notes_drop_in_de_escalation_need,

  // drop in notes
  dropInConsult: SchemaType.vault_coach_notes_drop_in_consult,

  dropInOther: SchemaType.vault_coach_notes_drop_in_other_note,

  experiencingRisk: SchemaType.vault_coach_notes_drop_in_experiencing_risk,

  exploringTheApp: SchemaType.vault_coach_notes_drop_in_exploring_the_app,

  initialConsult: SchemaType.vault_coach_notes_initial_consult,

  // legacy daily coaching notes
  legacyDailyCoachingNotes: SchemaType.vault_coach_notes_notes_coaching_note,

  messageToCareTeam: SchemaType.vault_clinical_notes_message_to_care_team,

  // notes user metadata
  notesUserMetadata: SchemaType.vault_shared_care_notes_user_metadata,

  outreachAttempt: SchemaType.vault_coach_notes_outreach_attempt,

  quickNote: SchemaType.vault_coach_notes_quick_note,

  risk: SchemaType.vault_coach_notes_risk_assessment,
  // shared clinical notes subsections
  safetyPlan: SchemaType.vault_clinical_notes_safety_plan,
  seekingClinical: SchemaType.vault_coach_notes_drop_in_seeking_clinical,
  terminationReasons: SchemaType.vault_clinical_notes_termination_reasons,
  treatmentGoals: SchemaType.vault_clinical_notes_treatment_goals,
};

export const noteTypeSchemaMap: Record<WritableNoteType, WritableNoteSchema> = {
  [CoachNoteType.FOLLOW_UP]: SchemaType.vault_coach_notes_follow_up,
  [CoachNoteType.INITIAL_CONSULT]: SchemaType.vault_coach_notes_initial_consult,
  [CoachNoteType.OUTREACH_ATTEMPT]:
    SchemaType.vault_coach_notes_outreach_attempt,
  [CoachNoteType.QUICK_NOTE]: SchemaType.vault_coach_notes_quick_note,
  [CoachNoteType.RISK]: SchemaType.vault_coach_notes_risk_assessment,

  [DropInNoteType.DROP_IN_CONSULT]:
    SchemaType.vault_coach_notes_drop_in_consult,
  [DropInNoteType.DE_ESCALATION_NEED]:
    SchemaType.vault_coach_notes_drop_in_de_escalation_need,
  [DropInNoteType.EXPERIENCING_RISK]:
    SchemaType.vault_coach_notes_drop_in_experiencing_risk,
  [DropInNoteType.EXPLORING_THE_APP]:
    SchemaType.vault_coach_notes_drop_in_exploring_the_app,
  [DropInNoteType.SEEKING_CLINICAL]:
    SchemaType.vault_coach_notes_drop_in_seeking_clinical,
  [DropInNoteType.DROP_IN_OTHER]:
    SchemaType.vault_coach_notes_drop_in_other_note,
};

export const encoderMap: Record<WritableNoteSchema, any> = {
  // coach notes
  [SchemaType.vault_coach_notes_follow_up]: FollowUp.encode,
  [SchemaType.vault_coach_notes_initial_consult]: InitialConsult.encode,
  [SchemaType.vault_coach_notes_outreach_attempt]: OutreachAttempt.encode,
  [SchemaType.vault_coach_notes_quick_note]: QuickNote.encode,
  [SchemaType.vault_coach_notes_risk_assessment]: RiskAssessment.encode,

  // drop in notes
  [SchemaType.vault_coach_notes_drop_in_consult]: DropInConsult.encode,
  [SchemaType.vault_coach_notes_drop_in_de_escalation_need]:
    DeescalationNeed.encode,
  [SchemaType.vault_coach_notes_drop_in_experiencing_risk]:
    ExperiencingRisk.encode,
  [SchemaType.vault_coach_notes_drop_in_exploring_the_app]:
    ExploringTheApp.encode,
  [SchemaType.vault_coach_notes_drop_in_seeking_clinical]:
    SeekingClinical.encode,
  [SchemaType.vault_coach_notes_drop_in_other_note]: OtherNote.encode,
};

export const decoderMap: Record<NoteSchema, ProtobufEncoder<any>> = {
  // coach notes
  [SchemaType.vault_coach_notes_follow_up]: FollowUp,
  [SchemaType.vault_coach_notes_initial_consult]: InitialConsult,
  [SchemaType.vault_coach_notes_outreach_attempt]: OutreachAttempt,
  [SchemaType.vault_coach_notes_quick_note]: QuickNote,
  [SchemaType.vault_coach_notes_risk_assessment]: RiskAssessment,

  // notes user metadata
  [SchemaType.vault_shared_care_notes_user_metadata]: NotesUserMetadata,

  // shared clinical notes subsections
  [SchemaType.vault_clinical_notes_safety_plan]: SafetyPlan,
  [SchemaType.vault_clinical_notes_treatment_goals]: TreatmentGoals,
  [SchemaType.vault_clinical_notes_message_to_care_team]: MessageToCareTeam,
  [SchemaType.vault_clinical_notes_termination_reasons]: TerminationReasons,

  // legacy daily coaching notes
  [SchemaType.vault_coach_notes_notes_coaching_note]: CoachingNote,

  // drop in notes
  [SchemaType.vault_coach_notes_drop_in_consult]: DropInConsult,
  [SchemaType.vault_coach_notes_drop_in_de_escalation_need]: DeescalationNeed,
  [SchemaType.vault_coach_notes_drop_in_experiencing_risk]: ExperiencingRisk,
  [SchemaType.vault_coach_notes_drop_in_exploring_the_app]: ExploringTheApp,
  [SchemaType.vault_coach_notes_drop_in_seeking_clinical]: SeekingClinical,
  [SchemaType.vault_coach_notes_drop_in_other_note]: OtherNote,
};
