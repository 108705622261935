import { InboxSections, InboxTab } from 'app/inbox/types';
import {
  ChatConversationState,
  CoachShiftStatusEnum,
} from 'generated/globalTypes';

import { Envelope, MessageToDisplay } from '../pubnub/types';

export enum HISTORY_STATE {
  NO_MORE_MESSAGES,
  STILL_HAS_SOME_MESSAGES,
  ERROR,
}

/**
 clock - an integral value increasing each time the isTyping is toggled
 nextMessageId - the message ID for the message being compiled to be sent
 isTyping - whether a user is currently typing a message
*/
export interface TypingState {
  clock: number;
  nextMessageId: string;
  isTyping: boolean;
}

export interface AmplitudeLogsContextFromRedux {
  listener_state: CoachShiftStatusEnum | null;
  channel_id: string;
  ginger_id: string;
  request_user_id: string | null;
  listener_id: string | null;
  request_username: string | null;
  tab: keyof typeof InboxTab;
  section: InboxSections | null;
}

export interface AmplitudeLogsContext extends AmplitudeLogsContextFromRedux {
  url: string;
  caller_id: string | null;
}

export interface ReadMessageAmplitudeEvent extends AmplitudeLogsContext {
  // ids of unread messages
  message_ids: string[];
  // timetoken that pubnub returns in case of a successfull publish call
  timetoken: string;
}

export interface TypingMessageAmplitudeEvent extends AmplitudeLogsContext {
  // new is_typing state
  is_typing: boolean;
}

export interface WriteMessageAmplitudeEvent extends AmplitudeLogsContext {
  message_id: string;
  msg_len: number;
  timetoken: string;
}

export interface ChatTextAreaFocusAmplitudeEvent extends AmplitudeLogsContext {
  focus: boolean;
}

export interface CareProviderSentSomeLinkAmplitudeEvent
  extends AmplitudeLogsContext {
  // the listener splits the string into words and each time it finds a url, it sends the logs, so its property is called "domain", we replaced it by domains since we send one request
  domain: string;
}

export interface ChatErrorAmplitudeEvent extends AmplitudeLogsContext {
  error: Error;
}

// a key as a memberCoachChannelId, a value is a memberId
export type ChannelIdMemberIdMap = Record<string, string>;

export interface TimeTokensAndStamps {
  lastMemberReadTimeToken?: string | null;
  lastListenerReadTimeToken: string | null;
  lastMemberWriteTimeToken: string | null;
  latestWriteTimestamp: ISODateString | null;
}
// a key is a memberCoachChannelId
export type TimetokensMap = Record<string, TimeTokensAndStamps>;

export interface ConversationState {
  stateLastUpdatedAt?: ISODateString | null;
  state?: ChatConversationState | null;
}
// a key is a memberCoachChannelId
export type ConversationStateMap = Record<string, ConversationState>;

export interface ExtractedStateFromInboxItem {
  channelMemberMap: ChannelIdMemberIdMap;
  conversationStateMap: ConversationStateMap;
}

export type UnreadMessagesMap = Record<string, MessageToDisplay[]>;

export type UpdateUnreadMessagesPayload = Array<{
  memberCoachChannelId: string;
  lastListenerReadTimetoken: string | null | undefined;
}>;

export type UnreadMessagesForAllConversations = {
  unreadMessages: UnreadMessagesMap;
  // pubnub coach <=> member channelIds are used as keys here
  ignoredRPCs: Record<string, Envelope[]>;
};

export type UnreadMessagesFoConversation = {
  unreadMessages: MessageToDisplay[];
  ignoredRPCs: Envelope[];
};
