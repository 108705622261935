import {
  arrayField,
  booleanField,
  field,
  FieldDefinitions,
  stringField,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import { NoteType } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/NoteType';
import {
  SafetyPlanStatus,
  Version as SafetyPlanVersion,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/safety/SafetyPlan';
import { SafetyConcern } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyConcern';
import {
  Safety,
  Version as SafetyProgressVersion,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyProgress';
import {
  optionalCHIDescription,
  optionalCSIDescription,
  optionalField,
  optionalSelfInjury,
} from 'app/notes-ui/shared/ValidationRules';

export const fieldDefs: FieldDefinitions<Safety> = {
  appointmentId: stringField(),
  anyChangesOrRisks: field({ rules: [optionalField] }),
  currentHomicidalIdeation: {
    description: stringField({ rules: [optionalCHIDescription] }),
    hasPast: booleanField({ default: false, rules: [optionalField] }),
    intent: booleanField(),
    means: booleanField(),
    pastSummarization: stringField({ rules: [optionalField] }),
    plan: booleanField(),
    specificTargetsEntityOrLocationConsidered: booleanField(),
    thoughts: booleanField(),
  },

  currentSelfInjury: {
    bodyPartHarmed: stringField({ rules: [optionalSelfInjury] }),
    currentSummarization: stringField({ rules: [optionalField] }),
    everSoughtMedicalAttentionAsAResult: booleanField({
      rules: [optionalSelfInjury],
    }),
    extentOfInjury: stringField({ rules: [optionalSelfInjury] }),
    frequency: {
      period: field({ rules: [optionalSelfInjury] }),
      timesPerPeriod: field({ rules: [optionalSelfInjury] }),
    },
    hasPast: booleanField({ default: false }),
    instrumentUsed: stringField({ rules: [optionalSelfInjury] }),
    isPresent: booleanField(),
    pastEverSoughtMedicalAttentionAsAResult: booleanField({ default: false }),
    pastSummarization: stringField({ rules: [optionalField] }),
  },

  currentSuicidalIdeation: {
    description: stringField({ rules: [optionalCSIDescription] }),
    hasPast: booleanField({ default: false, rules: [optionalField] }),
    intent: booleanField(),
    means: booleanField(),
    pastSummarization: stringField({ rules: [optionalField] }),
    plan: booleanField(),
    thoughts: booleanField(),
  },

  domesticViolenceRisk: {
    currentDescription: stringField({ rules: [optionalField] }),
  },

  eatingDisorderRisk: {
    currentDescription: stringField({ rules: [optionalField] }),
  },

  highSubstanceUse: {
    currentDescription: stringField({ rules: [optionalField] }),
  },

  otherConcerns: {
    currentDescription: stringField({ rules: [optionalField] }),
  },

  safetyPlan: {
    appointmentEnd: stringField({ rules: [] }),
    appointmentId: stringField({ rules: [] }),
    appointmentStart: stringField({ rules: [] }),
    description: stringField(),
    noteType: field<NoteType>({ rules: [] }),
    actionsTaken: {
      consultedWithManager: field<BooleanOption>({
        default: BooleanOption.undefined_choice,
        rules: [optionalField],
      }),
      crr: field<BooleanOption>({
        default: BooleanOption.undefined_choice,
        rules: [optionalField],
      }),
      sharedResources: field<BooleanOption>({
        default: BooleanOption.undefined_choice,
        rules: [optionalField],
      }),
      careCoordinationTeamEngaged: field<BooleanOption>({
        default: BooleanOption.undefined_choice,
        rules: [optionalField],
      }),
      onCallClinicianContacted: field<BooleanOption>({
        default: BooleanOption.undefined_choice,
        rules: [optionalField],
      }),
      onCallClinicianContactedNotes: stringField({ rules: [optionalField] }),
    },
    psychiatryQuestions: {
      agenciesToContactInCrisis: field<string[]>({
        default: [],
        rules: [optionalField],
      }),
      safetyPlanDocumentation: stringField({ rules: [optionalField] }),
    },
    status: field<SafetyPlanStatus>({
      default: SafetyPlanStatus.undefined_status,
      rules: [optionalField],
    }),
    therapyQuestions: {
      agenciesToContactInCrisis: field<string[]>({
        default: [],
        rules: [optionalField],
      }),
      externalStrategiesOrientationForMentalHealthCrisis: stringField({
        rules: [optionalField],
      }),
      internalStrategiesOrientationForMentalHealthCrisis: stringField({
        rules: [optionalField],
      }),
      peopleToContactInCrisis: field<string[]>({
        default: [],
        rules: [optionalField],
      }),
      rememberWhatsImportant: stringField({ rules: [optionalField] }),
      safeEnvironmentOrientation: stringField({ rules: [optionalField] }),
      warningSignsAndTriggersForDevelopingCrisis: stringField({
        rules: [optionalField],
      }),
    },
    version: field<SafetyPlanVersion>({
      default: SafetyPlanVersion.undefined_version,
    }),
  },

  selectedConcerns: arrayField<SafetyConcern>(),

  version: field<SafetyProgressVersion>({
    default: SafetyProgressVersion.undefined_version,
  }),
  vulnerablePopulationsAbuse: {
    currentDescription: stringField({ rules: [optionalField] }),
  },
};

export const validate = (data: Safety | null) =>
  data !== null && validateForm(data, fieldDefs);
