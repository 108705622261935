import { TableCell } from '@mui/material';
import Paper from '@mui/material/Paper';
import MUITableBody from '@mui/material/TableBody';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { V2Table as Table } from 'shared-components/table/V2Table';
import { V2TableBody } from 'shared-components/table/V2TableBody';
import { V2TableHead as TableHead } from 'shared-components/table/V2TableHead';
import { Loader } from 'shared-components/loader/Loader';
import { MemberChartErrorState } from 'app/member-chart-cards/error-state/MemberChartErrorState';
import {
  Document,
  DocumentStatus,
  UploadDocumentInput,
  UploadFileTypeMap,
  UploadResponse,
} from 'app/patients/tabs/document-upload/types';
import { useAppState } from 'app/state';
import { StateSlice, Status } from 'app/state/status/types/StateSlice';
import { VaultItemSortOrder } from 'generated/globalTypes';
import React, { useEffect, useRef, useState } from 'react';

import styles from './DocumentGrid.module.scss';
import { Action } from './DocumentGridActionButton';
import { ExistPrompt } from './ExistPrompt';
import {
  DocumentGridRow,
  inputFileToDocument,
  UploadDocumentRow,
} from './GridRow';
import { ReactComponent as SortIconAsc } from './sort-icon-asc.svg';
import { ReactComponent as SortIconDesc } from './sort-icon-desc.svg';

interface Props {
  onPreview: (document: Document) => Promise<void>;
  documents: StateSlice<Document[]>;
  onActionItemClick: (action: Action, document: Document) => Promise<void>;
  onSortClick: (order: VaultItemSortOrder) => Promise<void>;
  filesToUpload: UploadDocumentInput[] | undefined;
  uploadDocuments: (input: UploadDocumentInput[]) => Promise<UploadResponse[]>;
  refreshData: (
    sortOrder?: VaultItemSortOrder,
    showLoader?: boolean,
  ) => Promise<void>;
  onComplete: () => void;
  onError: (message: string) => void;
}

const tableColumns: {
  label: string;
  key: string;
  sortable?: boolean;
  vaultSortable?: boolean;
  width?: number;
}[] = [
  { key: 'ext', label: '', width: 45 },
  { key: 'name', label: 'Name', sortable: true, width: 350 },
  { key: 'documentType', label: 'Type', sortable: true },
  { key: 'author', label: 'Added By', sortable: true },
  {
    key: 'createdAt',
    label: 'Date Added',
    sortable: true,
    vaultSortable: true,
  },
  { key: 'fileSize', label: 'File Size', sortable: true },
  { key: '', label: 'Actions' },
];

const tableColumnsV2: {
  label: string;
  key: string;
  sortable?: boolean;
  vaultSortable?: boolean;
  width?: number;
}[] = [
  { key: 'ext', label: '', width: 35 },
  { key: 'name', label: 'Name', sortable: true, width: 60 },
  { key: 'documentType', label: 'Type', sortable: true, width: 50 },
  { key: 'author', label: 'Added By', sortable: true },
  {
    key: 'createdAt',
    label: 'Date Added',
    sortable: true,
    vaultSortable: true,
  },
  { key: 'fileSize', label: 'File Size', sortable: true },
  { key: '', label: 'Actions' },
];

function DocumentGrid(props: Props) {
  const {
    onActionItemClick,
    onPreview,
    documents,
    uploadDocuments,
    refreshData,
  } = props;
  const {
    enable_care_hub_notes_efficiency,
  } = useFeatureFlags().transientFeatureFlags;
  const formattedColumns = enable_care_hub_notes_efficiency
    ? tableColumnsV2
    : tableColumns;
  const author = useAppState(({ user }) =>
    `${user.firstName ?? ''} ${user.lastName ?? ''}`.trim(),
  );
  const [uploading1, setUploading1] = useState<UploadDocumentInput>();
  const [uploading2, setUploading2] = useState<UploadDocumentInput>();
  const [uploading3, setUploading3] = useState<UploadDocumentInput>();
  const [pendingFiles, setPendingFiles] = useState<UploadDocumentInput[]>([]);
  const [failedUploads, setFailedUploads] = useState<UploadDocumentInput[]>([]);
  const [sortOrder, setSortOrder] = useState(
    Array(formattedColumns.length).fill(VaultItemSortOrder.DESC),
  );
  const [vaultSortOrder, setVaultSortOrder] = useState<VaultItemSortOrder>(
    VaultItemSortOrder.DESC,
  );
  const [columnSortIndex, setColumnSortIndex] = useState(-1);
  const mountRef = useRef(false);

  const onSortClick = async (index: number, isVaultSortable: boolean) => {
    if (isVaultSortable) {
      const order =
        vaultSortOrder === VaultItemSortOrder.DESC
          ? VaultItemSortOrder.ASC
          : VaultItemSortOrder.DESC;
      await props.onSortClick(order);
      setVaultSortOrder(order);
      const newSortOrder = [...sortOrder];
      newSortOrder[index] = order;
      setSortOrder(newSortOrder);
      setColumnSortIndex(index);
    } else {
      const order =
        sortOrder[index] === VaultItemSortOrder.DESC
          ? VaultItemSortOrder.ASC
          : VaultItemSortOrder.DESC;
      const newSortOrder = [...sortOrder];
      newSortOrder[index] = order;
      setSortOrder(newSortOrder);
      setColumnSortIndex(index);
    }
  };

  const handleUpdate = (pendingFiles: UploadDocumentInput[]) => {
    const hasPendingUpload =
      pendingFiles.length !== 0 ||
      uploading1 !== undefined ||
      uploading2 !== undefined ||
      uploading3 !== undefined;
    const updatedFiles = [...pendingFiles];
    if (!uploading1) setUploading1(updatedFiles.shift());
    if (!uploading2) setUploading2(updatedFiles.shift());
    if (!uploading3) setUploading3(updatedFiles.shift());
    setPendingFiles(updatedFiles);
    if (!hasPendingUpload) {
      props.onComplete();
    }
  };

  const onRetryOrRemove = (doc: Document, retry = false) => {
    const updateFailedUploads = [...failedUploads];
    const index = updateFailedUploads.findIndex((_) => _.name === doc.name);
    const [input] = updateFailedUploads.splice(index, 1);
    setFailedUploads(updateFailedUploads);
    if (retry) handleUpdate(pendingFiles.concat([input]));
  };

  const onError = (doc: UploadDocumentInput) => (message: string) => {
    props.onError(message);
    if (doc === uploading1) setUploading1(undefined);
    if (doc === uploading2) setUploading2(undefined);
    if (doc === uploading3) setUploading3(undefined);
    setFailedUploads(failedUploads.concat([doc]));
  };

  useEffect(() => {
    if (!mountRef.current) return;
    handleUpdate(pendingFiles);
  }, [uploading1, uploading2, uploading3]);

  useEffect(() => {
    if (props.filesToUpload && props.filesToUpload.length > 0) {
      mountRef.current = true;
      handleUpdate(pendingFiles.concat(props.filesToUpload));
    }
  }, [props.filesToUpload]);

  const { documentBody } = convertSliceStateToElement(
    documents,
    onActionItemClick,
    onPreview,
    sortOrder,
    columnSortIndex,
  );
  const columns = formattedColumns.map(
    ({ label, sortable = false, vaultSortable = false, width, key }, index) => (
      <TableCell width={width} key={label}>
        <span className={styles.tableHead}>
          {label}
          &nbsp;
          {sortable && (
            <>
              {sortOrder[index] === VaultItemSortOrder.ASC ? (
                <SortIconDesc
                  className={styles.sortIcon}
                  data-testid={`${key}-sort`}
                  onClick={() => onSortClick(index, vaultSortable)}
                />
              ) : (
                <SortIconAsc
                  className={styles.sortIcon}
                  data-testid={`${key}-sort`}
                  onClick={() => onSortClick(index, vaultSortable)}
                />
              )}
            </>
          )}
        </span>
      </TableCell>
    ),
  );
  const showPrompt =
    pendingFiles.length !== 0 ||
    uploading1 !== undefined ||
    uploading2 !== undefined ||
    uploading3 !== undefined;

  return (
    <>
      <ExistPrompt
        showPrompt={showPrompt}
        message="Document upload in progress. Are you sure you want to leave?"
      />
      <Paper className={styles.documentGrid}>
        <Table dataTestId="clinicalDocsTable">
          <TableHead>{columns}</TableHead>
          <>
            <MUITableBody>
              {uploading1 && (
                <UploadDocumentRow
                  key={uploading1.name}
                  author={author}
                  file={uploading1}
                  uploader={uploadDocuments}
                  onError={onError(uploading1)}
                  onComplete={async () => {
                    await refreshData(vaultSortOrder);
                    setUploading1(undefined);
                  }}
                />
              )}
              {uploading2 && (
                <UploadDocumentRow
                  key={uploading2.name}
                  author={author}
                  file={uploading2}
                  uploader={uploadDocuments}
                  onError={onError(uploading2)}
                  onComplete={async () => {
                    await refreshData(vaultSortOrder);
                    setUploading2(undefined);
                  }}
                />
              )}
              {uploading3 && (
                <UploadDocumentRow
                  key={uploading3.name}
                  author={author}
                  file={uploading3}
                  uploader={uploadDocuments}
                  onError={onError(uploading3)}
                  onComplete={async () => {
                    await refreshData(vaultSortOrder);
                    setUploading3(undefined);
                  }}
                />
              )}
            </MUITableBody>
            <V2TableBody>
              {pendingFiles.map((file) => (
                <DocumentGridRow
                  onActionItemClick={() => Promise.resolve()}
                  onNameClick={() => Promise.resolve()}
                  key={file.name}
                  document={inputFileToDocument(
                    author,
                    file,
                    DocumentStatus.PENDING,
                  )}
                />
              ))}
            </V2TableBody>
            <V2TableBody>
              {failedUploads.map((file) => (
                <DocumentGridRow
                  onActionItemClick={() => Promise.resolve()}
                  onNameClick={() => Promise.resolve()}
                  key={file.name}
                  document={inputFileToDocument(
                    author,
                    file,
                    DocumentStatus.FAILED,
                  )}
                  onRetry={(_) => onRetryOrRemove(_, true)}
                  onRemove={onRetryOrRemove}
                />
              ))}
            </V2TableBody>
            {documentBody}
          </>
        </Table>
      </Paper>
    </>
  );
}

export function convertSliceStateToElement(
  documents: StateSlice<Document[]>,
  onActionItemClick: (action: Action, document: Document) => Promise<void>,
  onNameClick: (document: Document) => Promise<void>,
  sortOrder: VaultItemSortOrder[] = [],
  columnSortIndex: number = -1,
) {
  const { data, status, error } = documents;
  let documentBody = null;
  let loader = null;
  let errorElement = null;

  switch (status) {
    case Status.LOADING:
      loader = <Loader />;
      break;
    case Status.ERROR:
      errorElement = (
        <div className={styles.errorRoot}>
          <MemberChartErrorState error={error || undefined} />
        </div>
      );
      break;
    case Status.COMPLETE: {
      let sortedData = [];
      if (columnSortIndex !== -1) {
        const sorter =
          sortOrder[columnSortIndex] === VaultItemSortOrder.DESC
            ? (a: Document, b: Document) => descSorter(a, b, columnSortIndex)
            : (a: Document, b: Document) => -descSorter(a, b, columnSortIndex);
        sortedData = (data ?? []).sort(sorter);
      } else {
        sortedData = data ?? [];
      }
      documentBody = (
        <V2TableBody>
          {sortedData.map((doc) => (
            <DocumentGridRow
              key={doc.itemId}
              onActionItemClick={onActionItemClick}
              onNameClick={onNameClick}
              document={doc}
            />
          ))}
        </V2TableBody>
      );
    }
  }
  return { documentBody, errorElement, loader };
}

function descSorter(a: Document, b: Document, columnSortIndex: number): number {
  switch (columnSortIndex) {
    case 1:
      if (a.name > b.name) return -1;
      if (a.name < b.name) return 1;
      break;
    case 2: {
      // Map sorts by alphabetical rather than enum value
      const docTypeA: string = UploadFileTypeMap.get(a.documentType) ?? '';
      const docTypeB: string = UploadFileTypeMap.get(b.documentType) ?? '';
      if (docTypeA > docTypeB) return -1;
      if (docTypeA < docTypeB) return 1;
      break;
    }
    case 3:
      if (a.author > b.author) return -1;
      if (a.author < b.author) return 1;
      break;
    case 5:
      if (a.fileSize > b.fileSize) return -1;
      if (a.fileSize < b.fileSize) return 1;
      break;
  }
  return 0;
}

export default DocumentGrid;
