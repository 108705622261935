import patientProfileStyles from 'app/patients/PatientProfile.module.scss';
import { prescriptionsRoute } from 'app/patients/Routes';
import { SecureRouteWrapper } from 'app/routes/SecureRoute';
import { UserRole } from 'generated/globalTypes';
import { TransientFeatureFlag } from 'hooks/useFeatureFlags';
import React, { Suspense, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const LazyPrescriptions = React.lazy(
  () => import('./tabs/prescriptions/Prescriptions'),
);

type Props = { id: string; pathPrefix: string };

export function PrescriptionRoute({ id, pathPrefix }: Readonly<Props>) {
  const location = useLocation();
  const [
    shouldLoadPrescriptionsComponent,
    setShouldLoadPrescriptionsComponent,
  ] = useState(false);
  useEffect(() => {
    setShouldLoadPrescriptionsComponent(false);
  }, [id]);
  useEffect(() => {
    if (location.pathname === prescriptionsRoute(pathPrefix, id)) {
      setShouldLoadPrescriptionsComponent(true);
    }
  }, [location, pathPrefix, id]);
  return (
    <>
      {/*
        Because the prescriptions component uses an iframe to load the UI, we lazy load the component and then
        show/hide it in order not to lose state within that UI when switching tabs.
       */}
      <div
        key={id}
        className={
          location.pathname === prescriptionsRoute(pathPrefix, id)
            ? ''
            : patientProfileStyles.hide
        }
      >
        {shouldLoadPrescriptionsComponent && (
          <Suspense fallback={<div>Loading...</div>}>
            <SecureRouteWrapper
              permittedRoles={[
                UserRole.PSYCHIATRIST_SUPERVISOR,
                UserRole.PSYCHIATRIST,
              ]}
              isVaultRequired={true}
              component={LazyPrescriptions}
              componentProps={{ memberId: id }}
            />
          </Suspense>
        )}
      </div>
    </>
  );
}
