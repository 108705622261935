import { ILogger } from 'app/state/log/Logger';
import { chunk } from 'lodash';
import Pubnub, {
  HereNowParameters,
  HereNowResponse,
  ListenerParameters,
  MessageCountsParameters,
  MessageCountsResponse,
  SetStateParameters,
} from 'pubnub';

import { IPubnubStrategy } from './PubnubAPIService';
import {
  GetHistory,
  PublishMessage,
  SubscribeToChannels,
  UpdateReadStateRPC,
} from './types';

export class InstantiatedPubnubstrategy implements IPubnubStrategy {
  private channels: Set<string> = new Set();

  private channelGroups: Set<string> = new Set();

  private subscribeRetryAttemptsByUrl = new Map();

  constructor(
    private pubnub: Pubnub,
    private logger: ILogger,
    private clientUUID: string = '',
  ) {}

  retryChannelSubscription(envelope: any): void {
    const subscribeUrl = envelope.errorData.url;
    const attemptsSoFar =
      this.subscribeRetryAttemptsByUrl.get(subscribeUrl) || 0;
    const additionalData = {
      attemptsSoFar,
      clientUUID: this.clientUUID,
      envelope,
      errorCategory: envelope.category,
      source: 'retryChannelSubscription',
      url: subscribeUrl,
    };

    if (attemptsSoFar > 5) {
      // TODO: add a counter metric for this.
      // https://www.pubnub.com/docs/sdks/javascript/status-events#subscription
      return this.logger.warning(
        'Unable to subscribe to pubnub channel. Exhausted retries',
        additionalData,
      );
    }
    this.logger.info('PubNub subscribe retrying', additionalData);
    const retryCount = attemptsSoFar + 1;
    this.subscribeRetryAttemptsByUrl.set(subscribeUrl, retryCount);

    const url = new URL(subscribeUrl);
    const pathComponents = url.pathname.split('/');
    // ["", "v2", "subscribe", "sub-c-97ab3044-3d42-11e5-a8de-0619f8945a4f", <encoded IDs separated by %2C>, ...]
    const idsComponent = pathComponents[4];

    if (!idsComponent) {
      return this.logger.warning('No channels to retry', additionalData);
    }

    const channels = decodeURIComponent(idsComponent)
      .split(',') // <encoded IDs separated by commas (%2C)>
      // Removing all the "-pnpres" channels because those are "presence" channels derived from the original channels
      // and added by the Pubnub library. If we pass those along and specify withPresence of true, the Pubnub
      // library will treat these "-pnpres" channels as regular channels and try to create "presence" channels for
      // them, resulting in "-pnpres-pnpres" channels which will lead to errors like "403 Forbidden"
      // or "414 Request URI Too Long"
      .filter((id) => !id.endsWith('-pnpres'));

    const delayMs = 2 ** retryCount * 1000;
    setTimeout(() => {
      const wildCardChannels = channels.filter((_) => _.endsWith('.*'));
      const nonWildCardChannels = channels.filter((_) => !_.endsWith('.*'));
      if (wildCardChannels.length > 0) {
        this.subscribeToChannels({ channels: wildCardChannels });
      }
      if (nonWildCardChannels.length > 0) {
        this.subscribeToChannels({
          channels: nonWildCardChannels,
          withPresence: true,
        });
      }
    }, delayMs);
  }

  publishMessage: PublishMessage = (params) => {
    const message = params.message as UpdateReadStateRPC;
    this.logger.info(
      'InstantiatedPubnubStrategy: Publishing message to pubnub',
      {
        channel: params.channel,
        messageId: message.id,
        messageType: message.rpc ?? 'text_message',
        updateStatusForMessageIds: message.message_ids,
      },
    );
    return this.pubnub.publish(params);
  };

  subscribeToChannels: SubscribeToChannels = (params) => {
    // remove duplicate channels
    const distinctChannels = new Set(params.channels);

    // do not allow duplicate subscriptions, so filter out channels that are already subscribed to
    const channelsToSubscribeTo = Array.from(distinctChannels)?.filter(
      (channel) => !this.channels.has(channel),
    );

    if (channelsToSubscribeTo && channelsToSubscribeTo.length) {
      this.channels = new Set([...this.channels, ...channelsToSubscribeTo]);
      this.logger.info(`Subscribing to channels for UUID ${this.clientUUID}`, {
        channels: channelsToSubscribeTo,
      });
      return this.pubnub.subscribe({
        ...params,
        channelGroups: undefined,
        channels: channelsToSubscribeTo,
      });
    }
  };

  subscribeToChannelGroups: SubscribeToChannels = (params) => {
    // remove duplicate channel groups
    const distinctChannelGroups = new Set(params.channelGroups);
    // do not allow duplicate subscriptions, so filter out channel groups that are already subscribed to
    const channelGroupsToSubscribeTo = Array.from(
      distinctChannelGroups,
    )?.filter((channel) => !this.channelGroups.has(channel));

    if (channelGroupsToSubscribeTo && channelGroupsToSubscribeTo.length) {
      this.channelGroups = new Set([
        ...this.channels,
        ...channelGroupsToSubscribeTo,
      ]);

      return this.pubnub.subscribe({
        ...params,
        channelGroups: channelGroupsToSubscribeTo,
        channels: undefined,
      });
    }
  };

  unsubscribeChannels: (channels: string[]) => void = (channels) => {
    const channelsToUnsubscribeFrom = channels.filter((channel) =>
      this.channels.has(channel),
    );

    if (channelsToUnsubscribeFrom && channelsToUnsubscribeFrom.length > 0) {
      // remove the channels from the set of subscribed channels
      channelsToUnsubscribeFrom.forEach((channel) => {
        this.channels.delete(channel);
      });

      this.pubnub.unsubscribe({
        channels: channelsToUnsubscribeFrom,
      });
    }
  };

  unsubscribeFromChannelGroups: (channelGroups: string[]) => void = (
    channelGroups,
  ) => {
    const channelGroupsToUnsubscribeFrom = channelGroups.filter(
      (channelGroup) => this.channelGroups.has(channelGroup),
    );

    if (
      channelGroupsToUnsubscribeFrom &&
      channelGroupsToUnsubscribeFrom.length > 0
    ) {
      // remove the channel groups from the set of subscribed channel groups
      channelGroupsToUnsubscribeFrom.forEach((channelGroup) => {
        this.channelGroups.delete(channelGroup);
      });

      this.pubnub.unsubscribe({
        channelGroups: channelGroupsToUnsubscribeFrom,
      });
    }
  };

  getSubscribedChannels = () => {
    return this.channels;
  };

  getHistory: GetHistory = ({ channelIds, start }) => {
    this.logger.info(
      'InstantiatedPubnubStrategy: Fetching messages from pubnub',
      { channels: JSON.stringify(channelIds), start },
    );
    const params =
      start && start.length
        ? {
            channels: channelIds,
            includeMeta: true,
            start,
          }
        : {
            channels: channelIds,
            includeMeta: true,
          };
    return this.pubnub.fetchMessages(params);
  };

  addListener(params: ListenerParameters) {
    return this.pubnub.addListener(params);
  }

  removeListener(params: ListenerParameters) {
    return this.pubnub.removeListener(params);
  }

  async hereNow(params: HereNowParameters): Promise<HereNowResponse> {
    return this.pubnub.hereNow(params);
  }

  unsubscribeAll = () => {
    return this.pubnub.unsubscribeAll();
  };

  setPubnubState = (params: SetStateParameters) => {
    return this.pubnub.setState(params);
  };

  getNumberOfUnreadMessages = async (
    params: MessageCountsParameters,
  ): Promise<MessageCountsResponse> => {
    const tokens = chunk(params.channelTimetokens as string[], 100);
    const promises = chunk(params.channels, 100).map((channels, index) =>
      this.pubnub.messageCounts({
        channelTimetokens: tokens[index],
        channels,
      }),
    );
    const results = await Promise.all(promises);
    const responses: MessageCountsResponse = {
      channels: {},
    };
    results.forEach(
      (response) =>
        (responses.channels = { ...responses.channels, ...response.channels }),
    );
    return responses;
  };
}

export class NotInstantiatedPubnubStrategy implements IPubnubStrategy {
  publishMessage: PublishMessage = (params) => {
    throw new Error(
      'Cannot publish a message as pubnub has not been instantiated',
    );
  };

  subscribeToChannels: SubscribeToChannels = (params) => {
    throw new Error(
      'Cannot subscribe to channels as pubnub has not been instantiated',
    );
  };

  subscribeToChannelGroups: SubscribeToChannels = (params) => {
    throw new Error(
      'Cannot subscribe to channels as pubnub has not been instantiated',
    );
  };

  unsubscribeChannels: (channels: string[]) => void = (channels) => {
    throw new Error(
      'Cannot unsubscribe to channels as pubnub has not been instantiated',
    );
  };

  unsubscribeFromChannelGroups: (channelGroups: string[]) => void = (
    channelGroups,
  ) => {
    throw new Error(
      'Cannot unsubscribe to channel groups as pubnub has not been instantiated',
    );
  };

  getSubscribedChannels = () => {
    throw new Error(
      'Cannot getSubscribedChannels as pubnub has not been instantiated',
    );
  };

  getHistory: GetHistory = ({ channelIds, start }) => {
    throw new Error('Cannot getHistory as pubnub has not been instantiated');
  };

  addListener(params: ListenerParameters) {
    throw new Error('Cannot addListener as pubnub has not been instantiated');
  }

  removeListener(params: ListenerParameters) {
    throw new Error(
      'Cannot removeListener as pubnub has not been instantiated',
    );
  }

  unsubscribeAll = () => {
    throw new Error(
      'Cannot unsubscribe from channels as pubnub has not been instantiated',
    );
  };

  setPubnubState = (params: SetStateParameters) => {
    throw new Error(
      'Cannot setPubnubState as pubnub has not been instantiated',
    );
  };

  hereNow(params: Pubnub.HereNowParameters): Promise<Pubnub.HereNowResponse> {
    throw new Error('Cannot call hereNow as pubnub has not been instantiated');
  }

  getNumberOfUnreadMessages = (
    params: MessageCountsParameters,
  ): Promise<MessageCountsResponse> => {
    throw new Error(
      'Cannot call getNumberOfUnreadMessages as pubnub has not been instantiated',
    );
  };

  retryChannelSubscription(envelope: any): void {
    throw new Error(
      'Cannot call getNumberOfUnreadMessages as pubnub has not been instantiated',
    );
  }
}
