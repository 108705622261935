import { KeyGenerator } from '@ginger.io/vault-core/dist/crypto';
import {
  crypto_box_NONCEBYTES,
  crypto_box_SEEDBYTES,
  crypto_secretbox_KEYBYTES,
  crypto_secretbox_NONCEBYTES,
  crypto_sign_SEEDBYTES,
  randombytes_buf_deterministic,
  ready,
} from 'libsodium-wrappers';

export class StubKeyGenerator implements KeyGenerator {
  private seed = new Uint8Array(32);

  async generateEncryptionKey(): Promise<Uint8Array> {
    await ready;
    return this.key(crypto_secretbox_KEYBYTES);
  }

  async generateSymmetricNonce(): Promise<Uint8Array> {
    await ready;
    return this.key(crypto_secretbox_NONCEBYTES);
  }

  async generateAsymmetricNonce(): Promise<Uint8Array> {
    await ready;
    return this.key(crypto_box_NONCEBYTES);
  }

  async generateEncryptionKeyPairSeed(): Promise<Uint8Array> {
    await ready;
    return this.key(crypto_box_SEEDBYTES);
  }

  async generateSigningKeyPairSeed(): Promise<Uint8Array> {
    await ready;
    return this.key(crypto_sign_SEEDBYTES);
  }

  async generateRandomPassword(byteLength: number): Promise<Uint8Array> {
    await ready;
    return this.key(byteLength);
  }

  private key(size: number): Uint8Array {
    return randombytes_buf_deterministic(size, this.seed);
  }
}
