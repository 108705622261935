import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MUIPopover from '@mui/material/Popover';
import { IconActionBarItem } from 'app/appointments/IconActionBarItem';
import { Loader } from 'shared-components/loader/Loader';
import { useLogger } from 'app/state/log/useLogger';
import { capitalize } from 'lodash';
import React, { MouseEvent, useState } from 'react';

import styles from './DocumentGridActionButton.module.scss';

type Props = {
  onClick: (noteType: Action) => Promise<void>;
};

export enum Action {
  EDIT = 'EDIT',
  DOWNLOAD = 'DOWNLOAD',
  DELETE = 'DELETE',
  PRINT = 'PRINT',
}

export function DocumentGridActionButton(props: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [loading, setLoading] = useState(false);
  const logger = useLogger();

  const onClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onItemClick = async (action: Action) => {
    try {
      setOpenPopover(false);
      setLoading(true);
      await props.onClick(action);
    } catch (error) {
      logger.error(
        new Error('DocumentGridActionButton: Unable to perform action'),
        { action, error },
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader className={styles.loader} size={10} topMargin={false} />;
  }

  return (
    <>
      <div role="presentation" onClick={onClick}>
        <IconActionBarItem
          testId="actionBtn"
          onClick={() => setOpenPopover(true)}
          title="More"
          Icon={MoreHorizIcon}
        />
      </div>
      <MUIPopover
        id="popover"
        open={openPopover}
        anchorEl={anchorEl}
        onClose={() => setOpenPopover(false)}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <ActionDropdown onClick={onItemClick} />
      </MUIPopover>
    </>
  );
}

export function ActionDropdown(props: { onClick: (action: Action) => void }) {
  const links = Object.entries(Action).map(([label, value]) => (
    <div
      role="presentation"
      key={label}
      data-testid={`select-${label}`}
      className={styles.item}
      onClick={(e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        props.onClick(value as Action);
      }}
    >
      {capitalize(label)}
    </div>
  ));

  return <div className={styles.root}>{links}</div>;
}
