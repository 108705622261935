import styles from 'app/coach/chat/messages/ThumbnailMessage.module.scss';
import Tooltip from 'shared-components/tooltip/Tooltip';
import React from 'react';

interface ThumbnailMessageProps {
  thumbnail: string;
  title: string;
  category: string;
  dateAndTime: string;
}

export const ThumbnailMessage = ({
  thumbnail,
  title,
  category,
  dateAndTime,
}: ThumbnailMessageProps) => {
  const style = {
    backgroundImage: `url(${thumbnail})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '211px',
  };
  return (
    <Tooltip
      classes={{ popper: styles.popper, tooltip: styles.tooltip }}
      title={dateAndTime}
      enterDelay={0}
      enterTouchDelay={0}
      placement="bottom-end"
    >
      <div className={styles.container}>
        <div className={styles.thumbnail} style={style} />
        <div className={styles.details}>
          <div className={styles.category}>{category}</div>
          <div className={styles.title}>{title}</div>
        </div>
      </div>
    </Tooltip>
  );
};
