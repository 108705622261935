import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Amendment } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/Amendment';
import { Metadata } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { KeyGenerator } from '@ginger.io/vault-core/dist/crypto';
import { VaultItem } from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import {
  getClinicalCareTeamGroupId,
  getCoachingTeamGroupId,
  getVaultWebUserId,
} from '@ginger.io/vault-core/dist/IdHelpers';
import {
  CreateVaultItemInputParams,
  UpdateVaultItemInputParams,
  VaultAPI,
  VaultAuditLogEvent,
  VaultItemPermissions,
  VaultSystemName,
} from '@ginger.io/vault-ui';
import { createCollaborationChatMessage } from 'app/collaboration/createCollaborationChatMessage';
import { VaultItemWithId } from '@ginger.io/vault-ui/dist/api/VaultAPI';
import moment from 'moment';
import { AmendmentWithAuditLog } from 'app/notes-ui/shared/amendments/types';
import {
  GetAppointmentById,
  GetAppointmentById_getAppointmentById,
  GetAppointmentById_getAppointmentById as Appointment,
  GetAppointmentByIdVariables,
} from 'app/vault/generated/GetAppointmentById';
import { getAppointmentById } from 'app/vault/queries';
import {
  getItemCreatedEvent,
  clinicalNoteSubsectionInputToVaultItem,
} from './utils';
import { VaultIds } from './VaultIds';
import {
  CoachClinicianCollaborationChatMessage,
  CoachClinicianCollaborationChatMessage_Author as Author,
  CoachClinicianCollaborationChatMessage_Author_AuthorType as AuthorType,
  CoachClinicianCollaborationChatMessage_GeneratedFrom as GeneratedFrom,
  CoachClinicianCollaborationChatMessage_Version as Version,
} from '@ginger.io/vault-care-collaboration/dist/generated/protobuf-schemas/vault-care-collaboration/CoachClinicianCollaborationChatMessage';
import { SafetyIntake } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyIntake';
import { Safety as SafetyProgress } from '@ginger.io/vault-clinical-notes/src/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyProgress';
import {
  CollaborationPlan,
  CollaborationPlan_GoalType as GoalType,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/CollaborationPlan';
import { labelFromEnumValue } from 'utils/notes';
import { ChatMessage } from 'app/collaboration/type';
import { Safety } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyProgress';
import { Clock } from 'app/vault/VaultTokenStorage';
import { Base64 } from '@ginger.io/vault-core/dist/crypto/Base64';
import { DeleteVaultItemsMutation } from '@ginger.io/vault-ui/src/generated/graphql';
import gql from 'graphql-tag';
import { SubsectionType } from './ShareableSubsectionTypes';
import { SafetyPlan } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/safety/SafetyPlan';
import { TreatmentGoal } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/TreatmentGoal';
import { NoteType } from 'generated/globalTypes';
import Messages from 'i18n/en/vault.json';

export abstract class ClinicalNotesAPI<T, U extends { name: string }> {
  protected abstract ids: typeof VaultIds;
  protected abstract sectionNames: Record<string, string>;
  abstract amendmentSectionName: string;
  protected vaultAPI: VaultAPI;

  public constructor(
    public apollo: ApolloClient<NormalizedCacheObject>,
    private keyGenerator: KeyGenerator,
    private clock: Clock = { now: () => new Date() },
  ) {
    this.vaultAPI = new VaultAPI(apollo, keyGenerator);
  }

  abstract getNote(appointmentId: string): Promise<T>;

  protected abstract encodeSection(data: U): VaultItem;

  protected abstract encodeMetadata(metadata: Metadata): VaultItem;

  abstract deleteDraftNote(
    userId: string,
    appointment: Appointment,
    note: T,
  ): Promise<DeleteVaultItemsMutation>;

  async getAmendments(
    appointmentId: string,
    timestampComparator = sortCreationTimeDesc,
  ): Promise<AmendmentWithAuditLog[]> {
    const vaultItemsWithEvents = await this.getAmendmentsSectionVaultItems(
      appointmentId,
    );
    const itemsWithEvents = vaultItemsWithEvents.map(
      ({ vaultItem, auditLog }) => {
        const amendment = Amendment.decode(vaultItem.item.data);
        return { amendment, auditLog };
      },
    );
    //TODO: remove this once Vault supports returning these in timestamp-sorted order
    itemsWithEvents.sort(timestampComparator);

    return itemsWithEvents;
  }

  async createAmendment(
    userId: string,
    appointmentId: string,
    amendment: U,
  ): Promise<void> {
    const itemId = this.ids.amendmentsSection();
    const tags = this.ids.sectionTags(userId, appointmentId);
    tags.push(
      this.ids.amendmentsSectionTag(appointmentId, this.amendmentSectionName),
    );

    await this.vaultAPI.createVaultItems([
      {
        itemId,
        vaultItem: this.encodeSection(amendment),
        tags,
        groupsToShareWith: [
          await this.getClinicalCareTeamGroupId(appointmentId),
        ],
        systemToShareWith: VaultSystemName.ClinicalNotesSyncProcess,
        permissions: VaultItemPermissions.ReadOnly,
      },
    ]);
  }

  async createDraftNoteSection(
    userId: string,
    appointment: Appointment,
    section: U,
    metadata?: Metadata,
    allowGroupWriteAccess = false,
  ): Promise<void> {
    const {
      id: appointmentId,
      member: { id: memberId },
    } = appointment;

    const items: CreateVaultItemInputParams[] = [
      {
        itemId: this.ids.section(appointmentId, section.name),
        tags: this.ids.sectionTags(userId, appointmentId),
        vaultItem: this.encodeSection(section as U),
        permissions: allowGroupWriteAccess
          ? VaultItemPermissions.WritableByAll
          : VaultItemPermissions.Writable,
        groupsToShareWith: [getClinicalCareTeamGroupId(memberId)],
      },
    ];

    if (metadata) {
      items.push({
        itemId: this.ids.metadata(appointmentId),
        vaultItem: this.encodeMetadata(metadata),
        tags: this.ids.metadataTags(appointmentId),
        // metadata is used by web to track draft/locked status so we share it immediately
        systemToShareWith: VaultSystemName.ClinicalNotesSyncProcess,
        permissions: allowGroupWriteAccess
          ? VaultItemPermissions.WritableByAll
          : VaultItemPermissions.Writable,
        groupsToShareWith: [getClinicalCareTeamGroupId(memberId)],
      });
    }

    await this.vaultAPI.createVaultItems(items);
  }

  async createDraftNoteSections(
    userId: string,
    appointment: Appointment,
    sections: U[],
    metadata?: Metadata,
    allowGroupWriteAccess = false,
  ): Promise<void> {
    const {
      id: appointmentId,
      member: { id: memberId },
    } = appointment;
    const items: CreateVaultItemInputParams[] = sections.map((section) => ({
      itemId: this.ids.section(appointmentId, section.name),
      tags: this.ids.sectionTags(userId, appointmentId),
      vaultItem: this.encodeSection(section),
      permissions: allowGroupWriteAccess
        ? VaultItemPermissions.WritableByAll
        : VaultItemPermissions.Writable,
      groupsToShareWith: [getClinicalCareTeamGroupId(memberId)],
    }));

    if (metadata) {
      items.push({
        itemId: this.ids.metadata(appointmentId),
        vaultItem: this.encodeMetadata(metadata),
        tags: this.ids.metadataTags(appointmentId),
        // metadata is used by web to track draft/locked status so we share it immediately
        systemToShareWith: VaultSystemName.ClinicalNotesSyncProcess,
        permissions: allowGroupWriteAccess
          ? VaultItemPermissions.WritableByAll
          : VaultItemPermissions.Writable,
        groupsToShareWith: [getClinicalCareTeamGroupId(memberId)],
      });
    }

    await this.vaultAPI.createVaultItems(items);
  }

  async updateDraftNoteSection(
    userId: string,
    appointment: Appointment,
    section: U,
    updatedMetadata?: Metadata,
    allowGroupWriteAccess = false,
  ): Promise<void> {
    const {
      id: appointmentId,
      member: { id: memberId },
    } = appointment;
    const groupId = await Base64.hash(getClinicalCareTeamGroupId(memberId));
    const permissions = allowGroupWriteAccess
      ? VaultItemPermissions.WritableByAll
      : VaultItemPermissions.Writable;
    const items: UpdateVaultItemInputParams[] = [
      {
        itemId: this.ids.section(appointmentId, section.name),
        tags: this.ids.sectionTags(userId, appointmentId),
        vaultItem: this.encodeSection(section as U),
        groupId,
        permissions,
      },
    ];

    if (updatedMetadata) {
      const encodedMetadata = this.encodeMetadata(updatedMetadata);

      items.push({
        itemId: this.ids.metadata(appointmentId),
        vaultItem: encodedMetadata,
        groupId,
        permissions,
        tags: [],
      });
    }

    await this.vaultAPI.updateVaultItems(items);
  }

  async updateNoteMetadata(
    metadata: Metadata,
    memberId: string,
    allowGroupWriteAccess = false,
  ): Promise<void> {
    const encodedMetadata = this.encodeMetadata(metadata);
    await this.vaultAPI.updateVaultItems([
      {
        itemId: this.ids.metadata(metadata.appointmentId),
        vaultItem: encodedMetadata,
        permissions: allowGroupWriteAccess
          ? VaultItemPermissions.WritableByAll
          : VaultItemPermissions.Writable,
        groupId: await Base64.hash(getClinicalCareTeamGroupId(memberId)),
        tags: [],
      },
    ]);
  }

  async lockNoteSections(appointment: Appointment): Promise<void> {
    const {
      id: appointmentId,
      member: { id: memberId },
    } = appointment;
    const ids = [this.ids.metadata(appointmentId)];

    Object.values(this.sectionNames).forEach((section) => {
      // Amendments are locked and shared as soon as they are added, so we don't need to lock them here when we lock
      // other note sections.
      if (section !== this.amendmentSectionName) {
        ids.push(this.ids.section(appointmentId, section));
      }
    });

    const groupId = await Base64.hash(getClinicalCareTeamGroupId(memberId));
    await this.vaultAPI.shareVaultItems(
      {
        itemIds: ids,
        setItemPermissionsToReadOnly: true,
        groupIds: [],
        systemNames: [VaultSystemName.ClinicalNotesSyncProcess],
      },
      groupId,
    );
  }

  /**
  @description These sections of Clinical Notes are split into separate VaultItems that
  are shared with both the clinical care team and coaching care team.
  */
  async createShareableSubsections(
    appointment: Appointment,
    noteType: NoteType,
    sections: {
      [subsectionType: string]:
        | SafetyPlan
        | TreatmentGoal[]
        | string
        | undefined;
    },
  ) {
    const {
      member: { id: memberId },
    } = appointment;
    const groupsAndPermissions = {
      groupsToShareWith: [
        getClinicalCareTeamGroupId(memberId),
        getCoachingTeamGroupId(memberId),
      ],
      permissions: VaultItemPermissions.ReadOnly,
    };

    const items: CreateVaultItemInputParams[] = [];

    Object.keys(sections).forEach((subsectionType) => {
      const vaultItem = clinicalNoteSubsectionInputToVaultItem({
        appointment,
        subsectionType: subsectionType as SubsectionType,
        data: sections[subsectionType as SubsectionType],
        noteType,
      });

      if (vaultItem) {
        items.push({
          itemId: getShareableSubsectionItemId(
            appointment,
            subsectionType as SubsectionType,
          ),
          tags: getShareableSubsectionsTags(
            appointment,
            subsectionType as SubsectionType,
          ),
          vaultItem,
          ...groupsAndPermissions,
        });
      }
    });

    if (items.length) {
      // todo: replace with createVaultItemsInBatch https://app.asana.com/0/1201082158239874/1204017937014215/f
      await Promise.all(
        items.map((item) => this.vaultAPI.createVaultItems([item])),
      );
    }
  }

  async getAppointment(appointmentId: string): Promise<Appointment> {
    const { data } = await this.apollo.query<
      GetAppointmentById,
      GetAppointmentByIdVariables
    >({
      query: getAppointmentById,
      variables: { id: appointmentId },
    });
    if (!data) {
      throw new Error(
        `${Messages.memberNotFoundForAppointment} ${appointmentId}`,
      );
    }
    return data.getAppointmentById;
  }

  async sendChatCollaborationAutoMessage(
    appointment: Appointment,
    safety: SafetyIntake | SafetyProgress | null,
    collaborationPlan: CollaborationPlan | null,
    timezone: string | null,
  ) {
    const memberId = appointment.member.id;
    const promises: Promise<ChatMessage>[] = [];
    if (safety) {
      promises.push(
        createCollaborationChatMessage(
          this.apollo,
          getSafetyPlanMessage(appointment, safety, this.clock, timezone),
          memberId,
          this.keyGenerator,
        ),
      );
    }
    if (collaborationPlan) {
      promises.push(
        createCollaborationChatMessage(
          this.apollo,
          getCollaborationPlanMessage(
            appointment,
            collaborationPlan,
            this.clock,
            timezone,
          ),
          memberId,
          this.keyGenerator,
        ),
      );
    }
    await Promise.all(promises);
  }

  protected async getClinicalCareTeamGroupId(
    appointmentId: string,
  ): Promise<string> {
    const appointment = await this.getAppointment(appointmentId);
    return getClinicalCareTeamGroupId(appointment.member.id);
  }

  private async getAmendmentsSectionVaultItems(
    appointmentId: string,
  ): Promise<{ vaultItem: VaultItemWithId; auditLog: VaultAuditLogEvent[] }[]> {
    const sectionTag = this.ids.amendmentsSectionTag(
      appointmentId,
      this.amendmentSectionName,
    );
    const groupId = await this.getClinicalCareTeamGroupId(appointmentId);
    const items = await this.vaultAPI.getVaultItemsByTag(sectionTag, {
      groupId,
    });
    const promises = items.map(async (vaultItem) => {
      // TODO: This results in n+1 calls. Ideally, we'd be able to fetch Vault Items by tag along with their logs.
      const auditLog = await this.vaultAPI.getAuditLogForVaultItem(
        vaultItem.id,
        { hashItemIds: false, groupId },
      );
      return { vaultItem, auditLog };
    });

    return await Promise.all(promises);
  }

  protected updateAppointmentCache(appointmentId?: string) {
    const legacyCachedAppointmentId = `Appointment:${appointmentId}`;
    const legacyCachedAppointment = this.apollo.readFragment({
      id: legacyCachedAppointmentId,
      fragment: gql`
        fragment CachedAppt on Appointment {
          id
        }
      `,
    });
    if (legacyCachedAppointment) {
      this.apollo.writeFragment({
        id: legacyCachedAppointmentId,
        fragment: gql`
          fragment CachedAppt on Appointment {
            clinicalNote {
              id
            }
          }
        `,
        data: {
          clinicalNote: null,
        },
      });
    }

    const cachedAppointmentId = `ClinicalAppointment:${appointmentId}`;
    const cachedAppointment = this.apollo.readFragment({
      id: cachedAppointmentId,
      fragment: gql`
        fragment CachedAppt on ClinicalAppointment {
          id
        }
      `,
    });
    if (cachedAppointment) {
      this.apollo.writeFragment({
        id: cachedAppointmentId,
        fragment: gql`
          fragment CachedAppt on ClinicalAppointment {
            clinicalNote {
              id
            }
          }
        `,
        data: {
          clinicalNote: null,
        },
      });
    }
  }
}

/**
 * Comparator for sorting objects with an auditLog by creation time descending
 */
function sortCreationTimeDesc(
  element1: { auditLog: VaultAuditLogEvent[] },
  element2: { auditLog: VaultAuditLogEvent[] },
): number {
  const item1Creation = getItemCreatedEvent(element1.auditLog);
  const item2Creation = getItemCreatedEvent(element2.auditLog);

  if (!item1Creation || !item2Creation) {
    throw new Error(Messages.creationAuditLog);
  }

  const item1CreationTime = item1Creation.timestamp;
  const item2CreationTime = item2Creation.timestamp;
  const datetime1 = moment(new Date(item1CreationTime)).unix();
  const datetime2 = moment(new Date(item2CreationTime)).unix();
  return datetime2 - datetime1;
}

function getSafetyPlanMessage(
  appointment: GetAppointmentById_getAppointmentById,
  safety: SafetyIntake | Safety,
  clock: Clock,
  tz: string | null,
): CoachClinicianCollaborationChatMessage {
  const {
    member: { id: memberId },
    clinician: { userId },
    start,
  } = appointment;

  // We currently check the subject field when consuming chat collab sqs message in listener.
  // When modifying this field we need to update the message consumer in listener too.
  const subject = moment(start)
    .tz(tz || 'UTC')
    .format(`[Safety plan for] MMM D, YYYY [session @] h:mm a [(${tz})]`);
  const body = safety.safetyPlan?.description ?? '';

  const author: Author = {
    id: getVaultWebUserId(userId),
    type: AuthorType.clinician,
  };
  return {
    memberId,
    subject,
    body,
    author,
    createdAt: moment(clock.now()).utc().toISOString(),
    version: Version.v0,
    generatedFrom: GeneratedFrom.clinical_note_safety_plan,
    generatedFromVaultItemId: '',
    requireTimelyReview: false,
  };
}

function getCollaborationPlanMessage(
  appointment: GetAppointmentById_getAppointmentById,
  collaborationPlan: CollaborationPlan,
  clock: Clock,
  tz?: string | null,
): CoachClinicianCollaborationChatMessage {
  const {
    member: { id: memberId },
    clinician: { userId },
    start,
  } = appointment;

  // We currently check the subject field when consuming chat collab sqs message in listener.
  // When modifying this field we need to update the message consumer in listener too.
  const subject = moment(start)
    .tz(tz || 'UTC')
    .format(
      `[Collaboration plan for] MMM D, YYYY [session @] h:mm a [(${tz})]`,
    );

  const body = collaborationPlan.goal
    .filter((_) => _.goalType !== GoalType.undefined_goal_type)
    .map((goal) => {
      const title = goal.label;
      const type =
        goal.goalType !== GoalType.other
          ? labelFromEnumValue(GoalType, goal.goalType)
          : goal.otherGoalTypeDescription;
      const steps = goal.recommendedSteps;
      return `${title}\nGoal Type: ${type}\n${goal.recommendedStepsLabel}: ${steps}`;
    })
    .join('\n\n');

  const author: Author = {
    id: getVaultWebUserId(userId),
    type: AuthorType.clinician,
  };
  return {
    memberId,
    subject,
    body,
    author,
    createdAt: moment(clock.now()).utc().toISOString(),
    version: Version.v0,
    generatedFrom: GeneratedFrom.clinical_note_collaboration_plan,
    generatedFromVaultItemId: '',
    requireTimelyReview: false,
  };
}

/**
@description Gets the VaultItem ID to use for a clinical note subsection. These "subsections" of clinical notesare shared with the coaching care team as well, whereas the broader clinical notes are not shared in their entirety with the coaching team.
*/
export function getShareableSubsectionItemId(
  appointment: Appointment,
  subsectionType: SubsectionType,
): string {
  const {
    id: appointmentId,
    member: { id: memberId },
  } = appointment;

  return `member-chart-${memberId}-shared-notes-${subsectionType}-${appointmentId}`;
}

export function getShareableSubsectionsTags(
  appointment: Appointment,
  noteType: SubsectionType,
) {
  const {
    id: appointmentId,
    member: { id: memberId },
    clinician: { userId },
  } = appointment;

  return [
    `member-chart-${memberId}-coach-notes`, // this tag is used by Coach Notes to retrieve all Coach Notes and subsections of Clinical Notes that are shared with coaches
    `member-chart-${memberId}-${userId}`,
    `member-chart-${memberId}-${noteType}`,
    `member-chart-${memberId}-${appointmentId}`,
  ];
}
