import {
  CreateMemberCoachingSessionInput,
  UpdateMemberCoachingSessionInput,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { InfoOutlined, ToggleOff, ToggleOn } from '@mui/icons-material';
import { useAppState } from 'app/state';
import { ReactComponent as LeftArrow } from 'assets/arrows/left.svg';
import React, { useState } from 'react';
import { Button } from 'shared-components/button/Button';
import ChipCard from 'shared-components/Card/ChipCard';
import Tooltip from 'shared-components/tooltip/Tooltip';
import { classNameCombiner } from 'utils';

import AvailabilityList from './AvailabilityList';
import styles from './AvailabilitySearch.module.scss';
import CreationButtons from './CreationButtons';
import { useCoachingSessionForm } from './hooks/useCoachingSessionForm';
import schedulerCardStyles from './SchedulerCard.module.scss';
import { Availability, SessionFormat } from './types';
import {
  formatSessionInfo,
  MAX_CONCURRENT_CHAT_SESSIONS,
  MAX_CONCURRENT_VIDEO_SESSIONS,
} from './utils';

export interface Props {
  memberId: string;
  onCreate: (input: CreateMemberCoachingSessionInput) => void;
  onUpdate: (input: UpdateMemberCoachingSessionInput) => void;
  onBack: () => void;
  onClose: () => void;
  coachTimezone: string;
  memberTimezone: string;
}

export const AvailabilityTooltip = ({
  maxConcurrentSessions,
}: {
  maxConcurrentSessions: number;
}) => (
  <ul className={styles.availabilityTooltip}>
    <li>
      Every time has a capacity of <strong>{maxConcurrentSessions}</strong>{' '}
      simultaneous sessions.
    </li>
    <li>You can overbook any time and exceed capacity.</li>
    <li>Members can only self-schedule times under capacity.</li>
  </ul>
);

const AvailabilitySearch = (props: Props) => {
  const {
    memberId,
    coachTimezone,
    memberTimezone,
    onCreate,
    onUpdate,
    onBack,
    onClose,
  } = props;
  const { onClickSave, onClickEdit } = useCoachingSessionForm({
    coachTimeZone: coachTimezone,
    memberId,
    memberTimeZone: memberTimezone,
    onCreate,
    onUpdate,
  });
  const {
    coachingSession,
    coachAvailabilitySchedule: availabilities,
    selectedSessionTimes,
  } = useAppState(
    ({
      scheduler: {
        coachingSession,
        coachAvailabilitySchedule,
        selectedSessionTimes,
      },
    }) => ({
      coachAvailabilitySchedule,
      coachingSession,
      selectedSessionTimes,
    }),
  );
  const [viewAllTimes, setViewAllTimes] = useState<boolean>(false);

  const handleToggleClick = () => {
    setViewAllTimes((prevState) => !prevState);
  };

  const sessionInfo = coachingSession
    ? formatSessionInfo(coachingSession, coachTimezone)
    : null;

  const toggleButton: JSX.Element = viewAllTimes ? (
    <ToggleOn />
  ) : (
    <ToggleOff className={styles.disabled} />
  );

  const isVideoAvailabilitySearch =
    coachingSession?.sessionFormat === SessionFormat.VIDEO;
  const maxConcurrentSessions = isVideoAvailabilitySearch
    ? MAX_CONCURRENT_VIDEO_SESSIONS
    : MAX_CONCURRENT_CHAT_SESSIONS;

  const displayAvailabilities: Availability[] = viewAllTimes
    ? availabilities
    : availabilities?.filter(
        (availability) =>
          availability.load === undefined ||
          availability.load < maxConcurrentSessions,
      ) ?? [];

  const hasAvailabilities = !!displayAvailabilities?.length;
  const enableSaveButton = hasAvailabilities && selectedSessionTimes;

  return (
    <ChipCard
      boxTitle="Scheduler"
      chip={
        <CreationButtons
          onClickEdit={onClickEdit}
          onClickSave={onClickSave}
          onClose={onClose}
          showSaveButton={true}
          disableSave={!enableSaveButton}
        />
      }
    >
      <div className={styles.container}>
        <button
          data-testid="backToAllButton"
          type="button"
          className={styles.backToSearchButton}
          onClick={onBack}
        >
          <LeftArrow width={16} height={16} className={styles.leftArrow} />
          Change search
        </button>
        {sessionInfo && (
          <div className={styles.sessionCard}>
            <h3 className={styles.title}>{sessionInfo.titleText}</h3>
            <div className={styles.description}>
              {sessionInfo.startDate}
              {sessionInfo.endDate ? ` - ${sessionInfo.endDate}` : ''}
            </div>
            <div className={styles.description}>
              {`${sessionInfo.numOfSessions} · ${sessionInfo.duration}`}
            </div>
          </div>
        )}
        <div className={styles.sessionCard}>
          <h4 className={styles.subtitle}>Select a time</h4>
          <div
            className={classNameCombiner([styles.description, styles.lighter])}
          >
            Your time | member&#39;s time
          </div>
          <div className={styles.toggleBtnContainer}>
            <button
              type="button"
              data-testid="availability-search-toggle-btn"
              className={styles.toggleButton}
              onClick={handleToggleClick}
            >
              {toggleButton}
            </button>
            <span className={styles.buttonText}>Show times at capacity</span>
            <Tooltip
              title={
                <AvailabilityTooltip
                  maxConcurrentSessions={maxConcurrentSessions}
                />
              }
              placement="top-end"
            >
              <InfoOutlined className={styles.info} />
            </Tooltip>
          </div>
          {hasAvailabilities && (
            <AvailabilityList
              availabilities={displayAvailabilities}
              coachTimezone={coachTimezone}
              memberTimezone={memberTimezone}
            />
          )}
        </div>
        {!hasAvailabilities && (
          <>
            <h3 className={styles.noAvailableTimes}>No available times</h3>
            <div
              className={classNameCombiner([styles.description, styles.bolder])}
            >
              Try changing the start date, cadence, or number of sessions. Or
              click the toggle above to schedule a time already at capacity.
            </div>
            <Button
              className={classNameCombiner([
                schedulerCardStyles.checkAvailability,
                schedulerCardStyles.withMargin,
              ])}
              size="small"
              onClick={onBack}
            >
              Change search
            </Button>
          </>
        )}
      </div>
    </ChipCard>
  );
};

export default AvailabilitySearch;
