import { FieldDefinitions, useForm } from '@ginger.io/react-use-form';
import { Field } from '@ginger.io/react-use-form/src/types';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import SaveIcon from '@mui/icons-material/Save';
import { TableRow } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { IconActionBarItem } from 'app/appointments/IconActionBarItem';
import React, { useState } from 'react';

import { PsychiatryHistoryCell } from './PsychiatryHistoryCell';
import styles from './PsychiatryHistoryTable.module.scss';

export type ColumnDefinition<T> = {
  key: keyof T;
  columnType: 'text' | 'number' | 'date' | 'textarea';
  label: string;
  placeholder?: string;
};

type Props<T> = {
  initialValue?: T;
  fieldDefs: FieldDefinitions<T>;
  disabled?: boolean;
  columnsDefinition: ColumnDefinition<T>[];
  isEditing?: boolean;
  onRemove: () => void;
  onChange: (value: T) => void;
  onSave: (value: T) => void;
  isNew?: boolean;
  testId?: string;
};

export function PsychiatryHistoryRow<T>(props: Props<T>) {
  const {
    initialValue,
    fieldDefs,
    disabled = false,
    columnsDefinition,
    onRemove,
    isNew = false,
  } = props;
  const { fields, getValue, validate } = useForm(fieldDefs, initialValue);
  const [isEditing, setIsEditing] = useState(isNew);

  const onSave = async () => {
    if (await validate()) {
      setIsEditing(false);
      props.onSave(getValue());
    }
  };

  const columns = columnsDefinition
    .filter(({ key }) => fields[key])
    .map(({ key, columnType, label, placeholder }) => (
      <PsychiatryHistoryCell
        key={key.toString()}
        testId={key.toString()}
        field={fields[key] as Field<any>}
        isEditing={isEditing}
        disabled={disabled}
        columnType={columnType}
        placeholder={placeholder}
        label={label}
        onChange={() => props.onChange(getValue())}
      />
    ));

  if (!disabled) {
    columns.push(
      <TableCell className={styles.actionCell} key="action-cell">
        <ActionButton
          isEditing={isEditing}
          isNew={isNew}
          onEditClick={() => setIsEditing(true)}
          onRemoveClick={onRemove}
          onSaveClick={onSave}
        />
      </TableCell>,
    );
  }
  return (
    <TableRow
      data-testid={props.testId}
      className={`${isEditing ? styles.rowEdit : styles.rowView}`}
    >
      {columns}
    </TableRow>
  );
}

type ActionButtonProps = {
  isEditing: boolean;
  isNew: boolean;
  onSaveClick: () => Promise<void>;
  onRemoveClick: () => void;
  onEditClick: () => void;
};

function ActionButton(props: ActionButtonProps) {
  const { isEditing, isNew, onRemoveClick, onSaveClick, onEditClick } = props;
  let Button;
  if (isEditing) {
    const Icon = isNew ? AddIcon : SaveIcon;
    Button = (
      <IconActionBarItem
        title="Save"
        Icon={Icon}
        testId="saveBtn"
        className={[styles.actionBtn, isNew ? styles.saveIcon : ''].join(' ')}
        onClick={onSaveClick}
        variation="compact"
      />
    );
  } else {
    Button = (
      <>
        <IconActionBarItem
          title="Edit"
          Icon={EditIcon}
          testId="editBtn"
          className={styles.actionBtn}
          onClick={onEditClick}
          variation="compact"
        />
        <IconActionBarItem
          title="Delete"
          Icon={DeleteIcon}
          testId="removeBtn"
          className={styles.actionBtn}
          onClick={onRemoveClick}
          variation="compact"
        />
      </>
    );
  }

  return <span className={styles.actionBtnGroup}>{Button}</span>;
}
