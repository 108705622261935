import { TableCell } from '@mui/material';
import { OutOfSessionOrTerminationNote } from 'app/vault/hooks/NonAppointments/useOutOfSessionAndTerminationNotes';
import React from 'react';

import { NoteStatusCard } from './NoteStatusCard';
import { NoteTypeCard } from './NoteTypeCard';

export const NoteRow = (props: {
  note: OutOfSessionOrTerminationNote;
  patientId: string;
  dataTestId?: string;
  onClick?: (note: OutOfSessionOrTerminationNote) => Promise<void>;
}) => {
  const { note, onClick, patientId, dataTestId } = props;

  return (
    <>
      <TableCell data-testid={dataTestId}>
        <NoteTypeCard note={note} />
      </TableCell>
      <TableCell />
      <TableCell>
        <NoteStatusCard note={note} patientId={patientId} onClick={onClick} />
      </TableCell>
    </>
  );
};
