import { PubnubStatus } from 'pubnub';

interface PubNubError extends Error {
  readonly status: PubnubStatus;
  readonly name: string;
}

/**
 * "Error Category" values that PubNub returns on its error objects
 * https://www.pubnub.com/docs/sdks/javascript/status-events
 */
export enum PubNubErrorCategory {
  PN_NETWORK_DOWN = 'PNNetworkDownCategory',
  // This error typically happens due to client network connection issues (e.g. flaky internet connection).
  NETWORK_ISSUES = 'PNNetworkIssuesCategory',
  ACCESS_DENIED = 'PNAccessDeniedCategory',
  MALFORMED_RESPONSE = 'PNMalformedResponseCategory',
  BAD_REQUEST = 'PNBadRequestCategory',
  DECRYPTION_ERROR = 'PNDecryptionErrorCategory',
  TIMEOUT = 'PNTimeoutCategory',
  REQUEST_MSG_COUNT_EXCEEDED = 'PNRequestMessageCountExceededCategory',
  UNKNOWN = 'PNUnknownCategory',
}

/**
 * Determines whether a given error object is a PubNub error of the given category
 * @param error
 * @param category
 * @returns boolean
 */
export function matchesPubNubErrorCategory(
  error: any,
  category: PubNubErrorCategory,
): boolean {
  return error?.status?.category === category;
}

export function isPubNubError(error: unknown): error is PubNubError {
  if (typeof error !== 'object' || error == null) {
    return false;
  }

  return 'name' in error && 'status' in error;
}

export function getPubErrorLogData(
  error: unknown,
): Record<string, unknown> | undefined {
  if (!isPubNubError(error)) return undefined;
  return {
    ...error.status,
    message: error.status.errorData?.message,
  };
}
