import Divider from '@mui/material/Divider';
import React from 'react';

import styles from './NoteTitle.module.scss';

export type Props = {
  'data-testid': string;
  label: string;
  subtext?: string;
};

export function NoteTitle(props: Props) {
  const { 'data-testid': dataTestId, label, subtext } = props;

  return (
    <div className={styles.container} id={dataTestId}>
      <div className={styles.container_label}>
        <h3 className={styles.label}>{label}</h3>
        <Divider className={styles.divider} />
      </div>
      {subtext && <h4 className={styles.subtext}>{subtext}</h4>}
    </div>
  );
}
