import React, { createContext, PropsWithChildren, useContext } from 'react';

import { PubnubAPIService } from './PubnubAPIService';

export const PubNubAPI = createContext<PubnubAPIService | null>(null);

export const PubNubProvider = ({
  children,
  pubnub,
}: PropsWithChildren<{
  pubnub: PubnubAPIService;
}>) => {
  return <PubNubAPI.Provider value={pubnub}>{children}</PubNubAPI.Provider>;
};

export function usePubNubAPI(): PubnubAPIService {
  const service = useContext(PubNubAPI);
  if (service) {
    return service;
  }
  throw new Error(
    `No PubNubAPI context found in your app's context. Did you forget to wrap your root component with PubNubAPI.Provider?`,
  );
}
