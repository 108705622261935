import {
  HomeworkSource,
  HomeworkStatus,
} from 'app/patients/tabs/content/types';
import {
  extractAssignmentIdFromUri,
  getHomeworkThumbnail,
} from 'app/patients/tabs/content/utils';
import {
  missingPropsErrorMessage,
  unrecognizedMessageError,
} from 'app/state/inbox/utils';
import { ILogger } from 'app/state/log/Logger';
import Pubnub from 'pubnub';

import {
  BOOKMARK_MESSAGE_TRANSFERED_TO_COACH,
  BOOKMARK_MESSAGE_TRANSFERED_TO_SCHEDULER,
  BOOKMARK_MESSAGE_TRANSFERED_TO_SUPPORT,
} from '../chat/strings';
import { ClinicalServicesLabels } from './clinicalServicesLabels';
import {
  AutoMessageRPC,
  BuildMessage,
  ContentRPC,
  DisplayableRPCs,
  InvalidMessageObject,
  MessagesBeforeCleanup,
  MessageStatus,
  MessageToDisplay,
  MessageType,
  RPC,
  RPCEnvelope,
  SenderType,
  TextMessage,
  TextMessageBeforePublishing,
  TextMessageEnvelope,
  ValidMessageObject,
} from './types';

export const enum BookmarkTagName {
  COACH = 'COACH',
  CONCIERGE = 'CONCIERGE',
  SCHEDULER = 'SCHEDULER',
}

// Maps a bookmark tag name to the message displayed in the chat transcript. Note that it is possible that we
// encounter a tag name not declared in BookmarkTagName. The logic to compose the message will handle that case.
const bookmarkTagToMessage: Record<BookmarkTagName, string> = {
  [BookmarkTagName.COACH]: BOOKMARK_MESSAGE_TRANSFERED_TO_COACH,
  [BookmarkTagName.SCHEDULER]: BOOKMARK_MESSAGE_TRANSFERED_TO_SCHEDULER,
  [BookmarkTagName.CONCIERGE]: BOOKMARK_MESSAGE_TRANSFERED_TO_SUPPORT,
};

export const isPubNubTruthy = (
  pubnub: Pubnub | undefined | null,
): pubnub is Pubnub => {
  return pubnub !== undefined && pubnub !== null;
};

export const buildMessage: BuildMessage = ({
  input,
  senderId,
  username,
  id,
}) => {
  const messageObject: TextMessageBeforePublishing = {
    id,
    message: input,
    oncall_listener_id: null,
    senderId,
    senderType: 'listener',
    username,
  };
  return messageObject;
};

export const makeSentMessage = (
  messageObj: TextMessageBeforePublishing,
  channel: string,
): MessageToDisplay => {
  const timestamp = Date.now();
  const timetoken = convertTimeStampToPubNubTimetoken(timestamp).toString();
  const { id, message } = messageObj;
  return {
    channel,
    id,
    message,
    status: MessageStatus.SENT,
    timetoken,
    type: MessageType.TEXT_FROM_LISTENER,
  };
};

export const isTextMessage = (
  envelope: TextMessageEnvelope | RPCEnvelope,
): envelope is TextMessageEnvelope => {
  return (
    doesObjectHaveProps(['message'], envelope) &&
    typeof envelope.message === 'object' &&
    envelope.message &&
    doesObjectHaveProps(['message'], envelope.message) &&
    typeof (envelope as TextMessageEnvelope).message.message === 'string' &&
    !('rpc' in envelope.message)
  );
};

export const isRPC = (
  envelope: TextMessageEnvelope | RPCEnvelope,
): envelope is RPCEnvelope => {
  return (
    doesObjectHaveProps(['message'], envelope) &&
    typeof envelope.message === 'object' &&
    envelope.message &&
    'rpc' in envelope.message &&
    envelope.message.rpc
  );
};

export const isValidCallerReadMessageRpc = (envelope: RPCEnvelope) => {
  return (
    envelope.message.rpc === 'update_read_state' &&
    'senderType' in envelope.message &&
    envelope.message.senderType === 'caller' &&
    'timetoken' in envelope &&
    envelope.timetoken
  );
};

export const isValidListenerReadMessageRpc = (
  envelope: RPCEnvelope,
): boolean => {
  return Boolean(
    envelope.message.rpc === 'update_read_state' &&
      'senderType' in envelope.message &&
      envelope.message.senderType === 'listener' &&
      'timetoken' in envelope &&
      envelope.timetoken,
  );
};

export const buildMessageToDisplayFromTextMessage = (
  envelope: TextMessageEnvelope,
): ValidMessageObject | InvalidMessageObject => {
  const {
    timetoken,
    message: { message, senderType, id, out_of_session: outOfSession },
    channel,
  } = envelope;
  switch (senderType) {
    case SenderType.LISTENER: {
      const type =
        'server_originated' in envelope.message &&
        envelope.message.server_originated
          ? MessageType.AUTO_MESSAGE
          : MessageType.TEXT_FROM_LISTENER;
      return {
        error: null,
        message: {
          channel,
          id,
          message,
          status: null,
          timetoken: timetoken.toString(),
          type,
        },
      };
    }
    case SenderType.CALLER: {
      return {
        error: null,
        message: {
          channel,
          id,
          message,
          status: null,
          timetoken: timetoken.toString(),
          type: outOfSession
            ? MessageType.OUT_OF_SESSION
            : MessageType.TEXT_FROM_MEMBER,
        },
      };
    }
    default: {
      const evelopeInString = JSON.stringify(envelope);
      const error = new Error(
        `Unknown sender type in a message: ${evelopeInString}`,
      );
      return { error, message: null };
    }
  }
};

export const canTextMessageBeDisplayed = (
  envelope: TextMessageEnvelope,
): { error: null; hasProps: true } | { error: Error; hasProps: false } => {
  const isSenderTypeInMessage = doesObjectHaveProps(
    ['senderType'],
    envelope.message,
  );
  if (!isSenderTypeInMessage.hasProps) return isSenderTypeInMessage;
  const isTimetokenInMessage = doesObjectHaveProps(['timetoken'], envelope);
  if (!isTimetokenInMessage.hasProps) return isTimetokenInMessage;
  return { error: null, hasProps: true };
};

export const doesObjectHaveProps = (
  props: string[],
  messageObject: TextMessageEnvelope | RPCEnvelope | TextMessage | RPC,
): { error: null; hasProps: true } | { error: Error; hasProps: false } => {
  const hasProps = props.every(
    (prop) =>
      messageObject &&
      prop &&
      typeof messageObject === 'object' &&
      prop in messageObject &&
      messageObject[prop],
  );
  if (!hasProps) {
    const propsInString = JSON.stringify(props);
    const err = new Error(`${missingPropsErrorMessage}: ${propsInString}`);
    return {
      error: err,
      hasProps: false,
    };
  }
  return { error: null, hasProps: true };
};

export const convertPubNubTimetokenToTimestamp = (
  timetoken: string | number,
) => {
  return Math.round(+timetoken / 10000);
};

export const convertTimeStampToPubNubTimetoken = (timestamp: number) => {
  return timestamp * 10000;
};

export function markLastMessageSeenByMember(messages: Array<MessageToDisplay>) {
  const lastMessageIndex = messages.length - 1;
  if (messages[lastMessageIndex].type === MessageType.TEXT_FROM_LISTENER) {
    const messagesWOTheLastOneAndStatuses = messages
      .slice(0, lastMessageIndex)
      .map((m) => ({ ...m, status: null }));
    const lastMessageWithUpdatedStatus = {
      ...messages[lastMessageIndex],
      status: MessageStatus.SEEN,
    };
    return [...messagesWOTheLastOneAndStatuses, lastMessageWithUpdatedStatus];
  }
  const indexOfLastListenerMessage = findLastIndexOfMessage(
    messages,
    (m: MessageToDisplay) => m.type === MessageType.TEXT_FROM_LISTENER,
  );

  if (indexOfLastListenerMessage >= 0) {
    return messages.map((m, index) =>
      index === indexOfLastListenerMessage
        ? {
            ...m,
            status: MessageStatus.SEEN,
          }
        : m,
    );
  }
  return messages;
}

export const convertRPCToMessage = (
  envelope: RPCEnvelope,
  logger: ILogger,
): ValidMessageObject | InvalidMessageObject => {
  // we don't know if rpcs always have props that we refer to, so we validate each prop we refer to prevent an error
  const error = validateEnvelope(envelope);
  if (error) {
    return { error, message: null };
  }
  const {
    timetoken,
    channel,
    message: { id, extra_params },
  } = envelope;
  let messageText: string | undefined;
  const rpc = envelope.message.rpc as AutoMessageRPC;
  switch (rpc) {
    case AutoMessageRPC.BOOKMARK_CREATED: {
      const { extra_param, messageGetter } = autoMessagesDict[
        AutoMessageRPC.BOOKMARK_CREATED
      ];
      const { error: extraParamsPropsError } = doesObjectHaveProps(
        [extra_param],
        envelope.message.extra_params,
      );
      if (!extraParamsPropsError) {
        messageText = messageGetter(
          extra_params.tag_name,
          extra_params.creator_name,
        );
      }
      break;
    }

    case AutoMessageRPC.LINK_CLICKED:
    case AutoMessageRPC.CLINICAL_INTEREST:
    case AutoMessageRPC.PROCESS_INTAKE_LINK: {
      const { extra_param, messageGetter } = autoMessagesDict[rpc];
      messageText = messageGetter(extra_params[extra_param]);

      break;
    }

    default:
      // The rpc is not one that we know how to display. It's either one that is not displayable or, if it is
      // in DisplayableRPCs, then a case block for the rpc is missing above.
      if (DisplayableRPCs.has(rpc)) {
        // The rpc should be displayable, but there was not a corresponding getLabelFor...() above
        logger.warning(
          `Received an RPC that should be displayable, but ended up in a default block of the convertRPCToMessage switch. This is unexpected`,
          { envelope },
        );
        // Display the rpc so that we at least don't lose any info. It may not be pretty, but at least we won't miss
        // an RPC that should be displayed.
        messageText = `RPC: ${rpc}`;
      } else {
        // This is a non-displayable RPC message
        return { error: null, message: null };
      }
      break;
  }

  if (messageText)
    return {
      error: null,
      message: {
        channel,
        id,
        message: messageText,
        status: null,
        timetoken: timetoken.toString(),
        type: MessageType.RPC,
      },
    };

  return {
    error: new Error(
      `RPC missed some extra_params. RPC: ${JSON.stringify(envelope)}`,
    ),
    message: null,
  };
};

function getLabelForBookmarkCreated(
  tagName: string,
  creatorName: string | undefined,
) {
  // I'm surprised it allows me to do this since this can introduce an invalid value for the enum.
  const bookmarkTag = tagName as BookmarkTagName;
  // If a newer version of Typescript tightens this up, we can always revert
  // to a switch/case enumerating all the enum keys.
  let message = bookmarkTagToMessage[bookmarkTag];

  if (!message) {
    message = `Bookmark ${tagName} created`;
  }
  if (creatorName) {
    message = `${message}, via ${creatorName}`;
  }
  return message;
}

function getLabelForLinkClicked(linkUrl?: string): string {
  return `Tapped ${linkUrl ?? 'a link'}`;
}

function getLabelForClinicalInterest(message?: string): string {
  return message || ClinicalServicesLabels.clinicalInterestLabel;
}

function getLabelForDeclinedClinicalServices(message?: string): string {
  return message || ClinicalServicesLabels.declinedClinicalServicesLabel;
}

export const tryToHandleAsTextMessage = (
  envelope: TextMessageEnvelope,
): ValidMessageObject | InvalidMessageObject => {
  const { hasProps, error } = canTextMessageBeDisplayed(envelope);
  if (!hasProps) {
    return { error, message: null };
  }
  return buildMessageToDisplayFromTextMessage(envelope);
};

export const autoMessagesDict: {
  [key in AutoMessageRPC]: {
    extra_param: string;
    messageGetter: (firstSt: string, secindStr?: string) => string;
  };
} = {
  [AutoMessageRPC.BOOKMARK_CREATED]: {
    extra_param: 'tag_name',
    messageGetter: getLabelForBookmarkCreated,
  },
  [AutoMessageRPC.LINK_CLICKED]: {
    extra_param: 'url',
    messageGetter: getLabelForLinkClicked,
  },
  [AutoMessageRPC.CLINICAL_INTEREST]: {
    extra_param: 'message',
    messageGetter: getLabelForClinicalInterest,
  },
  [AutoMessageRPC.PROCESS_INTAKE_LINK]: {
    extra_param: 'message',
    messageGetter: getLabelForDeclinedClinicalServices,
  },
};

export const clearCorruptedMessages = (
  messages: MessagesBeforeCleanup[],
  channelId: string,
  logger: ILogger,
): MessageToDisplay[] => {
  const corruptedMessages: any = [];

  const validMessages = messages
    .filter((messageAndError): messageAndError is ValidMessageObject => {
      const { error, message } = messageAndError;

      if (error) {
        corruptedMessages.push(error);
        return false;
      }
      if (message) {
        return Boolean(message);
      }
      // it means it's an rpc that shouldn't be displayed as a message and it's expected
      return false;
    })
    .map((messageObj) => messageObj.message);

  const numberOfCorruptedMessages = corruptedMessages.length;

  if (numberOfCorruptedMessages > 0) {
    // we don't log the messages themselves since they contain protected info
    logger.warning(`Found corrupted messages`, {
      channelId,
      numberOfCorruptedMessages,
    });
  }

  return validMessages;
};

export const formatAndFilterHistory = (
  rawMessages: Array<TextMessageEnvelope | RPCEnvelope>,
  channelId: string,
  logger: ILogger,
) => {
  const formattedMessages = rawMessages.map((envelope):
    | ValidMessageObject
    | InvalidMessageObject => {
    const { hasProps, error } = doesObjectHaveProps(
      ['channel', 'message'],
      envelope,
    );
    if (!hasProps) {
      return { error, message: null };
    }
    // check if it's a text message
    if (isTextMessage(envelope)) {
      const { message, error } = tryToHandleAsTextMessage(envelope);
      if (error) {
        return { error, message: null };
      }
      return { error: null, message };
    }
    // check if it's an rpc
    if (isRPC(envelope)) {
      const rpcType = envelope.message.rpc;
      switch (rpcType) {
        case ContentRPC.GIO_DEEPLINK_BUTTON: {
          const { message, error } = convertRPCToContentButton(envelope);
          if (error) {
            return { error, message: null };
          }
          return { error: null, message };
        }
        case AutoMessageRPC.BOOKMARK_CREATED:
        case AutoMessageRPC.CLINICAL_INTEREST:
        case AutoMessageRPC.LINK_CLICKED:
        case AutoMessageRPC.PROCESS_INTAKE_LINK: {
          const { message, error } = convertRPCToMessage(envelope, logger);
          if (error) {
            return { error, message: null };
          }
          return { error: null, message };
        }
        default: {
          /* if it's an rpc of the type that we don't need to display, we don't need to log it as an error and
          also we don't need to add it to a messages array, so both values are null */
          return { error: null, message: null };
        }
      }
    }
    // if it's neither an rpc nor a text message
    const err = new Error(unrecognizedMessageError);
    return { error: err, message: null };
  });
  const messagesWithoutInvalidObjects = clearCorruptedMessages(
    formattedMessages,
    channelId,
    logger,
  );
  return messagesWithoutInvalidObjects;
};
export const ENVELOPE_PROPERTIES = ['timetoken', 'channel', 'message'];
export const RPC_MESSAGE_PROPERTIES = ['id', 'extra_params', 'rpc'];
const validateEnvelope = (envelope: RPCEnvelope): Error | null => {
  const { error: envelopePropsError } = doesObjectHaveProps(
    ENVELOPE_PROPERTIES,
    envelope,
  );
  const { error: messagePropsError } = doesObjectHaveProps(
    RPC_MESSAGE_PROPERTIES,
    envelope.message,
  );
  if (envelopePropsError || messagePropsError) {
    const error = envelopePropsError || messagePropsError;
    return error;
  }

  return null;
};

export const convertRPCToContentButton = (
  envelope: RPCEnvelope,
): ValidMessageObject | InvalidMessageObject => {
  const error = validateEnvelope(envelope);

  if (error) {
    return { error, message: null };
  }

  const {
    timetoken,
    channel,
    message: { id, extra_params },
  } = envelope;
  const assignmentId = extractAssignmentIdFromUri(extra_params.uri);

  if (!assignmentId) {
    // implies that this is not a content/homework rpc. We ignore it
    return { error: null, message: null };
  }

  return {
    error: null,
    message: {
      channel,
      contentData: {
        assignmentId: `content-${assignmentId}`,
        contentType: 'content',
        date: new Date().toISOString(),
        slug: extra_params.content_id,
        source: HomeworkSource.GINGER,
        status: HomeworkStatus.NOT_STARTED,
        thumbnail: getHomeworkThumbnail(extra_params.thumbnail),
        title: extra_params.label,
      },
      id,
      message: extra_params.label,
      status: null,
      timetoken: timetoken.toString(),
      type: MessageType.CONTENT_MESSAGE,
    },
  };
};
// one we upgrade the node version to 18.x.x, we an remove this and use the built-in Array method instead - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findLastIndex
export const findLastIndexOfMessage = (
  messagesArray: Array<MessageToDisplay>,
  searchFunction: (message: MessageToDisplay) => boolean,
) => {
  const lastMessageIndex = messagesArray.length - 1;
  // we have got an empty array. so we can return -1 upfront
  if (lastMessageIndex < 0) return -1;
  let searchElementIndex = -1;
  for (let i = lastMessageIndex; i >= 0; --i) {
    const isItSearchedElement = searchFunction(messagesArray[i]);
    if (isItSearchedElement) {
      searchElementIndex = i;
      break;
    }
  }
  return searchElementIndex;
};
