import { createTheme } from '@mui/material/styles';

export const SkyColor = '#9EC3EC';
export const StatusCriticalColor = '#D64218';

export const appTheme = createTheme({
  palette: {
    primary: {
      main: '#2875cc',
    },
  },
  typography: {
    fontFamily: ['Apercu Pro'].join(','),
  },
});
