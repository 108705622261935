import { PSSMAX } from 'app/patients/tabs/surveys/SurveysV3/constants';
import { PSSSurveyResponse } from 'app/patients/tabs/surveys/SurveysV3/types';
import { useAppState } from 'app/state';
import { formatDateFromUTC } from 'utils/dateTime';
import React, { Fragment, useMemo, useState } from 'react';
import { Dropdown } from 'shared-components/Dropdown';
import { Size } from 'types/StyleTypes';

import { PSS_DROPDOWN_OPTIONS, pssStringQuestions } from './constants';
import { LineChart, RenderTooltip, Series, YAxisOptions } from './LineChart';
import styles from './LineChart.module.scss';
import { SurveyTooltip } from './SurveyTooltip';

type Props = {
  disableTooltip?: boolean;
  disableDataLabel?: boolean;
  responses: PSSSurveyResponse[];
  onClick?: (index: number, data: PSSSurveyResponse) => void;
};

type Dimension = keyof Omit<PSSSurveyResponse, 'timestamp' | 'stress'>;

export default function PSSLineChart(props: Props) {
  const { responses, onClick } = props;
  const [dimension, setDimension] = useState<Dimension>('total');
  const defaultYaxisOption = { max: PSSMAX, min: 0, title: 'Score' };
  const [yaxis, setYaxis] = useState<YAxisOptions>(defaultYaxisOption);
  const timezone = useAppState((_) => _.user.timezone!);
  const labels = useMemo(
    () => responses.map((_) => formatDateFromUTC(_.timestamp, timezone)),
    [responses],
  );
  const data = useMemo(() => responses.map((r) => r[dimension]), [
    responses,
    dimension,
  ]);
  const series: Series[] = [{ data, name: dimension }];

  const handleClick = (dataPointIndex: number) => {
    onClick?.(dataPointIndex, responses[dataPointIndex]);
  };

  const onSelect = (selected: any) => {
    if (selected === 'total') {
      setYaxis(defaultYaxisOption);
    } else {
      // Pss scores go from 0-4, not 0-3 like PHQ/GAD
      setYaxis({ max: 4, min: 0, tickAmount: 4, title: 'Score' });
    }
    return setDimension(selected);
  };

  return (
    <Fragment>
      <div className={styles.chartTitle}>
        <span className={styles.titleLabel}>
          <b>Perceived Stress Scale</b> • PSS Check-in
        </span>
        <Dropdown
          size={Size.SM}
          variant="standard"
          data-testid="PSS-select"
          dataTestId="PSS-select"
          initialValue="total"
          options={PSS_DROPDOWN_OPTIONS}
          onSelect={onSelect}
        />
      </div>
      <LineChart
        data-testid="lineChart"
        labels={labels}
        data={series}
        tooltip={createPSSTooltip(responses)}
        xaxisOptions={{ title: 'Date Submitted' }}
        yaxisOptions={yaxis}
        onClick={handleClick}
        disableDataLabel={props.disableDataLabel}
        disableTooltip={props.disableTooltip}
      />
    </Fragment>
  );
}

function createPSSTooltip(pssSurveys: PSSSurveyResponse[]): RenderTooltip {
  return (props: { dataPointIndex: number }) => {
    const { dataPointIndex } = props;
    const pss = pssSurveys[dataPointIndex];
    const data: [string, number][] = [
      [
        pssStringQuestions.UPSET_BECAUSE_OF_SOMETHING_UNEXPECTED,
        pss.upsetBecauseOfSomethingUnexpected,
      ],
      [
        pssStringQuestions.UNABLE_TO_CONTROL_IMPORTANT_THINGS,
        pss.unableToControlImportantThings,
      ],
      [
        pssStringQuestions.FELT_NERVOUS_AND_STRESSED,
        pss.feltNervousAndStressed,
      ],
      [
        pssStringQuestions.FELT_CONFIDENT_TO_HANDLE_PERSONAL_PROBLEMS,
        pss.feltConfidentToHandlePersonalProblems,
      ],
      [
        pssStringQuestions.FELT_THINGS_GOING_YOUR_WAY,
        pss.feltThingsGoingYourWay,
      ],
      [
        pssStringQuestions.FOUND_COULD_NOT_COPE_WITH_ALL_THE_THINGS,
        pss.foundCouldNotCopeWithAllTheThings,
      ],
      [
        pssStringQuestions.ABLE_TO_CONTROL_IRRITATIONS_IN_YOUR_LIFE,
        pss.ableToControlIrritationsInYourLife,
      ],
      [pssStringQuestions.FELT_ON_TOP_OF_THINGS, pss.feltOnTopOfThings],
      [
        pssStringQuestions.ANGERED_BY_THINGS_OUTSIDE_OF_YOUR_CONTROL,
        pss.angeredByThingsOutsideOfYourControl,
      ],
      [pssStringQuestions.FELT_DIFFICULTIES_PILING, pss.feltDifficultiesPiling],
    ];
    return <SurveyTooltip data={data} total={pss.total} variant="four" />;
  };
}
