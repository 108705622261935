import { RootState } from 'app/state/hooks/baseTypedHooks';

export const selectCardState = (
  state: RootState,
  memberId: string | null,
  cardTitle?: string,
) => {
  const {
    persistedLayout: { cardsExpansion },
  } = state;
  return memberId &&
    cardTitle &&
    memberId in cardsExpansion &&
    cardTitle in cardsExpansion[memberId]
    ? cardsExpansion[memberId][cardTitle]
    : true;
};
