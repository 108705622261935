import { ItemState } from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/member-tasks/ItemState';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Button,
  Menu,
  MenuItem,
  SvgIconTypeMap,
  TableCell,
  TableSortLabel,
} from '@mui/material';
import Fade from '@mui/material/Fade';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { classNameCombiner } from 'utils';
import React, { FunctionComponent, SVGProps, useState } from 'react';
import { MenuItem as FilterMenuItem } from 'shared-components/menu/FilterMenu';
import { V2TableHead as TableHead } from 'shared-components/table/V2TableHead';
import { ThreeStateCheckbox } from 'shared-components/ThreeStateCheckbox/ThreeStateCheckbox';

import styles from './SortableTableHead.module.scss';

export type SortableColumn = {
  name: string;
  label: string;
  active?: boolean;
  direction: 'asc' | 'desc';
  sortable: boolean;
  filters?: FilterMenuItem[];
  size: 'xs' | 'small' | 'medium' | 'large';
  sortBy?: string;
  hideMenu?: boolean;
};

type BaseSVGComponentType = FunctionComponent<
  SVGProps<SVGSVGElement> & { title?: string | undefined }
>;

export type SortableTableHeadProps = {
  /**
   * An @mui/icons-material component to use for the sort icon.
   * If not provided, default is FilterListIcon.
   * */
  filterIcon?:
    | BaseSVGComponentType
    | (OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
        muiName: string;
      });
  columns: ({ filters: FilterMenuItem[] } & SortableColumn)[];

  className?: string;
  sortLabelClass?: string;
  cellClass?: string;

  // todo: make the following functions required when backend work for coach notes is complete
  onSortableTableFilter?: (column: string, item: FilterMenuItem) => void;
  changeColumnSort?: (column: SortableColumn) => void;
  dataTestId?: string;
};

/**
 * Renders a table head with sortable columns that can be sorted by clicking on the column header.
 * The sort direction is determined by the active column and the direction of the sort specified in the columns property.
 *
 * @param props
 * @returns
 */
export const SortableTableHead = (props: SortableTableHeadProps) => {
  const {
    className = '',
    sortLabelClass = '',
    cellClass = '',
    columns: sortedColumns,
    onSortableTableFilter,
    changeColumnSort,
    filterIcon: Icon = FilterListIcon,
    dataTestId = 'sortable-table-header',
  } = props;

  const elements = sortedColumns.map((column) => {
    // disabled this eslint error for now, we need to go back and rework this to prevent any issues in the future.
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null); // eslint-disable-line react-hooks/rules-of-hooks
    const onMenuClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const onMenuClose = () => {
      setAnchorEl(null);
    };
    const { name, label, active, direction, sortable, size, hideMenu } = column;

    if (hideMenu) {
      return (
        <TableCell
          key="hidden-cell"
          classes={{
            root: classNameCombiner([styles.cell, styles[size], className]),
          }}
        />
      );
    }

    return (
      <TableCell
        classes={{
          root: classNameCombiner([styles.cell, styles[size], className]),
          stickyHeader: styles.stickyHeader,
        }}
        key={name}
        align="right"
        data-testId={dataTestId}
      >
        {sortable ? (
          <TableSortLabel
            active={active}
            className={classNameCombiner([
              styles.sortableCellTop,
              sortLabelClass,
            ])}
            direction={direction}
            onClick={() => changeColumnSort?.(column)}
          >
            {label}
          </TableSortLabel>
        ) : (
          <Button
            data-testid={`${dataTestId}-filter-menu`}
            className={classNameCombiner([styles.filterItem, cellClass])}
            variant="contained"
            size="small"
            onClick={onMenuClick}
          >
            <span className={styles.cellTop}>{label}</span>
            {Icon && <Icon className={styles.filterIcon} />}
          </Button>
        )}

        <Menu
          id="newNoteButton"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          classes={{
            list: styles.menuList,
            paper: styles.menuPaper,
            root: styles.menuRoot,
          }}
          onClose={onMenuClose}
          TransitionComponent={Fade}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
        >
          {column.filters &&
            column.filters.map((item) => (
              <MenuItem
                className={styles.menuItem}
                key={`${item.key}`}
                data-testid={item.key}
                onClick={() => {}}
              >
                <ThreeStateCheckbox
                  id={item.key}
                  label={item.label}
                  labelSize="xs"
                  defaultState={
                    item.checked ? ItemState.checked : ItemState.unchecked
                  }
                  enableTextStrikeThrough={false}
                  onIconClick={() => onSortableTableFilter?.(column.name, item)}
                  onTextClick={() => onSortableTableFilter?.(column.name, item)}
                />
              </MenuItem>
            ))}
        </Menu>
      </TableCell>
    );
  });

  return <TableHead>{elements}</TableHead>;
};
