import MaterialUITooltip, { TooltipProps } from '@mui/material/Tooltip';
import { useAppState } from 'app/state';
import { tooltipViewed } from 'app/state/amplitude/actions/etc';
import { FC } from 'react';
import { useDispatch } from 'react-redux';

import styles from './TooltipWithReduxStyles.module.scss';

interface TProps extends TooltipProps {
  labelForAnalytics: string;
}

export const TooltipWithRedux: FC<TProps> = (props) => {
  const {
    placement = 'bottom-start',
    labelForAnalytics,
    children,
    enterDelay = 300,
    enterNextDelay = 300,
    disableHoverListener = false,
    ...restProps
  } = props;
  const dispatch = useDispatch();
  const { role } = useAppState((_) => _.user);

  const onOpen = () => {
    dispatch(tooltipViewed({ label: labelForAnalytics, role }));
  };

  return (
    <MaterialUITooltip
      data-testid="tooltip"
      placement={placement}
      enterDelay={enterDelay}
      enterNextDelay={enterNextDelay}
      classes={{
        popper: styles.popper,
        tooltip: styles.tooltip,
      }}
      onOpen={onOpen}
      disableHoverListener={disableHoverListener}
      {...restProps}
    >
      {children}
    </MaterialUITooltip>
  );
};
