import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import InputBase, { InputBaseComponentProps } from '@mui/material/InputBase';
import { FormError } from 'shared-components/FormError';
import { Width } from 'types/StyleTypes';
import { classNameCombiner } from 'utils';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React, { ChangeEvent, Ref } from 'react';

import styles from './AutoCompleteWithTag.module.scss';
import { Tag, TagV2 } from './Tag';

type ListItemProps = {
  inputValue: string;
  index?: number;
  label: string;
  className?: string;
};

type SearchInputProps = {
  name: string;
  InputProps: { ref: Ref<any> };
  inputProps: InputBaseComponentProps;
  placeholder?: string;
};

export enum AutocompleteIcon {
  SEARCH,
  DROPDOWN,
}

type AutocompleteWithTagProps<T> = {
  name: string;
  placeholder?: string;
  noOptionsText?: string;
  getOptionLabel: (option: T) => string;
  options: T[];
  tags: T[];
  hideSelectedTags?: boolean;
  onChange: (values: T[]) => void;
  error?: string;
  disabled?: boolean;
  className?: string;
  width?: Width;
  icon?: AutocompleteIcon;
  tagClass?: string;
  'data-testid'?: string;
};

function ListItem({
  inputValue,
  label,
  ...props
}: ListItemProps & React.HTMLAttributes<HTMLLIElement>) {
  const parts = getMatches(label, inputValue);

  return (
    <li
      {...props}
      key={label}
      className={[props.className ?? '', styles.item].join(' ')}
    >
      <div>
        {parts.map((part, index) => (
          <span
            key={`${part}-${index}`}
            className={part.highlight ? styles.highlight : undefined}
          >
            {part.text}
          </span>
        ))}
      </div>
    </li>
  );
}

function SearchInput(props: SearchInputProps) {
  return (
    <div ref={props.InputProps.ref} className={`${styles.inputWrapper}`}>
      <IconButton
        disableRipple={true}
        type="submit"
        className={styles.iconButton}
        aria-label={props.placeholder}
        size="large"
      >
        <SearchIcon />
      </IconButton>
      <InputBase
        data-testid="autocompleteInput"
        className={styles.input}
        name={props.name}
        placeholder={props.placeholder}
        inputProps={props.inputProps}
      />
    </div>
  );
}

const DropDownSearchInput = (props: SearchInputProps) => {
  return (
    <div ref={props.InputProps.ref} className={`${styles.inputWrapper}`}>
      <InputBase
        data-testid="autocompleteInput"
        className={styles.input}
        name={props.name}
        placeholder={props.placeholder}
        inputProps={props.inputProps}
      />
      <IconButton
        ref={props.InputProps.ref}
        disableRipple={true}
        type="submit"
        className={styles.iconButton}
        aria-label={props.placeholder}
        size="large"
      >
        <ExpandMoreIcon />
      </IconButton>
    </div>
  );
};

export function AutoCompleteWithTag<T>(props: AutocompleteWithTagProps<T>) {
  const {
    tags,
    options,
    disabled,
    getOptionLabel,
    placeholder,
    name,
    noOptionsText,
    className,
    width = Width.MAXWIDTH,
    icon = AutocompleteIcon.SEARCH,
    hideSelectedTags = false,
  } = props;

  const onDelete = (option: T) => {
    const updatedTags = tags.filter(
      (item) => getOptionLabel(item) !== getOptionLabel(option),
    );
    props.onChange(updatedTags);
  };

  const onChange = (_: ChangeEvent<{}>, updatedTags: T[]) => {
    props.onChange(updatedTags);
  };

  const getIconComponent = (icon: AutocompleteIcon) => {
    switch (icon) {
      case AutocompleteIcon.SEARCH:
        return SearchInput;
      case AutocompleteIcon.DROPDOWN:
        return DropDownSearchInput;
      default:
        return SearchInput;
    }
  };

  const IconComponent = getIconComponent(icon);

  return (
    <div
      data-testid={`${name}`}
      className={classNameCombiner([styles.root, styles[width.toLowerCase()]])}
    >
      <Autocomplete
        disabled={disabled}
        data-testid="Autocomplete"
        multiple={true}
        disablePortal={true}
        disableCloseOnSelect={true}
        renderTags={() => null}
        noOptionsText={noOptionsText}
        value={tags}
        onChange={onChange}
        options={options}
        getOptionDisabled={(option) =>
          tags.find((item) => item === option) !== undefined
        }
        getOptionLabel={getOptionLabel}
        renderOption={(props, option, { inputValue }) => (
          <ListItem
            {...props}
            label={getOptionLabel(option)}
            inputValue={inputValue}
          />
        )}
        renderInput={(params) => (
          <IconComponent
            placeholder={placeholder}
            name={name}
            InputProps={params.InputProps}
            inputProps={params.inputProps}
          />
        )}
        className={className}
      />

      {!hideSelectedTags && tags.length > 0 && (
        <div className={styles.tags}>
          {tags.map((option: T, index: number) => (
            <Tag
              data-testid={`tag-${index}`}
              getTagLabel={getOptionLabel}
              index={index}
              key={index}
              tag={option}
              disabled={disabled}
              onDelete={onDelete}
            />
          ))}
        </div>
      )}
      <FormError error={props.error} />
    </div>
  );
}

export function AutoCompleteWithTagV2<T>(props: AutocompleteWithTagProps<T>) {
  const {
    tags,
    options,
    disabled,
    getOptionLabel,
    placeholder,
    name,
    noOptionsText,
    className,
    width = Width.MAXWIDTH,
    icon = AutocompleteIcon.SEARCH,
    hideSelectedTags = false,
    tagClass,
  } = props;

  const onDelete = (option: T) => {
    const updatedTags = tags.filter(
      (item) => getOptionLabel(item) !== getOptionLabel(option),
    );
    props.onChange(updatedTags);
  };

  const onChange = (_: ChangeEvent<{}>, updatedTags: T[]) => {
    props.onChange(updatedTags);
  };

  const getIconComponent = (icon: AutocompleteIcon) => {
    switch (icon) {
      case AutocompleteIcon.SEARCH:
        return SearchInput;
      case AutocompleteIcon.DROPDOWN:
        return DropDownSearchInput;
      default:
        return SearchInput;
    }
  };

  const IconComponent = getIconComponent(icon);

  return (
    <div
      data-testid={`${props['data-testid'] ? props['data-testid'] : name}`}
      className={classNameCombiner([styles.root, styles[width.toLowerCase()]])}
    >
      <Autocomplete
        disabled={disabled}
        data-testid="Autocomplete"
        multiple={true}
        disablePortal={true}
        disableCloseOnSelect={true}
        renderTags={() => null}
        noOptionsText={noOptionsText}
        value={tags}
        onChange={onChange}
        options={options}
        getOptionDisabled={(option) =>
          tags.find((item) => item === option) !== undefined
        }
        getOptionLabel={getOptionLabel}
        renderOption={(props, option, { inputValue }) => (
          <ListItem
            {...props}
            label={getOptionLabel(option)}
            inputValue={inputValue}
          />
        )}
        renderInput={(params) => (
          <IconComponent
            placeholder={placeholder}
            name={name}
            InputProps={params.InputProps}
            inputProps={params.inputProps}
          />
        )}
        className={className}
      />

      {!hideSelectedTags && tags.length > 0 && (
        <div className={styles.tags}>
          {tags.map((option: T, index: number) => (
            <TagV2
              data-testid={`tag-${index}`}
              getTagLabel={getOptionLabel}
              index={index}
              key={index}
              tag={option}
              disabled={disabled}
              onDelete={onDelete}
              className={tagClass}
            />
          ))}
        </div>
      )}
      {props.error && <FormError error={props.error} />}
    </div>
  );
}

function getMatches(label: string, inputValue: string) {
  const matches = match(label, inputValue);
  return parse(label, matches);
}
