import { Stage } from '@ginger.io/core-ui';

/**
 * A map of Stage to the corresponding URL for the Content Portal.
 *
 * Currently there are only two variants: prod and non-prod.
 */
export const ContentPortalUrlForStage: Record<Stage, string> = {
  [Stage.PROD]: 'https://content.ginger.io/',
  [Stage.DEV]: 'https://content.ginger.dev/',
  [Stage.LOCAL]: 'https://content.ginger.dev/',
  [Stage.LOCAL_GRAPHQL]: 'https://content.ginger.dev/',
};
