import { KeyboardArrowRight } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import { classNameCombiner } from 'utils';
import React, { ReactNode, useState } from 'react';

import styles from './Accordion.module.scss';

export type AccordionProps = {
  children: ReactNode;
  accordionTitle?: string;
  testId?: string;
  /* Required if using multiple accordions as subsections of a component. 
  This is the integer, starting from 0, that describes the order of accordion subsections from the top of the parent component. 
  For example, the first accordion subsection would be 0, the second 1, and so on. */
  subsectionIndex?: number;
  isExpandedByDefault?: boolean;
  classes?: {
    titleHeader?: string;
  };
};

export const Accordion = ({
  children,
  accordionTitle,
  testId,
  isExpandedByDefault = true,
  classes = {},
}: AccordionProps): JSX.Element => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(
    isExpandedByDefault,
  );

  const handleAccordionHeaderClick = (
    e: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    e.preventDefault();
    if (isAccordionExpanded) {
      setIsAccordionExpanded(false);
    } else {
      setIsAccordionExpanded(true);
    }
  };
  const accordionHeader = accordionTitle ? (
    <button
      data-testid={testId}
      style={{ top: 0 }}
      className={classNameCombiner([
        styles.accordionHeader,
        classes?.titleHeader ?? '',
      ])}
      onClick={handleAccordionHeaderClick}
    >
      <div className={styles.accordionTitle}>{accordionTitle}</div>
      <div
        className={
          isAccordionExpanded
            ? styles.expandIcon
            : `${styles.expandIcon} ${styles.rotate90deg}`
        }
      >
        <KeyboardArrowRight />
      </div>
    </button>
  ) : null;

  const accordionContent = (
    <Collapse
      in={isAccordionExpanded}
      timeout={400}
      children={children}
      className={
        accordionTitle
          ? `${styles.accordionContent}`
          : `${styles.accordionContent} ${styles.paddingTop}`
      }
    />
  );

  return (
    <div className={styles.accordionContainer}>
      {accordionHeader}
      <div className={styles.accordion}>{accordionContent}</div>
    </div>
  );
};
