import {
  arrayField,
  booleanField,
  field,
  FieldDefinitions,
  numberField,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  PsychiatricHistory,
  PsychiatricHistory_PsychotropicMedication as PsychotropicMedication,
  PsychiatricHistory_SuicideAttempt as SuicideAttempt,
  PsychiatricHistory_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/PsychiatricHistory';
import {
  PsychiatricHistorySection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { Width } from 'types/StyleTypes';
import { TextAreaField, TextBoxField } from 'app/notes-ui/forms/fields';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { AUTO_SAVE_DRAFT_DELAY } from 'app/notes-ui/utils';
import React from 'react';

import { PsychiatricHistoryTable } from './PsychiatricHistoryTable';
import { ColumnDefinition } from './PsychiatryHistoryRow';
import { useFormTable } from './useFormTable';

type Props = {
  appointmentId: string;
  onSubmit: (data: PsychiatricHistorySection) => void;
  updateDraftNoteState: (data: PsychiatricHistorySection) => void;
  disabled?: boolean;
  savingNote?: boolean;
  initialValue?: PsychiatricHistory;
};

export function PsychiatricHistoryForm(props: Props) {
  const { initialValue, disabled, savingNote, appointmentId } = props;

  const { fields, validate, getValue } = useForm<PsychiatricHistory>(
    fieldDefs,
    { ...initialValue, appointmentId } as PsychiatricHistory,
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: PsychiatrySectionName.PSYCH_HISTORY,
        }),
    },
  );

  const pastPsychotropicMedicationsResponsesRow = useFormTable<
    PsychotropicMedication
  >(
    'Must fill the medication response table or check the checkbox if there are no known medications',
    props.initialValue?.pastPsychotropicMedicationsResponses,
    fields.pastPsychotropicMedicationsResponses.setValue,
  );
  const pastSuicideAttemptRow = useFormTable<SuicideAttempt>(
    'Must fill the suicide attempts table or check the checkbox if there are no known suicide attempts',
    props.initialValue?.pastSuicideAttempt,
    fields.pastSuicideAttempt.setValue,
  );

  const onSubmit = async () => {
    if (await validate()) {
      props.onSubmit({
        data: getValue(),
        name: PsychiatrySectionName.PSYCH_HISTORY,
      });
    }
  };
  return (
    <div>
      <TextAreaField
        width={Width.FULL}
        disabled={disabled}
        testId="pastOutpatientTreatment"
        field={fields.pastOutpatientTreatment}
        label="Past Outpatient Treatment (Therapy and/or med management)*"
      />
      <PsychiatricHistoryTable
        label="Past Psychotropic Medications and Responses"
        noDataCheckboxLabel="No known medications"
        onChange={pastPsychotropicMedicationsResponsesRow.onChange}
        error={
          pastPsychotropicMedicationsResponsesRow.error ||
          fields.pastPsychotropicMedicationsResponses.error
        }
        testId="pastPsychotropicMedicationsResponses"
        initialValue={props.initialValue?.pastPsychotropicMedicationsResponses}
        columns={psychotropicMedicationColumnDef}
        fieldDefs={psychotropicMedicationDef}
        getBlankRecord={() => PsychotropicMedication.fromPartial({})}
        disabled={disabled}
      />
      <PsychiatricHistoryTable
        label="Past Suicide Attempts"
        noDataCheckboxLabel="No known suicide attempts"
        onChange={pastSuicideAttemptRow.onChange}
        error={pastSuicideAttemptRow.error || fields.pastSuicideAttempt.error}
        initialValue={props.initialValue?.pastSuicideAttempt}
        testId="pastSuicideAttempt"
        columns={suicideAttemptColumnDef}
        fieldDefs={suicideAttemptDef}
        getBlankRecord={() => SuicideAttempt.fromPartial({})}
        disabled={disabled}
      />
      <NoteFormControlLabel label="Past Inpatient Hospitalizations and/or Residential Stays">
        <TextBoxField
          disabled={disabled}
          testId="pastInpatientHospitalization"
          field={fields.pastInpatientHospitalization}
          label=""
        />
      </NoteFormControlLabel>
      <NoteFormControlLabel label="Past Substance Use Treatment">
        <TextBoxField
          disabled={disabled}
          testId="pastSubstanceUseTreatment"
          field={fields.pastSubstanceUseTreatment}
          label=""
        />
      </NoteFormControlLabel>
      <NoteFormControlLabel label="History of complicated EtOH or Benzo Withdrawal (DTs or Seizures)?">
        <TextBoxField
          disabled={disabled}
          testId="historyOfComplicatedEtohOrBenzoWithdrawal"
          field={fields.historyOfComplicatedEtohOrBenzoWithdrawal}
          label=""
        />
      </NoteFormControlLabel>
      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </div>
  );
}

const suicideAttemptDef: FieldDefinitions<SuicideAttempt> = {
  clinicalIntervention: stringField({ rules: [] }),
  comments: stringField({ rules: [] }),
  method: field(),
  timeframe: field({ rules: [] }),
};

const psychotropicMedicationDef: FieldDefinitions<PsychotropicMedication> = {
  adverseReaction: stringField({ rules: [] }),
  clinicalResponse: stringField({ rules: [] }),
  comments: stringField({ rules: [] }),
  dosage: numberField({ rules: [] }),
  duration: numberField({ rules: [] }),
  medication: field(),
};

const fieldDefs: FieldDefinitions<PsychiatricHistory> = {
  appointmentId: field(),
  historyOfComplicatedEtohOrBenzoWithdrawal: stringField({ rules: [] }),
  pastInpatientHospitalization: stringField({ rules: [] }),
  pastOutpatientTreatment: stringField(),
  pastPsychotropicMedicationsResponses: arrayField({
    rules: [
      (value) => {
        const isValid =
          value !== undefined &&
          value.every((_) =>
            validateForm<PsychotropicMedication>(_, psychotropicMedicationDef),
          );
        if (!isValid) return 'This field contains invalid input';
        return undefined;
      },
    ],
  }),
  pastSelfHarmEpisode: stringField({ rules: [] }),
  pastSubstanceUseTreatment: stringField({ rules: [] }),
  pastSuicidalIdeationEpisode: stringField({ rules: [] }),
  pastSuicideAttempt: arrayField({
    rules: [
      (value) => {
        const isValid =
          value !== undefined &&
          value.every((_) =>
            validateForm<SuicideAttempt>(_, suicideAttemptDef),
          );
        if (!isValid) return 'This field contains invalid input';
        return undefined;
      },
    ],
  }),
  pastTreatments: {
    inpatientTreatment: booleanField({ rules: [] }),
    outpatientTreatment: booleanField({ rules: [] }),
  },
  version: field<PsychiatricHistory_Version>({
    default: PsychiatricHistory_Version.undefined_version,
  }),
};

const psychotropicMedicationColumnDef: ColumnDefinition<
  PsychotropicMedication
>[] = [
  {
    columnType: 'text',
    key: 'medication',
    label: 'Medication*',
    placeholder: 'Drug name',
  },
  { columnType: 'number', key: 'dosage', label: 'Dosage (mg/day)' },
  { columnType: 'number', key: 'duration', label: 'Duration (year)' },
  {
    columnType: 'text',
    key: 'clinicalResponse',
    label: 'Clinical Response',
    placeholder: 'Response',
  },
  {
    columnType: 'text',
    key: 'adverseReaction',
    label: 'Adverse Reaction',
    placeholder: 'Reaction',
  },
];

const suicideAttemptColumnDef: ColumnDefinition<SuicideAttempt>[] = [
  {
    columnType: 'text',
    key: 'timeframe',
    label: 'Timeframe',
    placeholder: 'Month/Year',
  },
  {
    columnType: 'text',
    key: 'method',
    label: 'Method*',
    placeholder: 'Method',
  },
  {
    columnType: 'text',
    key: 'clinicalIntervention',
    label: 'Clinical Intervention',
    placeholder: 'Intervention',
  },
  { columnType: 'text', key: 'comments', label: 'Comments' },
];

export function validate(data: PsychiatricHistory | null): boolean {
  return data !== null && validateForm<PsychiatricHistory>(data, fieldDefs);
}
