import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import Tooltip from 'shared-components/tooltip/Tooltip';
import { MarkdownContent } from 'shared-components/markdown-content/MarkdownContent';
import React from 'react';

import styles from './NoteTooltip.module.scss';

export function NoteTooltip({ tooltip }: { tooltip: string }) {
  const {
    enable_care_hub_notes_efficiency,
  } = useFeatureFlags().transientFeatureFlags;

  const tooltipContent = <MarkdownContent>{tooltip}</MarkdownContent>;

  return (
    <Tooltip placement="bottom-start" title={tooltipContent} arrow={true}>
      {enable_care_hub_notes_efficiency ? (
        <InfoOutlinedIcon className={styles.info} />
      ) : (
        <InfoIcon className={styles.info} />
      )}
    </Tooltip>
  );
}
