import { Field } from '@ginger.io/react-use-form';
import TableCell from '@mui/material/TableCell';
import { Width } from 'types/StyleTypes';
import {
  NumberField,
  TextAreaField,
  TextBoxField,
} from 'app/notes-ui/forms/fields';
import React, { useEffect } from 'react';

import styles from './PsychiatryHistoryTableV2.module.scss';

type Props = {
  field: Field<any>;
  testId: string;
  isEditing: boolean;
  disabled: boolean;
  columnType: 'text' | 'number' | 'date' | 'textarea';
  columnSize?: number;
  placeholder: string | undefined;
  width?: Width;
  label: string;
  onChange: (value: any) => void;
  children?: React.ReactNode;
};

export function PsychiatryHistoryCellV2(props: Props) {
  const {
    field,
    columnType,
    columnSize,
    placeholder,
    width = Width.HALF,
    testId,
    disabled,
    isEditing,
    onChange,
    children,
  } = props;

  // adding onChange as a dep causes an infinite loop - seems like a code smell
  useEffect(() => {
    if (field.touched) onChange(field.value);
  }, [field.value, field.error, field.touched]);

  let cell = <div />;
  if (!isEditing) {
    cell = (
      <div className={styles.cellValue} data-testid={testId}>
        {field.value || '-'}
      </div>
    );
  } else if (columnType === 'text') {
    cell = (
      <TextBoxField
        disabled={disabled}
        placeholder={placeholder}
        label=""
        testId={testId}
        field={field}
      />
    );
  } else if (columnType === 'number') {
    cell = (
      <NumberField
        disabled={disabled}
        placeholder={placeholder}
        testId={testId}
        label=""
        field={field}
      />
    );
  } else if (columnType === 'textarea') {
    cell = (
      <TextAreaField
        disabled={disabled}
        placeholder={placeholder}
        testId={testId}
        label=""
        field={field}
        width={width}
        formControlStyles={{ root: styles.textAreaLabelRoot }}
        rows={1}
        autoExpand={true}
      />
    );
  }
  return (
    <TableCell
      width={columnSize ? `${columnSize}%` : undefined}
      className={styles.cell}
    >
      {cell} {children}
    </TableCell>
  );
}
