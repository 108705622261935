import { OktaAuth } from '@okta/okta-auth-js';
import { Stage } from 'app/Stage';

type Config = {
  issuer: string;
  clientId: string; // public identifier, not a "secret"
  redirectUri: string;
};

const headspaceProdConfig: Config = {
  clientId: '0oa3howlmxq747Tv6697',
  issuer: 'https://headspace.okta.com',
  redirectUri: 'https://care.ginger.io/oauth/login',
};

const headspaceDevConfig: Config = {
  clientId: '0oa2hg2fdpOngWeH4697',
  issuer: 'https://headspace.okta.com',
  redirectUri: 'https://care.ginger.dev/oauth/login',
};

const headspaceLocalConfig: Config = {
  clientId: '0oa2hg2fdpOngWeH4697',
  issuer: 'https://headspace.okta.com',
  redirectUri: 'http://localhost:3000/oauth/login',
};

const config: { [T in Stage]: Config } = {
  [Stage.PROD]: headspaceProdConfig,

  [Stage.DEV]: headspaceDevConfig,

  [Stage.LOCAL]: headspaceLocalConfig,
  [Stage.LOCAL_GRAPHQL]: headspaceLocalConfig,
};

export function createOktaClient(stage: Stage): OktaAuth {
  return new OktaAuth({
    ...config[stage],
    pkce: true,
    scopes: ['openid', 'profile', 'email', 'groups'], // When pkce is true, responseType will automatically include an `id_token` and `token`
  });
}
