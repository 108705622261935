import React from 'react';
import { PillButton } from 'shared-components/button/PillButton';

import { viewMoreButtonText } from '../strings';
import { HISTORY_STATE } from '../types';
import { LoadMoreError } from './LoadMoreError';
import styles from './LoadMoreSection.module.scss';

interface LoadMoreSectionProps {
  onGetMoreMessages: () => void;
  historyState?: HISTORY_STATE;
}

export const LoadMoreSection = ({
  onGetMoreMessages,
  historyState,
}: LoadMoreSectionProps) => {
  const renderHistoryState = () => {
    switch (historyState) {
      case HISTORY_STATE.STILL_HAS_SOME_MESSAGES: {
        return (
          <PillButton
            testId="moreButton"
            onClick={onGetMoreMessages}
            text={viewMoreButtonText}
            className={styles.viewMoreButton}
          />
        );
      }
      case HISTORY_STATE.ERROR: {
        return <LoadMoreError />;
      }
      default: {
        return null;
      }
    }
  };
  return renderHistoryState();
};
