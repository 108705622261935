import FiberNewIcon from '@mui/icons-material/FiberNew';
import Badge from '@mui/material/Badge';
import React, { ReactNode } from 'react';

import styles from './TabLabel.module.scss';

export function TabLabel(props: {
  children: ReactNode;
  isNewFeature: boolean;
  isNotificationVisible?: boolean;
}) {
  if (props.isNewFeature) {
    return (
      <Badge
        overlap="rectangular"
        data-testid="new-badge"
        badgeContent={<FiberNewIcon color="inherit" />}
        classes={{ badge: styles.badge, root: styles.root }}
      >
        {props.children}
      </Badge>
    );
  }
  if (props?.isNotificationVisible) {
    return (
      <Badge
        overlap="rectangular"
        data-testid="notification-badge"
        badgeContent={<span>•</span>}
        classes={{ badge: styles.dotNotification, root: styles.root }}
      >
        {props.children}
      </Badge>
    );
  }
  return <span>{props.children}</span>;
}
