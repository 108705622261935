import { summaryRoute } from 'app/coach/member-chart/Routes';
import { InboxView } from 'app/inbox/InboxView';
import { useDispatch } from 'app/state';
import { clickMemberItemAction } from 'app/state/amplitude/actions/inbox';
import { openNewTab } from 'app/state/member-tabs/actions';
import { selectUserRole } from 'app/state/user/selectors';
import { Routes } from 'app/top-nav/Routes';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { InboxItem, InboxSections, InboxSource, InboxTab } from './types';
import { useCoachInbox } from './UseCoachInbox';

const COACH_TODAYS_SECTIONS = [
  InboxSections.COMPLETED,
  InboxSections.SCHEDULED_CHECKINS,
  InboxSections.CONVERSATIONS_AND_TASKS,
  InboxSections.RISKS,
];

export function CoachInboxView({ tabIndex }: { tabIndex: number }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const role = selectUserRole();

  const inbox = useCoachInbox();

  const onItemClick = useCallback(
    (item: InboxItem, section: InboxSections) => {
      history.replace(summaryRoute(Routes.COACHING_MEMBERS, item.id));
      dispatch(
        clickMemberItemAction({
          memberId: item.id,
          role: role!,
          section,
          source: InboxSource.COACH_INBOX,
          tab: COACH_TODAYS_SECTIONS.includes(section)
            ? InboxTab.TODAYS
            : InboxTab.ALL,
        }),
      );
      dispatch(
        openNewTab({
          memberId: item.id,
          section,
          source: InboxSource.COACH_INBOX,
        }),
      );
    },
    [role, dispatch, history],
  );

  return <InboxView {...inbox} tabIndex={tabIndex} onItemClick={onItemClick} />;
}
