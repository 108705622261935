import { MemberChartErrorState } from 'app/member-chart-cards/error-state/MemberChartErrorState';
import React, { ReactElement } from 'react';
import { Loader } from 'shared-components/loader/Loader';

import { StateSlice, Status } from './types/StateSlice';

export function renderSliceStateResult<T>(
  state: StateSlice<T>,
  render: (data: T | null) => ReactElement,
): ReactElement {
  const { status, error, data } = state;
  switch (status) {
    case Status.LOADING:
      return <Loader />;
    case Status.ERROR:
      return <MemberChartErrorState error={error || undefined} />;
    case Status.COMPLETE:
      return render(data);
  }
}
