import React from 'react';

import styles from './TimeDivider.module.scss';

export const TimeDivider = ({
  dateAndTime,
  comment,
}: {
  dateAndTime: string;
  comment?: string;
}) => {
  return (
    <div className={styles.container}>
      <span className={styles.dateAndTime}>
        {comment && (
          <>
            {comment}
            <br />
          </>
        )}
        {dateAndTime}
      </span>
    </div>
  );
};
