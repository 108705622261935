import Paper from '@mui/material/Paper';
import { useOktaAuth } from '@okta/okta-react';
import { ReactComponent as Logo } from 'app/top-nav/logo.svg';
import { useLogger } from 'app/state/log/useLogger';
import Messages from 'i18n/en/core.json';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { Button } from 'shared-components/button/Button';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';

import styles from './styles/NotLoggedInScreen.module.scss';

export function NotLoggedInScreen() {
  const { oktaAuth, authState } = useOktaAuth();
  const logger = useLogger();

  if (authState?.isAuthenticated) {
    return <Redirect to="/" />;
  }

  const onClick = () => {
    logger.info('NotLoggedInScreen: user clicked login');
    return oktaAuth.signInWithRedirect();
  };
  return (
    <div data-testid="notAuthenticatedScreen">
      <Grid justify="center" align="center" className={styles.container}>
        <Columns widths={[COLUMN_WIDTH.HALF]}>
          <Paper className={styles.loginBox} elevation={3} square={true}>
            <Logo />
            <h1>Care Hub</h1>
            <h3>{Messages.notLoggedIn}</h3>
            <Button data-testid="login" onClick={onClick}>
              {Messages.logIn}
            </Button>
          </Paper>
        </Columns>
      </Grid>
    </div>
  );
}
