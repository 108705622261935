import { ClinicalAppointmentsAPI } from 'app/appointments/api/ClinicalAppointmentsAPI';
import { NonAppointmentNotesAPI } from 'app/vault/api/NonAppointmentNotesAPI';
import { PsychiatryIntakeNotesAPI } from 'app/vault/api/PsychiatryIntakeNotesAPI';
import { PsychiatryProgressNotesAPI } from 'app/vault/api/PsychiatryProgressNotesAPI';
import { TherapyIntakeNotesAPI } from 'app/vault/api/TherapyIntakeNotesAPI';
import { TherapyProgressNotesAPI } from 'app/vault/api/TherapyProgressNotesAPI';
import { createContext, useContext } from 'react';

export type ClinicalNotesAPIClients = {
  therapyIntakeNotes: TherapyIntakeNotesAPI;
  therapyProgressNotes: TherapyProgressNotesAPI;
  psychiatryIntakeNotes: PsychiatryIntakeNotesAPI;
  psychiatryProgressNotes: PsychiatryProgressNotesAPI;
  nonAppointmentNotesAPI: NonAppointmentNotesAPI;
  clinicalAppointmentsAPI: ClinicalAppointmentsAPI;
};

export const ClinicalNotesAPIContext = createContext<ClinicalNotesAPIClients | null>(
  null,
);

export function useTherapyIntakeNotesAPI(): TherapyIntakeNotesAPI {
  return useClinicalNotesAPI().therapyIntakeNotes;
}

export function useTherapyProgressNotesAPI(): TherapyProgressNotesAPI {
  return useClinicalNotesAPI().therapyProgressNotes;
}

export function usePsychiatryIntakeNotesAPI(): PsychiatryIntakeNotesAPI {
  return useClinicalNotesAPI().psychiatryIntakeNotes;
}

export function usePsychiatryProgressNotesAPI(): PsychiatryProgressNotesAPI {
  return useClinicalNotesAPI().psychiatryProgressNotes;
}

export function useClinicalAppointmentsAPI(): ClinicalAppointmentsAPI {
  return useClinicalNotesAPI().clinicalAppointmentsAPI;
}

export function useClinicalNotesAPI(): ClinicalNotesAPIClients {
  const api = useContext(ClinicalNotesAPIContext);
  if (api !== null) {
    return api;
  }
  throw new Error(
    `No ClinicalNotesAPI found in your app's context. Did you forget to wrap your root component with ClinicalNotesAPIContext.Provider?`,
  );
}
