import { UserClaims } from '@okta/okta-auth-js';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FeatureFlags } from 'hooks/useFeatureFlags';
import { Routes } from 'app/top-nav/Routes';
import { services } from 'app/services';
import { isLocal } from 'app/Stage';
import { AuthAsyncThunkAPI } from 'app/state/features/auth/types';
import { LogLevel } from 'app/state/log/Logger';
import { getAuthenticatedUserData } from 'app/state/syncAuthenticatedUserToRedux';
import { AuthenticatedUser, logout } from 'app/state/user/actions';

type GetAuthUserDataReturnType = {
  oktaUser: UserClaims;
  appUser: AuthenticatedUser;
  featureFlags: FeatureFlags;
};

export class AuthService {
  static startLogin = createAsyncThunk<void, void, AuthAsyncThunkAPI>(
    'auth/startLogin',
    async (_, thunkAPI) => {
      const { extra, rejectWithValue } = thunkAPI;
      try {
        const { okta, logger } = extra.services;
        logger.info('AuthService: redirecting to user to okta login screen');
        await okta.signInWithRedirect();
        logger.info('AuthService: redirect to okta login screen complete');
      } catch (error) {
        return rejectWithValue({
          error: new Error('Failed to redirect user to okta login screen', {
            cause: error,
          }),
        });
      }
    },
  );

  static completeLogin = createAsyncThunk<void, void, AuthAsyncThunkAPI>(
    'auth/completeLogin',
    async (_, thunkAPI) => {
      const { extra, rejectWithValue } = thunkAPI;
      try {
        const { okta, logger } = extra.services;
        logger.info('AuthService: completing okta login flow');
        await okta.handleLoginRedirect();
        logger.info('AuthService: user successfully logged in');
      } catch (error) {
        return rejectWithValue({
          error: new Error('Failed to complete login', { cause: error }),
        });
      }
    },
  );

  static logout = createAsyncThunk<void, void, AuthAsyncThunkAPI>(
    'auth/logout',
    async (_, thunkAPI) => {
      const { extra, rejectWithValue, dispatch } = thunkAPI;
      try {
        const { apollo, okta, logger } = extra.services;
        logger.info(
          'AuthService: logging out user and redirecting to user to login screen',
        );
        await okta
          .signOut({
            postLogoutRedirectUri: `${window.location.origin}${Routes.LOGOUT}`,
          })
          .catch((error: Error & { errorCode: string }) => {
            const { errorCode } = error;
            if (errorCode !== 'login_required') {
              logger.error(new Error('Okta logout failed', { cause: error }), {
                error,
              });
            }
            // reloading the page redirect the user to okta's login page
            window.location.reload();
          });

        // We use clearStore instead of resetStore as we don't want to re-fetch active queries
        // see: https://www.apollographql.com/docs/react/networking/authentication/#reset-store-on-logout
        await apollo.clearStore();
        // todo: we'll want perform this action outside the thunk when we've move the user slice into RTK
        dispatch(logout());
      } catch (error) {
        return rejectWithValue({
          error: new Error('Unable to log user out platform', { cause: error }),
        });
      }
    },
  );

  static getAuthUserData = createAsyncThunk<
    GetAuthUserDataReturnType,
    void,
    AuthAsyncThunkAPI
  >('auth/queryUserInfo', async (_, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;
    try {
      const { authFormatter } = extra.formatters;
      const { apollo, okta } = extra.services;
      const [user, data] = await Promise.all([
        okta.getUser(),
        getAuthenticatedUserData(apollo, 'network-only'),
      ]);
      const featureFlags = authFormatter.getFeatureFlags(data);
      const appUser = authFormatter.anAuthenticatedUser(data);
      const { role, userId, listenerId } = appUser;

      const enableDebugLogger =
        featureFlags.transientFeatureFlags.enable_carehub_debug_logger;
      services.logger.setLogLevel(
        enableDebugLogger || isLocal() ? LogLevel.DEBUG : LogLevel.INFO,
      );
      services.logger.setUser({
        listenerId: listenerId ?? undefined,
        role,
        userId,
      });

      return { appUser, featureFlags, oktaUser: user };
    } catch (error) {
      return rejectWithValue({
        error: new Error('An error occurred while loading your user data.', {
          cause: error,
        }),
      });
    }
  });
}
