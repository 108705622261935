import { useOktaAuth } from '@okta/okta-react';
import { Modal } from 'shared-components/modal/Modal';
import { ReactComponent as Logo } from 'app/top-nav/logo.svg';
import { useAppState, useDispatch } from 'app/state';
import { updateSessionState } from 'app/state/features/auth/authSlice';
import { selectSessionState } from 'app/state/features/auth/selectors';
import { SessionState } from 'app/state/features/auth/types';
import { useLogger } from 'app/state/log/useLogger';
import { oktaTokenExpirationDate } from 'utils';
import Messages from 'i18n/en/core.json';
import React, { useEffect, useState } from 'react';

const reloadPage = () => {
  window.location.reload();
};

type Props = {
  onClick?: () => void;
};

export function AuthenticationErrorModal(props: Props) {
  const { onClick = reloadPage } = props;
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();
  const sessionState = useAppState(selectSessionState);
  const [showAuthorizationModal, setShowAuthorizationModal] = useState(false);

  const logger = useLogger();
  useEffect(() => {
    const oktaExpiration = oktaTokenExpirationDate(
      oktaAuth.getIdToken(),
    )?.toISOString();
    const run = async () => {
      if (sessionState !== SessionState.EXPIRED) return;

      // Sometimes we hit an edge case where we make a request with an expired token while the sdk is still trying to
      // renew the user token. If the user is still authenticated in okta and the session state is expired,
      // we update the session state to valid. Otherwise, we show the modal.

      const isAuthenticated = await oktaAuth.isAuthenticated();

      logger.debug('AuthenticationErrorModal: checking auth state', {
        isAuthenticated,
        sessionState,
      });

      if (!isAuthenticated && sessionState === SessionState.EXPIRED) {
        setShowAuthorizationModal(true);
        logger.info(
          "AuthenticationErrorModal: showing modal because user's okta auth token or vault token has expired",
          {
            oktaExpiration,
          },
        );
      } else {
        dispatch(updateSessionState(SessionState.VALID));
      }
    };
    run().catch((error) =>
      logger.error(
        new Error('AuthenticationErrorModal: error checking auth state', {
          cause: error,
        }),
        { error, oktaExpiration },
      ),
    );
  }, [oktaAuth, sessionState, logger]);

  const onButtonClick = () => {
    logger.info('AuthenticationErrorModal: user closed modal. Reloading page.');
    onClick();
  };
  return (
    <Modal
      open={showAuthorizationModal}
      onButtonClick={onButtonClick}
      buttonLabel="Reload Page"
    >
      <Logo />
      <h1 data-testid="authentication-error-modal-title">
        {Messages.sessionExpiredTitle}
      </h1>
      <div>
        <p>{Messages.sessionExpiredMessage}</p>
      </div>
    </Modal>
  );
}
