export const visibleAutoMessageTitle = 'Auto-message sent to the Member';
export const rpcAutoMessageTitle = 'Auto-message (not visible to the Member)';

export const MOCK_MESSAGE_FOR_TESTING_ERROR_FLOW =
  'MOCK::FAIL_MESSAGE::(some UUID)';

export const errorTooltipText = 'Message failed to send. Copy/paste and retry.';

export const viewMoreButtonText = 'Load More...';

// to be confirmed w/ Sasha
export const loadMoreError =
  'Oops, error loading. Please refresh the page and try again.';
// to be confirmed w/ Sasha
export const noMoreHistory = 'Beginning of chat history';

export const BOOKMARK_MESSAGE_TRANSFERED_TO_COACH = 'Transferred to COACH';
export const BOOKMARK_MESSAGE_TRANSFERED_TO_SCHEDULER =
  'Transferred to SCHEDULER';
export const BOOKMARK_MESSAGE_TRANSFERED_TO_SUPPORT = 'Transferred to SUPPORT';

export const oneNewMessageButtonText = 'new message';
export const multipleNewMessagesButtonText = 'new messages';

export const noHistoryForCountingError = `Have not received messages for channel when called getHistory, cannot count unread messages`;
