import { ActionHandler } from './types';

/**
 * This handler is triggered when the server signals that the status of a message has changed.
 */
export const onStatusReceived = async ({
  action: { payload },
  context: { services },
}: ActionHandler<{ envelope: any }>) => {
  const { pubnub, logger } = services;
  const { envelope } = payload;
  const additionalData: Record<string, unknown> = { ...envelope };
  if (envelope.error) {
    additionalData.pubnubError = {
      ...envelope.errorData,
      message: envelope.errorData?.message,
    };
  }

  logger.info('PubNub status received', additionalData);
  const retryablePubnubSubscribeErrorCategories = new Set([
    'PNNetworkIssuesCategory', // General network error
    'PNTimeoutCategory', // Failure to establish a connection to PubNub due to a timeout.
  ]);
  if (
    envelope.operation === 'PNSubscribeOperation' &&
    envelope.error &&
    retryablePubnubSubscribeErrorCategories.has(envelope.category) &&
    envelope.errorData.url
  ) {
    pubnub.retryChannelSubscription(envelope);
  }
};
