import {
  InboxSection,
  InboxSectionHeaderColor,
  InboxSectionIcon,
} from 'app/inbox/components/InboxSection';
import { InboxSectionContentWithRedux } from 'app/inbox/components/InboxSectionContentWithRedux';
import { InboxSections, InboxTab } from 'app/inbox/types';
import { useAppState } from 'app/state';
import { selectUserRole } from 'app/state/user/selectors';
import { isClinicianOrSupervisor, isCoachOrSupervisor } from 'utils';

import { DataStateProps } from './InboxView';

export const InboxTodaysTab = (props: Readonly<DataStateProps>) => {
  const { loadMore, onItemClick } = props;
  const hasRiskItems = useAppState(
    ({ inbox: { tabSections } }) =>
      tabSections[InboxSections.RISKS].ids.size > 0,
  );
  const role = selectUserRole();
  return (
    <>
      <InboxSection
        headerColor={
          hasRiskItems
            ? InboxSectionHeaderColor.LIGHT_ROSE
            : InboxSectionHeaderColor.LIGHT_GREY
        }
        sectionIcon={
          hasRiskItems
            ? InboxSectionIcon.SUICIDE_RISK
            : InboxSectionIcon.NO_SUICIDE_RISK
        }
        sectionTitle="Risk Alerts"
        section={InboxSections.RISKS}
      >
        <InboxSectionContentWithRedux
          onItemClick={onItemClick}
          section={InboxSections.RISKS}
          tab={InboxTab.TODAYS}
          loadMore={loadMore}
        />
      </InboxSection>

      {isCoachOrSupervisor(role) && (
        <>
          <InboxSection
            headerColor={InboxSectionHeaderColor.LIGHT_CLOUD}
            sectionTitle="Conversations & Tasks"
            section={InboxSections.CONVERSATIONS_AND_TASKS}
          >
            <InboxSectionContentWithRedux
              onItemClick={onItemClick}
              section={InboxSections.CONVERSATIONS_AND_TASKS}
              tab={InboxTab.TODAYS}
              loadMore={loadMore}
            />
          </InboxSection>
          <InboxSection
            headerColor={InboxSectionHeaderColor.LIGHT_CLOUD}
            sectionTitle="Scheduled"
            section={InboxSections.SCHEDULED_CHECKINS}
          >
            <InboxSectionContentWithRedux
              onItemClick={onItemClick}
              section={InboxSections.SCHEDULED_CHECKINS}
              tab={InboxTab.TODAYS}
              loadMore={loadMore}
            />
          </InboxSection>
        </>
      )}

      {isClinicianOrSupervisor(role) && (
        <>
          <InboxSection
            headerColor={InboxSectionHeaderColor.LIGHT_CLOUD}
            sectionTitle="Appointments"
            section={InboxSections.APPOINTMENTS}
          >
            <InboxSectionContentWithRedux
              onItemClick={onItemClick}
              section={InboxSections.APPOINTMENTS}
              tab={InboxTab.TODAYS}
              loadMore={loadMore}
            />
          </InboxSection>
          <InboxSection
            headerColor={InboxSectionHeaderColor.LIGHT_CLOUD}
            sectionTitle="Tasks"
            section={InboxSections.TASKS}
          >
            <InboxSectionContentWithRedux
              onItemClick={onItemClick}
              section={InboxSections.TASKS}
              tab={InboxTab.TODAYS}
              loadMore={loadMore}
            />
          </InboxSection>
        </>
      )}
      <InboxSection
        headerColor={InboxSectionHeaderColor.LIGHT_MINT}
        sectionTitle="Completed"
        section={InboxSections.COMPLETED}
      >
        <InboxSectionContentWithRedux
          onItemClick={onItemClick}
          section={InboxSections.COMPLETED}
          tab={InboxTab.TODAYS}
          loadMore={loadMore}
        />
      </InboxSection>
    </>
  );
};
