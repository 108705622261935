import moment from 'moment';

import {
  EditInputData,
  PropType,
  Validator,
} from './components/DemographicsInputControl';
import { genderOption, stateOption } from './constants';
import { GetMember_getMember as Member } from './generated/GetMember';

export function extractDemographics(
  member: Member,
): {
  profile: EditInputData[];
  address: EditInputData[];
  emergencyContact: EditInputData[];
} {
  const {
    billingFirstName,
    email,
    phone,
    billingLastName,
    gender,
    dateOfBirth,
    preferredPharmacy,
  } = member;

  const address = member.address || {
    city: '',
    state: '',
    street: '',
    zip: '',
  };
  const emergencyContact = member.emergencyContact || {
    name: '',
    phone: '',
    relationship: '',
  };
  const profileData: EditInputData[] = [
    {
      label: 'First Name',
      propName: 'firstName',
      propType: PropType.TEXT,
      propValue: billingFirstName || '',
      validator: requiredInputValidator,
    },
    {
      label: 'Last Name',
      propName: 'lastName',
      propType: PropType.TEXT,
      propValue: billingLastName || '',
      validator: requiredInputValidator,
    },
    {
      label: 'Email',
      propName: 'email',
      propType: PropType.TEXT,
      propValue: email || '',
      validator: requiredInputValidator,
    },
    {
      label: 'Phone',
      propName: 'phoneNumber',
      propType: PropType.TEXT,
      propValue: phone || '',
      validator: requiredInputValidator,
    },
    {
      label: 'Date of Birth',
      propName: 'dateOfBirth',
      propType: PropType.DATE,
      propValue: dateOfBirth || '',
      validator: dateValidator,
    },
    {
      label: 'Gender',
      options: genderOption,
      propName: 'gender',
      propType: PropType.SELECT,
      propValue: gender || '',
      validator: requiredInputValidator,
    },
    {
      label: 'Preferred Pharmacy',
      propName: 'preferredPharmacy',
      propType: PropType.TEXT,
      propValue: preferredPharmacy || '',
      validator: requiredInputValidator,
    },
  ];
  const addressData: EditInputData[] = [
    {
      label: 'Street',
      propName: 'address',
      propType: PropType.TEXT,
      propValue: address.street || '',
      validator: requiredInputValidator,
    },
    {
      label: 'City',
      propName: 'city',
      propType: PropType.TEXT,
      propValue: address.city || '',
      validator: requiredInputValidator,
    },
    {
      label: 'Zip code',
      propName: 'zipCode',
      propType: PropType.TEXT,
      propValue: address.zip || '',
      validator: requiredInputValidator,
    },
    {
      label: 'State',
      options: stateOption,
      propName: 'state',
      propType: PropType.SELECT,
      propValue: address.state || '',
      validator: requiredInputValidator,
    },
  ];
  const emergencyContactData: EditInputData[] = [
    {
      label: 'Name',
      propName: 'emergencyContactName',
      propType: PropType.TEXT,
      propValue: emergencyContact.name || '',
      validator: requiredInputValidator,
    },
    {
      label: 'Phone',
      propName: 'emergencyContactPhone',
      propType: PropType.TEXT,
      propValue: emergencyContact.phone || '',
      validator: requiredInputValidator,
    },
    {
      label: 'Relationship',
      propName: 'emergencyContactRelationship',
      propType: PropType.TEXT,
      propValue: emergencyContact.relationship || '',
      validator: requiredInputValidator,
    },
  ];
  return {
    address: addressData,
    emergencyContact: emergencyContactData,
    profile: profileData,
  };
}

export function requiredInputValidator(text?: string): Validator {
  if (!text) return { error: 'This field is required', isValid: false };
  return { isValid: true };
}

export function dateValidator(dateStr?: string | null): Validator {
  const date = moment(dateStr || '');
  if (!date.isValid()) {
    return { error: 'Invalid date format', isValid: false };
  }
  return { isValid: true };
}
