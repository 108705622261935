import gql from 'graphql-tag';

export const getMemberChartIndicatorsQuery = gql`
  query GetMemberChartIndicators($memberId: ID!) {
    getMemberChartIndicators(input: { id: $memberId }) {
      surveyScores
      intake
    }
  }
`;

export const updateMemberChartIndicatorMutation = gql`
  mutation UpdateMemberChartIndicator(
    $input: UpdateMemberChartIndicatorInput!
  ) {
    updateMemberChartIndicator(input: $input) {
      ok
    }
  }
`;
