import {
  FieldDefinitions,
  stringField,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import { FocusArea } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/FocusArea';

export const fieldDefs: FieldDefinitions<FocusArea> = {
  appointmentId: stringField(),
  sessionFocusInClientWords: stringField(),
};

export const validate = (data: FocusArea | null) =>
  data !== null && validateForm(data, fieldDefs);
