import { EventType } from 'app/EventType';
import { DropInAlertType } from 'app/state/features/structuredCoaching/schema';
import { createAction } from 'redux-reloaded';

export const enableDropInNotificationDisplayed = createAction(
  EventType.ENABLE_DROP_IN_NOTIFICATION_DISPLAYED,
  (payload: {
    listenerId: string | null;
    displayedAt?: ISODateString;
    motive: DropInAlertType;
    accepted: boolean;
  }) => ({
    ...payload,
  }),
);

export const disableDropInNotificationDisplayed = createAction(
  EventType.DISABLE_DROP_IN_NOTIFICATION_DISPLAYED,
  (payload: {
    listenerId: string | null;
    displayedAt?: ISODateString;
    motive: DropInAlertType;
    accepted: boolean;
  }) => ({
    ...payload,
  }),
);
