import { InboxItem, InboxSections, Results } from 'app/inbox/types';
import { State } from 'app/state/schema';

import { initConversationsInInbox, updateTabSection } from '../actions';
import { ActionHandler } from './types';

export const onSetSearchResult = ({
  action,
  redux,
}: ActionHandler<{
  sections: {
    [k in
      | InboxSections.PAST
      | InboxSections.CLOSED
      | InboxSections.SCHEDULED]?: Results;
  };
}>) => {
  const { sections } = action.payload;
  const inboxItems: InboxItem[] = Object.values(sections).flatMap(
    (_) => _?.items ?? [],
  );
  const search: State['inbox']['search'] = {
    [InboxSections.PAST]: undefined,
    [InboxSections.CLOSED]: undefined,
    [InboxSections.SCHEDULED]: undefined,
  };

  Object.entries(sections).forEach((_) => {
    const [section, value] = _ as [InboxSections, Results];
    if (!value) return;
    if (
      section === InboxSections.PAST ||
      section === InboxSections.CLOSED ||
      section === InboxSections.SCHEDULED
    ) {
      search[section] = {
        cursor: value.cursor,
        hasMore: value.hasMore,
        ids: new Set([...value.items.map((i) => i.id)]),
      };
    }
  });
  redux.dispatch(initConversationsInInbox({ inboxItems }));
  redux.dispatch(updateTabSection({ search }));
};
