import { Button } from '@mui/material';
import { Box } from 'shared-components/Box';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';
import { teenIntakeRoute } from 'app/top-nav/Routes';
import { ReactComponent as NewTabIcon } from 'assets/ic-open-in-new-tab.svg';
import React from 'react';

import styles from './DependentIntakeTab.module.scss';

type Props = {
  patientId: string;
};

export function DependentIntakeTab({ patientId }: Props) {
  return (
    <Grid data-testid="teenIntakeTab">
      <Columns widths={[COLUMN_WIDTH.FULL]}>
        <Box title="Teen Intake">
          <div className={styles.teenIntakeTab}>
            The Teen Intake includes responses to parent/guardian screener
            questions, teen responses, and a clinical intake completed by the
            parent/guardian.
          </div>
          <Button
            data-testid="teenIntakeButton"
            href={teenIntakeRoute(patientId)}
            target="_blank"
            size="small"
            variant="contained"
            startIcon={<NewTabIcon />}
            fullWidth={false}
            rel="noopener noreferrer"
          >
            Open Teen Intake
          </Button>
        </Box>
      </Columns>
    </Grid>
  );
}
