export enum DropInAlertType {
  LOW_SESSION_LOAD = 'low_session_load',
  HIGH_SESSION_LOAD = 'high_session_load',
  ITMS_TEAM_AT_MAX_LOAD = 'itms_team_at_max_load',
}

export type State = {
  currentAlert?: DropInAlertType;
  displayedAt?: ISODateString;
};

export const getInitialDropInAlertState = (): State => ({
  currentAlert: undefined,
  displayedAt: undefined,
});
