import { ClinicalAppointmentNoteType, NoteType } from 'generated/globalTypes';
import React, { MouseEvent } from 'react';

import styles from './TherapyNoteTemplateDropdown.module.scss';

export type Option = {
  label: string;
  template: ClinicalAppointmentNoteType | NoteType;
};

export function NoteTemplateDropdown(props: {
  options: Option[];
  onClick: (noteType: ClinicalAppointmentNoteType | NoteType) => void;
}) {
  const links = props.options.map(({ label, template }) => (
    <div
      role="presentation"
      key={template}
      data-testid={`select-${template}-template`}
      className={styles.item}
      onClick={(e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        props.onClick(template);
      }}
    >
      {label}
    </div>
  ));

  return <div className={styles.root}>{links}</div>;
}
