import {
  AppointmentType,
  ClinicalAppointmentStatus,
  ClinicalNoteStatus,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments } from '@headspace/carehub-graphql/dist/patients/generated/GetClinicalAppointmentsForMember';
import {
  NonAppointmentNotesForClinicalView,
  NotesItemResponse,
} from 'app/coach/coach-notes/CoachNotesTypes';
import { useDispatch } from 'app/state';
import { setSelectedNote } from 'app/state/coach-notes/coachNotesSlice';
import { useSortableColumns } from 'hooks/useSortableColumns';
import Messages from 'i18n/en/appointment.json';
import React, { createContext, useContext, useState } from 'react';
import { MenuItem } from 'shared-components/menu/FilterMenu';
import { SortableColumn } from 'shared-components/table/SortableTableHead';

import { useAppointmentsAndNotesFilters } from './hooks';
import { defaultColumns } from './table/constants';

export type AppointmentsAndNotes =
  | GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments
  | NonAppointmentNotesForClinicalView;

export type AppointmentsAndNotesCursor =
  | GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments
  | NotesItemResponse
  | null;

export type AppointmentsAndNotesAPIContextProps = {
  table: {
    appointmentsAndNotes: AppointmentsAndNotes[];
    setAppointmentsAndNotes: (
      appointmentsAndNotes: AppointmentsAndNotes[],
    ) => void;
    columns: SortableColumn[];
    changeColumnSort: (column: SortableColumn) => void;
    filters: {
      activeAppStatusFilters: ClinicalAppointmentStatus[];
      activeTypeFilters: AppointmentType[];
      activeNoteStatusFilters: ClinicalNoteStatus[];
      appStatusFilters: MenuItem[];
      noteStatusFilters: MenuItem[];
      typeFilters: MenuItem[];
      unreadFilter: MenuItem[];
      toggleTypeFilters: (key: string) => void;
      toggleAppStatusFilters: (key: string) => void;
      toggleNoteStatusFilters: (key: string) => void;
    };
  };
  noteDetails: {
    cursor: AppointmentsAndNotesCursor;
    setCursor: (cursor: AppointmentsAndNotesCursor) => void;
  };
};

const AppointmentsAndNotesAPIContext = createContext<AppointmentsAndNotesAPIContextProps | null>(
  null,
);

const useAppointmentsAndNotesAPI = (): AppointmentsAndNotesAPIContextProps => {
  const api = useContext(AppointmentsAndNotesAPIContext);
  if (api !== null) {
    return api;
  }
  throw new Error(Messages.appointmentAndNotesContextAPI);
};

const AppointmentsAndNotesAPIProvider: React.FC = ({ children }) => {
  // empty array state for appointments and notes table
  const [appointmentsAndNotes, setAppointmentsAndNotes] = useState<
    AppointmentsAndNotes[]
  >([]);

  // null state for note details -- no active note
  const [cursor, setCursor] = useState<AppointmentsAndNotesCursor>(null);

  // in order to render shared coach notes, we need to store the note in redux state
  const dispatch = useDispatch();
  dispatch(setSelectedNote({ selectedNote: cursor }));

  // default table columns state
  const [columns, changeColumnSort] = useSortableColumns(defaultColumns);

  // initial filters state
  const {
    activeAppStatusFilters,
    activeTypeFilters,
    activeNoteStatusFilters,
    appStatusFilters,
    noteStatusFilters,
    typeFilters,
    unreadFilter,
    toggleTypeFilters,
    toggleAppStatusFilters,
    toggleNoteStatusFilters,
  } = useAppointmentsAndNotesFilters();

  return (
    <AppointmentsAndNotesAPIContext.Provider
      value={{
        noteDetails: {
          cursor,
          setCursor,
        },
        table: {
          appointmentsAndNotes,
          changeColumnSort,
          columns,
          filters: {
            activeAppStatusFilters,
            activeNoteStatusFilters,
            activeTypeFilters,
            appStatusFilters,
            noteStatusFilters,
            toggleAppStatusFilters,
            toggleNoteStatusFilters,
            toggleTypeFilters,
            typeFilters,
            unreadFilter,
          },
          setAppointmentsAndNotes,
        },
      }}
    >
      {children}
    </AppointmentsAndNotesAPIContext.Provider>
  );
};

export default AppointmentsAndNotesAPIContext;
export { AppointmentsAndNotesAPIProvider, useAppointmentsAndNotesAPI };
