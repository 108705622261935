import { ApolloClient } from '@apollo/client';
import {
  ClinicalAppointmentStatus,
  ClinicalAppointmentType,
  PaginationInput,
} from 'generated/globalTypes';
import Messages from 'i18n/en/patients.json';

import {
  GetClinicalAppointmentsForMember,
  GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments,
  GetClinicalAppointmentsForMemberVariables,
} from './generated/GetClinicalAppointmentsForMember';
import { queryApptsForMemberSchedule } from './queries';

export const getMemberAppointments = async (
  apolloClient: ApolloClient<any>,
  patientId: string,
  appointmentStatus?: ClinicalAppointmentStatus[],
  pagination?: PaginationInput,
  appointmentTypes?: ClinicalAppointmentType[],
): Promise<
  GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments[]
> => {
  const { errors, data } = await apolloClient.query<
    GetClinicalAppointmentsForMember,
    GetClinicalAppointmentsForMemberVariables
  >({
    errorPolicy: 'all',
    query: queryApptsForMemberSchedule,
    variables: {
      input: {
        filters: {
          appointmentStatus_In: appointmentStatus,
          appointmentType_In: appointmentTypes,
        },
        memberId: patientId,
        pagination,
      },
    },
  });

  if (errors) {
    throw new GetMemberAppointmentsMessageError(
      Messages.failureToSearchMemberAppointments,
      errors,
    );
  }

  if (!data) {
    throw new Error(`${Messages.noAppointmentsForMember} ${patientId}`);
  }

  return data.getClinicalAppointmentsForMember?.appointments || [];
};

class GetMemberAppointmentsMessageError extends Error {
  constructor(public message: string, public errors: any) {
    super(message);
  }
}
