import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GetMemberChartIndicators,
  GetMemberChartIndicatorsVariables,
} from 'app/queries/generated/GetMemberChartIndicators';
import {
  UpdateMemberChartIndicator,
  UpdateMemberChartIndicatorVariables,
} from 'app/queries/generated/UpdateMemberChartIndicator';
import {
  getMemberChartIndicatorsQuery,
  updateMemberChartIndicatorMutation,
} from 'app/queries/GetMemberChartIndicators';
import { useLogger } from 'app/state/log/useLogger';
import { ChartAreaViewed } from 'generated/globalTypes';
import { useEffect } from 'react';

export interface MemberChartIndicatorsHookState {
  updateSurveyScoreIndicator:
    | ((input: { memberId: string }) => Promise<UpdateMemberChartIndicator>)
    | undefined;
  updateIntakeIndicator:
    | ((input: { memberId: string }) => Promise<UpdateMemberChartIndicator>)
    | undefined;
  showSurveyScoreIndicator: boolean;
  showIntakeIndicator: boolean;
}
interface Result {
  showSurveyScoreIndicator: boolean;
  showIntakeIndicator: boolean;
  updateSurveyScoreIndicator: () => Promise<void>;
  updateIntakeIndicator: () => Promise<void>;
}

export const useMemberChartIndicators = (memberId: string): Result => {
  const logger = useLogger();

  const [executor, { data, loading, error, refetch }] = useLazyQuery<
    GetMemberChartIndicators,
    GetMemberChartIndicatorsVariables
  >(getMemberChartIndicatorsQuery);

  const [updateMemberChartIndicator] = useMutation<
    UpdateMemberChartIndicator,
    UpdateMemberChartIndicatorVariables
  >(updateMemberChartIndicatorMutation);

  useEffect(() => {
    if (memberId) {
      void executor({ variables: { memberId } });
    }
  }, [memberId]);

  const updateIndicator = async (chartAreaViewed: ChartAreaViewed) => {
    try {
      await updateMemberChartIndicator({
        variables: {
          input: {
            chartAreaViewed,
            memberId,
          },
        },
      });
    } catch (error) {
      logger.error(
        new Error(
          'useMemberChartIndicators: Unable to update member chart indicator',
        ),
        {
          chartAreaViewed,
          error,
          memberId,
        },
      );
    }
  };

  const updateSurveyScoreIndicator = async () => {
    await updateIndicator(ChartAreaViewed.survey_scores).then(() => {
      return refetch({ memberId });
    });
  };

  const updateIntakeIndicator = async () => {
    await updateIndicator(ChartAreaViewed.intake).then(() => {
      return refetch({ memberId });
    });
  };

  const surveyScores = data?.getMemberChartIndicators?.surveyScores ?? false;
  const intake = data?.getMemberChartIndicators?.intake ?? false;

  return {
    showIntakeIndicator: intake && !loading && !error,
    showSurveyScoreIndicator: surveyScores && !loading && !error,
    updateIntakeIndicator,
    updateSurveyScoreIndicator,
  };
};
