import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import { Button } from 'shared-components/button/Button';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import useTaskMutations from 'app/member-chart-cards/tasks/useTaskMutations';
import { hideOrShowSnackNotification } from 'app/state/notifications/actions';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import { LockNoteDialog } from './LockNoteDialog';
import { NoteType } from './NoteHeader';
import styles from './NoteHeader.module.scss';

type NoteHeaderProps = {
  status: Metadata_NoteStatus;
  noteType: NoteType;
  memberId: string;
  memberName: string;
  clinicianName: string;
  apptDate?: string;
  disabled?: boolean;
  onNoteLocked: () => Promise<void>;
  onValidate?: () => Promise<boolean>;
};

export function NoteHeaderV2(props: NoteHeaderProps) {
  const {
    noteType,
    status,
    disabled = true,
    memberId,
    memberName,
    clinicianName,
    apptDate,
  } = props;
  const history = useHistory();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <span className={styles.backBtn}>
          <IconButton onClick={() => history.goBack()} size="large">
            <CloseIcon />
          </IconButton>
          <span className={styles.text}>{`${noteType} Note`}</span>
        </span>
        <div className={styles.content}>
          <ul>
            <li>
              <span className={styles.item}>
                Patient:{' '}
                <strong data-testid="memberInfo">
                  {memberName} ({memberId})
                </strong>
              </span>
            </li>
            <li>
              <span className={styles.item}>
                Creator:{' '}
                <strong data-testid="clinician">{clinicianName}</strong>
              </span>
            </li>
            <li>
              <span className={styles.item}>
                Status:{' '}
                <strong data-testid="status">{formatStatus(status)}</strong>
              </span>
            </li>
            {apptDate && (
              <li>
                <span className={styles.item}>
                  Appt Date: <strong data-testid="apptDate">{apptDate}</strong>
                </span>
              </li>
            )}
          </ul>
        </div>
        <div className={styles.action}>
          <ButtonWithConfirmation
            disabled={disabled}
            memberId={memberId}
            memberName={memberName}
            onNoteLocked={props.onNoteLocked}
            onValidate={props.onValidate}
          />
        </div>
      </div>
      <Divider />
    </div>
  );
}

type ButtonProps = Pick<
  NoteHeaderProps,
  'onValidate' | 'disabled' | 'onNoteLocked' | 'memberId' | 'memberName'
>;

export function ButtonWithConfirmation(props: ButtonProps) {
  const { memberId, memberName } = props;
  const dispatch = useDispatch();
  const [openNoteDialog, setLockNoteDialogOpen] = useState(false);
  const [isLocking, setLocking] = useState(false);
  const {
    transientFeatureFlags: { enable_tasks_v2: enableTasksV2 },
  } = useFeatureFlags();
  const { dismissNoteTasks } = useTaskMutations({ memberId });

  const showConfirmationDialog = async () => {
    if (props.onValidate === undefined || (await props.onValidate())) {
      setLockNoteDialogOpen(true);
    }
  };

  const onClose = () => setLockNoteDialogOpen(false);

  const onNoteLocked = async () => {
    try {
      onClose();
      setLocking(true);
      await props.onNoteLocked();
      dispatch(
        hideOrShowSnackNotification({
          message: 'Note has been signed & locked',
          type: 'success',
        }),
      );
      if (enableTasksV2) {
        dismissNoteTasks();
      }
    } catch (e) {
      dispatch(
        hideOrShowSnackNotification({
          message: 'Unable to lock note',
          type: 'error',
        }),
      );
    } finally {
      setLocking(false);
    }
  };
  return (
    <>
      <LockNoteDialog
        data-testid="lockNoteModal"
        memberId={memberId}
        memberName={memberName}
        open={openNoteDialog}
        onNoteLocked={onNoteLocked}
        onClose={onClose}
      />
      <Button
        data-testid="signBtn"
        testId="signBtn"
        disabled={props.disabled || isLocking}
        className={styles.signBtn}
        onClick={showConfirmationDialog}
      >
        {isLocking ? <ClipLoader color="#fff" size={12} /> : 'Sign & Lock'}
      </Button>
    </>
  );
}

function formatStatus(status: Metadata_NoteStatus): string {
  switch (status) {
    case Metadata_NoteStatus.draft_note: {
      return 'Draft';
    }

    case Metadata_NoteStatus.undefined_note_status: {
      return 'Draft';
    }

    case Metadata_NoteStatus.signed_and_locked_note: {
      return 'Signed & Locked';
    }

    default: {
      throw new Error(`Note status: ${status} isn't valid`);
    }
  }
}
