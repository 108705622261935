export const Labels = {
  comments: {
    id: 'comments',
    label: 'Comments on medical ROS',
    placeholder: 'Type here...',
  },
  constitutional: {
    deniesLabel: 'Not Endorsing Constitutional symptoms',
    id: 'constitutional',
    label: 'Constitutional',
  },
  endocrine: {
    deniesLabel: 'Not Endorsing Endocrine symptoms',
    id: 'endocrine',
    label: 'Endocrine',
  },
  musculoskeletal: {
    deniesLabel: 'Not Endorsing Musculoskeletal symptoms',
    id: 'musculoskeletal',
    label: 'Musculoskeletal',
  },
  neurological: {
    deniesLabel: 'Not Endorsing Neurological symptoms',
    id: 'neurological',
    label: 'Neurological',
  },
};
