import { InboxSections } from 'app/inbox/types';
import risksIcon from 'assets/inbox/landing-screen-icon.png';
import appointmentIcon from 'assets/inbox/sections/appointment.png';
import completedIcon from 'assets/inbox/sections/completed.png';
import tasksIcon from 'assets/inbox/sections/tasks.png';

import styles from './EmptyInboxSection.module.scss';

const emptyStateLabelAndImageMap: {
  [section in InboxSections]: {
    image?: string;
    label: string;
  };
} = {
  [InboxSections.RISKS]: { image: risksIcon, label: 'All clear!' },
  [InboxSections.TASKS]: {
    image: tasksIcon,
    label: 'You cleared all the tasks!',
  },
  [InboxSections.SCHEDULED_CHECKINS]: {
    image: tasksIcon,
    label: 'You cleared all the checkins!',
  },
  [InboxSections.COMPLETED]: { image: completedIcon, label: 'You can do it!' },
  [InboxSections.SCHEDULED]: { label: 'No member scheduled' },
  [InboxSections.CLOSED]: { label: 'No closed members' },
  [InboxSections.PAST]: { label: 'No past members' },
  [InboxSections.CONVERSATIONS]: {
    image: appointmentIcon,
    label: 'No members at the moment!',
  },
  [InboxSections.CONVERSATIONS_AND_TASKS]: {
    image: appointmentIcon,
    label: 'No members at the moment!',
  },
  [InboxSections.APPOINTMENTS]: {
    image: appointmentIcon,
    label: 'No more members today!',
  },
};

interface EmptyInboxSectionProps {
  section: InboxSections;
}

export function EmptyInboxSection({
  section,
}: Readonly<EmptyInboxSectionProps>) {
  const { image, label } = emptyStateLabelAndImageMap[section];
  return (
    <div
      data-testid={`empty-inbox-section-${section.toLowerCase()}`}
      className={styles.emptySectionWrapper}
    >
      {image && <img src={image} alt={label} />}
      <p>{label}</p>
    </div>
  );
}
