import React from 'react';

import styles from './TabBar.module.scss';
import { TabLabel } from './TabLabel';

export type Tab = {
  label: string;
  onClick: () => void;
  active?: boolean;
  isNewFeature?: boolean;
  isNotificationVisible?: boolean;
};

type Props = {
  tabs: Tab[];
};

export function TabBar(props: Props) {
  const tabs = props.tabs.map(
    ({
      label,
      onClick,
      active,
      isNewFeature = false,
      isNotificationVisible = false,
    }) => {
      return (
        <li data-testid={`tab-${label.replace(/ /g, '-')}`} key={label}>
          <button onClick={onClick} className={active ? styles.active : ''}>
            <TabLabel
              isNewFeature={isNewFeature}
              isNotificationVisible={isNotificationVisible}
            >
              {label}
            </TabLabel>
          </button>
        </li>
      );
    },
  );

  return <ul className={styles.tabs}>{tabs}</ul>;
}
