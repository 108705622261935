// TODO: remove enum here and replace with Stage from ginger.io/core-ui package
export enum Stage {
  PROD = 'PROD',
  DEV = 'DEV',
  LOCAL = 'LOCAL', // local UI with staging graphQL
  LOCAL_GRAPHQL = 'LOCAL_GRAPHQL', // local UI + local graphQL
}

export function getStage(): Stage {
  const stage = process.env.REACT_APP_STAGE;

  if (stage === undefined) {
    console.warn(
      'No REACT_APP_STAGE environment variable set. Using Stage.LOCAL',
    );
    return Stage.LOCAL;
  }
  if (isStage(stage)) {
    return Stage[stage];
  }
  throw new Error(
    "Invalid stage - make sure to set REACT_APP_STAGE='...' (env var).",
  );
}

function isStage(stage: string): stage is Stage {
  return stage in Stage;
}

export const isLocal = () =>
  !process.env.REACT_APP_STAGE ||
  process.env.REACT_APP_STAGE === Stage.LOCAL_GRAPHQL ||
  process.env.REACT_APP_STAGE === Stage.LOCAL;

export function isTest() {
  return process.env.NODE_ENV === 'test';
}

export const stage = getStage();
