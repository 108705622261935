import { COLUMN_WIDTH, Columns, Grid } from '@ginger.io/core-ui';
import { Stack } from '@mui/material';
import { useAppState } from 'app/state';
import { clickCareMetricsMonthlyPickerAction } from 'app/state/amplitude/actions/careMetrics';
import { TransientFeatureFlag, useFeatureFlags } from 'hooks/useFeatureFlags';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tab, TabBar } from 'shared-components/tabs/TabBar';
import { getTimezone } from 'utils/dateTime';

import styles from './CareMetrics.module.scss';
import MonthPicker from './MonthPicker';
import SessionsOffered from './SessionsOfferedGraph';
import { DateRange } from './types';
import { getStartAndEndOfMonth } from './utils';

export enum Tabs {
  CARE_METRICS,
  RESOURCES,
  SETTINGS,
}

interface DashboardContainerProps {
  clinicianId: string;
}

export function DashboardContainer(props: DashboardContainerProps) {
  const { clinicianId } = props;
  const localTimezone = useAppState(({ user: { timezone } }) =>
    getTimezone(timezone),
  );
  const today = moment();

  const [activeTab, setActiveTab] = useState(Tabs.CARE_METRICS);
  const [dateRange, setDateRange] = useState<DateRange>(() => {
    const dates = getStartAndEndOfMonth(today.toDate(), localTimezone);
    return {
      endDate: dates.endDate.format(),
      startDate: dates.startDate.format(),
    };
  });
  const featureFlags = useFeatureFlags();
  const isCompanyTargetEnabled =
    featureFlags.transientFeatureFlags[
      TransientFeatureFlag.ENABLE_CARE_METRICS_COMPANY_TARGET
    ];
  const isSessionOfferedMetricsEnabled =
    featureFlags.transientFeatureFlags[
      TransientFeatureFlag.ENABLE_SESSIONS_OFFERED_METRICS
    ];

  const dispatch = useDispatch();
  const tabs: Tab[] = [
    {
      active: activeTab === Tabs.CARE_METRICS,
      label: 'Care Metrics',
      onClick: () => setActiveTab(Tabs.CARE_METRICS),
    },
  ];

  const onDateChange = (date: Moment) => {
    const dates = getStartAndEndOfMonth(date.toDate(), localTimezone);
    setDateRange({
      endDate: dates.endDate.format(),
      startDate: dates.startDate.format(),
    });
    dispatch(
      clickCareMetricsMonthlyPickerAction({
        clinicianId,
        month: date.format('MMMM YYYY'),
      }),
    );
  };

  return (
    <>
      {isSessionOfferedMetricsEnabled && (
        <div data-test-id="care-metrics-section">
          <nav className={styles.nav}>
            <div className={styles.header}>
              <TabBar tabs={tabs} />
            </div>
          </nav>
          <Grid className={styles.grid}>
            <Columns widths={[COLUMN_WIDTH.HALF, COLUMN_WIDTH.HALF]}>
              <Stack spacing={2}>
                <MonthPicker value={today.format()} onChange={onDateChange} />
                <SessionsOffered
                  enableCompanyTarget={isCompanyTargetEnabled}
                  clinicianId={clinicianId}
                  startDate={dateRange.startDate}
                  endDate={dateRange.endDate}
                />
              </Stack>
            </Columns>
          </Grid>
        </div>
      )}
    </>
  );
}

export default DashboardContainer;
