import { ClinicianRole } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/ClinicianRole';
import {
  OutOfSessionNote,
  OutOfSessionNote_ContactReason,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/OutOfSessionNote';
import { VaultItem_SchemaType } from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import { Typography } from '@mui/material';
import { BulletSeparator } from 'shared-components/BulletSeparator';
import styles from 'app/sortable-table/note-card/NoteTableCard.module.scss';
import { TagAndRole } from 'app/sortable-table/note-card/TagAndRole';
import { useAppState } from 'app/state';
import { getTimezone } from 'utils/dateTime';
import { labelFromEnumValue } from 'utils/notes';
import { OutOfSessionOrTerminationNote } from 'app/vault/hooks/NonAppointments/useOutOfSessionAndTerminationNotes';
import moment from 'moment';
import React from 'react';

const roleMap: Record<ClinicianRole, string | undefined> = {
  [ClinicianRole.UNRECOGNIZED]: undefined,
  [ClinicianRole.undefined_role]: undefined,
  [ClinicianRole.psychiatrist]: 'Psychiatry',
  [ClinicianRole.psychiatrist_supervisor]: 'Psychiatry',
  [ClinicianRole.therapist]: 'Therapy',
  [ClinicianRole.therapist_supervisor]: 'Therapy',
};

export const NoteTypeCard = (props: {
  note: OutOfSessionOrTerminationNote;
}) => {
  const { timezone } = useAppState((_) => _.user);
  const localTimezone = getTimezone(timezone);

  const { note } = props;

  const { date, authorName, contents } = note;
  const { clinicianRole } = contents.note;
  const start = moment(date);
  const startDate = start.format('MMM D, YYYY');

  const noteReason =
    contents.schema === VaultItem_SchemaType.vault_clinical_notes_out_of_session
      ? labelFromEnumValue(
          OutOfSessionNote_ContactReason,
          (note.contents.note as OutOfSessionNote).reasonForContact,
        )
      : '';

  return (
    <article
      data-testid={`note-row-${note.vaultItemId}`}
      className={styles.row}
    >
      <Typography
        data-testid="note-type"
        component="div"
        className={styles.notesSessionType}
      >
        <BulletSeparator showBullet={false}>
          <strong>
            {contents.schema ===
            VaultItem_SchemaType.vault_clinical_notes_out_of_session
              ? 'Out of Session'
              : 'Termination'}
          </strong>
        </BulletSeparator>
        <BulletSeparator>{noteReason}</BulletSeparator>
      </Typography>
      <Typography
        component="div"
        data-testid="note-date"
        className={styles.date}
      >
        {startDate} {localTimezone}
      </Typography>
      <TagAndRole careTeamMember={authorName} role={roleMap[clinicianRole]} />
    </article>
  );
};
