import { TimetokensMap } from 'app/coach/chat/types';

export const mergeTimetokensMapByOverriding = (
  existingMap: TimetokensMap,
  updatedMap: TimetokensMap,
) => {
  return {
    ...existingMap,
    ...updatedMap,
  };
};
