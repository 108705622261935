import {
  FieldDefinitions,
  validate as validateFields,
} from '@ginger.io/react-use-form';
import TableCell from '@mui/material/TableCell';
import { useOnMount } from 'hooks/useOnMount';
import { V2Table } from 'shared-components/table/V2Table';
import { V2TableBody } from 'shared-components/table/V2TableBody';
import { V2TableHead } from 'shared-components/table/V2TableHead';
import React, { useState } from 'react';

import {
  ColumnDefinition,
  PsychiatryHistoryRowV2,
} from './PsychiatryHistoryRowV2';
import styles from './PsychiatryHistoryTable.module.scss';

export type Props<T> = {
  initialValue?: T[];
  fieldDefs: FieldDefinitions<T>;
  columns: ColumnDefinition<T>[];
  getBlankRecord: () => T;
  disabled?: boolean;
  renderEmptyRow?: boolean;
  onChange: (values: T[], isValid: boolean) => void;
  addButtonLabel: string;
};

type Row<T> = [string, T, boolean | null];

export function TableV2<T>(props: Props<T>) {
  const newRowId = 'newRow';
  const {
    columns: columnDefs,
    fieldDefs,
    renderEmptyRow = false,
    initialValue = [],
    getBlankRecord,
    addButtonLabel,
  } = props;
  const [rows, setRows] = useState<Row<T>[]>([]);

  const getRecords = (records: Row<T>[]): T[] =>
    records
      .filter(
        ([key, , isValid]) =>
          key !== newRowId || (key === newRowId && isValid != null),
      )
      .map(([, value]) => value);

  const updateRows = (key: string, value: T, isValid: boolean) => {
    const updatedRows = [...rows];
    const rowId = updatedRows.findIndex(([_]) => _ === key);
    if (rowId >= 0) {
      updatedRows[rowId][1] = value;
      updatedRows[rowId][2] = isValid;
    }
    return updatedRows;
  };

  const onSave = (value: T) => {
    const updatedRows = [...rows];
    updatedRows.pop(); // remove the last element with rowId = 'newRow'
    const lastRowId = updatedRows.length
      ? parseInt(updatedRows[updatedRows.length - 1][0])
      : 0;
    updatedRows.push([`${lastRowId + 1}`, value, true]);
    updatedRows.push([newRowId, getBlankRecord(), null]); // reset form row
    setRows(updatedRows);
  };

  const onRemove = (key: string) => {
    const updatedRows = rows.filter(([id]) => key !== id);
    setRows(updatedRows);
    props.onChange(getRecords(updatedRows), checkValidity(updatedRows));
  };

  const onChange = (value: T, key: string) => {
    const rows = updateRows(key, value, validateFields(value, fieldDefs));
    const records: T[] = getRecords(rows);
    const isValid = checkValidity(rows);
    props.onChange(records, isValid);
  };

  useOnMount(() => {
    const rows: Row<T>[] = initialValue.map((item, rowId) => [
      `${rowId}`,
      item,
      true,
    ]);
    rows.push([newRowId, getBlankRecord(), null]);
    setRows(rows);
  });

  const columns = columnDefs.map(({ label }) => (
    <TableCell
      className={props.disabled ? styles.disabled : ''}
      key={`column-header-label-${label}`}
    >
      {label}
    </TableCell>
  ));

  const rowData: Row<T>[] =
    renderEmptyRow && !props.disabled
      ? [[newRowId, getBlankRecord(), null]]
      : rows;
  const tableRows = rowData.map(([key, value], index) => {
    const isNew = key === newRowId;
    return (
      <PsychiatryHistoryRowV2
        key={!isNew ? key : `${key}-${index}`}
        fieldDefs={fieldDefs}
        columnsDefinition={columnDefs}
        initialValue={value}
        onRemove={() => onRemove(key)}
        onSave={(value) => {
          if (isNew) onSave(value);
        }}
        onChange={(value) => onChange(value, key)}
        isNew={isNew}
        addButtonLabel={addButtonLabel}
        disabled={props.disabled}
        testId={key}
      />
    );
  });
  return (
    <div className={styles.root}>
      <V2Table className={styles.table}>
        <V2TableHead>{columns}</V2TableHead>
        <V2TableBody>{tableRows}</V2TableBody>
      </V2Table>
    </div>
  );
}

function checkValidity<T>(rows: Row<T>[]) {
  return rows.every(([, , isValid]) => isValid || isValid === null);
}
