import {
  TreatmentGoal as Goal,
  TreatmentGoal_GoalStatus,
  TreatmentGoal_GoalType,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/TreatmentGoal';
import { Width } from 'types/StyleTypes';
import { EnumDropdown } from 'app/notes-ui/forms/form-controls/EnumDropdown';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { TextAreaWithLabel } from 'app/notes-ui/forms/form-controls/TextArea';
import { Labels } from 'app/notes-ui/therapy/progress/treatment-plan/constants';
import { SectionDivider } from 'app/notes-ui/therapy/shared/SectionDivider';
import { TherapistRole } from 'generated/globalTypes';
import React from 'react';

type Props = {
  disabled?: boolean;
  name: string;
  key: string;
  index: number;
  goal: Goal;
  onChangeGoal: (goal: Goal) => void;
  onRemoveGoal: () => void;
  clinicianType: TherapistRole;
};

const updateGoalField = <T extends keyof Goal>(
  field: T,
  value: Goal[T],
  goal: Goal,
  onChangeGoal: (goal: Goal) => void,
) => {
  const updatedGoal = { ...goal, [field]: value };
  onChangeGoal(updatedGoal);
};

export function TreatmentGoal(props: Props) {
  const { name, disabled, index, goal, clinicianType } = props;

  return (
    <div data-testid={name} className={styles.root}>
      <SectionDivider
        label={`Goal #${index}`}
        isNew={false}
        index={index}
        isRemovable={index > 1}
        onDelete={props.onRemoveGoal}
      />
      <NoteFormControlLabel label={Labels.goals.goalType.label}>
        <EnumDropdown
          disabled={disabled}
          data-testid={name + Labels.goals.goalType.label}
          placeholder={Labels.goals.goalType.placeholder}
          enum={TreatmentGoal_GoalType}
          initialValue={goal.goalType}
          width={Width.FULL}
          onSelect={(value: TreatmentGoal_GoalType) =>
            updateGoalField('goalType', value, goal, props.onChangeGoal)
          }
        />

        {goal.goalType === TreatmentGoal_GoalType.other && (
          <TextAreaWithLabel
            disabled={disabled}
            optional={false}
            name={name + Labels.goals.goalTypeComment.id}
            width={Width.FULL}
            placeholder={Labels.goals.goalTypeComment.placeholder}
            value={goal.otherGoalStatusComment}
            onChange={(value: string) =>
              updateGoalField(
                'otherGoalStatusComment',
                value,
                goal,
                props.onChangeGoal,
              )
            }
            rows={1}
            autoExpand={true}
            error={
              goal.otherGoalStatusComment.trim() === ''
                ? 'Please enter a comment'
                : undefined
            }
          />
        )}
      </NoteFormControlLabel>

      <TextAreaWithLabel
        className={styles.textArea}
        disabled={disabled}
        name={name + Labels.goals.goalDetails.id}
        label={Labels.goals.goalDetails.label}
        tooltip={Labels.goals.goalDetails.tooltip}
        value={goal.goalDetails}
        onChange={(value: string) =>
          updateGoalField('goalDetails', value, goal, props.onChangeGoal)
        }
        placeholder={Labels.goals.goalDetails.placeholder}
        subtext={
          clinicianType === TherapistRole.THERA
            ? Labels.goals.goalDetails.description
            : Labels.goals.goalDetails.subtext
        }
        width={Width.FULL}
        rows={1}
        autoExpand={true}
      />

      <TextAreaWithLabel
        className={styles.textArea}
        disabled={disabled}
        name={name + Labels.goals.actionSteps.id}
        label={Labels.goals.actionSteps.label}
        subtext={Labels.goals.actionSteps.description}
        value={goal.actionPlan}
        onChange={(value: string) =>
          updateGoalField('actionPlan', value, goal, props.onChangeGoal)
        }
        placeholder={Labels.goals.actionSteps.placeholder}
        width={Width.FULL}
        rows={1}
        autoExpand={true}
      />

      <NoteFormControlLabel label={Labels.goals.goalStatus.label}>
        <EnumDropdown
          disabled={disabled}
          data-testid={name + Labels.goals.goalStatus.id}
          placeholder={Labels.goals.goalStatus.placeholder}
          enum={TreatmentGoal_GoalStatus}
          initialValue={goal.goalStatus}
          width={Width.FULL}
          onSelect={(value: TreatmentGoal_GoalStatus) =>
            updateGoalField('goalStatus', value, goal, props.onChangeGoal)
          }
        />

        {[
          TreatmentGoal_GoalStatus.discontinued,
          TreatmentGoal_GoalStatus.met,
        ].includes(goal.goalStatus) && (
          <TextAreaWithLabel
            className={styles.textArea}
            disabled={disabled}
            name={name + Labels.goals.goalStatusUpdateComment.id}
            value={goal.goalStatusUpdateComment}
            onChange={(value: string) =>
              updateGoalField(
                'goalStatusUpdateComment',
                value,
                goal,
                props.onChangeGoal,
              )
            }
            placeholder={Labels.goals.goalStatusUpdateComment.placeholder}
            width={Width.FULL}
            rows={1}
            autoExpand={true}
          />
        )}
      </NoteFormControlLabel>
    </div>
  );
}
