import { URLParams } from 'app/notes-ui/ClinicalNotesScreenRoutes';
import { NoteHeader, NoteType } from 'app/notes-ui/NoteHeader';
import { canCreateOrUpdateNote, canLockNote } from 'app/notes-ui/utils';
import { MemberHeaderBar } from 'app/patients/header/MemberHeaderBar';
import { useAppState } from 'app/state';
import { renderSliceStateResult } from 'app/state/status/RenderSliceState';
import { Status } from 'app/state/status/types/StateSlice';
import { selectUserIdAndRole } from 'app/state/user/selectors';
import { useTherapyProgressNote } from 'app/vault/hooks/useTherapyProgressNote';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

import { TherapyProgressNoteScreen } from './progress/TherapyProgressNoteScreen';

export function TherapyProgressNoteContainer(): ReactElement {
  const noteType = NoteType.THERAPY_PROGRESS;

  const userRoleAndId = useAppState(selectUserIdAndRole);
  const { userId } = userRoleAndId;
  const { appointmentId } = useParams<URLParams>();

  const {
    note,
    isLockable,
    lockNote,
    createOrUpdateSection,
    appointment,
    updateDraftNoteState,
    draftNote,
    deleteDraft,
  } = useTherapyProgressNote(userId!, appointmentId);

  const featureFlags = useFeatureFlags();

  return renderSliceStateResult(note.current, (data) => (
    <>
      {appointment && (
        <>
          <MemberHeaderBar memberId={appointment.member.id} />
          <NoteHeader
            noteType={noteType}
            status={data!.metadata.status}
            onNoteLocked={lockNote}
            deleteDraftNote={deleteDraft}
            disabled={
              !isLockable ||
              !canLockNote(
                featureFlags,
                appointment,
                userRoleAndId,
                data?.permissions!,
              )
            }
            appointment={appointment}
          />
        </>
      )}
      <TherapyProgressNoteScreen
        status={Status.COMPLETE}
        appointmentId={appointmentId}
        memberId={appointment?.member.id}
        note={data!}
        onSubmit={createOrUpdateSection}
        updateDraftNoteState={updateDraftNoteState}
        draftNote={draftNote.current.data!}
        readOnly={
          !canCreateOrUpdateNote(
            featureFlags,
            appointment,
            userRoleAndId,
            data!.metadata.status,
          )
        }
      />
    </>
  ));
}
