import { useEnvironment } from '@ginger.io/core-ui';
import { viewLibraryButtonClicked } from 'app/state/amplitude/actions/content';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { ContentPortalUrlForStage } from './constants';

/**
 * A hook to return helpers dealing with the Content Portal.
 *
 * - openContentPortal() : opens the Content Portal in a new tab
 *
 * @returns helper functions
 */
export function useContentPortalHelpers() {
  const dispatch = useDispatch();
  const stage = useEnvironment();
  const openContentPortal = useMemo(() => {
    return () => {
      dispatch(viewLibraryButtonClicked());
      const url = ContentPortalUrlForStage[stage];

      window.open(url, '_blank', 'noreferrer');
    };
  }, [stage]);

  return {
    openContentPortal,
  };
}
