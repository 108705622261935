import { Field, validate as validateForm } from '@ginger.io/react-use-form';
import { TreatmentGoal as Goal } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/TreatmentGoal';
import { SectionDivider } from 'app/notes-ui/therapy/shared/SectionDivider';
import { ActionModal } from 'app/patients/tabs/document-upload/ActionModal';
import { TherapistRole } from 'generated/globalTypes';
import React, { useState } from 'react';

import { TreatmentGoalFieldDefs } from './schema';
import { TreatmentGoal } from './TreatmentGoal';

type Props = {
  label: string;
  disabled?: boolean;
  goals: Field<Goal[]>;
  clinicianType: TherapistRole;
};

export function GoalContainer(props: Props) {
  const { disabled, goals, clinicianType } = props;
  const [deleteGoalModalOpen, setDeleteGoalModalOpen] = useState(false);
  const [deleteGoalIndex, setDeleteGoalIndex] = useState(-1);

  const onAddGoal = () => {
    goals.setValue([...goals.value, Goal.fromPartial({})]);
  };

  const onRemoveClick = (index: number) => {
    const goalsValue = [...goals.value];
    goalsValue.splice(index, 1);
    goals.setValue(goalsValue);
  };

  const onChangeGoal = (index: number, goal: Goal) => {
    const currentGoals = goals.value;
    const newGoals = [
      ...currentGoals.slice(0, index),
      goal,
      ...currentGoals.slice(index + 1),
    ];
    goals.setValue(newGoals);
  };

  const treatmentGoals = goals.value.map((value, index) => {
    const displayIndex = index + 1;

    return (
      <TreatmentGoal
        key={displayIndex.toString()}
        onChangeGoal={(goal) => {
          onChangeGoal(index, goal);
        }}
        name={`treatmentGoal${index}`}
        index={displayIndex}
        disabled={disabled}
        goal={value}
        onRemoveGoal={() => {
          setDeleteGoalIndex(index);
          setDeleteGoalModalOpen(true);
        }}
        clinicianType={clinicianType}
      />
    );
  });

  return (
    <>
      {treatmentGoals}
      <SectionDivider label="+ Goal" isNew={true} onClick={onAddGoal} />

      <ActionModal
        isOpen={deleteGoalModalOpen}
        isConfirmDisabled={false}
        title="Delete Goal?"
        onClose={() => setDeleteGoalModalOpen(false)}
        onConfirm={() => {
          onRemoveClick(deleteGoalIndex);
          setDeleteGoalModalOpen(false);
          setDeleteGoalIndex(-1);
        }}
        actionLabel="Yes, Delete"
        variation="modernWarning"
      >
        Are you sure you want to delete <b>Goal #{deleteGoalIndex + 1}</b> from
        your note? This action is irreversible.
        <br />
        <b>Note:</b> The rest of your note will not be altered
      </ActionModal>
    </>
  );
}

export function validate(data: Goal[]) {
  return (
    data.length > 0 &&
    data.every((_) => validateForm(_, TreatmentGoalFieldDefs))
  );
}
