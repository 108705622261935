import { CoachingSessionModificationScope } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import {
  GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions_recurrence as RecurrenceGql,
  GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions_sessions as CoachingSession,
} from '@headspace/carehub-graphql/dist/scheduler/generated/GetMemberUpcomingCoachingSessions';

import { Option } from '../../notes-ui/forms/form-controls/RadioButtonGroup';

export enum SessionType {
  FOLLOW_UP = 'Follow-up',
  INTAKE = 'Intake',
  REMINDER = 'Reminder',
}

export type IntakeOrFollowUpSession = Exclude<
  SessionType,
  SessionType.REMINDER
>;

export enum SessionFormat {
  CHAT = 'Chat',
  VIDEO = 'Video',
}

export enum SessionDisplayOptions {
  CHAT_FOLLOW_UP = 'Chat Follow-up',
  CHAT_DISCOVERY = 'Chat Discovery',
  VIDEO_FOLLOW_UP = 'Video Follow-up',
  VIDEO_DISCOVERY = 'Video Discovery',
}

export type SessionOption = {
  sessionFormat: SessionFormat;
  sessionType: SessionType;
};

export enum Duration {
  TWENTY = 20,
  THIRTY = 30,
  FORTY = 40,
  FIFTY = 50,
  SIXTY = 60,
}

export enum RecurrenceEnum {
  DOES_NOT_REPEAT = 0,
  ONCE_A_WEEK = 1,
  EVERY_2_WEEKS = 2,
  EVERY_3_WEEKS = 3,
  EVERY_4_WEEKS = 4,
}

export const RecurrenceOptions: Option<RecurrenceEnum>[] = [
  {
    label: 'Does not repeat',
    value: RecurrenceEnum.DOES_NOT_REPEAT,
  },
  { label: 'Once a week', value: RecurrenceEnum.ONCE_A_WEEK },
  { label: 'Every 3 weeks', value: RecurrenceEnum.EVERY_2_WEEKS },
  { label: 'Every 3 weeks', value: RecurrenceEnum.EVERY_3_WEEKS },
  { label: 'Every 4 weeks', value: RecurrenceEnum.EVERY_4_WEEKS },
];

export const SessionOptions: Option<CoachingSessionModificationScope>[] = [
  {
    label: 'This session',
    value: CoachingSessionModificationScope.THIS_ONLY,
  },
  {
    label: 'This and following sessions',
    value: CoachingSessionModificationScope.THIS_AND_FOLLOWING,
  },
];

export type StartTime = {
  hour: number;
  minute: number;
};
export type EveryNWeeks = 1 | 2 | 3 | 4;
export type NumOccurrences = 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export interface Recurrence {
  everyNWeeks: RecurrenceEnum;
  numOccurrences: NumOccurrences;
}

export interface SchedulerFormData {
  startTime: StartTime;
  durationMinutes: number;
  recurrence: {
    everyNWeeks: RecurrenceEnum;
    numOccurrences: number;
  };
}

export const RECURRENCE_VALUES = [
  RecurrenceEnum.ONCE_A_WEEK,
  RecurrenceEnum.EVERY_2_WEEKS,
  RecurrenceEnum.EVERY_3_WEEKS,
  RecurrenceEnum.EVERY_4_WEEKS,
];

export type StartDate = {
  year: number;
  month: number;
  date: number;
};
export interface CreationFields {
  id?: string;
  duration: number;
  everyNWeeks: RecurrenceEnum;
  numOccurrences: number;
  startDate: StartDate;
  sessionStartTime: StartTime;
  sessionType: SessionType;
  sessionFormat: SessionFormat;
}

export type ExistingCoachingSession = {
  coachingSession: CoachingSession;
  recurrence: RecurrenceGql | null;
};

export interface SessionInfo {
  titleText: string;
  startDate: string;
  endDate?: string;
  numOfSessions: string;
  duration: string;
}

export interface TimeSlot {
  id: string;
  displayCoachStartTime: ISODateString;
  coachTimezone: string;
  displayMemberStartTime: ISODateString;
  memberTimezone: string;
}

export interface Availability {
  startTime: ISODateString;
  endTime: ISODateString;
  load?: number;
}

export type DisplayTimes = {
  displayCoachStartTime: string;
  displayMemberStartTime: string;
};

export interface NewCoachingSessionParams {
  formData: CreationFields;
  memberTimeZone: string;
  memberId: string;
  startTime?: ISODateString;
  endTime?: ISODateString;
  coachTimeZone?: string;
}
