import Messages from 'i18n/en/appointment.json';
import React from 'react';

import { BaseModal, ModalProps } from './BaseModal';

export function MissingCreditCardWarning({
  onClick,
  onClose,
  isOpen,
}: ModalProps) {
  return (
    <BaseModal
      onClick={onClick}
      onClose={onClose}
      isOpen={isOpen}
      modalName="MissingCreditCard"
      headerText={Messages.missingCreditCardHeaderText}
      bodyText={Messages.missingCreditCardBodyText}
    />
  );
}
