import { useForm } from '@ginger.io/react-use-form';
import {
  Assessment,
  Assessment_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/Assessment';
import { CPTCode } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/CPTCode';
import {
  AssessmentSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import { CodeSetFields } from 'app/notes-ui/shared/code-set/CodeSetFields';
import {
  codeSetsFromAssessment,
  NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
} from 'app/notes-ui/utils';
import { acceptedTherapyCptCodes, filterCptCodes } from 'utils/notes';
import React from 'react';

import { fieldDefs } from './schema';

type Props = {
  appointmentId: string;
  onSubmit: (data: AssessmentSection) => void;
  updateDraftNoteState: (data: AssessmentSection) => void;
  initialValue?: Assessment;
  disabled?: boolean;
  savingNote?: boolean;
};

export function AssessmentFormv2(props: Props) {
  const { disabled, initialValue, appointmentId } = props;

  const { fields, getValue } = useForm<Assessment>(
    fieldDefs,
    {
      ...initialValue,
      appointmentId,
      codeSets: codeSetsFromAssessment(initialValue),
      version: Assessment_Version.v0,
    } as Assessment,
    {
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
      onStateChange: async () => {
        await onSubmit();
      },
    },
  );

  const onSubmit = async () => {
    if (disabled) return;

    props.onSubmit({
      data: getValue(),
      name: TherapyIntakeSectionName.ASSESSMENT,
    });
  };

  return (
    <CodeSetFields
      disabled={disabled}
      cptCodeEnum={filterCptCodes(CPTCode, acceptedTherapyCptCodes)}
      field={fields.codeSets}
    />
  );
}
