import MaterialGrid, { GridSpacing } from '@mui/material/Grid';
import { classNameCombiner } from 'utils';
import React, { ReactNode } from 'react';

import styles from './Columns.module.scss';

export type GridItemsAlignment =
  | 'flex-start'
  | 'center'
  | 'flex-end'
  | 'stretch'
  | 'baseline';

export type GridJustification =
  | 'flex-start'
  | 'center'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';

type Props = {
  children: ReactNode | ReactNode[];
  className?: string;
  justify?: GridJustification;
  align?: GridItemsAlignment;
  'data-testid'?: string;
  spacing?: GridSpacing;
};

export function Grid(props: Props) {
  const { 'data-testid': testId, spacing = 3, className = '' } = props;
  return (
    <MaterialGrid
      data-testid={testId || 'grid'}
      container={true}
      justifyContent={props.justify}
      alignItems={props.align}
      spacing={spacing}
      className={classNameCombiner([styles.columns, className])}
    >
      {props.children}
    </MaterialGrid>
  );
}
