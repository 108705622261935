import gql from 'graphql-tag';

export const createCSCAutomatedPauseReauestMutation = gql`
  mutation createPauseRequest($input: CreateCSCOutreachPauseRequestInput!) {
    createPauseRequest(input: $input) {
      ok
      error
    }
  }
`;
