import * as Sentry from '@sentry/react';
import { NoteAction } from 'app/appointments/types';
import { Team } from 'app/care-team/Team';
import { SurveyResponseRoutesV3 } from 'app/coach/member-chart/SurveyResponseRoutesV3';
import { useGetMemberData } from 'app/inbox/multi-tabs/MemberChartDataState';
import { MemberChartErrorState } from 'app/member-chart-cards/error-state/MemberChartErrorState';
import { SupervisorProfile } from 'app/member-support/SupervisorProfile';
import { MemberHeaderBar } from 'app/patients/header/MemberHeaderBar';
import { IntakeTabView } from 'app/patients/IntakeTabView';
import styles from 'app/patients/PatientProfile.module.scss';
import {
  appointmentsRoute,
  appointmentsScheduleRoute,
  careTeamRoute,
  chatCollaborationRoute,
  demographicsRoute,
  documentsRoute,
  intakeRoute,
  notesRoute,
  prescriptionsRoute,
  summaryRoute,
  surveysRoute,
} from 'app/patients/Routes';
import {
  AppointmentsTab,
  UILocation,
} from 'app/patients/tabs/appointments/AppointmentsTab';
import { AppointmentsTabSchedule } from 'app/patients/tabs/appointments/AppointmentsTabSchedule';
import ChatCollaboration from 'app/patients/tabs/chat-collaboration/ChatCollaboration';
import DocumentUpload from 'app/patients/tabs/document-upload/DocumentUpload';
import { NotesTabContainer } from 'app/patients/tabs/notes/NotesTabContainer';
import Prescriptions from 'app/patients/tabs/prescriptions/Prescriptions';
import { SummaryTabV2 } from 'app/patients/tabs/summary/SummaryTabV2';
import { GetMemberQueryWithCareTeam_getMember as Member } from 'app/queries/generated/GetMemberQueryWithCareTeam';
import { SecureRoute } from 'app/routes/SecureRoute';
import { useAppState } from 'app/state';
import { Status } from 'app/state/status/types/StateSlice';
import { Routes } from 'app/top-nav/Routes';
import { UserRole } from 'generated/globalTypes';
import { TransientFeatureFlag, useFeatureFlags } from 'hooks/useFeatureFlags';
import { useMemberChartIndicators } from 'hooks/useMemberChartIndicators';
import React, { Fragment } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { Loader } from 'shared-components/loader/Loader';
import { NavigationTabs, TabLink } from 'shared-components/tabs/NavigationTabs';
import {
  CARE_TEAM_USER_ROLE,
  CLINICIAN_OR_SUPERVISOR_USER_ROLE,
  SUPERVISOR_USER_ROLE,
} from 'utils';

export type Props = {
  pathPrefix: string;
  member: Member;
};

const Profile = (props: { member: Member; pathPrefix: string }) => {
  const { member, pathPrefix } = props;

  const {
    id,
    id: memberId,
    preferredFirstName,
    preferredLastName,
    preferences,
    timezone,
    gender: memberGender,
    genderIdentification,
    ethnicities,
    pronouns,
    dateOfBirth,
    firstName,
    lastName,
    email,
    guardianRelationship,
    phone,
    insurance,
    coverageDetails,
    emergencyContact,
    dosespotId,
    address,
    isMinorDependent,
    intakeCompletedAt,
  } = member;

  const history = useHistory();
  const SentryRoute = Sentry.withSentryRouting(Route);

  const role = useAppState((_) => _.user.role);
  const navigationTabLinks = useMemberChartNavigationLinks(id, pathPrefix);

  return (
    <Fragment>
      <nav className={styles.nav}>
        <NavigationTabs className={styles.navTab} links={navigationTabLinks} />
      </nav>

      <SentryRoute
        exact={true}
        path={pathPrefix}
        render={() => {
          if (role === UserRole.MEMBER_SUPPORT) {
            return <Redirect to={demographicsRoute(pathPrefix, id)} />;
          }
          return <Redirect to={summaryRoute(pathPrefix, id)} />;
        }}
      />

      <SentryRoute
        path={summaryRoute(pathPrefix, id)}
        render={() => (
          <SummaryTabV2
            data-testid="summaryTab"
            id={memberId}
            insurance={insurance}
            coverageDetails={coverageDetails}
            preferredFirstName={preferredFirstName}
            preferredLastName={preferredLastName}
            preferences={preferences}
            timezone={timezone}
            gender={memberGender}
            genderIdentification={genderIdentification}
            ethnicities={ethnicities}
            pronouns={pronouns}
            dateOfBirth={dateOfBirth}
            firstName={firstName}
            lastName={lastName}
            email={email}
            address={address}
            phone={phone}
            emergencyContact={emergencyContact}
            isSupervisorAndMSUI={true}
            guardianRelationship={guardianRelationship}
            dosespotId={dosespotId}
            intakeCompletedAt={intakeCompletedAt}
          />
        )}
      />

      <SentryRoute
        path={intakeRoute(pathPrefix, id)}
        render={() => (
          <IntakeTabView
            pathPrefix={pathPrefix}
            memberId={memberId}
            isMinorDependent={isMinorDependent ?? false}
          />
        )}
      />

      {/*  Making this a SentryRoute causes test failures with the old renderer */}
      <Route
        exact={true}
        path={appointmentsRoute(pathPrefix, id)}
        render={() => (
          <AppointmentsTab
            data-testid="appointments"
            uiLocation={UILocation.MS}
            navigateTo={(url) => history.push(url)}
            patientId={id}
            signedLockedAction={NoteAction.DOWNLOAD}
          />
        )}
      />

      {/*  Making this a SentryRoute causes test failures with the old renderer */}
      <Route
        exact={true}
        path={appointmentsScheduleRoute(pathPrefix, id)}
        render={() => (
          <AppointmentsTabSchedule
            data-testid="appointmentsSchedule"
            navigateTo={(url) => history.push(url)}
            patientId={id}
            signedLockedAction={NoteAction.DOWNLOAD}
          />
        )}
      />

      <SecureRoute
        exact={true}
        path={notesRoute(pathPrefix, id)}
        permittedRoles={CLINICIAN_OR_SUPERVISOR_USER_ROLE}
        component={() => (
          <NotesTabContainer
            signedLockedAction={NoteAction.DOWNLOAD}
            navigateTo={(url) => history.push(url)}
            patientId={id}
          />
        )}
      />

      <SecureRoute
        exact={true}
        path={chatCollaborationRoute(pathPrefix, id)}
        requiresFeatureFlag={TransientFeatureFlag.CHAT_COLLABORATION}
        permittedRoles={CLINICIAN_OR_SUPERVISOR_USER_ROLE}
        component={() => <ChatCollaboration memberId={id} />}
      />

      <SecureRoute
        exact={true}
        path={demographicsRoute(pathPrefix, id)}
        permittedRoles={[...SUPERVISOR_USER_ROLE, UserRole.MEMBER_SUPPORT]}
        component={() => <SupervisorProfile memberId={id} />}
      />

      <SecureRoute
        exact={true}
        path={documentsRoute(pathPrefix, id)}
        requiresFeatureFlag={TransientFeatureFlag.ENABLE_DOCUMENT_UPLOAD}
        permittedRoles={[
          ...CLINICIAN_OR_SUPERVISOR_USER_ROLE,
          UserRole.MEMBER_SUPPORT,
        ]}
        component={() => <DocumentUpload memberId={id} />}
      />

      <SecureRoute
        exact={true}
        path={prescriptionsRoute(pathPrefix, id)}
        permittedRoles={CLINICIAN_OR_SUPERVISOR_USER_ROLE}
        component={() => <Prescriptions memberId={id} />}
      />

      <SurveyResponseRoutesV3 memberId={id} pathPrefix={pathPrefix} />

      <SecureRoute
        exact={true}
        path={careTeamRoute(pathPrefix, id)}
        permittedRoles={CARE_TEAM_USER_ROLE}
        component={() => (
          <Team data-testid="patientProfileCareTeam" memberId={id} />
        )}
      />
    </Fragment>
  );
};

/**
 *  This component is responsible for rendering the member's chart to clinical supervisors. This is different from the
 *  profile screen shown to MCA agents. It includes the following tabs:
 *  - Summary: contains the member's demographic information
 *  - Intake: contains the member's clinical intake information
 *  - Appointments Schedule: contains the member's upcoming appointments. This enables supervisors to download clinical notes
 *  - Notes: contains non-clinical appointment notes such as OOS & Termination notes. This enables supervisors to download these notes
 *  - Chat Collaboration: contains the chat collaboration feature gated by a feature flag: enable_chat_collaboration
 *  - Demographics: contains the member's demographic information
 *  - Team: contains the member's care team and team collaboration feature
 *  - Documents: contains the document upload feature gated by a feature flag: enable_document_upload
 *  - Prescriptions: Dosespot integration to view member's prescriptions
 *
 */
export function PatientProfile({ member, pathPrefix }: Props) {
  return (
    <Profile
      data-testid="patientProfile"
      member={member}
      pathPrefix={pathPrefix}
    />
  );
}

export function PatientProfileWrapper(props: {
  patientId: string;
  pathPrefix?: string;
  className?: string;
}) {
  const { patientId, className, pathPrefix = Routes.PATIENTS } = props;
  const state = useGetMemberData(patientId);

  if (state.status === Status.LOADING) return <Loader />;

  if (state.status === Status.ERROR) {
    return (
      <div className={styles.errorContainer}>
        <MemberChartErrorState showMessage={true} error={state.error} />
      </div>
    );
  }
  return (
    <>
      <MemberHeaderBar memberId={patientId} />
      <div className={className}>
        <PatientProfile member={state.data} pathPrefix={pathPrefix} />
      </div>
    </>
  );
}

function useMemberChartNavigationLinks(id: string, pathPrefix: string) {
  const {
    showIntakeIndicator,
    showSurveyScoreIndicator,
  } = useMemberChartIndicators(id);
  const showChatCollaboration = useFeatureFlags().transientFeatureFlags[
    TransientFeatureFlag.CHAT_COLLABORATION
  ];
  const collaborationTabLink: TabLink[] = [];
  if (showChatCollaboration) {
    collaborationTabLink.push({
      isNewFeature: false,
      label: 'Collaboration',
      path: chatCollaborationRoute(pathPrefix, id),
    });
  }
  const navigationTabLinks: TabLink[] = [
    { label: 'Summary', path: summaryRoute(pathPrefix, id) },
    {
      isNotificationVisible: showIntakeIndicator,
      label: 'Intake',
      path: intakeRoute(pathPrefix, id),
    },
    {
      isNotificationVisible: showSurveyScoreIndicator,
      label: 'Survey Scores',
      path: surveysRoute(pathPrefix, id),
    },
    {
      label: 'Appointments',
      path: appointmentsScheduleRoute(pathPrefix, id),
    },
    {
      label: 'Notes',
      path: notesRoute(pathPrefix, id),
    },
    ...collaborationTabLink,
    {
      label: 'Demographics',
      path: demographicsRoute(pathPrefix, id),
    },
    {
      label: 'Team',
      path: careTeamRoute(pathPrefix, id),
    },
    {
      label: 'Documents',
      path: documentsRoute(pathPrefix, id),
    },
    {
      label: 'Prescriptions',
      path: prescriptionsRoute(pathPrefix, id),
    },
  ];

  return navigationTabLinks;
}
