import { useUpdateUnreadMessageCallback } from 'app/coach/chat/UseUpdateUnreadMessageCallback';
import {
  getMessageElementId,
  getTextForNewMessagesPill,
  isElementInViewportOfContainer,
  scrollToTheBottomOfScrollableContainer,
} from 'app/coach/chat/utils';
import { MessageType } from 'app/coach/pubnub/types';
import { useAppState } from 'app/state';
import { selectLastListenerRead } from 'app/state/features/conversationTimetokens/selectors';
import { TimetokensService } from 'app/state/features/conversationTimetokens/TimetokensService';
import { useAppDispatch } from 'app/state/hooks/baseTypedHooks';
import {
  selectConversationIdByMemberId,
  selectCurrentPubnubChannelId,
  selectMessages,
  selectUnreadMessages,
} from 'app/state/inbox/selectors';
import { displayableMessagesToString } from 'app/state/inbox/utils';
import { useLogger } from 'app/state/log/useLogger';
import { useDebounce } from 'hooks/useDebounce';
import {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

type UseChatMessagesStateHandlerParams = {
  callerId: string;
  memberId: string;
  containerRef: RefObject<HTMLDivElement>;
};

/**
 * This hook manages marking unread messages as read when they become visible in the message list (chat window) or when
 * the user scrolls to the bottom of the screen. It also updates the new message pill text when unread messages are
 * outside the viewport or when the Care Hub page is not visible (e.g., when the user switches browser tabs, minimizes
 * the window, or the computer goes to sleep).
 *
 */
export function useChatMessagesStateHandler(
  params: UseChatMessagesStateHandlerParams,
) {
  const { containerRef, callerId, memberId } = params;
  const logger = useLogger();
  const dispatch = useAppDispatch();

  const [pillText, setPillText] = useState<string | null>(null);

  const currentConversationId = selectConversationIdByMemberId(memberId);
  const currentChannelId = selectCurrentPubnubChannelId();
  const messages = selectMessages(currentChannelId);
  const unreadMessages = selectUnreadMessages(currentChannelId);
  const lastListenerReadTimetoken = useAppState(
    (state) => selectLastListenerRead(state, currentChannelId) ?? '0',
  );
  const container = containerRef.current;

  const updateUnreadMessageToRead = useUpdateUnreadMessageCallback({
    callerId,
    channelId: currentChannelId,
    memberId,
  });
  const scrollToTheBottom = () => {
    scrollToTheBottomOfScrollableContainer(containerRef);
  };
  const updateScrollPositionBasedOnMessageType = () => {
    const lastMessage = [...messages].pop();
    if (!lastMessage) return;
    // scroll to the bottom when the message is from the coach, a homework assignment.
    if (
      lastMessage.type === MessageType.TEXT_FROM_LISTENER ||
      lastMessage.type === MessageType.CONTENT_MESSAGE
    ) {
      scrollToTheBottom();
    }
  };

  // To minimize the number of times the useEffect hook is triggered, we convert its dependencies to primitive values.
  const isContainerReady = container != null;
  const unreadMessagesIdsString = unreadMessages.map((_) => _.id).join(' ');
  const messageIdsString = messages.map((_) => _.id).join(' ');

  const maybeSetNewMessagePillText = () => {
    const careHubWindowIsVisible = document.visibilityState === 'visible';
    const unreadMessagesNotInView = unreadMessages.filter(
      (_) =>
        !isElementInViewportOfContainer(container, getMessageElementId(_.id)),
    );

    if (
      unreadMessagesNotInView.length > 0 ||
      (!careHubWindowIsVisible && unreadMessages.length > 0)
    ) {
      setPillText(getTextForNewMessagesPill(unreadMessages.length));
    } else {
      setPillText(null);
    }
  };

  const maybeMarkUnreadMessagesAsRead = useCallback(() => {
    /*
       Mark messages as read
       - if the latest unread message is visible within the message list container's viewport
       - the care hub window is visible to the user
       - and there is at least one unread message with a time token newer than the lastListenerReadTimetoken.
       Otherwise, display the unread message pill.
    */
    if (!container) return;
    const lastUnreadMessage = [...unreadMessages].pop();
    const careHubWindowIsVisible = document.visibilityState === 'visible';
    const inViewport = isElementInViewportOfContainer(
      container,
      getMessageElementId(lastUnreadMessage?.id ?? ''),
    );
    const requiresTimeTokenUpdate = unreadMessages.some(
      (_) => _.timetoken > lastListenerReadTimetoken,
    );
    const shouldMarkAsRead =
      inViewport && careHubWindowIsVisible && requiresTimeTokenUpdate;

    if (shouldMarkAsRead) {
      updateUnreadMessageToRead();
      setPillText(null);
    } else {
      maybeSetNewMessagePillText();
    }
  }, [
    unreadMessagesIdsString,
    messageIdsString,
    isContainerReady,
    lastListenerReadTimetoken,
  ]);
  const debouncedMaybeMarkUnreadMessagesAsRead = useDebounce(
    maybeMarkUnreadMessagesAsRead,
    1_000,
  );

  const onNewMessageButtonClick = () => {
    // We just need to scroll to the bottom of the message list container to cleat the pill text and mark messages as read.
    if (unreadMessages.length > 0) {
      maybeMarkUnreadMessagesAsRead();
    } else {
      setPillText(null);
    }
    scrollToTheBottom();
  };

  function maybeUpdateListenerReadTimetoken() {
    const lastMessageTimetoken = [...messages].pop()?.timetoken ?? '0';
    if (
      unreadMessages.length === 0 &&
      messages.length > 0 &&
      lastListenerReadTimetoken < lastMessageTimetoken
    ) {
      logger.info(
        'useChatMessagesStateHandler: updating listener read timetoken',
        {
          lastListenerReadTimetoken,
          lastMessageTimetoken,
        },
      );
      dispatch(
        TimetokensService.updateConversationTimetokens({
          conversationId: currentConversationId,
          lastListenerReadTimetoken: lastMessageTimetoken,
        }),
      );
    }
  }

  // This hook registers to the message container's scroll event, allowing unread messages to be marked as read when
  // the user scrolls to the bottom of the message list.
  useEffect(() => {
    const onScrollContainer = () => {
      debouncedMaybeMarkUnreadMessagesAsRead();
    };
    if (isContainerReady) {
      container.addEventListener('scroll', onScrollContainer);
    }

    return () => {
      if (!isContainerReady) return;
      container.removeEventListener('scroll', onScrollContainer);
    };
  }, [maybeMarkUnreadMessagesAsRead]);

  // This hook is responsible for watch for changes in the unread messages list and the message list, and then
  // determines whether to mark unread messages as read or to display the unread message pill.
  useLayoutEffect(() => {
    maybeUpdateListenerReadTimetoken();
    if (
      !isContainerReady ||
      messages.length === 0 ||
      unreadMessages.length === 0
    ) {
      return;
    }
    const messageSet = new Set(messageIdsString);
    const unreadMessageInConversation = unreadMessages.filter((_) =>
      messageSet.has(_.id),
    );
    if (unreadMessageInConversation.length !== unreadMessages.length) {
      logger.warning(
        'useChatUnreadMessageHandler: some unread messages are not in the message list',
        {
          messages: displayableMessagesToString(messages),
          unreadMessageInConversation: displayableMessagesToString(
            unreadMessageInConversation,
          ),
          unreadMessages: displayableMessagesToString(unreadMessages),
        },
      );
      // TODO: Maybe Re-fetch messages from history to make sure that the unread messages are in the message list.
    }

    maybeMarkUnreadMessagesAsRead();
    updateScrollPositionBasedOnMessageType();
  }, [unreadMessagesIdsString, messageIdsString, isContainerReady]);

  return {
    onNewMessageButtonClick,
    pillText,
  };
}
