/* eslint-disable no-undef */
import * as Sentry from '@sentry/react';
import { Options } from '@sentry/types';
import { flattenAdditionalData } from 'app/services/sentry/utils';

import { SentryClient } from './SentryClient';

export interface Config extends Options {
  // The release version, which will be a git hash
  release: string;
  replaysSessionSampleRate: number;
  replaysOnErrorSampleRate: number;
  fetchUser: () => Promise<UserDetails>;
}

const enum AuthErrors {
  OAuthError = 'OAuthError',
  AuthApiError = 'AuthApiError',
  AuthSdkError = 'AuthSdkError',
}

export interface UserDetails {
  email: string;
}

export type AdditionalData = Record<string, any>;

export class SentryClientImpl implements SentryClient {
  constructor(private config: Config) {
    Sentry.init({ ...config });
  }

  async captureException(
    error: Error,
    additionalData?: AdditionalData,
  ): Promise<void> {
    let email: string | undefined;
    try {
      email = (await this.config.fetchUser()).email;
    } catch (e) {
      if (
        e.name === AuthErrors.AuthApiError ||
        e.name === AuthErrors.OAuthError ||
        e.name === AuthErrors.AuthSdkError
      ) {
        R7Insight.info(
          'SentryClientImpl.captureException: Auth error while fetching user details',
          {
            additionalData: { errorCaptured: error, errorThrown: e },
          },
        );
        return;
      }
      R7Insight.error(
        'SentryClientImpl.captureException: Failed to fetch user details',
        {
          additionalData: { errorCaptured: error, errorThrown: e },
        },
      );
    }

    Sentry.configureScope((scope) => {
      if (email) Sentry.setUser({ email });

      if (additionalData) {
        scope.setContext(
          'Additional Data',
          flattenAdditionalData(additionalData),
        );
      }
      Sentry.captureException(error);
    });
  }
}
