import { useAppState } from 'app/state';
import moment from 'moment/moment';
import React from 'react';
import { Dropdown } from 'shared-components/Dropdown';
import { Width } from 'types/StyleTypes';

import { formatAs2Digits, get15MinuteIncrement } from '../utils';

export interface Props {
  hour: number;
  minute: number;
  onChange: (hour: number, minute: number) => void;
  testId?: string;
  memberTimezone: string;
  selectedDate: string;
}

export function TimeDropdown(props: Props) {
  const {
    hour,
    minute,
    onChange,
    testId = 'timeDropdown',
    memberTimezone,
    selectedDate,
  } = props;
  const { timezone } = useAppState((_) => _.user);
  const selectedOptionID = toOptionID(hour, get15MinuteIncrement(minute));
  const options = getOptions(timezone || 'UTC', memberTimezone, selectedDate);

  const handleChange = (value: string) => {
    const { hour, minute } = toHourMinute(value);
    onChange(hour, minute);
  };

  return (
    <Dropdown
      onSelect={handleChange}
      initialValue={selectedOptionID}
      options={options}
      dataTestId={testId}
      width={Width.FULL}
    />
  );
}

/**
 * @return Returns 00:00 to 23:45 in 15-minute increments.
 */
function getOptions(
  coachTimezone: string,
  memberTimezone: string,
  selectedDate: string,
): { name: string; value: string }[] {
  let currentTime = moment.tz(selectedDate, coachTimezone).startOf('day');
  const endTime = moment.tz(selectedDate, coachTimezone).endOf('day');
  const options = [];

  while (currentTime <= endTime) {
    const value = toOptionID(currentTime.hour(), currentTime.minute());
    const memberTime = currentTime.clone().tz(memberTimezone).format('h:mma z');
    const name = `${currentTime.format('hh:mma z')} / ${memberTime}`;
    options.push({ name, value });
    currentTime = currentTime.add(15, 'minutes');
  }

  return options;
}

function toOptionID(hour: number, minute: number): string {
  const zeroPaddedHour = formatAs2Digits(hour);
  const zeroPaddedMinute = formatAs2Digits(minute);
  return `${zeroPaddedHour}:${zeroPaddedMinute}`;
}

function toHourMinute(optionID: string): { hour: number; minute: number } {
  const [hour2DigitStr, minute2DigitStr] = optionID.split(':');
  return { hour: parseInt(hour2DigitStr), minute: parseInt(minute2DigitStr) };
}
