import { useSelector } from 'react-redux';

import { State } from '../schema';

export const selectCountDownForDelayedMessage = (channelId: string): number => {
  return useSelector((state: State) => {
    const {
      timedSends: { countDownMap },
    } = state;

    return countDownMap[channelId]?.seconds ?? 0;
  });
};
