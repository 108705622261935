import { Stage, useEnvironment } from '@ginger.io/core-ui';
import { CoachClinicianCollaborationChatMessage_Author_AuthorType as AuthorType } from '@ginger.io/vault-care-collaboration/dist/generated/protobuf-schemas/vault-care-collaboration/CoachClinicianCollaborationChatMessage';
import { ChatMessage } from 'app/collaboration/type';

import { ChatConstants } from '../constants';

export function useAvatarURL(chatMessage: ChatMessage): string | undefined {
  const stage = useEnvironment();
  let avatarSrc;
  if (stage === Stage.PROD) {
    if (chatMessage.author.type === AuthorType.clinician) {
      avatarSrc = `${ChatConstants.AVATAR_URL}{chatMessage.author.firstName}-${chatMessage.author.lastName}.png`;
    } else if (chatMessage.author.type === AuthorType.coach) {
      // TODO with coach avatar update see: https://app.asana.com/0/1199537661207281/1200329160728971
    }
  } else {
    // default to development
    if (chatMessage.author.type === AuthorType.clinician) {
      avatarSrc = `${ChatConstants.AVATAR_URL}{chatMessage.author.firstName}-${chatMessage.author.lastName}.png`;
    } else if (chatMessage.author.type === AuthorType.coach) {
      // TODO with coach avatar update see: https://app.asana.com/0/1199537661207281/1200329160728971
    }
  }
  return avatarSrc;
}
