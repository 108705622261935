import { InputBaseComponentProps } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';

export type InputProps = {
  name: string;
  value?: string;
  placeholder?: string;
  className?: string;
  error?: string;
  onChange: (val: string) => void;
  variant?: TextFieldProps['variant'];
  type?: TextFieldProps['type'];
  disabled?: boolean;
  fullWidth?: boolean;
  size?: TextFieldProps['size'];
  inputProps?: InputBaseComponentProps;
};

export function Input(props: InputProps) {
  const {
    name,
    placeholder,
    error,
    disabled,
    className = '',
    variant = 'standard',
    type,
    fullWidth,
    size,
    inputProps,
  } = props;
  const [currentValue, setCurrentValue] = useState('');

  useEffect(() => {
    if (props.value && props.value !== currentValue) {
      setCurrentValue(props.value);
    }
  }, [props.value, currentValue]);

  const onInputChange = (event: React.ChangeEvent<{ value: string }>) => {
    const { value } = event.target;
    setCurrentValue(value);
    props.onChange(value);
  };

  return (
    <TextField
      disabled={disabled}
      data-testid={name}
      error={error !== undefined}
      name={name}
      className={className}
      value={currentValue}
      onChange={onInputChange}
      helperText={error}
      placeholder={placeholder}
      variant={variant}
      type={type}
      inputProps={{
        'aria-label': name,
        'data-testid': `${name}-input`,
        ...inputProps,
      }}
      fullWidth={fullWidth}
      size={size}
    />
  );
}
