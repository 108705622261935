import { InboxSections } from 'app/inbox/types';
import { ChatConversationState } from 'generated/globalTypes';
import moment from 'moment';

import {
  moveInboxItemBetweenSections,
  refreshCoachTodaysMemberList,
  sortInboxSections,
  updateConversationState,
} from '../actions';
import { ActionHandler } from './types';

export const onMarkConversationAsDone = ({
  action,
  redux,
  context,
}: ActionHandler<{ memberId: string }>) => {
  // the action updates today's member list by moving the member item between the today's tab section.
  const { tabSections, inboxItems } = redux.getState().inbox;
  const { logger } = context.services;

  const { memberId } = action.payload;
  const stateLastUpdatedAt = moment().toISOString();
  const isThereInboxItemForTheMember = memberId in inboxItems;

  const { memberCoachChannelId } = inboxItems[memberId];
  if (!isThereInboxItemForTheMember || !memberCoachChannelId) {
    logger.error(
      new Error(
        `Cannot complete markConversationAsDone since the inboxItem is not found in the inboxItems`,
      ),
      {
        isThereInboxItemForTheMember,
        memberCoachChannelId,
        memberId,
        tabSections,
      },
    );
    return;
  }
  const { taskDetails } = inboxItems[memberId];

  if (taskDetails) {
    logger.debug('markConversationAsDone: refreshing inbox', { taskDetails });
    redux.dispatch(
      updateConversationState({
        channelId: memberCoachChannelId,
        updatedState: { state: ChatConversationState.DONE, stateLastUpdatedAt },
      }),
    );
    redux.dispatch(
      refreshCoachTodaysMemberList({
        sections: [
          InboxSections.CONVERSATIONS_AND_TASKS,
          InboxSections.COMPLETED,
        ],
      }),
    );

    return redux.dispatch(
      sortInboxSections({ sections: [InboxSections.CONVERSATIONS_AND_TASKS] }),
    );
  }
  const sectionToUpdate = [InboxSections.COMPLETED];

  const isInboxItemInConversationsAndTasks = tabSections.CONVERSATIONS_AND_TASKS.ids.has(
    memberId,
  );
  if (isInboxItemInConversationsAndTasks) {
    logger.debug(
      `markConversationAsDone: moving conversation to completed with refreshing, isInboxItemInConversationsAndTasks ${isInboxItemInConversationsAndTasks}`,
    );

    redux.dispatch(
      moveInboxItemBetweenSections({
        from: InboxSections.CONVERSATIONS_AND_TASKS,
        memberId,
        to: InboxSections.COMPLETED,
      }),
    );
    sectionToUpdate.push(InboxSections.CONVERSATIONS_AND_TASKS);
  }
  const isInboxItemInRiskAlerts = tabSections.RISK_ALERTS.ids.has(memberId);
  if (isInboxItemInRiskAlerts) {
    logger.debug(
      `markConversationAsDone: moving conversation to completed with refreshing, isInboxItemInRiskAlerts ${isInboxItemInRiskAlerts}`,
    );
    redux.dispatch(
      moveInboxItemBetweenSections({
        from: InboxSections.RISKS,
        memberId,
        to: InboxSections.COMPLETED,
      }),
    );
    sectionToUpdate.push(InboxSections.RISKS);
  }
  redux.dispatch(refreshCoachTodaysMemberList({ sections: sectionToUpdate }));

  redux.dispatch(
    updateConversationState({
      channelId: memberCoachChannelId,
      updatedState: { state: ChatConversationState.DONE, stateLastUpdatedAt },
    }),
  );
};
