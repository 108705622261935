import gql from 'graphql-tag';

export const getDiagnosisCodes = gql`
  query GetDiagnosisCodes {
    getDiagnosisCodes {
      code
      description
      readonly
      vaultIds
    }
  }
`;
