import { Loader } from '@ginger.io/core-ui';
import { TableBody, TableCell, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import { MenuItem as FilterMenuItem } from 'shared-components/menu/FilterMenu';
import {
  SortableColumn,
  SortableTableHead,
} from 'shared-components/table/SortableTableHead';
import { V2Table as Table } from 'shared-components/table/V2Table';
import { MemberChartErrorState } from 'app/member-chart-cards/error-state/MemberChartErrorState';
import React from 'react';

import { ReactComponent as FilterIcon } from './icons/filterIcon.svg';
import styles from './SortableTable.module.scss';

export interface Row {
  id: string;
  component: JSX.Element;
}

// todo: replace "any" with actual types when backend work for coach notes is complete
type SortableTableProps = {
  rows: Row[] | null;
  columns: ({ filters: FilterMenuItem[] } & SortableColumn)[] | any;
  loading: boolean;
  error: false | Error;
  errorMessage: Error | undefined;
  tableClass?: string;
  rowClass?: string;
  headerClass?: string;
  sortLabelClass?: string;
  headerCellClass?: string;
  InfoHeader?: JSX.Element;
  onSortableTableFilter?: (column: string, item: FilterMenuItem) => void;
  changeColumnSort?: (column: SortableColumn) => void;
  dataTestId?: string;
};

const TableWithLoader = ({
  colSpan,
}: {
  colSpan: number;
}): React.ReactElement => {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={colSpan}>
          <Loader />
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export function SortableTable({
  InfoHeader,
  tableClass,
  rowClass,
  headerClass,
  sortLabelClass,
  headerCellClass,
  columns,
  rows,
  loading,
  error,
  errorMessage,
  onSortableTableFilter,
  changeColumnSort,
  dataTestId,
}: SortableTableProps): React.ReactElement | null {
  if (!rows) {
    return null;
  }

  return (
    <Paper className={styles.container}>
      {InfoHeader}
      {error ? <MemberChartErrorState error={errorMessage} /> : null}
      <Table dataTestId={dataTestId} className={tableClass} stickyHeader={true}>
        <SortableTableHead
          filterIcon={FilterIcon}
          columns={columns}
          onSortableTableFilter={onSortableTableFilter}
          changeColumnSort={changeColumnSort}
          className={headerClass}
          sortLabelClass={sortLabelClass}
          cellClass={headerCellClass}
          dataTestId={`${dataTestId}-sortable-table-head`}
        />
        {loading ? (
          <TableWithLoader colSpan={columns.length} />
        ) : (
          <TableBody>
            {rows.map((row: any) => (
              <TableRow data-testid={row.id} key={row.id} className={rowClass}>
                {row.component}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </Paper>
  );
}
