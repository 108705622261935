import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { getErrorDataForClipboard } from 'app/appointments/errorUtils';
import { Links } from 'app/constants';
import { useLogger } from 'app/state/log/useLogger';
import { classNameCombiner } from 'utils';
import React, { useState } from 'react';

import styles from './BugReporButton.module.scss';

interface Props {
  className?: string;
  errors: Array<Error | string>;
  onSubmit?: () => void;
}
enum CopyState {
  SUCCESS,
  FAILED,
  UNTOUCHED,
}
export function BugReportButton(props: Readonly<Props>) {
  const { errors, className = '' } = props;
  const [copied, setCopied] = useState(CopyState.UNTOUCHED);
  const logger = useLogger();
  const copyErrorToClipboard = async () => {
    let text;
    try {
      text = JSON.stringify(
        errors.map(getErrorDataForClipboard).join('\n---------\n'),
      );
      if ('clipboard' in navigator) {
        await navigator.clipboard.writeText(text);
        setCopied(CopyState.SUCCESS);
      } else {
        setCopied(CopyState.FAILED);
        logger.error(
          new Error(
            'BugReportButton.copyErrorToClipboard clipboard API is not available',
          ),
          { errors, text },
        );
      }
    } catch (error) {
      setCopied(CopyState.FAILED);
      logger.error(
        new Error(
          'BugReportButton.copyErrorToClipboard: Failed to copy text to clipboard',
        ),
        {
          error,
          errors,
          text,
        },
      );
    }
  };
  const onClick = () => {
    window.open(Links.BUG_SUBMISSION_FORM_URL, '_blank', 'noopener');
    props.onSubmit?.();
  };
  let button;
  switch (copied) {
    case CopyState.UNTOUCHED:
      button = (
        <Link
          component="button"
          underline="hover"
          className={`${styles.btn} ${styles.copyBtn}`}
          onClick={copyErrorToClipboard}
        >
          <ContentCopyIcon
            className={`${styles.iconSize} ${styles.copyIcon}`}
          />
          Copy Error Code
        </Link>
      );
      break;
    case CopyState.SUCCESS:
      button = (
        <IconButton
          className={`${styles.btn} ${styles.copiedBtn}`}
          disableRipple={true}
          disableFocusRipple={true}
          disableTouchRipple={true}
        >
          <CheckCircleIcon className={`${styles.iconSize}`} />
          copied to clipboard
        </IconButton>
      );
      break;
    case CopyState.FAILED:
      button = (
        <IconButton
          className={`${styles.btn} ${styles.copiedBtn}`}
          disableRipple={true}
          disableFocusRipple={true}
          disableTouchRipple={true}
        >
          <CancelIcon className={`${styles.iconSize}`} />
          failed to copy to clipboard
        </IconButton>
      );
      break;
    default:
      button = <></>;
  }

  return (
    <div className={classNameCombiner([className, styles.container])}>
      {button}
      <Button
        color="primary"
        variant="contained"
        data-testid="signLockBtn"
        onClick={onClick}
        className={`${styles.btn} ${styles.submitBtn}`}
      >
        Submit a bug report
      </Button>
    </div>
  );
}
