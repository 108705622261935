import { useLogger } from 'app/state/log/useLogger';
import { useOnMount } from 'hooks/useOnMount';
import moment from 'moment';
import React, { useState } from 'react';

import styles from './ChatClock.module.scss';

interface ChatClockProps {
  timezone: string;
  firstName: string;
  memberId: string;
}
export const ChatClock = ({
  timezone,
  firstName,
  memberId,
}: ChatClockProps) => {
  const [time, setTime] = useState('');
  const logger = useLogger();

  const updateTime = () => {
    const doesMomentKnowTimezone = moment.tz.zone(timezone);
    if (doesMomentKnowTimezone) {
      const timeForMember = moment().tz(timezone);
      setTime(timeForMember.format('LT z'));
    } else {
      logger.error(new Error('Moment does not have this timezone'), {
        memberId,
        timezone,
      });
    }
  };
  useOnMount(() => {
    updateTime();
    const timeInterval = setInterval(updateTime, 30000);
    return () => {
      clearInterval(timeInterval);
    };
  });

  const timeLabel = `It's ${time} for ${firstName}`;
  const unavailableTimeLabel = `Local time is currently unavailable for ${firstName}`;

  return time ? (
    <h4 className={styles.title} title={timeLabel}>
      It&apos;s &nbsp;
      <span className={styles.time}>{time}</span>
      &nbsp;for {firstName}
    </h4>
  ) : (
    <h4 className={styles.title} title={unavailableTimeLabel}>
      {unavailableTimeLabel}
    </h4>
  );
};
