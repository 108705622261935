import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Container from '@mui/material/Container';
import Search from 'shared-components/search-input/Search';
import { Routes, searchRoute } from 'app/top-nav/Routes';

import styles from './MemberSearchScreen.module.scss';
import { useDispatch } from 'react-redux';
import { viewMemberSearchPageAction } from 'app/state/amplitude/actions/etc';

function MemberSearchScreen(props: RouteComponentProps<any>) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewMemberSearchPageAction());
  }, [dispatch]);

  const handleSearch = (q: string) => {
    props.history.push(searchRoute(Routes.MEMBERS, q));
  };

  return (
    <div className={styles.root} data-testid="searchBox">
      <Container maxWidth="sm">
        <Search
          placeholder="Search by email or ID"
          onSearchQuery={handleSearch}
        />
      </Container>
    </div>
  );
}

export default withRouter(MemberSearchScreen);
