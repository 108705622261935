/* eslint-disable no-param-reassign */ // immer does not play well with eslint, so we disable this rule for slices files
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { startAppListening } from 'app/state/hooks/baseTypedHooks';
import { closeTab, openNewTab } from 'app/state/member-tabs/actions';

import { PersistedLayout } from './types';
import {
  getDefaultStateForCards,
  getInitialState,
  localStorageKeyForCards,
  persistedLayoutSliceName,
} from './util';

export const initialState: PersistedLayout = getInitialState();

const persistedLayoutSlice = createSlice({
  extraReducers: (builder) => {
    // set default state for newly opened tabs when there's no persisted state
    builder.addCase(openNewTab, (state, action) => {
      const { memberId } = action.payload;
      const doesMemberHavePersistedStateForCards =
        memberId in state.cardsExpansion;
      if (!doesMemberHavePersistedStateForCards) {
        state.cardsExpansion[memberId] = getDefaultStateForCards();
      }
    });
    // set default state for a member on the tab close
    builder.addCase(closeTab, (state, action) => {
      const { idToRemove } = action.payload;
      state.cardsExpansion[idToRemove] = getDefaultStateForCards();
    });
  },
  initialState,
  name: persistedLayoutSliceName,
  reducers: {
    toggleCardExpansion(
      state,
      { payload }: PayloadAction<{ memberId: string; cardTitle: string }>,
    ) {
      const { memberId, cardTitle } = payload;
      if (!state.cardsExpansion[memberId]) {
        state.cardsExpansion[memberId] = getDefaultStateForCards();
        state.cardsExpansion[memberId][cardTitle] = !state.cardsExpansion[
          memberId
        ][cardTitle];
        return;
      }
      state.cardsExpansion[memberId][cardTitle] = !state.cardsExpansion[
        memberId
      ][cardTitle];
    },
  },
});

export default persistedLayoutSlice.reducer;

export const { toggleCardExpansion } = persistedLayoutSlice.actions;

// All listener predicates and callbacks are checked after the root reducer has already processed the action and updated the state
startAppListening({
  effect: (action, listenerApi) => {
    const updatedCardsState = listenerApi.getState().persistedLayout
      .cardsExpansion;
    localStorage.setItem(
      localStorageKeyForCards,
      JSON.stringify(updatedCardsState),
    );
  },
  matcher: isAnyOf(
    (
      action,
    ): action is PayloadAction<{ memberId: string; cardTitle: string }> =>
      action.type === toggleCardExpansion.type,
    (action): action is PayloadAction<{ memberId: string }> =>
      action.type === openNewTab.type,
    (action): action is PayloadAction<{ idToRemove: string }> =>
      action.type === closeTab.type,
  ),
});
