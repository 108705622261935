import { Chip, Typography } from '@mui/material';
import { BulletSeparator } from 'shared-components/BulletSeparator';
import { classNameCombiner } from 'utils';
import React from 'react';

import styles from './NoteTableCard.module.scss';

enum Roles {
  THERAPY = 'therapy',
  PSYCHIATRY = 'psychiatry',
  COACHING = 'coaching',
}

const chipStyleMap = {
  clinical: styles.clinicalTag,
  coaching: styles.coachingTag,
  psychiatry: styles.psychiatryTag,
  therapy: styles.therapyTag,
};

export const TagAndRole = (props: {
  role?: string;
  careTeamMember?: string | null;
  className?: string;
}) => {
  const { role, careTeamMember, className } = props;

  return (
    <Typography
      data-testid="tag-and-role"
      component="div"
      className={classNameCombiner([styles.tagContainer, className || ''])}
      variant="body2"
    >
      {!!role && (
        <BulletSeparator>
          <Chip
            className={classNameCombiner([
              styles.tag,
              role ? chipStyleMap[role.toLowerCase() as Roles] : '',
            ])}
            label={role}
          />
        </BulletSeparator>
      )}
      {!!careTeamMember && (
        <BulletSeparator>
          <span className={styles.careTeamMember}>{careTeamMember}</span>
        </BulletSeparator>
      )}
    </Typography>
  );
};
