import { ClinicianRow } from 'app/care-team/past-care-team-members/ClinicianRow';
import { CoachRow } from 'app/care-team/past-care-team-members/CoachRow';
import styles from 'app/care-team/past-care-team-members/PastCareMembers.module.scss';
import { Coach, Psychiatrist, Therapist } from 'app/care-team/types';
import { getCareMemberRoleLabel } from 'app/care-team/utils';
import { Accordion } from 'shared-components/Accordion';
import { CareTeamRole } from 'generated/globalTypes';
import React from 'react';

export type CareTeamRoleAccordionProps = {
  role: CareTeamRole;
  items: (Coach | Therapist | Psychiatrist)[];
};

export const CareTeamRoleAccordion = (props: CareTeamRoleAccordionProps) => {
  const { role, items } = props;
  return (
    <Accordion
      key={`care_members_${role}`}
      testId={`care_members_${role}`}
      accordionTitle={`Past ${getCareMemberRoleLabel(role, true)}`}
    >
      {items?.length > 0 &&
        items?.map((item, i) =>
          item.__typename === 'CareTeamCoach'
            ? CoachRow(item)
            : ClinicianRow(item),
        )}
      {!items ||
        (items.length === 0 && (
          <div data-testid="care_member_no_data" className={styles.item}>
            <div>
              No past
              {getCareMemberRoleLabel(role, true, true)}
            </div>
          </div>
        ))}
    </Accordion>
  );
};
