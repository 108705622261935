import { CardFieldAndGridCell } from 'app/member-chart-cards/card-field/CardField';
import { CardSection } from 'app/member-chart-cards/card-section/CardSection';
import styles from 'app/member-chart-cards/prescriptions/PrescriptionCard.module.scss';
import { Pharmacy } from 'app/member-chart-cards/prescriptions/PrescriptionCardTypes';
import React from 'react';

interface PharmacyCardProps {
  pharmacy?: Pharmacy | null;
  fallbackCardMessage?: string;
  'data-testid'?: string;
}

export const PharmacyCard = (props: PharmacyCardProps) => {
  const { pharmacy, fallbackCardMessage, 'data-testid': testId } = props;
  let address = null;
  if (pharmacy?.name && pharmacy.address1) {
    address = [
      pharmacy.address1,
      `${pharmacy.city}, ${pharmacy.state} ${pharmacy.zip}`,
      pharmacy?.phone,
    ].join('\n');
  }

  const defaultPharmacyFields: CardFieldAndGridCell[] = [
    {
      'data-testid': testId,
      label: pharmacy?.name || '',
      labelForAnalytics: 'Prescriptions Card > Pharmacy Card',
      value: address,
    },
  ];
  return (
    <CardSection
      className={styles.cardSection}
      fields={defaultPharmacyFields}
      placeholder={fallbackCardMessage || 'No default pharmacy selected'}
      hideTooltip={true}
    />
  );
};
