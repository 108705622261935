import { EventType } from 'app/EventType';
import { createAction } from 'redux-reloaded';

export const viewClinicianAnalyticsAction = createAction(
  EventType.CLINICIAN_ANALYTICS_VIEWED,
  (payload: { clinicianId?: string }) => ({ ...payload }),
);
export const clickWeeklyBreakdownViewAction = createAction(
  EventType.WEEKLY_BREAKDOWN_VIEW_CLICKED,
  (payload: { clinicianId?: string; key: string }) => ({ ...payload }),
);
export const clickCareMetricsMonthlyPickerAction = createAction(
  EventType.CARE_METRICS_MONTHLY_PICKER_CLICKED,
  (payload: { clinicianId?: string; month: string }) => ({ ...payload }),
);
export const clickGoogleCalendarAction = createAction(
  EventType.GOOGLE_CALENDAR_LINK_CLICKED,
  (payload: { clinicianId?: string }) => ({ ...payload }),
);
export const clickFeedbackCarousalAction = createAction(
  EventType.FEEDBACK_CAROUSAL_CLICKED,
  (payload: { clinicianId?: string; direction: string }) => ({ ...payload }),
);
