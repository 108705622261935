export const Labels = {
  additionalSessionsRequired: {
    description:
      'Only edit if / when revising the total # of anticipated sessions.',
    id: 'numberOfSessionsAnticipated',
    label: 'Number of sessions anticipated',
    label2: 'Total # anticipated sessions',
    tooltip:
      'This is the total number of anticipated sessions for the member’s care.',
  },
  approaches: {
    id: 'approaches',
    label: 'Treatment approach/modality to be used by therapist',
    placeholder: 'Select...',
  },
  approachesUpdateComments: {
    id: 'approachesUpdateComments',
    placeholder: 'Details for changing approach / modality...',
  },
  changesToTreatmentPlan: {
    id: 'changesToTreatmentPlan',
    label: 'Review/update treatment plan/cultural considerations',
    placeholder: 'Type here...',
  },
  frequency: {
    description: 'Only edit if / when revising the cadence of future sessions',
    id: 'sessionFrequency',
    label: 'Frequency',
    placeholder: 'Select',
  },
  goals: {
    actionSteps: {
      description: 'Update each session',
      id: 'actionSteps',
      label: 'Homework/plan for next session',
      placeholder: 'Type here...',
    },
    goalDetails: {
      description:
        'Review every 4th session for progress and revise by 90 days',
      id: 'goalDetails',
      label: 'SMART goal details',
      placeholder: 'Type here...',
      subtext: 'Review every session for progress and revise by 90 days',
      tooltip:
        'Example: Decrease PHQ score by 2 points in the next 4 weeks by practicing mindfulness twice a week.',
    },
    goalStatus: {
      id: 'goalStatus',
      label: 'Goal Status',
      placeholder: 'Select...',
    },
    goalStatusUpdateComment: {
      id: 'goalStatusUpdateComment',
      placeholder: 'Status update details...',
    },
    goalType: {
      id: 'GoalType',
      label: 'What is the member working on?',
      placeholder: 'Select...',
    },
    goalTypeComment: {
      id: 'goalTypeComment',
      placeholder: 'Comment...',
    },
    label: 'Treatment Goal',
  },
  interventionsUsed: {
    id: 'interventionsUsed',
    label: 'Interventions used',
    placeholder: 'Select...',
  },
  memberOutOfScope: {
    id: 'memberOutOfScope',
    label: 'Member out of scope for care via platform?',
  },
  memberOutOfScopeReason: {
    id: 'memberOutOfScopeReason',
    placeholder: 'Comment...',
  },
  messageToCareTeam: {
    description:
      'Highlight successes, challenges, or other updates the team should be aware of this week',
    id: 'messageToCare',
    label: 'Message to care team',
    placeholder: 'Strengths, challenges, barriers, pertinent changes...',
  },
  numberOfSessionsAnticipated: {
    id: 'numberOfSessionsAnticipated',
    label: 'Total # anticipated sessions',
    placeholder: 'Select',
  },
  otherApproachComment: {
    id: 'otherApproachComment',
    label: '',
    placeholder: 'Type other modality used...',
  },
  referralsToCareOutsideGingerNecessary: {
    id: 'referralsToCareOutsideGingerNecessaryProgress',
    placeholder: 'Type here...',
  },
};
