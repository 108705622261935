import gql from 'graphql-tag';

export const getOutreachHistoryGQL = gql`
  query getOutreachHistory($input: GetCSCOutreachDataInput!) {
    getOutreachHistory(input: $input) {
      history {
        member
        pause
        updatedBy
        additionalNote
        reason
        unpauseAt
        createdAt
      }
    }
  }
`;
