import gql from 'graphql-tag';

export const getCoachInboxTotalQuery = gql`
  query GetCoachInboxTotal {
    getCoachInboxTotal {
      todaysTasksMembers {
        active
        risks
      }
      todaysCoachingMembers {
        conversations
        scheduledSessions
      }
    }
  }
`;

export const getClinicianInboxTotalQuery = gql`
  query GetClinicianInboxTotal {
    getInboxTotal {
      todaysTasksMembers {
        active
        risks
      }
      todaysClinicalMembers {
        appointments
      }
    }
  }
`;
