import { InboxContainer } from 'app/inbox/InboxContainer';
import VerticalMenu from 'app/inbox/vertical-menu';
import { useAppState } from 'app/state';
import { useLogger } from 'app/state/log/useLogger';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import React, { ReactNode } from 'react';
import { DropInAlert } from 'shared-components/DropInAlert/DropInAlert';
import { classNameCombiner } from 'utils';

import styles from './CareHubScreen.module.scss';

/** The core "frame" / "layout" of the app - e.g. top-bar, content area etc */
type Props = {
  children?: ReactNode;
};

function CareHubScreen({ children }: Props) {
  const logger = useLogger();
  const {
    enable_itms_toggle: enableItmsToggle,
  } = useFeatureFlags().transientFeatureFlags;
  const { currentAlert } = useAppState(({ structuredCoaching }) => ({
    currentAlert: structuredCoaching.currentAlert,
  }));

  if (!enableItmsToggle && currentAlert) {
    logger.warning(
      'CareHubScreen: ITMS alert received for a coach without the enable_itms_toggle feature flag enabled',
      { currentAlert },
    );
  }

  return (
    <div className={classNameCombiner([styles.careHubScreen])}>
      <div className={styles.verticalMenuContainer}>
        <VerticalMenu />
      </div>
      <InboxContainer />

      <div className={styles.careHubScreenWrapper}>{children}</div>
      {enableItmsToggle && currentAlert && <DropInAlert alert={currentAlert} />}
    </div>
  );
}
const MemoizedCareHubScreen = React.memo(CareHubScreen);
export default MemoizedCareHubScreen;
