import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';

/**
 * Use this component shows an alert when users tries to navigate or refresh/reload the page(component)
 * that renders it.
 */
export function ExistPrompt(props: { showPrompt: boolean; message: string }) {
  useEffect(() => {
    window.addEventListener('beforeunload', preventReloadHandle);

    function preventReloadHandle(event: BeforeUnloadEvent) {
      if (props.showPrompt) {
        const e = event || window.event;
        if (e) {
          e.preventDefault();
          e.returnValue = '';
        }
        return '';
      }
    }
    return () => {
      window.removeEventListener('beforeunload', preventReloadHandle);
    };
  }, [props.showPrompt]);

  return <Prompt when={props.showPrompt} message={props.message} />;
}
