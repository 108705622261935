import { createSelector } from '@reduxjs/toolkit';
import { TimeTokensAndStamps } from 'app/coach/chat/types';
import { RootState } from 'app/state/hooks/baseTypedHooks';

export const selectTimetokensMap = (state: RootState) =>
  state.conversationsTimetokens;
export const selectTimetokensForConversation = (
  state: RootState,
  memberCoachChannelId: string,
) =>
  memberCoachChannelId in state.conversationsTimetokens.timetokensMap
    ? state.conversationsTimetokens.timetokensMap[memberCoachChannelId]
    : null;

// benefit from reselect that memoizes the selectors when a selector can rely on the other selector
export const selectLastListenerRead = createSelector(
  [selectTimetokensForConversation],
  (conversationTimetokens: TimeTokensAndStamps | null) => {
    return conversationTimetokens
      ? conversationTimetokens.lastListenerReadTimeToken
      : null;
  },
);
export const selectLatestWriteTimestamp = createSelector(
  [selectTimetokensForConversation],
  (conversationTimetokens: TimeTokensAndStamps | null) => {
    return conversationTimetokens
      ? conversationTimetokens.latestWriteTimestamp
      : null;
  },
);
export const selectLastMemberRead = createSelector(
  [selectTimetokensForConversation],
  (conversationTimetokens: TimeTokensAndStamps | null): string | null => {
    return conversationTimetokens?.lastMemberReadTimeToken ?? null;
  },
);
export const selectLastMemberWrite = createSelector(
  [selectTimetokensForConversation],
  (conversationTimetokens: TimeTokensAndStamps | null) => {
    return conversationTimetokens
      ? conversationTimetokens.lastMemberWriteTimeToken
      : null;
  },
);
