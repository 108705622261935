import { NoteViewOrder, RoleType } from 'app/coach/coach-notes/CoachNotesTypes';
import { useFilters } from 'hooks/useFilters';
import { useState } from 'react';

export const useCoachNotesFilters = () => {
  const [noteOrder, setNoteOrder] = useState<NoteViewOrder>(
    NoteViewOrder.MOST_RECENT,
  );
  const [typeFilters, toggleTypeFilters] = useFilters([
    { checked: true, key: RoleType.COACHING, label: RoleType.COACHING },
    { checked: true, key: RoleType.CLINICAL, label: RoleType.CLINICAL },
  ]);

  const toggleOrderFilter = () =>
    setNoteOrder((prevState) =>
      prevState === NoteViewOrder.MOST_RECENT
        ? NoteViewOrder.LEAST_RECENT
        : NoteViewOrder.MOST_RECENT,
    );

  const activeTypeFilters = typeFilters
    .filter((_) => _.checked)
    .map((_) => _.key as RoleType);

  return {
    activeTypeFilters,
    noteOrder,
    toggleOrderFilter,
    toggleTypeFilters,
    typeFilters,
  };
};
