import { HomeworkStatus } from 'app/patients/tabs/content/types';
import { classNameCombiner } from 'utils';
import React from 'react';

import styles from './ProgressBadge.module.scss';

export interface ProgressBadgePros {
  status: HomeworkStatus;
}

export default function ProgressBadge({ status }: ProgressBadgePros) {
  const text = status.split('-').join(' ');
  return (
    <span className={classNameCombiner([styles.badge, styles[status]])}>
      {text}
    </span>
  );
}
