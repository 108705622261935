import { HighlightOffOutlined } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { IconActionBarItem } from 'app/appointments/IconActionBarItem';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';
import React from 'react';

import styles from './SectionDivider.module.scss';

export type Props = {
  label: string;
  onClick?: () => void;
  onDelete?: () => void;
  isNew?: boolean;
  isRemovable?: boolean;
  index?: number;
  subtext?: string;
  // use a red color to indicate it's a critical divider
  isCritical?: boolean;
  disabled?: boolean;
};

export function SectionDivider(props: Props) {
  const {
    label,
    isNew = false,
    onClick,
    onDelete,
    isRemovable = false,
    subtext,
    isCritical = false,
    disabled = false,
  } = props;

  const width = isRemovable
    ? [COLUMN_WIDTH.THREE, COLUMN_WIDTH.EIGHT, COLUMN_WIDTH.ONE]
    : [COLUMN_WIDTH.THREE, COLUMN_WIDTH.NINE];
  return (
    <Grid spacing={0} className={styles.root}>
      <Columns widths={width}>
        {!isNew ? (
          <>
            <span className={isCritical ? styles.label_critical : styles.label}>
              {label}
            </span>
            {subtext && (
              <h4
                className={
                  isCritical ? styles.subtext_critical : styles.subtext
                }
              >
                {subtext}
              </h4>
            )}
          </>
        ) : (
          <Button
            data-testid="newSection"
            className={styles.button}
            aria-controls="newSection"
            aria-haspopup="true"
            variant="contained"
            color="primary"
            onClick={onClick}
            disabled={disabled}
          >
            {label}
          </Button>
        )}
        <Divider
          className={isCritical ? styles.divider_critical : styles.divider}
        />
        {isRemovable && onDelete !== undefined && (
          <IconActionBarItem
            className={styles.deleteButton}
            title="Delete"
            Icon={HighlightOffOutlined}
            testId="removeBtn"
            onClick={onDelete}
          />
        )}
      </Columns>
    </Grid>
  );
}
