import { Grid } from '@mui/material';
import { MenuButton } from 'shared-components/menu/MenuButton';
import { Loader } from 'shared-components/loader/Loader';
import React, { ReactElement } from 'react';

import { EventTypeFilter, EventTypeFilterProps } from './EventTypeFilter';
import styles from './ScheduleActionBar.module.scss';

type NewEventDropDownProps = {
  onNewEvent: () => void;
  onNewAppointment: (start?: string, end?: string) => void;
};

function NewEventDropdown(props: NewEventDropDownProps) {
  const { onNewEvent, onNewAppointment } = props;
  return (
    <MenuButton
      buttonText="+ New"
      submenuText="Create new:"
      items={['Appointment', 'Event']}
      onItemClick={(item) => {
        switch (item) {
          case 'Appointment':
            onNewAppointment();
            break;
          case 'Event':
            onNewEvent();
        }
      }}
      testId="new-button"
    />
  );
}

type ActionBarProps = {
  leftElement: ReactElement;
  rightElement: ReactElement;
  centerElement?: ReactElement;
};

export function ActionBar(props: ActionBarProps) {
  const { leftElement, rightElement, centerElement } = props;

  return (
    <Grid
      id={styles.actionBar}
      container={true}
      direction="row"
      spacing={2}
      justifyContent="space-between"
    >
      <Grid item={true}>{leftElement}</Grid>
      {centerElement && <Grid item={true}>{centerElement}</Grid>}
      <Grid item={true}>{rightElement}</Grid>
    </Grid>
  );
}

type ScheduleActionBarProps = { loading?: boolean } & EventTypeFilterProps &
  NewEventDropDownProps;

export function ScheduleActionBar(props: ScheduleActionBarProps) {
  const loader = props.loading ? <Loader topMargin={false} /> : undefined;

  return (
    <ActionBar
      leftElement={
        <EventTypeFilter
          fields={props.fields}
          onOpen={props.onOpen}
          onFilter={props.onFilter}
        />
      }
      rightElement={
        <NewEventDropdown
          onNewEvent={props.onNewEvent}
          onNewAppointment={props.onNewAppointment}
        />
      }
      centerElement={loader}
    />
  );
}
