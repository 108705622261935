import { DependentIntakeReport } from '@ginger.io/core-ui';
import { useAppState } from 'app/state';
import { viewDependentReport } from 'app/state/amplitude/actions/etc';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

function DependentIntakeScreen(
  props: RouteComponentProps<{ patientId: string }>,
) {
  const { match } = props;
  const { params } = match;
  const role = useAppState((_) => _.user.role);

  const dispatch = useDispatch();
  useEffect(() => {
    if (role)
      dispatch(viewDependentReport({ memberId: params.patientId, role }));
  }, [dispatch]);

  return (
    <div data-testid="dependent-intake-screen">
      <DependentIntakeReport id={params.patientId} />
    </div>
  );
}

export default withRouter(DependentIntakeScreen);
