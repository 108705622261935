import MUITableBody from '@mui/material/TableBody';
import { TableRowProps } from '@mui/material/TableRow';
import { ReactElement } from 'react';

import styles from './TableBody.module.scss';

export interface V2TableBodyProps {
  children: ReactElement<TableRowProps>[];
  dataTestId?: string;
}

/** Wraps Material UI's TableBody with a type-safe API where the caller has to pass an array of TableRow as children */
export function V2TableBody({ dataTestId, children }: V2TableBodyProps) {
  return (
    <MUITableBody data-testid={dataTestId} className={styles.root}>
      {children}
    </MUITableBody>
  );
}
