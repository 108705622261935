import styles from 'app/coach/chat/messages/StickyChatNotification.module.scss';
import Tooltip from 'shared-components/tooltip/Tooltip';
import React from 'react';

export const StickyChatNotification = ({
  message,
  dateAndTime,
}: {
  message: string;
  dateAndTime: string;
}) => {
  return (
    <Tooltip
      classes={{ popper: styles.popper, tooltip: styles.tooltip }}
      title={dateAndTime}
      enterDelay={0}
      enterTouchDelay={0}
      placement="bottom-end"
    >
      <div className={styles.container}>
        <div className={styles.notification}>{message}</div>
      </div>
    </Tooltip>
  );
};
