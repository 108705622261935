import {
  PsychiatryIntakeSection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import {
  ClinicalNote,
  ClinicalNotesScreen,
  Section,
} from 'app/notes-ui/ClinicalNotesScreen';
import {
  AllergiesForm,
  validate as validateAllergies,
} from 'app/notes-ui/psychiatry/shared/allergies/AllergiesForm';
import {
  ChiefComplaintForm,
  validate as validateChiefComplaint,
} from 'app/notes-ui/psychiatry/shared/chief-complaint/ChiefComplaintForm';
import {
  FamilyHistoryForm,
  validate as validateFamilyHistory,
} from 'app/notes-ui/psychiatry/shared/family-history/FamilyHistoryForm';
import {
  MedicalHistoryForm,
  validate as validateMedicalHistory,
} from 'app/notes-ui/psychiatry/shared/medical-history/MedicalHistoryForm';
import {
  MedicalReviewOfSystemForm,
  validate as validateMedicalReviewOfSystem,
} from 'app/notes-ui/psychiatry/shared/medical-ros/MedicalReviewOfSystemForm';
import {
  MentalStatusExamForm,
  validate as validateMentalStatusExam,
} from 'app/notes-ui/psychiatry/shared/mental-status-exam/MentalStatusExamForm';
import {
  PsychReviewOfSystemForm,
  validate as validatePsychReviewOfSystem,
} from 'app/notes-ui/psychiatry/shared/psych-ros/PsychReviewOfSystemForm';
import {
  PsychiatricHistoryForm,
  validate as validatePsychiatricHistory,
} from 'app/notes-ui/psychiatry/shared/psychiatric-history/PsychiatricHistoryForm';
import {
  SocialHistoryForm,
  validate as validateSocialHistory,
} from 'app/notes-ui/psychiatry/shared/social-history/SocialHistoryForm';
import {
  TreatmentPlanForm,
  validate as validateTreatmentPlan,
} from 'app/notes-ui/psychiatry/shared/treatment-plan/TreatmentPlanForm';
import {
  ClinicianChecklistForm,
  validate as validateClinicianChecklist,
} from 'app/notes-ui/shared/clinician-checklist/ClinicianChecklistForm';
import { CollaborationPlanForm } from 'app/notes-ui/shared/collaboration-plan/CollaborationPlanForm';
import { validate as validateCollaboration } from 'app/notes-ui/shared/collaboration-plan/schema';
import { SafetyForm } from 'app/notes-ui/shared/safety-intake/SafetyForm';
import { validate as validateSafety } from 'app/notes-ui/shared/safety-progress/schema';
import { validate as validateSubstanceUsed } from 'app/notes-ui/shared/substance-abuse/schema';
import { SubstanceAbuseForm } from 'app/notes-ui/shared/substance-abuse/SubstanceAbuseForm';
import { getPsychiatrySectionLabel } from 'app/notes-ui/utils';
import { Status } from 'app/state/status/types/StateSlice';
import { PsychiatryIntakeIds } from 'app/vault/api/PsychiatryIntakeIds';
import { PsychiatryIntakeNote } from 'app/vault/api/PsychiatryIntakeNotesAPI';
import React, { ElementType } from 'react';

import { AssessmentPlanForm } from './assessment/AssessmentPlanForm';
import { validate as validateAssessment } from './assessment/schema';

export type Props = {
  appointmentId: string;
  memberId?: string;
  status: Status;
  note: PsychiatryIntakeNote;
  draftNote: PsychiatryIntakeNote;
  onSubmit: (section: PsychiatryIntakeSection) => Promise<void>;
  updateDraftNoteState: (section: PsychiatryIntakeSection) => void;
  readOnly: boolean;
};

function section<T extends PsychiatryIntakeSection>(
  name: T['name'],
  form: ElementType<{
    appointmentId: string;
    noteLastUpdatedAt?: string;
    onSubmit: (section: Record<string, any>) => void;
    updateDraftNoteState: (section: Record<string, any>) => void;
  }>,
  validator: (data: any) => boolean,
): Section<T> {
  return [name, getPsychiatrySectionLabel(name), form, validator];
}

const sections = [
  section(
    PsychiatrySectionName.CLINICIAN_CHECKLIST,
    (props) => <ClinicianChecklistForm templateType="Psychiatry" {...props} />,
    validateClinicianChecklist,
  ),
  section(
    PsychiatrySectionName.CHIEF_COMPLAINT,
    ChiefComplaintForm,
    validateChiefComplaint,
  ),
  section(
    PsychiatrySectionName.PSYCH_ROS,
    PsychReviewOfSystemForm,
    validatePsychReviewOfSystem,
  ),
  section(
    PsychiatrySectionName.MEDICAL_ROS,
    MedicalReviewOfSystemForm,
    validateMedicalReviewOfSystem,
  ),
  section(PsychiatrySectionName.SAFETY, SafetyForm, validateSafety),
  section(
    PsychiatrySectionName.PSYCH_HISTORY,
    PsychiatricHistoryForm,
    validatePsychiatricHistory,
  ),
  section(PsychiatrySectionName.ALLERGIES, AllergiesForm, validateAllergies),
  section(
    PsychiatrySectionName.MEDICAL_HISTORY,
    MedicalHistoryForm,
    validateMedicalHistory,
  ),
  section(
    PsychiatrySectionName.FAMILY_HISTORY,
    FamilyHistoryForm,
    validateFamilyHistory,
  ),
  section(
    PsychiatrySectionName.SOCIAL_HISTORY,
    SocialHistoryForm,
    validateSocialHistory,
  ),
  section(
    PsychiatrySectionName.SUBSTANCE_ABUSE,
    SubstanceAbuseForm,
    validateSubstanceUsed,
  ),
  section(
    PsychiatrySectionName.MENTAL_STATUS_EXAM,
    MentalStatusExamForm,
    validateMentalStatusExam,
  ),
  section(
    PsychiatrySectionName.ASSESSMENT,
    AssessmentPlanForm,
    validateAssessment,
  ),
  section(
    PsychiatrySectionName.TREATMENT_PLAN,
    TreatmentPlanForm,
    validateTreatmentPlan,
  ),
  section(
    PsychiatrySectionName.COLLABORATION_PLAN,
    CollaborationPlanForm,
    validateCollaboration,
  ),
];

export function PsychiatryIntakeNoteScreen(props: Props) {
  const {
    appointmentId,
    note,
    status,
    memberId,
    onSubmit,
    updateDraftNoteState,
    draftNote,
  } = props;

  return (
    <ClinicalNotesScreen
      appointmentId={appointmentId}
      status={status}
      onSubmit={onSubmit}
      updateDraftNoteState={updateDraftNoteState}
      memberId={memberId}
      sections={sections}
      ids={PsychiatryIntakeIds}
      note={note as ClinicalNote<PsychiatryIntakeSection>}
      draftNote={draftNote as ClinicalNote<PsychiatryIntakeSection>}
      readOnly={props.readOnly}
    />
  );
}
