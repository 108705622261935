import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  TypedDocumentNode,
} from '@apollo/client';
import { NetworkError } from '@apollo/client/errors';
import { setContext } from '@apollo/client/link/context';
import { Operation } from '@apollo/client/link/core/types';
import { ErrorHandler, onError } from '@apollo/client/link/error';
import { createHttpLink } from '@apollo/client/link/http';
import { RetryLink } from '@apollo/client/link/retry';
import { SentryClient } from '@ginger.io/core-ui';
import { OktaAuth } from '@okta/okta-auth-js';
import { SentryLink } from 'apollo-link-sentry';
import {
  isGraphQLAuthenticationError,
  isServerError,
} from 'shared-components/error-state/utils';
import { flattenAdditionalData } from 'app/services/sentry/utils';
import { scrubAndFlattenVariables } from 'app/services/utils';
import { ILogger } from 'app/state/log/Logger';
import userStorage from 'app/state/user/userStorage';
import { oktaTokenExpirationDate } from 'utils';
import tokenStorage from 'app/vault/VaultTokenStorage';
import { DocumentNode, GraphQLError } from 'graphql';

export function createApolloClient(
  okta: OktaAuth,
  sentry: SentryClient,
  logger: ILogger,
  uri: string,
): ApolloClient<NormalizedCacheObject> {
  const graphQLEndpoint = createHttpLink({
    uri,
  });
  const errorLink = onError(createGraphQLErrorHandler(logger, okta));
  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...buildAuthHeaders(okta),
      },
    };
  });

  const retryLink = createRetryLink(logger);
  const cache = new InMemoryCache({
    typePolicies: {
      CareTeamCoach: {
        keyFields: ['id', 'isTeamLead', 'isBackup'],
      },
      ListenerAuthenticatedUser: {
        keyFields: ['listenerId', 'listenerUserId'],
      },
      User: {
        fields: {
          billing: {
            merge: true,
          },
          coachingCareTeam: {
            merge: true,
          },
          coverageDetails: {
            merge: true,
          },
        },
      },
    },
  });
  const sentryLink = new SentryLink({
    attachBreadcrumbs: {
      includeError: true,
    },
    setTransaction: false,
  });

  return new ApolloClient<NormalizedCacheObject>({
    cache,
    link: ApolloLink.from([
      authLink,
      retryLink,
      errorLink,
      sentryLink,
      graphQLEndpoint,
    ]),
  });
}

function isQuery(query: DocumentNode | TypedDocumentNode<any, any>): boolean {
  const operationDefinition = query.definitions.find(
    (_) => _.kind === 'OperationDefinition' && _.operation === 'query',
  );
  return Boolean(operationDefinition);
}

// Don't send the following types of errors to Sentry.
// See https://www.apollographql.com/docs/apollo-server/v2/data/errors#error-codes.
const ERROR_CODES_TO_IGNORE = [
  'UNAUTHENTICATED',
  'FORBIDDEN',
  'DAYLIGHT SAVINGS DISRUPTIVE SHIFT',
  'APPOINTMENT CLINICIAN DATETIME CONFLICT',
  'APPOINTMENT PATIENT DATETIME CONFLICT',
  'CLINICIAN UNAVAILABLE',
];

export function createGraphQLErrorHandler(
  logger: ILogger,
  okta: OktaAuth,
): ErrorHandler {
  return ({ graphQLErrors, networkError, operation, forward }) => {
    const gqlErrors = graphQLErrors ?? [];

    // if the error is an authentication, we want to avoid sending it to sentry, instead we log them as warnings
    if (
      isGraphQLAuthenticationError(gqlErrors) ||
      isGraphQLAuthenticationError(networkError)
    ) {
      const logAuthError = async () => {
        const idToken = okta.getIdToken();
        logger.warning('onErrorLink: authentication error', {
          ...(graphQLErrors
            ? createAdditionalDataContext(graphQLErrors[0], operation)
            : {}),
          ...(networkError
            ? createAdditionalDataContext(networkError, operation)
            : {}),
          networkError: true,

          oktaExpiration: oktaTokenExpirationDate(idToken)?.toISOString(),
          // adding expiration date, so we know which token caused the authentication error
          vaultTokenExpiration: tokenStorage
            .getTokenExpirationDate()
            ?.toISOString(),
        });
      };
      void logAuthError();
      return;
    }

    if (isRetryableGQLError(gqlErrors, operation)) {
      // Retry the request by returning a new observable. This will retry the operation once without causing
      // infinite loop
      logger.info(`onErrorLink: Retrying query ${operation.operationName}`, {
        graphQLErrors,
      });
      return forward(operation);
    }
    gqlErrors.forEach((error) => captureGQLError(logger, operation)(error));

    if (networkError) void captureNetworkError(logger, operation, networkError);
  };
}

function createAdditionalDataContext(
  error: GraphQLError | NetworkError,
  operation: Operation,
) {
  const { operationName, variables } = operation;
  const baseData: Record<string, any> = {
    operationName,
    ...scrubAndFlattenVariables(variables),
  };
  if (error instanceof GraphQLError) {
    const { locations, path, positions, extensions } = error;
    return {
      ...baseData,
      code: extensions?.code,
      locations,
      path,
      positions,

      // We "flatten" these properties to prevent Sentry from truncating nested
      // data e.g. foo: { boo: [1,2,3] } becomes foo: { boo: "[Array]" } in the console
      // at the time of writing, no depth param existed on the Sentry JS SDK
      ...extensions?.response,
      ...extensions?.exception,
    };
  }
  if (isServerError(error)) {
    const { result, statusCode } = error;
    return {
      ...baseData,
      ...flattenAdditionalData(result),
      statusCode,
    };
  }

  return baseData;
}

function captureGQLError(logger: ILogger, operation: Operation) {
  return (error: GraphQLError) => {
    const { message, extensions } = error;
    const additionalData = createAdditionalDataContext(error, operation);

    if (extensions?.code && ERROR_CODES_TO_IGNORE.includes(extensions?.code)) {
      logger.warning(`ErrorLink: ${message}`, additionalData);
      return;
    }

    // GraphQLError instances do not pass an instanceof Error check that Sentry uses,
    // so to make error reporting in Sentry nicer, we wrap the message in an Error
    // + send additional data
    const sentryError = new Error(message);

    void logger.error(sentryError, { ...additionalData, graphQLError: true });
  };
}

function captureNetworkError(
  logger: ILogger,
  operation: Operation,
  error: NetworkError,
) {
  if (isRetryableNetworkError(error, operation)) {
    //  do nothing since we're going to retry the request and send error to sentry after exhausting the retry. This is
    //  to avoid logging & sending to sentry the same error multiple times, since all network error gets capture
    //  in onErrorLink handler.
    return;
  }
  if (!isServerError(error)) {
    logger.error(error as Error, {
      ...createAdditionalDataContext(error, operation),
      networkError: true,
    });
    return;
  }
  for (const err of error.result.errors ?? []) {
    logger.error(err as Error, {
      ...createAdditionalDataContext(error, operation),
      networkError: true,
    });
  }
}

function createRetryLink(logger: ILogger) {
  return new RetryLink({
    attempts: (retryCount: number, operation: Operation, error: any) => {
      const { query, operationName, variables } = operation;
      if (!isQuery(query)) return false;
      const additionalData = {
        error,
        isOnline: window.navigator.onLine,
        operationName,
        retryCount,
        variables: scrubAndFlattenVariables(variables), // to tell us if the user is connected at the time of failure
      };
      if (retryCount > 5) {
        logger.warning(
          `RetryLink.attempts: Exhausted retry attempts for query ${operationName} with errorMessage ${error.message}`,
          additionalData,
        );
        // Since we are unable to recover from this error, report to sentry
        logger.error(error as Error, {
          ...createAdditionalDataContext(error, operation),
          networkError: true,
        });
        return false;
      }
      const shouldRetry = isRetryableNetworkError(error, operation);
      logger.info(
        `RetryLink.attempts: Retrying query with errorMessage ${error.message}`,
        {
          ...additionalData,
          shouldRetry,
        },
      );
      return shouldRetry;
    },

    delay: {
      initial: 600,
      jitter: true,
      max: Infinity, // initial delay in milliseconds
    },
  });
}

function isRetryableGQLError(
  gqlErrors: ReadonlyArray<GraphQLError>,
  operation: Operation,
) {
  const codes = gqlErrors.map(
    ({ extensions }) =>
      extensions.exception?.code ?? extensions.exception?.errno,
  );
  const messages = gqlErrors.map(({ message }) => message);
  const isConnResetError = codes.some((code) => /ECONNRESET/i.test(code ?? ''));
  const isFailedToFetch = messages.some((message) =>
    /Failed to Fetch/i.test(message ?? ''),
  );
  return isQuery(operation.query) && (isConnResetError || isFailedToFetch);
}

function isRetryableNetworkError(error: any, operation: Operation) {
  const { query } = operation;
  if (!isQuery(query) || isGraphQLAuthenticationError(error)) return false;
  const connectionResetError = /ECONNRESET/i.test(error.message ?? '');
  return (
    (error.message ?? '').toLowerCase().includes('failed to fetch') ||
    connectionResetError
  );
}

export const buildAuthHeaders = (okta: OktaAuth) => {
  const token = okta.getIdToken();
  const vaultToken = tokenStorage.getIfNotExpired();
  const useOktaAuth = userStorage.get()?.useOktaAuth;
  return {
    authorization: token ? `Bearer ${token}` : '',
    vaultAuthorization: vaultToken ? `Bearer ${vaultToken}` : undefined,
    ...(useOktaAuth ? { useOktaAuth: 'true' } : {}),
  };
};
