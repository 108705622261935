import { InboxItemState, InboxSections } from 'app/inbox/types';

import { updateTabSection } from '../actions';
import {
  openConvoAndTaskCompareFn,
  riskTaskCompareFn,
  scheduledCheckComparedFn,
  todaysCompletedInboxItemsComparedFn,
} from '../sort-function';
import { ActionHandler } from './types';

export const onSortInboxSections = ({
  action,
  redux,
}: ActionHandler<{ sections: InboxSections[] }>) => {
  function mapSortAndGetIds(
    ids: Set<string>,
    compareFn: (a: InboxItemState, b: InboxItemState) => number,
  ) {
    return new Set(
      Array.from(ids)
        .map((id) => inboxItems[id])
        .sort(compareFn)
        .map((_) => _.id),
    );
  }

  const {
    inbox: { tabSections, inboxItems, unreadMessagesMap, conversationStateMap },
    conversationsTimetokens: { timetokensMap },
  } = redux.getState();
  const updatedTabSections = { ...tabSections };
  const sectionSet = new Set(action.payload.sections);

  if (sectionSet.has(InboxSections.RISKS)) {
    const ids = mapSortAndGetIds(
      tabSections[InboxSections.RISKS].ids,
      riskTaskCompareFn,
    );
    updatedTabSections[InboxSections.RISKS] = {
      ...tabSections[InboxSections.RISKS],
      ids,
    };
  }

  if (sectionSet.has(InboxSections.CONVERSATIONS_AND_TASKS)) {
    const section = tabSections[InboxSections.CONVERSATIONS_AND_TASKS];
    const ids = mapSortAndGetIds(
      section.ids,
      openConvoAndTaskCompareFn(
        unreadMessagesMap,
        timetokensMap,
        conversationStateMap,
      ),
    );
    updatedTabSections[InboxSections.CONVERSATIONS_AND_TASKS] = {
      ...section,
      ids,
    };
  }

  if (sectionSet.has(InboxSections.COMPLETED)) {
    const ids = mapSortAndGetIds(
      tabSections[InboxSections.COMPLETED].ids,
      todaysCompletedInboxItemsComparedFn(conversationStateMap),
    );
    updatedTabSections[InboxSections.COMPLETED] = {
      ...tabSections[InboxSections.COMPLETED],
      ids,
    };
  }

  if (sectionSet.has(InboxSections.SCHEDULED_CHECKINS)) {
    const ids = mapSortAndGetIds(
      tabSections[InboxSections.SCHEDULED_CHECKINS].ids,
      scheduledCheckComparedFn,
    );
    updatedTabSections[InboxSections.SCHEDULED_CHECKINS] = {
      ...tabSections[InboxSections.SCHEDULED_CHECKINS],
      ids,
    };
  }

  redux.dispatch(updateTabSection({ tabSections: updatedTabSections }));
};
