import { CareTeamRoleAccordion } from 'app/care-team/past-care-team-members/CareTeamRoleAccordion';
import styles from 'app/care-team/past-care-team-members/PastCareMembers.module.scss';
import { Coach, Psychiatrist, Therapist } from 'app/care-team/types';
import { CareTeamRole } from 'generated/globalTypes';
import React from 'react';

export type PastCareMembersProps = {
  coaches: Coach[];
  therapists: Therapist[];
  psychiatrists: Psychiatrist[];
};

const PastCareMembers = (props: PastCareMembersProps) => {
  const { coaches, therapists, psychiatrists } = props;

  return (
    <div className={styles.itemList}>
      <CareTeamRoleAccordion role={CareTeamRole.COACH} items={coaches} />
      <CareTeamRoleAccordion role={CareTeamRole.THERAPIST} items={therapists} />
      <CareTeamRoleAccordion
        role={CareTeamRole.PSYCHIATRIST}
        items={psychiatrists}
      />
    </div>
  );
};

export default PastCareMembers;
