import { useEffect, useRef, useState } from 'react';

import { BreakpointsConfig } from '../shared-components/masonry/Masonry';
import { useResizeObserver } from './useResizeObserver';

export const useContainerWidth = (breakpointsConfig: BreakpointsConfig) => {
  const [numberOfColumns, setNumberOfColumns] = useState(1);
  const target = useRef(null);
  const [width] = useResizeObserver(target);

  useEffect(() => {
    if (typeof breakpointsConfig === 'number') {
      setNumberOfColumns(breakpointsConfig);
    } else if (width) {
      const breakPoints = Object.keys(breakpointsConfig).reverse();
      for (let i = 0; i <= breakPoints.length; i++) {
        const currentBreakpoint: number = +breakPoints[i];
        if (width >= currentBreakpoint) {
          const numberOfColumnsValue = breakpointsConfig[currentBreakpoint];
          setNumberOfColumns(numberOfColumnsValue);
          break;
        } else {
          setNumberOfColumns(1);
        }
      }
    }
  }, [width, breakpointsConfig]);

  return { numberOfColumns, target };
};
