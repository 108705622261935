import {
  arrayField,
  booleanField,
  field,
  FieldDefinitions,
  stringField,
  useForm,
} from '@ginger.io/react-use-form';
import {
  CoachingNote,
  CoachingNote_Version as Version,
} from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/notes/CoachingNote';
import {
  Section,
  Section_SectionType as SectionType,
} from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/Section';
import {
  LegacyNoteType,
  SummaryNote as SummaryNoteFieldDefs,
} from 'app/coach/coach-notes/CoachNotesTypes';
import { CardTextAreaField } from 'app/coach/coach-notes/note-detail-view/note-fields';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import { CardTextArea } from 'app/member-chart-cards/text-area/CardTextArea';
import { useAppState } from 'app/state';
import React, { Fragment } from 'react';

const SUMMARY_NOTE_LABEL_MAP: { [x: string | number]: string } = {
  backgroundInfo: 'Background Info',
  demo: 'Demo',
  disposition: 'Disposition',
  followUp: 'Follow-up',
  goals: 'Goals',
  issues: 'Issues',
  location: 'Location',
  medicationsAndCare: 'Medications and Care',
  msAndCoachNotes: 'MS/Coach Notes',
  triage: 'Triage',
};

type AllowedSectionType = Exclude<
  SectionType,
  | SectionType.undefined_section_type
  | SectionType.section_none
  | SectionType.UNRECOGNIZED
>;

const DAILY_COACHING_NOTE_LABEL_MAP: { [key in AllowedSectionType]: string } = {
  [SectionType.section_daily_coaching_going_well]: "What's going well",
  [SectionType.section_daily_coaching_challenges]: 'Opportunities/Challenges',
  [SectionType.section_daily_coaching_next_steps]: "What's next",
  [SectionType.section_daily_coaching_other]: 'Other',
};

export const summaryNoteFieldDefs = {
  backgroundInfo: stringField({ rules: [] }),
  demo: stringField({ rules: [] }),
  disposition: stringField({ rules: [] }),
  followUp: stringField({ rules: [] }),
  goals: stringField({ rules: [] }),
  issues: stringField({ rules: [] }),
  medicationsAndCare: stringField({ rules: [] }),
  msAndCoachNotes: stringField({ rules: [] }),
  triage: stringField({ rules: [] }),
};

export const dailyCoachingNoteFieldDefs: FieldDefinitions<CoachingNote> = {
  callerId: stringField({ rules: [] }),
  coachId: stringField({ rules: [] }),
  coachName: stringField({ rules: [] }),
  createdAt: stringField({ rules: [] }),
  draft: booleanField({ rules: [] }),
  editable: booleanField({ rules: [] }),
  memberId: stringField({ rules: [] }),
  publishedAt: stringField({ rules: [] }),
  sections: arrayField<Section>({ rules: [] }),
  title: stringField({ rules: [] }),
  version: field<Version>({ default: Version.v0, rules: [] }),
};

export const LegacyNoteTemplate = ({
  readOnly,
  noteType,
  'data-testid': testId = 'legacyNoteTemplate',
}: {
  readOnly: boolean;
  noteType: LegacyNoteType;
  'data-testid'?: string;
}): React.ReactElement | null => {
  const { selectedNote } = useAppState((_) => _.coachNotes);
  const { fields: summaryNoteFields } = useForm<SummaryNoteFieldDefs>(
    summaryNoteFieldDefs,
    {
      ...(selectedNote?.data as SummaryNoteFieldDefs),
    },
  );
  const { fields: dailyCoachingNoteFields } = useForm<CoachingNote>(
    dailyCoachingNoteFieldDefs,
    {
      ...(selectedNote?.data as CoachingNote),
    },
  );

  const tooltipEventLabel = `Coach Notes > Legacy Note > ${noteType}`;

  return (
    <div data-testid={testId} className={styles.cardSection}>
      {noteType === LegacyNoteType.SUMMARY_NOTE &&
        Object.entries(summaryNoteFields).map(([key, field]) => (
          <Fragment key={`${SUMMARY_NOTE_LABEL_MAP[key]}`}>
            <FieldLabel
              className={styles.label}
              labelText={SUMMARY_NOTE_LABEL_MAP[key]}
            />
            <CardTextAreaField
              field={field}
              tooltipEventLabel={`${tooltipEventLabel}: ${SUMMARY_NOTE_LABEL_MAP[key]}`}
              tooltipText=""
              placeholder=""
              readOnly={readOnly}
            />
          </Fragment>
        ))}

      {noteType === LegacyNoteType.DAILY_COACHING_NOTE && (
        <>
          {dailyCoachingNoteFields.sections.value.map((section) => {
            const label =
              DAILY_COACHING_NOTE_LABEL_MAP[
                section.sectionType as AllowedSectionType
              ];
            return (
              <Fragment key={label}>
                <FieldLabel className={styles.label} labelText={label} />
                <CardTextArea
                  key={label}
                  value={section.value}
                  tooltipEventLabel={`${tooltipEventLabel}: ${label}`}
                  tooltipText=""
                  placeholder=""
                  readOnly={readOnly}
                  minRows={1}
                />
              </Fragment>
            );
          })}
        </>
      )}
    </div>
  );
};
