export type EmptyValues<T> = {
  [K in keyof T]: T[K] extends Array<any> ? [] : undefined;
};

export type ProtobufEnum = Record<string | number, string | number>;
export type ProtobufCodeSet = {
  cptCode: number;
  icd10Codes: number[];
};

// The protobuf codegen library we use sticks extra fields on
// generated enum types, so we strip these off when we render the dropdown
export const blacklistedKeys = new Set(['UNRECOGNIZED']);

export type EnumOption = {
  name: string;
  value: number;
  readonly?: boolean;
};
