import Button from '@mui/material/Button';
import styles from 'shared-components/CardActionMenu/CardActionMenu.module.scss';
import { classNameCombiner } from 'utils';
import React from 'react';
import { TooltipWithRedux } from 'shared-components/tooltip/TooltipWithRedux';

export interface Props {
  chipIcon: JSX.Element;
  setAnchorEl?: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  className?: string;
  tooltipEventLabel?: string;
  tooltipText?: string;
  'data-testid'?: string;
}

export default function ChipWithTooltip({
  setAnchorEl = () => {},
  onClick = () => {},
  chipIcon,
  className = '',
  tooltipEventLabel = '',
  tooltipText,
  'data-testid': testId = 'chipWithTooltip',
}: Props) {
  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onClick(event);
  };

  return (
    <TooltipWithRedux
      labelForAnalytics={tooltipEventLabel}
      title={tooltipText || ''}
      placement="left"
      classes={{
        tooltipPlacementLeft: styles.tooltip,
      }}
      enterDelay={300}
    >
      <Button
        data-testid={testId}
        className={classNameCombiner([styles.addNewItemButton, className])}
        aria-controls="new-menu"
        variant="contained"
        size="small"
        onClick={handleOnClick}
      >
        {chipIcon}
      </Button>
    </TooltipWithRedux>
  );
}
