import { AnyAction } from '@reduxjs/toolkit';
import {
  AutoMessageRPC,
  ContentRPC,
  IgnoredRPCs,
  MemberActionRPC,
  RPCEnvelope,
  ServerRPC,
} from 'app/coach/pubnub/types';
import {
  convertRPCToContentButton,
  convertRPCToMessage,
  isValidCallerReadMessageRpc,
  isValidListenerReadMessageRpc,
} from 'app/coach/pubnub/utils';
import { InboxSections } from 'app/inbox/types';
import { HomeworkStatus } from 'app/patients/tabs/content/types';
import { loadCareTeam } from 'app/state/care-team/actions';
import { addHomework, updateHomework } from 'app/state/content/actions';
import { updateTimetokens } from 'app/state/features/conversationTimetokens/conversationTimetokensSlice';
import { SchedulerService } from 'app/state/features/scheduler/SchedulerService';
import { updateCurrentAlert } from 'app/state/features/structuredCoaching/structuredCoachingSlice';
import { ILogger } from 'app/state/log/Logger';
import { refetchMemberData } from 'app/state/member-tabs/actions';
import { State } from 'app/state/schema';
import { onNewRiskAlertTaskCreated } from 'app/state/tasks/actions';
import { UserRole } from 'generated/globalTypes';
import { batch } from 'react-redux';
import { Dispatch, MiddlewareAPI } from 'redux';

import {
  addMessage,
  onCallerReadMessageRPC,
  onNewConversation,
  onNewMessageRPC,
  readAllMessages,
  refreshCoachTodaysMemberList,
  sortInboxSections,
} from '../actions';
import {
  autoMessageErrorMessage,
  pubnubTimeTokenToMoment,
  redactSensitiveInfoFromPubhubData,
} from '../utils';
import { ActionHandler } from './types';

export const onHandleRPC = async ({
  action,
  redux,
  context: { services },
}: ActionHandler<{ envelope: RPCEnvelope }>) => {
  const {
    payload: { envelope },
  } = action;
  const rpcType = envelope.message.rpc;
  const { logger } = services;
  logger.debug('Handle RPC: ', {
    envelope: redactSensitiveInfoFromPubhubData(envelope),
    rpcType,
  });
  const { channel: channelId } = envelope;
  switch (rpcType) {
    case MemberActionRPC.UPDATE_READ_STATE: {
      handleUpdateReadStateRPC({ envelope, logger, redux });
      break;
    }
    case AutoMessageRPC.BOOKMARK_CREATED:
    case AutoMessageRPC.LINK_CLICKED:
    case AutoMessageRPC.CLINICAL_INTEREST:
    case AutoMessageRPC.PROCESS_INTAKE_LINK: {
      const { message, error } = convertRPCToMessage(envelope, logger);
      if (error) {
        const { channel, message } = envelope;
        logger.error(new Error(autoMessageErrorMessage), {
          channel,
          error,
          id: message.id,
        });
        return;
      }
      if (message) {
        redux.dispatch(addMessage(message));
      }
      break;
    }
    case ServerRPC.DIRTY_CONVERSATION: {
      const {
        message: { member_id, rpc_source },
      } = envelope;
      if (member_id) {
        const memberId = member_id.toString();
        redux.dispatch(
          refreshCoachTodaysMemberList({
            RPC: ServerRPC.DIRTY_CONVERSATION,
            loadMessageHistory: true,
            memberIds: [memberId.toString()],
            rpcSource: rpc_source,
            sections: [
              InboxSections.CONVERSATIONS_AND_TASKS,
              InboxSections.SCHEDULED_CHECKINS,
              InboxSections.COMPLETED,
              InboxSections.RISKS,
            ],
          }),
        );
      } else {
        logger.error(
          new Error(
            `Got the dirty_conversation rpc but it has an invalid member_id`,
          ),
          {
            envelope,
            member_id,
          },
        );
      }
      break;
    }
    case ServerRPC.NEW_MESSAGE: {
      const {
        message: { member_ids },
      } = envelope;
      if (member_ids.length) {
        redux.dispatch(onNewMessageRPC({ envelope }));
      } else {
        logger.error(
          new Error(
            `Got the new_message rpc but it has an invalid member_ids prop`,
          ),
          {
            envelope,
            member_ids,
          },
        );
      }
      break;
    }
    case ContentRPC.GIO_DEEPLINK_CLICKED: {
      const {
        message: { extra_params },
      } = envelope;

      const {
        inbox: { inboxItems },
      } = redux.getState();

      // Obtaining the memberId from inbox for a given channelId
      const memberIdWithItem = Object.entries(inboxItems).find(
        ([_, item]) => item.memberCoachChannelId === channelId,
      );
      const memberId = memberIdWithItem ? memberIdWithItem[0] : undefined;

      if (memberId) {
        // Handle the gio_deeplink_clicked RPC
        redux.dispatch(
          updateHomework({
            label: extra_params.label,
            status: HomeworkStatus.IN_PROGRESS,
            userId: memberId,
          }),
        );
      } else {
        // No memberID found in the inbox collection. This is not an error because a member can tap a deeplink
        // button at any time and may not still be on the coach's care team when they do tap it.
        logger.info(
          "Got the gio_deeplink_clicked rpc but there's no channel related to memberId in inbox collection",
          {
            envelope,
          },
        );
      }
      break;
    }
    // This RPC is not exclusively for content; it is used for any deeplink within the Ginger app. For example, see
    // https://github.com/HeadspaceMeditation/listener-server/blob/03835d1819dc4db447ada0c8f98b77e67d655d28/apps/braze_api/views.py#L368.
    case ContentRPC.GIO_DEEPLINK_BUTTON: {
      const {
        inbox: { inboxItems },
      } = redux.getState();

      const memberIdWithItem = Object.entries(inboxItems).find(
        ([_, item]) => item.memberCoachChannelId === channelId,
      );
      const memberId = memberIdWithItem ? memberIdWithItem[0] : undefined;

      const { message, error } = convertRPCToContentButton(envelope);
      if (error) {
        logger.error(error, {
          envelope: redactSensitiveInfoFromPubhubData(envelope),
        });
        return;
      }

      if (memberId && message?.contentData) {
        redux.dispatch(addMessage(message));
        redux.dispatch(
          addHomework({ homework: message.contentData, userId: memberId }),
        );
      } else {
        // No memberID found in the inbox collection
        logger.error(
          new Error(
            `Got the gio_deeplink_button rpc but there's no channel related to memberId in inbox collection`,
          ),
          { envelope },
        );
      }
      break;
    }
    case ServerRPC.NEW_RISK_ALERT_TASK: {
      const rpcSource: string | undefined = envelope.message.rpc_source;
      if (!envelope.message.member_id) {
        logger.error(
          new Error(`new_risk_alert_task rpc with an invalid member_id prop`),
          {
            envelope,
          },
        );
      } else {
        const memberId = `${envelope.message.member_id}`;
        redux.dispatch(
          refreshCoachTodaysMemberList({
            RPC: ServerRPC.NEW_RISK_ALERT_TASK,
            loadMessageHistory: true,
            memberIds: [memberId],
            rpcSource,
            sections: [
              InboxSections.RISKS,
              InboxSections.CONVERSATIONS_AND_TASKS,
            ],
          }),
        );
        redux.dispatch(
          onNewRiskAlertTaskCreated({
            memberId,
            taskType: envelope.message.rpc_source,
          }),
        );
      }
      break;
    }
    case ServerRPC.CARE_TEAM_CHANGE: {
      const { member_id } = envelope.message;
      const memberId = member_id.toString();
      if (memberId) {
        redux.dispatch(refetchMemberData({ memberId }));
      } else {
        logger.error(new Error('handleRPC: missing member id'), { envelope });
      }
      break;
    }

    // This RPC is sent when a coach's status changes.
    // It does not contain the shift status, so we need to reload the care team.
    case ServerRPC.SHIFT_CHANGE: {
      const {
        careTeam: { activeMemberId },
      } = redux.getState();

      if (activeMemberId !== null && activeMemberId.length > 0) {
        redux.dispatch(
          loadCareTeam({ forceRefresh: true, memberId: activeMemberId }),
        );
        logger.info('handleRPC: SHIFT_CHANGE received, reloading care team', {
          activeMemberId,
        });
      }

      break;
    }

    case ServerRPC.SCHEDULING_CHANGE: {
      const {
        message: { member_id: memberId },
      } = envelope;
      const {
        inbox: { inboxItems },
        multiTab: { activeTab },
        user: { role },
        scheduler: { showD2cSessionInfoBanner },
      } = redux.getState();

      // check if the member chart is open for this member before making the request for coaches only
      if (activeTab === memberId?.toString() && role === UserRole.COACH) {
        redux.dispatch(
          (SchedulerService.getMemberUpcomingCoachingSessions({
            memberId,
          }) as unknown) as AnyAction,
        );

        // if the member is D2C, refetch the session info as the session counts have likely changed
        // TODO: whhen MARKET-3453 is complete, we can remove the showD2cSessionInfoBanner check and just rely on on member.isD2C
        if (showD2cSessionInfoBanner && inboxItems[memberId]?.isD2c) {
          redux.dispatch(
            (SchedulerService.getMemberSessionUsage({
              isD2c: inboxItems[memberId]?.isD2c,
              memberId,
            }) as unknown) as AnyAction,
          );
        }
      }
      break;
    }

    case ServerRPC.NEW_CONVERSATION:
      redux.dispatch(onNewConversation({ envelope }));
      break;

    case ServerRPC.COACH_ITMS_ALERT: {
      const {
        message: { reason },
      } = envelope;
      redux.dispatch(updateCurrentAlert(reason));
      break;
    }

    case IgnoredRPCs.FORCE_CONFIG:
    case IgnoredRPCs.FORCE_REFRESH:
    case IgnoredRPCs.INBOX_STATE_CHANGED:
    case IgnoredRPCs.CLIENT_RELOAD: {
      logger.info('Received an RPC that has been intentionally ignored', {
        envelope: redactSensitiveInfoFromPubhubData(envelope),
      });
      break;
    }

    default: {
      const err = new Error(
        `RPC handler for '${envelope.message.rpc}' not implemented.`,
      );
      logger.error(err, {
        envelope: redactSensitiveInfoFromPubhubData(envelope),
      });
    }
  }
};

function handleUpdateReadStateRPC({
  envelope,
  logger,
  redux,
}: {
  envelope: RPCEnvelope;
  redux: MiddlewareAPI<Dispatch<AnyAction>, State>;
  logger: ILogger;
}) {
  const { channel: channelId } = envelope;

  const lastReadTimeToken = envelope.timetoken.toString();
  if (isValidCallerReadMessageRpc(envelope)) {
    const { channel, timetoken: lastMemberReadTimetoken } = envelope;
    const { channelIdMemberIdMap } = redux.getState().inbox;
    const memberId = channelIdMemberIdMap[channelId];
    logger.info(`onHandleRPC: Processing member update_read_state RPC`, {
      channelId,
      lastReadTimeToken: pubnubTimeTokenToMoment(
        lastReadTimeToken,
      ).toISOString(),
      memberId,
    });
    redux.dispatch(
      onCallerReadMessageRPC({ channel, lastMemberReadTimetoken }),
    );
    redux.dispatch(
      updateTimetokens([
        {
          channelId,
          timetokens: { lastMemberReadTimeToken: lastReadTimeToken },
        },
      ]),
    );
  } else if (isValidListenerReadMessageRpc(envelope)) {
    // we want to maintain the sort order across browser when the coach opens a
    // conversation with unread message, this way will always have unread messages at the top of the list.

    // We sometimes receive RPC with stale timetokens, so we need to check if the lastReadTimeToken is greater than
    // the lastListenerReadTimeToken in store
    const lastListenerReadTimeToken =
      redux.getState().conversationsTimetokens.timetokensMap[channelId]
        ?.lastListenerReadTimeToken ?? '0';
    if (lastReadTimeToken > lastListenerReadTimeToken) {
      batch(() => {
        redux.dispatch(
          updateTimetokens([
            {
              channelId,
              timetokens: { lastListenerReadTimeToken: lastReadTimeToken },
            },
          ]),
        );
        redux.dispatch(readAllMessages({ channelId }));
        redux.dispatch(
          sortInboxSections({
            sections: [InboxSections.CONVERSATIONS_AND_TASKS],
          }),
        );
      });
    } else {
      logger.warning(
        `onHandleRPC: Ignoring listener update_read_state RPC with stale timetoken`,
        {
          channelId,
          lastListenerReadTimeToken: pubnubTimeTokenToMoment(
            lastListenerReadTimeToken,
          ).toISOString(),
          lastReadTimeToken: pubnubTimeTokenToMoment(
            lastReadTimeToken,
          ).toISOString(),
        },
      );
    }
  } else {
    logger.info(
      `Got the update_read_state RPC from a listener OR a corrupted update_read_state RPC from a caller. ChannelId: ${channelId}, RPC id: ${envelope.id}`,
    );
  }
}
