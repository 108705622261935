import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import gql from 'graphql-tag';
import { encryptedDataFragment } from 'app/vault/queries';
import { ChatMessage, ChatMessageVariables } from './generated/ChatMessage';
import {
  GetChatStream,
  GetChatStreamVariables,
} from './generated/GetChatStream';

const vaultItemFragment = gql`
  fragment vaultItemFragment on VaultItem {
    id
    encryptedData {
      ...encryptedDataFragment
    }
    creator {
      id
      firstName
      lastName
    }
    createdAt
    updatedAt
  }
  ${encryptedDataFragment}
`;

export const getChatStream: TypedDocumentNode<
  GetChatStream,
  GetChatStreamVariables
> = gql`
  query GetChatStream(
    $tag: String!
    $pagination: VaultPaginationInput!
    $groupId: ID
  ) {
    getPaginatedVaultItemsByTag(
      input: { tag: $tag, groupId: $groupId, pagination: $pagination }
    ) {
      items {
        encryptedItem {
          ...vaultItemFragment
        }
      }
      cursor
    }
  }
  ${vaultItemFragment}
`;

export const createChatMessage: TypedDocumentNode<
  ChatMessage,
  ChatMessageVariables
> = gql`
  mutation ChatMessage($input: [CreateVaultItemInput!]!) {
    createVaultItems(input: $input) {
      encryptedItem {
        ...vaultItemFragment
      }
    }
  }
  ${vaultItemFragment}
`;

export const createUserMetadata = gql`
  mutation CreateUserCollaborationMetadata($input: CreateUserMetadataInput!) {
    createUserCollaborationMetadata(input: $input) {
      userMetadata {
        id
      }
    }
  }
`;
export const updateUserMetadata = gql`
  mutation UpdateUserCollaborationMetadata($input: UpdateUserMetadataInput!) {
    updateUserCollaborationMetadata(input: $input) {
      userMetadata {
        id
      }
    }
  }
`;

export const getUserCollaborationMetadata = gql`
  query GetUserCollaborationMetadata(
    $input: GetUserCollaborationMetadataInput!
  ) {
    getUserCollaborationMetadata(input: $input) {
      id
      userId
      memberId
      latestTimestamp
    }
  }
`;
