import { ApolloError, QueryResult, useLazyQuery } from '@apollo/client';
import { CustomError } from 'app/appointments/errorUtils';
import { NotesAndSessionInfo } from 'app/coach/coach-notes/CoachNotesTypes';
import {
  DecodeVaultItemParams,
  decodeVaultItems,
} from 'app/coach/coach-notes/decodeVaultItems';
import {
  GetCoachNotesAndUserMetadata,
  GetCoachNotesAndUserMetadataVariables,
} from 'app/coach/coach-notes/generated/GetCoachNotesAndUserMetadata';
import {
  LegacyDailyCoachingNotes,
  LegacyDailyCoachingNotesVariables,
} from 'app/coach/coach-notes/generated/LegacyDailyCoachingNotes';
import {
  getLegacyNotesVariables,
  getVariables,
} from 'app/coach/coach-notes/notesQueryVariables';
import {
  getCoachNotesAndUserMetadata,
  getLegacyDailyCoachingNotes,
} from 'app/coach/coach-notes/queries';
import { useAppState } from 'app/state';
import { ILogger } from 'app/state/log/Logger';
import { useLogger } from 'app/state/log/useLogger';
import { State } from 'app/state/schema';
import { StateSlice } from 'app/state/status/types/StateSlice';
import { useStateSlice } from 'app/vault/hooks/utils';
import { UserRole } from 'generated/globalTypes';
import { useEffect, useRef } from 'react';

type AuthData = {
  role: UserRole;
  timezone: string;
  vaultUserId: string;
  memberId: string;
};

type ProcessGQLResultsParams = {
  coachNotesResponse: QueryResult<
    GetCoachNotesAndUserMetadata,
    GetCoachNotesAndUserMetadataVariables
  >;
  legacyCoachingNotesResponse: QueryResult<
    LegacyDailyCoachingNotes,
    LegacyDailyCoachingNotesVariables
  >;
  authData: AuthData;
  logger: ILogger;
};

export function useGetCoachNotes(
  memberId: string,
): StateSlice<NotesAndSessionInfo> {
  const logger = useLogger();
  const memberIdRef = useRef<string | null>(null);
  const { state, setLoading, setData, setError } = useStateSlice<
    NotesAndSessionInfo
  >();
  const { role, userId, timezone, vaultUserId } = useAppState(selectState);

  const [coachNotesQuery, coachNotesResponse] = useLazyQuery<
    GetCoachNotesAndUserMetadata,
    GetCoachNotesAndUserMetadataVariables
  >(getCoachNotesAndUserMetadata, { errorPolicy: 'all' });

  const [legacyCoachingNotesQuery, legacyCoachingNotesResponse] = useLazyQuery<
    LegacyDailyCoachingNotes,
    LegacyDailyCoachingNotesVariables
  >(getLegacyDailyCoachingNotes, { errorPolicy: 'all' });

  const authData = { memberId, role, timezone, vaultUserId };

  useEffect(() => {
    void fetchNotes();
    return () => {
      memberIdRef.current = null;
    };
  }, [memberId, role, userId]);

  useEffect(() => {
    void processResults();
  }, [
    coachNotesResponse.data,
    coachNotesResponse.error,
    legacyCoachingNotesResponse.data,
    legacyCoachingNotesResponse.error,
  ]);

  async function fetchNotes() {
    if (!role || !memberId || !userId) return;
    memberIdRef.current = memberId;
    setLoading();
    const variables = await getVariables(memberId, userId, role);
    const legacyVariables = await getLegacyNotesVariables(memberId, role);
    try {
      await Promise.allSettled([
        coachNotesQuery({ variables }),
        legacyCoachingNotesQuery({
          variables: { legacyDailyCoachingNotesInput: legacyVariables },
        }),
      ]);
    } catch (error) {
      logger.error(
        new Error(`useGetCoachNotes: nable to retrieve coaching note`),
        { error, memberId },
      );
      if (memberIdRef.current === memberId) setError(error);
    }
  }

  async function processResults() {
    if (isLoading(coachNotesResponse) || isLoading(legacyCoachingNotesResponse))
      return;
    const { error, data } = await processGQLResults({
      authData,
      coachNotesResponse,
      legacyCoachingNotesResponse,
      logger,
    });
    if (memberIdRef.current !== memberId) return;
    if (error) setError(error);
    if (data) setData(data);
  }

  return state.current;
}

async function processGQLResults(
  params: ProcessGQLResultsParams,
): Promise<
  { error: Error; data: null } | { data: NotesAndSessionInfo; error: null }
> {
  const {
    coachNotesResponse,
    legacyCoachingNotesResponse,
    logger,
    authData,
  } = params;
  const { memberId } = authData;
  const noCoachNotesData = !hasData(coachNotesResponse?.data);
  const coachNotesErrors = coachNotesResponse?.error;
  const noLegacyNotesData = !hasData(legacyCoachingNotesResponse?.data);
  const legacyNotesErrors = legacyCoachingNotesResponse?.error;

  const decodeParams: DecodeVaultItemParams = {
    authData,
    legacyDailyCoachingNotesData: dataOrNull(
      legacyCoachingNotesResponse?.data?.LegacyDailyCoachingNotes,
    ),
    legacySummaryNotesData: dataOrNull(
      coachNotesResponse?.data?.LegacyCoachSummaryNotes,
    ),
    logger,
    metadata: dataOrNull(coachNotesResponse?.data?.NotesUserMetadata),
    notesData: dataOrNull(coachNotesResponse?.data?.CoachNotes),
  };

  if (coachNotesErrors || legacyNotesErrors) {
    logger.error(
      new Error(
        `useCoachNotes: Unable to retrieve new coach note templates or legacy daily coaching notes`,
      ),
      {
        coachNotesError: coachNotesErrors,
        hasPartialData: !noLegacyNotesData || !noCoachNotesData,
        legacyNotesError: legacyNotesErrors,
        memberId,
      },
    );
  }
  if (noLegacyNotesData && noCoachNotesData) {
    // render the error state, since there are no data to present to the user
    const error = new Error('Unable to retrieve coaching notes');
    return { data: null, error };
  }
  const result = await decodeVaultItems(decodeParams);
  const dataResult = {
    ...result,
    error: getError(legacyNotesErrors, coachNotesErrors),
  };
  return { data: dataResult, error: null };
}

function selectState({ user }: State) {
  return {
    listenerId: user.listenerId!,
    role: user.role!,
    timezone: user.timezone ?? 'UTC',
    userId: user.userId!,
    vaultUserId: user.vaultUserId!,
  };
}

function hasData(
  data: GetCoachNotesAndUserMetadata | LegacyDailyCoachingNotes | undefined,
) {
  return data && Object.values(data).some((value) => !!value);
}

function dataOrNull<T>(data?: T): T | null {
  return data ?? null;
}

function getError(
  legacyNotesErrors: MaybeUndefined<ApolloError>,
  coachNotesErrors: MaybeUndefined<ApolloError>,
): CustomError | null {
  if (!coachNotesErrors && !legacyNotesErrors) return null;

  const message = legacyNotesErrors
    ? 'An error occurred while retrieving legacy notes'
    : 'An error occurred while retrieving the new note template data';
  return new CustomError(message, 'GET_COACH_NOT_ERROR', {
    coachNotesErrors,
    legacyNotesErrors,
  });
}

function isLoading(result: QueryResult<any, any>) {
  return !result.called || result.loading;
}
