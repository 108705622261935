import { Assessment } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/intake/Assessment';
import { Allergies } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/Allergies';
import { ChiefComplaint } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/ChiefComplaint';
import { FamilyHistory } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/FamilyHistory';
import { MedicalHistory } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MedicalHistory';
import { MedicalReviewOfSystem } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MedicalROS';
import { MentalStatusExam } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MentalStatusExam';
import { PsychiatricHistory } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/PsychiatricHistory';
import { PsychReviewOfSystem } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/PsychROS';
import { SocialHistory } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/SocialHistory';
import { TreatmentPlan } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/TreatmentPlan';
import { ClinicianIntakeChecklist } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/ClinicianIntakeChecklist';
import { CollaborationPlan } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/CollaborationPlan';
import { SafetyIntake } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyIntake';
import { SubstanceUse } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import { Metadata } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { PsychiatrySectionName } from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { VaultItem_SchemaType } from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import { VaultItemWithId } from '@ginger.io/vault-ui/dist/api/VaultAPI';
import { AmendmentWithAuditLog } from 'app/notes-ui/shared/amendments/types';
import { PsychiatryIntakeNote } from 'app/vault/api/PsychiatryIntakeNotesAPI';
import { newClinicalNoteMetadata } from 'app/vault/data/utils';

export function decodePsychiatryIntakeNote(
  appointmentId: string,
  items: VaultItemWithId[],
  amendments: AmendmentWithAuditLog[],
): PsychiatryIntakeNote {
  const note: PsychiatryIntakeNote = {
    [PsychiatrySectionName.CLINICIAN_CHECKLIST]: null,
    [PsychiatrySectionName.CHIEF_COMPLAINT]: null,
    [PsychiatrySectionName.PSYCH_ROS]: null,
    [PsychiatrySectionName.MEDICAL_ROS]: null,
    [PsychiatrySectionName.SAFETY]: null,
    [PsychiatrySectionName.PSYCH_HISTORY]: null,
    [PsychiatrySectionName.ALLERGIES]: null,
    [PsychiatrySectionName.MEDICAL_HISTORY]: null,
    [PsychiatrySectionName.SOCIAL_HISTORY]: null,
    [PsychiatrySectionName.FAMILY_HISTORY]: null,
    [PsychiatrySectionName.SUBSTANCE_ABUSE]: null,
    [PsychiatrySectionName.MENTAL_STATUS_EXAM]: null,
    [PsychiatrySectionName.ASSESSMENT]: null,
    [PsychiatrySectionName.TREATMENT_PLAN]: null,
    [PsychiatrySectionName.COLLABORATION_PLAN]: null,
    [PsychiatrySectionName.AMENDMENTS]: null,
    amendments,
    createdAt: null,
    metadata: newClinicalNoteMetadata(appointmentId),
    permissions: null,
    updatedAt: null,
  };

  const schemas = VaultItem_SchemaType;
  items.forEach(({ item: vaultItem, permissions, updatedAt, createdAt }) => {
    switch (vaultItem.schemaType) {
      case schemas.vault_clinical_notes_psychiatry_intake_clinician_checklist: {
        note[
          PsychiatrySectionName.CLINICIAN_CHECKLIST
        ] = ClinicianIntakeChecklist.decode(vaultItem.data);
        break;
      }

      case schemas.vault_clinical_notes_psychiatry_intake_chief_complaint: {
        note[PsychiatrySectionName.CHIEF_COMPLAINT] = ChiefComplaint.decode(
          vaultItem.data,
        );
        break;
      }

      case schemas.vault_clinical_notes_psychiatry_intake_psych_review_of_system: {
        note[PsychiatrySectionName.PSYCH_ROS] = PsychReviewOfSystem.decode(
          vaultItem.data,
        );
        break;
      }

      case schemas.vault_clinical_notes_psychiatry_intake_medical_review_of_system: {
        note[PsychiatrySectionName.MEDICAL_ROS] = MedicalReviewOfSystem.decode(
          vaultItem.data,
        );
        break;
      }

      case schemas.vault_clinical_notes_psychiatry_intake_safety: {
        note[PsychiatrySectionName.SAFETY] = SafetyIntake.decode(
          vaultItem.data,
        );
        break;
      }

      case schemas.vault_clinical_notes_psychiatry_intake_psychiatric_history: {
        note[PsychiatrySectionName.PSYCH_HISTORY] = PsychiatricHistory.decode(
          vaultItem.data,
        );
        break;
      }

      case schemas.vault_clinical_notes_psychiatry_intake_allergies: {
        note[PsychiatrySectionName.ALLERGIES] = Allergies.decode(
          vaultItem.data,
        );
        break;
      }

      case schemas.vault_clinical_notes_psychiatry_intake_medical_history: {
        note[PsychiatrySectionName.MEDICAL_HISTORY] = MedicalHistory.decode(
          vaultItem.data,
        );
        break;
      }

      case schemas.vault_clinical_notes_psychiatry_intake_social_history: {
        note[PsychiatrySectionName.SOCIAL_HISTORY] = SocialHistory.decode(
          vaultItem.data,
        );
        break;
      }

      case schemas.vault_clinical_notes_psychiatry_intake_family_history: {
        note[PsychiatrySectionName.FAMILY_HISTORY] = FamilyHistory.decode(
          vaultItem.data,
        );
        break;
      }

      case schemas.vault_clinical_notes_psychiatry_intake_substance_use: {
        note[PsychiatrySectionName.SUBSTANCE_ABUSE] = SubstanceUse.decode(
          vaultItem.data,
        );
        break;
      }

      case schemas.vault_clinical_notes_psychiatry_intake_mental_status_exam: {
        note[
          PsychiatrySectionName.MENTAL_STATUS_EXAM
        ] = MentalStatusExam.decode(vaultItem.data);
        break;
      }

      case schemas.vault_clinical_notes_psychiatry_intake_assessment: {
        note[PsychiatrySectionName.ASSESSMENT] = Assessment.decode(
          vaultItem.data,
        );
        break;
      }

      case schemas.vault_clinical_notes_psychiatry_intake_treatment_plan: {
        note[PsychiatrySectionName.TREATMENT_PLAN] = TreatmentPlan.decode(
          vaultItem.data,
        );
        break;
      }

      case schemas.vault_clinical_notes_psychiatry_intake_collaboration_plan: {
        note[
          PsychiatrySectionName.COLLABORATION_PLAN
        ] = CollaborationPlan.decode(vaultItem.data);
        break;
      }

      case schemas.vault_clinical_notes_psychiatry_intake_metadata: {
        note.metadata = Metadata.decode(vaultItem.data);
        note.permissions = permissions;
        note.createdAt = createdAt;
        note.updatedAt = updatedAt ?? createdAt;
        break;
      }

      default: {
      } // no-op
    }
  });

  return note;
}
