import { CoachNotesItem } from 'app/coach/coach-notes/CoachNotesTypes';

export type State = {
  selectedNote?: CoachNotesItem;
};

export const getInitialCoachNotesState = (): State => {
  return {
    selectedNote: undefined,
  };
};
