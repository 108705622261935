import {
  OperationVariables,
  QueryHookOptions,
  useQuery as useApolloQuery,
} from '@apollo/client';
import { ApolloQueryResult } from '@apollo/client/core';
import { DocumentNode } from 'graphql';
import React, { ElementType, ReactElement, useEffect } from 'react';
import { ErrorState } from 'shared-components/error-state/ErrorState';
import { isGraphQLAuthenticationError } from 'shared-components/error-state/utils';
import { Loader } from 'shared-components/loader/Loader';

/** Custom hook that render the results of a graphQL query  */
export function useQuery<T, U = OperationVariables>(
  render: (
    data: T,
    refetch: (
      variables?: Partial<U> | undefined,
    ) => Promise<ApolloQueryResult<T>>,
  ) => ReactElement,
  query: DocumentNode,
  options?: QueryHookOptions<T, U>,
  ErrorStateFallback: ElementType = ErrorState,
): ReactElement {
  const { loading, error, data, refetch, stopPolling } = useApolloQuery(
    query,
    options,
  );

  useEffect(() => {
    if (
      options?.pollInterval !== undefined &&
      isGraphQLAuthenticationError(error)
    )
      stopPolling();
  }, [error]);

  if (loading) {
    return <Loader />;
  }
  if (error !== undefined) {
    return <ErrorStateFallback error={error} />;
  }
  if (data !== undefined) {
    return render(data, refetch);
  }
  return <div />;
}

export function useQuerySkippable<T, U = OperationVariables>(
  render: (data: T | undefined) => ReactElement,
  query: DocumentNode,
  options?: QueryHookOptions<T, U>,
  ErrorStateFallback: ElementType = ErrorState,
): ReactElement {
  const { loading, error, data, stopPolling } = useApolloQuery(query, options);

  useEffect(() => {
    if (
      options?.pollInterval !== undefined &&
      isGraphQLAuthenticationError(error)
    )
      stopPolling();
  }, [error]);

  if (loading) {
    return <Loader />;
  }
  if (error !== undefined) {
    return <ErrorStateFallback error={error} />;
  }
  if (data !== undefined || options?.skip) {
    return render(data);
  }
  return <div />;
}
