import {
  CloseChatConversationWithMember,
  CloseChatConversationWithMemberVariables,
} from 'app/coach/chat/generated/CloseChatConversationWithMember';
import { useChatMessagesStateHandler } from 'app/coach/chat/UseChatMessagesStateHandler';
import { usePollMessageHistory } from 'app/coach/chat/UsePollMessageHistory';
import { getPubErrorLogData } from 'app/coach/pubnub/errors';
import { usePubNubAPI } from 'app/coach/pubnub/PubNubContextProvider';
import { useAppState } from 'app/state';
import { selectIsReadOnly } from 'app/state/chat/selectors';
import {
  selectLastListenerRead,
  selectLastMemberRead,
} from 'app/state/features/conversationTimetokens/selectors';
import { useAppDispatch } from 'app/state/hooks/baseTypedHooks';
import { markConversationAsDone, setMessages } from 'app/state/inbox/actions';
import {
  selectConversationState,
  selectCurrentPubnubChannelId,
  selectMessages,
} from 'app/state/inbox/selectors';
import {
  displayableMessagesToString,
  pubnubTimeTokenToMoment,
} from 'app/state/inbox/utils';
import { useLogger } from 'app/state/log/useLogger';
import arrowDown from 'assets/chat/ArrowDown.svg';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useMutationWithGlobalState } from 'hooks/useMutationWithGlobalState';
import { useOnMount } from 'hooks/useOnMount';
import React, { useRef } from 'react';
import { PillButton } from 'shared-components/button/PillButton';
import ErrorBoundary from 'shared-components/error-state/ErrorBoundary';
import { classNameCombiner } from 'utils';

import { ChatContainerProps } from './ChatContainer';
import styles from './ChatContainer.module.scss';
import { closeChatConversationWithMember } from './ChatQueries';
import { ChatErrorState } from './error/ChatErrorState';
import { ChatHeader } from './header/ChatHeader';
import { MessagesSection } from './messages-section/MessagesSection';
import { ReadOnlyChat } from './text-area-section/ReadOnlyChat';
import { TextAreaGroup } from './text-area-section/TextAreaGroup';
import {
  getMinNumberOfMessages,
  MIN_NUMBER_OF_MESSAGES,
  scrollToTheBottomOfScrollableContainer,
  updateMessagesStatuses,
} from './utils';

/**
 * This file duplicates src/app/coach/chat/Chat.tsx, with the key changes being the replacement of the useEffect hooks
 * and the sendUpdateReadStateRPCAndUpdateTimeToken function with useChatMessagesStateHandler. The file was created
 * to avoid conditionally rendering hooks, as the changes are behind a feature flag.
 *
 * Once the feature flag 'enable_fix_unread_messages_read_logic' is enabled for all users, this file will be renamed
 * and will replace src/app/coach/chat/Chat.tsx.
 */
export const Chat = ({
  memberProfile,
  isChatExpanded,
  toggleChatExpansion,
  coachingCareTeam,
}: ChatContainerProps) => {
  const {
    baseProps,
    preferences: { timezone },
    memberId,
    callerId,
  } = memberProfile;
  const listenerId = useAppState(({ user }) => user.listenerId);

  const api = usePubNubAPI();
  const { getHistory } = api;

  const { firstName } = baseProps;
  const currentChannelId = selectCurrentPubnubChannelId();
  const messages = selectMessages(currentChannelId);
  /*  an example of selecting the state from the slice */
  const lastListenerReadTimetoken = useAppState(
    (state) => selectLastListenerRead(state, currentChannelId) ?? '0',
  );
  const lastMemberReadTimetoken = useAppState((state) =>
    selectLastMemberRead(state, currentChannelId),
  );
  const { enable_read_only_chat } = useFeatureFlags().transientFeatureFlags;
  const isReadOnly =
    selectIsReadOnly(currentChannelId) && enable_read_only_chat;
  const conversationState = selectConversationState(currentChannelId);

  const dispatch = useAppDispatch();
  const logger = useLogger();
  const [closeChatConversationWithMemberFn] = useMutationWithGlobalState<
    CloseChatConversationWithMember,
    CloseChatConversationWithMemberVariables
  >(closeChatConversationWithMember);

  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const scrollToTheBottom = () => {
    scrollToTheBottomOfScrollableContainer(messagesContainerRef);
  };

  const closeChatConversation = async () => {
    const result = await closeChatConversationWithMemberFn({
      input: {
        gingerId: memberId,
        lastKnownTimetoken: [...messages].pop()?.timetoken ?? null,
      },
    });
    const success =
      result.data?.closeChatConversationWithMember?.success ?? false;
    if (success) {
      dispatch(markConversationAsDone({ memberId }));
    }
    return success;
  };

  const { pillText, onNewMessageButtonClick } = useChatMessagesStateHandler({
    callerId,
    containerRef: messagesContainerRef,
    memberId,
  });

  // check the number of messages on mount and load necessary number of messages (MIN_NUMBER_OF_MESSAGES)
  useOnMount(() => {
    const abortController = new AbortController();
    const initChat = async () => {
      if (!currentChannelId) return;
      let lastMessageTimetoken =
        messages.length > 0 ? messages[messages.length - 1].timetoken : '0';
      if (messages.length < MIN_NUMBER_OF_MESSAGES) {
        try {
          const updatedMessages = await getMinNumberOfMessages({
            channelId: currentChannelId,
            getHistory,
            initialMessages: messages,
            logger,
          });
          const messagesWithStatuses = updateMessagesStatuses(
            lastMemberReadTimetoken,
            updatedMessages,
          );
          logger.info('Chat: loading the messages history', {
            channelId: currentChannelId,
            messageFromStore: displayableMessagesToString(messages),
            messagesFromHistory: displayableMessagesToString(updatedMessages),
          });
          dispatch(
            setMessages({
              channelId: currentChannelId,
              messages: messagesWithStatuses,
            }),
          );

          if (updatedMessages.length > 0) {
            lastMessageTimetoken =
              updatedMessages[updatedMessages.length - 1].timetoken ?? '0';
          }
        } catch (e) {
          logger.error(
            new Error(
              `Got an error when tried to load the messages history from the Chat component`,
              { cause: e },
            ),
            {
              callerId,
              channelId: currentChannelId,
              error: e,
              listenerId,
              pubnubError: getPubErrorLogData(e),
            },
          );
        }
      }

      logger.info("Chat: updating the conversation's timetokens", {
        lastListenerReadTimetoken,
        lastListenerReadTimetokenStr: pubnubTimeTokenToMoment(
          lastListenerReadTimetoken,
        ).toISOString(),
        lastMessageTimetoken,
        lastMessageTimetokenStr: pubnubTimeTokenToMoment(
          lastMessageTimetoken,
        ).toISOString(),
      });
    };
    initChat();

    /* even though the useEffect fires only onMount if the tabs are switched too quickly, we may have some not completed async operations.
    We need to abort the async operations in process to prevent this warting from React:
    Cann't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
     */
    return () => abortController.abort();
  });

  usePollMessageHistory({ channelId: currentChannelId, memberId });

  if (!currentChannelId) return null;

  return (
    <div data-testid="chat-container" className={styles.wrapper}>
      <div
        className={classNameCombiner([styles.container, styles.withMultiTab])}
      >
        <ChatHeader
          memberId={memberId}
          firstName={firstName}
          isExpanded={isChatExpanded}
          timezone={timezone}
          toggleExpansion={toggleChatExpansion}
          conversationState={conversationState?.state ?? undefined}
          onCloseConversation={closeChatConversation}
        />
        {pillText ? (
          <PillButton
            testId="newMessageButton"
            onClick={onNewMessageButtonClick}
            text={pillText}
            className={styles.newMessageButton}
            imgSrc={arrowDown}
          />
        ) : (
          <></>
        )}
        {/* we place the ErrorBoundary here since we need to display the header as usual if something goes
            wrong w/ the chat and the main chat logic is in the wrapped components */}
        <ErrorBoundary FallbackComponent={ChatErrorState}>
          <div ref={messagesContainerRef} className={styles.content}>
            <MessagesSection
              scrollToTheBottom={scrollToTheBottom}
              memberProfileBaseProps={baseProps}
            />
          </div>
          {isReadOnly ? (
            <ReadOnlyChat coachingCareTeam={coachingCareTeam} />
          ) : (
            <TextAreaGroup
              callerId={callerId}
              memberId={memberId}
              memberName={baseProps.firstName}
            />
          )}
        </ErrorBoundary>
      </div>
    </div>
  );
};
