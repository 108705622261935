import { AnyAction } from '@reduxjs/toolkit';
import { createReducer } from 'redux-reloaded';

import { error, resetFailedRequest, saving, success } from './actions';
import { getInitialState, RequestState, State } from './schema';

export const requestStateReducer = createReducer<State>(getInitialState());

const actionReducer = (state: State, { payload }: AnyAction) => {
  return {
    ...state,
    [payload.queryName]: payload as RequestState,
  };
};
requestStateReducer.on(error, actionReducer);
requestStateReducer.on(saving, actionReducer);
requestStateReducer.on(success, actionReducer);
requestStateReducer.on(resetFailedRequest, (state, { payload }) =>
  payload.reduce(
    (prev, queryName) => ({
      ...prev,
      [queryName]: undefined,
    }),
    state,
  ),
);
