import Titles from 'app/member-chart-cards/constants/cards-titles';
import styles from 'app/patients/tabs/intake/IntakeTab.module.scss';
import React, { useMemo } from 'react';
import { Card } from 'shared-components/Card';
import {
  CardColumnGaps,
  CardGrid,
  CardRowGaps,
  NumberOfCardColumns,
} from 'shared-components/grid-layout/CardGrid.module';
import { RowType, Table } from 'shared-components/table/Table';
import { HeadCell } from 'shared-components/table/TableHead';

type Relationship = {
  score: number | null;
  additionalDetails: string | null;
  hasRelationship?: string | null;
};

type Props = {
  family: Relationship;
  kids: Relationship;
  spouse: Relationship;
};

type RowData = {
  type: string;
  rating: string;
  comments: string;
  hasRelationShip: string;
};

const columns: HeadCell<RowData>[] = [
  { key: 'type', label: 'Type' },
  { key: 'hasRelationShip', label: 'Yes/No' },
  { key: 'rating', label: 'Rating' },
  { key: 'comments', label: 'Comments' },
];

export function Relationships(props: Readonly<Props>) {
  const rows: RowType<RowData> = useMemo(() => toRowData(props), [props]);
  return (
    <Card data-testid="relationshipsCard" boxTitle={Titles.RELATIONSHIPS_TITLE}>
      <div className={styles.cardSection}>
        <CardGrid
          rowGap={CardRowGaps.STANDARD}
          columnGap={CardColumnGaps.STANDARD}
          numberOfColumns={NumberOfCardColumns.ONE}
        >
          <Table
            classes={{ cell: 'vertical-align-top' }}
            showCheckboxes={false}
            data-testid="relationshipRatingTable"
            columns={columns}
            rows={rows}
          />
        </CardGrid>
      </div>
    </Card>
  );
}

function toRowData(props: Props) {
  const { family, kids, spouse } = props;
  const row = (type: string, entry: Relationship): RowData => {
    const { score, additionalDetails, hasRelationship } = entry;
    return {
      comments: getOrNA(additionalDetails),
      hasRelationShip: getOrNA(hasRelationship),
      rating: score ? `${score} out of 10` : '-',
      type,
    };
  };
  return {
    Children: row('Children', kids),
    Family: row('Family', family),
    Spouse: row('Spouse', spouse),
  };
}

function getOrNA(str?: string | null) {
  if (str === undefined || str === null) return '-';
  return str;
}
