import styles from 'app/patients/tabs/surveys/SurveysV3/InfoBox.module.scss';
import { classNameCombiner, formatEnumValue } from 'utils';
import React from 'react';

import { titleImageMapping } from './constants';
import { InfoBoxContent } from './types';

interface Props {
  info: InfoBoxContent | null;
  useStrengthsLabel?: boolean;
}
export default function InfoBox({ info, useStrengthsLabel = false }: Props) {
  if (!info) {
    return (
      <div className={classNameCombiner([styles.infobox, styles.default])}>
        <div className={styles.defaultText}>
          Click on a specific point above to view a summary
        </div>
      </div>
    );
  }
  const { insights, summaries, scoreType } = info;
  return (
    <div
      className={classNameCombiner([
        styles.infobox,
        scoreType === 'negative' ? styles.danger : '',
      ])}
    >
      <div className={classNameCombiner([styles.tr, styles.header])}>
        <div className={classNameCombiner([styles.td, styles.title])}>
          Summary
        </div>
        <div className={classNameCombiner([styles.td_grow2, styles.title])}>
          {useStrengthsLabel ? 'Strengths' : 'Insights'}: What your member sees
        </div>
      </div>
      <div className={`${styles.tr}`}>
        <div
          className={classNameCombiner([
            styles.td,
            styles.section,
            styles.summary,
          ])}
        >
          {summaries.map(({ title, subText, icon, colorText = 'normal' }) => {
            return (
              <div
                key={title}
                className={classNameCombiner([styles.tr, styles[colorText]])}
              >
                <div className={`${styles.td_shrink}`}>
                  <img className={`${styles.icon}`} src={icon} alt="" />
                </div>
                <div className={`${styles.td}`}>
                  <div
                    className={classNameCombiner([
                      styles.title,
                      styles['text-color'],
                    ])}
                  >
                    {title}
                  </div>
                  <div
                    className={classNameCombiner([
                      styles.sub_title,
                      styles['text-color'],
                    ])}
                  >
                    {subText}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={`${styles.td_grow2}`}>
          <div className={`${styles.tr}`}>
            {insights.map(({ frequency, title, details }) => (
              <div
                key={title}
                className={classNameCombiner([styles.td, styles.section])}
              >
                <div className={`${styles.tr}`}>
                  <div className={`${styles.td_shrink}`}>
                    <img
                      className={`${styles.image}`}
                      src={titleImageMapping[title]}
                      alt=""
                    />
                  </div>
                  <div className={`${styles.td}`}>
                    <div className={`${styles.title}`}>{title}</div>
                    <div className={`${styles.sub_title}`}>
                      {formatEnumValue(frequency)}
                    </div>
                  </div>
                </div>
                <div className={`${styles.content}`}>{details}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
