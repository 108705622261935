import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Card } from 'shared-components/Card';
import {
  CardColumnGaps,
  CardGrid,
  CardRowGaps,
  NumberOfCardColumns,
} from 'shared-components/grid-layout/CardGrid.module';
import Titles from 'app/member-chart-cards/constants/cards-titles';
import React from 'react';

import { HeaderWithDot } from './HeaderWithDot';
import styles from './IntakeTab.module.scss';
import { Label } from './Label';

const Indicator = (props: { question: string; answer: boolean }) => {
  const icon = props.answer ? <Label active>Yes</Label> : <Label>No</Label>;
  return (
    <tr>
      <td>{props.question}</td>
      <td>{icon}</td>
    </tr>
  );
};

const Indicators = (props: {
  name: string;
  indicators: SelfReportedSymptom[];
}) => {
  const { name, indicators } = props;

  const isIndicatorActive = indicators.some((_) => Boolean(_.answer));

  const indicatorComponents = indicators.map((indicator) => {
    const { question, answer } = indicator;
    return <Indicator key={question} question={question} answer={answer} />;
  });

  return (
    <>
      <Accordion elevation={0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <HeaderWithDot name={name} active={isIndicatorActive} />
        </AccordionSummary>
        <AccordionDetails>
          <table>
            <tbody>{indicatorComponents}</tbody>
          </table>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

type SelfReportedSymptom = {
  question: string;
  answer: boolean;
};

type Props = {
  mania: SelfReportedSymptom[];
  psychosis: SelfReportedSymptom[];
  panicDisorder: SelfReportedSymptom[];
  socialOrPerformanceAnxiety: SelfReportedSymptom[];
  ocd: SelfReportedSymptom[];
  ptsd: SelfReportedSymptom[];
  somaticSymptomDisorder: SelfReportedSymptom[];
};

export function SelfReportedSymptoms(props: Readonly<Props>) {
  return (
    <Card
      data-testid="selfReportedSymptom"
      boxTitle={Titles.SELD_REPORTED_TITLE}
    >
      <div className={styles.cardSection}>
        <CardGrid
          rowGap={CardRowGaps.STANDARD}
          columnGap={CardColumnGaps.STANDARD}
          numberOfColumns={NumberOfCardColumns.ONE}
        >
          <Indicators name="Mania" indicators={props.mania} />
          <Indicators name="Psychosis" indicators={props.psychosis} />
          <Indicators name="Panic Disorder" indicators={props.panicDisorder} />
          <Indicators
            name="Social Or Performance Anxiety"
            indicators={props.socialOrPerformanceAnxiety}
          />
          <Indicators name="OCD" indicators={props.ocd} />
          <Indicators name="PTSD" indicators={props.ptsd} />
          <Indicators
            name="Somatic Symptom Disorder"
            indicators={props.somaticSymptomDisorder}
          />
        </CardGrid>
      </div>
    </Card>
  );
}
