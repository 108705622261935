import { EventType } from 'app/EventType';
import { createAction } from 'redux-reloaded';

export const newEventViewedAction = createAction(
  EventType.SCHEDULE_NEW_EVENT_VIEWED,
);
export const eventViewedAction = createAction(
  EventType.SCHEDULE_EVENT_VIEWED,
  (payload: { eventId: string }) => ({
    ...payload,
  }),
);
export const clickScheduleNewEventCreateAction = createAction(
  EventType.SCHEDULE_NEW_EVENT_CREATE_CLICKED,
  (payload: { eventIds: string[] }) => ({ ...payload }),
);
export const clickScheduleEventUpdateAction = createAction(
  EventType.SCHEDULE_EVENT_UPDATE_CLICKED,
  (payload: { eventId: string }) => ({ ...payload }),
);
