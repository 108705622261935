import { gql } from '@apollo/client/core';

export const GetTimetokensForConversations = gql`
  query getInboxConversationTimetokens($memberIds: [ID!]!) {
    getInboxConversationByIds(input: { ids: $memberIds }) {
      conversationStats {
        lastListenerReadTimeToken
        lastMemberReadTimeToken
        lastMemberWriteTimeToken
        latestWriteTimestamp
        memberCoachChannelId
      }
    }
  }
`;

export const updateConversationTimetokenMutation = gql`
  mutation updateChatTimetoken($input: UpdateChatConversationTimetokenInput!) {
    updateChatConversationTimetoken(input: $input) {
      success
      error
      conversationStats {
        id
        latestWriteTimestamp
        lastListenerReadTimeToken
        memberCoachChannelId
        lastMemberWriteTimeToken
        lastMemberReadTimeToken
      }
    }
  }
`;
