import {
  SubstanceUse_CurrentSubstanceUse,
  SubstanceUse_SubstanceType,
  SubstanceUse_SubstanceUseLineItem as SubstanceUseLineItem,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import React, { useMemo } from 'react';

import { isEmpty } from '../ValidationRules';
import { substances } from './constants';
import { OnChangeHandler } from './SubstanceField';
import { SubstanceFieldV2 } from './SubstanceFieldV2';
import { getSubstanceUseDescription } from './utils';

type Props = {
  includeStoppedUse: boolean;
  initialValue?: SubstanceUse_CurrentSubstanceUse[];
  onChange: OnChangeHandler;
  onSubmit: () => void;
  disabled?: boolean;
  notEndorsed: boolean;
};

export function SubstanceUseContainerV2(props: Props) {
  const {
    initialValue = [],
    onChange,
    onSubmit,
    disabled,
    notEndorsed,
  } = props;

  const initialValues = useMemo(() => {
    const rowsBySubstance = {} as {
      [substance in SubstanceUse_SubstanceType]: SubstanceUseLineItem;
    };
    initialValue.forEach(({ substance }) => {
      if (substance !== undefined) {
        rowsBySubstance[substance.substanceType] = substance;
      }
    });
    return rowsBySubstance;
  }, [initialValue]);

  const substanceItems = substances.map((s, i) => {
    let initialValue = initialValues[s];

    // For notes created on the previous version, generate description from deprecated properties
    if (initialValue && isEmpty(initialValue.description)) {
      initialValue = {
        ...initialValues[s],
        description: getSubstanceUseDescription(initialValues[s]),
      };
    }

    return (
      <SubstanceFieldV2
        key={`substance-${s}`}
        substance={s}
        initialValue={initialValue}
        onChange={onChange}
        onSubmit={onSubmit}
        disabled={disabled}
        notEndorsed={notEndorsed}
      />
    );
  });

  return <>{substanceItems}</>;
}
