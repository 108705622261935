import styles from 'app/inbox/components/styles/InboxSectionItem.module.scss';
import { ReactComponent as NotificationIndicator } from 'assets/icons/notification-indicator.svg';
import CalendarIcon from 'assets/inbox/segments/calendar-blank.svg';
import React from 'react';
import { SessionIcon } from 'shared-components/SessionIcon/SessionIcon';
import { classNameCombiner } from 'utils';

export interface SegmentSubContentProps {
  showCalendarIcon?: boolean;
  text?: string;
  textColor?: 'critical' | 'primary' | 'default';
  showBlueDot?: boolean;
  sessionFormat?: string | null;
}

export const SegmentSubContent = (props: SegmentSubContentProps) => {
  const {
    sessionFormat,
    showCalendarIcon,
    textColor = 'default',
    text,
    showBlueDot,
  } = props;

  return (
    <div className={classNameCombiner([styles.tr, styles.summary])}>
      {showCalendarIcon && (
        <SessionIcon
          sessionFormat={sessionFormat}
          calendarIcon={CalendarIcon}
          className={styles.icon}
        />
      )}
      <div
        className={classNameCombiner([
          styles.td,
          styles.text,
          styles[textColor],
        ])}
      >
        {text}
      </div>
      {showBlueDot && (
        <span className={classNameCombiner([styles.td])}>
          <NotificationIndicator data-testid="blueDot" />
        </span>
      )}
    </div>
  );
};
