import { TableCellProps, TableRow } from '@mui/material';
import MUITableHead from '@mui/material/TableHead';
import React, { ReactElement } from 'react';

import styles from './TableHead.module.scss';

export type Props = {
  children: ReactElement<TableCellProps>[];
};

/** Wraps Material UI's TableHead with a type-safe API where the caller has to pass an array of TableRow as children */
export function V2TableHead(props: Props) {
  return (
    <MUITableHead className={styles.rootV2}>
      <TableRow>{props.children}</TableRow>
    </MUITableHead>
  );
}
