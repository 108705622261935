import { SvgIcon } from '@mui/material';
import { ReactComponent as CheckedRadioButtonSVG } from 'assets/checked-radio-button-icon.svg';
import React from 'react';

export const CheckedRadioButtonIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <CheckedRadioButtonSVG />
    </SvgIcon>
  );
};
