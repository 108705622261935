import { UseForm, useForm } from '@ginger.io/react-use-form';
import {
  CollaborationPlan,
  CollaborationPlan_Goal,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/CollaborationPlan';
import {
  CollaborationPlanSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import goalLabelsGoingWell from '@ginger.io/vault-clinical-notes/src/i18n/vault-clinical-notes/collaborationPlan/goalLabelsGoingWell/en_US.json';
import goalLabelsWorkingOn from '@ginger.io/vault-clinical-notes/src/i18n/vault-clinical-notes/collaborationPlan/goalLabelsWorkingOn/en_US.json';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import Labels from 'app/notes-ui/strings/en.json';
import { AUTO_SAVE_DRAFT_DELAY } from 'app/notes-ui/utils';
import React, { useState } from 'react';

import { CollaborationGoal } from './CollaborationGoal';
import { collaborationGoalFieldDefs } from './schema';

type Props = {
  appointmentId: string;
  onSubmit: (data: CollaborationPlanSection) => void;
  updateDraftNoteState: (data: CollaborationPlanSection) => void;
  initialValue?: CollaborationPlan;
  disabled?: boolean;
  savingNote?: boolean;
};

export function CollaborationPlanForm(props: Props) {
  const { appointmentId, disabled, savingNote } = props;
  const [showNoGoalError, setShowNoGoalError] = useState(false);
  const [initialGoal1Value, initialGoal2Value, initialGoal3Value] = (
    props.initialValue || { goal: [] }
  ).goal;
  const [goals, setGoals] = useState<CollaborationPlan_Goal[]>([
    CollaborationPlan_Goal.fromPartial({ ...initialGoal1Value }),
    CollaborationPlan_Goal.fromPartial({ ...initialGoal2Value }),
    CollaborationPlan_Goal.fromPartial({ ...initialGoal3Value }),
  ]);

  const onStateChange = (index: number) => (goal: CollaborationPlan_Goal) => {
    const updatedGoals = [...goals];
    updatedGoals[index] = goal;
    setGoals(updatedGoals);
    props.updateDraftNoteState({
      data: { appointmentId, goal: updatedGoals },
      name: TherapyIntakeSectionName.COLLABORATION_PLAN,
    });
  };

  const goal1 = useGoalFormState(
    initialGoal1Value,
    Labels.WHAT_MEMBER_IS_WORKING_ON,
    Labels.STEPS_AGREED_TO,
    onStateChange(0),
  );
  const goal2 = useGoalFormState(
    initialGoal2Value,
    Labels.WHAT_MEMBER_IS_WORKING_ON,
    Labels.STEPS_AGREED_TO,
    onStateChange(1),
  );
  const goal3 = useGoalFormState(
    initialGoal3Value,
    Labels.WHATS_GOING_WELL_FOR_MEMBER,
    Labels.MESSAGE_TO_CARE_TEAM,
    onStateChange(2),
  );

  const onSubmit = async () => {
    const goals = [goal1, goal2, goal3];

    const touchedGoals = goals.filter((_) => _.isTouched);

    if (
      (touchedGoals.length > 0 && (await validateGoals(touchedGoals))) ||
      (touchedGoals.length === 0 && (await hasAtLeastOneValidGoal(goals)))
    ) {
      setShowNoGoalError(false);
      props.onSubmit({
        // Note: we only validate goals "touched" by the user, but send all goals to the server,
        // even if "empty". Since goals in this UI can have different "prompts" in each UI "slot",
        // This ensures consistent ordering when we re-hydrate goals from the API as they'll always load in the same "slot".
        // We anticipate this UI shifting to more of a "add as many goals as you want" paradigm ~soon,
        // at which point goals would be normalized vs unique per slot.
        data: { appointmentId, goal: goals.map((_) => _.getValue()) },

        name: TherapyIntakeSectionName.COLLABORATION_PLAN,
      });
    } else if (touchedGoals.length === 0) {
      setShowNoGoalError(true);
    }
  };

  return (
    <>
      <CollaborationGoal
        disabled={disabled}
        name="goal1"
        tooltip={
          'Resources/exercises/assignments for member\\nFor example: Continue daily mindfulness journal. Work with coach on filling in thought record.'
        }
        recommendedStepsLabel={Labels.STEPS_AGREED_TO}
        label={Labels.WHAT_MEMBER_IS_WORKING_ON}
        fields={goal1.fields}
        goalLabels={goalLabelsWorkingOn}
      />

      <Divider />

      <CollaborationGoal
        disabled={disabled}
        name="goal2"
        tooltip={
          'Resources/exercises/assignments for member\\nFor example: Continue daily mindfulness journal. Work with coach on filling in thought record.'
        }
        recommendedStepsLabel={Labels.STEPS_AGREED_TO}
        label={Labels.WHAT_MEMBER_IS_WORKING_ON}
        fields={goal2.fields}
        goalLabels={goalLabelsWorkingOn}
      />

      <Divider />

      <CollaborationGoal
        disabled={disabled}
        name="goal3"
        tooltip="Summarize any updates, takeaways, challenges, care planning information, etc. that is both helpful and appropriate for the whole care team to know."
        recommendedStepsLabel={Labels.MESSAGE_TO_CARE_TEAM}
        label={Labels.WHATS_GOING_WELL_FOR_MEMBER}
        fields={goal3.fields}
        goalLabels={goalLabelsGoingWell}
      />
      <Collapse
        className={styles.formGroup}
        addEndListener={() => null}
        in={showNoGoalError}
      >
        <Alert severity="error" onClose={() => setShowNoGoalError(false)}>
          You haven&apos;t filled out a goal.
        </Alert>
      </Collapse>
      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </>
  );
}

async function validateGoals(
  goals: UseForm<CollaborationPlan_Goal>[],
): Promise<boolean> {
  const validationPromises = goals
    .filter((_) => _.isTouched)
    .map((_) => _.validate());
  const validationResults = await Promise.all(validationPromises);
  return validationResults.reduce((a, b) => a && b, true);
}

async function hasAtLeastOneValidGoal(
  goals: UseForm<CollaborationPlan_Goal>[],
): Promise<boolean> {
  const validationPromises = goals.map((_) => _.validate());
  const validationResults = await Promise.all(validationPromises);
  return validationResults.reduce((a, b) => a || b, false);
}

function useGoalFormState(
  initialValue: CollaborationPlan_Goal,
  label: string,
  recommendedStepsLabel: string,
  onStateChange: (goal: CollaborationPlan_Goal) => void,
): UseForm<CollaborationPlan_Goal> {
  return useForm<CollaborationPlan_Goal>(
    collaborationGoalFieldDefs,
    { ...initialValue, label, recommendedStepsLabel },
    { delay: AUTO_SAVE_DRAFT_DELAY, onStateChange },
  );
}
