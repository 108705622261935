export enum HomeworkStatus {
  NOT_STARTED = 'not-started',
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
}

export enum assignHomeworkResultStatus {
  OK = 'OK',
  INVALID_ID = 'INVALID_ID',
  INVALID_LINK = 'INVALID_LINK',
  NO_LINK = 'INVALID_LINK',
  IS_DUPLICATED = 'IS_DUPLICATED',
  IS_COMPLETED = 'IS_COMPLETED',
  NOT_ALLOWED = 'NOT_ALLOWED',
  UNKNOWN = 'UNKNOWN',
}

export type HomeworkType = {
  assignmentId: string;
  slug: string;
  thumbnail: string;
  title: string;
  source: HomeworkSource;
  date: string;
  contentType: string;
  status: HomeworkStatus;
};

export interface assignHomeworkStatus {
  title: string;
  status: assignHomeworkResultStatus;
}

export interface HomeworkData {
  currentHomework: HomeworkType[];
  completedHomework: HomeworkType[];
}

export type HomeworkMap = Record<string, HomeworkData>;

export enum HomeworkSource {
  GINGER = 'Ginger',
}
