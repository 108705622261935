import React, { Fragment } from 'react';

import styles from './MemberHeaderBar.module.scss';

interface HeaderNameProps {
  firstName: string | null;
  lastName: string | null;
}

export default function HeaderName({ firstName, lastName }: HeaderNameProps) {
  const hasFirstName = firstName !== null;
  const hasLastName = lastName !== null;
  if (hasFirstName && hasLastName) {
    return (
      <span
        data-testid="member-header-full-name"
        className={[styles.demographicsItem, styles.memberName].join(' ')}
      >
        {firstName} {lastName}
      </span>
    );
  }
  if (!hasFirstName && hasLastName) {
    return (
      <span
        data-testid="member-header-last-name"
        className={[styles.demographicsItem, styles.memberName].join(' ')}
      >
        <span className={styles.noName}>(First Name Not Available)</span>{' '}
        {lastName}
      </span>
    );
  }
  if (hasFirstName && !hasLastName) {
    return (
      <span
        data-testid="member-header-first-name"
        className={[styles.demographicsItem, styles.memberName].join(' ')}
      >
        {firstName}{' '}
        <span className={styles.noName}>(Last Name Not Available)</span>
      </span>
    );
  }
  if (!hasFirstName && !hasLastName) {
    return (
      <span
        data-testid="member-header-no-name"
        className={[styles.demographicsItem, styles.memberName].join(' ')}
      >
        <span className={styles.noName}>Name Not Available</span>
      </span>
    );
  }
  return <Fragment />; // typescript can't figure out these if statements are exhaustive
}
