import { Button as MuiButton, Grid, Typography } from '@mui/material';
import Labels from 'app/appointments/strings/en.json';
import React, { ReactNode, useState } from 'react';

import styles from './AppointmentScreen.module.scss';
import { EventButtons } from './EventButtons';
import { ActionBar } from './ScheduleActionBar';

export function EventControls({
  onSave,
  onClose,
  onDelete,
  disableMenuAction,
  children,
}: {
  onSave: () => Promise<void>;
  onDelete?: () => Promise<void>;
  disableMenuAction?: boolean;
  onClose: () => void;
  children: ReactNode;
}) {
  const [submitting, setSubmitting] = useState(false);
  const dialogMessages = {
    error: Labels.EVENT_DELETION_FAILURE,
    label: Labels.EVENT_LABEL,
    message: `${Labels.EVENT_LABEL} **will be lost and cannot be recovered** once deleted.`,
    success: Labels.EVENT_DELETION_SUCCESS,
  };

  async function onSaveWrapper() {
    setSubmitting(true);
    try {
      await onSave();
    } catch {}
    setSubmitting(false);
  }

  return (
    <Grid container={true} className={styles.root} data-testid="Event">
      <div className={styles.appointment}>
        <Grid item={true} xs={1}>
          <MuiButton className={styles.close} startIcon="X" onClick={onClose} />
        </Grid>
        <Grid item={true} xs={11}>
          <ActionBar
            leftElement={<Typography variant="h1">Event</Typography>}
            rightElement={
              <EventButtons
                onSave={onSaveWrapper}
                loading={submitting}
                disableActionMenu={disableMenuAction}
                onDelete={onDelete!}
                dialogMessage={dialogMessages}
              />
            }
          />
          {children}
        </Grid>
      </div>
    </Grid>
  );
}
