import gql from 'graphql-tag';

export const getServiceProvisionIntervalsForMemberQuery = gql`
  query GetServiceProvisionIntervalsForMember(
    $input: GetMemberServiceProvisionsInput!
  ) {
    getServiceProvisionIntervalsForMember(input: $input) {
      intervals {
        numSessions
        numVideoSessions
        startsAt
        endsAt
        isAutorenew
      }
    }
  }
`;
