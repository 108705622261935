import CreateSharpIcon from '@mui/icons-material/CreateSharp';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { appointmentNoteRoute } from 'app/top-nav/Routes';
import { isConfirmedAppointment } from 'utils';
import {
  AppointmentType,
  ClinicalAppointmentNoteType,
  ClinicalAppointmentType,
  NoteLocation,
  NoteType,
} from 'generated/globalTypes';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import React, { ReactElement } from 'react';

import styles from './ClinicalNoteActionBar.module.scss';
import { CreateClinicalNoteButton } from './CreateClinicalNoteButton';
import {
  DownloadClinicalNoteButton,
  isDownloadableNoteType,
} from './DownloadClinicalNoteButton';
import { IconActionBarItem } from './IconActionBarItem';
import { Appointment, NoteAction } from './types';
import { getNoteAction } from './utils';

// THe default set on actions a clinician/clinical-supervisor can perform on
// clinical appointment notes, out-of-session notes, and termination notes
export const defaultNoteActions: Set<NoteAction> = new Set([
  NoteAction.CREATE,
  NoteAction.EDIT,
  NoteAction.VIEW,
  NoteAction.DOWNLOAD,
]);

type Props = {
  userId: string;
  className?: string;
  navigateTo: (url: string) => void;
  navigateToExternalSite?: (url: string) => void;
  signedLockedAction: NoteAction.VIEW | NoteAction.DOWNLOAD;
  appointment: Appointment;
  vaultEnabled: boolean;
  allowedNoteActions: Set<NoteAction>;
  onClick?: (
    type: ClinicalAppointmentNoteType | NoteType,
    appointmentId: string,
  ) => void;
};

export function ClinicalNoteActionBar(props: Props) {
  const {
    userId,
    vaultEnabled,
    navigateTo,
    navigateToExternalSite = (url: string) => window.open(url, '_blank'),
    appointment,
    className = '',
    allowedNoteActions,
    signedLockedAction,
    onClick,
  } = props;
  const {
    id,
    clinicalNote,
    clinicianId,
    appointmentType,
    start,
    member: { id: memberId },
  } = appointment;
  const { url: drChronoUrl, location, status } = clinicalNote;
  const { transientFeatureFlags } = useFeatureFlags();

  // TODO [Fix]: Only allow owner of appointment to create & edit clinical note.
  const onCreateClick = (noteType: ClinicalAppointmentNoteType | NoteType) => {
    const canStartPsychVaultNote =
      appointmentType === ClinicalAppointmentType.PSYCHIATRY_INTAKE ||
      appointmentType === ClinicalAppointmentType.PSYCHIATRY_PROGRESS ||
      appointmentType === AppointmentType.PSYCHIATRY;
    const canStartTherapyVaultNote =
      vaultEnabled &&
      (appointmentType === ClinicalAppointmentType.THERAPY_INTAKE ||
        appointmentType === ClinicalAppointmentType.THERAPY_PROGRESS ||
        appointmentType === AppointmentType.THERAPY);

    // "An UNSPECIFIED NoteType indicates we're using legacy Dr. Chrono notes"
    if (
      (canStartTherapyVaultNote || canStartPsychVaultNote) &&
      noteType !== ClinicalAppointmentNoteType.UNSPECIFIED &&
      noteType !== NoteType.UNSPECIFIED
    ) {
      if (onClick) onClick(noteType, id);
      navigateTo(
        appointmentNoteRoute(
          { appointmentId: id, noteType },
          memberId,
          transientFeatureFlags,
        ),
      );
    } else {
      navigateToExternalSite(drChronoUrl);
    }
  };

  const onEditOrViewClick = () => {
    if (vaultEnabled && location === NoteLocation.VAULT) {
      navigateTo(
        appointmentNoteRoute(
          {
            appointmentId: id,
            noteType: clinicalNote.noteType!,
          },
          memberId,
          transientFeatureFlags,
        ),
      );
    } else {
      navigateToExternalSite(drChronoUrl);
    }
  };

  if (!isConfirmedAppointment(appointment.appointmentStatus)) {
    return <span data-testid="emptyIcon" />;
  }

  const ownsAppointment = userId === clinicianId;
  const action = getNoteAction(
    status,
    allowedNoteActions,
    signedLockedAction,
    ownsAppointment,
    start,
  );

  const isNoteDownloadable =
    vaultEnabled &&
    location === NoteLocation.VAULT &&
    isDownloadableNoteType(clinicalNote.noteType!);

  switch (action) {
    case NoteAction.CREATE: {
      return (
        <Wrapper className={className}>
          <CreateClinicalNoteButton
            appointmentType={appointmentType!}
            onClick={onCreateClick}
          />
        </Wrapper>
      );
    }

    case NoteAction.EDIT: {
      return (
        <Wrapper className={className}>
          <IconActionBarItem
            testId="editNote"
            onClick={onEditOrViewClick} // check location here
            title="Edit Note"
            Icon={CreateSharpIcon}
            stopPropagation={true}
          />
        </Wrapper>
      );
    }

    case NoteAction.VIEW: {
      return (
        <Wrapper className={className}>
          <IconActionBarItem
            testId="viewNote"
            onClick={onEditOrViewClick}
            title="View Note"
            Icon={VisibilityOutlinedIcon}
            stopPropagation={true}
          />
        </Wrapper>
      );
    }

    case NoteAction.DOWNLOAD: {
      return (
        <Wrapper className={className}>
          {isDownloadableNoteType(clinicalNote.noteType!) &&
          isNoteDownloadable ? (
            <DownloadClinicalNoteButton
              appointmentId={id}
              noteType={clinicalNote.noteType!}
            />
          ) : (
            <IconActionBarItem
              data-testid="viewNoteInDrC"
              onClick={() => navigateToExternalSite(drChronoUrl)}
              title="View Note In Dr. Chrono"
              Icon={VisibilityOutlinedIcon}
              stopPropagation={true}
            />
          )}
        </Wrapper>
      );
    }

    case NoteAction.NONE:
    case NoteAction.REVIEW_DRAFT: {
      return null;
    }
  }
}

function Wrapper(props: { children: ReactElement; className: string }) {
  return (
    <div
      data-testid="iconActionBar"
      className={[styles.root, props.className].join(' ')}
    >
      {props.children}
    </div>
  );
}
