import {
  booleanField,
  positiveNumberField,
  stringField,
  useForm,
} from '@ginger.io/react-use-form';
import { Box, Button, Container } from '@mui/material';
import {
  CheckboxField,
  EnumDropdownField,
  NumberField,
} from 'app/notes-ui/forms/fields';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { TimeZoneOptions } from 'app/timezones';
import React from 'react';
import { Width } from 'types/StyleTypes';

import { Settings } from './ClinicianSettingsScreen';

/* eslint-enable @typescript-eslint/naming-convention */
export const DEFAULT_TIMEZONE = TimeZoneOptions['America/Los_Angeles'];

export type Props = {
  clinicianId: string;
  initialSettings: Settings;
  onSave: (clinicianId: string, settings: Settings) => void;
};

export function ClinicianSettingsComponent(props: Props): JSX.Element {
  const { clinicianId, initialSettings, onSave } = props;

  const { fields, getValue, validate } = useForm<Settings>({
    calendarSyncEnabled: booleanField({
      default: initialSettings.calendarSyncEnabled,
    }),
    maxAppointments: positiveNumberField({
      default: initialSettings.maxAppointments,
    }),
    timeZone: stringField({ default: initialSettings.timeZone }),
  });

  async function onClick() {
    if (await validate()) {
      onSave(clinicianId, getValue());
    }
  }

  return (
    <Container maxWidth="md">
      <h1>Settings</h1>
      <h2>Preferences</h2>
      <NoteFormControlLabel label="Time Zone">
        <EnumDropdownField
          width={Width.HALF}
          testId="timeZone"
          label="Time zone"
          options={TimeZoneOptions}
          field={fields.timeZone}
          disabled={false}
        />
      </NoteFormControlLabel>
      <NoteFormControlLabel label="Max. Appointments per week">
        <NumberField
          testId="maxAppointments"
          disabled={true}
          label=""
          field={fields.maxAppointments}
        />
      </NoteFormControlLabel>
      <NoteFormControlLabel label="Sync my Google Calendar">
        <CheckboxField
          testId="calendarSyncEnabled"
          disabled={initialSettings.calendarSyncEnabled}
          label=""
          field={fields.calendarSyncEnabled}
        />
      </NoteFormControlLabel>
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={onClick}
          size="medium"
          data-testid="save-button"
        >
          Save
        </Button>
      </Box>
    </Container>
  );
}
