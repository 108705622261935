import { UserRole } from 'generated/globalTypes';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { useAppointmentScreenHelpers } from './hooks';
import { UpdateAppointmentClinicianContainer } from './UpdateAppointmentClinicianContainer';
import { UpdateAppointmentMSContainer } from './UpdateAppointmentMSContainer';

export type AppointmentScreenProps = {
  appointmentId: string;
};

export function AppointmentScreen(
  props: RouteComponentProps<AppointmentScreenProps>,
) {
  const { location, match } = props;
  const {
    params: { appointmentId },
  } = match;

  const searchParams = new URLSearchParams(location.search);
  const role = searchParams.get('role') as UserRole;
  const { allowEditOthersAppointments } = useAppointmentScreenHelpers(role);

  // If allowing the current user to edit others' appointments, use the more powerful container. Otherwise, use the
  // container that constrains the ability to edit only appointments of the current user.
  if (allowEditOthersAppointments) {
    return <UpdateAppointmentMSContainer appointmentId={appointmentId} />;
  }
  return <UpdateAppointmentClinicianContainer appointmentId={appointmentId} />;
}

export default withRouter(AppointmentScreen);
