import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { ClinicalAppointmentById_getClinicalAppointment } from '@headspace/carehub-graphql/dist/appointments/generated/ClinicalAppointmentById';
import {
  NotesItemResponse,
  RoleType,
} from 'app/coach/coach-notes/CoachNotesTypes';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { appointmentNoteRoute } from 'app/top-nav/Routes';
import { DATE_TIME_FORMAT } from 'app/notes-ui/forms/form-controls/DateTimeRangeInput';
import {
  appointmentRecurringBiweeklyClick,
  appointmentRecurringDoesNotRepeatClick,
  appointmentRecurringWeeklyClick,
} from 'app/state/amplitude/actions/appointments';
import { formatAppointmentStatus } from 'utils';
import {
  AppointmentType,
  ClinicalAppointmentNoteStatus,
  ClinicalAppointmentNoteType,
  ClinicalAppointmentStatus,
  ClinicalAppointmentType,
  ClinicalNoteStatus,
  NoteType,
  UpdateClinicalAppointmentInput,
} from 'generated/globalTypes';
import moment from 'moment/moment';
import { MouseEvent, useState } from 'react';
import { Action } from 'redux-reloaded';

import { AppointmentsAndNotes } from './AppointmentsAndNotesAPIContext';
import { ClinicalAppointmentRecurrenceIntervalOptions } from './NewAppointmentForMeForm';
import { EmptyFormValue } from './NewEventForm';
import { Appointment, NoteAction, UpdateAppointmentForm } from './types';

/**
 * State of the Appointment create/edit form, especially w/r to saving data to server-side. Notifications should
 * be displayed correspondingly if any one of these states is defined/true.
 *
 * - error: indicate an error message to be presented (this is more general and can be used for any error)
 * - submitting: true indicates that a "save" operation is in progress (i.e. a request is being submitted to the server)
 * - succeeded: true indicates that a submission has come back successfully (no errors).
 */
type AppointmentFormState = {
  error?: string;
  succeeded?: boolean;
  submitting: boolean;
};

type AppointmentTypeDetail = {
  role: string;
  session: string;
};

const CANCELLED_STATUSES: (EmptyFormValue | ClinicalAppointmentStatus)[] = [
  ClinicalAppointmentStatus.Cancelled,
  ClinicalAppointmentStatus.CancelledClinician,
];

export const CLINICAL_APPOINTMENT = 'ClinicalAppointment';
export const OUT_OF_SESSION_OR_TERMINATION_NOTE =
  'OutOfSessionOrTerminationNote';

export function useAppointmentFormState() {
  const [state, setState] = useState<AppointmentFormState>({
    error: undefined,
    submitting: false,
    succeeded: undefined,
  });

  const setError = (error: string) =>
    setState({ error, submitting: false, succeeded: false });
  const setSubmitting = () =>
    setState({ error: undefined, submitting: true, succeeded: undefined });
  const setComplete = () =>
    setState({ error: undefined, submitting: false, succeeded: true });

  return { setComplete, setError, setSubmitting, state };
}

export const noPropagation = <T,>(e: MouseEvent<T>) => e.stopPropagation();

export const makeOnRecurrenceSelect = (
  dispatch: (action: Action<unknown>) => void,
) => {
  return (selected: ClinicalAppointmentRecurrenceIntervalOptions) => {
    switch (selected) {
      case ClinicalAppointmentRecurrenceIntervalOptions.NONE:
        dispatch(appointmentRecurringDoesNotRepeatClick());
        return;
      case ClinicalAppointmentRecurrenceIntervalOptions.ONE_WEEK:
        dispatch(appointmentRecurringWeeklyClick());
        return;
      case ClinicalAppointmentRecurrenceIntervalOptions.TWO_WEEKS:
        dispatch(appointmentRecurringBiweeklyClick());
    }
  };
};

export function notEmptyRule(
  value: EmptyFormValue | ClinicalAppointmentStatus,
): string | undefined {
  if (value === EmptyFormValue.NONE) {
    return "This field can't be empty";
  }
}

export function notCanceledRule(
  value: EmptyFormValue | ClinicalAppointmentStatus,
  data: ClinicalAppointmentById_getClinicalAppointment,
): string | undefined {
  if (
    Boolean(data.clinicalNote) && // not null or undefined
    CANCELLED_STATUSES.includes(value)
  ) {
    return 'This appointment already has a clinical note attached to it and cannot be cancelled';
  }
}

export function isUpdatingPastAppointment(
  input: UpdateClinicalAppointmentInput,
  appointment: ClinicalAppointmentById_getClinicalAppointment,
) {
  const now = moment();
  const updatedStartTime = moment(input.start);
  const updatedEndTime = moment(input.end);
  const startTime = moment(appointment.start);
  const endDatetime = moment(appointment.end);
  return (
    (!updatedStartTime.isSame(startTime) ||
      !updatedEndTime.isSame(endDatetime)) &&
    startTime.isBefore(now)
  );
}

export function reformatUpdateAppointmentFormFields(
  appointmentData: UpdateAppointmentForm,
  timezone: string,
): UpdateClinicalAppointmentInput {
  const start = moment
    .tz(appointmentData.start, DATE_TIME_FORMAT, timezone)
    .toISOString();
  const end = moment
    .tz(appointmentData.end, DATE_TIME_FORMAT, timezone)
    .toISOString();
  const appointmentStatus = appointmentData.appointmentStatus as ClinicalAppointmentStatus;
  const type = appointmentData.type as ClinicalAppointmentType;
  const cancelledReason =
    appointmentData.cancelledReason !== EmptyFormValue.NONE
      ? appointmentData.cancelledReason
      : null;
  return {
    ...appointmentData,
    appointmentStatus,
    cancelledReason,
    end,
    start,
    type,
  };
}

export function getAppointmentStatusOptions(isCancelledAppointment: boolean) {
  const cancelledAppointmentStatuses = [
    {
      name: formatAppointmentStatus(ClinicalAppointmentStatus.NoShow),
      value: ClinicalAppointmentStatus.NoShow,
    },
    {
      name: formatAppointmentStatus(ClinicalAppointmentStatus.ProviderNoShow),
      value: ClinicalAppointmentStatus.ProviderNoShow,
    },
    {
      name: formatAppointmentStatus(ClinicalAppointmentStatus.Cancelled),
      value: ClinicalAppointmentStatus.Cancelled,
    },
    {
      name: formatAppointmentStatus(
        ClinicalAppointmentStatus.CancelledClinician,
      ),
      value: ClinicalAppointmentStatus.CancelledClinician,
    },
    {
      name: formatAppointmentStatus(ClinicalAppointmentStatus.LateCancelled),
      value: ClinicalAppointmentStatus.LateCancelled,
    },
  ];

  const otherAppointmentStatuses = [
    {
      name: formatAppointmentStatus(ClinicalAppointmentStatus.Confirmed),
      value: ClinicalAppointmentStatus.Confirmed,
    },
    {
      name: formatAppointmentStatus(ClinicalAppointmentStatus.Complete),
      value: ClinicalAppointmentStatus.Complete,
    },
    {
      name: formatAppointmentStatus(ClinicalAppointmentStatus.Tentative),
      value: ClinicalAppointmentStatus.Tentative,
    },
  ];

  return isCancelledAppointment
    ? cancelledAppointmentStatuses
    : otherAppointmentStatuses.concat(cancelledAppointmentStatuses);
}

export function getAppointmentTypeDuration(type: ClinicalAppointmentType) {
  switch (type) {
    case ClinicalAppointmentType.PSYCHIATRY_INTAKE:
    case ClinicalAppointmentType.THERAPY_INTAKE:
      return 60;
    case ClinicalAppointmentType.THERAPY_PROGRESS:
      return 45;
    case ClinicalAppointmentType.PSYCHIATRY_PROGRESS:
      return 30;
    default:
      return 45;
  }
}

export function getAppointmentTypeDetails(
  type: ClinicalAppointmentType | null,
): AppointmentTypeDetail {
  if (!type) {
    return {
      role: 'Therapy',
      session: 'Initial Consult',
    };
  }

  switch (type) {
    case ClinicalAppointmentType.PSYCHIATRY_INTAKE:
      return {
        role: 'Psychiatry',
        session: 'Initial Consult',
      };
    case ClinicalAppointmentType.PSYCHIATRY_PROGRESS:
      return {
        role: 'Psychiatry',
        session: 'Follow-up',
      };
    case ClinicalAppointmentType.THERAPY_INTAKE:
      return {
        role: 'Therapy',
        session: 'Initial Consult',
      };
    case ClinicalAppointmentType.THERAPY_PROGRESS:
      return {
        role: 'Therapy',
        session: 'Follow-up',
      };
  }
}

export const createNoteClick = (
  appointment: Appointment,
  noteType: ClinicalAppointmentNoteType | NoteType,
  appointmentType: ClinicalAppointmentType | AppointmentType | null,
  vaultEnabled: boolean,
  dispatchHandler: (
    type: ClinicalAppointmentNoteType | NoteType,
    appointmentId: string,
  ) => void,
  navigateTo: (url: string) => void,
) => {
  const canStartPsychVaultNote =
    appointmentType === ClinicalAppointmentType.PSYCHIATRY_INTAKE ||
    appointmentType === ClinicalAppointmentType.PSYCHIATRY_PROGRESS ||
    appointmentType === AppointmentType.PSYCHIATRY;
  const canStartTherapyVaultNote =
    vaultEnabled &&
    (appointmentType === ClinicalAppointmentType.THERAPY_INTAKE ||
      appointmentType === ClinicalAppointmentType.THERAPY_PROGRESS ||
      appointmentType === AppointmentType.THERAPY);

  const {
    member: { id: memberId },
  } = appointment;
  const { transientFeatureFlags } = useFeatureFlags();

  if (
    (canStartTherapyVaultNote || canStartPsychVaultNote) &&
    noteType !== ClinicalAppointmentNoteType.UNSPECIFIED &&
    noteType !== NoteType.UNSPECIFIED
  ) {
    if (dispatchHandler) dispatchHandler(noteType, appointment.id);
    navigateTo(
      appointmentNoteRoute(
        { appointmentId: appointment.id, noteType },
        memberId,
        transientFeatureFlags,
      ),
    );
  }
};

export function getNoteTypeFromAppointmetType(
  type: ClinicalAppointmentType | null,
): ClinicalAppointmentNoteType {
  switch (type) {
    case ClinicalAppointmentType.PSYCHIATRY_INTAKE:
      return ClinicalAppointmentNoteType.PSYCHIATRY_INTAKE;
    case ClinicalAppointmentType.THERAPY_INTAKE:
      return ClinicalAppointmentNoteType.THERAPY_INTAKE;
    case ClinicalAppointmentType.PSYCHIATRY_PROGRESS:
      return ClinicalAppointmentNoteType.PSYCHIATRY_PROGRESS;
    case ClinicalAppointmentType.THERAPY_PROGRESS:
      return ClinicalAppointmentNoteType.THERAPY_PROGRESS;
    default:
      return ClinicalAppointmentNoteType.UNSPECIFIED;
  }
}

export function getNoteAction(
  noteStatus:
    | ClinicalAppointmentNoteStatus
    | ClinicalNoteStatus
    | null
    | undefined,
  allowedActions: Set<NoteAction>,
  signedLockedAction: NoteAction.VIEW | NoteAction.DOWNLOAD,
  ownsAppointment: boolean,
  appointmentStart: string,
): NoteAction {
  const currentTime = moment();
  if (
    moment(appointmentStart) > currentTime.add(3, 'days') &&
    noteStatus === ClinicalNoteStatus.NOT_STARTED
  ) {
    // We don't let clinicians start notes "too far" in the future because
    // they often accidentally start notes for the wrong appointment
    return NoteAction.NONE;
  }
  if (
    noteStatus === ClinicalNoteStatus.NOT_STARTED &&
    allowedActions.has(NoteAction.CREATE) &&
    ownsAppointment
  ) {
    return NoteAction.CREATE;
  }
  if (
    noteStatus === ClinicalNoteStatus.DRAFT &&
    allowedActions.has(NoteAction.EDIT) &&
    (ownsAppointment || allowedActions.has(NoteAction.REVIEW_DRAFT))
  ) {
    return NoteAction.EDIT;
  }
  if (
    noteStatus === ClinicalNoteStatus.SIGNED_AND_LOCKED &&
    allowedActions.has(NoteAction.VIEW) &&
    signedLockedAction === NoteAction.VIEW
  ) {
    return NoteAction.VIEW;
  }
  if (
    noteStatus === ClinicalNoteStatus.SIGNED_AND_LOCKED &&
    allowedActions.has(NoteAction.DOWNLOAD) &&
    signedLockedAction === NoteAction.DOWNLOAD
  ) {
    return NoteAction.DOWNLOAD;
  }
  if (
    !ownsAppointment &&
    noteStatus === ClinicalNoteStatus.SIGNED_AND_LOCKED &&
    allowedActions.has(NoteAction.VIEW)
  ) {
    return NoteAction.VIEW;
  }
  return NoteAction.NONE;
}

export const activeTypeFilter = (
  item: AppointmentsAndNotes,
  activeAppointmentTypes: (AppointmentType | RoleType)[],
): boolean => {
  const filters = [];

  if ('__typename' in item) {
    // only Clinical Appointments and Out-of-Session/Termination notes have typename
    if (item.__typename === CLINICAL_APPOINTMENT) {
      if (activeAppointmentTypes.includes(AppointmentType.THERAPY)) {
        filters.push(
          [
            ClinicalAppointmentType.THERAPY_INTAKE,
            ClinicalAppointmentType.THERAPY_PROGRESS,
          ].includes(item.type || ClinicalAppointmentType.THERAPY_INTAKE),
        );
      }

      if (activeAppointmentTypes.includes(AppointmentType.PSYCHIATRY)) {
        filters.push(
          [
            ClinicalAppointmentType.PSYCHIATRY_INTAKE,
            ClinicalAppointmentType.PSYCHIATRY_PROGRESS,
          ].includes(item.type || ClinicalAppointmentType.PSYCHIATRY_INTAKE),
        );
      }

      // return true if any of the filters are true
      return filters.some((filter) => filter);
    }

    // no filter for out-of-session or termination notes, but we will want to exclude them if the
    // only active type filter is "coaching" since out-of-session and termination notes are clinical
    return (
      activeAppointmentTypes.includes(AppointmentType.THERAPY) ||
      activeAppointmentTypes.includes(AppointmentType.PSYCHIATRY)
    );
  }
  // filter shared coaching notes
  if (activeAppointmentTypes.includes(RoleType.COACHING)) {
    filters.push(
      [RoleType.COACHING].includes(
        (item as NotesItemResponse).noteDetails?.role as RoleType,
      ),
    );
  }

  return filters.some((filter) => filter);
};

export const activeAppStatusFilter = (
  item: AppointmentsAndNotes,
  statuses: ClinicalAppointmentStatus[],
): boolean => {
  if ('__typename' in item && item.__typename === CLINICAL_APPOINTMENT) {
    // only Clinical Appointments and Out-of-Session/Termination notes have typename
    if (!item.appointmentStatus) return true;

    return statuses.includes(item.appointmentStatus);
  }

  return true;
};

export const activeNoteStatusFilter = (
  item: AppointmentsAndNotes,
  statuses: ClinicalNoteStatus[],
): boolean => {
  const filters = [];

  if ('__typename' in item) {
    // only Clinical Appointments and Out-of-Session/Termination notes have typename
    if (item.__typename === CLINICAL_APPOINTMENT) {
      const status = (item.clinicalNote?.status ||
        ClinicalNoteStatus.NOT_STARTED) as ClinicalNoteStatus;

      return statuses.includes(status);
    }

    if (item.__typename === OUT_OF_SESSION_OR_TERMINATION_NOTE) {
      if (item.status === Metadata_NoteStatus.signed_and_locked_note) {
        filters.push(statuses.includes(ClinicalNoteStatus.SIGNED_AND_LOCKED));
      }

      if (item.status === Metadata_NoteStatus.draft_note) {
        filters.push(statuses.includes(ClinicalNoteStatus.DRAFT));
      }

      if (
        item.status === Metadata_NoteStatus.UNRECOGNIZED ||
        item.status === Metadata_NoteStatus.undefined_note_status
      ) {
        filters.push(statuses.includes(ClinicalNoteStatus.NOT_STARTED));
      }

      return filters.some((filter) => filter);
    }
  } else {
    // filter shared coaching notes, which will always be read-only (i.e., Signed & Locked)
    filters.push(statuses.includes(ClinicalNoteStatus.SIGNED_AND_LOCKED));
    return filters.some((filter) => filter);
  }

  return true;
};
