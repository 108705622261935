import { useMutation } from '@apollo/client';
import {
  CreateClinicalAppointment,
  CreateClinicalAppointmentVariables,
} from '@headspace/carehub-graphql/dist/appointments/generated/CreateClinicalAppointment';
import {
  GetMemberTimezoneById,
  GetMemberTimezoneByIdVariables,
} from '@headspace/carehub-graphql/dist/appointments/generated/GetMemberTimezoneById';
import { DEFAULT_TIMEZONE } from 'app/clinician/ClinicianSettingsComponent';
import { useDispatch } from 'app/state';
import {
  appointmentRecurringClick,
  clickScheduleNewAppointmentSaveAction,
  createAppointmentAction,
} from 'app/state/amplitude/actions/appointments';
import { CreateClinicalAppointmentInput } from 'generated/globalTypes';
import { useQuery } from 'hooks/useQuery';
import { useSnackNotification } from 'hooks/useSnackNotification';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { ErrorState } from 'shared-components/error-state/ErrorState';

import {
  createAppointmentForClinicianMutation,
  getMemberTimezoneAndBillingByIdQuery,
} from './appointmentQueries';
import { getErrorMessage } from './errorUtils';
import { NewAppointmentMSForm } from './NewAppointmentMSForm';
import { makeOnRecurrenceSelect } from './utils';

export type CreateClinicalAppointmentMS = {
  appointmentData: CreateClinicalAppointmentInput;
  clinicianId: string;
};

/**
 * Container for new appointment for use by MS
 */
export function NewAppointmentMSContainer(props: { patientId: string }) {
  const { patientId } = props;
  const history = useHistory();
  const [createAppointment] = useMutation<
    CreateClinicalAppointment,
    CreateClinicalAppointmentVariables
  >(createAppointmentForClinicianMutation);
  const {
    showErrorNotification,
    showSuccessNotification,
  } = useSnackNotification();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createAppointmentAction({ patientId }));
  }, [dispatch, patientId]);

  const onCreate = async (input: CreateClinicalAppointmentMS) => {
    const { clinicianId } = input;
    let response;
    try {
      response = await createAppointment({
        variables: {
          input: {
            appointmentData: input.appointmentData,
            clinicianId: Number.parseInt(clinicianId, 10),
          },
        },
      });
      const appointmentIds = response.data?.createAppointmentForClinician?.appointments.map(
        (a) => a.id,
      );
      if (appointmentIds) {
        dispatch(clickScheduleNewAppointmentSaveAction({ appointmentIds }));
        history.goBack();
        showSuccessNotification('Appointment Created');
      }
    } catch (e) {
      const errors = response?.errors ? response.errors.join(', ') : e;
      const errorMessage = getErrorMessage(errors);
      const message = ['Error updating event', errorMessage].join(': ');

      showErrorNotification(message);
    }
  };

  return useQuery<GetMemberTimezoneById, GetMemberTimezoneByIdVariables>(
    (memberTimezoneData) => {
      const member = memberTimezoneData.getMember;
      const timezone = member?.preferences?.timezone ?? DEFAULT_TIMEZONE;

      if (!member) {
        return (
          <div data-testid="member-not-found">
            <ErrorState error={new Error('Member not found')} />
          </div>
        );
      }

      return (
        <NewAppointmentMSForm
          onClose={history.goBack}
          member={member}
          onCreate={onCreate}
          timezone={timezone}
          onRecurrenceSelect={makeOnRecurrenceSelect(dispatch)}
          onRecurrenceClick={() => dispatch(appointmentRecurringClick())}
          data={memberTimezoneData}
        />
      );
    },

    getMemberTimezoneAndBillingByIdQuery,
    {
      variables: { id: patientId },
    },
  );
}
