import { stringField, useForm } from '@ginger.io/react-use-form';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';
import { Input } from 'shared-components/form-inputs/Input';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import styles from 'app/member-chart-cards/prescriptions/Pharmacy.module.scss';
import React, { useState } from 'react';

interface SearchPharmacyProps {
  onSearch: (filters: { [k: string]: string }) => Promise<void>;
  onBack: () => void;
}

interface SearchPharmacyForm {
  address: string;
  city: string;
  state: string;
  zip: string;
  name: string;
}

export const SearchPharmacy = (props: SearchPharmacyProps) => {
  const { onSearch, onBack } = props;
  const [loading, setLoading] = useState(false);
  const { fields, getValue } = useForm<SearchPharmacyForm>({
    address: stringField(),
    city: stringField(),
    name: stringField(),
    state: stringField(),
    zip: stringField(),
  });
  const searchableInputs = [
    {
      field: fields.address,
      label: 'Address',
      name: 'address',
    },
    {
      field: fields.city,
      label: 'City',
      name: 'city',
    },
    {
      field: fields.state,
      label: 'State',
      name: 'state',
    },
    {
      field: fields.zip,
      label: 'Zip Code',
      name: 'zipCode',
    },
    {
      field: fields.name,
      label: 'Name',
      name: 'name',
    },
  ];

  const onSearchClick = async () => {
    setLoading(true);

    const filters = Object.fromEntries(
      Object.entries(getValue()).filter(([_, value]) => value !== ''),
    );
    await onSearch(filters);
    setLoading(false);
  };

  return (
    <div data-testid="searchPharmacyCard" className={styles.root}>
      <button className={styles.backButton} onClick={onBack}>
        <ArrowBackIcon /> Back to Prescriptions
      </button>
      <div className={styles.helpText}>
        <h4>Search Pharmacies</h4>
        You may use any of the fields to search.
      </div>

      {searchableInputs.map((input) => {
        return (
          <div className={styles.formInput} key={input.name}>
            <FieldLabel labelText={input.label} />
            <Input
              value={input.field.value}
              name={input.name}
              type="text"
              onChange={input.field.setValue}
              placeholder={input.label}
              variant="outlined"
              size="small"
              className={styles.input}
            />
          </div>
        );
      })}
      <div className={styles.searchButton}>
        <LoadingButton
          data-testid="searchBtn"
          onClick={onSearchClick}
          loading={loading}
          variant="contained"
          size="small"
        >
          Search
        </LoadingButton>
      </div>
    </div>
  );
};
