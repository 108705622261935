import CSSRSLineChart from 'app/charts/CSSRSLineChart';
import { ThreeDots } from 'app/charts/ThreeDots';
import { Box } from 'shared-components/Box';
import { CSSRS_SCORE_LABEL } from 'app/patients/tabs/surveys/SurveysV3/constants';
import { ScoreDetails } from 'app/patients/tabs/surveys/SurveysV3/ScoreDetails';
import {
  GetMemberSurvey_getMember_surveyResponses,
  GetMemberSurvey_getMember_surveyResponses_cssrsSurveys_surveys,
} from 'app/queries/generated/GetMemberSurvey';
import { viewSurveyTabAction } from 'app/state/amplitude/actions/surveys';
import { sortTimestampAsc } from 'utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';
import { NavigationTabs } from 'shared-components/tabs/NavigationTabs';
import CssrsInfoBox from './CssrsInfoBox';
import styles from './SurveysTabV3PSS.module.scss';
import { CSSRSSummary, CSSRSSurveyResponse } from './types';
import { useTabLinksForSurveyV3 } from './utils';

interface Props {
  id: string;
  pathPrefix: string;
  coachingPaths?: boolean;
  surveyResponses?: GetMemberSurvey_getMember_surveyResponses | null;
}

export default function SurveysTabV3CSSRS(props: Props) {
  const { id, pathPrefix, coachingPaths = false, surveyResponses } = props;
  const tabs = useTabLinksForSurveyV3(pathPrefix, id, coachingPaths);

  const cssrs = formatCSSRS(surveyResponses);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewSurveyTabAction());
  }, [dispatch]);

  const [selectedInfo, setSelectedInfo] = useState<CSSRSSummary | null>(null);
  const [selectedResponse, setSelectedResponse] = useState<
    MaybeUndefined<
      GetMemberSurvey_getMember_surveyResponses_cssrsSurveys_surveys
    >
  >(undefined);

  const handleClick = useCallback(
    (index: number, data: CSSRSSurveyResponse) => {
      setSelectedInfo({
        riskLevel: data.riskAssessment ?? '',
      });
      setSelectedResponse(surveyResponses?.cssrsSurveys?.surveys[index]);
    },
    [setSelectedInfo, surveyResponses, setSelectedResponse],
  );

  return (
    <div className={styles.body}>
      <div className={styles.content}>
        <Grid>
          <Columns widths={[COLUMN_WIDTH.FULL]}>
            <Box>
              <div className={styles.surveyScore}>
                <NavigationTabs links={tabs} />
                <div className={styles.graph}>
                  {cssrs.length >= 1 ? (
                    <CSSRSLineChart
                      disableTooltip
                      responses={cssrs}
                      onClick={handleClick}
                    />
                  ) : (
                    <p>Member has not completed this survey yet. </p>
                  )}
                </div>
                <CssrsInfoBox info={selectedInfo} />
                <ScoreDetails
                  Component={ThreeDots}
                  scoreLabels={CSSRS_SCORE_LABEL}
                  questions={surveyResponses?.cssrsSurveys?.questions}
                  answers={selectedResponse}
                />
              </div>
            </Box>
          </Columns>
        </Grid>
      </div>
    </div>
  );
}

export function formatCSSRS(
  surveyResponses?: GetMemberSurvey_getMember_surveyResponses | null,
): CSSRSSurveyResponse[] {
  return (surveyResponses?.cssrsSurveys?.surveys ?? [])
    .sort(sortTimestampAsc)
    .map(({ ...rest }) => {
      return {
        detailedPlans: rest.detailedPlans ?? 0,
        intendToAct: rest.intendToAct ?? 0,
        recentActions: rest.recentActions ?? 0,
        riskAssessment: rest.riskAssessment ?? '',
        thoughtsOfSuicide: rest.thoughtsOfSuicide ?? 0,
        thoughtsOnHow: rest.thoughtsOnHow ?? 0,
        thoughtsWithIntention: rest.thoughtsWithIntention ?? 0,
        timestamp: rest.timestamp ?? '',
        total: rest.total ?? 0,
        wishedDead: rest.wishedDead ?? 0,
      };
    });
}
