import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { classNameCombiner } from 'utils';
import React from 'react';

import styles from './Tag.module.scss';

type TagProps<T> = {
  index: number;
  tag: T;
  getTagLabel: (t: T) => string;
  onDelete?: (e: T) => void;
  disabled?: boolean;
  className?: string;
  iconClass?: string;
};

export function Tag<T>({
  tag,
  getTagLabel,
  onDelete,
  index,
  disabled = false,
}: TagProps<T>) {
  const _onClick = () => onDelete?.(tag);

  return (
    <div data-tag-index={index} className={styles.tag}>
      <span data-testid={`${getTagLabel(tag)} - label`}>
        {getTagLabel(tag)}
      </span>
      {!disabled && (
        <CancelIcon
          data-testid={`${getTagLabel(tag)} - delete`}
          className={styles.icon}
          onClick={_onClick}
        />
      )}
    </div>
  );
}

export function TagV2<T>({
  tag,
  getTagLabel,
  onDelete,
  index,
  disabled = false,
  className = '',
}: TagProps<T>) {
  const _onClick = () => onDelete?.(tag);

  return (
    <div
      data-tag-index={index}
      className={classNameCombiner([styles.tagV2, className])}
    >
      <span data-testid={`${getTagLabel(tag)} - label`}>
        {getTagLabel(tag)}
      </span>
      {!disabled && (
        <CloseIcon
          data-testid={`${getTagLabel(tag)} - delete`}
          className={styles.icon}
          onClick={_onClick}
        />
      )}
    </div>
  );
}
