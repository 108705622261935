import { useLazyQuery } from '@apollo/client';
import { GQL_TASK_CARD_POLLING_INTERVAL } from 'app/constants';
import {
  GetMemberSurvey,
  GetMemberSurveyVariables,
} from 'app/queries/generated/GetMemberSurvey';
import { getMemberSurvey } from 'app/queries/GetMemberSurvey';
import { useLogger } from 'app/state/log/useLogger';
import { PaginationInput, SortDirection } from 'generated/globalTypes';
import { useEffect } from 'react';
import { isGraphQLAuthenticationError } from 'shared-components/error-state/utils';

type Props = {
  enablePolling?: boolean;
  loadCSSRS?: boolean;
  loadGAD7s?: boolean;
  loadPHQ9s?: boolean;
  loadPSS?: boolean;
  loadQuestions?: boolean;
  loadRisc10s?: boolean;
  memberId: string;
  phqgadPaginationInput?: PaginationInput;
};
export const useMemberSurveys = (props: Props) => {
  const logger = useLogger();
  const {
    memberId,
    loadCSSRS = false,
    loadQuestions = false,
    loadGAD7s = false,
    loadRisc10s = false,
    loadPHQ9s = false,
    loadPSS = false,
    enablePolling = false,
    phqgadPaginationInput,
  } = props;

  const [
    runInitQuery,
    { data, error, loading, called, startPolling, stopPolling },
  ] = useLazyQuery<GetMemberSurvey, GetMemberSurveyVariables>(getMemberSurvey);

  useEffect(() => {
    const run = async () => {
      const result = await runInitQuery({
        fetchPolicy: 'network-only',
        variables: {
          input: { id: memberId },
          loadCSSRS,
          loadGAD7s,
          loadPHQ9s,
          loadPSS,
          loadQuestions,
          loadRisc10s,
          phqgadPaginationInput: phqgadPaginationInput ?? {
            maxItemsPerPage: 25,
            sortDirection: SortDirection.DESC,
          },
        },
      });
      // start polling only when we receive a successful response
      if (result.data && enablePolling)
        startPolling(GQL_TASK_CARD_POLLING_INTERVAL);
    };
    run().catch((reason) => {
      logger.error(
        new Error('useMemberSurveys::Failed to poll for new surveys'),
        { error: reason },
      );
    });
    return () => stopPolling();
  }, [memberId]);

  useEffect(() => {
    if (isGraphQLAuthenticationError(error)) stopPolling();
  }, [error]);

  return { called, data, error, loading };
};
