/** User state slice */
import { SnackbarNotification } from 'shared-components/Snackbar';

export type State = {
  readonly snackbarNotification: SnackbarNotification | null;
  readonly showSnackbar: boolean;
};

export function getInitialState(): State {
  return {
    showSnackbar: false,
    snackbarNotification: null,
  };
}
