import React, { useState } from 'react';
import { Button } from 'shared-components/button/Button';
import { Modal } from 'shared-components/modal/Modal';

import styles from './NotePDFPasswordModal.module.scss';

type Props = {
  password: string;
  onClose: () => void;
};

export function NotePDFPasswordModal({ password, onClose }: Props) {
  const [didClickCopy, setDidClickCopy] = useState(false);
  const copyBtnText = didClickCopy ? 'Copied!' : 'Copy';

  const onClickCopy = async () => {
    await navigator.clipboard.writeText(password);
    setDidClickCopy(true);
  };

  return (
    <Modal data-testid="pdf-password-modal" onButtonClick={onClose}>
      <>
        <h1>PDF Password</h1>
        <p>
          Provide this password to the person requesting the notes. They will
          not be able to open the PDF without it.
        </p>
        <div className={styles.passwordContainer}>
          <p data-testid="pdfPassword" className={styles.password}>
            {password}
          </p>
          <div className={styles.buttonContainer}>
            <Button
              onClick={onClickCopy}
              disabled={didClickCopy}
              fullWidth={false}
              size="small"
              variant="outlined"
            >
              {copyBtnText}
            </Button>
          </div>
        </div>
      </>
    </Modal>
  );
}
