import { PresenceEvent } from 'pubnub';

import { updateConversationPresence } from '../actions';
import { ConvoPresence } from '../schema';
import { ActionHandler } from './types';

export const onPresenceChange = async ({
  action,
  redux,
  context,
}: ActionHandler<{ envelope: PresenceEvent }>) => {
  const { logger } = context.services;
  const { envelope } = action.payload;
  const uuid = context.services.pubnub.getUUID();
  const { presenceByChannelId } = redux.getState().inbox;

  // If we find an occupant UUID that doesn't look like our own coach's UUID, we assume that is the
  // member/Caller's UUID, so we want to update the presence status on the chat conversation.
  // we also assumed that an undefined or null UUID was equivalent
  // to the member UUID.

  if (envelope.uuid && uuid && envelope.uuid.startsWith(uuid)) return;
  logger.debug('pubnub presence state changed', { envelope });
  let presence: ConvoPresence = {
    isOnline: false,
    isTyping: false,
    timerId: null,
  };

  if (presenceByChannelId[envelope.channel])
    presence = { ...presenceByChannelId[envelope.channel] };

  // message about the caller
  if (
    envelope.action === 'join' ||
    (envelope.action === 'state-change' &&
      envelope.state &&
      envelope.state.is_typing)
  ) {
    presence.isOnline = true;
  }
  if (envelope.action === 'leave' || envelope.action === 'timeout') {
    presence.isOnline = false;
  }
  if (envelope.action === 'state-change' && envelope.state) {
    presence.isTyping = envelope.state.is_typing;
    if (presence.timerId) {
      clearTimeout(presence.timerId);
      presence.timerId = null;
    }
    if (presence.isTyping) {
      const id = setTimeout(() => {
        const updated = { ...presence };
        updated.isTyping = false;
        updated.timerId = null;
        redux.dispatch(
          updateConversationPresence({
            presences: { [envelope.channel]: updated },
          }),
        );
      }, 60000);
      presence.timerId = (id as unknown) as number;
    }
  }
  redux.dispatch(
    updateConversationPresence({ presences: { [envelope.channel]: presence } }),
  );
};
