import { useState } from 'react';

type UseModalStateType = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

/**
 * Hook to manage modal state
 * This hook is used to manage the state of a modal,
 * so instead of having to manage the state in the parent component with multiple useState declarations,
 * we can use this hook.
 * @param initialState
 * @returns {UseModalStateType}
 * @example
 * const { isOpen, open, close } = useModalState()
 * <Modal open={isOpen} onClose={close} />
 * <Button onClick={open}>Open Modal</Button>
 * <Button onClick={close}>Close Modal</Button>
 * */
const useModalState = (initialState: boolean = false): UseModalStateType => {
  const [isOpen, setIsOpen] = useState(initialState);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return { close, isOpen, open };
};

export default useModalState;
