import { ReactComponent as StarIcon } from 'assets/star.svg';
import { CoachCareTeamRoleEnum, CoachShiftType } from 'generated/globalTypes';
import React from 'react';
import { capitalize } from 'utils';

import { Coach, temporaryCoachCareTeamRoles } from '../types';
import styles from './Row.module.scss';

export const coachShiftTypeToLabel = (shiftType: CoachShiftType | null) => {
  if (!shiftType) {
    return '';
  }

  // the CoachShiftType consists of a value containing Coach_ and a shift type (A, A1, A2, B, C, D)
  // we want to return the Coach and shift type only, ignoring the shift type number (if it exists) i.e. A1 -> A, B2 -> B

  // split the shift type into Coach_ and the shift type
  const shiftTypeArray = shiftType.split('_');

  // if the shift type array has a length of 2, it means it has a shift type, so we return the Coach and shift type i.e. Coach (A)
  // otherwise, we return the shift type as is (i.e. Coach)
  return shiftTypeArray.length === 2
    ? `Coach (${shiftTypeArray[1][0]})`
    : 'Coach';
};

export const coachCareTeamRoleToLabel = (
  careTeamRole: CoachCareTeamRoleEnum,
) => {
  if (!careTeamRole) {
    return '';
  }

  if (careTeamRole == CoachCareTeamRoleEnum.DROPIN) {
    return 'Drop-in';
  }
  return capitalize(careTeamRole).replace('_', ' ');
};

export const formatRoleContainerLabel = (coach: Coach) => {
  if (
    coach.careTeamRole &&
    temporaryCoachCareTeamRoles.has(coach.careTeamRole)
  ) {
    // if the coach is playing a temporary role, we want to display it instead of the role shift
    return coachCareTeamRoleToLabel(coach.careTeamRole);
  }
  // If the role is not temporary, we can give more information and display the shift of the coach
  return coachShiftTypeToLabel(coach.roleShift);
};

const CoachRow = (coach: Coach) => {
  return (
    <div data-testid={`coach-${coach.id}`}>
      <div className={styles.nameContainer}>
        <div>{coach.name}</div>
        {coach.isTeamLead && (
          <StarIcon
            data-testid={`coach-${coach.id}-team-lead`}
            title="Lead Coach"
            className={styles.leadCoachIcon}
          />
        )}
      </div>
      <div className={styles.roleContainer}>
        <div>{formatRoleContainerLabel(coach)}</div>
      </div>
      <div className={styles.specializationContainer}>
        {coach.specializations?.map((specialization) => (
          <div key={specialization} className={styles.specialization}>
            {specialization}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoachRow;
