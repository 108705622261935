import { ALLOWED_FIELDS } from 'app/services/constant';

export function scrubAndFlattenVariables(
  inputVariables: Record<string, any> | null | undefined,
  parent: string = '',
  outputVariables: Record<any, any> = {},
): Record<string, any> {
  if (!inputVariables) return {};
  // we only want to log non-phi fields in sentry, and we want to flatten the data to prevent Sentry from truncating nested
  for (const key in inputVariables) {
    const propName = parent ? `${parent}.${key}` : key;
    const value = inputVariables[key];
    if (value instanceof Array) {
      const newObj = value.reduce(
        (prev: Record<any, any>, curr: any, index: number) => ({
          ...prev,
          [index]: curr,
        }),
        {} as Record<any, any>,
      );
      scrubAndFlattenVariables(newObj, propName, outputVariables);
    } else if (typeof value === 'object') {
      scrubAndFlattenVariables(value, propName, outputVariables);
    } else {
      const keys = propName.split('.');
      const parentKey = keys.length > 1 ? keys[keys.length - 2] : null;
      let showValue = ALLOWED_FIELDS.has(key);
      if (/^[0-9]+$/.test(key) && parentKey) {
        showValue = ALLOWED_FIELDS.has(parentKey);
      }
      outputVariables[propName] = showValue
        ? value ?? 'null'
        : `<${typeof value}>`;
    }
  }
  return outputVariables;
}
