import { SentryClient } from 'app/services/sentry/SentryClient';
import { isLocal } from 'app/Stage';
import { AdditionalLogData } from 'app/state/log/actions';
import { ILogger, LogLevel, UserInfo } from 'app/state/log/Logger';
import memberChartStorage from 'app/state/member-tabs/memberChartStorage';

export class LoggerImpl implements ILogger {
  private user: UserInfo | undefined;

  constructor(
    private readonly sentry: SentryClient,
    private logLevel: LogLevel = LogLevel.INFO,
  ) {
    this.sentry = sentry;
  }

  setUser(user: UserInfo) {
    this.user = user;
  }

  debug(message: string, additionalData?: AdditionalLogData) {
    if (this.logLevel > LogLevel.DEBUG) return;

    const additionalDataWithContext = this.getAdditionalDataWithContext(
      additionalData,
    );

    if (isLocal()) {
      console.debug(`Logger (debug): `, message, additionalDataWithContext);
    } else {
      // Rapid7 doesn't have a `debug` method.
      R7Insight.info(message, { additionalData: additionalDataWithContext });
    }
  }

  info(message: string, additionalData?: AdditionalLogData) {
    if (this.logLevel > LogLevel.INFO) return;

    const additionalDataWithContext = this.getAdditionalDataWithContext(
      additionalData,
    );

    if (isLocal()) {
      console.info(`Logger (info): `, message, additionalDataWithContext);
    } else {
      R7Insight.info(message, { additionalData: additionalDataWithContext });
    }
  }

  warning(message: string, additionalData?: AdditionalLogData) {
    if (this.logLevel > LogLevel.WARNING) return;

    const additionalDataWithContext = this.getAdditionalDataWithContext(
      additionalData,
    );

    if (isLocal()) {
      console.warn(`Logger (warn): `, message, additionalDataWithContext);
    } else {
      R7Insight.warn(message, { additionalData: additionalDataWithContext });
    }
  }

  // TODO(Gaston): change signature to (message: string, additionalData?: AdditionalLogData) => void
  error(error: Error, additionalData?: AdditionalLogData) {
    if (this.logLevel > LogLevel.ERROR) return;

    const additionalDataWithContext = this.getAdditionalDataWithContext(
      additionalData,
    );

    if (isLocal()) {
      console.error('Logger (error): ', error.message, error, additionalData);
    } else {
      R7Insight.error(
        error.message,
        { error },
        { additionalData: additionalDataWithContext },
      );
      void this.sentry.captureException(error, additionalDataWithContext);
    }
  }

  setLogLevel(logLevel: LogLevel) {
    this.logLevel = logLevel;
  }

  log(logLevel: LogLevel, message: string, additionalData?: AdditionalLogData) {
    switch (logLevel) {
      case LogLevel.ERROR: {
        this.error(new Error(message), additionalData);
        break;
      }
      case LogLevel.WARNING: {
        this.warning(message, additionalData);
        break;
      }
      case LogLevel.DEBUG: {
        this.debug(message, additionalData);
        break;
      }
      default: {
        this.info(message, additionalData);
      }
    }
  }

  private getAdditionalDataWithContext(
    additionalData?: AdditionalLogData,
  ): AdditionalLogData {
    return {
      ...this.user,
      activeMemberId: memberChartStorage.get().activeMemberId ?? undefined,
      ...additionalData,
      careHubVersion: window.careHubVersion,
      path: window.location.pathname,
    };
  }
}
