import { SafetyConcern } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyConcern';
import { DomesticViolenceRisk } from 'app/notes-ui/shared/safety-progress/concerns/DomesticViolenceRisk';
import { EatingDisorderRisk } from 'app/notes-ui/shared/safety-progress/concerns/EatingDisorderRisk';
import { HighSubstanceRisk } from 'app/notes-ui/shared/safety-progress/concerns/HighSubstanceRisk';
import { HomicideRisk } from 'app/notes-ui/shared/safety-progress/concerns/HomicideRisk';
import { OtherConcerns } from 'app/notes-ui/shared/safety-progress/concerns/OtherConcerns';
import { SelfHarmRisk } from 'app/notes-ui/shared/safety-progress/concerns/SelfHarmRisk';
import { SuicideRisk } from 'app/notes-ui/shared/safety-progress/concerns/SuicideRisk';
import { VulnerablePopulationsAbuseRisk } from 'app/notes-ui/shared/safety-progress/concerns/VulnerablePopulationsAbuseRisk';

export const safetyConcernsMap = [
  { component: SuicideRisk, risk: SafetyConcern.suicide_risk },
  { component: SelfHarmRisk, risk: SafetyConcern.self_harm_risk },
  { component: HomicideRisk, risk: SafetyConcern.homicide_risk },
  {
    component: DomesticViolenceRisk,
    risk: SafetyConcern.domestic_violence_risk,
  },
  { component: HighSubstanceRisk, risk: SafetyConcern.substance_use_risk },
  { component: EatingDisorderRisk, risk: SafetyConcern.eating_disorder_risk },
  {
    component: VulnerablePopulationsAbuseRisk,
    risk: SafetyConcern.abuse_of_vulnerable_risk,
  },
  { component: OtherConcerns, risk: SafetyConcern.other_risks },
];
