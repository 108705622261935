import { useQuery } from '@apollo/client';
import { Field } from '@ginger.io/react-use-form/dist/types';
import { Result } from 'shared-components/autocomplete-search/AutoCompleteSearch';
import { AutoCompleteSearchField } from 'shared-components/autocomplete-search/AutoCompleteSearchField';
import gql from 'graphql-tag';
import React, { useState } from 'react';

import { ClinicianAutoCompleteType } from '../constants';
import {
  GetClinicians,
  GetCliniciansVariables,
} from './generated/GetClinicians';

export const getCliniciansForMemberQuery = gql`
  query GetClinicians($input: GetCliniciansInput!) {
    getClinicians(input: $input) {
      clinicians {
        id
        fullName
        onClinicalCareTeamOfMember
      }
    }
  }
`;

type ClinicianSearchFieldContainerProps = {
  clinicianIdField: Field<string>;
  clinicianGroupField?: Field<string>;
  memberId: string;
};

export function ClinicianSearchFieldContainer(
  props: ClinicianSearchFieldContainerProps,
) {
  const { clinicianIdField, clinicianGroupField, memberId } = props;
  const [searchQuery, setSearchQuery] = useState(clinicianIdField.value ?? '');

  const { error, data, loading } = useQuery<
    GetClinicians,
    GetCliniciansVariables
  >(getCliniciansForMemberQuery, {
    variables: { input: { filters: { fullName: searchQuery, memberId } } },
  });

  let results: Result[] = [];
  const clinicians = data?.getClinicians?.clinicians;
  if (clinicians) {
    const hasLastClinicians = clinicians?.some(
      (m) => m.onClinicalCareTeamOfMember,
    );
    results = clinicians.map((m) => {
      let groupBy = '';
      if (hasLastClinicians) {
        groupBy = m.onClinicalCareTeamOfMember
          ? ClinicianAutoCompleteType.PREVIOUS_CLINICIAN
          : ClinicianAutoCompleteType.OTHER_CLINICIAN;
      }
      return {
        display: m.fullName ?? m.id,
        groupBy,
        id: m.id,
        queryOn: m.fullName ?? m.id,
      };
    });
  }

  results.sort((a, b) => {
    if (a.groupBy && b.groupBy) {
      return b.groupBy.localeCompare(a.groupBy);
    }
    return 0;
  });

  return (
    <AutoCompleteSearchField
      idField={clinicianIdField}
      groupField={clinicianGroupField}
      error={error?.message}
      searchFieldProps={{
        loading,
        placeholder: '🔍  Search clinician name',
        results,
        searchCallback: setSearchQuery,
        testId: 'clinician-search-autocomplete',
      }}
    />
  );
}
