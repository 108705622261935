import {
  CoachClinicianCollaborationChatMessage,
  CoachClinicianCollaborationChatMessage_Author_AuthorType as AuthorType,
  CoachClinicianCollaborationChatMessage_GeneratedFrom,
  CoachClinicianCollaborationChatMessage_Version,
} from '@ginger.io/vault-care-collaboration/dist/generated/protobuf-schemas/vault-care-collaboration/CoachClinicianCollaborationChatMessage';
import { getVaultWebUserId } from '@ginger.io/vault-core/dist/IdHelpers';
import { AddMemberSupportModal } from 'app/care-team/add-member-support-modal/AddMemberSupportModal';
import { MemberSupportOperationType } from 'app/care-team/add-member-support-modal/enums';
import { AddSICoachButton } from 'app/care-team/add-si-coach-button/AddSICoachButton';
import { AddSICoachModal } from 'app/care-team/add-si-coach-modal/AddSICoachModal';
import styles from 'app/care-team/care-team-v2/CareTeamV2.module.scss';
import { EscalateTeenMemberToClinicalModal } from 'app/care-team/escalate-teen-to-clinical-modal/EscalateTeenMemberToClinicalModal';
import { EscalateMemberToClinicalModal } from 'app/care-team/escalate-to-clinical-modal/EscalateMemberToClinicalModal';
import { InlineNotification } from 'app/care-team/notifications/InlineNotification';
import { useCollaborationChatStream } from 'app/collaboration/useCollaborationChatStream';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import useElementWidth from 'hooks/useElementWidth';
import { useAppState } from 'app/state';
import { loadCareTeam } from 'app/state/care-team/actions';
import { selectCareTeamButtonState } from 'app/state/care-team/selectors';
import {
  classNameCombiner,
  isClinicianOrSupervisor,
  isCoachOrSupervisor,
} from 'utils';
import { ReactComponent as Checkmark } from 'assets/Checkmark.svg';
import useModalState from 'hooks/useModalState';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'shared-components/button/Button';

const MAX_BUTTONS_CONTAINER_WIDTH = 580;

function CareTeamV2Button({ memberId }: { memberId: string }) {
  const dispatch = useDispatch();
  const { role, userId, firstName, lastName } = useAppState(
    ({ user: { role, userId, firstName, lastName } }) => ({
      firstName,
      lastName,
      role,
      userId,
    }),
  );
  const isCoach = isCoachOrSupervisor(role);
  const {
    isOpen: isAddSICoachModalOpen,
    close: closeAddSICoachModal,
    open: openAddSICoachModal,
  } = useModalState(false);
  const {
    enable_add_si_coach_button,
    enable_add_member_support_button,
    enable_escalate_to_clinical_button,
  } = useFeatureFlags().transientFeatureFlags;
  const {
    isOpen: isAddMemberSupportModalOpen,
    close: closeAddMemberSupportModal,
    open: openAddMemberSupportModal,
  } = useModalState(false);
  const {
    isOpen: isEscalateToClinicalModalOpen,
    close: closeEscalateToClinicalModal,
    open: openEscalateToClinicalModal,
  } = useModalState(false);
  const {
    isOpen: isEscalateTeenToClinicalModalOpen,
    close: closeEscalateTeenToClinicalModal,
    open: openEscalateTeenToClinicalModal,
  } = useModalState(false);
  const [escalatedToClinical, setEscalatedToClinical] = useState(false);
  const [memberSupportAdded, setMemberSupportAdded] = useState(false);
  const [memberSupportPinged, setMemberSupportPinged] = useState(false);

  // care team card redux states
  const {
    riskStatus,
    isTeen,
    hasSICoach,
    hasMS,
    hasIntakeSent,
    hasClinical,
  } = useAppState(selectCareTeamButtonState);
  const authorType = isClinicianOrSupervisor(role)
    ? AuthorType.clinician
    : AuthorType.coach;
  const author = {
    firstName: firstName!,
    id: getVaultWebUserId(userId!),
    lastName: lastName!,
    type: authorType,
  };

  const chatStream = useCollaborationChatStream(memberId, author);

  const publishMessageToTeamCommunicationCard = async (message: string) => {
    const chatMessage: CoachClinicianCollaborationChatMessage = {
      author,
      body: message,
      createdAt: moment.utc().toISOString(),
      generatedFrom:
        CoachClinicianCollaborationChatMessage_GeneratedFrom.si_transfer,
      generatedFromVaultItemId: '',
      memberId,
      requireTimelyReview: false,
      subject: '',
      version: CoachClinicianCollaborationChatMessage_Version.v0,
    };

    await chatStream.createChatMessage(chatMessage);
  };

  const onSICoachAdded = async (optionalDetails: string) => {
    // hide modal
    closeAddSICoachModal();

    // trigger a refresh of the care team card to show the added SI coach
    dispatch(loadCareTeam({ forceRefresh: true, memberId }));

    if (optionalDetails !== '') {
      // publish the message to the team communication card
      void publishMessageToTeamCommunicationCard(optionalDetails);
    }
  };

  const onAddMemberSupportAdded = () => {
    closeAddMemberSupportModal();
    // trigger a refresh of the care team card to show the added MS coach
    dispatch(loadCareTeam({ forceRefresh: true, memberId }));
    setMemberSupportAdded(true);
  };

  const onPingMemberSupport = () => {
    closeAddMemberSupportModal();
    // trigger a refresh of the care team card to show the added MS coach
    dispatch(loadCareTeam({ forceRefresh: true, memberId }));
    setMemberSupportPinged(true);
    setMemberSupportAdded(false);
  };

  const onEscalateToClinicalAdded = () => {
    closeEscalateToClinicalModal();
    dispatch(loadCareTeam({ forceRefresh: true, memberId }));
    setEscalatedToClinical(true);
  };

  const onEscalateTeenToClinicalAdded = () => {
    closeEscalateTeenToClinicalModal();
    setEscalatedToClinical(true);
  };
  // refresh care team card component width during component mount
  // this is needed to calculate the width of the action buttons
  const {
    elementWidth: careTeamButtonElementWidth,
    elementRef: careTeamButtonContainerRef,
    refreshElementWidth,
  } = useElementWidth();
  useEffect(() => {
    refreshElementWidth();
  }, [refreshElementWidth]);

  const showAddMemberSupportButton =
    isCoach && enable_add_member_support_button;
  const memberOnRiskAlert = riskStatus?.onRiskAlert ?? false;
  const showAddSICoachButton =
    isCoach && enable_add_si_coach_button && !hasSICoach && memberOnRiskAlert;
  const showEscalateToClinicalButton =
    isCoach &&
    enable_escalate_to_clinical_button &&
    !hasClinical &&
    !escalatedToClinical &&
    !hasIntakeSent;
  const showEscalateToClinicalTooltip =
    isCoach &&
    enable_escalate_to_clinical_button &&
    (escalatedToClinical || hasIntakeSent);
  const addMemberSupportOperationType = hasMS
    ? MemberSupportOperationType.PING
    : MemberSupportOperationType.ADD;

  return (
    <>
      <AddSICoachModal
        open={isAddSICoachModalOpen}
        onCancel={closeAddSICoachModal}
        memberId={memberId}
        onSICoachAdded={onSICoachAdded}
      />

      <AddMemberSupportModal
        open={isAddMemberSupportModalOpen}
        onCancel={closeAddMemberSupportModal}
        memberId={memberId}
        onMemberSupportAdded={
          addMemberSupportOperationType === MemberSupportOperationType.ADD
            ? onAddMemberSupportAdded
            : onPingMemberSupport
        }
        operationType={addMemberSupportOperationType}
      />
      <EscalateMemberToClinicalModal
        open={isEscalateToClinicalModalOpen}
        onCancel={closeEscalateToClinicalModal}
        memberId={memberId}
        onEscalateToClinical={onEscalateToClinicalAdded}
      />

      <EscalateTeenMemberToClinicalModal
        open={isEscalateTeenToClinicalModalOpen}
        onCancel={closeEscalateTeenToClinicalModal}
        memberId={memberId}
        onEscalateToClinical={onEscalateTeenToClinicalAdded}
      />
      <div
        className={styles.careTeamButtonContainer}
        ref={careTeamButtonContainerRef}
      >
        {showAddMemberSupportButton && (
          <div
            className={classNameCombiner([
              styles.careTeamButton,
              careTeamButtonElementWidth < MAX_BUTTONS_CONTAINER_WIDTH
                ? styles.fullWidth
                : '',
            ])}
          >
            <Button onClick={openAddMemberSupportModal}>
              {hasMS ? 'Ping Member Support' : 'Add Member Support'}
            </Button>
          </div>
        )}
        {showAddSICoachButton && (
          <div
            className={classNameCombiner([
              styles.careTeamButton,
              careTeamButtonElementWidth < MAX_BUTTONS_CONTAINER_WIDTH
                ? styles.fullWidth
                : '',
            ])}
          >
            <AddSICoachButton
              onClick={openAddSICoachModal}
              memberId={memberId}
            />
          </div>
        )}
        {showEscalateToClinicalButton && (
          <div
            className={classNameCombiner([
              styles.careTeamButton,
              careTeamButtonElementWidth < MAX_BUTTONS_CONTAINER_WIDTH
                ? styles.fullWidth
                : '',
            ])}
          >
            <Button
              testId="escalateToClinicalBtn"
              onClick={
                isTeen
                  ? openEscalateTeenToClinicalModal
                  : openEscalateToClinicalModal
              }
            >
              Escalate to Clinical
            </Button>
          </div>
        )}
      </div>
      {showEscalateToClinicalTooltip && (
        <InlineNotification
          testId="escalatedToClinicalTooltip"
          icon={<Checkmark />}
        >
          Escalated to clinical
        </InlineNotification>
      )}
      {memberSupportAdded && (
        <InlineNotification icon={<Checkmark />}>
          Member Support Added
        </InlineNotification>
      )}
      {memberSupportPinged && (
        <InlineNotification icon={<Checkmark />}>
          Member Support Pinged
        </InlineNotification>
      )}
    </>
  );
}
export default React.memo(CareTeamV2Button);
