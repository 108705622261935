import { Alert, Button as MuiButton, Grid, Typography } from '@mui/material';
import React, { ReactNode, useState } from 'react';

import { AppointmentButtons } from './AppointmentButtons';
import styles from './AppointmentScreen.module.scss';
import { AppointmentIsInThePastWarning } from './modals/AppointmentIsInThePastWarning';
import { AvailabilityDoesNotExistWarning } from './modals/AvailabilityDoesNotExistWarning';
import { MissingCreditCardWarning } from './modals/MissingCreditCardWarning';
import { NoEAPSessionsWarning } from './modals/NoEAPSessionsWarning';
import { PreviousClinicianWarning } from './modals/PreviousClinicianWarning';
import { ActionBar } from './ScheduleActionBar';

export enum AvailableModalsEnum {
  CLINICIAN_AVAILABILITY = 1,
  APPOINTMENT_IN_PAST = 2,
  MISSING_CREDIT_CARD = 3,
  NO_EAP_SESSIONS = 4,
  PREVIOUS_CLINICIAN = 5,
}

type ActiveModals = {
  modal: AvailableModalsEnum;
  onConfirm: (status: boolean) => void;
  isOpen: boolean;
};

type AppointmentControlsProps = {
  onSave: () => Promise<void>;
  onClose: () => void;
  children: ReactNode;
  onModalConfirm?: () => Promise<void>;
  onModalClose?: () => void;
  activeModals?: Array<ActiveModals>;

  /**
   * @property Disables the save button
   */
  saveDisabled?: boolean;

  /**
   * @property this error message will persist at the top with an alert bubble
   * This is different from the submission error messages using SnackNotification
   * ex: This is currently only used for displaying warning message for clinicians
   *     that the member is out of sessions and has no card on file
   */
  error?: string;
};

export function AppointmentControls(props: AppointmentControlsProps) {
  const {
    onSave,
    onClose,
    children,
    saveDisabled,
    error,
    onModalClose,
    onModalConfirm,
    activeModals,
  } = props;
  const [submitting, setSubmitting] = useState(false);

  const clinicianAvailabilityModal = activeModals?.find(
    (o) => o.modal === AvailableModalsEnum.CLINICIAN_AVAILABILITY,
  );
  const appointmentInPastModal = activeModals?.find(
    (o) => o.modal === AvailableModalsEnum.APPOINTMENT_IN_PAST,
  );
  const missingCreditCardModal = activeModals?.find(
    (o) => o.modal === AvailableModalsEnum.MISSING_CREDIT_CARD,
  );
  const noEapSessionsModal = activeModals?.find(
    (o) => o.modal === AvailableModalsEnum.NO_EAP_SESSIONS,
  );
  const previousClinicianModal = activeModals?.find(
    (o) => o.modal === AvailableModalsEnum.PREVIOUS_CLINICIAN,
  );

  async function onSaveWrapper() {
    setSubmitting(true);
    try {
      await onSave();
    } finally {
      setSubmitting(false);
    }
  }

  const onModalConfirmWrapper = async () => {
    if (!onModalConfirm) return;
    try {
      setSubmitting(true);

      if (activeModals) {
        const activeModalsCount = activeModals.filter((modal) => modal.isOpen)
          .length;
        const modal = activeModals.find((modal) => modal.isOpen);
        if (modal) {
          modal.onConfirm(false);
          // we can't call onUpdate if there's more than 1 modal active
          if (activeModalsCount > 1) {
            return;
          }
        }
      }
      await onModalConfirm();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <AppointmentIsInThePastWarning
        isOpen={appointmentInPastModal?.isOpen ?? false}
        onClick={onModalConfirmWrapper}
        onClose={onModalClose}
      />
      <AvailabilityDoesNotExistWarning
        isOpen={clinicianAvailabilityModal?.isOpen ?? false}
        onClick={onModalConfirmWrapper}
        onClose={onModalClose}
      />
      <MissingCreditCardWarning
        onClick={onModalConfirmWrapper}
        onClose={onModalClose}
        isOpen={missingCreditCardModal?.isOpen ?? false}
      />
      <PreviousClinicianWarning
        onClick={onModalConfirmWrapper}
        onClose={onModalClose}
        isOpen={previousClinicianModal?.isOpen ?? false}
      />
      <NoEAPSessionsWarning
        onClick={onModalConfirmWrapper}
        onClose={onModalClose}
        isOpen={noEapSessionsModal?.isOpen ?? false}
      />

      <Grid container={true} className={styles.root} data-testid="Appointment">
        <div className={styles.appointment}>
          <Grid item={true} xs={1}>
            <MuiButton
              className={styles.close}
              startIcon="X"
              onClick={onClose}
            />
          </Grid>
          <Grid item={true} xs={11}>
            <ActionBar
              leftElement={<Typography variant="h1">Appointment</Typography>}
              rightElement={
                <AppointmentButtons
                  onSave={onSaveWrapper}
                  loading={submitting}
                  saveDisabled={saveDisabled}
                />
              }
            />

            {submitting && (
              <Alert className={styles.alert} severity="info">
                Saving data. This can take a while. Please stand by...
              </Alert>
            )}

            {error && (
              <Alert
                className={styles.alert}
                severity="error"
                data-testid="appointment-control-error"
              >
                {error}
              </Alert>
            )}
            {children}
          </Grid>
        </div>
      </Grid>
    </>
  );
}
