import Labels from 'app/member-chart-cards/constants/member-chart-card.json';
import React, { ReactElement } from 'react';
import { GridCellProps } from 'shared-components/grid-layout/GridCell';
import { TooltipWithRedux } from 'shared-components/tooltip/TooltipWithRedux';

import { FieldLabel } from './FieldLabel';
import styles from './styles/CardField.module.scss';

type CardField = {
  label: string;
  labelForAnalytics: string;
  value?: ReactElement | string | number | null;
  placeholder?: string;
  markdown?: boolean;
  'data-testid'?: string;
};

export type LastUpdatedField = {
  timestamp: string;
  role: string;
  name: string;
};

export type CardFieldAndGridCell = CardField & GridCellProps;

export const CardField = ({
  field,
  lastUpdated,
  hideTooltip,
  placeholder = Labels.EMPTY_VALUE_PLACEHOLDER,
}: {
  field: CardField;
  lastUpdated?: LastUpdatedField;
  hideTooltip?: boolean;
  placeholder?: string;
}): React.ReactElement => {
  const tooltipTextForFilledInField = lastUpdated
    ? `${lastUpdated.timestamp} | ${lastUpdated.role} ${lastUpdated.name} last edited`
    : '';
  const tooltipText = field.value
    ? tooltipTextForFilledInField
    : Labels.TOOLTIP_NOT_COMPLETED_FIELDS;
  const fieldValue = field.value ? field.value : placeholder;

  return (
    <div data-testid={field['data-testid']} className={styles.cardField}>
      {field.label && !field.markdown && <FieldLabel labelText={field.label} />}
      {field.markdown && React.isValidElement(fieldValue) ? (
        <div className={styles.cardFieldValue}>{fieldValue}</div>
      ) : (
        <TooltipWithRedux
          labelForAnalytics={field.labelForAnalytics}
          title={tooltipText}
          placement="bottom-start"
          disableHoverListener={hideTooltip}
        >
          <p
            className={
              field.value
                ? styles.cardFieldValue
                : `${styles.cardFieldValue} ${styles.emptyCardFieldValue}`
            }
          >
            {fieldValue}
          </p>
        </TooltipWithRedux>
      )}
    </div>
  );
};
