import { VaultMasterKeys } from '@ginger.io/vault-core/dist/crypto/UserMasterKeySet';
import { Formatters, Services } from 'app/services';
import { AppDispatch, RootState } from 'app/state/hooks/baseTypedHooks';
import { RejectValue } from 'app/state/middlewares/types';

export enum SessionState {
  NOT_INITIALIZED = 'NOT_INITIALIZED',
  VALID = 'VALID',
  EXPIRED = 'EXPIRED',
}
export interface IAuthSlice {
  loggedIntoVault: boolean;
  sessionState: SessionState;
  vaultAuthChallenge: string | null;
  vaultJWTToken: string | null;
  vaultMasterKeys: VaultMasterKeys | null;
}

export type AuthAsyncThunkAPI = {
  dispatch: AppDispatch;
  extra: {
    services: Services;
    formatters: Formatters;
  };
  rejectValue: RejectValue;
  state: RootState;
};
