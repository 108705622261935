import React from 'react';
import { TooltipWithRedux } from 'shared-components/tooltip/TooltipWithRedux';
import { fromUTCToLocal } from 'utils/dateTime';

export type CareMemberTooltipProps = {
  id: string;
  name: string;
  nextScheduledSession?: string;
  lastSession?: string;
  lastMessageExchanged?: string;
  totalMessagesExchanged?: number;
  totalCompletedSessions?: number | null;
  previousAppointmentDate?: string | null;
  nextAppointmentDate?: string | null;
  timezone: string;
  children: React.ReactElement;
};

export const TooltipTitle = (props: CareMemberTooltipProps) => {
  const {
    nextScheduledSession,
    lastSession,
    lastMessageExchanged,
    totalMessagesExchanged,
    totalCompletedSessions,
    nextAppointmentDate,
    previousAppointmentDate,
    timezone,
  } = props;

  return (
    <div>
      {nextScheduledSession !== undefined && (
        <div data-testid="next-scheduled-session">
          <strong>Next scheduled session: </strong>
          {nextScheduledSession
            ? fromUTCToLocal(nextScheduledSession, timezone).calendar()
            : 'N/A'}
        </div>
      )}
      {lastSession !== undefined && (
        <div data-testid="last-session">
          <strong>Last session: </strong>
          {lastSession
            ? fromUTCToLocal(lastSession, timezone).calendar()
            : 'N/A'}
        </div>
      )}
      {lastMessageExchanged !== undefined && (
        <div data-testid="last-message-exchanged">
          <strong>Last message exchanged: </strong>
          {lastMessageExchanged
            ? fromUTCToLocal(lastMessageExchanged, timezone).calendar()
            : 'N/A'}
        </div>
      )}
      {totalMessagesExchanged !== undefined && (
        <div data-testid="total-messages-exchanged">
          <strong>Total messages exchanged: </strong>
          {totalMessagesExchanged ?? 0}
        </div>
      )}
      {totalCompletedSessions !== undefined && (
        <div data-testid="total-completed-sessions">
          <strong>Completed sessions: </strong>
          {totalCompletedSessions ?? 'N/A'}
        </div>
      )}
      {nextAppointmentDate !== undefined && (
        <div data-testid="next-appointment-date">
          <strong>Next scheduled session: </strong>
          {nextAppointmentDate
            ? fromUTCToLocal(nextAppointmentDate, timezone).calendar()
            : 'N/A'}
        </div>
      )}
      {previousAppointmentDate !== undefined && (
        <div data-testid="previous-appointment-date">
          <strong>Last session: </strong>
          {previousAppointmentDate
            ? fromUTCToLocal(previousAppointmentDate, timezone).calendar()
            : 'N/A'}
        </div>
      )}
    </div>
  );
};

export function CareMemberTooltip(props: CareMemberTooltipProps) {
  const { id, name, children } = props;

  return (
    <TooltipWithRedux
      key={`careMember_tooltip_${id}`}
      title={<TooltipTitle {...props} />}
      labelForAnalytics={`Care Team Member: Member details for ${id} - ${name}`}
    >
      {children}
    </TooltipWithRedux>
  );
}
