import moment from 'moment';

export const formatYesNoValue = (item: unknown): string => {
  switch (item) {
    case true:
      return 'Yes';
    case false:
      return 'No';
    default:
      return item ? `${item}` : 'N/A';
  }
};

export const formatDate = (dateString?: string | null) => {
  return dateString ? moment(dateString).format('MMM DD, YYYY') : '';
};
