import { useAppState } from 'app/state/index';

import { State } from '../schema';

export const selectHomeworkAssignmentStatusByChannel = (channelId: string) => {
  return useAppState((state: State) => {
    const {
      content: { homeworkAssignmentStatus },
    } = state;
    return channelId in homeworkAssignmentStatus
      ? homeworkAssignmentStatus[channelId]
      : null;
  });
};
