import { Link, SvgIcon, TableCell, Typography } from '@mui/material';
import { ReactComponent as LockedIcon } from 'app/appointments/table/icons/Lock.svg';
import clinicalNoteCardStyles from 'app/appointments/table/NoteCard.module.scss';
import { ZoomButton } from 'app/appointments/ZoomButton';
import {
  CoachNoteSessionType,
  Details,
  NotesItemResponse as CoachNotes,
} from 'app/coach/coach-notes/CoachNotesTypes';
import { BulletSeparator } from 'shared-components/BulletSeparator';
import { GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments as Appointments } from 'app/patients/generated/GetClinicalAppointmentsForMember';
import { classNameCombiner } from 'utils';
import React from 'react';

import { ReactComponent as DraftIcon } from '../icons/draftIcon.svg';
import styles from './NoteTableCard.module.scss';
import { TagAndRole } from './TagAndRole';

export type NoteOrAppointmentType = Appointments | CoachNotes;

export const NoteTableCard = (props: {
  note: NoteOrAppointmentType;
  colSpan?: number;
  dateString?: ISODateString;
  startDate?: ISODateString;
  className?: string;
  onClick?: (arg0: NoteOrAppointmentType) => void;
  careTeamMember?: string | null;
  details?: Details;
  dataTestId?: string;
  showZoomButton?: boolean;
  showDraftIcon?: boolean;
  showClinicalNotesView?: boolean;
}) => {
  const {
    note,
    dateString,
    startDate,
    careTeamMember,
    details,
    dataTestId,
    className = '',
    showZoomButton,
    showDraftIcon,
    showClinicalNotesView,
    onClick,
  } = props;
  const appointment = (note as Appointments) || {};
  const { role, session, titleAndSessionNumber, risks } = details || {};
  const isOutreachAttempt = session === CoachNoteSessionType.OUTREACH_ATTEMPT;
  const isRiskAssessment = session === CoachNoteSessionType.RISK;

  const _onClick = () => onClick?.(note);

  return (
    <>
      <TableCell
        data-testid={dataTestId}
        className={classNameCombiner([className])}
        onClick={!showClinicalNotesView ? _onClick : () => {}}
      >
        <article
          className={styles.row}
          data-testid={`table-card-row-${note.id}-card-type`}
        >
          {session && (
            <Typography
              data-testid="session-and-zoom"
              component="div"
              className={classNameCombiner([
                styles.sessionType,
                isOutreachAttempt ? styles.riskAssessment : '',
              ])}
            >
              <div className={styles.titleAndSessionContainer}>
                {isOutreachAttempt && <BulletSeparator>Risk</BulletSeparator>}
                {!isRiskAssessment && (
                  <BulletSeparator
                    className={classNameCombiner([
                      isOutreachAttempt ? styles.subtitle : '',
                    ])}
                  >
                    {session}
                  </BulletSeparator>
                )}{' '}
                {titleAndSessionNumber && (
                  <BulletSeparator className={styles.subtitle}>
                    {titleAndSessionNumber}
                  </BulletSeparator>
                )}
              </div>
              {!isRiskAssessment && showDraftIcon && (
                <DraftIcon className={styles.draftIcon} />
              )}
              {appointment.zoomMeetingId && showZoomButton && (
                <ZoomButton
                  label="Join"
                  appointmentId={appointment.id}
                  meetingId={appointment.zoomMeetingId!}
                />
              )}
            </Typography>
          )}
          {risks && (
            <Typography
              data-testid="risks"
              component="div"
              className={styles.riskAssessment}
            >
              <BulletSeparator
                className={classNameCombiner([
                  styles.subtitle,
                  styles.riskSubtitle,
                ])}
                showBullet={false}
                withMarkdown={true}
              >
                {`**Risk** • ${risks}`}
              </BulletSeparator>
              {isRiskAssessment && showDraftIcon && (
                <DraftIcon className={styles.draftIcon} />
              )}
            </Typography>
          )}
          <div
            data-testid={`date-info-${note.id}`}
            className={styles.dateContainer}
          >
            {startDate && (
              <BulletSeparator className={styles.date}>
                {startDate}
              </BulletSeparator>
            )}
            {dateString && (
              <BulletSeparator className={styles.date}>
                {dateString}
              </BulletSeparator>
            )}
          </div>
          <TagAndRole role={role} careTeamMember={careTeamMember} />
        </article>
      </TableCell>
      {showClinicalNotesView && (
        <>
          <TableCell />
          <TableCell>
            <article className={clinicalNoteCardStyles.statusColumn}>
              <Typography
                data-testid="shared-coach-note-status"
                component="div"
                className={clinicalNoteCardStyles.status}
              >
                <span className={clinicalNoteCardStyles.icon}>
                  <SvgIcon viewBox="0 0 32 32" fontSize="small">
                    <LockedIcon />
                  </SvgIcon>
                </span>
                <span
                  className={clinicalNoteCardStyles.iconLabel}
                  data-testid="shared-coach-note-status-formatted"
                >
                  Signed & Locked
                </span>
              </Typography>

              <Typography
                component="div"
                className={clinicalNoteCardStyles.link}
              >
                <Link
                  data-testid="shared-coach-note-status-action-link"
                  className={clinicalNoteCardStyles.capitalize}
                  onClick={_onClick}
                >
                  view note
                </Link>
              </Typography>
            </article>
          </TableCell>
        </>
      )}
    </>
  );
};
