import MUITable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import React, { ReactElement } from 'react';

import { V2TableBodyProps as TableBodyProps } from './V2TableBody';
import { Props as TableHeadProps } from './V2TableHead';

type Props = {
  children: [ReactElement<TableHeadProps>, ReactElement<TableBodyProps>];
  className?: string;
  stickyHeader?: boolean;
  dataTestId?: string;
};

/** Wraps Material UI's Table with a type-safe API where the caller has to pass a tuple-2 of <TableHead ../>, <TableBody .../> */
export function V2Table(props: Props) {
  const { dataTestId, children, className, stickyHeader } = props;
  const [head, body] = children;

  return (
    <TableContainer className={className} data-testid={dataTestId}>
      <MUITable
        data-testid="table"
        aria-labelledby="table"
        aria-label="table"
        stickyHeader={stickyHeader}
      >
        {head}
        {body}
      </MUITable>
    </TableContainer>
  );
}
