import EmptyStateIcon from 'assets/chat/empty-state-screen-icon.png';
import React from 'react';

import styles from './CommunicationEmptyState.module.scss';
import { Labels } from './constants';

export function CommunicationEmptyState() {
  return (
    <div className={styles.container}>
      <img
        src={EmptyStateIcon}
        className={styles.img}
        alt="empty state screen icon"
      />
      <p className={styles.text}>{Labels.emptyState}</p>
    </div>
  );
}
