import { ApolloError, isApolloError } from '@apollo/client';
import { ServerError } from '@apollo/client/link/utils';
import {
  isGraphQLError,
  isServerError,
} from 'shared-components/error-state/utils';
import { GraphQLError } from 'graphql';
import errorsTable_en_US from 'i18n/apps/appointments/Errors/en_US.json';
import { DEFAULT_LOCALE, LocaleName } from 'i18n/locales';

export class CustomError<T extends Record<any, any> = any> extends Error {
  constructor(
    public message: string,
    public readonly code: string,
    public readonly data?: T,
  ) {
    super(message);
  }
}

const errorsForLocale: Record<LocaleName, Record<string, string>> = {
  [LocaleName.EN_US]: errorsTable_en_US,
};

function getGraphQLErrorMessage(
  graphQLError: GraphQLError,
  locale: LocaleName,
): string {
  const errorCode = graphQLError.extensions?.code;
  let errorDisplayMessage = graphQLError.message;

  if (errorCode) {
    const errorMessageTable =
      errorsForLocale[locale] ?? errorsForLocale[DEFAULT_LOCALE];

    errorDisplayMessage =
      errorMessageTable[errorCode as string] ?? graphQLError.message;
  }
  return errorDisplayMessage;
}

/**
 * Returns the error code from an exception captured by Apollo-related error handling.
 */
export function getErrorCode(
  exception: string | ApolloError | Error,
): string | undefined {
  if (exception instanceof CustomError) {
    return exception.code;
  }

  if (typeof exception === 'object') {
    const apolloError = exception as ApolloError;
    const [serverError] =
      (apolloError.networkError as ServerError)?.result?.errors ?? [];
    const [firstGraphQLError] = apolloError.graphQLErrors ?? [];

    return (
      firstGraphQLError?.extensions?.code ||
      serverError?.extensions?.code ||
      undefined
    );
  }

  return undefined;
}

export function getErrorMessage(
  exception: string | ApolloError | Error,
  localeName: LocaleName = DEFAULT_LOCALE,
): string {
  let errorMessage: string | undefined;

  if (exception instanceof CustomError) {
    const errorMessageTable =
      errorsForLocale[localeName] ?? errorsForLocale[DEFAULT_LOCALE];
    return errorMessageTable[exception.code] ?? exception.message;
  }

  if (typeof exception === 'object') {
    const apolloError = exception as ApolloError;
    const [serverError] =
      (apolloError.networkError as ServerError)?.result?.errors ?? [];
    const [firstGraphQLError] = apolloError.graphQLErrors ?? [];

    if (firstGraphQLError) {
      errorMessage = getGraphQLErrorMessage(firstGraphQLError, localeName);
    } else if (serverError) {
      errorMessage = getGraphQLErrorMessage(serverError, localeName);
    } else {
      errorMessage = apolloError.message;
    }
  }
  if (!errorMessage) {
    errorMessage = exception as string;
  }
  return errorMessage;
}

export function getErrorData(
  exception: string | ApolloError | Error,
): Record<string, any> | undefined {
  if (exception instanceof CustomError) {
    return exception.data;
  }

  return undefined;
}

export function getErrorDataForClipboard(error: any): string {
  if (!error) return '';
  if (error instanceof CustomError) {
    const { code, data, message } = error;
    return `Message: ${message}\nCode: ${code}\nAdditional Data:\n${getErrorDataForClipboard(
      data,
    )}`;
  }

  if (isGraphQLError(error)) {
    const { extensions, path } = error;
    const code = extensions?.code;
    return `Message: "${getGraphQLErrorMessage(
      error,
      DEFAULT_LOCALE,
    )}", Code: ${code}, Path: ${path?.join(' >') ?? 'N/A'}`;
  }
  if (isServerError(error)) {
    const { result, statusCode, message } = error;
    const errors = result?.errors ?? [];
    const code = result.extensions?.code;
    return `Message: ${message}, StatusCode: ${statusCode}, Code: ${code}, Error: ${errors?.join(
      '\\ ',
    )}`;
  }

  if (error instanceof Error && isApolloError(error)) {
    const { graphQLErrors, networkError, message } = error;
    const gqlMessage = (graphQLErrors ?? [])
      .map(getErrorDataForClipboard)
      .join('\n');
    const serverMessage = getErrorDataForClipboard(networkError);
    return `Message: ${message}\nGQL Error:\n${gqlMessage}\nServer Error:\n${serverMessage}`.trim();
  }
  if (error instanceof Error) {
    return error.message;
  }
  if (typeof error === 'object') {
    return Object.entries(error)
      .map(([key, value]) => {
        return `${key}:\n${getErrorDataForClipboard(value)}`;
      })
      .join('\n');
  }

  return getErrorMessage(error);
}
