import {
  field,
  FieldDefinitions,
  nonEmptyArrayField,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  CurrentFunctioning,
  CurrentFunctioning_CopingSkills as CopingSkills,
  CurrentFunctioning_Stressors as Stressors,
  CurrentFunctioning_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/CurrentFunctioning';
import {
  CurrentFunctioningSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';
import { EnumCheckboxesField, TextAreaField } from 'app/notes-ui/forms/fields';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { isEmpty } from 'app/notes-ui/shared/ValidationRules';
import { NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD } from 'app/notes-ui/utils';
import React from 'react';

type Props = {
  appointmentId: string;
  initialValue?: CurrentFunctioning;
  disabled?: boolean;
  savingNote?: boolean;
  onSubmit: (data: CurrentFunctioningSection) => void;
  updateDraftNoteState: (data: CurrentFunctioningSection) => void;
};

export const fieldDefs: FieldDefinitions<CurrentFunctioning> = {
  appointmentId: stringField(),
  copingSkills: nonEmptyArrayField(),
  copingSkillsComments: stringField({ rules: [requiredIfOtherCopingSkills] }),
  currentStressors: nonEmptyArrayField(),
  stressorsComments: stringField({ rules: [requiredIfOtherStressors] }),
  version: field<CurrentFunctioning_Version>({
    default: CurrentFunctioning_Version.v0,
  }),
};

export function CurrentFunctioningFormv2(props: Props) {
  const { initialValue, appointmentId, disabled } = props;

  const { fields, getValue } = useForm<CurrentFunctioning>(
    fieldDefs,
    CurrentFunctioning.fromPartial({
      ...initialValue,
      appointmentId,
      version: CurrentFunctioning_Version.v0,
    }),
    {
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
      onStateChange: async () => {
        await onSubmit();
      },
    },
  );

  const onSubmit = async () => {
    if (disabled) return;

    props.onSubmit({
      data: getValue(),
      name: TherapyIntakeSectionName.CURRENT_FUNCTIONING,
    });
  };

  return (
    <>
      <Grid spacing={1}>
        <Columns widths={[COLUMN_WIDTH.FULL, COLUMN_WIDTH.FULL]}>
          <NoteFormControlLabel label="Stressors">
            <EnumCheckboxesField
              disabled={disabled}
              testId="currentStressors"
              label=""
              options={Stressors}
              field={fields.currentStressors}
              keyLabels={{
                identity_related:
                  'Identity Related (e.g. sexual orientation, gender)',
                stressors_other: 'Other',
              }}
            />
            {fields.currentStressors.value.includes(
              Stressors.stressors_other,
            ) && (
              <TextAreaField
                disabled={disabled}
                testId="stressorsComments"
                label=""
                placeholder="Type here..."
                field={fields.stressorsComments}
                rows={1}
                className={styles.otherTextField}
                autoExpand={true}
              />
            )}
          </NoteFormControlLabel>

          <NoteFormControlLabel label="Coping skills/strengths">
            <EnumCheckboxesField
              disabled={disabled}
              testId="copingSkills"
              label=""
              options={CopingSkills}
              field={fields.copingSkills}
              keyLabels={{
                attention_to_self_care:
                  'Attention to self care (e.g.‚ sleep‚ nutrition)',
                coping_skills_other: 'Other',
              }}
            />
            {fields.copingSkills.value.includes(
              CopingSkills.coping_skills_other,
            ) && (
              <TextAreaField
                disabled={disabled}
                testId="copingSkillsComments"
                label=""
                placeholder="Type here..."
                className={styles.otherTextField}
                field={fields.copingSkillsComments}
                rows={1}
                autoExpand={true}
              />
            )}
          </NoteFormControlLabel>
        </Columns>
      </Grid>
    </>
  );
}

export function requiredIfOtherStressors(
  value: string,
  state: CurrentFunctioning,
): string | undefined {
  if (
    state.currentStressors.includes(Stressors.stressors_other) &&
    isEmpty(value)
  ) {
    return 'This field is required';
  }
  return undefined;
}

export function requiredIfOtherCopingSkills(
  value: string,
  state: CurrentFunctioning,
): string | undefined {
  if (
    state.copingSkills.includes(CopingSkills.coping_skills_other) &&
    isEmpty(value)
  ) {
    return 'This field is required';
  }
  return undefined;
}

export const validate = (data: CurrentFunctioning | null) =>
  data !== null && validateForm(data, fieldDefs);
