import {
  field,
  numberField,
  stringField,
  useForm,
} from '@ginger.io/react-use-form';
import { NewEventForMeVariables } from '@headspace/carehub-graphql/dist/appointments/generated/NewEventForMe';
import {
  DateTimeRangeField,
  DropDownField,
  NumberField,
} from 'app/notes-ui/forms/fields';
import { DATE_TIME_FORMAT } from 'app/notes-ui/forms/form-controls/DateTimeRangeInput';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { TopLevelRoutes } from 'app/top-nav/Routes';
import {
  ClinicalAppointmentRecurrenceInterval,
  ClinicalEventRecurrenceInput,
  ClinicalEventType,
} from 'generated/globalTypes';
import moment from 'moment-timezone';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Size } from 'types/StyleTypes';

import styles from './AppointmentScreen.module.scss';
import { EventControls } from './EventControls';

export enum EmptyFormValue {
  NONE = 'none',
}

export type NewEventFormFields = {
  eventType: ClinicalEventType;
  start: string;
  end: string;
  recurrence: {
    interval: ClinicalAppointmentRecurrenceInterval | EmptyFormValue;
    occurrenceCount: PositiveInt;
  };
};

export type NewEventFormProps = {
  onSave: (event: NewEventForMeVariables) => Promise<void>;
  onClose: () => void;
  timezone: string;
};

export function NewEventForm({ onClose, onSave, timezone }: NewEventFormProps) {
  const { fields, validate, getValue } = useForm<NewEventFormFields>({
    end: stringField(),
    eventType: field(),
    recurrence: {
      interval: field({ rules: [] }),
      occurrenceCount: numberField(),
    },
    start: stringField(),
  });

  async function onSaveWrapper() {
    const valid = await validate();
    if (!valid) {
      return;
    }
    const eventData = getValue();
    const recurrence = (eventData.recurrence.interval === EmptyFormValue.NONE ||
    !eventData.recurrence.interval
      ? null
      : eventData.recurrence) as ClinicalEventRecurrenceInput | null;
    const start = moment
      .tz(eventData.start, DATE_TIME_FORMAT, timezone)
      .toISOString();
    const end = moment
      .tz(eventData.end, DATE_TIME_FORMAT, timezone)
      .toISOString();
    return onSave({
      input: { eventData: { ...eventData, end, recurrence, start } },
    });
  }

  return (
    <EventControls
      onSave={onSaveWrapper}
      onClose={onClose}
      disableMenuAction={true}
    >
      <div className={styles.flex}>
        <div className={styles.controlGroup}>
          <NoteFormControlLabel label="Event Type">
            <DropDownField
              field={fields.eventType}
              dropdownProps={{
                className: styles.input,
                dataTestId: 'eventType',
                options: [
                  {
                    name: 'Interview Panel',
                    value: ClinicalEventType.INTERVIEW_PANEL,
                  },
                  { name: 'QA Work', value: ClinicalEventType.QA_WORK },
                  {
                    name: 'Special Project',
                    value: ClinicalEventType.SPECIAL_PROJECT,
                  },
                  { name: 'Training', value: ClinicalEventType.TRAINING },
                ],
                placeholder: 'Event Type',
                size: Size.MD,
              }}
            />
          </NoteFormControlLabel>
        </div>
      </div>

      <div className={styles.flex}>
        <DateTimeRangeField
          label="Date and Time"
          field={{ end: fields.end, start: fields.start }}
        >
          <div className={styles.timezone}>
            <NavLink to={TopLevelRoutes.SETTINGS}>{timezone}</NavLink>
          </div>
        </DateTimeRangeField>
      </div>

      <div className={styles.flex}>
        <div className={styles.marginAfter}>
          <DropDownField
            field={fields.recurrence.interval}
            dropdownProps={{
              dataTestId: 'interval',
              initialValue: EmptyFormValue.NONE,
              options: [
                { name: 'Does not repeat', value: EmptyFormValue.NONE },
                {
                  name: 'Once a week',
                  value: ClinicalAppointmentRecurrenceInterval.ONE_WEEK,
                },
                {
                  name: 'Every two weeks',
                  value: ClinicalAppointmentRecurrenceInterval.TWO_WEEKS,
                },
              ],
              size: Size.MD,
            }}
          />
        </div>
        <span className={styles.formText}>ends after</span>
        <div className={styles.marginBeforeAndAfter}>
          <NumberField
            testId="occurrenceCount"
            label=""
            field={fields.recurrence.occurrenceCount}
            inputProps={{ max: 4, min: 1 }}
          />
        </div>
        <span className={styles.formText}>occurrences</span>
      </div>
    </EventControls>
  );
}
