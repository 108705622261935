import React, { createContext, useContext, useState } from 'react';

import Messages from 'i18n/en/appointment.json';

export type CardContextProps = {
  isExpanded: boolean;
  toggle: () => void;
  expand: () => void;
  collapse: () => void;
};

export const CardContext = createContext<CardContextProps | null>(null);

const useCardContext = (isToggled?: boolean): CardContextProps => {
  const api = useContext(CardContext);
  if (api !== null) {
    if (isToggled) {
      api.expand();
    }

    return api;
  }
  throw new Error(Messages.cardContext);
};

const CardContextProvider: React.FC = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggle = () => {
    setIsExpanded(!isExpanded);
  };

  const expand = () => {
    setIsExpanded(true);
  };

  const collapse = () => {
    setIsExpanded(false);
  };

  return (
    <CardContext.Provider value={{ collapse, expand, isExpanded, toggle }}>
      {children}
    </CardContext.Provider>
  );
};

export default CardContext;

export { CardContextProvider, useCardContext };
