import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { ModalLoader } from 'app/care-team/modal-loader/ModalLoader';
import { Width } from 'types/StyleTypes';
import { TextArea } from 'shared-components/text-area/TextArea';
import { ReactComponent as Close } from 'assets/close-icon.svg';
import { TransferMemberToSICoachErrorCode } from 'generated/globalTypes';
import React, { useState } from 'react';

import styles from './AddSICoachModal.module.scss';
import useTransferMemberToSICoach, {
  AddSICoachErrorState,
} from './hooks/useTransferMemberToSICoach';
import { AddSICoachModalButton } from './modal-button/AddSICoachModalButton';

export interface AddSICoachModalProps {
  onCancel: () => void;
  onSICoachAdded: (detailsForSICoachInput: string) => void;
  open: boolean;
  memberId: string;
}

export const AddSICoachModal = ({
  open,
  onCancel,
  memberId,
  onSICoachAdded,
}: AddSICoachModalProps) => {
  const [detailsForSICoachInput, setDetailsForSICoachInput] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState<MaybeUndefined<AddSICoachErrorState>>(
    undefined,
  );

  const { transferMemberToSICoach } = useTransferMemberToSICoach();

  const closeModal = () => {
    // reset modal state
    setError(undefined);

    // close modal
    onCancel();
  };

  const onAddSICoachButtonClicked = async () => {
    // reset modal state
    setError(undefined);

    // show loader
    setShowLoader(true);

    // execute mutation
    try {
      await transferMemberToSICoach({
        memberId,
      });

      onSICoachAdded(detailsForSICoachInput);
    } catch (error) {
      setError(error);
    } finally {
      // show loader
      setShowLoader(false);
    }
  };

  return (
    <Dialog open={open} className={styles.addSICoachModal}>
      {(!showLoader || error) && (
        <DialogTitle className={styles.modalTitle}>
          <div>{error?.title ?? 'Add SI Coach'}</div>{' '}
          <Close onClick={closeModal} className={styles.close} />
        </DialogTitle>
      )}

      <DialogContent className={styles.modalBody}>
        {!showLoader && !error && (
          <>
            <p className={styles.text}>
              <span className={styles.handoffTip}>Handoff Tip: </span> Before
              adding, let the member know what you&apos;re doing and what to
              expect from this specialized coach.
            </p>

            <p className={styles.text}>
              Adding an SI coach will temporarily add the coach to the
              member&apos;s care team to complete a Risk evaluation
            </p>

            <TextArea
              textAreaClassName={styles.detailsForSICoachInput}
              placeholder="Optional details for SI coach..."
              width={Width.FULL}
              value={detailsForSICoachInput}
              onChange={(value) => {
                setDetailsForSICoachInput(value);
              }}
            />

            <p className={styles.noteText}>
              Note: This message will be shown in Team Communication. All
              messages are part of the member&apos;s medical file.
            </p>

            <div className={styles.buttonContainer}>
              <AddSICoachModalButton primary={false} onClick={onCancel}>
                Cancel
              </AddSICoachModalButton>
              <AddSICoachModalButton
                testId="add-si-coach-button"
                primary={true}
                onClick={onAddSICoachButtonClicked}
              >
                Yes, add SI Coach
              </AddSICoachModalButton>
            </div>
          </>
        )}

        {showLoader && <ModalLoader description="Adding an SI coach..." />}

        {error && (
          <p
            data-testid="add-si-coach-modal-error-text"
            className={styles.text}
          >
            {error.message}
          </p>
        )}

        {!showLoader && error && (
          // Some of the errors will display both buttons:
          // "Try again" should be displayed for any error except ALL_SI_COACHES_OFFLINE
          // "Start risk assessment should be displayed for any error that has errorType
          <div className={styles.buttonContainer}>
            {error.errorType !==
              TransferMemberToSICoachErrorCode.ALL_SI_COACHES_OFFLINE && (
              <AddSICoachModalButton
                primary={!error.errorType}
                onClick={onAddSICoachButtonClicked}
                testId="add-si-coach-modal-try-again-btn"
              >
                Try again
              </AddSICoachModalButton>
            )}

            {error.errorType && (
              <AddSICoachModalButton
                primary={true}
                onClick={closeModal}
                testId="add-si-coach-modal-start-risk-assessment-btn"
              >
                Start risk evaluation
              </AddSICoachModalButton>
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
