import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import { isEmpty } from 'app/notes-ui/shared/ValidationRules';

export function booleanOptionRequired(booleanOption: BooleanOption) {
  return booleanOption > 0 ? undefined : 'This field is required.';
}

export function requiredIfYes(
  value: string,
  booleanOption: BooleanOption,
): MaybeUndefined<string> {
  const isYes = booleanOption === BooleanOption.yes;
  if (isYes !== undefined && isYes && isEmpty(value)) {
    return 'This field is required.';
  }
  return undefined;
}

export function validateSessionCount(
  value: number,
  sessionCount: number,
): string | undefined {
  return value < sessionCount
    ? `Session number must be ${sessionCount} or higher.`
    : undefined;
}
