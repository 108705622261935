import {
  booleanField,
  field,
  FieldDefinitions,
  numberField,
  stringField,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import { AnticipatedSession_Frequency } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/AnticipatedSession';
import { Interventions } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Interventions';
import { TreatmentGoal } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/TreatmentGoal';
import {
  TreatmentPlan,
  TreatmentPlan_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/TreatmentPlan';
import { ApproachType } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/ApproachType';
import { TreatmentGoalFieldDefs } from 'app/notes-ui/shared/treatment-plan/schema';
import { isEmpty, optionalField } from 'app/notes-ui/shared/ValidationRules';

export const fieldDefs: FieldDefinitions<TreatmentPlan> = {
  additionalSessionsRequired: field({ rules: [] }),
  anticipatedSession: {
    frequency: field({
      rules: [
        (value) =>
          isEmpty(value) || value === AnticipatedSession_Frequency.UNRECOGNIZED
            ? 'These two fields are required'
            : undefined,
      ],
    }),
    numberOfSessionsAnticipated: numberField(),
  },
  appointmentId: stringField(),
  appointmentOffered: field({ rules: [] }),
  approaches: field<ApproachType[]>({ default: [], rules: [optionalField] }),
  approachesUpdateComments: stringField({ rules: [optionalField] }),
  changesToTreatmentPlan: stringField(),
  goal: field<TreatmentGoal[]>({
    default: [],
    rules: [
      (value) => {
        const isValid =
          value !== undefined &&
          value.every((_) => validateForm(_, TreatmentGoalFieldDefs));
        if (!isValid) {
          return 'This field contains invalid inputs';
        }
        return undefined;
      },
    ],
  }),
  interventionsUsed: field<Interventions[]>({
    default: [],
    rules: [optionalField],
  }),
  memberOutOfScope: booleanField({ default: false, rules: [optionalField] }),
  memberOutOfScopeReason: stringField({ rules: [optionalField] }),
  messageToCareTeam: stringField({ rules: [optionalField] }),
  offeredAppointmentAccepted: field({ rules: [] }),
  referralsToCareOutsideGingerNecessary: stringField({ rules: [] }),
  waitTimeDetrimentalEffect: booleanField(),
  otherApproachComment: stringField({ rules: [] }),
  version: field<TreatmentPlan_Version>({
    default: TreatmentPlan_Version.undefined_version,
  }),
};

export const validate = (data: TreatmentPlan | null) =>
  validateForm(data, fieldDefs);
