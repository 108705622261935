import { ApproachType } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/ApproachType';
import { NoteType } from 'generated/globalTypes';
import { useEffect, useState } from 'react';

import {
  useTherapyIntakeNotesAPI,
  useTherapyProgressNotesAPI,
} from './useClinicalNotesAPI';
import { getPreviousAppointment } from './useTherapyProgressNote';

export const usePreviousApproaches = (appointmentId: string) => {
  const [previousApproaches, setPreviousApproaches] = useState<ApproachType[]>(
    [],
  );
  const progressApi = useTherapyProgressNotesAPI();
  const intakeApi = useTherapyIntakeNotesAPI();

  useEffect(() => {
    const getPreviousApproaches = async () => {
      const appointment = await progressApi.getAppointment(appointmentId);
      const previousAppointmentIdAndNoteType = getPreviousAppointment(
        appointment,
      );

      if (
        previousAppointmentIdAndNoteType &&
        previousAppointmentIdAndNoteType.noteType === NoteType.THERAPY_PROGRESS
      ) {
        const previousAppointment = await progressApi.getNote(
          previousAppointmentIdAndNoteType.id,
        );
        setPreviousApproaches(
          previousAppointment.TREATMENT_PLAN?.approaches || [],
        );
      }

      if (
        previousAppointmentIdAndNoteType &&
        previousAppointmentIdAndNoteType.noteType === NoteType.THERAPY_INTAKE
      ) {
        const previousAppointment = await intakeApi.getNote(
          previousAppointmentIdAndNoteType.id,
        );
        setPreviousApproaches(
          previousAppointment.TREATMENT_PLAN?.approaches || [],
        );
      }
    };
    void getPreviousApproaches();
  }, [appointmentId, progressApi, intakeApi]);

  return previousApproaches;
};
