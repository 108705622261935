export enum InsuranceFields {
  TYPE = 'Type',
  SERVICES_COVERED = 'Services Covered',
  VIDEO_ELIGIBILITY = 'Video Eligibility',
  CLINICAL_COVERAGE = 'Clinical Coverage',
  CLINICAL_USAGE = 'Clinical Usage',
  COVERAGE_RESET_DATE = 'Coverage Reset Date',
  PLAN_DESCRIPTION = 'Plan Description',
  INSURANCE_COMPANY = 'Insurance Company',
  INSURANCE_ID = 'Insurance ID',
  ORG_DETAILS = 'Organization Details',
}
