import { useAppState } from 'app/state';
import { Routes, searchRoute } from 'app/top-nav/Routes';
import gql from 'graphql-tag';
import React from 'react';
import AppBanner from 'shared-components/banners/AppBanner';
import Search from 'shared-components/search-input/Search';

import { ReactComponent as Logo } from './logo.svg';
import { SettingsMenu } from './SettingsMenu';
import styles from './TopNav.module.scss';

export const getTasksAndFeatureFlagsQuery = gql`
  query getTasksAndFeatureFlags {
    getTasks {
      all {
        id
        status
        reasonForCreation
      }
    }
  }
`;

type TopNavProps = {
  redirectTo: (path: string) => void;
  dataTestId?: string;
};

/** The top nav bar of the UI - contains the ginger logo + logout links */
export function TopNav({ redirectTo, dataTestId }: TopNavProps) {
  const role = useAppState((_) => _.user.role);
  const onSearchQuery = (q: string) =>
    redirectTo(searchRoute(Routes.MEMBERS, q));

  return (
    <div data-testid={dataTestId}>
      <AppBanner />
      <nav className={styles.topNav}>
        <ul className={styles.main}>
          <li>
            <Logo />
          </li>
        </ul>
        <div className={styles.search}>
          <Search
            data-testid="search"
            onSearchQuery={onSearchQuery}
            className={styles.input}
          />
        </div>
        <ul className={styles.settings}>
          <li>
            <SettingsMenu
              data-testid="settingsMenu"
              redirectTo={redirectTo}
              role={role}
            />
          </li>
        </ul>
      </nav>
    </div>
  );
}
