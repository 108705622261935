import {
  HomeworkData,
  HomeworkStatus,
  HomeworkType,
} from 'app/patients/tabs/content/types';
import { createAction } from 'redux-reloaded';

import { HomeworkAssignmentStatus } from './schema';

export enum Actions {
  ADD_HOMEWORK = 'ADD_HOMEWORK',
  SET_HOMEWORKS = 'SET_HOMEWORKS',
  UNASSIGN_HOMEWORK = 'UNASSIGN_HOMEWORK',
  UPDATE_HOMEWORK = 'UPDATE_HOMEWOWRK',
  SET_ASSIGNMENT_STATUS = 'SET_ASSIGNMENT_STATUS',
  ASSIGN_HOMEWORK = 'ASSIGN_HOMEWORK',
}

export const setHomeworkAssignmentStatus = createAction(
  Actions.SET_ASSIGNMENT_STATUS,
  ({
    channelId,
    assignmentState,
  }: {
    channelId: string;
    assignmentState: HomeworkAssignmentStatus;
  }) => ({
    assignmentState,
    channelId,
  }),
);

export const addHomework = createAction(
  Actions.ADD_HOMEWORK,
  ({ homework, userId }: { homework: HomeworkType; userId: string }) => ({
    homework,
    userId,
  }),
);

export const setHomeworks = createAction(
  Actions.SET_HOMEWORKS,
  ({ homeworks, userId }: { homeworks: HomeworkData; userId: string }) => ({
    homeworks,
    userId,
  }),
);

export const unassignHomework = createAction(
  Actions.UNASSIGN_HOMEWORK,
  ({ contentId, userId }: { contentId: string; userId: number }) => ({
    contentId,
    userId,
  }),
);

export const updateHomework = createAction(
  Actions.UPDATE_HOMEWORK,
  ({
    label,
    status,
    userId,
  }: {
    label: string;
    status: HomeworkStatus;
    userId: string;
  }) => ({ label, status, userId }),
);

export const assignHomework = createAction(
  Actions.ASSIGN_HOMEWORK,
  (payload: {
    memberId: string;
    contentId: string;
    contentType: string;
    channelId: string;
  }) => payload,
);
