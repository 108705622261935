import { createAction } from 'redux-reloaded';

export enum Actions {
  ON_NEW_RISK_ALERT_TASK_CREATED = 'ON_NEW_RISK_ALERT_TASK_CREATED',
  REFRESH_TASKS_CARD_TODAYS_TASK_LIST = 'REFRESH_TASKS_CARD_TODAYS_TASK_LIST',
}

export const onNewRiskAlertTaskCreated = createAction(
  Actions.ON_NEW_RISK_ALERT_TASK_CREATED,
  (payload: { taskType: string; memberId: string }) => payload,
);

export const refreshTodaysTaskCard = createAction(
  Actions.REFRESH_TASKS_CARD_TODAYS_TASK_LIST,
  (payload: { memberId: string }) => payload,
);
