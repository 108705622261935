import {
  CloseChatConversationWithMember,
  CloseChatConversationWithMemberVariables,
} from 'app/coach/chat/generated/CloseChatConversationWithMember';
import { usePollMessageHistory } from 'app/coach/chat/UsePollMessageHistory';
import { getPubErrorLogData } from 'app/coach/pubnub/errors';
import { usePubNubAPI } from 'app/coach/pubnub/PubNubContextProvider';
import { MessageType } from 'app/coach/pubnub/types';
import { useAppState } from 'app/state';
import { onReadingMessagesByCareProvider } from 'app/state/chat/actions';
import {
  selectIsReadOnly,
  selectLoggingContextWithMemberData,
} from 'app/state/chat/selectors';
import {
  selectLastListenerRead,
  selectLastMemberRead,
} from 'app/state/features/conversationTimetokens/selectors';
import { TimetokensService } from 'app/state/features/conversationTimetokens/TimetokensService';
import { useAppDispatch } from 'app/state/hooks/baseTypedHooks';
import { markConversationAsDone, setMessages } from 'app/state/inbox/actions';
import {
  selectConversationIdByMemberId,
  selectConversationState,
  selectCurrentPubnubChannelId,
  selectMessages,
  selectUnreadMessages,
} from 'app/state/inbox/selectors';
import { displayableMessagesToString } from 'app/state/inbox/utils';
import { useLogger } from 'app/state/log/useLogger';
import arrowDown from 'assets/chat/ArrowDown.svg';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useMutationWithGlobalState } from 'hooks/useMutationWithGlobalState';
import { useOnMount } from 'hooks/useOnMount';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { PillButton } from 'shared-components/button/PillButton';
import ErrorBoundary from 'shared-components/error-state/ErrorBoundary';
import { classNameCombiner } from 'utils';

import { ChatContainerProps } from './ChatContainer';
import styles from './ChatContainer.module.scss';
import { closeChatConversationWithMember } from './ChatQueries';
import { ChatErrorState } from './error/ChatErrorState';
import { ChatHeader } from './header/ChatHeader';
import { MessagesSection } from './messages-section/MessagesSection';
import {
  multipleNewMessagesButtonText,
  oneNewMessageButtonText,
} from './strings';
import { ReadOnlyChat } from './text-area-section/ReadOnlyChat';
import { TextAreaGroup } from './text-area-section/TextAreaGroup';
import {
  buildUpdateReadStateRPC,
  getMinNumberOfMessages,
  isContainerScrolledToTheBottom,
  MIN_NUMBER_OF_MESSAGES,
  scrollToTheBottomOfScrollableContainer,
  updateMessagesStatuses,
} from './utils';

export const Chat = ({
  memberProfile,
  isChatExpanded,
  toggleChatExpansion,
  coachingCareTeam,
}: ChatContainerProps) => {
  const {
    baseProps,
    preferences: { timezone },
    memberId,
    callerId,
  } = memberProfile;
  const { listenerId, coachinghubUsername } = useAppState(
    ({ user: { listenerId, coachinghubUsername } }) => ({
      coachinghubUsername,
      listenerId,
    }),
  );

  const api = usePubNubAPI();
  const { publishMessage, getHistory } = api;

  const { firstName } = baseProps;
  const currentChannelId = selectCurrentPubnubChannelId();
  const currentConversationId = selectConversationIdByMemberId(memberId);
  const unreadMessages = selectUnreadMessages(currentChannelId);
  const messages = selectMessages(currentChannelId);
  /*  an example of selecting the state from the slice */
  const lastListenerReadTimetoken = useAppState((state) =>
    selectLastListenerRead(state, currentChannelId),
  );
  const lastMemberReadTimetoken = useAppState((state) =>
    selectLastMemberRead(state, currentChannelId),
  );
  const { enable_read_only_chat } = useFeatureFlags().transientFeatureFlags;
  const isReadOnly =
    selectIsReadOnly(currentChannelId) && enable_read_only_chat;
  const logsContext = selectLoggingContextWithMemberData(callerId, memberId);
  const conversationState = selectConversationState(currentChannelId);

  // the variable for the chat scroll position on a moment BEFORE getting a new message from a member
  const [, setWasScrolledToTheBottom] = useState(false);
  const dispatch = useAppDispatch();
  const logger = useLogger();
  const [closeChatConversationWithMemberFn] = useMutationWithGlobalState<
    CloseChatConversationWithMember,
    CloseChatConversationWithMemberVariables
  >(closeChatConversationWithMember);

  const messagesContainerRef = useRef<HTMLDivElement>(null);
  usePollMessageHistory({ channelId: currentChannelId, memberId });
  const scrollToTheBottom = () => {
    scrollToTheBottomOfScrollableContainer(messagesContainerRef);
  };

  const [shouldDisplayAPill, setShouldDisplayAPill] = useState(false);

  const sendUpdateReadStateRPCAndUpdateTimeToken = async () => {
    if (coachinghubUsername && listenerId) {
      const message_ids = unreadMessages.map((m) => m.id);
      const updateReadStateRPC = buildUpdateReadStateRPC({
        message_ids,
        senderId: listenerId,
        username: coachinghubUsername,
      });
      /* This is the behavior copied from Listener UI: an "update_read_state"
          RPC is sent to the channel. The timetoken of the RPC "update_read_state"
          is then used to call Listener to update the Listener read timetoken/
          timestamp. */
      try {
        const publishResponse = await publishMessage({
          channel: currentChannelId,
          message: updateReadStateRPC,
        });
        const stringifiedPublishTimetoken = publishResponse.timetoken.toString();
        const updateTimetokenRes = await dispatch(
          TimetokensService.updateConversationTimetokens({
            conversationId: currentConversationId,
            lastListenerReadTimetoken: stringifiedPublishTimetoken,
          }),
        ).unwrap();
        // To-Do: amplitude slice should listen to updateConversationTimetokens.fullfill, check if lastListenerRead was updated and log if it was; this doesn't belog to a component
        if (updateTimetokenRes) {
          dispatch(
            onReadingMessagesByCareProvider({
              channelId: currentChannelId,
              logsPayload: {
                ...logsContext,
                message_ids,
                timetoken: stringifiedPublishTimetoken,
              },
            }),
          );
        } else {
          const err = new Error(`Could not publish update_read_state rpc.`);
          logger.error(err, {
            channelId: currentChannelId,
            response: updateTimetokenRes,
          });
        }
      } catch (e) {
        logger.error(
          new Error(`Could not publish update_read_state rpc.`, { cause: e }),
          {
            callerId,
            channelId: currentChannelId,
            error: e,
            listenerId,
          },
        );
      }
    } else {
      logger.error(
        new Error(`Could not send update read state RPC due to invalid params`),
        {
          channelId: currentChannelId,
          coachinghubUsername,
          listenerId,
        },
      );
    }
  };

  const closeChatConversation = async () => {
    const result = await closeChatConversationWithMemberFn({
      input: {
        gingerId: memberId,
        lastKnownTimetoken: [...messages].pop()?.timetoken ?? null,
      },
    });
    const success =
      result.data?.closeChatConversationWithMember?.success ?? false;
    if (success) {
      dispatch(markConversationAsDone({ memberId }));
    }
    return success;
  };

  const onNewMessageButtonClick = () => {
    if (unreadMessages.length) void sendUpdateReadStateRPCAndUpdateTimeToken();
    scrollToTheBottom();
    setWasScrolledToTheBottom(true);
  };

  const numberOfUnreadMessages = unreadMessages?.length;
  const textForPill = `${numberOfUnreadMessages} ${
    numberOfUnreadMessages > 1
      ? multipleNewMessagesButtonText
      : oneNewMessageButtonText
  }`;

  /* the useEffect is responsible for making UI changes on getting a new message from a member.
      There're 2 possible scenarios:
      (1) the chat is scrolled to the bottom when member's message arrives ->
      we need to scroll the chat to the bottom to make a new message visible and call sendUpdateReadStateRPCAndUpdateTimeToken
      (2) the chat is NOT scrolled to the bottom (a coach is reading earlier messages) ->
      we need to display the unread messages pill
      W/O making this message read and calling sendUpdateReadStateRPCAndUpdateTimeToken (a click on the pill should make it) */
  useLayoutEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (!lastMessage) return;
    // scroll to the bottom when the message is from the coach or a home work assignment
    if (
      lastMessage.type === MessageType.TEXT_FROM_LISTENER ||
      lastMessage.type === MessageType.CONTENT_MESSAGE
    ) {
      scrollToTheBottom();
      return;
    }

    if (lastMessage.type === MessageType.TEXT_FROM_MEMBER) {
      const isScrolled = isContainerScrolledToTheBottom(messagesContainerRef);
      if (isScrolled) {
        void sendUpdateReadStateRPCAndUpdateTimeToken();
        scrollToTheBottom();
      }
    }
  }, [messages.length]);

  useEffect(() => {
    // display the pill only when there're some unread messages AND the chat is not scrolled to the bottom
    const isPillNeeded =
      Boolean(numberOfUnreadMessages) &&
      !isContainerScrolledToTheBottom(messagesContainerRef);
    setShouldDisplayAPill(isPillNeeded);
  }, [numberOfUnreadMessages]);

  // check the number of messages on mount and load necessary number of messages (MIN_NUMBER_OF_MESSAGES)
  useOnMount(() => {
    const abortController = new AbortController();
    const initChat = async () => {
      if (!currentChannelId) return;
      let lastMessageTimetoken =
        messages.length > 0
          ? messages[messages.length - 1].timetoken
          : undefined;
      if (messages.length < MIN_NUMBER_OF_MESSAGES) {
        try {
          const updatedMessages = await getMinNumberOfMessages({
            channelId: currentChannelId,
            getHistory,
            initialMessages: messages,
            logger,
          });
          const messagesWithStatuses = updateMessagesStatuses(
            lastMemberReadTimetoken,
            updatedMessages,
          );
          logger.info('Chat: loading the messages history', {
            channelId: currentChannelId,
            messageFromStore: displayableMessagesToString(messages),
            messagesFromHistory: displayableMessagesToString(updatedMessages),
          });
          dispatch(
            setMessages({
              channelId: currentChannelId,
              messages: messagesWithStatuses,
            }),
          );

          if (updatedMessages.length > 0) {
            lastMessageTimetoken =
              updatedMessages[updatedMessages.length - 1].timetoken;
          }
        } catch (e) {
          logger.error(
            new Error(
              `Got an error when tried to load the messages history from the Chat component`,
              { cause: e },
            ),
            {
              callerId,
              channelId: currentChannelId,
              error: e,
              listenerId,
              pubnubError: getPubErrorLogData(e),
            },
          );
        }
      }

      // compare the lastListenerRead vs messages timetokens
      // note: lastListenerReadTimetoken maybe null for a new conversation.
      if (
        lastMessageTimetoken &&
        (!lastListenerReadTimetoken ||
          lastListenerReadTimetoken < lastMessageTimetoken)
      ) {
        void dispatch(
          TimetokensService.updateConversationTimetokens({
            conversationId: currentConversationId,
            lastListenerReadTimetoken: lastMessageTimetoken,
          }),
        );
      }
    };
    void initChat();

    /* even though the useeffect fires only onMount if the tabs are switched too quickly, we may have some not completed async operations.
    We need to abort the async operations in process to prevent this warting from React:
    Cann't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
     */
    return () => abortController.abort();
  });

  if (!currentChannelId) return null;

  return (
    <div data-testid="chat-container" className={styles.wrapper}>
      <div
        className={classNameCombiner([styles.container, styles.withMultiTab])}
      >
        <ChatHeader
          memberId={memberId}
          firstName={firstName}
          isExpanded={isChatExpanded}
          timezone={timezone}
          toggleExpansion={toggleChatExpansion}
          conversationState={conversationState?.state ?? undefined}
          onCloseConversation={closeChatConversation}
        />
        {shouldDisplayAPill ? (
          <PillButton
            testId="newMessageButton"
            onClick={onNewMessageButtonClick}
            text={textForPill}
            className={styles.newMessageButton}
            imgSrc={arrowDown}
          />
        ) : (
          <></>
        )}
        {/* we place the ErrorBoundary here since we need to display the header as usual if something goes
            wrong w/ the chat and the main chat logic is in the wrapped components */}
        <ErrorBoundary FallbackComponent={ChatErrorState}>
          <div ref={messagesContainerRef} className={styles.content}>
            <MessagesSection
              scrollToTheBottom={scrollToTheBottom}
              memberProfileBaseProps={baseProps}
            />
          </div>
          {isReadOnly ? (
            <ReadOnlyChat coachingCareTeam={coachingCareTeam} />
          ) : (
            <TextAreaGroup
              callerId={callerId}
              memberId={memberId}
              memberName={baseProps.firstName}
            />
          )}
        </ErrorBoundary>
      </div>
    </div>
  );
};
