import { ConversationState, ConversationStateMap } from 'app/coach/chat/types';
import { PubnubAPIService } from 'app/coach/pubnub/PubnubAPIService';
import {
  MessageToDisplay,
  MessageType,
  RPCEnvelope,
  TextMessageEnvelope,
} from 'app/coach/pubnub/types';
import { InboxItem, InboxItemState } from 'app/inbox/types';
import { ConversationStatsFragment } from 'app/queries/generated/ConversationStatsFragment';
import { GetClinicianInboxTotal_getInboxTotal } from 'app/queries/generated/GetClinicianInboxTotal';
import { GetCoachInboxTotal_getCoachInboxTotal } from 'app/queries/generated/GetCoachInboxTotal';
import { isLocal } from 'app/Stage';
import { setShouldPlayNotificationSound } from 'app/state/chat/actions';
import { addUnreadByListenerMessage } from 'app/state/inbox/actions';
import { ConvoPresence } from 'app/state/inbox/schema';
import { ILogger } from 'app/state/log/Logger';
import moment, { Moment } from 'moment-timezone';
import { Dispatch } from 'redux';

export async function getOnlineStatusByChannelIds(
  pubnub: PubnubAPIService,
  channelIds: Array<string>,
  logger: ILogger,
): Promise<Record<string, Partial<ConvoPresence>>> {
  const uuid = pubnub.getUUID();
  const resp = await pubnub.hereNow({
    channels: channelIds,
    includeState: true,
    includeUUIDs: true,
  });
  const onlineStatusByChannelId: Record<string, Partial<ConvoPresence>> = {};
  for (const channelId in resp.channels) {
    if (!onlineStatusByChannelId[channelId]) {
      onlineStatusByChannelId[channelId] = { isOnline: false };
    }
    onlineStatusByChannelId[channelId].isOnline = false;
    resp.channels[channelId].occupants.forEach((o) => {
      const occupantUUID = o.uuid;
      // If we find an occupant UUID that doesn't look like our own coach's UUID, we assume that is the
      // member/Caller's UUID, so we mark the caller_present flag to indicate that the caller is in the channel
      // (i.e. the caller is online).
      if (occupantUUID) {
        if (!occupantUUID.startsWith(uuid ?? '')) {
          onlineStatusByChannelId[channelId].isOnline = true;
        } else if (occupantUUID !== uuid) {
          logger.warning(
            'formatPubnubPresence: occupantUUID starts with uuid but not equal. why?',
            {
              occupantUUID,
              uuid,
            },
          );
        }
      } else {
        // This should be an error condition because the incoming message should include UUIDs.
        // However, the legacy code would've assumed that an undefined or null UUID was equivalent
        // to the member UUID. So to not deviate from that, I'll do the same (but will log something so
        // we can debug the circumstances when this happens).
        logger.error(
          new Error(
            'formatPubnubPresence: Falsy occupant UUID in payload of pubnub.hereNow() response. Assuming it is a member.',
          ),
          { entry: resp.channels[channelId], uuid },
        );
        onlineStatusByChannelId[channelId].isOnline = true;
      }
    });
  }
  return onlineStatusByChannelId;
}

export function getTodaysCoachInboxMemberCount(
  coachInboxTotal: GetCoachInboxTotal_getCoachInboxTotal,
): number {
  const { todaysCoachingMembers, todaysTasksMembers } = coachInboxTotal;
  const { conversations, scheduledSessions } = todaysCoachingMembers;
  const { active } = todaysTasksMembers;

  return scheduledSessions + conversations + active;
}

export function getTodaysClinicalInboxMemberCount(
  clinicalInboxTotal: GetClinicianInboxTotal_getInboxTotal | null,
): number {
  let todaysMemberCount = 0;

  if (clinicalInboxTotal) {
    const { todaysClinicalMembers, todaysTasksMembers } = clinicalInboxTotal;
    const { appointments } = todaysClinicalMembers;
    const { active } = todaysTasksMembers;

    todaysMemberCount += appointments + active;
  }

  return todaysMemberCount;
}

export const removeConvoStats = (inboxItemRaw: InboxItem): InboxItemState => {
  const independentCopy: InboxItem = {
    ...inboxItemRaw,
  };
  delete independentCopy.conversationStats;
  return independentCopy;
};

export const updateStateIfChangesDetected = (
  currentState: ConversationState,
  stateUpdates: ConversationState,
): ConversationState | null => {
  if (stateUpdates.state || stateUpdates.stateLastUpdatedAt) {
    const updatedState: ConversationState = {};
    const hasStateChanged =
      stateUpdates.state && currentState.state !== stateUpdates.state;
    const hasTimestampChanged =
      stateUpdates.stateLastUpdatedAt &&
      currentState.stateLastUpdatedAt !== stateUpdates.stateLastUpdatedAt;
    if (!hasStateChanged && !hasTimestampChanged) return null;

    updatedState.state = hasStateChanged
      ? stateUpdates.state
      : currentState.state;
    updatedState.stateLastUpdatedAt = hasTimestampChanged
      ? stateUpdates.stateLastUpdatedAt
      : currentState.stateLastUpdatedAt;
    return updatedState;
  }
  return null;
};

export const reduceConversationStatsToConversationStateMap = (
  conversationsData: Array<ConversationStatsFragment | undefined>,
): ConversationStateMap => {
  return conversationsData.reduce<ConversationStateMap>(
    (stateMap, convoStats) => {
      if (!convoStats) return stateMap;
      const { memberCoachChannelId } = convoStats;
      stateMap[memberCoachChannelId] = {
        state: convoStats.state,
        stateLastUpdatedAt: convoStats.stateLastUpdatedAt,
      };
      return stateMap;
    },
    {},
  );
};

export const autoMessageErrorMessage =
  'An error ocurred on trying to handle the AutoMessageRPC';

export const missingPropsErrorMessage =
  'Could not handle a message as it does not have one of these props:';

export const unrecognizedMessageError =
  'Got a message that is neither a text message nor an rpc.';

export const refetchHistoryErrorMessage =
  'Got an error when re-fetched history';

export const noHistoryForRequestedChannelErrorMessage =
  'Have not got history for requested channel when tried to re-fetch history.';

export const unableToCountUnreadMessagesErrorMessage =
  'Unable to count unread messages due to invalid memberCoachChannel and/or the last listener read timetoken';

export function pubnubTimeTokenToMoment(
  timeToken: string | null | undefined,
): Moment {
  return moment(timeToken ? parseInt(timeToken) / 10000 : new Date(0));
}

export function uniqueArrayList<T>(list: Array<T>): Array<T> {
  return Array.from(new Set(list));
}

export function redactSensitiveInfoFromPubhubData(
  envelope: TextMessageEnvelope | RPCEnvelope,
  isLocalFn = isLocal,
) {
  const data = { ...envelope };
  if (data.message?.message) {
    data.message = {
      ...data.message,
      message: isLocalFn() ? data.message.message : '<redacted>',
    };
  }
  return data;
}

export function displayableMessagesToString(
  missedMessages?: MessageToDisplay[],
) {
  if (missedMessages == null) {
    return '';
  }
  return JSON.stringify(
    Object.fromEntries(
      missedMessages.map((m) => [
        m.id,
        {
          timetoken: pubnubTimeTokenToMoment(m.timetoken).toISOString(),
          type: MessageType[m.type].toLowerCase(),
        },
      ]),
    ),
  );
}

export function notifyAndUpdateUnreadCount(params: {
  channelId: string;
  dispatch: Dispatch;
  logger: ILogger;
  unreadMessage: MessageToDisplay[] | MessageToDisplay;
}) {
  const { channelId, unreadMessage, dispatch, logger } = params;
  logger.info('notifyAndUpdateUnreadCount: Notifying user of unread messages', {
    channelId,
    unreadMessage: displayableMessagesToString([unreadMessage].flat()),
  });
  dispatch(
    addUnreadByListenerMessage({
      channelId,
      unreadMessage,
    }),
  );
  dispatch(setShouldPlayNotificationSound({ shouldPlaySound: true }));
}
