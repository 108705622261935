import TextField, { TextFieldProps } from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormError } from 'shared-components/FormError';
import moment, { Moment } from 'moment-timezone';
import React, { useState } from 'react';

export type DatePickerProps = {
  error?: string;
  name: string;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  fullWidth?: boolean;
  value?: string | null;
  onChange: (val: string | null) => void;
  variant?: TextFieldProps['variant'];
  disabled?: boolean;
};

export function DatePicker(props: DatePickerProps) {
  const {
    value,
    name,
    onChange,
    error,
    disabled,
    className = '',
    inputClassName = '',
    variant = 'outlined',
    fullWidth = false,
  } = props;
  const date = value ? moment(value) : null;
  const [selectedDate, setSelectedDate] = useState<Moment | null>(date);

  const onDateChange = (date: Moment | null) => {
    if (date) {
      const localDate = moment(date);
      setSelectedDate(localDate);
      if (localDate.isValid()) {
        onChange(localDate.format('YYYY-MM-DD'));
      } else {
        onChange(null);
      }
    } else {
      onChange(null);
    }
  };

  return (
    <div data-testid="picker" className={className}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MuiDatePicker
          renderInput={(props: any) => (
            <TextField
              {...props}
              inputProps={{
                ...props.inputProps,
                'aria-label': name,
                className: inputClassName,
              }}
              name={name}
              variant={variant}
              disabled={disabled}
              fullWidth={fullWidth}
              data-testid={`${name}-input`}
            />
          )}
          disabled={disabled}
          className={className}
          value={selectedDate}
          onChange={onDateChange}
          OpenPickerButtonProps={{
            'aria-label': `${name}-button`,
          }}
        />
      </LocalizationProvider>
      <FormError error={error} />
    </div>
  );
}
