import React from 'react';

import { Psychiatrist, Therapist } from '../types';
import styles from './Row.module.scss';

const ClinicianRow = ({
  clinician,
  role,
}: {
  clinician: Therapist | Psychiatrist;
  role?: 'therapist' | 'psychiatrist';
}) => {
  return (
    <div data-testid={`clinician-${clinician.id}`}>
      <div className={styles.nameContainer}>
        <div>{clinician.fullName}</div>
      </div>
      {role && (
        <div className={styles.roleContainer}>
          {role === 'therapist' && <div>Therapist</div>}
          {role === 'psychiatrist' && <div>Psychiatrist</div>}
        </div>
      )}
      <div className={styles.specializationContainer}>
        {clinician.specializations?.map((specialization) => (
          <div key={specialization} className={styles.specialization}>
            {specialization}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClinicianRow;
