export interface AppointmentsSummary {
  date: string;
  offered: number;
  scheduled: number;
  completed: number;
  canceled: number;
}

export interface WeekData {
  [key: number]: AppointmentsSummary[];
}

export interface ChartSeries {
  myPerformance: number[];
  companyTarget: number[];
  cancelled: number[];
}

export type DateRange = {
  startDate: string;
  endDate: string;
};

export enum ChartLabel {
  MY_PERFORMANCE = 'My Performance',
  COMPANY_TARGET = 'Company Target',
  CANCELLED = 'Cancelled / no show',
}
