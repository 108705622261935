import { createReducer } from 'redux-reloaded';

import {
  clearActiveMemberProfile,
  loadCareTeamFailed,
  loadCareTeamRequest,
  loadCareTeamSuccess,
  selectActiveMemberProfile,
  toggleActiveCareTeamView,
} from './actions';
import { ActiveView, getInitialCareTeamState, State } from './schema';

export const reducer = createReducer<State>(getInitialCareTeamState());

reducer.on(loadCareTeamRequest, (state, { payload }) => {
  return {
    ...state,
    activeMemberId: payload.memberId,
    // clear any previous errors
    activeView: ActiveView.ACTIVE_CARE_TEAM,

    clinicalCareTeam:
      payload.memberId !== state.activeMemberId ? null : state.clinicalCareTeam,

    // clear the selected member profile
    // clear the care team data for a different member
    coachingCareTeam:
      payload.memberId !== state.activeMemberId ? null : state.coachingCareTeam,

    error: null,

    isFetching: true,

    memberGuardianRelationshipType:
      payload.memberId !== state.activeMemberId
        ? null
        : state.memberGuardianRelationshipType,

    riskStatus:
      payload.memberId !== state.activeMemberId ? null : state.riskStatus,
    // reset the view to active care team
    selectedCareTeamMemberProfile: null,
  };
});

reducer.on(loadCareTeamFailed, (state, { payload }) => {
  const { error } = payload;
  return {
    ...state,
    error,
    isFetching: false,
  };
});

reducer.on(loadCareTeamSuccess, (state, { payload }) => {
  return {
    ...state,
    clinicalCareTeam: payload.careTeam?.clinicalCareTeam,
    coachingCareTeam: payload.careTeam?.coachingCareTeam,
    dateOfBirth: payload.careTeam?.dateOfBirth ?? null,
    hasIntakeSent: payload.careTeam?.intakeSent || false,
    isFetching: false,
    memberGuardianRelationshipType:
      payload.careTeam?.guardianRelationship?.guardianRelationshipType,
    riskStatus: payload.careTeam?.riskStatus,
  };
});

reducer.on(clearActiveMemberProfile, (state) => {
  return {
    ...state,
    isFetching: false,
    selectedCareTeamMemberProfile: null,
  };
});

reducer.on(toggleActiveCareTeamView, (state, { payload }) => {
  const { view } = payload;

  const toggledView =
    state.activeView === ActiveView.ACTIVE_CARE_TEAM
      ? ActiveView.PAST_CARE_TEAM
      : ActiveView.ACTIVE_CARE_TEAM;

  return {
    ...state,
    activeView: view === undefined ? toggledView : view,
  };
});

reducer.on(selectActiveMemberProfile, (state, { payload }) => {
  return {
    ...state,
    selectedCareTeamMemberProfile: payload.careTeamMember,
  };
});
