import errorIcon from 'assets/chat/load-more-error.svg';
import React from 'react';

import { loadMoreError } from '../strings';
import styles from './LoadMoreError.module.scss';

export const LoadMoreError = () => {
  return (
    <div data-testid="historyError" className={styles.container}>
      <img className={styles.icon} src={errorIcon} alt="error-icon" />
      <p className={styles.text}>{loadMoreError}</p>
    </div>
  );
};
