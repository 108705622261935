import { useState } from 'react';

import { OnChangeHandler } from './PsychiatricHistoryTable';

export function useFormTable<T>(
  errorMessage = 'This field is required',
  initialValue?: T[],
  onFormValueChange?: (value: T[]) => void,
): {
  isValid: boolean;
  onChange: OnChangeHandler<T>;
  rows: T[];
  error?: string;
} {
  const [rows, setRows] = useState<T[]>(initialValue ?? []);
  const [isValid, setIsValid] = useState(initialValue !== undefined);

  const onChange: OnChangeHandler<T> = ({ items, isValid }) => {
    setRows(items);
    setIsValid(isValid);
    if (onFormValueChange) onFormValueChange(items);
  };

  return {
    error: !isValid ? errorMessage : undefined,
    isValid,
    onChange,
    rows,
  };
}
