import { SegmentSubContent } from 'app/inbox/components/Content/SegmentSubContent';
import { FrenchTag, SpanishTag, TeenTag } from 'app/inbox/components/Tags';
import { InboxItemState, InboxSections, InboxTab } from 'app/inbox/types';
import { useAppState } from 'app/state';
import {
  InboxSegmentState,
  selectInboxSegmentState,
} from 'app/state/inbox/selectors';
import { ReactComponent as OfflineIndicator } from 'assets/icons/offline-indicator.svg';
import { ReactComponent as OnlineIndicator } from 'assets/icons/online-indicator.svg';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { classNameCombiner } from 'utils';
import { formatDateToHumanReadable } from 'utils/dateTime';

import {
  ConversationsAndTasksContent,
  OPEN_CONVERSATION_TEXT,
} from './Content/ConversationsAndTasksContent';
import { ScheduledContent } from './Content/ScheduledContent';
import styles from './styles/InboxSectionItem.module.scss';

export interface InboxSectionItemProps {
  memberId: string;
  tab: InboxTab;
  section: InboxSections;
  onItemClick: (item: InboxItemState, section: InboxSections) => void;
}

export const InboxSectionItem = ({
  onItemClick,
  memberId,
  tab,
  section,
}: Readonly<InboxSectionItemProps>) => {
  const state = useAppState((_) =>
    selectInboxSegmentState(_, memberId, section),
  );

  const { presence, item, isOpenConvo, timestamp } = state;
  const { name, id, isTeen = false, isFrench = false, isSpanish = false } =
    item ?? {};

  const isInboxItemValid = useMemo(
    () => (item ? Object.keys(item).length > 0 : {}),
    [item],
  );

  const handleOnClick = useCallback(() => {
    if (isInboxItemValid && item) {
      onItemClick(item, section);
    }
  }, [item, onItemClick, section, isInboxItemValid]);

  return isInboxItemValid ? (
    <div
      data-testid={`segment-${section.toLowerCase()}`}
      key={id}
      className={styles.inboxSegmentWrapper}
    >
      <button
        onClick={handleOnClick}
        className={classNameCombiner([styles.tr, styles.inboxSegment])}
        type="button"
      >
        <div className={styles.td}>
          <div className={classNameCombiner([styles.tr, styles.summary])}>
            <div className={classNameCombiner([styles.td_grow2, styles.title])}>
              {isOpenConvo &&
                section === InboxSections.CONVERSATIONS_AND_TASKS && (
                  <StatusIndicator isOnline={presence?.isOnline} />
                )}
              {isFrench && <FrenchTag />}
              {isSpanish && <SpanishTag />}
              {isTeen && <TeenTag />}
              {name}
            </div>
            <div className={classNameCombiner([styles.td, styles.duration])}>
              {tab === InboxTab.TODAYS && timestamp && (
                <TimeFromNow timestamp={timestamp} />
              )}
              {tab === InboxTab.ALL && <>{id}</>}
            </div>
          </div>
          <SegmentContent section={section} state={{ ...state, item }} />
        </div>
      </button>
    </div>
  ) : null;
};
const StatusIndicator = ({ isOnline }: { isOnline?: boolean }) =>
  isOnline ? <OnlineIndicator /> : <OfflineIndicator />;

interface SegmentContentProps {
  state: InboxSegmentState & { item: InboxItemState };
  section: InboxSections;
}

function SegmentContent(props: Readonly<SegmentContentProps>) {
  const { state, section } = props;

  const {
    presence,
    item,
    isOpenConvo,
    unreadMessageCount,
    latestWriteTimestamp,
  } = state;
  const contentText = getContentText(item, section, isOpenConvo);

  const { summary, prevAppointment, nextAppointment } = item;
  const {
    terminated = false,
    transferred = false,
    showNewRiskTaskIndicator = false,
  } = item;
  if (section === InboxSections.CONVERSATIONS_AND_TASKS) {
    return (
      <ConversationsAndTasksContent
        presence={presence}
        unreadMessageCount={unreadMessageCount}
        isOpenConvo={isOpenConvo}
        summary={summary}
        previewText={item.taskDetails?.previewText}
        latestWriteTimestamp={latestWriteTimestamp ?? undefined}
      />
    );
  }
  if (section === InboxSections.SCHEDULED || section === InboxSections.PAST) {
    return (
      <ScheduledContent
        nextAppointment={nextAppointment}
        prevAppointment={prevAppointment}
        latestWriteTimestamp={latestWriteTimestamp}
      />
    );
  }
  if (section === InboxSections.CLOSED) {
    const closedStatusLabel = terminated
      ? 'Terminated'
      : transferred
      ? 'Transferred'
      : undefined;
    return <SegmentSubContent text={closedStatusLabel} />;
  }
  if (section === InboxSections.RISKS) {
    const text = (contentText ?? '').split('+')[0]?.trim() ?? '';
    return (
      <SegmentSubContent
        showBlueDot={showNewRiskTaskIndicator}
        text={text}
        textColor="critical"
      />
    );
  }
  if (
    [InboxSections.SCHEDULED_CHECKINS, InboxSections.APPOINTMENTS].includes(
      section,
    )
  ) {
    return (
      <SegmentSubContent
        showCalendarIcon={true}
        text={contentText}
        sessionFormat={item.scheduledSessionFormat}
      />
    );
  }
  if (InboxSections.TASKS === section) {
    return <SegmentSubContent text={contentText} />;
  }
  return null;
}

function getContentText(
  state: InboxItemState,
  section: InboxSections,
  isOpenConvo: boolean,
) {
  const { riskTaskDetails, taskDetails, summary } = state;

  let contentText = taskDetails?.previewText ?? riskTaskDetails?.previewText;
  const isApptOrCheckin = [
    InboxSections.APPOINTMENTS,
    InboxSections.SCHEDULED_CHECKINS,
  ].includes(section);

  if (section === InboxSections.RISKS && riskTaskDetails) {
    contentText = riskTaskDetails.previewText ?? '';
  } else if (isApptOrCheckin) {
    contentText = summary;
  } else if (section === InboxSections.CONVERSATIONS_AND_TASKS && isOpenConvo) {
    contentText = OPEN_CONVERSATION_TEXT;
  }
  return contentText;
}

export const TimeFromNow = React.memo(
  ({ timestamp }: { timestamp: string }) => {
    const [time, setTime] = useState('');
    const timezone = useAppState(({ user }) => user.timezone!);

    const updateTime = () =>
      setTime(formatDateToHumanReadable(timestamp, timezone, true));
    useEffect(() => {
      updateTime();
      const timeInterval = setInterval(updateTime, getInterval(timestamp));
      return () => {
        clearInterval(timeInterval);
      };
    }, [timestamp]);

    return <span>{time}</span>;
  },
);

function getInterval(timestamp: string) {
  const time = Date.now() - new Date(timestamp).getTime();
  const twentyFourHours = 86_400_000;
  const oneHour = 3_600_000;
  const oneMinute = 60_000;
  if (time > twentyFourHours) {
    return twentyFourHours;
  }
  if (time > oneHour) {
    return oneHour;
  }
  if (time > oneMinute) {
    return oneMinute;
  }
  return 30_000;
}
