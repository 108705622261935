import { VaultIds } from './VaultIds';

export class OutOfSessionNoteIds extends VaultIds {
  protected static prefix = 'nonAppointment';

  protected static noteName = 'outOfSessionNotes';

  protected static delimiter = '-';

  static sectionTags(memberId: string, clinicianId: string): string[] {
    return [
      this.myNotesTag(memberId),
      this.memberNotesTag(memberId), // this will allow us to get all notes in 1 query for the member
      this.memberNonAppointmentNotes(memberId), // this will allow us to get out of session + termination in 1 query later
      this.memberPrefixedId(memberId, 'clinician', clinicianId, this.noteName), // allows to get out of session notes for given clinician/member pair
    ];
  }
}
