import React, { MouseEventHandler } from 'react';
import { classNameCombiner } from 'utils';

import styles from './PillButton.module.scss';

interface PillButtonProps {
  text: string;
  // you can use this to override the default styles
  className?: string;
  testId: string;
  onClick: MouseEventHandler;
  imgSrc?: string;
}

export const PillButton = ({
  text,
  className = '',
  onClick,
  testId,
  imgSrc,
}: PillButtonProps) => {
  return (
    <button
      data-testid={testId}
      onClick={onClick}
      className={classNameCombiner([styles.pillButton, className])}
    >
      {text}
      {imgSrc && <img className={styles.image} src={imgSrc} alt={testId} />}
    </button>
  );
};
