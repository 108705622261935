import { VaultLoginScreen as VaultLoginFlow } from '@ginger.io/vault-ui';
import { Loader } from 'shared-components/loader/Loader';
import { LocationState } from 'app/routes/SecureRoute';
import { useAppState, useDispatch } from 'app/state';
import { vaultLoginCompleted } from 'app/state/amplitude/actions/etc';
import { useLogger } from 'app/state/log/useLogger';
import { completeVaultAuth } from 'app/state/user/actions';
import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

export function VaultLoginScreen() {
  const location = useLocation<LocationState>();
  const redirectPath = location?.state?.previousPath || '/';
  const { vaultUserId, firstName, lastName, role } = useAppState(
    ({ user: { vaultUserId, firstName, lastName, role } }) => ({
      firstName,
      lastName,
      role,
      vaultUserId,
    }),
  );
  const dispatch = useDispatch();
  const logger = useLogger();
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    if (vaultUserId)
      logger.info(`VaultLoginScreen: initiating login for ${vaultUserId}`, {
        redirectPath,
      });
  }, [vaultUserId]);

  if (isCompleted) {
    return <Redirect to={redirectPath} />;
  }
  if (!vaultUserId) {
    return <Loader />;
  }
  return (
    <VaultLoginFlow
      firstName={firstName ?? undefined}
      lastName={lastName ?? undefined}
      vaultUserId={vaultUserId}
      onComplete={(jwtToken) => {
        // We want to know care providers' screen sizes and window sizes to help inform designs.
        // We're adding those as props to the Vault login event since that's an event that happens
        // at least once per day for all care providers.
        logger.info(`VaultLoginScreen: completed login for ${vaultUserId}`, {
          redirectPath,
        });
        dispatch(
          vaultLoginCompleted({
            role,
            screenHeight: screen.height,
            screenWidth: screen.width,
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
          }),
        );
        dispatch(completeVaultAuth(jwtToken));
        setIsCompleted(true);
      }}
    />
  );
}
