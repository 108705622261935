import {
  GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions,
  GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions_recurrence as Recurrence,
  GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions_sessions as CoachingSession,
} from '@headspace/carehub-graphql/dist/scheduler/generated/GetMemberUpcomingCoachingSessions';
import React from 'react';

import MultiScheduledSession from './MultiScheduledSession';
import styles from './ScheduledSession.module.scss';
import SingleScheduledSession from './SingleScheduledSession';

interface Props {
  memberTimezone: string | undefined;
  session: GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions;
  onClickCoachingSession: (
    coachingSession: CoachingSession,
    recurrence: Recurrence | null,
  ) => void;
}

const ScheduledSession = ({
  session,
  onClickCoachingSession,
  memberTimezone,
}: Props) => {
  return (
    <div className={styles.sessionContainer}>
      {session.sessions.length > 1 ? (
        <MultiScheduledSession
          memberTimezone={memberTimezone}
          multiSession={session}
          onClickCoachingSession={onClickCoachingSession}
        />
      ) : (
        <SingleScheduledSession
          memberTimezone={memberTimezone}
          session={session.sessions[0]}
          onClickCoachingSession={onClickCoachingSession}
        />
      )}
    </div>
  );
};

export default ScheduledSession;
