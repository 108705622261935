import { ClinicianIntakeChecklist_Version } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/ClinicianIntakeChecklist';
import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { TherapyIntakeSection } from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import { DeleteVaultItemsMutation } from '@ginger.io/vault-ui/src/generated/graphql';
import { CustomError } from 'app/appointments/errorUtils';
import { isGraphQLAuthenticationError } from 'shared-components/error-state/utils';
import { TransientFeatureFlag, useFeatureFlags } from 'hooks/useFeatureFlags';
import { Routes } from 'app/top-nav/Routes';
import { NoteType } from 'app/notes-ui/NoteActions';
import { TherapyIntakeNoteScreenV2 } from 'app/notes-ui/therapy/intake/TherapyIntakeNoteScreenV2';
import { canCreateOrUpdateNote, canLockNote } from 'app/notes-ui/utils';
import { useAppState, useDispatch } from 'app/state';
import { updateSessionState } from 'app/state/features/auth/authSlice';
import { SessionState } from 'app/state/features/auth/types';
import { ILogger } from 'app/state/log/Logger';
import { useLogger } from 'app/state/log/useLogger';
import {
  error as triggerError,
  saving as triggerSaving,
  success as triggerSuccess,
} from 'app/state/request/actions';
import { renderSliceStateResult } from 'app/state/status/RenderSliceState';
import { Status } from 'app/state/status/types/StateSlice';
import { selectUserIdAndRole } from 'app/state/user/selectors';
import { TherapyIntakeNote } from 'app/vault/api/TherapyIntakeNotesAPI';
import { GetAppointmentById_getAppointmentById as Appointment } from 'app/vault/generated/GetAppointmentById';
import { useTherapyIntakeNote } from 'app/vault/hooks/useTherapyIntakeNote';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Action } from 'redux-reloaded/dist/actions';

export interface NoteHeaderItems {
  status: Metadata_NoteStatus;
  noteType: NoteType;
  disabled?: boolean;
  onNoteLocked: () => Promise<void>;
  deleteDraftNote: () => Promise<DeleteVaultItemsMutation>;
  appointment?: Appointment;
}

const noteType = NoteType.THERAPY_INTAKE;

export const onCreateOrUpdateSection = async (props: {
  section: TherapyIntakeSection;
  appointmentId: string;
  createOrUpdateSection: (section: TherapyIntakeSection) => Promise<void>;
  dispatch: (action: Action<unknown>) => void;
  logger: ILogger;
}) => {
  const {
    section,
    appointmentId,
    createOrUpdateSection,
    dispatch,
    logger,
  } = props;

  const queryName = 'TherapyIntakeNote::createOrUpdateSection';
  try {
    dispatch(triggerSaving({ queryName }));
    await createOrUpdateSection(section);
    dispatch(
      triggerSuccess({ queryName, timestamp: new Date().toISOString() }),
    );
  } catch (e) {
    const errorData = {
      appointmentId,
      noteType,
      sectionName: section.name,
    };
    const error = new CustomError(e, e.code ?? '', errorData);
    dispatch(triggerError({ error, queryName }));
    if (isGraphQLAuthenticationError(e)) {
      dispatch(updateSessionState(SessionState.EXPIRED));
    } else {
      logger.error(
        new Error(
          'TherapyIntakeNoteContainerv2.onCreateOrUpdateSection: Unable to create or update section',
        ),
        {
          ...errorData,
          error: e,
        },
      );
    }
  }
};

export function TherapyIntakeNoteContainerv2(props: { appointmentId: string }) {
  const { appointmentId } = props;
  const logger = useLogger();
  const dispatch = useDispatch();

  const userRoleAndId = useAppState(selectUserIdAndRole);
  const { userId } = userRoleAndId;
  const history = useHistory();
  const featureFlags = useFeatureFlags();

  const {
    note,
    isLockable,
    lockNote,
    createOrUpdateSection,
    appointment,
    updateDraftNoteState,
    draftNote,
    deleteDraft,
  } = useTherapyIntakeNote(userId!, appointmentId);

  const createOrUpdateSectionHandler = async (
    section: TherapyIntakeSection,
  ) => {
    return onCreateOrUpdateSection({
      appointmentId,
      createOrUpdateSection,
      dispatch,
      logger,
      section,
    });
  };

  const getNoteHeaderItems = (data: TherapyIntakeNote | null) => {
    return {
      appointment,
      deleteDraftNote: deleteDraft,
      disabled:
        !isLockable ||
        !canLockNote(
          featureFlags,
          appointment,
          userRoleAndId,
          data?.permissions!,
        ),
      noteType,
      onNoteLocked: lockNote,
      status: data!.metadata.status,
    };
  };

  const noteData = note.current.data?.CLINICIAN_CHECKLIST;
  const apptRoute = Routes.APPOINTMENT_NOTES.replace(
    ':appointmentId',
    appointmentId,
  );
  if (
    (noteData && noteData.version !== ClinicianIntakeChecklist_Version.v0) ||
    featureFlags.transientFeatureFlags[
      TransientFeatureFlag.ENABLE_NOTES_EFFICIENCY_CREATION_DISABLED
    ]
  ) {
    history.push(`${apptRoute}/therapy-intake`);
  }

  return renderSliceStateResult(note.current, (data) => (
    <>
      <TherapyIntakeNoteScreenV2
        status={Status.COMPLETE}
        appointmentId={appointmentId}
        memberId={appointment?.member.id}
        note={data!}
        draftNote={draftNote.current.data!}
        onSubmit={createOrUpdateSectionHandler}
        updateDraftNoteState={updateDraftNoteState}
        readOnly={
          !canCreateOrUpdateNote(
            featureFlags,
            appointment,
            userRoleAndId,
            data!.metadata.status,
          )
        }
        noteHeader={getNoteHeaderItems(data)}
      />
    </>
  ));
}
