import { InputBaseComponentProps } from '@mui/material';
import { FormError } from 'shared-components/FormError';
import { Input } from 'shared-components/form-inputs/Input';
import { Size } from 'types/StyleTypes';
import { classNameCombiner } from 'utils';
import React from 'react';

import styles from './NumberInputV2.module.scss';

export function NumberInput(props: {
  name: string;
  label: string;
  value: number;
  error?: string;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  onChange: (value: number) => void;
  tooltip?: string;
  size?: Size;
  inputProps?: InputBaseComponentProps;
}) {
  const { size = Size.SM, inputProps } = props;
  return (
    <div className={classNameCombiner([styles.root])}>
      <div className={styles.control}>
        <Input
          className={classNameCombiner([styles.input, styles[size]])}
          value={`${props.value}`}
          name={props.name}
          type="number"
          variant="outlined"
          onChange={(v) => props.onChange(parseInt(v))}
          disabled={props.disabled}
          placeholder={props.placeholder}
          inputProps={inputProps}
        />
      </div>
      <FormError error={props.error} />
    </div>
  );
}
