import { InboxSections } from 'app/inbox/types';
import { Source } from 'app/state/amplitude/actions/inbox';
import { createAction } from 'redux-reloaded';

enum Actions {
  INITIALIZE_MEMBER_CHART_TABS = 'INITIALIZE_MEMBER_CHART_TABS',
  SET_ACTIVE_TAB = 'SET_ACTIVE_TAB',
  REMOVE_MEMBER_FROM_TAB_LIST = 'REMOVE_MEMBER_FROM_TAB_LIST',
  CLOSE_TAB = 'OPEN_NEW_INBOX_TAB',
  OPEN_TAB = 'OPEN_TAB',
  SELECT_TAB = 'SELECT_TAB',
  REFETCH_MEMBER_DATA = 'REFETCH_MEMBER_DATA',
}

export const initializeMultiTabState = createAction(
  Actions.INITIALIZE_MEMBER_CHART_TABS,
  () => ({}),
);

export const setActiveTab = createAction(
  Actions.SET_ACTIVE_TAB,
  (activeTab: string) => ({
    activeTab,
  }),
);

export const removeMemberFromTabList = createAction(
  Actions.REMOVE_MEMBER_FROM_TAB_LIST,
  (payload: { idToRemove: string; activeTab: string | null }) => ({
    ...payload,
  }),
);

export const closeTab = createAction(
  Actions.CLOSE_TAB,
  (payload: { idToRemove: string; activeTab: string | null }) => payload,
);

export const selectTab = createAction(
  Actions.SELECT_TAB,
  (payload: { memberId: string }) => payload,
);

export const openNewTab = createAction(
  Actions.OPEN_TAB,
  (payload: { source: Source; section: InboxSections; memberId: string }) =>
    payload,
);

export const refetchMemberData = createAction(
  Actions.REFETCH_MEMBER_DATA,
  (payload: { memberId: string }) => ({
    ...payload,
  }),
);
