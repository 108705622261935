import { DateMessage } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/Date';
import moment from 'moment-timezone';

// define a custom locale that shortens relative datetime calculation.
// For example, when we call moment("2020-01-01T00:00:00.000Z").fromNow() it returns "2y" instead of "2 years ago"
moment.defineLocale('shorten-relative-time', {
  relativeTime: {
    M: '1mo',
    MM: '%dmo',
    d: '1d',
    dd: '%dd',
    future: '%s',
    h: '1h',
    hh: '%dh',
    m: '1m',
    mm: '%dm',
    past: '%s',
    s: 'now',
    y: '1y',
    yy: '%dy',
  },
});
moment.locale('en');

export type DateTime = DateMessage & {
  hour: number;
  minute: number;
  second: number;
};

export function formatTime(dateStr: string, timezone: string = 'UTC'): string {
  const date = new Date(dateStr);
  return moment(date).tz(timezone).format('h:mm a');
}

export function formatDate(
  dateString: string,
  timezone: string = 'UTC',
  includeTimezone: boolean = false,
): string {
  const date = moment(new Date(dateString)).tz(timezone);
  const tz = includeTimezone ? ` (${timezone})` : '';
  return `${date.format('M/D/YYYY')}${tz}`;
}

/**
  Receives a datetime string in utc, timezone and format. It then formats it from UTC to given timezone and format
  @param dateString of format YYYY-M-DTHH:M:S e.g 2020-02-02T22:50:00
  @param timezone of a string format e.g UTC
  @param format  date format e.g "M/D/YYYY"
 * */
export function formatDateFromUTC(
  dateString: string,
  timezone: string = 'UTC',
  format: string = 'M/D/YYYY',
): string {
  const date = fromUTCToLocal(dateString, timezone);
  return `${date.format(format)}`;
}

/**
 * Returns a string representing the time difference between the current time and the given date in a human readable format.
 * @param dateString
 * @param timezone
 * @param shortened
 * @returns string
 * @example
 * // sample outputs
 * formatDateToHumanReadable("2020-01-01T00:00:00.000Z") // "2 years ago"
 * formatDateToHumanReadable("2023-01-01T00:00:00.000Z", "America/New_York") // "20 days ago"
 * formatDateToHumanReadable("2022-01-01T00:00:00.000Z") // "2 hours ago"
 * formatDateToHumanReadable("2023-10-10T00:00:00.000Z", "America/New_York") // "in 10 months"
 * formatDateToHumanReadable("2023-10-10T10:00:00.000Z", "America/New_York") // "in 10 hours"
 */
export function formatDateToHumanReadable(
  dateString: string,
  timezone: string = 'UTC',
  shortened = false,
): string {
  const date = moment.utc(dateString).tz(timezone);
  if (shortened) {
    date.locale('shorten-relative-time');
  }
  return date.fromNow();
}

export function formatTimestampToHumanReadable(
  timestampMiliseconds: string,
  timezone: string = 'UTC',
): string {
  const date = moment.unix(Number(timestampMiliseconds) / 1000).tz(timezone);
  return date.fromNow();
}

export function formatDateFromObject(
  dateTimeObject: DateTime,
  timezone: string,
  initialTimezone: string = 'UTC',
  format: string = 'M/D/YYYY',
) {
  // dateTimeObject month field is expected to be between 1-12
  // and moment month field is expected to be between 0-11
  return moment
    .tz({ ...dateTimeObject, month: dateTimeObject.month - 1 }, initialTimezone)
    .tz(timezone)
    .format(format);
}

export function formatDateWithDayOfWeek(
  dateString: string,
  timezone: string = 'UTC',
  includeTimezone: boolean = false,
): string {
  const date = moment(new Date(dateString)).tz(timezone);
  const tz = includeTimezone ? ` (${timezone})` : '';
  return `${date.format('dddd, MMMM DD, YYYY')}${tz}`;
}

export function formatDateTime(
  dateString: string,
  timezone: string = 'UTC',
): string {
  const date = moment(new Date(dateString)).tz(timezone);
  const today = moment().tz(timezone);
  if (date.isSame(today, 'day')) {
    return `${date.format('h:mm a')}`;
  }
  return `${date.format('M/DD, h:mm a')}`;
}

export const formatDateTimeWithSeconds = 'M/D/YYYY h:mm:ssa z';

export function formatTimestampWithTz(
  dateTime: string,
  timezone: string,
  includeSeconds: boolean = false,
) {
  const date = moment(new Date(dateTime)).tz(timezone);
  return date.format(
    includeSeconds ? formatDateTimeWithSeconds : 'M/D/YYYY h:mma z',
  );
}

export function formatNoteTimestampWithTz(dateTime: string, timezone: string) {
  const date = moment(new Date(dateTime)).tz(timezone);
  return date.format('MMM D, YYYY h:mma z');
}

export function daysBetweenCurrentDate(
  date: string,
  timezone: string = 'UTC',
): number {
  const start = moment(new Date(date)).tz(timezone).startOf('days');
  const end = moment().tz(timezone).startOf('days');
  return Math.abs(start.diff(end, 'days'));
}

export function getTimezone(timezone: string | null) {
  return timezone || 'UTC';
}

export function fromUTCToLocal(
  dateTime: string,
  timezone: string,
): moment.Moment {
  return moment.tz(dateTime, 'UTC').tz(timezone);
}

export function getAgeFromDateOfBirth(dateString: string): number {
  return moment().diff(dateString, 'years', false);
}

export function formatDatetimeStr(
  dateString: string,
  format: string,
  timezone: string = 'UTC',
): string {
  const date = moment(new Date(dateString)).tz(timezone);
  return `${date.format(format)}`;
}

export const getFormattedDateAndTimeForTooltips = (
  date: moment.Moment,
  timezone: string,
): string => {
  return date.tz(timezone).format('MMM D, YYYY · h:mma z');
};

export const getFormattedDate = (
  date: moment.Moment,
  timezone: string,
): string => {
  return date.tz(timezone).format('MMM D, YYYY');
};
