import { AnyAction } from 'redux';
import { Action } from 'redux-reloaded';

import { Config, UserDetails } from './config';
import { ReactAmplitudeClient } from './ReactAmplitudeClient';

const defaultConfig: Config = {
  apiKey: 'api-123',
  appName: 'lib-stub',
  fetchUser: async () => ({
    id: 'user-123',
  }),
};

export class StubAmplitudeClient implements ReactAmplitudeClient {
  events: AnyAction[] = [];

  user: UserDetails | undefined;

  constructor(private config: Config = defaultConfig) {}

  async logEvent<T>(event: Action<T>) {
    this.user = await this.config.fetchUser();
    this.events.push(event);
    console.debug(`Amplitude: ${event.type}`, event.payload);
  }
}
