import { Loader } from '@ginger.io/core-ui';
import { maybeParseActivityId } from 'app/patients/tabs/content//maybeParseActivityId';
import styles from 'app/patients/tabs/content/AddHomeworkDialog.module.scss';
import { useAssignHomeworkMutation } from 'app/patients/tabs/content/UseAssignHomeworkMutation';
import {
  addHomeworkButtonClicked,
  contentUrlPastedIntoModal,
  invalidContentUrlAdded,
} from 'app/state/amplitude/actions/content';
import { useLogger } from 'app/state/log/useLogger';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { assignHomeworkResultStatus } from './types';

export default function AddHomeworkDialog(props: {
  memberId: string;
  onClose: () => void;
}) {
  const urlInputRef = useRef(document.createElement('input'));
  const [error, setError] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const logger = useLogger();
  const assignHomework = useAssignHomeworkMutation();

  const setErrMessage = (reason: assignHomeworkResultStatus) => {
    switch (reason) {
      case assignHomeworkResultStatus.IS_DUPLICATED:
        setError(
          'This content has already been recommended to this member. Please select another recommendation.',
        );
        break;
      case assignHomeworkResultStatus.IS_COMPLETED:
        setError(
          'This content has already been completed by this member. Please select another recommendation.',
        );
        break;
      case assignHomeworkResultStatus.NOT_ALLOWED:
        setError(
          "This URL is invalid. Please confirm the member's content access in the Benefits card under the Details tab.",
        );
        break;
      default:
        setError(
          'Unexpected error happened, Please check your link and try again',
        );
        logger.error(
          new Error(`AddHomeworkDialog: unexpected error assigning content`),
          {
            reason,
            url: urlInputRef.current.value,
            memberId: props.memberId,
          },
        );
        break;
    }
  };

  const handlePaste = (event: React.ClipboardEvent) => {
    const pastedData = event.clipboardData.getData('text');
    dispatch(contentUrlPastedIntoModal({ content_url: pastedData }));
  };

  const createHomework = () => {
    const url = urlInputRef.current.value;
    const matchedSlug = maybeParseActivityId(url);
    if (!matchedSlug) {
      setError('This URL is invalid. Please paste a URL from the Library.');
      dispatch(invalidContentUrlAdded({ invalid_url: url }));
      return;
    }
    setError('');
    setLoading(true);
    dispatch(addHomeworkButtonClicked({ content_url: url }));
    assignHomework(matchedSlug, 'content', props.memberId)
      .then((res) => {
        if (res.status === assignHomeworkResultStatus.OK) {
          props.onClose();
        } else {
          setErrMessage(res.status);
        }
      })
      .catch((error) => {
        logger.error(
          new Error(
            'AddHomeworkDialog: got server error when assigning content.',
          ),
          {
            error,
            url,
            memberId: props.memberId,
          },
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.addCirclePopover}>
      <div className={styles.addCirclePopoverText}>
        Copy and paste a URL from the Library to add homework
      </div>
      <div className={styles.addCirclePopoverTextbox}>
        <input
          className={styles.urlBox}
          type="text"
          placeholder="http://ginger.io/library/shaping..."
          ref={urlInputRef}
          onPaste={handlePaste}
          data-testid="content_url_input"
        />
      </div>
      <div hidden={!isLoading} className={styles.addCirclePopoverTextbox}>
        <Loader />
      </div>
      <div hidden={!error} className={styles.addCirclePopoverError}>
        {error}
      </div>
      <div className={styles.addCirclePopoverButton}>
        <button
          onClick={createHomework}
          className={styles.addButton}
          data-testid="add_homework_button"
        >
          Add Homework
        </button>
      </div>
    </div>
  );
}
