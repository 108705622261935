import { ActionsTaken } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/ActionsTaken';
import { BooleanOption } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/BooleanOption';
import { Typography } from '@mui/material';
import {
  AdditionalRiskActions,
  RiskFormComponent,
  RiskFormProps,
} from 'app/coach/coach-notes/CoachNotesTypes';
import { CardTextAreaField } from 'app/coach/coach-notes/note-detail-view/note-fields';
import { actionsTaken } from 'app/coach/coach-notes/note-detail-view/note-templates/fieldDefs';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { useRiskForm } from 'app/coach/coach-notes/useRiskForm';
import { MarkdownContent } from 'shared-components/markdown-content/MarkdownContent';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import { BooleanRadioField } from 'app/notes-ui/forms/form-controls/BooleanRadioField';
import React from 'react';

const CRR_URL = `https://form.asana.com/?k=30TKezioGBgUWHkQ86orBQ&d=1290778030743`;
const MARKDOWN_CRR_FILLED = `#### [CRR](${CRR_URL}) filed?`;

export enum FieldLabels {
  CRR_FILED = 'CRR filed?',
  CLINICAL_SERVICES_RECOMMENDED = 'Clinical services recommended?',
  REFERRED_FOR_THERAPY = 'Referred for therapy?',
  CONNECTED_TO_MEMBER_SUPPORT = 'Connected to Member Support?',
  RESOURCES_SHARED = 'Resources shared?',
  CONSULTED_COACH_MANAGER = 'Consulted with Coach Manager?',
  CLINICIAN_NOTIFED = 'Was clinician notified?',
  ON_CALL_INVOLVED = 'Was an on-call involved?',
  ON_CALL_PERSON = 'Who? *',
}

const RISK_AND_POLICIES_URL =
  'https://docs.google.com/document/d/1dXnlvdzdVo_axEOSj2ZlfXJh6oYy4nAadYy1YWSAp6s/';
const DESCRIPTION = `For required actions based on risk type, consult the [Risk Policies & Protocols](${RISK_AND_POLICIES_URL}).`;

enum Placeholders {
  ON_CALL_PERSON = 'Full name...',
}

const TOOLTIP_EVENT_LABEL = 'Coach Notes > Actions Taken';

export const ActionsTakenForm: RiskFormComponent<ActionsTaken> = (
  props: RiskFormProps<ActionsTaken>,
) => {
  const { readOnly, inline, initialValue, onChange } = props;
  const { fields } = useRiskForm<ActionsTaken>({
    initialValue,
    onChange,
    riskType: AdditionalRiskActions.ACTIONS_TAKEN,
    validationSchema: actionsTaken,
  });

  return (
    <div className={inline ? styles.inlineRiskSection : styles.cardSection}>
      <Typography className={styles.actionsTakenTitle}>
        Actions Taken
      </Typography>
      <MarkdownContent className={styles.actionsTakenDescription}>
        {DESCRIPTION}
      </MarkdownContent>
      <FieldLabel
        className={styles.label}
        labelText={MARKDOWN_CRR_FILLED}
        withMarkdown={true}
      />
      <BooleanRadioField
        disabled={readOnly}
        name={FieldLabels.CRR_FILED}
        value={fields.crrFilled.value}
        onChange={fields.crrFilled.setValue}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.CLINICAL_SERVICES_RECOMMENDED}
      />
      <BooleanRadioField
        disabled={readOnly}
        name={FieldLabels.CLINICAL_SERVICES_RECOMMENDED}
        value={fields.clinicalServicesRecommended.value}
        onChange={fields.clinicalServicesRecommended.setValue}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.REFERRED_FOR_THERAPY}
      />
      <BooleanRadioField
        disabled={readOnly}
        name={FieldLabels.REFERRED_FOR_THERAPY}
        value={fields.referredForTherapy.value}
        onChange={fields.referredForTherapy.setValue}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.CONNECTED_TO_MEMBER_SUPPORT}
      />
      <BooleanRadioField
        disabled={readOnly}
        name={FieldLabels.CONNECTED_TO_MEMBER_SUPPORT}
        value={fields.connectedToMemberSupport.value}
        onChange={fields.connectedToMemberSupport.setValue}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.RESOURCES_SHARED}
      />
      <BooleanRadioField
        disabled={readOnly}
        name={FieldLabels.RESOURCES_SHARED}
        value={fields.resourcesShared.value}
        onChange={fields.resourcesShared.setValue}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.CONSULTED_COACH_MANAGER}
      />
      <BooleanRadioField
        disabled={readOnly}
        name={FieldLabels.CONSULTED_COACH_MANAGER}
        value={fields.consultedCoachManager.value}
        onChange={fields.consultedCoachManager.setValue}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.CLINICIAN_NOTIFED}
      />
      <BooleanRadioField
        disabled={readOnly}
        name={FieldLabels.CLINICIAN_NOTIFED}
        value={fields.clinicianNotified.value}
        onChange={fields.clinicianNotified.setValue}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.ON_CALL_INVOLVED}
      />
      <BooleanRadioField
        disabled={readOnly}
        name={FieldLabels.ON_CALL_INVOLVED}
        value={fields.onCallInvolved.value}
        onChange={fields.onCallInvolved.setValue}
      />

      {fields.onCallInvolved.value === BooleanOption.yes && (
        <>
          <FieldLabel
            className={styles.label}
            labelText={FieldLabels.ON_CALL_PERSON}
          />
          <CardTextAreaField
            name="coachNotesActionTaken"
            field={fields.onCallPerson}
            tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.ON_CALL_PERSON}`}
            tooltipText=""
            placeholder={Placeholders.ON_CALL_PERSON}
            readOnly={readOnly}
          />
        </>
      )}
    </div>
  );
};
