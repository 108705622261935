import { Assessment_CPTCode as CPTCode } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/intake/Assessment';
import { PsychiatrySectionName } from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import Labels from 'app/notes-ui/strings/en.json';
import { psychiatryIntakeSectionToLabel } from 'app/notes-ui/utils';
import { ILogger } from 'app/state/log/Logger';
import { PsychiatryIntakeNote } from 'app/vault/api/PsychiatryIntakeNotesAPI';

import { EnumOption } from '../types';
import { NotePDFBuilder, NotePDFBuilderImpl } from './NotePDFBuilder';
import { PsychiatryNotePDF } from './PsychiatryNotePDF';
import { NoteHeader } from './types';
import { buildAssessmentCodeSets } from './utils';

export class PsychiatryIntakeNotePDF extends PsychiatryNotePDF<
  PsychiatryIntakeNote
> {
  constructor(
    protected note: PsychiatryIntakeNote,
    protected noteHeader: NoteHeader,
    protected diagnosisCodesOptions: EnumOption[],
    protected logger: ILogger,
    protected password?: string,
    protected enableNewPdfFont?: boolean,
    protected timezone = 'America/Los_Angeles',
    protected pdfBuilder: NotePDFBuilder = new NotePDFBuilderImpl(
      password,
      psychiatryIntakeSectionToLabel,
      enableNewPdfFont,
    ),
  ) {
    super(
      note,
      noteHeader,
      timezone,
      diagnosisCodesOptions,
      logger,
      pdfBuilder,
    );
  }

  getPDF(): typeof PDFDocument {
    this.header();
    this.clinicianChecklist();
    this.chiefComplaint();
    this.psychiatryReviewOfSystem();
    this.medicalReviewOfSystem();
    this.safetyIntake();
    this.psychiatricHistory();
    this.allergies();
    this.medicalHistory();
    this.familyHistory();
    this.socialHistory();
    this.substanceAbuse();
    this.mentalStatusExam();
    this.assessment();
    this.treatmentPlan();
    this.collaborationPlan();
    this.amendments(PsychiatrySectionName.AMENDMENTS);

    return this.pdfBuilder.build();
  }

  private assessment() {
    const sectionLabel = PsychiatrySectionName.ASSESSMENT;

    this.pdfBuilder.section(
      sectionLabel,
      () => {
        const assessment = this.note.ASSESSMENT;
        if (!assessment) return false;

        if (assessment) {
          buildAssessmentCodeSets(
            this.pdfBuilder,
            CPTCode,
            this.diagnosisCodesOptions,
            assessment.codeSets,
            assessment.cptCode,
            assessment.icd10Codes,
            this.logger,
          )
            .padding()
            .longTextAnswer(
              Labels.FORMULATION_AND_MEDICAL_DECISION_MAKING,
              assessment.formulationAndMedicalDecisionMaking,
            );
        }

        return true;
      },
      `No ${sectionLabel} data`,
    );
  }
}
