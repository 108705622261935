import { shouldShowNewRiskTaskIndicator } from 'app/inbox/utils';
import { useAppState } from 'app/state';
import { updateRiskAlertIndicator } from 'app/state/inbox/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export function useUpdateRiskAlertIndicator(memberId: string) {
  const dispatch = useDispatch();
  const shouldUpdateRiskTask = useAppState(({ inbox }) => {
    const inboxItem = inbox.inboxItems[memberId];
    return (
      (inboxItem?.showNewRiskTaskIndicator ?? false) ||
      shouldShowNewRiskTaskIndicator(inboxItem?.riskTaskDetails?.tasks ?? [])
    );
  });

  useEffect(() => {
    if (shouldUpdateRiskTask) dispatch(updateRiskAlertIndicator({ memberId }));
  }, [memberId, shouldUpdateRiskTask]);
}
