import { createReducer } from 'redux-reloaded';

import { updateCountDown, updateTimedSend } from './actions';
import { getInitialTimedSendState, State } from './schema';

export const reducer = createReducer<State>(getInitialTimedSendState());

reducer.on(updateCountDown, (state, { payload }) => {
  const { channelId, newValue } = payload;
  return {
    ...state,
    countDownMap: {
      ...state.countDownMap,
      [channelId]: {
        ...state.countDownMap[channelId],
        seconds: newValue,
      },
    },
  };
});

reducer.on(updateTimedSend, (state, { payload }) => {
  const { channelId, countDown, timeoutId, message } = payload;

  return {
    ...state,
    countDownMap: {
      ...state.countDownMap,
      [channelId]: countDown,
    },
    timedSends: {
      ...state.timedSends,
      [channelId]: {
        message,
        timeout: timeoutId,
      },
    },
  };
});
