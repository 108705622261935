import { SentryClient } from './SentryClient';

export class StubSentryClient implements SentryClient {
  errors: { error: Error; additionalData?: Record<string, any> }[] = [];

  async captureException(
    error: Error,
    additionalData?: Record<string, any>,
  ): Promise<void> {
    this.errors.push({ additionalData, error });
  }
}
