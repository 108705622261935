import { AppointmentsAndNotesCursor } from 'app/appointments/AppointmentsAndNotesAPIContext';
import {
  CareProviderNoteType,
  CoachNotesItem,
  CoachNoteType,
  DecodedNotes,
  DropInNoteType,
  LegacyNoteType,
  NavigationParams,
  NotesItemResponse,
  SessionInfo,
  ShareableClinicianNoteType,
} from 'app/coach/coach-notes/CoachNotesTypes';
import {
  DropInNote,
  FollowUpNote,
  InitialConsult,
  LegacyNoteTemplate,
  OutreachAttempt,
  QuickNote,
  RiskAssessment,
  SharedClinicalNote,
} from 'app/coach/coach-notes/note-detail-view/note-templates';
import {
  getNoteDetails,
  getNoteDetailsFromQueryString,
  getStartDateAndEndTime,
  isDropInNoteType,
  isLegacyNoteType,
  isShareableSubsectionType,
} from 'app/coach/coach-notes/utils';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { NoteDetailHeader } from 'app/sortable-table/note-header/NoteDetailHeader';
import { useAppState } from 'app/state';
import { formatName } from 'utils';
import moment from 'moment';
import React from 'react';

import styles from './NoteDetailView.module.scss';

export const NoteDetailView = ({
  noteType,
  readOnly,
  notes,
  updateDraftNoteState,
  setQueryParams,
  setCursor,
  sessionInfo,
  selectedNote,
  showClinicalView,
  'data-testid': testId = 'noteDetailView',
}: {
  noteType: CareProviderNoteType;
  readOnly: boolean;
  updateDraftNoteState?: (noteState: CoachNotesItem) => Promise<void>;
  setQueryParams?: (params: NavigationParams) => void;
  notes?: DecodedNotes;
  sessionInfo?: SessionInfo;
  selectedNote?: CoachNotesItem;
  'data-testid'?: string;

  showClinicalView?: boolean;
  /**
   * @property Used in the clinical view for shared coach notes
   */
  setCursor?: (params: AppointmentsAndNotesCursor) => void;
}): React.ReactElement | null => {
  const { firstName, lastName, timezone } = useAppState(
    ({ user: { firstName, lastName, timezone } }) => ({
      firstName,
      lastName,
      timezone: timezone ?? 'UTC',
    }),
  );

  const {
    enable_drop_in_notes: enableDropInNotes,
  } = useFeatureFlags().transientFeatureFlags;
  const {
    startDate: draftStartDate,
    dateString: draftdateString,
  } = getStartDateAndEndTime(timezone, moment().format());
  const startDate = selectedNote?.startDate
    ? selectedNote.startDate
    : draftStartDate;
  const dateString = selectedNote?.dateString
    ? selectedNote.dateString
    : draftdateString;
  const hasData = !!Object.keys(selectedNote?.data || {}).length;
  const noteDetails = hasData
    ? getNoteDetails(selectedNote?.data, selectedNote?.noteType)
    : getNoteDetailsFromQueryString(noteType);

  const _updateDraftNoteState = async (updatedNote: CoachNotesItem) => {
    await updateDraftNoteState?.(updatedNote);
  };

  const _setQueryParams = (note: NotesItemResponse | null) => {
    const index = noteIds.findIndex((id) => id === note?.id) || 0;
    setQueryParams?.({ index });
  };

  const _setCursor = (note: NotesItemResponse | null) => {
    setCursor?.(null); // todo: this is currently set to null until navigation is implemented on the clinical side
  };

  const noteIds = Object.keys(notes || {});
  const index = noteIds.findIndex((id) => id === selectedNote?.id) || 0;
  const navigation = {
    index,
    next: index + 1 < noteIds.length ? { id: noteIds[index + 1] } : null,
    previous: index - 1 > -1 ? { id: noteIds[index - 1] } : null,
  };
  const backToListHandler = () =>
    showClinicalView ? _setCursor(null) : setQueryParams?.({ index: null });

  return (
    <div data-testid={testId} key={selectedNote?.id}>
      <NoteDetailHeader
        className={styles.coachHeader}
        careTeamMember={
          selectedNote?.createdBy
            ? selectedNote.createdBy
            : formatName(firstName, lastName)
        }
        startDate={startDate}
        dateString={dateString}
        details={noteDetails}
        navigation={navigation}
        navigateToCursor={showClinicalView ? _setCursor : _setQueryParams}
        backToListHandler={backToListHandler}
        showClinicalView={showClinicalView}
        isCoachNote={true}
      />
      {noteType === CoachNoteType.FOLLOW_UP && (
        <FollowUpNote
          noteId={selectedNote?.id}
          latestSessionCount={sessionInfo?.latestSessionCount ?? 0}
          readOnly={readOnly}
          onStateChange={_updateDraftNoteState}
        />
      )}
      {noteType === CoachNoteType.INITIAL_CONSULT && (
        <InitialConsult
          noteId={selectedNote?.id}
          latestSessionCount={sessionInfo?.latestSessionCount ?? 0}
          readOnly={readOnly}
          onStateChange={_updateDraftNoteState}
        />
      )}
      {noteType === CoachNoteType.QUICK_NOTE && (
        <QuickNote readOnly={readOnly} onStateChange={_updateDraftNoteState} />
      )}
      {noteType === CoachNoteType.OUTREACH_ATTEMPT && (
        <OutreachAttempt
          readOnly={readOnly}
          onStateChange={_updateDraftNoteState}
        />
      )}
      {noteType === CoachNoteType.RISK && (
        <RiskAssessment
          readOnly={readOnly}
          onStateChange={_updateDraftNoteState}
        />
      )}
      {isDropInNoteType(noteType) && enableDropInNotes && (
        <DropInNote
          noteType={noteType as DropInNoteType}
          readOnly={readOnly}
          onStateChange={_updateDraftNoteState}
          navigateTo={setQueryParams}
        />
      )}
      {isShareableSubsectionType(noteType) && (
        <SharedClinicalNote
          noteType={noteType as ShareableClinicianNoteType}
          readOnly={true}
        />
      )}
      {isLegacyNoteType(noteType) && (
        <LegacyNoteTemplate
          noteType={noteType as LegacyNoteType}
          readOnly={true}
        />
      )}
    </div>
  );
};
