import { Avatar } from '@ginger.io/core-ui';
import {
  GetMemberById,
  GetMemberByIdVariables,
} from '@headspace/carehub-graphql/dist/queries/generated/GetMemberById';
import { getMemberQuery } from '@headspace/carehub-graphql/dist/queries/GetMember';
import { AppBar } from '@mui/material';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useQuery } from 'hooks/useQuery';
import moment from 'moment/moment';
import React from 'react';
import { AppMutationState } from 'shared-components/AppMutationState';
import { TooltipWithRedux } from 'shared-components/tooltip/TooltipWithRedux';
import { classNameCombiner, formatPronouns } from 'utils';

import headerStyles from './header.module.scss';
import HeaderName from './HeaderName';
import styles from './MemberHeaderBar.module.scss';
import { MemberHeaderSurvey } from './MemberHeaderSurvey';
import { TagsAndDemographics } from './TagsAndDemographics';

interface MemberHeadProps {
  memberId: string;
  'data-testid'?: string;
}

export function MemberHeaderBar({
  memberId,
  'data-testid': testId = 'memberHeaderBar',
}: MemberHeadProps) {
  const {
    enable_tooltip_label_underline,
  } = useFeatureFlags().transientFeatureFlags;
  return useQuery<GetMemberById, GetMemberByIdVariables>(
    ({ getMember }) => {
      if (getMember) {
        const firstName = getMember.preferredFirstName || getMember.firstName;
        const lastName = getMember.preferredLastName || getMember.lastName;
        const pronouns = getMember.pronouns
          ? formatPronouns(getMember.pronouns)
          : null;
        const { dateOfBirth } = getMember;
        const age = dateOfBirth
          ? moment().diff(dateOfBirth, 'years', false)
          : null;
        const avatarSrc = getMember.profilePicture?.original || undefined;

        const demographicsTopLine = (
          <div className={styles.demographicsTop} data-testid="header-top-line">
            <HeaderName firstName={firstName} lastName={lastName} />
            {pronouns ? (
              <span
                data-testid="member-header-pronouns"
                className={styles.demographicsItem}
              >
                {pronouns}
              </span>
            ) : null}
            {age ? (
              <TooltipWithRedux
                labelForAnalytics="Member Header: Date of Birth"
                title={
                  <span className={styles.dobTooltip}>
                    DOB: {moment(dateOfBirth).format('MM/DD/YY')}
                  </span>
                }
              >
                <span
                  data-testid="member-header-age"
                  className={classNameCombiner([
                    styles.demographicsItem,
                    enable_tooltip_label_underline && dateOfBirth
                      ? headerStyles.underline
                      : '',
                  ])}
                >
                  {age.toString()}
                </span>
              </TooltipWithRedux>
            ) : null}
            <span className={styles.mutationState}>
              <AppMutationState />
            </span>
          </div>
        );

        return (
          <AppBar
            position="sticky"
            elevation={0}
            className={`${styles.bar} ${styles.memberHeader}`}
            data-testid={testId}
          >
            <div className={styles.content}>
              <Avatar
                src={avatarSrc}
                className={styles.avatar}
                firstName={firstName || ''}
                lastName={lastName || ''}
                includeLastName={true}
              />
              <div className={styles.demographics}>
                {demographicsTopLine}
                <TagsAndDemographics
                  getMember={getMember}
                  memberId={memberId}
                />
              </div>
              <MemberHeaderSurvey memberId={memberId} />
            </div>
          </AppBar>
        );
      }
      return <div data-testid="memberHeaderQueryError" />;
    },
    getMemberQuery,
    { variables: { input: { id: memberId } } },
  );
}
