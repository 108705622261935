import { InboxItemState, InboxSections } from 'app/inbox/types';
import { isRiskTask, shouldShowNewRiskTaskIndicator } from 'app/inbox/utils';
import moment from 'moment';

import { updateInboxItems } from '../actions';
import {
  TaskUpdate,
  TaskUpdate_bulkUpdateCareProviderTask_results,
  TaskUpdateVariables,
} from './generated/TaskUpdate';
import { updateRiskTaskIndicatorMutation } from './queries';
import { ActionHandler } from './types';

export const onUpdateRiskAlertIndicator = async ({
  action,
  redux,
  context,
}: ActionHandler<{ memberId: string }>) => {
  const {
    inbox: { inboxItems, tabSections },
    multiTab: { openTabs, activeTab },
  } = redux.getState();
  const { memberId } = action.payload;
  const inboxItem = inboxItems[memberId];
  const { logger } = context.services;
  if (!inboxItem) {
    if (Object.keys(inboxItems).length > 0) {
      logger.warning(
        `updateRiskAlertIndicator: InboxItem for member id ${memberId} not found in state`,
        {
          activeTab,
          openTabs,
          tabSections,
        },
      );
    }
    return;
  }
  logger.debug(`updateRiskAlertIndicator: running check memberId ${memberId}`);
  if (
    !inboxItem.showNewRiskTaskIndicator ||
    !tabSections[InboxSections.RISKS].ids.has(memberId)
  ) {
    logger.debug(`updateRiskAlertIndicator: Nothing to do`, {
      activeTab,
      memberInRiskSection: tabSections[InboxSections.RISKS].ids.has(memberId),
      openTabs,
      showNewRiskTaskIndicator: inboxItem.showNewRiskTaskIndicator,
      tabSections,
    });
    return;
  }
  const tasks = inboxItem.riskTaskDetails?.tasks ?? [];
  try {
    const variables = {
      input: {
        tasks: tasks
          .filter((task) => !task.hasViewed && isRiskTask(task))
          .map((task) => ({
            id: task.id,
            viewAt: moment().utc().toISOString(),
          })),
      },
    };

    const { data, errors } = await context.services.apollo.mutate<
      TaskUpdate,
      TaskUpdateVariables
    >({
      mutation: updateRiskTaskIndicatorMutation,
      variables,
    });

    if (errors) {
      logger.error(
        new Error('updateRiskAlertIndicator: Unable to risk tasks indicator'),
        { errors },
      );
    }

    const results = data?.bulkUpdateCareProviderTask?.results;

    if (!results) return;

    const {
      updateRiskTasks,
      updateTasks,
      failedRequest,
    } = processUpdateRiskResult(results, inboxItem);

    if (failedRequest.length > 0) {
      logger.warning('updateRiskAlertIndicator: Some request failed', {
        failedRequest,
      });
    }
    const updatedInboxItem = {
      ...inboxItem,
      riskTaskDetails: {
        ...inboxItem.riskTaskDetails,
        tasks: updateRiskTasks,
      },
      showNewRiskTaskIndicator: shouldShowNewRiskTaskIndicator(updateRiskTasks),
      taskDetails: {
        ...inboxItem.taskDetails,
        tasks: updateTasks,
      },
    };
    redux.dispatch(updateInboxItems([updatedInboxItem]));
  } catch (e) {
    logger.error(
      new Error(
        'updateRiskAlertIndicator: Error occurred while updating risk task indicator',
      ),
      {
        error: e,
        memberId,
      },
    );
  }
};

export function processUpdateRiskResult(
  results: TaskUpdate_bulkUpdateCareProviderTask_results[],
  inboxItem: InboxItemState,
) {
  const mapped: Record<string, boolean> = {};
  const failedRequest: string[] = [];
  results.forEach((result) => {
    const id = result.task?.id ?? '';
    if (result.ok && result.task) {
      mapped[id] = result.task.hasViewed;
    }
    if (result.error) {
      failedRequest.push(`Task: ${id}, Error: ${result.error}`);
    }
  });

  const updateRiskTasks = (inboxItem.riskTaskDetails?.tasks ?? []).map(
    (task) => ({
      ...task,
      hasViewed: mapped[task.id] ?? task.hasViewed ?? false,
    }),
  );
  const updateTasks = (inboxItem.taskDetails?.tasks ?? []).map((task) => {
    if (task.id in mapped) return { ...task, hasViewed: mapped[task.id] };
    return task;
  });
  return { failedRequest, updateRiskTasks, updateTasks };
}
