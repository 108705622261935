/* # # # # # # # # # #
 * THIS FILE IS A EXACT COPY OF LISTENER'S maybeParseActiviyId.tsx
 * https://github.com/HeadspaceMeditation/listener-server/blob/77ae9ec9284779acbb4ee44efc665506febe1155/build/src/portal/content/maybeParseActivityId.tsx
 * # # # # # # # # # #
 */
const activityRegex = /^https:\/\/.+ginger\.(?:io|dev).+\/activities\/([0-9a-zA-Z_\-+]+)/;

/** When a coach copy/pastes an activity url from content.ginger.io to share with a member,
 *  Listener's chatbox attempts to parse out the activity's "id" from the url.
 *
 *  This is just a helper function to handle that parsing, which was extracted from portal.js
 *  so we could test it independently.
 */
export function maybeParseActivityId(url: string): string | undefined {
  const matchedParts = url.match(activityRegex);
  if (matchedParts) {
    return matchedParts[1];
  }
}
