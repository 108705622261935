import {
  SubstanceUse_CurrentSubstanceUse,
  SubstanceUse_PastSubstanceUse,
  SubstanceUse_SubstanceType,
  SubstanceUse_SubstanceUseLineItem as SubstanceUseLineItem,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import { TableCell } from '@mui/material';
import { V2Table } from 'shared-components/table/V2Table';
import { V2TableBody } from 'shared-components/table/V2TableBody';
import { V2TableHead } from 'shared-components/table/V2TableHead';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { NoteTooltip } from 'app/notes-ui/forms/form-controls/NoteTooltip';
import { isEmpty } from 'app/notes-ui/shared/ValidationRules';
import React, { useMemo } from 'react';

import { substances } from './constants';
import { OnChangeHandler, SubstanceTableRow } from './SubstanceTableRow';
import { getSubstanceUseDescription } from './utils';

type Props = {
  includeStoppedUse: boolean;
  initialValue?: (
    | SubstanceUse_CurrentSubstanceUse
    | SubstanceUse_PastSubstanceUse
  )[];
  onChange: OnChangeHandler;
  disabled?: boolean;
};

const tooltip =
  'Please include duration, frequency, and amount of substance used. Note any tolerance or withdrawal here as well';

export function SubstanceTable(props: Props) {
  const { initialValue = [] } = props;
  const columnLabels = [
    { label: '' },
    { label: 'Substance' },
    { label: 'Details & Duration', tooltip },
  ];

  const columns = columnLabels.map(({ label, tooltip }) => (
    <TableCell key={`substance-label-${label}`}>
      {label} {tooltip && <NoteTooltip tooltip={tooltip} />}
    </TableCell>
  ));

  const initialValues = useMemo(() => {
    const rowsBySubstance = {} as {
      [substance in SubstanceUse_SubstanceType]: SubstanceUseLineItem;
    };
    initialValue.forEach(({ substance }) => {
      if (substance !== undefined) {
        rowsBySubstance[substance.substanceType] = substance;
      }
    });
    return rowsBySubstance;
  }, [initialValue]);

  const rows = substances.map((s, i) => {
    let initialValue = initialValues[s];
    if (initialValue && isEmpty(initialValue.description)) {
      initialValue = {
        ...initialValues[s],
        description: getSubstanceUseDescription(initialValues[s]),
      };
    }

    return (
      <SubstanceTableRow
        key={`substance-${s}`}
        substance={s}
        initialValue={initialValue}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    );
  });

  return (
    <NoteFormControlLabel data-testid="title" label="">
      <V2Table>
        <V2TableHead>{columns}</V2TableHead>
        <V2TableBody>{rows}</V2TableBody>
      </V2Table>
    </NoteFormControlLabel>
  );
}
