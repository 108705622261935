import {
  arrayField,
  booleanField,
  field,
  FieldDefinitions,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  TreatmentPlan,
  TreatmentPlan_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/TreatmentPlan';
import { TreatmentGoal } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/TreatmentGoal';
import {
  PsychiatrySectionName,
  TreatmentPlanSection,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { Width } from 'types/StyleTypes';
import {
  NumberField,
  TextAreaField,
  TextBoxField,
  YesOrNoField,
} from 'app/notes-ui/forms/fields';
import { BooleanRadioField } from 'app/notes-ui/forms/form-controls/BooleanRadioField';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import Labels from 'app/notes-ui/strings/en.json';
import {
  AUTO_SAVE_DRAFT_DELAY,
  getDefaultEnumValue,
  showComplianceFields,
} from 'app/notes-ui/utils';
import React from 'react';

type Props = {
  appointmentId: string;
  onSubmit: (data: TreatmentPlanSection) => void;
  updateDraftNoteState: (data: TreatmentPlanSection) => void;
  disabled?: boolean;
  savingNote?: boolean;
  initialValue?: TreatmentPlan;
  noteLastUpdatedAt?: string;
};
export const fieldDefs: FieldDefinitions<TreatmentPlan> = {
  appointmentId: field(),
  appointmentOffered: field({ rules: [] }),
  approachesUpdateComments: stringField({ rules: [] }),
  followUp: stringField(),
  goal: arrayField<TreatmentGoal>({ default: [] }),
  medicationManagement: stringField(),
  memberOutOfScope: booleanField({ rules: [] }),
  memberOutOfScopeReason: stringField({ rules: [] }),
  messageToCareTeam: stringField({ rules: [] }),
  numberOfSessionsAnticipated: field(),
  offeredAppointmentAccepted: field({ rules: [] }),
  referralsToCareOutsideGingerNecessary: stringField({ rules: [] }),
  version: field<TreatmentPlan_Version>({
    default: TreatmentPlan_Version.undefined_version,
    rules: [],
  }),
  waitTimeDetrimentalEffect: booleanField(),
};

export function TreatmentPlanForm(props: Props) {
  const {
    savingNote,
    disabled,
    appointmentId,
    initialValue,
    noteLastUpdatedAt,
  } = props;
  const { fields, validate, getValue } = useForm<TreatmentPlan>(
    fieldDefs,
    { ...initialValue, appointmentId } as TreatmentPlan,
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: PsychiatrySectionName.TREATMENT_PLAN,
        }),
    },
  );

  const onSubmit = async () => {
    const isValid = await validate();
    if (isValid) {
      props.onSubmit({
        data: getValue(),
        name: PsychiatrySectionName.TREATMENT_PLAN,
      });
    }
  };

  return (
    <div>
      <TextAreaField
        width={Width.FULL}
        label="Medication management*"
        disabled={disabled}
        testId="medicationManagement"
        field={fields.medicationManagement}
      />
      <NoteFormControlLabel label="Follow-up*">
        <TextBoxField
          disabled={disabled}
          testId="followUp"
          field={fields.followUp}
          label=""
        />
      </NoteFormControlLabel>
      <div className={styles.formGroup}>
        <NumberField
          testId="numberOfSessionsAnticipated"
          placeholder="0"
          disabled={disabled}
          label="number sessions anticipated*"
          field={fields.numberOfSessionsAnticipated}
        />
      </div>

      {showComplianceFields(noteLastUpdatedAt) && (
        <div>
          <div className={styles.formGroup}>
            <YesOrNoField
              disabled={disabled}
              testId="waitTimeEffect"
              label={`${Labels.WAIT_TIME_EFFECT_PSYCHIATRY}*`}
              field={fields.waitTimeDetrimentalEffect}
            />
          </div>

          <div className={styles.formGroup}>
            <BooleanRadioField
              disabled={disabled}
              name="appointmentOffered"
              label={Labels.OFFER_AN_APPOINTMENT_PSYCHIATRY}
              value={getDefaultEnumValue(fields.appointmentOffered)}
              onChange={fields.appointmentOffered.setValue}
            />
          </div>
          <div className={styles.formGroup}>
            <BooleanRadioField
              disabled={disabled}
              name="offeredAppointmentAccepted"
              label={Labels.APPOINTMENT_OFFER_ACCEPTED}
              value={getDefaultEnumValue(fields.offeredAppointmentAccepted)}
              onChange={fields.offeredAppointmentAccepted.setValue}
            />
          </div>

          <TextAreaField
            disabled={disabled}
            testId="referralsToCareOutsideGingerNecessary"
            label={Labels.REFERRALS_TO_CARE_OUTSIDE}
            field={fields.referralsToCareOutsideGingerNecessary}
          />
        </div>
      )}
      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </div>
  );
}

export const validate = (data: TreatmentPlan | null) =>
  data !== null && validateForm(data, fieldDefs);
