import { CoachNotesItem } from 'app/coach/coach-notes/CoachNotesTypes';
import { CardTextAreaField } from 'app/coach/coach-notes/note-detail-view/note-fields';
import { deescalationNeed } from 'app/coach/coach-notes/note-detail-view/note-templates/fieldDefs';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { RisksForm } from 'app/coach/coach-notes/note-detail-view/risk-templates/RisksForm';
import { DeescalationNeedWithoutRisks } from 'app/coach/coach-notes/types';
import { useCoachNotesForm } from 'app/coach/coach-notes/useCoachNotesForm';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import { BooleanRadioField } from 'app/notes-ui/forms/form-controls/BooleanRadioField';
import React from 'react';

enum FieldLabels {
  GATHER = 'Gather',
  SYMPTOMS = 'Physical/emotional symptoms',
  COPING_SKILLS = 'Coping skills already tried',
  RESOURCES_PROVIDED = 'Tool/exercises/resources provided',
  OTHER_RELEVANT_INFO = 'Other relevant information',

  // Actions Taken
  PROCESSING_SPACE = '5 Minutes of processing space provided?',
  ITMS_EXPLANATION = 'Explanation of ITMS provided?',
  RESOURCES_SHARED = 'Tool/resource shared?',
  COACHING_BENEFITS = 'Benefits of ongoing, scheduled coaching explained',
  SCHEDULER_DIRECTION = 'Direction to scheduler/lead coach provided?',
  MS_CONNECTED = 'Connected to MS for clinical',
  RISK_ASSESSMENT_PROVIDED = 'Risk Assessment provided?',

  NEXT_STEPS = "What's next?",
}

export enum Placeholders {
  GATHER = 'Anxiety, panic, feeling down SI, relationships, stress, overwhelm...',
  GATHER_HELPER_TEXT = 'What caused the member to drop in? *',
  SYMPTOMS = 'Symptoms...',
  COPING_SKILLS = 'Skills',
  RESOURCES_PROVIDED = 'Resources...',
  OTHER_RELEVANT_INFO = 'Anything else...',
  NEXT_STEPS = 'Homework, goal plan, future discussions, scheduling...',
  NEXT_STEPS_HELPER_TEXT = 'Remember to summarize your session to the member! :)',
}

const TOOLTIP_EVENT_LABEL = 'Drop-in Team Notes > De-escalation need';
export const DeescalationNeed = ({
  readOnly,
  onStateChange,
  'data-testid': testId = 'deescalationNeed',
}: {
  readOnly: boolean;
  onStateChange: (noteDraft: CoachNotesItem) => void;
  'data-testid'?: string;
}): React.ReactElement | null => {
  const { fields, onChangeRisksForm, risks } = useCoachNotesForm<
    DeescalationNeedWithoutRisks
  >({
    canContainNestedRisks: true,
    onStateChange,
    validationSchema: deescalationNeed,
  });

  return (
    <>
      <FieldLabel
        className={`${styles.label} ${styles.groupLabel}`}
        labelText={FieldLabels.GATHER}
      />
      <div className={`${styles.helperText} ${styles.subtle}`}>
        {Placeholders.GATHER_HELPER_TEXT}
      </div>
      <CardTextAreaField
        field={fields.whatCausedMemberToDropIn}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${Placeholders.GATHER}`}
        tooltipText=""
        placeholder={Placeholders.GATHER}
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.subtle}`}
        labelText={FieldLabels.SYMPTOMS}
      />
      <CardTextAreaField
        field={fields.symptoms}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.SYMPTOMS}`}
        tooltipText=""
        placeholder={Placeholders.SYMPTOMS}
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.subtle}`}
        labelText={FieldLabels.COPING_SKILLS}
      />
      <CardTextAreaField
        field={fields.copingSkills}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.COPING_SKILLS}`}
        tooltipText=""
        placeholder={Placeholders.COPING_SKILLS}
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.subtle}`}
        labelText={FieldLabels.RESOURCES_PROVIDED}
      />
      <CardTextAreaField
        field={fields.resourcesProvided}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.RESOURCES_PROVIDED}`}
        tooltipText=""
        placeholder={Placeholders.RESOURCES_PROVIDED}
        readOnly={readOnly}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.OTHER_RELEVANT_INFO}
      />
      <CardTextAreaField
        field={fields.otherRelevantInfo}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.OTHER_RELEVANT_INFO}`}
        tooltipText=""
        placeholder={Placeholders.OTHER_RELEVANT_INFO}
        readOnly={readOnly}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.PROCESSING_SPACE}
      />
      <BooleanRadioField
        disabled={readOnly}
        name="processingSpace"
        value={fields.processingSpaceProvided.value}
        onChange={fields.processingSpaceProvided.setValue}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.ITMS_EXPLANATION}
      />
      <BooleanRadioField
        disabled={readOnly}
        name="itmsExplanations"
        value={fields.explanationOfItmsProvided.value}
        onChange={fields.explanationOfItmsProvided.setValue}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.RESOURCES_SHARED}
      />
      <BooleanRadioField
        disabled={readOnly}
        name="toolsShared"
        value={fields.resourcesShared.value}
        onChange={fields.resourcesShared.setValue}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.COACHING_BENEFITS}
      />
      <BooleanRadioField
        disabled={readOnly}
        name="coachingBenefits"
        value={fields.benefitsOfCoachingExplained.value}
        onChange={fields.benefitsOfCoachingExplained.setValue}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.SCHEDULER_DIRECTION}
      />
      <BooleanRadioField
        disabled={readOnly}
        name="schedulerDirection"
        value={fields.directionToCoachProvided.value}
        onChange={fields.directionToCoachProvided.setValue}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.MS_CONNECTED}
      />
      <BooleanRadioField
        disabled={readOnly}
        name="msConnected"
        value={fields.connectedToMs.value}
        onChange={fields.connectedToMs.setValue}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.RISK_ASSESSMENT_PROVIDED}
      />
      <BooleanRadioField
        disabled={readOnly}
        name="msConnected"
        value={fields.riskAssessmentProvided.value}
        onChange={fields.riskAssessmentProvided.setValue}
      />

      <FieldLabel className={styles.label} labelText={FieldLabels.NEXT_STEPS} />
      {!readOnly && (
        <div className={styles.helperText}>
          {Placeholders.NEXT_STEPS_HELPER_TEXT}
        </div>
      )}
      <CardTextAreaField
        field={fields.nextSteps}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.NEXT_STEPS}`}
        tooltipText=""
        placeholder={Placeholders.NEXT_STEPS}
        readOnly={readOnly}
      />

      <RisksForm
        risks={risks}
        onChange={onChangeRisksForm}
        readOnly={readOnly}
        inline={true}
      />
    </>
  );
};
