import { IntakeCard } from 'app/patients/tabs/intake/IntakeTabV2Card';
import React from 'react';

type Props = {
  thoughtsOfHarm: boolean;
  reasonsForThoughtsOfHarm: string | null;
  riskKeywords: string[];
  phq9SelfHarmAnswers: { selfHarm: number }[];
};

export function RiskIndicators(props: Readonly<Props>) {
  const {
    thoughtsOfHarm,
    reasonsForThoughtsOfHarm,
    phq9SelfHarmAnswers,
    riskKeywords,
  } = props;
  return (
    <IntakeCard
      testId="riskIndicators"
      title="Risk Indicators"
      terms={{
        "Highest recorded answer to PHQ-9: 'Thoughts you would be better off dead or hurting yourself in someway?'": formatPHQ9SelfHarmAnswers(
          phq9SelfHarmAnswers,
        ),
        'Reasons for these thoughts': reasonsForThoughtsOfHarm,
        'Risk keywords': formatRiskKeywords(riskKeywords),
        'Thoughts of harming yourself or others': thoughtsOfHarm.toString(),
      }}
    />
  );
}

function formatPHQ9SelfHarmAnswers(
  phq9SelfHarmAnswers: { selfHarm: number }[],
): string {
  const selfHarmAnswers = phq9SelfHarmAnswers
    .map((_) => _.selfHarm)
    .sort((a, b) => b - a);

  const highestSelfHarmAnswer =
    selfHarmAnswers.length >= 0 ? selfHarmAnswers[0] : null;

  switch (highestSelfHarmAnswer) {
    case 0: {
      return 'Not at all';
    }
    case 1: {
      return 'Several days';
    }

    case 2: {
      return 'More than half the days';
    }

    case 3: {
      return 'Nearly every day';
    }

    default: {
      return 'N/A';
    }
  }
}

function formatRiskKeywords(riskKeywords: string[]): string {
  const keywords = riskKeywords.join(', ');
  return keywords.length > 0
    ? keywords
    : "No risk keywords were flagged on this member's intake form.";
}
