import { v4 as uuidv4 } from 'uuid';

/** Vault IDs for Clinical Note objects */
export class VaultIds {
  protected static prefix = 'appointment';

  protected static noteName = 'noteName';

  protected static delimiter = '-';

  static section(appointmentId: string, sectionName: any): string {
    return this.id(this.prefix, appointmentId, this.noteName, sectionName);
  }

  static amendmentsSection(): string {
    // An amendment section is an array of immutable items, so the ID of an individual item is arbitrary. It is
    // fetched by tags only.
    return uuidv4();
  }

  static metadata(appointmentId: string) {
    return this.id(this.prefix, appointmentId, this.noteName, 'metadata');
  }

  static metadataTags(appointmentId: string): string[] {
    return [this.noteSectionsTag(appointmentId)];
  }

  static sectionTags(userId: string, appointmentId: string): string[] {
    return [
      // get all note sections for a note
      this.noteSectionsTag(appointmentId),

      // get "my" therapy intake notes
      this.myNotesTag(userId),

      // TODO: tag for retrieving notes for a specific member
    ];
  }

  static amendmentsSectionTag(
    appointmentId: string,
    sectionName: string,
  ): string {
    return this.section(appointmentId, sectionName);
  }

  static noteSectionsTag(appointmentId: string): string {
    return this.id(this.prefix, appointmentId, this.noteName);
  }

  static myNotesTag(userId: string): string {
    return this.userPrefixedId(userId, this.noteName);
  }

  static memberNotesTag(memberId: string): string {
    return this.memberPrefixedId(memberId, 'clinicalNotes');
  }

  static memberNonAppointmentNotes(memberId: string) {
    return this.memberPrefixedId(memberId, 'nonAppointmentNotes');
  }

  static memberPrefixedId(memberId: string, ...parts: string[]): string {
    return this.id('member', memberId, ...parts);
  }

  private static userPrefixedId(userId: string, ...parts: string[]): string {
    return this.id('user', userId, ...parts);
  }

  protected static id(...parts: string[]): string {
    return parts.join(this.delimiter);
  }
}
