import 'date-fns';

import CreateIcon from '@mui/icons-material/Create';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Box } from 'shared-components/Box';
import { DemographicsInput } from 'generated/globalTypes';
import { useSnackNotification } from 'hooks/useSnackNotification';
import Messages from 'i18n/en/memberSupport.json';
import React, { useMemo, useState } from 'react';

import classes from './components.module.scss';
import {
  DemographicsInputControl,
  DisabledDemographicsInput,
  EditInputCardProps,
  Validator,
} from './DemographicsInputControl';

export type TitleProps = {
  title: string;
  onSave: () => boolean;
  onEdit: (edit: boolean) => void;
  onCancel: () => void;
};

export function Title(props: TitleProps) {
  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    setEdit(true);
    props.onEdit(!edit);
  };
  const handleCancel = () => {
    props.onCancel();
    setEdit(false);
    props.onEdit(false);
  };
  const handleSave = () => {
    const result = props.onSave();
    setEdit(!result);
    props.onEdit(!result);
  };
  return (
    <div className={classes.titleRoot}>
      <h2 className={classes.title}>{props.title}</h2>
      {!edit && (
        <Tooltip title="Edit">
          <IconButton
            data-testid="edit-btn"
            className={classes.iconButton}
            onClick={handleEdit}
            type="button"
            aria-label="edit"
            size="large"
          >
            <CreateIcon />
          </IconButton>
        </Tooltip>
      )}
      {edit && (
        <>
          <Tooltip title="Save">
            <IconButton
              data-testid="save-btn"
              className={classes.iconButton}
              onClick={handleSave}
              type="submit"
              aria-label="save"
              size="large"
            >
              <SaveIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton
              data-testid="cancel-btn"
              className={classes.iconButton}
              onClick={handleCancel}
              type="button"
              aria-label="cancel"
              size="large"
            >
              <HighlightOffIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </div>
  );
}

type InputErrors = { [T in keyof DemographicsInput]: Validator };

export function EditableDemographicsCard(props: EditInputCardProps) {
  const { showErrorNotification } = useSnackNotification();
  const { title, data, preferredName } = props;

  const initState = useMemo(() => {
    const demographics: Partial<DemographicsInput> = {};
    const errors: InputErrors = {};
    const validators: {
      [T in keyof DemographicsInput]: (input?: string) => Validator;
    } = {};

    data.forEach((entry) => {
      const { propName, validator, propValue } = entry;
      demographics[propName] = propValue;
      validators[propName] = validator;
      errors[propName] = validator(propValue ?? 'N/A');
    });

    return { demographics, errors, validators };
  }, [data]);

  const [edit, setEdit] = useState(false);
  const [demographics, setDemographics] = useState<Partial<DemographicsInput>>(
    initState.demographics,
  );
  const [errors, setErrors] = useState<InputErrors>(initState.errors);

  const handleChange = (prop: keyof DemographicsInput) => (
    value: string | null,
  ) => {
    setErrors({
      ...errors,
      [prop]: initState.validators[prop]!(value || ''),
    });
    setDemographics({
      ...demographics,
      [prop]: value,
    });
  };

  const onSubmit = () => {
    const error = Object.entries(errors).find(
      ([, value]) => value && !value.isValid,
    );

    if (props.onSave && error === undefined) {
      props.onSave(demographics);
      return true;
    }
    showErrorNotification(Messages.demographicValidationError);
    return false;
  };
  const onCancel = () => {
    setDemographics(initState.demographics);
    setErrors(initState.errors);
  };
  const boxTitle = (
    <Title
      data-testid="cardTitle"
      title={title}
      onSave={onSubmit}
      onCancel={onCancel}
      onEdit={(val) => setEdit(val)}
    />
  );
  return (
    <Box testId={`box-${title}`} title={boxTitle}>
      {preferredName && (
        <DisabledDemographicsInput
          label="Preferred Name"
          propValue={preferredName}
        />
      )}
      {data.map((entry) => {
        const { label, propType, propName, options, validator } = entry;
        const { error } = errors[propName]!;
        const value = demographics[propName];
        return (
          <DemographicsInputControl
            data-testid={`${propName}:${propType}`}
            key={propName}
            label={label}
            propName={propName}
            propValue={value}
            propType={propType}
            options={options}
            validator={validator}
            error={error}
            onChange={handleChange(propName)}
            edit={edit}
          />
        );
      })}
    </Box>
  );
}
