import { initializeTypingState } from 'app/coach/chat/utils';
import { GetAllTaskItemsForMe_getTasksForMember } from 'app/coach/member-chart/generated/GetAllTaskItemsForMe';
import { Reason } from 'generated/globalTypes';
import { createReducer } from 'redux-reloaded';

import {
  addTypingState,
  clearMessagePublishLatencyTimer,
  setInputValue,
  setMemberCoachChannelPrefixForCoach,
  setPublishLatencyTimeout,
  setShouldPlayNotificationSound,
  setTaskRelatedMessages,
  updateChatReadOnly,
  updatePubnubStatus,
  updateTypingState,
} from './actions';
import { getInitialChatState, State } from './schema';
import { TaskRelatedMessageInfo, Tasks } from './types';

export const reducer = createReducer<State>(getInitialChatState());

reducer.on(clearMessagePublishLatencyTimer, (state, { payload }) => {
  const { messageId } = payload;
  const { publishTimeoutsMap } = state;
  const timer = publishTimeoutsMap ? publishTimeoutsMap[messageId] : null;
  if (timer) {
    clearTimeout(timer);
    return {
      ...state,
      publishTimeoutsMap: {
        ...publishTimeoutsMap,
        [messageId]: null,
      },
    };
  }
  return state;
});

reducer.on(setPublishLatencyTimeout, (state, { payload }) => {
  const { timeoutObject } = payload;

  return {
    ...state,
    publishTimeoutsMap: {
      ...state.publishTimeoutsMap,
      ...timeoutObject,
    },
  };
});

reducer.on(updatePubnubStatus, (state, { payload }) => {
  return {
    ...state,
    pubnubStatus: payload.status,
  };
});
reducer.on(
  setMemberCoachChannelPrefixForCoach,
  (state, { payload: { memberCoachChannelPrefix } }) => ({
    ...state,
    memberCoachChannelPrefix,
  }),
);

reducer.on(addTypingState, (state, { payload }) => {
  const { channelId } = payload;
  return {
    ...state,
    typingStateMap: {
      ...state.typingStateMap,
      [channelId]: initializeTypingState(),
    },
  };
});

reducer.on(updateTypingState, (state, { payload }) => {
  const { channelId, updatedParam } = payload;
  const updatedState = {
    ...state,
    typingStateMap: {
      ...state.typingStateMap,
      [channelId]: {
        ...state.typingStateMap[channelId],
        ...updatedParam,
      },
    },
  };
  return updatedState;
});

reducer.on(setInputValue, (state, { payload }) => {
  const { channelId, input } = payload;
  return {
    ...state,
    inputsMap: {
      ...state.inputsMap,
      [channelId]: input,
    },
  };
});

reducer.on(setShouldPlayNotificationSound, (state, { payload }) => {
  const { shouldPlaySound } = payload;
  return {
    ...state,
    shouldPlayNotificationSound: shouldPlaySound,
  };
});

reducer.on(updateChatReadOnly, (state, { payload }) => {
  const { channelId, isReadOnly } = payload;
  return {
    ...state,
    isReadOnlyMap: {
      ...state.isReadOnlyMap,
      [channelId]: isReadOnly,
    },
  };
});

const mapTaskRelatedMessages = (tasks: Tasks, isActive: boolean) => {
  if (!tasks) {
    return {};
  }
  return tasks.reduce((acc, task) => {
    if (task && task.id) {
      const filteredRelatedMessages = task.relatedMessages
        ? task.relatedMessages.map((item) => {
            if (item && '__typename' in item) {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { __typename, ...rest } = item;
              return rest;
            }
            return item;
          })
        : [];

      filteredRelatedMessages.forEach((msg) => {
        if (msg && msg.uuid) {
          acc[msg.uuid] = {
            isActive,
            outOfSession: !!msg.outOfSession,
            reasonForCreation: Reason.RiskyChatDetected,
            taskId: task.id,
          };
        }
      });
    }
    return acc;
  }, {} as Record<string, TaskRelatedMessageInfo>);
};

reducer.on(setTaskRelatedMessages, (state, { payload }) => {
  const { tasks } = payload;

  if (!tasks) {
    return {
      ...state,
      taskRelatedMessages: {},
    };
  }

  const {
    active,
    completed,
    dismissed,
  } = tasks as GetAllTaskItemsForMe_getTasksForMember;

  const newTaskRelatedMessages = {
    ...mapTaskRelatedMessages(active, true),
    ...mapTaskRelatedMessages(completed, false),
    ...mapTaskRelatedMessages(dismissed, false),
  };

  return {
    ...state,
    taskRelatedMessages: newTaskRelatedMessages,
  };
});
