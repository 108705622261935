import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import MaterialMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';

import styles from './ActionMenu.module.scss';

export type Option<T> = {
  value: T;
  text: string;
};

export type Props<T> = {
  options: Option<T>[];
  onSelectOption: (key: T) => void;
};
export type MenuProps<T> = {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  options: Option<T>[];
  onSelectOption: (key: T) => void;
};

export function Menu<T>(props: MenuProps<T>) {
  const { anchorEl, open, onClose, options, onSelectOption } = props;
  return (
    <MaterialMenu
      keepMounted={true}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      {options.map((option) => (
        <MenuItem
          classes={{
            root: styles.menuItemRoot,
          }}
          key={`${option.value}`}
          data-testid={`${option.value}`}
          onClick={() => {
            onSelectOption(option.value);
            onClose();
          }}
        >
          <span className={styles.menuItemContent}>{option.text}</span>
        </MenuItem>
      ))}
    </MaterialMenu>
  );
}

export function ActionMenu<T>(props: Props<T>) {
  const { options, onSelectOption } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;

  const onClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const onClose = () => setAnchorEl(null);
  return (
    <div className={styles.menuContainer}>
      <IconButton
        data-testid="action-icon"
        aria-label="more"
        aria-haspopup="true"
        classes={{ root: styles.iconButton }}
        onClick={onClick}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        data-testid="action-menu"
        onSelectOption={onSelectOption}
        options={options}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
      />
    </div>
  );
}
