import { PsychiatrySectionName } from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';

import { VaultIds } from './VaultIds';

/** Vault IDs for Clinical Note objects */
export class PsychiatryIntakeIds extends VaultIds {
  protected static prefix = 'appointment';

  protected static noteName = 'psychiatryIntakeNote';

  protected static delimiter = '-';

  static section(
    appointmentId: string,
    sectionName: PsychiatrySectionName,
  ): string {
    return this.id(this.prefix, appointmentId, this.noteName, sectionName);
  }
}
