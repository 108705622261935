import { Field } from '@ginger.io/react-use-form';
import { MedicalHistory_Allergy } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MedicalHistory';
import { FormError } from 'shared-components/FormError';
import { Width } from 'types/StyleTypes';
import { NoteFormControlLabelStyles } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { TextAreaWithLabel } from 'app/notes-ui/forms/form-controls/TextArea';
import medicalStyles from 'app/notes-ui/psychiatry/shared/medical-history/MedicalHistoryForm.module.scss';
import { classNameCombiner } from 'utils';
import React, { useEffect } from 'react';

import { Labels } from './constants';

export const AllergiesFieldList = (props: {
  field: Field<MedicalHistory_Allergy[]>;
  minItems?: number;
  drugNamePlaceholder?: string[];
  descriptionPlaceholder?: string[];
  disabled?: boolean;
  formControlStyles?: NoteFormControlLabelStyles;
}) => {
  const { field, minItems = 1 } = props;
  const values = [...field.value];

  useEffect(() => {
    if (values.length < minItems) {
      values.push(
        ...Array(minItems - values.length).fill(
          MedicalHistory_Allergy.fromPartial({}),
        ),
      );
      field.setValue(values);
    }
  }, [values.length, minItems, field]);

  const fields = values.map((fieldValue, i) => (
    <div key={`${i}`} className={medicalStyles.allergies_rows}>
      <div
        className={classNameCombiner([
          medicalStyles.allergy_container,
          medicalStyles.drug_name_field_container,
        ])}
      >
        <TextAreaWithLabel
          name={`medication_${i}`}
          disabled={props.disabled}
          value={fieldValue.medication}
          onChange={(value) => {
            const allergie = { ...field.value[i], medication: value };
            const new_allergies = [
              ...field.value.slice(0, i),
              allergie,
              ...field.value.slice(i + 1),
            ];
            field.setValue(new_allergies);
          }}
          placeholder={Labels.medicalHistory.allergies.medication_placeholder}
          width={Width.FULL}
          formControlStyles={props.formControlStyles}
          rows={1}
          autoExpand={true}
        />
      </div>
      <div
        className={classNameCombiner([
          medicalStyles.allergy_container,
          medicalStyles.comments_field_container,
        ])}
      >
        <TextAreaWithLabel
          name={`comments_${i}`}
          disabled={props.disabled}
          value={fieldValue.comments}
          onChange={(value) => {
            const allergie = { ...field.value[i], comments: value };
            const new_allergies = [
              ...field.value.slice(0, i),
              allergie,
              ...field.value.slice(i + 1),
            ];
            field.setValue(new_allergies);
          }}
          placeholder={Labels.medicalHistory.allergies.comments_placeholder}
          width={Width.FULL}
          formControlStyles={props.formControlStyles}
          rows={1}
          autoExpand={true}
        />
      </div>
    </div>
  ));

  return (
    <div>
      {fields}
      <FormError error={field.error} />
    </div>
  );
};
