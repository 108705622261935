import { GetMemberSurvey_getMember_surveyResponses_phq9s_surveys as Phq9Surveys } from 'app/queries/generated/GetMemberSurvey';
import { useAppState } from 'app/state';
import { classNameCombiner } from 'utils';
import { fromUTCToLocal } from 'utils/dateTime';
import warningIcon from 'assets/warning-icon.svg';
import { PHQ9Severity } from 'generated/globalTypes';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { isNull } from 'lodash';
import React, { Fragment } from 'react';
import { TooltipWithRedux } from 'shared-components/tooltip/TooltipWithRedux';

import ChangeIcon from './ChangeIcon';
import headerStyles from './header.module.scss';
import styles from './PHQ9Info.module.scss';
import { SuicidalFlag } from './SuicidalFlag';
import { PHQ9SeverityMap } from './utils';

interface PHQ9InfoProps {
  phqLatest: Phq9Surveys | null | undefined;
  phqPrevious: Phq9Surveys | null | undefined;
  memberId: string;
}

/** PHQ Order is the following:
 * Enjoyment
 * Mood
 * Sleep
 * Energy
 * Eating
 * Failure
 * Concentration
 * Restlessness
 * Self Harm
 */
const PHQ9Info = ({ phqLatest, phqPrevious, memberId }: PHQ9InfoProps) => {
  const timezone = useAppState((_) => _.user.timezone || 'UTC');
  const hasRisk = useAppState(({ inbox }) =>
    inbox.tabSections.RISK_ALERTS.ids.has(memberId),
  );
  const {
    enable_tooltip_label_underline,
  } = useFeatureFlags().transientFeatureFlags;

  if (phqLatest) {
    const { severity } = phqLatest;
    const severityString = severity ? PHQ9SeverityMap[severity] : undefined;
    const showWarning =
      severity === PHQ9Severity.MODERATE ||
      severity === PHQ9Severity.MODERATELY_SEVERE ||
      severity === PHQ9Severity.SEVERE;
    const totalComponent =
      !isNull(phqLatest.total) && severityString ? (
        <p className={classNameCombiner([styles.main, styles.total])}>
          <TooltipWithRedux
            labelForAnalytics="Member Header: PHQ9Info"
            title={
              <div>
                {!isNull(phqLatest.enjoyment) ? (
                  <>
                    <p className={styles.tooltipLine}>
                      <span>Enjoyment:</span>

                      <b>{phqLatest.enjoyment}</b>
                      <ChangeIcon
                        latestScore={phqLatest.enjoyment}
                        previousScore={phqPrevious?.enjoyment}
                      />
                    </p>
                  </>
                ) : undefined}
                {!isNull(phqLatest.mood) ? (
                  <>
                    <p className={styles.tooltipLine}>
                      <span>Mood:</span>
                      <b>{phqLatest.mood}</b>
                      <ChangeIcon
                        latestScore={phqLatest.mood}
                        previousScore={phqPrevious?.mood}
                      />
                    </p>
                  </>
                ) : undefined}
                {!isNull(phqLatest.sleep) ? (
                  <>
                    <p className={styles.tooltipLine}>
                      <span>Sleep:</span>
                      <b>{phqLatest.sleep}</b>
                      <ChangeIcon
                        latestScore={phqLatest.sleep}
                        previousScore={phqPrevious?.sleep}
                      />
                    </p>
                  </>
                ) : undefined}
                {!isNull(phqLatest.energy) ? (
                  <>
                    <p className={styles.tooltipLine}>
                      <span>Energy:</span>
                      <b>{phqLatest.energy}</b>
                      <ChangeIcon
                        latestScore={phqLatest.energy}
                        previousScore={phqPrevious?.energy}
                      />
                    </p>
                  </>
                ) : undefined}
                {!isNull(phqLatest.eating) ? (
                  <>
                    <p className={styles.tooltipLine}>
                      <span>Eating:</span>
                      <ChangeIcon
                        latestScore={phqLatest.eating}
                        previousScore={phqPrevious?.eating}
                      />
                    </p>
                  </>
                ) : undefined}
                {!isNull(phqLatest.failure) ? (
                  <>
                    <p className={styles.tooltipLine}>
                      <span>Failure:</span>
                      <b>{phqLatest.failure}</b>
                      <ChangeIcon
                        latestScore={phqLatest.failure}
                        previousScore={phqPrevious?.failure}
                      />
                    </p>
                  </>
                ) : undefined}
                {!isNull(phqLatest.concentration) ? (
                  <>
                    <p className={styles.tooltipLine}>
                      <span>Concentration:</span>
                      <b>{phqLatest.concentration}</b>
                      <ChangeIcon
                        latestScore={phqLatest.concentration}
                        previousScore={phqPrevious?.concentration}
                      />
                    </p>
                  </>
                ) : undefined}
                {!isNull(phqLatest.restlessness) ? (
                  <>
                    <p className={styles.tooltipLine}>
                      <span>Restlessness:</span>
                      <b>{phqLatest.restlessness}</b>
                      <ChangeIcon
                        latestScore={phqLatest.restlessness}
                        previousScore={phqPrevious?.restlessness}
                      />
                    </p>
                  </>
                ) : undefined}
                {!isNull(phqLatest.selfHarm) ? (
                  <>
                    <p className={styles.tooltipLine}>
                      <span> Self Harm:</span>
                      <b>{phqLatest.selfHarm}</b>
                      <ChangeIcon
                        latestScore={phqLatest.selfHarm}
                        previousScore={phqPrevious?.selfHarm}
                      />
                    </p>
                  </>
                ) : undefined}
              </div>
            }
          >
            <div
              className={classNameCombiner([
                enable_tooltip_label_underline ? headerStyles.underline : '',
              ])}
              data-testid="phq-severity"
            >
              {showWarning ? (
                <img
                  className={styles.icon}
                  alt="Warning: PHQ9 Moderate or above"
                  src={warningIcon}
                />
              ) : null}
              <span>{`${severityString} (${phqLatest.total}/27)`}</span>
            </div>
          </TooltipWithRedux>
        </p>
      ) : undefined;
    const timestamp = phqLatest.timestamp ? (
      <p className={[styles.main, styles.timestamp].join(' ')}>
        <ChangeIcon
          latestScore={phqLatest.total}
          previousScore={phqPrevious?.total}
        />
        <span className={styles.content}>
          {fromUTCToLocal(phqLatest.timestamp, timezone).format(
            'MMM DD, YYYY h:mma z',
          )}
        </span>
      </p>
    ) : undefined;
    return (
      <div className={styles.PHQ9Info} data-testid="phq9info">
        {hasRisk && (
          <div className={styles.flag}>
            <SuicidalFlag phqLatest={phqLatest} />
          </div>
        )}
        <div>
          <p className={classNameCombiner([styles.main, styles.title])}>PHQ</p>
          {totalComponent}
          {timestamp}
        </div>
      </div>
    );
  }
  // if no info, return empty JSX fragment
  return <Fragment />;
};

export default PHQ9Info;
