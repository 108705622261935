import { LoggerContext } from 'app/state/log/useLogger';
import React, {
  Component,
  ElementType,
  ErrorInfo,
  PropsWithChildren,
} from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

interface Props {
  FallbackComponent: ElementType;
  dispatch: Dispatch;
}

interface State {
  error?: Error;
}

class ErrorBoundary extends Component<PropsWithChildren<Props>, State> {
  static contextType = LoggerContext;

  public state: State = {
    error: undefined,
  };

  static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.context.error(error, { errorInfo });
  }

  render() {
    const { error } = this.state;
    const { children, FallbackComponent } = this.props;
    if (error) {
      return <FallbackComponent error={error} />;
    }

    return children;
  }
}

export default connect()(ErrorBoundary);
