import * as Sentry from '@sentry/react';
import { NoteRoutes, Routes } from 'app/top-nav/Routes';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { OutOfSessionNoteScreen } from './out-of-session/OutOfSessionNoteScreen';
import { PsychiatryIntakeNoteContainer } from './psychiatry/PsychiatryIntakeNoteContainer';
import { PsychiatryProgressNoteContainer } from './psychiatry/PsychiatryProgressNoteContainer';
import { TerminationNoteScreen } from './termination/TerminationNoteScreen';
import { TherapyIntakeNoteContainer } from './therapy/TherapyIntakeNoteContainer';
import { TherapyProgressNoteContainer } from './therapy/TherapyProgressNoteContainer';

export type Props = {};

export type URLParams = {
  appointmentId: string;
};

export function ClinicalNotesScreenRoutes(props: Props) {
  const SentryRoute = Sentry.withSentryRouting(Route);
  return (
    <Switch>
      <SentryRoute
        path={`${Routes.APPOINTMENT_NOTES}/therapy-intake`}
        component={TherapyIntakeNoteContainer}
      />
      <SentryRoute
        path={`${Routes.APPOINTMENT_NOTES}/therapy-progress`}
        component={TherapyProgressNoteContainer}
      />
      <SentryRoute
        path={`${Routes.APPOINTMENT_NOTES}/psychiatry-intake`}
        component={PsychiatryIntakeNoteContainer}
      />
      <SentryRoute
        path={`${Routes.APPOINTMENT_NOTES}/psychiatry-progress`}
        component={PsychiatryProgressNoteContainer}
      />
    </Switch>
  );
}

export function OtherClinicalNotesScreenRoutes() {
  const SentryRoute = Sentry.withSentryRouting(Route);
  return (
    <Switch>
      <SentryRoute
        path={`${Routes.NOTES}/${NoteRoutes.OUT_OF_SESSION}/:vaultItemId?`}
        component={OutOfSessionNoteScreen}
      />
      <SentryRoute
        path={`${Routes.NOTES}/${NoteRoutes.TERMINATION}/:vaultItemId?`}
        component={TerminationNoteScreen}
      />
    </Switch>
  );
}
