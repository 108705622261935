import { Availability } from 'app/member-chart-cards/scheduler/types';
import { GetAvailabilitiesForMe_getAvailabilitiesForMe_timeSlotSeries as TimeSlotSeriesWithLoad } from 'app/scheduler/generated/GetAvailabilitiesForMe';
import { ILogger } from 'app/state/log/Logger';

export class SchedulerFormatter {
  constructor(private logger: ILogger) {}

  formatAvailabilitySchedule(
    timeSlotSeriesArray: TimeSlotSeriesWithLoad[] | undefined,
  ): Availability[] | undefined {
    if (!timeSlotSeriesArray || timeSlotSeriesArray.length === 0) {
      this.logger.error(
        new Error(
          'getCoachAvailabilitySchedule query fulfilled, but response data is invalid. The state will not be updated on the frontend.',
        ),
      );
      return undefined;
    }

    // Flattening all time slots into a single array of Availability objects
    const availabilities: Availability[] = timeSlotSeriesArray.flatMap(
      (series) =>
        series.timeSlots.map((slot) => ({
          endTime: slot.end,
          load: slot.load,
          startTime: slot.start,
        })),
    );

    return availabilities;
  }
}
