import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import MUISnackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { TransitionProps } from '@mui/material/transitions';
import { useAppState } from 'app/state';
import { hideOrShowSnackNotification } from 'app/state/notifications/actions';
import React from 'react';
import { useDispatch } from 'react-redux';

import styles from './Snackbar.module.scss';

export type SnackbarNotification = {
  message?: string;
  key?: number;
  onDismiss?: () => void;
  type?: 'success' | 'error';
  dismissible?: boolean;
  autoHide?: boolean;
};

export function Snackbar() {
  const dispatch = useDispatch();
  const { snackbarNotification, showSnackbar } = useAppState(
    (_) => _.notification,
  );

  const {
    message,
    key,
    type = 'success',
    onDismiss,
    dismissible = false,
    autoHide = true,
  } = snackbarNotification ?? {};

  const onClose = () => {
    dispatch(hideOrShowSnackNotification(null));
    if (onDismiss) onDismiss();
  };

  const showDismissButton = dismissible || onDismiss !== undefined;
  const action = showDismissButton ? (
    <Button color="secondary" size="small" onClick={() => onClose()}>
      Dismiss
    </Button>
  ) : null;

  const transitionComponent = (
    transProps: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
  ) => (
    <Slide {...transProps} children={transProps.children} direction="right" />
  );
  if (!showSnackbar) return null;
  return (
    <MUISnackbar
      data-testid="snackbar"
      open={showSnackbar}
      className={styles.root}
      onClose={(_, reason) => (reason === 'timeout' ? onClose() : undefined)}
      TransitionComponent={transitionComponent}
      key={key}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      autoHideDuration={autoHide ? 6000 : null}
    >
      <SnackbarContent
        action={action}
        className={[styles.contentRoot, styles[type]].join(' ')}
        message={message}
      />
    </MUISnackbar>
  );
}
