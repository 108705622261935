import { LocalStorage } from 'app/vault/VaultTokenStorage';

type UserState = {
  useOktaAuth: string | null;
  enableListenerAwsMigrationTest: boolean | null;
};

class UserStorage {
  private key = 'user';

  constructor(private storage: LocalStorage = localStorage) {}

  get(): UserState {
    const defaultState = {
      enableListenerAwsMigrationTest: null,
      useOktaAuth: null,
    };
    return JSON.parse(
      this.storage.getItem(this.key) ?? JSON.stringify(defaultState),
    );
  }

  set(data: Partial<UserState>): void {
    const prev = this.get();
    this.storage.setItem(this.key, JSON.stringify({ ...prev, ...data }));
  }

  clear(): void {
    this.storage.removeItem(this.key);
  }
}

const userStorage = new UserStorage(localStorage);
export default userStorage;
