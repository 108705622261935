import gql from 'graphql-tag';

export const getDosespotIframeCredentials = gql`
  query GetDosespotIframeCredentials {
    getDosespotIframeCredentials {
      clinicId
      userId
      encryptedClinicianId
      encryptedUserId
      url
    }
  }
`;
