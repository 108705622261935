import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { PropsWithChildren } from 'react';
import { classNameCombiner } from 'utils';

import headerStyles from './header.module.scss';

export type ContainerProps = PropsWithChildren<{
  classNames: Array<string>;
}>;

export const TagContainer = ({ children, classNames }: ContainerProps) => {
  const {
    enable_tooltip_label_underline,
  } = useFeatureFlags().transientFeatureFlags;

  return (
    <span
      className={classNameCombiner([
        ...classNames,
        enable_tooltip_label_underline ? headerStyles.underline : '',
      ])}
      data-testid="tag-container"
    >
      {children}
    </span>
  );
};
