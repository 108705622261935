import { useAppState } from 'app/state';
import { userIdleTimeOutEvent } from 'app/state/amplitude/actions/etc';
import { useLogger } from 'app/state/log/useLogger';
import { debounce } from 'lodash';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

export const IDLE_TIMEOUT_SECONDS = 20 * 60; // 20 minutes
const DEBOUNCE_SCALE_FACTOR = 5;
const TIMER_CHECK_FREQUENCY = 60;

/**
 * @description A custom hook designed to handle user inactivity timeouts.
 * Upon the detection of a specified duration of inactivity, it triggers specified actions.
 * The idle state is determined by listening for `mousemove` and `keypress` events.
 *
 * @function
 * @param {() => void} onTimeout - A callback function that is invoked when the user has been idle for `idleTimeout` seconds.
 * @param {number} [idleTimeout=IDLE_TIMEOUT_SECONDS] - The duration (in seconds) of inactivity after which `onTimeout` will be invoked. Default is `IDLE_TIMEOUT_SECONDS`. Must be at least 60 seconds.
 *
 * @throws {Error} Will throw an error if `idleTimeout` is less than 60 seconds.
 *
 * @return {void | null} - Returns null if the state values `role` or `userId` are falsy, otherwise returns void.
 *
 * @example
 * useIdleTimeout(() => {
 *   console.log('User has been idle for too long')
 * }, 1200)
 */
export function useIdleTimeout(
  onTimeout: () => void,
  idleTimeout: number = IDLE_TIMEOUT_SECONDS,
): void | null {
  if (idleTimeout < 60) {
    throw new Error('idleTimeout must be at least 60 seconds');
  }

  const onTimeoutRef = useRef(onTimeout);
  const idleStartTimeRef = useRef<number | null>(null);
  const idleIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const { role, userId, listenerId } = useAppState((_) => _.user);

  if (!role || !userId) {
    return null;
  }

  const dispatch = useDispatch();
  const logger = useLogger();

  const resetTimer = () => {
    idleStartTimeRef.current = null;
  };

  const handleTimer = () => {
    if (idleStartTimeRef.current === null) {
      idleStartTimeRef.current = Date.now();
    }

    const elapsedIdleTime = (Date.now() - idleStartTimeRef.current) / 1000;
    if (elapsedIdleTime > idleTimeout) {
      handleIdle(idleStartTimeRef.current, elapsedIdleTime);
    }
  };

  const handleIdle = (startTimeRef: number, elapsedIdleTime: number) => {
    const idleStartTime = new Date(startTimeRef).toISOString();

    const analyticsEventData = {
      idleStartTime,
      idleTimeSeconds: elapsedIdleTime,
      idleTimeout,
      listenerId,
      role,
      userId,
    };
    dispatch(userIdleTimeOutEvent(analyticsEventData));
    logger.info('useIdleTimeout: user inactivity detected', analyticsEventData);
    onTimeoutRef.current();
  };

  useEffect(() => {
    onTimeoutRef.current = onTimeout;
  }, [onTimeout]);

  useEffect(() => {
    const interval = Math.floor((idleTimeout * 1000) / TIMER_CHECK_FREQUENCY); // Check every 1/60th of the idleTimeout
    const debouncedResetTimer = debounce(
      resetTimer,
      interval / DEBOUNCE_SCALE_FACTOR,
    ); // Scale debounce based on interval

    const handleMouseMove = () => debouncedResetTimer();
    const handleKeyPress = () => debouncedResetTimer();

    document.documentElement.addEventListener('mousemove', handleMouseMove);
    document.documentElement.addEventListener('keypress', handleKeyPress);

    idleIntervalRef.current = setInterval(handleTimer, interval);

    return () => {
      document.documentElement.removeEventListener(
        'mousemove',
        handleMouseMove,
      );
      document.documentElement.removeEventListener('keypress', handleKeyPress);
      clearInterval(idleIntervalRef.current || undefined);
      debouncedResetTimer.cancel();
      idleStartTimeRef.current = null;
    };
  }, [role, userId]);
}
