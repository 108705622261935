import { SvgIconTypeMap } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import Tooltip, { TooltipProps } from 'shared-components/tooltip/Tooltip';
import React, { MouseEvent } from 'react';

type Props = {
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  onClick: () => void;
  title: string;
  disabled?: boolean;
  testId?: string;
  containerClass?: string;
  className?: string;
  stopPropagation?: boolean;
  variation?: TooltipProps['variation'];
};

export function IconActionBarItem(props: Props) {
  const {
    onClick,
    Icon,
    title,
    testId,
    disabled,
    stopPropagation,
    containerClass,
    className,
    variation = 'regular',
  } = props;
  // A disabled element does not fire events, but Tooltip needs to listen to the child's events to display a title.
  // Therefore, we add a <span> to wrap the potentially disabled IconButton.
  return (
    <Tooltip title={title} variation={variation}>
      <span className={containerClass}>
        <IconButton
          className={className}
          data-testid={testId}
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            if (stopPropagation) {
              e.stopPropagation();
            }

            onClick();
          }}
          disableFocusRipple={true}
          disabled={disabled}
          size="large"
        >
          <Icon />
        </IconButton>
      </span>
    </Tooltip>
  );
}
