import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { InputAdornment, TextField } from '@mui/material';
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';

import style from './CareMetrics.module.scss';

export interface MonthPickerProps {
  value?: string;
  minDate?: Moment;
  maxDate?: Moment;
  onChange: (_: Moment) => void;
}

function MonthPicker(props: MonthPickerProps) {
  const { value, onChange } = props;
  let { minDate, maxDate } = props;
  const date = value ? moment(value) : null;
  const [selectedDate, setSelectedDate] = useState<Moment | null>(date);
  const [open, setOpen] = useState(false);

  if (!minDate) {
    // defaults to 2010
    minDate = moment('20100101');
  }
  if (!maxDate) {
    // default to current month
    maxDate = moment();
  }

  const onDateChange = (date: Moment | null) => {
    if (date) {
      setSelectedDate(date);
      onChange(date);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDatePicker
        views={['year', 'month']}
        label="Month"
        open={open}
        onClose={() => setOpen(false)}
        minDate={minDate}
        maxDate={maxDate}
        value={selectedDate}
        onChange={onDateChange}
        renderInput={(params: any) => (
          <TextField
            {...params}
            size="small"
            onClick={() => setOpen(true)}
            className={style.datePicker}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarMonthIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default MonthPicker;
