import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import Labels from 'app/member-chart-cards/constants/member-chart-card.json';
import { MemberChartErrorState } from 'app/member-chart-cards/error-state/MemberChartErrorState';
import { formatTooltipText } from 'app/member-chart-cards/member-background/utils';
import { CardTextArea } from 'app/member-chart-cards/text-area/CardTextArea';
import { useAppState } from 'app/state';
import { formatListToString, formatNameOrMemberID } from 'utils';
import { useQuery } from 'hooks/useQuery';
import moment from 'moment-timezone';
import React from 'react';
import styles from 'shared-components/Card/Card.module.scss';
import {
  CardColumnGaps,
  CardGrid,
  CardRowGaps,
  NumberOfCardColumns,
} from 'shared-components/grid-layout/CardGrid.module';
import { GridCell } from 'shared-components/grid-layout/GridCell';

import {
  GetMemberNotes,
  GetMemberNotes_getMember,
  GetMemberNotesVariables,
} from './generated/GetMemberNotes';
import { MemberSectionFields } from './MemberNotesSectionTypes';
import { GetMemberNotesQuery } from './queries';

export type MemberNotesSectionProps = {
  memberId: string;
};

export const MemberNotesSection = (
  props: MemberNotesSectionProps,
): React.ReactElement => {
  const id = props.memberId;
  const { timezone } = useAppState(({ user: { timezone } }) => ({
    timezone: timezone!,
  }));

  return useQuery<GetMemberNotes, GetMemberNotesVariables>(
    (data: GetMemberNotes) => {
      return renderMemberNotes(data.getMember, timezone);
    },
    GetMemberNotesQuery,
    { variables: { input: { id } } },
    MemberChartErrorState,
  );
};

function renderMemberNotes(
  data: GetMemberNotes_getMember | null,
  timezone: string,
): React.ReactElement {
  if (!data) {
    throw new Error('There was an error fetching member onboard answers');
  }

  const {
    id,
    onboardAnswers,
    preferredFirstName,
    preferredLastName,
    onboard,
  } = data;

  const memberNotesFields = [
    {
      label: MemberSectionFields.BENCHMARK_FEELING,
      labelForAnalytics: `Member background: ${MemberSectionFields.BENCHMARK_FEELING}`,
      value: onboardAnswers?.howAreYouToday?.answers,
    },
    {
      label: MemberSectionFields.NEEDS,
      labelForAnalytics: `Member background: ${MemberSectionFields.NEEDS}`,
      value: onboardAnswers?.howCanWeHelp?.answers,
    },
    {
      label: MemberSectionFields.IMMEDIATE_NEED,
      labelForAnalytics: `Member background: ${MemberSectionFields.IMMEDIATE_NEED}`,
      value: onboardAnswers?.importantToYou?.answers,
    },
    {
      label: MemberSectionFields.IMPACT,
      labelForAnalytics: `Member background: ${MemberSectionFields.IMPACT}`,
      value: onboardAnswers?.impact?.answers,
    },
    {
      label: MemberSectionFields.STAGE_OF_CHANGE,
      labelForAnalytics: `Member background: ${MemberSectionFields.STAGE_OF_CHANGE}`,
      value: onboardAnswers?.feelAboutMakingChanges?.answers,
    },
  ];

  const completedAt = onboard?.completedAt;
  let tooltipText: string | undefined;
  if (completedAt) {
    const nameDisplay = formatNameOrMemberID(
      id,
      preferredFirstName,
      preferredLastName,
    );
    tooltipText = formatTooltipText(
      moment(new Date(completedAt)),
      timezone,
      nameDisplay,
    );
  }

  return (
    <div className={styles.cardSection}>
      <CardGrid
        rowGap={CardRowGaps.STANDARD}
        columnGap={CardColumnGaps.STANDARD}
        numberOfColumns={NumberOfCardColumns.ONE}
      >
        {memberNotesFields.map((field) => (
          <GridCell key={field.label}>
            <FieldLabel labelText={field.label} />
            <CardTextArea
              tooltipEventLabel={field.labelForAnalytics}
              tooltipText={field.value ? tooltipText : undefined}
              value={field.value ? formatListToString(field.value) : ''}
              readOnly={true}
              placeholder={Labels.EMPTY_VALUE_PLACEHOLDER}
              minRows={0}
            />
          </GridCell>
        ))}
      </CardGrid>
    </div>
  );
}
