import { MemberChartQuery_getMember } from '@headspace/carehub-graphql/dist/member-chart-cards/generated/MemberChartQuery';
import { MemberChartTab } from 'app/coach/member-chart/MemberChartTab';
import { AccessCard } from 'app/member-chart-cards/access-card/AccessCard';
import { BenefitsCard } from 'app/member-chart-cards/benefits-card/BenefitsCard';
import { ContactInfoCard } from 'app/member-chart-cards/contact-info/ContactInfoCard';
import React from 'react';

export const DetailsTab = ({
  memberInfo,
}: {
  memberInfo: MemberChartQuery_getMember | null;
}) => {
  return (
    <MemberChartTab>
      <BenefitsCard member={memberInfo} />

      <ContactInfoCard member={memberInfo} />

      <AccessCard member={memberInfo} />
    </MemberChartTab>
  );
};
