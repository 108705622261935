import { KeyboardArrowRight, ToggleOff, ToggleOn } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import { useAppState, useDispatch } from 'app/state';
import { toggleCardExpansion } from 'app/state/features/persistedLayout/persistedLayoutSlice';
import { selectCardState } from 'app/state/features/persistedLayout/selectors';
import { useLogger } from 'app/state/log/useLogger';
import { selectActiveTabMemberId } from 'app/state/member-tabs/selectors';
import { classNameCombiner } from 'utils';
import React, { ReactNode, useEffect, useState } from 'react';

import styles from './Card.module.scss';

export type CardProps = {
  children: ReactNode;
  boxTitle?: string;
  toggleLabel?: string;
  onToggle?: (isOn: boolean) => void;
  centered?: boolean;
  actionMenuClass?: string;
  'data-testid'?: string;
  size?: 'small' | 'medium' | 'large' | 'fit-vh' | 'fit-container';
  actionMenu?: ReactNode;
  boxContentClass?: string;
  toggleInitialState?: boolean;
  togglePosition?: 'left' | 'right' | 'center';
  isScrollable?: boolean;
  isCollapsible?: boolean;
};

export function Card({
  children,
  boxTitle,
  toggleLabel,
  onToggle,
  centered,
  'data-testid': testId,
  size = 'medium',
  actionMenu,
  actionMenuClass = '',
  boxContentClass = '',
  toggleInitialState = false,
  togglePosition = 'right',
  isScrollable = true,
  isCollapsible = true,
}: CardProps): JSX.Element {
  const [isToggleOn, setIsToggleOn] = useState(toggleInitialState);
  const [isOverflowHidden, setIsOverflowHidden] = useState(!isScrollable);
  const memberId = useAppState((state) => selectActiveTabMemberId(state));
  const logger = useLogger();
  const isBoxExpanded = useAppState((state) =>
    selectCardState(state, memberId, boxTitle),
  );

  const dispatch = useDispatch();
  useEffect(() => setIsOverflowHidden(!isScrollable), [isScrollable]);

  const handleBoxHeaderClick = (
    e: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    e.preventDefault();
    if (!memberId || !boxTitle) {
      logger.warning(
        'Card: Failed to toggle card expansion: memberId or boxTitle is missing',
        {
          boxTitle,
          memberId,
        },
      );
      return;
    }
    dispatch(
      toggleCardExpansion({
        cardTitle: boxTitle,
        memberId,
      }),
    );
  };
  const handleToggleClick = (): void => {
    const altToggleStatus: boolean = !isToggleOn;
    setIsToggleOn(altToggleStatus);
    if (onToggle) onToggle(altToggleStatus);
  };

  let toggleLabelStyling;
  let boxTitleStyling;
  let toggleButtonStyling;

  switch (togglePosition) {
    case 'center':
      toggleLabelStyling = styles.toggleLabelCenter;
      boxTitleStyling = styles.boxTitleCenter;
      toggleButtonStyling = styles.toggleButtonCenter;
      break;
    default:
      toggleLabelStyling = styles.toggleLabel;
      boxTitleStyling = styles.boxTitle;
      toggleButtonStyling = styles.toggleButton;
  }

  const toggleButton: JSX.Element = isToggleOn ? (
    <ToggleOn />
  ) : (
    <ToggleOff className={styles.disabled} />
  );
  const toggle: JSX.Element | null = toggleLabel ? (
    <button
      data-testid="toggle-btn"
      className={toggleButtonStyling}
      onClick={handleToggleClick}
    >
      {toggleButton}
    </button>
  ) : null;

  const titleStyle: string = centered
    ? classNameCombiner([boxTitleStyling, styles.centered])
    : boxTitleStyling;
  const boxHeader: JSX.Element | null = boxTitle ? (
    <>
      {toggle}
      <div className={classNameCombiner([styles.actionMenu, actionMenuClass])}>
        {actionMenu}
      </div>
      <button
        className={styles.boxHeader}
        onClick={handleBoxHeaderClick}
        disabled={!isCollapsible}
        data-testid="box-header"
      >
        {isCollapsible && (
          <div
            className={
              isBoxExpanded
                ? styles.expandIcon
                : classNameCombiner([styles.expandIcon, styles.rotate90deg])
            }
          >
            <KeyboardArrowRight />
          </div>
        )}
        <h4 className={titleStyle}>{boxTitle}</h4>
        <div className={toggleLabelStyling}>{toggleLabel || null}</div>
        <div className={styles.toggleBox} />
      </button>
    </>
  ) : null;

  const hideOverflow = () => {
    setIsOverflowHidden(true);
  };
  const showOverflow = () => {
    setIsOverflowHidden(false);
  };

  const contentStyle: string = boxTitle
    ? classNameCombiner([
        styles.boxContent,
        styles[`boxContent-${size}`],
        styles.hr,
        boxContentClass,
      ])
    : styles.boxContent;
  const boxContent = (
    <Collapse
      onEntering={hideOverflow}
      onEntered={showOverflow}
      onExiting={hideOverflow}
      onExited={showOverflow}
      in={isBoxExpanded}
      timeout={300}
      children={children}
      className={classNameCombiner([
        contentStyle,
        isOverflowHidden || !isScrollable
          ? styles.hideScrollbar
          : styles.showScrollbar,
      ])}
    />
  );

  return (
    <div
      data-testid={testId}
      className={classNameCombiner([styles.box, styles[`box-${size}`]])}
    >
      {boxHeader}
      {boxContent}
    </div>
  );
}
