import { DemographicsInput } from 'generated/globalTypes';
import React from 'react';
import { DatePicker } from 'shared-components/form-inputs/DatePicker';
import { Input } from 'shared-components/form-inputs/Input';
import { Option, Select } from 'shared-components/form-inputs/Select';
import { formatDate } from 'utils/dateTime';

import classes from './components.module.scss';

export enum PropType {
  TEXT,
  DATE,
  SELECT,
}

export type EditInputCardProps = {
  title: string;
  onSave?: (demographics: Partial<DemographicsInput>) => void;
  data: EditInputData[];
  preferredName?: string;
};

export type Validator = { isValid: boolean; error?: string };

export type DisabledInputData = {
  label: string;
  propValue: string | null | undefined;
};

export type EditInputData = {
  label: string;
  propName: keyof DemographicsInput;
  propValue: string | null | undefined;
  propType: PropType;
  validator: (input?: string) => Validator;
  options?: Array<Option>;
};

export type DemographicsInputControlProps = EditInputData & {
  onChange: (data: string | null) => void;
  error?: string;
  edit: boolean;
};

export function DemographicsInputControl(props: DemographicsInputControlProps) {
  const {
    label,
    propType,
    propName,
    propValue,
    options,
    onChange,
    edit,
    error,
  } = props;
  let content;
  if (!edit) {
    let text = propValue;
    if (propType === PropType.DATE) {
      text = propValue ? formatDate(propValue) : undefined;
    }
    if (!text || text === '') {
      text = 'N/A';
    }
    content = (
      <div data-testid="displayText" className={classes.text}>
        {text}
      </div>
    );
  } else if (propType === PropType.SELECT) {
    content = (
      <Select
        variant="standard"
        data-testid="selectInput"
        name={propName}
        className={classes.selectOption}
        error={error}
        value={propValue ?? undefined}
        placeholder={label}
        options={options || []}
        onChange={onChange}
      />
    );
  } else if (propType === PropType.DATE) {
    content = (
      <DatePicker
        variant="standard"
        data-testid="datepickerInput"
        className={classes.datePicker}
        name={propName}
        value={propValue ?? undefined}
        error={error}
        onChange={onChange}
      />
    );
  } else {
    content = (
      <Input
        data-testid="textInput"
        name={propName}
        error={error}
        value={propValue ?? undefined}
        onChange={onChange}
        placeholder={label}
      />
    );
  }
  return (
    <div className={classes.root} key={propName}>
      <label>{label}</label>
      {content}
    </div>
  );
}

export function DisabledDemographicsInput(props: DisabledInputData) {
  const { label, propValue } = props;
  return (
    <div className={classes.root} key={propValue}>
      <div data-testid="displayText" className={classes.text}>
        <label>{label}</label>
      </div>
      {propValue}
    </div>
  );
}
