import FormLabel from '@mui/material/FormLabel';
import { FormError } from 'shared-components/FormError';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';
import { NoteTooltip } from 'app/notes-ui/forms/form-controls/NoteTooltip';
import RadioButton from 'app/notes-ui/forms/form-controls/RadioButton';
import styles from 'app/notes-ui/forms/form-controls/YesOrNoQuestion.module.scss';
import { classNameCombiner } from 'utils';
import React from 'react';

export type Props = {
  name: string;
  label: string;
  gridClass?: string;
  buttonContainerClass?: string;
  labelSize?: 'XS' | 'M' | 'L';
  yesLabel?: string;
  noLabel?: string;
  value: boolean | undefined; // This prop is non-optional as undefined represents "user has not touched this yet"
  error?: string;
  disabled?: boolean;
  onChange: (value: boolean) => void;
  tooltip?: string;
  fullWidth?: boolean;
};

export function YesOrNoQuestion(props: Props) {
  const {
    name,
    value,
    label,
    error,
    disabled,
    tooltip,
    yesLabel,
    noLabel,
    fullWidth,
    labelSize = 'XS',
    gridClass = '',
    buttonContainerClass = '',
  } = props;

  const onChange = (value: boolean) => {
    props.onChange(value);
  };
  const width = fullWidth ? COLUMN_WIDTH.FULL : COLUMN_WIDTH.HALF;

  return (
    <div>
      <Grid spacing={0} className={classNameCombiner([styles.root, gridClass])}>
        <Columns widths={[width, width]}>
          <div className={styles[`label-${labelSize}`]}>
            <FormLabel aria-label={label}>
              <span data-testid="questionLabel">{label}</span>
              {tooltip && <NoteTooltip tooltip={tooltip} />}
            </FormLabel>
          </div>
          <div
            data-testid={name}
            className={classNameCombiner([buttonContainerClass])}
          >
            <RadioButton
              name={name}
              label={yesLabel || 'Yes'}
              value={true}
              checked={value === true}
              onChange={onChange}
              disabled={disabled}
            />

            <RadioButton
              name={name}
              label={noLabel || 'No'}
              value={false}
              checked={value === false}
              onChange={onChange}
              disabled={disabled}
            />
          </div>
        </Columns>
      </Grid>
      <FormError error={error} />
    </div>
  );
}
