import React from 'react';
import { classNameCombiner } from 'utils';

import styles from './UnreadIndicator.module.scss';

export const UnreadIndicator = (props: { className?: string }) => {
  const { className = '' } = props;

  return (
    <span className={classNameCombiner([styles.unreadIndicator, className])} />
  );
};
