import { getTasks } from 'app/coach/member-chart/queries';
import { isGraphQLAuthenticationError } from 'shared-components/error-state/utils';
import { getVariables } from 'app/member-chart-cards/tasks/useTasks';
import { Context } from 'app/state/context';
import { updateSessionState } from 'app/state/features/auth/authSlice';
import { SessionState } from 'app/state/features/auth/types';
import { State } from 'app/state/schema';
import { refreshTodaysTaskCard } from 'app/state/tasks/actions';
import { createActionHandlers } from 'redux-reloaded';

export const handlers = createActionHandlers<Context, State>();

handlers.on(refreshTodaysTaskCard, async ({ redux, context, action }) => {
  const { apollo, logger } = context.services;
  let variables;
  const {
    user: { userId, timezone, role },
  } = redux.getState();
  const { memberId } = action.payload;

  try {
    variables = await getVariables({
      includeCareProviderTasks: true,
      memberId,
      role: role!,
      timezone: timezone!,
      userId: userId!,
    });
    const { errors, error } = await apollo.query({
      fetchPolicy: 'network-only',
      query: getTasks,
      variables,
    });
    if (!errors && !error) return;

    if (
      isGraphQLAuthenticationError(error) ||
      errors?.some(isGraphQLAuthenticationError)
    ) {
      redux.dispatch(updateSessionState(SessionState.EXPIRED));
    } else {
      logger.error(
        new Error('refreshTodaysTaskCard: Unable to refresh task card query'),
        {
          error,
          errors,
          memberId,
          variables,
        },
      );
    }
  } catch (error) {
    logger.error(
      new Error('refreshTodaysTaskCard: Unable to refresh task card query'),
      { error, memberId, variables },
    );
  }
});
