import React, { PropsWithChildren } from 'react';

export interface GridCellProps {
  gridArea?: string;
}

type GridCellPropsWithChildren = PropsWithChildren<GridCellProps>;

export const GridCell = ({
  children,
  gridArea,
}: GridCellPropsWithChildren): React.ReactElement => {
  const style = {
    gridArea,
  };
  return <div style={gridArea ? style : {}}>{children}</div>;
};
