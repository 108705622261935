import { createReducer } from 'redux-reloaded';

import { selectPatient } from './actions';
import { State } from './schema';

export const reducer = createReducer<State>({
  selectedPatientId: null,
});

reducer.on(selectPatient, (state, { payload }) => ({
  ...state,
  selectedPatientId: payload ? payload.patientId : null,
}));
