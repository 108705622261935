import { FieldDefinitions, Fields } from '@ginger.io/react-use-form';
import { ExperiencingRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/ExperiencingRisk';
import { ExploringTheApp } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/ExploringTheApp';
import { OtherNote as OtherNoteVaultType } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/OtherNote';
import { SeekingClinical } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/SeekingClinical';
import {
  CoachNotesItem,
  DropInNoteType,
} from 'app/coach/coach-notes/CoachNotesTypes';
import { CardTextAreaField } from 'app/coach/coach-notes/note-detail-view/note-fields';
import {
  experiencingRiskFieldDefs,
  exploringTheAppFieldDefs,
  otherNoteFieldDefs,
  seekingClinicalFieldDefs,
} from 'app/coach/coach-notes/note-detail-view/note-templates/fieldDefs';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { RisksForm } from 'app/coach/coach-notes/note-detail-view/risk-templates/RisksForm';
import {
  ExperiencingRiskWithoutRisks,
  ExploringTheAppWithoutRisks,
  OtherNoteWithoutRisks,
  SeekingClinicalWithoutRisks,
} from 'app/coach/coach-notes/types';
import { useCoachNotesForm } from 'app/coach/coach-notes/useCoachNotesForm';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import React from 'react';

type OtherNoteType =
  | ExperiencingRisk
  | ExploringTheApp
  | SeekingClinical
  | OtherNoteVaultType;

type WithoutExperiencingRisk = Exclude<OtherNoteType, ExperiencingRisk>;

type OtherNoteTypesWithoutRisks =
  | ExperiencingRiskWithoutRisks
  | ExploringTheAppWithoutRisks
  | SeekingClinicalWithoutRisks
  | OtherNoteWithoutRisks;

export type OtherDropInNoteType =
  | DropInNoteType.EXPERIENCING_RISK
  | DropInNoteType.EXPLORING_THE_APP
  | DropInNoteType.SEEKING_CLINICAL
  | DropInNoteType.DROP_IN_OTHER;

interface NoteProps {
  fieldDefs: FieldDefinitions<OtherNoteTypesWithoutRisks>;
  tooltipEventLabel: string;
}

const dropInNoteProps: Record<OtherDropInNoteType, NoteProps> = {
  [DropInNoteType.EXPERIENCING_RISK]: {
    fieldDefs: experiencingRiskFieldDefs,
    tooltipEventLabel: 'Drop-in Team Notes > Experiencing risk',
  },
  [DropInNoteType.EXPLORING_THE_APP]: {
    fieldDefs: exploringTheAppFieldDefs,
    tooltipEventLabel: 'Drop-in Team Notes > Exploring the app',
  },
  [DropInNoteType.SEEKING_CLINICAL]: {
    fieldDefs: seekingClinicalFieldDefs,
    tooltipEventLabel: 'Drop-in Team Notes > Seeking clinical',
  },
  [DropInNoteType.DROP_IN_OTHER]: {
    fieldDefs: otherNoteFieldDefs,
    tooltipEventLabel: 'Drop-in Team Notes > Other',
  },
};

enum FieldLabels {
  GATHER = 'Gather',
  TITLE = 'Title this note',
  NOTES = 'Notes',
  NEXT_STEPS = "What's next?",
}

export enum Placeholders {
  TITLE = 'Unique name...',
  GATHER = 'Anxiety, panic, feeling down SI, relationships, stress, overwhelm...',
  GATHER_HELPER_TEXT = 'What caused the member to drop in? *',
  NOTES = 'Outreach, scheduling, other uncategorized details...',
  NEXT_STEPS = 'Homework, goal plan, future discussions, scheduling...',
  NEXT_STEPS_HELPER_TEXT = 'Remember to summarize your session to the member! :)',
}

export const OtherNote = ({
  noteType,
  readOnly,
  onStateChange,
}: {
  readOnly: boolean;
  noteType: DropInNoteType;
  onStateChange: (noteDraft: CoachNotesItem) => void;
}): React.ReactElement | null => {
  const { fieldDefs, tooltipEventLabel } = dropInNoteProps[
    noteType as OtherDropInNoteType
  ];
  const { fields, onChangeRisksForm, risks } = useCoachNotesForm<
    OtherNoteTypesWithoutRisks
  >({
    canContainNestedRisks: true,
    onStateChange,
    validationSchema: fieldDefs,
  });

  const isExperiencingRiskNoteType =
    noteType === DropInNoteType.EXPERIENCING_RISK;

  return (
    <>
      {noteType === DropInNoteType.DROP_IN_OTHER && (
        <>
          <FieldLabel className={styles.label} labelText={FieldLabels.TITLE} />
          <CardTextAreaField
            field={(fields as Fields<OtherNoteVaultType>).title}
            tooltipEventLabel={`${tooltipEventLabel}: ${FieldLabels.TITLE}`}
            tooltipText=""
            placeholder={Placeholders.TITLE}
            readOnly={readOnly}
            multiline={false}
          />
        </>
      )}

      <FieldLabel
        className={`${styles.label} ${styles.groupLabel}`}
        labelText={FieldLabels.GATHER}
      />
      <div className={`${styles.helperText} ${styles.subtle}`}>
        {Placeholders.GATHER_HELPER_TEXT}
      </div>
      <CardTextAreaField
        field={fields.whatCausedMemberToDropIn}
        tooltipEventLabel={`${tooltipEventLabel}: ${Placeholders.GATHER}`}
        tooltipText=""
        placeholder={Placeholders.GATHER}
        readOnly={readOnly}
      />

      {!isExperiencingRiskNoteType && (
        <>
          <FieldLabel className={styles.label} labelText={FieldLabels.NOTES} />
          <CardTextAreaField
            field={(fields as Fields<WithoutExperiencingRisk>).notes}
            tooltipEventLabel={`${tooltipEventLabel}: ${FieldLabels.NOTES}`}
            tooltipText=""
            placeholder={Placeholders.NOTES}
            readOnly={readOnly}
          />

          <FieldLabel
            className={styles.label}
            labelText={FieldLabels.NEXT_STEPS}
          />
          {!readOnly && (
            <div className={styles.helperText}>
              {Placeholders.NEXT_STEPS_HELPER_TEXT}
            </div>
          )}
          <CardTextAreaField
            field={(fields as Fields<WithoutExperiencingRisk>).nextSteps}
            tooltipEventLabel={`${tooltipEventLabel}: ${FieldLabels.NEXT_STEPS}`}
            tooltipText=""
            placeholder={Placeholders.NEXT_STEPS}
            readOnly={readOnly}
          />
        </>
      )}

      <RisksForm
        risks={risks}
        onChange={onChangeRisksForm}
        readOnly={readOnly}
        className={isExperiencingRiskNoteType ? styles.dropInCardSection : ''}
        showDropdown={isExperiencingRiskNoteType}
        inline={true}
      />
    </>
  );
};
