export enum Font {
  COURIER = 'Courier',
  COURIER_BOLD = 'Courier-Bold',
  COURIER_OBLIQUE = 'Courier-Oblique',
  COURIER_BOLD_OBLIQUE = 'Courier-BoldOblique',
  HELVETICA = 'Helvetica',
  HELVETICA_BOLD = 'Helvetica-Bold',
  HELVETICA_OBLIQUE = 'Helvetica-Oblique',
  HELVETICA_BOLD_OBLIQUE = 'Helvetica-BoldOblique',
  SYMBOL = 'Symbol',
  TIMES_ROMAN = 'Times-Roman',
  TIMES_BOLD = 'Times-Bold',
  TIMES_ITALIC = 'Times-Italic',
  TIMES_BOLD_ITALIC = 'Times-BoldItalic',
  ZAPF_DINGBATS = 'ZapfDingbats',
  NOTO = 'NotoSerif-Regular',
  NOTO_BOLD = 'NotoSerif-Bold',
}

// PDFKit is in 72 DPI. This const makes it easier for us to maintain 1-inch margins.
export const oneInchInPixels = 72;
