import { AmplitudeLogsContext } from 'app/coach/chat/types';
import { MessageToDisplay } from 'app/coach/pubnub/types';
import { createAction } from 'redux-reloaded';

import { CountDown } from './schema';

export enum Actions {
  START_TIMED_SEND = 'START_TIMED_SEND',
  UPDATE_TIMED_SEND_STATE = 'UPDATE_TIMED_SEND_STATE',
  NULLIFY_COUNTDOWN = 'NULLIFY_COUNTDOWN',
  DECREMENT_COUNTDOWN = 'DECREMENT_COUNTDOWN',
  UPDATE_COUNTDOWN = 'UPDATE_COUNTDOWN',
}

export const startTimedSend = createAction(
  Actions.START_TIMED_SEND,
  (payload: {
    channelId: string;
    messageText: string;
    currentMessages: MessageToDisplay[];
    logsContext: AmplitudeLogsContext;
  }) => payload,
);

export const updateTimedSend = createAction(
  Actions.UPDATE_TIMED_SEND_STATE,
  (payload: {
    channelId: string;
    timeoutId: NodeJS.Timeout | null;
    message: string;
    countDown: CountDown;
  }) => payload,
);

export const nullifyCountdown = createAction(
  Actions.NULLIFY_COUNTDOWN,
  (payload: { channelId: string }) => payload,
);

export const updateCountDown = createAction(
  Actions.UPDATE_COUNTDOWN,
  (payload: { channelId: string; newValue: number }) => payload,
);
