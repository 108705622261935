import { Metadata } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import {
  TherapyIntakeSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import { TherapyIntakeSectionEncoder } from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSectionEncoder';
import { Base64 } from '@ginger.io/vault-core/dist/crypto/Base64';
import {
  VaultItem,
  VaultItem_SchemaType,
} from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import { getClinicalCareTeamGroupId } from '@ginger.io/vault-core/dist/IdHelpers';
import { VaultItemPermissions, VaultSystemName } from '@ginger.io/vault-ui';
import { DeleteVaultItemsMutation } from '@ginger.io/vault-ui/src/generated/graphql';
import { AmendmentWithAuditLog } from 'app/notes-ui/shared/amendments/types';
import {
  GetMemberIntakeSurvey,
  GetMemberIntakeSurvey_getMemberIntakeSurvey_intakeSurvey,
  GetMemberIntakeSurveyVariables,
} from 'app/queries/generated/GetMemberIntakeSurvey';
import { getMemberIntakeSurveyQuery } from 'app/queries/GetMemberIntakeSurvey';
import { decodeTherapyIntakeNote } from 'app/vault/data/decodeTherapyIntakeNote';
import { GetAppointmentById_getAppointmentById as Appointment } from 'app/vault/generated/GetAppointmentById';

import { ClinicalNotesAPI } from './ClinicalNotesAPI';
import { TherapyIntakeIds } from './TherapyIntakeIds';
import { deletionMutationErrorHandler } from './utils';

export type TherapyIntakeNote = {
  [T in TherapyIntakeSectionName]:
    | Extract<TherapyIntakeSection, { name: T }>['data']
    | null;
} & {
  metadata: Metadata;
  amendments: AmendmentWithAuditLog[];
  permissions: VaultItemPermissions | null;
  createdAt: string | null; // ISO date that reps when the note metadata was created
  updatedAt: string | null; // ISO date that reps when the note metadata was last modified e.g. when a note is signed & locked
};

export class TherapyIntakeNotesAPI extends ClinicalNotesAPI<
  TherapyIntakeNote,
  TherapyIntakeSection
> {
  protected ids = TherapyIntakeIds;

  protected sectionNames = TherapyIntakeSectionName;

  readonly amendmentSectionName = TherapyIntakeSectionName.AMENDMENTS;

  async getNote(appointmentId: string): Promise<TherapyIntakeNote> {
    const groupId = await this.getClinicalCareTeamGroupId(appointmentId);
    const items = await this.vaultAPI.getVaultItemsByTag(
      TherapyIntakeIds.noteSectionsTag(appointmentId),
      { groupId },
    );
    const amendments = await this.getAmendments(appointmentId);
    return decodeTherapyIntakeNote(appointmentId, items, amendments);
  }

  protected encodeSection(data: TherapyIntakeSection): VaultItem {
    return TherapyIntakeSectionEncoder.encode(data);
  }

  protected encodeMetadata(metadata: Metadata): VaultItem {
    return {
      data: Metadata.encode(metadata).finish(),
      schemaType:
        VaultItem_SchemaType.vault_clinical_notes_therapy_intake_metadata,
    };
  }

  async deleteDraftNote(
    userId: string,
    appointment: Appointment,
    note: TherapyIntakeNote,
  ): Promise<DeleteVaultItemsMutation> {
    const groupId = await Base64.hash(
      getClinicalCareTeamGroupId(appointment.member.id),
    );
    const vaultItemIds = Object.values(TherapyIntakeSectionName)
      .filter((_) => note[_] !== null)
      .map((name) => TherapyIntakeIds.section(appointment.id, name));

    if (note.metadata) {
      vaultItemIds.push(TherapyIntakeIds.metadata(appointment.id));
    }

    const deletedVaultItems = await this.vaultAPI.deleteVaultItems({
      groupId,
      itemIds: vaultItemIds,
      shareWithSystems: [VaultSystemName.ClinicalNotesSyncProcess],
    });
    deletionMutationErrorHandler(deletedVaultItems); // throws on error
    await this.updateAppointmentCache(appointment.id);
    return deletedVaultItems;
  }

  async getMemberIntakeSurvey(
    memberId: string,
  ): Promise<GetMemberIntakeSurvey_getMemberIntakeSurvey_intakeSurvey> {
    const { data, error: apolloError } = await this.apollo.query<
      GetMemberIntakeSurvey,
      GetMemberIntakeSurveyVariables
    >({
      query: getMemberIntakeSurveyQuery,
      variables: { input: { id: memberId } },
    });

    const intakeSurvey = data.getMemberIntakeSurvey?.intakeSurvey;

    if (apolloError) {
      throw apolloError;
    }
    if (!intakeSurvey) {
      throw new Error(`No intake survey results returned for ${memberId}`);
    }

    return intakeSurvey;
  }
}
