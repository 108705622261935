import {
  arrayField,
  field,
  FieldDefinitions,
  stringField,
  useForm,
} from '@ginger.io/react-use-form';
import { ClinicianRole } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/ClinicianRole';
import {
  TerminationReasons,
  TerminationReasons_Reason as Reason,
  TerminationReasons_Version as Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/TerminationReasons';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import { CardTextArea } from 'app/member-chart-cards/text-area/CardTextArea';
import { enumToOptions } from 'utils/notes';
import { Subsection } from 'app/vault/api/ShareableSubsectionTypes';
import React from 'react';

enum FieldLabels {
  REASON = 'Reason for termination',
}

export const fieldDefs: FieldDefinitions<TerminationReasons> = {
  clinicianId: stringField({ rules: [] }),
  clinicianRole: field<ClinicianRole>({ rules: [] }),
  patientId: stringField({ rules: [] }),
  reasons: arrayField({ rules: [] }),
  version: field<Version>({ default: Version.v0, rules: [] }),
};

export const TerminationNote = ({
  selectedNote,
  readOnly,
  tooltipEventLabel,
}: {
  readOnly: boolean;
  tooltipEventLabel: string;
  selectedNote?: Subsection;
}): React.ReactElement | null => {
  const { fields } = useForm<TerminationReasons>(fieldDefs, {
    ...(selectedNote as TerminationReasons),
  });

  return (
    <>
      <FieldLabel className={styles.label} labelText={FieldLabels.REASON} />
      {fields.reasons.value.map((reason) => (
        <CardTextArea
          key={reason}
          value={
            enumToOptions(Reason).filter((option) => option.value === reason)[0]
              ?.name
          }
          tooltipEventLabel={`${tooltipEventLabel}: ${FieldLabels.REASON}`}
          tooltipText=""
          placeholder=""
          readOnly={readOnly}
          minRows={1}
        />
      ))}
    </>
  );
};
