import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import styles from 'app/coach/coach-notes/CoachNotes.module.scss';
import {
  CoachNotesActions,
  NavigationParams,
} from 'app/coach/coach-notes/CoachNotesTypes';
import {
  NOTE_CREATION_MENU_ITEMS,
  NOTE_DRAFT_MENU_ITEMS,
} from 'app/coach/coach-notes/constants';
import { ActionDialog } from 'shared-components/ActionDialog';
import {
  CardActionMenu,
  MENU_KEY_TYPE,
} from 'shared-components/CardActionMenu';
import { CoachNotesRoutes } from 'app/top-nav/Routes';
import { useAppState } from 'app/state';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import React, { useState } from 'react';
import { Button } from 'shared-components/button/Button';

import { isNoteValidToSignAndLock } from './utils';

const DELETE_WARNING_MESSAGE =
  'Are you sure you want to delete this note? This action is irreversible.';
const PUBLISH_WARNING_MESSAGE =
  "Once a note is published, you will not be able to make changes.\n\nYour note will automatically be shared with other coaches and clinicians on this member's care team.";

export const ActionMenu = ({
  showNoteDraftActions,
  publishNote,
  deleteCoachNote,
  navigateTo,
  'data-testid': testId = 'draftActionMenu',
}: {
  navigateTo: (params?: NavigationParams) => void;
  publishNote: () => Promise<void>;
  deleteCoachNote: () => Promise<void>;
  showNoteDraftActions?: boolean;
  'data-testid'?: string;
}): React.ReactElement => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const {
    enable_drop_in_notes: enableDropInNotes,
  } = useFeatureFlags().transientFeatureFlags;
  const selectedNote = useAppState(
    ({ coachNotes: { selectedNote } }) => selectedNote,
  );

  const _setIsPublishModalOpen = () => {
    setIsPublishModalOpen(true);
  };

  const onSelectDraftMenu = (action: MENU_KEY_TYPE) => {
    if (action === CoachNotesActions.DELETE_DRAFT) {
      setIsDeleteModalOpen(true);
    }
  };

  const onSelect = (noteType: MENU_KEY_TYPE) => {
    navigateTo({ noteType });
  };

  const menuItems = NOTE_CREATION_MENU_ITEMS;
  if (
    enableDropInNotes &&
    !menuItems.find((item) => item.key === CoachNotesRoutes.DROP_IN)
  ) {
    menuItems.splice(1, 0, { key: CoachNotesRoutes.DROP_IN, label: 'Drop-in' });
  }

  const enableSignAndLockButton = isNoteValidToSignAndLock(selectedNote);

  return (
    <>
      <ActionDialog
        data-testid="publishActionDialog"
        className={styles.actionDialog}
        bodyClass={styles.actionDialogBody}
        cancelBtnClass={styles.actionDialogCancel}
        confirmBtnClass={styles.publishDialogConfirm}
        titleText="Sign & Lock?"
        confirmLabel="Yes, Sign & Lock"
        isOpen={isPublishModalOpen}
        setIsOpen={setIsPublishModalOpen}
        actionHandler={publishNote}
        dialogMessage={{
          message: PUBLISH_WARNING_MESSAGE,
        }}
      />
      <ActionDialog
        data-testid="deleteActionDialog"
        className={styles.actionDialog}
        bodyClass={styles.actionDialogBody}
        cancelBtnClass={styles.actionDialogCancel}
        confirmBtnClass={styles.deleteDialogConfirm}
        titleText="Delete draft note?"
        confirmLabel="Yes, Delete"
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        actionHandler={deleteCoachNote}
        dialogMessage={{
          message: DELETE_WARNING_MESSAGE,
        }}
      />
      {showNoteDraftActions ? (
        <div data-testid={testId} className={styles.draftMenuContainer}>
          {!selectedNote?.readOnly && (
            <>
              <CardActionMenu
                data-testid="draftMenu"
                className={styles.draftMenu}
                menuItems={NOTE_DRAFT_MENU_ITEMS}
                onSelect={onSelectDraftMenu}
                actionIcon={<MoreHorizIcon />}
              />
              {selectedNote && (
                <Button
                  testId="signLockButton"
                  className={styles.publishButton}
                  onClick={_setIsPublishModalOpen}
                  disabled={!enableSignAndLockButton}
                >
                  Sign & Lock
                </Button>
              )}
            </>
          )}
        </div>
      ) : (
        <CardActionMenu
          tooltipText="NEW NOTE"
          tooltipEventLabel="Coach Notes > Add new note"
          menuItems={menuItems}
          onSelect={onSelect}
          actionIcon={<AddIcon />}
        />
      )}
    </>
  );
};
