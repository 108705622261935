import CloseIcon from '@mui/icons-material/Close';
import MuiDialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { getErrorMessage } from 'app/appointments/errorUtils';
import { RequestErrorState } from 'app/state/request/schema';
import React from 'react';

import { BugReportButton } from '../BugReportButton';
import styles from './ErrorModal.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmitReport: () => void;
  errors: Array<RequestErrorState['error']>;
  title: string;
}

export function ErrorModal({
  isOpen,
  title,
  onClose,
  onSubmitReport,
  errors,
}: Readonly<Props>) {
  const message = errors.map((error) => getErrorMessage(error)).join('\n\n');
  return (
    <MuiDialog
      data-testid="dialog"
      disableEscapeKeyDown={true}
      onClose={onClose}
      maxWidth="lg"
      aria-labelledby="Error Details Modal"
      open={isOpen}
      classes={{ root: styles.errorDetailsModal }}
    >
      <MuiDialogTitle className={styles.dialogTitle}>
        <span data-testid="dialogTitle">{title}</span>
        <IconButton
          aria-label="close"
          data-testid="titleCloseBtn"
          className={styles.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <MuiDialogContent
        data-testid="error-details-message"
        className={styles.dialogContent}
      >
        <p>{message}</p>
        <p>
          Please refresh the page and try again. If the problem persists,
          contact support.
        </p>
      </MuiDialogContent>
      <MuiDialogActions className={styles.dialogAction}>
        <BugReportButton errors={errors} onSubmit={onSubmitReport} />
      </MuiDialogActions>
    </MuiDialog>
  );
}
