export enum Width {
  FULL = 'FULL',
  HALF = 'HALF',
  MAXWIDTH = 'MAXWIDTH',
}

export enum Size {
  SM = 'sm',
  MD = 'md',
}
