import React from 'react';
import { classNameCombiner } from 'utils';
import styles from './CommunicationBubble.module.scss';
import { ShowMoreText } from 'app/collaboration/ShowMoreText';
import { ChatMessage } from 'app/collaboration/type';
import { CoachClinicianCollaborationChatMessage_GeneratedFrom } from '@ginger.io/vault-care-collaboration/dist/generated/protobuf-schemas/vault-care-collaboration/CoachClinicianCollaborationChatMessage';

const CommnunicationLabel = ({
  label,
  isOwner,
}: {
  label: string;
  isOwner: boolean;
}) => (
  <div
    className={classNameCombiner([
      isOwner ? styles.alertSection : styles.replyAlertSection,
      isOwner ? styles.alertText : styles.replyAlertText,
    ])}
  >
    <span>{label}</span>
  </div>
);

export const CommunicationBubble = ({
  chat,
  isOwner,
}: {
  chat: ChatMessage;
  isOwner: boolean;
}) => {
  const { generatedFrom } = chat;

  return (
    <div
      className={classNameCombiner([
        styles.message,
        !isOwner ? styles.ownerBubble : '',
      ])}
    >
      {chat.requireTimelyReview && (
        <CommnunicationLabel label="Requires review" isOwner={isOwner} />
      )}
      {generatedFrom ===
        CoachClinicianCollaborationChatMessage_GeneratedFrom.si_transfer && (
        <CommnunicationLabel label="Requires SI review" isOwner={isOwner} />
      )}
      {chat.subject ? (
        <>
          <p
            className={classNameCombiner([
              styles.subject,
              !isOwner ? styles.ownerSubject : '',
            ])}
          >
            {chat.subject}
          </p>
          <div
            className={classNameCombiner([
              styles.text,
              !isOwner ? styles.ownerText : '',
            ])}
          >
            <ShowMoreText lines={4}>
              {chat.body.split('\n').map((paragraph, i, arr) => {
                const line = <span key={i}>{paragraph}</span>;
                if (i === arr.length - 1) {
                  return line;
                } else {
                  return [line, <br key={i + 'br'} />];
                }
              })}
            </ShowMoreText>
          </div>
        </>
      ) : (
        <span
          className={classNameCombiner([
            styles.text,
            !isOwner ? styles.ownerText : '',
          ])}
        >
          {chat.body}
        </span>
      )}
    </div>
  );
};
