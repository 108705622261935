import React from 'react';

import styles from './IncompleteSurveyWarning.module.scss';

const IncompleteSurveyWarning = () => {
  return (
    <p className={styles.message}>Member has not completed this survey yet.</p>
  );
};

export default IncompleteSurveyWarning;
