import { EnumOption, ProtobufEnum } from 'app/notes-ui/types';
import { enumToOptions } from 'utils/notes';
import React, { useMemo } from 'react';
import { Dropdown } from 'shared-components/Dropdown';
import { Size, Width } from 'types/StyleTypes';

export type Props<T> = {
  enum: ProtobufEnum;
  onSelect: (selected: T) => void;
  'data-testid'?: string;
  initialValue?: T;
  disabled?: boolean;
  className?: string;
  menuItemClass?: string;
  selectClass?: string;
  placeholder?: string;
  width?: Width;
  size?: Size;
  error?: string;
  optionLabels?: Record<string, string>;
  convertEnumToOptions?: (obj: ProtobufEnum) => EnumOption[];
};

/** Renders a drop down for a generated protobuf enum
 *
 * Enum values are assumed to be snake case and will be rendered in the UI in sentence case e.g. "foo_value"
 * is displayed in the dropdown as "Foo value"
 */
export function EnumDropdown<T>(props: Props<T>) {
  const options = useMemo(
    () =>
      props.convertEnumToOptions
        ? props.convertEnumToOptions(props.enum)
        : enumToOptions(props.enum, props.optionLabels),
    [props.enum, props.optionLabels],
  );
  const {
    placeholder,
    disabled,
    className,
    menuItemClass,
    selectClass,
    initialValue,
    width,
    error,
    onSelect,
    size,
  } = props;
  return (
    <Dropdown
      dataTestId={props['data-testid']} // eslint-disable-line react/destructuring-assignment
      placeholder={placeholder}
      initialValue={(initialValue as any) as number}
      disabled={disabled}
      className={className}
      menuItemClass={menuItemClass}
      selectClass={selectClass}
      options={options}
      error={error}
      onSelect={(enumValue) => onSelect((enumValue as any) as T)}
      width={width}
      size={size}
    />
  );
}
