import { Interventions } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Interventions';
import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import {
  ClinicianIntakeChecklist_Version,
  Location,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/ClinicianIntakeChecklist';
import {
  CurrentFunctioning_CopingSkills,
  CurrentFunctioning_Stressors,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/CurrentFunctioning';
import {
  PresentingProblem_SymptomImpact,
  PresentingProblem_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/PresentingProblem';
import {
  SocialAndDevelopmental_Employment,
  SocialAndDevelopmental_EmploymentStatus_Status,
  SocialAndDevelopmental_LivingArrangement,
  SocialAndDevelopmental_LivingSituation_Status,
  SocialAndDevelopmental_MaritalStatus_Status,
  SocialAndDevelopmental_RelationShipStatus,
  SocialAndDevelopmental_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/SocialAndDevelopmental';
import { TreatmentPlan_Version } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/TreatmentPlan';
import { ApproachType } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/ApproachType';
import { CPTCode } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/CPTCode';
import { TherapyIntakeSectionName } from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import Labels from 'app/notes-ui/strings/en.json';
import { therapyIntakeSectionToLabel } from 'app/notes-ui/utils';
import { ILogger } from 'app/state/log/Logger';
import { TherapyIntakeNote } from 'app/vault/api/TherapyIntakeNotesAPI';

import { EnumOption } from '../types';
import { NotePDFBuilder, NotePDFBuilderImpl } from './NotePDFBuilder';
import { TherapyNotePDF } from './TherapyNotePDF';
import { NoteHeader } from './types';
import { buildAssessmentCodeSets } from './utils';

export class TherapyIntakeNotePDF extends TherapyNotePDF<TherapyIntakeNote> {
  constructor(
    protected note: TherapyIntakeNote,
    protected noteHeader: NoteHeader,
    protected diagnosisCodesOptions: EnumOption[],
    protected logger: ILogger,
    protected password?: string,
    protected enableNewPdfFont?: boolean,
    protected timezone = 'America/Los_Angeles',
    protected pdfBuilder: NotePDFBuilder = new NotePDFBuilderImpl(
      password,
      therapyIntakeSectionToLabel,
      enableNewPdfFont,
    ),
  ) {
    super(
      note,
      noteHeader,
      timezone,
      diagnosisCodesOptions,
      logger,
      pdfBuilder,
    );
  }

  getPDF(): typeof PDFDocument {
    this.header();
    this.clinicianChecklist();
    this.presentingProblem();
    this.currentFunctioning();
    this.socialDevelopmental();
    this.medicalHealth();
    this.safetyIntake();
    this.behavioralObservation();
    this.assessment();
    this.treatmentPlan();
    this.substanceAbuse();
    this.collaborationPlan();
    this.amendments(TherapyIntakeSectionName.AMENDMENTS);

    return this.pdfBuilder.build();
  }

  private clinicianChecklist() {
    this.pdfBuilder.section(
      TherapyIntakeSectionName.CLINICIAN_CHECKLIST,
      () => {
        const checklist = this.note.CLINICIAN_CHECKLIST;
        if (!checklist) return false;

        if (checklist.version === ClinicianIntakeChecklist_Version.v0) {
          this.pdfBuilder
            .yesOrNoAnswer(
              Labels.INTRO_COACHING_TO_MEMBER_INTAKE,
              checklist.introducedCoachingToMember,
            )
            .radioItemAnswer(
              Location,
              Labels.PHYSICAL_LOCATION,
              checklist.physicallyLocatedAtApptAddress,
            )
            .shortTextAnswer(
              Labels.CURRENT_LOCATION,
              checklist.currentLocation,
            );
        } else {
          this.pdfBuilder
            .yesOrNoAnswer(
              Labels.INTRO_COACHING_TO_MEMBER_INTAKE,
              checklist.introducedCoachingToMember,
            )
            .yesOrNoAnswer(
              Labels.MEMBER_SAID_YES_TO_THOUGHTS_OF_HARM,
              checklist.memberSaidYesToThoughtsOfHarm,
            )
            .yesOrNoAnswer(
              Labels.REVIEWED_INFORMED_CONSENT,
              checklist.reviewedInformedConsentWithMember,
            )
            .yesOrNoAnswer(
              Labels.REVIEWED_INTAKE_BEFORE_MEETING,
              checklist.reviewedIntakeBeforeMeeting,
            );
        }
        return true;
      },
      'No clinician checklist',
    );
  }

  private presentingProblem() {
    this.pdfBuilder.section(
      TherapyIntakeSectionName.PRESENTING_PROBLEM,
      () => {
        const problem = this.note.PRESENTING_PROBLEM;
        if (!problem) return false;

        if (problem.version === PresentingProblem_Version.v0) {
          this.pdfBuilder
            .longTextAnswer(
              Labels.PRESENTING_PROBLEM,
              problem.presentingProblem,
            )
            .padding()
            .enumSelectionsGroup(
              PresentingProblem_SymptomImpact,
              problem.impactOfSymptoms,
              Labels.IMPACT_OF_SYMPTOMS,
              'No impact reported',
            )
            .padding()
            .radioItemAnswer(
              BooleanOption,
              Labels.CURRENT_TREATMENT,
              problem.receivingTreatmentBeyondClinician,
            )
            .padding()
            .longTextAnswer(
              Labels.COMMENTS_ON_CURRENT_TREATMENT,
              problem.currentTreatmentDescription,
            )
            .padding()
            .longTextAnswer(
              Labels.PAST_MENTAL_TREATMENT,
              problem.pastMentalHealthTreatment,
            )
            .padding()
            .longTextAnswer(
              Labels.MEDICAL_CONCERNS,
              problem.historyOfPresentingProblem,
            );
        } else {
          this.pdfBuilder
            .longTextAnswer(
              Labels.PRESENTING_PROBLEM,
              problem.presentingProblem,
            )
            .padding()
            .longTextAnswer(
              Labels.HISTORY_OF_PROBLEM,
              problem.historyOfPresentingProblem,
            )
            .padding()
            .longTextAnswer(Labels.SYMPTOMS_REPORTED, problem.symptomsReported)
            .padding()
            .enumSelectionsGroup(
              PresentingProblem_SymptomImpact,
              problem.impactOfSymptoms,
              Labels.IMPACT_OF_SYMPTOMS,
              'No impact reported',
            );
        }
        return true;
      },
      'No presenting problem',
    );
  }

  private currentFunctioning() {
    this.pdfBuilder.section(
      TherapyIntakeSectionName.CURRENT_FUNCTIONING,
      () => {
        const functioning = this.note.CURRENT_FUNCTIONING;
        if (!functioning) return false;
        this.pdfBuilder
          .enumSelectionsGroup(
            CurrentFunctioning_Stressors,
            functioning.currentStressors,
            Labels.CURRENT_STRESSORS,
            'No stressors reported',
          )
          .padding();
        if (
          functioning.currentStressors.includes(
            CurrentFunctioning_Stressors.stressors_other,
          )
        ) {
          this.pdfBuilder
            .shortTextAnswer(
              Labels.OTHER_COMMENT,
              functioning.stressorsComments,
            )
            .padding();
        }
        this.pdfBuilder.enumSelectionsGroup(
          CurrentFunctioning_CopingSkills,
          functioning.copingSkills,
          Labels.COPING_SKILLS,
          'No coping skills reported',
        );
        if (
          functioning.copingSkills.includes(
            CurrentFunctioning_CopingSkills.coping_skills_other,
          )
        ) {
          this.pdfBuilder
            .shortTextAnswer(
              Labels.OTHER_COMMENT,
              functioning.copingSkillsComments,
            )
            .padding();
        }
        return true;
      },
      'No data on current functioning',
    );
  }

  private socialDevelopmental() {
    this.pdfBuilder.section(
      TherapyIntakeSectionName.SOCIAL_DEVELOPMENTAL,
      () => {
        const socialDev = this.note.SOCIAL_DEVELOPMENTAL;
        if (!socialDev) return false;

        if (socialDev.version === SocialAndDevelopmental_Version.v0) {
          this.pdfBuilder
            .enumSelectionsGroup(
              SocialAndDevelopmental_RelationShipStatus,
              socialDev.relationshipStatus,
              Labels.RELATIONSHIP_STATUS,
              'No relationship status',
            )
            .padding();
          if (
            socialDev.relationshipStatus.includes(
              SocialAndDevelopmental_RelationShipStatus.relationship_status_other,
            )
          ) {
            this.pdfBuilder
              .shortTextAnswer(
                Labels.OTHER_COMMENT,
                socialDev.otherRelationshipStatusComments,
              )
              .padding();
          }

          this.pdfBuilder
            .enumSelectionsGroup(
              SocialAndDevelopmental_LivingArrangement,
              socialDev.livingArrangement,
              Labels.LIVING_SITUATION,
              'No living arrangement',
            )
            .padding();
          if (
            socialDev.livingArrangement.includes(
              SocialAndDevelopmental_LivingArrangement.living_arrangement_other,
            )
          ) {
            this.pdfBuilder
              .shortTextAnswer(
                Labels.OTHER_COMMENT,
                socialDev.otherLivingArrangementComments,
              )
              .padding();
          }

          this.pdfBuilder
            .enumSelectionsGroup(
              SocialAndDevelopmental_Employment,
              socialDev.employment,
              Labels.EMPLOYMENT_STATUS,
              'No employment status',
            )
            .padding();
          if (
            socialDev.employment.includes(
              SocialAndDevelopmental_Employment.other,
            )
          ) {
            this.pdfBuilder
              .shortTextAnswer(
                Labels.OTHER_COMMENT,
                socialDev.otherEmploymentComments,
              )
              .padding();
          }
        } else {
          this.pdfBuilder
            .radioItemAnswer(
              SocialAndDevelopmental_MaritalStatus_Status,
              Labels.MARITAL_STATUS_STATUS,
              socialDev.maritalStatus?.status,
            )
            .longTextAnswer(
              Labels.MARITAL_STATUS_COMMENT,
              socialDev.maritalStatus?.comment,
            )
            .padding()
            .radioItemAnswer(
              SocialAndDevelopmental_LivingSituation_Status,
              Labels.LIVING_SITUATION_STATUS,
              socialDev.livingSituation?.status,
            )
            .longTextAnswer(
              Labels.LIVING_SITUATION_COMMENT,
              socialDev.livingSituation?.comment,
            )
            .padding()
            .radioItemAnswer(
              SocialAndDevelopmental_EmploymentStatus_Status,
              Labels.EMPLOYMENT_STATUS,
              socialDev.employmentStatus?.status,
            )
            .padding();
        }
        this.pdfBuilder
          .longTextAnswer(
            Labels.SOCIAL_FAMILY_HISTORY,
            socialDev.relevantSocialOrFamilyHistory,
          )
          .padding()
          .longTextAnswer(Labels.OTHER_COMMENTS, socialDev.otherComments)
          .padding()
          .longTextAnswer(
            Labels.CULTURAL_CONSIDERATIONS,
            socialDev.culturalConsiderations,
          );
        return true;
      },
      'No social/developmental data',
    );
  }

  private medicalHealth() {
    this.pdfBuilder.section(
      TherapyIntakeSectionName.MEDICAL_HEALTH,
      () => {
        const medical = this.note.MEDICAL_HEALTH;

        if (medical) {
          this.pdfBuilder
            .yesOrNoAnswer(
              Labels.CURRENT_TREATMENT_RECEIVING_BEYOND_CLINICIAN,
              medical.currentTreatment?.receivingTreatmentBeyondClinician,
            )
            .padding()
            .longTextAnswer(
              Labels.CURRENT_TREATMENT_DESCRIPTION,
              medical.currentTreatment?.description,
            )
            .padding()
            .longTextAnswer(
              Labels.PAST_TREATMENT_DESCRIPTION,
              medical.pastTreatment?.description,
            )
            .padding()
            .longTextAnswer(
              Labels.CURRENT_MEDICAL_ISSUES_DESCRIPTION,
              medical.currentMedicalIssues?.description,
            )
            .padding()
            .longTextAnswer(
              Labels.CURRENT_MEDICATION_DESCRIPTION,
              medical.currentMedication?.description,
            );
          return true;
        }
        return false;
      },
      'No data for medical health',
    );
  }

  private assessment() {
    this.pdfBuilder.section(
      TherapyIntakeSectionName.ASSESSMENT,
      () => {
        const assessment = this.note.ASSESSMENT;

        if (assessment) {
          buildAssessmentCodeSets(
            this.pdfBuilder,
            CPTCode,
            this.diagnosisCodesOptions,
            assessment.codeSets,
            assessment.cptCode,
            assessment.icd10Codes,
            this.logger,
          );
          return true;
        }
        return false;
      },
      'No assessment data',
    );
  }

  private treatmentPlan() {
    this.pdfBuilder.section(
      TherapyIntakeSectionName.TREATMENT_PLAN,
      () => {
        const plan = this.note.TREATMENT_PLAN;
        if (!plan) return false;
        // goal field is required on new templates
        if (plan.version === TreatmentPlan_Version.v0 || plan.goal.length > 0) {
          plan.goal.forEach((goal, index) => {
            this.treatmentGoal(goal, index + 1);
          });
          this.pdfBuilder
            .longTextAnswer(Labels.MESSAGE_TO_CARE_TEAM, plan.messageToCareTeam)
            .padding()
            .enumSelectionsGroup(
              Interventions,
              plan.interventionsUsed,
              Labels.INTERVENTIONS_USED,
              'No interventions selected.',
            )
            .padding()
            .enumSelectionsGroup(
              ApproachType,
              plan.approaches,
              Labels.APPROACH,
              'No approaches selected.',
            )
            .padding()
            .longTextAnswer(
              Labels.NUMBER_OF_SESSIONS_ANTICIPATED,
              plan.anticipatedSession?.numberOfSessionsAnticipated.toString(),
            )
            .padding()
            .yesOrNoAnswer(
              Labels.WAIT_TIME_EFFECT,
              plan.waitTimeDetrimentalEffect,
            )
            .padding()
            .radioItemAnswer(
              BooleanOption,
              Labels.OFFER_AN_APPOINTMENT,
              plan.appointmentOffered,
            )
            .padding()
            .radioItemAnswer(
              BooleanOption,
              Labels.APPOINTMENT_OFFER_ACCEPTED,
              plan.offeredAppointmentAccepted,
            )
            .padding()
            .longTextAnswer(
              Labels.REFERRALS_TO_CARE_OUTSIDE,
              plan.referralsToCareOutsideGingerNecessary,
            )
            .padding();
        } else {
          this.pdfBuilder
            .enumSelectionsGroup(
              ApproachType,
              plan.approaches,
              Labels.APPROACH,
              'No approaches selected.',
            )
            .padding()
            .longTextAnswer(
              Labels.NUMBER_OF_SESSIONS_ANTICIPATED,
              plan.anticipatedSession?.numberOfSessionsAnticipated.toString(),
            )
            .padding()
            .yesOrNoAnswer(
              Labels.WAIT_TIME_EFFECT,
              plan.waitTimeDetrimentalEffect,
            )
            .padding()
            .radioItemAnswer(
              BooleanOption,
              Labels.OFFER_AN_APPOINTMENT,
              plan.appointmentOffered,
            )
            .padding()
            .radioItemAnswer(
              BooleanOption,
              Labels.APPOINTMENT_OFFER_ACCEPTED,
              plan.offeredAppointmentAccepted,
            )
            .padding()
            .longTextAnswer(
              Labels.REFERRALS_TO_CARE_OUTSIDE,
              plan.referralsToCareOutsideGingerNecessary,
            )
            .padding()
            .longTextAnswer(
              Labels.NON_STANDARD_USAGE_DESCRIPTION,
              plan.nonStandardUsageDescription,
            );
        }
        return true;
      },
      'No treatment plan',
    );
  }
}
