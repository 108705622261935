import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RetryIcon from '@mui/icons-material/ReplayOutlined';
import { Link, TableCell, TableRow } from '@mui/material';
import { TableRowProps } from '@mui/material/TableRow';
import { IconActionBarItem } from 'app/appointments/IconActionBarItem';
import {
  ClinicalDocumentHookState,
  Document,
  DocumentStatus,
  UploadDocumentInput,
  UploadFileTypeMap,
} from 'app/patients/tabs/document-upload/types';
import { getFileExtension } from 'app/patients/tabs/document-upload/useClinicalDocument';
import { formatFileSize } from 'utils';
import React, { ReactElement, useEffect, useState } from 'react';

import { Action, DocumentGridActionButton } from './DocumentGridActionButton';
import styles from './GridRow.module.scss';
import { LinearProgressWithLabel } from './LinearProgressWithLabel';

type Props = {
  document: Document;
  uploading?: boolean;
  progress?: number;
  onActionItemClick: (action: Action, document: Document) => Promise<void>;
  onNameClick: (document: Document) => Promise<void>;
  onRemove?: (document: Document) => void;
  onRetry?: (document: Document) => void;
};

export function DocumentGridRow(props: Props): ReactElement<TableRowProps> {
  const { document, onNameClick } = props;
  const {
    name,
    status = DocumentStatus.UPLOADED,
    documentType,
    author,
    createdAt,
    fileSize,
    ext,
  } = document;
  let nameComponent;
  let actionComponent: ReactElement | null = null;
  if (status === DocumentStatus.UPLOADED) {
    nameComponent = (
      <Link className={styles.btnLink} onClick={() => onNameClick(document)}>
        {`${name}.${ext}`}
      </Link>
    );
    actionComponent = (
      <DocumentGridActionButton
        onClick={(action) => props.onActionItemClick(action, document)}
      />
    );
  } else if (status === DocumentStatus.FAILED) {
    nameComponent = <span>{`${name}.${ext}`}</span>;
    actionComponent = (
      <>
        <IconActionBarItem
          title="Retry"
          Icon={RetryIcon}
          onClick={() => props.onRetry && props.onRetry(document)}
        />
        <IconActionBarItem
          title="Remove"
          Icon={HighlightOffIcon}
          onClick={() => props.onRemove && props.onRemove(document)}
        />
      </>
    );
  } else {
    nameComponent = (
      <>
        <span>{`${name}.${ext}`}</span>
        <LinearProgressWithLabel value={props.progress ?? 0} />
      </>
    );
  }

  return (
    <TableRow className={styles.root} data-testid={`${name}`} key={name}>
      <TableCell data-testid="ext">
        <span className={[styles.ext, extStyle(ext)].join(' ')}>
          {document.ext}
        </span>
      </TableCell>

      <TableCell
        className={styles.nameCol}
        data-testid="name"
        key={`name-${name}`}
      >
        <div>{nameComponent}</div>
      </TableCell>

      <TableCell data-testid="documentType" key={`documentType-${name}`}>
        {UploadFileTypeMap.get(documentType)}
      </TableCell>

      <TableCell data-testid="author" key={`author-${name}`}>
        {author}
      </TableCell>

      <TableCell data-testid="createdAt" key={`createdAt-${name}`}>
        {createdAt}
      </TableCell>

      <TableCell data-testid="fileSize" key={`fileSize-${name}`}>
        {fileSize}
      </TableCell>

      <TableCell data-testid="actionBar" key={`actionBar-${name}`}>
        {actionComponent}
      </TableCell>
    </TableRow>
  );
}

interface UploadDocumentRowProps {
  author: string;
  file: UploadDocumentInput;
  uploader: ClinicalDocumentHookState['uploadDocuments'];
  onComplete: () => Promise<void>;
  onError: (message: string) => void;
  onRemove?: Props['onRemove'];
  onRetry?: Props['onRetry'];
}

export function UploadDocumentRow({
  file,
  uploader,
  author,
  onComplete,
  onError,
  onRetry,
  onRemove,
}: UploadDocumentRowProps) {
  const [progress, setProgress] = useState<number>();

  useEffect(() => {
    const run = async () => {
      const [resp] = await uploader([
        { ...file, onUploadProgressCallback: (_) => setProgress(_) },
      ]);
      const { success, errorMessage = '' } = resp;
      if (!success) {
        onError(errorMessage);
        return;
      }
      await onComplete();
    };
    void run();
  }, []);

  return (
    <DocumentGridRow
      document={inputFileToDocument(author, file, DocumentStatus.UPLOADING)}
      onActionItemClick={() => Promise.resolve()}
      onNameClick={() => Promise.resolve()}
      onRetry={onRetry}
      onRemove={onRemove}
      progress={progress}
    />
  );
}

export function inputFileToDocument(
  author: string,
  input: UploadDocumentInput,
  status = DocumentStatus.PENDING,
): Document {
  return {
    author,
    createdAt: '-',
    documentType: input.documentType,
    ext: getFileExtension(input.file.name),
    fileId: input.file.name,
    fileSize: formatFileSize(input.file.size),
    itemId: input.file.name,
    name: input.name,
    status,
  };
}

function extStyle(ext: string) {
  switch (ext.toLowerCase()) {
    case 'png':
    case 'jpeg':
    case 'jpg':
      return styles.images;
    case 'doc':
    case 'docx':
    case 'pdf':
      return styles.docs;
    default:
      return '';
  }
}
