import { Fields } from '@ginger.io/react-use-form/dist/types';
import { Amendment } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/Amendment';
import { TextAreaField } from 'app/notes-ui/forms/fields';
import React from 'react';

type Props = {
  disabled?: boolean;
  name: string;
  label: string;
  fields: Fields<Amendment>;
};

export function AmendmentInput(props: Props) {
  const { name, label, fields, disabled } = props;

  return (
    <TextAreaField
      rows={1}
      disabled={disabled}
      testId={name}
      label={label}
      field={fields.text}
    />
  );
}
