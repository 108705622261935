import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import React from 'react';

import styles from './Checkbox.module.scss';

export enum CheckboxVariant {
  PINE,
  STEEL_BLUE,
  TUMERIC,
  DARK_GREY,
}

export default function (props: CheckboxProps & { variant?: CheckboxVariant }) {
  const { variant, size = 'small' } = props;

  const classes = [] as string[];
  if (variant === CheckboxVariant.PINE) {
    classes.push(styles.pine);
  }
  if (variant === CheckboxVariant.STEEL_BLUE) {
    classes.push(styles.steelBlue);
  }
  if (variant === CheckboxVariant.TUMERIC) {
    classes.push(styles.tumeric);
  }
  if (variant === CheckboxVariant.DARK_GREY) {
    classes.push(styles.darkGrey);
  }

  return (
    <Checkbox
      {...props}
      size={size}
      color="primary"
      classes={{
        checked: [styles.checked, [...classes]].join(' '),
        colorPrimary: [styles.primary, [...classes]].join(' '),
        disabled: [styles.checkbox, styles.disabled, [...classes]].join(' '),
        root: [styles.checkbox, styles.unchecked, [...classes]].join(' '),
      }}
    />
  );
}
