import { TherapyProgressSectionName } from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';

import { VaultIds } from './VaultIds';

/** Vault IDs for Clinical Note objects */
export class TherapyProgressIds extends VaultIds {
  protected static prefix = 'appointment';

  protected static noteName = 'therapyProgressNote';

  protected static delimiter = '-';

  static section(
    appointmentId: string,
    sectionName: TherapyProgressSectionName,
  ): string {
    return VaultIds.id(this.prefix, appointmentId, this.noteName, sectionName);
  }
}
