import { setInboxPollingID } from '../actions';
import { ActionHandler } from './types';

export const onStopInboxPolling = ({ redux }: ActionHandler<any>) => {
  const { pollingID } = redux.getState().inbox;
  if (pollingID) {
    clearInterval(pollingID);
    redux.dispatch(setInboxPollingID(null));
  }
};
