import {
  booleanField,
  field,
  FieldDefinitions,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  TreatmentPlan,
  TreatmentPlan_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/TreatmentPlan';
import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import { TreatmentGoal } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/TreatmentGoal';
import {
  PsychiatrySectionName,
  TreatmentPlanSection,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { Width } from 'types/StyleTypes';
import { TextAreaField, YesOrNoField } from 'app/notes-ui/forms/fields';
import { BooleanRadioField } from 'app/notes-ui/forms/form-controls/BooleanRadioField';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import {
  GoalContainer,
  validate as validateGoals,
} from 'app/notes-ui/shared/treatment-plan/GoalContainer';
import { TreatmentGoalFieldDefs } from 'app/notes-ui/shared/treatment-plan/schema';
import Labels from 'app/notes-ui/strings/en.json';
import { Labels as TreatmentLabels } from 'app/notes-ui/therapy/progress/treatment-plan/constants';
import { SectionDivider } from 'app/notes-ui/therapy/shared/SectionDivider';
import {
  getDefaultEnumValue,
  NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
  showComplianceFields,
} from 'app/notes-ui/utils';
import { TherapistRole } from 'generated/globalTypes';
import React, { useState } from 'react';

type Props = {
  appointmentId: string;
  onSubmit: (data: TreatmentPlanSection) => void;
  updateDraftNoteState: (data: TreatmentPlanSection) => void;
  disabled?: boolean;
  savingNote?: boolean;
  initialValue?: TreatmentPlan;
  noteLastUpdatedAt?: string;
};

export const fieldDefs: FieldDefinitions<TreatmentPlan> = {
  appointmentId: field(),
  appointmentOffered: field<BooleanOption>({
    default: BooleanOption.not_applicable,
    rules: [],
  }),
  followUp: stringField(),
  approachesUpdateComments: stringField({ rules: [] }),
  goal: field<TreatmentGoal[]>({
    default: [TreatmentGoal.fromPartial({})],
    rules: [
      (value) => {
        const isValid =
          value !== undefined &&
          value.every((_) => validateForm(_, TreatmentGoalFieldDefs));
        if (!isValid) {
          return 'This field contains invalid inputs';
        }
        return undefined;
      },
    ],
  }),
  medicationManagement: stringField(),
  memberOutOfScope: booleanField({ rules: [] }),
  memberOutOfScopeReason: stringField({ rules: [] }),
  messageToCareTeam: stringField(),
  numberOfSessionsAnticipated: field({ rules: [] }),
  offeredAppointmentAccepted: field<BooleanOption>({
    default: BooleanOption.not_applicable,
    rules: [],
  }),
  referralsToCareOutsideGingerNecessary: stringField({ rules: [] }),
  version: field<TreatmentPlan_Version>({ default: TreatmentPlan_Version.v0 }),
  waitTimeDetrimentalEffect: field(),
};

export function TreatmentPlanFormV2(props: Props) {
  const { disabled, appointmentId, initialValue, noteLastUpdatedAt } = props;
  const [showNoGoalError, setShowNoGoalError] = useState(false);
  const { fields, getValue } = useForm<TreatmentPlan>(
    fieldDefs,
    {
      ...initialValue,
      appointmentId,
      version: TreatmentPlan_Version.v0,
    } as TreatmentPlan,
    {
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
      onStateChange: async () => {
        await onSubmit();
      },
    },
  );

  const onSubmit = async () => {
    if (disabled) return;

    const goalsValid = validateGoals(fields.goal.value);
    if (!goalsValid) {
      setShowNoGoalError(true);
    } else {
      setShowNoGoalError(false);
    }

    props.onSubmit({
      data: getValue(),
      name: PsychiatrySectionName.TREATMENT_PLAN,
    });
  };

  return (
    <>
      <GoalContainer
        label={TreatmentLabels.goals.label}
        disabled={disabled}
        goals={fields.goal}
        clinicianType={TherapistRole.PSYCH}
      />

      <TextAreaField
        disabled={disabled}
        testId={TreatmentLabels.messageToCareTeam.id}
        label={TreatmentLabels.messageToCareTeam.label}
        field={fields.messageToCareTeam}
        labelDescription={TreatmentLabels.messageToCareTeam.description}
        placeholder={TreatmentLabels.messageToCareTeam.placeholder}
        width={Width.FULL}
        rows={1}
        autoExpand={true}
      />

      <SectionDivider label="Plan" isNew={false} />

      <TextAreaField
        width={Width.FULL}
        label="Medication management"
        disabled={disabled}
        testId="medicationManagement"
        field={fields.medicationManagement}
        placeholder="Type here..."
        rows={1}
        autoExpand={true}
      />

      <TextAreaField
        width={Width.FULL}
        label="Follow-up scheduling"
        disabled={disabled}
        testId="followUp"
        field={fields.followUp}
        placeholder="Type here..."
        rows={1}
        autoExpand={true}
      />

      {showComplianceFields(noteLastUpdatedAt) && (
        <div>
          <div className={styles.formGroupV2}>
            <YesOrNoField
              disabled={disabled}
              testId="waitTimeEffect"
              label={Labels.WAIT_TIME_EFFECT_PSYCHIATRY}
              field={fields.waitTimeDetrimentalEffect}
              fullWidth={true}
            />
          </div>

          <div className={styles.formGroupV2}>
            <BooleanRadioField
              disabled={disabled}
              name="appointmentOffered"
              label={Labels.OFFER_AN_APPOINTMENT_PSYCHIATRY}
              value={getDefaultEnumValue(fields.appointmentOffered)}
              onChange={fields.appointmentOffered.setValue}
            />
          </div>
          <div className={styles.formGroupV2}>
            <BooleanRadioField
              disabled={disabled}
              name="offeredAppointmentAccepted"
              label={Labels.APPOINTMENT_OFFER_ACCEPTED}
              value={getDefaultEnumValue(fields.offeredAppointmentAccepted)}
              onChange={fields.offeredAppointmentAccepted.setValue}
            />
          </div>

          <TextAreaField
            disabled={disabled}
            testId="referralsToCareOutsideGingerNecessary"
            label={Labels.REFERRALS_TO_CARE_OUTSIDE}
            tooltip={Labels.REFERRALS_TO_CARE_OUTSIDE_TOOLTIP}
            optional={true}
            field={fields.referralsToCareOutsideGingerNecessary}
            width={Width.FULL}
            placeholder="Type here..."
            rows={1}
            autoExpand={true}
          />
        </div>
      )}

      <Collapse
        className={styles.formGroupV2}
        addEndListener={() => null}
        in={showNoGoalError}
      >
        <Alert severity="error" onClose={() => setShowNoGoalError(false)}>
          You haven&apos;t filled out a goal.
        </Alert>
      </Collapse>
    </>
  );
}

export const validate = (data: TreatmentPlan | null) =>
  data !== null && validateForm(data, fieldDefs);
