import { SwitchUnstyled } from '@mui/base';
import React from 'react';

import styles from './ToggleButton.module.scss';

interface ToggleProps {
  isOn: boolean;
  onChange: () => void;
}

export const ToggleButton = ({ onChange, isOn }: ToggleProps) => {
  return (
    <SwitchUnstyled
      slotProps={{
        input: { className: styles.input },
        root: {
          className: isOn
            ? `${styles.switch} ${styles.switchChecked}`
            : styles.switch,
        },
        thumb: {
          className: isOn
            ? `${styles.thumb} ${styles.thumbChecked}`
            : styles.thumb,
        },
        track: { className: styles.track },
      }}
      checked={isOn}
      onChange={onChange}
    />
  );
};
