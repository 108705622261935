import {
  MemberBackgroundSection,
  MemberBackgroundSection_SectionType as SectionType,
} from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/MemberBackgroundSection';
import { StateSlice } from 'app/state/status/types/StateSlice';

export const PLACEHOLDERS = {
  ADDITIONAL_BACKGROUND_INFO: 'Life story, mental health history...',
  SELF_CARE_STRATEGIES: 'Hiking, baths...',
  SUPPORT_SYSTEM: 'Brother, best friend...',
  VALUES: 'Honesty, communication...',
};

export enum CareTeamNotesSectionFields {
  SELF_CARE_STRATEGIES = 'Self-care Strategies',
  VALUES = 'Values',
  SUPPORT_SYSTEM = 'Support System',
  ADDITIONAL_BACKGROUND_INFO = 'Additional Background Info',
}

export interface MemberBackgroundSectionFieldItem
  extends MemberBackgroundSection {
  id: string;
  sourceVersion: string;
  updatedAt: ISODateString;
  updatedBy: string;
}

export interface MutationResponse {
  success: boolean;
  data: MemberBackgroundSectionFieldItem | null;
  errorMessage?: string;
}

type InvalidSectionType =
  | SectionType.UNRECOGNIZED
  | SectionType.undefined_section_type;

export type ValidSectionType = Exclude<SectionType, InvalidSectionType>;

interface FieldValueAndTooltipText {
  id?: string;
  value: string;
  sourceVersion?: string;
  tooltipText: string;
}

export type MemberBackgroundSectionValues = Record<
  ValidSectionType,
  FieldValueAndTooltipText | undefined
>;

export interface VersionedMemberBackgroundSection
  extends MemberBackgroundSection {
  sourceVersion?: string;
  source: string;
}

export interface MemberBackgroundSectionHookState {
  memberBackground: StateSlice<MemberBackgroundSectionValues>;
  saveMemberBackgroundSection: (
    input: VersionedMemberBackgroundSection,
  ) => Promise<MutationResponse>;
  createMemberBackgroundSection: (
    input: VersionedMemberBackgroundSection,
  ) => Promise<MutationResponse>;
  updateMemberBackgroundSection: (
    input: VersionedMemberBackgroundSection,
  ) => Promise<MutationResponse>;
}
