import { Safety } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyProgress';
import { Width } from 'types/StyleTypes';
import { TextAreaField, YesOrNoField } from 'app/notes-ui/forms/fields';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { NoteFormControlLabelStyles } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { YesOrNoQuestion } from 'app/notes-ui/forms/form-controls/YesOrNoQuestion';
import { DeleteRiskDialog } from 'app/notes-ui/shared/DeleteRiskDialog';
import { clearHomicideRiskFields } from 'app/notes-ui/shared/safety';
import {
  Labels,
  SafetyConcerns,
} from 'app/notes-ui/shared/safety-progress/constants';
import { SafetyFormProps } from 'app/notes-ui/shared/safety-progress/types';
import { SectionDivider } from 'app/notes-ui/therapy/shared/SectionDivider';
import { classNameCombiner } from 'utils';
import React, { useEffect } from 'react';

const formControlStyles: NoteFormControlLabelStyles = {
  checkbox: styles.checkbox,
  label: styles.label,
  root: styles.root,
  subtext: styles.subtext,
};

const RISK = SafetyConcerns.HOMICIDE_RISK;

export const InitialStateChecker = (note: Safety) => {
  return (
    note?.currentHomicidalIdeation?.thoughts ||
    note?.currentHomicidalIdeation?.intent ||
    note?.currentHomicidalIdeation?.plan ||
    note?.currentHomicidalIdeation?.means ||
    note?.currentHomicidalIdeation?.hasPast
  );
};

export const HomicideRisk = (props: SafetyFormProps) => {
  const { disabled, fields, onDelete } = props;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);

  const [endorsesAll, setEndorsesAll] = React.useState<boolean | undefined>(
    undefined,
  );
  const endorsesAllHandler = (value: boolean) => {
    setEndorsesAll(value);
    fields.currentHomicidalIdeation.thoughts.setValue(value);
    fields.currentHomicidalIdeation.intent.setValue(value);
    fields.currentHomicidalIdeation.plan.setValue(value);
    fields.currentHomicidalIdeation.means.setValue(value);
    fields.currentHomicidalIdeation.specificTargetsEntityOrLocationConsidered.setValue(
      value,
    );
  };

  useEffect(() => {
    if (
      fields.currentHomicidalIdeation.thoughts.value !== endorsesAll ||
      fields.currentHomicidalIdeation.intent.value !== endorsesAll ||
      fields.currentHomicidalIdeation.plan.value !== endorsesAll ||
      fields.currentHomicidalIdeation.means.value !== endorsesAll ||
      fields.currentHomicidalIdeation.specificTargetsEntityOrLocationConsidered
        .value !== endorsesAll
    ) {
      setEndorsesAll(undefined);
    }
  }, [fields.currentSuicidalIdeation]);

  const deleteRisk = () => {
    clearHomicideRiskFields(fields);
    onDelete(RISK);
    setIsDeleteDialogOpen(false);
  };

  return (
    <>
      {isDeleteDialogOpen && (
        <DeleteRiskDialog
          deletingRisk={RISK}
          onClose={() => {
            setIsDeleteDialogOpen(false);
          }}
          onConfirm={deleteRisk}
        />
      )}
      <SectionDivider
        label={RISK}
        isCritical={true}
        isRemovable={true}
        onDelete={() => setIsDeleteDialogOpen(true)}
      />
      <YesOrNoQuestion
        disabled={disabled}
        name={Labels.currentHomicidalIdeation.current.id}
        label={Labels.currentHomicidalIdeation.current.label}
        labelSize="M"
        value={endorsesAll}
        yesLabel="Endorses all"
        noLabel="Denies all"
        onChange={endorsesAllHandler}
      />
      <YesOrNoField
        disabled={disabled}
        testId={Labels.currentHomicidalIdeation.thoughts.id}
        label={Labels.currentHomicidalIdeation.thoughts.label}
        field={fields.currentHomicidalIdeation?.thoughts}
        yesLabel="Endorsed"
        noLabel="Denied"
      />
      <YesOrNoField
        disabled={disabled}
        testId={Labels.currentHomicidalIdeation.intent.id}
        label={Labels.currentHomicidalIdeation.intent.label}
        field={fields.currentHomicidalIdeation?.intent}
        yesLabel="Endorsed"
        noLabel="Denied"
      />
      <YesOrNoField
        disabled={disabled}
        testId={Labels.currentHomicidalIdeation.plan.id}
        label={Labels.currentHomicidalIdeation.plan.label}
        field={fields.currentHomicidalIdeation?.plan}
        yesLabel="Endorsed"
        noLabel="Denied"
      />
      <YesOrNoField
        disabled={disabled}
        testId={Labels.currentHomicidalIdeation.means.id}
        label={Labels.currentHomicidalIdeation.means.label}
        field={fields.currentHomicidalIdeation?.means}
        yesLabel="Endorsed"
        noLabel="Denied"
      />
      <YesOrNoField
        disabled={disabled}
        testId={
          Labels.currentHomicidalIdeation
            .specificTargetsEntityOrLocationConsidered.id
        }
        label={
          Labels.currentHomicidalIdeation
            .specificTargetsEntityOrLocationConsidered.label
        }
        field={
          fields.currentHomicidalIdeation
            ?.specificTargetsEntityOrLocationConsidered
        }
        yesLabel="Endorsed"
        noLabel="Denied"
      />

      <div className={classNameCombiner([styles.textAreaField])}>
        <TextAreaField
          testId={Labels.currentHomicidalIdeation.description.id}
          disabled={disabled}
          label={Labels.currentHomicidalIdeation.description.label}
          placeholder={Labels.currentHomicidalIdeation.description.placeholder}
          field={fields.currentHomicidalIdeation?.description}
          width={Width.FULL}
          className={styles.textAreaField}
          formControlStyles={formControlStyles}
          autoExpand={true}
          rows={1}
        />
      </div>

      <div className={styles.marginBooleanField}>
        <YesOrNoField
          disabled={disabled}
          testId={Labels.currentHomicidalIdeation.hasPast.id}
          label={Labels.currentHomicidalIdeation.hasPast.label}
          labelSize="M"
          field={fields.currentHomicidalIdeation?.hasPast}
          yesLabel="Endorsed"
          noLabel="Denied"
        />
      </div>

      {fields.currentHomicidalIdeation?.hasPast && (
        <TextAreaField
          testId={Labels.currentHomicidalIdeation.pastSummarization.id}
          disabled={disabled}
          label={Labels.currentHomicidalIdeation.pastSummarization.label}
          placeholder={
            Labels.currentHomicidalIdeation.pastSummarization.placeholder
          }
          field={fields.currentHomicidalIdeation?.pastSummarization}
          width={Width.FULL}
          className={styles.textAreaField}
          formControlStyles={formControlStyles}
          autoExpand={true}
          rows={1}
        />
      )}
    </>
  );
};
