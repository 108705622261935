import gql from 'graphql-tag';

export const getMemberUpcomingCoachingSessionsQuery = gql`
  query GetMemberUpcomingCoachingSessions(
    $input: GetMemberUpcomingCoachingSessionsInput!
  ) {
    getMemberUpcomingCoachingSessions(input: $input) {
      coachingSessions {
        recurrence {
          everyNWeeks
          numOccurrences
        }
        sessions {
          id
          member {
            id
            firstName
            preferredFirstName
            preferredLastName
          }
          coach {
            id
            name
            shift
            timezone
          }
          startTime
          endTime
          eventType
          sessionFormat
          zoomMeetingId
          zoomMeetingUrl
          note
        }
      }
      error
    }
  }
`;

export const createMemberCoachingSessionMutation = gql`
  mutation CreateMemberCoachingSession(
    $input: CreateMemberCoachingSessionInput!
  ) {
    createMemberCoachingSession(input: $input) {
      ok
      error
    }
  }
`;

export const updateMemberCoachingSessionMutation = gql`
  mutation UpdateMemberCoachingSession(
    $input: UpdateMemberCoachingSessionInput!
  ) {
    updateMemberCoachingSession(input: $input) {
      ok
      error
    }
  }
`;

export const cancelMemberCoachingSessionMutation = gql`
  mutation CancelMemberCoachingSession(
    $input: CancelMemberCoachingSessionInput!
  ) {
    cancelMemberCoachingSession(input: $input) {
      ok
      error
    }
  }
`;

export const getAvailabilitiesForMe = gql`
  query GetAvailabilitiesForMe($input: GetAvailableTimeSlotsForMeInput!) {
    getAvailabilitiesForMe(input: $input) {
      timeSlotSeries {
        start
        timeSlots {
          start
          end
          load
        }
      }
      error
    }
  }
`;

export const getMemberSessionUsage = gql`
  query getMemberSessionUsage($input: GetMemberSessionUsageInput!) {
    getMemberSessionUsage(input: $input) {
      sessionUsage {
        usedChatSessions
        usedVideoSessions
        nextEligibleVideoSessionDate
      }
      error
    }
  }
`;
