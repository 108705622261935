import { Field } from '@ginger.io/react-use-form';
import { CardTextArea } from 'app/member-chart-cards/text-area/CardTextArea';
import React from 'react';

export const CardTextAreaField = ({
  readOnly,
  multiline = true,
  tooltipEventLabel,
  tooltipText,
  field,
  placeholder,
  name,
}: {
  field: Field<string>;
  placeholder: string;
  name?: string;
  tooltipEventLabel: string;
  readOnly: boolean;
  tooltipText?: string;
  multiline?: boolean;
}): React.ReactElement | null => {
  const onChangeTextarea = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.setValue(e.target.value);
  };

  return (
    <CardTextArea
      name={name}
      multiline={multiline}
      minRows={1}
      tooltipEventLabel={tooltipEventLabel}
      tooltipText={tooltipText}
      value={field.value}
      placeholder={readOnly ? '—' : placeholder}
      onChange={onChangeTextarea}
      readOnly={readOnly}
    />
  );
};
