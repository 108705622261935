import { usePubNubAPI } from 'app/coach/pubnub/PubNubContextProvider';
import { MessageToDisplay } from 'app/coach/pubnub/types';
import { useAppState } from 'app/state';
import { setMessages } from 'app/state/inbox/actions';
import { displayableMessagesToString } from 'app/state/inbox/utils';
import { useLogger } from 'app/state/log/useLogger';
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'redux-reloaded';

import { HISTORY_STATE } from '../types';
import { getMinNumberOfMessages } from '../utils';
import { LoadMoreSection } from './LoadMoreSection';

interface LoadMoreSectionContainerProps {
  messages: MessageToDisplay[];
  currentChannelId: string;
  setHistoryState: (updatedState: HISTORY_STATE) => void;
  historyState: HISTORY_STATE;
}

export const LoadMoreSectionContainer = ({
  messages,
  currentChannelId,
  historyState,
  setHistoryState,
}: LoadMoreSectionContainerProps) => {
  const logger = useLogger();
  const api = usePubNubAPI();
  const { getHistory } = api;
  const listenerId = useAppState((_) => _.user.listenerId);
  const { memberId } = useParams<{ memberId: string }>();

  const earliestMessageTimetoken = useRef<string | undefined>(undefined);
  const dispatch = useDispatch();

  const onGetMoreMessages = async () => {
    try {
      const updatedMessages = await getMinNumberOfMessages({
        channelId: currentChannelId,
        getHistory,
        initialMessages: messages,
        logger,
      });
      if (updatedMessages.length === messages.length) {
        setHistoryState(HISTORY_STATE.NO_MORE_MESSAGES);
      } else {
        logger.info(
          'onGetMoreMessages: Loading more message for conversation',
          {
            channelId: currentChannelId,
            memberId,
            messages: displayableMessagesToString(updatedMessages),
          },
        );
        dispatch(
          setMessages({
            channelId: currentChannelId,
            messages: updatedMessages,
          }),
        );
      }
    } catch (e) {
      setHistoryState(HISTORY_STATE.ERROR);
      logger.error(
        new Error(`Got an error in the onGetMoreMessages`, { cause: e }),
        { error: e, listenerId, memberId },
      );
    }
  };

  useEffect(() => {
    if (currentChannelId) {
      earliestMessageTimetoken.current = messages[0]?.timetoken.toString();
      setHistoryState(HISTORY_STATE.STILL_HAS_SOME_MESSAGES);
    }
  }, [currentChannelId, messages]);

  return (
    <div data-testid="loadMoreSection">
      <LoadMoreSection
        historyState={historyState}
        onGetMoreMessages={onGetMoreMessages}
      />
    </div>
  );
};
