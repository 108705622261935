import { Field } from '@ginger.io/react-use-form';
import { FormError } from 'shared-components/FormError';
import React from 'react';

import {
  AutoCompleteSearch,
  AutoCompleteSearchProps,
} from './AutoCompleteSearch';

export type AutoCompleteSearchField = {
  idField: Field<string>;
  groupField?: Field<string>;
  error?: string;
  searchFieldProps: Omit<AutoCompleteSearchProps, 'resultCallback'>;
};

export function AutoCompleteSearchField(props: AutoCompleteSearchField) {
  const {
    idField,
    groupField,
    searchFieldProps: { searchCallback, results, placeholder, testId, loading },
    error,
  } = props;

  const formError = idField.error ? idField.error : error;

  return (
    <>
      <AutoCompleteSearch
        resultCallback={(result) => {
          idField.setValue(result?.id ?? '');
          const group = result?.groupBy;
          if (groupField) {
            groupField.setValue(group ?? '');
          }
        }}
        searchCallback={searchCallback}
        results={results}
        placeholder={placeholder}
        testId={testId}
        loading={loading}
      />
      <FormError error={formError} />
    </>
  );
}
