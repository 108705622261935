import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';

import { ReactComponent as PartialCheckCircleIcon } from './dotted-circle.svg';
import styles from './Tabs.module.scss';

export enum TabState {
  COMPLETED,
  PARTIAL,
  NOT_STARTED,
}

export type Props = {
  label: string;
  active: boolean;
  tabState: TabState;
};

export function Tab(props: Props) {
  const { label, active, tabState } = props;
  const completed = tabState === TabState.COMPLETED;
  const className = [
    styles.tab,
    active ? styles.active : '',
    completed ? styles.completed : '',
  ].join(' ');
  const icon =
    tabState === TabState.PARTIAL ? (
      <PartialCheckCircleIcon data-testid="partialIcon" />
    ) : (
      <RadioButtonUncheckedIcon data-testid="notStartedIcon" />
    );
  return (
    <div className={className}>
      <Checkbox
        data-testid="checkbox"
        disableRipple={true}
        checked={completed}
        name={`${label}`}
        checkedIcon={<CheckCircleIcon data-testid="completedIcon" />}
        icon={icon}
      />
      <span data-testid="title">{label}</span>
    </div>
  );
}
