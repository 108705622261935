import { ApolloClient } from '@apollo/client';
import {
  GetAppointmentById,
  GetAppointmentById_getAppointmentById,
  GetAppointmentByIdVariables,
} from '@headspace/carehub-graphql/dist/vault/generated/GetAppointmentById';
import { NoteHeader } from 'app/notes-ui/pdf/types';
import { getAppointmentById } from 'app/vault/queries';
import moment from 'moment-timezone';
import { formatDate } from 'utils/dateTime';

export async function getAppointmentMetadata(
  apolloClient: ApolloClient<object>,
  appointmentId: string,
): Promise<GetAppointmentById_getAppointmentById | null> {
  const { data } = await apolloClient.query<
    GetAppointmentById,
    GetAppointmentByIdVariables
  >({
    query: getAppointmentById,
    variables: { id: appointmentId },
  });

  const appointment = data?.getAppointmentById;

  if (!appointment) {
    return null;
  }

  return appointment;
}

export function toNoteHeader(
  appointmentMetadata: GetAppointmentById_getAppointmentById,
  timezone = 'America/Los_Angeles',
): NoteHeader {
  const {
    firstName,
    lastName,
    gender,
    dateOfBirth,
  } = appointmentMetadata.member;
  return {
    clinician: { name: appointmentMetadata.clinician.name },
    dateTime: formatAppointmentTime(appointmentMetadata.start, timezone),
    patient: {
      dateOfBirth: formatDate(dateOfBirth),
      firstName,
      gender,
      lastName,
    },
  };
}

function formatAppointmentTime(dateTime: string, timezone: string) {
  const date = moment(new Date(dateTime)).tz(timezone);
  return date.format('M/D/YYYY h:mma z');
}
