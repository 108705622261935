import {
  field,
  FieldDefinitions,
  stringField,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  Assessment,
  Assessment_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/Assessment';
import { buildCodeSetFieldDefs } from 'app/notes-ui/shared/code-set/CodeSetFields';

export const fieldDefs: FieldDefinitions<Assessment> = {
  appointmentId: stringField(),
  version: field<Assessment_Version>({
    default: Assessment_Version.undefined_version,
  }),
  ...buildCodeSetFieldDefs(),
};

export const validate = (data: Assessment | null) =>
  data !== null && validateForm(data, fieldDefs);
