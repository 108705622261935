import { RPCEnvelope, ServerRPC } from 'app/coach/pubnub/types';
import { InboxSections } from 'app/inbox/types';

import { refreshCoachTodaysMemberList } from '../actions';
import { pubnubTimeTokenToMoment } from '../utils';
import { ActionHandler } from './types';

export const onNewMessageRPC = async ({
  action,
  redux,
  context,
}: ActionHandler<{ envelope: RPCEnvelope }>) => {
  const { logger } = context.services;
  // new_message rpc contains an array of member_ids for which we need to refresh the ConversatiionItem data
  // and maybe re-fetch the messages history
  const { envelope } = action.payload;
  const {
    message: { member_ids: memberIds },
  } = envelope;
  const filteredMemberIds: string[] = memberIds
    .filter(Boolean)
    .map((id: string | number) => id.toString());

  // we've seen cases where we received this RPC with null memberId, what this means is that the coach won't access to
  // the member that triggered this RPC. We want to log this without breaking the UI
  if (filteredMemberIds.length < memberIds.length) {
    logger.error(
      new Error('Received a new_message RPC containing missing member id'),
      {
        envelope,
      },
    );
  }

  const {
    inbox,
    user,
    conversationsTimetokens: { timetokensMap },
  } = redux.getState();
  const { tabSections, unreadMessagesMap, inboxItems } = inbox;
  // We have a task in listener that sends a `new_message` RPC for 6 minutes @ 1 minute interval when a coach has unread
  // messages. So we need to prevent unnecessary calls to refresh inbox list.
  // We should only require a refresh if the member (with unread message) isn't in the conversations & tasks list or we
  // haven't re-fetch the message history and calculated the unread message count.
  const refreshInbox = filteredMemberIds.some((id) => {
    const channelId = inboxItems[id]?.memberCoachChannelId;
    if (!channelId) return !channelId;
    const lastMemberWriteTimeToken = pubnubTimeTokenToMoment(
      timetokensMap[channelId]?.lastMemberWriteTimeToken,
    );
    const lastListenerReadTimeToken = pubnubTimeTokenToMoment(
      timetokensMap[channelId]?.lastListenerReadTimeToken,
    );
    const hasUnreadMessage =
      unreadMessagesMap[channelId] &&
      unreadMessagesMap[channelId!]?.length > 0 &&
      lastMemberWriteTimeToken.isAfter(lastListenerReadTimeToken);
    return (
      (!channelId && !tabSections.CONVERSATIONS_AND_TASKS.ids.has(`${id}`)) ||
      !hasUnreadMessage
    );
  });
  const additionalData = {
    listenerId: user.listenerId,
    memberIds: filteredMemberIds,
    userId: user.userId,
  };

  if (refreshInbox) {
    logger.info(
      "new_message RPC received: refreshing today's inbox",
      additionalData,
    );
    return redux.dispatch(
      refreshCoachTodaysMemberList({
        RPC: ServerRPC.NEW_MESSAGE,
        loadMessageHistory: true,
        memberIds: filteredMemberIds,
        sections: [InboxSections.CONVERSATIONS_AND_TASKS],
      }),
    );
  }
  logger.info('new_message RPC received: ignoring inbox refresh', {
    ...additionalData,
    convos: Array.from(tabSections.CONVERSATIONS_AND_TASKS.ids),
    unreadMessages: filteredMemberIds.map((id) => ({
      count: unreadMessagesMap[inboxItems[id]?.memberCoachChannelId!].length,
      id,
    })),
  });
};
