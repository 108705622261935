import {
  CardColumnGaps,
  CardGrid,
  CardRowGaps,
  NumberOfCardColumns,
} from 'shared-components/grid-layout/CardGrid.module';
import { GridCell } from 'shared-components/grid-layout/GridCell';
import {
  CardField,
  CardFieldAndGridCell,
} from 'app/member-chart-cards/card-field/CardField';
import React from 'react';

export const CardSection = ({
  className,
  fields,
  hideTooltip,
  placeholder,
}: {
  className?: string;
  fields: CardFieldAndGridCell[];
  hideTooltip?: boolean;
  placeholder?: string;
}): React.ReactElement => {
  return (
    <div className={className}>
      <CardGrid
        rowGap={CardRowGaps.STANDARD}
        columnGap={CardColumnGaps.STANDARD}
        numberOfColumns={NumberOfCardColumns.ONE}
      >
        {fields.map((field, index) => (
          <GridCell key={`${field.label}${index}`}>
            <CardField
              field={field}
              placeholder={placeholder}
              hideTooltip={hideTooltip}
            />
          </GridCell>
        ))}
      </CardGrid>
    </div>
  );
};
