import { useForm } from '@ginger.io/react-use-form';
import {
  Assessment,
  Assessment_CPTCode as CPTCode,
  Assessment_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/intake/Assessment';
import {
  IntakeAssessmentSection as AssessmentSection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { Width } from 'types/StyleTypes';
import { TextAreaField } from 'app/notes-ui/forms/fields';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { NoteFormControlLabelStyles } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { CodeSetFields } from 'app/notes-ui/shared/code-set/CodeSetFields';
import {
  codeSetsFromAssessment,
  NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
} from 'app/notes-ui/utils';
import { acceptedPsychiatryCptCodes, filterCptCodes } from 'utils/notes';
import React from 'react';

import { fieldDefs } from './schema';

type Props = {
  appointmentId: string;
  onSubmit: (data: AssessmentSection) => void;
  updateDraftNoteState: (data: AssessmentSection) => void;
  disabled?: boolean;
  savingNote?: boolean;
  initialValue?: Assessment;
};

const formControlStyles: NoteFormControlLabelStyles = {
  label: styles.label,
  subtext: styles.subtext,
};

export function AssessmentPlanFormV2(props: Props) {
  const { disabled, initialValue, appointmentId } = props;
  const { fields, getValue } = useForm<Assessment>(
    fieldDefs,
    {
      ...initialValue,
      appointmentId,
      codeSets: codeSetsFromAssessment(initialValue),
      version: Assessment_Version.v0,
    } as Assessment,
    {
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
      onStateChange: async () => {
        await onSubmit();
      },
    },
  );

  const onSubmit = async () => {
    if (disabled) return;

    props.onSubmit({
      data: getValue(),
      name: PsychiatrySectionName.ASSESSMENT,
    });
  };
  return (
    <>
      <CodeSetFields
        disabled={disabled}
        cptCodeEnum={filterCptCodes(CPTCode, acceptedPsychiatryCptCodes)}
        field={fields.codeSets}
      />

      <TextAreaField
        disabled={disabled}
        testId="formulationAndMedicalDecisionMaking"
        label="Formulation and Medical Decision Making"
        field={fields.formulationAndMedicalDecisionMaking}
        width={Width.FULL}
        formControlStyles={formControlStyles}
        placeholder="Type here..."
        rows={1}
        autoExpand={true}
      />
    </>
  );
}
