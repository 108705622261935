import {
  TherapyProgressSection,
  TherapyProgressSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';
import {
  ClinicalNote,
  ClinicalNotesScreen,
} from 'app/notes-ui/ClinicalNotesScreen';
import { CollaborationPlanForm } from 'app/notes-ui/shared/collaboration-plan/CollaborationPlanForm';
import { validate as validateCollaboration } from 'app/notes-ui/shared/collaboration-plan/schema';
import { SafetyForm } from 'app/notes-ui/shared/safety-progress/SafetyForm';
import { validate as validateSafety } from 'app/notes-ui/shared/safety-progress/schema';
import { validate as validateSubstanceUsed } from 'app/notes-ui/shared/substance-abuse/schema';
import { SubstanceAbuseForm } from 'app/notes-ui/shared/substance-abuse/SubstanceAbuseForm';
import { validate as validateAssessment } from 'app/notes-ui/therapy/progress/assessment/schema';
import { validate as validateClinicianChecklist } from 'app/notes-ui/therapy/progress/clinician-checklist/schema';
import { validate as validateFocus } from 'app/notes-ui/therapy/progress/focus-area/schema';
import { validate as validateTreatmentPlan } from 'app/notes-ui/therapy/progress/treatment-plan/schema';
import { BehavioralObservationsForm } from 'app/notes-ui/therapy/shared/behavioral-observations/BehavioralObservationsForm';
import { validate as validateBehavioralObservation } from 'app/notes-ui/therapy/shared/behavioral-observations/schema';
import { getTherapyProgressSectionLabel } from 'app/notes-ui/utils';
import { Status } from 'app/state/status/types/StateSlice';
import { TherapyProgressIds } from 'app/vault/api/TherapyProgressIds';
import { TherapyProgressNote } from 'app/vault/api/TherapyProgressNotesAPI';
import React, { ElementType } from 'react';

import { AssessmentForm } from './assessment/AssessmentForm';
import { ClinicianChecklistForm } from './clinician-checklist/ClinicianChecklistForm';
import { FocusAreaForm } from './focus-area/FocusArea';
import { TreatmentPlanForm } from './treatment-plan/TreatmentPlanForm';

export type Props = {
  appointmentId: string;
  memberId?: string;
  note: TherapyProgressNote;
  draftNote: TherapyProgressNote;
  onSubmit: (section: TherapyProgressSection) => Promise<void>;
  updateDraftNoteState: (section: TherapyProgressSection) => void;
  status: Status;
  readOnly: boolean;
};

type Section<T extends TherapyProgressSection> = [
  T['name'],
  string,
  ElementType,
  (data: any) => boolean,
];

function section<T extends TherapyProgressSection>(
  name: T['name'],
  form: ElementType,
  validator: (data: any) => boolean,
): Section<T> {
  return [name, getTherapyProgressSectionLabel(name), form, validator];
}

const sections = [
  section(
    TherapyProgressSectionName.CLINICIAN_CHECKLIST,
    ClinicianChecklistForm,
    validateClinicianChecklist,
  ),
  section(TherapyProgressSectionName.FOCUS_AREA, FocusAreaForm, validateFocus),
  section(TherapyProgressSectionName.SAFETY, SafetyForm, validateSafety),
  section(
    TherapyProgressSectionName.SUBSTANCE_ABUSE,
    SubstanceAbuseForm,
    validateSubstanceUsed,
  ),
  section(
    TherapyProgressSectionName.BEHAVIORAL_OBSERVATION,
    BehavioralObservationsForm,
    validateBehavioralObservation,
  ),
  section(
    TherapyProgressSectionName.ASSESSMENT,
    AssessmentForm,
    validateAssessment,
  ),
  section(
    TherapyProgressSectionName.TREATMENT_PLAN,
    TreatmentPlanForm,
    validateTreatmentPlan,
  ),
  section(
    TherapyProgressSectionName.COLLABORATION_PLAN,
    CollaborationPlanForm,
    validateCollaboration,
  ),
];

export function TherapyProgressNoteScreen(props: Props) {
  const {
    appointmentId,
    note,
    status,
    memberId,
    onSubmit,
    updateDraftNoteState,
    draftNote,
  } = props;

  return (
    <ClinicalNotesScreen
      appointmentId={appointmentId}
      status={status}
      onSubmit={onSubmit}
      updateDraftNoteState={updateDraftNoteState}
      draftNote={draftNote as ClinicalNote<TherapyProgressSection>}
      memberId={memberId}
      sections={sections}
      ids={TherapyProgressIds}
      note={note as ClinicalNote<TherapyProgressSection>}
      readOnly={props.readOnly}
    />
  );
}
