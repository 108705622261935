import React, { useContext } from 'react';

export type TabContextAPI = {
  activeTab: string | null;
  setActiveTab: (tab: string) => void;
  sectionName?: string;
};

export const TabContext = React.createContext<TabContextAPI | null>(null);

export function useTabContext() {
  const api = useContext(TabContext);
  if (api !== null) {
    return api;
  }
  throw new Error(
    `No TabContext found in your app's context. Did you forget to wrap your root component with TabContext.Provider?`,
  );
}
