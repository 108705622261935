import {
  GetMemberIntake,
  GetMemberIntake_getMember_intakeChart as IntakeChart,
  GetMemberIntakeVariables,
} from '@headspace/carehub-graphql/dist/queries/generated/GetMemberIntake';
import {
  GetMemberIntakeV2,
  GetMemberIntakeV2_member_referralNote as ReferralNote,
  GetMemberIntakeV2_survey_intakeSurvey as IntakeSurvey,
  GetMemberIntakeV2Variables,
} from '@headspace/carehub-graphql/dist/queries/generated/GetMemberIntakeV2';
import {
  getMemberIntake,
  getMemberIntakeV2,
} from '@headspace/carehub-graphql/dist/queries/GetMember';
import { DEFAULT_TIMEZONE } from 'app/clinician/ClinicianSettingsComponent';
import { MemberChartTab } from 'app/coach/member-chart/MemberChartTab';
import { TeenIntakeV2 } from 'app/patients/tabs/intake/dependent-intake/TeenIntakeV2';
import { EmptyIntakeTab } from 'app/patients/tabs/intake/EmptyIntakeTab';
import { IntakeCard } from 'app/patients/tabs/intake/IntakeTabV2Card';
import { useAppState } from 'app/state';
import { viewIntakeTabAction } from 'app/state/amplitude/actions/etc';
import { useLogger } from 'app/state/log/useLogger';
import { useMemberChartIndicators } from 'hooks/useMemberChartIndicators';
import { useMemberSurveys } from 'hooks/useMemberSurveys';
import { useQuery } from 'hooks/useQuery';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import styles from './IntakeTab.module.scss';
import { Relationships } from './RelationshipsV2';
import { RiskIndicators } from './RiskIndicatorsV2';
import { SelfReportedSymptoms } from './SelfReportedSymptomsV2';

interface Props {
  chart: IntakeSurvey | (IntakeChart & { completedAt: string | null });
  referralNote: ReferralNote | null;
  memberId: string;
  'data-testid'?: string;
}

export function IntakeTabContent(props: Readonly<Props>) {
  const logger = useLogger();
  const { chart, referralNote, memberId, 'data-testid': testId } = props;
  const {
    clinicalServicesSeeking,
    reasonsForSeekingClinicalServices,
    mentalIllnessIndicators,
    additionalInformationForClinician,
    preExistingMedicalConditions,
    currentMedications,
    relationships,
    riskKeywordsExtractedFromIntake,
    substanceConsumption,
    frequencyOfSubstanceConsumption,
    thoughtsOfHarm,
    reasonsForThoughtsOfHarm,
    religion,
    completedAt: intakeCompletedAt,
  } = chart;

  const referralNoteExists = referralNote != null;
  const { note, source, serviceType } = referralNote ?? {
    note: '',
    serviceType: null,
    source: '',
  };

  const {
    updateIntakeIndicator,
    showIntakeIndicator,
  } = useMemberChartIndicators(memberId);

  useEffect(() => {
    if (showIntakeIndicator) {
      updateIntakeIndicator?.().catch((reason) =>
        logger.warning('Failed to update intake indicator', { error: reason }),
      );
    }
  }, [showIntakeIndicator]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewIntakeTabAction());
  }, [dispatch]);

  const { data } = useMemberSurveys({ loadPHQ9s: true, memberId });

  const phq9s: { selfHarm: number }[] = (
    data?.getMember?.surveyResponses?.phq9s?.surveys ?? []
  )
    .filter((survey) => survey.selfHarm != null)
    .map((survey) => ({ selfHarm: survey.selfHarm as number }));

  const timezone = useAppState((_) => _.user.timezone ?? DEFAULT_TIMEZONE);
  const formattedTime = intakeCompletedAt
    ? moment(intakeCompletedAt).tz(timezone).format('MM/DD/YYYY')
    : null;
  const careTerms = {
    'Additional information for Clinician':
      additionalInformationForClinician ?? null,
    'Intake Form Submitted': formattedTime,
    'Reasons for Seeking Care': reasonsForSeekingClinicalServices ?? null,
    'Seeking Services': clinicalServicesSeeking ?? null,
  };

  return (
    <div data-testid={testId} className={styles.tabContent}>
      <MemberChartTab>
        <IntakeCard testId="careCard" title="Care" terms={careTerms} />
        <IntakeCard
          testId="medHistoryCard"
          title="Medical History"
          terms={{
            'Current Medications': currentMedications ?? null,
            'Frequency of substance use':
              frequencyOfSubstanceConsumption ?? null,
            'Preexisting Medical Conditions':
              preExistingMedicalConditions ?? null,
            'Substance use': substanceConsumption
              ? substanceConsumption.join(', ')
              : 'N/A',
          }}
        />
        {mentalIllnessIndicators && (
          <SelfReportedSymptoms {...mentalIllnessIndicators} />
        )}
        <RiskIndicators
          thoughtsOfHarm={convertYesNoStringToBoolean(thoughtsOfHarm)}
          reasonsForThoughtsOfHarm={reasonsForThoughtsOfHarm ?? null}
          phq9SelfHarmAnswers={phq9s}
          riskKeywords={riskKeywordsExtractedFromIntake ?? []}
        />
        {relationships && (
          <Relationships
            family={{
              additionalDetails: relationships.familyDetails,
              score: relationships.familyScore,
            }}
            spouse={{
              additionalDetails: relationships.spouseDetails,
              hasRelationship: relationships.hasSpouse,
              score: relationships.spouseScore,
            }}
            kids={{
              additionalDetails: relationships.childrenDetails,
              hasRelationship: relationships.hasChildren,
              score: relationships.childrenScore,
            }}
          />
        )}
        {religion && (
          <IntakeCard
            testId="religionCard"
            title="Religion"
            terms={{
              'Additional Info:': religion.description,
              'Is a member of religious or spiritual group': religion.isMemberOfReligiousGroup.toString(),
            }}
          />
        )}

        {referralNoteExists && (
          <IntakeCard
            testId="referralInfoCard"
            title="Referral Information"
            terms={{
              'Referral Note': note,
              'Service Type': serviceType,
              Source: source,
            }}
          />
        )}
      </MemberChartTab>
    </div>
  );
}

export function IntakeTabV2WithDeprecatedResolver(props: { memberId: string }) {
  const logger = useLogger();
  return useQuery<GetMemberIntake, GetMemberIntakeVariables>(
    (data) => {
      const member = data?.getMember;
      if (member?.intakeChart == null) {
        logger.info('IntakeTabV2: intake chart is null', {
          memberId: props.memberId,
        });
        return <EmptyIntakeTab />;
      }

      return (
        <IntakeTabContent
          data-testid="intakeTabDeprecated"
          chart={{
            ...member.intakeChart,
            completedAt: member?.intakeCompletedAt,
          }}
          referralNote={member?.referralNote}
          memberId={props.memberId}
        />
      );
    },
    getMemberIntake,
    {
      variables: { input: { id: props.memberId } },
    },
  );
}

export function IntakeTabV2(props: { memberId: string; pathPrefix?: string }) {
  const { memberId, pathPrefix = '' } = props;
  const logger = useLogger();
  return useQuery<GetMemberIntakeV2, GetMemberIntakeV2Variables>(
    (data) => {
      const member = data?.member;
      const survey = data?.survey?.intakeSurvey;
      if (survey == null) {
        logger.info('IntakeTabV2: intake chart is null', { memberId });
        return <EmptyIntakeTab />;
      }
      if (!survey.isAdultIntake) {
        logger.info('IntakeTabV2: loading teen intake instead', { memberId });
        return <TeenIntakeV2 pathPrefix={pathPrefix} memberId={memberId} />;
      }

      return (
        <IntakeTabContent
          data-testid="intakeTab"
          chart={survey}
          referralNote={member?.referralNote ?? null}
          memberId={memberId}
        />
      );
    },
    getMemberIntakeV2,
    {
      variables: { memberId },
    },
  );
}

function convertYesNoStringToBoolean(
  thoughtsOfHarm: string | boolean | null,
): boolean {
  return typeof thoughtsOfHarm === 'string'
    ? thoughtsOfHarm?.toLowerCase() === 'yes'
    : thoughtsOfHarm ?? false;
}
