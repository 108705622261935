import {
  InboxSection,
  InboxSectionHeaderColor,
} from 'app/inbox/components/InboxSection';
import { InboxSections } from 'app/inbox/types';
import { useAppState } from 'app/state';
import { useLogger } from 'app/state/log/useLogger';

import { useGetConversationsQuery } from './inboxEndpoints';
import styles from './styles/InboxV2.module.scss';

export const InboxV2 = () => {
  const showInbox = useAppState((state) => state.sideBar.showInbox);
  const logger = useLogger();

  const { data, isLoading, isError, error } = useGetConversationsQuery({
    limit: 20,
    status: 'open',
  });

  logger.info('useGetConversationsQuery result', {
    data,
    error,
    isError,
    isLoading,
  });

  return (
    <>
      {showInbox ? (
        <div data-testid="inboxV2" className={styles.inboxContainer}>
          <h1>InboxV2</h1>
          <InboxSection
            headerColor={InboxSectionHeaderColor.LIGHT_GREY}
            sectionTitle="Section Title1"
            section={InboxSections.SCHEDULED}
          >
            test
          </InboxSection>
        </div>
      ) : null}
    </>
  );
};
