import styles from './MemberHeaderBar.module.scss';

interface MemberIdProps {
  memberId: string;
}

export const MemberId = ({ memberId }: MemberIdProps) => {
  return (
    <span data-testid="member-header-id" className={styles.demographicsItem}>
      {memberId.toString()}
      {' • '}
    </span>
  );
};
