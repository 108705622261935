import {
  booleanField,
  field,
  FieldDefinitions,
  stringField,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  SubstanceUse,
  SubstanceUse_SubstanceType,
  SubstanceUse_SubstanceUseLineItem,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import { BooleanOption } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/BooleanOption';
import { isEmpty } from 'app/notes-ui/shared/ValidationRules';

import { getSubstanceUseDescription } from './utils';

const validateOtherSubstanceType = (
  substance: string,
  state: SubstanceUse_SubstanceUseLineItem,
) => {
  if (
    state.substanceType === SubstanceUse_SubstanceType.Other &&
    isEmpty(substance)
  ) {
    return 'This field is required';
  }
  return undefined;
};

const substanceUsedRequired = (
  _: boolean,
  state: SubstanceUse_SubstanceUseLineItem,
) => {
  if (state.currentlyUsed || state.pastUsed) {
    return undefined;
  }
  return 'This field is required';
};

export const substanceRowFieldDefs: FieldDefinitions<Pick<
  SubstanceUse_SubstanceUseLineItem,
  'substanceType' | 'otherSubstanceTypeDescription' | 'description'
>> = {
  description: stringField(),
  otherSubstanceTypeDescription: stringField({
    rules: [validateOtherSubstanceType],
  }),
  substanceType: field(),
};

export const validate = (data: SubstanceUse | null) => {
  if (data !== null) {
    const {
      appointmentId,
      substancesCurrentlyUsed,
      substancesPreviouslyUsed,
    } = data;

    const isPreviousSubstanceValid = substancesPreviouslyUsed.every(
      ({ substance }) => {
        const description = substance?.description
          ? substance.description
          : getSubstanceUseDescription(substance);
        return validateForm(
          { ...substance, description },
          substanceRowFieldDefs,
        );
      },
    );

    const isCurrentSubstanceValid = substancesCurrentlyUsed.every(
      ({ substance }) => {
        const description = substance?.description
          ? substance.description
          : getSubstanceUseDescription(substance);
        return validateForm(
          { ...substance, description },
          substanceRowFieldDefs,
        );
      },
    );

    return (
      !isEmpty(appointmentId) &&
      isCurrentSubstanceValid &&
      isPreviousSubstanceValid
    );
  }
  return false;
};

export const substanceUseFieldDefs: FieldDefinitions<Pick<
  SubstanceUse_SubstanceUseLineItem,
  | 'substanceType'
  | 'otherSubstanceTypeDescription'
  | 'description'
  | 'pastUsed'
  | 'currentlyUsed'
>> = {
  currentlyUsed: booleanField({ rules: [substanceUsedRequired] }),
  description: stringField(),
  otherSubstanceTypeDescription: stringField({
    rules: [validateOtherSubstanceType],
  }),
  pastUsed: booleanField({ rules: [] }),
  substanceType: field(),
};

export const validateV2 = (data: SubstanceUse | null) => {
  if (data !== null) {
    const { appointmentId, substancesCurrentlyUsed, anySubstanceUsed } = data;
    if (
      anySubstanceUsed === BooleanOption.no &&
      substancesCurrentlyUsed.length === 0
    ) {
      return true;
    }
    if (
      anySubstanceUsed === BooleanOption.yes &&
      substancesCurrentlyUsed.length === 0
    ) {
      return false;
    }
    const isCurrentSubstanceValid = substancesCurrentlyUsed.every(
      ({ substance }) => {
        const description = substance?.description
          ? substance.description
          : getSubstanceUseDescription(substance);
        return validateForm(
          { ...substance, description },
          substanceUseFieldDefs,
        );
      },
    );
    return !isEmpty(appointmentId) && isCurrentSubstanceValid;
  }
  return false;
};
