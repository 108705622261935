import { useAppState } from 'app/state';
import { formatTimestampWithTz, getTimezone } from 'utils/dateTime';
import { getItemCreatedEvent } from 'app/vault/api/utils';
import React, { useMemo } from 'react';

import styles from './LockedAmendments.module.scss';
import { AmendmentWithAuditLog } from './types';

type Props = {
  amendmentsWithAuditLogs: AmendmentWithAuditLog[];
};

export function LockedAmendments(props: Props) {
  const { amendmentsWithAuditLogs } = props;
  const { timezone } = useAppState((_) => _.user);
  const tzOrUtc = getTimezone(timezone);
  const amendmentsWithCreationTs = useMemo(() => {
    return amendmentsWithAuditLogs.map(({ amendment, auditLog }) => {
      const creationEvt = getItemCreatedEvent(auditLog);
      const creationDateTime = creationEvt
        ? formatTimestampWithTz(creationEvt.timestamp, tzOrUtc)
        : null;
      return {
        amendment,
        creationDateTime,
      };
    });
  }, [amendmentsWithAuditLogs, tzOrUtc]);

  return (
    <div data-testid="lockedAmendments" className={styles.amendmentsContainer}>
      {amendmentsWithCreationTs.map(({ amendment, creationDateTime }, i) => {
        return (
          <div key={i} className={styles.amendmentContainer}>
            {creationDateTime ? <p>{creationDateTime}</p> : null}
            <p>{amendment.text}</p>
          </div>
        );
      })}
    </div>
  );
}
