import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Menu } from '@mui/material';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import { NoteRoutes, patientNoteRoute } from 'app/top-nav/Routes';
import {
  nonAppointmentNoteButtonClicked,
  selectNonAppointmentNoteTemplateFromDropdown,
} from 'app/state/amplitude/actions/notes';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './NotesTab.module.scss';

export function NewNoteMenu(props: {
  navigateTo: (url: string) => void;
  patientId: string;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const { patientId } = props;

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    dispatch(nonAppointmentNoteButtonClicked({ patientId }));
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onItemClick = (item: NoteRoutes) => {
    dispatch(
      selectNonAppointmentNoteTemplateFromDropdown({
        patientId,
        template: item,
      }),
    );
    props.navigateTo(patientNoteRoute(props.patientId, item));
    onClose();
  };
  return (
    <div>
      <Button
        data-testid="newMenu"
        className={styles.dropdownButton}
        aria-controls="new-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={onClick}
      >
        + New
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="newNoteButton"
        anchorEl={anchorEl}
        keepMounted={true}
        open={open}
        classes={{ list: styles.menuRoot }}
        onClose={onClose}
        TransitionComponent={Fade}
      >
        <div className={styles.subheader}>Note</div>
        <MenuItem
          data-testid="outOfSessionItemBtn"
          className={styles.item}
          onClick={() => onItemClick(NoteRoutes.OUT_OF_SESSION)}
        >
          Out of Session
        </MenuItem>
        <MenuItem
          data-testid="terminationItemBtn"
          className={styles.item}
          onClick={() => onItemClick(NoteRoutes.TERMINATION)}
        >
          Termination
        </MenuItem>
      </Menu>
    </div>
  );
}
