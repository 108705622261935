import { ClinicianChecklist_Version } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/ClinicianChecklist';
import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { validate as validateCollaboration } from 'app/notes-ui/shared/collaboration-plan/schema';
import { validateTherapy as validateSafetyV2 } from 'app/notes-ui/shared/safety-intake/schema';
import { validate as validateSafety } from 'app/notes-ui/shared/safety-progress/schema';
import {
  validate as validateSubstanceUsed,
  validateV2 as validateSubstanceUsedV2,
} from 'app/notes-ui/shared/substance-abuse/schema';
import { validate as validateBehavioralObservationV2 } from 'app/notes-ui/therapy/intake/behavioral-observations/BehavioralObservationsFormv2';
import { validate as validateAssessment } from 'app/notes-ui/therapy/progress/assessment/schema';
import { validate as validateClinicianChecklistV2 } from 'app/notes-ui/therapy/progress/clinician-checklist/ClinicianChecklistFormV2';
import { validate as validateClinicianChecklist } from 'app/notes-ui/therapy/progress/clinician-checklist/schema';
import { validate as validateFocus } from 'app/notes-ui/therapy/progress/focus-area/schema';
import { validate as validateTreatmentPlan } from 'app/notes-ui/therapy/progress/treatment-plan/schema';
import { validate as validateTreatmentPlanV2 } from 'app/notes-ui/therapy/progress/treatment-plan/TreatmentPlanFormV2';
import { validate as validateBehavioralObservation } from 'app/notes-ui/therapy/shared/behavioral-observations/schema';
import { TherapyProgressNote } from 'app/vault/api/TherapyProgressNotesAPI';

export function TherapyProgressIsNoteLockable(
  note: TherapyProgressNote,
): boolean {
  return (
    note.metadata.status === Metadata_NoteStatus.draft_note &&
    validateAssessment(note.ASSESSMENT) &&
    validateBehavioralObservation(note.BEHAVIORAL_OBSERVATION) &&
    validateFocus(note.FOCUS_AREA) &&
    validateClinicianChecklist(note.CLINICIAN_CHECKLIST) &&
    validateCollaboration(note.COLLABORATION_PLAN) &&
    validateSafety(note.SAFETY) &&
    validateSubstanceUsed(note.SUBSTANCE_ABUSE) &&
    validateTreatmentPlan(note.TREATMENT_PLAN)
  );
}

export function TherapyProgressIsNoteLockableV2(
  note: TherapyProgressNote,
): boolean {
  return (
    note.metadata.status === Metadata_NoteStatus.draft_note &&
    validateClinicianChecklistV2(note.CLINICIAN_CHECKLIST) &&
    validateFocus(note.FOCUS_AREA) &&
    validateSubstanceUsedV2(note.SUBSTANCE_ABUSE) &&
    validateSafetyV2(note.SAFETY) &&
    validateBehavioralObservationV2(note.BEHAVIORAL_OBSERVATION) &&
    validateAssessment(note.ASSESSMENT) &&
    validateTreatmentPlanV2(note.TREATMENT_PLAN)
  );
}

/**
 * This is a map for therapy progress note versions to their respective validation functions
 * The Record is used to ensure that all versions are accounted for
 * It uses the ClinicianChecklist_Version enum as the key as it is the first section in the note
 * and we don't have a note version field in the note metadata
 */
export const versionValidationMap: Record<
  ClinicianChecklist_Version,
  (note: TherapyProgressNote) => boolean
> = {
  [ClinicianChecklist_Version.undefined_version]: TherapyProgressIsNoteLockable,
  [ClinicianChecklist_Version.UNRECOGNIZED]: TherapyProgressIsNoteLockable,
  // V0 corresponds to the most recent version of the note, as previous versions were not versioned
  [ClinicianChecklist_Version.v0]: TherapyProgressIsNoteLockableV2,
};

/**
 * This function is used to validate a therapy intake note
 * It checks for the notes schema version and then calls the appropriate validation function
 * @param note The note to validate
 * @returns true if the note is valid, false otherwise
 */
export function validateTherapyProgressNote(
  note: TherapyProgressNote | null,
): boolean {
  if (!note || note.CLINICIAN_CHECKLIST?.version === undefined) return false;

  // select the validation function based on the version of the clinician checklist
  // the reason for using clinician checks is merely because it is the first section and we
  // don't have a note version field in the note metadata
  const validationFunction =
    versionValidationMap[note.CLINICIAN_CHECKLIST?.version];

  return validationFunction ? validationFunction(note) : false;
}
