import ladybug from 'assets/member-chart/error-ladybug.svg';
import Messages from 'i18n/en/memberChartCard.json';
import React from 'react';
import { BugReportButton } from 'shared-components/BugReportButton';
import { ErrorState } from 'shared-components/error-state/ErrorState';
import styles from 'shared-components/error-state/styles/BaseErrorState.module.scss';
import {
  CardColumnGaps,
  CardGrid,
  CardRowGaps,
  NumberOfCardColumns,
} from 'shared-components/grid-layout/CardGrid.module';

export type ErrorProps = {
  error?: Error;
  smallError?: boolean;
  showMessage?: boolean;
};
export function MemberChartErrorState(props: ErrorProps) {
  return (
    <ErrorState
      error={props.error}
      children={<MemberChatErrorComponent {...props} />}
    />
  );
}

const MemberChatErrorComponent = (props: ErrorProps): React.ReactElement => {
  const { error, smallError = false, showMessage = false } = props;
  const className = smallError
    ? `${styles.container} ${styles.smallError}`
    : styles.container;

  return (
    <div data-testid="member-chart-error" className={className}>
      <div className={styles.title}>
        <span>{Messages.anErrorOccurred}</span>
        <img className={styles.picture} src={ladybug} alt="ladybug" />
      </div>

      <div className={styles.body}>
        {showMessage && error && (
          <p className={styles.errorText}>{error.message}</p>
        )}
        <h4 className={styles.subtitle}>Let’s try this:</h4>
        <ul className={styles.list}>
          <li className={styles.item}>Refresh the page.</li>
          <li className={styles.item}>{Messages.submitBugReport}</li>
        </ul>
      </div>

      <div className={styles.footer}>
        <CardGrid
          rowGap={CardRowGaps.CONDENSED}
          columnGap={CardColumnGaps.CONDENSED}
          numberOfColumns={NumberOfCardColumns.TWO}
        >
          <BugReportButton
            className={styles.butReportButtonContainer}
            errors={[error ?? new Error('Error not provided')]}
          />
        </CardGrid>
      </div>
    </div>
  );
};
