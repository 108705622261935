import { RejectValue, RejectValueWithNullError } from './types';

export const cutOutError = (
  rejectValue: RejectValue,
): RejectValueWithNullError => {
  return {
    ...rejectValue,
    error: null,
    message: rejectValue.error.message,
  };
};
