import { Fields } from '@ginger.io/react-use-form';
import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import { SafetyPlanStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/safety/SafetyPlan';
import { Safety } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyProgress';
import { Link } from '@mui/material';
import { Size, Width } from 'types/StyleTypes';
import {
  EnumCheckboxField,
  TextAreaField,
  TextAreaFieldList,
} from 'app/notes-ui/forms/fields';
import { BooleanRadioField } from 'app/notes-ui/forms/form-controls/BooleanRadioField';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import {
  NoteFormControlLabel,
  NoteFormControlLabelStyles,
} from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { NoteTitle } from 'app/notes-ui/forms/form-controls/NoteTitle';
import { Labels } from 'app/notes-ui/shared/safety-progress/constants';
import { TherapistRole } from 'generated/globalTypes';
import React from 'react';

const formControlStyles: NoteFormControlLabelStyles = {
  checkbox: styles.checkbox,
  label: styles.label,
  root: styles.root,
  subtext: styles.subtext,
};

type Props = {
  disabled?: boolean;
  fields: Fields<Safety>;
  clinicianType: TherapistRole;
  showCondensedFields?: boolean;
};

export const SafetyPlan = (props: Props) => {
  const { disabled, fields, showCondensedFields = true } = props;

  return (
    <>
      <NoteTitle
        data-testid="safetyPlanTitle"
        label={Labels.safetyPlan.title}
      />
      <div className={styles.formGroup}>
        <EnumCheckboxField
          disabled={disabled}
          testId={Labels.safetyPlan.status.id}
          label={Labels.safetyPlan.status.label}
          checkedValue={SafetyPlanStatus.incomplete}
          uncheckedValue={SafetyPlanStatus.completed}
          field={fields.safetyPlan.status}
        />
      </div>
      {fields.safetyPlan.status.value != SafetyPlanStatus.incomplete &&
        showCondensedFields && (
          <div data-testid="condensedSafetyPlanFields">
            <div className={styles.textAreaField}>
              <TextAreaField
                testId={
                  Labels.safetyPlan.psychiatryQuestions.safetyPlanDocumentation
                    .id
                }
                disabled={disabled}
                label={
                  Labels.safetyPlan.psychiatryQuestions.safetyPlanDocumentation
                    .label
                }
                placeholder={
                  Labels.safetyPlan.psychiatryQuestions.safetyPlanDocumentation
                    .placeholder
                }
                field={
                  fields.safetyPlan.psychiatryQuestions.safetyPlanDocumentation
                }
                width={Width.FULL}
                className={styles.textAreaField}
                formControlStyles={formControlStyles}
                autoExpand={true}
                rows={1}
              />
            </div>
            <div className={styles.textAreaField}>
              <TextAreaFieldList
                disabled={disabled}
                minItems={1}
                testId={
                  Labels.safetyPlan.psychiatryQuestions
                    .agenciesToContactInCrisis.id
                }
                label={
                  Labels.safetyPlan.psychiatryQuestions
                    .agenciesToContactInCrisis.labels
                }
                subtext={[
                  Labels.safetyPlan.psychiatryQuestions.agenciesToContactInCrisis.subtexts[0]
                    .split('\n')
                    .map((subtext, index) => (
                      <p className={styles.noMargin} key={index}>
                        {subtext}
                      </p>
                    )),
                ]}
                placeholder={
                  Labels.safetyPlan.psychiatryQuestions
                    .agenciesToContactInCrisis.placeholder
                }
                field={
                  fields.safetyPlan.psychiatryQuestions
                    .agenciesToContactInCrisis
                }
                width={Width.FULL}
                className={styles.textAreaField}
                formControlStyles={formControlStyles}
                autoExpand={true}
                rows={1}
              />
            </div>
          </div>
        )}
      {fields.safetyPlan.status.value != SafetyPlanStatus.incomplete &&
        !showCondensedFields && (
          <div data-testid="fullSafetyPlanFields">
            <div className={styles.textAreaField}>
              <TextAreaField
                testId={
                  Labels.safetyPlan.therapyQuestions
                    .warningSignsAndTriggersForDevelopingCrisis.id
                }
                disabled={disabled}
                label={
                  Labels.safetyPlan.therapyQuestions
                    .warningSignsAndTriggersForDevelopingCrisis.label
                }
                placeholder={
                  Labels.safetyPlan.therapyQuestions
                    .warningSignsAndTriggersForDevelopingCrisis.placeholder
                }
                field={
                  fields.safetyPlan.therapyQuestions
                    .warningSignsAndTriggersForDevelopingCrisis
                }
                width={Width.FULL}
                className={styles.textAreaField}
                formControlStyles={formControlStyles}
                autoExpand={true}
                rows={1}
              />
            </div>

            <div className={styles.textAreaField}>
              <TextAreaField
                testId={
                  Labels.safetyPlan.therapyQuestions
                    .internalStrategiesOrientationForMentalHealthCrisis.id
                }
                disabled={disabled}
                label={
                  Labels.safetyPlan.therapyQuestions
                    .internalStrategiesOrientationForMentalHealthCrisis.label
                }
                subtext={
                  Labels.safetyPlan.therapyQuestions
                    .internalStrategiesOrientationForMentalHealthCrisis.subtext
                }
                placeholder={
                  Labels.safetyPlan.therapyQuestions
                    .internalStrategiesOrientationForMentalHealthCrisis
                    .placeholder
                }
                field={
                  fields.safetyPlan.therapyQuestions
                    .internalStrategiesOrientationForMentalHealthCrisis
                }
                width={Width.FULL}
                className={styles.textAreaField}
                formControlStyles={formControlStyles}
                autoExpand={true}
                rows={1}
              />
            </div>

            <div className={styles.textAreaField}>
              <TextAreaField
                testId={
                  Labels.safetyPlan.therapyQuestions
                    .externalStrategiesOrientationForMentalHealthCrisis.id
                }
                disabled={disabled}
                subtext={
                  Labels.safetyPlan.therapyQuestions
                    .externalStrategiesOrientationForMentalHealthCrisis.subtext
                }
                placeholder={
                  Labels.safetyPlan.therapyQuestions
                    .externalStrategiesOrientationForMentalHealthCrisis
                    .placeholder
                }
                field={
                  fields.safetyPlan.therapyQuestions
                    .externalStrategiesOrientationForMentalHealthCrisis
                }
                width={Width.FULL}
                className={styles.textAreaField}
                formControlStyles={formControlStyles}
                autoExpand={true}
                rows={1}
              />
            </div>

            <div className={styles.textAreaField}>
              <TextAreaFieldList
                disabled={disabled}
                minItems={3}
                testId={
                  Labels.safetyPlan.therapyQuestions.peopleToContactInCrisis.id
                }
                label={
                  Labels.safetyPlan.therapyQuestions.peopleToContactInCrisis
                    .labels
                }
                subtext={
                  Labels.safetyPlan.therapyQuestions.peopleToContactInCrisis
                    .subtexts
                }
                placeholder={
                  Labels.safetyPlan.therapyQuestions.peopleToContactInCrisis
                    .placeholder
                }
                field={
                  fields.safetyPlan.therapyQuestions.peopleToContactInCrisis
                }
                width={Width.FULL}
                className={styles.textAreaField}
                formControlStyles={formControlStyles}
                autoExpand={true}
                rows={1}
              />
            </div>

            <div className={styles.textAreaField}>
              <TextAreaField
                testId={
                  Labels.safetyPlan.therapyQuestions.safeEnvironmentOrientation
                    .id
                }
                disabled={disabled}
                label={
                  Labels.safetyPlan.therapyQuestions.safeEnvironmentOrientation
                    .label
                }
                placeholder={
                  Labels.safetyPlan.therapyQuestions.safeEnvironmentOrientation
                    .placeholder
                }
                field={
                  fields.safetyPlan.therapyQuestions.safeEnvironmentOrientation
                }
                width={Width.FULL}
                className={styles.textAreaField}
                formControlStyles={formControlStyles}
                autoExpand={true}
                rows={1}
              />
            </div>

            <div className={styles.textAreaField}>
              <TextAreaField
                testId={
                  Labels.safetyPlan.therapyQuestions.rememberWhatsImportant.id
                }
                disabled={disabled}
                label={
                  Labels.safetyPlan.therapyQuestions.rememberWhatsImportant
                    .label
                }
                placeholder={
                  Labels.safetyPlan.therapyQuestions.rememberWhatsImportant
                    .placeholder
                }
                field={
                  fields.safetyPlan.therapyQuestions.rememberWhatsImportant
                }
                width={Width.FULL}
                className={styles.textAreaField}
                formControlStyles={formControlStyles}
                autoExpand={true}
                rows={1}
              />
            </div>

            <div className={styles.textAreaField}>
              <TextAreaFieldList
                disabled={disabled}
                minItems={1}
                testId={
                  Labels.safetyPlan.therapyQuestions.agenciesToContactInCrisis
                    .id
                }
                label={
                  Labels.safetyPlan.therapyQuestions.agenciesToContactInCrisis
                    .labels
                }
                subtext={[
                  Labels.safetyPlan.psychiatryQuestions.agenciesToContactInCrisis.subtexts[0]
                    .split('\n')
                    .map((subtext, index) => (
                      <p className={styles.noMargin} key={index}>
                        {subtext}
                      </p>
                    )),
                ]}
                placeholder={
                  Labels.safetyPlan.therapyQuestions.agenciesToContactInCrisis
                    .placeholder
                }
                field={
                  fields.safetyPlan.therapyQuestions.agenciesToContactInCrisis
                }
                width={Width.FULL}
                className={styles.textAreaField}
                formControlStyles={formControlStyles}
                autoExpand={true}
                rows={1}
              />
            </div>

            <NoteFormControlLabel
              label="Actions Taken"
              size={Size.MD}
              subtext={
                <>
                  <span>
                    For required actions based on risk type, consult the{' '}
                    <Link
                      target="_blank"
                      href="https://docs.google.com/document/d/1hk-VNlVOurTO-MQaVlSzXDZWpk8GjCzLD4gQpwO7rg4/edit#"
                    >
                      Procedures Manual
                    </Link>
                    .
                  </span>
                </>
              }
              styles={formControlStyles}
            >
              <div className={styles.formGroup}>
                <BooleanRadioField
                  disabled={disabled}
                  name="actionsTaken_ccr"
                  label="CRR filed?"
                  value={fields.safetyPlan.actionsTaken.crr.value}
                  onChange={fields.safetyPlan.actionsTaken.crr.setValue}
                  error={fields.safetyPlan.actionsTaken.crr.error}
                  horizontalOriented={true}
                />
                <BooleanRadioField
                  disabled={disabled}
                  name="actionsTaken_sharedResources"
                  label="Resources shared?"
                  value={fields.safetyPlan.actionsTaken.sharedResources.value}
                  onChange={
                    fields.safetyPlan.actionsTaken.sharedResources.setValue
                  }
                  error={fields.safetyPlan.actionsTaken.sharedResources.error}
                  horizontalOriented={true}
                />
                <BooleanRadioField
                  disabled={disabled}
                  name="actionsTaken_consultedWithManager"
                  label="Consulted with manager?"
                  value={
                    fields.safetyPlan.actionsTaken.consultedWithManager.value
                  }
                  onChange={
                    fields.safetyPlan.actionsTaken.consultedWithManager.setValue
                  }
                  error={
                    fields.safetyPlan.actionsTaken.consultedWithManager.error
                  }
                  horizontalOriented={true}
                />
                <BooleanRadioField
                  disabled={disabled}
                  name="actionsTaken_careCoordinationTeamEngaged"
                  label="Engaged the Care Coordination team?"
                  value={
                    fields.safetyPlan.actionsTaken.careCoordinationTeamEngaged
                      .value
                  }
                  onChange={
                    fields.safetyPlan.actionsTaken.careCoordinationTeamEngaged
                      .setValue
                  }
                  error={
                    fields.safetyPlan.actionsTaken.careCoordinationTeamEngaged
                      .error
                  }
                  horizontalOriented={true}
                />
                <BooleanRadioField
                  disabled={disabled}
                  name="actionsTaken_onCallClinicianContacted"
                  label="Was an on-call clinician consulted?"
                  value={
                    fields.safetyPlan.actionsTaken.onCallClinicianContacted
                      .value
                  }
                  onChange={
                    fields.safetyPlan.actionsTaken.onCallClinicianContacted
                      .setValue
                  }
                  error={
                    fields.safetyPlan.actionsTaken.onCallClinicianContacted
                      .error
                  }
                  horizontalOriented={true}
                />

                {fields.safetyPlan.actionsTaken.onCallClinicianContacted
                  .value === BooleanOption.yes && (
                  <TextAreaField
                    testId={
                      Labels.safetyPlan.actionsTaken
                        .onCallClinicianContactedNotes.id
                    }
                    disabled={disabled}
                    label={
                      Labels.safetyPlan.actionsTaken
                        .onCallClinicianContactedNotes.label
                    }
                    placeholder={
                      Labels.safetyPlan.actionsTaken
                        .onCallClinicianContactedNotes.placeholder
                    }
                    field={
                      fields.safetyPlan.actionsTaken
                        .onCallClinicianContactedNotes
                    }
                    width={Width.FULL}
                    className={styles.textAreaField}
                    formControlStyles={formControlStyles}
                    autoExpand={true}
                    rows={1}
                  />
                )}
              </div>
            </NoteFormControlLabel>
          </div>
        )}
    </>
  );
};
