import { Add as AddCircleIcon, KeyboardArrowRight } from '@mui/icons-material';
import { Collapse, Popover } from '@mui/material';
import { InboxSections } from 'app/inbox/types';
import styles from 'app/inbox/components/styles/InboxSection.module.scss';
import AddHomeworkDialog from 'app/patients/tabs/content/AddHomeworkDialog';
import { classNameCombiner, toKebabCase } from 'utils';
import { MouseEvent, ReactNode, useCallback, useMemo, useState } from 'react';

export enum InboxSectionHeaderColor {
  LIGHT_ROSE = 'light-rose',
  LIGHT_CLOUD = 'light-cloud',
  LIGHT_MINT = 'light-mint',
  LIGHT_GREY = 'light-grey',
}
export enum InboxSectionIcon {
  SUICIDE_RISK = 'suicide-risk',
  NO_SUICIDE_RISK = 'no-suicide-risk',
  CHECKMARK = 'checkmark',
}

// type SectionIcon =  'suicide-risk' | 'no-suicide-risk' | 'checkmark'

export type InboxSectionProps = {
  children: ReactNode;
  headerColor: InboxSectionHeaderColor;
  sectionIcon?: InboxSectionIcon;
  sectionTitle?: string;
  section?: InboxSections;
  memberId?: string;
  isExpandedByDefault?: boolean;
  isCollapsible?: boolean;
  useAddCircleButton?: boolean;
  addCircleButtonCallback?: () => void;
};

export const InboxSection = ({
  children,
  sectionIcon,
  sectionTitle,
  section,
  headerColor,
  memberId = '',
  isExpandedByDefault = true,
  isCollapsible = true,
  useAddCircleButton = false,
  addCircleButtonCallback = undefined,
}: InboxSectionProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isSectionExpanded, setIsSectionExpanded] = useState(
    isExpandedByDefault,
  );

  const dataTestId = useMemo(() => `inbox-section-${section?.toLowerCase()}`, [
    section,
  ]);
  const popoverIsOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      if (addCircleButtonCallback !== undefined) {
        addCircleButtonCallback();
      }
    },
    [addCircleButtonCallback],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSectionHeaderClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>): void => {
      e.preventDefault();
      setIsSectionExpanded(!isSectionExpanded);
    },
    [isSectionExpanded],
  );

  return (
    <span data-testid={`inbox-section-${section?.toLowerCase()}`}>
      <div
        data-testid={`${dataTestId}-header`}
        className={classNameCombiner([
          styles.sectionHeaderWrapper,
          section ? styles[toKebabCase(section.toLowerCase())] : '',
        ])}
      >
        <button
          data-testid={`${dataTestId}-header-button`}
          className={classNameCombiner([
            styles.sectionHeader,
            styles[headerColor],
          ])}
          onClick={handleSectionHeaderClick}
          disabled={!isCollapsible}
          type="button"
        >
          {sectionIcon && (
            <div
              className={classNameCombiner([
                styles.sectionIcon,
                styles[sectionIcon],
              ])}
            />
          )}
          <div className={styles.sectionTitle}>{sectionTitle}</div>
          {isCollapsible && (
            <div
              className={
                isSectionExpanded
                  ? styles.expandIcon
                  : classNameCombiner([styles.expandIcon, styles.rotate90deg])
              }
            >
              <KeyboardArrowRight />
            </div>
          )}
          {useAddCircleButton && (
            <button
              className={styles.addCircleButton}
              onClick={handleClick}
              data-testid="add_content_button"
              type="button"
            >
              <AddCircleIcon className={styles.addCircleIcon} />
            </button>
          )}
          <Popover
            open={popoverIsOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
          >
            <AddHomeworkDialog memberId={memberId} onClose={handleClose} />
          </Popover>
        </button>
      </div>

      <div className={styles.section} data-testid={`${dataTestId}-body`}>
        <Collapse
          in={isSectionExpanded}
          timeout={400}
          className={
            sectionTitle
              ? styles.sectionContent
              : classNameCombiner([styles.sectionContent, styles.paddingTop])
          }
        >
          {children}
        </Collapse>
      </div>
    </span>
  );
};
