import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import styles from './CSCAutoOutreachEnablePopup.module.scss';
import { Button } from 'shared-components/button/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';

export type Props = {
  lastDisableReason?: string | null;
  lastDisableNote?: string | null;
  closer: () => void;
  unPause: (notes: string) => void;
};

export function CSCAutoOutreacEnableComponent(props: Props) {
  const [reasonNoSeletected, setReasonNoSeletected] = React.useState<boolean>(
    false,
  );
  const [additionalNotes, setAdditionalNotes] = React.useState<string>('');
  const handleYesNoRadioGroupChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setReasonNoSeletected((event.target as HTMLInputElement).value === 'no');
  };

  const handleCancelBtnClick = () => {
    // we should call the closer
    props.closer();
  };

  const onInputChange = (event: React.ChangeEvent<{ value: string }>) => {
    const value = event.target.value;
    setAdditionalNotes(value);
  };

  const handleEnableBtnClick = () => {
    props.unPause(additionalNotes);
    props.closer();
  };

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <div>
        If enabled, members who do not have upcoming appointments will be sent
        auto-messages reminding them to schedule a follow-up appointment.
      </div>

      {props.lastDisableReason && (
        <div>Was &quot;{props.lastDisableReason}&quot; resolved?</div>
      )}
      {props.lastDisableNote && (
        <div>Additional notes: {props.lastDisableNote}</div>
      )}

      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="yes"
        name="radio-buttons-group"
        onChange={handleYesNoRadioGroupChange}
        data-testid="yesNoSwitch"
        row
      >
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>

      {reasonNoSeletected && (
        <div>The reason must be resolved in order to enable auto-messages.</div>
      )}

      <div>Additional notes</div>
      <TextField
        id="outlined-textarea"
        label="Details about the situation"
        placeholder="Details about the situation..."
        onChange={onInputChange}
        fullWidth
        maxRows={4}
        data-testid="additionalNotesEnable"
      />

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item>
          <Button
            size={'medium'}
            className={styles.cancel}
            variant={'outlined'}
            fullWidth={false}
            onClick={handleCancelBtnClick}
            data-testid="cancelEnableBtn"
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="medium"
            className={styles.enableDisable}
            fullWidth={false}
            onClick={handleEnableBtnClick}
            disabled={reasonNoSeletected}
            data-testid="saveEnableBtn"
          >
            Enable
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
}
