import styles from 'app/coach/coach-notes/CoachNotes.module.scss';
import { CoachNotesErrorNotification } from 'app/coach/coach-notes/CoachNotesErrorNotification';
import {
  CareProviderNoteType,
  CoachNotesItem,
  FilterKeys,
  NotesAndSessionInfo,
  NotesItemResponse,
} from 'app/coach/coach-notes/CoachNotesTypes';
import { useCoachNotesFilters } from 'app/coach/coach-notes/hooks';
import { NoteDetailView } from 'app/coach/coach-notes/note-detail-view/NoteDetailView';
import { getNotesTableBody } from 'app/coach/coach-notes/NotesTable';
import { getColumns } from 'app/coach/coach-notes/utils';
import { MenuItem as FilterMenuItem } from 'shared-components/menu/FilterMenu';
import { SortableTable } from 'app/sortable-table/SortableTable';
import { useAppState } from 'app/state';
import { classNameCombiner } from 'utils';
import React from 'react';

type Props = {
  data: NotesAndSessionInfo;
  onRowClick: (note: NotesItemResponse) => Promise<void>;
  updateNote: (noteState: CoachNotesItem) => Promise<void>;
  noteType?: CareProviderNoteType;
  setQueryParams: () => void;
};

export function NoteDetailViewContainer(props: Readonly<Props>) {
  const {
    noteOrder,
    typeFilters,
    toggleTypeFilters,
    toggleOrderFilter,
    activeTypeFilters,
  } = useCoachNotesFilters();
  const selectedNote = useAppState((_) => _.coachNotes.selectedNote);

  const _onSortableTableFilter = (column: string, item: FilterMenuItem) => {
    switch (column) {
      case FilterKeys.NOTE_TYPE:
        toggleTypeFilters(item.key);
        break;
      default:
        break;
    }
  };

  const { data, noteType, onRowClick, setQueryParams, updateNote } = props;
  const showNoteDetailView = !!noteType;
  if (showNoteDetailView) {
    return (
      <NoteDetailView
        data-testid="noteDetailView"
        noteType={selectedNote?.noteType || noteType}
        selectedNote={selectedNote}
        notes={data?.notes}
        readOnly={selectedNote?.readOnly ?? false}
        sessionInfo={data?.sessionInfo}
        updateDraftNoteState={updateNote}
        setQueryParams={setQueryParams}
      />
    );
  }
  const noteRows = getNotesTableBody({
    activeTypeFilters,
    noteOrder,
    notes: data.notes,
    onClick: onRowClick,
  });

  return (
    <>
      <CoachNotesErrorNotification error={data.error} />
      <SortableTable
        columns={getColumns({ direction: noteOrder, typeFilters })}
        tableClass={classNameCombiner([
          styles.coachNotesTable,
          styles.withMultiTab,
        ])}
        rowClass={styles.tableRow}
        sortLabelClass={styles.sortLabel}
        headerCellClass={styles.headerCell}
        dataTestId="coachNotesTable"
        rows={noteRows}
        loading={false}
        error={false}
        errorMessage={undefined}
        onSortableTableFilter={_onSortableTableFilter}
        changeColumnSort={toggleOrderFilter}
      />
    </>
  );
}
