import { FormHelperText } from '@mui/material';
import React from 'react';

import styles from './FormError.module.scss';

export function FormError({ error }: { error?: string }) {
  return (
    <FormHelperText component="p" className={styles.helpText} error={true}>
      <span data-testid="inputError">{error}</span>
    </FormHelperText>
  );
}
