import { ReactComponent as Checkmark } from 'assets/Checkmark.svg';
import React from 'react';

import styles from './SICoachAddedBanner.module.scss';

export const SICoachAddedBanner = () => {
  return (
    <div
      data-testid="si-coach-added-banner"
      className={styles.siCoachAddedBanner}
    >
      <Checkmark />
      SI Coach temporarily added
    </div>
  );
};
