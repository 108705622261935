import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import {
  CreatePreSignedUploadUrl,
  CreatePreSignedUploadUrlVariables,
} from './generated/CreatePreSignedUploadUrl';
import gql from 'graphql-tag';
import { encryptedDataFragment } from 'app/vault/queries';
import {
  GetClinicalDocuments,
  GetClinicalDocumentsVariables,
} from './generated/GetClinicalDocuments';
import {
  CreateClinicalDocument,
  CreateClinicalDocumentVariables,
} from './generated/CreateClinicalDocument';
import {
  DeleteClinicalDocument,
  DeleteClinicalDocumentVariables,
} from './generated/DeleteClinicalDocument';
import {
  UpdateClinicalDocument,
  UpdateClinicalDocumentVariables,
} from './generated/UpdateClinicalDocument';
import {
  GetClinicalDocument,
  GetClinicalDocumentVariables,
} from './generated/GetClinicalDocument';

const vaultItemFragment = gql`
  fragment vaultItemFragment on VaultItem {
    id
    encryptedData {
      ...encryptedDataFragment
    }
    creator {
      id
      firstName
      lastName
    }
    createdAt
  }
  ${encryptedDataFragment}
`;

export const createPreSignedUploadUrl: TypedDocumentNode<
  CreatePreSignedUploadUrl,
  CreatePreSignedUploadUrlVariables
> = gql`
  mutation CreatePreSignedUploadUrl($input: CreatePreSignedUrlInput!) {
    createPreSignedUploadUrl(input: $input) {
      url
    }
  }
`;

export const createPreSignedDownloadUrl: TypedDocumentNode<
  CreatePreSignedUploadUrl,
  CreatePreSignedUploadUrlVariables
> = gql`
  mutation CreatePreSignedDownloadUrl($input: CreatePreSignedUrlInput!) {
    createPreSignedDownloadUrl(input: $input) {
      url
    }
  }
`;

export const deleteClinicalDocument: TypedDocumentNode<
  DeleteClinicalDocument,
  DeleteClinicalDocumentVariables
> = gql`
  mutation DeleteClinicalDocument($input: DeleteVaultItemInput!) {
    deleteVaultItem(input: $input) {
      id
      success
      errorMessage
    }
  }
`;

export const getClinicalDocuments: TypedDocumentNode<
  GetClinicalDocuments,
  GetClinicalDocumentsVariables
> = gql`
  query GetClinicalDocuments(
    $tag: String!
    $pagination: VaultPaginationInput!
    $groupId: ID
  ) {
    getPaginatedVaultItemsByTag(
      input: { tag: $tag, groupId: $groupId, pagination: $pagination }
    ) {
      items {
        encryptedItem {
          ...vaultItemFragment
        }
      }
      cursor
    }
  }
  ${vaultItemFragment}
`;

export const getClinicalDocument: TypedDocumentNode<
  GetClinicalDocument,
  GetClinicalDocumentVariables
> = gql`
  query GetClinicalDocument($id: ID!, $groupId: ID) {
    getVaultItemById(id: $id, groupId: $groupId) {
      encryptedItem {
        ...vaultItemFragment
      }
    }
  }
  ${vaultItemFragment}
`;

export const createClinicalDocument: TypedDocumentNode<
  CreateClinicalDocument,
  CreateClinicalDocumentVariables
> = gql`
  mutation CreateClinicalDocument($input: [CreateVaultItemInput!]!) {
    createVaultItems(input: $input) {
      encryptedItem {
        ...vaultItemFragment
      }
    }
  }
  ${vaultItemFragment}
`;

export const updateClinicalDocument: TypedDocumentNode<
  UpdateClinicalDocument,
  UpdateClinicalDocumentVariables
> = gql`
  mutation UpdateClinicalDocument($input: [UpdateVaultItemInput!]!) {
    updateVaultItems(input: $input) {
      ...vaultItemFragment
    }
  }
  ${vaultItemFragment}
`;
