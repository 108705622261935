import React from 'react';

import styles from './Label.module.scss';

type Props = { active?: boolean; children?: any };

export function Label(props: Props) {
  const classes = [styles.label];

  if (props.active) {
    classes.push(styles.active);
  }

  return <div className={classes.join(' ')}>{props.children}</div>;
}
