import { MenuItem } from '@mui/material';
import { Links } from 'app/constants';
import styles from 'app/inbox/vertical-menu/styles/index.module.scss';
import { metricsRoute, TopLevelRoutes } from 'app/top-nav/Routes';
import { TransientFeatureFlag, useFeatureFlags } from 'hooks/useFeatureFlags';
import React from 'react';

type Props = {
  redirectTo: (path: string) => void;
  clinicianId: string | null;
};

export function ClinicianMenuItems({
  redirectTo,
  clinicianId,
}: Props): JSX.Element[] {
  const featureFlags = useFeatureFlags();
  const isCareMetricsEnabled =
    featureFlags.transientFeatureFlags[
      TransientFeatureFlag.ENABLE_CARE_METRICS
    ];
  const menuItems = [];
  const openLinkInNewTab = (link: string) => window.open(link, '_blank');

  if (isCareMetricsEnabled && clinicianId) {
    menuItems.push(
      <MenuItem
        className={styles.menuItem}
        key="careMetrics"
        data-testid="careMetrics"
        onClick={() => redirectTo(metricsRoute(clinicianId))}
      >
        Care Metrics
      </MenuItem>,
    );
  }

  menuItems.push(
    ...[
      <MenuItem
        key="resources"
        onClick={() => openLinkInNewTab(Links.CLINICIAN_SITE)}
      >
        Resources
      </MenuItem>,
      <MenuItem
        key="settings"
        data-testid="settings"
        onClick={() => redirectTo(TopLevelRoutes.SETTINGS)}
      >
        Settings
      </MenuItem>,
    ],
  );

  return menuItems;
}
