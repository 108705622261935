import gql from 'graphql-tag';

export const getAppointmentsAndNotesQuery = gql`
  query GetClinicalAppointmentsAndNotesForMe(
    $input: GetClinicalAppointmentsForMeInput!
  ) {
    getClinicalAppointmentsForMe(input: $input) {
      appointments {
        id
        drchronoId
        member {
          id
          firstName
          lastName
        }
        clinician {
          id
          user {
            id
          }
          fullName
          role
        }
        start
        end
        appointmentStatus
        cancelledReason
        type
        zoomMeetingId
        clinicalNote {
          id
          status
          location
          noteType
          statusUpdatedAt
        }
      }
    }
    getAuthenticatedClinician {
      id
    }
  }
`;

export const getLatestCompleteClinicalAppointmentQuery = gql`
  query GetLatestCompleteClinicalAppointmentQuery(
    $input: GetLatestCompleteClinicalAppointmentInput!
  ) {
    getLatestCompleteAppointment(input: $input) {
      appointment {
        id
        drchronoId
        clinician {
          id
          user {
            id
          }
          fullName
          role
        }
        member {
          id
          firstName
          lastName
        }
        start
        end
        appointmentStatus
        cancelledReason
        type
        zoomMeetingId
        clinicalNote {
          id
          status
          noteType
          location
          statusUpdatedAt
        }
      }
    }
  }
`;
