// Type: Component File

import { NetworkStatus, useQuery } from '@apollo/client';
import styles from 'app/care-team/care-team-v2/CareTeamV2.module.scss';
import {
  GetEligibleSiCoachesStatusForMember,
  GetEligibleSiCoachesStatusForMemberVariables,
} from 'app/care-team/generated/GetEligibleSiCoachesStatusForMember';
import { getEligibleSiCoachesStatusForMember } from 'app/care-team/queries';
import { Button } from 'shared-components/button/Button';
import { isGraphQLAuthenticationError } from 'shared-components/error-state/utils';
import Tooltip from 'shared-components/tooltip/Tooltip';
import { addSICoachButtonStatusChanged } from 'app/state/amplitude/actions/careTeam';
import { CoachShiftStatusEnum } from 'generated/globalTypes';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const OFFLINE_INDICATOR_STATUSES = new Set([
  CoachShiftStatusEnum.ADMIN,
  CoachShiftStatusEnum.ALL_HANDS,
  CoachShiftStatusEnum.COACH_MEETING,
  CoachShiftStatusEnum.LUNCH,
  CoachShiftStatusEnum.MEETING,
  CoachShiftStatusEnum.NOTES,
  CoachShiftStatusEnum.OFFLINE,
  CoachShiftStatusEnum.ONE_ON_ONE,
  CoachShiftStatusEnum.OTHER_MEETING,
  CoachShiftStatusEnum.PROCESS_GROUP,
  CoachShiftStatusEnum.REPLY_ONLY,
  CoachShiftStatusEnum.REST_BREAK,
  CoachShiftStatusEnum.SHIFT_LEAD_MEETING,
  CoachShiftStatusEnum.SNAPS,
  CoachShiftStatusEnum.WRAP_UP,
]);

export const ALL_SI_COACHES_UNAVAILABLE_MESSAGE =
  'No SI coaches are available at the moment.';
export const ALL_SI_COACHES_AT_PEAK_MESSAGE =
  'All SI coaches are at maximum capacity at the moment.';

type AddSICoachButtonProps = {
  onClick: () => void;
  memberId: string;
};

export enum SICoachAvailability {
  UNAVAILABLE,
  AVAILABLE,
}

type AddSICoachButtonState = {
  availability?: SICoachAvailability;
  unavailableReason?: string;
};

const AddSICoachButtonIndicator = ({ availability }: AddSICoachButtonState) => {
  switch (availability) {
    case SICoachAvailability.AVAILABLE:
      return <div className={styles.careTeamButtonSICoachIndicatorOnline} />;
    case SICoachAvailability.UNAVAILABLE:
      return <div className={styles.careTeamButtonSICoachIndicatorOffline} />;
    default:
      return <div className={styles.careTeamButtonSICoachIndicatorReadOnly} />;
  }
};

export const AddSICoachButton = (props: AddSICoachButtonProps) => {
  const { onClick, memberId } = props;

  const [siCoachButtonState, setSiCoachButtonState] = useState<
    AddSICoachButtonState
  >({});

  const dispatch = useDispatch();

  const { loading, error, data, networkStatus, stopPolling } = useQuery<
    GetEligibleSiCoachesStatusForMember,
    GetEligibleSiCoachesStatusForMemberVariables
  >(getEligibleSiCoachesStatusForMember, {
    notifyOnNetworkStatusChange: true,
    pollInterval: 60 * 1000,
    variables: { input: { memberId } },
  });

  const getCoachesStatus = (): AddSICoachButtonState => {
    if (loading || networkStatus === NetworkStatus.refetch || error || !data) {
      return { ...siCoachButtonState };
    }

    const SIcoaches = data.getEligibleSiCoachesForMember?.coaches || [];

    if (
      SIcoaches.every((coach) =>
        OFFLINE_INDICATOR_STATUSES.has(
          coach?.state?.shiftStatus as CoachShiftStatusEnum,
        ),
      )
    ) {
      return {
        availability: SICoachAvailability.UNAVAILABLE,
        unavailableReason: ALL_SI_COACHES_UNAVAILABLE_MESSAGE,
      };
    }

    const onlineSICoaches = SIcoaches.filter(
      (coach) => coach?.state?.shiftStatus === CoachShiftStatusEnum.ONLINE,
    );
    if (onlineSICoaches.every((coach) => coach?.state?.atPeak)) {
      return {
        availability: SICoachAvailability.UNAVAILABLE,
        unavailableReason: ALL_SI_COACHES_AT_PEAK_MESSAGE,
      };
    }

    return { availability: SICoachAvailability.AVAILABLE };
  };

  useEffect(() => {
    const newStatus = getCoachesStatus();
    setSiCoachButtonState(newStatus);
    if (
      siCoachButtonState.availability !== newStatus.availability ||
      siCoachButtonState.unavailableReason !== newStatus.unavailableReason
    ) {
      dispatch(
        addSICoachButtonStatusChanged({
          current_reason: newStatus.unavailableReason,
          current_status: newStatus.availability,
          memberId,
          previous_reason: siCoachButtonState.unavailableReason,
          previous_status: siCoachButtonState.availability,
        }),
      );
    }
    if (isGraphQLAuthenticationError(error)) {
      stopPolling();
    }
  }, [loading, error, data, networkStatus, memberId]);

  return (
    <Tooltip
      title={siCoachButtonState.unavailableReason}
      placement="bottom-start"
    >
      <div
        className={styles.fullWidth}
        data-testid="careTeamAddSICoachButtonContainer"
      >
        <Button
          testId="careTeamAddSICoachButton"
          onClick={onClick}
          disabled={
            siCoachButtonState.availability == SICoachAvailability.UNAVAILABLE
          }
        >
          Add SI Coach <AddSICoachButtonIndicator {...siCoachButtonState} />
        </Button>
      </div>
    </Tooltip>
  );
};
