import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { List, ListItem, ListItemButton } from '@mui/material';
import styles from 'app/member-chart-cards/prescriptions/Pharmacy.module.scss';
import { PharmacyCard } from 'app/member-chart-cards/prescriptions/PharmacyCard';
import { Pharmacy } from 'app/member-chart-cards/prescriptions/PrescriptionCardTypes';
import React, { useState } from 'react';

interface UpdatePharmacyProps {
  pharmacies: (Pharmacy | null)[];
  onBack: () => void;
  onSelect: (pharmacyId: string) => void;
}

export const UpdatePharmacy = (props: UpdatePharmacyProps) => {
  const { onBack, onSelect, pharmacies } = props;
  const [selectedPharmacy, setSelectedPharmacy] = useState('');

  const onSelectClick = async (pharmacyId?: string) => {
    if (pharmacyId) {
      setSelectedPharmacy(pharmacyId);
      onSelect(pharmacyId);
    }
  };

  return (
    <div data-testid="updatePharmacyCard" className={styles.root}>
      <button className={styles.backButton} onClick={onBack}>
        <ArrowBackIcon /> Edit search
      </button>
      <div className={styles.helpText}>
        <h4>Pharmacy Results</h4>
      </div>

      {pharmacies.length > 0 ? (
        <List>
          {pharmacies.map((pharmacy, idx) => {
            return (
              <ListItem key={pharmacy?.id}>
                <ListItemButton
                  selected={selectedPharmacy === pharmacy?.id}
                  onClick={() => onSelectClick(pharmacy?.id)}
                >
                  <PharmacyCard
                    data-testid={`pharmacy-${idx}`}
                    pharmacy={pharmacy}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <p>No pharmacy results</p>
      )}
    </div>
  );
};
