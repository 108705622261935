import {
  ChiefComplaint,
  ChiefComplaint_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/ChiefComplaint';
import { MedicalHistory_Version } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MedicalHistory';
import {
  MedicalReviewOfSystem,
  MedicalReviewOfSystem_Constitutional as Constitutional,
  MedicalReviewOfSystem_Endocrine as Endocrine,
  MedicalReviewOfSystem_Musculoskeletal as Musculoskeletal,
  MedicalReviewOfSystem_Neurological as Neurological,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MedicalROS';
import {
  MentalStatusExam_Association,
  MentalStatusExam_Orientation,
  MentalStatusExam_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MentalStatusExam';
import { PsychiatricHistory_Version } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/PsychiatricHistory';
import {
  PsychReviewOfSystem_Depression as Depression,
  PsychReviewOfSystem_EatingDisorder as EatingDisorder,
  PsychReviewOfSystem_Hypomania as Hypomania,
  PsychReviewOfSystem_OCD as OCD,
  PsychReviewOfSystem_Panic as Panic,
  PsychReviewOfSystem_Psychosis as Psychosis,
  PsychReviewOfSystem_PTSD as PTSD,
  PsychReviewOfSystem_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/PsychROS';
import {
  SocialHistory_ChildHoodAbuse,
  SocialHistory_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/SocialHistory';
import { Affect_Scope } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Affect';
import { Insight } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Insight';
import { Judgment } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Judgment';
import { Mood } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Mood';
import { Speech } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Speech';
import { ThoughtContent } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/ThoughtContent';
import { ThoughtProcess } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/ThoughtProcess';
import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import {
  ClinicianIntakeChecklist_Version,
  Location,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/ClinicianIntakeChecklist';
import { PsychiatrySectionName } from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import Labels from 'app/notes-ui/strings/en.json';
import { ILogger } from 'app/state/log/Logger';
import { labelFromEnumValue } from 'utils/notes';
import { PsychiatryIntakeNote } from 'app/vault/api/PsychiatryIntakeNotesAPI';
import { PsychiatryProgressNote } from 'app/vault/api/PsychiatryProgressNotesAPI';

import { EnumOption } from '../types';
import { ClinicalNotePDF } from './ClinicalNotePDF';
import { PDFLabels } from './constants';
import { NotePDFBuilder, NotePDFBuilderImpl } from './NotePDFBuilder';
import { NoteHeader } from './types';

export abstract class PsychiatryNotePDF<
  T extends PsychiatryProgressNote | PsychiatryIntakeNote
> extends ClinicalNotePDF<T> {
  protected constructor(
    protected note: T,
    protected noteHeader: NoteHeader,
    protected timezone: string,
    protected diagnosisCodesOptions: EnumOption[],
    protected logger: ILogger,
    protected pdfBuilder: NotePDFBuilder = new NotePDFBuilderImpl(),
  ) {
    super(
      note,
      noteHeader,
      timezone,
      diagnosisCodesOptions,
      logger,
      pdfBuilder,
    );
  }

  protected clinicianChecklist() {
    this.pdfBuilder.section(
      PsychiatrySectionName.CLINICIAN_CHECKLIST,
      () => {
        const checklist = this.note.CLINICIAN_CHECKLIST;

        if (!checklist) return false;

        if (checklist.version === ClinicianIntakeChecklist_Version.v0) {
          const physicallyLocatedAtApptAddress =
            labelFromEnumValue(
              Location,
              checklist.physicallyLocatedAtApptAddress,
              {
                located_at_appt_address: 'Yes',
                located_outside_appt_address: 'No',
              },
            ) ?? 'No physical location provided';

          this.pdfBuilder
            .padding()
            .shortTextAnswer(
              Labels.MEMBER_IS_PHYSICALLY_LOCATED_AT_ADDRESS_ON_FILE_FOR_THIS_SESSION,
              physicallyLocatedAtApptAddress,
            )
            .padding()
            .longTextAnswer(Labels.CURRENT_LOCATION, checklist.currentLocation)
            .padding()
            .yesOrNoAnswer(
              Labels.CLINICIAN_REVIEWED_INFORMED_CONSENT,
              checklist.introducedCoachingToMember,
            )
            .padding();
        } else {
          this.pdfBuilder
            .yesOrNoAnswer(
              Labels.INTRO_COACHING_TO_MEMBER_INTAKE,
              checklist.introducedCoachingToMember,
            )
            .yesOrNoAnswer(
              Labels.MEMBER_SAID_YES_TO_THOUGHTS_OF_HARM,
              checklist.memberSaidYesToThoughtsOfHarm,
            )
            .yesOrNoAnswer(
              Labels.REVIEWED_INFORMED_CONSENT,
              checklist.reviewedInformedConsentWithMember,
            )
            .yesOrNoAnswer(
              Labels.REVIEWED_INTAKE_BEFORE_MEETING,
              checklist.reviewedIntakeBeforeMeeting,
            );
        }

        return true;
      },
      'No clinician checklist',
    );
  }

  protected chiefComplaint() {
    this.pdfBuilder.section(
      PsychiatrySectionName.CHIEF_COMPLAINT,
      () => {
        const section = this.note.CHIEF_COMPLAINT;

        if (!section) return false;

        const isV0 = (section: ChiefComplaint) => {
          return (
            section.version === ChiefComplaint_Version.v0 ||
            !!section?.relevantPhysicalHealthUpdates
          );
        };

        if (isV0(section)) {
          this.pdfBuilder
            .longTextAnswer(Labels.CHIEF_COMPLAINTS, section.chiefComplaint)
            .padding()
            .longTextAnswer(
              Labels.HISTORY_OF_PRESENT_ILLNESS,
              section.historyOfPresentIllness,
            )
            .padding()
            .longTextAnswer(
              Labels.RELEVANT_PHYSICAL_HEALTH_FINDINGS,
              section.relevantPhysicalHealthUpdates,
            )
            .padding();
        } else {
          this.pdfBuilder
            .longTextAnswer(Labels.CHIEF_COMPLAINTS, section.chiefComplaint)
            .padding()
            .longTextAnswer(
              Labels.HISTORY_OF_PRESENT_ILLNESS,
              section.historyOfPresentIllness,
            );
        }

        return true;
      },
      'No chief complaint',
    );
  }

  protected psychiatryReviewOfSystem() {
    this.pdfBuilder.section(
      PsychiatrySectionName.PSYCH_ROS,
      () => {
        const section = this.note.PSYCH_REVIEW_OF_SYSTEM;

        if (!section) return false;

        const isV0 =
          section.version === PsychReviewOfSystem_Version.v0 ||
          !!(section?.eatingDisorder || []).length ||
          !!section?.comments;

        if (isV0) {
          this.pdfBuilder
            .enumSelectionsGroup(
              Depression,
              section.depression,
              Labels.DEPRESSION,
              'Not endorsing depressive symptoms',
              PDFLabels.depressionLabels,
            )
            .padding()
            .enumSelectionsGroup(
              Panic,
              section.panic,
              Labels.PANIC,
              'Not endorsing anxiety / panic symptoms',
              PDFLabels.depressionLabels,
            )
            .padding()
            .enumSelectionsGroup(
              Hypomania,
              section.hypomania,
              Labels.HYPOMANIA,
              'Not endorsing symptoms of mania',
              PDFLabels.hypomaniaLabels,
            )
            .padding()
            .enumSelectionsGroup(
              Psychosis,
              section.psychosis,
              Labels.PSYCHOSIS,
              'Not endorsing symptoms of psychosis',
              PDFLabels.depressionLabels,
            )
            .padding()
            .enumSelectionsGroup(
              OCD,
              section.ocd,
              Labels.OCD,
              'Not endorsing symptoms of OCD',
              PDFLabels.depressionLabels,
            )
            .padding()
            .enumSelectionsGroup(
              EatingDisorder,
              section.eatingDisorder,
              Labels.EATING_DISORDER_LABEL,
              'Not endorsing eating disorder symptoms',
              PDFLabels.depressionLabels,
            )
            .padding()
            .enumSelectionsGroup(
              PTSD,
              section.ptsd,
              Labels.PTSD,
              'Not endorsing symptoms of PTSD',
              PDFLabels.depressionLabels,
            )
            .padding()
            .longTextAnswer(Labels.COMMENTS_ON_PSYCH_ROS, section.comments)
            .padding();
        } else {
          this.pdfBuilder
            .enumSelectionsGroup(
              Depression,
              section.depression,
              Labels.DEPRESSION,
              'No depression symptoms',
            )
            .padding()
            .enumSelectionsGroup(
              Panic,
              section.panic,
              Labels.PANIC,
              'No anxiety or panic symptoms',
            )
            .padding()
            .enumSelectionsGroup(
              Hypomania,
              section.hypomania,
              Labels.HYPOMANIA,
              'No (hypo)mania symptoms',
            )
            .padding()
            .enumSelectionsGroup(
              Psychosis,
              section.psychosis,
              Labels.PSYCHOSIS,
              'No psychosis symptoms',
            )
            .padding()
            .enumSelectionsGroup(
              PTSD,
              section.ptsd,
              Labels.PTSD,
              'No PTSD symptoms',
            )
            .padding()
            .enumSelectionsGroup(
              OCD,
              section.ocd,
              Labels.OCD,
              'No OCD symptoms',
            );
        }

        return true;
      },
      'No psychiatry review of system',
    );
  }

  protected medicalReviewOfSystem() {
    this.pdfBuilder.section(
      PsychiatrySectionName.MEDICAL_ROS,
      () => {
        const section: MedicalReviewOfSystem | null = this.note
          .MEDICAL_REVIEW_OF_SYSTEM;

        if (!section) return false;

        // A new field version that was added on new template schema, helps in determining the labeling among notes versions
        // Reference : https://github.com/HeadspaceMeditation/ginger-vault-clients/blob/81c9d380be563338a373d480ed95fd62d3513953/protobuf-schemas/vault-clinical-notes/vault-clinical-notes/shared/ClinicianIntakeChecklist.proto#L14
        const isV0 =
          this.note.CLINICIAN_CHECKLIST?.version ===
          ClinicianIntakeChecklist_Version.v0;
        let constitutionalLabels = {};
        let endocrineLabels = {};
        let musculoskeletalLabels = {};
        let neurologicalLabels = {};
        if (isV0) {
          constitutionalLabels = PDFLabels.constitutionalLabels;
          endocrineLabels = PDFLabels.endocrineLabels;
          musculoskeletalLabels = PDFLabels.musculoskeletalLabels;
          neurologicalLabels = PDFLabels.neurologicalLabels;
        }

        this.pdfBuilder
          .enumSelectionsGroup(
            Constitutional,
            section.constitutional,
            Labels.CONSTITUTIONAL,
            'No constitutional symptoms',
            constitutionalLabels,
          )
          .longTextAnswer('', section?.constitutionalComment)
          .padding()
          .enumSelectionsGroup(
            Endocrine,
            section.endocrine,
            Labels.ENDOCRINE,
            'No endocrine symptoms',
            endocrineLabels,
          )
          .longTextAnswer('', section?.endocrineComment)
          .padding()
          .enumSelectionsGroup(
            Musculoskeletal,
            section.musculoskeletal,
            Labels.MUSCULOSKELETAL,
            'No musculoskeletal symptoms',
            musculoskeletalLabels,
          )
          .longTextAnswer('', section?.musculoskeletalComment)
          .padding()
          .enumSelectionsGroup(
            Neurological,
            section.neurological,
            Labels.NEUROLOGICAL,
            'No neurological symptoms',
            neurologicalLabels,
          )
          .longTextAnswer('', section?.neurologicalComment)
          .padding()
          .longTextAnswer(
            Labels.MEDICAL_REVIEW_OF_SYSTEM_COMMENT,
            section.comments,
          );

        return true;
      },
      'No medical review of system',
    );
  }

  protected psychiatricHistory() {
    this.pdfBuilder.section(
      PsychiatrySectionName.PSYCH_HISTORY,
      () => {
        const section = this.note.PSYCH_HISTORY;

        if (!section) return false;

        const isV0 =
          section.version === PsychiatricHistory_Version.v0 ||
          !!section.pastTreatments?.outpatientTreatment ||
          !!section.pastTreatments?.inpatientTreatment ||
          !!section?.pastSuicidalIdeationEpisode ||
          !!section?.pastSelfHarmEpisode;

        if (isV0) {
          this.pdfBuilder
            .longTextAnswer(
              Labels.PAST_OUTPATIENT_TREATMENT,
              section.pastOutpatientTreatment,
            )
            .padding()
            .yesOrNoAnswersGroup(
              Labels.PAST_PSYCHIATRIC_TREATMENT_DESCRIPTION,
              [
                {
                  label: Labels.PAST_OUTPATIENT_TREATMENT_DESCRIPTION,
                  value: section.pastTreatments?.outpatientTreatment,
                },
                {
                  label: Labels.PAST_INPATIENT_HOSPITALIZATION_DESCRIPTION,
                  value: section.pastTreatments?.inpatientTreatment,
                },
              ],
              'Not endorsing past outpatient/inpatient treatment',
            )
            .padding()
            .table(
              Labels.PAST_PSYCHOTROPIC_MEDICATIONS_RESPONSES,
              {
                comments: 'Comments',
                medication: 'Drug Name',
              },
              section.pastPsychotropicMedicationsResponses,
              'No known medications',
            )
            .padding()
            .table(
              Labels.PAST_SUICIDE_ATTEMPT,
              {
                comments: 'Comment',
                timeframe: 'When & how',
              },
              section.pastSuicideAttempt,
              'No known suicide attempts',
            )
            .padding()
            .longTextAnswer(
              Labels.PAST_SUICIDE_IDEATION,
              section.pastSuicidalIdeationEpisode,
            )
            .padding()
            .longTextAnswer(
              Labels.PAST_SELF_HARM_EPISODES,
              section.pastSelfHarmEpisode,
            )
            .padding();
        } else {
          this.pdfBuilder
            .longTextAnswer(
              Labels.PAST_OUTPATIENT_TREATMENT,
              section.pastOutpatientTreatment,
            )
            .padding()
            .table(
              Labels.PAST_PSYCHOTROPIC_MEDICATIONS_RESPONSES,
              {
                adverseReaction: 'Adverse Reaction',
                clinicalResponse: 'Clinical Response',
                dosage: 'Dosage (mg/day)',
                duration: 'Duration (year)',
                medication: 'Medication',
              },
              section.pastPsychotropicMedicationsResponses,
              'No known medications',
            )
            .padding()
            .table(
              Labels.PAST_SUICIDE_ATTEMPT,
              {
                clinicalIntervention: 'Clinical Intervention',
                comments: 'Comment',
                method: 'Method',
                timeframe: 'Month/Year',
              },
              section.pastSuicideAttempt,
              'No known suicide attempts',
            )
            .padding()
            .longTextAnswer(
              Labels.PAST_INPATIENT_HOSPITALIZATION,
              section.pastInpatientHospitalization,
            )
            .padding()
            .longTextAnswer(
              Labels.PAST_SUBSTANCE_USE_TREATMENT,
              section.pastSubstanceUseTreatment,
            )
            .padding()
            .longTextAnswer(
              Labels.HISTORY_OF_COMPLICATED_ETOH_OR_BENZO_WITHDRAWAL,
              section.historyOfComplicatedEtohOrBenzoWithdrawal,
            );
        }
        return true;
      },
      'No psychiatric history',
    );
  }

  protected allergies() {
    this.pdfBuilder.section(
      PsychiatrySectionName.ALLERGIES,
      () => {
        const section = this.note.ALLERGIES;
        if (section) {
          this.pdfBuilder.table(
            '',
            {
              adverseReaction: 'Adverse Reaction',
              comments: 'Comments',
              medication: 'Medication',
            },
            section.allergies,
            'No known allergies',
          );
          return true;
        }
        return false;
      },
      'No allergies',
    );
  }

  protected familyHistory() {
    this.pdfBuilder.section(
      PsychiatrySectionName.FAMILY_HISTORY,
      () => {
        const section = this.note.FAMILY_HISTORY;
        if (section) {
          this.pdfBuilder.longTextAnswer('', section.comments);
          return true;
        }
        return false;
      },
      'No family history',
    );
  }

  protected medicalHistory() {
    this.pdfBuilder.section(
      PsychiatrySectionName.MEDICAL_HISTORY,
      () => {
        const section = this.note.MEDICAL_HISTORY;

        if (!section) return false;

        const isV0 =
          section.version === MedicalHistory_Version.v0 ||
          !!section?.nonPsychiatricCurrentMedicines ||
          (section?.allergies || []).length;

        if (isV0) {
          this.pdfBuilder
            .longTextAnswer(Labels.MEDICAL_HISTORY_COMMENTS, section.comments)
            .padding()
            .longTextAnswer(
              Labels.NON_PSYCHIATRIC_CURRENT_MEDICINES,
              section.nonPsychiatricCurrentMedicines,
            )
            .padding()
            .subsection(Labels.PAST_MEDICAL_EXPERIENCES, () => {
              this.pdfBuilder
                .yesOrNoAnswer(Labels.SEIZURES, section.seizures?.isPresent)
                .padding()
                .yesOrNoAnswer(
                  Labels.TRAUMATIC_BRAIN_INJURY_V2,
                  section.traumaticBrainInjury?.isPresent,
                )
                .padding()
                .yesOrNoAnswer(
                  Labels.OBSTRUCTIVE_SLEEP_APNEA,
                  section.obstructiveSleepApnea?.isPresent,
                )
                .padding()
                .yesOrNoAnswer(
                  Labels.THYROID_DISEASE,
                  section.thyroidDisease?.isPresent,
                )
                .padding()
                .yesOrNoAnswer(
                  Labels.HEART_DISEASE,
                  section.heartDisease?.isPresent,
                )
                .padding()
                .longTextAnswer(Labels.OTHER_COMMENT, section.comments);
            })
            .subsection(Labels.ALLERGIES, () => {
              section.allergies?.forEach((allergy) => {
                this.pdfBuilder
                  .shortTextAnswer(Labels.DRUG_NAME, allergy.medication)
                  .shortTextAnswer(Labels.DRUG_COMMENTS, allergy.comments)
                  .padding();
              });
            });
        } else {
          this.pdfBuilder
            .yesOrNoWithCommentAnswer(
              Labels.SEIZURES,
              section.seizures,
              'No seizures',
            )
            .padding()
            .yesOrNoWithCommentAnswer(
              Labels.TRAUMATIC_BRAIN_INJURY,
              section.traumaticBrainInjury,
              'No traumatic brain injury',
            )
            .padding()
            .yesOrNoWithCommentAnswer(
              Labels.OBSTRUCTIVE_SLEEP_APNEA,
              section.obstructiveSleepApnea,
              'No seizures',
            )
            .padding()
            .yesOrNoWithCommentAnswer(
              Labels.THYROID_DISEASE,
              section.thyroidDisease,
              'No seizures',
            )
            .padding()
            .yesOrNoWithCommentAnswer(
              Labels.HEART_DISEASE,
              section.heartDisease,
              'No seizures',
            )
            .padding()
            .longTextAnswer(Labels.MEDICAL_HISTORY_COMMENT, section.comments);
        }

        return true;
      },
      'No medical history',
    );
  }

  protected socialHistory() {
    const sectionLabel = PsychiatrySectionName.SOCIAL_HISTORY;

    this.pdfBuilder.section(
      sectionLabel,
      () => {
        const section = this.note.SOCIAL_HISTORY;

        if (!section) return false;

        const isV0 =
          section.version === SocialHistory_Version.v0 ||
          (section?.childAbuseExperiences || []).length ||
          !!section?.memberHadLegalStressor ||
          !!section.family?.additionalSocialFamilyHistoryComments ||
          !!section.family?.history;

        if (isV0) {
          this.pdfBuilder
            .longTextAnswer(
              Labels.FAMILY_HISTORY,
              section.family?.history ?? 'No description',
            )
            .padding()
            .longTextAnswer(
              Labels.DESCRIBES_CHILDHOOD_AS,
              section.childhood?.describesChildhoodAs ?? 'No description',
            )
            .padding()
            .longTextAnswer(
              Labels.DEVELOPMENTAL_HISTORY,
              section.childhood?.developmentalHistory ?? 'No description',
            )
            .padding()
            .enumSelectionsGroup(
              SocialHistory_ChildHoodAbuse,
              section.childAbuseExperiences,
              Labels.CHILDHOOD_ABUSE_EXPERIENCES,
              'N/A',
            )
            .padding()
            .yesOrNoWithCommentAnswer(
              Labels.HISTORY_OF_DOMESTIC_VIOLENCE_V2,
              section.historyOfDomesticViolence,
              'N/A',
            )
            .padding()
            .longTextAnswer(
              Labels.CULTURAL_CONSIDERATIONS,
              section.culturalConsiderations,
            )
            .padding()
            .longTextAnswer(Labels.FAMILY_SUPPORT, section.familySupport)
            .padding()
            .longTextAnswer(Labels.LIVING_SITUATION, section.livingSituation)
            .padding()
            .longTextAnswer(Labels.EDUCATION, section.education)
            .padding()
            .longTextAnswer(Labels.EMPLOYMENT, section.employment)
            .padding()
            .yesOrNoAnswer(
              Labels.ACCESS_TO_GUNS,
              section.accessToGuns?.isPresent,
            )
            .longTextAnswer('', section.accessToGuns?.description)
            .padding()
            .yesOrNoAnswer(
              Labels.LEGAL_STRESSORS_DESCRIPTION,
              section.memberHadLegalStressor,
            )
            .longTextAnswer('', section.legalStressor)
            .padding()
            .longTextAnswer(
              Labels.SOCIAL_FAMILY_HISTORY_COMMENTS,
              section.family?.additionalSocialFamilyHistoryComments,
            )
            .padding();
        } else {
          this.pdfBuilder
            .longTextAnswer(
              Labels.GREW_UP_IN,
              section.childhood?.grewUpIn ?? 'No description',
            )
            .padding()
            .longTextAnswer(
              Labels.DESCRIBES_CHILDHOOD_AS,
              section.childhood?.describesChildhoodAs ?? 'No description',
            )
            .padding()
            .yesOrNoAnswersGroup(
              Labels.CHILDHOOD_ABUSE,
              [
                {
                  label: Labels.CHILDHOOD_ABUSE_PHYSICAL,
                  value: section.childhood?.childAbuse?.physical,
                },
                {
                  label: Labels.CHILDHOOD_ABUSE_EMOTIONAL,
                  value: section.childhood?.childAbuse?.emotional,
                },
                {
                  label: Labels.CHILDHOOD_ABUSE_SEXUAL,
                  value: section.childhood?.childAbuse?.sexual,
                },
              ],
              'No childhood abuse',
            )
            .longTextAnswer(
              Labels.MEDICAL_HISTORY_COMMENT,
              section.childhood?.childAbuse?.comments,
            )
            .padding()
            .longTextAnswer(
              Labels.DEVELOPMENTAL_HISTORY,
              section.childhood?.developmentalHistory ?? '',
            )
            .padding()
            .longTextAnswer(Labels.FAMILY_SUPPORT, section.familySupport)
            .padding()
            .yesOrNoWithCommentAnswer(
              Labels.HISTORY_OF_DOMESTIC_VIOLENCE,
              section.historyOfDomesticViolence,
              'No history of domestic violence',
            )
            .padding()
            .longTextAnswer(Labels.LIVING_SITUATION, section.livingSituation)
            .padding()
            .longTextAnswer(Labels.EDUCATION, section.education)
            .padding()
            .longTextAnswer(Labels.EMPLOYMENT, section.employment)
            .padding()
            .longTextAnswer(
              Labels.CULTURAL_CONSIDERATIONS,
              section.culturalConsiderations,
            )
            .padding()
            .longTextAnswer(Labels.LEGAL_STRESSORS, section.legalStressor)
            .padding()
            .yesOrNoWithCommentAnswer(
              Labels.ACCESS_TO_GUNS,
              section.accessToGuns,
              'Has no access to guns',
            );
        }
        return true;
      },
      'No social history',
    );
  }

  protected mentalStatusExam() {
    this.pdfBuilder.section(
      PsychiatrySectionName.MENTAL_STATUS_EXAM,
      () => {
        const section = this.note.MENTAL_STATUS_EXAM;
        if (!section) return false;

        const isV0 =
          section.version === MentalStatusExam_Version.v0 ||
          !!section?.appearance?.effectWithinNormalLimits ||
          !!section?.appearance?.other?.isPresent ||
          !!section?.speechStatus?.effectWithinNormalLimits ||
          !!section?.speechStatus?.other?.isPresent ||
          !!section?.thoughtProcessStatus?.effectWithinNormalLimits ||
          !!section?.thoughtProcessStatus?.other?.isPresent ||
          !!section?.thoughtContentStatus?.effectWithinNormalLimits ||
          !!section?.thoughtContentStatus?.other?.isPresent ||
          !!section?.affect?.effectWithinNormalLimits ||
          !!section?.affect?.other?.isPresent ||
          !!section?.orientationStatus?.effectWithinNormalLimits ||
          !!section?.orientationStatus?.other?.isPresent ||
          !!section?.insightStatus?.effectWithinNormalLimits ||
          !!section?.insightStatus?.other?.isPresent;

        if (isV0) {
          this.pdfBuilder
            .longTextAnswer(
              Labels.MOOD_AND_AFFECT_IN_PATIENT_WORD,
              section.moodAndAffect?.moodInPatientWord ?? '',
            )
            .padding()
            .subsection(Labels.APPEARANCE, () => {
              this.pdfBuilder
                .yesOrNoAnswer(
                  Labels.AFFECT_WITHIN_NORMAL_LIMITS,
                  section.appearance?.effectWithinNormalLimits,
                )
                .padding()
                .yesOrNoWithCommentAnswer(
                  Labels.APPEARANCE_OTHER,
                  section.appearance?.other,
                  'N/A',
                )
                .padding();
            })
            .subsection(Labels.SPEECH, () => {
              this.pdfBuilder
                .yesOrNoAnswer(
                  Labels.SPEECH_WITHIN_NORMAL_LIMITS,
                  section.speechStatus?.effectWithinNormalLimits,
                )
                .padding()
                .yesOrNoWithCommentAnswer(
                  Labels.SPEECH_OTHER,
                  section.speechStatus?.other,
                  'N/A',
                )
                .padding();
            })
            .subsection(Labels.THOUGHT_PROCESS, () => {
              this.pdfBuilder
                .yesOrNoAnswer(
                  Labels.THOUGHT_PROCESS_WITHIN_NORMAL_LIMITS,
                  section.thoughtProcessStatus?.effectWithinNormalLimits,
                )
                .padding()
                .yesOrNoWithCommentAnswer(
                  Labels.THOUGHT_PROCESS_OTHER,
                  section.thoughtProcessStatus?.other,
                  'N/A',
                )
                .padding();
            })
            .subsection(Labels.THOUGHT_CONTENT, () => {
              this.pdfBuilder
                .yesOrNoAnswer(
                  Labels.THOUGHT_CONTENT_WITHIN_NORMAL_LIMITS,
                  section.thoughtContentStatus?.effectWithinNormalLimits,
                )
                .padding()
                .yesOrNoWithCommentAnswer(
                  Labels.THOUGHT_CONTENT_OTHER,
                  section.thoughtContentStatus?.other,
                  'N/A',
                )
                .padding();
            })
            .subsection(Labels.AFFECT, () => {
              this.pdfBuilder
                .yesOrNoAnswer(
                  Labels.AFFECT_WITHIN_NORMAL_LIMITS_V2,
                  section.affect?.effectWithinNormalLimits,
                )
                .padding()
                .yesOrNoWithCommentAnswer(
                  Labels.AFFECT_OTHER,
                  section.affect?.other,
                  'N/A',
                )
                .padding();
            })
            .subsection(Labels.ORIENTATION, () => {
              this.pdfBuilder
                .yesOrNoAnswer(
                  Labels.ORIENTATION_WITHIN_NORMAL_LIMITS,
                  section.orientationStatus?.effectWithinNormalLimits,
                )
                .padding()
                .yesOrNoWithCommentAnswer(
                  Labels.ORIENTATION_OTHER,
                  section.orientationStatus?.other,
                  'N/A',
                )
                .padding();
            })
            .subsection(Labels.INSIGHT, () => {
              this.pdfBuilder
                .yesOrNoAnswer(
                  Labels.INSIGHT_WITHIN_NORMAL_LIMITS,
                  section.insightStatus?.effectWithinNormalLimits,
                )
                .padding()
                .yesOrNoWithCommentAnswer(
                  Labels.INSIGHT_OTHER,
                  section.insightStatus?.other,
                  'N/A',
                )
                .padding();
            })
            .subsection(Labels.JUDGMENT, () => {
              this.pdfBuilder
                .yesOrNoAnswer(
                  Labels.JUDGMENT_WITHIN_NORMAL_LIMITS,
                  section.judgement?.effectWithinNormalLimits,
                )
                .padding()
                .yesOrNoWithCommentAnswer(
                  Labels.JUDGMENT_OTHER,
                  section.judgement?.other,
                  'N/A',
                )
                .padding();
            })
            .subsection(Labels.COGNITIVE, () => {
              this.pdfBuilder
                .yesOrNoAnswer(
                  Labels.COGNITIVE_WITHIN_NORMAL_LIMITS,
                  section.cognitive?.effectWithinNormalLimits,
                )
                .padding()
                .yesOrNoWithCommentAnswer(
                  Labels.COGNITIVE_OTHER,
                  section.cognitive?.other,
                  'N/A',
                )
                .padding();
            });
        } else {
          this.pdfBuilder
            .enumSelectionsGroup(Speech, section.speech, Labels.SPEECH, 'N/A')
            .padding()
            .yesOrNoWithCommentAnswer(
              Labels.RECENT_AND_REMOTE_MEMORY,
              section.recentAndRemoteMemory,
              'N/A',
            )
            .padding()
            .yesOrNoWithCommentAnswer(
              Labels.ATTENTION_AND_CONCENTRATION,
              section.attentionAndConcentration,
              'N/A',
            )
            .padding()
            .yesOrNoWithCommentAnswer(Labels.LANGUAGE, section.language, 'N/A')
            .padding()
            .yesOrNoWithCommentAnswer(
              Labels.FUND_OF_KNOWLEDGE,
              section.fundOfKnowledge,
              'N/A',
            )
            .padding()
            .subsection('Mood and Affect', () => {
              this.pdfBuilder
                .longTextAnswer(
                  Labels.MOOD_AND_AFFECT_IN_PATIENT_WORD,
                  section.moodAndAffect?.moodInPatientWord ?? '',
                )
                .enumSelectionsGroup(
                  Mood,
                  section.moodAndAffect?.affectDescription ?? [],
                  Labels.AFFECT_DESCRIPTION,
                  'No affect',
                )
                .radioItemAnswer(
                  Affect_Scope,
                  Labels.RANGE_OF_AFFECT,
                  section.moodAndAffect?.affect?.scope,
                )
                .yesOrNoAnswer(
                  Labels.AFFECT_CONGRUENT_WITH_MOOD,
                  section.moodAndAffect?.affect?.congruentWithMood,
                )
                .yesOrNoAnswer(
                  Labels.AFFECT_CONGRUENT_WITH_CONTENT,
                  section.moodAndAffect?.affect?.congruentWithContent,
                );
            })
            .padding()
            .subsection('Thought Content and Thought Process', () => {
              this.pdfBuilder
                .enumSelectionsGroup(
                  ThoughtContent,
                  section.thoughtContent,
                  Labels.THOUGHT_CONTENT,
                  '',
                )
                .padding()
                .enumSelectionsGroup(
                  ThoughtProcess,
                  section.thoughtProcess,
                  Labels.THOUGHT_PROCESS,
                  '',
                );
            })
            .padding()
            .subsection('Orientation and Associations', () => {
              this.pdfBuilder
                .enumSelectionsGroup(
                  MentalStatusExam_Association,
                  section.associations,
                  Labels.ASSOCIATIONS,
                  '',
                )
                .padding()
                .enumSelectionsGroup(
                  MentalStatusExam_Orientation,
                  section.orientation,
                  Labels.ORIENTATION,
                  '',
                );
            })
            .padding()
            .subsection('Insight and Judgement', () => {
              this.pdfBuilder
                .radioItemAnswer(Insight, Labels.INSIGHT, section.insight)
                .padding()
                .radioItemAnswer(Judgment, Labels.JUDGMENT, section.judgment);
            });
        }
        return true;
      },
      'No mental status exam',
    );
  }

  protected treatmentPlan() {
    this.pdfBuilder.section(
      PsychiatrySectionName.TREATMENT_PLAN,
      () => {
        const section = this.note.TREATMENT_PLAN;

        if (!section) return false;

        // [CARE-3929]
        // We cannot check "section.version === TreatmentPlan_Version.v0" in this case because
        // of a bug that assigned "v0" to the TreatmentPlan section of older notes instead of
        // "version_undefined".
        // We can only rely on the presence of new fields: "goal" or "messageToCareTeam" to
        // determine the section's version.
        const isV0 =
          !!(section?.goal || []).length ||
          !!(section?.messageToCareTeam || '').length;

        if (isV0) {
          const goals = section?.goal.filter((_) => _.goalType > 0) ?? []; // 0 === undefined, -1 === unrecognized
          if (goals.length > 0) {
            goals.forEach((goal, index) => {
              this.treatmentGoal(goal, index + 1);
            });
          }
          this.pdfBuilder
            .longTextAnswer(
              Labels.MESSAGE_TO_CARE_TEAM,
              section.messageToCareTeam,
            )
            .padding()
            .longTextAnswer(
              Labels.MEDICATION_MANAGEMENT,
              section.medicationManagement,
            )
            .padding()
            .longTextAnswer(Labels.FOLLOW_UP, section.followUp)
            .padding()
            .yesOrNoAnswer(
              Labels.WAIT_TIME_EFFECT_PSYCHIATRY,
              section.waitTimeDetrimentalEffect,
            )
            .padding()
            .radioItemAnswer(
              BooleanOption,
              Labels.OFFER_AN_APPOINTMENT_PSYCHIATRY,
              section.appointmentOffered,
            )
            .padding()
            .radioItemAnswer(
              BooleanOption,
              Labels.APPOINTMENT_OFFER_ACCEPTED,
              section.offeredAppointmentAccepted,
            )
            .padding()
            .longTextAnswer(
              Labels.REFERRALS_TO_CARE_OUTSIDE,
              section.referralsToCareOutsideGingerNecessary,
            );
        } else {
          this.pdfBuilder
            .longTextAnswer(
              Labels.MEDICATION_MANAGEMENT,
              section.medicationManagement,
            )
            .padding()
            .longTextAnswer(Labels.FOLLOW_UP, section.followUp)
            .padding()
            .shortTextAnswer(
              Labels.NUMBER_OF_SESSIONS_ANTICIPATED,
              section.numberOfSessionsAnticipated.toString(),
            )
            .padding()
            .yesOrNoAnswer(
              Labels.WAIT_TIME_EFFECT_PSYCHIATRY,
              section.waitTimeDetrimentalEffect,
            )
            .padding()
            .radioItemAnswer(
              BooleanOption,
              Labels.OFFER_AN_APPOINTMENT_PSYCHIATRY,
              section.appointmentOffered,
            )
            .padding()
            .radioItemAnswer(
              BooleanOption,
              Labels.APPOINTMENT_OFFER_ACCEPTED,
              section.offeredAppointmentAccepted,
            )
            .padding()
            .longTextAnswer(
              Labels.REFERRALS_TO_CARE_OUTSIDE,
              section.referralsToCareOutsideGingerNecessary,
            );
        }
        return true;
      },
      'No treatment plan',
    );
  }
}
