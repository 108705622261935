import { SurveyTab } from './tabs/surveys/SurveysV3/utils';

export enum Routes {
  SUMMARY = 'summary',
  CONTENT = 'content',
  INTAKE = 'intake',
  SURVEYS = 'surveys',
  NOTES = 'notes',
  APPOINTMENTS = 'appointments',
  APPOINTMENTSSCHEDULE = 'appointmentsSchedule',
  CHAT_COLLABORATION = 'chat-collaboration',
  DEMOGRAPHICS = 'demographics',
  DOCUMENTS = 'documents',
  PRESCRIPTIONS = 'prescriptions',
  CARE_TEAM = 'team',
}

export function appointmentDetailRoute(
  prefix: string,
  patientId: string,
  appointmentId: string,
): string {
  const path = prefix.replace(':patientId?', patientId);
  return `${path}/${Routes.SUMMARY}?appointmentNote=${appointmentId}`;
}

export function contentRoute(prefix: string, patientId: string): string {
  const path = prefix.replace(':patientId?', patientId);
  return `${path}/${Routes.CONTENT}`;
}

export function summaryRoute(prefix: string, patientId: string): string {
  const path = prefix.replace(':patientId?', patientId);
  return `${path}/${Routes.SUMMARY}`;
}

export function intakeRoute(prefix: string, patientId: string): string {
  const path = prefix.replace(':patientId?', patientId);
  return `${path}/${Routes.INTAKE}`;
}
export function intakeRouteV2(
  prefix: string,
  patientId: string,
  selectedTab?: string,
): string {
  let path = `${prefix.replace(':patientId?', patientId)}/${Routes.INTAKE}`;
  if (selectedTab) path = `${path}/${selectedTab.toString()}`;
  return `${path}`;
}

export function surveysRoute(prefix: string, patientId: string): string {
  const path = prefix.replace(':patientId?', patientId);
  return `${path}/${Routes.SURVEYS}`;
}

export function surveysV3Route(
  prefix: string,
  patientId: string,
  selectedTab?: SurveyTab,
): string {
  let path = `${prefix.replace(':patientId?', patientId)}/${Routes.SURVEYS}`;
  if (selectedTab) path = `${path}/${selectedTab.toString()}`;
  return path;
}

export function appointmentsRoute(prefix: string, patientId: string): string {
  const path = prefix.replace(':patientId?', patientId);
  return `${path}/${Routes.APPOINTMENTS}`;
}

export function notesRoute(prefix: string, patientId: string): string {
  const path = prefix.replace(':patientId?', patientId);
  return `${path}/${Routes.NOTES}`;
}

export function appointmentsScheduleRoute(
  prefix: string,
  patientId: string,
): string {
  const path = prefix.replace(':patientId?', patientId);
  return `${path}/${Routes.APPOINTMENTSSCHEDULE}`;
}

export function chatCollaborationRoute(
  prefix: string,
  patientId: string,
): string {
  const path = prefix.replace(':patientId?', patientId);
  return `${path}/${Routes.CHAT_COLLABORATION}`;
}

export function demographicsRoute(prefix: string, patientId: string): string {
  const path = prefix.replace(':patientId?', patientId);
  return `${path}/${Routes.DEMOGRAPHICS}`;
}

export function documentsRoute(prefix: string, patientId: string): string {
  const path = prefix.replace(':patientId?', patientId);
  return `${path}/${Routes.DOCUMENTS}`;
}

export function prescriptionsRoute(
  prefix: string,
  patientId: string,
  showRefillsErrors: boolean = false,
): string {
  const path = prefix.replace(':patientId?', patientId);
  // RefillsErrors=1 is a parameter that must be passed to the Dosespot iframe url, redirecting clinician to notification summary page
  return `${path}/${Routes.PRESCRIPTIONS}${
    showRefillsErrors ? '?RefillsErrors=1' : ''
  }`;
}

export function careTeamRoute(prefix: string, patientId: string): string {
  const path = prefix.replace(':patientId?', patientId);
  return `${path}/${Routes.CARE_TEAM}`;
}
