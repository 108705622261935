export const Labels = {
  medicalHistory: {
    allergies: {
      comments_placeholder: 'Adverse reaction, etc...',
      id: 'medicalHistory_allergies',
      label: 'No known allergies',
      medication_placeholder: 'Drug name',
      title: 'Allergies',
    },
    checkBoxesTitle:
      'Does the member currently or have they in the past experienced any of the following?',
    comments: {
      id: 'medicalHistory_comments',
      label: 'Pertinent medical history',
      placeholder: 'Type here...',
      subtext: 'Medical conditions, surgeries, injuries ',
    },
    heartDisease: {
      id: 'medicalHistory_heartDisease',
      label: 'Heart / cardiovascular disease',
      placeholder: '(optional) Comment',
    },
    nonPsychiatricCurrentMedicines: {
      id: 'medicalHistory_nonPsychiatricCurrentMedicines',
      label: 'Non-psychiatric current medicines',
      placeholder: 'Type here...',
      subtext: 'Name, dose, indication',
    },
    notEndorsing: {
      id: 'medicalHistory_notEndorsing',
      label:
        'Not endorsing seizure, concussion, stroke, sleep apnea, thyroid and/or heart disease',
    },
    obstructiveSleepApnea: {
      id: 'medicalHistory_obstructiveSleepApnea',
      label: 'Obstructive Sleep Apnea (OSA)',
    },
    seizures: {
      id: 'medicalHistory_seizures',
      label: 'Seizures',
    },
    thyroidDisease: {
      id: 'medicalHistory_thyroidDisease',
      label: 'Thyroid Disease',
    },
    traumaticBrainInjury: {
      id: 'medicalHistory_traumaticBrainInjury',
      label: 'TBI / LOC',
    },
  },
};
