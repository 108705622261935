import { CoachNotesItem } from 'app/coach/coach-notes/CoachNotesTypes';
import { riskAssessmentsFieldDefs } from 'app/coach/coach-notes/note-detail-view/note-templates/fieldDefs';
import { RisksForm } from 'app/coach/coach-notes/note-detail-view/risk-templates/RisksForm';
import { RiskAssessmentWithoutRisks } from 'app/coach/coach-notes/types';
import { useCoachNotesForm } from 'app/coach/coach-notes/useCoachNotesForm';
import React from 'react';

type Props = {
  readOnly: boolean;
  onStateChange: (noteDraft: CoachNotesItem) => void;
  'data-testid'?: string;
};
export const RiskAssessment = (
  props: Readonly<Props>,
): React.ReactElement | null => {
  const { readOnly, onStateChange, 'data-testid': testId } = props;
  const { onChangeRisksForm, risks } = useCoachNotesForm<
    RiskAssessmentWithoutRisks
  >({
    canContainNestedRisks: true,
    nestedRisksFieldName: 'risks',
    onStateChange,
    validationSchema: riskAssessmentsFieldDefs,
  });

  return (
    <RisksForm
      risks={risks}
      onChange={onChangeRisksForm}
      readOnly={readOnly}
      data-testid={testId}
    />
  );
};
