import { noteTabViewed } from 'app/state/amplitude/actions/notes';
import { useOnMount } from 'hooks/useOnMount';
import React from 'react';
import { useDispatch } from 'react-redux';

import { NewNoteMenu } from './NewNoteMenu';
import { NotesTab, Props } from './NotesTab';
import styles from './NotesTab.module.scss';

export function NotesTabContainer(props: Props) {
  const dispatch = useDispatch();
  const { patientId } = props;
  useOnMount(() => {
    dispatch(noteTabViewed({ patientId }));
  });

  return (
    <div className={styles.root}>
      <header data-testid="appointmentTabHeader" className={styles.header}>
        <NewNoteMenu patientId={patientId} navigateTo={props.navigateTo} />
      </header>
      <NotesTab
        signedLockedAction={props.signedLockedAction}
        patientId={patientId}
        navigateTo={props.navigateTo}
      />
    </div>
  );
}
