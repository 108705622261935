import { TimeDivider } from 'app/coach/chat/messages-section/TimeDivider';
import { CommunicationEmptyState } from 'app/collaboration/v2/CommunicationEmptyState';
import { isNull } from 'lodash';
import moment from 'moment';
import React, { useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from 'shared-components/loader/Loader';

import { AuthorWithName, ChatMessage } from '../type';
import styles from './CommunicationList.module.scss';
import { CommunicationScreen } from './communications/CommunicationScreen';

export interface Props {
  chats: ChatMessage[];
  author: AuthorWithName;
  loadMoreChatMessage: (cursor: string | null) => Promise<void>;
  cursor: string | null;
}

interface CommunicationListProps {
  chats: ChatMessage[];
  author: AuthorWithName;
}
const CommunicationListContent = ({
  chats,
  author,
}: CommunicationListProps) => {
  const dateRef = useRef<Date | null>(null);
  const renderCommunicationMessage = (message: ChatMessage) => {
    const dateObj = new Date(message.createdAt);
    const isTimeDividerNeeded = dateObj.getDay() !== dateRef.current?.getDay();
    dateRef.current = dateObj;
    const formattedString = moment(message.createdAt).format(
      'ddd, MMM DD, YYYY',
    );

    return (
      <div className={styles.messagesContainer} key={message.createdAt}>
        {isTimeDividerNeeded && <TimeDivider dateAndTime={formattedString} />}
        <CommunicationScreen
          key={message.subject}
          chat={message}
          author={author}
        />
      </div>
    );
  };
  return <>{chats.map(renderCommunicationMessage)}</>;
};

export function CommunicationList({
  chats,
  author,
  loadMoreChatMessage,
  cursor,
}: Readonly<Props>) {
  return (
    <InfiniteScroll
      dataLength={chats.length}
      hasMore={!isNull(cursor)}
      /**
       * Setting "flex-direction to column-reverse" because it controls the behavior of infinite scrolling, enabling the
       * loader to appear at the top of the list when the user scrolls up. Without it, the function to load additional
       * messages will not be triggered.
       */
      style={{ display: 'flex', flexDirection: 'column-reverse' }}
      loader={<Loader topMargin={false} size={10} />}
      next={() => loadMoreChatMessage(cursor)}
      scrollableTarget="message-container"
      inverse={true}
      className="infinite-scroll-container" // this class is used to query element in test
    >
      {chats.length === 0 && <CommunicationEmptyState />}
      {chats.length > 0 && (
        <CommunicationListContent chats={chats} author={author} />
      )}
    </InfiniteScroll>
  );
}
