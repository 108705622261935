import { ClassNameMap } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import MUITableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { ChangeEvent } from 'react';

import checkboxStyles from './Checkbox.module.scss';
import styles from './TableHead.module.scss';

export type TableRowClassKey = 'root' | 'row' | 'cell';

export type HeadCell<T> = {
  key: keyof T;
  label: string;
  colSpan?: number;
};

export type ActionItem<T> = {
  text: string;
  value: T;
};

type Props<T> = {
  numSelected: number;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  columns: HeadCell<T>[];
  showCheckboxes?: boolean;
  disableActionItem?: boolean;
  classes?: Partial<ClassNameMap<TableRowClassKey>>;
  'data-testid'?: string;
};

export function TableHead<T>(props: Props<T>) {
  const {
    onSelectAllClick,
    numSelected,
    rowCount,
    showCheckboxes,
    disableActionItem,
    classes = {},
    columns,
  } = props;
  return (
    <MUITableHead
      className={`${styles.root} ${classes.root || ''}`}
      data-testid={props['data-testid']}
    >
      <TableRow className={classes.row}>
        {showCheckboxes && (
          <TableCell padding="checkbox">
            <Checkbox
              data-testid="tableHeadCheckbox"
              disableRipple={true}
              disableFocusRipple={true}
              disableTouchRipple={true}
              classes={{
                checked: checkboxStyles.checkbox,
                indeterminate: checkboxStyles.checkbox,
                root: checkboxStyles.root,
              }}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
        )}
        {columns.map((headCell: HeadCell<T>, index: number) => (
          <TableCell
            data-testid={headCell.key.toString()}
            className={`${styles.cell} ${classes.cell || ''}`}
            key={headCell.key.toString()}
            padding={showCheckboxes && index === 0 ? 'none' : 'normal'}
          >
            <span data-testid={`${headCell.key.toString()}-label`}>
              {headCell.label}
            </span>
          </TableCell>
        ))}
        {!disableActionItem && <TableCell />}
      </TableRow>
    </MUITableHead>
  );
}
