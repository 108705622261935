import {
  ClinicalDocument_ClinicalDocumentType,
  ClinicalDocument_ClinicalDocumentType as ClinicalDocumentType,
} from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/ClinicalDocument';
import { FormHelperText } from '@mui/material';
import { ActionModal } from 'app/patients/tabs/document-upload/ActionModal';
import {
  InputFile,
  UploadDocumentInput,
} from 'app/patients/tabs/document-upload/types';
import React, { useEffect, useState } from 'react';

import UploadModalHeader from './UploadModalHeader';
import UploadModalRow from './UploadModalRow';

interface Props {
  open: boolean;
  files: FileList | null;
  onClose: () => void;
  onUploadClick: (input: UploadDocumentInput[]) => void;
  memberId: string;
}

export default function DocumentUploadModal(props: Props) {
  const { open, onClose, memberId } = props;
  const [files, setFiles] = useState<InputFile[]>([]);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const inputFiles: InputFile[] = [];
    if (props.files) {
      const filesArray: File[] = Array.from(props.files);
      filesArray.forEach((file) => {
        const { name } = file;
        inputFiles.push({
          file,
          fileType: ClinicalDocumentType.undefined_type,
          name: name.substring(0, name.lastIndexOf('.')),
          size: file.size,
        });
      });
    }
    setFiles(inputFiles);
    setShowError(false);
  }, [props.files]);
  const validateFiles = () => {
    return (
      files.find(
        (item) =>
          item.fileType ===
            ClinicalDocument_ClinicalDocumentType.undefined_type ||
          item.name === '',
      ) === undefined
    );
  };

  const onUploadClick = async () => {
    setShowError(false);
    if (!validateFiles()) {
      setShowError(true);
      return Promise.resolve();
    }
    const uploadInput: UploadDocumentInput[] = files.map((item) => ({
      documentType: item.fileType,
      file: item.file!,
      memberId,
      name: item.name,
    }));
    props.onUploadClick(uploadInput);
    onClose();
  };
  const onDelete = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1); // put splice on new line so we dont return the removed item
    setFiles(newFiles);
  };
  const onFileUpdate = (index: number) => (newFile: InputFile) => {
    const newFiles = [...files];
    newFiles[index] = newFile;
    setFiles(newFiles);
    setShowError(false);
  };
  const filesRows = files.map((file, index) => (
    <UploadModalRow
      key={index}
      file={file}
      onDeleteClick={() => onDelete(index)}
      updateFileProps={onFileUpdate(index)}
      data-testid={`uploadFileRow-${index}`}
    />
  ));
  return (
    <ActionModal
      isOpen={open}
      isConfirmDisabled={files.length === 0}
      onClose={onClose}
      title="Upload Documents"
      onConfirm={onUploadClick}
      actionLabel="Upload"
    >
      <>
        <UploadModalHeader />
        {filesRows}
        {showError ? (
          <FormHelperText error={true}>
            Please ensure you have a file name and file type selected
          </FormHelperText>
        ) : null}
      </>
    </ActionModal>
  );
}
