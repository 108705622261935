import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Loader } from 'shared-components/loader/Loader';

import {
  GetDosespotIframeCredentials,
  GetDosespotIframeCredentials_getDosespotIframeCredentials,
} from './generated/GetDosespotIframeCredentials';
import styles from './Prescriptions.module.scss';
import { getDosespotIframeCredentials } from './queries';

const DosespotNotifications = () => {
  const [loading, setLoading] = useState(true);
  const dosespotCredentialsQuery = useQuery<GetDosespotIframeCredentials>(
    getDosespotIframeCredentials,
    {},
  );

  useEffect(() => {
    async function loadData() {
      setLoading(false);
    }
    void loadData();
  }, [dosespotCredentialsQuery]);

  const buildDosespotIframeUrl = (
    credentials: GetDosespotIframeCredentials_getDosespotIframeCredentials | null,
  ): string => {
    if (!credentials) {
      return '';
    }
    return `${credentials.url}&RefillsErrors=1`;
  };

  if (loading) {
    return <Loader topMargin={false} />;
  }

  if (!dosespotCredentialsQuery?.data?.getDosespotIframeCredentials) {
    return <div>No Dosespot credentials</div>;
  }

  return (
    <div className={styles.prescriptions}>
      <iframe
        title="DosespotIframe"
        className={styles.iframe}
        src={buildDosespotIframeUrl(
          dosespotCredentialsQuery.data.getDosespotIframeCredentials,
        )}
      />
    </div>
  );
};

export default DosespotNotifications;
