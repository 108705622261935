import React, {
  PropsWithChildren,
  ReactElement,
  useLayoutEffect,
  useState,
} from 'react';
import Masonry from 'react-masonry-css';
import { classNameCombiner } from 'utils';

import { useContainerWidth } from '../../hooks/useContainerWidth';
import styles from './Masonry.module.scss';

export enum WidthRef {
  /** @description Adjust the number of columns based on the width of the browser window. * */
  WINDOW,
  /** @description Adjust the number of columns based on the width of the container (as opposed to the window). * */
  CONTAINER,
}

export type BreakpointsConfig = number | { [key: number]: number };

interface MasonryProps {
  breakPointCols: BreakpointsConfig;
  widthRef?: WidthRef;
  className?: string;
}

export function MasonryLayout({
  children,
  widthRef = WidthRef.CONTAINER,
  className,
  breakPointCols,
}: PropsWithChildren<MasonryProps>) {
  const [config, setConfig] = useState<BreakpointsConfig>(1);
  const { target, numberOfColumns } = useContainerWidth(breakPointCols);
  const isValidElement = (element: ReactElement) => {
    return React.isValidElement(element) && typeof element !== 'boolean';
  };
  useLayoutEffect(() => {
    if (widthRef === WidthRef.WINDOW) {
      setConfig(breakPointCols);
    } else if (Array.isArray(children)) {
      const numberOfCards = children.filter((child) => isValidElement(child))
        .length;
      if (numberOfColumns > numberOfCards) {
        setConfig(numberOfCards);
      } else {
        setConfig(numberOfColumns);
      }
    }
  }, [numberOfColumns, children, breakPointCols, widthRef]);

  return (
    <div className={styles.container} ref={target}>
      <Masonry
        className={`${styles.masonryGrid} ${className || ''}`}
        columnClassName={styles.column}
        breakpointCols={config}
      >
        {Array.isArray(children) ? (
          children.map((child, index) => {
            const childKey = `child-${index}`;
            return (
              isValidElement(child) && (
                <MasonryItem key={childKey}>{child}</MasonryItem>
              )
            );
          })
        ) : (
          <MasonryItem>{children}</MasonryItem>
        )}
      </Masonry>
    </div>
  );
}

export interface MasonryItemProps {
  customClass?: string;
}
type MasonryItemPropsWithChildren = PropsWithChildren<MasonryItemProps>;

const MasonryItem = ({
  children,
  customClass,
}: MasonryItemPropsWithChildren): ReactElement => {
  return (
    <div
      className={classNameCombiner([
        styles.column,
        styles.masonryItem,
        customClass ?? '',
      ])}
    >
      {children}
    </div>
  );
};
