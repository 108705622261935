import AvatarWithStatus from 'app/care-team/avatar-vith-status/AvatarWithStatus';
import { useAppState } from 'app/state';
import { selectActiveMemberProfile } from 'app/state/care-team/actions';
import { classNameCombiner } from 'utils';
import { getTimezone } from 'utils/dateTime';
import React from 'react';
import { useDispatch } from 'react-redux';

import { CareMemberTooltip } from '../care-member-tooltip/CareMemberTooltip';
import { Psychiatrist, Therapist } from '../types';
import ClinicianRow from './ClinicianRow';
import styles from './Member.module.scss';

type ClinicianMemberProps = {
  item: Therapist | Psychiatrist;
  index: number;
  columns: number;
  userRole?: 'therapist' | 'psychiatrist';
  disableProfileLink?: boolean; // this is used to disable the profile link when you are on the profile page
};

const ClinicianMember = (props: ClinicianMemberProps) => {
  const dispatch = useDispatch();
  const { item, index, columns, userRole, disableProfileLink = false } = props;
  const timezone = useAppState((state) => getTimezone(state.user.timezone));

  const onSetCareTeamProfile = () => {
    dispatch(selectActiveMemberProfile({ careTeamMember: item }));
  };

  return (
    <CareMemberTooltip
      timezone={timezone}
      key={`clinician_member_tooltip_${item.id}`}
      id={item.id}
      name={item.fullName}
      totalCompletedSessions={item.totalCompletedSessions ?? null}
      previousAppointmentDate={item.prevAppointmentDate ?? null}
      nextAppointmentDate={item.nextAppointmentDate ?? null}
    >
      <button
        onClick={onSetCareTeamProfile}
        key={`care_member_${index}`}
        data-testid={`btn_care_member_${item.id}`}
        className={classNameCombiner([
          styles.item,
          columns === 1 ? styles.fullscreen : '',
          disableProfileLink ? styles.disabled : '',
        ])}
      >
        <AvatarWithStatus picture={item.avatar!} name={item.fullName} />
        <ClinicianRow clinician={item} role={userRole} />
      </button>
    </CareMemberTooltip>
  );
};

export default ClinicianMember;
