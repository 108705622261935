import { InboxView } from 'app/inbox/InboxView';
import { summaryRoute } from 'app/patients/Routes';
import { useDispatch } from 'app/state';
import { clickMemberItemAction } from 'app/state/amplitude/actions/inbox';
import { openNewTab } from 'app/state/member-tabs/actions';
import { selectUserRole } from 'app/state/user/selectors';
import { Routes } from 'app/top-nav/Routes';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { InboxItem, InboxSections, InboxSource, InboxTab } from './types';
import { useClinicianInbox } from './UseClinicianInbox';

const CLINICIAN_TODAYS_SECTION = [
  InboxSections.COMPLETED,
  InboxSections.TASKS,
  InboxSections.APPOINTMENTS,
  InboxSections.RISKS,
];

export function ClinicianInboxView({ tabIndex }: { tabIndex: number }) {
  const inbox = useClinicianInbox();
  const role = selectUserRole();
  const history = useHistory();
  const dispatch = useDispatch();

  const onItemClick = (item: InboxItem, section: InboxSections): void => {
    history.replace(summaryRoute(Routes.PATIENTS, item.id));
    const tab = CLINICIAN_TODAYS_SECTION.includes(section)
      ? InboxTab.TODAYS
      : InboxTab.ALL;
    dispatch(
      clickMemberItemAction({
        memberId: item.id,
        role: role!,
        section,
        source: InboxSource.CLINICIAN_INBOX,
        tab,
      }),
    );

    dispatch(
      openNewTab({
        memberId: item.id,
        section,
        source: InboxSource.COACH_INBOX,
      }),
    );
  };
  return <InboxView {...inbox} tabIndex={tabIndex} onItemClick={onItemClick} />;
}
