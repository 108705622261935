import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import * as Sentry from '@sentry/react';
import { NotAuthorizedScreen } from 'shared-components/error-state/NotAuthorizedScreen';
import { NotLoggedInScreen } from 'shared-components/error-state/NotLoggedInScreen';
import { TransientFeatureFlag, useFeatureFlags } from 'hooks/useFeatureFlags';
import { TopLevelRoutes } from 'app/top-nav/Routes';
import { MemberChartErrorState } from 'app/member-chart-cards/error-state/MemberChartErrorState';
import { ComingSoon } from 'shared-components/throwaway/ComingSoon';
import { useAppState } from 'app/state';
import { useLogger } from 'app/state/log/useLogger';
import { UserRole } from 'generated/globalTypes';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router';

type ConfigProps = {
  permittedRoles: UserRole[];
  isVaultRequired?: boolean;
  component: React.ComponentType<any>;
  requiresFeatureFlag?: TransientFeatureFlag; // if provided checks whether the feature is enabled for auth user
  componentProps?: any;
};

type Props = Omit<RouteProps, 'render'> & ConfigProps;

export type LocationState = { previousPath: string };

const SentryRoute = Sentry.withSentryRouting(Route);

export function SecureRoute(props: Props) {
  const { permittedRoles, isVaultRequired } = props;
  return (
    <SentryRoute
      path={props.path}
      exact={props.exact}
      strict={props.strict}
      sensitive={props.sensitive}
      render={() => (
        <SecureRouteWrapper
          data-testid="wrapper"
          isVaultRequired={isVaultRequired}
          permittedRoles={permittedRoles}
          component={props.component}
          requiresFeatureFlag={props.requiresFeatureFlag}
        />
      )}
    />
  );
}

export function SecureRouteWrapper(props: ConfigProps) {
  const { permittedRoles, component: Component } = props;
  const location = useLocation<LocationState>();
  const { oktaAuth, authState } = useOktaAuth();
  const { transientFeatureFlags } = useFeatureFlags();
  const { role, loggedIntoVault } = useAppState(({ user }) => ({
    loggedIntoVault: user.loggedIntoVault,
    role: user.role,
  }));
  const logger = useLogger();

  const pendingLogin = React.useRef(false);
  const isAuthenticated = authState?.isAuthenticated ?? false;
  const error = authState?.error;
  const isLoading = !error && (pendingLogin.current || role === null);

  const [handleLoginError, setHandleLoginError] = useState<Error | null>(null);

  const isAuthorized = role !== null && permittedRoles.includes(role);
  const isVaultOktaAuthEnabled =
    transientFeatureFlags[TransientFeatureFlag.ENABLE_VAULT_OKTA_AUTH];

  // Historically, we've required Vault login only when accessing a route that will render a component that relies on
  // Vault. Over time, we've expanded our use of Vault to the point that care providers need it for many parts of their
  // workflow, and they'd prefer to log into Vault upfront instead of being interrupted later in their workflow to
  // log in when requesting a page that needs Vault. As a result, we'll check, when accessing any SecureRoute,
  // whether the user is logged into Vault, and will show a Vault login page if they're not yet logged into Vault.
  // Since our PMs are going to train care providers to explicitly use the "Logout" button when completing their shift,
  // and given that no shift is longer than the TTL for a Vault token (24 hours), care providers will be prompted to
  // log into Vault only upon accessing the first ClinHub/Care Hub page for that shift.

  // [CARE-4354] If authenticating with Okta, we don't need to display the Vault login page.
  const isVaultRequired = isVaultOktaAuthEnabled
    ? false
    : transientFeatureFlags[TransientFeatureFlag.ENABLE_VAULT_LOGIN_UPFRONT]
    ? true
    : props.isVaultRequired;

  useEffect(() => {
    const handleLogin = async () => {
      if (pendingLogin.current) return;

      pendingLogin.current = true;

      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin,
      );

      await oktaAuth.signInWithRedirect({ originalUri });
    };
    if (!authState) return;

    if (!authState.isAuthenticated) {
      logger.info(
        'SecureRoute: User is not authenticated, redirecting to okta login.',
      );
      handleLogin().catch((err) => {
        const loginError = new Error(
          'SecureRoute: unable to redirect to okta login',
          {
            cause: err,
          },
        );
        logger.error(loginError, { err });
        setHandleLoginError(err);
      });
    } else {
      pendingLogin.current = false;
    }
  }, [authState, oktaAuth]);

  if (handleLoginError) {
    return (
      <MemberChartErrorState showMessage={true} error={handleLoginError} />
    );
  }

  if (isLoading) {
    return null;
  }

  if (isAuthenticated && isAuthorized && isVaultRequired && !loggedIntoVault) {
    return (
      // @deprecated
      <Redirect
        to={{
          pathname: TopLevelRoutes.VAULT_LOGIN,
          search: location.search,
          state: { previousPath: location?.pathname },
        }}
      />
    );
  }
  if (isAuthenticated && isAuthorized) {
    const featureEnabled = props.requiresFeatureFlag
      ? transientFeatureFlags[props.requiresFeatureFlag]
      : true;
    return featureEnabled ? (
      <Component {...props.componentProps} />
    ) : (
      <ComingSoon />
    );
  }
  if (!isAuthenticated) {
    return <NotLoggedInScreen />;
  }
  return <NotAuthorizedScreen />;
}
