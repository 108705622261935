import { createAction } from 'redux-reloaded';

export enum Actions {
  LOG_ERROR = 'log_error',
  LOG_SENTRY_AND_RAPID_7 = 'LOG_SENTRY_AND_RAPID_7',
}

export type AdditionalLogData = Record<string, any>;

/**
 * @deprecated Use ILogger interface instead.
 */
export const logErrorInSentry = createAction(
  Actions.LOG_ERROR,
  (payload: { error: Error; additionalData?: AdditionalLogData }) => payload,
);
/**
 * @deprecated Use ILogger interface instead.
 */
export const logSentryAndRapid7 = createAction(
  Actions.LOG_SENTRY_AND_RAPID_7,
  (payload: { error: Error; additionalData?: AdditionalLogData }) => payload,
);
