import { useForm } from '@ginger.io/react-use-form';
import { SafetyPlan } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/safety/SafetyPlan';
import { Typography } from '@mui/material';
import { CardTextAreaField } from 'app/coach/coach-notes/note-detail-view/note-fields';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import { CardTextArea } from 'app/member-chart-cards/text-area/CardTextArea';
import { psychiatryFieldDefs } from 'app/notes-ui/shared/safety-intake/schema';
import { Subsection } from 'app/vault/api/ShareableSubsectionTypes';
import React, { Fragment } from 'react';

enum FieldLabels {
  WARNING_SIGNS = 'Warning signs and triggers that indicate that a crisis may be developing or contribute to the crisis',
  THINGS_I_CAN_DO = 'Things I can do to take my mind off my problems',
  PEOPLE_TO_CONTACT = 'People I can ask for help',
  SAFE_ENVIRONMENT = 'Making the environment safe',
  THINGS_THAT_ARE_IMPORTANT = 'Things that are important to me and worth living for:',
}

export enum Placeholders {
  INTERNAL_COPING_STRATEGIES = 'Internal coping strategies:',
  EXTERNAL_COPING_STRATEGIES = 'External coping strategies:',
  PEOPLE_TO_CONTACT = '(within 5 miles)',
}

export const TherapySafetyPlan = ({
  selectedNote,
  readOnly,
  tooltipEventLabel,
}: {
  readOnly: boolean;
  tooltipEventLabel: string;
  selectedNote?: Subsection;
}): React.ReactElement | null => {
  const { fields } = useForm<SafetyPlan>(psychiatryFieldDefs.safetyPlan, {
    ...(selectedNote as SafetyPlan),
  });

  return (
    <>
      <div className={styles.titleField}>
        <Typography className={styles.riskAssessmentTitle}>
          Safety Plan
        </Typography>
        <span className={styles.inlineBorder} />
      </div>
      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.WARNING_SIGNS}
      />
      <CardTextAreaField
        field={
          fields.therapyQuestions.warningSignsAndTriggersForDevelopingCrisis
        }
        tooltipEventLabel={`${tooltipEventLabel}: ${FieldLabels.WARNING_SIGNS}`}
        tooltipText=""
        placeholder=""
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.groupLabel}`}
        labelText={FieldLabels.THINGS_I_CAN_DO}
      />
      <div className={`${styles.helperText} ${styles.subtleClinical}`}>
        {Placeholders.INTERNAL_COPING_STRATEGIES}
      </div>
      <CardTextAreaField
        field={
          fields.therapyQuestions
            .internalStrategiesOrientationForMentalHealthCrisis
        }
        tooltipEventLabel={`${tooltipEventLabel}: ${Placeholders.INTERNAL_COPING_STRATEGIES}`}
        tooltipText=""
        placeholder=""
        readOnly={readOnly}
      />

      <div className={`${styles.helperText} ${styles.subtleClinical}`}>
        {Placeholders.EXTERNAL_COPING_STRATEGIES}
      </div>
      <CardTextAreaField
        field={
          fields.therapyQuestions
            .externalStrategiesOrientationForMentalHealthCrisis
        }
        tooltipEventLabel={`${tooltipEventLabel}: ${Placeholders.EXTERNAL_COPING_STRATEGIES}`}
        tooltipText=""
        placeholder=""
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.groupLabel}`}
        labelText={FieldLabels.THINGS_I_CAN_DO}
      />
      {fields.therapyQuestions.peopleToContactInCrisis.value.map(
        (contacts, index) => (
          <Fragment key={`${contacts}-${index}`}>
            <div className={`${styles.helperText} ${styles.subtleClinical}`}>
              {`${index + 1} ${
                index === 0 ? Placeholders.PEOPLE_TO_CONTACT : ''
              }`}
            </div>
            <CardTextArea
              value={contacts}
              tooltipEventLabel={`${tooltipEventLabel}: ${FieldLabels.PEOPLE_TO_CONTACT}`}
              tooltipText=""
              placeholder=""
              readOnly={readOnly}
              multiline={false}
            />
          </Fragment>
        ),
      )}

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.SAFE_ENVIRONMENT}
      />
      <CardTextAreaField
        field={fields.therapyQuestions.safeEnvironmentOrientation}
        tooltipEventLabel={`${tooltipEventLabel}: ${FieldLabels.SAFE_ENVIRONMENT}`}
        tooltipText=""
        placeholder=""
        readOnly={readOnly}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.THINGS_THAT_ARE_IMPORTANT}
      />
      <CardTextAreaField
        field={fields.therapyQuestions.rememberWhatsImportant}
        tooltipEventLabel={`${tooltipEventLabel}: ${FieldLabels.THINGS_THAT_ARE_IMPORTANT}`}
        tooltipText=""
        placeholder=""
        readOnly={readOnly}
      />
    </>
  );
};
