import gql from 'graphql-tag';

export const GetMemberNotesQuery = gql`
  query GetMemberNotes($input: GetMemberInput!) {
    getMember(input: $input) {
      id
      preferredFirstName
      preferredLastName
      onboard {
        completedAt
      }
      onboardAnswers {
        howAreYouToday {
          answers
        }
        howCanWeHelp {
          answers
        }
        importantToYou {
          answers
        }
        impact {
          answers
        }
        feelAboutMakingChanges {
          answers
        }
      }
    }
  }
`;
