import { createAction } from 'redux-reloaded';

export enum Actions {
  SELECT_PATIENT = 'SELECT_PATIENT',
}

export const selectPatient = createAction(
  Actions.SELECT_PATIENT,
  (id: string) => ({
    patientId: id,
  }),
);
