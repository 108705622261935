import {
  arrayField,
  booleanField,
  field,
  FieldDefinitions,
  stringField,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  SafetyIntake,
  Version as SafetyIntakeVersion,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyIntake';
import { Safety as SafetyProgress } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyProgress';
import {
  deprecatedField,
  optionalActionsTakenOptions,
  optionalAnyChangesOrRisks,
  optionalCHIDescription,
  optionalCSIDescription,
  optionalField,
  optionalOnCallClinicianContacted,
  optionalPastHomicidalIdeation,
  optionalPastSelfInjury,
  optionalPastSuicidalIdeation,
  optionalPHIDescription,
  optionalPSIDescription,
  optionalSafetyPlan,
  optionalSafetyPlanList,
  optionalSelfInjury,
  requiredTrueFalseBooleanOption,
} from '../ValidationRules';
import {
  SafetyPlanStatus,
  Version as SafetyPlanVersion,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/safety/SafetyPlan';
import { NoteType } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/NoteType';
import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import { SafetyConcern } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyConcern';

export const fieldDefs: FieldDefinitions<SafetyIntake> = {
  appointmentId: stringField(),
  selectedConcerns: arrayField<SafetyConcern>(),
  currentSuicidalIdeation: {
    description: stringField({ rules: [optionalCSIDescription] }),
    intent: booleanField(),
    means: booleanField(),
    plan: booleanField(),
    thoughts: booleanField(),
    hasPast: field(),
    pastSummarization: stringField({ rules: [optionalPastSuicidalIdeation] }),
  },
  pastSuicidalIdeation: {
    description: stringField({ rules: [optionalPSIDescription] }),
    isPresent: booleanField({ rules: [] }),
  },

  currentHomicidalIdeation: {
    description: stringField({ rules: [optionalCHIDescription] }),
    intent: booleanField(),
    means: booleanField(),
    plan: booleanField(),
    specificTargetsEntityOrLocationConsidered: booleanField(),
    thoughts: booleanField(),
    hasPast: field(),
    pastSummarization: stringField({ rules: [optionalPastHomicidalIdeation] }),
  },
  pastHomicidalIdeation: {
    description: stringField({ rules: [optionalPHIDescription] }),
    isPresent: booleanField({ rules: [] }),
  },

  currentSelfInjury: {
    bodyPartHarmed: stringField({ rules: [deprecatedField] }),
    extentOfInjury: stringField({ rules: [deprecatedField] }),
    frequency: {
      period: field({ rules: [deprecatedField] }),
      timesPerPeriod: field({ rules: [deprecatedField] }),
    },
    instrumentUsed: stringField({ rules: [deprecatedField] }),
    isPresent: field(),
    everSoughtMedicalAttentionAsAResult: field({ rules: [optionalSelfInjury] }),
    currentSummarization: stringField({ rules: [optionalSelfInjury] }),
    hasPast: field(),
    pastEverSoughtMedicalAttentionAsAResult: field({
      rules: [optionalPastSelfInjury],
    }),
    pastSummarization: stringField({ rules: [optionalPastSelfInjury] }),
  },

  safetyPlan: {
    appointmentId: stringField({ rules: [] }),
    noteType: field<NoteType>({ rules: [] }),
    appointmentStart: stringField({ rules: [] }),
    appointmentEnd: stringField({ rules: [] }),
    description: stringField({ rules: [deprecatedField] }),
    status: field<SafetyPlanStatus>({ rules: [optionalAnyChangesOrRisks] }),
    therapyQuestions: {
      warningSignsAndTriggersForDevelopingCrisis: stringField({
        rules: [optionalSafetyPlan],
      }),
      internalStrategiesOrientationForMentalHealthCrisis: stringField({
        rules: [optionalSafetyPlan],
      }),
      externalStrategiesOrientationForMentalHealthCrisis: stringField({
        rules: [optionalSafetyPlan],
      }),
      peopleToContactInCrisis: field<string[]>({
        default: [],
        rules: [optionalSafetyPlanList],
      }),
      agenciesToContactInCrisis: field<string[]>({
        default: [],
        rules: [optionalSafetyPlanList],
      }),
      safeEnvironmentOrientation: stringField({ rules: [optionalSafetyPlan] }),
      rememberWhatsImportant: stringField({ rules: [optionalSafetyPlan] }),
    },
    psychiatryQuestions: {
      safetyPlanDocumentation: stringField({ rules: [optionalField] }),
      agenciesToContactInCrisis: field<string[]>({ default: [] }),
    },
    actionsTaken: {
      crr: field<BooleanOption>({ rules: [optionalActionsTakenOptions] }),
      sharedResources: field<BooleanOption>({
        rules: [optionalActionsTakenOptions],
      }),
      consultedWithManager: field<BooleanOption>({
        rules: [optionalActionsTakenOptions],
      }),
      careCoordinationTeamEngaged: field<BooleanOption>({
        rules: [optionalActionsTakenOptions],
      }),
      onCallClinicianContacted: field<BooleanOption>({
        rules: [optionalActionsTakenOptions],
      }),
      onCallClinicianContactedNotes: stringField({
        rules: [optionalOnCallClinicianContacted],
      }),
    },
    version: field<SafetyPlanVersion>({ default: SafetyPlanVersion.v0 }),
  },

  domesticViolenceRisk: {
    currentDescription: stringField({ rules: [] }),
  },

  highSubstanceUse: {
    currentDescription: stringField({ rules: [] }),
  },

  eatingDisorderRisk: {
    currentDescription: stringField({ rules: [] }),
  },

  vulnerablePopulationsAbuse: {
    currentDescription: stringField({ rules: [] }),
  },

  otherConcerns: {
    currentDescription: stringField({ rules: [] }),
  },

  anyChangesOrRisks: field<BooleanOption>({
    default: BooleanOption.no,
    rules: [requiredTrueFalseBooleanOption],
  }),
  version: field<SafetyIntakeVersion>({ default: SafetyIntakeVersion.v0 }),
};

export const therapyFieldDefs: FieldDefinitions<SafetyIntake> = {
  appointmentId: stringField(),
  selectedConcerns: arrayField<SafetyConcern>(),
  currentSuicidalIdeation: {
    description: stringField({ rules: [optionalCSIDescription] }),
    intent: booleanField({ rules: [] }),
    means: booleanField({ rules: [] }),
    plan: booleanField({ rules: [] }),
    thoughts: booleanField({ rules: [] }),
    hasPast: field({ rules: [] }),
    pastSummarization: stringField({ rules: [optionalPastSuicidalIdeation] }),
  },
  pastSuicidalIdeation: {
    description: stringField({ rules: [optionalPSIDescription] }),
    isPresent: booleanField({ rules: [] }),
  },

  currentHomicidalIdeation: {
    description: stringField({ rules: [optionalCHIDescription] }),
    intent: booleanField({ rules: [] }),
    means: booleanField({ rules: [] }),
    plan: booleanField({ rules: [] }),
    specificTargetsEntityOrLocationConsidered: booleanField({ rules: [] }),
    thoughts: booleanField({ rules: [] }),
    hasPast: field({ rules: [] }),
    pastSummarization: stringField({ rules: [optionalPastHomicidalIdeation] }),
  },
  pastHomicidalIdeation: {
    description: stringField({ rules: [optionalPHIDescription] }),
    isPresent: booleanField({ rules: [] }),
  },

  currentSelfInjury: {
    bodyPartHarmed: stringField({ rules: [] }),
    extentOfInjury: stringField({ rules: [] }),
    frequency: {
      period: field({ rules: [] }),
      timesPerPeriod: field({ rules: [] }),
    },
    instrumentUsed: stringField({ rules: [] }),
    isPresent: field({ rules: [] }),
    everSoughtMedicalAttentionAsAResult: field({ rules: [optionalSelfInjury] }),
    currentSummarization: stringField({ rules: [optionalSelfInjury] }),
    hasPast: field({ rules: [] }),
    pastEverSoughtMedicalAttentionAsAResult: field({
      rules: [optionalPastSelfInjury],
    }),
    pastSummarization: stringField({ rules: [optionalPastSelfInjury] }),
  },

  safetyPlan: {
    appointmentId: stringField({ rules: [] }),
    noteType: field<NoteType>({ rules: [] }),
    appointmentStart: stringField({ rules: [] }),
    appointmentEnd: stringField({ rules: [] }),
    description: stringField({ rules: [] }),
    status: field<SafetyPlanStatus>({
      default: SafetyPlanStatus.completed,
      rules: [],
    }),
    therapyQuestions: {
      warningSignsAndTriggersForDevelopingCrisis: stringField({
        rules: [optionalSafetyPlan],
      }),
      internalStrategiesOrientationForMentalHealthCrisis: stringField({
        rules: [optionalSafetyPlan],
      }),
      externalStrategiesOrientationForMentalHealthCrisis: stringField({
        rules: [optionalSafetyPlan],
      }),
      peopleToContactInCrisis: field<string[]>({
        default: [],
        rules: [optionalSafetyPlanList],
      }),
      agenciesToContactInCrisis: field<string[]>({
        default: [],
        rules: [optionalSafetyPlanList],
      }),
      safeEnvironmentOrientation: stringField({ rules: [optionalSafetyPlan] }),
      rememberWhatsImportant: stringField({ rules: [optionalSafetyPlan] }),
    },
    psychiatryQuestions: {
      safetyPlanDocumentation: stringField({ rules: [] }),
      agenciesToContactInCrisis: field<string[]>({ default: [], rules: [] }),
    },
    actionsTaken: {
      crr: field<BooleanOption>({ rules: [optionalActionsTakenOptions] }),
      sharedResources: field<BooleanOption>({
        rules: [optionalActionsTakenOptions],
      }),
      consultedWithManager: field<BooleanOption>({
        rules: [optionalActionsTakenOptions],
      }),
      careCoordinationTeamEngaged: field<BooleanOption>({
        rules: [optionalActionsTakenOptions],
      }),
      onCallClinicianContacted: field<BooleanOption>({
        rules: [optionalActionsTakenOptions],
      }),
      onCallClinicianContactedNotes: stringField({
        rules: [optionalOnCallClinicianContacted],
      }),
    },
    version: field<SafetyPlanVersion>({ default: SafetyPlanVersion.v0 }),
  },

  domesticViolenceRisk: {
    currentDescription: stringField({ rules: [] }),
  },

  highSubstanceUse: {
    currentDescription: stringField({ rules: [] }),
  },

  eatingDisorderRisk: {
    currentDescription: stringField({ rules: [] }),
  },

  vulnerablePopulationsAbuse: {
    currentDescription: stringField({ rules: [] }),
  },

  otherConcerns: {
    currentDescription: stringField({ rules: [] }),
  },

  anyChangesOrRisks: field<BooleanOption>({
    default: BooleanOption.undefined_choice,
    rules: [requiredTrueFalseBooleanOption],
  }),
  version: field<SafetyIntakeVersion>({ default: SafetyIntakeVersion.v1 }),
};

export const psychiatryFieldDefs: FieldDefinitions<SafetyIntake> = Object.assign(
  {},
  therapyFieldDefs,
  {
    safetyPlan: {
      appointmentId: stringField({ rules: [] }),
      appointmentType: stringField({ rules: [] }),
      appointmentStart: stringField({ rules: [] }),
      appointmentEnd: stringField({ rules: [] }),
      description: stringField({ rules: [] }),
      status: field<SafetyPlanStatus>({
        default: SafetyPlanStatus.completed,
        rules: [optionalAnyChangesOrRisks],
      }),
      therapyQuestions: {
        warningSignsAndTriggersForDevelopingCrisis: stringField({ rules: [] }),
        internalStrategiesOrientationForMentalHealthCrisis: stringField({
          rules: [],
        }),
        externalStrategiesOrientationForMentalHealthCrisis: stringField({
          rules: [],
        }),
        peopleToContactInCrisis: field<string[]>({ default: [], rules: [] }),
        agenciesToContactInCrisis: field<string[]>({ default: [], rules: [] }),
        safeEnvironmentOrientation: stringField({ rules: [] }),
        rememberWhatsImportant: stringField({ rules: [] }),
      },
      psychiatryQuestions: {
        safetyPlanDocumentation: stringField({ rules: [optionalSafetyPlan] }),
        agenciesToContactInCrisis: field<string[]>({
          default: [],
          rules: [optionalSafetyPlanList],
        }),
      },
      actionsTaken: {
        crr: field<BooleanOption>({ rules: [] }),
        sharedResources: field<BooleanOption>({ rules: [] }),
        consultedWithManager: field<BooleanOption>({ rules: [] }),
        careCoordinationTeamEngaged: field<BooleanOption>({ rules: [] }),
        onCallClinicianContacted: field<BooleanOption>({ rules: [] }),
        onCallClinicianContactedNotes: stringField({ rules: [] }),
      },
      version: field<SafetyPlanVersion>({ default: SafetyPlanVersion.v0 }),
    },
  },
);

export const validateTherapy = (data: SafetyIntake | SafetyProgress | null) => {
  return data !== null && validateForm(data, therapyFieldDefs);
};
export const validatePsychiatry = (
  data: SafetyIntake | SafetyProgress | null,
) => {
  return data !== null && validateForm(data, psychiatryFieldDefs);
};
