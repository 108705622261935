import { MemberProfileBaseProps } from 'app/coach/chat/ChatContainer';
import { TimeDivider } from 'app/coach/chat/messages-section/TimeDivider';
import { noMoreHistory } from 'app/coach/chat/strings';
import { HISTORY_STATE } from 'app/coach/chat/types';
import {
  getFormattedDateAndTime,
  getMessageElementId,
} from 'app/coach/chat/utils';
import {
  MessageStatus,
  MessageToDisplay,
  MessageType,
} from 'app/coach/pubnub/types';
import { findLastIndexOfMessage } from 'app/coach/pubnub/utils';
import { useAppState } from 'app/state';
import { uniqueArrayList } from 'app/state/inbox/utils';
import { useLogger } from 'app/state/log/useLogger';
import { useOnMount } from 'hooks/useOnMount';
import React, { useRef } from 'react';

import { MessageContainer } from './MessageContainer';
import styles from './MessagesList.module.scss';

type DuplicatedMessageInfo = {
  messageId: string;
  messageType: MessageType;
  messageTimetoken: string;
  messageChannel: string;
};

export const MessagesList = ({
  messages,
  memberProfileBaseProps,
  scrollToTheBottom,
  historyState,
}: {
  messages: MessageToDisplay[];
  memberProfileBaseProps: MemberProfileBaseProps;
  scrollToTheBottom: () => void;
  historyState: HISTORY_STATE;
}) => {
  useOnMount(() => {
    scrollToTheBottom();
  });
  const { timezone, currentChannelId } = useAppState(({ user, inbox }) => ({
    currentChannelId: inbox.currentChannelId,
    timezone: user.timezone,
  }));

  const dateRef = useRef<Date | null>(null);
  const logger = useLogger();
  const renderMessage = ({
    message,
    index,
    shouldDisplaySeenStatus,
  }: {
    message: MessageToDisplay;
    shouldDisplaySeenStatus?: boolean;
    index: number;
  }) => {
    const { timetoken } = message;

    const { formattedString, date } = getFormattedDateAndTime(
      timetoken,
      timezone ?? 'UTC',
    );
    const isBeginningOfChat =
      historyState === HISTORY_STATE.NO_MORE_MESSAGES && index === 0;
    const isTimeDividerNeeded =
      date.getDay() !== dateRef.current?.getDay() || isBeginningOfChat;

    dateRef.current = date;
    const testId = isBeginningOfChat ? 'noMoreMessages' : '';
    return (
      <div
        data-testid={testId}
        className={styles.container}
        id={getMessageElementId(message.id)}
        key={message.id}
      >
        {isTimeDividerNeeded && (
          <TimeDivider
            dateAndTime={formattedString}
            comment={isBeginningOfChat ? noMoreHistory : undefined}
          />
        )}
        <MessageContainer
          dateAndTime={formattedString}
          memberProfileBaseProps={memberProfileBaseProps}
          messageProps={message}
          shouldDisplaySeenStatus={shouldDisplaySeenStatus}
        />
      </div>
    );
  };

  const duplicatedMessagesInfo: DuplicatedMessageInfo[] = [];

  const renderMessages = (messages: MessageToDisplay[]) => {
    const messageIdsSet = new Set<string>();
    // unique array is needed to count the index of the last seen message correctly since duplicated messages will be rendered once
    const uniqueMessagesArray = uniqueArrayList(messages);
    const indexOfLastSeenByMemberMessage = findLastIndexOfMessage(
      uniqueMessagesArray,
      (m: MessageToDisplay) =>
        m.status === MessageStatus.SEEN &&
        m.type === MessageType.TEXT_FROM_LISTENER,
    );

    const componentMap = messages.map((message, index) => {
      // if the message is in the set, it means it has already been rendered, so we don't want to render it but we want to log such cases
      if (messageIdsSet.has(message.id)) {
        duplicatedMessagesInfo.push({
          messageChannel: message.channel,
          messageId: message.id,
          messageTimetoken: message.timetoken,
          messageType: message.type,
        });
        return null;
      }
      // add the message to the set for the duplication check
      messageIdsSet.add(message.id);

      return index === indexOfLastSeenByMemberMessage
        ? renderMessage({ index, message, shouldDisplaySeenStatus: true })
        : renderMessage({ index, message });
    });

    // If there are any duplicated messages, log a single warning with all their details
    if (duplicatedMessagesInfo.length > 0) {
      logger.warning(
        `Received ${duplicatedMessagesInfo.length} duplicated messages in MessagesList`,
        {
          currentChannelId,
          duplicatedMessages: duplicatedMessagesInfo,
        },
      );
    }

    return componentMap;
  };

  return <>{renderMessages(messages)}</>;
};
