import { BooleanOption } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/BooleanOption';
import { DomesticViolenceRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/DomesticViolenceRisk';
import { RiskLevel } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/RiskLevel';
import DeleteIcon from '@mui/icons-material/HighlightOffRounded';
import { Typography } from '@mui/material';
import {
  RiskFormComponent,
  RiskFormProps,
  RiskType,
} from 'app/coach/coach-notes/CoachNotesTypes';
import {
  FIDO_FIELDS,
  FIDO_PLACEHOLDERS,
} from 'app/coach/coach-notes/constants';
import { CardTextAreaField } from 'app/coach/coach-notes/note-detail-view/note-fields';
import { domesticViolenceRisk } from 'app/coach/coach-notes/note-detail-view/note-templates/fieldDefs';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { useRiskForm } from 'app/coach/coach-notes/useRiskForm';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import { EnumRadioField } from 'app/notes-ui/forms/fields';
import { BooleanRadioField } from 'app/notes-ui/forms/form-controls/BooleanRadioField';
import { classNameCombiner } from 'utils';
import React from 'react';

const FieldLabels = {
  ATTRIBUTES: 'Attributes of abuse and degree of control',
  DISCLOSED_TO: 'Who? *',
  DISCLOSURE:
    'Disclosure to anyone, such as a friend, family member, PCP, or therapist?',
  OTHER_RELEVANT_INFO: 'Other relevant information',
  RISK_LEVEL: 'Risk level outcome *',
  SOUGHT_MEDICAL_ATTENTION: 'Ever sought medical attention as a result?',
  SUMMARY: 'Summarization of past DV risk',
  VIEW_TOWARD_CHANGE: 'View toward change',
  ...FIDO_FIELDS,
};

const Placeholders = {
  ATTRIBUTES:
    'Coercion, threats, intimidation (physical, emotional), emotional / verbal abuse, economic, isolation, physical...',
  DISCLOSED_TO: 'Type here...',
  MEANS: 'Razor blade, hair pulling, burning...',
  OTHER_RELEVANT_INFO: 'Location, if imminent...',
  SUMMARY: 'History, dates...',
  VIEW_TOWARD_CHANGE: 'Type here...',
  VIEW_TOWARD_CHANGE_HELPER_TEXT:
    'Readiness / openness toward taking steps to secure additional support / safety',
  ...FIDO_PLACEHOLDERS,
};

const TOOLTIP_EVENT_LABEL = 'Coach Notes > Domestic Violence Risk';

export const DomesticViolenceRiskForm: RiskFormComponent<DomesticViolenceRisk> = (
  props: RiskFormProps<DomesticViolenceRisk>,
) => {
  const {
    readOnly,
    inline,
    isRemovable,
    initialValue,
    onChange,
    removeRisk,
  } = props;
  const { fields, onRemoveRisk } = useRiskForm<DomesticViolenceRisk>({
    initialValue,
    onChange,
    removeRisk,
    riskType: RiskType.DOMESTIC_VIOLENCE_RISK,
    validationSchema: domesticViolenceRisk,
  });

  return (
    <div className={inline ? styles.inlineRiskSection : styles.cardSection}>
      <div
        className={classNameCombiner([
          styles.riskTitleField,
          isRemovable ? styles.withButton : '',
        ])}
      >
        <Typography className={styles.riskAssessmentTitle}>
          Domestic Violence Risk
        </Typography>
        <span className={styles.inlineBorder} />
        {isRemovable && (
          <DeleteIcon
            data-testid="removeBtn"
            className={styles.removeRiskBtn}
            onClick={onRemoveRisk}
          />
        )}
      </div>

      <EnumRadioField
        label={FieldLabels.RISK_LEVEL}
        disabled={readOnly}
        options={RiskLevel}
        keysToExclude={['imminent']}
        field={fields.riskLevel}
        formControlStyles={{ label: styles.label }}
      />

      <FieldLabel className={styles.label} labelText={FieldLabels.SUMMARY} />
      <CardTextAreaField
        name="domesticViolenceSummary"
        field={fields.summary}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.SUMMARY}`}
        tooltipText=""
        placeholder={Placeholders.SUMMARY}
        readOnly={readOnly}
      />

      <FieldLabel className={styles.label} labelText={FieldLabels.ATTRIBUTES} />
      <CardTextAreaField
        name="domesticViolenceAttributes"
        field={fields.attributes}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.ATTRIBUTES}`}
        tooltipText=""
        placeholder={Placeholders.ATTRIBUTES}
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.groupLabel}`}
        labelText={FieldLabels.FIDO}
      />
      <div className={`${styles.helperText} ${styles.subtle}`}>
        {Placeholders.FIDO_HELPER_TEXT}
      </div>
      <CardTextAreaField
        name="domesticViolenceFrequency"
        field={fields.frequency}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.FREQUENCY}`}
        tooltipText=""
        placeholder={Placeholders.FREQUENCY}
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.subtle}`}
        labelText={FieldLabels.INTENSITY}
      />
      <CardTextAreaField
        name="domesticViolenceIntensity"
        field={fields.intensity}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.INTENSITY}`}
        tooltipText=""
        placeholder={Placeholders.INTENSITY}
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.subtle}`}
        labelText={FieldLabels.DURATION}
      />
      <CardTextAreaField
        name="domesticViolenceDuration"
        field={fields.duration}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.DURATION}`}
        tooltipText=""
        placeholder={Placeholders.DURATION}
        readOnly={readOnly}
      />

      <FieldLabel
        className={`${styles.label} ${styles.subtle}`}
        labelText={FieldLabels.ONSET}
      />
      <CardTextAreaField
        name="domesticViolenceOnSet"
        field={fields.onset}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.ONSET}`}
        tooltipText=""
        placeholder={Placeholders.ONSET}
        readOnly={readOnly}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.VIEW_TOWARD_CHANGE}
      />
      <div className={styles.helperText}>
        {Placeholders.VIEW_TOWARD_CHANGE_HELPER_TEXT}
      </div>
      <CardTextAreaField
        name="domesticViolenceViewToChange"
        field={fields.viewTowardChange}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.VIEW_TOWARD_CHANGE}`}
        tooltipText=""
        placeholder={Placeholders.VIEW_TOWARD_CHANGE}
        readOnly={readOnly}
      />

      <FieldLabel className={styles.label} labelText={FieldLabels.DISCLOSURE} />
      <BooleanRadioField
        disabled={readOnly}
        name="domesticViolenceRiskDisclosure"
        value={fields.disclosure.value}
        onChange={fields.disclosure.setValue}
      />

      {fields.disclosure.value === BooleanOption.yes && (
        <>
          <FieldLabel
            className={styles.label}
            labelText={FieldLabels.DISCLOSED_TO}
          />
          <CardTextAreaField
            name="domesticViolenceDisclosedTo"
            field={fields.disclosedTo}
            tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.DISCLOSED_TO}`}
            tooltipText=""
            placeholder={Placeholders.DISCLOSED_TO}
            readOnly={readOnly}
          />
        </>
      )}

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.OTHER_RELEVANT_INFO}
      />
      <CardTextAreaField
        name="domesticViolenceOtherRelevantInfo"
        field={fields.otherRelevantInfo}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.OTHER_RELEVANT_INFO}`}
        tooltipText=""
        placeholder={Placeholders.OTHER_RELEVANT_INFO}
        readOnly={readOnly}
      />
    </div>
  );
};
