export const Labels = {
  culturalConsiderations: {
    id: 'culturalConsiderations',
    label: 'Cultural Considerations',
    placeholder: 'Race, ethnicity, religion, community, beliefs...',
    tooltip:
      'Document identifications the member shared and be curious about their lived experiences.\n\nSome questions to consider including: Have you been treated poorly because of your beliefs /ethnicity / race / etc? Have you ever felt different from others? What are some wrong assumptions people have made about you that have caused problems?',
  },
  employment: {
    disabledLabel: 'Disabled / receiving SSDI',
    id: 'employment',
    label: 'Employment',
    placeholder: 'Describe employment...',
  },
  livingArrangement: {
    id: 'livingArrangement',
    keyLabels: {
      friends: 'With friends / roommates',
      other: 'Other',
    },
    label: 'Living arrangement',
    placeholder: 'Describe living arrangement...',
  },
  otherComments: {
    id: 'otherComments',
    label: 'Additional social/developmental status & functioning comments',
    placeholder: 'Brief additional comments not capture above...',
  },
  relationShipStatus: {
    id: 'relationshipStatus',
    keyLabels: {
      in_relationship: 'In a relationship(s)',
      other: 'Other',
    },
    label: 'Relationship status',
    placeholder: 'Describe relationship status...',
  },
  relevantSocialHistory: {
    id: 'relevantSocialOrFamilyHistory',
    label: 'Relevant social / family history',
    placeholder: 'Divorce, family history of mental illness...',
  },
};
