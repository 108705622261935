// member profile grid styling
export enum GridAreaOptions {
  NAME = 'name',
  GENDER = 'gender',
  PRONOUNS = 'pronouns',
  RACE = 'race',
  BIRTHDATE = 'birthDate',
  AGE = 'age',
  OVERVIEW = 'overview',
}

export const gridTemplate = `
"${GridAreaOptions.NAME} ${GridAreaOptions.NAME}"
"${GridAreaOptions.GENDER} ${GridAreaOptions.GENDER}"
"${GridAreaOptions.PRONOUNS} ${GridAreaOptions.PRONOUNS}"
"${GridAreaOptions.RACE} ${GridAreaOptions.RACE}"
"${GridAreaOptions.BIRTHDATE} ${GridAreaOptions.AGE}"
"${GridAreaOptions.OVERVIEW} ${GridAreaOptions.OVERVIEW}"
`;

export const gridTemplateAdjusted = `
"${GridAreaOptions.OVERVIEW} ${GridAreaOptions.OVERVIEW}"
"${GridAreaOptions.NAME} ${GridAreaOptions.NAME}"
"${GridAreaOptions.GENDER} ${GridAreaOptions.GENDER}"
"${GridAreaOptions.PRONOUNS} ${GridAreaOptions.PRONOUNS}"
"${GridAreaOptions.RACE} ${GridAreaOptions.RACE}"
"${GridAreaOptions.BIRTHDATE} ${GridAreaOptions.AGE}"
`;
