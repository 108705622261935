import { AdditionalData } from 'app/services/sentry/SentryClientImpl';

/**
 * Flattens the given object past the first level of nesting.
 *
 * If calling setContext("Additional Data", {a: {b: {c: 1}}}), Sentry will show that the value of `a` is
 * {b: "[Object]"}. To avoid that, we flatten all objects past the first level of nesting. The above example would
 * become this: {a: {"b.c": 1}}.
 */
export function flattenAdditionalData(
  additionalData: AdditionalData,
): AdditionalData {
  const flattenedData: AdditionalData = {};

  for (const key in additionalData) {
    let flattenedArrayData = additionalData[key];
    if (Array.isArray(flattenedArrayData)) {
      flattenedArrayData = flattensArrayThenToObject(flattenedArrayData);
    }
    if (isNonArrayObject(flattenedArrayData)) {
      flattenedData[key] = flattenObject(flattenedArrayData);
    } else {
      flattenedData[key] = additionalData[key];
    }
  }

  return flattenedData;
}

/**
 * Given a nested object, this function returns a flattened object.
 *
 * Example: {a: {b: {c: 1}}} becomes {"a.b.c": 1}
 */
function flattenObject(obj: AdditionalData, parentKey = ''): AdditionalData {
  const flattenedData: AdditionalData = {};
  for (const key in obj) {
    const flattenedKey = parentKey ? `${parentKey}.${key}` : key;
    let flattenedArrayData = obj[key];
    if (Array.isArray(flattenedArrayData)) {
      flattenedArrayData = flattensArrayThenToObject(flattenedArrayData);
    }
    if (isNonArrayObject(flattenedArrayData)) {
      Object.assign(
        flattenedData,
        flattenObject(flattenedArrayData, flattenedKey),
      );
    } else {
      flattenedData[flattenedKey] = obj[key];
    }
  }

  return flattenedData;
}

function isNonArrayObject(obj: any): boolean {
  return typeof obj === 'object' && !Array.isArray(obj);
}

/**
 * Flattens a given array and converts it to an object.
 * e.g {locations: [{foo: "baz"}, [{ef:"your"}]] } become this: {locations: { '0': { foo: 'baz' }, '1':{ ef: 'your' }}}.
 */
function flattensArrayThenToObject(arr: AdditionalData): AdditionalData {
  const objArray = arr.flat();
  return { ...objArray };
}
