import { createAction } from 'redux-reloaded';

import { RequestErrorState } from './schema';

export enum State {
  SUCCESS,
  ERROR,
  SAVING,
}

export enum Actions {
  REQUEST_STATE_CHANGE = 'REQUEST_STATE_CHANGE',
  RESET_RETRY_STATE = 'RESET_RETRY_STATE',
}

export const success = createAction(
  Actions.REQUEST_STATE_CHANGE,
  ({ queryName, timestamp }: { queryName: string; timestamp: string }) => ({
    queryName,
    state: State.SUCCESS,
    timestamp,
  }),
);

export const saving = createAction(
  Actions.REQUEST_STATE_CHANGE,
  ({ queryName }: { queryName: string }) => ({
    queryName,
    state: State.SAVING,
  }),
);

export const error = createAction(
  Actions.REQUEST_STATE_CHANGE,
  ({
    queryName,
    error,
  }: {
    queryName: string;
    error: RequestErrorState['error'];
  }) => ({
    error,
    queryName,
    state: State.ERROR,
  }),
);

export const resetFailedRequest = createAction(
  Actions.RESET_RETRY_STATE,
  (payload: string[]) => payload,
);
