import {
  GetMemberCareTeam_getMember_clinicalCareTeam,
  GetMemberCareTeam_getMember_coachingCareTeam,
  GetMemberCareTeam_getMember_riskStatus,
} from 'app/care-team/generated/GetMemberCareTeam';
import { Coach, Psychiatrist, Therapist } from 'app/care-team/types';
import { GuardianRelationshipType } from 'generated/globalTypes';

// enum for active view
export enum ActiveView {
  ACTIVE_CARE_TEAM = 'ActiveCareTeam',
  PAST_CARE_TEAM = 'PastCareTeam',
}

export type State = {
  activeMemberId: string | null;
  isFetching: boolean;
  hasIntakeSent: boolean;
  dateOfBirth: string | null;
  error: Error | null;
  activeView: ActiveView;
  coachingCareTeam?: GetMemberCareTeam_getMember_coachingCareTeam | null;
  clinicalCareTeam?: GetMemberCareTeam_getMember_clinicalCareTeam | null;
  riskStatus?: GetMemberCareTeam_getMember_riskStatus | null;
  selectedCareTeamMemberProfile: Coach | Therapist | Psychiatrist | null;
  memberGuardianRelationshipType?: GuardianRelationshipType | string | null;
};

export const getInitialCareTeamState = (): State => {
  return {
    // TODO: remove this once we have a store that centralizes the active member
    activeMemberId: null,
    activeView: ActiveView.ACTIVE_CARE_TEAM,
    clinicalCareTeam: null,
    coachingCareTeam: null,
    dateOfBirth: null,
    error: null,
    hasIntakeSent: false,
    isFetching: true,
    memberGuardianRelationshipType: null,
    riskStatus: null,
    selectedCareTeamMemberProfile: null,
  };
};
