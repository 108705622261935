import React, { Fragment, ReactElement, ReactNode, useMemo } from 'react';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';

import { Tab, TabState } from './Tab';
import { Props as TabPanelProps } from './TabPanel';
import styles from './Tabs.module.scss';

export type Tab = {
  label: string;
  key: string;
  tabState: TabState;
  component: ReactElement<TabPanelProps>;
};

type TabsProps = {
  onTabClick: (tab: string) => void;
  currentTabKey: string;
  tabs: Tab[];
  auditLogView?: ReactNode;
};

export function Tabs(props: TabsProps) {
  const { tabs, currentTabKey } = props;

  const components: { [key: string]: ReactElement } = useMemo(() => {
    const components: { [key: string]: ReactElement } = {};
    tabs.forEach(({ component, key }) => {
      components[key] = <Fragment key={`${key}-wrapper`}>{component}</Fragment>;
    });
    return components;
  }, [tabs]);

  const onTabClick = (key: string) => props.onTabClick(key);

  const widths = props.auditLogView
    ? [COLUMN_WIDTH.TWO, COLUMN_WIDTH.SEVEN, COLUMN_WIDTH.THREE]
    : [COLUMN_WIDTH.TWO, COLUMN_WIDTH.TEN];

  return (
    <Grid className={styles.root} spacing={0}>
      <Columns
        className={[
          styles.col,
          props.auditLogView ? styles.auditView : '',
        ].join(' ')}
        widths={widths}
      >
        <ul className={styles.tabs}>
          {tabs.map(({ label, key, tabState }) => (
            <div
              tabIndex={0}
              role="button"
              data-testid={`${key.toLowerCase()}-button`}
              key={`${key}-li`}
              onClick={() => onTabClick(key)}
              onKeyDown={() => onTabClick(key)}
            >
              <Tab
                data-testid={`${key.toLowerCase()}-tab`}
                tabState={tabState}
                active={key === currentTabKey}
                label={label}
              />
            </div>
          ))}
        </ul>
        {components[currentTabKey || tabs[0].key]}
        {props.auditLogView}
      </Columns>
    </Grid>
  );
}
