import { createReducer } from 'redux-reloaded';

import {
  initializeMultiTabState,
  removeMemberFromTabList,
  setActiveTab,
} from './actions';
import memberChartStorage, { MemberChartState } from './memberChartStorage';
import { getInitialMultiTabState, State } from './schema';

export const reducer = createReducer<State>(getInitialMultiTabState());

reducer.on(initializeMultiTabState, (state, { payload }) => {
  const item: MemberChartState = memberChartStorage.get();
  return {
    ...state,
    activeTab: item.activeMemberId ?? item.activeTab,
    initialized: true,
    openTabs: item.openTabs,
  };
});

reducer.on(setActiveTab, (state, { payload }) => {
  const { activeTab } = payload;
  const openTabs = getUpdateOpenTabState(state, activeTab);
  return {
    ...state,
    activeTab,
    openTabs,
  };
});

reducer.on(removeMemberFromTabList, (state, { payload }) => {
  const { idToRemove: id, activeTab } = payload;
  const openTabs = [...state.openTabs];
  const index = openTabs.indexOf(id);
  openTabs.splice(index, 1);
  return {
    ...state,
    activeTab,
    openTabs,
  };
});

function getUpdateOpenTabState(state: State, id: string) {
  const openTabs = [...state.openTabs];
  if (!openTabs.find((_) => _ === id)) {
    openTabs.push(id);
  }
  return openTabs;
}
