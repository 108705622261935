import { TopNav } from 'app/top-nav/TopNav';
import React, { Fragment, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

/** The core "frame" / "layout" of the app - e.g. top-bar, content area etc */
interface ScreenProps extends RouteComponentProps {
  children?: ReactNode;
}

function Screen({ history, children }: ScreenProps) {
  return (
    <Fragment>
      <TopNav redirectTo={history.push} dataTestId="topNav" />
      {children}
    </Fragment>
  );
}

export default withRouter(Screen);
