import React from 'react';

import { classNameCombiner } from 'utils';

import styles from './SpecialtyTag.module.scss';

export const SpecialtyTag = ({
  text,
  className = '',
}: {
  text: string;
  className?: string;
}) => {
  const textKebabCase = text.replace(/ /g, '-').toLowerCase();

  // we use a div container here to make the same height for the elements that are wrapped in tooltips and that are not
  return (
    <div>
      <span
        data-testid={`specialty-tag-${textKebabCase}`}
        className={classNameCombiner([styles.tagContainer, className])}
      >
        {text}
      </span>
    </div>
  );
};
