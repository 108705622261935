import React from 'react';

import styles from './LineChart.module.scss';

type DotVariant = 'grey' | 'yellow' | 'red';

/** A shaded dot marker used to visualize a numerical value */
function Dot(props: { active?: boolean; variant: DotVariant }) {
  const { variant } = props;
  const classes = [styles.dot];
  if (props.active) {
    classes.push(styles.active);
  }
  if (variant === 'red') {
    classes.push(styles.red);
  } else if (variant === 'yellow') {
    classes.push(styles.yellow);
  } else if (variant === 'grey') {
    classes.push(styles.grey);
  }
  return <div className={classes.join(' ')} />;
}

/**  Set of shaded 3 dots, representing a value in the range of 0-3 - e.g. [X][X][] for 2 */
export function ThreeDots(props: { value: number | undefined }) {
  const { value = 0 } = props;
  let variant: DotVariant = 'red'; // red by default
  switch (value) {
    case 1:
      variant = 'grey';
      break;
    case 2:
      variant = 'yellow';
      break;
  }
  return (
    <div className={styles.container}>
      <Dot active={value >= 1} variant={variant} />
      <Dot active={value >= 2} variant={variant} />
      <Dot active={value >= 3} variant={variant} />
    </div>
  );
}
