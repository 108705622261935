import { AdditionalDemographicInfo } from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/AdditionalDemographicInfo';
import { StateSlice } from 'app/state/status/types/StateSlice';

export interface MemberProfileCardFields {
  preferredName: string | null;
  gender: string | null;
  pronouns: string | null;
  race: string | null;
  birthDate: ISODateString | null;
  age: number | null;
}

export enum ProfileCardFields {
  NAME = 'Preferred Name',
  GENDER = 'Gender',
  PRONOUNS = 'Pronouns',
  RACE = 'Race / Ethnicity',
  BIRTHDATE = 'Date of Birth',
  AGE = 'Age',
  OVERVIEW = 'Overview',
}

export type MemberProfileCardFieldValue = MemberProfileCardFields[keyof MemberProfileCardFields];

export interface MutationResponse {
  success: boolean;
  data: AdditionalDemographicInfo | null;
  errorMessage?: string;
}

export type AdditionalDemographicInfoValues = {
  id?: string;
  value: string;
  tooltipText: string;
  sourceVersion?: string;
};

export interface VersionedAdditionalDemographicInfo
  extends AdditionalDemographicInfo {
  sourceVersion?: string;
  source: string;
}

export interface AdditionalDemographicInfoHookState {
  additionalDemographicInfo: StateSlice<AdditionalDemographicInfoValues>;
  saveAdditionalDemographicInfo: (
    input: VersionedAdditionalDemographicInfo,
  ) => Promise<MutationResponse>;
  createAdditionalDemographicInfo: (
    input: VersionedAdditionalDemographicInfo,
  ) => Promise<MutationResponse>;
  updateAdditionalDemographicInfo: (
    input: VersionedAdditionalDemographicInfo,
  ) => Promise<MutationResponse>;
}
