import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { MemberSupportOperationType } from 'app/care-team/add-member-support-modal/enums';
import { ModalLoader } from 'app/care-team/modal-loader/ModalLoader';
import { Width } from 'types/StyleTypes';
import { careTeamAddMemberSupportClicked } from 'app/state/amplitude/actions/careTeam';
import { ReactComponent as Close } from 'assets/close-icon.svg';
import useStringState from 'hooks/useRequiredText';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextArea } from 'shared-components/text-area/TextArea';

import styles from './AddMemberSupportModal.module.scss';
import useAddMemberSupport, {
  AddMemberSupportErrorState,
} from './hooks/useAddMemberSupport';
import { AddMemberSupportModalButton } from './modal-button/AddMemberSupportModalButton';

export interface AddMemberSupportModalProps {
  onCancel: () => void;
  open: boolean;
  memberId: string;
  onMemberSupportAdded?: () => void;
  operationType: MemberSupportOperationType;
}

const operationTypes = {
  add: {
    buttonLabel: 'Yes, add Member Support',
    description:
      'Please provide a reason for adding Member Support to the member&apos;s Care Team.',
    loadingDescription: 'Add Member Support',
    reason: 'Reason for adding Member Support...',
    title: 'Add Member Support',
  },
  ping: {
    buttonLabel: 'Yes, ping Member Support',
    description:
      'The member support will be pinged. Are you sure you want to ping the member support?',
    loadingDescription: 'Ping Member Support',
    reason: 'Reason for pinging a member support...',
    title: 'Ping Member Support',
  },
};

export const AddMemberSupportModal = ({
  open,
  onCancel,
  memberId,
  onMemberSupportAdded,
  operationType = MemberSupportOperationType.ADD,
}: AddMemberSupportModalProps) => {
  const {
    value: addMemberSupportNote,
    setValue: setAddMemberSupportNote,
    isEmpty: isMemberSupportNoteEmpty,
  } = useStringState('');
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState<AddMemberSupportErrorState | undefined>(
    undefined,
  );
  const dispatch = useDispatch();

  const { addMemberSupport } = useAddMemberSupport();

  const closeModal = () => {
    setError(undefined);
    onCancel();
  };

  const onAddMemberSupportButtonClicked = async () => {
    setError(undefined);
    setShowLoader(true);

    try {
      await addMemberSupport({ memberId, reason: addMemberSupportNote });
      dispatch(
        careTeamAddMemberSupportClicked({
          memberId,
        }),
      );
      // execute callback if provided
      onMemberSupportAdded?.();
    } catch (error) {
      setError(error);
    } finally {
      setShowLoader(false);
    }
  };

  const labels = operationTypes[operationType];

  return (
    <Dialog open={open} className={styles.addMemberSupportModal}>
      {(!showLoader || error) && (
        <DialogTitle className={styles.modalTitle}>
          <div>{error?.title ?? labels.title}</div>{' '}
          <Close onClick={closeModal} className={styles.close} />
        </DialogTitle>
      )}

      <DialogContent
        data-testid="add-member-support-dialog-content"
        className={styles.modalBody}
      >
        {!showLoader && !error && (
          <>
            <p className={styles.text}>{labels.description}</p>

            <TextArea
              textAreaClassName={styles.addMemberSupportNote}
              placeholder={labels.reason}
              width={Width.FULL}
              value={addMemberSupportNote}
              name="add-member-support-note"
              onChange={setAddMemberSupportNote}
            />

            <p className={styles.noteText}>
              Note: This message will be shown for the member support.
            </p>

            <div className={styles.buttonContainer}>
              <AddMemberSupportModalButton onClick={onCancel}>
                Cancel
              </AddMemberSupportModalButton>
              <AddMemberSupportModalButton
                disabled={isMemberSupportNoteEmpty()}
                testId="add-member-support-button"
                primary={true}
                onClick={onAddMemberSupportButtonClicked}
              >
                {labels.buttonLabel}
              </AddMemberSupportModalButton>
            </div>
          </>
        )}

        {showLoader && <ModalLoader description={labels.loadingDescription} />}

        {error && (
          <p
            data-testid="add-member-support-modal-error-text"
            className={styles.text}
          >
            {error.message}
          </p>
        )}

        {!showLoader && error && (
          <div className={styles.buttonContainer}>
            <AddMemberSupportModalButton
              primary={!error.errorType}
              onClick={onAddMemberSupportButtonClicked}
            >
              Try again
            </AddMemberSupportModalButton>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
