import {
  field,
  FieldDefinitions,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  Assessment,
  Assessment_CPTCode as CPTCode,
  Assessment_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/progress/Assessment';
import {
  ProgressAssessmentSection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { Width } from 'types/StyleTypes';
import { TextAreaField } from 'app/notes-ui/forms/fields';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import {
  NoteFormControlLabel,
  NoteFormControlLabelStyles,
} from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { NumberInput } from 'app/notes-ui/forms/form-controls/NumberInputV2';
import {
  buildCodeSetFieldDefs,
  CodeSetFields,
} from 'app/notes-ui/shared/code-set/CodeSetFields';
import { optionalField } from 'app/notes-ui/shared/ValidationRules';
import {
  codeSetsFromAssessment,
  NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
} from 'app/notes-ui/utils';
import { acceptedPsychiatryCptCodes, filterCptCodes } from 'utils/notes';
import React from 'react';

const formControlStyles: NoteFormControlLabelStyles = {
  label: styles.label,
  subtext: styles.subtext,
};

type Props = {
  appointmentId: string;
  onSubmit: (data: ProgressAssessmentSection) => void;
  updateDraftNoteState: (data: ProgressAssessmentSection) => void;
  initialValue?: Assessment;
  disabled?: boolean;
  savingNote?: boolean;
};

const fieldDefs: FieldDefinitions<Assessment> = {
  appointmentDurationInMinutes: field({ rules: [optionalField] }),
  appointmentId: field(),
  todaysUpdate: field(),
  version: field<Assessment_Version>({ default: Assessment_Version.v0 }),
  ...buildCodeSetFieldDefs(),
};

export const validate = (data: Assessment | null) =>
  data !== null && validateForm(data, fieldDefs);

export function AssessmentFormV2(props: Props) {
  const { disabled, initialValue, appointmentId } = props;

  const { fields, getValue } = useForm<Assessment>(
    fieldDefs,
    {
      ...initialValue,
      appointmentId,
      codeSets: codeSetsFromAssessment(initialValue),
    } as Assessment,
    {
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
      onStateChange: async () => {
        await onSubmit();
      },
    },
  );

  const onSubmit = async () => {
    if (disabled) return;

    props.onSubmit({
      data: getValue(),
      name: PsychiatrySectionName.ASSESSMENT,
    });
  };

  return (
    <>
      <CodeSetFields
        disabled={disabled}
        cptCodeEnum={filterCptCodes(CPTCode, acceptedPsychiatryCptCodes)}
        field={fields.codeSets}
      />

      <div className={styles.formGroup}>
        <NoteFormControlLabel
          label="Total minutes spent on appointment"
          subtext="Face-to-face and non face-to-face"
          tooltip="i.e., reviewing clinical documentation or test results, orders for medication/labs, case management, and/or other administrative tasks related to this appointment."
        >
          <NumberInput
            placeholder="0"
            disabled={disabled}
            value={fields.appointmentDurationInMinutes.value}
            label=""
            className={styles.numberTextField}
            name="appointmentDurationInMinutes"
            onChange={(durationInMinutes) => {
              let value: number = durationInMinutes;
              if (isNaN(value)) value = 0;
              fields.appointmentDurationInMinutes.setValue(value);
            }}
          />
        </NoteFormControlLabel>
      </div>
      <TextAreaField
        disabled={disabled}
        testId="todaysUpdate"
        label="Formulation and medical decision making"
        field={fields.todaysUpdate}
        placeholder="Type here..."
        width={Width.FULL}
        formControlStyles={formControlStyles}
        rows={1}
        autoExpand={true}
      />
    </>
  );
}
