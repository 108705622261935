import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  GetLatestCompleteClinicalAppointmentQuery,
  GetLatestCompleteClinicalAppointmentQueryVariables,
} from '@headspace/carehub-graphql/dist/appointments/generated/GetLatestCompleteClinicalAppointmentQuery';
import { getLatestCompleteClinicalAppointmentQuery } from 'app/appointments/queries';
import { ILogger } from 'app/state/log/Logger';
import {
  AppointmentIdWithNoteType,
  clinicalAppointNoteTypeToNoteType,
} from 'app/typeUtils';

import { ClinicalAppointmentsAPI } from './ClinicalAppointmentsAPI';
import { ClinicalAppointmentsApiConfig } from './types';

export class ClinicalAppointmentsImpl implements ClinicalAppointmentsAPI {
  private apollo: ApolloClient<NormalizedCacheObject>;

  private logger: ILogger;

  constructor(config: ClinicalAppointmentsApiConfig) {
    this.apollo = config.apolloClient;
    this.logger = config.logger;
  }

  async getLatestCompleteClinicalAppointmentAndNoteType(
    appointmentId: string,
  ): Promise<AppointmentIdWithNoteType> {
    this.logger.info(
      '[getLatestCompleteClinicalAppointmentAndNoteType] Fetching latest complete appointment',
      {
        appointmentId,
      },
    );

    const { data, errors } = await this.apollo.query<
      GetLatestCompleteClinicalAppointmentQuery,
      GetLatestCompleteClinicalAppointmentQueryVariables
    >({
      query: getLatestCompleteClinicalAppointmentQuery,
      variables: {
        input: {
          appointmentId,
        },
      },
    });
    if (errors && !data) {
      this.logger.error(
        new Error(
          'ClinicalAppointmentsImpl.getLatestCompleteClinicalAppointmentAndNoteType: Error fetching latest completed appointment',
        ),
        { appointmentId, errors },
      );
      return null;
    }

    if (
      !data?.getLatestCompleteAppointment?.appointment ||
      !data?.getLatestCompleteAppointment?.appointment?.clinicalNote
    ) {
      this.logger.info(
        '[getLatestCompleteClinicalAppointmentAndNoteType] No appointment or clinical note returned from query',
        {
          appointmentId,
          clinicalNoteData:
            data?.getLatestCompleteAppointment?.appointment?.clinicalNote,
          errors,
          latestCompletedAppointmentId:
            data?.getLatestCompleteAppointment?.appointment?.id,
        },
      );
      return null;
    }

    this.logger.info(
      '[getLatestCompleteClinicalAppointmentAndNoteType] Found latest complete appointment',
      {
        appointmentId,
        clinicalNoteData:
          data.getLatestCompleteAppointment?.appointment?.clinicalNote,
        latestCompletedAppointmentId:
          data.getLatestCompleteAppointment?.appointment.id,
      },
    );

    return {
      id: data.getLatestCompleteAppointment.appointment.id,
      noteType: clinicalAppointNoteTypeToNoteType(
        data.getLatestCompleteAppointment.appointment.clinicalNote.noteType,
      ),
    };
  }
}
