import { ReportProblemOutlined as WarningIcon } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Button } from 'shared-components/button/Button';
import styles from 'app/notes-ui/NoteHeader.module.scss';
import React from 'react';

export interface ModalProps {
  onClick: () => Promise<void>;
  onClose?: () => void;
  isOpen: boolean;
}

export interface BaseModalProps extends ModalProps {
  modalName: string;
  headerText: string;
  bodyText: string;
}

export function BaseModal({
  onClick,
  onClose,
  isOpen,
  modalName,
  headerText,
  bodyText,
}: BaseModalProps) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className={styles.noteDeletionModal}
    >
      <DialogTitle className={styles.modalTitle}>
        <WarningIcon />
        <span className={styles.titleText}>{headerText}</span>
      </DialogTitle>
      <DialogContent className={styles.modalBody}>
        <p className={styles.modalText}>{bodyText}</p>
        <div className={styles.buttonContainer}>
          <Button
            testId={`cancelModalBtn${modalName}`}
            size="medium"
            onClick={onClose}
            variant="outlined"
            fullWidth={false}
          >
            Cancel
          </Button>
          <Button
            testId={`yesBtn${modalName}`}
            size="medium"
            className={styles.deleteBtn}
            onClick={onClick}
            fullWidth={false}
          >
            Yes
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
