export const ALLOWED_FIELDS = new Set([
  'id',
  'ids',
  'memberId',
  'patientId',
  'memberIds',
  'itemId',
  'recipientId',
  'creatorId',
  'gingerId',
  'contentId',
  'userId',
  'listenerId',
  'groupId',
  'coachingSessionId',
  'tag',
  'tags',
  'shareWithSystems',
  'pagination',
  'sourceVersion',
  'cursor',
  'maxItemsPerPage',
  'sortField',
  'sortOrder',
]);
