import { InboxSections } from 'app/inbox/types';

export const COACH_TODAYS_INBOX_SECTIONS = [
  InboxSections.RISKS,
  InboxSections.CONVERSATIONS_AND_TASKS,
  InboxSections.SCHEDULED_CHECKINS,
  InboxSections.COMPLETED,
];

export const CLINICIAN_TODAYS_INBOX_SECTIONS = [
  InboxSections.RISKS,
  InboxSections.TASKS,
  InboxSections.APPOINTMENTS,
  InboxSections.COMPLETED,
];
