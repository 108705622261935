import { Metadata } from 'app/member-chart-cards/tasks/TasksTooltip';
import { Item, TaskItem } from 'app/member-chart-cards/tasks/types';
import moment from 'moment';
import { isUnChecked } from 'shared-components/ThreeStateCheckbox/ThreeStateCheckbox';

import { formatDateTimeWithSeconds } from './dateTime';

export function filterTasksToDisplay<T extends Item>(
  items: T[],
  show: boolean,
): T[] {
  return items.filter(({ state }) => show || isUnChecked(state));
}

export const sortTasksToDisplay = (
  tasks: Array<TaskItem>,
  metadata: Record<string, Metadata>,
  tz: string | null,
): Array<TaskItem> => {
  const timezone = tz ?? 'UTC';
  return tasks.sort((a, b) => {
    const aCreatedAt = metadata[a.id]?.createdAt
      ? moment
          .tz(metadata[a.id]?.createdAt, formatDateTimeWithSeconds, timezone)
          .unix()
      : null;
    const bCreatedAt = metadata[b.id]?.createdAt
      ? moment
          .tz(metadata[b.id]?.createdAt, formatDateTimeWithSeconds, timezone)
          .unix()
      : null;
    if (!aCreatedAt || !bCreatedAt) return 0;
    return bCreatedAt - aCreatedAt;
  });
};
