import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { VaultItem_SchemaType } from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import { Link, SvgIcon, Typography } from '@mui/material';
import { NoteRoutes, Routes } from 'app/top-nav/Routes';
import { OutOfSessionOrTerminationNote } from 'app/vault/hooks/NonAppointments/useOutOfSessionAndTerminationNotes';
import React from 'react';
import { useHistory } from 'react-router';

import { ReactComponent as LockedIcon } from './icons/Lock.svg';
import { ReactComponent as EditIcon } from './icons/Pencil.svg';
import styles from './NoteCard.module.scss';

export const NoteStatusCard = (props: {
  note: OutOfSessionOrTerminationNote;
  patientId: string;
  onClick?: (note: OutOfSessionOrTerminationNote) => Promise<void>;
}) => {
  const { note } = props;
  const { vaultItemId, status, contents } = note;
  const noteRouteType =
    contents.schema === VaultItem_SchemaType.vault_clinical_notes_out_of_session
      ? NoteRoutes.OUT_OF_SESSION
      : NoteRoutes.TERMINATION;
  const history = useHistory();

  const onClick = async () => {
    await props.onClick?.(note);
    history.push(
      `${Routes.NOTES.replace(
        ':patientId',
        contents.note.patientId,
      )}/${noteRouteType}/${vaultItemId}`,
    );
  };

  return (
    <article className={styles.statusColumn}>
      {status === Metadata_NoteStatus.signed_and_locked_note && (
        <Typography
          data-testid="appt-note-status"
          component="div"
          className={styles.status}
        >
          <span className={styles.icon}>
            <SvgIcon viewBox="0 0 32 32" fontSize="small">
              <LockedIcon />
            </SvgIcon>
          </span>
          <span
            className={styles.iconLabel}
            data-testid="appt-note-status-formatted"
          >
            Signed & Locked
          </span>
        </Typography>
      )}

      {status === Metadata_NoteStatus.draft_note && (
        <Typography
          data-testid="appt-note-status"
          component="div"
          className={styles.status}
        >
          <span className={styles.icon}>
            <SvgIcon viewBox="0 0 32 32" fontSize="small">
              <EditIcon />
            </SvgIcon>
          </span>
          <span
            className={styles.iconLabel}
            data-testid="appt-note-status-formatted"
          >
            Draft
          </span>
        </Typography>
      )}

      <Typography component="div" className={styles.link}>
        <Link
          data-testid="appt-note-status-action-link"
          className={styles.capitalize}
          onClick={onClick}
        >
          view note
        </Link>
      </Typography>
    </article>
  );
};
