import { InboxItem, InboxItemState, InboxSections } from 'app/inbox/types';
import { ConvoPresence } from 'app/state/inbox/schema';
import { useAppState } from 'app/state/index';
import { State } from 'app/state/schema';
import { ChatConversationState } from 'generated/globalTypes';

export const selectCurrentPubnubChannelId = () => {
  return useAppState((state: State) => {
    const {
      inbox: { currentChannelId },
    } = state;
    return currentChannelId;
  });
};

export const selectMessages = (channel: string) => {
  return useAppState((state: State) => {
    const {
      inbox: { messagesMap },
    } = state;
    return messagesMap[channel] ?? [];
  });
};

export const selectConversationIdByMemberId = (memberId: string) => {
  return useAppState((state: State) => {
    const { inbox } = state;
    const { inboxItems } = inbox;
    return inboxItems[memberId]?.conversationId ?? null;
  });
};

export const selectInboxItemByChannelId = (
  channel: string,
): InboxItem | undefined => {
  return useAppState((state: State) => {
    const { inbox } = state;
    const { inboxItems } = inbox;
    return Object.values(inboxItems).find((_) =>
      Boolean(_.memberCoachChannelId === channel),
    );
  });
};

export const selectChannelByMemberId = (
  memberId: string,
): string | undefined => {
  return useAppState((state: State) => {
    const { inbox } = state;
    const { inboxItems } = inbox;
    return inboxItems[memberId]?.memberCoachChannelId;
  });
};

export const selectUnreadMessages = (channelId: string) => {
  return useAppState((state: State) => {
    const {
      inbox: { unreadMessagesMap },
    } = state;
    return channelId in unreadMessagesMap ? unreadMessagesMap[channelId] : [];
  });
};

export const selectInboxItemByMemberId = (
  memberId: string,
): InboxItem | undefined => {
  return useAppState((state: State) => {
    const { inbox } = state;
    const { inboxItems } = inbox;
    return inboxItems[memberId];
  });
};

export const selectShowDrawer = (): boolean => {
  return useAppState((state: State) => {
    const { inbox } = state;
    const { showInboxDrawer } = inbox;
    return showInboxDrawer;
  });
};

export const selectSelectedTab = () => {
  return useAppState((state: State) => {
    const {
      inbox: { selectedTab },
    } = state;
    return selectedTab;
  });
};

export const selectConversationState = (channelId: string) => {
  return useAppState((state: State) => {
    const {
      inbox: { conversationStateMap },
    } = state;
    if (channelId in conversationStateMap) {
      return conversationStateMap[channelId];
    }
    return null;
  });
};

export const selectIsChatRelatedDataLoaded = (channelId: string) => {
  return useAppState((state: State) => {
    const {
      inbox: { currentChannelId, conversationStateMap },
      conversationsTimetokens: { timetokensMap },
    } = state;
    if (
      currentChannelId &&
      channelId in conversationStateMap &&
      channelId in timetokensMap
    ) {
      return true;
    }
    return false;
  });
};

export interface InboxSegmentState {
  item: InboxItemState;
  presence: MaybeUndefined<ConvoPresence>;
  isOpenConvo: boolean;
  unreadMessageCount: number;
  timestamp?: string;
  latestWriteTimestamp?: string;
}

export function selectInboxSegmentState(
  state: State,
  memberId: string,
  section: InboxSections,
): InboxSegmentState {
  const {
    inbox: {
      presenceByChannelId,
      inboxItems,
      unreadMessagesMap,
      conversationStateMap,
    },
    conversationsTimetokens: { timetokensMap },
  } = state;

  const item = inboxItems[memberId] ?? {};

  const memberCoachChannelId = item?.memberCoachChannelId;
  const riskTaskDetails = item?.riskTaskDetails;

  const presence: MaybeUndefined<ConvoPresence> = memberCoachChannelId
    ? presenceByChannelId[memberCoachChannelId]
    : undefined;
  const isOpenConvo: boolean = memberCoachChannelId
    ? conversationStateMap[memberCoachChannelId]?.state ===
      ChatConversationState.OPEN
    : false;
  const unreadMessageCount =
    item.memberCoachChannelId && unreadMessagesMap[item.memberCoachChannelId]
      ? unreadMessagesMap[item.memberCoachChannelId].length
      : 0;

  let timestamp;
  const latestWriteTimestamp = memberCoachChannelId
    ? timetokensMap[memberCoachChannelId]?.latestWriteTimestamp
    : undefined;

  if (section === InboxSections.RISKS && riskTaskDetails) {
    timestamp = riskTaskDetails.latestCreatedTaskDatetime;
  } else if (section === InboxSections.CONVERSATIONS_AND_TASKS && isOpenConvo) {
    timestamp = latestWriteTimestamp;
  }
  return {
    isOpenConvo,
    item,
    latestWriteTimestamp: latestWriteTimestamp ?? undefined,
    presence,
    timestamp: timestamp ?? undefined,
    unreadMessageCount,
  };
}
