/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SessionFormat } from 'app/member-chart-cards/scheduler/types';
import {
  getInitialValues,
  mapStringToSessionFormat,
} from 'app/member-chart-cards/scheduler/utils';
import moment from 'moment';

import { SchedulerService } from './SchedulerService';
import { getInitialSchedulerState, SchedulerView } from './schema';
import {
  ChooseCoachingSessionPayload,
  CreateCoachingSessionPayload,
  SetSessionTimesPayload,
  UpdateCoachingSessionPayload,
} from './types';

const schedulerSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(
        SchedulerService.getMemberSessionUsage.fulfilled,
        (state, action) => {
          state.memberSessionUsage = state.memberSessionUsage ?? {};

          if (action.payload && action.payload.memberId) {
            state.memberSessionUsage[action.payload.memberId] = action.payload;
          }

          state.memberSessionUsageLoading = false;
        },
      )
      .addCase(SchedulerService.getMemberSessionUsage.pending, (state) => {
        state.memberSessionUsageLoading = true;
      })
      .addCase(SchedulerService.getMemberSessionUsage.rejected, (state) => {
        state.memberSessionUsageLoading = false;
      })
      .addCase(
        SchedulerService.getMemberUpcomingCoachingSessions.fulfilled,
        (state, action) => {
          state.sessions = action.payload ?? [];
          state.schedulerError = undefined;
          state.schedulerLoading = false;
          state.hasUpcomingVideoSessions = state.sessions.some((session) =>
            session.sessions.some(
              (s) =>
                mapStringToSessionFormat(s.sessionFormat) ===
                SessionFormat.VIDEO,
            ),
          );
        },
      )
      .addCase(
        SchedulerService.getMemberUpcomingCoachingSessions.rejected,
        (state, action) => {
          state.sessions = [];
          state.schedulerError =
            action.payload?.error || action.payload?.message;
          state.schedulerLoading = false;
        },
      )
      .addCase(
        SchedulerService.getMemberUpcomingCoachingSessions.pending,
        (state) => {
          state.schedulerLoading = true;
          state.schedulerError = undefined;
        },
      )
      .addCase(
        SchedulerService.getAvailabilitiesForMe.fulfilled,
        (state, action) => {
          state.coachAvailabilitySchedule = action.payload;
        },
      )
      .addCase(SchedulerService.getAvailabilitiesForMe.rejected, (state) => {
        state.coachAvailabilitySchedule = [];
      });
  },
  initialState: getInitialSchedulerState(),
  name: 'scheduler',
  reducers: {
    chooseCoachingSession: (
      state,
      action: PayloadAction<ChooseCoachingSessionPayload>,
    ) => {
      const { coachTimeZone, coachingSession, recurrence } = action.payload;
      state.coachingSession = getInitialValues({
        coachTimeZone,
        existingCoachingSession: coachingSession,
        recurrence,
      });
      state.selectedCoachingSession = { coachingSession, recurrence };
      state.currentView = SchedulerView.SESSION_FORM;
    },
    createCoachingSession: (
      state,
      action: PayloadAction<CreateCoachingSessionPayload>,
    ) => {
      const { coachTimeZone } = action.payload;
      state.coachingSession = getInitialValues({ coachTimeZone });
      state.selectedCoachingSession = undefined;
      state.currentView = SchedulerView.SESSION_FORM;
    },
    resetSchedulerView(state) {
      state.coachingSession = undefined;
      state.selectedCoachingSession = undefined;
      state.currentView = SchedulerView.SUMMARY;
    },
    setSchedulerView(state, action) {
      state.currentView = action.payload.currentView;
    },
    setSessionTimes: (state, action: PayloadAction<SetSessionTimesPayload>) => {
      const { startTime } = action.payload;
      if (state.coachingSession) {
        const endTime = moment(startTime)
          .add(state.coachingSession.duration, 'minutes')
          .toISOString();
        state.selectedSessionTimes = { endTime, startTime };
      }
    },
    toggleD2cSessionInfoBanner(state, action) {
      state.showD2cSessionInfoBanner = action.payload.showD2cSessionInfoBanner;
      if (!action.payload.showD2cSessionInfoBanner) {
        state.memberSessionUsage = undefined;
      }
    },
    updateCoachingSession: (
      state,
      action: PayloadAction<UpdateCoachingSessionPayload>,
    ) => {
      state.coachingSession = {
        ...state.coachingSession,
        ...action.payload.coachingSession,
      };
    },
  },
});

export const { reducer } = schedulerSlice;

export const {
  chooseCoachingSession,
  createCoachingSession,
  updateCoachingSession,
  resetSchedulerView,
  setSessionTimes,
  setSchedulerView,
  toggleD2cSessionInfoBanner,
} = schedulerSlice.actions;
