import { Fields } from '@ginger.io/react-use-form';
import { DatePicker } from 'shared-components/form-inputs/DatePicker';
import {
  NoteFormControlLabel,
  NoteFormControlLabelStyles,
} from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import React from 'react';

import { StartDate } from '../types';

interface Props {
  fields: Fields<StartDate>;
  label?: string;
  className?: string;
  inputClassName?: string;
  noteStyles?: NoteFormControlLabelStyles;
  onSelectDate?: (selectedDate: string | null) => void;
  disabled?: boolean;
}

export function DateInputField(props: Props) {
  const {
    label = 'Date',
    fields,
    className,
    inputClassName,
    noteStyles,
    disabled = false,
  } = props;

  const handleUpdate = (value: string | null) => {
    const [year, month, date] = (value || '').split('-');
    fields.year.setValue(parseInt(year));
    fields.month.setValue(parseInt(month));
    fields.date.setValue(parseInt(date));
    const selectedDate = value ? `${year}-${month}-${date}` : null;
    if (props.onSelectDate) {
      props.onSelectDate(selectedDate);
    }
  };
  return (
    <NoteFormControlLabel label={label} styles={noteStyles}>
      <DatePicker
        className={className}
        inputClassName={inputClassName}
        name={label}
        onChange={handleUpdate}
        value={getDateValue(fields)}
        fullWidth={true}
        disabled={disabled}
      />
    </NoteFormControlLabel>
  );
}

function getDateValue(dateValue: Fields<StartDate>) {
  const hasEmptyComponent = Object.values(dateValue).some(
    ({ value }) => value === undefined || value === 0,
  );

  if (hasEmptyComponent) {
    return null;
  }

  const { date, month, year } = dateValue;
  const padWithZero = (value: number) => `${value}`.padStart(2, '0');
  return `${padWithZero(year.value)}-${padWithZero(month.value)}-${padWithZero(
    date.value,
  )}`;
}
