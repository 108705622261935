import { FieldDefinitions, useForm } from '@ginger.io/react-use-form';
import { Fields } from '@ginger.io/react-use-form/dist/types';
import { Risks } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/RiskAssessment';
import { TYPING_THRESHOLD } from 'app/notes-ui/utils';
import { useAppState } from 'app/state';

import { CoachNotesItem, NoteOrSubsection } from './CoachNotesTypes';

type NestedRisksFieldName = 'riskAssessments' | 'risks';
type UseCoachNotesFormParams<T extends Record<string, any>> = {
  validationSchema: FieldDefinitions<T>;
  onStateChange: (noteDraft: CoachNotesItem) => void;
  sessionCount?: number;
  canContainNestedRisks?: boolean;
  nestedRisksFieldName?: NestedRisksFieldName;
  typingThreshold?: number;
};

export type CoachFormHook<T> = {
  fields: Fields<T>;
  validate: () => Promise<boolean>;
  getValue: () => T;
  onChangeRisksForm: (risks: Partial<Risks>) => void;
  risks?: Risks;
};

export function useCoachNotesForm<T extends Record<string, any>>({
  validationSchema,
  onStateChange,
  sessionCount,
  canContainNestedRisks = false,
  nestedRisksFieldName = 'riskAssessments',
  typingThreshold = TYPING_THRESHOLD,
}: UseCoachNotesFormParams<T>): CoachFormHook<T> {
  const selectedNote = useAppState((_) => _.coachNotes.selectedNote);
  const { data } = selectedNote ?? {};
  const risks = getInitialRiskAssessments(canContainNestedRisks, data);

  const initialValue =
    sessionCount !== undefined ? { ...data, sessionCount } : data;

  const { fields, validate, getValue } = useForm<T>(
    validationSchema,
    (initialValue as unknown) as T,
    {
      delay: typingThreshold,
      onStateChange: async (data) => {
        const updatedData = formatDataWithRisks(
          data,
          canContainNestedRisks,
          nestedRisksFieldName,
          risks,
        );
        onStateChange({ data: updatedData });
        // call validate to update the ui to display error message where applicable
        void validate();
      },
    },
  );

  const onChangeRisksForm = async (risks: Partial<Risks>) => {
    const data = formatDataWithRisks(
      getValue(),
      canContainNestedRisks,
      nestedRisksFieldName,
      risks,
    );
    onStateChange({ data });
  };

  return { fields, getValue, onChangeRisksForm, risks, validate };
}

function getInitialRiskAssessments(
  canContainNestedRisks: boolean,
  data?: NoteOrSubsection,
): MaybeUndefined<Risks> {
  if (!canContainNestedRisks || !data) return undefined;
  if ('risks' in data) return data?.risks;
  if ('riskAssessments' in data) return data?.riskAssessments;
}

function formatDataWithRisks<T extends Record<string, any>>(
  data: T,
  canContainNestedRisks: boolean,
  riskFieldName: NestedRisksFieldName,
  riskAssessments?: Partial<Risks>,
): MaybeUndefined<NoteOrSubsection> {
  const formattedData = canContainNestedRisks
    ? { ...data, [riskFieldName]: riskAssessments }
    : data;
  return (formattedData as unknown) as NoteOrSubsection;
}
