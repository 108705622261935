import {
  ApolloError,
  isApolloError,
  OperationVariables,
  useMutation as useApolloMutation,
} from '@apollo/client';
import { FetchResult } from '@apollo/client/link/core/types';
import { ServerError } from '@apollo/client/link/utils';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { scrubAndFlattenVariables } from 'app/services/utils';
import { useAppState, useDispatch } from 'app/state';
import { itemModified } from 'app/state/amplitude/actions/etc';
import { useLogger } from 'app/state/log/useLogger';
import {
  error as triggerError,
  saving,
  success,
} from 'app/state/request/actions';
import { DocumentNode } from 'graphql';
import { OperationDefinitionNode } from 'graphql/language/ast';
import { useCallback, useState } from 'react';

export const DEFAULT_NUMBER_OF_RETRIES = 2;
export const NON_RETRIABLE_ERROR_CODE = [
  'VAULT_ITEM_VERSION_MISMATCH',
  'VAULT_ITEM_ALREADY_EXIST',
  'UNAUTHENTICATED',
  'GRAPHQL_VALIDATION_FAILED',
  'BAD_USER_INPUT',
  'UNMODIFIABLE_VAULT_ITEM',
];

type ExecutorOptions = { amplitudeMetadata: Record<string, any> };

export interface MutationWithGlobalStateResult<T> {
  data?: T;
  error?: Error;
  loading: boolean;
}

export type MutationWithGlobalStateTuple<T, U = OperationVariables> = [
  (param: U, options?: Partial<ExecutorOptions>) => Promise<FetchResult<T>>,
  MutationWithGlobalStateResult<T>,
];

const defaultShouldRetryMutation = (
  error: ApolloError | string,
  retries: number,
) => {
  if (typeof error !== 'string' && isApolloError(error)) {
    const [gqlError] = error.graphQLErrors;
    const [serverError] =
      (error.networkError as ServerError)?.result?.errors ?? [];
    const code = gqlError?.extensions?.code ?? serverError?.extensions?.code;

    if (NON_RETRIABLE_ERROR_CODE.includes(code)) {
      return false;
    }
  }
  return retries < DEFAULT_NUMBER_OF_RETRIES;
};
export interface Options<T, U, V = any> extends MutationHookOptions<T, U, V> {
  shouldRetryMutation: (
    error: ApolloError | string,
    retries: number,
  ) => boolean;
  amplitudeMetadata?: ExecutorOptions['amplitudeMetadata'];
}

interface Variables {
  source: string;
}

export function useMutationWithGlobalState<T, U = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<T, U>,
  options: Partial<Options<T, U>> = {},
): MutationWithGlobalStateTuple<T, U> {
  const dispatch = useDispatch();
  const logger = useLogger();
  const role = useAppState((_) => _.user.role!);
  const [queryName] = useState(() => {
    const ops = query.definitions.find(
      (_) => _.kind === 'OperationDefinition' && _.operation === 'mutation',
    ) as OperationDefinitionNode;
    return ops?.name?.value ?? generateAlphaText();
  });

  const {
    shouldRetryMutation = defaultShouldRetryMutation,
    onCompleted,
    amplitudeMetadata,
    ...apolloMutationOption
  } = options;

  const _onCompleted = (
    data: T,
    variables: U,
    id: string,
    options: Partial<ExecutorOptions>,
    retryCount: number,
  ) => {
    dispatch(
      itemModified({
        queryName,
        role,
        source: ((variables as unknown) as Variables)?.source,
        success: true,
        ...(options?.amplitudeMetadata ?? amplitudeMetadata),
      }),
    );
    logger.info(
      `useMutationWithGlobalState::${
        retryCount > 0 ? 'Retried ' : ''
      }Mutation successful: ${queryName}`,
      {
        operationId: id,
        retryCount,
      },
    );
    dispatch(
      success({
        queryName: `${queryName}-${id}`,
        timestamp: new Date().toISOString(),
      }),
    );
    if (onCompleted) onCompleted(data);
  };
  const [executeQuery, { ...result }] = useApolloMutation<T, U>(query, {
    ...apolloMutationOption,
  });

  const executor = async (
    variables: U,
    id: string,
    retries: number,
    options: Partial<ExecutorOptions>,
  ): Promise<FetchResult<T>> => {
    const maybeDoRetry = async (
      error: ApolloError,
    ): Promise<FetchResult<T>> => {
      if (!shouldRetryMutation(error, retries)) {
        const payload = {
          errorMessage: error.message,
          queryName,
          role,
          source: ((variables as unknown) as Variables)?.source,
          success: false,
          ...(options?.amplitudeMetadata ?? amplitudeMetadata),
        };
        dispatch(itemModified(payload));
        logger.warning(
          `useMutationWithGlobalState::${
            retries > 0 ? 'Retried ' : ''
          }Mutation error: ${queryName}`,
          {
            ...payload,
            error,
            operationId: id,
            retryCount: retries,
            variables: scrubAndFlattenVariables(variables),
          },
        );
        dispatch(triggerError({ error, queryName: `${queryName}-${id}` }));
        return { errors: error.graphQLErrors };
      }
      // Retry with exponential backoff to delay retry a little
      // (2 ** 1) * 100 = 200 ms
      // (2 ** 2) * 100 = 400 ms
      // (2 ** 3) * 100 = 800 ms
      const retry = retries + 1;
      logger.info(
        `useMutationWithGlobalState::Retrying mutation: ${queryName} count:${retry}`,
        {
          operationId: id,
          variables: scrubAndFlattenVariables(variables),
        },
      );
      const timeToWait = 2 ** retry * 100;
      await delay(timeToWait);
      return executor(variables, id, retry, options);
    };
    try {
      dispatch(saving({ queryName: `${queryName}-${id}` }));
      const result = await executeQuery({ variables });
      if (result.errors) {
        return maybeDoRetry(new ApolloError({ graphQLErrors: result.errors }));
      }
      _onCompleted(result.data!, variables, id, options, retries);
      return result;
    } catch (error) {
      logger.warning(
        `useMutationWithGlobalState::Failed to execute query ${queryName}`,
        {
          error,
          operationId: id,
          variables: scrubAndFlattenVariables(variables),
        },
      );
      let apolloError: ApolloError;
      if (error instanceof ApolloError) {
        apolloError = error;
      } else {
        apolloError = new ApolloError({ clientErrors: [error] });
      }
      return maybeDoRetry(apolloError);
    }
  };

  const trigger = useCallback(
    async (newVariables: U, options: Partial<ExecutorOptions> = {}) => {
      const id = generateAlphaText();
      logger.info(
        `useMutationWithGlobalState::Running mutation: ${queryName}`,
        {
          operationId: id,
          variables: scrubAndFlattenVariables(newVariables),
        },
      );
      return executor(newVariables, id, 0, options);
    },
    [],
  );

  return [
    trigger,
    {
      data: result.data ?? undefined,
      error: result.error,
      loading: result.loading,
    },
  ];
}

const delay = async (ms: number) => new Promise((res) => setTimeout(res, ms));

function generateAlphaText() {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '');
}
