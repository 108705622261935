import React, { useCallback } from 'react';
import moment from 'moment-timezone';
import {
  Calendar,
  CalendarProps,
  DateRange,
  momentLocalizer,
} from 'react-big-calendar';
import { EventWrapper } from './EventWrapper';
import { ScheduleActionBar } from './ScheduleActionBar';
import { EventTypeFilterForm } from './EventTypeFilter';
import { booleanField, Fields, useForm } from '@ginger.io/react-use-form';
import { DEFAULT_TIMEZONE } from 'app/clinician/ClinicianSettingsComponent';
import { useDispatch } from 'app/state';
import {
  CalendarDateRange,
  CalendarView,
  CalendarViewProps,
  EventType,
  GingerEvent,
} from './types';
import {
  calendarFilterAppointmentsClick,
  calendarFilterAvailabilityClick,
  calendarFilterClick,
  calendarFilterEventsClick,
  calendarFilterGoogleEventClick,
} from 'app/state/amplitude/actions/appointments';

export type ScheduleContainerProps = {
  events: GingerEvent[];
  onDoubleClickEvent: (
    event: GingerEvent,
    e: React.SyntheticEvent<HTMLElement, globalThis.Event>,
  ) => void;
  onCalendarRangeChange: (dateRange: DateRange) => void;
  clinicianId: string | undefined;
  calendarViewProps?: CalendarViewProps;
  onNewEvent: () => void;
  onNewAppointment: (start?: string, end?: string) => void;
  timezone?: string;
  loading: boolean;
};

export function ScheduleContainer(props: ScheduleContainerProps) {
  const {
    events,
    onDoubleClickEvent,
    onNewEvent,
    onNewAppointment,
    onCalendarRangeChange,
    timezone = DEFAULT_TIMEZONE,
    loading,
  } = props;
  const { fields } = useForm<EventTypeFilterForm>(
    {
      [EventType.AVAILABILITY]: booleanField(),
      [EventType.APPOINTMENT]: booleanField(),
      [EventType.EVENT]: booleanField(),
      [EventType.MEETING]: booleanField(),
    },
    {
      [EventType.AVAILABILITY]: true,
      [EventType.APPOINTMENT]: true,
      [EventType.EVENT]: true,
      [EventType.MEETING]: true,
    },
  );
  const dispatch = useDispatch();

  const onFilterOpen = () => {
    dispatch(calendarFilterClick());
  };

  const onFilter = (eventType: EventType, checked: boolean) => {
    switch (eventType) {
      case EventType.APPOINTMENT:
        dispatch(calendarFilterAppointmentsClick({ exclude: !checked }));
        return;
      case EventType.AVAILABILITY:
        dispatch(calendarFilterAvailabilityClick({ exclude: !checked }));
        return;
      case EventType.MEETING:
        dispatch(calendarFilterGoogleEventClick({ exclude: !checked }));
        return;
      case EventType.EVENT:
        dispatch(calendarFilterEventsClick({ exclude: !checked }));
    }
  };

  const filterEventTypes = makeFilterEventTypes(fields);

  const {
    calendarViewProps = createDefaultCalendarViewProps(timezone),
  } = props;
  const localizer = momentLocalizer(moment);

  /**
   * These accessors work around the brokenness of react-big-calendar timezones.
   * RBC currently doesn't support rendering outside the browser's local timezone.
   * Since the calendar can only render locally, these accessors shift all the events times instead.
   * The times are shifted by the difference between the local timezone and the clinician's timezone.
   */
  const startAccessor = (event: GingerEvent) =>
    offsetDate(event.start, timezone);
  const endAccessor = (event: GingerEvent) => offsetDate(event.end, timezone);

  function onRangeChange(dateRange: CalendarDateRange | Date[]): void {
    onCalendarRangeChange(dateRangeWithOffsetMinutes(dateRange, timezone));
  }

  const handleSelectSlot = useCallback(({ start, end }) => {
    onNewAppointment(start, end);
  }, []);

  const calendarProps: CalendarProps<GingerEvent, {}> = {
    style: { height: '100vh' },
    localizer: localizer,
    events: events.filter(filterEventTypes),
    startAccessor,
    endAccessor,
    defaultView: calendarViewProps.view,
    onDoubleClickEvent,
    components: {
      eventWrapper: EventWrapper,
    },
    onRangeChange,
    views: ['month', 'week', 'day'],
    // Remove time range label from event bubbles, so we can put it after
    formats: {
      eventTimeRangeFormat: (range) => '',
      dayRangeHeaderFormat: ({ start, end }, culture, local) =>
        local!.format(start, 'MMMM DD', culture!) +
        ' – ' +
        local!.format(
          end,
          moment(start).isSame(end, 'month') ? 'DD' : 'MMMM DD',
          culture!,
        ) +
        ` (${timezone})`,
      // square brackets [] escape momentjs format strings
      monthHeaderFormat: `MMMM YYYY [(${timezone})]`,
      dayHeaderFormat: `dddd MMM DD [(${timezone})]`,
    },
    showMultiDayTimes: true,
    onSelectSlot: handleSelectSlot,
    selectable: true,
  };

  return (
    <div data-testid="calendar">
      <ScheduleActionBar
        onNewEvent={onNewEvent}
        onNewAppointment={onNewAppointment}
        fields={fields}
        onOpen={onFilterOpen}
        onFilter={onFilter}
        loading={loading}
      />
      <Calendar {...calendarProps} />
    </div>
  );
}

export function dateRangeWithOffsetMinutes(
  dateRange: CalendarDateRange | Date[],
  timezone: string,
  localZone = moment.tz.guess(),
): DateRange {
  // The dates within dateRange will be in the timezone as identified by localZone. We need to get the same time
  // BUT in the clinician's timezone/offset. Compensate by adding the negative of the offset difference.
  // E.g. clinician timezone EDT is 3 hours ahead of local timezone PDT. localOffsetFromClinicianMins is +3.
  // If we want to express 00:00:00 EDT in PDT, we need to add -3 to get 21:00:00 PDT (prev day), therefore we need
  // to add the NEGATIVE localOffsetFromClinicianMins.

  let end;
  let start;
  if (Array.isArray(dateRange)) {
    // Week and Day views
    const dates = dateRange as Date[];
    start = offsetDate(dates[0], timezone, localZone, true);
    end = offsetDate(dates[dates.length - 1], timezone, localZone, true);
  } else {
    // Month view
    const calendarDateRange = dateRange as CalendarDateRange;
    start = offsetDate(calendarDateRange.start, timezone, localZone, true);
    end = offsetDate(calendarDateRange.end, timezone, localZone, true);
  }
  // API will select events in range [start, end), so add 1 to the end date here
  end.setDate(end.getDate() + 1);
  return { start, end };
}

function makeFilterEventTypes(fields: Fields<EventTypeFilterForm>) {
  return (event: GingerEvent) => {
    switch (event.gingerType) {
      case EventType.APPOINTMENT:
        return fields.Appointment.value;
      case EventType.AVAILABILITY:
        return fields.Availability.value;
      case EventType.EVENT:
        return fields.Event.value;
      case EventType.MEETING:
        return fields.Meeting.value;
    }
  };
}

// By default, open the calendar viewing the current week
export const createDefaultCalendarViewProps = (timezone: string) => ({
  view: CalendarView.WEEK,
  start: moment.tz(timezone).startOf('week'),
  end: moment.tz(timezone).endOf('week'),
});

export function offsetDate(
  date: Date | string,
  timezone: string,
  localZone = moment.tz.guess(),
  negateOffset: boolean = false,
): Date {
  let multiplier = 1;
  if (negateOffset) {
    multiplier = -1;
  }
  const offset =
    (moment.tz(date, timezone).utcOffset() -
      moment.tz(date, localZone).utcOffset()) *
    multiplier;
  return moment(date).add(offset, 'minutes').toDate();
}
