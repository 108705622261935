export enum pssStringQuestions {
  UPSET_BECAUSE_OF_SOMETHING_UNEXPECTED = 'Been upset because of something that happened unexpectedly',
  UNABLE_TO_CONTROL_IMPORTANT_THINGS = 'Felt that you were unable to control the important things in your life',
  FELT_NERVOUS_AND_STRESSED = 'Felt nervous and stressed',
  FELT_CONFIDENT_TO_HANDLE_PERSONAL_PROBLEMS = 'Felt confident about your ability to handle your personal problems',
  FELT_THINGS_GOING_YOUR_WAY = 'Felt that things were going your way',
  FOUND_COULD_NOT_COPE_WITH_ALL_THE_THINGS = 'Found that you could not cope with all the things that you had to do',
  ABLE_TO_CONTROL_IRRITATIONS_IN_YOUR_LIFE = 'Been able to control irritations in your life',
  FELT_ON_TOP_OF_THINGS = 'Felt that you were on top of things',
  ANGERED_BY_THINGS_OUTSIDE_OF_YOUR_CONTROL = 'Been angered because of things that happened that were outside of your control',
  FELT_DIFFICULTIES_PILING = 'Felt difficulties were piling up so high that you could not overcome them',
}

export const PSS_DROPDOWN_OPTIONS = [
  { name: 'Total Scores', value: 'total' },
  {
    name: pssStringQuestions.UPSET_BECAUSE_OF_SOMETHING_UNEXPECTED,
    value: 'upsetBecauseOfSomethingUnexpected',
  },
  {
    name: pssStringQuestions.UNABLE_TO_CONTROL_IMPORTANT_THINGS,
    value: 'unableToControlImportantThings',
  },
  {
    name: pssStringQuestions.FELT_NERVOUS_AND_STRESSED,
    value: 'feltNervousAndStressed',
  },
  {
    name: pssStringQuestions.FELT_CONFIDENT_TO_HANDLE_PERSONAL_PROBLEMS,
    value: 'feltConfidentToHandlePersonalProblems',
  },
  {
    name: pssStringQuestions.FELT_THINGS_GOING_YOUR_WAY,
    value: 'feltThingsGoingYourWay',
  },
  {
    name: pssStringQuestions.FOUND_COULD_NOT_COPE_WITH_ALL_THE_THINGS,
    value: 'foundCouldNotCopeWithAllTheThings',
  },
  {
    name: pssStringQuestions.ABLE_TO_CONTROL_IRRITATIONS_IN_YOUR_LIFE,
    value: 'ableToControlIrritationsInYourLife',
  },
  {
    name: pssStringQuestions.FELT_ON_TOP_OF_THINGS,
    value: 'feltOnTopOfThings',
  },
  {
    name: pssStringQuestions.ANGERED_BY_THINGS_OUTSIDE_OF_YOUR_CONTROL,
    value: 'angeredByThingsOutsideOfYourControl',
  },
  {
    name: pssStringQuestions.FELT_DIFFICULTIES_PILING,
    value: 'feltDifficultiesPiling',
  },
];

export enum cssrsStringQuestions {
  WISHED_DEAD = 'Have you wished you were dead or wished you could go to sleep and not wake up?',
  THOUGHTS_OF_SUICIDE = 'Have you actually had any thoughts of killing yourself?',
  THOUGHTS_ON_HOW = 'Have you been thinking about how you might do this?',
  THOUGHTS_WITH_INTENTION = 'Have you had these thoughts and had some intention of acting on them?',
  DETAILED_PLANS = 'Have you started to work out or worked out the details of how to kill yourself? Do you intend to carry out this plan?',
  INTEND_TO_ACT = 'Have you ever done anything, started to do anything, or prepared to do anything to end your life?',
  RECENT_ACTIONS = 'Was this within the past three months?',
}

export const CSSRS_DROPDOWN_OPTIONS = [
  { name: 'Total Rating', value: 'total' },
  { name: cssrsStringQuestions.WISHED_DEAD, value: 'wishedDead' },
  {
    name: cssrsStringQuestions.THOUGHTS_OF_SUICIDE,
    value: 'thoughtsOfSuicide',
  },
  { name: cssrsStringQuestions.THOUGHTS_ON_HOW, value: 'thoughtsOnHow' },
  {
    name: cssrsStringQuestions.THOUGHTS_WITH_INTENTION,
    value: 'thoughtsWithIntention',
  },
  { name: cssrsStringQuestions.DETAILED_PLANS, value: 'detailedPlans' },
  { name: cssrsStringQuestions.INTEND_TO_ACT, value: 'intendToAct' },
  { name: cssrsStringQuestions.RECENT_ACTIONS, value: 'recentActions' },
];
