import { SvgIcon } from '@mui/material';
import { ReactComponent as UncheckedCheckboxSVG } from 'assets/unchecked-checkbox-icon.svg';
import React from 'react';

export const UncheckedCheckboxIcon = (props: any) => {
  const stroke = props.stroke || '#272727';
  return (
    <SvgIcon {...props} stroke={stroke}>
      <UncheckedCheckboxSVG />
    </SvgIcon>
  );
};
