import { GetTeenDependantSurveyResponse_survey_intakeSurvey_relationships as Relationships } from '@headspace/carehub-graphql/dist/patients/tabs/intake/generated/GetTeenDependantSurveyResponse';
import Titles from 'app/member-chart-cards/constants/cards-titles';
import { formatYesNoValue } from 'app/patients/tabs/intake/dependent-intake/common';
import styles from 'app/patients/tabs/intake/dependent-intake/DependentReport.module.scss';
import React from 'react';
import { Card } from 'shared-components/Card';
import {
  CardColumnGaps,
  CardGrid,
  CardRowGaps,
  NumberOfCardColumns,
} from 'shared-components/grid-layout/CardGrid.module';
import { Table } from 'shared-components/table/Table';

type Props = {
  relationships: Relationships | null;
};

export function RelationshipCard({ relationships }: Readonly<Props>) {
  return (
    <Card data-testid="relationshipsCard" boxTitle={Titles.RELATIONSHIPS_TITLE}>
      <div className={styles.cardSection}>
        <CardGrid
          rowGap={CardRowGaps.STANDARD}
          columnGap={CardColumnGaps.STANDARD}
          numberOfColumns={NumberOfCardColumns.ONE}
        >
          <Table
            classes={{ cell: 'vertical-align-top' }}
            showCheckboxes={false}
            data-testid="relationshipRatingTable"
            columns={[
              { key: 'type', label: 'Type' },
              { key: 'rating', label: 'Rating' },
              { key: 'comments', label: 'Comments' },
            ]}
            rows={{
              family: {
                comments: formatYesNoValue(relationships?.familyDetails),
                rating: relationships?.familyScore
                  ? `${formatYesNoValue(relationships?.familyScore)} out of 10`
                  : '-',
                type: 'Family',
              },
              kids: {
                comments: formatYesNoValue(relationships?.childrenDetails),
                rating: relationships?.childrenScore
                  ? `${formatYesNoValue(
                      relationships?.childrenScore,
                    )} out of 10`
                  : '-',
                type: 'Children',
              },
              spouse: {
                comments: formatYesNoValue(relationships?.spouseDetails),
                rating: relationships?.spouseScore
                  ? `${formatYesNoValue(relationships?.spouseScore)} out of 10`
                  : '-',
                type: 'Romantic Partner',
              },
            }}
          />
        </CardGrid>
      </div>
    </Card>
  );
}
