import { Field } from '@ginger.io/react-use-form/dist/types';
import { GetMembersForMe_getMembersForMe_members } from 'app/appointments/generated/GetMembersForMe';
import { Result } from 'shared-components/autocomplete-search/AutoCompleteSearch';
import { AutoCompleteSearchField } from 'shared-components/autocomplete-search/AutoCompleteSearchField';
import React from 'react';

type MemberSearchFieldContainerProps = {
  field: Field<string>;
  members?: GetMembersForMe_getMembersForMe_members[];
  error?: string;
  setSearchQuery: (query: string) => void;
  loading: boolean;
};

export function MemberSearchField({
  field,
  members,
  error,
  setSearchQuery,
  loading,
}: MemberSearchFieldContainerProps) {
  let results: Result[] = [];
  if (members) {
    results = members.map(
      ({ id, firstName, lastName, preferredFirstName, preferredLastName }) => {
        return {
          display: `${firstName} ${lastName} (${id})`,
          id,
          queryOn: `${firstName} ${lastName} ${preferredFirstName} ${preferredLastName}`,
        };
      },
    );
  }

  return (
    <AutoCompleteSearchField
      idField={field}
      error={error}
      searchFieldProps={{
        loading,
        placeholder: '🔍  Search member name or ID number',
        results,
        searchCallback: setSearchQuery,
        testId: 'member-search-autocomplete',
      }}
    />
  );
}
