import { ApolloCache } from '@apollo/client/cache';
import { decodeBase64VaultItems } from '@ginger.io/vault-core';
import { KeyGenerator } from '@ginger.io/vault-core/dist/crypto';
import { Base64 } from '@ginger.io/vault-core/dist/crypto/Base64';
import {
  VaultItem,
  VaultItem_SchemaType as SchemaType,
} from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import {
  getClinicalCareTeamGroupId,
  getCoachingTeamGroupId,
} from '@ginger.io/vault-core/dist/IdHelpers';
import { AdditionalDemographicInfo } from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/AdditionalDemographicInfo';
import { MemberBackgroundSection } from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/MemberBackgroundSection';
import {
  createVaultItemInput,
  CreateVaultItemInputParams,
  updateVaultItemInput,
  UpdateVaultItemInputParams,
  VaultItemPermissions,
} from '@ginger.io/vault-ui';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import {
  GetMemberChartVaultItemById,
  GetMemberChartVaultItemByIdVariables,
} from 'app/coach/member-chart/generated/GetMemberChartVaultItemById';
import {
  GetMemberChartVaultItems,
  GetMemberChartVaultItems_getPaginatedVaultItemsByTag_items,
  GetMemberChartVaultItems_getPaginatedVaultItemsByTag_items_encryptedItem as EncryptedItem,
  GetMemberChartVaultItemsVariables,
} from 'app/coach/member-chart/generated/GetMemberChartVaultItems';
import { MutationResponse as MemberBackgroundSectionMutationResponse } from 'app/member-chart-cards/member-background/MemberBackgroundTypes';
import { MutationResponse as AdditionalDemographicInfoMutationResponse } from 'app/member-chart-cards/member-profile/types/memberProfleTypes';
import { ILogger } from 'app/state/log/Logger';
import { isClinicianOrSupervisor, isCoachOrSupervisor, isMS } from 'utils';
import { formatTimestampWithTz } from 'utils/dateTime';
import { memberChartVaultItem } from 'app/vault/queries';
import {
  CreateVaultItemInput,
  UpdateVaultItemInput,
  UserRole,
  VaultItemSortField,
  VaultItemSortOrder,
} from 'generated/globalTypes';
import gql from 'graphql-tag';
import { v4 as uuidv4 } from 'uuid';

import {
  CreateMemberChartVaultItems,
  CreateMemberChartVaultItemsVariables,
} from './generated/CreateMemberChartVaultItems';
import {
  CreateMemberChartVaultItemsInBatch,
  CreateMemberChartVaultItemsInBatch_createVaultItemsInBatch_itemWithKey as VaultItemWithKey,
} from './generated/CreateMemberChartVaultItemsInBatch';
import { DeleteMemberChartVaultItems } from './generated/DeleteMemberChartVaultItems';
import {
  GetAllTaskItemsForMe,
  GetAllTaskItemsForMeVariables,
} from './generated/GetAllTaskItemsForMe';
import { UpdateMemberChartVaultItemsVariables } from './generated/UpdateMemberChartVaultItems';

export enum MemberChartMapQueries {
  MEMBER_BACKGROUND_SECTION,
  ADDITIONAL_DEMOGRAPHIC_INFO,
}

type Decoder =
  | typeof MemberBackgroundSection
  | typeof AdditionalDemographicInfo;

type SchemaMapType = {
  [key in MemberChartMapQueries]: {
    objectForDecoding: { [key in SchemaType]?: Decoder };
    schemaType: SchemaType;
    decoder: Decoder;
  };
};

export const getMemberChartVaultItems = gql`
  query GetMemberChartVaultItems(
    $tag: String!
    $pagination: VaultPaginationInput!
    $groupId: ID
  ) {
    getPaginatedVaultItemsByTag(
      input: { tag: $tag, groupId: $groupId, pagination: $pagination }
    ) @connection(key: $tag) {
      items {
        encryptedItem {
          ...memberChartVaultItem
        }
      }
      cursor
    }
  }
  ${memberChartVaultItem}
`;

export const getMemberChartVaultItemById: TypedDocumentNode<
  GetMemberChartVaultItemById,
  GetMemberChartVaultItemByIdVariables
> = gql`
  query GetMemberChartVaultItemById($vaultId: ID!, $groupId: ID) {
    getVaultItemById(id: $vaultId, groupId: $groupId) {
      encryptedItem {
        ...memberChartVaultItem
      }
    }
  }
  ${memberChartVaultItem}
`;

export const getTasks: TypedDocumentNode<
  GetAllTaskItemsForMe,
  GetAllTaskItemsForMeVariables
> = gql`
  query GetAllTaskItemsForMe(
    $tag: String!
    $clinicalTaskInput: GetTasksForMemberInput!
    $pagination: VaultPaginationInput!
    $includeCareProviderTasks: Boolean = false
    $groupId: ID
    $dateRange: DateTimeRange
  ) {
    getTasksForMember(input: $clinicalTaskInput)
      @include(if: $includeCareProviderTasks)
      @connection(key: $tag) {
      active(input: { range: $dateRange }) {
        ...TaskItem
      }
      completed(input: { range: $dateRange }) {
        ...TaskItem
      }
      dismissed(input: { range: $dateRange }) {
        ...TaskItem
      }
    }
    getPaginatedVaultItemsByTag(
      input: { tag: $tag, groupId: $groupId, pagination: $pagination }
    ) @connection(key: $tag) {
      items {
        encryptedItem {
          createdAt
          firstVersionCreator {
            id
            firstName
            lastName
          }
          ...memberChartVaultItem
        }
      }
      cursor
    }
  }
  fragment TaskItem on CareProviderTask {
    id
    callToAction
    reasonForCreation
    priority
    relatedCareProviderName
    relatedMessages {
      uuid
      outOfSession
    }
  }
  ${memberChartVaultItem}
`;

export const updateCache = (
  cache: ApolloCache<any>,
  data: CreateMemberChartVaultItems | CreateMemberChartVaultItemsInBatch,
  variables: GetMemberChartVaultItemsVariables,
) => {
  const items = cache.readQuery<
    GetMemberChartVaultItems,
    GetMemberChartVaultItemsVariables
  >({
    query: getMemberChartVaultItems,
    variables,
  });
  let item: VaultItemWithKey[] = [];
  if ((data as CreateMemberChartVaultItems).createVaultItems) {
    item = (data as CreateMemberChartVaultItems).createVaultItems;
  } else if (
    (data as CreateMemberChartVaultItemsInBatch).createVaultItemsInBatch
  ) {
    item = (data as CreateMemberChartVaultItemsInBatch).createVaultItemsInBatch.reduce(
      (arr, _) => (_.success ? [...arr, _.itemWithKey!] : arr),
      [] as Array<VaultItemWithKey>,
    );
  }
  cache.writeQuery<GetMemberChartVaultItems, GetMemberChartVaultItemsVariables>(
    {
      data: {
        getPaginatedVaultItemsByTag: {
          __typename: 'GetVaultItemsByTagResponse',
          cursor: null,
          ...items?.getPaginatedVaultItemsByTag,
          items: [...(items?.getPaginatedVaultItemsByTag.items ?? []), ...item],
        },
      },
      query: getMemberChartVaultItems,
      variables,
    },
  );
};

export const removeItemsFromCache = (
  cache: ApolloCache<any>,
  data: DeleteMemberChartVaultItems,
  variables: GetMemberChartVaultItemsVariables,
) => {
  const items = cache.readQuery<
    GetMemberChartVaultItems,
    GetMemberChartVaultItemsVariables
  >({
    query: getMemberChartVaultItems,
    variables,
  });

  const cachedItems: GetMemberChartVaultItems_getPaginatedVaultItemsByTag_items[] =
    items?.getPaginatedVaultItemsByTag.items ?? [];

  const updatedItems = data.deleteVaultItems.reduce(
    (arr: GetMemberChartVaultItems_getPaginatedVaultItemsByTag_items[], _) => {
      const updatedList = [...arr];
      const index = arr.findIndex((item) => item.encryptedItem.id === _.id);
      if (_.success && index > -1) {
        updatedList.splice(index, 1);
      }
      return updatedList;
    },
    cachedItems,
  );
  cache.writeQuery<GetMemberChartVaultItems, GetMemberChartVaultItemsVariables>(
    {
      data: {
        getPaginatedVaultItemsByTag: {
          __typename: 'GetVaultItemsByTagResponse',
          cursor: null,
          ...items?.getPaginatedVaultItemsByTag,
          items: updatedItems,
        },
      },
      query: getMemberChartVaultItems,
      variables,
    },
  );
};

const SCHEMA_MAP: SchemaMapType = {
  [MemberChartMapQueries.MEMBER_BACKGROUND_SECTION]: {
    decoder: MemberBackgroundSection,
    objectForDecoding: {
      [SchemaType.vault_member_chart_member_background_section]: MemberBackgroundSection,
    },
    schemaType: SchemaType.vault_member_chart_member_background_section,
  },
  [MemberChartMapQueries.ADDITIONAL_DEMOGRAPHIC_INFO]: {
    decoder: AdditionalDemographicInfo,
    objectForDecoding: {
      [SchemaType.vault_member_chart_additional_demographic_info]: AdditionalDemographicInfo,
    },
    schemaType: SchemaType.vault_member_chart_additional_demographic_info,
  },
};

export async function getGroupId(
  memberId: string,
  role: UserRole,
): Promise<string | undefined> {
  if (isClinicianOrSupervisor(role) || isMS(role)) {
    return await Base64.hash(getClinicalCareTeamGroupId(memberId));
  }

  if (isCoachOrSupervisor(role)) {
    return await Base64.hash(getCoachingTeamGroupId(memberId));
  }

  return Promise.resolve(undefined);
}

const decodeQueryResult = async (
  cipherText: string,
  query: MemberChartMapQueries,
) => {
  let decodedItems;
  try {
    decodedItems = await decodeBase64VaultItems(
      [cipherText],
      SCHEMA_MAP[query].objectForDecoding,
    );
  } catch (ex) {
    return null;
  }

  const vaultSection = decodedItems[SCHEMA_MAP[query].schemaType];

  if (!vaultSection || vaultSection.length !== 1)
    throw Error(
      `Failed to decode vault query: ${MemberChartMapQueries[query]}`,
    );

  return vaultSection[0];
};

interface VaultUser {
  role: UserRole;
  timezone: string;
  vaultUserId: string;
}

export const mapPaginatedVaultItems = async (
  encryptedItem: EncryptedItem,
  userInfo: VaultUser,
  query: MemberChartMapQueries,
) => {
  const {
    id,
    creator,
    sourceVersion,
    updatedAt,
    encryptedData,
  } = encryptedItem;
  const { timezone, vaultUserId } = userInfo;
  const { id: creatorId, firstName, lastName } = creator;

  const hashedVaultId = await Base64.hash(vaultUserId);
  const decodedVaultItem = await decodeQueryResult(
    encryptedData.cipherText,
    query,
  );

  if (!decodedVaultItem) {
    return null;
  }

  return {
    ...decodedVaultItem,
    id,
    sourceVersion,
    updatedAt: updatedAt ? formatTimestampWithTz(updatedAt, timezone) : null,
    updatedBy:
      creatorId === hashedVaultId
        ? `You`
        : `${firstName ?? ''} ${lastName ?? ''}`.trim(),
  };
};

export const getVariables = async (
  memberId: string,
  role: UserRole,
): Promise<GetMemberChartVaultItemsVariables> => {
  return {
    groupId: await getGroupId(memberId, role),
    pagination: {
      cursor: null,
      maxItemsPerPage: 200,
      sortField: VaultItemSortField.CREATED_AT,
      sortOrder: VaultItemSortOrder.DESC,
    },
    tag: await Base64.hash(`member-chart-${memberId}`),
  };
};
export const getMemberBackgroundVariables = async (
  memberId: string,
  role: UserRole,
): Promise<GetMemberChartVaultItemsVariables> => {
  return {
    groupId: await getGroupId(memberId, role),
    pagination: {
      cursor: null,
      maxItemsPerPage: 200,
      sortField: VaultItemSortField.CREATED_AT,
      sortOrder: VaultItemSortOrder.DESC,
    },
    tag: await Base64.hash(`member-chart-${memberId}-member-background`),
  };
};

interface inputParams {
  itemId: string;
  tags: string[];
  vaultItem: VaultItem;
}

export const createVaultInputParams = async (
  memberId: string,
  role: UserRole,
  inputParams: inputParams,
  update?: boolean,
): Promise<CreateVaultItemInputParams | UpdateVaultItemInputParams> => {
  const param = {
    ...inputParams,
    permissions: VaultItemPermissions.WritableByAll,
  };

  if (update) {
    return {
      ...param,
      groupId: await getGroupId(memberId, role),
    };
  }

  return {
    ...param,
    groupsToShareWith: [
      getClinicalCareTeamGroupId(memberId),
      getCoachingTeamGroupId(memberId),
    ],
  };
};

export interface Options {
  generateId?: () => string;
  keyGenerator: KeyGenerator;
}

export const createVaultItems = gql`
  mutation CreateMemberChartVaultItems($input: [CreateVaultItemInput!]!) {
    createVaultItems(input: $input) {
      encryptedItem {
        ...memberChartVaultItem
      }
    }
  }
  ${memberChartVaultItem}
`;

export const createVaultItemsInBatch = gql`
  mutation CreateMemberChartVaultItemsInBatch(
    $input: [CreateVaultItemInput!]!
  ) {
    createVaultItemsInBatch(input: $input) {
      id
      success
      error {
        code
        message
      }
      itemWithKey {
        encryptedItem {
          ...memberChartVaultItem
        }
      }
    }
  }
  ${memberChartVaultItem}
`;

export const deleteVaultItems = gql`
  mutation DeleteMemberChartVaultItems($input: DeleteVaultItemsInput!) {
    deleteVaultItems(input: $input) {
      id
      success
      errorMessage
    }
  }
`;

export const shareVaultItems = gql`
  mutation ShareMemberChartVaultItems(
    $groupId: String
    $input: ShareVaultItemsInput!
  ) {
    shareVaultItems(groupId: $groupId, input: $input)
  }
`;

export const updateVaultItems = gql`
  mutation UpdateMemberChartVaultItems($input: [UpdateVaultItemInput!]!) {
    updateVaultItems(input: $input) {
      ...memberChartVaultItem
    }
  }
  ${memberChartVaultItem}
`;

export const updateVaultItemsInBatch = gql`
  mutation UpdateMemberChartVaultItemsInBatch(
    $input: [UpdateVaultItemInput!]!
  ) {
    updateVaultItemsInBatch(input: $input) {
      id
      success
      item {
        ...memberChartVaultItem
      }
      error {
        code
        message
      }
    }
  }
  ${memberChartVaultItem}
`;

// when a new mutation needs to be added please make this a union type
type MutationResponse =
  | MemberBackgroundSectionMutationResponse
  | AdditionalDemographicInfoMutationResponse;

interface CreateVaultItemVariables
  extends CreateMemberChartVaultItemsVariables {
  source: string;
}

interface CreateMemberChartVaultItemsParams {
  param: CreateVaultItemInputParams;
  source: string;
  userInfo: VaultUser;
  query: MemberChartMapQueries;
  createMemberChartVaultItemFn: (
    arg0: CreateVaultItemVariables,
  ) => Promise<any>;
  opts: Partial<Options>;
  logger: ILogger;
}

export const createMemberChartVaultItem = async (
  args: CreateMemberChartVaultItemsParams,
): Promise<MutationResponse> => {
  const {
    param,
    source,
    userInfo,
    query,
    createMemberChartVaultItemFn,
    opts,
    logger,
  } = args;
  const { generateId = uuidv4, keyGenerator = new KeyGenerator() } = opts;
  const item = await createVaultItemInput(param, keyGenerator, generateId);

  // TypeScript believes that there is a type mismatch between the gql type "CreateVaultItemInput" generated in the
  // ginger-react-ui library and this repo, so we are doing double assertion to keep TypeScript from complaining.
  const {
    errors: createVaultItemErrors,
    data: response,
  } = await createMemberChartVaultItemFn({
    input: [(item as unknown) as CreateVaultItemInput],
    source,
  });

  if (createVaultItemErrors || !response) {
    logger.error(new Error('Unable to create vault item'), {
      errors: createVaultItemErrors,
      inputSource: source,
    });
    return {
      data: null,
      errorMessage:
        createVaultItemErrors
          ?.map((_: { message: any }) => _.message)
          .join('\n') ?? 'Unable to create vault item',
      success: false,
    };
  }

  return {
    // since we only send one VaultItem to the createVaultItems mutation, the response will be always
    // be an array of size one, so we only select response.createVaultItems[0]
    data: await mapPaginatedVaultItems(
      response.createVaultItems[0].encryptedItem,
      userInfo,
      query,
    ),

    success: true,
  };
};

interface UpdateVaultItemVariables
  extends UpdateMemberChartVaultItemsVariables {
  source: string;
}

interface UpdateMemberChartVaultItemsParams {
  param: UpdateVaultItemInputParams;
  source: string;
  userInfo: VaultUser;
  query: MemberChartMapQueries;
  updateMemberChartVaultItemFn: (
    arg0: UpdateVaultItemVariables,
  ) => Promise<any>;
  opts: Partial<Options>;
  logger: ILogger;
}

export const updateMemberChartVaultItem = async (
  args: UpdateMemberChartVaultItemsParams,
): Promise<MutationResponse> => {
  const {
    param,
    source,
    userInfo,
    query,
    updateMemberChartVaultItemFn,
    opts,
    logger,
  } = args;
  const { keyGenerator = new KeyGenerator() } = opts;
  const item = await updateVaultItemInput(param, keyGenerator);

  const {
    errors: updateError,
    data: response,
  } = await updateMemberChartVaultItemFn({
    input: [(item as unknown) as UpdateVaultItemInput],
    source,
  });

  if (updateError || !response) {
    logger.error(new Error('Unable to update vault item'), {
      errors: updateError,
      inputSource: source,
    });
    return {
      data: null,
      errorMessage:
        updateError?.map((_: { message: any }) => _.message).join('\n') ??
        'Unable to update vault item',
      success: false,
    };
  }

  return {
    // since we only send one VaultItem to the updateVaultItems mutations, the response will be always
    // be an array of size one, so we only select response.updateVaultItems[0]
    data: await mapPaginatedVaultItems(
      response.updateVaultItems[0],
      userInfo,
      query,
    ),

    success: true,
  };
};
