import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { Loader } from 'shared-components/loader/Loader';
import { GetMember } from 'app/member-support/generated/GetMember';
import { getMemberQuery } from 'app/member-support/MemberDemographicsGQL';
import { useLogger } from 'app/state/log/useLogger';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
  GetDosespotIframeCredentials,
  GetDosespotIframeCredentials_getDosespotIframeCredentials,
} from './generated/GetDosespotIframeCredentials';
import styles from './Prescriptions.module.scss';
import { getDosespotIframeCredentials } from './queries';

interface Props {
  memberId: string | null;
}

export default function Prescriptions(props: Props) {
  const { memberId } = props;
  const search = useLocation().search;
  const refillsErrors = new URLSearchParams(search).get('RefillsErrors');

  const [
    doseSpotCredentials,
    setDoseSpotCredentials,
  ] = useState<GetDosespotIframeCredentials_getDosespotIframeCredentials | null>(
    null,
  );
  const [dosespotPatientId, setDoseSpotPatientId] = useState<string | null>(
    null,
  );
  const [loading, setLoading] = useState(true);
  const apollo = useApolloClient();
  const {
    enable_care_hub_notes_efficiency,
  } = useFeatureFlags().transientFeatureFlags;
  const prescriptionsStyles = enable_care_hub_notes_efficiency
    ? styles.prescriptionsV2
    : styles.prescriptions;
  const logger = useLogger();

  const loadDosespotCredentials = async () => {
    const response: ApolloQueryResult<GetDosespotIframeCredentials> = await apollo.query(
      {
        query: getDosespotIframeCredentials,
      },
    );

    if (response.data) {
      setDoseSpotCredentials(response.data.getDosespotIframeCredentials);
    }
  };

  const loadDosespotPatientId = async () => {
    const response: ApolloQueryResult<GetMember> = await apollo.query({
      query: getMemberQuery,
      variables: {
        id: memberId,
      },
    });

    if (response.data) {
      setDoseSpotPatientId(response.data.getMember?.dosespotId ?? null);
    }
  };

  useEffect(() => {
    async function loadData() {
      await loadDosespotCredentials();
      await loadDosespotPatientId();
      setLoading(false);
    }
    void loadData();
  }, []);

  const buildDosespotIframeUrl = (
    credentials: GetDosespotIframeCredentials_getDosespotIframeCredentials | null,
    refillsErrors: string | null = null,
  ): string => {
    if (!credentials) {
      return '';
    }
    if (refillsErrors) {
      refillsErrors = '&RefillsErrors=' + refillsErrors;
    }
    return `${credentials.url}&PatientId=${dosespotPatientId}${
      refillsErrors || ''
    }`;
  };

  if (loading) {
    return <Loader topMargin={false} />;
  }

  if (!dosespotPatientId || dosespotPatientId === '') {
    logger.error(new Error(`No Dosespot ID for the patient`), { memberId });
    return <div>No Dosespot ID for the member</div>;
  }

  if (!doseSpotCredentials) {
    return <div>No Dosespot credentials</div>;
  }

  return (
    <div className={prescriptionsStyles}>
      <iframe
        title={'DosespotIframe'}
        className={styles.iframe}
        src={buildDosespotIframeUrl(doseSpotCredentials, refillsErrors)}
        sandbox="allow-scripts allow-forms allow-modals allow-same-origin"
      />
    </div>
  );
}
