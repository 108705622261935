import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { DeleteVaultItemsMutation } from '@ginger.io/vault-ui/src/generated/graphql';
import { ReportProblemOutlined as WarningIcon } from '@mui/icons-material';
import ClinicianIcon from '@mui/icons-material/AccountCircleOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClipboardIcon from '@mui/icons-material/AssignmentOutlined';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import UnlockedIcon from '@mui/icons-material/LockOpenOutlined';
import LockedIcon from '@mui/icons-material/LockOutlined';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useTaskMutations from 'app/member-chart-cards/tasks/useTaskMutations';
import { useAppState } from 'app/state';
import { useLogger } from 'app/state/log/useLogger';
import { patientProfileRoute } from 'app/top-nav/Routes';
import { GetAppointmentById_getAppointmentById as Appointment } from 'app/vault/generated/GetAppointmentById';
import { TransientFeatureFlag, useFeatureFlags } from 'hooks/useFeatureFlags';
import { useSnackNotification } from 'hooks/useSnackNotification';
import React, { SyntheticEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Button } from 'shared-components/button/Button';
import { formatDateWithDayOfWeek, getTimezone } from 'utils/dateTime';

import { LockNoteDialog } from './LockNoteDialog';
import styles from './NoteHeader.module.scss';
import { formatStatus } from './utils';

export enum NoteType {
  THERAPY_INTAKE = 'Therapy Intake',
  THERAPY_PROGRESS = 'Therapy Progress',
  PSYCHIATRY_INTAKE = 'Psychiatry Intake',
  PSYCHIATRY_PROGRESS = 'Psychiatry Progress',
  OUT_OF_SESSION = 'Out of Session',
  TERMINATION = 'Termination',
}

type NoteHeaderProps = {
  status: Metadata_NoteStatus;
  noteType: NoteType;
  disabled?: boolean;
  onNoteLocked: () => Promise<void>;
  deleteDraftNote: () => Promise<DeleteVaultItemsMutation>;
  appointment: Appointment;
};

export function NoteHeader(props: NoteHeaderProps) {
  const {
    noteType,
    status,
    appointment,
    deleteDraftNote,
    disabled = true,
  } = props;
  const [openNoteDialog, setLockNoteDialogOpen] = useState(false);
  const [isLocking, setLocking] = useState(false);
  const {
    showErrorNotification,
    showSuccessNotification,
  } = useSnackNotification();
  const history = useHistory();
  const { timezone } = useAppState((_) => _.user);
  const localTimezone = getTimezone(timezone);
  const { apptDate, memberId, memberName, clinicianName } = getAppointmentInfo(
    appointment,
    localTimezone,
  );

  const showConfirmationDialog = () => setLockNoteDialogOpen(true);

  const onClose = () => setLockNoteDialogOpen(false);
  const featureFlags = useFeatureFlags();
  const enableTasksV2 =
    featureFlags.transientFeatureFlags[TransientFeatureFlag.ENABLE_TASKS_V2];
  const enableNotesEfficiency =
    featureFlags.transientFeatureFlags[
      TransientFeatureFlag.ENABLE_CARE_HUB_NOTES_EFFICIENCY
    ];
  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onMoreActionsClick = (event?: SyntheticEvent) => {
    setAnchorEl(event?.currentTarget);
  };
  const onActionMenuClose = () => {
    setAnchorEl(undefined);
  };
  const onDeleteNoteClick = () => {
    setAnchorEl(undefined);
    setIsDeleteModalOpen(true);
  };
  const { dismissNoteTasks } = useTaskMutations({
    memberId: appointment.member.id,
  });

  const onNoteLocked = async () => {
    try {
      onClose();
      setLocking(true);
      await props.onNoteLocked();
      if (enableTasksV2) {
        dismissNoteTasks();
      }
      showSuccessNotification('Note has been signed & locked');
    } catch (e) {
      showErrorNotification('Unable to lock note');
    } finally {
      setLocking(false);
    }
  };

  const onBackClick = () => {
    if (enableNotesEfficiency) {
      history.push(patientProfileRoute(memberId));
    } else {
      history.goBack();
    }
  };

  const actionItems = [];
  if (status === Metadata_NoteStatus.draft_note) {
    actionItems.push(
      <MenuItem
        key="deleteNote"
        data-testid="deleteNote"
        onClick={onDeleteNoteClick}
      >
        Delete Note
      </MenuItem>,
    );
  }

  return (
    <>
      <LockNoteDialog
        data-testid="lockNoteModal"
        memberId={memberId}
        memberName={memberName}
        appointmentDate={apptDate}
        open={openNoteDialog}
        onNoteLocked={onNoteLocked}
        onClose={onClose}
      />
      <DeleteNoteDialog
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        deleteDraft={deleteDraftNote}
      />
      <div className={styles.root}>
        <div className={styles.header}>
          <div className={styles.content}>
            <h1 data-testid="memberInfo">
              {memberName} ({memberId})
            </h1>
            <ul>
              <li>
                <Link
                  className={styles.item}
                  variant="body2"
                  onClick={onBackClick}
                  color="primary"
                >
                  {'< Back to Appointments'}
                </Link>
              </li>
              <li>
                <CalendarIcon />
                <span data-testid="apptDate">{apptDate}</span>
              </li>

              <li>
                <ClinicianIcon />
                <span data-testid="clinician">{clinicianName}</span>
              </li>

              <li>
                <ClipboardIcon />
                <span data-testid="noteType">{noteType}</span>
              </li>

              <li>
                {status === Metadata_NoteStatus.signed_and_locked_note ? (
                  <LockedIcon />
                ) : (
                  <UnlockedIcon />
                )}
                <span className={styles.item}>
                  <span data-testid="status">{formatStatus(status)}</span>
                </span>
              </li>
            </ul>
          </div>
          <div className={styles.action}>
            <Button
              testId="signBtn"
              disabled={disabled || isLocking}
              className={styles.signBtn}
              size="small"
              onClick={showConfirmationDialog}
            >
              {isLocking ? (
                <ClipLoader color="#fff" size={12} />
              ) : (
                'Sign & Lock'
              )}
            </Button>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={onMoreActionsClick}
              className={styles.actionsBtn}
              variant="outlined"
              size="small"
              endIcon={<ArrowDropDownIcon />}
              disabled={actionItems.length === 0 || isLocking}
              testId="moreActionsBtn"
            >
              More Actions
            </Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted={true}
              open={Boolean(anchorEl)}
              onClose={onActionMenuClose}
              PaperProps={{
                style: { marginTop: '12px', width: anchorEl?.clientWidth },
              }}
              anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
              transformOrigin={{ horizontal: 'center', vertical: 'top' }}
            >
              {actionItems}
            </Menu>
          </div>
        </div>
        <Divider />
      </div>
    </>
  );
}

function getAppointmentInfo(
  appointment: Appointment | undefined,
  timezone: string,
): {
  apptDate: string;
  memberName: string;
  memberId: string;
  clinicianName: string;
} {
  if (appointment) {
    const { start, member, clinician } = appointment;
    return {
      apptDate: formatDateWithDayOfWeek(start, timezone),
      clinicianName: clinician.name,
      memberId: member.id,
      memberName: `${member.firstName} ${member.lastName}`,
    };
  }
  return {
    apptDate: '',
    clinicianName: '',
    memberId: '',
    memberName: '',
  };
}

type DeleteNoteDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  deleteDraft: () => Promise<DeleteVaultItemsMutation>;
};

function DeleteNoteDialog({
  deleteDraft,
  isOpen,
  setIsOpen,
}: DeleteNoteDialogProps) {
  const history = useHistory();
  const logger = useLogger();
  const [deleting, setDeleting] = useState(false);
  const {
    showErrorNotification,
    showSuccessNotification,
  } = useSnackNotification();
  const onCancelModalClick = () => {
    setIsOpen(false);
  };

  const onDeleteModalConfirm = async () => {
    try {
      setDeleting(true);
      await deleteDraft();
      showSuccessNotification('The draft note has been deleted');
      history.goBack(); // Go back to appointments
    } catch (error) {
      setDeleting(false);
      logger.error(new Error('DeleteNoteDialog:Unable to delete draft note'), {
        error,
      });
      showErrorNotification('Unable to delete draft note');
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        if (!deleting) setIsOpen(false);
      }}
      className={styles.noteDeletionModal}
    >
      <DialogTitle className={styles.modalTitle}>
        <WarningIcon />
        <span className={styles.titleText}>
          Are you sure you want to delete?
        </span>
      </DialogTitle>
      <DialogContent className={styles.modalBody}>
        <p className={styles.modalText}>
          All note entries <b>will be lost and cannot be recovered</b> once
          deleted.
        </p>
        <div className={styles.buttonContainer}>
          <Button
            disabled={deleting}
            size="medium"
            onClick={onCancelModalClick}
            variant="outlined"
            fullWidth={false}
          >
            Cancel
          </Button>
          <Button
            disabled={deleting}
            size="medium"
            className={styles.deleteBtn}
            onClick={onDeleteModalConfirm}
            fullWidth={false}
          >
            {deleting && <ClipLoader color="#fff" size={12} />} Delete
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
