import Button from '@mui/material/Button';
import { useAppState, useDispatch } from 'app/state';
import { resetFailedRequest, State } from 'app/state/request/actions';
import {
  RequestErrorState,
  RequestSuccessState,
} from 'app/state/request/schema';
import { classNameCombiner } from 'utils';
import { formatTime } from 'utils/dateTime';
import { ReactComponent as CancelIcon } from 'assets/cancel.svg';
import { ReactComponent as CheckCircleIcon } from 'assets/check-circle.svg';
import { ReactComponent as ClearIcon } from 'assets/clear.svg';
import React, { ReactElement, useState } from 'react';

import { ErrorModal } from '../ErrorModal';
import styles from './AppMutationState.module.scss';

export function AppMutationState({ className = '' }: { className?: string }) {
  const { states, timezone } = useAppState(
    ({ request: requestStates, user: { timezone: timezoneState } }) => ({
      states: requestStates,
      timezone: timezoneState,
    }),
  );
  let content: ReactElement | undefined;
  const requestStates = Object.values(states).filter((_) => _);
  const saving = requestStates.filter((_) => _?.state === State.SAVING);
  const errors = requestStates.filter(
    (_) => _?.state === State.ERROR,
  ) as RequestErrorState[];

  if (saving.length > 0) {
    content = <span>Saving...</span>;
  } else if (errors.length > 0) {
    content = <AppMutationErrorState errors={errors} />;
  } else if (
    requestStates.length > 0 &&
    requestStates.every((_) => _?.state === State.SUCCESS)
  ) {
    const latestRequestState = requestStates.sort(
      (a, b) =>
        new Date((b as RequestSuccessState).timestamp).getTime() -
        new Date((a as RequestSuccessState).timestamp).getTime(),
    )[0] as RequestSuccessState;

    content = (
      <span className={`${styles.successState} ${styles.flexCenter}`}>
        <CheckCircleIcon className={styles.iconSize} />
        {`Saved ${formatTime(latestRequestState.timestamp, timezone ?? 'UTC')}`}
      </span>
    );
  }

  return (
    <div
      className={classNameCombiner([
        styles.coachRequestState,
        styles.flexCenter,
        className,
      ])}
    >
      {content}
    </div>
  );
}

function AppMutationErrorState(props: { errors: RequestErrorState[] }) {
  const dispatch = useDispatch();
  const [showDetail, setShowDetail] = useState(false);
  const [showClearButton, setShowClearButton] = useState(false);
  const { errors } = props;

  const clearErrors = () => {
    // resetting request state for failed requests
    dispatch(resetFailedRequest(errors.map(({ queryName }) => queryName)));
    setShowClearButton(false);
  };

  return (
    <>
      <div className={`${styles.errorState} ${styles.flexCenter}`}>
        <span className={styles.flexCenter}>
          <CancelIcon className={styles.iconSize} />
          Error Saving
        </span>
        &nbsp;
        <Button
          disableRipple={true}
          disableFocusRipple={true}
          disableTouchRipple={true}
          className={styles.errorDetailBtn}
          onClick={() => {
            setShowDetail(true);
          }}
        >
          View Details
        </Button>
        {showClearButton && (
          <ClearIcon
            onClick={clearErrors}
            className={`${styles.iconSize} ${styles.clearBtn}`}
          />
        )}
      </div>
      {showDetail && (
        <ErrorModal
          title="An error occurred while trying to save"
          errors={errors.map(({ error }) => error)}
          onSubmitReport={() => {
            clearErrors();
            setShowDetail(false);
          }}
          onClose={() => {
            setShowDetail(false);
            setShowClearButton(true);
          }}
          isOpen={showDetail}
        />
      )}
    </>
  );
}
