import {
  GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions as CoachingSessions,
  GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions_recurrence as Recurrence,
  GetMemberUpcomingCoachingSessions_getMemberUpcomingCoachingSessions_coachingSessions_sessions as Sessions,
} from '@headspace/carehub-graphql/dist/scheduler/generated/GetMemberUpcomingCoachingSessions';
import {
  Availability,
  CreationFields,
} from 'app/member-chart-cards/scheduler/types';

import { MemberSessionUsage, SelectedSessionTimes } from './types';

export enum SchedulerView {
  SUMMARY,
  SESSION_FORM,
  AVAILABILITY,
}

export type State = {
  currentView: SchedulerView;
  sessions: CoachingSessions[];
  coachingSession?: CreationFields;
  selectedCoachingSession?: {
    coachingSession: Sessions;
    recurrence: Recurrence | null;
  };
  selectedSessionTimes?: SelectedSessionTimes;
  coachAvailabilitySchedule: Availability[];
  schedulerError?: Error;
  schedulerLoading: boolean;
  memberSessionUsage?: { [memberId: string]: MemberSessionUsage };
  memberSessionUsageLoading: boolean;
  showD2cSessionInfoBanner?: boolean;
  hasUpcomingVideoSessions: boolean;
};

export const getInitialSchedulerState = (): State => {
  return {
    coachAvailabilitySchedule: [],
    coachingSession: undefined,
    currentView: SchedulerView.SUMMARY,
    hasUpcomingVideoSessions: false,
    memberSessionUsageLoading: false,
    schedulerError: undefined,
    schedulerLoading: false,
    selectedCoachingSession: undefined,
    selectedSessionTimes: undefined,
    sessions: [],
    showD2cSessionInfoBanner: false,
  };
};
