import { FieldDefinitions, useForm } from '@ginger.io/react-use-form';
import { Field } from '@ginger.io/react-use-form/src/types';
import EditIcon from '@mui/icons-material/EditRounded';
import DeleteIcon from '@mui/icons-material/HighlightOffRounded';
import { TableRow } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { IconActionBarItem } from 'app/appointments/IconActionBarItem';
import { Width } from 'types/StyleTypes';
import { ButtonAdd } from 'app/notes-ui/forms/form-controls/ButtonAdd';
import React, { useState } from 'react';

import { PsychiatryHistoryCellV2 } from './PsychiatryHistoryCellV2';
import styles from './PsychiatryHistoryTableV2.module.scss';

export type ColumnDefinition<T> = {
  key: keyof T;
  columnType: 'text' | 'number' | 'date' | 'textarea';
  columnSize?: number;
  label: string;
  placeholder?: string;
};

type Props<T> = {
  initialValue?: T;
  fieldDefs: FieldDefinitions<T>;
  disabled?: boolean;
  columnsDefinition: ColumnDefinition<T>[];
  isEditing?: boolean;
  onRemove: () => void;
  onChange: (value: T) => void;
  onSave: (value: T) => void;
  isNew?: boolean;
  testId?: string;
  addButtonLabel: string;
};

export function PsychiatryHistoryRowV2<T>(props: Props<T>) {
  const {
    initialValue,
    fieldDefs,
    disabled = false,
    columnsDefinition,
    onRemove,
    addButtonLabel,
    isNew = false,
  } = props;
  const { fields, getValue, validate } = useForm(fieldDefs, initialValue);
  const [isEditing, setIsEditing] = useState(isNew);

  const onSave = async () => {
    if (await validate()) {
      setIsEditing(false);
      props.onSave(getValue());
    }
  };

  const columns = columnsDefinition
    .filter(({ key }) => fields[key])
    .map(({ key, columnType, label, placeholder, columnSize }, index) => (
      <PsychiatryHistoryCellV2
        key={key.toString()}
        testId={key.toString()}
        field={fields[key] as Field<any>}
        isEditing={isEditing}
        disabled={disabled}
        columnType={columnType}
        columnSize={columnSize}
        placeholder={placeholder}
        width={Width.FULL}
        label={label}
        onChange={() => props.onChange(getValue())}
      >
        {index === columnsDefinition.length - 1 && !disabled && !isEditing && (
          <ActionButton
            isEditing={isEditing}
            isNew={isNew}
            onEditClick={() => setIsEditing(true)}
            onRemoveClick={onRemove}
            onSaveClick={onSave}
            addButtonLabel={addButtonLabel}
          />
        )}
      </PsychiatryHistoryCellV2>
    ));

  return (
    <>
      <TableRow
        data-testid={props.testId}
        className={`${isEditing ? styles.rowEdit : styles.rowView}`}
      >
        {columns}
      </TableRow>
      {!disabled && isEditing && (
        <TableRow className={`${isEditing ? styles.rowEdit : styles.rowView}`}>
          <TableCell className={styles.actionCell} key="action-cell">
            <ActionButton
              isEditing={isEditing}
              isNew={isNew}
              onEditClick={() => setIsEditing(true)}
              onRemoveClick={onRemove}
              onSaveClick={onSave}
              addButtonLabel={addButtonLabel}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

type ActionButtonProps = {
  isEditing: boolean;
  isNew: boolean;
  onSaveClick: () => Promise<void>;
  onRemoveClick: () => void;
  onEditClick: () => void;
  addButtonLabel: string;
};

function ActionButton(props: ActionButtonProps) {
  const {
    isEditing,
    isNew,
    addButtonLabel,
    onRemoveClick,
    onSaveClick,
    onEditClick,
  } = props;
  let Button;
  if (isEditing) {
    Button = (
      <ButtonAdd
        label={isNew ? addButtonLabel : 'Save'}
        color="blue"
        data-testid="add-btn-label"
        onClick={onSaveClick}
        showLine={false}
      />
    );
  } else {
    Button = (
      <>
        <IconActionBarItem
          title="Edit record"
          Icon={EditIcon}
          testId="editBtn"
          className={styles.actionBtn}
          onClick={onEditClick}
        />
        <IconActionBarItem
          title="Delete record"
          Icon={DeleteIcon}
          testId="removeBtn"
          className={styles.actionBtn}
          onClick={onRemoveClick}
        />
      </>
    );
  }

  return <div className={styles.actionBtnGroup}>{Button}</div>;
}
