import { OpenInNew } from '@mui/icons-material';

import styles from '../SchedulerCard.module.scss';

export const ViewCalendarLink = () => (
  <a
    className={styles.gcalLink}
    rel="noopener noreferrer"
    href="https://calendar.google.com/"
    target="_blank"
  >
    View your calendar
    <OpenInNew className={styles.calendarIcon} />
  </a>
);
