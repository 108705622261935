import { Fields } from '@ginger.io/react-use-form';
import { BooleanOption } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/BooleanOption';
import { HomicideRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/HomicideRisk';
import { RiskLevel } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/RiskLevel';
import { SuicideRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/SuicideRisk';
import DeleteIcon from '@mui/icons-material/HighlightOffRounded';
import { Typography } from '@mui/material';
import {
  RiskFormComponent,
  RiskFormProps,
  RiskType,
} from 'app/coach/coach-notes/CoachNotesTypes';
import { CardTextAreaField } from 'app/coach/coach-notes/note-detail-view/note-fields';
import {
  homicideRisk,
  suicideRisk,
} from 'app/coach/coach-notes/note-detail-view/note-templates/fieldDefs';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { useRiskForm } from 'app/coach/coach-notes/useRiskForm';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import { EnumRadioField, YesOrNoField } from 'app/notes-ui/forms/fields';
import { BooleanRadioField } from 'app/notes-ui/forms/form-controls/BooleanRadioField';
import { classNameCombiner } from 'utils';
import React from 'react';

enum FieldLabels {
  RISK_LEVEL = 'Risk level outcome *',
  THOUGHTS = 'Thoughts',
  INTENT = 'Intent *',
  PLAN = 'Plan',
  MEANS = 'Means',
  SUMMARY = 'Summarization of answers',
  DISCLOSURE = 'Disclosure to anyone, such as a friend, family member, PCP, or therapist?',
  DISCLOSED_TO = 'Who? *',
  OTHER_RELEVANT_INFO = 'Other relevant information',
  LOCATION = 'Current location, if imminent',
}

enum Placeholders {
  SUMMARY = 'Type here...',
  DISCLOSED_TO = 'Type here...',
  OTHER_RELEVANT_INFO = 'Former attempts, previous treatment...',
  OTHER_RELEVANT_INFO_HOMICIDE = 'Former attempts, previous treatment, identifiable targets...',
  LOCATION = 'Type here...',
}

export const SuicideOrHomicideRisk = ({
  fields,
  readOnly,
  riskType,
  'data-testid': testId = 'suicideOrHomicideRisk',
}: {
  fields: Fields<SuicideRisk> | Fields<HomicideRisk>;
  readOnly: boolean;
  inline?: boolean;
  riskType: RiskType;
  'data-testid'?: string;
}): React.ReactElement | null => {
  const isHomicideRisk = riskType === RiskType.HOMICIDE_RISK;
  const label = isHomicideRisk ? 'Homicide Risk' : 'Suicide Risk';

  return (
    <>
      <EnumRadioField
        label={FieldLabels.RISK_LEVEL}
        disabled={readOnly}
        options={RiskLevel}
        field={fields.riskLevel}
        formControlStyles={{ label: styles.label }}
      />

      <div className={styles.radioButtonContainer}>
        <YesOrNoField
          gridClass={styles.yesOrNoField}
          buttonContainerClass={styles.yesOrNoButtonContainer}
          testId="thoughts"
          label={FieldLabels.THOUGHTS}
          yesLabel="Endorsed"
          noLabel="Denied"
          field={fields.thoughts}
          disabled={readOnly}
          fullWidth={true}
        />
        <YesOrNoField
          gridClass={styles.yesOrNoField}
          buttonContainerClass={styles.yesOrNoButtonContainer}
          testId="intent"
          label={FieldLabels.INTENT}
          yesLabel="Endorsed"
          noLabel="Denied"
          field={fields.intent}
          disabled={readOnly}
          fullWidth={true}
        />
        <YesOrNoField
          gridClass={styles.yesOrNoField}
          buttonContainerClass={styles.yesOrNoButtonContainer}
          testId="plan"
          label={FieldLabels.PLAN}
          yesLabel="Endorsed"
          noLabel="Denied"
          field={fields.plan}
          disabled={readOnly}
          fullWidth={true}
        />
        <YesOrNoField
          gridClass={styles.yesOrNoField}
          buttonContainerClass={styles.yesOrNoButtonContainer}
          testId="means"
          label={FieldLabels.MEANS}
          yesLabel="Endorsed"
          noLabel="Denied"
          field={fields.means}
          disabled={readOnly}
          fullWidth={true}
        />
      </div>

      <FieldLabel className={styles.label} labelText={FieldLabels.SUMMARY} />
      <CardTextAreaField
        name="suicideOrHomicideSummary"
        field={fields.summary}
        tooltipEventLabel={`Coach Notes > ${label}: ${FieldLabels.SUMMARY}`}
        tooltipText=""
        placeholder={Placeholders.SUMMARY}
        readOnly={readOnly}
      />

      <FieldLabel className={styles.label} labelText={FieldLabels.DISCLOSURE} />
      <BooleanRadioField
        disabled={readOnly}
        name="disclosure"
        value={fields.disclosure.value}
        onChange={fields.disclosure.setValue}
      />

      {fields.disclosure.value === BooleanOption.yes && (
        <>
          <FieldLabel
            className={styles.label}
            labelText={FieldLabels.DISCLOSED_TO}
          />
          <CardTextAreaField
            name="suicideOrHomicideDisclosedTo"
            field={fields.disclosedTo}
            tooltipEventLabel={`Coach Notes > ${label}: ${FieldLabels.DISCLOSED_TO}`}
            tooltipText=""
            placeholder={Placeholders.DISCLOSED_TO}
            readOnly={readOnly}
          />
        </>
      )}

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.OTHER_RELEVANT_INFO}
      />
      <CardTextAreaField
        name="suicideOrHomicideOtherRelevantInfo"
        field={fields.otherRelevantInfo}
        tooltipEventLabel={`Coach Notes > ${label}: ${FieldLabels.OTHER_RELEVANT_INFO}`}
        tooltipText=""
        placeholder={
          isHomicideRisk
            ? Placeholders.OTHER_RELEVANT_INFO_HOMICIDE
            : Placeholders.OTHER_RELEVANT_INFO
        }
        readOnly={readOnly}
      />

      <FieldLabel className={styles.label} labelText={FieldLabels.LOCATION} />
      <CardTextAreaField
        name="suicideOrHomicideLocation"
        field={fields.location}
        tooltipEventLabel={`Coach Notes > ${label}: ${FieldLabels.LOCATION}`}
        tooltipText=""
        placeholder={Placeholders.LOCATION}
        readOnly={readOnly}
      />
    </>
  );
};

export const SuicideRiskForm: RiskFormComponent<SuicideRisk> = (
  props: RiskFormProps<SuicideRisk>,
) => {
  const {
    readOnly,
    inline,
    isRemovable,
    initialValue,
    onChange,
    removeRisk,
  } = props;
  const { fields, onRemoveRisk } = useRiskForm<SuicideRisk>({
    initialValue,
    onChange,
    removeRisk,
    riskType: RiskType.SUICIDE_RISK,
    validationSchema: suicideRisk,
  });

  return (
    <div className={inline ? styles.inlineRiskSection : styles.cardSection}>
      <div
        className={classNameCombiner([
          styles.riskTitleField,
          isRemovable ? styles.withButton : '',
        ])}
      >
        <Typography className={styles.riskAssessmentTitle}>
          Suicide Risk
        </Typography>
        <span className={styles.inlineBorder} />
        {isRemovable && (
          <DeleteIcon
            data-testid="removeBtn"
            className={styles.removeRiskBtn}
            onClick={onRemoveRisk}
          />
        )}
      </div>
      <SuicideOrHomicideRisk
        riskType={RiskType.SUICIDE_RISK}
        fields={fields}
        readOnly={readOnly}
      />
    </div>
  );
};

export const HomicideRiskForm: RiskFormComponent<HomicideRisk> = (
  props: RiskFormProps<HomicideRisk>,
) => {
  const {
    readOnly,
    inline,
    isRemovable,
    initialValue,
    onChange,
    removeRisk,
  } = props;
  const { fields, onRemoveRisk } = useRiskForm<HomicideRisk>({
    initialValue,
    onChange,
    removeRisk,
    riskType: RiskType.HOMICIDE_RISK,
    validationSchema: homicideRisk,
  });

  return (
    <div className={inline ? styles.inlineRiskSection : styles.cardSection}>
      <div
        className={classNameCombiner([
          styles.riskTitleField,
          isRemovable ? styles.withButton : '',
        ])}
      >
        <Typography className={styles.riskAssessmentTitle}>
          Homicide Risk /
        </Typography>
        <span className={styles.inlineBorder} />
        <Typography className={styles.riskAssessmentSubtitle}>
          Risk of Harm to Others
        </Typography>
        {isRemovable && (
          <DeleteIcon
            data-testid="removeBtn"
            className={styles.removeRiskBtn}
            onClick={onRemoveRisk}
          />
        )}
      </div>
      <SuicideOrHomicideRisk
        riskType={RiskType.HOMICIDE_RISK}
        fields={fields}
        readOnly={readOnly}
      />
    </div>
  );
};
