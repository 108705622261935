import { ApolloQueryResult } from '@apollo/client';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { ILogger } from 'app/state/log/Logger';
import { DocumentNode } from 'graphql';
import { OperationDefinitionNode } from 'graphql/language/ast';

function getGQLQueryName(query: DocumentNode | TypedDocumentNode<any, any>) {
  const ops = query?.definitions.find(
    (_) =>
      _.kind === 'OperationDefinition' &&
      (_.operation === 'mutation' || _.operation === 'query'),
  ) as OperationDefinitionNode;
  return ops?.name?.value;
}

type Options = { query?: DocumentNode | TypedDocumentNode<any, any> };

export function assertResponseHasNoError(
  res: ApolloQueryResult<any>,
  logger: ILogger,
  options: Partial<Options> = {},
) {
  const { errors, data } = res;

  if (!errors || data) return;
  const query = options?.query;
  logger.error(
    new Error(`assertResponseHasNoError::GQL error ${errors[0].message}`),
    {
      errors,
      operationName: query ? getGQLQueryName(query) : undefined,
    },
  );
  throw errors[0];
}
