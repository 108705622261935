import {
  assignHomeworkResultStatus,
  HomeworkMap,
} from 'app/patients/tabs/content/types';

export interface HomeworkAssignmentStatus {
  title: string;
  dateAndTime: string;
  status: assignHomeworkResultStatus;
}

export type State = {
  contentMap: HomeworkMap;
  // a key is a channelId
  homeworkAssignmentStatus: Record<string, HomeworkAssignmentStatus>;
};

export const getInitialContentState = (): State => {
  return {
    contentMap: {},
    homeworkAssignmentStatus: {},
  };
};
