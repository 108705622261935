import { Field } from '@ginger.io/react-use-form/dist/types';
import { DateMessage } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/Date';
import { Time } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/Time';
import { validateTimeFormat } from 'app/notes-ui/shared/ValidationRules';
import moment from 'moment';

export type TimeField = {
  hour: Field<Time['hour']>;
  minute: Field<Time['minute']>;
  second: Field<Time['second']>;
};

export function getTimeValue(time: Time | undefined) {
  if (
    time === undefined ||
    time.minute === undefined ||
    time.hour === undefined
  )
    return '';
  const { hour, minute } = time;
  return moment.utc(`${hour}:${minute}`, 'HH:mm').format('HH:mm');
}

export function setTimeValue(value: string, timeField: TimeField) {
  if (validateTimeFormat(value) === undefined) {
    const momentTime = moment.utc(value, 'HH:mm');
    timeField.minute.setValue(momentTime.get('minute'));
    timeField.hour.setValue(momentTime.get('hour'));
  }
}

export function getTimeError(time: TimeField) {
  const { hour, minute, second } = time;
  return hour.error || minute.error || second.error;
}

export function getDateValue(date: DateMessage | undefined): string {
  if (date === undefined) return '';

  const hasEmptyComponent = Object.values(date).some(
    (value) => value === undefined || value === 0,
  );

  if (hasEmptyComponent) {
    return '';
  }

  const { day, month, year } = date;
  const padWithZero = (value: number) => `${value}`.padStart(2, '0');
  return `${padWithZero(year)}-${padWithZero(month)}-${padWithZero(day)}`;
}
