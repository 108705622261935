import { Box } from '@mui/material';
import React from 'react';

import styles from './CareMetrics.module.scss';

function FeedbackRatingComponent(props: { rating?: number | null }) {
  return (
    <Box className={styles.feedbackWrapper}>
      <div className={styles.rating}>
        {props.rating}
        <span className={styles.mute}>/5</span>
      </div>
      <div>Avg Star Rating</div>
    </Box>
  );
}

export default FeedbackRatingComponent;
