import { useAppState } from 'app/state';
import { classNameCombiner } from 'utils';
import { formatDatetimeStr } from 'utils/dateTime';
import React from 'react';

import styles from '../styles/InboxSectionItem.module.scss';

export interface ScheduledContentProps {
  nextAppointment?: string;
  prevAppointment?: string;
  latestWriteTimestamp?: string | null;
}

export const ScheduledContent = (props: ScheduledContentProps) => {
  const { nextAppointment, prevAppointment, latestWriteTimestamp } = props;
  const {
    user: { timezone },
  } = useAppState((state) => state);

  const format = 'M/D/YY';
  const nextLabel = nextAppointment
    ? formatDatetimeStr(nextAppointment, format, timezone ?? 'UTC')
    : undefined;

  const timeStampForLastLabel = latestWriteTimestamp || prevAppointment;
  const lastLabel = timeStampForLastLabel
    ? formatDatetimeStr(timeStampForLastLabel, format, timezone!)
    : undefined;

  return (
    <div className={classNameCombiner([styles.tr, styles.summary])}>
      <div className={classNameCombiner([styles.td, styles.text])}>
        {nextLabel && (
          <>
            <span className={styles.bold}>Next:</span>
            &nbsp;
            {nextLabel}
          </>
        )}
        {lastLabel && nextLabel && <span>&nbsp;•&nbsp;</span>}
        {lastLabel && (
          <>
            <span className={styles.bold}>Last:</span>
            &nbsp;
            {lastLabel}
          </>
        )}
      </div>
    </div>
  );
};
