import {
  AmplitudeLogsContext,
  ReadMessageAmplitudeEvent,
  TypingMessageAmplitudeEvent,
  TypingState,
} from 'app/coach/chat/types';
import { GetAllTaskItemsForMe_getTasksForMember } from 'app/coach/member-chart/generated/GetAllTaskItemsForMe';
import {
  MessageToDisplay,
  PUBNUB_STATUS,
  TimeoutsMap,
} from 'app/coach/pubnub/types';
import { GetMemberCoachingTeam_getMember_coachingCareTeam as CoachingCareTeam } from 'app/queries/generated/GetMemberCoachingTeam';
import { PubnubConfig } from 'app/state/user/actions';
import { createAction } from 'redux-reloaded';

export enum Actions {
  SET_PUBLISH_LATENCY_TIMEOUT = 'SET_PUBLISH_LATENCY_TIMEOUT',
  UPDATE_TIMEOUTS_MAP = 'UPDATE_TIMEOUTS_MAP',
  INITIAL_PUBNUB = 'INITIAL_PUBNUB',
  UPDATE_PUBNUB_STATUS = 'UPDATE_PUBNUB_STATUS',
  SET_MEMBER_COACH_CHANNEL_WILD_CARD_PREFIX = 'SET_MEMBER_COACH_CHANNEL_WILD_CARD_PREFIX',
  ADD_TYPING_STATE = 'ADD_TYPING_STATE',
  UPDATE_TYPING_STATE = 'UPDATE_TYPING_STATE',
  SET_INPUT_VALUE = 'SET_INPUT_VALUE',
  SET_SHOULD_PLAY_NOTIFICATION_SOUND = 'SET_SHOULD_PLAY_NOTIFICATION_SOUND',
  ON_CARE_PROVIDER_TYPING_EVENT = 'ON_CARE_PROVIDER_TYPING_EVENT',
  ON_READING_MESSAGES_BY_CARE_PROVIDER = 'ON_READING_MESSAGES_BY_CARE_PROVIDER',
  UPDATE_CHAT_READ_ONLY = 'UPDATE_CHAT_READ_ONLY',
  MAYBE_UPDATE_READ_ONLY_STATE = 'MAYBE_UPDATE_READ_ONLY_STATE',
  PARSE_LINKS_AND_LOG_IF_ANY = 'PARSE_LINKS_AND_LOG_IF_ANY',
  ON_ENTER_CLICK = 'ON_ENTER_CLICK',
  SEND_MESSAGE = 'SEND_MESSAGE',
  ON_PUBLISH_SUCCESS = 'ON_PUBLISH_SUCCESS',
  ON_PUBLISH_ERROR = 'ON_PUBLISH_ERROR',
  SET_TASK_RELATED_MESSAGES = 'SET_TASK_RELATED_MESSAGES',
}

export const clearMessagePublishLatencyTimer = createAction(
  Actions.UPDATE_TIMEOUTS_MAP,
  (messageId: string) => ({
    messageId,
  }),
);

export const setPublishLatencyTimeout = createAction(
  Actions.SET_PUBLISH_LATENCY_TIMEOUT,
  (timeoutObject: TimeoutsMap) => ({
    timeoutObject,
  }),
);

export const initializePubnub = createAction(
  Actions.INITIAL_PUBNUB,
  (
    payload: PubnubConfig & {
      listenerId: string | null;
      publishKey: string | null;
      subscribeKey: string | null;
      useWildCard: boolean;
      logVerbosity: boolean;
    },
  ) => ({
    ...payload,
  }),
);

export const updatePubnubStatus = createAction(
  Actions.UPDATE_PUBNUB_STATUS,
  (status: PUBNUB_STATUS) => ({
    status,
  }),
);

export const setMemberCoachChannelPrefixForCoach = createAction(
  Actions.SET_MEMBER_COACH_CHANNEL_WILD_CARD_PREFIX,
  (memberCoachChannelPrefix: string | null) => ({ memberCoachChannelPrefix }),
);

export const addTypingState = createAction(
  Actions.ADD_TYPING_STATE,
  ({ channelId }: { channelId: string }) => ({
    channelId,
  }),
);

export const updateTypingState = createAction(
  Actions.UPDATE_TYPING_STATE,
  ({
    channelId,
    updatedParam,
  }: {
    channelId: string;
    updatedParam: Partial<TypingState>;
  }) => ({
    channelId,
    updatedParam,
  }),
);

export const setInputValue = createAction(
  Actions.SET_INPUT_VALUE,
  ({ channelId, input }: { channelId: string; input: string }) => ({
    channelId,
    input,
  }),
);

export const setShouldPlayNotificationSound = createAction(
  Actions.SET_SHOULD_PLAY_NOTIFICATION_SOUND,
  ({ shouldPlaySound }: { shouldPlaySound: boolean }) => ({
    shouldPlaySound,
  }),
);

export const onCareProviderTypingEvent = createAction(
  Actions.ON_CARE_PROVIDER_TYPING_EVENT,
  (payload: {
    logsContext: TypingMessageAmplitudeEvent;
    updatedTypingState: Partial<TypingState>;
    channelId: string;
  }) => payload,
);

export const onReadingMessagesByCareProvider = createAction(
  Actions.ON_READING_MESSAGES_BY_CARE_PROVIDER,
  (payload: { channelId: string; logsPayload: ReadMessageAmplitudeEvent }) =>
    payload,
);

export const updateChatReadOnly = createAction(
  Actions.UPDATE_CHAT_READ_ONLY,
  ({ channelId, isReadOnly }: { channelId: string; isReadOnly: boolean }) => ({
    channelId,
    isReadOnly,
  }),
);

export const maybeUpdateReadOnlyState = createAction(
  Actions.MAYBE_UPDATE_READ_ONLY_STATE,
  ({
    channelId,
    coachingCareTeam,
  }: {
    channelId: string;
    coachingCareTeam: CoachingCareTeam;
  }) => ({
    channelId,
    coachingCareTeam,
  }),
);

export const parseLinksAndLogIfAny = createAction(
  Actions.PARSE_LINKS_AND_LOG_IF_ANY,
  ({
    message,
    logsContext,
  }: {
    message: string;
    logsContext: AmplitudeLogsContext;
  }) => ({
    logsContext,
    message,
  }),
);

export const onEnterClick = createAction(
  Actions.ON_ENTER_CLICK,
  ({
    logsContext,
    channelId,
    inputValue,
    nextMessageId,
    memberId,
  }: {
    logsContext: AmplitudeLogsContext;
    channelId: string;
    inputValue: string;
    nextMessageId: string;
    memberId: string;
  }) => ({ channelId, inputValue, logsContext, memberId, nextMessageId }),
);

export const sendMessage = createAction(
  Actions.SEND_MESSAGE,
  ({
    logsContext,
    channelId,
    inputValue,
    nextMessageId,
    memberId,
  }: {
    logsContext: AmplitudeLogsContext;
    channelId: string;
    inputValue: string;
    nextMessageId: string;
    memberId: string;
  }) => ({ channelId, inputValue, logsContext, memberId, nextMessageId }),
);

export const onPublishSuccess = createAction(
  Actions.ON_PUBLISH_SUCCESS,
  ({
    timetoken,
    conversationId,
  }: {
    timetoken: string;
    conversationId?: string | null;
  }) => ({
    conversationId,
    timetoken,
  }),
);

export const onPublishError = createAction(
  Actions.ON_PUBLISH_ERROR,
  ({
    sentMessage,
    channelId,
    logsContext,
  }: {
    sentMessage: MessageToDisplay;
    logsContext: AmplitudeLogsContext;
    channelId: string;
  }) => ({
    channelId,
    logsContext,
    sentMessage,
  }),
);

export const setTaskRelatedMessages = createAction(
  Actions.SET_TASK_RELATED_MESSAGES,
  (payload: { tasks: GetAllTaskItemsForMe_getTasksForMember | null }) =>
    payload,
);
