import { Button } from '@ginger.io/core-ui';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CheckmarkIcon } from 'assets/inbox/sections/checkmark.svg';
import { ChatConversationState } from 'generated/globalTypes';
import React, { useState } from 'react';
import { ToggleButton } from 'shared-components/buttons/ToggleButton';
import Tooltip from 'shared-components/tooltip/Tooltip';

import { ChatClock } from './ChatClock';
import styles from './ChatHeader.module.scss';

interface ChatHeaderProps {
  memberId: string;
  firstName: string;
  timezone: string;
  toggleExpansion: () => void;
  isExpanded: boolean;
  conversationState?: ChatConversationState;
  onCloseConversation: () => Promise<boolean>;
}

export const ChatHeader = ({
  timezone,
  firstName,
  toggleExpansion,
  isExpanded,
  onCloseConversation,
  conversationState,
  memberId,
}: ChatHeaderProps) => {
  const chatTooltipTitle = (
    <span className={styles.tooltipTitle}>EXPAND CHAT</span>
  );

  return (
    <div className={styles.header}>
      <div className={styles.tooltipContainer}>
        <Tooltip
          classes={{ popper: styles.popper, tooltip: styles.tooltip }}
          title={chatTooltipTitle}
          enterDelay={0}
          enterTouchDelay={0}
          placement="right"
        >
          <div>
            <ToggleButton isOn={isExpanded} onChange={toggleExpansion} />
          </div>
        </Tooltip>

        <ChatClock
          firstName={firstName}
          timezone={timezone}
          memberId={memberId}
        />
      </div>
      <CloseConversationButton
        onClick={onCloseConversation}
        state={conversationState}
      />
    </div>
  );
};

function CloseConversationButton(props: {
  onClick: () => Promise<boolean>;
  state?: ChatConversationState;
}) {
  const { state = ChatConversationState.OPEN } = props;
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    setLoading(true);
    void props.onClick().finally(() => setLoading(false));
  };

  if (loading) {
    return <span className={styles.closingText}>closing...</span>;
  }
  return (
    <>
      {state === ChatConversationState.OPEN && (
        <Button
          testid="closeConversation"
          className={styles.closeBtn}
          onClick={onClick}
        >
          Close conversation
        </Button>
      )}
      {state === ChatConversationState.DONE && (
        <IconButton
          className={`${styles.btn}`}
          disableRipple={true}
          disableFocusRipple={true}
          disableTouchRipple={true}
        >
          <span className={styles.closeText}>Closed</span>
          &nbsp;
          <CheckmarkIcon />
        </IconButton>
      )}
    </>
  );
}
