import { OutOfSessionNote } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/OutOfSessionNote';
import { Metadata_NoteStatus as NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { VaultItemPermissions } from '@ginger.io/vault-ui';
import { useOnMount } from 'hooks/useOnMount';
import { useLogger } from 'app/state/log/useLogger';
import { StateSlice } from 'app/state/status/types/StateSlice';
import { NonAppointmentNoteAction } from 'app/vault/api/NonAppointmentNotesAPI';
import { MemberDetailsById_getMember as MemberDetails } from 'app/vault/generated/MemberDetailsById';
import { useClinicalNotesAPI } from 'app/vault/hooks/useClinicalNotesAPI';
import { useStateSlice } from 'app/vault/hooks/utils';
import { useCallback, useState } from 'react';

import { vaultItemFragment_creator as Creator } from './generated/vaultItemFragment';

export type OutOfSessionNoteResult = {
  note: StateSlice<OutOfSessionNote | null>;
  createOrUpdateNote: (
    data: OutOfSessionNote,
    action: NonAppointmentNoteAction,
    itemId?: string,
  ) => Promise<{ itemId?: string }>;
  patientProfile?: MemberDetails;
  creator?: Creator;
  noteStatus: NoteStatus;
};

export function useOutOfSessionNote(
  patientId: string,
  clinicianId: string,
  vaultItemId?: string,
): OutOfSessionNoteResult {
  const noteState = useStateSlice<OutOfSessionNote | null>();
  const logger = useLogger();
  const { nonAppointmentNotesAPI } = useClinicalNotesAPI();
  const [patientProfile, setPatient] = useState<MemberDetails>();
  const [creator, setCreator] = useState<Creator>();
  const [noteStatus, setNoteStatus] = useState<NoteStatus>(
    NoteStatus.draft_note,
  );

  useOnMount(() => {
    const callback = async () => {
      try {
        noteState.setLoading();
        if (vaultItemId) {
          const [patient, result] = await Promise.all([
            nonAppointmentNotesAPI.getMemberDetails(patientId),
            nonAppointmentNotesAPI.getOutOfSessionNote(vaultItemId, patientId),
          ]);
          setCreator(result.creator);
          setPatient(patient);
          setNoteStatus(
            result.permissions === VaultItemPermissions.ReadOnly
              ? NoteStatus.signed_and_locked_note
              : NoteStatus.draft_note,
          );
          noteState.setData(result.note);
        } else {
          const patient = await nonAppointmentNotesAPI.getMemberDetails(
            patientId,
          );
          setPatient(patient);
          noteState.setData(null);
        }
      } catch (e) {
        logger.error(
          new Error('useOutOfSessionNote: Error fetching out of session note'),
          { error: e },
        );
        noteState.setError(e);
      }
    };
    void callback();
  });

  const createNote = useCallback(
    async (
      data: OutOfSessionNote,
      action: NonAppointmentNoteAction,
      itemId?: string,
    ) => {
      const result = await nonAppointmentNotesAPI.createOutOfSessionNote({
        action,
        clinicianId,
        itemId,
        memberId: patientId,
        outOfSessionNote: data,
      });
      if (
        [
          NonAppointmentNoteAction.CREATE_AND_LOCK,
          NonAppointmentNoteAction.LOCK,
        ].includes(action)
      ) {
        setNoteStatus(NoteStatus.signed_and_locked_note);
      }
      noteState.setData(data);
      return result;
    },
    [patientId, clinicianId, noteState, nonAppointmentNotesAPI],
  );

  return {
    createOrUpdateNote: createNote,
    creator,
    note: noteState.state.current,
    noteStatus,
    patientProfile,
  };
}
