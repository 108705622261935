import { ClinicianIntakeChecklist_Version } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/ClinicianIntakeChecklist';
import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { validate as validateClinicianChecklist } from 'app/notes-ui/shared/clinician-checklist/ClinicianChecklistForm';
import { validate as validateCollaboration } from 'app/notes-ui/shared/collaboration-plan/schema';
import { validate as validateSafety } from 'app/notes-ui/shared/safety-intake/SafetyForm';
import { validateTherapy as validateSafetyV2 } from 'app/notes-ui/shared/safety-intake/schema';
import {
  validate as validateSubstanceUsed,
  validateV2 as validateSubstanceUsedV2,
} from 'app/notes-ui/shared/substance-abuse/schema';
import { validate as validateAssessment } from 'app/notes-ui/therapy/intake/assessment/schema';
import { validate as validateBehavioralObservationV2 } from 'app/notes-ui/therapy/intake/behavioral-observations/BehavioralObservationsFormv2';
import { validate as validateClinicianChecklistV2 } from 'app/notes-ui/therapy/intake/clinician-checklist/ClinicianChecklistFormV2';
import { validate as validateCurrentFunctioning } from 'app/notes-ui/therapy/intake/current-funtioning/CurrentFunctioningForm';
import { validate as validateCurrentFunctioningV2 } from 'app/notes-ui/therapy/intake/current-funtioning/CurrentFunctioningFormv2';
import { validate as validateMedicalHealth } from 'app/notes-ui/therapy/intake/medical-health/MedicalHealthForm';
import { validate as validatePresentingProblem } from 'app/notes-ui/therapy/intake/presenting-problem/PresentingProblemForm';
import { validate as validatePresentingProblemV2 } from 'app/notes-ui/therapy/intake/presenting-problem/PresentingProblemFormV2';
import { validate as validateSocialDevelopment } from 'app/notes-ui/therapy/intake/social-development/SocialDevelopmentForm';
import { validate as validateSocialDevelopmentV2 } from 'app/notes-ui/therapy/intake/social-development/SocialDevelopmentFormV2';
import { validate as validateTreatmentPlan } from 'app/notes-ui/therapy/intake/treatment-plan/TreatmentPlanForm';
import { validate as validateTreatmentPlanV2 } from 'app/notes-ui/therapy/intake/treatment-plan/TreatmentPlanFormV2';
import { validate as validateBehavioralObservation } from 'app/notes-ui/therapy/shared/behavioral-observations/schema';

import { TherapyIntakeNote } from '../api/TherapyIntakeNotesAPI';

export function TherapyIntakeIsNoteLockable(note: TherapyIntakeNote): boolean {
  return (
    note.metadata.status === Metadata_NoteStatus.draft_note &&
    validateAssessment(note.ASSESSMENT) &&
    validateBehavioralObservation(note.BEHAVIORAL_OBSERVATION) &&
    validateClinicianChecklist(note.CLINICIAN_CHECKLIST) &&
    validateCollaboration(note.COLLABORATION_PLAN) &&
    validateCurrentFunctioning(note.CURRENT_FUNCTIONING) &&
    validateMedicalHealth(note.MEDICAL_HEALTH) &&
    validatePresentingProblem(note.PRESENTING_PROBLEM) &&
    validateSafety(note.SAFETY) &&
    validateSocialDevelopment(note.SOCIAL_DEVELOPMENTAL) &&
    validateSubstanceUsed(note.SUBSTANCE_ABUSE) &&
    validateTreatmentPlan(note.TREATMENT_PLAN)
  );
}

export function TherapyIntakeIsNoteLockableV2(
  note: TherapyIntakeNote,
): boolean {
  return (
    note.metadata.status === Metadata_NoteStatus.draft_note &&
    validateAssessment(note.ASSESSMENT) &&
    validateBehavioralObservationV2(note.BEHAVIORAL_OBSERVATION) &&
    validateClinicianChecklistV2(note.CLINICIAN_CHECKLIST) &&
    validateCurrentFunctioningV2(note.CURRENT_FUNCTIONING) &&
    validatePresentingProblemV2(note.PRESENTING_PROBLEM) &&
    validateSafetyV2(note.SAFETY) &&
    validateSocialDevelopmentV2(note.SOCIAL_DEVELOPMENTAL) &&
    validateSubstanceUsedV2(note.SUBSTANCE_ABUSE) &&
    validateTreatmentPlanV2(note.TREATMENT_PLAN)
  );
}

/**
 * This is a map for therapy intake note versions to their respective validation functions
 * The Record is used to ensure that all versions are accounted for
 * It uses the ClinicianIntakeChecklist_Version enum as the key as it is the first section in the note
 * and we don't have a note version field in the note metadata
 */
export const versionValidationMap: Record<
  ClinicianIntakeChecklist_Version,
  (note: TherapyIntakeNote) => boolean
> = {
  [ClinicianIntakeChecklist_Version.undefined_version]: TherapyIntakeIsNoteLockable,
  [ClinicianIntakeChecklist_Version.UNRECOGNIZED]: TherapyIntakeIsNoteLockable,
  // V0 corresponds to the most recent version of the note, as previous versions were not versioned
  [ClinicianIntakeChecklist_Version.v0]: TherapyIntakeIsNoteLockableV2,
};

/**
 * This function is used to validate a therapy intake note
 * It checks for the notes schema version and then calls the appropriate validation function
 * @param note The note to validate
 * @returns true if the note is valid, false otherwise
 */
export function validateTherapyIntakeNote(
  note: TherapyIntakeNote | null,
): boolean {
  if (!note || note.CLINICIAN_CHECKLIST?.version === undefined) return false;

  // select the validation function based on the version of the clinician checklist
  // the reason for using clinician checks is merely because it is the first section and we
  // don't have a note version field in the note metadata
  const validationFunction =
    versionValidationMap[note.CLINICIAN_CHECKLIST?.version];

  return validationFunction ? validationFunction(note) : false;
}
