import { useMutation } from '@apollo/client';
import { ApolloCachingStrategy } from 'app/constants';
import { TransientFeatureFlag, useFeatureFlags } from 'hooks/useFeatureFlags';
import {
  updateMemberProfileAction,
  viewMemberProfileAction,
} from 'app/state/amplitude/actions/etc';
import { useLogger } from 'app/state/log/useLogger';
import { getExpectedErrorMessages } from 'utils/errorUtils';
import { DemographicsInput } from 'generated/globalTypes';
import { useQuery } from 'hooks/useQuery';
import { useSnackNotification } from 'hooks/useSnackNotification';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  GetMember,
  GetMember_getMember as Member,
  GetMemberVariables,
} from './generated/GetMember';
import {
  UpdateMemberDemographics,
  UpdateMemberDemographicsVariables,
} from './generated/UpdateMemberDemographics';
import {
  getMemberQuery,
  updateMemberDemographicsMutationV2,
} from './MemberDemographicsGQL';
import { SummaryTab } from './SummaryTab';

type ProfileProps = {
  member: Member;
  onUpdateDemographics: (demographics: Partial<DemographicsInput>) => void;
  isSchedulingUIEnabled?: boolean;
};

export function SupervisorMemberProfile(props: ProfileProps) {
  const dispatch = useDispatch();
  const {
    member,
    member: { id },
    onUpdateDemographics,
  } = props;

  useEffect(() => {
    dispatch(viewMemberProfileAction({ patientId: id }));
  }, [dispatch, id]);

  return (
    <SummaryTab member={member} onUpdateDemographics={onUpdateDemographics} />
  );
}

export function SupervisorProfile(props: { memberId: string }) {
  const dispatch = useDispatch();
  const {
    showErrorNotification,
    showSuccessNotification,
  } = useSnackNotification();

  const featureFlags = useFeatureFlags();
  const isSchedulingUIEnabled =
    featureFlags.transientFeatureFlags[TransientFeatureFlag.SCHEDULE] ?? false;
  const [updateDemographics, mutationResult] = useMutation<
    UpdateMemberDemographics,
    UpdateMemberDemographicsVariables
  >(updateMemberDemographicsMutationV2);
  const { memberId } = props;
  const logger = useLogger();

  useEffect(() => {
    const { data: updateData, error: updateError, called } = mutationResult;
    if (called) {
      if (updateError) {
        showErrorNotification(`Unable to update user #${memberId}`, true);
      } else if (updateData) {
        const errors = updateData?.updateMemberDemographicsV2?.errors;

        if (errors && errors.length > 0) {
          showErrorNotification(
            `Unable to update user #${memberId}: ${getExpectedErrorMessages(
              errors,
              logger,
            )}`,
            true,
          );
        } else {
          showSuccessNotification(`Updated user #${memberId}`, true);
        }
      }
    }
  }, [
    mutationResult,
    memberId,
    dispatch,
    showErrorNotification,
    showSuccessNotification,
  ]);

  const onUpdateDemographics = (demographics: Partial<DemographicsInput>) => {
    void updateDemographics({
      variables: { demographics, patientId: props.memberId },
    });
    dispatch(updateMemberProfileAction({ patientId: memberId }));
  };

  return useQuery<GetMember, GetMemberVariables>(
    (data) => (
      <SupervisorMemberProfile
        data-testid="memberProfile"
        onUpdateDemographics={onUpdateDemographics}
        member={data.getMember!}
        isSchedulingUIEnabled={isSchedulingUIEnabled}
      />
    ),
    getMemberQuery,
    {
      fetchPolicy: ApolloCachingStrategy.NETWORK_ONLY,
      variables: { id: props.memberId }, // due to an issue with typing of getMember and getPatient, we don't want to cache
    },
  );
}
