import { Tasks } from 'app/member-chart-cards/tasks/Tasks';
import useTasks from 'app/member-chart-cards/tasks/useTasks';
import { renderSliceStateResult } from 'app/state/status/RenderSliceState';
import React from 'react';

export function TasksCard({ memberId }: { memberId: string }) {
  const { tasks, updateTask, createTask, deleteTask, onCheckTask } = useTasks({
    memberId,
  });

  return renderSliceStateResult(tasks, (data) => {
    const { initialConsultItems, followupItems, todaysItems } = data!;
    return (
      <Tasks
        data-testid="tasksCard"
        followupItems={followupItems}
        initialConsultItems={initialConsultItems}
        createTask={createTask}
        updateTask={updateTask}
        deleteTask={deleteTask}
        memberId={memberId}
        todaysTasks={todaysItems}
        onCheck={onCheckTask}
      />
    );
  });
}
