import { validate } from '@ginger.io/react-use-form';
import { DeescalationNeed } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/DeescalationNeed';
import { DropInConsult } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/DropInConsult';
import { ExperiencingRisk } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/ExperiencingRisk';
import { ExploringTheApp } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/ExploringTheApp';
import { OtherNote } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/OtherNote';
import { SeekingClinical } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/drop-in-notes/SeekingClinical';
import { FollowUp } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/FollowUp';
import { InitialConsult } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/InitialConsult';
import { OutreachAttempt } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/OutreachAttempt';
import { QuickNote as QuickNoteVaultType } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/QuickNote';
import { RiskAssessment } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/RiskAssessment';
import {
  CoachNoteType,
  DropInNoteType,
  NoteItemWithRiskAssessments,
  NoteItemWithRisks,
} from 'app/coach/coach-notes/CoachNotesTypes';
import {
  deescalationNeed,
  dropInConsult,
  experiencingRiskFieldDefs,
  exploringTheAppFieldDefs,
  followup,
  initialConsult,
  otherNoteFieldDefs,
  outreachAttempt,
  quickNote,
  riskAssessments,
  riskAssessmentsFieldDefs,
  seekingClinicalFieldDefs,
} from 'app/coach/coach-notes/note-detail-view/note-templates/fieldDefs';
import { WritableNoteType } from 'app/coach/coach-notes/types';
import { isObjKey } from 'utils';

type FormValidator<T> = (data: T) => boolean;

export function validateRisks<
  T extends NoteItemWithRiskAssessments | NoteItemWithRisks
>(data: T): boolean {
  const risks = 'riskAssessments' in data ? data.riskAssessments : data.risks;

  // If no risks are defined, consider it valid
  if (risks == null) return true;

  const riskEntries = Object.entries(risks).filter(([_, value]) =>
    Boolean(value),
  );

  // 'Actions Taken' is linked to Risk Assessment entries. Avoid submitting
  // risk assessments if it only contains 'Actions Taken' without any risks
  if (riskEntries.length === 1 && risks.actionsTaken) return false;

  return riskEntries.every(
    ([key, risk]) =>
      isObjKey(key, riskAssessments) && validate(risk, riskAssessments[key]),
  );
}

export const noteTypeFormValidatorMap: Record<
  WritableNoteType,
  FormValidator<any>
> = {
  // For notes with nested risk assessments, validate the base note template and the nested risks
  [CoachNoteType.FOLLOW_UP]: (data: FollowUp) =>
    validate(data, followup) && validateRisks(data),
  [CoachNoteType.INITIAL_CONSULT]: (data: InitialConsult) =>
    validate(data, initialConsult) && validateRisks(data),
  [CoachNoteType.RISK]: (data: RiskAssessment) =>
    validate(data, riskAssessmentsFieldDefs) &&
    !!data.risks &&
    validateRisks(data),
  [DropInNoteType.DROP_IN_CONSULT]: (data: DropInConsult) =>
    validate(data, dropInConsult) && validateRisks(data),
  [DropInNoteType.DE_ESCALATION_NEED]: (data: DeescalationNeed) =>
    validate(data, deescalationNeed) && validateRisks(data),
  [DropInNoteType.EXPERIENCING_RISK]: (data: ExperiencingRisk) =>
    validate(data, experiencingRiskFieldDefs) && validateRisks(data),
  [DropInNoteType.EXPLORING_THE_APP]: (data: ExploringTheApp) =>
    validate(data, exploringTheAppFieldDefs) && validateRisks(data),
  [DropInNoteType.SEEKING_CLINICAL]: (data: SeekingClinical) =>
    validate(data, seekingClinicalFieldDefs) && validateRisks(data),
  [DropInNoteType.DROP_IN_OTHER]: (data: OtherNote) =>
    validate(data, otherNoteFieldDefs) && validateRisks(data),

  // For note types without risks, just validate the base note template
  [CoachNoteType.OUTREACH_ATTEMPT]: (data: OutreachAttempt) =>
    validate(data, outreachAttempt),
  [CoachNoteType.QUICK_NOTE]: (data: QuickNoteVaultType) =>
    validate(data, quickNote),
};
