import { Context } from 'app/state/context';
import { State } from 'app/state/schema';
import { createActionHandlers } from 'redux-reloaded';

import * as actions from './actions';

export const handlers = createActionHandlers<Context, State>();

handlers.on(actions.logErrorInSentry, async ({ action, context }) => {
  const { additionalData, error } = action.payload;
  await context.services.sentry.captureException(error, additionalData);
});
