import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import {
  AmplitudeContext,
  EnvironmentContext,
  ReactAmplitudeClient,
  StubAmplitudeClient,
} from '@ginger.io/core-ui';
import { UserDetails } from '@ginger.io/core-ui/dist/services/amplitude/config';
import { KeyGenerator } from '@ginger.io/vault-core/dist/crypto';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { AnyAction } from '@reduxjs/toolkit';
import { BaseQueryApi, fetchBaseQuery } from '@reduxjs/toolkit/query';
import { ClinicalAppointmentsImpl } from 'app/appointments/api/ClinicalAppointmentsImpl';
import {
  AppointmentsAndNotes,
  AppointmentsAndNotesAPIContextProps,
  AppointmentsAndNotesCursor,
} from 'app/appointments/AppointmentsAndNotesAPIContext';
import { defaultColumns } from 'app/appointments/table/constants';
import { appTheme } from 'app/AppTheme';
import { PubnubAPIService } from 'app/coach/pubnub/PubnubAPIService';
import { PubNubProvider } from 'app/coach/pubnub/PubNubContextProvider';
import { StubApolloClient } from 'app/services/apollo';
import { getStage, Stage, stage } from 'app/Stage';
import { AuthFormatter } from 'app/state/features/auth/AuthFormatter';
import { TimetokensFormatter } from 'app/state/features/conversationTimetokens/TimetokensFormatter';
import { SchedulerFormatter } from 'app/state/features/scheduler/SchedulerFormatter';
import { ILogger, LogLevel } from 'app/state/log/Logger';
import { LoggerImpl } from 'app/state/log/LoggerImpl';
import { StubLogger } from 'app/state/log/StubLogger';
import { LoggerContext } from 'app/state/log/useLogger';
import { State } from 'app/state/schema';
import { getAuthenticatedUserData } from 'app/state/syncAuthenticatedUserToRedux';
import { NonAppointmentNotesAPI } from 'app/vault/api/NonAppointmentNotesAPI';
import { PsychiatryIntakeNotesAPI } from 'app/vault/api/PsychiatryIntakeNotesAPI';
import { PsychiatryProgressNotesAPI } from 'app/vault/api/PsychiatryProgressNotesAPI';
import { TherapyIntakeNotesAPI } from 'app/vault/api/TherapyIntakeNotesAPI';
import { TherapyProgressNotesAPI } from 'app/vault/api/TherapyProgressNotesAPI';
import {
  ClinicalNotesAPIClients,
  ClinicalNotesAPIContext,
} from 'app/vault/hooks/useClinicalNotesAPI';
import {
  AppointmentType,
  ClinicalAppointmentStatus,
  ClinicalNoteStatus,
} from 'generated/globalTypes';
import { createBrowserHistory, History } from 'history';
import { FeatureFlags, FeatureFlagsContext } from 'hooks/useFeatureFlags';
import React, { ReactElement } from 'react';
import { Provider } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Store } from 'redux';
import { SortableColumn } from 'shared-components/table/SortableTableHead';

import { createAmplitudeClient } from './amplitude';
import { createApolloClient } from './apolloClient';
import { createOktaClient } from './okta';
import { StubOktaAuth } from './okta/StubOktaAuth';
import { createSentryClient } from './sentry';
import { SentryClient } from './sentry/SentryClient';
import { StubSentryClient } from './sentry/StubSentryClient';
import { BaseQueryWithExtraOptions, ServerType } from './server/types';

export interface ServicesProviderProps {
  stage: Stage;
  services: Services;
  reduxStore: Store<State, AnyAction>;
  featureFlags: FeatureFlags;
  children?: ReactElement | ReactElement[];
}

/** A single React component that sets up all the necessary xxxProvider classes to render the application
 *  both for tests or "for real"
 */
export function ServicesProvider(props: ServicesProviderProps) {
  const { services, featureFlags, children, reduxStore, stage } = props;
  const clinicalNoteAPI = createClinicalNotesAPIClients(
    services.apollo,
    services.logger,
  );
  const history = useHistory();
  const restoreOriginalUri = (
    _oktaAuth: OktaAuth,
    originalUri: string | undefined,
  ) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  return (
    <EnvironmentContext.Provider value={stage}>
      <AmplitudeContext.Provider value={services.amplitude}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={appTheme}>
            <LoggerContext.Provider value={services.logger}>
              <ApolloProvider client={services.apollo}>
                <ClinicalNotesAPIContext.Provider value={clinicalNoteAPI}>
                  <Provider store={reduxStore}>
                    <Security
                      oktaAuth={services.okta}
                      restoreOriginalUri={restoreOriginalUri}
                    >
                      <PubNubProvider pubnub={services.pubnub}>
                        <FeatureFlagsContext.Provider value={featureFlags}>
                          {children}
                        </FeatureFlagsContext.Provider>
                      </PubNubProvider>
                    </Security>
                  </Provider>
                </ClinicalNotesAPIContext.Provider>
              </ApolloProvider>
            </LoggerContext.Provider>
          </ThemeProvider>
        </StyledEngineProvider>
      </AmplitudeContext.Provider>
    </EnvironmentContext.Provider>
  );
}

export interface StubServices {
  okta: StubOktaAuth;
  amplitude: StubAmplitudeClient;
  logger: StubLogger;
  sentry: StubSentryClient;
  apollo: StubApolloClient;
  pubnub: PubnubAPIService; // replace with a stub implementation
}

export interface ImplServices {
  okta: OktaAuth;
  amplitude: ReactAmplitudeClient;
  logger: ILogger;
  sentry: SentryClient;
  pubnub: PubnubAPIService;
  apollo: ApolloClient<NormalizedCacheObject>;
}

export type Services = StubServices | ImplServices;

export interface Formatters {
  authFormatter: AuthFormatter;
  timetokensFormatter: TimetokensFormatter;
  schedulerFormatter: SchedulerFormatter;
}

type ApolloConfig = {
  endpoint: string;
};

const apolloconfig: { [T in Stage]: ApolloConfig } = {
  [Stage.PROD]: {
    endpoint: 'https://graphql.ginger.io',
  },

  [Stage.DEV]: {
    endpoint: 'https://graphql.ginger.dev',
  },

  [Stage.LOCAL]: {
    endpoint: 'https://graphql.ginger.dev',
  },

  [Stage.LOCAL_GRAPHQL]: {
    endpoint: 'http://localhost:4000',
  },
};

export function createServices(
  stage: Stage = getStage(),
  history: History,
  fetchAmplitudeUserFn?: () => Promise<UserDetails>,
): Services {
  const okta = createOktaClient(stage);
  const amplitude = createAmplitudeClient(
    stage,
    fetchAmplitudeUserFn || (() => fetchAmplitudeUser(apollo, okta)),
  );
  const sentry = createSentryClient(stage, okta, history);

  // We default to DEBUG level. Once flags have loaded, we set this to INFO instead if the flag for debug logging is
  // disabled. We're defaulting to DEBUG to go with the more conservative approach so that we don't miss debug logs
  // that we might want, while waiting on flags to load, to determine whether to continue using DEBUG level.
  const logger = new LoggerImpl(sentry, LogLevel.DEBUG);

  // TODO(Gaston): in a separate PR, pass logger instead of Sentry client here.
  const apollo = createApolloClient(
    okta,
    sentry,
    logger,
    apolloconfig[stage].endpoint,
  );

  return {
    okta,
    amplitude,
    logger,
    sentry,
    apollo,
    pubnub: new PubnubAPIService(logger),
  };
}

/**
 * Get the user identity to use for emitting Amplitude events.
 */
async function fetchAmplitudeUser(
  apollo: ApolloClient<NormalizedCacheObject>,
  okta: OktaAuth,
): Promise<UserDetails> {
  const userData = await getAuthenticatedUserData(apollo, 'cache-first');
  const id = userData.getWebAuthenticatedUser.userId;
  const { email } = await okta.getUser();
  return {
    id,
    email,
  };
}

function createClinicalNotesAPIClients(
  apollo: ApolloClient<NormalizedCacheObject>,
  logger: ILogger,
): ClinicalNotesAPIClients {
  const keyGenerator = new KeyGenerator();
  const therapyIntakeNotes = new TherapyIntakeNotesAPI(apollo, keyGenerator);
  const therapyProgressNotes = new TherapyProgressNotesAPI(
    apollo,
    keyGenerator,
  );
  const psychiatryIntakeNotes = new PsychiatryIntakeNotesAPI(
    apollo,
    keyGenerator,
  );
  const psychiatryProgressNotes = new PsychiatryProgressNotesAPI(
    apollo,
    keyGenerator,
  );
  const nonAppointmentNotesAPI = new NonAppointmentNotesAPI(
    apollo,
    keyGenerator,
  );
  const clinicalAppointmentsAPI = new ClinicalAppointmentsImpl({
    apolloClient: apollo,
    logger: logger,
  });
  return {
    therapyIntakeNotes,
    therapyProgressNotes,
    psychiatryIntakeNotes,
    psychiatryProgressNotes,
    nonAppointmentNotesAPI,
    clinicalAppointmentsAPI,
  };
}

export const createAppointmentsAndNotesAPI = (
  appointmentsAndNotes: AppointmentsAndNotes[] = [],
  setCursor: (cursor: AppointmentsAndNotesCursor) => void = () => {},
  activeFilters: {
    activeTypeFilters: AppointmentType[];
    activeAppStatusFilters: ClinicalAppointmentStatus[];
    activeNoteStatusFilters: ClinicalNoteStatus[];
  } = {
    activeAppStatusFilters: [],
    activeTypeFilters: [],
    activeNoteStatusFilters: [],
  },
): AppointmentsAndNotesAPIContextProps => {
  const setAppointmentsAndNotes = (
    newAppointmentsAndNotes: AppointmentsAndNotes[],
  ) => {
    appointmentsAndNotes = newAppointmentsAndNotes;
  };

  const cursor: AppointmentsAndNotesCursor = null;
  const columns = defaultColumns;
  const changeColumnSort = (column: SortableColumn) => {};

  const {
    activeAppStatusFilters,
    activeTypeFilters,
    activeNoteStatusFilters,
    appStatusFilters,
    noteStatusFilters,
    typeFilters,
    unreadFilter,
    toggleTypeFilters,
    toggleAppStatusFilters,
    toggleNoteStatusFilters,
  } = {
    activeAppStatusFilters: activeFilters.activeAppStatusFilters || [],
    activeTypeFilters: activeFilters.activeTypeFilters || [],
    activeNoteStatusFilters: activeFilters.activeNoteStatusFilters || [],
    appStatusFilters: [],
    noteStatusFilters: [],
    typeFilters: [],
    unreadFilter: [],
    toggleTypeFilters: () => {},
    toggleAppStatusFilters: () => {},
    toggleNoteStatusFilters: () => {},
  };

  return {
    noteDetails: {
      cursor,
      setCursor,
    },
    table: {
      appointmentsAndNotes,
      setAppointmentsAndNotes,
      columns,
      changeColumnSort,
      filters: {
        activeAppStatusFilters,
        activeTypeFilters,
        activeNoteStatusFilters,
        appStatusFilters,
        noteStatusFilters,
        typeFilters,
        unreadFilter,
        toggleTypeFilters,
        toggleAppStatusFilters,
        toggleNoteStatusFilters,
      },
    },
  };
};

export const createFormatters = (services: Services): Formatters => {
  return {
    authFormatter: new AuthFormatter(services.logger),
    timetokensFormatter: new TimetokensFormatter(services.logger),
    schedulerFormatter: new SchedulerFormatter(services.logger),
  };
};

export const history = createBrowserHistory();
export const services = createServices(stage, history);

export const formatters = createFormatters(services);

export const webApiBaseUrls: Record<Stage, string> = {
  [Stage.PROD]: 'https://data.ginger.io/',
  [Stage.DEV]: 'https://dev.ginger.io/',
  // can be removed once weremove apollo client, added for parity purposes
  [Stage.LOCAL_GRAPHQL]: 'http://localhost:8002/',
  [Stage.LOCAL]: 'https://dev.ginger.io/',
};

export const listenerApiBaseUrls: Record<Stage, string> = {
  [Stage.PROD]: 'https://www.listenernow.com/',
  [Stage.DEV]: 'https://dev.listenernow.com/',
  // can be removed once we remove apollo client, added for parity purposes
  [Stage.LOCAL_GRAPHQL]: 'http://localhost:8003/',
  [Stage.LOCAL]: 'https://dev.listenernow.com/',
};

export const prepareHeaders = (
  headers: Headers,
  api: Pick<
    BaseQueryApi,
    'type' | 'getState' | 'extra' | 'endpoint' | 'forced'
  >,
) => {
  const { okta } = (api.extra as { services: Services }).services;
  const token = okta.getIdToken();
  headers.set('Content-Type', 'application/json');
  headers.set('authorization', `Bearer ${token}`);
  return headers;
};

export const getBaseQuery = (server: ServerType): BaseQueryWithExtraOptions => {
  if (server === ServerType.Web) {
    return fetchBaseQuery({
      baseUrl: webApiBaseUrls[stage],
      prepareHeaders,
    });
  }
  return fetchBaseQuery({
    baseUrl: listenerApiBaseUrls[stage],
    prepareHeaders,
  });

  // to-do: uncomment once we hook up the real URLs
  /* throw new Error('Cannot build base query', { server, stage }) */
};
