import { BooleanOption } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/BooleanOption';
import { FollowUp } from '@ginger.io/vault-coach-notes/dist/generated/protobuf-schemas/vault-coach-notes/FollowUp';
import { CoachNotesItem } from 'app/coach/coach-notes/CoachNotesTypes';
import { CardTextAreaField } from 'app/coach/coach-notes/note-detail-view/note-fields';
import { followup } from 'app/coach/coach-notes/note-detail-view/note-templates/fieldDefs';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { RisksForm } from 'app/coach/coach-notes/note-detail-view/risk-templates/RisksForm';
import { FollowUpWithoutRisks } from 'app/coach/coach-notes/types';
import { useCoachNotesForm } from 'app/coach/coach-notes/useCoachNotesForm';
import { autoIncrementSessionCount } from 'app/coach/coach-notes/utils';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import { NumberField } from 'app/notes-ui/forms/fields';
import { BooleanRadioField } from 'app/notes-ui/forms/form-controls/BooleanRadioField';
import { useAppState } from 'app/state';
import React from 'react';

enum FieldLabels {
  TITLE = 'Title this session',
  SESSION_NUMBER = 'Session number *',
  WHATS_GOING_WELL = "What's going well?",
  CHALLENGES = 'Opportunities or obstacles?',
  NEXT_STEPS = "What's next?",
  NEEDS_PROGRESS_REVIEW = 'Is it time for a progress review? *',
  PROGRESS_REVIEW = 'Progress Review *',
}

export enum Placeholders {
  TITLE = 'Unique name...',
  WHATS_GOING_WELL = 'Strengths, positives, good news, goal updates...',
  CHALLENGES = 'Concerns, roadblocks, interventions, goal updates...',
  NEXT_STEPS = 'Homework, goal plan, future discussions, scheduling...',
  NEXT_STEPS_HELPER_TEXT = 'Remember to summarize your session to the member! :)',
  PROGRESS_REVIEW = 'Scaling questions, feedback on coaching, reflections...',
  PROGRESS_REVIEW_HELPER_TEXT = 'You should review member progress every 4 sessions',
}

const TOOLTIP_EVENT_LABEL = 'Coach Notes > Follow-up';

export const FollowUpNote = ({
  noteId,
  readOnly,
  latestSessionCount,
  onStateChange,
  'data-testid': testId = 'followUpNote',
}: {
  readOnly: boolean;
  latestSessionCount: number;
  noteId?: string;
  onStateChange: (noteDraft: CoachNotesItem) => void;
  'data-testid'?: string;
}): React.ReactElement | null => {
  const selectedNote = useAppState((_) => _.coachNotes.selectedNote);
  const initialValue = selectedNote?.data as FollowUp;
  const currentSessionCount = initialValue?.sessionCount;
  const incrementedSessionCount = autoIncrementSessionCount({
    currentSessionCount,
    latestSessionCount,
    noteId,
    readOnly,
  });
  const { fields, onChangeRisksForm, risks } = useCoachNotesForm<
    FollowUpWithoutRisks
  >({
    canContainNestedRisks: true,
    onStateChange,
    sessionCount: incrementedSessionCount,
    validationSchema: followup,
  });

  return (
    <div data-testid={testId} className={styles.cardSection}>
      <FieldLabel className={styles.label} labelText={FieldLabels.TITLE} />
      <CardTextAreaField
        name="coachNotesFollowUpTitle"
        field={fields.title}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.TITLE}`}
        tooltipText=""
        placeholder={Placeholders.TITLE}
        readOnly={readOnly}
        multiline={false}
      />

      {!readOnly && (
        <>
          <FieldLabel
            className={styles.label}
            labelText={FieldLabels.SESSION_NUMBER}
          />
          <NumberField
            testId="sessionNumberField"
            inputClass={styles.numberField}
            field={fields.sessionCount}
            disabled={readOnly}
            inputProps={{
              min: '0',
            }}
          />
        </>
      )}

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.WHATS_GOING_WELL}
      />
      <CardTextAreaField
        name="coachNotesFollowUpGoingWell"
        field={fields.goingWell}
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.WHATS_GOING_WELL}`}
        tooltipText=""
        placeholder={Placeholders.WHATS_GOING_WELL}
        readOnly={readOnly}
      />

      <FieldLabel className={styles.label} labelText={FieldLabels.CHALLENGES} />
      <CardTextAreaField
        field={fields.challenges}
        name="coachNotesFollowUpOpportunitiesOrObstacles"
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.CHALLENGES}`}
        tooltipText=""
        placeholder={Placeholders.CHALLENGES}
        readOnly={readOnly}
      />

      <FieldLabel className={styles.label} labelText={FieldLabels.NEXT_STEPS} />
      {!readOnly && (
        <div className={styles.helperText}>
          {Placeholders.NEXT_STEPS_HELPER_TEXT}
        </div>
      )}
      <CardTextAreaField
        field={fields.nextSteps}
        name="coachNotesFollowNextSteps"
        tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.NEXT_STEPS}`}
        tooltipText=""
        placeholder={Placeholders.NEXT_STEPS}
        readOnly={readOnly}
      />

      <FieldLabel
        className={styles.label}
        labelText={FieldLabels.NEEDS_PROGRESS_REVIEW}
      />
      {!readOnly && (
        <div className={styles.helperText}>
          {Placeholders.PROGRESS_REVIEW_HELPER_TEXT}
        </div>
      )}
      <BooleanRadioField
        disabled={readOnly}
        name="followUpProgressReview"
        value={fields.needsProgressReview.value}
        onChange={fields.needsProgressReview.setValue}
      />

      {fields.needsProgressReview.value === BooleanOption.yes && (
        <>
          <FieldLabel
            className={styles.label}
            labelText={FieldLabels.PROGRESS_REVIEW}
          />
          <CardTextAreaField
            name="coachNotesFollowUpProgressReview"
            field={fields.progressReview}
            tooltipEventLabel={`${TOOLTIP_EVENT_LABEL}: ${FieldLabels.PROGRESS_REVIEW}`}
            tooltipText=""
            placeholder={Placeholders.PROGRESS_REVIEW}
            readOnly={readOnly}
          />
        </>
      )}
      <RisksForm
        risks={risks}
        onChange={onChangeRisksForm}
        readOnly={readOnly}
        inline={true}
      />
    </div>
  );
};
