import { Metadata } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import {
  PsychiatryIntakeSection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { PsychiatryIntakeSectionEncoder } from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSectionEncoder';
import { Base64 } from '@ginger.io/vault-core/dist/crypto/Base64';
import {
  VaultItem,
  VaultItem_SchemaType,
} from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import { getClinicalCareTeamGroupId } from '@ginger.io/vault-core/dist/IdHelpers';
import { VaultItemPermissions, VaultSystemName } from '@ginger.io/vault-ui';
import { DeleteVaultItemsMutation } from '@ginger.io/vault-ui/src/generated/graphql';
import { AmendmentWithAuditLog } from 'app/notes-ui/shared/amendments/types';
import { decodePsychiatryIntakeNote } from 'app/vault/data/decodePsychiatryIntakeNote';
import { GetAppointmentById_getAppointmentById as Appointment } from 'app/vault/generated/GetAppointmentById';

import { ClinicalNotesAPI } from './ClinicalNotesAPI';
import { PsychiatryIntakeIds } from './PsychiatryIntakeIds';
import { deletionMutationErrorHandler } from './utils';

export type PsychiatryIntakeNote = {
  [T in PsychiatrySectionName]:
    | Extract<PsychiatryIntakeSection, { name: T }>['data']
    | null;
} & {
  metadata: Metadata;
  permissions: VaultItemPermissions | null;
  amendments: AmendmentWithAuditLog[];
  createdAt: string | null; // ISO date: reps when the note metadata was created
  updatedAt: string | null; // ISO date: reps when the note metadata was last modified e.g. when a note is signed & locked
};

export class PsychiatryIntakeNotesAPI extends ClinicalNotesAPI<
  PsychiatryIntakeNote,
  PsychiatryIntakeSection
> {
  protected ids = PsychiatryIntakeIds;

  protected sectionNames = PsychiatrySectionName;

  readonly amendmentSectionName = 'AMENDMENTS';

  async getNote(appointmentId: string): Promise<PsychiatryIntakeNote> {
    const groupId = await this.getClinicalCareTeamGroupId(appointmentId);
    const [items, amendments] = await Promise.all([
      this.vaultAPI.getVaultItemsByTag(
        PsychiatryIntakeIds.noteSectionsTag(appointmentId),
        {
          groupId,
        },
      ),
      this.getAmendments(appointmentId),
    ]);

    return decodePsychiatryIntakeNote(appointmentId, items, amendments);
  }

  protected encodeSection(data: PsychiatryIntakeSection): VaultItem {
    return PsychiatryIntakeSectionEncoder.encode(data);
  }

  protected encodeMetadata(metadata: Metadata): VaultItem {
    return {
      data: Metadata.encode(metadata).finish(),
      schemaType:
        VaultItem_SchemaType.vault_clinical_notes_psychiatry_intake_metadata,
    };
  }

  async deleteDraftNote(
    userId: string,
    appointment: Appointment,
    note: PsychiatryIntakeNote,
  ): Promise<DeleteVaultItemsMutation> {
    const groupId = await Base64.hash(
      getClinicalCareTeamGroupId(appointment.member.id),
    );
    const vaultItemIds = Object.values(PsychiatrySectionName)
      .filter((_) => note[_] !== null)
      .map((name) => PsychiatryIntakeIds.section(appointment.id, name));
    if (note.metadata) {
      vaultItemIds.push(PsychiatryIntakeIds.metadata(appointment.id));
    }
    const deletedVaultItems = await this.vaultAPI.deleteVaultItems({
      groupId,
      itemIds: vaultItemIds,
      shareWithSystems: [VaultSystemName.ClinicalNotesSyncProcess],
    });
    deletionMutationErrorHandler(deletedVaultItems); // throws on error
    await this.updateAppointmentCache(appointment.id);
    return deletedVaultItems;
  }
}
