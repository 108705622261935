import { useState } from 'react';

import { SortableColumn } from '../shared-components/table/SortableTableHead';

/**
 * @param {SortableColumn[]} columns Initial state of the columns
 * @description Returns an array of sortable columns and a callback to change a column sort order
 * */
export const useSortableColumns = (
  columns: SortableColumn[],
): [SortableColumn[], (column: SortableColumn) => void] => {
  const [sortableColumns, setSortableColumns] = useState(columns);

  const onColumnSort = (sortColumn: SortableColumn) => {
    const newSortableColumns = sortableColumns.map((column) => {
      if (column.name === sortColumn.name) {
        return {
          ...column,
          active: true,
          // toggle the direction on each click
          direction: column.direction === 'asc' ? 'desc' : 'asc',
        };
      }
      return {
        ...column,
        active: false,
      };
    });

    setSortableColumns(newSortableColumns as SortableColumn[]);
  };

  return [sortableColumns, onColumnSort];
};
