import { UserDetails } from '@ginger.io/core-ui/dist/services/amplitude/config';
import { AmplitudeClientImpl } from 'app/services/amplitude/AmplitudeClientImpl';
import { StubAmplitudeClient } from 'app/services/amplitude/StubAmplitudeClient';
import { Stage } from 'app/Stage';

import { ReactAmplitudeClient } from './ReactAmplitudeClient';

export type Config = {
  apiKey: string;
};
const config: { [T in Stage]: Config } = {
  [Stage.PROD]: {
    apiKey: '14bfbe9880b485dbe8e27592d7cbfa9a',
  },

  [Stage.DEV]: {
    apiKey: '7c81dadff8720f1cb93ed7c8e7af3a0c',
  },

  [Stage.LOCAL]: {
    apiKey: '',
  },

  [Stage.LOCAL_GRAPHQL]: {
    apiKey: '',
  },
};

export function createAmplitudeClient(
  stage: Stage,
  fetchUser: () => Promise<UserDetails>,
): ReactAmplitudeClient {
  const stubAmplitudeClient = new StubAmplitudeClient();
  const realAmplitudeClient = new AmplitudeClientImpl({
    apiKey: config[stage].apiKey,
    appName: 'Care Hub',
    fetchUser: () => fetchUser(),
  });
  switch (stage) {
    case Stage.DEV:
      return realAmplitudeClient;
    case Stage.PROD:
      return realAmplitudeClient;
    case Stage.LOCAL:
      return stubAmplitudeClient;
    case Stage.LOCAL_GRAPHQL:
      return stubAmplitudeClient;
    default:
      return stubAmplitudeClient;
  }
}
