import HomePageIcon from 'assets/inbox/landing-screen-icon.png';
import React from 'react';

import styles from './CareHubLandingScreen.module.scss';

export function CareHubLandingScreen() {
  return (
    <div className={styles.noSelectedMember}>
      <img src={HomePageIcon} alt="lang screen icon" />
      <p className={styles.text}>Take a moment to breathe.</p>
    </div>
  );
}
