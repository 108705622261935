import { SvgIcon } from '@mui/material';
import { ReactComponent as CheckedCheckboxSVG } from 'assets/checked-checkbox-icon.svg';
import React from 'react';

export const CheckedCheckboxIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <CheckedCheckboxSVG />
    </SvgIcon>
  );
};
