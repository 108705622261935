import { ILogger } from 'app/state/log/Logger';
import { createContext, useContext } from 'react';

export const LoggerContext = createContext<ILogger | null>(null);

export function useLogger(): ILogger {
  const logger = useContext(LoggerContext);
  if (logger) {
    return logger;
  }
  throw new Error(
    `No Logger context found in your app's context. Did you forget to wrap your root component with Logger.Provider?`,
  );
}
