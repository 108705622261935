import { useRef, useState } from 'react';

export enum Status {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  DATA = 'DATA',
}

export type LoadingState = { status: Status.LOADING; data: null };
export type ErrorState = { status: Status.ERROR; error: Error; data: null };
export type DataState<T> = {
  status: Status.DATA;
  data: T;
};

export type HookState<T> = LoadingState | ErrorState | DataState<T>;

export function loadingState(): LoadingState {
  return { data: null, status: Status.LOADING };
}

export function errorState(error: Error): ErrorState {
  return { data: null, error, status: Status.ERROR };
}

export function dataState<T>(data: T): DataState<T> {
  return { data, status: Status.DATA };
}

export type ReadOnlyRef<T> = { readonly current: T };

export type StateController<T = null> = {
  state: ReadOnlyRef<HookState<T>>;
  setLoading: () => void;
  setError: (e: Error) => void;
  setData: (item: T) => void;
};

export function useHookState<T = null>(
  initialState: HookState<T> = loadingState(),
): StateController<T> {
  const state = useRef<HookState<T>>(initialState);
  const [, forceRender] = useState(false);

  const setState = (updated: HookState<T>) => {
    state.current = updated;
    forceRender((bool) => !bool); // flipping the flag forces a re-render
  };

  return {
    setData: (item: T) => setState(dataState(item)),
    setError: (e: Error) => setState(errorState(e)),
    setLoading: () => setState(loadingState()),
    state,
  };
}
