import { useDebounce } from 'hooks/useDebounce';
import { useOnMount } from 'hooks/useOnMount';
import React from 'react';

export function useViewPortSize() {
  const handleResize = useDebounce(() => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  });
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  useOnMount(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return dimensions;
}
