import {
  ClinicalAppointmentNoteType,
  NoteType,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';

/** When Apollo code-generates TypeScript types for graphQL queries,
 *  it includes a __typename field. This field is "real" - i.e. present in the graphQL response
 *  And is necessary so that you can discriminate between union types.
 *
 *  However when writing React components that take GraphQL data as Props,
 *  we don't want to have to deal with __typename.
 *
 *  Thus this helper exists to strip the __typename field from the TS type, e.g.
 *
 *  function MyComponent(props: GraphQLProps<SomeGeneratedQueryFragment>) {
 *   ...
 *  }
 *
 *
 */
export type GraphQLProps<T> = Omit<T, '__typename'>;

export const clinicalAppointNoteTypeToNoteType = (
  clinicalAppointNoteType: ClinicalAppointmentNoteType | null,
): NoteType => {
  switch (clinicalAppointNoteType) {
    case ClinicalAppointmentNoteType.PSYCHIATRY_INTAKE:
      return NoteType.PSYCHIATRY_INTAKE;
    case ClinicalAppointmentNoteType.PSYCHIATRY_PROGRESS:
      return NoteType.PSYCHIATRY_PROGRESS;
    case ClinicalAppointmentNoteType.THERAPY_INTAKE:
      return NoteType.THERAPY_INTAKE;
    case ClinicalAppointmentNoteType.THERAPY_PROGRESS:
      return NoteType.THERAPY_PROGRESS;
    case ClinicalAppointmentNoteType.UNSPECIFIED:
      return NoteType.UNSPECIFIED;
  }

  return NoteType.UNSPECIFIED;
};

export type AppointmentIdWithNoteType = {
  id: string;
  noteType: NoteType;
} | null;
