import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Button } from 'shared-components/button/Button';
import React, { ReactNode, SyntheticEvent } from 'react';

import styles from './Modal.module.scss';

type Props = {
  buttonLabel?: string;
  hideButton?: boolean;
  children: ReactNode;
  onButtonClick?: () => void;
  open?: boolean;
  'data-testid'?: string;
};

export function Modal(props: Props) {
  const {
    onButtonClick = () => {},
    children,
    buttonLabel = 'Close',
    hideButton = false,
    'data-testid': testId,
  } = props;

  // calls stopPropagation to prevent event propagation to parent components, as Modal is rendered as a child
  // component. It prevents onClick from being called on parent components, causing unexpected behavior.
  // Modal must be the only component that can be clicked on when visible.
  const preventEventPropagation = (e: SyntheticEvent): void => {
    e.stopPropagation();
  };

  const content = (
    <div
      data-testid={testId}
      role="presentation"
      onClick={preventEventPropagation}
      className={styles.contentWrapper}
    >
      {children}
      {!hideButton && (
        <div className={styles.buttonWrapper}>
          <Button onClick={onButtonClick}>{buttonLabel}</Button>
        </div>
      )}
    </div>
  );
  const { open = true } = props;

  // The material UI dialog requires a real DOM and doesn't work with react-test-renderer ,
  // so we avoid rendering it in unit tests. This can be removed once we migrate tests over to
  // react-testing-library as it uses JSDom
  if (process.env.NODE_ENV === 'test' && open) {
    return content;
  }
  return (
    // calls the same function as the Button's onClick handler when Dialog was closed
    <Dialog
      disableEscapeKeyDown={!hideButton && onButtonClick != null}
      open={open}
      onClose={() => onButtonClick()}
    >
      <DialogContent>{content}</DialogContent>
    </Dialog>
  );
}
