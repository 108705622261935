import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import FormLabel from '@mui/material/FormLabel';
import { FormError } from 'shared-components/FormError';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';
import React from 'react';

import styles from './BooleanRadioField.module.scss';
import { NoteTooltip } from './NoteTooltip';
import RadioButton from './RadioButton';

export type Props = {
  name: string;
  label?: string;
  value: BooleanOption;
  error?: string;
  disabled?: boolean;
  onChange: (value: BooleanOption) => void;
  tooltip?: string;
  horizontalOriented?: boolean;
  excludeNotApplicable?: boolean;
};

export function BooleanRadioField(props: Props) {
  const {
    name,
    value,
    label,
    error,
    disabled,
    tooltip,
    horizontalOriented = false,
    excludeNotApplicable = false,
  } = props;

  const onChange = (value: BooleanOption) => {
    props.onChange(value);
  };

  const width = horizontalOriented
    ? [COLUMN_WIDTH.FIVE, COLUMN_WIDTH.SEVEN]
    : [COLUMN_WIDTH.FULL];

  const displayRadioButtons = () => {
    return (
      <div data-testid={name}>
        <RadioButton
          name={name}
          label="Yes"
          value={BooleanOption.yes}
          checked={value === BooleanOption.yes}
          onChange={onChange}
          disabled={disabled}
        />

        <RadioButton
          name={name}
          label="No"
          value={BooleanOption.no}
          checked={value === BooleanOption.no}
          onChange={onChange}
          disabled={disabled}
        />
        {!excludeNotApplicable && (
          <RadioButton
            name={name}
            label="N/A"
            value={BooleanOption.not_applicable}
            checked={value === BooleanOption.not_applicable}
            onChange={onChange}
            disabled={disabled}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <Grid spacing={0} className={styles.root}>
        <Columns widths={width}>
          <div className={styles.label}>
            <FormLabel aria-label={label}>
              <span data-testid="questionLabel">{label}</span>
              {tooltip && <NoteTooltip tooltip={tooltip} />}
            </FormLabel>
          </div>
          {horizontalOriented && displayRadioButtons()}
        </Columns>
        {!horizontalOriented && (
          <Columns widths={[COLUMN_WIDTH.FULL]}>
            {displayRadioButtons()}
          </Columns>
        )}
      </Grid>
      {error ? <FormError error={error} /> : null}
    </div>
  );
}
