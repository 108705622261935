import { RPCEnvelope, TextMessageEnvelope } from 'app/coach/pubnub/types';
import {
  doesObjectHaveProps,
  isRPC,
  isTextMessage,
} from 'app/coach/pubnub/utils';

import { handleRPC, handleTextMessage } from '../actions';
import {
  redactSensitiveInfoFromPubhubData,
  unrecognizedMessageError,
} from '../utils';
import { ActionHandler } from './types';

export const onNewMessage = async ({
  action,
  redux,
  context: { services },
}: ActionHandler<{ envelope: TextMessageEnvelope | RPCEnvelope }>) => {
  const {
    payload: { envelope },
  } = action;

  const { hasProps, error } = doesObjectHaveProps(['channel'], envelope);
  if (!hasProps) {
    services.logger.error(error, {
      envelope: redactSensitiveInfoFromPubhubData(envelope),
    });
    return;
  }
  // check if it's a text message
  if (isTextMessage(envelope)) {
    redux.dispatch(handleTextMessage({ envelope }));
  }
  // check if it's an rpc
  else if (isRPC(envelope)) {
    redux.dispatch(handleRPC({ envelope }));
  }
  // log an object that could not be handled neither as an rpc nor as a text message
  else {
    const err = new Error(unrecognizedMessageError);
    services.logger.error(err, {
      envelope: redactSensitiveInfoFromPubhubData(envelope),
    });
  }
};
