import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { BenefitsCardFragment } from '@headspace/carehub-graphql/dist/member-chart-cards/benefits-card/generated/BenefitsCardFragment';
import { ContactInfoCardFragment } from '@headspace/carehub-graphql/dist/member-chart-cards/contact-info/generated/ContactInfoCardFragment';
import { MemberProfileFragment } from '@headspace/carehub-graphql/dist/member-chart-cards/member-profile/generated/MemberProfileFragment';
import { IntakeTabFragment } from '@headspace/carehub-graphql/dist/patients/tabs/intake/generated/IntakeTabFragment';
import { MemberChartTab } from 'app/coach/member-chart/MemberChartTab';
import { BenefitsCard } from 'app/member-chart-cards/benefits-card/BenefitsCard';
import { ContactInfoCard } from 'app/member-chart-cards/contact-info/ContactInfoCard';
import { BackgroundCard } from 'app/member-chart-cards/member-background/BackgroundCard';
import { MemberProfile } from 'app/member-chart-cards/member-profile/MemberProfile';
import { PrescriptionsCard } from 'app/member-chart-cards/prescriptions/PrescriptionsCard';
import { SchedulerCard } from 'app/member-chart-cards/scheduler/SchedulerCard';
import { TasksCard } from 'app/member-chart-cards/tasks/TasksCard';
import { shouldShowPrescriptionsCard } from 'app/patients/tabs/summary/utils';
import { useAppState } from 'app/state';
import { viewSummaryTabAction } from 'app/state/amplitude/actions/etc';
import { GraphQLProps } from 'app/typeUtils';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isTeen } from 'utils';

import styles from './SummaryTab.module.scss';

export interface SummaryTabData
  extends GraphQLProps<MemberProfileFragment>,
    GraphQLProps<ContactInfoCardFragment>,
    GraphQLProps<BenefitsCardFragment>,
    GraphQLProps<Pick<IntakeTabFragment, 'intakeCompletedAt'>> {
  isSupervisorAndMSUI?: boolean;
  dosespotId?: string | null;
  'data-testid'?: string;
}

export function SummaryTabV2(props: SummaryTabData) {
  const {
    id,
    preferredFirstName,
    preferredLastName,
    preferences,
    timezone,
    gender,
    genderIdentification,
    ethnicities,
    pronouns,
    dateOfBirth,
    firstName,
    lastName,
    email,
    address,
    phone,
    emergencyContact,
    insurance,
    coverageDetails,
    guardianRelationship,
    dosespotId,
    intakeCompletedAt,
    isSupervisorAndMSUI = false,
    'data-testid': testId,
  } = props;

  const {
    enable_scheduler_v2: enableSchedulerV2,
  } = useFeatureFlags().transientFeatureFlags;

  const _isTeen = isTeen(dateOfBirth);

  const role = useAppState(({ user }) => user.role);

  const showPrescriptionsCard = shouldShowPrescriptionsCard(role, dosespotId);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewSummaryTabAction());
  }, [dispatch]);

  return (
    <div data-testid={testId} className={styles.tabContent}>
      <MemberChartTab>
        <ContactInfoCard
          member={{
            address,
            email,
            emergencyContact,
            firstName,
            lastName,
            phone,
          }}
        />
        {!isSupervisorAndMSUI && <TasksCard memberId={id} />}
        {enableSchedulerV2 &&
        role === (UserRole.COACH || UserRole.COACH_SUPERVISOR) ? (
          <SchedulerCard memberId={id} memberTimezone={preferences?.timezone} />
        ) : null}
        <MemberProfile
          member={{
            dateOfBirth,
            ethnicities,
            gender,
            genderIdentification,
            guardianRelationship,
            id,
            preferences,
            preferredFirstName,
            preferredLastName,
            pronouns,
            timezone,
          }}
        />
        <BenefitsCard member={{ coverageDetails, insurance }} />
        {showPrescriptionsCard && <PrescriptionsCard memberId={id} />}
        <BackgroundCard
          memberId={id}
          isTeen={_isTeen}
          intakeCompleted={!!intakeCompletedAt}
        />
      </MemberChartTab>
    </div>
  );
}
