import { useForm } from '@ginger.io/react-use-form';
import {
  Assessment,
  Assessment_CPTCode as CPTCode,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/intake/Assessment';
import {
  IntakeAssessmentSection as AssessmentSection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { TextAreaField } from 'app/notes-ui/forms/fields';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { CodeSetFields } from 'app/notes-ui/shared/code-set/CodeSetFields';
import {
  AUTO_SAVE_DRAFT_DELAY,
  codeSetsFromAssessment,
} from 'app/notes-ui/utils';
import { acceptedPsychiatryCptCodes, filterCptCodes } from 'utils/notes';
import React from 'react';

import { fieldDefs } from './schema';

type Props = {
  appointmentId: string;
  onSubmit: (data: AssessmentSection) => void;
  updateDraftNoteState: (data: AssessmentSection) => void;
  disabled?: boolean;
  savingNote?: boolean;
  initialValue?: Assessment;
};

export function AssessmentPlanForm(props: Props) {
  const { savingNote, disabled, initialValue, appointmentId } = props;
  const { fields, validate, getValue } = useForm<Assessment>(
    fieldDefs,
    {
      ...initialValue,

      appointmentId,

      codeSets: codeSetsFromAssessment(initialValue),
      // pre-select the only CPT code for psych intake
      cptCode:
        CPTCode.cpt_90792_diagnostic_evaluation_with_medical_services_45_min,
    } as Assessment,
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data: Assessment.fromPartial(data),
          name: PsychiatrySectionName.ASSESSMENT,
        }),
    },
  );
  const onSubmit = async () => {
    const isValid = await validate();
    if (isValid) {
      props.onSubmit({
        data: getValue(),
        name: PsychiatrySectionName.ASSESSMENT,
      });
    }
  };

  return (
    <>
      <CodeSetFields
        disabled={disabled}
        cptCodeEnum={filterCptCodes(CPTCode, acceptedPsychiatryCptCodes)}
        field={fields.codeSets}
      />
      <TextAreaField
        disabled={disabled}
        rows={10}
        testId="formulationAndMedicalDecisionMaking"
        label="Formulation and Medical Decision Making*"
        field={fields.formulationAndMedicalDecisionMaking}
      />

      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </>
  );
}
