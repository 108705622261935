import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import moment from 'moment';
import React from 'react';

import styles from '../SchedulerCard.module.scss';
import { DisplayTimes } from '../types';

export const DisplayTime = ({
  displayTimes,
  sessionStartTime,
  coachTimezone,
  memberTimezone,
}: {
  displayTimes: DisplayTimes;
  sessionStartTime?: string | null;
  coachTimezone: string;
  memberTimezone: string;
}) => (
  <div className={styles.displayTimeContainer}>
    <FieldLabel className={styles.label} labelText="Time" />
    <span>
      {`${displayTimes.displayCoachStartTime} ${moment(sessionStartTime)
        .tz(coachTimezone)
        .format('z')} | ${displayTimes.displayMemberStartTime} ${moment(
        sessionStartTime,
      )
        .tz(memberTimezone)
        .format('z')}`}
    </span>
  </div>
);
