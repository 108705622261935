import { ClinicalDocument_ClinicalDocumentType as ClinicalDocumentType } from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/ClinicalDocument';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Grid, IconButton, MenuItem, TextField } from '@mui/material';
import Tooltip from 'shared-components/tooltip/Tooltip';
import {
  InputFile,
  UploadFileTypeMap,
} from 'app/patients/tabs/document-upload/types';
import { formatFileSize } from 'utils';
import React, { ReactNode } from 'react';

import styles from './UploadModalRow.module.scss';

export default function UploadModalRow(props: {
  file: InputFile;
  onDeleteClick: () => void;
  updateFileProps: (newFile: InputFile) => void;
  allowDelete?: boolean;
  'data-testid'?: string;
}) {
  const { file, onDeleteClick, updateFileProps, allowDelete = true } = props;
  const onFileTypeChange = (event: React.ChangeEvent<{ value: string }>) => {
    const fileType = parseInt(event.target.value) as ClinicalDocumentType;
    const newFile: InputFile = { ...file, fileType };
    updateFileProps(newFile);
  };
  const onNameChange = (event: React.ChangeEvent<{ value: string }>) => {
    const name = event.target.value;
    const newFile: InputFile = { ...file, name };
    updateFileProps(newFile);
  };
  const docTypeOptions: ReactNode[] = [];

  UploadFileTypeMap.forEach((value, key) =>
    docTypeOptions.push(
      <MenuItem key={key} value={key}>
        {value}
      </MenuItem>,
    ),
  );
  return (
    <div data-testid={props['data-testid']} className={styles.uploadRow}>
      <Grid container={true} justifyContent="space-between" spacing={2}>
        <Grid data-testid="name-column" item={true} xs={6}>
          <TextField
            className={styles.textField}
            variant="outlined"
            value={file.name}
            fullWidth={true}
            onChange={onNameChange}
          />
        </Grid>
        <Grid data-testid="doc-type-column" item={true} xs={3}>
          <TextField
            select={true}
            SelectProps={{
              classes: {
                select: [styles.selectRoot, styles.selectField].join(' '),
              },
            }}
            variant="outlined"
            value={file.fileType}
            fullWidth={true}
            onChange={onFileTypeChange}
          >
            {docTypeOptions}
          </TextField>
        </Grid>
        <Grid item={true} xs={2}>
          <span className={styles.sizeText}>{formatFileSize(file.size)}</span>
        </Grid>
        <Grid item={true} xs={1}>
          {allowDelete ? (
            <Tooltip title="Remove">
              <IconButton onClick={onDeleteClick} size="large">
                <HighlightOffIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}
