export const Labels = {
  additionalComments: {
    id: 'additionalComments',
    label: 'Additional comments on current treatment',
    placeholder: 'Psychiatry, groups, other therapists...',
  },
  currentTreatment: {
    id: 'currentTreatment',
    label:
      'Current mental health treatment in addition to what the clinician is providing?',
    tooltip:
      'It is best practice for members to see one therapist at a time, unless there are clear boundaries, such as individual therapy vs couples counseling. Obtain consultation if you are unsure about the member’s other mental health treatments.',
  },
  impactOfSymptoms: {
    id: 'impactOfSymptoms',
    label: 'How is this impacting the member?',
  },
  medicalConcerns: {
    id: 'medicalConcerns',
    label: 'Medical concerns and medications',
    placeholder:
      "Current and/or historical medical concerns, medications and dosages, per member's report...",
  },
  pastTreatment: {
    id: 'pastTreatment',
    label: 'Past mental health treatment (including hospitalizations)',
    placeholder: 'Type here...',
  },
  presentingProblem: {
    description:
      'History, symptoms reported including FIDO (frequency, intensity, duration, onset), survey scores, ROI Information',
    id: 'presentingProblem',
    label: 'Presenting problem',
    placeholder: 'Type here...',
  },
};
