import { DateMessage as ProtoBufDate } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/Date';
import {
  Frequency,
  Frequency_Period,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/Frequency';
import {
  SubstanceUse_CurrentSubstanceUse,
  SubstanceUse_SubstanceUseLineItem,
  SubstanceUse_SubstanceUseLineItem as SubstanceUseLineItem,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import { labelFromEnumValue, protobufDateToString } from 'utils/notes';

export function lineItemToData(name: string, lineItem: SubstanceUseLineItem) {
  const {
    substanceType,
    frequency,
    otherSubstanceTypeDescription,
    startedUse,
    stoppedUse,
  } = lineItem;
  const { timesPerPeriod, period: frequencyPeriod } =
    frequency || ({} as Frequency);
  return {
    frequencyPeriod,
    name,
    otherSubstanceTypeDescription,
    startedUse: startedUse ? protobufDateToString(startedUse) : undefined,
    stoppedUse: stoppedUse ? protobufDateToString(stoppedUse) : undefined,
    substanceType,
    timesPerPeriod,
  };
}

function isDateEmpty(date: ProtoBufDate | undefined): boolean {
  return (
    date === undefined ||
    (date.day === undefined &&
      date.month === undefined &&
      date.year === undefined)
  );
}

function isFrequencyEmpty(date: Frequency | undefined): boolean {
  return (
    date === undefined ||
    (date.period === undefined && date.timesPerPeriod === undefined)
  );
}

export function getSubstanceUseDescription(
  substance: SubstanceUse_SubstanceUseLineItem | undefined,
) {
  const startedUse = substance?.startedUse;
  const stoppedUse = substance?.stoppedUse;
  const frequency = substance?.frequency;

  if (
    isDateEmpty(startedUse) &&
    isDateEmpty(stoppedUse) &&
    isFrequencyEmpty(frequency)
  ) {
    return '';
  }

  const amount = frequency?.timesPerPeriod;
  const period = labelFromEnumValue(Frequency_Period, frequency?.period);
  const startDate = startedUse ? protobufDateToString(startedUse) : null;
  const endDate = stoppedUse ? protobufDateToString(stoppedUse) : null;
  const frequencyStr =
    amount && period ? `${amount} every ${period?.toLowerCase()}` : '';

  let useRangeStr = '';

  if (startDate) {
    useRangeStr = endDate
      ? `from ${startDate} until ${endDate}`
      : `since ${startDate}`;
  }

  return `${frequencyStr} ${useRangeStr}`;
}

export function remapSubstanceUse(
  substancesCurrentlyUsed: SubstanceUse_CurrentSubstanceUse[],
) {
  return substancesCurrentlyUsed.map(({ substance }) => ({
    substance: SubstanceUse_SubstanceUseLineItem.fromPartial({
      ...substance,
      description: substance?.description
        ? substance.description
        : getSubstanceUseDescription(substance),
    }),
  }));
}
