import React from 'react';

import styles from './EmptyTableView.module.scss';
import { ReactComponent as Icon } from './file-plus-icon.svg';

export function EmptyTableView({ onClick }: { onClick?: () => void }) {
  return (
    <div className={styles.root}>
      <div
        tabIndex={0}
        role="button"
        onKeyDown={onClick}
        onClick={onClick}
        className={styles.content}
      >
        <Icon />
        <span>Select files to upload</span>
      </div>
    </div>
  );
}
