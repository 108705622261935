import React from 'react';
import { Button } from 'shared-components/button/Button';
import { classNameCombiner } from 'utils';

import styles from './EscalateTeenMemberToClinicalModalButton.module.scss';

export interface EscalateTeenMemberToClinicalModalButtonProps {
  children: any;
  primary?: boolean;
  onClick: () => void;
  disabled?: boolean;
  testId?: string;
}

export const EscalateTeenMemberToClinicalModalButton = ({
  children,
  onClick,
  testId,
  disabled,
  primary = false,
}: EscalateTeenMemberToClinicalModalButtonProps) => {
  return (
    <Button
      testId={testId}
      disabled={disabled}
      className={classNameCombiner([
        styles.escalateTeenMemberToClinicalModalButton,
        !primary ? styles.light : '',
      ])}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
