import { ClinicianIntakeChecklist } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/ClinicianIntakeChecklist';
import {
  TherapyIntakeSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import {
  ClinicalNote,
  ClinicalNotesScreen,
  Section,
} from 'app/notes-ui/ClinicalNotesScreen';
import {
  ClinicianChecklistForm,
  validate as validateClinicianChecklist,
} from 'app/notes-ui/shared/clinician-checklist/ClinicianChecklistForm';
import { CollaborationPlanForm } from 'app/notes-ui/shared/collaboration-plan/CollaborationPlanForm';
import { validate as validateCollaboration } from 'app/notes-ui/shared/collaboration-plan/schema';
import {
  SafetyForm,
  validate as validateSafety,
} from 'app/notes-ui/shared/safety-intake/SafetyForm';
import { validate as validateSubstanceUsed } from 'app/notes-ui/shared/substance-abuse/schema';
import { SubstanceAbuseForm } from 'app/notes-ui/shared/substance-abuse/SubstanceAbuseForm';
import { BehavioralObservationsForm } from 'app/notes-ui/therapy/shared/behavioral-observations/BehavioralObservationsForm';
import { validate as validateBehavioralObservation } from 'app/notes-ui/therapy/shared/behavioral-observations/schema';
import { getTherapyIntakeSectionLabel } from 'app/notes-ui/utils';
import { Status } from 'app/state/status/types/StateSlice';
import { TherapyIntakeIds } from 'app/vault/api/TherapyIntakeIds';
import { TherapyIntakeNote } from 'app/vault/api/TherapyIntakeNotesAPI';
import React, { ElementType } from 'react';

import { AssessmentForm } from './assessment/AssessmentForm';
import { validate as validateAssessment } from './assessment/schema';
import {
  CurrentFunctioningForm,
  validate as validateCurrentFunctioning,
} from './current-funtioning/CurrentFunctioningForm';
import {
  MedicalHealthForm,
  validate as validateMedicalHealth,
} from './medical-health/MedicalHealthForm';
import {
  PresentingProblemForm,
  validate as validatePresentingProblem,
} from './presenting-problem/PresentingProblemForm';
import {
  SocialDevelopmentForm,
  validate as validateSocialDevelopment,
} from './social-development/SocialDevelopmentForm';
import {
  TreatmentPlanForm,
  validate as validateTreatmentPlan,
} from './treatment-plan/TreatmentPlanForm';

export type Props = {
  appointmentId: string;
  memberId?: string;
  status: Status;
  note: TherapyIntakeNote;
  draftNote: TherapyIntakeNote;
  onSubmit: (section: TherapyIntakeSection) => Promise<void>;
  updateDraftNoteState: (section: TherapyIntakeSection) => void;
  readOnly: boolean;
};

function section<T extends TherapyIntakeSection>(
  name: T['name'],
  form: ElementType<{
    appointmentId: string;
    initialValue?: T['data'];
    noteLastUpdatedAt?: string;
    updateDraftNoteState: (section: T) => void;
    onSubmit: (section: T) => void;
  }>,
  validator: (data: any) => boolean,
): Section<T> {
  return [name, getTherapyIntakeSectionLabel(name), form, validator];
}

const sections = [
  section(
    TherapyIntakeSectionName.CLINICIAN_CHECKLIST,
    ({ initialValue, ...props }) => (
      <ClinicianChecklistForm
        initialValue={initialValue as ClinicianIntakeChecklist}
        templateType="Therapy"
        {...props}
      />
    ),
    validateClinicianChecklist,
  ),
  section(
    TherapyIntakeSectionName.PRESENTING_PROBLEM,
    PresentingProblemForm,
    validatePresentingProblem,
  ),
  section(
    TherapyIntakeSectionName.CURRENT_FUNCTIONING,
    CurrentFunctioningForm,
    validateCurrentFunctioning,
  ),
  section(
    TherapyIntakeSectionName.SOCIAL_DEVELOPMENTAL,
    SocialDevelopmentForm,
    validateSocialDevelopment,
  ),
  section(
    TherapyIntakeSectionName.MEDICAL_HEALTH,
    MedicalHealthForm,
    validateMedicalHealth,
  ),
  section(TherapyIntakeSectionName.SAFETY, SafetyForm, validateSafety),
  section(
    TherapyIntakeSectionName.SUBSTANCE_ABUSE,
    SubstanceAbuseForm,
    validateSubstanceUsed,
  ),
  section(
    TherapyIntakeSectionName.BEHAVIORAL_OBSERVATION,
    BehavioralObservationsForm,
    validateBehavioralObservation,
  ),
  section(
    TherapyIntakeSectionName.ASSESSMENT,
    AssessmentForm,
    validateAssessment,
  ),
  section(
    TherapyIntakeSectionName.TREATMENT_PLAN,
    TreatmentPlanForm,
    validateTreatmentPlan,
  ),
  section(
    TherapyIntakeSectionName.COLLABORATION_PLAN,
    CollaborationPlanForm,
    validateCollaboration,
  ),
];

export function TherapyIntakeNoteScreen(props: Props) {
  const {
    appointmentId,
    note,
    status,
    memberId,
    onSubmit,
    updateDraftNoteState,
    draftNote,
  } = props;

  return (
    <ClinicalNotesScreen
      appointmentId={appointmentId}
      status={status}
      onSubmit={onSubmit}
      updateDraftNoteState={updateDraftNoteState}
      memberId={memberId}
      sections={sections}
      ids={TherapyIntakeIds}
      note={note as ClinicalNote<TherapyIntakeSection>}
      draftNote={draftNote as ClinicalNote<TherapyIntakeSection>}
      readOnly={props.readOnly}
    />
  );
}
