import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { classNameCombiner } from 'utils';

import styles from './CopyZoomLink.module.scss';

export const CopyZoomLink = ({
  zoomUrl,
  className = '',
}: {
  zoomUrl: string;
  className?: string;
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigator.clipboard.writeText(zoomUrl).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Tooltip disappears after 2 seconds
    });
  };

  return (
    <div className={classNameCombiner([styles.container, className])}>
      <span data-testid="copyZoomLink">Copy Zoom link</span>
      <Tooltip
        title="Link copied to clipboard"
        open={copied}
        disableFocusListener={true}
        disableHoverListener={true}
        disableTouchListener={true}
      >
        <ContentCopyIcon
          className={styles.copyIcon}
          onClick={handleCopyClick}
          style={{ cursor: 'pointer', marginLeft: '8px' }}
        />
      </Tooltip>
    </div>
  );
};
