import React from 'react';

export interface TabPanelProps {
  children?: React.ReactNode;
  selectedTabIndex: number;
  selectedTabValue: number;
}

export default function TabPanel({
  children,
  selectedTabValue,
  selectedTabIndex,
}: TabPanelProps) {
  return (
    <div role="tabpanel" hidden={selectedTabValue !== selectedTabIndex}>
      {selectedTabValue === selectedTabIndex && (
        <div>
          <>{children}</>
        </div>
      )}
    </div>
  );
}
