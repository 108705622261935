import { Popover } from '@mui/material';
import EllipsisMenu from 'app/patients/tabs/content/EllipsisMenu';
import styles from 'app/patients/tabs/content/Homework.module.scss';
import { HomeworkStatus } from 'app/patients/tabs/content/types';
import { classNameCombiner, formatDate } from 'utils';
import { useContainerWidth } from 'hooks/useContainerWidth';
import React from 'react';
import ProgressBadge from 'shared-components/badge/ProgressBadge';

import { useUnassignHomeworkMutation } from './UseUnassignHomeworkMutation';
import { contentDetailsLayoutConfig } from './utils';

export interface HomeworkProps {
  payload: {
    assignmentId: string;
    slug: string;
    thumbnail: string;
    title: string;
    source: string;
    date: string;
    contentType: string;
    status: HomeworkStatus;
  }[];
  memberId: string;
  dateLabel: string;
}

export default function Homework({
  payload,
  memberId,
  dateLabel,
}: HomeworkProps) {
  /*  showDrawer that was used before does not work for defining the layout.
  The drawer may be open but there's still plenty of space, so we don't need to use the single column layout and vice versa.
  The useContainerWidth hook looks at the container width  and return the number of columns based on the config */
  const { target, numberOfColumns } = useContainerWidth(
    contentDetailsLayoutConfig,
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const removeHomework = useUnassignHomeworkMutation(memberId);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const isSingleColumn = numberOfColumns === 1;

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUnassigned = () => {
    const contentId: string = anchorEl?.id ?? '';
    void removeHomework(contentId);
    handleClose();
  };

  return (
    <div className={styles.recommendationWrapper}>
      {!isSingleColumn && (
        <div
          className={classNameCombiner([
            styles.recommendationHeader,
            styles.header,
          ])}
        >
          <div className={styles.recommendationThumbnail}>&nbsp;</div>
          <div className={styles.recommendationDetails}>
            <div
              className={classNameCombiner([
                styles.recommendationTextbox,
                styles.header,
              ])}
            >
              Content
            </div>
            <div
              className={classNameCombiner([
                styles.recommendationDate,
                styles.header,
              ])}
            >
              {dateLabel}
            </div>
            <div
              className={classNameCombiner([
                styles.recommendationProgress,
                styles.header,
              ])}
            >
              Progress
            </div>
          </div>
          <div className={styles.recommendationMenu}>&nbsp;</div>
        </div>
      )}
      {payload.map((homework) => {
        const {
          thumbnail,
          title,
          source,
          date,
          assignmentId,
          status,
        } = homework;
        const style = {
          backgroundImage: `url(${thumbnail})`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '82px',
        };
        return (
          <div
            key={assignmentId}
            className={classNameCombiner([
              styles.recommendationContent,
              styles.content,
            ])}
          >
            <div
              className={classNameCombiner([
                styles.recommendationThumbnail,
                styles.content,
              ])}
              style={style}
            >
              &nbsp;
            </div>
            <div
              ref={target}
              className={classNameCombiner([
                styles.recommendationDetails,
                isSingleColumn ? styles.singleColumn : '',
              ])}
            >
              <div className={styles.recommendationTextbox}>
                <div
                  className={classNameCombiner([
                    styles.recommendationTitle,
                    isSingleColumn ? styles.singleColumn : '',
                  ])}
                >
                  {title}
                </div>
                {!isSingleColumn && (
                  <div className={styles.recommendationSource}>{source}</div>
                )}
              </div>
              <div
                className={classNameCombiner([
                  styles.recommendationDate,
                  styles.content,
                ])}
              >
                &emsp;&nbsp;
                {formatDate(date, 'MMM DD, YYYY')}
              </div>
              <div
                className={classNameCombiner([
                  styles.recommendationProgress,
                  styles.content,
                ])}
              >
                <ProgressBadge status={status} />
              </div>
            </div>
            <div className={styles.recommendationMenu}>
              {/* When this button is clicked, the "Popover" element is made visible */}
              <button
                className={styles.ellipsisButton}
                onClick={handleClick}
                id={assignmentId}
                aria-label="ellipsis-menu"
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom',
                }}
                transformOrigin={{
                  horizontal: 'right',
                  vertical: 'top',
                }}
              >
                {status !== HomeworkStatus.COMPLETED && (
                  <EllipsisMenu handleUnassign={() => handleUnassigned()} />
                )}
              </Popover>
            </div>
          </div>
        );
      })}
    </div>
  );
}
