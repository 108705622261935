import {
  field,
  Fields,
  numberField,
  stringField,
  useForm,
} from '@ginger.io/react-use-form';
import {
  CancelMemberCoachingSessionInput,
  CoachingSessionModificationScope,
  CreateMemberCoachingSessionInput,
  UpdateMemberCoachingSessionInput,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { useAppState } from 'app/state';
import { updateCoachingSession } from 'app/state/features/scheduler/schedulerSlice';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { CreationFields } from '../types';
import {
  DEFAULT_SESSION_COUNT,
  DEFAULT_SESSION_COUNT_OLD,
  toNewCoachingSession,
  toUpdatedCoachingSession,
  validateNumOccurrences,
} from '../utils';

export interface Props {
  memberId: string;
  coachTimeZone: string;
  memberTimeZone: string;
  onCreate: (input: CreateMemberCoachingSessionInput) => void;
  onUpdate?: (input: UpdateMemberCoachingSessionInput) => void;
  onCancelSession?: (input: CancelMemberCoachingSessionInput) => void;
}

export function useCoachingSessionForm(
  props: Props,
): {
  fields: Fields<CreationFields>;
  onClickSave: () => void;
  onConfirmEdit: (modificationScope: CoachingSessionModificationScope) => void;
  onDismissConfirmEdit: () => void;
  onClickCancel: () => void;
  onClickEdit: () => void;
  onConfirmCancel: (input: CancelMemberCoachingSessionInput) => void;
  onDismissConfirmCancel: () => void;
  showConfirmEdit: boolean;
  showConfirmCancel: boolean;
} {
  const dispatch = useDispatch();
  const {
    enable_coaching_scheduler: enableCoachingScheduler,
  } = useFeatureFlags().transientFeatureFlags;
  const {
    coachingSession,
    selectedCoachingSession: existingCoachingSession,
    selectedSessionTimes,
  } = useAppState(
    ({
      scheduler: {
        coachingSession,
        selectedCoachingSession,
        selectedSessionTimes,
      },
    }) => ({
      coachingSession,
      selectedCoachingSession,
      selectedSessionTimes,
    }),
  );
  const {
    memberId,
    coachTimeZone,
    memberTimeZone,
    onCancelSession,
    onCreate,
    onUpdate,
  } = props;
  const [showConfirmCancel, setShowConfirmCancel] = useState<boolean>(false);
  const [showConfirmEdit, setShowConfirmEdit] = useState<boolean>(false);
  const form = useForm<CreationFields>(
    {
      duration: numberField(),
      everyNWeeks: field(),
      id: stringField({ rules: [] }),
      numOccurrences: field({
        default: enableCoachingScheduler
          ? DEFAULT_SESSION_COUNT
          : DEFAULT_SESSION_COUNT_OLD,
        rules: [validateNumOccurrences],
      }),
      sessionFormat: field({ rules: [] }),
      sessionStartTime: {
        hour: numberField(),
        minute: numberField(),
      },
      sessionType: field({ rules: [] }),
      startDate: {
        date: numberField(),
        month: numberField(),
        year: numberField(),
      },
    },
    coachingSession,
    {
      onStateChange: async (data) =>
        dispatch(
          updateCoachingSession({
            coachingSession: {
              ...data,
              // Ensure numOccurrences is set to 1 for non-recurring sessions to avoid inconsistent
              // data scenarios where numOccurrences might incorrectly suggest multiple sessions.
              numOccurrences: data.everyNWeeks === 0 ? 1 : data.numOccurrences,
            },
          }),
        ),
    },
  );

  const onClickSave = () => {
    if (existingCoachingSession && existingCoachingSession.recurrence) {
      setShowConfirmEdit(true);
    } else {
      void validateAndSave(CoachingSessionModificationScope.THIS_ONLY);
    }
  };

  const onClickCancel = () => {
    setShowConfirmCancel(true);
  };

  const onClickEdit = () => {
    setShowConfirmEdit(true);
  };

  const onConfirmCancel = (input: CancelMemberCoachingSessionInput) => {
    setShowConfirmCancel(false);
    onCancelSession?.(input);
  };

  const onConfirmEdit = (
    modificationScope: CoachingSessionModificationScope,
  ) => {
    setShowConfirmEdit(false);
    void validateAndSave(modificationScope);
  };

  const onDismissConfirmEdit = () => setShowConfirmEdit(false);

  const onDismissConfirmCancel = () => setShowConfirmCancel(false);

  const prepareNewSessionData = (
    formData: CreationFields,
  ): CreateMemberCoachingSessionInput => {
    if (enableCoachingScheduler) {
      return toNewCoachingSession({
        endTime: selectedSessionTimes?.endTime,
        formData: {
          ...formData,
          sessionStartTime:
            coachingSession?.sessionStartTime ?? formData.sessionStartTime,
        },
        memberId,
        memberTimeZone,
        startTime: selectedSessionTimes?.startTime,
      });
    }

    return toNewCoachingSession({
      coachTimeZone,
      formData,
      memberId,
      memberTimeZone,
    });
  };

  const validateAndSave = async (
    modificationScope: CoachingSessionModificationScope,
  ) => {
    const isValid = await form.validate();

    if (!isValid) {
      return;
    }

    if (existingCoachingSession) {
      let sessionData = toUpdatedCoachingSession(
        form.getValue(),
        coachTimeZone,
        memberTimeZone,
        modificationScope,
        selectedSessionTimes,
      );
      sessionData = { ...sessionData, modificationScope };
      onUpdate?.(sessionData);
    } else {
      const formData = form.getValue();
      const sessionData = prepareNewSessionData(formData);
      onCreate(sessionData);
    }
  };

  return {
    fields: form.fields,
    onClickCancel,
    onClickEdit,
    onClickSave,
    onConfirmCancel,
    onConfirmEdit,
    onDismissConfirmCancel,
    onDismissConfirmEdit,
    showConfirmCancel,
    showConfirmEdit,
  };
}
