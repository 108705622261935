import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Menu } from '@mui/material';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';

import styles from './MenuButton.module.scss';

export type MenuButtonProps = {
  onItemClick: (item: string) => void;
  buttonText: string;
  submenuText?: string;
  testId?: string;
  items: string[];
};

/**
 * A button with a menu dropdown
 */
export function MenuButton(props: MenuButtonProps) {
  const { testId, items, buttonText, submenuText } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onItemClick = (item: string) => {
    props.onItemClick(item);
    onClose();
  };

  return (
    <div>
      <Button
        data-testid={testId}
        className={styles.dropdownButton}
        aria-controls="new-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={onClick}
      >
        {buttonText}
        <ArrowDropDownIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted={true}
        open={open}
        classes={{ list: styles.menuRoot }}
        onClose={onClose}
        TransitionComponent={Fade}
      >
        {submenuText && <div className={styles.subheader}>{submenuText}</div>}
        {items.map((item) => (
          <MenuItem
            key={item}
            data-testid={`${testId}-${item}-item`}
            className={styles.item}
            onClick={() => onItemClick(item)}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
