import { QuickLinks } from 'app/inbox/vertical-menu/QuickLinks';
import styles from 'app/inbox/vertical-menu/styles/index.module.scss';
import { toggleInbox as toggleInboxV2 } from 'app/inboxV2/state/sideBar/sideBarSlice';
import { useAppState } from 'app/state';
import { toggleDrawer } from 'app/state/inbox/actions';
import { State } from 'app/state/schema';
import { selectShouldDisplayInboxV2 } from 'app/state/user/selectors';
import { TopLevelRoutes } from 'app/top-nav/Routes';
import MenuV2 from 'assets/icons/menu-v2.svg';
import InboxNotificationIcon from 'assets/inbox/alert.svg';
import { UserRole } from 'generated/globalTypes';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import Tooltip from 'shared-components/tooltip/Tooltip';
import { classNameCombiner, isClinicianOrSupervisor } from 'utils';

import { StatusMenu } from './StatusMenu';

const routeToLabel: { [k in TopLevelRoutes]?: string } = {
  [TopLevelRoutes.PATIENTS]: 'Member charts',
  [TopLevelRoutes.COACHING]: 'Member charts',
  [TopLevelRoutes.SCHEDULE]: 'Schedule',
  [TopLevelRoutes.APPOINTMENTS]: 'Appointments',
  [TopLevelRoutes.ERX_NOTIFICATIONS]: 'Prescriptions',
};

const LinkWithTooltip = forwardRef<HTMLButtonElement>((props: any, ref) => {
  const {
    href,
    navigate,
    onClick: propOnClick,
    ...restProps
  } = (props as unknown) as any;
  const onClick = propOnClick ?? (() => navigate(href));
  return (
    <Tooltip
      classes={{ popper: styles.popper, tooltip: styles.tooltip }}
      title={routeToLabel[href as TopLevelRoutes] ?? 'Unknown'}
    >
      <button
        onClick={onClick}
        ref={ref}
        className={classNameCombiner([styles.menuItemBtn])}
      >
        <div {...restProps} />
      </button>
    </Tooltip>
  );
});

const InboxV2Menu = () => {
  const dispatch = useDispatch();
  return (
    <Tooltip
      classes={{ popper: styles.popper, tooltip: styles.tooltip }}
      title="Tasks & Members"
      data-testid="inboxV2-menu-item"
    >
      <button
        onClick={() => {
          dispatch(toggleInboxV2());
        }}
        className={classNameCombiner([styles.menuItemBtn])}
        type="button"
        aria-label="inbox v2 menu item"
      >
        <div className={classNameCombiner([styles.menuItem])}>
          <img src={MenuV2} className={styles.inboxIconV2} alt="" />
        </div>
      </button>
    </Tooltip>
  );
};

const InboxV1Menu = () => {
  const dispatch = useDispatch();
  const showBlueDot = useAppState(selectHasNotification);
  return (
    <Tooltip
      classes={{ popper: styles.popper, tooltip: styles.tooltip }}
      title="Tasks & Members"
    >
      <button
        type="button"
        onClick={() => {
          dispatch(toggleDrawer({}));
        }}
        className={classNameCombiner([styles.menuItemBtn])}
      >
        <div className={classNameCombiner([styles.menuItem, styles.inboxIcon])}>
          {showBlueDot && (
            <img
              className={styles.inboxNotificationIcon}
              src={InboxNotificationIcon}
              alt="Inbox Notification"
            />
          )}
        </div>
      </button>
    </Tooltip>
  );
};

function VerticalMenu() {
  const role = useAppState(({ user }) => user.role!);
  const history = useHistory();

  const {
    transientFeatureFlags: { carehub_enable_coach_inbox_v2: enableInboxV2 },
  } = useFeatureFlags();

  const shouldShowInboxV2 = useAppState((state) =>
    selectShouldDisplayInboxV2(enableInboxV2)(state),
  );

  return (
    <>
      <div className={styles.top}>
        {shouldShowInboxV2 ? <InboxV2Menu /> : <InboxV1Menu />}

        <NavLink
          component={LinkWithTooltip}
          to={
            isClinicianOrSupervisor(role)
              ? TopLevelRoutes.PATIENTS
              : TopLevelRoutes.COACHING
          }
          activeClassName={styles.active}
          className={classNameCombiner([
            styles.menuItem,
            styles.memberChartsIcon,
          ])}
        />
        {isClinicianOrSupervisor(role) && (
          <>
            <NavLink
              component={LinkWithTooltip}
              to={TopLevelRoutes.SCHEDULE}
              activeClassName={styles.active}
              className={classNameCombiner([
                styles.menuItem,
                styles.scheduleIcon,
              ])}
            />
          </>
        )}
        {[UserRole.PSYCHIATRIST, UserRole.PSYCHIATRIST_SUPERVISOR].includes(
          role,
        ) && (
          <NavLink
            component={LinkWithTooltip}
            to={TopLevelRoutes.ERX_NOTIFICATIONS}
            activeClassName={styles.active}
            className={classNameCombiner([
              styles.menuItem,
              styles.prescriptionsIcon,
            ])}
          />
        )}
        <hr />
        <StatusMenu redirectTo={history.push} />
        <QuickLinks role={role} redirectTo={history.push} />
      </div>

      <div className={styles.bottom}>
        <div
          className={classNameCombiner([
            styles.menuItem,
            styles.gingerLogoIcon,
          ])}
        />
      </div>
    </>
  );
}
export default React.memo(VerticalMenu);

const selectHasNotification = ({ inbox }: State) => {
  const { unreadMessagesMap, inboxItems, tabSections, showInboxDrawer } = inbox;
  const hasUnreadMessages =
    Object.values(unreadMessagesMap).flatMap((_) => _).length > 0;
  const hasUnViewedRiskAlerts = Array.from(tabSections.RISK_ALERTS.ids)
    .filter((id) => inboxItems[id])
    .map((id) => inboxItems[id].showNewRiskTaskIndicator)
    .some((_) => _);
  const hasNotification = hasUnreadMessages || hasUnViewedRiskAlerts;

  return hasNotification && !showInboxDrawer;
};
