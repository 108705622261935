import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import styles from 'app/inbox/modals/AlertDialog.module.scss';
import Messages from 'i18n/en/inbox.json';
import React, { useEffect } from 'react';

export type AlertDialogProps = {
  open: boolean;
  close: () => void;
};

export default function AlertDialog({ open, close }: AlertDialogProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    if (close) close();
    setIsOpen(false);
  };
  useEffect(() => {
    if (open) handleOpen();
  }, [open]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        className={styles.alertDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        PaperProps={{
          style: { borderRadius: 15 },
        }}
      >
        <DialogContent className={styles.alertDialogContent}>
          <DialogTitle
            className={styles.alertDialogTitle}
            id="alert-dialog-title"
          >
            <h2>Too many tabs open!</h2>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={styles.alertDialogContent}>
            <DialogContentText
              className={styles.alertDialogText}
              id="alert-dialog-description"
            >
              {Messages.maximumNumberOfOpenCharts}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            className={styles.alertDialogActions}
            sx={{
              margin: 0,
              padding: '10px 10px 20px',
            }}
          >
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{
                margin: 0,
                padding: 0,
              }}
            >
              Okay
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
