import React from 'react';

import styles from './LineChart.module.scss';

type DotVariant = 'grey' | 'blue';
/** A shaded dot marker used to visualize a numerical value */
function Dot(props: { active?: boolean; variant: DotVariant }) {
  const classes = [styles.dot];
  if (props.active) {
    classes.push(styles.active);
  }
  if (props.variant === 'grey') {
    classes.push(styles.grey);
  }
  if (props.variant === 'blue') {
    classes.push(styles.blue);
  }
  return <div className={classes.join(' ')} />;
}

/**  Set of shaded 4 dots, representing a value in the range of 0-3 - e.g. [X][X][][] for 2 */
export default function FourDots(props: { value: number | undefined }) {
  const { value = 0 } = props;
  let variant: DotVariant = 'grey'; // grey by default for 1 or 2
  switch (value) {
    case 3:
    case 4:
      variant = 'blue';
      break;
  }
  return (
    <div className={styles.container}>
      <Dot active={value >= 1} variant={variant} />
      <Dot active={value >= 2} variant={variant} />
      <Dot active={value >= 3} variant={variant} />
      <Dot active={value >= 4} variant={variant} />
    </div>
  );
}
