import { CareHubLandingScreen } from 'app/CareHubLandingScreen';
import useMultiTabs from 'app/inbox/components/useMultiTabs';
import MultiTabs from 'app/inbox/multi-tabs/index';
import { setCurrentChannel } from 'app/state/inbox/actions';
import { setActiveTab } from 'app/state/member-tabs/actions';
import { useOnMount } from 'hooks/useOnMount';
import React, { ElementType } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import styles from './MultiTabMemberScreen.module.scss';

export function MultiTabMemberScreen(props: {
  paramKey: string;
  navigateTo: (id: string | null) => void;
  Component: ElementType<{ selectedMemberId: string }>;
}) {
  const params = useParams<Record<string, string>>();
  const memberId = params[props.paramKey];
  const dispatch = useDispatch();
  const { initialized, activeTab } = useMultiTabs();

  useOnMount(() => {
    if (memberId) {
      dispatch(setActiveTab(memberId));
    } else if (initialized && activeTab) {
      props.navigateTo(activeTab);
    }
  });
  if (!memberId) {
    if (activeTab) props.navigateTo(activeTab);
    return <CareHubLandingScreen />;
  }

  const onTabClose = (newActiveTab: string | null) => {
    // do nothing if the memberId is the same
    if (memberId !== newActiveTab) {
      props.navigateTo(newActiveTab);
      dispatch(setCurrentChannel(''));
    }
  };

  const onTabSelect = (key: string) => {
    // do nothing if the memberId is the same
    if (memberId !== key) {
      props.navigateTo(key);
      dispatch(setCurrentChannel(''));
    }
  };

  return (
    <>
      <div className={styles.multiTabContainer}>
        <MultiTabs onTabSelect={onTabSelect} onTabClose={onTabClose} />
      </div>
      <props.Component selectedMemberId={memberId} />
    </>
  );
}
