import {
  AutocompleteIcon,
  AutoCompleteWithTag,
  AutoCompleteWithTagV2,
} from 'app/notes-ui/forms/form-controls/AutoCompleteWithTag';
import { EnumOption, ProtobufEnum } from 'app/notes-ui/types';
import { enumToOptions } from 'utils/notes';
import React, { useEffect, useMemo, useState } from 'react';
import { Width } from 'types/StyleTypes';

type Props<T> = {
  name: string;
  placeholder?: string;
  disabled?: boolean;
  type: ProtobufEnum;
  width?: Width;
  className?: string;
  icon?: AutocompleteIcon;
  value?: T[];
  keyLabels?: Record<string, string>;
  error?: string;
  hideSelectedTags?: boolean;
  onChange: (value: T[]) => void;
  convertEnumToOptions?: (obj: ProtobufEnum) => EnumOption[];
  tagClass?: string;
  'data-testid'?: string;
};

export function MultipleSelectEnumDropdown<T>(props: Props<T>) {
  const {
    name,
    disabled,
    placeholder = '',
    className,
    value = [],
    keyLabels,
    error,
    hideSelectedTags = false,
  } = props;
  const getOptionLabel = (option: EnumOption) => option.name;

  const options = useMemo(
    () =>
      props.convertEnumToOptions
        ? props.convertEnumToOptions(props.type)
        : enumToOptions(props.type, keyLabels),
    [props.type],
  );

  const initialValue = options.filter((options) =>
    value.includes((options.value as any) as T),
  );

  const [tags, setTags] = useState<EnumOption[]>(initialValue);
  const onChange = (tags: EnumOption[]) => {
    setTags(tags);
    props.onChange(tags.map((tag) => (tag.value as any) as T));
  };
  return (
    <AutoCompleteWithTag
      disabled={disabled}
      noOptionsText="Not found"
      placeholder={placeholder}
      name={name}
      tags={tags}
      hideSelectedTags={hideSelectedTags}
      options={options}
      onChange={onChange}
      error={error}
      getOptionLabel={getOptionLabel}
      width={props.width}
      className={className}
      icon={props.icon}
    />
  );
}

export function MultipleSelectEnumDropdownV2<T>(props: Props<T>) {
  const {
    name,
    disabled,
    placeholder = '',
    className,
    value = [],
    keyLabels,
    error,
    hideSelectedTags = false,
    tagClass,
  } = props;
  const getOptionLabel = (option: EnumOption) => option.name;

  const options = useMemo(
    () =>
      props.convertEnumToOptions
        ? props.convertEnumToOptions(props.type)
        : enumToOptions(props.type, keyLabels),
    [props.type],
  );

  useEffect(() => {
    const initialValue = options.filter((options) =>
      value.includes((options.value as any) as T),
    );
    setTags(initialValue);
  }, [value]);

  const [tags, setTags] = useState<EnumOption[]>([] as EnumOption[]);
  const onChange = (tags: EnumOption[]) => {
    setTags(tags);
    props.onChange(tags.map((tag) => (tag.value as any) as T));
  };
  return (
    <AutoCompleteWithTagV2
      disabled={disabled}
      noOptionsText="Not found"
      placeholder={placeholder}
      name={name}
      tags={tags}
      hideSelectedTags={hideSelectedTags}
      options={options.filter((o) => o.readonly !== true)}
      onChange={onChange}
      error={error}
      getOptionLabel={getOptionLabel}
      width={props.width}
      className={className}
      icon={props.icon}
      tagClass={tagClass}
      data-testid={props['data-testid']}
    />
  );
}
