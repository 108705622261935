import { updateConversationPresence } from '../actions';
import { getOnlineStatusByChannelIds, uniqueArrayList } from '../utils';
import { ActionHandler } from './types';

export const onTriggerPubnubChannelSubscription = async ({
  action,
  redux,
  context,
}: ActionHandler<{ channelIds: string[] }>) => {
  // We want to subscribe to the member<>coach pubnub channel for each member in the channelIds list
  // and retrieve their online presence status.
  const state = redux.getState();
  const selectedMemberId = state.multiTab.activeTab;
  // ensure we subscribe to the selected member channel
  const { inboxItems } = state.inbox;
  const selectedChannelId =
    selectedMemberId && inboxItems[selectedMemberId]
      ? inboxItems[selectedMemberId]?.memberCoachChannelId
      : null;

  const { logger, pubnub } = context.services;

  if (selectedMemberId && !state.inbox.inboxItems[selectedMemberId]) {
    logger.warning(
      'triggerPubnubChannelSubscription:: Inbox item not found for the selectedMemberId.',
      {
        selectedMemberId,
      },
    );
  }
  const channelIds = uniqueArrayList(
    selectedChannelId
      ? [...action.payload.channelIds, selectedChannelId]
      : action.payload.channelIds,
  );
  const { memberCoachChannelPrefix } = state.chat;
  if (memberCoachChannelPrefix) {
    const nonHierarchicalChannels = channelIds.filter(
      (channel) => !channel.startsWith(memberCoachChannelPrefix),
    );
    logger.info(
      'triggerPubnubChannelSubscription:: subscribing to channels with wildcard',
      {
        hierarchicalChannelCount:
          channelIds.length - nonHierarchicalChannels.length,
        memberCoachChannelPrefix,
        nonHierarchicalChannelCount: nonHierarchicalChannels.length,
        totalChannelCount: channelIds.length,
      },
    );
    pubnub.subscribeToChannels({ channels: [`${memberCoachChannelPrefix}*`] });
    // non-hierarchical channels require withPresence
    pubnub.subscribeToChannels({
      channels: nonHierarchicalChannels,
      withPresence: true,
    });
  } else {
    logger.info(
      'triggerPubnubChannelSubscription:: subscribing to channels without wildcard',
      {
        totalChannelCount: channelIds.length,
      },
    );
    pubnub.subscribeToChannels({ channels: channelIds, withPresence: true });
  }
  try {
    const onlineStatusByChannelId = await getOnlineStatusByChannelIds(
      pubnub,
      channelIds,
      logger,
    );
    redux.dispatch(
      updateConversationPresence({ presences: onlineStatusByChannelId }),
    );
  } catch (error) {
    logger.error(
      new Error(
        "triggerPubnubChannelSubscription: unable to fetch online status of members care provider's inbox",
      ),
      { error, uuid: pubnub.getUUID() },
    );
  }
};
