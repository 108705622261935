import React from 'react';
import { Input } from 'shared-components/form-inputs/Input';
import styles from './NumberInput.module.scss';
import { FormError } from 'shared-components/FormError';
import { NoteTooltip } from './NoteTooltip';
import { Size } from 'types/StyleTypes';
import { InputBaseComponentProps } from '@mui/material';

export function NumberInput(props: {
  name: string;
  label?: string;
  value: number;
  error?: string;
  disabled?: boolean;
  inputClass?: string;
  className?: string;
  placeholder?: string;
  onChange: (value: number) => void;
  tooltip?: string;
  size?: Size;
  inputProps?: InputBaseComponentProps;
}) {
  const { size = Size.SM, inputProps } = props;
  return (
    <div className={[styles.root, props.className].join(' ')}>
      <div className={styles.control}>
        <Input
          className={[styles.input, styles[size], props.inputClass].join(' ')}
          value={`${props.value}`}
          name={props.name}
          type="number"
          variant="outlined"
          onChange={(v) => props.onChange(parseInt(v))}
          disabled={props.disabled}
          placeholder={props.placeholder}
          inputProps={inputProps}
        />
        {props.label && (
          <label className={styles.label} data-testid="inputLabel">
            {props.label}{' '}
            {props.tooltip && <NoteTooltip tooltip={props.tooltip} />}
          </label>
        )}
      </div>
      <FormError error={props.error} />
    </div>
  );
}
