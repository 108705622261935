import { URLParams } from 'app/notes-ui/ClinicalNotesScreenRoutes';
import { NoteHeader, NoteType } from 'app/notes-ui/NoteHeader';
import { canCreateOrUpdateNote, canLockNote } from 'app/notes-ui/utils';
import { MemberHeaderBar } from 'app/patients/header/MemberHeaderBar';
import { useAppState } from 'app/state';
import { renderSliceStateResult } from 'app/state/status/RenderSliceState';
import { Status } from 'app/state/status/types/StateSlice';
import { selectUserIdAndRole } from 'app/state/user/selectors';
import { usePsychiatryProgressNote } from 'app/vault/hooks/usePsychiatryProgressNote';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

import { PsychiatryProgressNoteScreen } from './progress/PsychiatryProgressNoteScreen';

export function PsychiatryProgressNoteContainer(): ReactElement {
  const noteType = NoteType.PSYCHIATRY_PROGRESS;
  const { appointmentId } = useParams<URLParams>();
  const userRoleAndId = useAppState(selectUserIdAndRole);
  const { userId } = userRoleAndId;
  const featureFlags = useFeatureFlags();

  const {
    note,
    isLockable,
    lockNote,
    createOrUpdateSection,
    appointment,
    updateDraftNoteState,
    draftNote,
    deleteDraft,
  } = usePsychiatryProgressNote(userId!, appointmentId);

  return renderSliceStateResult(note.current, (data) => (
    <>
      {appointment && (
        <>
          <MemberHeaderBar memberId={appointment.member.id} />
          <NoteHeader
            noteType={noteType}
            status={data!.metadata.status}
            onNoteLocked={lockNote}
            disabled={
              !isLockable ||
              !canLockNote(
                featureFlags,
                appointment,
                userRoleAndId,
                data?.permissions!,
              )
            }
            appointment={appointment}
            deleteDraftNote={deleteDraft}
          />
        </>
      )}
      <PsychiatryProgressNoteScreen
        status={Status.COMPLETE}
        appointmentId={appointmentId}
        memberId={appointment?.member.id}
        note={data!}
        draftNote={draftNote.current.data!}
        onSubmit={createOrUpdateSection}
        updateDraftNoteState={updateDraftNoteState}
        readOnly={
          !canCreateOrUpdateNote(
            featureFlags,
            appointment,
            userRoleAndId,
            data!.metadata.status,
          )
        }
      />
    </>
  ));
}
