import MenuMui from '@mui/material/Menu';
import React, { ElementType, MouseEvent, ReactElement, useState } from 'react';

interface MenuComponentProps {
  menuItems: ReactElement[];
  testId?: string;
  Icon: ElementType;
}

function Menu(props: MenuComponentProps) {
  const { menuItems, Icon, testId } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (event?: MouseEvent<HTMLButtonElement>) => {
    if (event !== undefined) {
      setAnchorEl(event.currentTarget);
    }
    setIsOpen(true);
  };

  const closePopup = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  return (
    <>
      <Icon onClick={handleClick} />
      <MenuMui
        data-testid={`${testId}PopuMenu`}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closePopup}
      >
        {menuItems}
      </MenuMui>
    </>
  );
}

export default Menu;
