import { useEffect, useRef } from 'react';

export function useInterval(
  callback: () => void,
  delay: number,
  enableInterval = true,
) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current!();
    }
    let id: ReturnType<typeof setInterval> | undefined;
    if (enableInterval) {
      id = setInterval(tick, delay);
    }
    return () => {
      if (id) clearInterval(id);
    };
  }, [delay, enableInterval]);
}
