import { ShareableClinicianNoteType } from 'app/coach/coach-notes/CoachNotesTypes';
import {
  PSYCHIATRY_NOTE_TYPES,
  TERMINATION_NOTE_TYPES,
  THERAPY_NOTE_TYPES,
} from 'app/coach/coach-notes/constants';
import {
  MessageToCareTeam,
  PsychiatrySafetyPlan,
  TerminationNote,
  TherapySafetyPlan,
  TreatmentPlanGoals,
} from 'app/coach/coach-notes/note-detail-view/note-templates/clinical-notes';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { useAppState } from 'app/state';
import { Subsection } from 'app/vault/api/ShareableSubsectionTypes';
import React from 'react';

const TOOLTIP_EVENT_LABEL = 'Coach Notes > Shared Clinical Note';

export const SharedClinicalNote = ({
  readOnly,
  noteType,
  'data-testid': testId = 'sharedClinicalNote',
}: {
  readOnly: boolean;
  noteType: ShareableClinicianNoteType;
  'data-testid'?: string;
}): React.ReactElement | null => {
  const { selectedNote } = useAppState((_) => _.coachNotes);
  const intialValue = selectedNote?.data as Subsection;
  const isTherapyNote = THERAPY_NOTE_TYPES.includes(noteType);
  const isPsychiatryNote = PSYCHIATRY_NOTE_TYPES.includes(noteType);
  const isTerminationNote = TERMINATION_NOTE_TYPES.includes(noteType);

  return (
    <div data-testid={testId} className={styles.cardSection}>
      {(isTherapyNote || isPsychiatryNote) && (
        <>
          <MessageToCareTeam
            selectedNote={intialValue}
            tooltipEventLabel={TOOLTIP_EVENT_LABEL}
            readOnly={readOnly}
          />
          <TreatmentPlanGoals
            selectedNote={intialValue}
            tooltipEventLabel={TOOLTIP_EVENT_LABEL}
            readOnly={readOnly}
          />
        </>
      )}
      {isTherapyNote && (
        <TherapySafetyPlan
          selectedNote={intialValue}
          tooltipEventLabel={TOOLTIP_EVENT_LABEL}
          readOnly={readOnly}
        />
      )}
      {isPsychiatryNote && (
        <PsychiatrySafetyPlan
          selectedNote={intialValue}
          tooltipEventLabel={TOOLTIP_EVENT_LABEL}
          readOnly={readOnly}
        />
      )}
      {isTerminationNote && (
        <TerminationNote
          selectedNote={intialValue}
          tooltipEventLabel={TOOLTIP_EVENT_LABEL}
          readOnly={readOnly}
        />
      )}
    </div>
  );
};
