import {
  field,
  FieldDefinitions,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  Allergies,
  Allergies_Allergy as Allergy,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/Allergies';
import {
  AllergiesSection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { SaveButton } from 'app/notes-ui/forms/form-controls/SaveButton';
import { PsychiatricHistoryTable } from 'app/notes-ui/psychiatry/shared/psychiatric-history/PsychiatricHistoryTable';
import { ColumnDefinition } from 'app/notes-ui/psychiatry/shared/psychiatric-history/PsychiatryHistoryRow';
import { useFormTable } from 'app/notes-ui/psychiatry/shared/psychiatric-history/useFormTable';
import { AUTO_SAVE_DRAFT_DELAY } from 'app/notes-ui/utils';
import React from 'react';

type Props = {
  appointmentId: string;
  onSubmit: (data: AllergiesSection) => void;
  updateDraftNoteState: (data: AllergiesSection) => void;
  disabled?: boolean;
  savingNote?: boolean;
  initialValue?: Allergies;
};

export function AllergiesForm(props: Props) {
  const { initialValue, disabled, savingNote, appointmentId } = props;
  const { fields, validate, getValue } = useForm(
    fieldDefs,
    { ...initialValue, appointmentId } as Allergies,
    {
      delay: AUTO_SAVE_DRAFT_DELAY,
      onStateChange: (data) =>
        props.updateDraftNoteState({
          data,
          name: PsychiatrySectionName.ALLERGIES,
        }),
    },
  );

  const allergiesFormTable = useFormTable<Allergy>(
    'Must fill the allergies table or check the checkbox if there are no known allergies',
    initialValue?.allergies,
    fields.allergies.setValue,
  );

  const onSubmit = async () => {
    if (await validate()) {
      props.onSubmit({
        data: getValue(),
        name: PsychiatrySectionName.ALLERGIES,
      });
    }
  };
  return (
    <>
      <PsychiatricHistoryTable
        label="Allergies*"
        noDataCheckboxLabel="No known allergies"
        onChange={allergiesFormTable.onChange}
        error={allergiesFormTable.error || fields.allergies.error}
        testId="allergies"
        initialValue={initialValue?.allergies}
        columns={allergiesColumnDef}
        fieldDefs={allergyFieldDefs}
        getBlankRecord={() => Allergy.fromPartial({})}
        disabled={disabled}
      />
      <SaveButton
        isLoading={savingNote}
        disabled={disabled}
        onSubmit={onSubmit}
      />
    </>
  );
}

const allergyFieldDefs: FieldDefinitions<Allergy> = {
  adverseReaction: field({ rules: [] }),
  comments: stringField({ rules: [] }),
  medication: stringField(),
};

const fieldDefs: FieldDefinitions<Allergies> = {
  allergies: field({
    rules: [
      (value) => {
        const isValid =
          value !== undefined &&
          value.every((_) => validateForm(_, allergyFieldDefs));
        if (!isValid) {
          return 'This field contains invalid input(s).';
        }
        return undefined;
      },
    ],
  }),
  appointmentId: field(),
};

const allergiesColumnDef: ColumnDefinition<Allergy>[] = [
  {
    columnType: 'text',
    key: 'medication',
    label: 'Medication*',
    placeholder: 'Drug name',
  },
  { columnType: 'text', key: 'comments', label: 'Comments' },
  {
    columnType: 'text',
    key: 'adverseReaction',
    label: 'Adverse Reaction',
    placeholder: 'Reaction',
  },
];

export function validate(data: Allergies | null) {
  return (
    data != null &&
    data.allergies.every((_) => validateForm(_, allergyFieldDefs))
  );
}
