import { Menu } from '@mui/material';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import { CoachNotesActions } from 'app/coach/coach-notes/CoachNotesTypes';
import { ActionMenuRoutes, CoachNotesRoutes } from 'app/top-nav/Routes';
import React, { useState } from 'react';
import ChipWithTooltip from 'shared-components/buttons/ChipWithTooltip';

import styles from './CardActionMenu.module.scss';

export type MENU_KEY_TYPE =
  | ActionMenuRoutes
  | CoachNotesRoutes
  | CoachNotesActions;

export type MenuItem = {
  label: string;
  key: MENU_KEY_TYPE;
};

export type ActionMenuProps = {
  menuItems: MenuItem[];
  actionIcon: JSX.Element;
  onSelect: (item: MENU_KEY_TYPE) => void;
  className?: string;
  tooltipText?: string;
  tooltipEventLabel?: string;
  'data-testid'?: string;
};

const CardActionMenuItem = (props: {
  item: MenuItem;
  onClick: (arg0: MENU_KEY_TYPE) => void;
}) => {
  const { item, onClick } = props;
  const _onClick = () => {
    onClick(item.key);
  };

  return (
    <MenuItem
      data-testid={item.key}
      classes={{ root: styles.item, selected: styles.focused }}
      onClick={_onClick}
      disableRipple={true}
    >
      {item.label}
    </MenuItem>
  );
};

export function CardActionMenu(props: ActionMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    className = '',
    tooltipEventLabel = '',
    tooltipText,
    menuItems,
    actionIcon,
    onSelect,
    'data-testid': testId = 'new-item-menu',
  } = props;

  const open = Boolean(anchorEl);
  const onClose = () => {
    setAnchorEl(null);
  };
  const onClickItem = (key: MENU_KEY_TYPE) => {
    onSelect(key);
    setAnchorEl(null);
  };

  return (
    <div>
      <ChipWithTooltip
        setAnchorEl={setAnchorEl}
        chipIcon={actionIcon}
        className={className}
        tooltipEventLabel={tooltipEventLabel}
        tooltipText={tooltipText}
        data-testid={testId}
      />
      <Menu
        id="newNoteButton"
        anchorEl={anchorEl}
        keepMounted={false}
        open={open}
        classes={{ list: styles.menuRoot }}
        onClose={onClose}
        TransitionComponent={Fade}
      >
        {menuItems.map((item) => (
          <CardActionMenuItem
            key={`${item.key}`}
            item={item}
            onClick={onClickItem}
          />
        ))}
      </Menu>
    </div>
  );
}
