import smileyAvatar from 'assets/chat/smiley-avatar.svg';
import { isUndefined } from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { classNameCombiner } from 'utils';

import styles from './Avatar.module.scss';

/**
 * @param firstName the first name of the person to be displayed
 * @param lastName the last name of the person to be displayed
 * @param src Optional source of avatar image, will use first letter of name otherwise
 * @param includeLastName Optional flag to include last name in intials if there's no image provided
 */
export interface Props {
  firstName: string;
  lastName: string;
  src?: string;
  className?: string;
  includeLastName?: boolean;
}

export function Avatar({
  firstName,
  lastName,
  src,
  className = '',
  includeLastName = false,
}: Props): ReactElement<Props> {
  const [hasImg, setHasImg] = React.useState(false);
  const altText = [firstName, lastName].join(' ');

  useEffect(() => {
    const checkImage = async () => {
      let exists = false;
      if (!isUndefined(src)) {
        exists = await imageExists(src);
        setHasImg(exists);
      }
    };
    void checkImage();
  }, [src]);

  if (!hasImg && !firstName) {
    return (
      <div className={classNameCombiner([styles.container, className])}>
        <img src={smileyAvatar} alt="Avatar" />
      </div>
    );
  }
  if (!hasImg) {
    const firstInitial = firstName.charAt(0).toUpperCase();
    const initials =
      includeLastName && lastName
        ? firstInitial + lastName.charAt(0).toUpperCase()
        : firstInitial;
    return <div className={`${styles.container} ${className}`}>{initials}</div>;
  }
  return (
    <div
      className={classNameCombiner([
        styles.container,
        styles.container_transparent,
        className,
      ])}
    >
      <img className={styles.image} alt={altText} src={src} />
    </div>
  );
}

async function imageExists(url: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = function () {
      resolve(true);
    };
    img.onerror = function () {
      resolve(false);
    };
  });
}
