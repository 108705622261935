import { Typography } from '@mui/material';
import {
  CoachNotesItem,
  DropInNoteType,
  NavigationParams,
} from 'app/coach/coach-notes/CoachNotesTypes';
import styles from 'app/coach/coach-notes/note-detail-view/NoteDetailView.module.scss';
import { Dropdown } from 'shared-components/Dropdown';
import { Width } from 'types/StyleTypes';
import { FieldLabel } from 'app/member-chart-cards/card-field/FieldLabel';
import React from 'react';

import { DeescalationNeed } from './DeescalationNeed';
import { DropInConsult } from './DropInConsult';
import { OtherNote } from './OtherNote';

const DROP_IN_NOTE_OPTIONS = [
  { name: 'Complete a consult', value: DropInNoteType.DROP_IN_CONSULT },
  { name: 'De-escalation need', value: DropInNoteType.DE_ESCALATION_NEED },
  { name: 'Experiencing risk', value: DropInNoteType.EXPERIENCING_RISK },
  { name: 'Exploring the app', value: DropInNoteType.EXPLORING_THE_APP },
  { name: 'Seeking clinical', value: DropInNoteType.SEEKING_CLINICAL },
  { name: 'Other', value: DropInNoteType.DROP_IN_OTHER },
];

const OtherDropInNoteType = [
  DropInNoteType.EXPERIENCING_RISK,
  DropInNoteType.EXPLORING_THE_APP,
  DropInNoteType.SEEKING_CLINICAL,
  DropInNoteType.DROP_IN_OTHER,
];

const dropInNoteTitle: Record<
  Exclude<DropInNoteType, DropInNoteType.DROP_IN>,
  string
> = {
  [DropInNoteType.DROP_IN_CONSULT]: 'Complete a consult',
  [DropInNoteType.DE_ESCALATION_NEED]: 'De-escalation need',
  [DropInNoteType.EXPERIENCING_RISK]: 'Experiencing risk',
  [DropInNoteType.EXPLORING_THE_APP]: 'Exploring the app',
  [DropInNoteType.SEEKING_CLINICAL]: 'Seeking clinical',
  [DropInNoteType.DROP_IN_OTHER]: 'Other',
};

export const DropInNote = ({
  noteType,
  readOnly,
  onStateChange,
  navigateTo,
  'data-testid': testId = 'dropIn',
}: {
  noteType: DropInNoteType;
  readOnly: boolean;
  navigateTo?: (params: NavigationParams) => void;
  onStateChange: (noteDraft: CoachNotesItem) => void;
  'data-testid'?: string;
}): React.ReactElement | null => {
  const onSelect = (selectedNoteType: DropInNoteType) =>
    navigateTo?.({ noteType: selectedNoteType });
  const dropInProps = { onStateChange, readOnly };

  return (
    <div data-testid={testId} className={styles.cardSection}>
      {noteType === DropInNoteType.DROP_IN ? (
        <>
          <FieldLabel className={styles.label} labelText="Reason for ITMS" />
          <Dropdown
            dataTestId="select"
            placeholder="Select..."
            selectClass={styles.dropdown}
            menuItemClass={styles.menuItem}
            options={DROP_IN_NOTE_OPTIONS}
            onSelect={onSelect}
            width={Width.FULL}
            showSelectedValue={false}
            MenuProps={{
              PaperProps: {
                style: {
                  maxWidth: '320px',
                  width: 'auto',
                },
              },
              anchorOrigin: {
                horizontal: 'center',
                vertical: 'bottom',
              },
              transformOrigin: {
                horizontal: 'center',
                vertical: 'top',
              },
            }}
          />
        </>
      ) : (
        <>
          <div className={styles.titleField}>
            <Typography className={styles.dropInTitle}>
              {dropInNoteTitle[noteType]}
            </Typography>
            <span className={`${styles.inlineBorder} ${styles.blueBorder}`} />
          </div>
          {noteType === DropInNoteType.DROP_IN_CONSULT && (
            <DropInConsult {...dropInProps} />
          )}
          {noteType === DropInNoteType.DE_ESCALATION_NEED && (
            <DeescalationNeed {...dropInProps} />
          )}
          {OtherDropInNoteType.includes(noteType) && (
            <OtherNote {...dropInProps} noteType={noteType} />
          )}
        </>
      )}
    </div>
  );
};
