import { OktaAuth } from '@okta/okta-auth-js';
import * as Sentry from '@sentry/react';
import { Event, EventHint } from '@sentry/types';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import { Stage } from 'app/Stage';
import { History } from 'history';

import { SentryClient } from './SentryClient';
import { Config, SentryClientImpl } from './SentryClientImpl';
import { StubSentryClient } from './StubSentryClient';

export function createSentryClient(
  stage: Stage,
  okta: OktaAuth,
  history: History,
): SentryClient {
  switch (stage) {
    case Stage.LOCAL:
      return new StubSentryClient();
    case Stage.LOCAL_GRAPHQL:
      return new StubSentryClient();
    case Stage.DEV:
      return new SentryClientImpl(
        buildSentryConfig(okta, history, {
          dsn:
            'https://531993cd28b0425fa73c8da30863e19b@o28532.ingest.us.sentry.io/3664471',
          replaysOnErrorSampleRate: 0.1,
          tracePropagationTargets: [
            /^\//,
            'graphql.ginger.dev',
            'dev.listenernow.com',
            'dev.ginger.io',
          ],
        }),
      );
    case Stage.PROD:
      return new SentryClientImpl(
        buildSentryConfig(okta, history, {
          dsn:
            'https://59bb0cb5bbf44296a90fee7c454e491f@o28532.ingest.us.sentry.io/3663974',
          replaysOnErrorSampleRate: 0.5,
          tracePropagationTargets: [
            /^\//,
            'graphql.ginger.io',
            'www.listenernow.com',
            'data.ginger.io',
          ],
        }),
      );
    default:
      throw new Error(
        `Unknown environment - sentry client not implemented for stage: ${stage}`,
      );
  }
}

function buildSentryConfig(
  okta: OktaAuth,
  history: History,
  overrides: Partial<Config>,
): Config {
  return {
    beforeBreadcrumb: excludeGraphQLFetch,
    beforeSend: setSentryEventFingerprint,
    dsn: '',
    fetchUser: async () => {
      const oktaUser = await okta.getUser();
      return { email: oktaUser.email ?? '' };
    },
    ignoreErrors: [
      'TypeError: Failed to fetch',
      'Not authenticated',
      'Authentication failed',
    ],
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
      }),
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [[500, 599]],
      }),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
      Sentry.extraErrorDataIntegration(),
      Sentry.replayIntegration({
        blockAllMedia: true,
        maskAllText: true,
      }),
    ],
    release: window.careHubVersion,
    replaysOnErrorSampleRate: 0,
    replaysSessionSampleRate: 0,
    tracePropagationTargets: [],
    tracesSampleRate: 0.1,
    ...overrides,
  };
}

/** Sets a sentry event's "fingerprint", which is used to group errors in Sentry's UI.
 *  See: https://docs.sentry.io/platforms/javascript/usage/sdk-fingerprinting/#group-errors-with-greater-granularity
 *
 *  Since some error messages from external services (e.g. Vault) include unique IDs, we first check whether the
 *  error message is one of those known patterns of messages that includes a unique ID, and we group on the non-unique
 *  portion.
 *
 *  If it's not one of those known patterns that includes unique IDs, we group on the error message itself.
 */
export async function setSentryEventFingerprint(
  event: Event,
  hint?: EventHint,
): Promise<Event> {
  const errorMessage = getErrorMessage(hint);

  // We naively assume we don't have overlapping grouping
  const fingerprintMatchers: [RegExp, string[]][] = [
    [/Received status code 400/i, ['400']],
    [/403/i, ['403']],
    [/404/i, ['404']],
    [/500/i, ['500']],
    [/502/i, ['502']],
    [/ECONNRESET/i, ['connection-reset']],
    [/VaultItems already exist/i, ['cant-overwrite-vault-items']],
    [
      /VaultItem (.*) has a different version than the given sourceVersion./i,
      ['vault-item-version-mismatch'],
    ],
    [
      /attempted to update a VaultItem they don't own/i,
      ['vault-cant-update-unowned-item'],
    ],
    [/VaultItem:(.*) doesn't exist./i, ['vault-update-non-existent-item']],
    [/failed to fetch/i, ['failed-to-fetch']],
    [
      /Cannot create a Ginger Auth Header/i,
      ['cannot-create-ginger-auth-header'],
    ],
    [/Cannot query field/i, ['cannot-query-field']],
    [/got invalid value/i, ['got-invalid-value']],
    [/Invalid or missing access token/i, ['invalid-access-token']],
    [/Cannot return null for non-nullable field/i, ['cannot-return-null']],
    [/non JSON payload/i, ['non-json-payload']],
    [
      /(event|appointment|available) in+( the)? range/i,
      ['existing-event-appointment'],
    ],
    [/marked as read-only/i, ['marked-as-read-only']],
    [/Authentication failed/i, ['authentication-failed']],
    [/end datetime must be > start datetime/i, ['invalid-start-datetime']],
    [
      /is not a valid choice for this appointment/i,
      ['invalid-appointment-status'],
    ],
    [/Transaction cancelled/i, ['transaction-cancelled']],
    [/no drchrono_id/i, ['no-drchrono-id']],
    [/unprocessedKeys/i, ['unprocessed-keys']],
    [
      /has not enabled availability/i,
      ['scheduling-clinician-availability-disabled'],
    ],
    [/Appointment(.*)does not exist/i, ['scheduling-appointment-404']],
    [/409 Client Error:(.*)drchrono/i, ['drc-409']],
    [/User(.*)already exists/i, ['vault-user-already-exists']],
    [/No VaultItemKeys found for VaultItems/i, ['vault-no-keys']],
    [/Not authenticated/i, ['not-authenticated']],
    [/Missing authorization header/i, ['missing-auth-header']],
    [/Not Authorised!/i, ['not-authorized']],
    [/deadlock detected/i, ['deadlock']],
    [
      /An error occurred in the current transaction. You can't execute queries until the end of the 'atomic' block./i,
      ['queries-after-transaction-error'],
    ],
  ];

  let matchesKnownPattern = false;

  fingerprintMatchers.forEach(([regex, fingerprint]) => {
    if (regex.exec(errorMessage)) {
      // Disabling lint rule since this matches Sentry's recommendation: https://docs.sentry.io/platforms/javascript/enriching-events/fingerprinting/.
      // eslint-disable-next-line no-param-reassign
      event.fingerprint = fingerprint;
      matchesKnownPattern = true;
    }
  });

  // If this is not one of the known patterns of errors, use the error message as the sole criterion
  // for grouping in Sentry.
  if (!matchesKnownPattern && errorMessage) {
    // Disabling lint rule since this matches Sentry's recommendation: https://docs.sentry.io/platforms/javascript/enriching-events/fingerprinting/.
    // eslint-disable-next-line no-param-reassign
    event.fingerprint = [errorMessage];
  }

  return event;
}

function getErrorMessage(hint?: EventHint): string {
  const error = hint?.originalException; // originalException: Error | string | null
  if (error === undefined || error === null) {
    return '';
  }
  if (typeof error === 'string') {
    return error;
  }

  return (error as Error).message;
}
