import Fab from '@mui/material/Fab';
import React from 'react';

import styles from './SchedulerCard.module.scss';
import { ExistingCoachingSession } from './types';

export interface Props {
  existingCoachingSession?: ExistingCoachingSession;
  onClose: () => void;
  onClickEdit: () => void;
  onClickSave: () => void;
  showSaveButton?: boolean;
  disableSave?: boolean;
}

const CreationButtons = (props: Props) => {
  const {
    existingCoachingSession,
    onClickEdit,
    onClickSave,
    onClose,
    showSaveButton = false,
    disableSave = false,
  } = props;

  return (
    <>
      <Fab
        className={styles.fab}
        variant="extended"
        size="small"
        onClick={() => onClose()}
      >
        <span className={styles.fabText}>Cancel</span>
      </Fab>
      {showSaveButton && (
        <Fab
          className={styles.fab}
          variant="extended"
          size="small"
          color="primary"
          onClick={existingCoachingSession ? onClickEdit : onClickSave}
          disabled={disableSave}
        >
          <span className={styles.fabText}>Save</span>
        </Fab>
      )}
    </>
  );
};

export default CreationButtons;
