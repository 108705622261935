import Titles from 'app/member-chart-cards/constants/cards-titles';
import styles from 'app/patients/tabs/content/Content.module.scss';
import { ContentSection } from 'app/patients/tabs/content/ContentSection';
import Homework from 'app/patients/tabs/content/Homework';
import { HomeworkData } from 'app/patients/tabs/content/types';
import { useContentPortalHelpers } from 'app/patients/tabs/content/UseContentHooks';
import { useAppState } from 'app/state';
import { addContentCircleButtonClicked } from 'app/state/amplitude/actions/content';
import React from 'react';
import { useDispatch } from 'react-redux';
import BeatLoader from 'react-spinners/BeatLoader';
import { Card } from 'shared-components/Card';

import { useGetHomeworkForMemberQuery } from './UseGetHomeworkForMemberQuery';

export const Content = (props: { memberId: string }) => {
  const contentMap = useAppState((_) => _.content.contentMap);
  const dataLoaded = useGetHomeworkForMemberQuery(props.memberId);
  const homeworks: HomeworkData = contentMap[props.memberId];
  const { openContentPortal } = useContentPortalHelpers();
  const dispatch = useDispatch();

  const circleButtonCallback = () => {
    dispatch(addContentCircleButtonClicked());
  };

  return (
    <div className={styles.contentTab}>
      <Card
        boxTitle={Titles.RECOMMENDATIONS_TITLE}
        actionMenu={
          <button
            className={styles.viewLibraryButton}
            onClick={openContentPortal}
            data-testid="view_library_button"
          >
            View Library
          </button>
        }
      >
        <div className={styles.contentTabContent}>
          {dataLoaded ? (
            <>
              <ContentSection
                sectionTitle="Current"
                isCollapsible={false}
                useAddCircleButton={true}
                headerStyle="light-cloud"
                memberId={props.memberId}
                addCircleButtonCallback={circleButtonCallback}
              >
                <Homework
                  payload={homeworks?.currentHomework ?? []}
                  memberId={props.memberId}
                  dateLabel="Date Assigned"
                />
              </ContentSection>
              <ContentSection sectionTitle="Completed" headerStyle="light-grey">
                <Homework
                  payload={homeworks?.completedHomework ?? []}
                  memberId={props.memberId}
                  dateLabel="Date Completed"
                />
              </ContentSection>
            </>
          ) : (
            <BeatLoader size={10} color="#dea531" loading={true} />
          )}
        </div>
      </Card>
    </div>
  );
};
