import { useState } from 'react';

type UseStringStateType = {
  value: string;
  setValue: (newValue: string) => void;
  isEmpty: () => boolean;
  isAtLeastNLength: (n: number) => boolean;
};

/**
 * Hook to manage string state with validation (e.g. required text)
 * This hook is used to manage the state of a string,
 * so instead of having to manage the state in the parent component with multiple useState declarations,
 * we can use this hook.
 * @param initialState
 * @returns {UseStringStateType}
 * @example
 * const { value, setValue, isEmpty, isAtLeastNLength } = useStringState()
 * <input value={value} onChange={e => setValue(e.target.value)} />
 * <button disabled={isEmpty() || !isAtLeastNLength(5)}>Submit</button>
 * <button onClick={() => setValue("")}>Clear</button>
 * */
const useStringState = (initialState: string = ''): UseStringStateType => {
  const [value, setValue] = useState(initialState);

  const isEmpty = () => value == null || value.trim() === '';
  const isAtLeastNLength = (n: number) => value.length >= n;

  return { isAtLeastNLength, isEmpty, setValue, value };
};

export default useStringState;
