import { Grid } from '@mui/material';
import React from 'react';

import styles from './UploadModalHeader.module.scss';

export default function UploadModalHeader() {
  return (
    <Grid
      className={styles.header}
      container={true}
      justifyContent="space-between"
      spacing={2}
    >
      <Grid item={true} xs={6}>
        <span>Name</span>
      </Grid>
      <Grid item={true} xs={3}>
        <span>Type</span>
      </Grid>
      <Grid item={true} xs={2}>
        <span>Size</span>
      </Grid>
      <Grid item={true} xs={1} />
    </Grid>
  );
}
